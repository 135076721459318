import {
  TITLE_ENGLISH_KEY,
  TITLE_KAZAKH_KEY,
  TITLE_RUSSIAN_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FieldDescription from '@/components/FieldDescription';
import TextField from '@/components/TextField';
import limits from '@/features/campaignCreatePage/EditPage/limits';

import { getName } from '../utils';

class TitleBlock extends Component {
  static propTypes = {
    Field: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  render() {
    const { Field, index, disabled } = this.props;

    return (
      <Fragment>
        <FieldDescription desc="Тип блока «Заголовок» позволяет вставить заголовок в начале статьи. Он отображается сразу под главным изображением, может быть только один и не может быть никуда перемещен." />

        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Field
              disabled={disabled}
              Target={TextField}
              name={getName(index, TITLE_RUSSIAN_KEY)}
              label="Текст заголовка"
              helperText="На русском"
              symbolsMax={limits.title}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              disabled={disabled}
              Target={TextField}
              name={getName(index, TITLE_ENGLISH_KEY)}
              label="Текст заголовка"
              helperText="На английском"
              symbolsMax={limits.title}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              disabled={disabled}
              Target={TextField}
              name={getName(index, TITLE_KAZAKH_KEY)}
              label="Текст заголовка"
              helperText="На казахском"
              symbolsMax={limits.title}
              fullWidth
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default TitleBlock;
