import cloneDeep from 'lodash.clonedeep';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FiltersRow from '@/components/FiltersRow';
import {
  filterPrefixes,
  filterTypes,
} from '@/features/mallReceptionUsersPage/filters';

const MallReceptionUsersFilterRow = props => {
  const [filters, setFilters] = useState(new Map());
  const currentUrlParams = new URLSearchParams(window.location.search);

  const changeUrlParams = params => {
    props.history.push({
      pathname: '/mall-reception-users',
      search: `?${decodeURIComponent(params.toString())}`,
    });
  };

  const updateFilters = payload => {
    const initMap = cloneDeep(filters);

    Object.keys(payload).forEach(fieldName => {
      const mapValue = payload[fieldName];

      if (mapValue && (mapValue.has('gte') || mapValue.has('lte'))) {
        mapValue.forEach((val, key) => {
          currentUrlParams.set(`${fieldName}[${key}]`, val);
        });
      } else {
        const { prefix } = filterPrefixes.find(
          filter => filter.field === fieldName,
        );
        const value = mapValue.get(prefix);

        currentUrlParams.set(`${fieldName}[${prefix}]`, value);
      }
      initMap.set(fieldName, mapValue);
    });

    changeUrlParams(currentUrlParams);
    setFilters(initMap);
  };

  const removeFilters = payload => {
    const params = new URLSearchParams(window.location.search);
    const initMap = cloneDeep(filters);

    payload.map(filter => {
      initMap.delete(filter);
      const { prefix } = filterPrefixes.find(({ field }) => field === filter);

      params.delete(`${filter}[${prefix}]`);

      return filter;
    });

    changeUrlParams(params);
    setFilters(initMap);
  };

  useEffect(() => {
    const initMap = new Map();
    let valueMap = new Map();
    let canClean = false;

    for (const param of currentUrlParams) {
      const name = param[0];
      const value = param[1];

      if (canClean) {
        valueMap = new Map();
        canClean = false;
      }

      if (name !== 'sort') {
        let filteredName = name.replace('[gte]', '').replace('[lte]', '');

        const filter = filterPrefixes.find(
          ({ field, prefix }) => `${field}[${prefix}]` === filteredName,
        );

        filteredName = filteredName.replace(`[${filter.prefix}]`, '');

        valueMap.set(filter.prefix, value);
        initMap.set(filteredName, valueMap);
      }
    }
    setFilters(initMap);
  }, []);

  return (
    <FiltersRow
      filters={filters}
      updateFilters={updateFilters}
      removeFilters={removeFilters}
      filterTypes={filterTypes}
    />
  );
};

MallReceptionUsersFilterRow.propTypes = {
  history: PropTypes.object.isRequired,
};

export default MallReceptionUsersFilterRow;
