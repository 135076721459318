import {
  getKkmLoadingError,
  isKkmLoaded,
  isKkmLoading,
} from '@esentai/core/features/kkms/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchKkm as fetchItem } from '@/features/kkmPage/actions';
import { getKkmId } from '@/features/kkmPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import Kkm from './component';

const mapStateToProps = state => {
  const itemId = getKkmId(state);

  return {
    error: getKkmLoadingError(state, itemId),
    isLoaded: isKkmLoaded(state, itemId),
    isLoading: isKkmLoading(state, itemId),
    itemId,
  };
};

const mapDispatchToProps = {
  fetchItem,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(Kkm);
