export default theme => {
  const margin = theme.spacing.unit / 2;

  return {
    filterBar: {
      marginBottom: -margin,
      marginRight: -margin,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '100%',
      fontWeight: 'normal',
    },
    input: {
      minWidth: 250,
      marginBottom: margin,
    },
  };
};
