import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';

import Filters from './Filters';
import MapWorkspace from './MapWorkspace';
import TopBar from './TopBar';

class LocationsStatistics extends Component {
  static propTypes = {
    defaultMetricPageUrl: PropTypes.string.isRequired,
    statsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { defaultMetricPageUrl, statsIds } = this.props;

    if (!statsIds.length) {
      return <Redirect to={defaultMetricPageUrl} />;
    }

    return (
      <Page>
        <PageHeader borderBottom>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Статистика</PageTitle>
          <PageSubtitle>
            Статистика ограничена только вашим магазином.
          </PageSubtitle>
        </PageHeader>
        <PageContent>
          <TopBar />
          <Filters />
          <MapWorkspace />
        </PageContent>
      </Page>
    );
  }
}

export default LocationsStatistics;
