import { cloneQuery } from '@esentai/core/queries';
import { call, select } from 'redux-saga/effects';

export default ({ selectors }) => {
  const { getQuery } = selectors;

  return handlerSaga =>
    function* querySaga() {
      const query = yield select(getQuery);

      yield call(handlerSaga, cloneQuery(query));
    };
};
