import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';
import ConfirmModal from '@/components/ConfirmModal';
import RemoveIcon from '@/components/Icon/Remove';
import CanView from '@/containers/CanView';

const DeleteButton = ({ onClick, permission, isVisible, ...props }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleModal = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <CanView permission={permission}>
        <ActionButton
          text="Удалить"
          button={AsyncButton}
          icon={RemoveIcon}
          onClick={handleModal}
          disabled={isVisible}
          {...props}
        />
      </CanView>
      {isDialogOpen && (
        <ConfirmModal
          open={isDialogOpen}
          onClose={handleModal}
          onConfirm={onClick}
          cancelLabel="Отмена"
          confirmLabel="Удалить"
          title="Вы уверены, что хотите удалить данную категорию?"
          style={{ display: 'flex' }}
        />
      )}
    </>
  );
};

DeleteButton.propTypes = {
  permission: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default DeleteButton;
