import { RESOURCE_NAME } from '@esentai/core/features/appVersion/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { APP_VERSION } from './consts/permissions';

export const canChangeAppVersion = authorizeIf(
  can(permission(RESOURCE_NAME, APP_VERSION)),
);
