import {
  getMerchantLoadingError,
  isMerchantLoaded,
  isMerchantLoading,
} from '@esentai/core/features/merchants/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchMerchant as fetchItem } from '@/features/merchantPage/actions';
import { getMerchantId } from '@/features/merchantPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import Merchant from './component';

const mapStateToProps = state => {
  const itemId = getMerchantId(state);

  return {
    error: getMerchantLoadingError(state, itemId),
    isLoaded: isMerchantLoaded(state, itemId),
    isLoading: isMerchantLoading(state, itemId),
    itemId,
  };
};

const mapDispatchToProps = {
  fetchItem,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(Merchant);
