import {
  AMOUNT_IMAGES,
  SPACE_BETWEEN_IMAGES,
} from '@/components/ArticleConstructorForm/Preview/ImagesBlock/consts';

export default {
  stackItem: {
    height: ({ height }) => height,
    width: `calc(50% - ${SPACE_BETWEEN_IMAGES / AMOUNT_IMAGES}px)`,
    objectFit: 'cover',

    '&:first-child': {
      marginRight: SPACE_BETWEEN_IMAGES,
    },
  },
};
