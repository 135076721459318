import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  EXTERNAL_PROMO_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/banner/consts/destinations';
import {
  DESTINATION_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_EXTERNAL_PROMO_ID_KEY,
  REFERRED_STORE_ID_KEY,
} from '@esentai/core/features/campaigns/banner/consts/keys';
import {
  ARTICLE_TYPE,
  CALENDAR_EVENT_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit, CanEditRadioBoxGroup } from '../../CanEdit';
import BenefitInlineSearch from '../BenefitInlineSearch';
import CampaignInlineSearch from '../CampaignInlineSearch';
import StoreInlineSearch from '../StoreInlineSearch';

class DestinationForm extends Component {
  static propTypes = FormikPropTypes;

  toggleDestination = () => {
    const { values } = this.props;
    const destination = values[DESTINATION_KEY];

    if (destination) {
      this.clearDestination();
    } else {
      this.setDestination(ARTICLE_DESTINATION);
    }
  };

  clearDestination = () => {
    this.setDestination(null);
  };

  handleDestinationChange = event => {
    const { value } = event.target;

    this.clearReferredValues();
    this.setDestination(value);
    if (value === EXTERNAL_PROMO_DESTINATION) {
      const { setFieldValue } = this.props;

      setFieldValue(REFERRED_EXTERNAL_PROMO_ID_KEY, 1);
    }
  };

  clearReferredValues = () => {
    const { setFieldValue } = this.props;

    setFieldValue(REFERRED_ARTICLE_ID_KEY, null);
    setFieldValue(REFERRED_CALENDAR_EVENT_ID_KEY, null);
    setFieldValue(REFERRED_EXTERNAL_PROMO_ID_KEY, null);
    setFieldValue(REFERRED_STORE_ID_KEY, null);
  };

  setDestination = value => {
    const { setFieldValue } = this.props;

    setFieldValue(DESTINATION_KEY, value);
  };

  setReferredCampaignId = (key, value) => {
    const { setFieldValue } = this.props;

    setFieldValue(key, value);
  };

  setBenefitId = value => {
    const { setFieldValue } = this.props;

    setFieldValue(REFERRED_BENEFIT_ID_KEY, value);
  };

  setStoreId = value => {
    const { setFieldValue } = this.props;

    setFieldValue(REFERRED_STORE_ID_KEY, value);
  };

  render() {
    const { values } = this.props;

    const destination = values[DESTINATION_KEY];
    const campaignType = this.getType();

    return (
      <FieldRow
        label="Точка назначения"
        subLabel="Является ли баннер ссылкой?"
        sudDesc="Баннер может быть просто рекламным постером, отображающим какую-то информацию самодостаточным сам по себе. Либо он может вести на статью или экран магазина."
      >
        <CanEdit field={REFERRED_STORE_ID_KEY}>
          <FormControlLabel
            label="Баннер ссылается на другую точку в приложении"
            control={
              <Checkbox
                checked={Boolean(destination)}
                onChange={this.toggleDestination}
              />
            }
          />
        </CanEdit>

        {destination ? (
          <div>
            <FieldDescription
              title="Точка назначения баннера"
              desc="Укажите, куда именно ссылается баннер."
            />
            <CanEditRadioBoxGroup field={REFERRED_STORE_ID_KEY}>
              <RadioBoxGroup
                name={REFERRED_STORE_ID_KEY}
                value={destination}
                onChange={this.handleDestinationChange}
              >
                <RadioBox
                  value={ARTICLE_DESTINATION}
                  label="Статья"
                  helperText="Баннер будет ссылаться на другое, уже существующее и опубликованное, рекламное размещение-статью."
                />
                <RadioBox
                  value={EXTERNAL_PROMO_DESTINATION}
                  label="CheckPromo"
                  helperText="Баннер будет ссылаться на другую, уже существующую и опубликованную, промо-акцию."
                />
              </RadioBoxGroup>
            </CanEditRadioBoxGroup>
            <CanEdit field={REFERRED_STORE_ID_KEY}>
              {campaignType && (
                <CampaignInlineSearch
                  values={values}
                  onSelect={this.setReferredCampaignId}
                  type={campaignType}
                />
              )}

              {destination === BENEFIT_DESTINATION && (
                <BenefitInlineSearch
                  value={values[REFERRED_BENEFIT_ID_KEY]}
                  onSelect={this.setBenefitId}
                />
              )}

              {destination === STORE_DESTINATION && (
                <StoreInlineSearch
                  value={values[REFERRED_STORE_ID_KEY]}
                  onSelect={this.setStoreId}
                />
              )}
            </CanEdit>
          </div>
        ) : null}
      </FieldRow>
    );
  }

  getType() {
    const { values } = this.props;

    const mapping = {
      [ARTICLE_DESTINATION]: ARTICLE_TYPE,
      [CALENDAR_EVENT_DESTINATION]: CALENDAR_EVENT_TYPE,
    };

    const destination = values[DESTINATION_KEY];

    return mapping[destination];
  }
}

export default DestinationForm;
