import {
  IS_ACTIVE_KEY,
  LOYALTY_REP_KEY,
  NAME_KEY,
} from '@esentai/core/features/stores/consts/keys';

import { createTextFilter } from '@/filters/SearchInput';
import { createStoreActivityStateFilter } from '@/filters/StoreActivityState';
import { createTrueFalseFilter } from '@/filters/TrueFalseFilter';

export const filterTypes = [
  createStoreActivityStateFilter({
    key: IS_ACTIVE_KEY,
    label: 'Состояние магазина',
    quicklyAccessible: true,
  }),
  createTextFilter({
    key: NAME_KEY,
    label: 'Название',
  }),
  createTrueFalseFilter({
    key: LOYALTY_REP_KEY,
    label: 'Участие в программе',
    labels: [
      {
        value: true,
        label: 'да',
      },
      {
        value: false,
        label: 'нет',
      },
    ],
  }),
];
