import { createAction } from 'redux-actions';

import query from './ducks/query';
import { CANCEL_INVITE, RELOAD, RETRY_FETCH } from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const cancelInvite = createAction(CANCEL_INVITE);

export const retryFetch = createAction(RETRY_FETCH);

export const reload = createAction(RELOAD);
