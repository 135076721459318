import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ActivateButton from '@/features/campaignsPage/Page/Row/buttons/ActivateButton';
import ArchiveButton from '@/features/campaignsPage/Page/Row/buttons/ArchiveButton';
import CloneButton from '@/features/campaignsPage/Page/Row/buttons/CloneButton';
import EditButton from '@/features/campaignsPage/Page/Row/buttons/EditButton';

const PausedStatusActions = ({ itemId }) => (
  <Fragment>
    <ActivateButton itemId={itemId} small={false} color="primary" />
    <ArchiveButton itemId={itemId} small={false} color="primary" />
    <CloneButton itemId={itemId} small={false} color="primary" />
    <EditButton
      itemId={itemId}
      small={false}
      color="primary"
      variant="contained"
    />
  </Fragment>
);

PausedStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default PausedStatusActions;
