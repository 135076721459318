import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Children } from 'react';

import pluralize from '@/utils/pluralize';

const BulkActionsRow = ({ classes, columnsCount, selectedItems, children }) => {
  const count = selectedItems.length;
  const hasActions = Boolean(Children.count(children));

  const translation = pluralize(count, {
    one: `Выбрана ${count} строка`,
    few: `Выбраны ${count} строки`,
    many: `Выбраны ${count} строк`,
  });

  return (
    <TableRow className={classes.bulkActionsRow}>
      <TableCell colSpan={columnsCount}>
        <Grid alignItems="center" spacing={16} container>
          <Grid item>
            <Typography
              color="inherit"
              variant="subtitle1"
              className={classes.title}
            >
              {translation}
            </Typography>
          </Grid>

          {hasActions && <Grid className={classes.separator} item />}

          {Children.map(children, child => (
            <Grid item>{child}</Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

BulkActionsRow.propTypes = {
  columnsCount: PropTypes.number.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkActionsRow;
