import { subHours } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import {
  formatHumanDate,
  formatShortHumanDate,
  formatTime,
} from '@/utils/format';

import FormatterLayout from '../FormatterLayout';
import { isFirstOfJanuary } from '../utils';

const HourTickFormatter = ({ date, Layout, asRange, ...rest }) => {
  const startDateText = formatTime(subHours(date, 1));
  const text = asRange
    ? `${startDateText}–${formatTime(date)}`
    : formatTime(date);
  const captionText = isFirstOfJanuary(date)
    ? formatHumanDate(date)
    : formatShortHumanDate(date);

  return <Layout text={text} captionText={captionText} {...rest} />;
};

HourTickFormatter.propTypes = {
  asRange: PropTypes.bool,
  date: PropTypes.instanceOf(Date).isRequired,
  Layout: PropTypes.func,
};

HourTickFormatter.defaultProps = {
  asRange: true,
  Layout: FormatterLayout,
};

export default HourTickFormatter;
