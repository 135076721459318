export default {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  date: {
    marginBottom: 5,
  },

  primaryFacts: {
    display: 'flex',
    flexDirection: ({ rightFirst }) =>
      rightFirst ? 'column' : 'column-reverse',
  },

  metricRoot: {
    fontWeight: 'normal',
    marginTop: 5,
    marginBottom: 5,
    '&:not(:last-child)': {
      marginRight: 5,
    },
  },

  primaryRoot: {
    fontWeight: 'bold',
    color: '#F5A623',
    '&:first-child': {
      color: '#4A90E2',
    },
  },

  secondaryDescription: {
    fontSize: 10,
  },

  label: {
    fontSize: 10,
    fontWeight: 'inherit',
  },

  value: {
    color: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 1,
  },
};
