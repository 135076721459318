import {
  ID_KEY,
  IS_MANNUALY_ADDED_KEY,
} from '@esentai/core/features/purchases/consts/keys';
import { annotations } from '@esentai/core/features/purchases/serializer';
import { ascending, equals, filter } from '@esentai/core/query-dsl';
import { bool, sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(ID_KEY),
  }),

  [IS_MANNUALY_ADDED_KEY]: bool({
    defaultValue: filter(equals(false)),
  }),
});
