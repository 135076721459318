import { connect } from 'react-redux';

import Metric from '@/components/Metric';
import { getAmountForRentPlaceMetric } from '@/features/locationsStatisticsPage/selectors';

const mapStateToProps = (state, { descriptor, rentId }) => ({
  value: getAmountForRentPlaceMetric(state, descriptor, rentId),
});

export default connect(mapStateToProps)(Metric);
