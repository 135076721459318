import serializer from '@/features/salesTurnoverDetailsPage/serializer';
import createQueryDuck from '@/redux/query';

import { FEATURE_NAME } from '../consts';
import { getQueryRoot } from '../utils';

export default createQueryDuck(FEATURE_NAME, {
  getRoot: getQueryRoot,
  serializer,
});
