import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import UserNameLazy from '@/containers/UserNameLazy';
import { dateTimeFormat, formatMoney } from '@/utils/format';

import { statusLabel } from '../../labels';

const Row = ({
  senderId,
  recipientId,
  dateCreated,
  expiredAmount,
  issuedAmount,
  recipientStatus,
}) => (
  <TableRow>
    <TableCell>
      <UserNameLazy userId={senderId} />
    </TableCell>
    <TableCell>{formatMoney(issuedAmount)}</TableCell>
    <TableCell>{dateCreated ? dateTimeFormat(dateCreated) : '-'}</TableCell>
    <TableCell>
      <UserNameLazy userId={recipientId} />
    </TableCell>
    <TableCell>{statusLabel(recipientStatus)}</TableCell>
    <TableCell>{formatMoney(expiredAmount)}</TableCell>
  </TableRow>
);

Row.propTypes = {
  senderId: PropTypes.number.isRequired,
  recipientId: PropTypes.number.isRequired,
  expiredAmount: PropTypes.number.isRequired,
  issuedAmount: PropTypes.number.isRequired,
  recipientStatus: PropTypes.string.isRequired,
  dateCreated: PropTypes.instanceOf(Date),
};

Row.defaultProps = {
  dateCreated: '',
};

export default Row;
