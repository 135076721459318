import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getRentPlaceName } from '@/features/locationsStatisticsPage/selectors';

import TooltipContent from './component';
import styles from './styles';

const mapStateToProps = (state, { rentId }) => ({
  rentPlaceName: getRentPlaceName(state, rentId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(TooltipContent);
