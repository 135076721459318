import {
  getEnglishAnswer,
  getEnglishQuestion,
  getKazakhAnswer,
  getKazakhQuestion,
  getRussianAnswer,
  getRussianQuestion,
} from '@esentai/core/features/questions/selectors';
import { connect } from 'react-redux';

import Form from '@/features/questionCreatePage/Page/Form';

const mapStateToProps = (state, { questionId }) => ({
  englishAnswer: getEnglishAnswer(state, questionId),
  englishQuestion: getEnglishQuestion(state, questionId),

  russianAnswer: getRussianAnswer(state, questionId),
  russianQuestion: getRussianQuestion(state, questionId),

  kazakhAnswer: getKazakhAnswer(state, questionId),
  kazakhQuestion: getKazakhQuestion(state, questionId),
});

export default connect(mapStateToProps)(Form);
