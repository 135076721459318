import { connect } from 'react-redux';

import { getEntityId } from '@/features/statisticsPage/selectors';

import Statistics from './component';

const mapStateToProps = state => ({
  entityId: getEntityId(state),
});

export default connect(mapStateToProps)(Statistics);
