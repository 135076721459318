import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getCampaignId,
  getCampaignType,
} from '@/features/campaignCreatePage/selectors';

import SecondStepForm from './component';
import styles from './styles';

const mapStateToProps = state => ({
  id: getCampaignId(state),
  campaignType: getCampaignType(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(SecondStepForm);
