import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import { EMAIL_KEY } from '@/features/invites/consts/keys';

const EmailField = ({ Field, classes }) => (
  <FieldRow
    label="Email"
    subLabel="Электронный адрес для инвайта"
    sudDesc="Адрес, на который пользователю будет отправлено приглашение для регистрации в системе."
    className={classes.row}
  >
    <Field
      Target={TextField}
      id={EMAIL_KEY}
      name={EMAIL_KEY}
      label="Введите e-mail адрес"
      type="emai"
      fullWidth
    />
  </FieldRow>
);

EmailField.propTypes = {
  Field: PropTypes.func.isRequired,
};

export default EmailField;
