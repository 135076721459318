import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// eslint-disable-next-line import/no-unresolved
import ReceiptModal from '@/features/purchaseHistoryPage/Page/ReceiptModal';
import { dateTimeFormat, formatMoney, formatNumber } from '@/utils/format';
import { purchaseStatus } from '@/utils/labels';

const Row = ({
  classes,
  customer,
  date,
  purchaseId,
  receipt,
  storeName,
  totalCost,
  activeBonuses,
  pendingBonuses,
  status,
  uuid,
  cashMachine,
  bonusPercent,
  refundMoneyCost,
  refundBonusCost,
  bonusCost,
  moneyCost,
}) => {
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);

  const handleReceiptModal = () => {
    setIsReceiptDialogOpen(!isReceiptDialogOpen);
  };

  return (
    <>
      <TableRow>
        <TableCell>{formatNumber(purchaseId)}</TableCell>
        <TableCell>{cashMachine}</TableCell>
        <TableCell>
          <div className={classes.link} onClick={handleReceiptModal}>
            {uuid}
          </div>
        </TableCell>
        <TableCell>{storeName}</TableCell>
        <TableCell>{dateTimeFormat(date)}</TableCell>
        <TableCell align="right">{formatMoney(totalCost)}</TableCell>
        <TableCell align="right">{bonusPercent}%</TableCell>
        <TableCell align="right">{formatMoney(pendingBonuses)}</TableCell>
        <TableCell align="right">{formatMoney(activeBonuses)}</TableCell>
        <TableCell>{purchaseStatus(status)}</TableCell>
        <TableCell align="right">{formatMoney(bonusCost)}</TableCell>
        <TableCell align="right">{formatMoney(moneyCost)}</TableCell>
        <TableCell align="right">{formatMoney(refundBonusCost)}</TableCell>
        <TableCell align="right">{formatMoney(refundMoneyCost)}</TableCell>
      </TableRow>
      {isReceiptDialogOpen && (
        <ReceiptModal
          uuid={uuid}
          receipt={receipt}
          userId={customer}
          isOpen={isReceiptDialogOpen}
          onClose={handleReceiptModal}
        />
      )}
    </>
  );
};

Row.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  purchaseId: PropTypes.number.isRequired,
  receipt: PropTypes.number.isRequired,
  storeName: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  totalCost: PropTypes.number.isRequired,
  activeBonuses: PropTypes.number.isRequired,
  pendingBonuses: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  cashMachine: PropTypes.string.isRequired,
  bonusPercent: PropTypes.number.isRequired,
  refundMoneyCost: PropTypes.number.isRequired,
  refundBonusCost: PropTypes.number.isRequired,
  moneyCost: PropTypes.number.isRequired,
  bonusCost: PropTypes.number.isRequired,
};

export default Row;
