import {
  AVG_NR_PURCHASES_KEY,
  AVG_NR_RECEIPTS_KEY,
  AVG_TICKET_KEY,
  BY_CAMPAIGN_KEY,
  NR_RECEIPTS_KEY,
} from '@/features/purchasesStatistics/consts/keys';
import { formatMoney, formatNumber } from '@/utils/format';

export default [
  {
    key: NR_RECEIPTS_KEY,
    url_key: 'all',
    label: 'Количество покупок',
    formatter: formatNumber,
    labelFormatter: () => 'количество покупок',
  },
  {
    key: AVG_NR_RECEIPTS_KEY,
    url_key: 'count',
    label: 'Количество покупок на покупателя',
    formatter: formatNumber,
    labelFormatter: () => 'количество покупок на покупателя',
  },
  {
    key: AVG_NR_PURCHASES_KEY,
    url_key: 'average_purchases',
    label: 'Среднее количество товаров',
    formatter: formatNumber,
    labelFormatter: () => 'среднее количество товаров',
  },
  {
    key: AVG_TICKET_KEY,
    url_key: 'average_sum',
    label: 'Средний чек',
    formatter: formatMoney,
    labelFormatter: () => 'средний чек',
  },
  {
    key: BY_CAMPAIGN_KEY,
    url_key: 'count_by_campaign',
    label: 'Количество покупок по кампаниям',
    formatter: formatNumber,
    labelFormatter: () => 'Количество покупок по кампаниям',
  },
];
