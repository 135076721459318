import { FEATURE_NAME } from './consts';

export const DELETE_VIEW = `${FEATURE_NAME}/DELETE_VIEW`;

export const MOVE_VIEW_DOWN = `${FEATURE_NAME}/MOVE_VIEW_DOWN`;

export const MOVE_VIEW_UP = `${FEATURE_NAME}/MOVE_VIEW_UP`;

export const CREATE_VIEW = `${FEATURE_NAME}/CREATE_VIEW`;

export const UPDATE_VIEW = `${FEATURE_NAME}/UPDATE_VIEW`;

export const RETRY_FETCH = `${FEATURE_NAME}/RETRY_FETCH`;
