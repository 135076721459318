export default () => ({
  text: {
    color: '#fff',
  },
  icon: {
    color: '#fff',
  },
  submenu: {
    background: '#717171',
  },
});
