import {
  getEventAdditionalEnglishText,
  getEventAdditionalRussianText,
  getEventEnglishTitle,
  getEventImageURL,
  getEventRussianTitle,
} from '@esentai/core/features/campaigns/calendarEvent/selectors';
import { connect } from 'react-redux';

import Banner from '@/features/campaignPage/Page/Content/ArticleContent/Banner/component';

const mapStateToProps = (state, { itemId }) => ({
  imageUrl: getEventImageURL(state, itemId),
  russianTitle: getEventRussianTitle(state, itemId),
  englishTitle: getEventEnglishTitle(state, itemId),
  additionalRussianText: getEventAdditionalRussianText(state, itemId),
  additionalEnglishText: getEventAdditionalEnglishText(state, itemId),
});

export default connect(mapStateToProps)(Banner);
