import {
  ID_KEY,
  TYPE_KEY,
} from '@esentai/core/features/bonus_transactions/consts/keys';
import { annotations } from '@esentai/core/features/bonus_transactions/serializer';
import { descending, equals, filter } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(ID_KEY),
  }),
  [TYPE_KEY]: string({
    defaultValue: filter(equals('level_change')),
  }),

  // customer_id: number({
  //   defaultValue: 123,
  // }),
});
