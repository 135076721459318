import { getError, isRunning } from '@esentai/core/ducks/tasks/selectors';
import { createId } from '@esentai/core/ducks/tasks/utils';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { giveOut } from '@/features/benefitGiveOutPage/actions';
import { getGrantedStatus } from '@/features/benefitGiveOutPage/selectors';

import Button from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => {
  const taskId = createId(giveOut(itemId));

  return {
    granted: getGrantedStatus(state, itemId),
    isLoading: isRunning(state, taskId),
    error: getError(state, taskId),
  };
};

const mapDispatchToProps = (dispatch, { itemId }) => ({
  giveOut: () => dispatch(giveOut(itemId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Button);
