import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import {
  SUBTITLE_ENGLISH_KEY,
  SUBTITLE_RUSSIAN_KEY,
} from '@/components/ArticleConstructorForm/consts';
import FieldDescription from '@/components/FieldDescription';
import TextField from '@/components/TextField';
import limits from '@/features/campaignCreatePage/EditPage/limits';

import { getName } from '../utils';

class SubtitleBlock extends Component {
  static propTypes = {
    Field: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  render() {
    const { Field, index, disabled } = this.props;

    return (
      <Fragment>
        <FieldDescription desc="???" />

        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Field
              disabled={disabled}
              Target={TextField}
              name={getName(index, SUBTITLE_RUSSIAN_KEY)}
              label="Текст подзаголовка"
              helperText="На русском"
              symbolsMax={limits.subtitle}
              fullWidth
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              disabled={disabled}
              Target={TextField}
              name={getName(index, SUBTITLE_ENGLISH_KEY)}
              label="Текст подзаголовка"
              helperText="На английском"
              symbolsMax={limits.subtitle}
              fullWidth
              multiline
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default SubtitleBlock;
