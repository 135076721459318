import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Tooltip extends Component {
  static propTypes = {
    selectedRentId: PropTypes.string.isRequired,
    TooltipContent: PropTypes.func.isRequired,
  };

  render() {
    const { classes, selectedRentId, TooltipContent } = this.props;

    return (
      <div className={classes.root}>
        <TooltipContent rentId={selectedRentId} />
      </div>
    );
  }
}

export default Tooltip;
