import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';

import query from './ducks/query';
import { CLONE_AND_EDIT, REMOVE } from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const cloneAndEdit = createTaskAction(CLONE_AND_EDIT);

export const remove = createTaskAction(REMOVE);
