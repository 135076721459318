import {
  AMOUNT_KEY,
  CUSTOMER_ID_KEY,
  ID_KEY,
  RECORD_CREATED_KEY,
} from '@esentai/core/features/bonus_transactions/consts/keys';

import { createDateTimeRangeFilter } from '@/filters/DateTimeRange';
import { createRangeFilter } from '@/filters/Range';
import { createIncludeFilter } from '@/filters/SearchInputs';

export const filterTypes = [
  createIncludeFilter({
    key: ID_KEY,
    label: 'ID сгоревших бонусов',
  }),
  createIncludeFilter({
    key: CUSTOMER_ID_KEY,
    label: 'ID пользователя',
  }),
  // createIncludeFilter({
  //   key: USER_ID_KEY,
  //   label: 'ID менеджера молла',
  // }),
  // createIncludeFilter({
  //   key: COMMISSAR_ID_KEY,
  //   label: 'ID комплаенс менеджера',
  // }),
  createRangeFilter({
    key: AMOUNT_KEY,
    label: 'Кол-во сгоревших бонусов',
  }),
  createDateTimeRangeFilter({
    key: RECORD_CREATED_KEY,
    label: 'Дата и время ответа изменения уровня',
  }),
];
