import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import { formatMoney } from '@/utils/format';

import { createStoreBonusDetailsPageUrl } from '../../../reconciliationStoreBonusDetailsPage/utils';

const Row = ({
  storeName,
  totalToPayAmount,
  totalPaidAmount,
  sumInProcessing,
  invoicedAmount,
  reconciliationId,
}) => (
  <TableRow>
    <TableCell>
      <Link to={createStoreBonusDetailsPageUrl(reconciliationId)}>
        {storeName}
      </Link>
    </TableCell>
    <TableCell>{formatMoney(totalPaidAmount)}</TableCell>
    <TableCell>{formatMoney(totalToPayAmount)}</TableCell>
    <TableCell>{formatMoney(sumInProcessing)}</TableCell>
    <TableCell>{formatMoney(invoicedAmount)}</TableCell>
  </TableRow>
);

Row.propTypes = {
  storeName: PropTypes.string.isRequired,
  totalToPayAmount: PropTypes.number.isRequired,
  reconciliationId: PropTypes.number.isRequired,
  totalPaidAmount: PropTypes.number.isRequired,
  sumInProcessing: PropTypes.number.isRequired,
  invoicedAmount: PropTypes.number.isRequired,
};

export default Row;
