import { findCampaignById } from '@esentai/core/features/campaigns/actions';
import { getReferredShowId } from '@esentai/core/features/campaigns/notification/selectors';
import { isLoadingCampaign } from '@esentai/core/features/campaigns/show/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import CampaignReference from '@/features/campaignCreatePage/EditPage/SecondStepBanner/CampaignInlineSearch/Suggestion';
import withItemApiRunner from '@/hocs/withItemApiRunner';

const mapStateToProps = (state, { itemId }) => {
  const showId = getReferredShowId(state, itemId);

  return {
    isLoading: isLoadingCampaign(state, showId),
    itemId: showId,
  };
};

const mapDispatchToProps = { fetchItem: findCampaignById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(CampaignReference);
