import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import {
  cloneBenefit,
  removeBenefit,
} from '@esentai/core/features/benefits/sagas';
import { clone } from '@esentai/core/features/campaigns/actions';
import { push } from 'connected-react-router';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';

import { createFormStepURL } from '@/features/benefitCreatePage/utils';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { CLONE_AND_EDIT, REMOVE } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* cloneCampaignAndEdit({ payload: id }) {
  const cloneAction = clone(id);
  const { id: newId } = yield call(cloneBenefit, cloneAction);
  const url = yield call(createFormStepURL, newId, 1);

  yield put(push(url));
}

export function* removeCampaignAndRefresh({ payload: id }) {
  yield call(removeBenefit, id);
  yield call(fetchItems);
}

export default function*() {
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield takeEvery(CLONE_AND_EDIT, createTaskSaga(cloneCampaignAndEdit));
  yield takeEvery(REMOVE, createTaskSaga(removeCampaignAndRefresh));
}
