import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/mallBonusesPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getMallBonusesError,
  getMallBonusesTotalCount,
  getMallBonusIds,
  isLoadingMallBonuses,
} from '../selectors';
import MallBonuses from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getMallBonusesError(state),
  mallBonusIds: getMallBonusIds(state),
  isLoading: isLoadingMallBonuses(state),
  totalCount: getMallBonusesTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(MallBonuses);
