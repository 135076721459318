import PropTypes from 'prop-types';
import { compose, indexBy, map, prop, replace, test } from 'ramda';
import React, { Component } from 'react';

import { stateChangeTypes } from '@/components/AutocompleteBase';
import Chip from '@/components/AutocompleteBase/Chip';
import Option from '@/components/AutocompleteBase/Option';

const omitWhitespaces = replace(/\s/g, '');

class Autocomplete extends Component {
  static propTypes = {
    Component: PropTypes.func.isRequired,
    keyProp: PropTypes.func,
    labelProp: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    keyProp: prop('key'),
    labelProp: prop('label'),
  };

  static getDerivedStateFromProps(props, state) {
    if (props.options !== state.options) {
      const { keyProp, labelProp, options } = props;
      const labelsIndex = compose(map(labelProp), indexBy(keyProp));

      return { labels: labelsIndex(options), options: state.options };
    }

    return null;
  }

  state = {
    queryString: '',
    options: null,
    labels: {},
  };

  getLabel = key => {
    const { labels } = this.state;

    return labels[key];
  };

  isMatching = option => {
    const { labelProp } = this.props;
    const { queryString } = this.state;

    const label = labelProp(option);

    return test(
      new RegExp(omitWhitespaces(queryString), 'i'),
      omitWhitespaces(label),
    );
  };

  setQueryString = queryString => this.setState({ queryString });

  stateReducer = (state, changes) => {
    const { type } = changes;

    // this prevents the menu from being closed when the user
    // selects an item with a keyboard or mouse
    if (
      type === stateChangeTypes.keyDownEnter ||
      type === stateChangeTypes.clickItem
    ) {
      return {
        ...changes,
        isOpen: state.isOpen,
        highlightedIndex: state.highlightedIndex,
      };
    }

    return changes;
  };

  render() {
    const {
      Component: WrappedComponent,
      options,
      keyProp,
      ...rest
    } = this.props;
    const matchingOptions = options.filter(this.isMatching).map(keyProp);

    return (
      <WrappedComponent
        isOpenOnFocus
        stateReducer={this.stateReducer}
        Chip={this.renderChip}
        Option={this.renderOption}
        {...rest}
        options={matchingOptions}
        onInputValueChange={this.setQueryString}
      />
    );
  }

  renderChip = ({ value, ...rest }) => {
    const label = this.getLabel(value);

    if (!label) {
      return null;
    }

    return <Chip {...rest} value={value} label={label} />;
  };

  renderOption = ({ value, ...rest }) => {
    const label = this.getLabel(value);

    if (!label) {
      return null;
    }

    return <Option {...rest} value={value} label={label} />;
  };
}

export default Autocomplete;
