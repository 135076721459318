import createAPISerializer from '@esentai/core/serializers/api';
import {
  array,
  datetime,
  maybe,
  number,
  string,
} from '@esentai/core/serializers/params';

import {
  EMAIL_KEY,
  LAST_ACTIVITY_DATE_KEY,
  NAME_KEY,
  ROLE_KEY,
  STATE_KEY,
  STORE_IDS_KEY,
} from './consts/keys';

export const annotations = {
  [NAME_KEY]: maybe(string()),
  [EMAIL_KEY]: string(),
  [LAST_ACTIVITY_DATE_KEY]: maybe(datetime()),
  [ROLE_KEY]: string(),
  [STATE_KEY]: string(),
  [STORE_IDS_KEY]: array(number()),
};

export default createAPISerializer(annotations);
