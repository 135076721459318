import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DayPickerRangeController } from 'react-dates';

class CalendarSelect extends Component {
  static propTypes = {
    minDate: PropTypes.instanceOf(Date).isRequired,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
  };

  state = {
    focusedInput: 'startDate',
  };

  render() {
    const { focusedInput } = this.state;
    const { value } = this.props;

    return (
      <DayPickerRangeController
        noBorder
        startDate={moment(value.from)}
        endDate={moment(value.to)}
        hideKeyboardShortcutsPanel
        numberOfMonths={2}
        onDatesChange={this.setDateRange}
        focusedInput={focusedInput}
        onFocusChange={this.setFocusInput}
        isOutsideRange={this.isOutsideRange}
      />
    );
  }

  isOutsideRange = date => {
    const { minDate, maxDate } = this.props;

    return (
      moment(minDate).isAfter(date, 'date') ||
      moment(maxDate).isBefore(date, 'date')
    );
  };

  setFocusInput = newFocusedInput => {
    this.setState({ focusedInput: newFocusedInput || 'startDate' });
  };

  setDateRange = ({ startDate, endDate }) => {
    const { onChange } = this.props;

    onChange({
      from: startDate.toDate(),
      to: endDate ? endDate.toDate() : startDate.toDate(),
    });
  };
}

export default CalendarSelect;
