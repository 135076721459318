import { createAction } from 'redux-actions';

import {
  CANCEL_DEACTIVATE,
  DEACTIVATE_FAILUTE,
  DEACTIVATE_START,
  DEACTIVATE_SUCCESS,
  SCHEDULE_DEACTIVATE,
} from './types';

export const scheduleDeactivate = createAction(SCHEDULE_DEACTIVATE);

export const cancelDeactivate = createAction(CANCEL_DEACTIVATE);

export const deactivateStart = createAction(DEACTIVATE_START);

export const deactivateFailure = createAction(DEACTIVATE_FAILUTE);

export const deactivateSuccess = createAction(DEACTIVATE_SUCCESS);
