import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { formatDescriptorValue } from '@/features/statisticsPage/utils';

import ColorScale from './ColorScale';
import { generateGradientTicks, generateTicks } from './utils';

class Legend extends Component {
  static propTypes = {
    descriptor: PropTypes.object.isRequired,
    maximumAmount: PropTypes.number.isRequired,
    minimumAmount: PropTypes.number.isRequired,
  };

  render() {
    return (
      <div>
        {this.renderTicks()}
        {this.renderGradient()}
      </div>
    );
  }

  renderGradient() {
    const { maximumAmount, minimumAmount } = this.props;
    const numberOfSections = 9;
    const gradientTicks = generateGradientTicks(
      numberOfSections,
      minimumAmount,
      maximumAmount,
    );

    return <ColorScale gradientTicks={gradientTicks} />;
  }

  renderTicks() {
    const { classes, maximumAmount, minimumAmount } = this.props;
    const numberOfTicks = 5;
    const ticks = generateTicks(numberOfTicks, minimumAmount, maximumAmount);

    return (
      <div className={classes.ticks}>
        {ticks.map(tick => (
          <Typography key={tick} variant="caption">
            {this.formatTick(tick)}
          </Typography>
        ))}
      </div>
    );
  }

  formatTick = tick => {
    const { descriptor } = this.props;

    return formatDescriptorValue(descriptor, tick);
  };
}

export default Legend;
