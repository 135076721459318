import { deleteParkingWhiteListGroup } from '@esentai/core/features/parkingWhitelistGroups/actions';
import { T } from 'ramda';
import { connect } from 'react-redux';

import RemoveButton from '@/components/ActionButtons/Remove';
import { retryFetch } from '@/features/parkingWhitelistGroupsPage/actions';

const mapDispatchToProps = (dispatch, { parkingWhitelistGroupId }) => ({
  onClick: () => {
    dispatch(deleteParkingWhiteListGroup(parkingWhitelistGroupId));
    setTimeout(() => dispatch(retryFetch()), 400);
  },
  title: 'Вы уверены, что удалить выбранную группу из списка?',
  permission: T,
});

export default connect(null, mapDispatchToProps)(RemoveButton);
