import {
  ALL_USERS_SEGMENT,
  EXISTING_USERS_SEGMENT,
  NEW_USERS_SEGMENT,
} from '@esentai/core/features/campaigns/consts/segments';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { __, isNil, prop } from 'ramda';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import FiltersList from '@/components/FilterBar/FiltersList';
import { filterTypesOld } from '@/features/campaignCreatePage/EditPage/ThirdStep/filters';
import { users } from '@/utils/labels';

import Label from '../Label';

const segmentTypeLabel = prop(__, {
  [ALL_USERS_SEGMENT]: 'Вся аудитория',
  [EXISTING_USERS_SEGMENT]: 'Только существующая аудитория',
  [NEW_USERS_SEGMENT]: 'Только новая аудитория',
});

const readFilters = query =>
  [...query.get('filters').entries()].map(([key, value]) => ({ key, value }));

const Segmentation = ({ classes, filters, segmentSize, segmentType }) => (
  <FieldRow name="Сегментация">
    {segmentType && (
      <Typography variant="h4" className={classes.typeLabel}>
        {segmentTypeLabel(segmentType)}
      </Typography>
    )}

    {!isNil(filters) && filters.get('filters').size > 0 && (
      <Label title="Количество пользователей, подпадающих под фильтр сегмента">
        <FiltersList
          filters={readFilters(filters)}
          filterTypes={filterTypesOld}
        />
      </Label>
    )}

    {!isNil(segmentSize) && (
      <Label title="Количество пользователей, подпадающих под фильтр сегмента">
        {users(segmentSize)}
      </Label>
    )}
  </FieldRow>
);

Segmentation.propTypes = {
  filters: PropTypes.object,
  segmentSize: PropTypes.number,
  segmentType: PropTypes.string,
};

Segmentation.defaultProps = {
  filters: null,
  segmentSize: null,
  segmentType: null,
};

export default Segmentation;
