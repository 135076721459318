import {
  getAmountKztForSalesTurnover,
  getAmountUsdForSalesTurnover,
  getMerchantNameForSalesTurnover,
  getStoreIsActiveForSalesTurnover,
  getStoreNameForSalesTurnover,
} from '@esentai/core/features/salesTurnovers/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: salesTurnoverId }) => ({
  storeName: getStoreNameForSalesTurnover(state, salesTurnoverId),
  isActive: getStoreIsActiveForSalesTurnover(state, salesTurnoverId),
  amountKzt: getAmountKztForSalesTurnover(state, salesTurnoverId),
  amountUsd: getAmountUsdForSalesTurnover(state, salesTurnoverId),
  merchantName: getMerchantNameForSalesTurnover(state, salesTurnoverId),
  salesTurnoverId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
