import React from 'react';

const ColGroup = () => (
  <colgroup>
    <col style={{ width: '200px' }} />
    <col style={{ width: '250px' }} />
    <col style={{ width: '200px' }} />
    <col style={{ width: '200px' }} />
  </colgroup>
);

export default ColGroup;
