import {
  AMOUNT_KZT_KEY,
  AMOUNT_USD_KEY,
  MERCHANT_NAME_KEY,
  STORE_NAME_KEY,
} from '@esentai/core/features/salesTurnovers/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell style={{ width: '200px' }}>
      <SortControl field={STORE_NAME_KEY}>Магазин</SortControl>
    </CondenseTableCell>
    <CondenseTableCell style={{ width: '250px' }} align="center">
      <SortControl field={MERCHANT_NAME_KEY}>Арендатор</SortControl>
    </CondenseTableCell>
    <CondenseTableCell style={{ width: '200px' }} align="center">
      <SortControl field={AMOUNT_KZT_KEY}>Оборот KZT</SortControl>
    </CondenseTableCell>

    <CondenseTableCell style={{ width: '200px' }} align="right">
      <SortControl field={AMOUNT_USD_KEY}>Оборот USD</SortControl>
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
