import { getBenefitStatus } from '@esentai/core/features/benefits/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Actions from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  itemId,
  status: getBenefitStatus(state, itemId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Actions);
