import { descending } from '@esentai/core/query-dsl';
import { date, sort, string } from '@esentai/core/serializers/params';

import {
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
} from '@/features/campaignsStatistics/consts/dimensions';
import { TIMEFRAME_KEY } from '@/features/campaignsStatistics/consts/keys';
import { annotations } from '@/features/campaignsStatistics/serializer';
import { getStatPageUrl } from '@/features/statisticsPage/utils';
import { createPageSerializer, getLinkCreator } from '@/serializers';

import { DETAILS_ROUTE_PATH, ROUTE_PATH } from './consts';
import { defaultCampaignType, defaultDateRange } from './consts/filters';

const serializer = createPageSerializer({
  ...annotations,

  [CAMPAIGN_TYPE_KEY]: string({
    defaultValue: defaultCampaignType,
  }),
  [TIME_SERIES_KEY]: date({
    defaultValue: defaultDateRange,
  }),

  sort: sort({
    defaultValue: descending(TIMEFRAME_KEY),
  }),
});

export default serializer;

export const createLink = getLinkCreator(ROUTE_PATH, serializer);

export const createStatPageUrl = (stats, query) => {
  const path = getStatPageUrl('campaigns', stats);
  const linkCreator = getLinkCreator(path, serializer);

  return linkCreator(query);
};

export const createDetailsLink = getLinkCreator(DETAILS_ROUTE_PATH, serializer);
