import {
  FIFTH_FLOOR_ID,
  FIRST_FLOOR_ID,
  FOURTH_FLOOR_ID,
  MINUS_FIRST_FLOOR_ID,
  SECOND_FLOOR_ID,
  THIRD_FLOOR_ID,
} from '@esentai/core/features/beacons/consts/floors';

import { floorLabel } from '@/features/locationsStatisticsPage/labels';
import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';

export const options = [
  MINUS_FIRST_FLOOR_ID,
  FIRST_FLOOR_ID,
  SECOND_FLOOR_ID,
  THIRD_FLOOR_ID,
  FOURTH_FLOOR_ID,
  FIFTH_FLOOR_ID,
].map(key => ({ key, label: floorLabel(key) }));

export const createFloorCheckboxFilter = createCheckboxFilterTemplate({
  initialValue: MINUS_FIRST_FLOOR_ID,
  options,
});

export default CheckboxGroupFilter;
