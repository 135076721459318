import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import CommonTable from '@/components/CommonTable';
import {
  updateLimit,
  updatePage,
  updateSort,
} from '@/features/productsViewPage/actions';
import {
  getLimit,
  getPage,
  getSort,
} from '@/features/productsViewPage/selectors';

import ErrorMessage from '../ErrorMessage';
import HeadRow from '../HeadRow';
import Row from '../Row';

const mapStateToProps = state => ({
  limit: getLimit(state),
  page: getPage(state),
  sort: getSort(state),
});

const mapDispatchToProps = { updateLimit, updatePage, updateSort };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({ ErrorMessage, HeadRow, Row }),
)(CommonTable);
