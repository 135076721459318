export default {
  titleBar: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    marginBottom: 20,
  },

  pageTitle: {
    color: 'inherit',
    marginRight: 30,
    fontSize: 33,
  },

  pageTitleTwo: {
    color: 'inherit',
    marginRight: 30,
    fontSize: 33,
  },

  sendPush: {
    marginRight: 30,
  },

  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    opacity: 0.5,
  },

  rightCornerActions: {
    marginLeft: 'auto',
  },

  personalInfo: {
    flexShrink: 1,
    marginRight: 80,
  },

  details: {
    display: 'flex',
  },

  facts: {
    flexGrow: 1,
    maxWidth: 1200,
    marginLeft: 'auto',
  },

  purchasesTitle: {
    marginTop: '3rem',
  },

  delimiter: {
    marginTop: 35,
    marginBottom: 32,
    borderBottom: '1px solid #f5f5f5',
  },
  bonusesButton: {
    marginRight: 10,
  },
};
