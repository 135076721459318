import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';

const options = [
  { label: 'Мужское', key: 1 },
  { label: 'Женское', key: 2 },
  { label: 'Детское', key: 3 },
  { label: 'Другое', key: 4 },
];

export const createProductCategoryCheckboxFilter = createCheckboxFilterTemplate(
  {
    options,
  },
);

export default CheckboxGroupFilter;
