import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const CheckboxGroupFilter = ({ value, options, onChange }) => {
  const inChecked = option => value.includes(option.key);
  const createChangeHandler = option => () => {
    const { key } = option;

    if (inChecked(option)) {
      onChange(value.filter(x => x !== key));
    } else {
      onChange([...value, key]);
    }
  };

  return (
    <FormGroup>
      {options.map(option => (
        <FormControlLabel
          key={option.key}
          control={
            <Checkbox
              checked={inChecked(option)}
              onChange={createChangeHandler(option)}
              value={option.key}
            />
          }
          label={option.label}
        />
      ))}
    </FormGroup>
  );
};

CheckboxGroupFilter.propTypes = {
  value: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckboxGroupFilter;
