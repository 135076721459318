import React from 'react';

const ColGroup = () => (
  <colgroup>
    <col style={{ width: '250px' }} />
    <col style={{ width: '250px' }} />
    <col style={{ width: '1px' }} />
  </colgroup>
);

export default ColGroup;
