import { connect } from 'react-redux';

import { isPersonnelInvited } from '@/features/personnel/selectors';

import Row from './component';

const mapStateToProps = (state, { personnelId }) => ({
  invited: isPersonnelInvited(state, personnelId),
});

export default connect(mapStateToProps)(Row);
