import PropTypes from 'prop-types';
import React from 'react';

const FifthFloor = ({ mapHeight, mapWidth, MapTransformer, ...props }) => (
  <MapTransformer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${mapWidth} ${mapHeight}`}
      {...props}
    >
      <g id="f384c68c-cdff-40ac-b27f-16bfa016cdd5" data-name="Background">
        <g id="e345da2e-dd56-48ae-ad59-a5069794fbbb" data-name="Background">
          <rect y="2" width="1420.16" height="1001" fill="#ededed" />
          <rect x="880" y="188" width="78" height="432" fill="#f7f7f7" />
          <rect x="1" y="661" width="1415" height="24" fill="#f7f7f7" />
          <rect x="1" y="698" width="1415" height="24" fill="#f7f7f7" />
          <path
            d="M915.34,337.85v-3.74H916V337h4v-2.08h.69V337h4.14v-2.88h.69v3.74Z"
            fill="#888685"
          />
          <path
            d="M923.59,328.82a2.15,2.15,0,0,1,1.48.48,2,2,0,0,1,.53,1.54,1.88,1.88,0,0,1-.56,1.49,2.23,2.23,0,0,1-1.51.49h-.84V332h.87a1.54,1.54,0,0,0,1-.28,1.1,1.1,0,0,0,.36-.92,1.21,1.21,0,0,0-.32-.94,1.44,1.44,0,0,0-1-.29h-.27a1.5,1.5,0,0,0-.8.21,3.94,3.94,0,0,0-.82.81l-.69.87a4.29,4.29,0,0,1-1,1,1.91,1.91,0,0,1-1.11.31h-.24a1.87,1.87,0,0,1-1.32-.46,1.83,1.83,0,0,1-.51-1.44,1.92,1.92,0,0,1,.51-1.5,2.15,2.15,0,0,1,1.49-.45h.61v.78h-.64a1.43,1.43,0,0,0-1,.27,1.16,1.16,0,0,0-.32.9,1.08,1.08,0,0,0,.3.85,1.15,1.15,0,0,0,.81.27h.4a1.27,1.27,0,0,0,.39,0,1.26,1.26,0,0,0,.33-.16,3.11,3.11,0,0,0,.33-.29c.11-.12.24-.28.39-.47l.69-.87c.2-.25.39-.46.56-.64a3.31,3.31,0,0,1,.5-.41,2,2,0,0,1,.53-.22,3.36,3.36,0,0,1,.63-.06Z"
            fill="#888685"
          />
          <path
            d="M922.57,323.25h.84a2.34,2.34,0,0,1,1.63.51,2,2,0,0,1,.56,1.55,2,2,0,0,1-.62,1.6,2.44,2.44,0,0,1-1.66.54h-4.14a2.42,2.42,0,0,1-1.67-.54,2.37,2.37,0,0,1,0-3.13,2.33,2.33,0,0,1,1.64-.53h2.12v3.39h2.17a1.56,1.56,0,0,0,1.1-.34,1.3,1.3,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58H919a1.66,1.66,0,0,0-1.08.32,1.15,1.15,0,0,0-.39,1,1.18,1.18,0,0,0,.39,1,1.51,1.51,0,0,0,1.08.34Z"
            fill="#888685"
          />
          <path
            d="M919,318.54a1.48,1.48,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.28,1.28,0,0,0,.35.91,1.33,1.33,0,0,0,1,.37h6.54v.82H917V321h.84a1.86,1.86,0,0,1-.66-.55,1.7,1.7,0,0,1-.27-1,1.61,1.61,0,0,1,.48-1.26,2.2,2.2,0,0,1,1.53-.43h6.59v.82Z"
            fill="#888685"
          />
          <path
            d="M917,316.53v-.66h-1.66v-.82H917v-1.19h.63v1.19h6.49a.71.71,0,0,0,.54-.16.76.76,0,0,0,.16-.56v-.47h.69v.56a1.58,1.58,0,0,1-.32,1.13,1.49,1.49,0,0,1-1.09.32h-6.47v.66Z"
            fill="#888685"
          />
          <path
            d="M925.51,309.36h-.77a1.8,1.8,0,0,1,.6.53,1.65,1.65,0,0,1,.26.94,1.7,1.7,0,0,1-.48,1.32,2.17,2.17,0,0,1-1.53.45h-.69a2.35,2.35,0,0,1-.83-.12,1.69,1.69,0,0,1-.58-.37,2.29,2.29,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.07,5.07,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H919a1.82,1.82,0,0,0-1,.23.93.93,0,0,0-.35.85,1.1,1.1,0,0,0,.33.89,1.3,1.3,0,0,0,.93.28h.83v.8H919a2.12,2.12,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.72,1.72,0,0,1,.54-1.45,2.48,2.48,0,0,1,1.56-.42h6.5Zm-.6,1.31a1.22,1.22,0,0,0-1.14-1.31h-3.21a.86.86,0,0,1,.14.13l.17.23q.09.13.21.36c.08.15.18.34.29.57a5.21,5.21,0,0,0,.28.52,1.31,1.31,0,0,0,.3.36,1,1,0,0,0,.4.22,1.76,1.76,0,0,0,.56.07h.65a1.61,1.61,0,0,0,1-.27,1,1,0,0,0,.35-.88Z"
            fill="#888685"
          />
          <path
            d="M915.34,307.11v-.82h.75v.82Zm1.66,0v-.82h8.51v.82Z"
            fill="#888685"
          />
          <path
            d="M925.6,300.38a1.92,1.92,0,0,1-1.65,2.16,2,2,0,0,1-.42,0H922v-.82h1.6a1.46,1.46,0,0,0,1-.33,1.36,1.36,0,0,0,.36-1,1.15,1.15,0,0,0-.39-1,1.51,1.51,0,0,0-1-.31H923a3.07,3.07,0,0,0-.53.05,1.77,1.77,0,0,0-.45.18,1.94,1.94,0,0,0-.44.31c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.44-.63.61a3.87,3.87,0,0,1-.57.43,2.27,2.27,0,0,1-.6.26,3.36,3.36,0,0,1-.71.08h-.39a1.9,1.9,0,0,1-1.41-.54,2.09,2.09,0,0,1-.52-1.53,2.16,2.16,0,0,1,.5-1.58,2,2,0,0,1,1.49-.51h1.11v.81h-1.14a1.3,1.3,0,0,0-1,.32,1.34,1.34,0,0,0-.3,1,1.27,1.27,0,0,0,.31.91,1.14,1.14,0,0,0,.92.35h.37a1.5,1.5,0,0,0,.94-.29,8.55,8.55,0,0,0,.89-.81l1-1q.35-.35.63-.6a3.43,3.43,0,0,1,.58-.42,2.78,2.78,0,0,1,.62-.25,3.53,3.53,0,0,1,.72-.08h.56a2.62,2.62,0,0,1,.83.13,1.53,1.53,0,0,1,.66.39,2,2,0,0,1,.44.68A2.42,2.42,0,0,1,925.6,300.38Z"
            fill="#888685"
          />
          <path
            d="M926.86,292.7v.82h-2.18a2.08,2.08,0,0,1,.63.51,1.55,1.55,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.1,2.1,0,0,1-1.54.48h-4.63a2,2,0,0,1-1.54-.52,2,2,0,0,1-.41-1.87,1.34,1.34,0,0,1,.47-.72,2.2,2.2,0,0,1,.32-.21H917v-.82Zm-3.32,3.3a1.6,1.6,0,0,0,1-.28,1.14,1.14,0,0,0,.35-.92,1.28,1.28,0,0,0-.37-1,1.36,1.36,0,0,0-1-.36H919a1.44,1.44,0,0,0-1,.4,1.24,1.24,0,0,0-.36.91c0,.81.46,1.21,1.37,1.21Z"
            fill="#888685"
          />
          <path
            d="M923.56,290.43q1.35,0,1.35-1.17a1.29,1.29,0,0,0-.32-.88,1.33,1.33,0,0,0-1-.39H917v-.83h8.51V288h-.84a1.71,1.71,0,0,1,.68.57,1.68,1.68,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.21,2.21,0,0,1-1.53.44H917v-.83Z"
            fill="#888685"
          />
          <path
            d="M925.51,282.53h-.77a1.53,1.53,0,0,1,.86,1.47,1.7,1.7,0,0,1-.48,1.32,2.17,2.17,0,0,1-1.53.45h-.69a2.16,2.16,0,0,1-.83-.13,1.48,1.48,0,0,1-.58-.37,2.29,2.29,0,0,1-.41-.57c-.12-.22-.24-.46-.36-.73a5.07,5.07,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H919a1.84,1.84,0,0,0-1,.22,1,1,0,0,0-.35.86,1.1,1.1,0,0,0,.33.89,1.3,1.3,0,0,0,.93.28h.83v.79H919a2.12,2.12,0,0,1-1.47-.48,1.92,1.92,0,0,1-.54-1.51,1.75,1.75,0,0,1,.54-1.46,2.48,2.48,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.22,1.22,0,0,0-1.15-1.3h-3.2a.44.44,0,0,1,.14.13l.17.22.21.37c.08.15.18.34.29.57a5.05,5.05,0,0,0,.28.51,1.15,1.15,0,0,0,.7.58,1.77,1.77,0,0,0,.56.08h.65a1.61,1.61,0,0,0,1-.27,1.06,1.06,0,0,0,.35-.89Z"
            fill="#888685"
          />
          <path
            d="M917.86,278v.14a1.39,1.39,0,0,0,.34,1,1.33,1.33,0,0,0,1,.37h6.33v.83H917v-.83h1a1.76,1.76,0,0,1-.39-.19,1.43,1.43,0,0,1-.35-.3,1.38,1.38,0,0,1-.25-.42,1.35,1.35,0,0,1-.1-.56Z"
            fill="#888685"
          />
          <path
            d="M922.57,272.51h.84A2.34,2.34,0,0,1,925,273a2.39,2.39,0,0,1-.06,3.14,2.39,2.39,0,0,1-1.66.55h-4.14a2.42,2.42,0,0,1-1.67-.54,2.1,2.1,0,0,1,1.64-3.66h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58H919a1.6,1.6,0,0,0-1.08.31,1.15,1.15,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1A1.51,1.51,0,0,0,919,276Z"
            fill="#888685"
          />
          <path
            d="M915.34,583.56v-3.73H916v2.88h4v-2.09h.69v2.09h4.14v-2.88h.69v3.73Z"
            fill="#888685"
          />
          <path
            d="M923.59,574.53a2.15,2.15,0,0,1,1.48.48,2,2,0,0,1,.53,1.55,1.88,1.88,0,0,1-.56,1.49,2.23,2.23,0,0,1-1.51.49h-.84v-.78h.87a1.55,1.55,0,0,0,1-.29,1.09,1.09,0,0,0,.36-.91,1.23,1.23,0,0,0-.32-.95,1.39,1.39,0,0,0-1-.28h-.27a1.5,1.5,0,0,0-.8.21,3.94,3.94,0,0,0-.82.81l-.69.87a4.29,4.29,0,0,1-1,.95,1.91,1.91,0,0,1-1.11.31h-.24a1.69,1.69,0,0,1-1.83-1.91,1.9,1.9,0,0,1,.51-1.49,2.21,2.21,0,0,1,1.49-.46h.61v.78h-.64a1.43,1.43,0,0,0-1,.27,1.16,1.16,0,0,0-.32.9,1.09,1.09,0,0,0,.3.86,1.15,1.15,0,0,0,.81.27h.4a1.74,1.74,0,0,0,.39-.05,1,1,0,0,0,.33-.15,2.35,2.35,0,0,0,.33-.3c.11-.12.24-.27.39-.46l.69-.87c.2-.25.39-.46.56-.64a3.31,3.31,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.7,2.7,0,0,1,.63-.07Z"
            fill="#888685"
          />
          <path
            d="M922.57,569h.84a2.34,2.34,0,0,1,1.63.51,2.39,2.39,0,0,1-.06,3.14,2.39,2.39,0,0,1-1.66.55h-4.14a2.42,2.42,0,0,1-1.67-.54,2.1,2.1,0,0,1,1.64-3.66h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58H919a1.6,1.6,0,0,0-1.08.31,1.15,1.15,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.51,1.51,0,0,0,1.08.34Z"
            fill="#888685"
          />
          <path
            d="M919,564.26a1.48,1.48,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.28,1.28,0,0,0,.35.91,1.36,1.36,0,0,0,1,.36h6.54v.83H917v-.83h.84a1.78,1.78,0,0,1-.66-.56,1.93,1.93,0,0,1,.21-2.26,2.21,2.21,0,0,1,1.53-.44h6.59v.83Z"
            fill="#888685"
          />
          <path
            d="M917,562.25v-.66h-1.66v-.83H917v-1.18h.63v1.18h6.49a.75.75,0,0,0,.54-.15.78.78,0,0,0,.16-.57v-.46h.69v.55a1.61,1.61,0,0,1-.32,1.14,1.53,1.53,0,0,1-1.09.32h-6.47v.66Z"
            fill="#888685"
          />
          <path
            d="M925.51,555.08h-.77a1.67,1.67,0,0,1,.6.52,2.05,2.05,0,0,1-.22,2.32,2.17,2.17,0,0,1-1.53.45h-.69a2.16,2.16,0,0,1-.83-.13,1.48,1.48,0,0,1-.58-.37,2.07,2.07,0,0,1-.41-.56c-.12-.23-.24-.47-.36-.74a5.07,5.07,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H919a1.84,1.84,0,0,0-1,.22,1,1,0,0,0-.35.86,1.1,1.1,0,0,0,.33.89,1.3,1.3,0,0,0,.93.28h.83v.79H919a2.12,2.12,0,0,1-1.47-.48,1.92,1.92,0,0,1-.54-1.51,1.75,1.75,0,0,1,.54-1.46,2.48,2.48,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.22,1.22,0,0,0-1.15-1.3h-3.2a.44.44,0,0,1,.14.13l.17.22.21.37c.08.15.18.34.29.57a5.67,5.67,0,0,0,.28.52,1.69,1.69,0,0,0,.3.36,1,1,0,0,0,.4.21,1.77,1.77,0,0,0,.56.08h.65a1.61,1.61,0,0,0,1-.27,1.06,1.06,0,0,0,.35-.89Z"
            fill="#888685"
          />
          <path
            d="M915.34,552.83V552h.75v.83Zm1.66,0V552h8.51v.83Z"
            fill="#888685"
          />
          <path
            d="M925.6,546.09a1.93,1.93,0,0,1-1.66,2.17,1.86,1.86,0,0,1-.41,0H922v-.83h1.6a1.46,1.46,0,0,0,1-.33,1.36,1.36,0,0,0,.36-1,1.15,1.15,0,0,0-.39-1,1.49,1.49,0,0,0-1-.31H923a3.07,3.07,0,0,0-.53.05,1.74,1.74,0,0,0-.45.17,2.36,2.36,0,0,0-.44.32c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.45-.63.62a4.73,4.73,0,0,1-.57.43,2.24,2.24,0,0,1-.6.25,2.42,2.42,0,0,1-.71.08h-.39a1.9,1.9,0,0,1-1.41-.54,2.09,2.09,0,0,1-.52-1.53,2.16,2.16,0,0,1,.5-1.58,2,2,0,0,1,1.49-.5h1.11v.81h-1.14a1.3,1.3,0,0,0-1,.31,1.34,1.34,0,0,0-.3,1,1.29,1.29,0,0,0,.31.92,1.14,1.14,0,0,0,.92.35h.37a1.5,1.5,0,0,0,.94-.29,8.55,8.55,0,0,0,.89-.81l1-1q.35-.35.63-.6a3.88,3.88,0,0,1,.58-.42,2.22,2.22,0,0,1,.62-.24,2.9,2.9,0,0,1,.72-.09h.56a2.62,2.62,0,0,1,.83.13,1.72,1.72,0,0,1,.66.4,2,2,0,0,1,.44.67A2.52,2.52,0,0,1,925.6,546.09Z"
            fill="#888685"
          />
          <path
            d="M926.86,538.41v.83h-2.18a2.08,2.08,0,0,1,.63.51,1.55,1.55,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.13,2.13,0,0,1-1.54.47h-4.63a2.06,2.06,0,0,1-1.54-.51,2,2,0,0,1-.41-1.87,1.34,1.34,0,0,1,.47-.72,2.2,2.2,0,0,1,.32-.21H917v-.83Zm-3.32,3.35a1.6,1.6,0,0,0,1-.28,1.14,1.14,0,0,0,.35-.92,1.28,1.28,0,0,0-.37-1,1.36,1.36,0,0,0-1-.36H919a1.44,1.44,0,0,0-1,.4,1.21,1.21,0,0,0-.36.9c0,.81.46,1.22,1.37,1.22Z"
            fill="#888685"
          />
          <path
            d="M923.56,536.15q1.35,0,1.35-1.17a1.32,1.32,0,0,0-.32-.89,1.25,1.25,0,0,0-1-.38H917v-.83h8.51v.83h-.84a1.71,1.71,0,0,1,.68.57,1.68,1.68,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.2,2.2,0,0,1-1.53.43H917v-.82Z"
            fill="#888685"
          />
          <path
            d="M925.51,528.25h-.77a1.8,1.8,0,0,1,.6.53,1.62,1.62,0,0,1,.26,1,1.71,1.71,0,0,1-.48,1.31,2.17,2.17,0,0,1-1.53.45h-.69a2.35,2.35,0,0,1-.83-.12,1.69,1.69,0,0,1-.58-.37,2.29,2.29,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.29,5.29,0,0,0-.63-1.17.71.71,0,0,0-.55-.29H919a1.84,1.84,0,0,0-1,.22,1,1,0,0,0-.35.86,1.1,1.1,0,0,0,.33.89,1.42,1.42,0,0,0,.93.28h.83v.79H919a2.12,2.12,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.72,1.72,0,0,1,.54-1.45,2.48,2.48,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.35,1.35,0,0,0-.34-.94,1.29,1.29,0,0,0-1-.36h-3a.41.41,0,0,1,.14.12l.17.23q.09.14.21.36c.08.15.18.34.29.58s.18.34.28.51a1.31,1.31,0,0,0,.3.36,1,1,0,0,0,.4.22,1.77,1.77,0,0,0,.56.08h.65a1.6,1.6,0,0,0,1-.28,1,1,0,0,0,.34-.88Z"
            fill="#888685"
          />
          <path
            d="M917.86,523.67v.13a1.39,1.39,0,0,0,.34,1,1.25,1.25,0,0,0,1,.38h6.33V526H917v-.83h1a1.79,1.79,0,0,1-.39-.2,1.43,1.43,0,0,1-.35-.3,1.38,1.38,0,0,1-.25-.42,1.28,1.28,0,0,1-.1-.55Z"
            fill="#888685"
          />
          <path
            d="M922.57,518.22h.84a2.35,2.35,0,0,1,1.63.52,2.39,2.39,0,0,1-.06,3.14,2.44,2.44,0,0,1-1.66.54h-4.14a2.36,2.36,0,0,1-1.67-.54,2.37,2.37,0,0,1,0-3.13,2.33,2.33,0,0,1,1.64-.53h2.12v3.4h2.17a1.61,1.61,0,0,0,1.1-.35,1.3,1.3,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.4V519H919a1.6,1.6,0,0,0-1.08.31,1.15,1.15,0,0,0-.39,1,1.22,1.22,0,0,0,.39,1,1.62,1.62,0,0,0,1.08.34Z"
            fill="#888685"
          />
          <polygon
            points="1044.9 393.5 1015.2 383.5 985.5 393.5 985.5 261.5 1015.2 271.5 1044.9 261.5 1044.9 393.5"
            fill="#e1e0e0"
          />
          <polygon
            points="1250.9 393.5 1221.2 383.5 1191.5 393.5 1191.5 261.5 1221.2 271.5 1250.9 261.5 1250.9 393.5"
            fill="#e1e0e0"
          />
          <polygon
            points="1046.2 227.2 1056.2 197.5 1046.2 167.8 1178.2 167.8 1168.2 197.5 1178.2 227.2 1046.2 227.2"
            fill="#e1e0e0"
          />
          <polygon
            points="1176.09 531.52 1083.12 531.52 1083.12 455.5 1009.1 455.5 1009.1 474.19 987.91 474.19 987.91 620 1009.1 620 1083.12 620 1083.12 596.32 1176.09 596.32 1176.09 531.52"
            fill="#e1e0e0"
          />
          <path
            d="M1142,61.71V0h-26V56.61l-408.91,54.2a62,62,0,0,0-118,12.73L208,176.69V673h26V199.31l355.77-49.62A62,62,0,0,0,712,136.4l413.29-54.79L1293,214.29v261L1173,666.08l22,13.84,124-197.17v-281ZM650,171a36,36,0,1,1,36-36A36,36,0,0,1,650,171Z"
            fill="#f7f7f7"
          />
          <path
            d="M1006.48,552.33h3.74V553h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
            fill="#888685"
          />
          <path
            d="M1015.51,560.58a2.21,2.21,0,0,1-.47,1.48,2,2,0,0,1-1.55.53A1.86,1.86,0,0,1,1012,562a2.23,2.23,0,0,1-.49-1.51v-.84h.78v.87a1.49,1.49,0,0,0,.29,1,1.09,1.09,0,0,0,.91.36,1.21,1.21,0,0,0,.94-.32,1.44,1.44,0,0,0,.29-1v-.26a1.45,1.45,0,0,0-.21-.81,3.89,3.89,0,0,0-.81-.81l-.87-.7a4.31,4.31,0,0,1-1-1,1.79,1.79,0,0,1-.31-1.1v-.24a1.87,1.87,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.92,1.92,0,0,1,1.5.51,2.14,2.14,0,0,1,.45,1.48v.62h-.77v-.65a1.49,1.49,0,0,0-.27-1,1.14,1.14,0,0,0-.91-.31,1.08,1.08,0,0,0-.85.3,1.15,1.15,0,0,0-.27.81V556a1.21,1.21,0,0,0,0,.38,1,1,0,0,0,.15.33,3.11,3.11,0,0,0,.29.33,5.71,5.71,0,0,0,.47.39l.87.7c.25.2.46.38.64.55a3.31,3.31,0,0,1,.41.5,2,2,0,0,1,.22.53,3.36,3.36,0,0,1,.06.63Z"
            fill="#888685"
          />
          <path
            d="M1021.08,559.56v.84a2.34,2.34,0,0,1-.51,1.63,2.13,2.13,0,0,1-3.69-1.67v-4.14a2.47,2.47,0,0,1,.54-1.67,2.41,2.41,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12h-3.39v2.18a1.55,1.55,0,0,0,.34,1.09,1.3,1.3,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58V556a1.7,1.7,0,0,0-.27-1,1.43,1.43,0,0,0-1.93,0,1.55,1.55,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M1025.79,556a1.6,1.6,0,0,0-.28-1,1.1,1.1,0,0,0-.89-.33,1.28,1.28,0,0,0-.91.34,1.33,1.33,0,0,0-.37,1v6.54h-.82V554h.82v.83h0a2,2,0,0,1,.56-.66,1.69,1.69,0,0,1,1-.26,1.63,1.63,0,0,1,1.26.47,2.29,2.29,0,0,1,.44,1.54v6.58h-.83Z"
            fill="#888685"
          />
          <path
            d="M1027.8,554h.66v-1.67h.82V554h1.19v.62h-1.19v6.49a.75.75,0,0,0,.16.54.8.8,0,0,0,.56.16h.47v.69h-.56a1.58,1.58,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.47h-.66Z"
            fill="#888685"
          />
          <path
            d="M1035,562.5v-.76h0a1.67,1.67,0,0,1-.53.59,1.65,1.65,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.16,2.16,0,0,1,.13-.83,1.46,1.46,0,0,1,.36-.58,2.29,2.29,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.41,4.41,0,0,0,1.17-.63.72.72,0,0,0,.3-.55V556a1.82,1.82,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.33,1.33,0,0,0-.28.94v.82h-.8V556a2.15,2.15,0,0,1,.48-1.48A2,2,0,0,1,1034,554a1.71,1.71,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.3,1.3,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.44.44,0,0,1-.13.14l-.22.17-.37.22-.57.28c-.2.1-.37.2-.52.29a1.48,1.48,0,0,0-.36.3,1.26,1.26,0,0,0-.22.39,1.86,1.86,0,0,0-.07.57v.64a1.61,1.61,0,0,0,.27,1,1,1,0,0,0,.85.34Z"
            fill="#888685"
          />
          <path
            d="M1037.22,552.33h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
            fill="#888685"
          />
          <path
            d="M1041.64,552.33h4.19V553h-1.67v9.48h-.85V553h-1.67Z"
            fill="#888685"
          />
          <path
            d="M1051.26,560.31a2.47,2.47,0,0,1-.54,1.67,2.34,2.34,0,0,1-3.15,0,2.47,2.47,0,0,1-.54-1.67v-4.12a2.48,2.48,0,0,1,.54-1.68,2,2,0,0,1,1.57-.6,2,2,0,0,1,1.58.6,2.48,2.48,0,0,1,.54,1.68Zm-2.12,1.59a1.22,1.22,0,0,0,1-.39,1.64,1.64,0,0,0,.33-1.09v-4.34a1.64,1.64,0,0,0-.33-1.09,1.22,1.22,0,0,0-1-.39,1.18,1.18,0,0,0-1,.39,1.55,1.55,0,0,0-.34,1.09v4.34a1.55,1.55,0,0,0,.34,1.09A1.18,1.18,0,0,0,1049.14,561.9Z"
            fill="#888685"
          />
          <path
            d="M1059.12,554l-1.37,8.5h-.81l-1.18-6.52-1.22,6.52h-.81l-1.38-8.5h.83l1,6.58h0l1.23-6.58h.7l1.22,6.58h0l1-6.58Z"
            fill="#888685"
          />
          <path
            d="M1064.43,559.56v.84a2.34,2.34,0,0,1-.51,1.63,2.13,2.13,0,0,1-3.69-1.67v-4.14a2.47,2.47,0,0,1,.54-1.67,2.41,2.41,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1061v2.18a1.55,1.55,0,0,0,.34,1.09,1.3,1.3,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58V556a1.69,1.69,0,0,0-.32-1.09,1.51,1.51,0,0,0-2,0A1.55,1.55,0,0,0,1061,556Z"
            fill="#888685"
          />
          <path
            d="M1068.19,554.85h-.13a1.39,1.39,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V554h.82v1h0a2.75,2.75,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.38,1.38,0,0,1,.42-.25,1.28,1.28,0,0,1,.55-.1Z"
            fill="#888685"
          />
          <path d="M1074.33,552l-2.18,11.82h-.72l2.18-11.82Z" fill="#888685" />
          <path
            d="M1006.25,568.33h4.18V569h-1.67v9.48h-.85V569h-1.66Z"
            fill="#888685"
          />
          <path
            d="M1014.93,572a1.6,1.6,0,0,0-.28-1,1.1,1.1,0,0,0-.89-.33,1.28,1.28,0,0,0-.91.34,1.33,1.33,0,0,0-.37,1v6.54h-.82V568.33h.82v2.5h0a2,2,0,0,1,.55-.66,1.69,1.69,0,0,1,1-.26,1.63,1.63,0,0,1,1.26.47,2.28,2.28,0,0,1,.43,1.54v6.58h-.82Z"
            fill="#888685"
          />
          <path
            d="M1021.37,575.56v.84a2.35,2.35,0,0,1-.52,1.63,2.4,2.4,0,0,1-3.14,0,2.47,2.47,0,0,1-.54-1.67v-4.14a2.36,2.36,0,0,1,.54-1.67,2.41,2.41,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1018v2.18a1.6,1.6,0,0,0,.35,1.09,1.3,1.3,0,0,0,1,.39c.84,0,1.25-.47,1.25-1.42v-.92Zm-3.4-2h2.58V572a1.64,1.64,0,0,0-.31-1.09,1.17,1.17,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.65,1.65,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M1026,573.37v5.13h-.86V568.33h2a2.16,2.16,0,0,1,1.58.5,2,2,0,0,1,.49,1.42v1.5a2,2,0,0,1-.49,1.44,2.12,2.12,0,0,1-1.62.52h0l2.45,4.79h-1Zm0-4.35v4h1.06a1.36,1.36,0,0,0,1-.32,1.24,1.24,0,0,0,.31-.9v-1.59q0-1.2-1.29-1.2Z"
            fill="#888685"
          />
          <path
            d="M1030.66,568.33h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
            fill="#888685"
          />
          <path
            d="M1032.7,570h.66v-1.67h.83V570h1.18v.62h-1.18v6.49a.71.71,0,0,0,.16.54.76.76,0,0,0,.56.16h.46v.69h-.55a1.58,1.58,0,0,1-1.13-.32,1.49,1.49,0,0,1-.33-1.09v-6.47h-.66Z"
            fill="#888685"
          />
          <path
            d="M1037.43,577.81h2.73v.69h-3.59v-.69l2.79-7.12h-2.59V570h3.45v.69Z"
            fill="#888685"
          />
          <path
            d="M1046.19,578.59a2.48,2.48,0,0,1-1-.2,2,2,0,0,1-.7-.55,2.41,2.41,0,0,1-.4-.8,3.76,3.76,0,0,1-.13-1v-5.28a3.76,3.76,0,0,1,.13-1,2.45,2.45,0,0,1,.4-.81,1.84,1.84,0,0,1,.7-.54,2.48,2.48,0,0,1,1-.2,2.63,2.63,0,0,1,1,.17,1.41,1.41,0,0,1,.66.47,1.85,1.85,0,0,1,.34.74,4.19,4.19,0,0,1,.09,1v1.2h-.84v-1.27a2.24,2.24,0,0,0-.25-1.16,1.14,1.14,0,0,0-1-.42,1.38,1.38,0,0,0-.66.14,1.39,1.39,0,0,0-.44.36,1.61,1.61,0,0,0-.25.55,3,3,0,0,0-.07.67v5.53a3,3,0,0,0,.07.67,1.61,1.61,0,0,0,.25.55,1.39,1.39,0,0,0,.44.36,1.35,1.35,0,0,0,.66.14,1.14,1.14,0,0,0,1-.44,2.36,2.36,0,0,0,.26-1.14v-1.81h.82v1.74a4.7,4.7,0,0,1-.09.92,1.91,1.91,0,0,1-.35.75,1.68,1.68,0,0,1-.66.49A2.53,2.53,0,0,1,1046.19,578.59Z"
            fill="#888685"
          />
          <path
            d="M1052.91,578.5v-.76h0a1.67,1.67,0,0,1-.53.59,1.65,1.65,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.16,2.16,0,0,1,.13-.83,1.46,1.46,0,0,1,.36-.58,2.29,2.29,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.41,4.41,0,0,0,1.17-.63.72.72,0,0,0,.3-.55V572a1.82,1.82,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.33,1.33,0,0,0-.28.94v.82h-.8V572a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.71,1.71,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.3,1.3,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.44.44,0,0,1-.13.14l-.22.17-.37.22-.57.28c-.2.1-.37.2-.52.29a1.48,1.48,0,0,0-.36.3,1.26,1.26,0,0,0-.22.39,1.86,1.86,0,0,0-.07.57v.64a1.61,1.61,0,0,0,.27,1A1,1,0,0,0,1051.6,577.9Z"
            fill="#888685"
          />
          <path
            d="M1057.48,570.85h-.13a1.39,1.39,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V570h.82v1h0a2.75,2.75,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.38,1.38,0,0,1,.42-.25,1.28,1.28,0,0,1,.55-.1Z"
            fill="#888685"
          />
          <path d="M1058.76,568.33h.82V578.5h-.82Z" fill="#888685" />
          <path
            d="M1060.8,570h.66v-1.67h.82V570h1.19v.62h-1.19v6.49a.75.75,0,0,0,.16.54.8.8,0,0,0,.56.16h.47v.69h-.56a1.58,1.58,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.47h-.66Z"
            fill="#888685"
          />
          <path
            d="M1069,576.31a2.47,2.47,0,0,1-.54,1.67,2.34,2.34,0,0,1-3.15,0,2.47,2.47,0,0,1-.54-1.67v-4.12a2.48,2.48,0,0,1,.54-1.68,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.57.6,2.48,2.48,0,0,1,.54,1.68Zm-2.11,1.59a1.18,1.18,0,0,0,1-.39,1.64,1.64,0,0,0,.33-1.09v-4.34a1.64,1.64,0,0,0-.33-1.09,1.18,1.18,0,0,0-1-.39,1.22,1.22,0,0,0-1,.39,1.65,1.65,0,0,0-.34,1.09v4.34a1.65,1.65,0,0,0,.34,1.09A1.22,1.22,0,0,0,1066.89,577.9Z"
            fill="#888685"
          />
          <path
            d="M1073.71,572a1.63,1.63,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.33,1.25,1.25,0,0,0-.9.34,1.33,1.33,0,0,0-.37,1v6.54h-.83V570h.83v.83h0a2.1,2.1,0,0,1,.56-.66,1.69,1.69,0,0,1,1-.26,1.63,1.63,0,0,1,1.26.47,2.29,2.29,0,0,1,.44,1.54v6.58h-.83Z"
            fill="#888685"
          />
          <path
            d="M1078.06,578.5l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
            fill="#888685"
          />
          <path d="M1084.35,568.33h.82V578.5h-.82Z" fill="#888685" />
          <path
            d="M1089.7,571.88a1.6,1.6,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.1,1.1,0,0,0-.85.35,1.4,1.4,0,0,0-.32,1v6.57h-.83V570h.83v.82h0a1.83,1.83,0,0,1,.52-.65,1.65,1.65,0,0,1,.94-.26,1.41,1.41,0,0,1,1.45,1h0a1.55,1.55,0,0,1,.57-.72,1.6,1.6,0,0,1,1-.28c1.07,0,1.61.65,1.61,2v6.64h-.83v-6.62a1.6,1.6,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.12,1.12,0,0,0-.81.32,1.34,1.34,0,0,0-.36.89v6.69h-.83Z"
            fill="#888685"
          />
          <path
            d="M1098.19,578.5v-.76h0a1.56,1.56,0,0,1-.52.59,2.05,2.05,0,0,1-2.32-.22,2.17,2.17,0,0,1-.45-1.53v-.69a2.16,2.16,0,0,1,.13-.83,1.48,1.48,0,0,1,.37-.58,2.29,2.29,0,0,1,.57-.41c.22-.12.46-.24.73-.36a4.41,4.41,0,0,0,1.17-.63.72.72,0,0,0,.3-.55V572a1.84,1.84,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.09,1.09,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94v.82h-.79V572a2.15,2.15,0,0,1,.48-1.48,1.91,1.91,0,0,1,1.51-.53,1.77,1.77,0,0,1,1.46.53,2.5,2.5,0,0,1,.42,1.5v6.5Zm-1.3-.6a1.35,1.35,0,0,0,.94-.34,1.29,1.29,0,0,0,.36-1v-3a.53.53,0,0,1-.12.14l-.23.17-.37.22-.57.28a4.21,4.21,0,0,0-.51.29,1.31,1.31,0,0,0-.36.3,1,1,0,0,0-.22.39,1.83,1.83,0,0,0-.08.57v.64a1.61,1.61,0,0,0,.27,1,1.07,1.07,0,0,0,.89.34Z"
            fill="#888685"
          />
          <path
            d="M1100.19,570h.66v-1.67h.82V570h1.19v.62h-1.19v6.49a.75.75,0,0,0,.16.54.8.8,0,0,0,.56.16h.47v.69h-.56a1.58,1.58,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.47h-.66Z"
            fill="#888685"
          />
          <path
            d="M1108.26,570l-2,8.61a2.46,2.46,0,0,1-.19.58,1,1,0,0,1-.73.6,3.36,3.36,0,0,1-.63.06h-.42v-.69h.41a.76.76,0,0,0,.56-.16,1.4,1.4,0,0,0,.28-.59l.19-.74h-.19L1103.8,570h.84l1.38,6.33h0l1.41-6.33Z"
            fill="#888685"
          />
          <path
            d="M1083.48,306.14h3.74v.69h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
            fill="#888685"
          />
          <path
            d="M1092.51,314.39a2.21,2.21,0,0,1-.47,1.48,2,2,0,0,1-1.55.53,1.88,1.88,0,0,1-1.49-.56,2.23,2.23,0,0,1-.49-1.51v-.84h.78v.87a1.49,1.49,0,0,0,.29,1,1.09,1.09,0,0,0,.91.36,1.16,1.16,0,0,0,.94-.32,1.41,1.41,0,0,0,.29-.95v-.27a1.41,1.41,0,0,0-.21-.8,3.5,3.5,0,0,0-.81-.82l-.87-.69a4.29,4.29,0,0,1-.95-1,1.91,1.91,0,0,1-.31-1.11v-.24a1.87,1.87,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.92,1.92,0,0,1,1.5.51,2.14,2.14,0,0,1,.45,1.48v.62h-.77v-.65a1.55,1.55,0,0,0-.27-1,1.14,1.14,0,0,0-.91-.31,1.08,1.08,0,0,0-.85.3,1.15,1.15,0,0,0-.27.81v.24a1.58,1.58,0,0,0,.05.38,1,1,0,0,0,.15.33,2.28,2.28,0,0,0,.29.33,5.85,5.85,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.24,2.24,0,0,1,.41.51,2,2,0,0,1,.22.52,3.47,3.47,0,0,1,.06.64Z"
            fill="#888685"
          />
          <path
            d="M1098.08,313.37v.84a2.34,2.34,0,0,1-.51,1.63,2.13,2.13,0,0,1-3.69-1.67V310a2.12,2.12,0,0,1,3.67-1.66,2.34,2.34,0,0,1,.53,1.65v2.11h-3.39v2.18a1.55,1.55,0,0,0,.34,1.09,1.3,1.3,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.64,1.64,0,0,0-.31-1.09,1.43,1.43,0,0,0-1.93,0,1.55,1.55,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M1102.79,309.76a1.48,1.48,0,0,0-.28-1,1.07,1.07,0,0,0-.89-.34,1.28,1.28,0,0,0-.91.34,1.33,1.33,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.78,1.78,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.21,2.21,0,0,1,.44,1.53v6.58h-.83Z"
            fill="#888685"
          />
          <path
            d="M1104.8,307.81h.66v-1.67h.82v1.67h1.19v.63h-1.19v6.48a.78.78,0,0,0,.16.55.8.8,0,0,0,.56.15h.47v.69h-.56a1.58,1.58,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.46h-.66Z"
            fill="#888685"
          />
          <path
            d="M1112,316.31v-.76h0a1.67,1.67,0,0,1-.53.59,1.51,1.51,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.36,2.36,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.58,2.58,0,0,1,.57-.41l.74-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.84,1.84,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.14,1.14,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94v.82h-.8v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.75,1.75,0,0,1,1.45.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.36,1.36,0,0,0,.94-.33,1.33,1.33,0,0,0,.37-1v-3a.44.44,0,0,1-.13.14,1.84,1.84,0,0,1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.69,1.69,0,0,0-.36.3,1.2,1.2,0,0,0-.22.4,1.76,1.76,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1,1.08,1.08,0,0,0,.85.33Z"
            fill="#888685"
          />
          <path
            d="M1114.22,306.14h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
            fill="#888685"
          />
          <path
            d="M1118.6,316.31l2-10.17h1.05l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
            fill="#888685"
          />
          <path
            d="M1124.88,307.81h.83v.85h0a2.21,2.21,0,0,1,.57-.65,1.68,1.68,0,0,1,1-.29,1.64,1.64,0,0,1,1.28.49,2.21,2.21,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.77,1.77,0,0,1-1.33.48,1.6,1.6,0,0,1-1-.28,1.73,1.73,0,0,1-.52-.62h0v2.16h-.83Zm3.35,2a1.55,1.55,0,0,0-.29-1,1.16,1.16,0,0,0-.93-.34,1.24,1.24,0,0,0-.91.36,1.48,1.48,0,0,0-.39,1v4.62a1.21,1.21,0,0,0,1.29,1.33c.82,0,1.23-.45,1.23-1.36Z"
            fill="#888685"
          />
          <path
            d="M1133.66,316.31v-.76h0a1.67,1.67,0,0,1-.53.59,1.51,1.51,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.36,2.36,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.58,2.58,0,0,1,.57-.41l.74-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.84,1.84,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.14,1.14,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94v.82h-.8v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.75,1.75,0,0,1,1.45.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.36,1.36,0,0,0,.94-.33,1.33,1.33,0,0,0,.37-1v-3a.44.44,0,0,1-.13.14,1.84,1.84,0,0,1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.69,1.69,0,0,0-.36.3,1.2,1.2,0,0,0-.22.4,1.76,1.76,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.08,1.08,0,0,0,1132.35,315.71Z"
            fill="#888685"
          />
          <path
            d="M1138.23,308.66h-.13a1.42,1.42,0,0,0-1,.34,1.34,1.34,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a1.79,1.79,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.41,1.41,0,0,1,.42-.26,1.28,1.28,0,0,1,.55-.1Z"
            fill="#888685"
          />
          <path
            d="M1139.25,307.81h.66v-1.67h.83v1.67h1.18v.63h-1.18v6.48a.76.76,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
            fill="#888685"
          />
          <path
            d="M1146.32,309.7a1.61,1.61,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.11,1.11,0,0,0-.84.35,1.37,1.37,0,0,0-.33,1v6.57h-.82v-8.5h.82v.82h0a1.5,1.5,0,0,1,1.45-.91,1.41,1.41,0,0,1,1.46,1h0a1.59,1.59,0,0,1,.56-.72,1.71,1.71,0,0,1,1-.28c1.07,0,1.6.65,1.6,1.95v6.64h-.82V309.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.08,1.08,0,0,0-.81.33,1.27,1.27,0,0,0-.36.88v6.69h-.82Z"
            fill="#888685"
          />
          <path
            d="M1155.81,313.37v.84a2.34,2.34,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.46,2.46,0,0,1-.55-1.67V310a2.11,2.11,0,1,1,4.2,0v2.11h-3.39v2.18a1.6,1.6,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2H1155v-1.53a1.64,1.64,0,0,0-.31-1.09,1.18,1.18,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.65,1.65,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M1160.52,309.76a1.61,1.61,0,0,0-.27-1,1.08,1.08,0,0,0-.9-.34,1.25,1.25,0,0,0-.9.34,1.33,1.33,0,0,0-.37,1v6.54h-.83v-8.5h.83v.84h0a1.78,1.78,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.21,2.21,0,0,1,.44,1.53v6.58h-.83Z"
            fill="#888685"
          />
          <path
            d="M1162.53,307.81h.66v-1.67h.83v1.67h1.18v.63H1164v6.48a.76.76,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
            fill="#888685"
          />
          <path
            d="M1170.45,314.39a2.15,2.15,0,0,1-.48,1.48,2,2,0,0,1-1.54.53,1.88,1.88,0,0,1-1.49-.56,2.23,2.23,0,0,1-.49-1.51v-.84h.78v.87a1.54,1.54,0,0,0,.28,1,1.1,1.1,0,0,0,.92.36,1.16,1.16,0,0,0,.94-.32,1.41,1.41,0,0,0,.29-.95v-.27a1.41,1.41,0,0,0-.21-.8,3.5,3.5,0,0,0-.81-.82l-.87-.69a4.74,4.74,0,0,1-1-1,1.91,1.91,0,0,1-.31-1.11v-.24a1.87,1.87,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.92,1.92,0,0,1,1.5.51,2.14,2.14,0,0,1,.45,1.48v.62h-.78v-.65a1.43,1.43,0,0,0-.27-1,1.15,1.15,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.15,1.15,0,0,0-.27.81v.24a1.21,1.21,0,0,0,0,.38,1,1,0,0,0,.16.33,1.81,1.81,0,0,0,.29.33,5.85,5.85,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.55,2.55,0,0,1,.41.51,2,2,0,0,1,.22.52,3.47,3.47,0,0,1,.06.64Z"
            fill="#888685"
          />
          <path
            d="M221.42,679.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18H225L223.9,671h0Z"
            fill="#888685"
          />
          <path d="M227.71,669.64h.82v10.17h-.82Z" fill="#888685" />
          <path d="M229.85,674.74h2v.69h-2Z" fill="#888685" />
          <path
            d="M233.18,669.64h3.63v.69H234v4h2.08V675H234v4.83h-.86Z"
            fill="#888685"
          />
          <path
            d="M241.21,679.81v-.76h0a1.67,1.67,0,0,1-.53.59,1.51,1.51,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.35,2.35,0,0,1,.12-.83,1.69,1.69,0,0,1,.37-.58,2.58,2.58,0,0,1,.57-.41l.74-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.84,1.84,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.16,1.16,0,0,0-.9.32,1.42,1.42,0,0,0-.27.94V674h-.8v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.75,1.75,0,0,1,1.45.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.12.89.89,0,0,0,0-.23v-3a.86.86,0,0,1-.13.14l-.23.17a2.16,2.16,0,0,1-.36.22l-.57.28a5.29,5.29,0,0,0-.52.29,1.31,1.31,0,0,0-.36.3,1,1,0,0,0-.22.4,1.76,1.76,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1,1.05,1.05,0,0,0,.88.35Z"
            fill="#888685"
          />
          <path
            d="M245.78,672.16h-.13a1.42,1.42,0,0,0-1,.34,1.34,1.34,0,0,0-.38,1v6.33h-.82v-8.5h.82v1h0a1.79,1.79,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.41,1.41,0,0,1,.42-.26,1.28,1.28,0,0,1,.55-.1Z"
            fill="#888685"
          />
          <path
            d="M250.22,679.81v-.76h0a1.56,1.56,0,0,1-.52.59,1.52,1.52,0,0,1-.95.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.36,2.36,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.58,2.58,0,0,1,.57-.41l.73-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.93,1.93,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94V674h-.79v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.77,1.77,0,0,1,1.46.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.2,1.2,0,0,0,1.3-1.11,1,1,0,0,0,0-.24v-3a.44.44,0,0,1-.13.14,1.84,1.84,0,0,1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.69,1.69,0,0,0-.36.3,1,1,0,0,0-.21.4,1.77,1.77,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.08,1.08,0,0,0,248.92,679.21Z"
            fill="#888685"
          />
          <path
            d="M252.47,669.64h.83v2.52h0a2.18,2.18,0,0,1,.56-.65,1.68,1.68,0,0,1,1-.29,1.64,1.64,0,0,1,1.28.49,2.21,2.21,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.77,1.77,0,0,1-1.33.48,1.48,1.48,0,0,1-1-.28,2,2,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.5,1.5,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.24,1.24,0,0,0-.91.36,1.48,1.48,0,0,0-.39,1v4.62a1.21,1.21,0,0,0,1.29,1.33c.82,0,1.23-.45,1.23-1.36Z"
            fill="#888685"
          />
          <path
            d="M258.08,669.64h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
            fill="#888685"
          />
          <path
            d="M265.91,679.81v-.76h0a1.56,1.56,0,0,1-.52.59,1.52,1.52,0,0,1-.95.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.36,2.36,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.58,2.58,0,0,1,.57-.41l.73-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.93,1.93,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94V674h-.79v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.77,1.77,0,0,1,1.46.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.2,1.2,0,0,0,1.3-1.11,1,1,0,0,0,0-.24v-3a.44.44,0,0,1-.13.14,1.84,1.84,0,0,1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.69,1.69,0,0,0-.36.3,1,1,0,0,0-.21.4,1.77,1.77,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1,1.08,1.08,0,0,0,.89.35Z"
            fill="#888685"
          />
          <path
            d="M272.24,671.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.39-6.93Z"
            fill="#888685"
          />
          <path
            d="M277.49,676.87v.84a2.34,2.34,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.41,2.41,0,0,1-.55-1.67v-4.14a2.11,2.11,0,1,1,4.2,0v2.11H274.1v2.18a1.6,1.6,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.64,1.64,0,0,0-.31-1.09,1.18,1.18,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.55,1.55,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M282.2,673.26a1.48,1.48,0,0,0-.28-1,1.07,1.07,0,0,0-.89-.34,1.28,1.28,0,0,0-.91.34,1.36,1.36,0,0,0-.36,1v6.54h-.83v-8.5h.83v.84h0a1.78,1.78,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.21,2.21,0,0,1,.44,1.53v6.58h-.83Z"
            fill="#888685"
          />
          <path
            d="M285.26,677.86q0,1.35,1.17,1.35a1.22,1.22,0,0,0,1.28-1.27v-6.63h.82v8.5h-.82V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.2,2.2,0,0,1-.43-1.53v-6.58h.82Z"
            fill="#888685"
          />
          <path
            d="M294.17,676.87v.84a2.34,2.34,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.41,2.41,0,0,1-.55-1.67v-4.14a2.11,2.11,0,1,1,4.2,0v2.11h-3.39v2.18a1.6,1.6,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.64,1.64,0,0,0-.31-1.09,1.18,1.18,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.55,1.55,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M698.81,679.81l2-10.17h1l2,10.17H703l-.48-2.49H700.1l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
            fill="#888685"
          />
          <path d="M705.1,669.64h.82v10.17h-.82Z" fill="#888685" />
          <path d="M707.24,674.74h2v.69h-2Z" fill="#888685" />
          <path
            d="M710.57,669.64h3.63v.69h-2.77v4h2.08V675h-2.08v4.83h-.86Z"
            fill="#888685"
          />
          <path
            d="M718.6,679.81v-.76h0a1.67,1.67,0,0,1-.53.59,1.51,1.51,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.35,2.35,0,0,1,.12-.83,1.69,1.69,0,0,1,.37-.58,2.58,2.58,0,0,1,.57-.41l.74-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.84,1.84,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.16,1.16,0,0,0-.9.32,1.42,1.42,0,0,0-.27.94V674h-.8v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.75,1.75,0,0,1,1.45.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.12.89.89,0,0,0,0-.23v-3a.86.86,0,0,1-.13.14l-.23.17a2.16,2.16,0,0,1-.36.22l-.57.28a5.29,5.29,0,0,0-.52.29,1.31,1.31,0,0,0-.36.3,1,1,0,0,0-.22.4,1.76,1.76,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.05,1.05,0,0,0,717.29,679.21Z"
            fill="#888685"
          />
          <path
            d="M723.17,672.16H723a1.42,1.42,0,0,0-1,.34,1.34,1.34,0,0,0-.38,1v6.33h-.82v-8.5h.82v1h0a1.79,1.79,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.41,1.41,0,0,1,.42-.26,1.28,1.28,0,0,1,.55-.1Z"
            fill="#888685"
          />
          <path
            d="M727.61,679.81v-.76h0a1.56,1.56,0,0,1-.52.59,1.52,1.52,0,0,1-1,.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.36,2.36,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.52,2.52,0,0,1,.56-.41l.74-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.93,1.93,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.49-1.48,1.91,1.91,0,0,1,1.51-.53,1.81,1.81,0,0,1,1.46.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.2,1.2,0,0,0,1.3-1.11,1,1,0,0,0,0-.24v-3a.44.44,0,0,1-.13.14,1.84,1.84,0,0,1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.69,1.69,0,0,0-.36.3,1,1,0,0,0-.21.4,1.77,1.77,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.08,1.08,0,0,0,726.31,679.21Z"
            fill="#888685"
          />
          <path
            d="M729.86,669.64h.83v2.52h0a2.18,2.18,0,0,1,.56-.65,1.68,1.68,0,0,1,1-.29,1.64,1.64,0,0,1,1.28.49,2.21,2.21,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.77,1.77,0,0,1-1.33.48,1.48,1.48,0,0,1-1-.28,2,2,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.5,1.5,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.24,1.24,0,0,0-.91.36,1.48,1.48,0,0,0-.39,1v4.62a1.33,1.33,0,0,0,.37,1,1.29,1.29,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
            fill="#888685"
          />
          <path
            d="M735.47,669.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
            fill="#888685"
          />
          <path
            d="M743.3,679.81v-.76h0a1.67,1.67,0,0,1-.53.59,1.51,1.51,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.36,2.36,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.58,2.58,0,0,1,.57-.41l.74-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.82,1.82,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94V674h-.8v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.75,1.75,0,0,1,1.45.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.36,1.36,0,0,0,.94-.33,1.33,1.33,0,0,0,.37-1v-3a.44.44,0,0,1-.13.14,1.84,1.84,0,0,1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.69,1.69,0,0,0-.36.3,1.2,1.2,0,0,0-.22.4,1.76,1.76,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1,1.1,1.1,0,0,0,.89.33Z"
            fill="#888685"
          />
          <path
            d="M749.63,671.31l-1.77,8.5h-.93l-1.77-8.5H746l1.41,6.93h0l1.4-6.93Z"
            fill="#888685"
          />
          <path
            d="M754.88,676.87v.84a2.34,2.34,0,0,1-.51,1.63,2.15,2.15,0,0,1-3.69-1.72v-4.14a2.12,2.12,0,0,1,3.67-1.66,2.34,2.34,0,0,1,.53,1.65v2.11h-3.39v2.18a1.55,1.55,0,0,0,.34,1.09,1.3,1.3,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.64,1.64,0,0,0-.31-1.09,1.43,1.43,0,0,0-1.93,0,1.55,1.55,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M759.59,673.26a1.48,1.48,0,0,0-.28-1,1.07,1.07,0,0,0-.89-.34,1.28,1.28,0,0,0-.91.34,1.33,1.33,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.86,1.86,0,0,1,.55-.66,1.7,1.7,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.2,2.2,0,0,1,.43,1.53v6.58h-.82Z"
            fill="#888685"
          />
          <path
            d="M762.65,677.86q0,1.35,1.17,1.35a1.25,1.25,0,0,0,.88-.32,1.26,1.26,0,0,0,.39-.95v-6.63h.83v8.5h-.83V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.21,2.21,0,0,1-.44-1.53v-6.58h.83Z"
            fill="#888685"
          />
          <path
            d="M771.56,676.87v.84a2.34,2.34,0,0,1-.51,1.63,2.15,2.15,0,0,1-3.69-1.72v-4.14a2.12,2.12,0,0,1,3.67-1.66,2.34,2.34,0,0,1,.53,1.65v2.11h-3.39v2.18a1.55,1.55,0,0,0,.34,1.09,1.3,1.3,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.64,1.64,0,0,0-.31-1.09,1.43,1.43,0,0,0-1.93,0,1.55,1.55,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M1176.2,679.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
            fill="#888685"
          />
          <path d="M1182.48,669.64h.83v10.17h-.83Z" fill="#888685" />
          <path d="M1184.63,674.74h2v.69h-2Z" fill="#888685" />
          <path
            d="M1188,669.64h3.63v.69h-2.78v4h2.09V675h-2.09v4.83H1188Z"
            fill="#888685"
          />
          <path
            d="M1196,679.81v-.76h0a1.67,1.67,0,0,1-.53.59,1.54,1.54,0,0,1-1,.26,1.68,1.68,0,0,1-1.31-.48,2.21,2.21,0,0,1-.46-1.53v-.69a2.62,2.62,0,0,1,.13-.83,1.69,1.69,0,0,1,.37-.58,2.58,2.58,0,0,1,.57-.41l.73-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.93,1.93,0,0,0-.22-1,.92.92,0,0,0-.86-.34,1.14,1.14,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94V674h-.79v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.77,1.77,0,0,1,1.46.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.4,1.4,0,0,0,.94-.33,1.36,1.36,0,0,0,.36-1v-3a.41.41,0,0,1-.12.14l-.23.17a2.77,2.77,0,0,1-.37.22c-.14.08-.34.17-.57.28a4.21,4.21,0,0,0-.51.29,1.31,1.31,0,0,0-.36.3,1,1,0,0,0-.22.4,1.77,1.77,0,0,0-.08.56v.64a1.7,1.7,0,0,0,.27,1,1.1,1.1,0,0,0,.87.33Z"
            fill="#888685"
          />
          <path
            d="M1200.56,672.16h-.14a1.42,1.42,0,0,0-1,.34,1.33,1.33,0,0,0-.37,1v6.33h-.83v-8.5h.83v1h0a1.79,1.79,0,0,1,.2-.39,1.21,1.21,0,0,1,.29-.35,1.42,1.42,0,0,1,1-.36Z"
            fill="#888685"
          />
          <path
            d="M1205,679.81v-.76h0a1.67,1.67,0,0,1-.53.59,1.51,1.51,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.36,2.36,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.58,2.58,0,0,1,.57-.41l.74-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.84,1.84,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.14,1.14,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94V674h-.8v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.75,1.75,0,0,1,1.45.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.36,1.36,0,0,0,.94-.33,1.33,1.33,0,0,0,.37-1v-3a.44.44,0,0,1-.13.14,1.84,1.84,0,0,1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.69,1.69,0,0,0-.36.3,1.2,1.2,0,0,0-.22.4,1.76,1.76,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.08,1.08,0,0,0,1203.69,679.21Z"
            fill="#888685"
          />
          <path
            d="M1207.25,669.64h.82v2.52h0a2.18,2.18,0,0,1,.56-.65,1.91,1.91,0,0,1,2.29.2,2.21,2.21,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.8,1.8,0,0,1-1.34.48,1.48,1.48,0,0,1-1-.28,1.94,1.94,0,0,1-.52-.62h0v.81h-.82Zm3.34,3.63a1.55,1.55,0,0,0-.29-1,1.13,1.13,0,0,0-.92-.34,1.26,1.26,0,0,0-.92.36,1.48,1.48,0,0,0-.39,1v4.62a1.22,1.22,0,0,0,1.29,1.33c.82,0,1.23-.45,1.23-1.36Z"
            fill="#888685"
          />
          <path
            d="M1212.86,669.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
            fill="#888685"
          />
          <path
            d="M1220.69,679.81v-.76h0a1.67,1.67,0,0,1-.53.59,1.51,1.51,0,0,1-.94.26,1.7,1.7,0,0,1-1.32-.48,2.17,2.17,0,0,1-.45-1.53v-.69a2.36,2.36,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.58,2.58,0,0,1,.57-.41l.74-.35a5.07,5.07,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.84,1.84,0,0,0-.23-1,1,1,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.43,1.43,0,0,0-.28.94V674h-.8v-.76a2.15,2.15,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.75,1.75,0,0,1,1.45.53,2.49,2.49,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.36,1.36,0,0,0,.94-.33,1.33,1.33,0,0,0,.37-1v-3a.44.44,0,0,1-.13.14,1.84,1.84,0,0,1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.69,1.69,0,0,0-.36.3,1.2,1.2,0,0,0-.22.4,1.76,1.76,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1,1.08,1.08,0,0,0,.88.33Z"
            fill="#888685"
          />
          <path
            d="M1227,671.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.4-6.93Z"
            fill="#888685"
          />
          <path
            d="M1232.27,676.87v.84a2.34,2.34,0,0,1-.51,1.63,2.15,2.15,0,0,1-3.69-1.72v-4.14a2.12,2.12,0,0,1,3.67-1.66,2.34,2.34,0,0,1,.53,1.65v2.11h-3.39v2.18a1.55,1.55,0,0,0,.34,1.09,1.3,1.3,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.69,1.69,0,0,0-.32-1.09,1.51,1.51,0,0,0-2,0,1.55,1.55,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <path
            d="M1237,673.26a1.48,1.48,0,0,0-.28-1,1.07,1.07,0,0,0-.89-.34,1.28,1.28,0,0,0-.91.34,1.33,1.33,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.86,1.86,0,0,1,.55-.66,1.7,1.7,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.2,2.2,0,0,1,.43,1.53v6.58H1237Z"
            fill="#888685"
          />
          <path
            d="M1240,677.86q0,1.35,1.17,1.35a1.25,1.25,0,0,0,.88-.32,1.26,1.26,0,0,0,.39-.95v-6.63h.83v8.5h-.83V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.21,2.21,0,0,1-.44-1.53v-6.58h.83Z"
            fill="#888685"
          />
          <path
            d="M1249,676.87v.84a2.34,2.34,0,0,1-.51,1.63,2.15,2.15,0,0,1-3.69-1.72v-4.14a2.12,2.12,0,0,1,3.67-1.66,2.34,2.34,0,0,1,.53,1.65v2.11h-3.39v2.18a1.55,1.55,0,0,0,.34,1.09,1.3,1.3,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.69,1.69,0,0,0-.32-1.09,1.51,1.51,0,0,0-2,0,1.55,1.55,0,0,0-.34,1.09Z"
            fill="#888685"
          />
          <rect x="301.43" y="629.39" width="40" height="23" fill="#dcdbdb" />
          <rect
            x="315.27"
            y="634.66"
            width="12.3"
            height="12.3"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <line
            x1="315.27"
            y1="637.1"
            x2="327.58"
            y2="637.1"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <line
            x1="315.27"
            y1="642.96"
            x2="327.58"
            y2="642.96"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <line
            x1="321.43"
            y1="637.1"
            x2="321.43"
            y2="642.96"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <circle
            cx="317"
            cy="644.81"
            r="0.61"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <circle
            cx="325.61"
            cy="644.81"
            r="0.61"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <rect
            x="316.38"
            y="646.96"
            width="1.23"
            height="2.15"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <rect
            x="324.99"
            y="646.96"
            width="1.23"
            height="2.15"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <rect x="146.43" y="737.39" width="40" height="23" fill="#dcdbdb" />
          <rect
            x="160.27"
            y="742.66"
            width="12.3"
            height="12.3"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <line
            x1="160.27"
            y1="745.1"
            x2="172.58"
            y2="745.1"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <line
            x1="160.27"
            y1="750.96"
            x2="172.58"
            y2="750.96"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <line
            x1="166.43"
            y1="745.1"
            x2="166.43"
            y2="750.96"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <circle
            cx="162"
            cy="752.81"
            r="0.61"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <circle
            cx="170.61"
            cy="752.81"
            r="0.61"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <rect
            x="161.38"
            y="754.96"
            width="1.23"
            height="2.15"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
          <rect
            x="169.99"
            y="754.96"
            width="1.23"
            height="2.15"
            fill="none"
            stroke="#ff4d00"
            strokeMiterlimit="10"
            strokeWidth="0.62"
          />
        </g>
      </g>
      <g id="ac6518a8-61e7-49e8-8da1-47d688be91c5" data-name="OUTLINE">
        <polyline
          points="638.85 619.28 850.17 619.28 850.17 188.57 401.83 282.27 401.83 619.28 409.32 619.28"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="642.86 397.9 687.63 397.9 701.79 384.06 733.1 384.06 733.1 221.1 642.86 239.69 642.86 397.9"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="843.33 468.77 843.33 612.61 678.67 612.61 678.67 568.21 788.67 568.21 788.67 468.77 843.33 468.77"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b84fd8b5-0d94-4c3e-a423-cc8e9ee125fc" data-name="5.0">
        <rect
          x="733.1"
          y="468.15"
          width="54.9"
          height="45.13"
          fill="#fff"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a7df1c6a-efac-43cd-be3f-e29fcc04a685" data-name="5.1a">
        <polygon
          points="638.85 546.2 409.32 546.2 409.32 288.33 638.85 240.71 638.85 546.2"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect x="509.25" y="423.94" width="38" height="121.08" fill="#ededed" />
      </g>
      <g id="b1732e60-9116-4a61-a229-fe956daa6ca1" data-name="5.1b">
        <rect
          x="409.32"
          y="546.2"
          width="229.53"
          height="73.08"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect
          x="409.32"
          y="619.28"
          width="229.53"
          height="24.92"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bacd21dd-ffe5-4772-8a6a-b0e6ead0aab0" data-name="5.2">
        <polygon
          points="642.86 546.13 678.67 546.13 678.67 568.21 788.67 568.21 788.67 468.77 843.33 468.77 843.33 197.5 733.1 221.1 733.1 384.06 701.79 384.06 687.63 397.9 642.86 397.9 642.86 546.13"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect
          x="733.21"
          y="468.15"
          width="54.79"
          height="45.13"
          fill="#ededed"
        />
        <polyline
          points="733.1 384.06 733.1 513.28 788.67 513.28"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <line
          x1="788.67"
          y1="468.77"
          x2="733.1"
          y2="468.77"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M668.46,442.91h3.95v.73h-3v4.19h2.2v.73h-2.2v4.38h3v.73h-3.95Z"
          fill="#1e0f00"
        />
        <path
          d="M678,451.64a2.24,2.24,0,0,1-.51,1.56,2.06,2.06,0,0,1-1.63.56,2,2,0,0,1-1.58-.59,2.38,2.38,0,0,1-.51-1.6v-.88h.82v.92a1.63,1.63,0,0,0,.3,1,1.15,1.15,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.46,1.46,0,0,0,.3-1v-.29a1.56,1.56,0,0,0-.22-.85,3.79,3.79,0,0,0-.86-.86l-.92-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.26a2,2,0,0,1,.48-1.39,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.24,2.24,0,0,1,.49,1.57v.65h-.83v-.68a1.53,1.53,0,0,0-.28-1,1.21,1.21,0,0,0-1-.33,1.16,1.16,0,0,0-.9.31,1.26,1.26,0,0,0-.29.86v.26a1.68,1.68,0,0,0,.05.4,1,1,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73a8.72,8.72,0,0,1,.67.59,2.29,2.29,0,0,1,.44.53,1.89,1.89,0,0,1,.23.55,2.79,2.79,0,0,1,.07.68Z"
          fill="#1e0f00"
        />
        <path
          d="M683.9,450.56v.89a2.48,2.48,0,0,1-.54,1.72,2.07,2.07,0,0,1-1.63.59,2.1,2.1,0,0,1-1.69-.65,2.53,2.53,0,0,1-.58-1.76V447a2.54,2.54,0,0,1,.57-1.76,2.52,2.52,0,0,1,3.31,0,2.51,2.51,0,0,1,.56,1.74v2.23h-3.59v2.3a1.65,1.65,0,0,0,.37,1.16,1.32,1.32,0,0,0,1.06.41c.89,0,1.33-.5,1.33-1.5v-1Zm-3.59-2.08H683v-1.62a1.79,1.79,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.4,1.29,1.29,0,0,0-1,.4,1.69,1.69,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M688.88,446.74a1.72,1.72,0,0,0-.29-1.08,1.19,1.19,0,0,0-1-.35,1.36,1.36,0,0,0-1,.36,1.41,1.41,0,0,0-.38,1.08v6.92h-.88v-9h.88v.89h0a2,2,0,0,1,.59-.7,1.73,1.73,0,0,1,1.06-.28,1.69,1.69,0,0,1,1.33.51,2.31,2.31,0,0,1,.46,1.61v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M691,444.67h.7v-1.76h.87v1.76h1.25v.67h-1.25v6.86a.75.75,0,0,0,.17.57.81.81,0,0,0,.59.17h.49v.73h-.58a1.64,1.64,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84H691Z"
          fill="#1e0f00"
        />
        <path
          d="M698.59,453.67v-.81h0a1.82,1.82,0,0,1-.56.63,1.67,1.67,0,0,1-1,.27,1.85,1.85,0,0,1-1.39-.5,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.45,2.45,0,0,1,.6-.44c.23-.12.49-.25.78-.37a5.59,5.59,0,0,0,1.23-.67.74.74,0,0,0,.32-.59v-.61a2,2,0,0,0-.24-1.07,1,1,0,0,0-.9-.36,1.16,1.16,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.87h-.84v-.81a2.26,2.26,0,0,1,.51-1.56,2,2,0,0,1,1.6-.56,1.85,1.85,0,0,1,1.54.56,2.63,2.63,0,0,1,.44,1.66v6.87Zm-1.38-.64a1.28,1.28,0,0,0,1.38-1.42v-3.18a.37.37,0,0,1-.13.15l-.24.18c-.1.07-.23.14-.39.23l-.6.3-.55.3a1.74,1.74,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2,2,0,0,0-.08.59v.69a1.65,1.65,0,0,0,.29,1.07A1.11,1.11,0,0,0,697.21,453Z"
          fill="#1e0f00"
        />
        <path d="M701,442.91h.87v.79H701Zm0,1.76h.87v9H701Z" fill="#1e0f00" />
        <path
          d="M668.46,456.91h3.84v.73h-2.93v4.19h2.2v.73h-2.2v5.11h-.91Z"
          fill="#1e0f00"
        />
        <path
          d="M673.6,456.91h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M675.76,458.67h.7v-1.76h.87v1.76h1.25v.67h-1.25v6.86a.79.79,0,0,0,.17.57.83.83,0,0,0,.59.17h.49v.73H678a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path
          d="M682.41,462h2V459.7h.74V462h2v.73h-2V465h-.74V462.7h-2Z"
          fill="#1e0f00"
        />
        <path
          d="M693.36,467.76a2,2,0,0,1-2.3-2.19v-1.66h.87v1.69a1.45,1.45,0,0,0,.35,1,1.38,1.38,0,0,0,1.09.38,1.23,1.23,0,0,0,1-.41,1.6,1.6,0,0,0,.33-1V465a2.47,2.47,0,0,0-.06-.56,2.22,2.22,0,0,0-.18-.49,4,4,0,0,0-.33-.46l-.51-.52-1.1-1.07c-.25-.25-.47-.47-.65-.67a3.91,3.91,0,0,1-.45-.6,2.56,2.56,0,0,1-.27-.64,2.83,2.83,0,0,1-.09-.74v-.42a2,2,0,0,1,.57-1.49,2.22,2.22,0,0,1,1.62-.55,2.29,2.29,0,0,1,1.68.53,2.15,2.15,0,0,1,.53,1.58v1.17h-.86v-1.21a1.36,1.36,0,0,0-.33-1,1.39,1.39,0,0,0-1-.32,1.4,1.4,0,0,0-1,.32,1.27,1.27,0,0,0-.36,1v.39a1.62,1.62,0,0,0,.3,1,8.26,8.26,0,0,0,.86.95l1.09,1.08c.25.24.46.46.64.67a3,3,0,0,1,.44.61,2.16,2.16,0,0,1,.26.65,3,3,0,0,1,.09.77v.58a2.75,2.75,0,0,1-.13.88,1.77,1.77,0,0,1-.42.7,2,2,0,0,1-.72.47A2.57,2.57,0,0,1,693.36,467.76Z"
          fill="#1e0f00"
        />
        <path
          d="M697.1,458.67H698v.91h0a2.13,2.13,0,0,1,.59-.69,2.08,2.08,0,0,1,2.46.2,2.4,2.4,0,0,1,.47,1.63v4.9a2.24,2.24,0,0,1-.51,1.63,1.89,1.89,0,0,1-1.41.51,1.62,1.62,0,0,1-1.05-.29,1.71,1.71,0,0,1-.55-.66h0v2.29h-.87Zm3.54,2.08a1.62,1.62,0,0,0-.31-1.08,1.23,1.23,0,0,0-1-.36,1.27,1.27,0,0,0-1,.38,1.51,1.51,0,0,0-.42,1.06v4.89a1.43,1.43,0,0,0,.4,1,1.35,1.35,0,0,0,1,.35c.86,0,1.3-.48,1.3-1.44Z"
          fill="#1e0f00"
        />
        <path
          d="M706.38,467.67v-.81h0a1.9,1.9,0,0,1-.55.63,1.71,1.71,0,0,1-1,.27,1.85,1.85,0,0,1-1.4-.5,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.45,2.45,0,0,1,.6-.44c.23-.12.49-.25.78-.37a6,6,0,0,0,1.24-.67.76.76,0,0,0,.31-.59v-.61a2.07,2.07,0,0,0-.23-1.07,1,1,0,0,0-.91-.36,1.16,1.16,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.87h-.84v-.81a2.26,2.26,0,0,1,.51-1.56,2,2,0,0,1,1.6-.56,1.83,1.83,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87ZM705,467a1.28,1.28,0,0,0,1.38-1.42v-3.18a.37.37,0,0,1-.13.15l-.24.18c-.1.07-.23.14-.39.23l-.6.3-.55.3a1.74,1.74,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2,2,0,0,0-.08.59v.69a1.71,1.71,0,0,0,.29,1.07A1.11,1.11,0,0,0,705,467Z"
          fill="#1e0f00"
        />
        <path
          d="M774,327.1h3.95v.73h-3V332h2.2v.73h-2.2v4.38h3v.73H774Z"
          fill="#1e0f00"
        />
        <path
          d="M783.51,335.83a2.22,2.22,0,0,1-.51,1.56,2.06,2.06,0,0,1-1.63.56,2,2,0,0,1-1.58-.59,2.37,2.37,0,0,1-.51-1.59v-.89h.82v.92a1.63,1.63,0,0,0,.3,1,1.16,1.16,0,0,0,1,.39,1.26,1.26,0,0,0,1-.35,1.46,1.46,0,0,0,.3-1v-.29a1.56,1.56,0,0,0-.22-.85,3.79,3.79,0,0,0-.86-.86l-.92-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.26a2,2,0,0,1,.48-1.39,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.24,2.24,0,0,1,.49,1.57v.65h-.83v-.68a1.53,1.53,0,0,0-.28-1,1.21,1.21,0,0,0-1-.33,1.12,1.12,0,0,0-.9.32,1.22,1.22,0,0,0-.29.85v.26a1.68,1.68,0,0,0,.05.4,1,1,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73a8.72,8.72,0,0,1,.67.59,2.29,2.29,0,0,1,.44.53,1.89,1.89,0,0,1,.23.55,2.79,2.79,0,0,1,.07.68Z"
          fill="#1e0f00"
        />
        <path
          d="M789.4,334.75v.89a2.48,2.48,0,0,1-.54,1.72,2.53,2.53,0,0,1-3.32-.06,2.53,2.53,0,0,1-.58-1.76v-4.38a2.55,2.55,0,0,1,.57-1.76,2.52,2.52,0,0,1,3.31,0,2.51,2.51,0,0,1,.56,1.74v2.23h-3.59v2.3a1.65,1.65,0,0,0,.37,1.16,1.33,1.33,0,0,0,1.06.42c.89,0,1.33-.51,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.62a1.79,1.79,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.4,1.29,1.29,0,0,0-1,.4,1.69,1.69,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M794.38,330.93a1.67,1.67,0,0,0-.29-1.07,1.16,1.16,0,0,0-1-.36,1.36,1.36,0,0,0-1,.36,1.43,1.43,0,0,0-.39,1.08v6.92h-.87v-9h.87v.89h0a2,2,0,0,1,.59-.7,1.73,1.73,0,0,1,1.06-.28,1.69,1.69,0,0,1,1.33.51,2.31,2.31,0,0,1,.46,1.61v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M796.51,328.86h.7V327.1h.87v1.76h1.25v.67h-1.25v6.86a.75.75,0,0,0,.17.57.81.81,0,0,0,.59.17h.49v.73h-.58a1.64,1.64,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path
          d="M804.09,337.86v-.81h0a1.82,1.82,0,0,1-.56.63,1.67,1.67,0,0,1-1,.27,1.81,1.81,0,0,1-1.39-.5,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.45,2.45,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5.59,5.59,0,0,0,1.23-.67.74.74,0,0,0,.32-.59v-.61a2,2,0,0,0-.24-1.07,1,1,0,0,0-.9-.36,1.2,1.2,0,0,0-1,.34,1.52,1.52,0,0,0-.29,1v.87h-.84v-.81a2.26,2.26,0,0,1,.51-1.56,2.07,2.07,0,0,1,1.6-.56,1.88,1.88,0,0,1,1.54.56A2.63,2.63,0,0,1,805,331v6.87Zm-1.38-.63a1.3,1.3,0,0,0,1.38-1.43v-3.18a.65.65,0,0,1-.13.16l-.24.17c-.1.07-.23.14-.39.23l-.6.3-.55.3a1.74,1.74,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2,2,0,0,0-.08.59v.69a1.65,1.65,0,0,0,.29,1.07A1.12,1.12,0,0,0,802.71,337.23Z"
          fill="#1e0f00"
        />
        <path
          d="M806.47,327.1h.87v.8h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M774,341.1h3.84v.73h-2.93V346h2.2v.73h-2.2v5.11H774Z"
          fill="#1e0f00"
        />
        <path d="M779.1,341.1H780v.8h-.87Zm0,1.76H780v9h-.87Z" fill="#1e0f00" />
        <path
          d="M781.26,342.86h.7V341.1h.87v1.76h1.25v.67h-1.25v6.86a.79.79,0,0,0,.17.57.83.83,0,0,0,.59.17h.49v.73h-.58a1.66,1.66,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path
          d="M787.91,346.16h2v-2.27h.74v2.27h2v.73h-2v2.26h-.74v-2.26h-2Z"
          fill="#1e0f00"
        />
        <path
          d="M798.86,352a2,2,0,0,1-2.31-2.18V348.1h.88v1.69a1.45,1.45,0,0,0,.35,1,1.38,1.38,0,0,0,1.09.39,1.23,1.23,0,0,0,1-.42,1.58,1.58,0,0,0,.33-1v-.57a2.47,2.47,0,0,0-.06-.56,2.22,2.22,0,0,0-.18-.49,4,4,0,0,0-.33-.46q-.21-.22-.51-.51l-1.1-1.08c-.25-.25-.47-.47-.65-.67a3.91,3.91,0,0,1-.45-.6,2.56,2.56,0,0,1-.27-.64,2.83,2.83,0,0,1-.09-.74v-.42a2,2,0,0,1,.57-1.49,2.22,2.22,0,0,1,1.62-.55,2.29,2.29,0,0,1,1.68.53,2.15,2.15,0,0,1,.53,1.58v1.17h-.86v-1.21a1.36,1.36,0,0,0-.33-1,1.39,1.39,0,0,0-1-.32,1.45,1.45,0,0,0-1,.32,1.27,1.27,0,0,0-.36,1v.39a1.62,1.62,0,0,0,.3,1,8.26,8.26,0,0,0,.86.95l1.09,1.08c.25.24.46.46.64.67a3.47,3.47,0,0,1,.44.61,2.16,2.16,0,0,1,.26.65,3,3,0,0,1,.09.77v.58a2.75,2.75,0,0,1-.13.88,1.68,1.68,0,0,1-.43.7,2,2,0,0,1-.71.47A2.57,2.57,0,0,1,798.86,352Z"
          fill="#1e0f00"
        />
        <path
          d="M802.6,342.86h.87v.91h0a2.13,2.13,0,0,1,.59-.69,2.08,2.08,0,0,1,2.46.2,2.4,2.4,0,0,1,.47,1.63v4.9a2.24,2.24,0,0,1-.51,1.63,1.89,1.89,0,0,1-1.41.51,1.62,1.62,0,0,1-1.05-.29,1.71,1.71,0,0,1-.55-.66h0v2.29h-.87Zm3.54,2.08a1.64,1.64,0,0,0-.31-1.08,1.23,1.23,0,0,0-1-.36,1.27,1.27,0,0,0-1,.38,1.51,1.51,0,0,0-.42,1.06v4.89a1.43,1.43,0,0,0,.4,1,1.35,1.35,0,0,0,1,.36c.86,0,1.3-.49,1.3-1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M811.88,351.86v-.81h0a1.9,1.9,0,0,1-.55.63,1.71,1.71,0,0,1-1,.27,1.82,1.82,0,0,1-1.4-.5,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.45,2.45,0,0,1,.6-.44c.23-.12.49-.24.78-.37a6,6,0,0,0,1.24-.67.76.76,0,0,0,.31-.59v-.61a2,2,0,0,0-.24-1.07,1,1,0,0,0-.9-.36,1.16,1.16,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.87h-.84v-.81a2.26,2.26,0,0,1,.51-1.56,2.07,2.07,0,0,1,1.6-.56,1.88,1.88,0,0,1,1.54.56,2.63,2.63,0,0,1,.44,1.66v6.87Zm-1.38-.63a1.3,1.3,0,0,0,1.38-1.43v-3.18a.48.48,0,0,1-.13.16l-.24.17c-.1.07-.23.14-.39.23l-.6.3-.55.3a1.74,1.74,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2,2,0,0,0-.08.59v.69a1.71,1.71,0,0,0,.29,1.07A1.12,1.12,0,0,0,810.5,351.23Z"
          fill="#1e0f00"
        />
        <path
          d="M510.62,368.76v5.39h-.91V363.39h.91v5.06l2.85-5.06h.94l-2.91,5.13,2.92,5.63h-.95Z"
          fill="#1e0f00"
        />
        <path
          d="M515.6,363.39h.87v.8h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M521.48,367.22a1.61,1.61,0,0,0-.29-1.07,1.15,1.15,0,0,0-.94-.36,1.36,1.36,0,0,0-1,.36,1.43,1.43,0,0,0-.39,1.08v6.92H518v-9h.88V366h0a2.08,2.08,0,0,1,.59-.7,1.73,1.73,0,0,1,1.06-.28,1.71,1.71,0,0,1,1.34.51,2.38,2.38,0,0,1,.46,1.61v7h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M528.32,371.83a2.57,2.57,0,0,1-.57,1.76,2.07,2.07,0,0,1-1.66.66,2.09,2.09,0,0,1-1.67-.66,2.57,2.57,0,0,1-.57-1.76v-4.36a2.58,2.58,0,0,1,.57-1.77,2.11,2.11,0,0,1,1.67-.64,2.09,2.09,0,0,1,1.66.64,2.58,2.58,0,0,1,.57,1.77Zm-2.23,1.69a1.28,1.28,0,0,0,1-.42,1.74,1.74,0,0,0,.35-1.15v-4.59a1.76,1.76,0,0,0-.35-1.16,1.5,1.5,0,0,0-2.06,0,1.71,1.71,0,0,0-.35,1.16V372a1.68,1.68,0,0,0,.35,1.15A1.28,1.28,0,0,0,526.09,373.52Z"
          fill="#1e0f00"
        />
        <path
          d="M529.85,365.15h.87v.91h0a2.17,2.17,0,0,1,.6-.69,1.71,1.71,0,0,1,1.1-.31,1.76,1.76,0,0,1,1.36.51,2.4,2.4,0,0,1,.47,1.63v4.9a2.24,2.24,0,0,1-.51,1.63,1.91,1.91,0,0,1-1.41.52,1.65,1.65,0,0,1-1.06-.3,1.8,1.8,0,0,1-.55-.66h0v2.29h-.87Zm3.53,2.08a1.69,1.69,0,0,0-.3-1.08,1.24,1.24,0,0,0-1-.36,1.34,1.34,0,0,0-1,.38,1.55,1.55,0,0,0-.41,1.06v4.89a1.39,1.39,0,0,0,.4,1,1.33,1.33,0,0,0,1,.36c.87,0,1.3-.49,1.3-1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M539.13,374.15v-.81h0a1.82,1.82,0,0,1-.56.63,1.69,1.69,0,0,1-1,.28,1.8,1.8,0,0,1-1.39-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.51,2.51,0,0,1,.13-.88,1.68,1.68,0,0,1,.39-.61,2.33,2.33,0,0,1,.61-.44l.77-.37a5.48,5.48,0,0,0,1.24-.67.77.77,0,0,0,.32-.58v-.62a2,2,0,0,0-.24-1.07,1,1,0,0,0-.9-.36,1.18,1.18,0,0,0-1,.34,1.52,1.52,0,0,0-.29,1V368h-.84v-.81a2.26,2.26,0,0,1,.51-1.56,2.06,2.06,0,0,1,1.6-.56,1.88,1.88,0,0,1,1.54.56,2.69,2.69,0,0,1,.44,1.66v6.87Zm-1.38-.63a1.3,1.3,0,0,0,1.38-1.43v-3.18a.52.52,0,0,1-.14.16l-.23.17-.39.23-.61.3-.54.3a1.53,1.53,0,0,0-.38.32,1.08,1.08,0,0,0-.23.42,1.74,1.74,0,0,0-.08.6v.68a1.71,1.71,0,0,0,.28,1.07A1.12,1.12,0,0,0,537.75,373.52Z"
          fill="#1e0f00"
        />
        <path
          d="M544,366.06h-.14a1.45,1.45,0,0,0-1.05.36,1.35,1.35,0,0,0-.4,1v6.7h-.87v-9h.87v1.11h0a3.19,3.19,0,0,1,.21-.41,1.58,1.58,0,0,1,.32-.37,1.48,1.48,0,0,1,.44-.27,1.55,1.55,0,0,1,.59-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M548.57,374.15l-2.38-4.59v4.59h-.87V363.39h.87v6l2.33-4.24h.89l-2.35,4.24,2.45,4.76Z"
          fill="#1e0f00"
        />
        <path
          d="M442.71,575h3.84v.73h-2.93v4.19h2.2v.73h-2.2v5.11h-.91Z"
          fill="#1e0f00"
        />
        <path
          d="M452.26,582.67v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.16,2.16,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.52,2.52,0,0,1,3.32,0,2.51,2.51,0,0,1,.55,1.74v2.24h-3.58v2.3a1.73,1.73,0,0,0,.36,1.16,1.38,1.38,0,0,0,1.07.41c.88,0,1.33-.5,1.33-1.51v-1Zm-3.58-2.08h2.73V579a1.7,1.7,0,0,0-.34-1.15,1.2,1.2,0,0,0-1-.41,1.27,1.27,0,0,0-1,.41,1.7,1.7,0,0,0-.35,1.15Z"
          fill="#1e0f00"
        />
        <path d="M453.79,575h.87v10.76h-.87Z" fill="#1e0f00" />
        <path d="M456.21,575h.88v10.76h-.88Z" fill="#1e0f00" />
        <path d="M458.64,575h.87v.8h-.87Zm0,1.77h.87v9h-.87Z" fill="#1e0f00" />
        <path
          d="M464.53,578.85a1.63,1.63,0,0,0-.3-1.07,1.14,1.14,0,0,0-.94-.36,1.33,1.33,0,0,0-1,.37,1.39,1.39,0,0,0-.39,1.07v6.92h-.87v-9h.87v.88h0a1.92,1.92,0,0,1,.58-.69,1.77,1.77,0,0,1,1.07-.29,1.7,1.7,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path d="M466.92,575h.88v.8h-.88Zm0,1.77h.88v9h-.88Z" fill="#1e0f00" />
        <path
          d="M442.71,626.34h3.84v.73h-2.93v4.19h2.2V632h-2.2v5.11h-.91Z"
          fill="#1e0f00"
        />
        <path
          d="M452.26,634v.89a2.48,2.48,0,0,1-.54,1.72,2.07,2.07,0,0,1-1.63.59,2.13,2.13,0,0,1-1.69-.65,2.53,2.53,0,0,1-.58-1.76V630.4a2.55,2.55,0,0,1,.57-1.76,2.14,2.14,0,0,1,1.67-.63,2.11,2.11,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.23h-3.58v2.3a1.7,1.7,0,0,0,.36,1.16,1.35,1.35,0,0,0,1.07.42c.88,0,1.33-.51,1.33-1.51v-1Zm-3.58-2.08h2.73v-1.62a1.73,1.73,0,0,0-.34-1.15,1.23,1.23,0,0,0-1-.4,1.3,1.3,0,0,0-1,.4,1.74,1.74,0,0,0-.35,1.15Z"
          fill="#1e0f00"
        />
        <path d="M453.79,626.34h.87V637.1h-.87Z" fill="#1e0f00" />
        <path d="M456.21,626.34h.88V637.1h-.88Z" fill="#1e0f00" />
        <path
          d="M458.64,626.34h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M464.53,630.17a1.66,1.66,0,0,0-.3-1.08,1.16,1.16,0,0,0-.94-.35,1.36,1.36,0,0,0-1,.36,1.43,1.43,0,0,0-.39,1.08v6.92h-.87v-9h.87V629h0a2,2,0,0,1,.58-.7,1.77,1.77,0,0,1,1.07-.28,1.7,1.7,0,0,1,1.33.51,2.31,2.31,0,0,1,.46,1.61v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M466.92,626.34h.88v.79h-.88Zm0,1.76h.88v9h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M471.59,628.1h.7v-1.76h.87v1.76h1.25v.67h-1.25v6.86a.79.79,0,0,0,.17.57.83.83,0,0,0,.59.17h.49v.73h-.58a1.66,1.66,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path
          d="M480.24,634v.89a2.48,2.48,0,0,1-.54,1.72,2.1,2.1,0,0,1-1.64.59,2.13,2.13,0,0,1-1.69-.65,2.57,2.57,0,0,1-.58-1.76V630.4a2.55,2.55,0,0,1,.57-1.76A2.16,2.16,0,0,1,478,628a2.11,2.11,0,0,1,1.65.61,2.51,2.51,0,0,1,.56,1.74v2.23h-3.59v2.3a1.65,1.65,0,0,0,.37,1.16,1.31,1.31,0,0,0,1.06.42c.89,0,1.33-.51,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.62a1.79,1.79,0,0,0-.33-1.15,1.25,1.25,0,0,0-1-.4,1.29,1.29,0,0,0-1,.4,1.69,1.69,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M484.22,629h-.14a1.5,1.5,0,0,0-1.05.35,1.39,1.39,0,0,0-.4,1v6.7h-.87v-9h.87v1.11h0c.06-.13.13-.27.21-.41a1.4,1.4,0,0,1,.32-.37,1.48,1.48,0,0,1,.44-.27,1.55,1.55,0,0,1,.59-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M488,629h-.15a1.49,1.49,0,0,0-1,.35,1.39,1.39,0,0,0-.4,1v6.7h-.87v-9h.87v1.11h0a2.28,2.28,0,0,1,.21-.41,1.4,1.4,0,0,1,.32-.37,1.48,1.48,0,0,1,.44-.27,1.52,1.52,0,0,1,.59-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M492.72,637.1v-.81h0a1.79,1.79,0,0,1-.55.63,1.71,1.71,0,0,1-1,.27,1.82,1.82,0,0,1-1.4-.5,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.45,2.45,0,0,1,.6-.44c.23-.12.49-.24.78-.37a6,6,0,0,0,1.24-.67.76.76,0,0,0,.31-.59v-.61a2.07,2.07,0,0,0-.23-1.07,1,1,0,0,0-.91-.36,1.16,1.16,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.87h-.84v-.81a2.26,2.26,0,0,1,.51-1.56,2.07,2.07,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.37,1.37,0,0,0,1-.36,1.39,1.39,0,0,0,.39-1.07v-3.18a.37.37,0,0,1-.13.15l-.24.18c-.1.07-.23.14-.39.23l-.6.3-.55.3a1.74,1.74,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2,2,0,0,0-.08.59V635a1.71,1.71,0,0,0,.29,1.07A1.12,1.12,0,0,0,491.34,636.47Z"
          fill="#1e0f00"
        />
        <path
          d="M499.45,634v.89a2.58,2.58,0,0,1-.51,1.72,2,2,0,0,1-1.61.59,2.1,2.1,0,0,1-1.69-.65,2.57,2.57,0,0,1-.57-1.76V630.4a2.55,2.55,0,0,1,.57-1.76,2.14,2.14,0,0,1,1.67-.63,2.07,2.07,0,0,1,1.62.58,2.44,2.44,0,0,1,.52,1.7v.65h-.86v-.71a1.76,1.76,0,0,0-.3-1.12,1.18,1.18,0,0,0-1-.37,1.26,1.26,0,0,0-1,.4,1.69,1.69,0,0,0-.36,1.15v4.6a1.7,1.7,0,0,0,.36,1.16,1.27,1.27,0,0,0,1,.42,1.19,1.19,0,0,0,1-.38,1.77,1.77,0,0,0,.3-1.13v-1Z"
          fill="#1e0f00"
        />
        <path
          d="M505.29,634v.89a2.48,2.48,0,0,1-.54,1.72,2.07,2.07,0,0,1-1.63.59,2.12,2.12,0,0,1-1.69-.65,2.53,2.53,0,0,1-.58-1.76V630.4a2.55,2.55,0,0,1,.57-1.76,2.52,2.52,0,0,1,3.31,0,2.51,2.51,0,0,1,.56,1.74v2.23H501.7v2.3a1.7,1.7,0,0,0,.37,1.16,1.33,1.33,0,0,0,1.06.42c.89,0,1.33-.51,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.62a1.79,1.79,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.4,1.29,1.29,0,0,0-1,.4,1.69,1.69,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <circle
          cx="559.31"
          cy="565.19"
          r="1.64"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="557.62 579.28 557.62 568.65 561 568.65 561 579.28"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="552.17 555.6 555.37 552.4 555.46 552.4 558.65 555.6"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="566.45 552.4 563.26 555.6 563.16 555.6 559.97 552.4"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="551.65"
          y="559.4"
          width="15.32"
          height="20"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
      </g>
      <g id="abc8c9d4-fed1-4eaa-bc5b-b1139c20f4cc" data-name="Icons">
        <polyline
          points="541.23 436.22 535.99 436.22 535.99 441.47 530.74 441.47 530.74 446.71 525.5 446.71 525.5 451.95 520.26 451.95 520.26 457.2 515.14 457.2"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <circle
          cx="746.83"
          cy="488.98"
          r="1.68"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="745.1 503.4 745.1 492.52 748.55 492.52 748.55 503.4"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="739.52 479.17 742.79 475.9 742.89 475.9 746.15 479.17"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="754.13 475.9 750.86 479.17 750.76 479.17 747.5 475.9"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="738.99"
          y="483.06"
          width="15.68"
          height="20.46"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  </MapTransformer>
);

FifthFloor.defaultProps = {
  mapWidth: 1420.16,
  mapHeight: 1003,
};

FifthFloor.propTypes = {
  mapHeight: PropTypes.number,
  mapWidth: PropTypes.number,
  MapTransformer: PropTypes.func.isRequired,
};

export default FifthFloor;
