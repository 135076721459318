import { RESOURCE_NAME } from '@esentai/core/features/guestParkings/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { GUEST_PARKING } from './consts/permissions';

export const canGuestParking = authorizeIf(
  can(permission(RESOURCE_NAME, GUEST_PARKING)),
);
