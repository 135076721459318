import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from './icon.svg';
import styles from './styles';

const Icon = ({ className, classes, color, ...props }) => (
  <SvgIcon className={classNames(className, classes[color])} {...props} />
);

Icon.defaultProps = {
  className: '',
  color: 'white',
};

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['white', 'black']),
};

export default withStyles(styles, { withTheme: true })(Icon);
