import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import { createStorePageUrl } from '@/features/salesTurnoverDetailsPage/utils';
import { formatMoney, formatMoneyUsd } from '@/utils/format';

const Row = ({
  classes,
  storeName,
  isActive,
  amountKzt,
  amountUsd,
  salesTurnoverId,
  merchantName,
}) => (
  <TableRow className={!isActive ? classes.dimmed : ''}>
    <TableCell align={'left'}>
      <Link to={createStorePageUrl(salesTurnoverId)}>{storeName}</Link>
    </TableCell>
    <TableCell align={'center'}>{merchantName}</TableCell>
    <TableCell align={'center'}>{formatMoney(amountKzt)}</TableCell>
    <TableCell align={'right'}>{formatMoneyUsd(amountUsd)}</TableCell>
  </TableRow>
);

Row.propTypes = {
  amountUsd: PropTypes.number.isRequired,
  amountKzt: PropTypes.number.isRequired,
  salesTurnoverId: PropTypes.number.isRequired,
  storeName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  merchantName: PropTypes.string.isRequired,
};

export default Row;
