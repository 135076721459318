import { RESOURCE_NAME } from '@esentai/core/features/mallBonuses/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadMallBonuses = authorizeIf(canRead(RESOURCE_NAME));
export const canReadMallBonus = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateMallBonus = authorizeIf(canCreate(RESOURCE_NAME));
export const canUpdateMallBonus = authorizeIf(canUpdate(RESOURCE_NAME));

// TODO set permission for campaign statistic
export const canReadMallBonusesStatistics = () => true;
