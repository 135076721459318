import { RESOURCE_NAME } from '@esentai/core/features/benefits/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { GIVE_OUT } from './consts/permissions';

export const canGiveOutBenefit = authorizeIf(
  can(permission(RESOURCE_NAME, GIVE_OUT)),
);
