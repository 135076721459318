export default theme => ({
  dimmed: {
    backgroundColor: '#F5F5F5',
  },

  suspiciousIcon: {
    opacity: 0.3,
  },

  secondaryColumn: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
});
