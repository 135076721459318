import api from '@esentai/core/features/productCategories/api';
import { NAME_RU_KEY } from '@esentai/core/features/productCategories/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import ProductCategoryChip from './ProductCategoryChip';
import ProductCategoryOption from './ProductCategoryOption';

const buildQuery = queryString => query(where(NAME_RU_KEY, ilike(queryString)));

const withProductCategoryProps = defaultProps({
  api,
  buildQuery,
  Chip: ProductCategoryChip,
  Option: ProductCategoryOption,
});

export const ProductCategoryAutocomplete = withProductCategoryProps(
  AutocompleteAPI,
);

export const ProductCategoryMultiAutocomplete = withProductCategoryProps(
  AutocompleteAPIMultiple,
);
