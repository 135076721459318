import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import Link from '@/components/Link';
import { createBrandPageUrl } from '@/features/brandPage/utils';

import Actions from './Actions';
import Stores from './Stores';

const Row = ({ itemId, name }) => (
  <TableRow>
    <CondenseTableCell>
      <Link to={createBrandPageUrl(itemId)}>{name}</Link>
    </CondenseTableCell>
    <CondenseTableCell>
      <Stores itemId={itemId} />
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <Actions itemId={itemId} />
    </CondenseTableCell>
  </TableRow>
);

Row.propTypes = {
  itemId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default Row;
