export default {
  root: {
    textAlign: 'center',
  },

  title: {
    marginBottom: 5,
  },

  icon: {
    marginBottom: 10,
  },
};
