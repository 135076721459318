import { path } from 'ramda';

import { PAGES_STORE_KEY } from '@/redux/constants';

import { FEATURE_NAME } from './consts';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getCurrentQuery = path([
  PAGES_STORE_KEY,
  FEATURE_NAME,
  'query',
  'current',
]);

export const getPreviousQuery = path([
  PAGES_STORE_KEY,
  FEATURE_NAME,
  'query',
  'previous',
]);
