import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import ProductCategoryNameLazy from '@/containers/ProductCategoryNameLazy';
import { getCuttedText } from '@/utils/format';

const ProductCategoryChip = ({ value, ...props }) => (
  <Chip
    {...props}
    id={value}
    label={
      <ProductCategoryNameLazy
        productCategoryId={value}
        onCutText={getCuttedText}
        maxLength={40}
      />
    }
  />
);

ProductCategoryChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default ProductCategoryChip;
