import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddCarForm from './Form';

class AddCarModal extends Component {
  static defaultProps = {
    currentCarDetails: null,
  };

  render() {
    const { isOpen, onClose, errorMessage } = this.props;

    const errorText = errorMessage && errorMessage.errorMessage;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">Добавить авто</DialogTitle>

        <DialogContent>
          <AddCarForm
            onClose={onClose}
            onSubmit={this.props.onSubmit.bind(this)}
          />
          {errorMessage !== null && (
            <Typography variant="caption">{errorText}</Typography>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

AddCarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.object.isRequired,
};

export default AddCarModal;
