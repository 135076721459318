import { path } from 'ramda';

import api from '@/api';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

import { FEATURE_NAME, ROUTE_PATH } from './consts';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const createStorePageUrl = storeId =>
  `${ROUTE_PATH.replace(':storeId', storeId)}`;

export const downloadXls = itemId => {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.delete('limit');
  urlParams.delete('offset');
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'issued_at');
  }

  api
    .doBlobGet(
      `/sale-xlsx-export/?company[ilike]=${itemId}&${decodeURIComponent(
        urlParams.toString(),
      )}`,
      null,
      'v1',
    )
    .then(response => {
      saveAs(response.data, `Обороты по магазину.xlsx`);
    });
};
