import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import LocationNameLazy from '@/containers/LocationNameLazy';

const LocationChip = ({ value, ...props }) => (
  <Chip {...props} id={value} label={<LocationNameLazy locationId={value} />} />
);

LocationChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default LocationChip;
