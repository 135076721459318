import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';

import Label from '../Label';
import { Bin } from './Bin/Bin';

const BinList = ({ merchantIds }) => (
  <FieldRow name="Список БИН/ИИН">
    <Grid container spacing={16}>
      {merchantIds &&
        merchantIds.map(merchId => (
          <Grid item key={merchId}>
            <Label>
              <Bin merchId={merchId} />
            </Label>
          </Grid>
        ))}
    </Grid>
  </FieldRow>
);

BinList.defaultProps = {
  merchantIds: [],
};

BinList.propTypes = {
  merchantIds: PropTypes.arrayOf(PropTypes.any),
};

export default BinList;
