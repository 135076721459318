import {
  getCampaignDateTimeEnd,
  getCampaignDateTimeStart,
  getCampaignLocationIds,
  getCampaignStatus,
  getCampaignTitle,
} from '@esentai/core/features/campaigns/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepTwo, remove } from '@/features/campaignCreatePage/actions';
import {
  getCampaignId,
  getCampaignType,
  getEditableAttributes,
} from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import FirstStep from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getCampaignId(state);
  const type = getCampaignType(state);

  return {
    currentStatus: getCampaignStatus(state, id),
    dateStart: getCampaignDateTimeStart(state, id),
    dateEnd: getCampaignDateTimeEnd(state, id),
    locations: getCampaignLocationIds(state, id),
    title: getCampaignTitle(state, id),
    getEditableAttributes: getEditableAttributes(state),
    type,
    id,
  };
};

const mapDispatchToProps = (dispatch, { type }) => ({
  onSubmit: attributes => dispatch(goToStepTwo({ type, ...attributes })),
  remove: () => dispatch(remove()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(FirstStep);
