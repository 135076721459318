export default theme => ({
  wrap: {
    width: '100%',
    maxWidth: 650,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 700,
  },
  imageControl: {
    display: 'flex',
  },
  hidden: {
    display: 'none',
  },
  checkbox: {
    width: 40,
  },
});
