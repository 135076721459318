import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { deauthorize } from '@/features/auth/actions';
import { getUserRole } from '@/features/benefitGiveOutPage/selectors';
import { getName } from '@/features/currentUser/selectors';

import UserMenu from './component';
import styles from './styles';

const mapStateToProps = state => ({
  name: getName(state),
  role: getUserRole(state),
});

const mapDispatchToProps = { logOut: deauthorize };

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserMenu);
