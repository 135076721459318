import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import { createAction } from 'redux-actions';

import query from './ducks/query';
import { ACTIVATE_STORES, DISABLE_STORES, RETRY_FETCH } from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const retryFetch = createAction(RETRY_FETCH);

export const activateStores = createTaskAction(ACTIVATE_STORES);

export const disableStores = createTaskAction(DISABLE_STORES);
