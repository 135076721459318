import {
  ACTION_RESULT_TYPE,
  ARTICLE_TYPE,
  BANNER_TYPE,
  CALENDAR_EVENT_TYPE,
  NOTIFICATION_TYPE,
  PURCHASE_RESULT_TYPE,
  SHOW_TYPE,
} from '@esentai/core/features/campaigns/consts/types';

export const campaignsWithPush = [
  ACTION_RESULT_TYPE,
  NOTIFICATION_TYPE,
  SHOW_TYPE,
];
export const campaignsWithPrePush = [PURCHASE_RESULT_TYPE];
export const campaignsWithActivation = [
  PURCHASE_RESULT_TYPE,
  ACTION_RESULT_TYPE,
];
export const campaignsWithViews = [
  BANNER_TYPE,
  ARTICLE_TYPE,
  CALENDAR_EVENT_TYPE,
];
