import { IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import BeaconInfo from './BeaconInfo';
import LocationInfo from './LocationInfo';

const InfoBlock = ({ classes, locationId, beaconId, clearSelection }) =>
  locationId || beaconId ? (
    <div className={classes.infoBlock}>
      <Typography className={classes.name}>
        <BeaconInfo beaconId={beaconId} />
        <LocationInfo locationId={locationId} />
      </Typography>
      <IconButton
        disableRipple
        className={classes.closeButton}
        onClick={clearSelection}
      >
        <CloseIcon />
      </IconButton>
    </div>
  ) : null;

InfoBlock.propTypes = {
  beaconId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  clearSelection: PropTypes.func.isRequired,
};

export default InfoBlock;
