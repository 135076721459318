import { RESOURCE_NAME } from '@esentai/core/features/users/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { EDIT_LOYALTY_DATA, VIEW_PERSONAL_DATA } from './consts/permissions';

export const canReadPersonalData = authorizeIf(
  can(permission(RESOURCE_NAME, VIEW_PERSONAL_DATA)),
);

export const canUpdateLoyaltyData = authorizeIf(
  can(permission(RESOURCE_NAME, EDIT_LOYALTY_DATA)),
);
