import {
  getCampaignLoadingError,
  isCampaignEditable,
  isCampaignLoaded,
  isLoadingCampaign,
} from '@esentai/core/features/campaigns/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchItem } from '@/features/campaignCreatePage/actions';
import {
  getCampaignId,
  getCampaignType,
} from '@/features/campaignCreatePage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import EditCampaign from './component';

const mapStateToProps = state => {
  const itemId = getCampaignId(state);
  const type = getCampaignType(state);

  return {
    error: getCampaignLoadingError(state, itemId),
    isEditable: isCampaignEditable(state, itemId),
    isLoaded: isCampaignLoaded(state, itemId),
    isLoading: isLoadingCampaign(state, itemId),
    itemId,
    type,
  };
};

const mapDispatchToProps = { fetchItem };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(EditCampaign);
