import CoreZoomable from '@esentai/core/components/Zoomable';
import { NATIVE_SCALE } from '@esentai/core/components/Zoomable/consts';
import { Fab } from '@material-ui/core';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Zoomable extends Component {
  static propTypes = {
    renderContent: PropTypes.func.isRequired,
  };

  render() {
    return <CoreZoomable {...this.props} renderContent={this.renderContent} />;
  }

  renderContent = ({ scaleFactor, toggleZoom, ...rest }) => {
    const { classes, renderContent: Content } = this.props;
    const Icon = scaleFactor === NATIVE_SCALE ? ZoomIn : ZoomOut;

    return (
      <div className={classes.root}>
        <Content {...rest} />
        <Fab className={classes.zoomButton} onClick={toggleZoom}>
          <Icon />
        </Fab>
      </div>
    );
  };
}

export default Zoomable;
