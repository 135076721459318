import {
  FACTORY_NUMBER_KEY,
  KKM_NUMBER_KEY,
  MERCHANT_ID_KEY,
  STATUS_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/kkms/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { number, object, string } from 'yup';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import styles from './styles';

const errorsMapping = {
  'data-error': {
    field: KKM_NUMBER_KEY,
    message: 'ККМ с данным номером уже существует',
  },
};

const formikOptions = {
  mapPropsToValues: ({ kkmNumber, kkmStatus, store, merchant, code }) => ({
    [KKM_NUMBER_KEY]: kkmNumber,
    [STORE_ID_KEY]: store,
    [MERCHANT_ID_KEY]: merchant,
    [STATUS_KEY]: kkmStatus,
    [FACTORY_NUMBER_KEY]: code,
  }),

  validationSchema: object().shape({
    [KKM_NUMBER_KEY]: string()
      // .matches(
      //   /^[^-\s][a-zA-Z0-9_\s-]+$/g,
      //   'Номер ККМ не может начинаться с пробела',
      // )
      .max(30)
      .required(),
    [MERCHANT_ID_KEY]: number(),
    [STORE_ID_KEY]: string().required(),
    [FACTORY_NUMBER_KEY]: string().max(9),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
