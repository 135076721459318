import { Button, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import DateTimePicker from '@/components/DateTimePicker';
import Modal from '@/components/Modal';
import { EXIT_DATETIME } from '@/features/parkingPage/consts/keys';

const SimulateFinish = props => {
  const { classes, onClose, onSubmit, Field, Form, values, isValid } = props;

  return (
    <Modal {...props} onClose={onClose}>
      <Paper className={classes.wrapper}>
        <Typography variant="h4" gutterBottom>
          Сформировать долг
        </Typography>

        <Form>
          <div>
            <Field
              margin="normal"
              Target={DateTimePicker}
              name={EXIT_DATETIME}
              label="Дата и время выезда"
              className={classes.datepicker}
            />
          </div>
        </Form>

        <div className={classes.footer}>
          <Button onClick={onClose}>Отмена</Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            disabled={!isValid}
            onClick={() => onSubmit(values)}
          >
            Сформировать
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

SimulateFinish.propTypes = {
  onClose: PropTypes.func.isRequired,
  Field: PropTypes.func.isRequired,
  Form: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
};

SimulateFinish.defaultProps = {
  values: {},
};

export default SimulateFinish;
