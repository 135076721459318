export default {
  root: {
    marginRight: 0,
  },

  radioBox: {
    display: 'flex',
    alignItems: 'center',
    padding: [0, 16],
    width: 160,
    height: 70,
    marginBottom: 8,
    backgroundColor: 'white',
    boxShadow: [0, 4, 10, '#bbb'],
    cursor: 'pointer',
  },

  body: {
    flexGrow: 1,
  },

  content: {
    display: 'flex',
    alignItems: 'center',
  },

  link: {
    marginLeft: 'auto',
  },

  icon: {
    width: 36,
    height: 36,
    color: '#BDBDBD',
  },
};
