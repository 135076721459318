import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Link from '@/components/Link';

class Option extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    option: PropTypes.any,
  };

  static defaultProps = {
    option: null,
  };

  onClick = clickEvent => {
    clickEvent.preventDefault();

    this.props.onClick(this.props.option);
  };

  render() {
    const { children, classes, active } = this.props;

    return (
      <Link
        component="a"
        onClick={this.onClick}
        className={classNames(classes.root, { [classes.active]: active })}
      >
        {children}
      </Link>
    );
  }
}

export default Option;
