import { RESOURCE_NAME as GIFT_CERTIFICATES } from '@esentai/core/features/giftCertificates/consts';
import { RESOURCE_NAME as MALL_BONUSES } from '@esentai/core/features/mallBonuses/consts';
import { RESOURCE_NAME as MALL_STATS } from '@esentai/core/features/mallStats/consts';
import { RESOURCE_NAME as MERCHANTS } from '@esentai/core/features/merchants/consts';
import { RESOURCE_NAME as PRODUCTS } from '@esentai/core/features/products/consts';
import { RESOURCE_NAME as PURCHASE_HISTORY } from '@esentai/core/features/purchases/consts';
import {
  REPAY,
  RESOURCE_NAME as RECONCILIATIONS_CP,
} from '@esentai/core/features/reconciliation/consts';
import { RESOURCE_NAME as RECONCILIATIONS_HISTORY } from '@esentai/core/features/reconciliationHistory/consts';
import { RESOURCE_NAME as RECONCILIATION_PERIOD_BONUS_DETAILS } from '@esentai/core/features/reconciliationPeriodBonusDetails/consts';
import { RESOURCE_NAME as RECONCILIATION_RECEIPTS } from '@esentai/core/features/reconciliationReceipts/consts';
import { RESOURCE_NAME as RECONCILIATION_REFUNDS } from '@esentai/core/features/reconciliationRefunds/consts';
import { RESOURCE_NAME as RECONCILIATION_STORE_BONUS_DETAILS } from '@esentai/core/features/reconciliationStoreBonusDetails/consts';
import { RESOURCE_NAME as RECONCILIATION_STORE_TO_CP } from '@esentai/core/features/reconciliationStoreToCp/consts';
import { RESOURCE_NAME as SALES_TURNOVERS } from '@esentai/core/features/salesTurnovers/consts';
import { RESOURCE_NAME as STORE_BONUSES } from '@esentai/core/features/storeBonuses/consts';
import { RESOURCE_NAME as STORES } from '@esentai/core/features/stores/consts';
import { RESOURCE_NAME as USERS } from '@esentai/core/features/users/consts';
import {
  can,
  CREATE,
  DELETE,
  EDIT,
  permit,
  VIEW,
} from '@esentai/core/policy-dsl';

import { VIEW_NAVIGATION_BAR } from '../consts/permissions';

export default [
  can(VIEW_NAVIGATION_BAR),
  permit(RECONCILIATIONS_CP, [VIEW, REPAY]),
  permit(RECONCILIATIONS_HISTORY, [VIEW, REPAY]),
  permit(RECONCILIATION_RECEIPTS, [VIEW]),
  permit(RECONCILIATION_REFUNDS, [VIEW]),
  permit(RECONCILIATION_STORE_TO_CP, [VIEW, REPAY]),
  permit(RECONCILIATION_STORE_BONUS_DETAILS, [VIEW, REPAY]),
  permit(RECONCILIATION_PERIOD_BONUS_DETAILS, [VIEW]),
  permit(PRODUCTS, [VIEW]),
  permit(USERS, [VIEW]),
  permit(MERCHANTS, [VIEW]),
  permit(STORES, [VIEW]),
  permit(MALL_BONUSES, [VIEW]),
  permit(MALL_STATS, [VIEW, EDIT, CREATE, DELETE]),
  permit(STORE_BONUSES, [VIEW]),
  permit(PURCHASE_HISTORY, [VIEW]),
  permit(SALES_TURNOVERS, [VIEW]),
  permit(GIFT_CERTIFICATES, [VIEW]),
];
