import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Input extends Component {
  static propTypes = {
    hideInputWhenSelected: PropTypes.bool,
    selectedItem: PropTypes.array,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    hideInputWhenSelected: true,
    selectedItem: [],
  };

  render() {
    const {
      hideInputWhenSelected,
      selectedItem,
      disabled,
      ...rest
    } = this.props;

    if (disabled || (selectedItem && hideInputWhenSelected)) {
      return null;
    }

    return <TextField {...rest} />;
  }
}

export default Input;
