import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PaperSheet from '@/components/PaperSheet';
import { downloadXls } from '@/features/salesTurnoverDetailsPage/utils';
import { ROUTE_PATH as SALES_PATH } from '@/features/salesTurnoversPage/consts';

import Description from './Description';
import Table from './Table';

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReceiptDialogOpen: false,
    };
  }

  static propTypes = {
    itemId: PropTypes.string.isRequired,
    error: PropTypes.any,
    saleIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  onReceiptClick = (receipt, uuid) => {
    this.setState({ curReceipt: receipt, curUuid: uuid });
    this.handleReceiptModal();
  };

  handleReceiptModal = () => {
    this.setState({ isReceiptDialogOpen: !this.state.isReceiptDialogOpen });
  };

  render() {
    const {
      error,
      saleIds,
      isLoading,
      totalCount,
      itemId,
      classes,
    } = this.props;

    return (
      <>
        <Page>
          <PageHeader gutterBottom={false}>
            <Breadcrumbs>
              <Breadcrumb to={SALES_PATH}>Обороты</Breadcrumb>
            </Breadcrumbs>
            <BackButton />
          </PageHeader>
          <PageContent>
            <PaperSheet>
              <Description itemId={itemId} />
              <Button
                variant="contained"
                color="primary"
                className={classes.excelButton}
                onClick={() => downloadXls(itemId)}
              >
                Скачать отчет
              </Button>
              <Table
                error={error}
                items={saleIds}
                isLoading={isLoading}
                totalCount={totalCount}
              />
            </PaperSheet>
          </PageContent>
        </Page>
      </>
    );
  }
}

export default Product;
