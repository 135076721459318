import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import {
  ID_KEY,
  MAIN_CAR_KEY,
  ORDER_CUSTOMER_ID_KEY,
  ORDER_CUSTOMER_LEVEL_KEY,
  ORDER_DEBT_KEY,
  ORDER_ID_KEY,
  ORDER_PAYMENT_TYPE_KEY,
  ORDER_STATUS_KEY,
  PARKING_PRICE_KEY,
  PARKING_RECORD_CREATED_KEY,
  PARKING_RECORD_PAYMENT_TIME_KEY,
  RECEIVED_AMOUNT_KEY,
  RECORD_CREATED_KEY,
  RECORD_PAYMENT_TIME_KEY,
  STATUS_KEY,
  TEMPORARY_CAR_KEY,
  TEMPORARY_CAR_MODEL_KEY,
  TEMPORARY_CAR_NUMBER_KEY,
  VALET_FULLNAME_KEY,
  VALET_ONSTART_FULLNAME_KEY,
  VALET_PRICE_KEY,
  VEHICLE_GOV_NUMBER_KEY,
  VEHICLE_MODEL_KEY,
} from '@/features/valetParkingPage/consts/keys';

const HeadRow = ({ SortControl, ...props }) => {
  const [sortFields, setSortFields] = useState({
    [ID_KEY]: [{}],
    [ORDER_ID_KEY]: [{}],
    [ORDER_CUSTOMER_ID_KEY]: [{}],
    [ORDER_CUSTOMER_LEVEL_KEY]: [{}],
    [RECORD_CREATED_KEY]: [{}],
    [PARKING_RECORD_CREATED_KEY]: [{}],
    [RECORD_PAYMENT_TIME_KEY]: [{}],
    [PARKING_RECORD_PAYMENT_TIME_KEY]: [{}],
    [ORDER_STATUS_KEY]: [{}],
    [ORDER_PAYMENT_TYPE_KEY]: [{}],
    [TEMPORARY_CAR_KEY]: [{}],
    [MAIN_CAR_KEY]: [{}],
    [PARKING_PRICE_KEY]: [{}],
    [STATUS_KEY]: [{}],
    [ORDER_DEBT_KEY]: [{}],
    [VALET_PRICE_KEY]: [{}],
    [VALET_ONSTART_FULLNAME_KEY]: [{}],
    [VALET_FULLNAME_KEY]: [{}],
    [RECEIVED_AMOUNT_KEY]: [{}],
  });

  const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  let currentUrlParams = new URLSearchParams(window.location.search);

  const changeFilter = fieldName => {
    Object.keys(initialSortFields).forEach(field => {
      initialSortFields[field] = [{}];
    });

    const changedField = fieldName[0];

    initialSortFields[changedField.field] = [changedField];

    const isMainCar = changedField.field === MAIN_CAR_KEY;
    const isTemporaryCar = changedField.field === TEMPORARY_CAR_KEY;

    if (isMainCar || isTemporaryCar) {
      const vehicleSort = isMainCar
        ? [
            `${VEHICLE_GOV_NUMBER_KEY},${changedField.direction}`,
            `${VEHICLE_MODEL_KEY},${changedField.direction}`,
          ]
        : [
            `${TEMPORARY_CAR_NUMBER_KEY},${changedField.direction}`,
            `${TEMPORARY_CAR_MODEL_KEY},${changedField.direction}`,
          ];

      // Combine two search params
      // First search params - previour params, second - sort params
      currentUrlParams.delete('sort');

      const outputSearchParams = new URLSearchParams();

      for (const [key, val] of currentUrlParams.entries()) {
        outputSearchParams.append(key, val);
      }

      const sortParams = new URLSearchParams(
        vehicleSort.map(param => ['sort', param]),
      );

      for (const [key, val] of sortParams.entries()) {
        outputSearchParams.append(key, val);
      }

      currentUrlParams = outputSearchParams;
      //
    } else if (changedField.direction === 'asc') {
      currentUrlParams.set('sort', `${changedField.field},asc`);
    } else {
      currentUrlParams.set('sort', `${changedField.field},desc`);
    }

    props.history.push({
      pathname: '/valet-parking',
      search: `?${decodeURIComponent(currentUrlParams.toString())}`,
    });

    setSortFields(initialSortFields);
  };

  useEffect(() => {
    const sortList = new URLSearchParams(window.location.search).getAll('sort');

    sortList.map(sort => {
      const sortElem = sort.split(',');

      const fieldName = sortElem[0];
      const fieldDirection = sortElem[1];

      if (
        fieldName === VEHICLE_GOV_NUMBER_KEY ||
        fieldName === VEHICLE_MODEL_KEY
      ) {
        initialSortFields[MAIN_CAR_KEY] = [
          {
            field: MAIN_CAR_KEY,
            direction: fieldDirection,
          },
        ];
      } else if (
        fieldName === TEMPORARY_CAR_NUMBER_KEY ||
        fieldName === TEMPORARY_CAR_MODEL_KEY
      ) {
        initialSortFields[TEMPORARY_CAR_KEY] = [
          {
            field: TEMPORARY_CAR_KEY,
            direction: fieldDirection,
          },
        ];
      } else {
        initialSortFields[fieldName] = [
          {
            field: fieldName,
            direction: fieldDirection,
          },
        ];
      }

      return sort;
    });
    setSortFields(initialSortFields);
  }, []);

  return (
    <TableRow>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ID_KEY]}
          field={ID_KEY}
          setSort={changeFilter}
        >
          ID заезда/выезда
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_ID_KEY]}
          field={ORDER_ID_KEY}
          setSort={changeFilter}
        >
          # заказа
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_CUSTOMER_ID_KEY]}
          field={ORDER_CUSTOMER_ID_KEY}
          setSort={changeFilter}
        >
          ID пользователя
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_CUSTOMER_LEVEL_KEY]}
          field={ORDER_CUSTOMER_LEVEL_KEY}
          setSort={changeFilter}
        >
          Уровень лояльности
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[MAIN_CAR_KEY]}
          field={MAIN_CAR_KEY}
          setSort={changeFilter}
        >
          Модель и гос. номер авто - основной
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[TEMPORARY_CAR_KEY]}
          field={TEMPORARY_CAR_KEY}
          setSort={changeFilter}
        >
          Модель и гос. номер авто - временный
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[RECORD_CREATED_KEY]}
          field={RECORD_CREATED_KEY}
          setSort={changeFilter}
        >
          Дата и время принятия
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[PARKING_RECORD_CREATED_KEY]}
          field={PARKING_RECORD_CREATED_KEY}
          setSort={changeFilter}
        >
          Дата и время заезда
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[RECORD_PAYMENT_TIME_KEY]}
          field={RECORD_PAYMENT_TIME_KEY}
          setSort={changeFilter}
        >
          Дата и время выдачи
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[PARKING_RECORD_PAYMENT_TIME_KEY]}
          field={PARKING_RECORD_PAYMENT_TIME_KEY}
          setSort={changeFilter}
        >
          Дата и время выезда
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[VALET_PRICE_KEY]}
          field={VALET_PRICE_KEY}
          setSort={changeFilter}
        >
          Стоимость услуги
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[PARKING_PRICE_KEY]}
          field={PARKING_PRICE_KEY}
          setSort={changeFilter}
        >
          Стоимость заезда
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_DEBT_KEY]}
          field={ORDER_DEBT_KEY}
          setSort={changeFilter}
        >
          Долг
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[STATUS_KEY]}
          field={STATUS_KEY}
          setSort={changeFilter}
        >
          Статус
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_PAYMENT_TYPE_KEY]}
          field={ORDER_PAYMENT_TYPE_KEY}
          setSort={changeFilter}
        >
          Способ оплаты
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ORDER_STATUS_KEY]}
          field={ORDER_STATUS_KEY}
          setSort={changeFilter}
        >
          Статус оплаты
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[RECEIVED_AMOUNT_KEY]}
          field={RECEIVED_AMOUNT_KEY}
          setSort={changeFilter}
        >
          Получено денег
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[VALET_ONSTART_FULLNAME_KEY]}
          field={VALET_ONSTART_FULLNAME_KEY}
          setSort={changeFilter}
        >
          ФИО Valet парковщика
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[VALET_FULLNAME_KEY]}
          field={VALET_FULLNAME_KEY}
          setSort={changeFilter}
        >
          ФИО Valet принимающий оплату
        </SortControl>
      </CondenseTableCell>
    </TableRow>
  );
};

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default HeadRow;
