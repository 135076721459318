import {
  areQueriesEqual,
  getQueryFilterPredicates,
  getQuerySort,
} from '@esentai/core/queries';
import { query as createQuery, sortBy } from '@esentai/core/query-dsl';

import { ROUTE_PATH, ROUTE_STAT_PATH } from './consts';

export const parseStats = string => (string ? string.split(':') : []);

export const serializeStats = stats => stats.join(':');

export const getEntityPageUrl = entityId =>
  ROUTE_PATH.replace(':entityId', entityId);

export const getStatPageUrl = (entityId, statsIds) =>
  ROUTE_STAT_PATH.replace(':entityId', entityId).replace(
    ':statId',
    serializeStats(statsIds),
  );

export const getStatId = stat => stat.url_key;

export const getStatField = stat => stat.key;

export const findStatById = (stats, statId) =>
  stats.find(stat => getStatId(stat) === statId);

export const getStatsInnerJoin = (stats, statsIds) =>
  statsIds.map(statId => findStatById(stats, statId));

export const formatDescriptorValue = (descriptor, value) => {
  const { formatter } = descriptor;

  return formatter(value);
};

export const formatDescriptorLabel = (descriptor, value) => {
  const { labelFormatter } = descriptor;

  return labelFormatter(value);
};

export const areStatisticsQueriesEqual = (someQuery, anotherQuery) => {
  const omitNonFilterPredicates = query => {
    const querySort = getQuerySort(query);
    const filterPredicates = getQueryFilterPredicates(query);

    return createQuery(sortBy(querySort), ...filterPredicates);
  };

  return areQueriesEqual(
    omitNonFilterPredicates(someQuery),
    omitNonFilterPredicates(anotherQuery),
  );
};
