import {
  AccountBox,
  AccountCircle,
  Android,
  Archive,
  BarChart,
  BlurOff,
  Business,
  CardGiftcard,
  CardTravel,
  Category,
  ContactMail,
  DriveEta,
  DriveEtaOutlined,
  GpsFixed,
  Group,
  HowToReg,
  ImportExportOutlined,
  LayersClear,
  LocalMall,
  LocalOffer,
  LocalOfferOutlined,
  LocalParking,
  LocationOn,
  LowPriorityOutlined,
  Map,
  MapOutlined,
  Money,
  Payment,
  PhoneIphone,
  Pool,
  QuestionAnswer,
  ReceiptOutlined,
  Settings,
  ShoppingBasket,
  ShoppingCart,
  ShoppingCartOutlined,
  Spa,
  Store,
  StoreOutlined,
  SupervisorAccount,
  Unarchive,
  ViewAgenda,
  ViewComfy,
  Web,
} from '@material-ui/icons';

import ChartBar from '@/components/Icon/ChartBar';
import Target from '@/components/Icon/Target';
import { ROUTE_PATH as VERSION_PATH } from '@/features/appVersionPage/consts';
import { ROUTE_PATH as ARCHIVE_BALANCE_PATH } from '@/features/archiveBalancePage/consts';
import { ROUTE_PATH as BEACONS_PATH } from '@/features/beaconsPage/consts';
import { ROUTE_PATH as BENEFITS_ITEMS_PATH } from '@/features/benefitItemsPage/consts';
import { ROUTE_PATH as BENEFITS_PATH } from '@/features/benefitsPage/consts';
import { ROUTE_PATH as BONUSES_HISTORY_PATH } from '@/features/bonusesHistoryPage/consts';
import { ROUTE_PATH as BONUS_TRANSACTION_LEVEL_HISTORY_PATH } from '@/features/bonusTransactionLevelHistoryPage/consts';
import { ROUTE_PATH as BONUS_PATH } from '@/features/bonusTransferPage/consts';
import { ROUTE_PATH as BRANDS_PATH } from '@/features/brandsPage/consts';
import { ROUTE_PATH as CAMPAIGNS_PATH } from '@/features/campaignsPage/consts';
import { ROUTE_PATH as CAR_LIST_PATH } from '@/features/carListPage/consts';
import { ROUTE_PATH as CONTACTS_EDIT_PATH } from '@/features/contactsEditPage/consts';
import { ROUTE_PATH as EFS_USERS_PATH } from '@/features/efsUsersPage/consts';
import { ROUTE_PATH as EFS_VISIT_RECORDS_PATH } from '@/features/efsVisitRecordsPage/consts';
import { ROUTE_PATH as EXECUTIVE_REPORTS_PATH } from '@/features/executiveReportsPage/consts';
import { ROUTE_PATH as FEED_LAYOUT_EDIT_PATH } from '@/features/feedLayoutEditPage/consts';
import { ROUTE_PATH as GIFT_CERTIFICATES_PATH } from '@/features/giftCertificatesPage/consts';
import { ROUTE_PATH as GIFT_SET_PATH } from '@/features/giftSetsPage/consts';
import { ROUTE_PATH as KKM_PATH } from '@/features/kkmsPage/consts';
import { ROUTE_PATH as LOCATIONS_PATH } from '@/features/locationsPage/consts';
import { ROUTE_PATH as LOYALTY_HISTORY_PATH } from '@/features/loyaltyHistoryPage/consts';
import { ROUTE_PATH as MALL_BONUSES_PATH } from '@/features/mallBonusesPage/consts';
import { ROUTE_PATH as MAP_PATH } from '@/features/mapPage/consts';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';
import { ROUTE_PATH as MERCHANTS_RECONCILIATION_PATH } from '@/features/merchantsReconciliationPage/consts';
import { ROUTE_PATH as PARKING_PATH } from '@/features/parkingPage/consts';
import { ROUTE_PATH as PARKING_WHITELIST_PATH } from '@/features/parkingWhitelistPage/consts';
import { ROUTE_PATH as PERSONNEL_PATH } from '@/features/personnelPage/consts';
import { ROUTE_PATH as PRODUCTS_CATEGORY_PATH } from '@/features/productsCategoryPage/consts';
import { ROUTE_PATH as PRODUCT_PATH } from '@/features/productsPage/consts';
import { ROUTE_PATH as PURCHASE_HISTORY_PATH } from '@/features/purchaseHistoryPage/consts';
import { ROUTE_PATH as PURCHASES_WITHOUT_SCAN_PATH } from '@/features/purchaseWithoutScanPage/consts';
import { ROUTE_PATH as QUESTIONS_PATH } from '@/features/questionsPage/consts';
import { ROUTE_PATH as RECONCILIATION_PATH } from '@/features/reconciliationPage/consts';
import { ROUTE_PATH as RECONCILIATION_STORE_CP_PATH } from '@/features/reconciliationStoreToCpPage/consts';
import { ROUTE_PATH as SALES_TURNOVERS_PATH } from '@/features/salesTurnoversPage/consts';
import { ROUTE_PATH as SITE_SYNC_PAGE } from '@/features/siteSyncPage/consts';
import { getEntityPageUrl } from '@/features/statisticsPage/utils';
import { ROUTE_PATH as STORES_PATH } from '@/features/storesPage/consts';
import { ROUTE_PATH as USERS_PATH } from '@/features/usersPage/consts';

export const mallManagerMenu = [
  {
    label: 'Статистика',
    path: getEntityPageUrl('users'),
    Icon: ChartBar,
    alias: [
      getEntityPageUrl('anonymous_purchases'),
      getEntityPageUrl('purchases'),
      getEntityPageUrl('campaigns'),
      getEntityPageUrl('locations'),
    ],
  },
  {
    label: 'Кампании',
    path: CAMPAIGNS_PATH,
    Icon: Target,
    alias: [BENEFITS_PATH],
  },
  {
    label: 'Привилегии',
    Icon: LocalOffer,
    items: [
      {
        label: 'Привилегии',
        path: BENEFITS_PATH,
        Icon: LocalOfferOutlined,
      },
      {
        label: 'Архив по купонам',
        path: BENEFITS_ITEMS_PATH,
        Icon: Archive,
      },
    ],
  },
  {
    label: 'Магазины',
    path: STORES_PATH,
    Icon: ShoppingCart,
  },
  {
    label: 'Персонал',
    path: PERSONNEL_PATH,
    Icon: ContactMail,
  },
  {
    label: 'Пользователи',
    Icon: AccountBox,
    items: [
      {
        label: 'Список пользователей',
        Icon: Group,
        path: USERS_PATH,
      },
      {
        label: 'Архив по бонусам/уровням',
        Icon: ImportExportOutlined,
        path: LOYALTY_HISTORY_PATH,
      },
      {
        label: 'Архив бонусов по чекам',
        Icon: ReceiptOutlined,
        path: BONUSES_HISTORY_PATH,
      },
      {
        label: 'Архив по добавлению баланса',
        path: ARCHIVE_BALANCE_PATH,
        Icon: Unarchive,
      },
      {
        label: 'Подарочные сертификаты',
        Icon: CardGiftcard,
        path: GIFT_CERTIFICATES_PATH,
      },
      {
        label: 'Пользователи EFS',
        path: EFS_USERS_PATH,
        Icon: Spa,
      },
      {
        label: 'Перенос покупок/бонусов',
        Icon: LowPriorityOutlined,
        path: BONUS_PATH,
      },
      {
        label: 'Сгоревшие бонусы',
        Icon: LayersClear,
        path: BONUS_TRANSACTION_LEVEL_HISTORY_PATH,
      },
    ],
  },
  {
    label: 'Список арендаторов',
    path: MERCHANTS_PATH,
    Icon: CardTravel,
  },
  {
    label: 'Справочник ККМ',
    path: KKM_PATH,
    Icon: Payment,
  },
  {
    label: 'Товары',
    Icon: ShoppingBasket,
    items: [
      {
        label: 'Все товары',
        path: PRODUCT_PATH,
        Icon: LocalMall,
      },
      {
        label: 'Категоризация товаров',
        path: PRODUCTS_CATEGORY_PATH,
        Icon: Category,
      },
    ],
  },
  {
    label: 'Подарочные наборы',
    path: GIFT_SET_PATH,
    Icon: CardGiftcard,
  },
  {
    label: 'Настройка бонусов',
    path: MALL_BONUSES_PATH,
    Icon: Store,
  },
  {
    label: 'Покупки',
    Icon: ShoppingCart,
    items: [
      {
        label: 'История покупок',
        path: PURCHASE_HISTORY_PATH,
        Icon: ShoppingCartOutlined,
      },
      {
        label: 'Покупки без сканирования',
        path: PURCHASES_WITHOUT_SCAN_PATH,
        Icon: BlurOff,
      },
    ],
  },
  {
    label: 'Обороты по магазинам',
    path: SALES_TURNOVERS_PATH,
    Icon: Money,
  },
  {
    label: 'Паркинг',
    Icon: DriveEta,
    items: [
      {
        label: 'Паркинг(SPT)/Valet/Тимирязева',
        path: PARKING_PATH,
        Icon: LocalParking,
      },
      {
        label: 'Список авто',
        path: CAR_LIST_PATH,
        Icon: DriveEtaOutlined,
      },
      {
        label: 'Список доступов',
        path: PARKING_WHITELIST_PATH,
        Icon: HowToReg,
      },
    ],
  },
  {
    label: 'EFS',
    Icon: Pool,
    path: EFS_VISIT_RECORDS_PATH,
  },
  {
    label: 'Реконсиляция',
    Icon: AccountBox,
    items: [
      {
        label: 'Бонусы от Esentai Mall',
        Icon: Business,
        items: [
          {
            label: 'Арендаторы',
            path: MERCHANTS_RECONCILIATION_PATH,
            Icon: SupervisorAccount,
          },
          {
            label: 'Магазины',
            path: RECONCILIATION_PATH,
            Icon: StoreOutlined,
          },
        ],
      },
      {
        label: 'Бонусы от имени магазина',
        path: RECONCILIATION_STORE_CP_PATH,
        Icon: Store,
      },
    ],
  },
  {
    label: 'Отчеты',
    Icon: BarChart,
    items: [
      {
        label: 'Executive report',
        Icon: ViewComfy,
        path: EXECUTIVE_REPORTS_PATH,
      },
    ],
  },
  {
    label: 'Настройки',
    Icon: Settings,
    items: [
      {
        label: 'Карта молла',
        Icon: Map,
        items: [
          {
            label: 'Карта',
            path: MAP_PATH,
            Icon: MapOutlined,
          },
          {
            label: 'Локации',
            path: LOCATIONS_PATH,
            Icon: LocationOn,
          },
          {
            label: 'Маяки',
            path: BEACONS_PATH,
            Icon: GpsFixed,
          },
        ],
      },
      {
        label: 'Контент приложения',
        Icon: Android,
        items: [
          {
            label: 'Контактная информация',
            path: CONTACTS_EDIT_PATH,
            Icon: AccountCircle,
          },
          {
            label: 'FAQ',
            path: QUESTIONS_PATH,
            Icon: QuestionAnswer,
          },
          {
            label: 'Бренды',
            path: BRANDS_PATH,
            Icon: Store,
          },
        ],
      },
      {
        label: 'Версия приложения',
        Icon: PhoneIphone,
        path: VERSION_PATH,
      },
      {
        label: 'Контент сайта',
        Icon: Web,
        path: SITE_SYNC_PAGE,
      },
      {
        label: 'Компоновка ленты',
        Icon: ViewAgenda,
        path: FEED_LAYOUT_EDIT_PATH,
      },
    ],
  },
];
