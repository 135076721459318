export default {
  root: {
    maxHeight: 640,
    maxWidth: 320,
    border: [1, 'solid', 'transparent'],

    '&:hover': {
      borderColor: 'gray',
    },
  },
};
