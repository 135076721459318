import {
  PARAGRAPH_ENGLISH_TEXT_KEY,
  PARAGRAPH_KAZAKH_TEXT_KEY,
  PARAGRAPH_RUSSIAN_TEXT_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import PropTypes from 'prop-types';
import React from 'react';

import Quadrolingual from '@/components/Quadrolingual';
import Label from '@/features/campaignPage/Page/Label';

const Paragraph = ({ payload }) => (
  <Label title="Текст абзаца">
    <Quadrolingual
      russianContent={payload[PARAGRAPH_RUSSIAN_TEXT_KEY]}
      englishContent={payload[PARAGRAPH_ENGLISH_TEXT_KEY]}
      kazakhContent={payload[PARAGRAPH_KAZAKH_TEXT_KEY]}
    />
  </Label>
);

Paragraph.propTypes = {
  payload: PropTypes.object.isRequired,
};

export default Paragraph;
