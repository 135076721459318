import {
  ENGLISH_TITLE_KEY,
  RUSSIAN_TITLE_KEY,
  SHOW_POINTER_KEY,
} from '@esentai/core/features/campaigns/button/consts/keys';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import TextField from '@/components/TextField';
import limits from '@/features/campaignCreatePage/EditPage/limits';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';
import Preview from './Preview';

class ContentForm extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { classes, Field, values } = this.props;

    return (
      <FieldRow
        label="Наполнение кнопки"
        subLabel="Заголовок кнопки"
        sudDesc="???"
        Aside={this.renderPreview}
      >
        <CanEdit field={RUSSIAN_TITLE_KEY}>
          <Field
            Target={TextField}
            name={RUSSIAN_TITLE_KEY}
            symbolsMax={limits.title}
            helperText="На русском"
          />
        </CanEdit>
        <CanEdit field={ENGLISH_TITLE_KEY}>
          <Field
            Target={TextField}
            name={ENGLISH_TITLE_KEY}
            symbolsMax={limits.title}
            helperText="На английском"
            className={classes.englishTitleField}
          />
        </CanEdit>
        <FieldDescription
          title="Стрелка кнопки"
          desc="Для дополнительной мотивации на кнопке можно отображать стрелку. Однако, она занимает лишнее место."
        />

        <CanEdit field={SHOW_POINTER_KEY}>
          <FormControlLabel
            label="Отображать на кнопке стрелку"
            control={
              <Checkbox
                checked={Boolean(values[SHOW_POINTER_KEY])}
                onChange={this.toggleShowPointer}
              />
            }
          />
        </CanEdit>
      </FieldRow>
    );
  }

  renderPreview = () => {
    const { values } = this.props;

    return <Preview values={values} />;
  };

  toggleShowPointer = event => {
    const { checked } = event.target;
    const { setFieldValue } = this.props;

    setFieldValue(SHOW_POINTER_KEY, checked);
  };
}

export default ContentForm;
