import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Quadrolingual from '@/components/Quadrolingual';
import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';
import { StoreMultiAutocomplete } from '@/containers/StoreAutocomplete';
import Image from '@/features/campaignPage/Page/Image';

const BrandPage = ({
  chineseDescription,
  classes,
  englishDescription,
  imageUrl,
  isLoaded,
  kazakhDescription,
  name,
  russianDescription,
  storesIds,
}) =>
  isLoaded && (
    <SettingsPage>
      <SettingsPageHeader borderBottom={false} gutterBottom>
        <SettingsPageTitle>Бренд «{name}»</SettingsPageTitle>
      </SettingsPageHeader>
      <SettingsPageContent>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Изображение бренда
          </Typography>
          <Image src={imageUrl} />
        </div>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Описание бренда
          </Typography>
          <Quadrolingual
            chineseContent={chineseDescription}
            englishContent={englishDescription}
            kazakhContent={kazakhDescription}
            russianContent={russianDescription}
          />
        </div>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Привязан к магазинам
          </Typography>
          <StoreMultiAutocomplete selectedItem={storesIds} disabled />
        </div>
      </SettingsPageContent>
    </SettingsPage>
  );

BrandPage.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  itemId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  updateBrand: PropTypes.func.isRequired,
  chineseDescription: PropTypes.string.isRequired,
  englishDescription: PropTypes.string.isRequired,
  kazakhDescription: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  russianDescription: PropTypes.string.isRequired,
  storesIds: PropTypes.any.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default BrandPage;
