import { DATE_START_KEY } from '@esentai/core/features/campaigns/consts/keys';
import { LOCATIONS_RELATION } from '@esentai/core/features/campaigns/consts/relations';
import { annotations } from '@esentai/core/features/campaigns/serializer';
import { descending } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer, getLinkCreator } from '@/serializers';

import { ROUTE_PATH } from './consts';

const serializer = createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(DATE_START_KEY),
  }),
  include: string({
    defaultValue: [LOCATIONS_RELATION],
  }),
});

export const createLink = getLinkCreator(ROUTE_PATH, serializer);

export default serializer;
