import { indexResources } from '@esentai/core/ducks/dao/actions';
import findByIdStart from '@esentai/core/ducks/dao/reducers/byId/findByIdStart';
import findByIdSuccess from '@esentai/core/ducks/dao/reducers/byId/findByIdSuccess';
import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import {
  createMallBonus,
  fetchMallBonus,
} from '@esentai/core/features/mallBonuses/sagas';
import { push } from 'connected-react-router';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { getMallBonusId } from './selectors';
import {
  CREATE_DRAFT,
  FETCH_ITEM,
  GO_TO_STEP_ONE,
  GO_TO_STEP_TWO,
  SUBMIT,
} from './types';
import { createFormStepURL, randomId } from './utils';

export function* goToStep(step) {
  const id = yield select(getMallBonusId);
  const url = yield call(createFormStepURL, id, step);

  yield put(push(url));
}

export function* createDraft(action) {
  const { payload: attributes } = action;
  const id = randomId();

  localStorage.setItem(`mall-bonus-${id}`, JSON.stringify(attributes));
  const url = yield call(createFormStepURL, id, 2);

  yield put(push(url));
}

export function* fetchItem(action) {
  const { payload: id } = action;

  if (Number.isInteger(id)) {
    yield call(fetchMallBonus, id);
  } else {
    const payload = JSON.parse(localStorage.getItem(`mall-bonus-${id}`));

    yield put(findByIdStart(id));
    yield put(indexResources(payload));
    yield put(findByIdSuccess(id));
  }
}

export function* goToStepOne() {
  yield call(goToStep, 1);
}

export function* goToStepTwo(action) {
  const { payload: attributes } = action;
  const id = yield select(getMallBonusId);

  localStorage.setItem(`mall-bonus-${id}`, JSON.stringify(attributes));
  yield call(goToStep, 2);
}

export function* submitMallBonus(action) {
  const { payload: attributes } = action;
  const id = yield select(getMallBonusId);
  const cached = `mall-bonus-${id}`;
  const firstStepAttrs = JSON.parse(localStorage.getItem(cached));

  yield call(createMallBonus, { ...attributes, ...firstStepAttrs });
  delete localStorage[cached];
}

export default function*() {
  yield takeEvery(CREATE_DRAFT, createTaskSaga(createDraft));
  yield takeEvery(FETCH_ITEM, createTaskSaga(fetchItem));
  yield takeEvery(GO_TO_STEP_ONE, createTaskSaga(goToStepOne));
  yield takeEvery(GO_TO_STEP_TWO, createTaskSaga(goToStepTwo));
  yield takeEvery(SUBMIT, createTaskSaga(submitMallBonus));
}
