import { Radio, RadioGroup, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import {
  LOCALE_EN,
  LOCALE_KZ,
  LOCALE_RU,
} from '@/hocs/withDevicePreview/consts';

class Container extends Component {
  state = {
    locale: LOCALE_RU,
  };

  toggleLocale = event =>
    this.setState({
      locale: event.target.value,
    });

  render() {
    const { classes, children } = this.props;
    const { locale } = this.state;

    return (
      <div className={classes.root}>
        <Typography variant="caption" className={classes.hint}>
          Вы видите примерный вид. Реальное отображение может несколько
          отличаться в зависимости от текущей версии приложения.
        </Typography>

        {this.renderToggler()}
        {children({ locale, selectByLocale: this.selectByLocale })}
      </div>
    );
  }

  selectByLocale = definitions => {
    const { locale } = this.state;

    return definitions[locale];
  };

  renderTogglerText = text => {
    const { classes } = this.props;

    return (
      <Typography
        classes={{
          h6: classes.radioText,
        }}
        variant="h6"
      >
        {text}
      </Typography>
    );
  };

  renderToggler() {
    const { classes } = this.props;
    const { locale } = this.state;

    return (
      <RadioGroup
        classes={{
          root: classes.radioGroup,
        }}
        value={locale}
        onChange={this.toggleLocale}
      >
        <Radio
          classes={{
            root: classes.radio,
            checked: classes.checked,
          }}
          disableRipple
          value={LOCALE_RU}
          checked={locale === LOCALE_RU}
          icon={this.renderTogglerText('RUS')}
          checkedIcon={this.renderTogglerText('RUS')}
        />
        <Radio
          classes={{
            root: classes.radio,
            checked: classes.checked,
          }}
          disableRipple
          className={classes}
          value={LOCALE_EN}
          checked={locale === LOCALE_EN}
          icon={this.renderTogglerText('ENG')}
          checkedIcon={this.renderTogglerText('ENG')}
        />
        <Radio
          classes={{
            root: classes.radio,
            checked: classes.checked,
          }}
          disableRipple
          className={classes}
          value={LOCALE_KZ}
          checked={locale === LOCALE_KZ}
          icon={this.renderTogglerText('KAZ')}
          checkedIcon={this.renderTogglerText('KAZ')}
        />
      </RadioGroup>
    );
  }
}

export default Container;
