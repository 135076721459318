import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';

import IndiceBrandsList from './IndiceBrandsList';

const Brands = ({ storeBrandsIndices }) => (
  <FieldRow name="Список брендов">
    {storeBrandsIndices.map(indice => (
      <IndiceBrandsList key={indice} indice={indice} />
    ))}
  </FieldRow>
);

Brands.propTypes = {
  storeBrandsIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Brands;
