import config from '@/config';

import API from './persistent-token-api';
import resources from './resources';

const api = new API(config.apiUrlV1, config.apiUrlV2);

resources.forEach(resource => api.addResource(resource));

if (process.env.NODE_ENV !== 'production') {
  window.__API__ = api;
}

export default api;
