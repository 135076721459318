import PropTypes from 'prop-types';
import React from 'react';

import { LocationAutocomplete } from '@/containers/LocationAutocomplete';

const Select = ({ name, error, onChange }) => (
  <LocationAutocomplete
    name={name}
    id={name}
    onChange={onChange}
    InputProps={{
      error: Boolean(error),
      fullWidth: true,
      placeholder: 'Название локации...',
      helperText: error,
    }}
  />
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  error: null,
};

export default Select;
