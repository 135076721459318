import PropTypes from 'prop-types';
import React from 'react';

import LocationPageTagLink from '@/containers/LocationPageTagLink';

const Locations = ({ locationsIds }) =>
  locationsIds.map(locationId => (
    <LocationPageTagLink key={locationId} locationId={locationId} />
  ));

Locations.defaultProps = {
  locationsIds: [],
};

Locations.propTypes = {
  locationsIds: PropTypes.arrayOf(PropTypes.number),
};

export default Locations;
