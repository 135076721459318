import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Link from '@/components/Link';
import StoreNameLazy from '@/containers/StoreNameLazy';
import { createHistoryPageUrl } from '@/features/reconciliationHistoryPage/utils';
import { dateTimeFormat, formatMoney, formatNumber } from '@/utils/format';

import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import { createReceiptsPageUrl } from '../../../reconciliationReceiptsPage/utils';
import { createRefundsPageUrl } from '../../../reconciliationRefundsPage/utils';
import { POST_URL, REPAY_CONFIRMATION_MSG } from '../../consts';
// import Actions from './Actions';

const Row = ({
  lastReconciliationDate,
  bonusesRefundedAmount,
  bonusesSpentAmount,
  // overpaymentAmount,
  storeId,
  totalToPayAmount,
  fetchItems,
  companyName,
}) => {
  const [isRepayDialogOpen, setIsRepayDialogOpen] = useState(false);

  const handleRepayModal = () => {
    setIsRepayDialogOpen(!isRepayDialogOpen);
  };

  const payload = {
    reconciliation: {
      store: storeId,
    },
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Link to={createHistoryPageUrl(storeId)}>
            <StoreNameLazy storeId={storeId} />
          </Link>
        </TableCell>
        <TableCell>{companyName || '-'}</TableCell>
        <TableCell>{formatMoney(totalToPayAmount)}</TableCell>
        <TableCell>
          <Link to={createReceiptsPageUrl(storeId)}>
            {formatNumber(bonusesSpentAmount)}
          </Link>
        </TableCell>
        <TableCell>
          <Link to={createRefundsPageUrl(storeId)}>
            {formatNumber(bonusesRefundedAmount)}
          </Link>
        </TableCell>
        {/* <TableCell>{formatMoney(overpaymentAmount)}</TableCell> */}
        <TableCell>
          {lastReconciliationDate
            ? dateTimeFormat(lastReconciliationDate)
            : '-'}
        </TableCell>
        {/* <TableCell>
          <Actions
            storeId={storeId}
            handleDialog={handleRepayModal}
            repayDisabled={
              bonusesRefundedAmount === 0 && bonusesSpentAmount === 0
            }
          />
        </TableCell> */}
      </TableRow>
      {isRepayDialogOpen && (
        <ConfirmationDialog
          isOpen={isRepayDialogOpen}
          onClose={handleRepayModal}
          storeId={storeId}
          fetchItems={fetchItems}
          url={POST_URL}
          payload={payload}
          confirmationMessage={REPAY_CONFIRMATION_MSG}
        />
      )}
    </>
  );
};

Row.propTypes = {
  storeId: PropTypes.number.isRequired,
  totalToPayAmount: PropTypes.number.isRequired,
  bonusesSpentAmount: PropTypes.number.isRequired,
  bonusesRefundedAmount: PropTypes.number.isRequired,
  // overpaymentAmount: PropTypes.number.isRequired,
  lastReconciliationDate: PropTypes.instanceOf(Date),
  fetchItems: PropTypes.func.isRequired,
  companyName: PropTypes.string,
};

Row.defaultProps = {
  lastReconciliationDate: '',
  companyName: '-',
};

export default Row;
