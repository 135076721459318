import { Button } from '@material-ui/core';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { without } from 'ramda';
import React, { Component } from 'react';

import DefaultFileChip from './FileChip';

export default class FileFiled extends Component {
  static propTypes = {
    FileChip: PropTypes.func,
    fileInputProps: PropTypes.object,
    maxItemsCount: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onGetFileName: PropTypes.func,
    upload: PropTypes.func,
    showButton: PropTypes.bool,
    disabled: PropTypes.bool.isRequired,
    ids: PropTypes.arrayOf(PropTypes.string).isRequired,
    showUploadProgress: PropTypes.bool,
  };

  static defaultProps = {
    showButton: true,
    fileInputProps: {},
    maxItemsCount: 1,
    upload: () => {},
    FileChip: DefaultFileChip,
    showUploadProgress: true,
    onGetFileName: () => {},
  };

  state = {
    pendingIds: [],
    loading: false,
  };

  upload = event => {
    const { upload, showUploadProgress, onGetFileName } = this.props;
    const files = [...event.target.files];
    const fileIds = files.map(x => x.name);

    this.setState(prevState => ({
      pendingIds: [...prevState.pendingIds, ...fileIds],
      loading: true,
    }));

    upload(
      files.map(file => {
        if (!showUploadProgress) {
          onGetFileName(file.name);
          event.target.value = '';
        }

        return { id: file.name, file };
      }),
    );
  };

  onDelete = fileId => {
    const { ids, onChange } = this.props;
    const idsWithoutRemoved = without([fileId], ids);

    this.setState(prevState => ({
      pendingIds: prevState.pendingIds.filter(x => x !== fileId),
    }));

    onChange(idsWithoutRemoved);
  };

  onFinishUploading = (fileId, url) => {
    const { ids, onChange } = this.props;

    this.setState(prevState => ({
      pendingIds: prevState.pendingIds.filter(x => x !== fileId),
      loading: false,
    }));

    onChange([...ids, url]);
  };

  render() {
    const {
      classes,
      fileInputProps,
      ids,
      maxItemsCount,
      FileChip,
      showButton,
      showUploadProgress,
      disabled,
    } = this.props;
    const { pendingIds, loading } = this.state;
    const allIds = [...ids, ...pendingIds];

    if (!showUploadProgress) {
      return (
        <div>
          <div style={{ display: 'none' }}>
            {allIds.map(fileId => (
              <FileChip
                disabled={disabled}
                key={fileId}
                fileId={fileId}
                deleteFile={this.onDelete}
                submitFile={this.onFinishUploading}
              />
            ))}
          </div>
          <Button
            disabled={loading}
            component="label"
            className={classes.button}
          >
            <input
              {...fileInputProps}
              className={classes.fileInput}
              onChange={this.upload}
              type="file"
            />
            <CloudUploadIcon className={classes.buttonIcon} />
            Загрузить
          </Button>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {allIds.map(fileId => (
          <FileChip
            disabled={disabled}
            key={fileId}
            fileId={fileId}
            deleteFile={this.onDelete}
            submitFile={this.onFinishUploading}
          />
        ))}
        {showButton && allIds.length < maxItemsCount && (
          <Button
            disabled={disabled}
            component="label"
            className={classes.button}
          >
            <input
              {...fileInputProps}
              className={classes.fileInput}
              onChange={this.upload}
              type="file"
            />
            <CloudUploadIcon className={classes.buttonIcon} />
            Загрузить
          </Button>
        )}
      </div>
    );
  }
}
