import {
  BONUS_PERCENT_KEY,
  FISCAL_ID_KEY,
  HMS_KEY,
  STATUS_KEY,
  STORE_NAME_KEY,
  TIMESTAMP_KEY,
  TOTAL_COST_KEY,
} from '@esentai/core/features/purchases/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createPurchaseStatusFilter } from '@/filters/PurchaseStatus';
import { createRangeFilter } from '@/filters/Range';
import { createTextFilter } from '@/filters/SearchInput';
import { createTimeRangeFilter } from '@/filters/TimeRange';

export const filterTypes = [
  createMoneyRangeFilter({
    key: TOTAL_COST_KEY,
    label: 'Cумма чека',
  }),
  createTextFilter({
    key: FISCAL_ID_KEY,
    label: 'UUID',
  }),
  createTextFilter({
    key: STORE_NAME_KEY,
    label: 'Магазин',
  }),
  createDateRangeFilter({
    key: TIMESTAMP_KEY,
    label: 'Дата покупки',
  }),
  createTimeRangeFilter({
    key: HMS_KEY,
    label: 'Время покупки',
  }),
  createRangeFilter({
    key: BONUS_PERCENT_KEY,
    label: 'Процент начисления бонусов',
  }),
  createPurchaseStatusFilter({
    key: STATUS_KEY,
    label: 'Способ оплаты',
  }),
];
