import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Children, cloneElement, Component } from 'react';

import MapRentPlace from '@/components/Map/RentPlace';

class RentPlace extends Component {
  static propTypes = {
    amount: PropTypes.any.isRequired,
    colorScale: PropTypes.func.isRequired,
  };

  render() {
    const { children, ...rest } = this.props;
    const shapeProps = this.getShapeProps();

    return (
      <MapRentPlace {...omit(['colorScale'], rest)} {...shapeProps}>
        {Children.map(children, child => cloneElement(child, shapeProps))}
      </MapRentPlace>
    );
  }

  getShapeProps = () => {
    const { amount, colorScale } = this.props;

    return { fill: colorScale(amount) };
  };
}

export default RentPlace;
