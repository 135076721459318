import { path } from 'ramda';

import { getStatPageUrl } from '@/features/statisticsPage/utils';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { getLinkCreator } from '@/serializers';

import { FEATURE_NAME } from './consts';
import serializer from './serializer';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQuery = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const createStatPageUrl = (statId, floor, query) => {
  const url = getStatPageUrl('locations', [statId, floor]);
  const serialize = getLinkCreator(url, serializer);

  return serialize(query);
};
