import api from '@esentai/core/features/parkingWhitelistGroups/api';
import { NAME_KEY } from '@esentai/core/features/parkingWhitelistGroups/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import WhitelistGroupChip from './WhitelistGroupChip';
import WhitelistGroupOption from './WhitelistGroupOption';

const buildQuery = queryString => query(where(NAME_KEY, ilike(queryString)));

const withWhitelistGroupProps = defaultProps({
  api,
  buildQuery,
  Chip: WhitelistGroupChip,
  Option: WhitelistGroupOption,
});

export const WhitelistGroupAutocomplete = withWhitelistGroupProps(
  AutocompleteAPI,
);

export const WhitelistGroupMultiAutocomplete = withWhitelistGroupProps(
  AutocompleteAPIMultiple,
);
