import {
  PAID_AMOUNT_KEY,
  PERCENT_KEY,
  SUM_INVOICED_KEY,
  SUM_PROCESSING_KEY,
  TOTAL_TO_PAY_KEY,
} from '@esentai/core/features/reconciliationStoreBonusDetails/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';

import { canRepayReconciliation } from '../../permissions';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    {/* <TableCell>Магазин</TableCell> */}
    <TableCell>Название акции</TableCell>
    <CondenseTableCell>Период действия акций</CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={PERCENT_KEY}>Процент начисляемых бонусов</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={TOTAL_TO_PAY_KEY}>Сумма к оплате</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={SUM_PROCESSING_KEY}>Сумма в обработке</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={SUM_INVOICED_KEY}>Выставленная сумма</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={PAID_AMOUNT_KEY}>Оплаченная сумма</SortControl>
    </CondenseTableCell>
    <CanView permission={canRepayReconciliation}>
      <TableCell align="center">Действия</TableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
