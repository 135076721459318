import {
  DATETIME_FILTER_KEY,
  STORE_NAME_KEY,
} from '@esentai/core/features/reconciliationArchive/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: STORE_NAME_KEY,
    label: 'Магазин',
  }),
  createDateRangeFilter({
    key: DATETIME_FILTER_KEY,
    label: 'Дата реконсиляции',
  }),
];
