import { removeCard } from '@esentai/core/features/creditCards/actions';
import { connect } from 'react-redux';

import CreditCardDeleteButton from './component';

const mapDispatchToProps = (dispatch, { cardInfo }) => ({
  removeCard: () => dispatch(removeCard(cardInfo)),
});

export default connect(null, mapDispatchToProps)(CreditCardDeleteButton);
