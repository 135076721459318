import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { createStore } from '@esentai/core/features/stores/sagas';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTE_PATH as STORES_PATH } from '@/features/storesPage/consts';

import { CREATE_STORE } from './types';

export function* createStoreSaga(action) {
  const { payload: attributes } = action;

  yield call(createStore, attributes);
  yield put(push(STORES_PATH));
}

export default function*() {
  yield takeLatest(CREATE_STORE, createTaskSaga(createStoreSaga));
}
