import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';

import { NEW_MALL_BONUS_ROUTE_PATH } from '../../features/mallBonusCreatePage/consts';
import { NEW_STORE_BONUS_ROUTE_PATH } from '../../features/storeBonusCreatePage/consts';
import Link from '../Link';

export interface SelectBonusTypeProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export class SelectBonusType extends React.Component<SelectBonusTypeProps> {
  public render(): JSX.Element {
    const { onClose, selectedValue, open } = this.props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">Выбрать функцию</DialogTitle>
        <List>
          <ListItem button onClick={() => this.setState({ route: 'cp' })}>
            <Link to={NEW_MALL_BONUS_ROUTE_PATH}>
              <ListItemText primary="Изменение процента от имени Esentai Mall" />
            </Link>
          </ListItem>

          <ListItem button onClick={() => this.setState({ route: 'store' })}>
            <Link to={NEW_STORE_BONUS_ROUTE_PATH}>
              <ListItemText primary="Изменение процента от имени Магазина" />
            </Link>
          </ListItem>
        </List>
      </Dialog>
    );
  }
}
