import {
  ARTICLE_TYPE,
  BANNER_TYPE,
  NOTIFICATION_TYPE,
  SHOW_TYPE,
} from '@esentai/core/features/campaigns/consts/types';

import { typeLabel } from '@/features/campaignsPage/labels';
import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';

export const options = [
  BANNER_TYPE,
  ARTICLE_TYPE,
  SHOW_TYPE,
  NOTIFICATION_TYPE,
].map(key => ({
  key,
  label: typeLabel(key),
}));

export const createCampaignTypeFilter = createCheckboxFilterTemplate({
  options,
});

export default CheckboxGroupFilter;
