import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { STORE_IDS_KEY } from '@esentai/core/features/users/consts/keys';
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects';

import { fetchPersonnel } from '@/features/personnel/sagas';
import { runBetween } from '@/utils/sagas';

import {
  authenticateFailure,
  authenticateSuccess,
  authorizeFailure,
  authorizeSuccess,
  changeCurrentUser,
  deauthorize as deauthorizeAction,
} from './actions';
import apiResource from './api';
import { isSessionExpired } from './selectors';
import {
  AUTHENTICATE,
  AUTHENTICATE_SUCCESS,
  AUTHORIZE,
  AUTHORIZE_BY_TOKEN,
  AUTHORIZE_FAILURE,
  AUTHORIZE_SUCCESS,
  DEAUTHORIZE,
} from './types';

export const authenticate = function*({ payload: credentials }) {
  try {
    yield call(apiResource.signIn, credentials);
    yield put(authenticateSuccess());
  } catch (error) {
    yield put(authenticateFailure(error));

    throw error;
  }
};

export const authorize = function*() {
  try {
    const userId = yield call(apiResource.getCurrentUserId);

    yield put(changeCurrentUser(userId));
    yield call(fetchPersonnel, userId, { include: [STORE_IDS_KEY] });
    yield put(authorizeSuccess());
  } catch (err) {
    yield put(authorizeFailure(err));
  }
};

export const authorizeByToken = function*({ payload: token }) {
  apiResource.setToken(token);

  yield call(authorize);
};

export const deauthorize = function*() {
  yield call(apiResource.signOut);
};

export const watchAuthenticate = function*() {
  yield takeEvery(AUTHENTICATE, createTaskSaga(authenticate));
};

export const watchAuthorize = function*() {
  yield takeEvery([AUTHORIZE, AUTHENTICATE_SUCCESS], authorize);
  yield takeEvery(AUTHORIZE_BY_TOKEN, authorizeByToken);
};

export const deauthorizeOnTokenExpire = function*() {
  const sessionExpired = yield select(isSessionExpired);

  if (sessionExpired) {
    yield put(deauthorizeAction());
  }
};

export const watchDeauthorize = function*() {
  yield takeEvery(DEAUTHORIZE, deauthorize);
};

export function* watchAuthorizeFailure() {
  yield takeEvery(AUTHORIZE_FAILURE, deauthorizeOnTokenExpire);
}

export function runWhileAuthorized(saga) {
  return runBetween(AUTHORIZE_SUCCESS, DEAUTHORIZE, saga);
}

export default function*() {
  yield spawn(watchAuthenticate);
  yield spawn(watchAuthorize);
  yield spawn(watchAuthorizeFailure);
  yield spawn(watchDeauthorize);
}
