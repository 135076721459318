import {
  ENGLISH_DESCRIPTION_KEY,
  ENGLISH_TITLE_KEY,
  KAZAKH_DESCRIPTION_KEY,
  KAZAKH_TITLE_KEY,
  RUSSIAN_DESCRIPTION_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/show/consts/keys';
import { Grid } from '@material-ui/core';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import TextField from '@/components/TextField';
import limits from '@/features/campaignCreatePage/EditPage/limits';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';
import Preview from './Preview';

class ContentForm extends Component {
  static propTypes = FormikPropTypes;

  renderPreview = () => {
    const { values, imageUrls } = this.props;

    return (
      <Preview
        imgUrls={imageUrls}
        englishTitle={values[ENGLISH_TITLE_KEY]}
        russianTitle={values[RUSSIAN_TITLE_KEY]}
        kazakhTitle={values[KAZAKH_TITLE_KEY]}
      />
    );
  };

  render() {
    const { Field } = this.props;

    return (
      <FieldRow
        label="Наполнение витрины"
        sudDesc="Витрина обязательно должна иметь изображение"
        Aside={this.renderPreview}
      >
        <Grid container spacing={40}>
          <Grid item xs={12}>
            <FieldDescription
              title="Заголовок"
              desc="Заголовок будет отображаться над онлайн-витриной. Не более 50 символов."
            />
            <CanEdit field={RUSSIAN_TITLE_KEY}>
              <Field
                Target={TextField}
                name={RUSSIAN_TITLE_KEY}
                symbolsMax={limits.title}
                helperText="На русском"
                fullWidth
              />
            </CanEdit>
            <CanEdit field={ENGLISH_TITLE_KEY}>
              <Field
                Target={TextField}
                name={ENGLISH_TITLE_KEY}
                symbolsMax={limits.title}
                helperText="На английском"
                fullWidth
              />
            </CanEdit>
            <CanEdit field={KAZAKH_TITLE_KEY}>
              <Field
                Target={TextField}
                name={KAZAKH_TITLE_KEY}
                symbolsMax={limits.title}
                helperText="На казахском"
                fullWidth
              />
            </CanEdit>
          </Grid>
          <Grid item xs={12}>
            <FieldDescription title="Описание" />
            <CanEdit field={RUSSIAN_DESCRIPTION_KEY}>
              <Field
                Target={TextField}
                name={RUSSIAN_DESCRIPTION_KEY}
                helperText="На русском"
                fullWidth
                symbolsMax={1000}
              />
            </CanEdit>
            <CanEdit field={ENGLISH_DESCRIPTION_KEY}>
              <Field
                Target={TextField}
                name={ENGLISH_DESCRIPTION_KEY}
                helperText="На английском"
                fullWidth
                symbolsMax={1000}
              />
            </CanEdit>
            <CanEdit field={KAZAKH_DESCRIPTION_KEY}>
              <Field
                Target={TextField}
                name={KAZAKH_DESCRIPTION_KEY}
                helperText="На казахском"
                fullWidth
                symbolsMax={1000}
              />
            </CanEdit>
          </Grid>
        </Grid>
      </FieldRow>
    );
  }
}

export default ContentForm;
