import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import StatisticsButton from '@/features/locationsPage/Page/Row/Actions/StatisticsButton';

const LocationInfo = ({ classes, locationName, locationId }) =>
  locationId ? (
    <Fragment>
      {locationName}
      <div className={classes.actions}>
        <StatisticsButton itemId={locationId} color="inherit" />
      </div>
    </Fragment>
  ) : null;

LocationInfo.propTypes = {
  locationId: PropTypes.string.isRequired,
  locationName: PropTypes.number.isRequired,
};

export default LocationInfo;
