import {
  DATE_CREATION_KEY,
  STORES_KEY,
  USER_KEY,
} from '@esentai/core/features/mallBonuses/consts/keys';
import { annotations } from '@esentai/core/features/mallBonuses/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(DATE_CREATION_KEY),
  }),
  include: string({
    defaultValue: [STORES_KEY, USER_KEY],
  }),
});
