import { pick } from 'ramda';

import {
  ACCESS_TO_STATISTIC_KEY,
  EMAIL_KEY,
  LOCATIONS_IDS_KEY,
  ROLE_KEY,
  STORE_IDS_KEY,
} from '@/features/invites/consts/keys';
import {
  BITRIX_ROLE,
  EFS_SALES_ROLE,
  GUEST_EFS_ROLE,
  GUEST_PARKING_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_SELLER_ROLE,
  MALL_VALET_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_BENEFIT_MANAGER_ROLE,
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
} from '@/features/personnel/consts/roles';

import EmailField from './EmailField';
import LocationsField from './LocationsField';
import StatisticAccessField from './StatisticAccessField';
import StoresField from './StoresField';

export const getFieldComponent = field => {
  const mapping = {
    [EMAIL_KEY]: EmailField,
    [STORE_IDS_KEY]: StoresField,
    [LOCATIONS_IDS_KEY]: LocationsField,
    [ACCESS_TO_STATISTIC_KEY]: StatisticAccessField,
  };

  return mapping[field];
};

export const getFields = role => {
  const mapping = {
    [MALL_COMMISSAR_ROLE]: [EMAIL_KEY],
    [MALL_MANAGER_ROLE]: [EMAIL_KEY],
    [MALL_SELLER_ROLE]: [EMAIL_KEY],
    [MALL_COMMISSAR_ROLE]: [EMAIL_KEY],
    [MALL_VALET_ROLE]: [EMAIL_KEY],
    [STORE_ADMINISTRATOR_ROLE]: [EMAIL_KEY, STORE_IDS_KEY, LOCATIONS_IDS_KEY],
    [STORE_MANAGER_ROLE]: [EMAIL_KEY, ACCESS_TO_STATISTIC_KEY],
    [STORE_SELLER_ROLE]: [EMAIL_KEY],
    [MALL_ACCOUNTANT_ROLE]: [EMAIL_KEY],
    [GUEST_PARKING_ROLE]: [EMAIL_KEY],
    [BITRIX_ROLE]: [EMAIL_KEY],
    [EFS_SALES_ROLE]: [EMAIL_KEY],
    [GUEST_EFS_ROLE]: [EMAIL_KEY],
    [STORE_BENEFIT_MANAGER_ROLE]: [EMAIL_KEY, STORE_IDS_KEY],
    [MALL_RECEPTION_ROLE]: [EMAIL_KEY],
    [MANAGING_TEAM_MEMBER_ROLE]: [EMAIL_KEY],
  };

  return mapping[role];
};

export const filterMallAdminFields = values => {
  const role = values[ROLE_KEY];
  const fields = getFields(role);

  return pick([...fields, ROLE_KEY], values);
};

export const filterStoreAdminFields = values => {
  const role = values[ROLE_KEY];
  const fields = getFields(role);

  return pick([...fields, ROLE_KEY, STORE_IDS_KEY], values);
};
