import PropTypes from 'prop-types';
import React from 'react';

import ButtonCancel from '@/components/ActionButtons/Cancel';
import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import FieldRow from '@/components/FieldRow';
import { NAME_KEY } from '@/features/personnel/consts/keys';

const fieldNames = {
  [NAME_KEY]: 'Имя пользователя',
};

const ButtonsField = ({ SubmitButton, RequiredMessages, classes }) => (
  <FieldRow>
    <ButtonSet>
      <ButtonCancel>Отменить</ButtonCancel>
      <SubmitButton
        Target={AsyncButton}
        type="submit"
        color="primary"
        size="large"
        variant="contained"
      >
        Сохранить изменения
      </SubmitButton>
    </ButtonSet>
    <div className={classes.requiredMessagesWrapper}>
      <RequiredMessages fieldNames={fieldNames} />
    </div>
  </FieldRow>
);

ButtonsField.propTypes = {
  SubmitButton: PropTypes.func.isRequired,
  RequiredMessages: PropTypes.func.isRequired,
};

export default ButtonsField;
