import { CssBaseline, withStyles } from '@material-ui/core';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router';

import CheckAccessRoute from '@/components/CheckAccessRoute';
import { NotFound } from '@/components/ErrorMessages';
import ProtectedRoute from '@/components/ProtectedRoute';
import Sidebar from '@/components/Sidebar';
import Switch from '@/components/Switch';
import { ROUTE_PATH as APP_REDIRECT_PATH } from '@/features/appRedirectPage/consts';
import AppRedirect from '@/features/appRedirectPage/Page';
import { ROUTE_PATH as ARCHIVE_BALANCE_PATH } from '@/features/archiveBalancePage/consts';
import ArchiveBalance from '@/features/archiveBalancePage/Page';
import {
  FORGOT_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
} from '@/features/auth/consts';
import ForgotPassword from '@/features/authPages/ForgotPassword';
import Login from '@/features/authPages/LoginPage';
import ResetPassword from '@/features/authPages/ResetPassword';
import {
  NEW_BENEFIT_ROUTE_PATH,
  STEP_ROUTE_PATH as CREATE_BENEFIT_STEP_PATH,
} from '@/features/benefitCreatePage/consts';
import EditBenefit from '@/features/benefitCreatePage/EditPage';
import NewBenefit from '@/features/benefitCreatePage/NewPage';
import { ROUTE_PATH as BENEFIT_GIVE_OUT_PATH } from '@/features/benefitGiveOutPage/consts';
import GiveOutBenefit from '@/features/benefitGiveOutPage/Page';
import { canGiveOutBenefit } from '@/features/benefitGiveOutPage/permissions';
import { ROUTE_PATH as BENEFIT_ITEMS_PATH } from '@/features/benefitItemsPage/consts';
import BenefitItems from '@/features/benefitItemsPage/Page';
import { ROUTE_PATH as BENEFITS_PATH } from '@/features/benefitsPage/consts';
import Benefits from '@/features/benefitsPage/Page';
import {
  canCreateBenefit,
  canReadBenefits,
} from '@/features/benefitsPage/permissions';
import { ROUTE_PATH as BITRIX_PATH } from '@/features/bitrixPage/consts';
import Bitrix from '@/features/bitrixPage/Page';
import { canBitrix } from '@/features/bitrixPage/permissions';
import { ROUTE_PATH as BONUSES_HISTORY_PATH } from '@/features/bonusesHistoryPage/consts';
import BonusesHistory from '@/features/bonusesHistoryPage/Page';
import { ROUTE_PATH as BONUS_TRANSACTION_LEVEL_HISTORY_PATH } from '@/features/bonusTransactionLevelHistoryPage/consts';
import BonusTransactionLevelHistoryPage from '@/features/bonusTransactionLevelHistoryPage/Page';
import { canReadBonusTransactionLevelHistory } from '@/features/bonusTransactionLevelHistoryPage/permissions.js';
import { ROUTE_PATH as BONUS_PATH } from '@/features/bonusTransferPage/consts';
import BonusTransferPage from '@/features/bonusTransferPage/Page';
import { canTransferBonuses } from '@/features/bonusTransferPage/permissions.js';
import { STEP_ROUTE_PATH as CREATE_CAMPAIGN_STEP_PATH } from '@/features/campaignCreatePage/consts';
import EditCampaign from '@/features/campaignCreatePage/EditPage';
import NewCampaign from '@/features/campaignCreatePage/NewPage';
import NewCampaignUserFilter from '@/features/campaignCreateUserFilterPage';
import { ROUTE_PATH as CAMPAIGN_PATH } from '@/features/campaignPage/consts';
import Campaign from '@/features/campaignPage/Page';
import { ROUTE_PATH as CAMPAIGNS_PATH } from '@/features/campaignsPage/consts';
import Campaigns from '@/features/campaignsPage/Page';
import {
  canCreateCampaign,
  canReadCampaigns,
} from '@/features/campaignsPage/permissions';
import { ROUTE_PATH as CAR_LIST_PATH } from '@/features/carListPage/consts';
import CarListPage from '@/features/carListPage/Page';
import CommissarAnswer from '@/features/commissarAnswerPage/component';
import { ROUTE_PATH as COMMISSAR_ANSWER_PATH } from '@/features/commissarAnswerPage/consts';
import { ROUTE_PATH as DOCUMENTS_PATH } from '@/features/documentsPage/consts';
import DocumentsPage from '@/features/documentsPage/Page';
import { ROUTE_PATH as EFS_PARKING_PATH } from '@/features/efsParkingPage/consts';
import EfsParking from '@/features/efsParkingPage/Page';
import { ROUTE_PATH as EFS_SALES_PATH } from '@/features/efsSalesPage/consts';
import EfsSales from '@/features/efsSalesPage/Page';
import { canEfsSales } from '@/features/efsSalesPage/permissions';
import { ROUTE_PATH as EFS_USERS_PATH } from '@/features/efsUsersPage/consts';
import EfsUsers from '@/features/efsUsersPage/Page';
import { ROUTE_PATH as EFS_VISIT_RECORDS_PATH } from '@/features/efsVisitRecordsPage/consts';
import EfsVisitRecordsPage from '@/features/efsVisitRecordsPage/Page';
import { canReadEfsVisitRecords } from '@/features/efsVisitRecordsPage/permissions';
import { ROUTE_PATH as EXECUTIVE_REPORTS_PATH } from '@/features/executiveReportsPage/consts';
import ExecutiveReports from '@/features/executiveReportsPage/Page';
import { canReadMallStats } from '@/features/executiveReportsPage/permissions';
import { ROUTE_PATH as GIFT_CERTIFICATES_PATH } from '@/features/giftCertificatesPage/consts';
import GiftCertificates from '@/features/giftCertificatesPage/Page';
import { canReadGiftCertificates } from '@/features/giftCertificatesPage/permissions';
import { ROUTE_PATH as GIFT_SETS_ARRANGEMENT_PATH } from '@/features/giftSetArrangementPage/consts';
import GiftSetsArrangement from '@/features/giftSetArrangementPage/Page';
import EditGiftSet from '@/features/giftSetEditPage/Page';
import { ROUTE_PATH as GIFT_SET_PATH } from '@/features/giftSetPage/consts';
import GiftSet from '@/features/giftSetPage/Page';
import { ROUTE_PATH as GIFT_SETS_PATH } from '@/features/giftSetsPage/consts';
import GiftSets from '@/features/giftSetsPage/Page';
import { ROUTE_PATH as GUEST_EFS_PATH } from '@/features/guestEfsPage/consts';
import GuestEfs from '@/features/guestEfsPage/Page';
import { canGuestEfs } from '@/features/guestEfsPage/permissions';
import { ROUTE_PATH as GUEST_PARKING_PATH } from '@/features/guestParkingPage/consts';
import GuestParking from '@/features/guestParkingPage/Page';
import { canGuestParking } from '@/features/guestParkingPage/permissions';
import Home from '@/features/homePage/Page';
import Invite from '@/features/invitePages/Invite';
import { ROUTE_PATH as INVITES_PATH } from '@/features/invitePages/Invite/consts';
import InviteError from '@/features/invitePages/InviteError';
import { ROUTE_PATH as INVITE_ERROR_ROOT_PATH } from '@/features/invitePages/InviteError/consts';
import MakeInvite from '@/features/invitePages/MakeInvite';
import { ROUTE_PATH as MAKE_INVITE_PATH } from '@/features/invitePages/MakeInvite/consts';
import { canCreateInvite } from '@/features/invites/permissions';
import { ROUTE_PATH as IPN_REPORTS_PATH } from '@/features/ipnReportsPage/consts';
import IpnReports from '@/features/ipnReportsPage/Page';
import CreateKkm from '@/features/kkmCreatePage/Page';
import EditKkm from '@/features/kkmEditPage/Page';
import { ROUTE_PATH as KKM_PATH } from '@/features/kkmPage/consts';
import Kkm from '@/features/kkmPage/Page';
import { ROUTE_PATH as KKMS_PATH } from '@/features/kkmsPage/consts';
import Kkms from '@/features/kkmsPage/Page';
import { canReadKkms, canUpdateKkm } from '@/features/kkmsPage/permissions';
import EditLocation from '@/features/locationEditPage/Page';
import { ROUTE_PATH as LOCATIONS_PATH } from '@/features/locationsPage/consts';
import { canUpdateLocation } from '@/features/locationsPage/permissions';
import { ROUTE_PATH as LOYALTY_HISTORY_PATH } from '@/features/loyaltyHistoryPage/consts';
import LoyaltyHistory from '@/features/loyaltyHistoryPage/Page';
import {
  NEW_MALL_BONUS_ROUTE_PATH,
  STEP_ROUTE_PATH as CREATE_MALL_BONUS_STEP_PATH,
} from '@/features/mallBonusCreatePage/consts';
import EditMallBonus from '@/features/mallBonusCreatePage/EditPage';
import NewMallBonus from '@/features/mallBonusCreatePage/NewPage';
import { ROUTE_PATH as MALL_BONUSES_PATH } from '@/features/mallBonusesPage/consts';
import MallBonuses from '@/features/mallBonusesPage/Page';
import { canReadMallBonuses } from '@/features/mallBonusesPage/permissions';
import { ROUTE_PATH as MALL_RECEPTION_PATH } from '@/features/mallReceptionPage/consts';
import MallReception from '@/features/mallReceptionPage/Page';
import { canMallReception } from '@/features/mallReceptionPage/permissions';
import { ROUTE_PATH as MALL_RECEPTION_USERS_PATH } from '@/features/mallReceptionUsersPage/consts';
import MallReceptionUsers from '@/features/mallReceptionUsersPage/Page';
import { ROUTE_PATH as MALL_VALET_PATH } from '@/features/mallValetPage/consts';
import MallValet from '@/features/mallValetPage/Page';
import { canMallValet } from '@/features/mallValetPage/permissions';
import CreateMerchant from '@/features/merchantCreatePage/Page';
import EditMerchant from '@/features/merchantEditPage/Page';
import { ROUTE_PATH as MERCHANT_PATH } from '@/features/merchantPage/consts';
import Merchant from '@/features/merchantPage/Page';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';
import Merchants from '@/features/merchantsPage/Page';
import { canUpdateMerchant } from '@/features/merchantsPage/permissions';
import { ROUTE_PATH as MERCHANTS_RECONCILIATION_ARCHIVE_PATH } from '@/features/merchantsReconciliationArchivePage/consts';
import merchantsReconciliationArchive from '@/features/merchantsReconciliationArchivePage/Page';
import { ROUTE_PATH as MERCHANTS_RECONCILIATION_DETAILS_PATH } from '@/features/merchantsReconciliationDetailsPage/consts';
import merchantsReconciliationDetails from '@/features/merchantsReconciliationDetailsPage/Page';
import { ROUTE_PATH as MERCHANTS_RECONCILIATION_PATH } from '@/features/merchantsReconciliationPage/consts';
import merchantsReconciliation from '@/features/merchantsReconciliationPage/Page';
import { ROUTE_PATH as MERCHANTS_RECONCILIATION_RECEIPTS_PATH } from '@/features/merchantsReconciliationReceiptsPage/consts';
import merchantsReconciliationReceiptsPage from '@/features/merchantsReconciliationReceiptsPage/Page';
import { ROUTE_PATH as MERCHANTS_RECONCILIATION_REFUNDS_PATH } from '@/features/merchantsReconciliationRefundsPage/consts';
import merchantsReconciliationRefundsPage from '@/features/merchantsReconciliationRefundsPage/Page';
import { ROUTE_PATH as PARKING_LEVEL_THREE_WHITELIST_ADD_PATH } from '@/features/parkingLevelThreeWhitelistAddPage/consts';
import ParkingLevelThreeWhitelistAddPage from '@/features/parkingLevelThreeWhitelistAddPage/Page';
import { ROUTE_PATH as PARKING_LEVEL_THREE_WHITELIST_PATH } from '@/features/parkingLevelThreeWhitelistPage/consts';
import ParkingLevelThreeWhitelistPage from '@/features/parkingLevelThreeWhitelistPage/Page';
import { canReadLevelThreeWhitelist } from '@/features/parkingLevelThreeWhitelistPage/permissions';
import { ROUTE_PATH as PARKING_PATH } from '@/features/parkingPage/consts';
import ParkingPage from '@/features/parkingPage/Page';
import { ROUTE_PATH as PARKING_TOWER_STOP_LIST_ADD_PATH } from '@/features/parkingTowerStopListAddPage/consts';
import ParkingTowerStopListAddPage from '@/features/parkingTowerStopListAddPage/Page';
import { canReadParkingTowerStopListAdd } from '@/features/parkingTowerStopListAddPage/permissions';
import { ROUTE_PATH as PARKING_TOWER_STOP_LIST_PATH } from '@/features/parkingTowerStopListPage/consts';
import ParkingTowerStopListPage from '@/features/parkingTowerStopListPage/Page';
import { canReadParkingStopList } from '@/features/parkingTowerStopListPage/permissions';
import { ROUTE_PATH as PARKING_WHITELIST_DYNAMIC_FILTER_PATH } from '@/features/parkingWhitelistDynamicFilterPage/consts';
import ParkingWhitelistDynamicFilterPage from '@/features/parkingWhitelistDynamicFilterPage/Page';
import { canReadWhitelistFilter as canReadWhitelistDynamicFilter } from '@/features/parkingWhitelistDynamicFilterPage/permissions';
import { ROUTE_PATH as PARKING_WHITELIST_GROUP_CREATE_PATH } from '@/features/parkingWhitelistGroupCreatePage/consts';
import ParkingWhitelistGroupCreatePage from '@/features/parkingWhitelistGroupCreatePage/Page';
import { ROUTE_PATH as ROUTE_PARKING_GROUP_DETAILS_PAGE } from '@/features/parkingWhitelistGroupDetailsPage/consts';
import ParkingWhitelistGroupDetailsPage from '@/features/parkingWhitelistGroupDetailsPage/Page';
import { ROUTE_PATH as PARKING_WHITELIST_GROUPS_PATH } from '@/features/parkingWhitelistGroupsPage/consts';
import ParkingWhitelistGroupsPage from '@/features/parkingWhitelistGroupsPage/Page';
import { canCreateWhitelistGroups } from '@/features/parkingWhitelistGroupsPage/permissions';
import { ROUTE_PATH as PARKING_WHITELIST_PATH } from '@/features/parkingWhitelistPage/consts';
import ParkingWhitelistPage from '@/features/parkingWhitelistPage/Page';
import { canReadWhitelist } from '@/features/parkingWhitelistPage/permissions';
import { ROUTE_PATH as PARKING_WHITELIST_FILTER_PATH } from '@/features/parkingWhitelistStaticFilterPage/consts';
import ParkingWhitelistStaticFilterPage from '@/features/parkingWhitelistStaticFilterPage/Page';
import { canReadWhitelistFilter } from '@/features/parkingWhitelistStaticFilterPage/permissions';
import {
  canReadPersonnel,
  canUpdatePersonnel,
} from '@/features/personnel/permissions';
import { ROUTE_PATH as PERSONNEL_EDIT_PATH } from '@/features/personnelEditPage/consts';
import EditPersonnel from '@/features/personnelEditPage/Page';
import { ROUTE_PATH as PERSONNEL_PATH } from '@/features/personnelPage/consts';
import Personnel from '@/features/personnelPage/Page';
import EditProduct from '@/features/productEditPage/Page';
import { ROUTE_PATH as PRODUCT_PATH } from '@/features/productPage/consts';
import Product from '@/features/productPage/Page';
import { ROUTE_PATH as PRODUCTS_ARRANGEMENT_PATH } from '@/features/productsArrangementPage/consts';
import ProductArrangement from '@/features/productsArrangementPage/Page';
import { ROUTE_PATH as PRODUCTS_CATEGORY_PATH } from '@/features/productsCategoryPage/consts';
import ProductsCategory from '@/features/productsCategoryPage/Page';
import { canReadProductsCategories } from '@/features/productsCategoryPage/permissions';
import { ROUTE_PATH as PRODUCTS_PATH } from '@/features/productsPage/consts';
import Products from '@/features/productsPage/Page';
import { canReadProducts } from '@/features/productsPage/permissions';
import ProductsView from '@/features/productsViewPage/Page';
import { ROUTE_PATH as PURCHASE_HISTORY_PATH } from '@/features/purchaseHistoryPage/consts';
import PurchaseHistory from '@/features/purchaseHistoryPage/Page';
import { canReadPurchases } from '@/features/purchaseHistoryPage/permissions';
import { ROUTE_PATH as PURCHASES_WITHOUT_SCAN_PATH } from '@/features/purchaseWithoutScanPage/consts';
import PurchaseWithoutScanPage from '@/features/purchaseWithoutScanPage/Page';
import { ROUTE_PATH as RECONCILIATION_ARCHIVE } from '@/features/reconciliationArchivePage/consts';
import ReconciliationArchive from '@/features/reconciliationArchivePage/Page';
import { ROUTE_PATH as RECONCILIATION_ARCHIVE_RECEIPTS } from '@/features/reconciliationArchiveReceiptsPage/consts';
import ReconciliationArchiveReceipts from '@/features/reconciliationArchiveReceiptsPage/Page';
import { ROUTE_PATH as RECONCILIATION_ARCHIVE_REFUNDS } from '@/features/reconciliationArchiveRefundsPage/consts';
import ReconciliationArchiveRefunds from '@/features/reconciliationArchiveRefundsPage/Page';
import { ROUTE_PATH as RECONCILIATION_HISTORY_PATH } from '@/features/reconciliationHistoryPage/consts';
import ReconciliationHistory from '@/features/reconciliationHistoryPage/Page';
import { canReadReconciliationHistory } from '@/features/reconciliationHistoryPage/permissions';
import { ROUTE_PATH as RECONCILIATION_PATH } from '@/features/reconciliationPage/consts';
import Reconciliation from '@/features/reconciliationPage/Page';
import { canReadReconciliation } from '@/features/reconciliationPage/permissions';
import { ROUTE_PATH as RECONCILIATION_PERIOD_BONUS_PATH } from '@/features/reconciliationPeriodBonusDetailsPage/consts';
import ReconciliationPeriodBonusDetails from '@/features/reconciliationPeriodBonusDetailsPage/Page';
import { canReadReconciliationPeriodBonusDetails } from '@/features/reconciliationPeriodBonusDetailsPage/permissions';
import { ROUTE_PATH as RECONCILIATION_PROMOTIONS_PATH } from '@/features/reconciliationPromotionsPage/consts';
import ReconciliationPromotions from '@/features/reconciliationPromotionsPage/Page';
import { ROUTE_PATH as RECONCILIATION_RECEIPT_BONUS_PATH } from '@/features/reconciliationReceiptBonusDetailsPage/consts';
import ReconciliationReceiptBonusDetails from '@/features/reconciliationReceiptBonusDetailsPage/Page';
import { canReadReconciliationReceiptBonusDetails } from '@/features/reconciliationReceiptBonusDetailsPage/permissions';
import {
  ARCHIVE_ROUTE_PATH as ARCHIVE_RECEIPTS_PATH,
  ROUTE_PATH as RECONCILIATION_RECEIPTS_PATH,
} from '@/features/reconciliationReceiptsPage/consts';
import ReconciliationReceipts from '@/features/reconciliationReceiptsPage/Page';
import { canReadReconciliationReceipts } from '@/features/reconciliationReceiptsPage/permissions';
import {
  ARCHIVE_ROUTE_PATH as ARCHIVE_REFUNDS_PATH,
  ROUTE_PATH as RECONCILIATION_REFUNDS_PATH,
} from '@/features/reconciliationRefundsPage/consts';
import reconciliationRefunds from '@/features/reconciliationRefundsPage/Page';
import { canReadReconciliationRefunds } from '@/features/reconciliationRefundsPage/permissions';
import { ROUTE_PATH as RECONCILIATION_STORE_BONUS_PATH } from '@/features/reconciliationStoreBonusDetailsPage/consts';
import ReconciliationStoreBonusDetails from '@/features/reconciliationStoreBonusDetailsPage/Page';
import { canReadReconciliationStoreBonusDetails } from '@/features/reconciliationStoreBonusDetailsPage/permissions';
import { ROUTE_PATH as RECONCILIATION_STORE_CP_PATH } from '@/features/reconciliationStoreToCpPage/consts';
import ReconciliationStoreToCp from '@/features/reconciliationStoreToCpPage/Page';
import { canReadReconciliationStoreToCp } from '@/features/reconciliationStoreToCpPage/permissions';
import { ROUTE_PATH as SALES_TURNOVER_DETAILS_PATH } from '@/features/salesTurnoverDetailsPage/consts';
import SalesTurnoverDetails from '@/features/salesTurnoverDetailsPage/Page';
import { ROUTE_PATH as SALES_TURNOVER_PATH } from '@/features/salesTurnoversPage/consts';
import SalesTurnovers from '@/features/salesTurnoversPage/Page';
import { canReadSalesTurnovers } from '@/features/salesTurnoversPage/permissions';
import { ROUTE_PATH as SETTINGS_PATH } from '@/features/settingsPage/consts';
import Settings from '@/features/settingsPage/Page';
import {
  ROUTE_PATH as STATISTICS_PATH,
  ROUTE_STAT_PATH as STATISTICS_STAT_PATH,
} from '@/features/statisticsPage/consts';
import Statistics from '@/features/statisticsPage/Page';
import {
  NEW_STORE_BONUS_ROUTE_PATH,
  STEP_ROUTE_PATH as CREATE_STORE_BONUS_STEP_PATH,
} from '@/features/storeBonusCreatePage/consts';
import EditStoreBonus from '@/features/storeBonusCreatePage/EditPage';
import NewStoreBonus from '@/features/storeBonusCreatePage/NewPage';
import { ROUTE_PATH as STORE_BONUSES_PATH } from '@/features/storeBonusesPage/consts';
import StoreBonuses from '@/features/storeBonusesPage/Page';
import { canReadStoreBonuses } from '@/features/storeBonusesPage/permissions';
import CreateStore from '@/features/storeCreatePage/Page';
import EditStore from '@/features/storeEditPage/Page';
import { ROUTE_PATH as STORE_PATH } from '@/features/storePage/consts';
import Store from '@/features/storePage/Page';
import { ROUTE_PATH as STORE_RECEIPTS_PATH } from '@/features/storeReceiptsPage/consts';
import StoreReceipts from '@/features/storeReceiptsPage/Page';
import { ROUTE_PATH as STORES_PATH } from '@/features/storesPage/consts';
import Stores from '@/features/storesPage/Page';
import {
  canReadStores,
  canUpdateStore,
} from '@/features/storesPage/permissions';
import { ROUTE_PATH as TIMIRYAZEV_PARKING_PAGE_PATH } from '@/features/timiryazevParkingPage/consts';
import TimiryazevParkingPage from '@/features/timiryazevParkingPage/Page';
import UserDeactivateCancelSnackBar from '@/features/userDeactivate/CancelSnackbar';
import User from '@/features/userPage/Page';
import { ROUTE_PATH as USERS_PATH } from '@/features/usersPage/consts';
import Users from '@/features/usersPage/Page';
import { canReadUser, canReadUsers } from '@/features/usersPage/permissions';
import { ROUTE_PATH as VALET_PARKING_PATH } from '@/features/valetParkingPage/consts';
import ValetParkingPage from '@/features/valetParkingPage/Page';
import history from '@/utils/history';

import styles from './styles';

const Root = ({ classes, authorized, canViewHeader }) => (
  <ConnectedRouter history={history}>
    <div
      className={`${classes.wrapper} ${
        authorized && canViewHeader ? '' : classes.centered
      }`}
    >
      <CssBaseline />
      <div style={{ zIndex: 300 }}>
        {authorized && canViewHeader ? <Sidebar /> : null}
      </div>
      <UserDeactivateCancelSnackBar />

      <div className={classes.fullWidth}>
        <Switch>
          <ProtectedRoute exact path="/" component={Home} />
          <Route path={APP_REDIRECT_PATH} component={AppRedirect} />
          <CheckAccessRoute
            path={CREATE_BENEFIT_STEP_PATH}
            component={EditBenefit}
            permission={canCreateBenefit}
          />
          <CheckAccessRoute
            path={NEW_BENEFIT_ROUTE_PATH}
            component={NewBenefit}
            permission={canCreateBenefit}
          />
          <CheckAccessRoute
            path={BENEFITS_PATH}
            component={Benefits}
            permission={canReadBenefits}
          />
          <CheckAccessRoute
            path={BENEFIT_ITEMS_PATH}
            component={BenefitItems}
            permission={canReadBenefits}
          />
          <CheckAccessRoute
            path={CREATE_MALL_BONUS_STEP_PATH}
            component={EditMallBonus}
            permission={canUpdateKkm}
          />
          <CheckAccessRoute
            path={CREATE_STORE_BONUS_STEP_PATH}
            component={EditStoreBonus}
            permission={canUpdateKkm}
          />
          <CheckAccessRoute
            path={NEW_MALL_BONUS_ROUTE_PATH}
            component={NewMallBonus}
            permission={canUpdateKkm}
          />
          <CheckAccessRoute
            path={NEW_STORE_BONUS_ROUTE_PATH}
            component={NewStoreBonus}
            permission={canUpdateKkm}
          />
          <CheckAccessRoute
            path={BONUS_TRANSACTION_LEVEL_HISTORY_PATH}
            component={BonusTransactionLevelHistoryPage}
            permission={canReadBonusTransactionLevelHistory}
          />
          <CheckAccessRoute
            path={CREATE_CAMPAIGN_STEP_PATH}
            component={EditCampaign}
            permission={canCreateCampaign}
          />
          <CheckAccessRoute
            path={CAMPAIGN_PATH}
            component={Campaign}
            permission={canReadCampaigns}
          />
          <CheckAccessRoute
            path={`${CAMPAIGNS_PATH}/new`}
            component={NewCampaign}
            permission={canCreateCampaign}
          />
          <CheckAccessRoute
            path={`${CAMPAIGNS_PATH}/new-filter`}
            component={NewCampaignUserFilter}
            permission={canCreateCampaign}
          />
          <CheckAccessRoute
            path={CAMPAIGNS_PATH}
            component={Campaigns}
            permission={canReadCampaigns}
          />
          <CheckAccessRoute
            path={MAKE_INVITE_PATH}
            component={MakeInvite}
            permission={canCreateInvite}
          />
          <Route path={`${INVITES_PATH}/:token`} component={Invite} />
          <Route
            path={`${INVITE_ERROR_ROOT_PATH}/:errorCode`}
            component={InviteError}
          />
          <CheckAccessRoute
            path={PARKING_WHITELIST_FILTER_PATH}
            component={ParkingWhitelistStaticFilterPage}
            permission={canReadWhitelistFilter}
          />
          <CheckAccessRoute
            path={PARKING_WHITELIST_DYNAMIC_FILTER_PATH}
            component={ParkingWhitelistDynamicFilterPage}
            permission={canReadWhitelistDynamicFilter}
          />
          <CheckAccessRoute
            path={ROUTE_PARKING_GROUP_DETAILS_PAGE}
            component={ParkingWhitelistGroupDetailsPage}
            permission={canCreateWhitelistGroups}
          />
          <CheckAccessRoute
            path={PARKING_WHITELIST_GROUP_CREATE_PATH}
            component={ParkingWhitelistGroupCreatePage}
            permission={canCreateWhitelistGroups}
          />
          <CheckAccessRoute
            path={PARKING_WHITELIST_GROUPS_PATH}
            component={ParkingWhitelistGroupsPage}
            permission={canCreateWhitelistGroups}
          />
          <CheckAccessRoute
            path={PARKING_TOWER_STOP_LIST_ADD_PATH}
            component={ParkingTowerStopListAddPage}
            permission={canReadParkingTowerStopListAdd}
          />
          <CheckAccessRoute
            path={PARKING_LEVEL_THREE_WHITELIST_ADD_PATH}
            component={ParkingLevelThreeWhitelistAddPage}
            permission={canReadLevelThreeWhitelist}
          />
          <CheckAccessRoute
            path={PARKING_LEVEL_THREE_WHITELIST_PATH}
            component={ParkingLevelThreeWhitelistPage}
            permission={canReadLevelThreeWhitelist}
          />
          <CheckAccessRoute
            path={PARKING_TOWER_STOP_LIST_PATH}
            component={ParkingTowerStopListPage}
            permission={canReadParkingStopList}
          />
          <CheckAccessRoute
            path={PARKING_WHITELIST_PATH}
            component={ParkingWhitelistPage}
            permission={canReadWhitelist}
          />
          <CheckAccessRoute
            path={PERSONNEL_EDIT_PATH}
            component={EditPersonnel}
            permission={canUpdatePersonnel}
          />
          <CheckAccessRoute
            path={PERSONNEL_PATH}
            component={Personnel}
            permission={canReadPersonnel}
          />
          <ProtectedRoute path={STATISTICS_STAT_PATH} component={Statistics} />
          <ProtectedRoute path={STATISTICS_PATH} component={Statistics} />
          <ProtectedRoute
            path={STORE_RECEIPTS_PATH}
            component={StoreReceipts}
          />
          <CheckAccessRoute
            path={`${MERCHANTS_PATH}/:merchantId/edit`}
            component={EditMerchant}
            permission={canUpdateMerchant}
          />
          <CheckAccessRoute
            path={`${MERCHANTS_PATH}/new`}
            component={CreateMerchant}
            permission={canUpdateMerchant}
          />
          <CheckAccessRoute
            path={`${LOCATIONS_PATH}/:locationId/edit`}
            component={EditLocation}
            permission={canUpdateLocation}
          />
          <CheckAccessRoute
            path={`${KKMS_PATH}/:kkmId/edit`}
            component={EditKkm}
            permission={canUpdateKkm}
          />
          <CheckAccessRoute
            path={`${PRODUCTS_PATH}/:productId/edit`}
            component={EditProduct}
            permission={canUpdateKkm}
          />
          <CheckAccessRoute
            path={`${KKMS_PATH}/new`}
            component={CreateKkm}
            permission={canUpdateKkm}
          />
          <CheckAccessRoute
            path={`${STORES_PATH}/new`}
            component={CreateStore}
            permission={canUpdateStore}
          />
          <CheckAccessRoute
            path={`${STORES_PATH}/:storeId/edit`}
            component={EditStore}
            permission={canUpdateStore}
          />
          <CheckAccessRoute
            path={PURCHASE_HISTORY_PATH}
            component={PurchaseHistory}
            permission={canReadPurchases}
          />
          <CheckAccessRoute
            path={EFS_VISIT_RECORDS_PATH}
            component={EfsVisitRecordsPage}
            permission={canReadEfsVisitRecords}
          />
          <CheckAccessRoute
            path={PURCHASES_WITHOUT_SCAN_PATH}
            component={PurchaseWithoutScanPage}
            permission={canReadPurchases}
          />
          <ProtectedRoute
            path={LOYALTY_HISTORY_PATH}
            component={LoyaltyHistory}
          />
          <ProtectedRoute
            path={BONUSES_HISTORY_PATH}
            component={BonusesHistory}
          />
          <ProtectedRoute path={PARKING_PATH} component={ParkingPage} />
          <ProtectedRoute
            path={TIMIRYAZEV_PARKING_PAGE_PATH}
            component={TimiryazevParkingPage}
          />
          <ProtectedRoute path={CAR_LIST_PATH} component={CarListPage} />
          <ProtectedRoute
            path={VALET_PARKING_PATH}
            component={ValetParkingPage}
          />
          <ProtectedRoute path={STORE_PATH} component={Store} />
          <ProtectedRoute path={MERCHANT_PATH} component={Merchant} />
          <CheckAccessRoute
            path={MERCHANTS_PATH}
            component={Merchants}
            permission={canReadStores}
          />
          <CheckAccessRoute
            path={MALL_BONUSES_PATH}
            component={MallBonuses}
            permission={canReadMallBonuses}
          />
          <CheckAccessRoute
            path={STORE_BONUSES_PATH}
            component={StoreBonuses}
            permission={canReadStoreBonuses}
          />
          <ProtectedRoute path={KKM_PATH} component={Kkm} />
          <ProtectedRoute path={PRODUCT_PATH} component={Product} />
          <CheckAccessRoute
            path={KKMS_PATH}
            component={Kkms}
            permission={canReadKkms}
          />
          <CheckAccessRoute
            path={PRODUCTS_PATH}
            component={Products}
            permission={canReadProducts}
          />
          <CheckAccessRoute
            path={PRODUCTS_ARRANGEMENT_PATH}
            component={ProductArrangement}
            permission={canReadProducts}
          />
          <CheckAccessRoute
            path={`${PRODUCTS_CATEGORY_PATH}/:categoryId`}
            component={ProductsView}
            permission={canReadProductsCategories}
          />
          <CheckAccessRoute
            path={PRODUCTS_CATEGORY_PATH}
            component={ProductsCategory}
            permission={canReadProductsCategories}
          />

          <CheckAccessRoute
            exact
            path={GIFT_SETS_PATH}
            component={GiftSets}
            permission={canReadProducts}
          />
          <ProtectedRoute exact path={GIFT_SET_PATH} component={GiftSet} />
          <CheckAccessRoute
            path={`${GIFT_SETS_PATH}/:giftSetId/edit`}
            component={EditGiftSet}
            permission={canReadKkms}
          />
          <CheckAccessRoute
            path={GIFT_SETS_ARRANGEMENT_PATH}
            component={GiftSetsArrangement}
            permission={canReadProducts}
          />
          <CheckAccessRoute
            path={STORES_PATH}
            component={Stores}
            permission={canReadStores}
          />
          <CheckAccessRoute
            path={`${USERS_PATH}/:userId`}
            component={User}
            permission={canReadUser}
          />
          <CheckAccessRoute
            path={USERS_PATH}
            component={Users}
            permission={canReadUsers}
          />
          <CheckAccessRoute
            path={BONUS_PATH}
            component={BonusTransferPage}
            permission={canTransferBonuses}
          />
          <ProtectedRoute path={SETTINGS_PATH} component={Settings} />
          <CheckAccessRoute
            path={`${BENEFIT_GIVE_OUT_PATH}/:token`}
            component={GiveOutBenefit}
            permission={canGiveOutBenefit}
          />
          <CheckAccessRoute
            path={BENEFIT_GIVE_OUT_PATH}
            component={GiveOutBenefit}
            permission={canGiveOutBenefit}
          />
          <CheckAccessRoute
            path={MALL_VALET_PATH}
            component={MallValet}
            permission={canMallValet}
          />
          <CheckAccessRoute
            path={EFS_SALES_PATH}
            component={EfsSales}
            permission={canEfsSales}
          />
          <ProtectedRoute path={EFS_USERS_PATH} component={EfsUsers} />
          <CheckAccessRoute
            path={EXECUTIVE_REPORTS_PATH}
            component={ExecutiveReports}
            permission={canReadMallStats}
          />
          <ProtectedRoute path={EFS_PARKING_PATH} component={EfsParking} />
          <ProtectedRoute path={IPN_REPORTS_PATH} component={IpnReports} />
          <CheckAccessRoute
            path={MALL_RECEPTION_PATH}
            component={MallReception}
            permission={canMallReception}
          />
          <CheckAccessRoute
            path={MALL_RECEPTION_USERS_PATH}
            component={MallReceptionUsers}
            permission={canMallReception}
          />
          <ProtectedRoute
            path={ARCHIVE_BALANCE_PATH}
            component={ArchiveBalance}
          />
          <CheckAccessRoute
            path={GUEST_PARKING_PATH}
            component={GuestParking}
            permission={canGuestParking}
          />
          <CheckAccessRoute
            path={GUEST_EFS_PATH}
            component={GuestEfs}
            permission={canGuestEfs}
          />
          <CheckAccessRoute
            path={BITRIX_PATH}
            component={Bitrix}
            permission={canBitrix}
          />
          <CheckAccessRoute
            path={RECONCILIATION_PATH}
            component={Reconciliation}
            permission={canReadReconciliation}
          />
          <CheckAccessRoute
            path={MERCHANTS_RECONCILIATION_PATH}
            component={merchantsReconciliation}
            permission={canReadReconciliation}
          />
          <CheckAccessRoute
            path={MERCHANTS_RECONCILIATION_DETAILS_PATH}
            component={merchantsReconciliationDetails}
            permission={canReadReconciliation}
          />
          <CheckAccessRoute
            path={MERCHANTS_RECONCILIATION_ARCHIVE_PATH}
            component={merchantsReconciliationArchive}
            permission={canReadReconciliation}
          />
          <CheckAccessRoute
            path={MERCHANTS_RECONCILIATION_RECEIPTS_PATH}
            component={merchantsReconciliationReceiptsPage}
            permission={canReadReconciliation}
          />
          <CheckAccessRoute
            path={MERCHANTS_RECONCILIATION_REFUNDS_PATH}
            component={merchantsReconciliationRefundsPage}
            permission={canReadReconciliation}
          />
          <CheckAccessRoute
            path={RECONCILIATION_STORE_CP_PATH}
            component={ReconciliationStoreToCp}
            permission={canReadReconciliationStoreToCp}
          />
          <CheckAccessRoute
            path={RECONCILIATION_STORE_BONUS_PATH}
            component={ReconciliationStoreBonusDetails}
            permission={canReadReconciliationStoreBonusDetails}
          />
          <CheckAccessRoute
            path={RECONCILIATION_HISTORY_PATH}
            component={ReconciliationHistory}
            permission={canReadReconciliationHistory}
          />
          <CheckAccessRoute
            path={RECONCILIATION_ARCHIVE}
            component={ReconciliationArchive}
            permission={canReadReconciliationHistory}
          />
          <CheckAccessRoute
            path={RECONCILIATION_ARCHIVE_REFUNDS}
            component={ReconciliationArchiveRefunds}
            permission={canReadReconciliation}
          />
          <CheckAccessRoute
            path={RECONCILIATION_ARCHIVE_RECEIPTS}
            component={ReconciliationArchiveReceipts}
            permission={canReadReconciliation}
          />
          <CheckAccessRoute
            path={RECONCILIATION_PROMOTIONS_PATH}
            component={ReconciliationPromotions}
            permission={canReadReconciliationStoreToCp}
          />
          <CheckAccessRoute
            path={RECONCILIATION_REFUNDS_PATH}
            component={reconciliationRefunds}
            permission={canReadReconciliationRefunds}
          />
          <CheckAccessRoute
            path={ARCHIVE_REFUNDS_PATH}
            component={reconciliationRefunds}
            permission={canReadReconciliationRefunds}
          />
          <CheckAccessRoute
            path={RECONCILIATION_RECEIPTS_PATH}
            component={ReconciliationReceipts}
            permission={canReadReconciliationReceipts}
          />
          <CheckAccessRoute
            path={RECONCILIATION_PERIOD_BONUS_PATH}
            component={ReconciliationPeriodBonusDetails}
            permission={canReadReconciliationPeriodBonusDetails}
          />
          <CheckAccessRoute
            path={ARCHIVE_RECEIPTS_PATH}
            component={ReconciliationReceipts}
            permission={canReadReconciliationReceipts}
          />
          <CheckAccessRoute
            path={RECONCILIATION_RECEIPT_BONUS_PATH}
            component={ReconciliationReceiptBonusDetails}
            permission={canReadReconciliationReceiptBonusDetails}
          />
          <CheckAccessRoute
            path={GIFT_CERTIFICATES_PATH}
            component={GiftCertificates}
            permission={canReadGiftCertificates}
          />
          <CheckAccessRoute
            path={SALES_TURNOVER_PATH}
            component={SalesTurnovers}
            permission={canReadSalesTurnovers}
          />
          <ProtectedRoute
            path={SALES_TURNOVER_DETAILS_PATH}
            component={SalesTurnoverDetails}
          />
          <ProtectedRoute path={DOCUMENTS_PATH} component={DocumentsPage} />
          <Route path={COMMISSAR_ANSWER_PATH} component={CommissarAnswer} />
          <Route path={LOGIN_PATH} component={Login} />
          <Route path={FORGOT_PATH} component={ForgotPassword} />
          <Route path={RESET_PASSWORD_PATH} component={ResetPassword} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  </ConnectedRouter>
);

Root.propTypes = {
  authorized: PropTypes.bool.isRequired,
  canViewHeader: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Root);
