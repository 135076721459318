import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  canMoveQuestionDown,
  canMoveQuestionUp,
} from '@/features/questionsPage/selectors';

import SortControls from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  canMoveQuestionDown: canMoveQuestionDown(state, itemId),
  canMoveQuestionUp: canMoveQuestionUp(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(SortControls);
