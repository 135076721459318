import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { clearSelection } from '@/features/mapPage/actions';
import {
  getBeaconIdFromRoute,
  getLocationIdFromRoute,
} from '@/features/mapPage/selectors';

import InfoBlock from './component';
import styles from './styles';

const mapStateToProps = state => {
  const beaconId = getBeaconIdFromRoute(state);
  const locationId = getLocationIdFromRoute(state);

  return {
    locationId,
    beaconId,
  };
};
const mapDispatchToProps = dispatch => ({
  clearSelection: () => dispatch(clearSelection()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(InfoBlock);
