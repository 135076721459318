import { TOTAL_TO_PAY_KEY } from '@esentai/core/features/reconciliation/consts/keys';
import { annotations } from '@esentai/core/features/reconciliation/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(TOTAL_TO_PAY_KEY),
  }),
});
