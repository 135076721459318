import Traverser from '@esentai/core/components/Traverser';
import React, { Component } from 'react';

class BackgroundTrimmer extends Component {
  render() {
    return (
      <Traverser {...this.props} traverseNode={this.ignoreBackgroundNode} />
    );
  }

  ignoreBackgroundNode = element => {
    if (
      element &&
      element.props &&
      element.props['data-name'] === 'Background'
    ) {
      return <g />;
    }

    return element;
  };
}

export default BackgroundTrimmer;
