import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';

import Form from './Form';

class Invite extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    findInvite: PropTypes.func.isRequired,
    confirmInvite: PropTypes.func.isRequired,
  };

  render() {
    const { token, confirmInvite } = this.props;

    return (
      <Page>
        <PageHeader gutterBottom={false}>
          <PageTitle gutterBottom>Подтверждение персонала</PageTitle>
        </PageHeader>
        <PageContent>
          <PaperSheet>
            <Form token={token} onSubmit={confirmInvite} />
          </PaperSheet>
        </PageContent>
      </Page>
    );
  }

  componentDidMount() {
    const { findInvite } = this.props;

    findInvite();
  }
}

export default Invite;
