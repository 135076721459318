import Traverser from '@esentai/core/components/Traverser';
import React, { cloneElement, Component } from 'react';

const PARASITE_AREAS_IDS = ['OUTLINE', 'Outline', 'Icons', 'Надписи'];
const AREA_ID_KEY = 'data-name';
const isParasiteId = id => PARASITE_AREAS_IDS.includes(id);

class ParasiteCursorAreasTrimmer extends Component {
  render() {
    return (
      <Traverser
        {...this.props}
        traverseNode={this.processParasiteCursorAreas}
      />
    );
  }

  processParasiteCursorAreas = element => {
    if (element && element.props && isParasiteId(element.props[AREA_ID_KEY])) {
      const { classes } = this.props;

      return cloneElement(element, {
        className: classes.ignoredArea,
        [AREA_ID_KEY]: null,
      });
    }

    return element;
  };
}

export default ParasiteCursorAreasTrimmer;
