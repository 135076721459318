import { push } from 'connected-react-router';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { getQueryParams } from '@/redux/router/selectors';

import { getEntityId } from './selectors';
import { CHANGE_ENTITY, CHANGE_STATS } from './types';
import {
  areStatisticsQueriesEqual,
  getEntityPageUrl,
  getStatPageUrl,
} from './utils';

export function createStatisticsQueryWatcher(duck, handlerSaga) {
  const { sagas } = duck;
  const { createQueryChangeWatcher } = sagas;

  let lastQuery = null;

  function* compareQueryAndRun(query) {
    if (!lastQuery || !areStatisticsQueriesEqual(query, lastQuery)) {
      yield call(handlerSaga, query);
    }

    lastQuery = query;
  }

  return createQueryChangeWatcher(compareQueryAndRun);
}

export function* changeEntity({ payload: entityId }) {
  const url = getEntityPageUrl(entityId);

  yield put(push(url));
}

export function* changeStats({ payload: statsIds }) {
  const entityId = yield select(getEntityId);
  const queryParams = yield select(getQueryParams);
  const url = getStatPageUrl(entityId, statsIds);

  yield put(push(`${url}${queryParams}`));
}

export default function*() {
  yield takeEvery(CHANGE_ENTITY, changeEntity);
  yield takeEvery(CHANGE_STATS, changeStats);
}
