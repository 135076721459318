export default {
  wrapper: {
    display: 'flex',
  },

  deviceChanges: {
    marginLeft: 'auto',
  },

  value: {
    fontWeight: 500,
  },

  label: {
    marginBottom: 38,
  },
};
