import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Autocomplete from '@/components/Autocomplete';

class SingleSelect extends Component {
  render() {
    const { onChange, options, inputProps, value, classes } = this.props;

    return (
      <div className={classes.root}>
        <Autocomplete
          isOpenOnFocus
          options={options}
          selectedItem={value}
          onChange={onChange}
          InputProps={{
            fullWidth: true,
            placeholder: 'Добавить фильтр',
            ...inputProps,
          }}
        />
      </div>
    );
  }
}

SingleSelect.defaultProps = {
  inputProps: {},
};

SingleSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputProps: PropTypes.object,
};

export default SingleSelect;
