import { path } from 'ramda';

import api from '@/api';
import { FEATURE_NAME } from '@/features/efsVisitRecordsPage/consts';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const downloadXls = (includeFilters = true) => {
  const urlParams = new URLSearchParams(window.location.search);
  const limit = Number(urlParams.get('limit')) || 10;
  const page = Number(urlParams.get('page'));
  const offset = page * limit;

  if (includeFilters) {
    urlParams.set('limit', limit.toString());
    urlParams.set('offset', offset.toString());
  } else {
    urlParams.delete('limit');
    urlParams.delete('offset');
  }

  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'id');
  }

  urlParams.delete('page');

  api
    .doBlobGet(`/purchase-history-xlsx-export?${urlParams.toString()}`)
    .then(response => {
      saveAs(response.data, `История покупок.xlsx`);
    });
};
