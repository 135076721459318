export default () => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  dimmed: {
    backgroundColor: '#F5F5F5',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#1e88e5',
  },
});
