import { Grid, Paper, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './styles';

const PageWrapper = ({ classes, children }) => (
  <Grid container justify="center" alignItems="center" className={classes.root}>
    <Grid item className={classes.rootItem} component={Paper}>
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles)(PageWrapper);
