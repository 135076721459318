import api from '@esentai/core/features/products/api';
import apiStores from '@esentai/core/features/stores/api';
import { Button, Checkbox, TextField, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import Dropzone from 'react-dropzone';

import AddLink from '@/components/AddLink';
import Breadcrumbs from '@/components/Breadcrumbs';
import ImageThumb from '@/components/ImageThumb';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PageTitleLink from '@/components/PageTitleLink';
import CanView from '@/containers/CanView';
import { ProductCategoryAutocomplete } from '@/containers/ProductCategoryAutocomplete';
import { ProductViewMultiAutocompleteWithCategory } from '@/containers/ProductViewAutocomplete';
import { StoreAutocomplete } from '@/containers/StoreAutocomplete';
import { isMallStaff } from '@/features/currentUser/selectors';
import { ROUTE_PATH as ARRANGEMENTS_PATH } from '@/features/productsArrangementPage/consts';
import apiUpload from '@/features/upload/api';

import TableItems from './Table';

const portal = document.createElement('div');

portal.classList.add('my-super-cool-portal');

if (!document.body) {
  throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

class Products extends Component {
  static propTypes = {
    error: PropTypes.any,
    productIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    updatePage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      newProducts: [],
      stores: [],
      productCategories: [],
    };
  }

  componentDidMount() {
    this.loadStores();
  }

  loadStores() {
    apiStores.doQuery([], 'id', 'asc', 9999).then(data => {
      this.setState({
        stores: data.payload.store.filter(
          s => !s.categories || !s.categories.includes('food'),
        ),
      });
    });
  }

  async save() {
    for (const prod of this.state.newProducts) {
      await api.create(prod);
    }
    this.setState({ newProducts: [] });
    this.props.updatePage(0);
  }

  async upload(files, index) {
    for (const file of files) {
      const { newProducts } = this.state;
      const previews = [...newProducts[index].previews];
      const urls = await apiUpload.getUrl(file.name);
      const { upload_url: url, download_url } = urls;

      await apiUpload.upload({ url, file });
      const pId = previews.findIndex(p => p.id === file.name);

      previews.splice(pId, 1);
      newProducts[index].images.push(download_url);
      newProducts[index].previews = previews;
      this.setState({ newProducts });
    }
  }

  renderProd(prod, ind, newProducts) {
    return (
      <>
        {prod.images.length === 0 && prod.previews.length === 0 && (
          <p>Перетащите изображения сюда</p>
        )}
        {prod.images.map((image, imgInd) => (
          <Draggable
            draggableId={`draggable-${image}`}
            index={imgInd}
            key={image}
            position="left"
          >
            {(providedDrag, snap) => {
              const el = (
                <div
                  ref={providedDrag.innerRef}
                  {...providedDrag.draggableProps}
                  {...providedDrag.dragHandleProps}
                >
                  <ImageThumb
                    url={image}
                    remove={() => {
                      newProducts[ind].images.splice(imgInd, 1);
                      this.setState({
                        newProducts,
                      });
                    }}
                  />
                </div>
              );

              if (!snap.isDragging) {
                return el;
              }

              return ReactDOM.createPortal(el, portal);
            }}
          </Draggable>
        ))}

        {prod.previews.map(prev => (
          <Grow in key={prev.id}>
            <ImageThumb url={prev.img} loading />
          </Grow>
        ))}
      </>
    );
  }

  dragEnd(result) {
    const { newProducts } = this.state;

    if (result.destination) {
      const dest = parseInt(
        result.destination.droppableId.replace('drop-', ''),
        10,
      );
      const source = parseInt(
        result.source.droppableId.replace('drop-', ''),
        10,
      );
      const [removed] = newProducts[source].images.splice(
        result.source.index,
        1,
      );

      newProducts[dest].images.splice(result.destination.index, 0, removed);
      this.setState({ newProducts });
    }
  }

  renderAdd() {
    const { classes } = this.props;
    const { newProducts } = this.state;

    return (
      <DragDropContext onDragEnd={result => this.dragEnd(result)}>
        <Typography variant="h6" gutterBottom>
          Новые товары
        </Typography>

        {newProducts.map((prod, ind) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grow in key={`prod-${ind}`}>
            <Card className={classes.card}>
              <div className={classes.prod}>
                <div className={classes.cell}>
                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Заголовок RU"
                      value={prod.title_ru}
                      // error={!prod.title_ru}
                      helperText={`${prod.title_ru.length}/200`}
                      onChange={e => {
                        if (e.target.value.length <= 200) {
                          newProducts[ind].title_ru = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>

                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Заголовок EN"
                      helperText={`${prod.title_en.length}/200`}
                      value={prod.title_en}
                      onChange={e => {
                        if (e.target.value.length <= 200) {
                          newProducts[ind].title_en = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>

                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Заголовок KZ"
                      helperText={`${prod.title_kz.length}/200`}
                      value={prod.title_kz}
                      onChange={e => {
                        if (e.target.value.length <= 200) {
                          newProducts[ind].title_kz = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className={classes.cell}>
                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Подзаголовок RU"
                      value={prod.subtitle_ru}
                      helperText={`${prod.subtitle_ru.length}/200`}
                      onChange={e => {
                        if (e.target.value.length <= 200) {
                          newProducts[ind].subtitle_ru = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>

                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Подзаголовок EN"
                      helperText={`${prod.subtitle_en.length}/200`}
                      value={prod.subtitle_en}
                      onChange={e => {
                        if (e.target.value.length <= 200) {
                          newProducts[ind].subtitle_en = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>

                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Подзаголовок KZ"
                      helperText={`${prod.subtitle_kz.length}/200`}
                      value={prod.subtitle_kz}
                      onChange={e => {
                        if (e.target.value.length <= 200) {
                          newProducts[ind].subtitle_kz = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className={classes.cell}>
                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Описание RU"
                      helperText={`${prod.description_ru.length}/500`}
                      value={prod.description_ru}
                      multiline
                      rows={4}
                      onChange={e => {
                        if (e.target.value.length <= 500) {
                          newProducts[ind].description_ru = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>

                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Описание EN"
                      helperText={`${prod.description_en.length}/500`}
                      value={prod.description_en}
                      multiline
                      rows={4}
                      onChange={e => {
                        if (e.target.value.length <= 500) {
                          newProducts[ind].description_en = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>

                  <div className={classes.third}>
                    <TextField
                      fullWidth
                      label="Описание KZ"
                      helperText={`${prod.description_kz.length}/500`}
                      value={prod.description_kz}
                      multiline
                      rows={4}
                      onChange={e => {
                        if (e.target.value.length <= 500) {
                          newProducts[ind].description_kz = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className={classes.cell}>
                  <div className={classes.quarter}>
                    <InputLabel>Магазин</InputLabel> &nbsp;
                    <StoreAutocomplete
                      onChange={val => {
                        newProducts[ind].store_id = val;
                        this.setState({ newProducts });
                      }}
                      reducer={payload => ({
                        store: payload.store.filter(
                          s => !s.categories || !s.categories.includes('food'),
                        ),
                      })}
                      selectedItem={prod.store_id}
                      InputProps={{
                        fullWidth: true,
                        placeholder: 'Добавьте магазин',
                        InputProps: { disableUnderline: true },
                      }}
                    />
                  </div>

                  <div className={classes.quarter}>
                    <InputLabel>Категория</InputLabel> &nbsp;
                    <ProductCategoryAutocomplete
                      onChange={val => {
                        newProducts[ind].category = val;
                        this.setState({ newProducts });
                        if (!val) {
                          newProducts[ind].view = null;
                          this.setState({ newProducts });
                        }
                      }}
                      selectedItem={prod.category}
                      InputProps={{
                        fullWidth: true,
                        placeholder: 'Выберите категорию',
                        InputProps: { disableUnderline: true },
                      }}
                    />
                  </div>

                  <div className={classes.quarter}>
                    <InputLabel>Вид(ы)</InputLabel> &nbsp;
                    {this.state.newProducts[ind].category ? (
                      <ProductViewMultiAutocompleteWithCategory
                        onChange={val => {
                          newProducts[ind].views = val;
                          this.setState({ newProducts });
                        }}
                        selectedItem={prod.views || []}
                        InputProps={{
                          fullWidth: true,
                          placeholder: 'Выберите вид(ы)',
                          InputProps: { disableUnderline: true },
                        }}
                        id={this.state.newProducts[ind].category}
                        categoryId={this.state.newProducts[ind].category}
                      />
                    ) : null}
                  </div>

                  <div className={classes.quarter}>
                    <TextField
                      fullWidth
                      label="Артикул"
                      value={prod.sku}
                      onChange={e => {
                        newProducts[ind].sku = e.target.value;
                        this.setState({ newProducts });
                      }}
                    />
                  </div>

                  <div className={classes.quarter}>
                    <TextField
                      fullWidth
                      label="Цена"
                      type="number"
                      value={prod.price}
                      onChange={e => {
                        const price = parseInt(e.target.value, 10);

                        if (
                          (price > 0 && price <= 100000000) ||
                          e.target.value === ''
                        ) {
                          newProducts[ind].price = e.target.value;
                          this.setState({ newProducts });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <Droppable droppableId={`drop-${ind}`} direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={{
                        backgroundColor: snapshot.isDraggingOver
                          ? '#eeeeee'
                          : '',
                      }}
                      {...provided.droppableProps}
                    >
                      <Dropzone
                        onDrop={async acceptedFiles => {
                          newProducts[ind].previews = [
                            ...newProducts[ind].previews,
                            ...acceptedFiles.map(file => ({
                              id: file.name,
                              img: URL.createObjectURL(file),
                            })),
                          ];
                          this.setState({ newProducts });
                          await this.upload(acceptedFiles, ind);
                        }}
                        noClick
                      >
                        {({ getRootProps, getInputProps, open }) => (
                          <section>
                            <div {...getRootProps()} className={classes.drop}>
                              <input {...getInputProps()} />
                              {this.renderProd(prod, ind, newProducts)}
                              {provided.placeholder}

                              <IconButton color="primary" onClick={open}>
                                <AddIcon />
                              </IconButton>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  )}
                </Droppable>
              </div>

              <div className={classes.cell}>
                <div className={classes.quarter}>
                  <Typography color="secondary" variant="subtitle1">
                    Отображение
                  </Typography>
                </div>
                <div className={classes.third}>
                  <Typography color="secondary" variant="subtitle1">
                    Отобразить товар после сохранения?
                  </Typography>
                  <Typography color="secondary" variant="caption">
                    Без включения этой настройки после нажатия кнопки
                    &quot;Сохранить&quot; товар не будет виден пользователям
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={prod.visible}
                        onChange={e => {
                          newProducts[ind].visible = e.target.checked;
                          this.setState({ newProducts });
                        }}
                      />
                    }
                    label="Показать товар пользователям"
                  />
                </div>
              </div>

              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                style={{ float: 'right' }}
                onClick={() => {
                  newProducts.splice(ind, 1);
                  this.setState({ newProducts });
                }}
              >
                <RemoveIcon />
              </Fab>
            </Card>
          </Grow>
        ))}

        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<SaveIcon />}
          disabled={newProducts.some(p => !p.store_id || !p.category)}
          onClick={async () => {
            await this.save();
          }}
        >
          Сохранить
        </Button>
      </DragDropContext>
    );
  }

  render() {
    const { error, productIds, isLoading, totalCount, classes } = this.props;
    const { newProducts } = this.state;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <Grid container alignItems="baseline">
            <PageTitle>Товары</PageTitle>
            <PageTitleLink to={ARRANGEMENTS_PATH}>
              Компоновка товаров
            </PageTitleLink>
          </Grid>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <CanView permission={isMallStaff}>
              <Tooltip title="Добавить новый товар">
                <AddLink
                  onClick={() => {
                    this.setState({
                      newProducts: [
                        ...newProducts,
                        {
                          title_ru: '',
                          title_en: '',
                          title_kz: '',

                          subtitle_ru: '',
                          subtitle_en: '',
                          subtitle_kz: '',

                          description_ru: '',
                          description_en: '',
                          description_kz: '',

                          price: 0,
                          sku: '',
                          images: [],
                          previews: [],
                          store_id: 0,
                          category: 0,
                          views: 0,
                          visible: false,
                        },
                      ],
                    });
                  }}
                />
              </Tooltip>
            </CanView>
            {newProducts.length > 0 && this.renderAdd()}

            <TableItems
              error={error}
              items={productIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default Products;
