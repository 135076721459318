import React from 'react';

import Countdown from '../Countdown';
import WarningMessageContent from '../WarningMessageContent';

const WarningMessage = ({ classes }) => (
  <div className={classes.root}>
    <Countdown classes={{ root: classes.countdown }} />
    <WarningMessageContent className={classes.content} />
  </div>
);

export default WarningMessage;
