import React from 'react';
import { Route } from 'react-router-dom';

import Switch from '@/components/Switch';
import DetailsPage from '@/features/campaignsStatisticsDetailsPage/Page';
import {
  DETAILS_ROUTE_PATH,
  ROUTE_PATH,
} from '@/features/campaignsStatisticsPage/consts';

import ListPage from './ListPage';

const Page = ({ ...props }) => (
  <Switch>
    <Route
      path={DETAILS_ROUTE_PATH}
      component={() => <DetailsPage {...props} />}
    />
    <Route path={ROUTE_PATH} component={() => <ListPage {...props} />} />
  </Switch>
);

export default Page;
