export default {
  text: {
    marginBottom: 14,
    fontFamily: 'Giorgio Sans',
    fontWeight: 'bold',
    fontSize: 27,
    lineHeight: 1.1,
    textTransform: 'uppercase',
    letterSpacing: 0.1,
    wordWrap: 'break-word',
  },
};
