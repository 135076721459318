import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Factoid = ({ classes, subtitle, title }) => (
  <div>
    <Typography variant="subtitle1" className={classes.title}>
      {title}
    </Typography>
    <Typography className={classes.subtitle}>{subtitle}</Typography>
  </div>
);

Factoid.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Factoid;
