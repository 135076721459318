import { connect } from 'react-redux';

import { getAuthorizedStatus } from '@/features/auth/selectors';

import Router from './component';
import { canViewHeader } from './permissions';

const mapStateToProps = state => ({
  authorized: getAuthorizedStatus(state),
  canViewHeader: canViewHeader(state),
});

export default connect(mapStateToProps)(Router);
