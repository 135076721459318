import { Typography as MuiTypography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './styles';

const Link = ({ disabled, Typography, ...props }) => (
  <Typography component={disabled ? 'span' : RouterLink} {...props} />
);

Link.propTypes = {
  disabled: PropTypes.bool,
  Typography: PropTypes.func,
};

Link.defaultProps = {
  disabled: false,
  Typography: MuiTypography,
};

export default withStyles(styles)(Link);
