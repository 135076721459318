import * as dsl from '@esentai/core/query-dsl';
import { compose, filter, gte, isNil, lt, not } from 'ramda';

import { createAdapter } from '@/structs/pageFilterAdapter';
import { formatNumber } from '@/utils/format';

const isPresent = compose(not, isNil);

export const createRangeAdapter = createAdapter(
  ({ from, to }) => {
    if (from !== undefined && to !== undefined) {
      return dsl.filter(dsl.gte(from), dsl.lte(to));
    } else if (from !== undefined) {
      return dsl.filter(dsl.gte(from));
    } else if (to !== undefined) {
      return dsl.filter(dsl.lte(to));
    }

    return dsl.filter();
  },
  filterValue => ({
    from: dsl.readGte(filterValue),
    to: dsl.readLte(filterValue),
  }),
);

export const swapIfNeed = ({ from, to }) => {
  if (from > to) {
    return { from: to, to: from };
  }

  return { from, to };
};

export const processNaturalNumberRange = swapIfNeed;

export const processPercentageRange = compose(
  filter(lt(0)),
  filter(gte(100)),
  swapIfNeed,
);

export const createFormatter = ({ format = formatNumber, unit = '' }) => ({
  value: { from, to },
}) => {
  if (isPresent(from) && isPresent(to)) {
    return `от ${format(from)} до ${format(to)} ${unit}`;
  } else if (isPresent(from)) {
    return `от ${format(from)} ${unit}`;
  } else if (isPresent(to)) {
    return `до ${format(to)} ${unit}`;
  }

  return null;
};
