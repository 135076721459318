import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import FileFiled from '@/components/FileFiled';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { isMallAdministrator } from '@/features/currentUser/selectors';

import Table from './Table';

const DocumentsPage = props => {
  const { classes, state } = props;
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const newFileInputProps = {
    accept:
      'application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };
  const [imgUrl, setImgUrl] = useState('');
  const [imgName, setImgName] = useState('');

  const [pageInfo, setPageInfo] = useState({
    limit: 10,
    totalCount: 0,
    page: 0,
    loading: false,
  });

  const loadDocumentsData = async (params = {}) => {
    setLoading(true);

    const searchParams = new URLSearchParams(window.location.search);

    const { limit, page } = pageInfo;

    const filters = {
      limit,
      offset: limit * page,
      sort: searchParams.get('sort'),
      ...params,
    };

    try {
      const response = await api.doGet('/document', { ...filters });

      setDocuments(response.payload.document);
      setPageInfo(prevState => ({
        ...prevState,
        totalCount: response.meta.total,
      }));
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const onSetPageSize = newPageSize => {
    setPageInfo(prevState => ({
      ...prevState,
      page: 0,
      limit: newPageSize,
    }));
  };

  const onSetPage = newPage => {
    setPageInfo(prevState => ({
      ...prevState,
      page: newPage,
    }));
  };

  const deleteFile = fileId => {
    setLoading(true);

    api
      .doDelete(`/document/${fileId}`)
      .then(() => {
        loadDocumentsData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadFile = fileId => {
    api
      .doGet(`/document/${fileId}`)
      .then(response => {
        const document = response.document[0];

        window.open(document.file_url);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setWindowData = () => {
    window.documentsPageData = {};
    window.documentsPageData = {
      deleteDocument: deleteFile,
      uploadDocument: uploadFile,
      isMallAdministrator: isMallAdministrator(state),
    };
  };

  const loadFile = urls => {
    setImgUrl(urls[0]);

    const payload = {
      file_url: urls[0],
      name: imgName,
    };

    api.doPost(`/document`, { document: payload }).then(() => {
      setImgUrl('');
      loadDocumentsData();
    });
  };

  useEffect(() => {
    loadDocumentsData();
    setWindowData();

    return () => {
      delete window.documentsPageData;
    };
  }, [pageInfo.limit, pageInfo.page]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>Документы </PageTitle>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        <div className={classes.buttonsWrapper}>
          {isMallAdministrator(state) && (
            <FileFiled
              ids={imgUrl ? [imgUrl] : []}
              onChange={urls => loadFile(urls)}
              onGetFileName={name => setImgName(name)}
              showUploadProgress={false}
              fileInputProps={newFileInputProps}
            />
          )}
        </div>
        <Table
          isLoading={loading}
          items={documents}
          limit={pageInfo.limit}
          totalCount={pageInfo.totalCount}
          page={pageInfo.page}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
      </PageContent>
    </Page>
  );
};

DocumentsPage.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  state: PropTypes.object,
};

DocumentsPage.defaultProps = {
  state: {},
};

export default DocumentsPage;
