export default {
  content: {
    textAlign: 'center',
  },

  title: {
    marginBottom: 5,
  },

  repairIcon: {
    marginBottom: 10,
  },

  retryButton: {
    height: 42,
    marginTop: 16,
  },
};
