import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getCampaignType,
  getScrollsCountTo25Percent,
  getScrollsCountTo50Percent,
  getScrollsCountTo75Percent,
  getScrollsCountTo100Percent,
} from '@/features/campaignsStatisticsDetailsPage/selectors';

import CampaignInvolvement from './component';
import styles from './styles';

const mapStateToProps = state => ({
  campaignType: getCampaignType(state),
  scrollCountTo25Percent: getScrollsCountTo25Percent(state),
  scrollCountTo50Percent: getScrollsCountTo50Percent(state),
  scrollCountTo75Percent: getScrollsCountTo75Percent(state),
  scrollCountTo100Percent: getScrollsCountTo100Percent(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CampaignInvolvement);
