export default {
  title: {
    marginBottom: 10,
  },

  facts: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  fact: {
    flexBasis: '33%',
    marginBottom: 10,
  },
};
