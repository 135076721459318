import { connect } from 'react-redux';

import {
  getTotalAveragePurchasesById,
  getTotalAverageReceiptsById,
  getTotalAverageTicketById,
  getTotalPercentageReceiptsByCampaignById,
  getTotalPurchasesCountById,
} from '@/features/purchasesStatisticsPage/selectors';

import TotalRow from './component';

const mapStateToProps = state => ({
  averagePurchases: getTotalAveragePurchasesById(state),
  averageReceipts: getTotalAverageReceiptsById(state),
  averageTicket: getTotalAverageTicketById(state),
  purchasesCount: getTotalPurchasesCountById(state),
  percentageReceiptsByCampaign: getTotalPercentageReceiptsByCampaignById(state),
});

export default connect(mapStateToProps)(TotalRow);
