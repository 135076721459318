import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { defaultProps } from 'recompose';

import SettingsPageTitle from '@/components/PageTitle';

import styles from './styles';

export default compose(
  defaultProps({ gutterBottom: true }),
  withStyles(styles),
)(SettingsPageTitle);
