import { Grid, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import { createMerchantDetailsPageUrl } from '@/features/merchantsReconciliationDetailsPage/utils';
// import { createHistoryPageUrl } from '@/features/reconciliationHistoryPage/utils';
import { formatMoney } from '@/utils/format';

import { GET_URL, PATCH_URL } from '../../consts';
import { canRepayReconciliation } from '../../permissions';
import FinishButton from './Actions/FinishButton';
import RepayReconciliationModal from './RepayReconciliationModal/RepayReconciliationModal';
// import HistoryButton from './Actions/HistoryButton';

const Row = ({
  classes,
  saldoAmount,
  companyName,
  totalPaidAmount,
  totalToPayAmount,
  merchantId,
  fetchItems,
}) => {
  const [isFinishDialogOpen, handleFinishModal] = useState(false);

  const setFinishModal = () => {
    handleFinishModal(!isFinishDialogOpen);
  };

  const payload = {
    merchant_reconciliation_payment: {
      merchant: 0,
      payment_file_url: '',
      paid: 0,
    },
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Link to={createMerchantDetailsPageUrl(merchantId)}>
            {companyName}
          </Link>
        </TableCell>
        <TableCell>{formatMoney(totalToPayAmount)}</TableCell>
        <TableCell>{formatMoney(totalPaidAmount)}</TableCell>
        <TableCell align={'center'}>{saldoAmount}</TableCell>
        <TableCell>
          <Grid className={classes.actions} container wrap="nowrap">
            <div className={classes.actionCell}>
              {/* <HistoryButton
                storeId={storeId}
                historyPageUrl={createHistoryPageUrl(storeId)}
              /> */}
              <CanView permission={canRepayReconciliation}>
                <FinishButton
                  finishDisabled={saldoAmount < 1}
                  handleDialog={setFinishModal}
                />
              </CanView>
            </div>
          </Grid>
        </TableCell>
      </TableRow>
      {isFinishDialogOpen && (
        <RepayReconciliationModal
          isOpen={isFinishDialogOpen}
          onClose={setFinishModal}
          fetchItems={fetchItems}
          url={PATCH_URL}
          getUrl={GET_URL}
          payload={payload}
          merchantId={merchantId}
        />
      )}
    </>
  );
};

Row.propTypes = {
  merchantId: PropTypes.number.isRequired,
  totalToPayAmount: PropTypes.number.isRequired,
  saldoAmount: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  totalPaidAmount: PropTypes.number.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default Row;
