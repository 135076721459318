import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';

import dao from './dao';
import { RESET_PASSWORD, TOGGLE_ACTIVITY } from './types';

export const {
  findById: findPersonalityById,
  update: updatePersonality,
  delete: deletePersonnel,
} = dao.actions;

export const resetPassword = createTaskAction(RESET_PASSWORD);

export const toggleActivity = createTaskAction(TOGGLE_ACTIVITY);
