import API from '@esentai/core/api';

class PersistentTokenAPI extends API {
  localStorage = window.localStorage;

  get token() {
    return this.localStorage.getItem('token');
  }

  set token(value) {
    if (value) {
      this.localStorage.setItem('token', value);
    } else {
      this.localStorage.removeItem('token');
    }
  }
}

export default PersistentTokenAPI;
