import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  ARCHIVED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import { Hidden, TableCell, TableRow, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React from 'react';

import Factoid from '@/components/Factoid';
import Link from '@/components/Link';
import TableCellTypography from '@/components/TableCellTypography';
import CanView from '@/containers/CanView';
import { createCampaignURL } from '@/features/campaignPage/utils';
import { typeLabel } from '@/features/campaignsPage/labels';
import { getPermissionForActions } from '@/features/campaignsPage/selectors';
import {
  formatDateRange,
  formatDateTimeRange,
  formatNumber,
  getCuttedText,
} from '@/utils/format';
import pluralize from '@/utils/pluralize';

import Actions from './Actions';
// import Locations from './Locations';
import Status from './Status';
import StatusTooltipIndicator from './StatusTooltipIndicator';

const pluralizeHumans = count =>
  pluralize(count, {
    one: 'человек',
    few: 'человека',
    many: 'человек',
  });

const Row = ({
  classes,
  dateStart,
  dateEnd,
  dateTimeStart,
  dateTimeEnd,
  itemId,
  segmentSize,
  status,
  title,
  type,
}) => (
  <TableRow
    className={classNames(classes.root, {
      [classes.active]: status === ACTIVE_STATUS || status === APPROVED_STATUS,
      [classes.archived]: status === ARCHIVED_STATUS,
    })}
  >
    <TableCell>
      <Link
        Typography={TableCellTypography}
        title={title}
        to={createCampaignURL(type, itemId)}
      >
        {getCuttedText(title, 40)}
      </Link>
      <Typography variant="caption">
        <Hidden xlUp>
          <StatusTooltipIndicator itemId={itemId} />{' '}
        </Hidden>
        {typeLabel(type)}
      </Typography>
    </TableCell>
    <Hidden lgDown>
      <TableCell>
        {!isNil(segmentSize) && (
          <Factoid
            title={formatNumber(segmentSize)}
            subtitle={pluralizeHumans(segmentSize)}
          />
        )}
      </TableCell>
    </Hidden>
    <TableCell>
      <Hidden lgDown>
        <Status itemId={itemId} />
      </Hidden>
      <Typography variant="caption">
        {dateTimeStart && dateTimeEnd
          ? formatDateTimeRange(dateTimeStart, dateTimeEnd)
          : formatDateRange(dateStart, dateEnd)}
      </Typography>
    </TableCell>
    {/* <TableCell>
      <Locations itemId={itemId} />
    </TableCell> */}
    <CanView permission={getPermissionForActions}>
      <TableCell>
        <Actions itemId={itemId} />
      </TableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  dateStart: PropTypes.instanceOf(Date).isRequired,
  dateTimeStart: PropTypes.instanceOf(Date).isRequired,
  dateTimeEnd: PropTypes.instanceOf(Date).isRequired,
  dateEnd: PropTypes.instanceOf(Date).isRequired,
  itemId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  segmentSize: PropTypes.number,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Row.defaultProps = {
  segmentSize: null,
};

export default Row;
