import { grey } from '@material-ui/core/colors';

export default {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: [1, 'solid', grey[300]],
    padding: [30, 35, 0, 0],
    marginBottom: 10,
  },
};
