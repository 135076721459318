import PropTypes from 'prop-types';
import React from 'react';

import Field from '../Field';

const Description = ({ classes, totalIssuedAmount, totalExpiredAmount }) => (
  <>
    <div className={classes.facts}>
      <Field label="Общая сумма сертификатов:" className={classes.fact}>
        {totalIssuedAmount}
      </Field>
      <Field
        label="Общая сумма не использованных бонусов:"
        className={classes.fact}
      >
        {totalExpiredAmount}
      </Field>
    </div>
  </>
);

Description.defaultProps = {
  totalExpiredAmount: 0,
  totalIssuedAmount: 0,
};

Description.propTypes = {
  totalExpiredAmount: PropTypes.number,
  totalIssuedAmount: PropTypes.number,
};

export default Description;
