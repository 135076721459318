import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React, { Component } from 'react';

import withFormikValidation from '@/hocs/withFormikValidation';
import { getErrorCode } from '@/utils/errors';

export const pureWithAsyncFormik = errorsMapping => WrappedComponent => {
  class WithAsyncFormik extends Component {
    static propTypes = {
      setFieldError: PropTypes.func.isRequired,
      handleChange: PropTypes.func.isRequired,
      error: PropTypes.object,
      errors: PropTypes.object.isRequired,
      Field: PropTypes.func.isRequired,
      SubmitButton: PropTypes.func.isRequired,
      isLoading: PropTypes.bool,
    };

    static defaultProps = {
      error: null,
      isLoading: false,
    };

    static getDerivedStateFromProps({ error }, { association }) {
      if (error) {
        const code = getErrorCode(error);
        const config = errorsMapping[code];

        if (config && association.error !== error) {
          const { field } = config;

          return {
            association: { error, field, touched: false },
          };
        }

        return { association };
      }

      return {
        association: {},
      };
    }

    state = {
      association: {},
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          Field={this.renderField}
          SubmitButton={this.renderSubmitButton}
          handleChange={this.handleChange}
        />
      );
    }

    componentDidUpdate() {
      const { errors, error, setFieldError } = this.props;
      const { association } = this.state;

      if (!error) {
        return;
      }

      const code = getErrorCode(error);
      const config = errorsMapping[code];

      if (!config) {
        return;
      }

      const { field, message } = config;

      if (!association.touched && !errors[field]) {
        setFieldError(field, message);
      }
    }

    handleChange = e => {
      const { handleChange } = this.props;
      const { name: field } = e.target;

      this.setState(({ association }) => {
        if (association.field === field) {
          return {
            association: { ...association, touched: true },
          };
        }

        return { association };
      });

      return handleChange(e);
    };

    renderField = props => {
      const { Field } = this.props;

      return <Field onChange={this.handleChange} {...props} />;
    };

    renderSubmitButton = props => {
      const { SubmitButton, isLoading, error } = this.props;

      return <SubmitButton {...props} error={error} isLoading={isLoading} />;
    };
  }

  return WithAsyncFormik;
};

export default ({ asyncOptions, formikOptions, errorsMapping }) =>
  compose(
    withAsyncRunner(asyncOptions),
    withFormikValidation(formikOptions),
    pureWithAsyncFormik(errorsMapping),
  );
