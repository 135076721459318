import React, { Fragment } from 'react';

import Banner from './Banner';
import Placement from './Placement';

const ButtonContent = props => (
  <Fragment>
    <Placement {...props} />
    <Banner {...props} />
  </Fragment>
);

export default ButtonContent;
