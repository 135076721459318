import {
  ID_KEY,
  STABLE_KEY,
} from '@esentai/core/features/parkingWhitelistFilter/consts/keys';
import { annotations } from '@esentai/core/features/parkingWhitelistFilter/serializer';
import { descending, equals, filter } from '@esentai/core/query-dsl';
import { bool, sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(ID_KEY),
  }),
  [STABLE_KEY]: bool({
    defaultValue: filter(equals(true)),
  }),
});
