import { connect } from 'react-redux';

import StatisticsTickDate from '@/components/StatisticsTickDate';
import { getDateById } from '@/features/purchasesStatistics/selectors';
import { getScale } from '@/features/purchasesStatisticsPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  date: getDateById(state, itemId),
  scale: getScale(state),
});

export default connect(mapStateToProps)(StatisticsTickDate);
