import { STATUS_VALUES } from '@esentai/core/features/merchants/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import { canUpdateMerchant } from '@/features/merchantsPage/permissions';

import Actions from './Actions';

const Row = ({
  status,
  bin,
  company,
  merchantId,
  merchantPageUrl,
  legalAddress,
  factAddress,
  mobilePhone,
  workPhone,
  rentAreaInfo,
  presidentFullName,
}) => (
  <TableRow>
    <CondenseTableCell>
      <Link to={merchantPageUrl}>{bin}</Link>
    </CondenseTableCell>

    <CondenseTableCell>
      <Link to={merchantPageUrl}>{company}</Link>
    </CondenseTableCell>

    <CondenseTableCell>{mobilePhone}</CondenseTableCell>

    <CondenseTableCell>{workPhone}</CondenseTableCell>

    <CondenseTableCell>{presidentFullName}</CondenseTableCell>

    <CondenseTableCell>{rentAreaInfo}</CondenseTableCell>

    <CondenseTableCell>{legalAddress}</CondenseTableCell>

    <CondenseTableCell>{factAddress}</CondenseTableCell>

    <CondenseTableCell>{STATUS_VALUES[status]}</CondenseTableCell>

    <CanView permission={canUpdateMerchant} merchantId={merchantId}>
      <CondenseTableCell>
        <Actions merchantId={merchantId} />
      </CondenseTableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  status: PropTypes.string.isRequired,
  bin: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  merchantId: PropTypes.any.isRequired,
  merchantPageUrl: PropTypes.string.isRequired,
  legalAddress: PropTypes.string.isRequired,
  factAddress: PropTypes.string.isRequired,
  mobilePhone: PropTypes.string.isRequired,
  workPhone: PropTypes.string.isRequired,
  rentAreaInfo: PropTypes.string.isRequired,
  presidentFullName: PropTypes.string.isRequired,
};

Row.defaultProps = {
  categoryKeys: [],
  brandsIds: [],
};

export default Row;
