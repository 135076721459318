export default reducer => {
  const initialState = {
    previous: null,
    current: reducer(undefined, {}),
  };

  return (state = initialState, action) => ({
    previous: state.current,
    current: reducer(state.current, action),
  });
};
