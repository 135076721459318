import {
  getEventEndDatetime,
  getEventStartDatetime,
  getEventTags,
} from '@esentai/core/features/campaigns/calendarEvent/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Properties from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  startDatetime: getEventStartDatetime(state, itemId),
  endDatetime: getEventEndDatetime(state, itemId),
  tags: getEventTags(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Properties);
