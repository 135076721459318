import { CircularProgress, Grid, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const SpinnerRow = ({ columnsCount, ...props }) => (
  <TableRow {...props}>
    <TableCell colSpan={columnsCount}>
      <Grid container justify="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </TableCell>
  </TableRow>
);

SpinnerRow.propTypes = {
  columnsCount: PropTypes.number.isRequired,
};

export default SpinnerRow;
