import { RESOURCE_NAME as BENEFITS } from '@esentai/core/features/benefits/consts';
import { can, permit, VIEW } from '@esentai/core/policy-dsl';

import { RESOURCE_NAME as PERSONNEL } from '@/features/personnel/consts';
import { RESOURCE_NAME as USERS_STATISTICS } from '@/features/usersStatistics/consts';

import { VIEW_NAVIGATION_BAR } from '../consts/permissions';

export default [
  can(VIEW_NAVIGATION_BAR),
  permit(BENEFITS, [VIEW]),
  permit(PERSONNEL, [VIEW]),
  permit(USERS_STATISTICS, [VIEW]),
];
