import { withProps } from 'recompose';

import { MerchantAutocomplete } from '@/containers/MerchantAutocomplete';
import Autocomplete from '@/filters/Autocomplete';

export default withProps(({ value, ...ownerProps }) => ({
  ...ownerProps,
  Component: MerchantAutocomplete,
  selectedItem: value,
  InputProps: {
    placeholder: 'БИН/ИИН...',
  },
}))(Autocomplete);
