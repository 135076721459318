import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Variant from '@/components/Bilingual/Variant';

const Quadrolingual = ({
  chineseContent,
  englishContent,
  kazakhContent,
  russianContent,
  variant,
}) => (
  <Typography variant={variant}>
    <Variant content={russianContent} label="RU" />
    <Variant content={englishContent} label="EN" />
    {kazakhContent && <Variant content={kazakhContent} label="KZ" />}
    {chineseContent && <Variant content={chineseContent} label="CN" />}
  </Typography>
);

Quadrolingual.propTypes = {
  chineseContent: PropTypes.any.isRequired,
  englishContent: PropTypes.any.isRequired,
  kazakhContent: PropTypes.any.isRequired,
  russianContent: PropTypes.any.isRequired,
  variant: PropTypes.string,
};

Quadrolingual.defaultProps = {
  variant: 'h5',
};

export default Quadrolingual;
