import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { activate, decline } from '@esentai/core/features/campaigns/actions';
import {
  COMMENT_KEY,
  STATUS_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import {
  APPROVED_STATUS,
  DECLINED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import {
  activateCampaign,
  declineCampaign,
  fetchCampaign,
} from '@esentai/core/features/campaigns/sagas';
import { push } from 'connected-react-router';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createCampaignsPageURL } from '@/features/campaignsPage/utils';

import { getCampaignId, getCampaignType } from './selectors';
import { FETCH_ITEM, SUBMIT_REVIEW } from './types';

export function* fetchItem(action) {
  const { payload: id } = action;
  const type = yield select(getCampaignType);

  yield call(fetchCampaign, type, id);
}

export function* submitReview(action) {
  const { payload } = action;
  const { [COMMENT_KEY]: comment, [STATUS_KEY]: status } = payload;
  const id = yield select(getCampaignId);

  if (status === APPROVED_STATUS) {
    const activateAction = activate(id);

    yield call(activateCampaign, activateAction);
  }

  if (status === DECLINED_STATUS) {
    const declineAction = decline({ id, comment });

    yield call(declineCampaign, declineAction);
  }

  yield put(push(createCampaignsPageURL()));
}

export default function*() {
  yield takeEvery(FETCH_ITEM, createTaskSaga(fetchItem));
  yield takeEvery(SUBMIT_REVIEW, createTaskSaga(submitReview));
}
