import { RESOURCE_NAME } from '@esentai/core/features/storeBonuses/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadStoreBonuses = authorizeIf(canRead(RESOURCE_NAME));
export const canReadStoreBonus = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateStoreBonus = authorizeIf(canCreate(RESOURCE_NAME));
export const canUpdateStoreBonus = authorizeIf(canUpdate(RESOURCE_NAME));

// TODO set permission for campaign statistic
export const canReadStoreBonusesStatistics = () => true;
