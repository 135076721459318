import { compose } from 'ramda';
import { connect } from 'react-redux';

import { isMallAdministrator } from '@/features/currentUser/selectors';

import ColGroup from './component';

const mapStateToProps = state => ({
  isMallAdmin: isMallAdministrator(state),
});

export default compose(connect(mapStateToProps))(ColGroup);
