import { REPAY } from '@esentai/core/features/reconciliation/consts';
import { RESOURCE_NAME } from '@esentai/core/features/reconciliationHistory/consts';
import {
  can,
  canCreate,
  canRead,
  canUpdate,
  permission,
} from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadReconciliationHistory = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateReconciliationHistory = authorizeIf(
  canCreate(RESOURCE_NAME),
);
export const canUpdateReconciliationHistory = authorizeIf(
  canUpdate(RESOURCE_NAME),
);
export const canRepayReconciliation = authorizeIf(
  can(permission(RESOURCE_NAME, REPAY)),
);
