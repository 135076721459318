import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import Button from '@/components/Button';
import ChartBarIcon from '@/components/Icon/ChartBar';
import CanView from '@/containers/CanView';

const StatisticButton = ({ statisticsPageUrl, permission, ...rest }) => (
  <CanView permission={permission}>
    <ActionButton
      text="Посмотреть статистику"
      button={Button}
      icon={ChartBarIcon}
      href={statisticsPageUrl}
      {...rest}
    />
  </CanView>
);

StatisticButton.propTypes = {
  permission: PropTypes.func.isRequired,
  statisticsPageUrl: PropTypes.string.isRequired,
};

export default StatisticButton;
