export default {
  wrapper: {
    outline: 0,
    width: 920,
    padding: 27,
    top: 20,
    bottom: 20,
    overflow: 'scroll',
    position: 'absolute',
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,
  },

  productButton: {
    height: 50,
    width: 50,
  },

  hideArrows: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
};
