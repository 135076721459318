import {
  ADDRESS_EN_KEY,
  ADDRESS_RU_KEY,
  EMAIL_KEY,
  MAP_URL_KEY,
  PHONE_KEY,
} from '@esentai/core/features/applicationSettings/consts/keys';
import {
  getEmail,
  getEnglishAddress,
  getMapUrl,
  getPhone,
  getRussianAddress,
} from '@esentai/core/features/applicationSettings/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { object, string } from 'yup';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import styles from './styles';

const errorsMapping = {};

const mapsUrlPrefix = 'https://yandex.ru/map-widget';

const formikOptions = {
  mapPropsToValues: ({
    email,
    englishAddress,
    mapUrl,
    phone,
    russianAddress,
  }) => ({
    [ADDRESS_EN_KEY]: englishAddress || '',
    [ADDRESS_RU_KEY]: russianAddress || '',
    [EMAIL_KEY]: email || '',
    [MAP_URL_KEY]: mapUrl || '',
    [PHONE_KEY]: phone || '',
  }),

  validationSchema: object().shape({
    [ADDRESS_EN_KEY]: string().required(),
    [ADDRESS_RU_KEY]: string().required(),
    [EMAIL_KEY]: string()
      .email('Это должно быть похоже на email')
      .required(),
    [MAP_URL_KEY]: string()
      .url()
      .matches(
        new RegExp(`^${mapsUrlPrefix}`),
        `URL должен начинаться на ${mapsUrlPrefix}`,
      )
      .required(),
    [PHONE_KEY]: string().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

const mapStateToProps = state => ({
  email: getEmail(state),
  englishAddress: getEnglishAddress(state),
  mapUrl: getMapUrl(state),
  phone: getPhone(state),
  russianAddress: getRussianAddress(state),
});

export default compose(
  connect(mapStateToProps),
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
