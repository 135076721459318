import {
  getEnglishDescription,
  getEnglishTitle,
  getKazakhDescription,
  getKazakhTitle,
  getRussianDescription,
  getRussianTitle,
} from '@esentai/core/features/campaigns/show/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getProductId } from '../../../../selectors';
import ContentForm from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getProductId(state);

  return {
    russianTitle: getRussianTitle(state, itemId),
    englishTitle: getEnglishTitle(state, itemId),
    kazakhTitle: getKazakhTitle(state, itemId),

    russianDescription: getRussianDescription(state, itemId),
    englishDescription: getEnglishDescription(state, itemId),
    kazakhDescription: getKazakhDescription(state, itemId),

    itemId,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(ContentForm);
