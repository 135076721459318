import { RESOURCE_NAME } from '@esentai/core/features/campaigns/consts';
import { can, canCreate, canRead, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { MODERATE } from './consts/permissions';

export const canReadCampaigns = authorizeIf(canRead(RESOURCE_NAME));

export const canReadCampaign = authorizeIf(canRead(RESOURCE_NAME));

export const canCreateCampaign = authorizeIf(canCreate(RESOURCE_NAME));

export const canUpdateCampaign = authorizeIf(canCreate(RESOURCE_NAME));

export const canDeleteCampaign = authorizeIf(canCreate(RESOURCE_NAME));

export const canModerateCampaign = authorizeIf(
  can(permission(RESOURCE_NAME, MODERATE)),
);

// TODO set permission for campaign statistic
export const canReadCampaignStatistics = () => true;
