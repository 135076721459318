import { findLocationById } from '@esentai/core/features/locations/actions';
import {
  getRussianName,
  isLocationLoaded,
  isLocationLoading,
} from '@esentai/core/features/locations/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import LocationNameLazy from './component';

const mapStateToProps = (state, { locationId }) => ({
  isLoaded: isLocationLoaded(state, locationId),
  isLoading: isLocationLoading(state, locationId),
  itemId: locationId,
  title: getRussianName(state, locationId),
});

const mapDispatchToProps = { fetchItem: findLocationById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(LocationNameLazy);
