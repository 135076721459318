import { getStoreName } from '@esentai/core/features/reconciliationHistory/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import { retryFetch } from '../actions';
import queryDuck from '../ducks/query';
import {
  getReconciliationError,
  getReconciliationIds,
  getReconciliationTotalCount,
  getStoreId,
  isLoadingReconciliation,
} from '../selectors';
import ReconciliationHistory from './component';
import styles from './styles';

const storeId = state => getStoreId(state);

const mapStateToProps = state => {
  const reconciliationIds = getReconciliationIds(state);

  return {
    error: getReconciliationError(state),
    reconciliationIds,
    isLoading: isLoadingReconciliation(state),
    totalCount: getReconciliationTotalCount(state),
    storeId: storeId(state),
    storeName: getStoreName(state, reconciliationIds[0]),
  };
};

const mapDispatchToProps = {
  fetchItems: retryFetch,
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ReconciliationHistory);
