import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  ARCHIVED_STATUS,
  DECLINED_STATUS,
  DRAFT_STATUS,
  MODERATING_STATUS,
  PAUSED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import DotIndicator from '@/components/DotIndicator';
import { statusLabel } from '@/features/campaignsPage/labels';

const dotIncicatorStatuses = {
  [ACTIVE_STATUS]: 'enabled',
  [ARCHIVED_STATUS]: 'disabled',
  [APPROVED_STATUS]: 'pending',
  [DECLINED_STATUS]: 'errorred',
  [DRAFT_STATUS]: 'blocked',
  [MODERATING_STATUS]: 'pending',
  [PAUSED_STATUS]: 'disabled',
};

const Status = ({ status, classes }) => (
  <div className={classes.root}>
    <DotIndicator status={dotIncicatorStatuses[status]} classes={classes} />
    <Typography className={classes.text} align="right">
      {statusLabel(status)}
    </Typography>
  </div>
);

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Status;
