import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import dao from '@esentai/core/features/locations/dao';
import { updateLocation } from '@esentai/core/features/locations/sagas';
import { select, takeLatest } from '@redux-saga/core/effects';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import { getLocationId } from '@/features/locationEditPage/selectors';

import { FETCH_LOCATION, UPDATE_LOCATION } from './consts/types';

export const { findById: findLocationById } = dao.sagas;

export function* fetchLocation(action) {
  const { payload: locationId } = action;

  yield call(findLocationById, locationId);
}

export function* updateLocationSaga(action) {
  const { payload: attributes } = action;
  const locationId = yield select(getLocationId);

  yield call(updateLocation, locationId, {
    ...attributes,
  });
}

export function* watchFetchLocation() {
  yield takeEvery(FETCH_LOCATION, fetchLocation);
}

export function* watchUpdateLocation() {
  yield takeEvery(UPDATE_LOCATION, updateLocationSaga);
}

export default function*() {
  yield spawn(watchFetchLocation);
  yield takeLatest(UPDATE_LOCATION, createTaskSaga(updateLocationSaga));
}
