import { ENTITIES_STORE_KEY } from '@esentai/core/ducks/consts';
import { head, last, path, pipe, split } from 'ramda';

export const getRoot = path([ENTITIES_STORE_KEY, 'upload']);

export const getFileProp = prop => (state, id) => {
  const root = getRoot(state);
  const item = root[id];

  if (item) {
    return item[prop];
  }

  return null;
};

export const getNameForFile = (state, id) =>
  pipe(split('?'), head, split('/'), last)(id);

const getFileFlag = prop => (...rest) => {
  const value = getFileProp(prop)(...rest);

  return value === null ? false : value;
};

export const getPreviewForFile = (state, id) => id;

export const getFileObjectForFile = getFileProp('file');

export const getURLForFile = getFileProp('url');

export const getUploadProgressForFile = getFileProp('progress');

export const getUploadErrorForFile = getFileProp('error');

export const isUploadingFile = getFileFlag('isUploading');

export const isFinishedUploadingFile = getFileFlag('isFinished');
