import React from 'react';

import Breadcrumb from './Breadcrumb';

const Breadcrumbs = ({ classes, children }) => (
  <div className={classes.wrapper}>
    <Breadcrumb withoutArrow to="/">
      Главная
    </Breadcrumb>
    {children}
  </div>
);

export default Breadcrumbs;
