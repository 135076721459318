import {
  ONE_TIME_TYPE_KEY,
  PERIODIC_TYPE_KEY,
} from '@esentai/core/features/storeBonuses/consts/keys';

export const reconciliationType = type => {
  const labels = {
    [ONE_TIME_TYPE_KEY]: 'Одноразовое погашение',
    [PERIODIC_TYPE_KEY]: 'Периодическое погашение',
  };

  return labels[type] || null;
};
