import { Tooltip } from '@material-ui/core';
import { HowToReg } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

export default class WhitelistCell extends React.Component {
  static propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    itemId: PropTypes.number,
    fetchItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    itemId: null,
  };

  render() {
    return this.props.isLoaded ? (
      <Tooltip title="Пользователь добавлен в список">
        <HowToReg
          style={{ fontSize: 20, verticalAlign: 'bottom', color: 'gray' }}
        />
      </Tooltip>
    ) : (
      this.props.isLoading && <span>...</span>
    );
  }
}
