import PropTypes from 'prop-types';
import React from 'react';

import {
  DAY_SCALE,
  HOUR_SCALE,
  MONTH_SCALE,
  WEEK_SCALE,
} from '@/features/statistics/consts/scales';

import { getStrategy } from './strategies';

const StatisticsTickDate = ({ date, scale, ...rest }) => {
  const { Formatter } = getStrategy(scale);

  return <Formatter date={date} {...rest} />;
};

StatisticsTickDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  scale: PropTypes.oneOf([HOUR_SCALE, DAY_SCALE, WEEK_SCALE, MONTH_SCALE])
    .isRequired,
};

export default StatisticsTickDate;
