import React from 'react';

import TopBar from './topBar.svg';

const LockScreenTopBar = ({ classes }) => (
  <div className={classes.root}>
    <TopBar />
  </div>
);

LockScreenTopBar.propTypes = {};

export default LockScreenTopBar;
