import { Typography } from '@material-ui/core';
import React from 'react';

const ActionItem = ({ children, classes, ...rest }) => (
  <Typography {...rest} className={classes.root}>
    {children}
  </Typography>
);

export default ActionItem;
