import { withStyles } from '@material-ui/core';
import { defaultProps } from 'recompose';

import { createCheckboxFilterTemplate } from '@/filters/CheckboxGroup';

import MultiSelect from './component';
import styles from './styles';

const MultiSelectFilter = withStyles(styles)(MultiSelect);

export const createMultiSelectFilterTemplate = ({ options, inputProps }) =>
  createCheckboxFilterTemplate({
    FilterComponent: defaultProps({ options, inputProps })(MultiSelectFilter),
    options,
  });

export default MultiSelectFilter;
