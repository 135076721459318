import {
  getCategories,
  getChineseDescription,
  getEnglishDescription,
  getImageUrl,
  getKazakhDescription,
  getName,
  getRussianDescription,
  getStoresIds,
} from '@esentai/core/features/brands/selectors';
import { connect } from 'react-redux';

import Form from '@/features/brandCreatePage/Page/Form';

const mapStateToProps = (state, { brandId }) => ({
  categories: getCategories(state, brandId),
  chineseDescription: getChineseDescription(state, brandId),
  englishDescription: getEnglishDescription(state, brandId),
  imageUrl: getImageUrl(state, brandId),
  kazakhDescription: getKazakhDescription(state, brandId),
  name: getName(state, brandId),
  russianDescription: getRussianDescription(state, brandId),
  storesIds: getStoresIds(state, brandId),
});

export default connect(mapStateToProps)(Form);
