import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getBonusTransactionError,
  getItemsIds: getBonusTransactionIds,
  getItemsMeta: getBonusTransactionMeta,
  getItemsTotalCount: getBonusTransactionTotalCount,
  isLoadingItems: isLoadingBonusTransaction,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
