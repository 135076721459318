import { Paper, Table } from '@material-ui/core';
import React from 'react';

import ErrorMessageRow from '@/components/CommonTable/ErrorMessage';

const ErrorMessage = props => (
  <Paper>
    <Table>
      <ErrorMessageRow columnsCount={1} {...props} />
    </Table>
  </Paper>
);

export default ErrorMessage;
