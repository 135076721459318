export default {
  flowRateChart: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'baseline',
    flexDirection: 'column',
    marginBottom: -6,
  },

  text: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 2,
  },

  chart: {
    height: 4,
    backgroundColor: 'black',
    opacity: 0.3,
    width: ({ percentage }) => `${percentage}%`,
  },

  percentage: {
    flexGrow: 0,
    marginRight: 3,
    opacity: 0.5,
  },

  expander: {
    flexGrow: 1,
    height: 0,
    borderBottom: [1, 'solid', 'gray'],
    opacity: 0.3,
  },

  value: {
    flexGrow: 0,
    marginLeft: 3,
  },
};
