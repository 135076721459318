import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const SuspiciotyToggler = ({ toggleSuspiciouty, suspicious }) => (
  <FormControlLabel
    control={<Checkbox checked={suspicious} onChange={toggleSuspiciouty} />}
    label="Подозрительный пользователь"
  />
);

SuspiciotyToggler.propTypes = {
  toggleSuspiciouty: PropTypes.func.isRequired,
  suspicious: PropTypes.bool.isRequired,
};

export default SuspiciotyToggler;
