import { RESOURCE_NAME } from '@esentai/core/features/reconciliationStoreToCp/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadReconciliationReceiptBonusDetails = authorizeIf(
  canRead(RESOURCE_NAME),
);
export const canCreateReconciliationReceiptBonusDetails = authorizeIf(
  canCreate(RESOURCE_NAME),
);
export const canUpdateReconciliationReceiptBonusDetails = authorizeIf(
  canUpdate(RESOURCE_NAME),
);
