import {
  MAIN_PAGE_TYPE,
  POPUP_TYPE,
  STORE_LIST_TYPE,
} from '@esentai/core/features/campaigns/banner/consts/types';

import { bannerType } from '@/features/campaignPage/labels';
import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';

export const options = [
  MAIN_PAGE_TYPE,
  POPUP_TYPE,
  STORE_LIST_TYPE,
].map(key => ({ key, label: bannerType(key) }));

export const createBannerTypeFilter = createCheckboxFilterTemplate({
  options,
});

export default CheckboxGroupFilter;
