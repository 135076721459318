export default () => ({
  root: {
    background: '#515151',
    color: '#fff',
    padding: '16px',
    fontFamily: 'Roboto',
    fontSize: '17px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  exitButton: {
    color: '#fff',
    borderColor: '#fff',
    textTransform: 'none',
  },
  icon: {
    transition: 'all 0.3s',
    cursor: 'pointer',
  },
  expanded: {
    transform: 'rotate(-180deg)',
  },
});
