import { grey } from '@material-ui/core/colors';

export default {
  rightYAxis: {
    '& text': {
      color: '#F5A623',
    },

    '& .domain, line': {
      color: grey[300],
    },
  },
};
