import { combineReducers } from 'redux';

import memoryReducer from '@/redux/memory/memoryReducer';

import items from './ducks/items';
import query from './ducks/query';

export default combineReducers({
  items: items.reducer,
  query: memoryReducer(query.reducer),
});
