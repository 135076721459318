import { RESOURCE_NAME } from '@esentai/core/features/loyalties/consts';
import {
  ACTIVITY_TYPE_KEY,
  FULLNAME_KEY,
  ROLE_KEY,
} from '@esentai/core/features/loyalties/consts/keys';
import {
  LEVEL_CHANGED,
  REWARD_BONUSES,
} from '@esentai/core/features/loyalties/consts/statuses';
import serializer from '@esentai/core/features/loyalties/serializer';
import { MALL_MANAGER_ROLE } from '@esentai/core/features/users/consts/roles';
import { mergeQueriesRight } from '@esentai/core/queries';
import {
  equals,
  hasFilter,
  query as makeQuery,
  where,
} from '@esentai/core/query-dsl';
import VirtualResource from '@esentai/core/resources/virtual';

export class LoyaltiesResource extends VirtualResource {
  query(query) {
    // On refresh params for query - type[in]
    // Current chunk of code needs to load data with specific types
    query.forEach((param, key) => {
      if (key === 'filters') {
        if (param.has(ACTIVITY_TYPE_KEY)) {
          param.forEach((filterName, filterKey) => {
            if (filterKey === ACTIVITY_TYPE_KEY) {
              filterName.set('in', [REWARD_BONUSES, LEVEL_CHANGED]);
            }
          });
        } else {
          param.set(
            'type',
            new Map().set('in', [REWARD_BONUSES, LEVEL_CHANGED]),
          );
        }
      }
    });
    //

    if (hasFilter(FULLNAME_KEY, query)) {
      const roleQuery = mergeQueriesRight(
        query,
        makeQuery(where(ROLE_KEY, equals(MALL_MANAGER_ROLE))),
      );

      return super.query(roleQuery);
    }

    return super.query(query);
  }
}

export default new LoyaltiesResource({
  aliasResourceName: 'loyalties',
  resourceName: RESOURCE_NAME,
  endpoint: '/customer-activity-history',
  serializer,
});
