const russianPluralizer = (count, { one, few, many }) => {
  const lastDigit = Number(count.toString().substr(-1));

  if (count > 10 && count < 20) {
    return many;
  }
  if (lastDigit === 1) {
    return one;
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return few;
  }

  return many;
};

export default russianPluralizer;
