import { deactivateUser } from '@esentai/core/features/users/sagas';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import {
  deactivateFailure,
  deactivateStart,
  deactivateSuccess,
} from './actions';
import { CANCELLATION_TIMEOUT } from './consts';
import { getUsersIds } from './selectors';
import { CANCEL_DEACTIVATE, SCHEDULE_DEACTIVATE } from './types';

export function* deactivateUsers() {
  yield put(deactivateStart());

  const ids = yield select(getUsersIds);
  const tasks = ids.map(id => call(deactivateUser, id));

  try {
    yield all(tasks);
    yield put(deactivateSuccess());
  } catch (error) {
    yield put(deactivateFailure(error));
  }
}

export function* userDeactivateFlow() {
  const { cancel } = yield race({
    timer: delay(CANCELLATION_TIMEOUT),
    cancel: take(CANCEL_DEACTIVATE),
  });

  if (!cancel) {
    yield call(deactivateUsers);
  }
}

export default function*() {
  yield takeLatest(SCHEDULE_DEACTIVATE, userDeactivateFlow);
}
