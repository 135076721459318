import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import FiltersRow from '@/components/FiltersRow';

import { removeFilters, updateFilters } from '../../actions';
import { filterTypes } from '../../filters';
import { getFilters } from '../../selectors';

const mapStateToProps = state => ({
  filters: getFilters(state),
});

const mapDispatchToProps = { removeFilters, updateFilters };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({ filterTypes }),
)(FiltersRow);
