import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ActivateButton from '../../buttons/ActivateButton';
import CloneButton from '../../buttons/CloneButton';

const ApprovedStatusActions = ({ itemId }) => (
  <Fragment>
    <ActivateButton itemId={itemId} />
    <CloneButton itemId={itemId} />
  </Fragment>
);

ApprovedStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default ApprovedStatusActions;
