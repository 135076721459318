import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  PAUSED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import { differenceInDays, startOfDay } from 'date-fns';

import pluralize from '@/utils/pluralize';

export const pluralizeDaysLeft = count =>
  pluralize(count, {
    one: `заканчивается завтра`,
    few: `осталось ${count} дня`,
    many: `осталось ${count} дней`,
  });

export const pluralizeDaysBefore = count =>
  count === 1
    ? 'начинается завтра'
    : pluralize(count - 1, {
        one: `через ${count - 1} день`,
        few: `через ${count - 1} дня`,
        many: `через ${count - 1} дней`,
      });

export const daysLeftCaption = ({
  dateStart,
  dateEnd,
  today = startOfDay(new Date()),
  status,
}) => {
  if (status === ACTIVE_STATUS || status === PAUSED_STATUS) {
    const daysLeft = differenceInDays(dateEnd, today);

    return `(${pluralizeDaysLeft(daysLeft)})`;
  }

  if (status === APPROVED_STATUS) {
    const daysBefore = differenceInDays(dateStart, today);

    return `(${pluralizeDaysBefore(daysBefore)})`;
  }

  return null;
};
