import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getStoreBonusesError,
  getItemsIds: getStoreBonusIds,
  getItemsMeta: getStoreBonusesMeta,
  getItemsTotalCount: getStoreBonusesTotalCount,
  isLoadingItems: isLoadingStoreBonuses,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
