import { isBeaconActive } from '@esentai/core/features/beacons/selectors';
import { connect } from 'react-redux';

import StatusIndicator from './component';

const mapStateToProps = (state, { beaconId }) => ({
  active: isBeaconActive(state, beaconId),
});

export default connect(mapStateToProps)(StatusIndicator);
