import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';

import MainPageTopBar from '@/components/DevicePreview/MainPageTopBar';
import {
  LOCALE_EN,
  LOCALE_KZ,
  LOCALE_RU,
} from '@/hocs/withDevicePreview/consts';

const Preview = ({
  classes,
  imageUrl,

  englishTitle,
  russianTitle,
  kazakhTitle,

  additionalEnglishText,
  additionalRussianText,
  additionalKazakhText,

  locale,
}) => {
  const text = useMemo(() => {
    switch (locale) {
      case LOCALE_RU:
        return { title: russianTitle, additionalText: additionalRussianText };
      case LOCALE_EN:
        return { title: englishTitle, additionalText: additionalEnglishText };
      case LOCALE_KZ:
        return { title: kazakhTitle, additionalText: additionalKazakhText };
      default:
        return { title: russianTitle, additionalText: additionalRussianText };
    }
  }, [locale]);

  return (
    <Fragment>
      <MainPageTopBar />
      <div className={classes.container}>
        <img className={classes.image} src={imageUrl} />
        <div className={classes.title}>{text.title}</div>
        <div className={classes.additionalText}>{text.additionalText}</div>
      </div>
    </Fragment>
  );
};

Preview.propTypes = {
  imageUrl: PropTypes.string,
  englishTitle: PropTypes.string,
  russianTitle: PropTypes.string,
  kazakhTitle: PropTypes.string,
  additionalEnglishText: PropTypes.string,
  additionalRussianText: PropTypes.string,
  additionalKazakhText: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

Preview.defaultProps = {
  imageUrl: null,
  englishTitle: null,
  russianTitle: null,
  kazakhTitle: null,
  additionalEnglishText: null,
  additionalRussianText: null,
  additionalKazakhText: null,
};

export default Preview;
