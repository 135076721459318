import { findMerchantById } from '@esentai/core/features/merchants/actions';
import {
  getBinForMerchant,
  getCompanyForMerchant,
  isMerchantLoaded,
  isMerchantLoading,
} from '@esentai/core/features/merchants/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import MerchantNameLazy from './component';

const mapStateToProps = (
  state,
  { merchantId, showSubtitle, getTitle, isLoaded },
) => ({
  isLoaded: !isLoaded
    ? isMerchantLoaded(state, merchantId)
    : isLoaded(state, merchantId),
  isLoading: isMerchantLoading(state, merchantId),
  itemId: merchantId,
  title: !getTitle
    ? getCompanyForMerchant(state, merchantId)
    : getTitle(state, merchantId),
  subTitle: getBinForMerchant(state, merchantId),
  showSubtitle,
});

const mapDispatchToProps = { fetchItem: findMerchantById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(MerchantNameLazy);
