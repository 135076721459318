import {
  IMAGES_LAYOUT,
  LOCATION_LAYOUT,
  ONLINE_SHOWCASE_LAYOUT,
  PARAGRAPH_LAYOUT,
  TITLE_LAYOUT,
  VIDEO_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/layouts';
import PropTypes from 'prop-types';
import React from 'react';

import { SUBTITLE_LAYOUT } from '@/components/ArticleConstructorForm/consts';

import Images from './Images';
import Location from './Location';
import Paragraph from './Paragraph';
import Showcase from './Showcase';
import Subtitle from './Subtitle';
import Title from './Title';
import Video from './Video';

const components = {
  [IMAGES_LAYOUT]: Images,
  [LOCATION_LAYOUT]: Location,
  [PARAGRAPH_LAYOUT]: Paragraph,
  [TITLE_LAYOUT]: Title,
  [SUBTITLE_LAYOUT]: Subtitle,
  [VIDEO_LAYOUT]: Video,
  [ONLINE_SHOWCASE_LAYOUT]: Showcase,
};

const Block = ({ payload, type }) => {
  const Component = components[type];

  if (Component) {
    return <Component payload={payload} />;
  }

  return null;
};

Block.propTypes = {
  payload: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default Block;
