export default {
  root: {
    marginTop: 20,
    marginBottom: 20,
  },

  suggestionsHeading: {
    display: 'flex',
    marginTop: 40,
    marginBottom: 10,
  },

  suggestionsCaption: {
    flex: 1,
  },

  selectedItemLabel: {
    marginBottom: 6,
  },
};
