export default {
  errorWrapper: {
    display: 'flex',
    width: '100%',
  },
  product: {
    marginRight: 15,
  },

  customerInfo: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },

  hideArrows: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },

  successAccent: {
    color: '#690',
  },
};
