import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  ARCHIVED_STATUS,
  DECLINED_STATUS,
  DRAFT_STATUS,
  MODERATING_STATUS,
  PAUSED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import PropTypes from 'prop-types';
import React from 'react';

import ActiveStatusActions from './ActiveStatusActions';
import ApprovedStatusActions from './ApprovedStatusActions';
import ArchivedStatusActions from './ArchivedStatusActions';
import DeclinedStatusActions from './DeclinedStatusActions';
import DraftStatusActions from './DraftStatusActions';
import ModeratingStatusActions from './ModeratingStatusActions';
import PausedStatusActions from './PausedStatusActions';

const components = {
  [ACTIVE_STATUS]: ActiveStatusActions,
  [APPROVED_STATUS]: ApprovedStatusActions,
  [ARCHIVED_STATUS]: ArchivedStatusActions,
  [DECLINED_STATUS]: DeclinedStatusActions,
  [DRAFT_STATUS]: DraftStatusActions,
  [MODERATING_STATUS]: ModeratingStatusActions,
  [PAUSED_STATUS]: PausedStatusActions,
};

const Actions = ({ classes, itemId, status }) => {
  const Component = components[status];

  return (
    <div className={classes.root}>
      <Component itemId={itemId} />
    </div>
  );
};

Actions.propTypes = {
  itemId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default Actions;
