import { FILTERS_KEY } from '@esentai/core/features/mallBonuses/consts/keys';
import api from '@esentai/core/features/users/api';
import { query as q } from '@esentai/core/query-dsl';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import Button from '@/components/Button';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FilterBar from '@/components/FilterBar';
import { createFormStepURL } from '@/features/mallBonusCreatePage/utils';
import FormikPropTypes from '@/prop-types/formik';
import { removeFilters, updateFilters } from '@/redux/query/utils';

import { filterTypes } from './filters';

const fieldNames = {};

class ThirdStep extends Component {
  static propTypes = FormikPropTypes;

  constructor(props) {
    super(props);

    this.state = { users: 0 };
  }

  componentDidMount() {
    this.updateUsers();
  }

  removeFilters = keys => {
    const { values, setFieldValue } = this.props;
    const query = values[FILTERS_KEY];

    setFieldValue(FILTERS_KEY, removeFilters(query, keys));
    setTimeout(() => this.updateUsers(), 500); // YEP, don't ask please
  };

  clearFilters = () => {
    const { setFieldValue } = this.props;

    setFieldValue(FILTERS_KEY, q());
    setTimeout(() => this.updateUsers(), 500);
  };

  updateFilters = updates => {
    const { values, setFieldValue } = this.props;
    const query = values[FILTERS_KEY];

    setFieldValue(FILTERS_KEY, updateFilters(query, updates));
    setFieldValue('all', false);
    setTimeout(() => this.updateUsers(), 500);
  };

  updateUsers() {
    const filters = [];

    for (const [filter, entry] of this.props.values[FILTERS_KEY].get(
      'filters',
    ).entries()) {
      for (const [key, value] of entry.entries()) {
        let v = value;

        if (filter.includes('date') || filter.includes('created')) {
          v = moment(v).format('YYYY-MM-DD');
        }

        filters.push([filter, key, v]);
      }
    }
    api
      .doQuery(filters)
      .then(users => this.setState({ users: users.meta.total }));
  }

  render() {
    const {
      classes,
      handleSubmit,
      id,
      values,
      Form,
      RequiredMessages,
      SubmitButton,
      Field,
      setFieldValue,
    } = this.props;
    const filters = [
      ...(values[FILTERS_KEY] || new Map()).get('filters').entries(),
    ].map(([key, value]) => ({ key, value }));
    const { all } = values;
    const { users } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <FieldRow
          label="Все пользователи"
          subLabel="Выбрать всех пользователей"
        >
          <Field
            margin="normal"
            Target={Checkbox}
            className={classes.checkbox}
            checked={all}
            name="kkmStatus"
            rows={4}
            label="Все пользователи"
            onChange={() => {
              setFieldValue('all', !all);
              this.clearFilters();
            }}
          />
        </FieldRow>
        <FieldRow
          label="Сегмент"
          subLabel="Сегментация аудитории"
          sudDesc="Фильтрация сегмента аудитории, к которому будет применена маркетинговая кампания, определяет группу пользователей, которые будут в ней участовать. Вы можете применить любые фильтры, направив действие акции на интересующий вас срез. Однако стоит учитывать, что сильная фильтрация сильно сократит аудиторию, а также необходимо понимать, что не все пользователи заполняют данные о себе и применение фильтров просто не будет их учитывать, если данные указанного фильтра у них отсутствуют."
        >
          <FieldDescription
            title="Фильтр сегмента"
            desc="Вы можете добавить также дополнительные фильтры, которые отделят пользователей с определенными характеристиками. К примеру, пользователей, обладающих Silver статусом, либо мужчин старше 35 лет."
          />
          <Typography variant="subtitle1" gutterBottom>
            Включить в сегмент пользователей, у которых:
          </Typography>
          <FilterBar
            filterTypes={filterTypes}
            filters={filters}
            removeFilters={this.removeFilters}
            updateFilters={this.updateFilters}
          />
        </FieldRow>

        <FieldRow label="Количество пользователей">
          <Typography>{users}</Typography>
        </FieldRow>

        <FieldRow>
          <div className={classes.footer}>
            <SubmitButton
              Target={AsyncButton}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
            >
              Сохранить
            </SubmitButton>
            <Button
              href={createFormStepURL(id, 1)}
              color="primary"
              size="large"
              className={classes.backButton}
            >
              Вернуться
            </Button>
            <RequiredMessages
              fieldNames={fieldNames}
              className={classes.requiredMessages}
            />
          </div>
        </FieldRow>
      </Form>
    );
  }
}

export default ThirdStep;
