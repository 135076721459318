import {
  ACTIVE_STATUS,
  ARCHIVED_STATUS,
} from '@esentai/core/features/benefits/consts/statuses';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import { getPermissionForActions } from '@/features/benefitsPage/selectors';
import { formatDateTimeRange } from '@/utils/format';

import { BonusFilters } from '../../../../components/BonusFIlters/BonusFilters';
import Actions from './Actions';

const Row = ({
  classes,
  comment,
  dateStart,
  dateEnd,
  itemId,
  storeId,
  status,
  title,
  titleEn,
  titleKz,
  authorName,
  storeName,
  discountPercent,
  filters,
}) => (
  <TableRow
    className={classNames(classes.root, {
      [classes.active]: status === ACTIVE_STATUS,
      [classes.archived]: status === ARCHIVED_STATUS,
    })}
  >
    <TableCell>{title}</TableCell>
    <TableCell>{titleEn}</TableCell>
    <TableCell>{titleKz}</TableCell>
    <TableCell>
      <Link to={`/stores/${storeId}`}>{storeName}</Link>
    </TableCell>
    <TableCell>
      <Typography variant="caption">
        {formatDateTimeRange(dateStart, dateEnd)}
      </Typography>
    </TableCell>
    <TableCell>{discountPercent} %</TableCell>

    <TableCell>
      <BonusFilters filter={filters} />
    </TableCell>

    <TableCell>{authorName}</TableCell>
    <TableCell>{comment}</TableCell>
    <CanView permission={getPermissionForActions}>
      <TableCell>
        <Actions itemId={itemId} />
      </TableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  filters: PropTypes.any.isRequired,
  dateEnd: PropTypes.instanceOf(Date).isRequired,
  dateStart: PropTypes.instanceOf(Date).isRequired,
  itemId: PropTypes.number.isRequired,
  discountPercent: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleEn: PropTypes.string.isRequired,
  titleKz: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
};

export default Row;
