import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { downloadXls } from '@/features/purchaseHistoryPage/utils';

import Table from './Table';

class PurchaseHistory extends Component {
  static propTypes = {
    error: PropTypes.any,
    purchaseIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    isStoreSeller: PropTypes.bool,
  };

  static defaultProps = {
    error: null,
    isStoreSeller: false,
  };

  render() {
    const {
      error,
      purchaseIds,
      isLoading,
      totalCount,
      classes,
      isStoreSeller,
    } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>История покупок</PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            {!isStoreSeller && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.excelButton}
                  onClick={downloadXls}
                >
                  Вывести в Excel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.excelButton}
                  style={{ marginLeft: 16 }}
                  onClick={() => downloadXls(false)}
                >
                  Вывести в Excel все
                </Button>
              </div>
            )}
            <Table
              error={error}
              items={purchaseIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default PurchaseHistory;
