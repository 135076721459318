import {
  AGE_KEY,
  AVERAGE_CHECK_KEY,
  AVERAGE_PURCHASES_PER_CHECK_KEY,
  BIRTH_DATE_KEY,
  BIRTH_MONTH_KEY,
  BIRTH_YEAR_KEY,
  CALENDAR_BIRTH_DAY_KEY,
  DATE_REGISTERED_KEY,
  EMAIL_KEY,
  FIRST_NAME_KEY,
  GENDER_KEY,
  LAST_NAME_KEY,
  LAST_PURCHASE_DATE_KEY,
  PHONE_KEY,
  PURCHASE_DATE_KEY,
  PURCHASE_HOUR_KEY,
  PURCHASE_STORE_ID_KEY,
  PURCHASES_COUNT_KEY,
  STATUS_KEY,
  TOTAL_LOYALTY_POINTS_KEY,
} from '@esentai/core/features/campaigns/consts/segmentFilterKeys';

import { createBirthdayRangeFilter } from '@/filters/BirthdayRange';
import { createDateRangeFilter } from '@/filters/DateRange';
import { createDayPartFilter } from '@/filters/DayPart';
import { createSingleDaySelectFilter } from '@/filters/DaySelect';
import { createGenderFilter } from '@/filters/Gender';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createMonthFilter } from '@/filters/MonthSelect';
import { createRangeFilter } from '@/filters/Range';
import { createTextFilter } from '@/filters/SearchInput';
import { createStoreFilter } from '@/filters/Store';
import { createUserAgeFilter } from '@/filters/UserAge';
import { createUserStatusFilter } from '@/filters/UserStatus';
import { createYearFilter } from '@/filters/YearSelect';

export const filterTypes = [
  createTextFilter({
    key: FIRST_NAME_KEY,
    label: 'Имя',
  }),
  createTextFilter({
    key: LAST_NAME_KEY,
    label: 'Фамилия',
  }),
  createTextFilter({
    key: EMAIL_KEY,
    label: 'Email',
  }),
  createTextFilter({
    key: PHONE_KEY,
    label: 'Телефон пользователя',
  }),
  createGenderFilter({
    key: GENDER_KEY,
    label: 'Пол',
  }),
  createUserAgeFilter({
    key: AGE_KEY,
    label: 'Возраст',
  }),
  createSingleDaySelectFilter({
    key: CALENDAR_BIRTH_DAY_KEY,
    label: 'Календарный день рождения',
  }),
  createMonthFilter({
    key: BIRTH_MONTH_KEY,
    label: 'Месяц рождения',
  }),
  createYearFilter({
    key: BIRTH_YEAR_KEY,
    label: 'Год рождения',
  }),
  createBirthdayRangeFilter({
    key: BIRTH_DATE_KEY,
    label: 'Дата рождения',
  }),
  createDateRangeFilter({
    key: DATE_REGISTERED_KEY,
    label: 'Дата регистрации',
  }),
  createUserStatusFilter({
    key: STATUS_KEY,
    label: 'Статус',
  }),
  createRangeFilter({
    key: TOTAL_LOYALTY_POINTS_KEY,
    label: 'Количество бонусов',
  }),
  createMoneyRangeFilter({
    key: AVERAGE_CHECK_KEY,
    label: 'Средний чек',
  }),
  createRangeFilter({
    key: PURCHASES_COUNT_KEY,
    label: 'Количество покупок',
  }),
  createDateRangeFilter({
    key: PURCHASE_DATE_KEY,
    label: 'Период покупок',
  }),
  createStoreFilter({
    key: PURCHASE_STORE_ID_KEY,
    label: 'Магазин покупки',
  }),
  createDayPartFilter({
    key: PURCHASE_HOUR_KEY,
    label: 'Дистрибуция покупки по времени в сутках',
  }),
  createDateRangeFilter({
    key: LAST_PURCHASE_DATE_KEY,
    label: 'Количество дней с последней покупки',
  }),
  createRangeFilter({
    key: AVERAGE_PURCHASES_PER_CHECK_KEY,
    label: 'Среднее количество товаров в покупке',
  }),
];

export const filterTypesShort = [
  createGenderFilter({
    key: GENDER_KEY,
    label: 'Пол',
  }),
  createBirthdayRangeFilter({
    key: BIRTH_DATE_KEY,
    label: 'Дата рождения',
  }),
  createDateRangeFilter({
    key: DATE_REGISTERED_KEY,
    label: 'Дата регистрации',
  }),
  createUserStatusFilter({
    key: STATUS_KEY,
    label: 'Статус',
  }),
];
