import {
  getDestination,
  getPushEnglishMessage,
  getPushEnglishTitle,
  getPushKazakhMessage,
  getPushKazakhTitle,
  getPushRussianMessage,
  getPushRussianTitle,
  getReferredArtcleId,
  getReferredBenefitId,
  getReferredCalendarEventId,
  getReferredShowId,
  getReferredStoreId,
  getSendSchedule,
  getStatus,
} from '@esentai/core/features/campaigns/notification/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepThree } from '@/features/campaignCreatePage/actions';
import {
  getCampaignId,
  getEditableAttributes,
} from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStepCalendarEvent from './component';
import form from './form';

const mapStateToProps = state => {
  const id = getCampaignId(state);

  return {
    status: getStatus(state, id),
    destination: getDestination(state, id),

    pushRussianTitle: getPushRussianTitle(state, id),
    pushRussianMessage: getPushRussianMessage(state, id),

    pushEnglishTitle: getPushEnglishTitle(state, id),
    pushEnglishMessage: getPushEnglishMessage(state, id),

    pushKazakhTitle: getPushKazakhTitle(state, id),
    pushKazakhMessage: getPushKazakhMessage(state, id),

    referredArticle: getReferredArtcleId(state, id),
    referredBenefit: getReferredBenefitId(state, id),
    referredCalendarEvent: getReferredCalendarEventId(state, id),
    referredStore: getReferredStoreId(state, id),
    referredShow: getReferredShowId(state, id),
    sendSchedule: getSendSchedule(state, id),
    getEditableAttributes: getEditableAttributes(state),
  };
};

const mapDispatchToProps = { onSubmit: goToStepThree };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
)(SecondStepCalendarEvent);
