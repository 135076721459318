import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import { StoreAutocomplete } from '@/containers/StoreAutocomplete';
import Label from '@/features/campaignPage/Page/Label';

const Stores = ({ trackedStoreIds }) => (
  <FieldRow label="Магазины">
    <Label title="Кампания сработает в этих магазинах">
      {trackedStoreIds ? (
        <StoreAutocomplete selectedItem={trackedStoreIds} disabled />
      ) : (
        'Любой магазин в молле'
      )}
    </Label>
  </FieldRow>
);

Stores.propTypes = {
  trackedStoreIds: PropTypes.arrayOf(PropTypes.number),
};

Stores.defaultProps = {
  trackedStoreIds: null,
};

export default Stores;
