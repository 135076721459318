import PropTypes from 'prop-types';
import React from 'react';

import BackButton from '@/components/BackButton';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';

import { propTypes as strategyPropTypes } from './strategies';

const MakeInvite = ({ strategy, createInvite }) => {
  const { Form } = strategy;

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <BackButton />
        <PageTitle gutterBottom>Приглашение пользователя</PageTitle>
      </PageHeader>
      <PageContent>
        <PaperSheet>
          <Form onSubmit={createInvite} />
        </PaperSheet>
      </PageContent>
    </Page>
  );
};

MakeInvite.propTypes = {
  createInvite: PropTypes.func.isRequired,
  strategy: strategyPropTypes.isRequired,
};

export default MakeInvite;
