import { path, pipe } from 'ramda';

import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';

export const getQuestionId = pipe(
  createMatchSelector(ROUTE_PATH),
  path(['params', 'questionId']),
);
