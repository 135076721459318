import { connect } from 'react-redux';

import { getEventLayoutWithRename } from '@/features/campaignCreatePage/selectors';
import Layout from '@/features/campaignPage/Page/Content/ArticleContent/Layout/component';

const mapStateToProps = (state, { itemId }) => ({
  layout: getEventLayoutWithRename(state, itemId),
});

export default connect(mapStateToProps)(Layout);
