import {
  getCategories,
  getChineseDescription,
  getEnglishDescription,
  getKazakhDescription,
  getLogoUrl,
  getRussianDescription,
} from '@esentai/core/features/stores/selectors';
import { connect } from 'react-redux';

import Description from './component';

const mapStateToProps = (state, { storeId }) => ({
  chineseDescription: getChineseDescription(state, storeId),
  categories: getCategories(state, storeId),
  englishDescription: getEnglishDescription(state, storeId),
  kazakhDescription: getKazakhDescription(state, storeId),
  logoUrl: getLogoUrl(state, storeId),
  russianDescription: getRussianDescription(state, storeId),
});

export default connect(mapStateToProps)(Description);
