export default {
  root: {
    position: 'relative',
  },

  zoomButton: {
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
};
