import {
  FLOOR_KEY,
  LOCATION_IDS_KEY,
  NAME_KEY,
} from '@esentai/core/features/beacons/consts/keys';

import { createBeaconFloorFilter } from '@/filters/BeaconFloor';
import { createLocationFilter } from '@/filters/Location';
import { createTextFilter } from '@/filters/SearchInput';
import { createMultipleValuesRelationAdapter } from '@/structs/pageFilterAdapter';

export const filterTypes = [
  createTextFilter({
    key: NAME_KEY,
    label: 'Название маяка',
  }),
  createLocationFilter({
    key: LOCATION_IDS_KEY,
    label: 'Локация',
    adapter: createMultipleValuesRelationAdapter(null),
  }),
  createBeaconFloorFilter({
    key: FLOOR_KEY,
    label: 'Этаж',
    quicklyAccessible: true,
  }),
];
