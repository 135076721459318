import React from 'react';
import { Redirect, Route } from 'react-router';

import CheckAccessRoute from '@/components/CheckAccessRoute';
import Switch from '@/components/Switch';
import {
  ROUTE_PATH as VERSION_PAGE,
  ROUTE_PATH as VERSION_PATH,
} from '@/features/appVersionPage/consts';
import AppVersionPage from '@/features/appVersionPage/Page';
import { canChangeAppVersion } from '@/features/appVersionPage/permissions';
import { ROUTE_PATH as BEACONS_PATH } from '@/features/beaconsPage/consts';
import Beacons from '@/features/beaconsPage/Page';
import { canReadBeacons } from '@/features/beaconsPage/permissions';
import { ROUTE_PATH as BRAND_CREATE_PATH } from '@/features/brandCreatePage/consts';
import NewBrand from '@/features/brandCreatePage/Page';
import { ROUTE_PATH as BRAND_EDIT_PATH } from '@/features/brandEditPage/consts';
import EditBrand from '@/features/brandEditPage/Page';
import { ROUTE_PATH as BRAND_PAGE_PATH } from '@/features/brandPage/consts';
import BrandPage from '@/features/brandPage/Page';
import { ROUTE_PATH as BRANDS_PATH } from '@/features/brandsPage/consts';
import Brands from '@/features/brandsPage/Page';
import { ROUTE_PATH as CONTACTS_EDIT_PATH } from '@/features/contactsEditPage/consts';
import ContactsEditPage from '@/features/contactsEditPage/Page';
import { ROUTE_PATH as FEED_LAYOUT_EDIT_PATH } from '@/features/feedLayoutEditPage/consts';
import FeedLayoutEditPage from '@/features/feedLayoutEditPage/Page';
import { ROUTE_PATH as LOCATIONS_PATH } from '@/features/locationsPage/consts';
import Locations from '@/features/locationsPage/Page';
import { canReadLocation } from '@/features/locationsPage/permissions';
import { ROUTE_PATH as MAP_PATH } from '@/features/mapPage/consts';
import MapPage from '@/features/mapPage/Page';
import { ROUTE_PATH as NEW_QUESTION_PATH } from '@/features/questionCreatePage/consts';
import NewQuestion from '@/features/questionCreatePage/Page';
import { ROUTE_PATH as EDIT_QUESTION_PATH } from '@/features/questionEditPage/consts';
import EditQuestion from '@/features/questionEditPage/Page';
import { ROUTE_PATH as QUESTIONS_PATH } from '@/features/questionsPage/consts';
import Questions from '@/features/questionsPage/Page';
import { ROUTE_PATH as SITE_SYNC_PAGE } from '@/features/siteSyncPage/consts';
import SiteSyncPage from '@/features/siteSyncPage/Page';

const Outlet = () => (
  <Switch>
    <CheckAccessRoute
      path={BEACONS_PATH}
      component={Beacons}
      permission={canReadBeacons}
    />
    <CheckAccessRoute
      path={LOCATIONS_PATH}
      component={Locations}
      permission={canReadLocation}
    />
    <CheckAccessRoute
      path={VERSION_PATH}
      component={AppVersionPage}
      permission={canChangeAppVersion}
    />
    <Route path={BRAND_CREATE_PATH} component={NewBrand} />
    <Route path={BRAND_EDIT_PATH} component={EditBrand} />
    <Route path={BRAND_PAGE_PATH} component={BrandPage} />
    <Route path={BRANDS_PATH} component={Brands} />
    <Route path={CONTACTS_EDIT_PATH} component={ContactsEditPage} />
    <Route path={FEED_LAYOUT_EDIT_PATH} component={FeedLayoutEditPage} />
    <Route path={MAP_PATH} component={MapPage} />
    <Route path={EDIT_QUESTION_PATH} component={EditQuestion} />
    <Route path={NEW_QUESTION_PATH} component={NewQuestion} />
    <Route path={QUESTIONS_PATH} component={Questions} />
    <Route path={SITE_SYNC_PAGE} component={SiteSyncPage} />
    <Route path={VERSION_PAGE} component={AppVersionPage} />
    <Redirect replace to={CONTACTS_EDIT_PATH} />
  </Switch>
);

export default Outlet;
