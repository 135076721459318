import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Link from '@/components/Link';
import { formatNumber } from '@/utils/format';

import ReceiptModal from '../../../purchaseHistoryPage/Page/ReceiptModal';

const Row = ({
  reconciliatioBonusCost,
  reconciliationFiscalId,
  merchantName,
  reconciliationId,
}) => {
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);

  const handleReceiptModal = () => {
    setIsReceiptDialogOpen(!isReceiptDialogOpen);
  };

  const preventDefault = event => {
    event.preventDefault();
    handleReceiptModal();
  };

  return (
    <>
      <TableRow>
        <TableCell>{merchantName}</TableCell>
        <TableCell>
          <Link to="#" onClick={preventDefault}>
            {reconciliationFiscalId}
          </Link>
        </TableCell>
        <TableCell align="right">
          {formatNumber(reconciliatioBonusCost)}
        </TableCell>
      </TableRow>
      {isReceiptDialogOpen && (
        <ReceiptModal
          uuid={reconciliationFiscalId}
          receipt={reconciliationId}
          isOpen={isReceiptDialogOpen}
          onClose={handleReceiptModal}
        />
      )}
    </>
  );
};

Row.propTypes = {
  reconciliatioBonusCost: PropTypes.number.isRequired,
  reconciliationFiscalId: PropTypes.string.isRequired,
  merchantName: PropTypes.string,
  reconciliationId: PropTypes.number.isRequired,
};

Row.defaultProps = {
  merchantName: '',
};

export default Row;
