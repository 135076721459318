export default {
  root: {
    padding: [10, 16, 6, 16],
  },

  rentPlaceName: {
    borderBottom: [1, 'solid', '#E4E4E4'],
    marginBottom: 12,
  },

  metricRoot: {
    display: 'inline-block',
    fontWeight: 'normal',
    marginRight: 24,
    marginBottom: 8,
  },

  primaryRoot: {
    fontWeight: 'bold',
  },

  label: {
    fontSize: 10,
    fontWeight: 'inherit',
  },

  value: {
    fontWeight: 'inherit',
    lineHeight: 1,
  },
};
