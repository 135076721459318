import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Selection extends Component {
  static propTypes = {
    Chip: PropTypes.func.isRequired,
    clearSelection: PropTypes.func.isRequired,
    selectedItem: PropTypes.number,
  };

  static defaultProps = {
    selectedItem: null,
  };

  render() {
    const { clearSelection, selectedItem, Chip, classes } = this.props;

    if (!selectedItem) {
      return null;
    }

    return (
      <Chip
        className={classes.chip}
        value={selectedItem}
        onDelete={clearSelection}
      />
    );
  }
}

export default Selection;
