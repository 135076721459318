import createItemsDuck from '@esentai/core/ducks/items';
import { RESOURCE_NAME } from '@esentai/core/features/parkingWhitelist/consts';

import api from '../api';
import { getItemsRoot } from '../utils';

export default createItemsDuck(RESOURCE_NAME, {
  api,
  getRoot: getItemsRoot,
});
