import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import {
  LOGIN_NAME,
  PASSWORD_MISMATCH_CODE,
  PASSWORD_NAME,
  USER_NOT_FOUND_CODE,
} from './consts';
import styles from './styles';

const asyncOptions = { runnerMethod: 'onSubmit' };

const formikOptions = {
  mapPropsToValues: () => ({ [LOGIN_NAME]: '', [PASSWORD_NAME]: '' }),

  validationSchema: object().shape({
    [LOGIN_NAME]: string()
      .email()
      .required(),
    [PASSWORD_NAME]: string().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const errorsMapping = {
  [USER_NOT_FOUND_CODE]: {
    field: LOGIN_NAME,
    message: 'Такого аккаунта нет',
  },
  [PASSWORD_MISMATCH_CODE]: {
    field: PASSWORD_NAME,
    message: 'Неверный пароль',
  },
};

export default compose(
  withStyles(styles),
  withAsyncFormik({ asyncOptions, formikOptions, errorsMapping }),
)(Form);
