import {
  EMAIL_KEY,
  LOCATIONS_IDS_KEY,
  STORE_IDS_KEY,
} from '@/features/invites/consts/keys';

export default {
  [EMAIL_KEY]: 'e-mail',
  [STORE_IDS_KEY]: 'Магазин администратора',
  [LOCATIONS_IDS_KEY]: 'Локации администратора',
};
