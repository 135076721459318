import { connect } from 'react-redux';

import { createKkmPageUrl } from '@/features/kkmPage/utils';

import HistoryButton from './component';

const mapStateToProps = (state, { kkmId }) => ({
  historyPageUrl: createKkmPageUrl(kkmId),
});

export default connect(mapStateToProps)(HistoryButton);
