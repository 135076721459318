import { equals, filter } from '@esentai/core/query-dsl';
import { createAction } from 'redux-actions';

import { CAMPAIGN_TYPE_KEY } from '@/features/campaignsStatistics/consts/dimensions';

import query from './ducks/query';
import { RETRY_FETCH } from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const updateCampaignType = value =>
  updateFilters({ [CAMPAIGN_TYPE_KEY]: filter(equals(value)) });

export const retryFetch = createAction(RETRY_FETCH);
