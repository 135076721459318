import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import TableCellTypography from '@/components/TableCellTypography';

import CommonCells from '../CommonCells';
import TickFormatter from './TickFormatter';
import TimeDistribution from './TimeDistribution';

const Row = ({ averageTicket, purchasesCount, itemId }) => (
  <TableRow>
    <CondenseTableCell>
      <TickFormatter itemId={itemId} component={TableCellTypography} />
    </CondenseTableCell>
    <CommonCells averageTicket={averageTicket} purchasesCount={purchasesCount}>
      <CondenseTableCell>
        <TimeDistribution itemId={itemId} />
      </CondenseTableCell>
    </CommonCells>
  </TableRow>
);

Row.propTypes = {
  averageTicket: PropTypes.string.isRequired,
  purchasesCount: PropTypes.number.isRequired,
  itemId: PropTypes.any.isRequired,
};

export default Row;
