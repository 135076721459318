import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { authorize } from '@/features/auth/actions';
import {
  getAuthorizedStatus,
  getAuthorizeError,
  getAuthorizingStatus,
  isSessionExpired,
} from '@/features/auth/selectors';

import ProtectedRoute from './component';
import styles from './styles';

const mapStateToProps = state => ({
  authorizeError: getAuthorizeError(state),
  authorized: getAuthorizedStatus(state),
  authorizing: getAuthorizingStatus(state),
  sessionExpired: isSessionExpired(state),
});

const mapDispatchToProps = { authorize };

// https://stackoverflow.com/questions/43520498/react-router-private-routes-redirect-not-working
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ProtectedRoute);
