import PropTypes from 'prop-types';
import React from 'react';

import { formatConversion } from '@/utils/format';

import Factoid from '../Factoid';

const ConversionFactoid = ({ classes, conversion, description }) => (
  <Factoid
    name="Конверсия"
    value={formatConversion(conversion)}
    description={description}
    classes={classes}
  />
);

ConversionFactoid.defaultProps = {
  description: 'Соотношение активаций кампании к сегменту',
};

ConversionFactoid.propTypes = {
  conversion: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
};

export default ConversionFactoid;
