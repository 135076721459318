import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import apiResource from './api';
import { RECOVER, RESET } from './types';

export const recoverPassword = function*({ payload }) {
  yield call(apiResource.recover, payload);
};

export const resetPassword = function*({ payload }) {
  yield call(apiResource.reset, payload);
};

export const watchRecoverPassword = function*() {
  yield takeEvery(RECOVER, createTaskSaga(recoverPassword));
};

export const watchResetPassword = function*() {
  yield takeEvery(RESET, createTaskSaga(resetPassword));
};

export default function*() {
  yield spawn(watchRecoverPassword);
  yield spawn(watchResetPassword);
}
