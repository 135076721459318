import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class InputListField extends Component {
  static propTypes = {
    handlerRemoveItem: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    preUrl: PropTypes.string.isRequired,
    Field: PropTypes.func.isRequired,
    helperText: PropTypes.string,
    label: PropTypes.string,
  };

  static defaultProps = {
    helperText: null,
    label: null,
  };

  handlerRemoveItem = () => {
    const { handlerRemoveItem, setFieldValue, name } = this.props;

    setFieldValue(name, null, false);
    handlerRemoveItem(name);
  };

  InputProps = {
    startAdornment: (
      <InputAdornment position="start">{this.props.preUrl}</InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={this.handlerRemoveItem} aria-label="Удалить">
          <CancelIcon />
        </IconButton>
      </InputAdornment>
    ),
  };

  render() {
    const { Field, name, helperText, label } = this.props;

    return (
      <Field
        fullWidth
        name={name}
        Target={TextField}
        InputProps={this.InputProps}
        helperText={helperText}
        label={label}
      />
    );
  }
}
