import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/campaignsPage/ducks/query';
import {
  getCampaginsError,
  getCampaginsIds,
  getCampaginsTotalCount,
  isLoadingCampagins,
} from '@/features/campaignsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import Campaigns from './component';

const mapStateToProps = state => ({
  error: getCampaginsError(state),
  itemsIds: getCampaginsIds(state),
  totalCount: getCampaginsTotalCount(state),
  isLoading: isLoadingCampagins(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(Campaigns);
