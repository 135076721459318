import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import { BALANCE_KEY } from '@/features/efsUsersPage/consts/keys';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import AddPurchase from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: () => ({
    BALANCE_KEY: '',
  }),

  validationSchema: object().shape({
    [BALANCE_KEY]: string().required(),
  }),

  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(AddPurchase);
