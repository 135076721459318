import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getParkingLevelThreeWhitelistError,
  getItemsIds: getParkingLevelThreeWhitelistIds,
  getItemsMeta: getParkingLevelThreeWhitelistMeta,
  getItemsTotalCount: getParkingLevelThreeWhitelistTotalCount,
  isLoadingItems: isLoadingParkingLevelThreeWhitelist,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
