import dao from '@esentai/core/features/reconciliationPeriodBonusDetails/dao';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import { FETCH_DETAILS } from './types';

export const { findById: findDetailsById } = dao.sagas;

export function* fetchItems(action) {
  const { payload: detailsId } = action;

  yield call(findDetailsById, detailsId);
}

export function* watchRetry() {
  yield takeEvery(FETCH_DETAILS, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
}
