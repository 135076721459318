import {
  REGISTERED_STATUS,
  UNREGISTERED_STATUS,
} from '@esentai/core/features/giftCertificates/consts/statuses';

import { statusLabel } from '@/features/giftCertificatesPage/labels';
import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';

export const options = [REGISTERED_STATUS, UNREGISTERED_STATUS].map(key => ({
  key,
  label: statusLabel(key),
}));

export const createRecipientStatusFilter = createCheckboxFilterTemplate({
  options,
});

export default CheckboxGroupFilter;
