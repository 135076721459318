import {
  DATE_CREATED_KEY,
  EXPIRED_AMOUNT_KEY,
  FIRSTNAME_KEY,
  ISSUED_AMOUNT_KEY,
  LASTNAME_KEY,
  RECIPIENT_STATUS_KEY,
} from '@esentai/core/features/giftCertificates/consts/keys';

import { createRecipientStatusFilter } from '@/filters/CertificateRecipientStatus';
import { createDateRangeFilter } from '@/filters/DateRange';
import {
  createMoneyRangeFilter,
  createMoneyRangeFilter as expiredRangeFilter,
} from '@/filters/MoneyRange';
import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: FIRSTNAME_KEY,
    label: 'Имя Отправителя',
  }),
  createTextFilter({
    key: LASTNAME_KEY,
    label: 'Фамилия отправителя',
  }),
  createMoneyRangeFilter({
    key: ISSUED_AMOUNT_KEY,
    label: 'Сумма сертификата',
  }),
  createDateRangeFilter({
    key: DATE_CREATED_KEY,
    label: 'Дата отправки',
  }),
  createRecipientStatusFilter({
    key: RECIPIENT_STATUS_KEY,
    label: 'Статус получателя',
    quicklyAccessible: true,
  }),
  expiredRangeFilter({
    key: EXPIRED_AMOUNT_KEY,
    label: 'Не использованные бонусы',
  }),
];
