import { BIN_KEY } from '@esentai/core/features/merchants/consts/keys';
import { annotations } from '@esentai/core/features/merchants/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(BIN_KEY),
  }),
});
