import { connect } from 'react-redux';

import {
  getAverageTicket,
  getPurchasesCount,
} from '@/features/anonymousPurchasesStatistics/selectors';

import Row from './component';

const mapStateToProps = (state, { item: itemId }) => ({
  averageTicket: getAverageTicket(state, itemId),
  purchasesCount: getPurchasesCount(state, itemId),
  itemId,
});

export default connect(mapStateToProps)(Row);
