import { NAME_RUSSIAN_KEY } from '@esentai/core/features/locations/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={NAME_RUSSIAN_KEY}>Название локации</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>Места на карте</CondenseTableCell>
    <CondenseTableCell>Действия</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
