import PropTypes from 'prop-types';
import React from 'react';

import {
  SUBTITLE_ENGLISH_KEY,
  SUBTITLE_RUSSIAN_KEY,
} from '@/components/ArticleConstructorForm/consts';
import Bilingual from '@/components/Bilingual';
import Label from '@/features/campaignPage/Page/Label';

const Subtitle = ({ payload }) => (
  <Label title="Подзаголовок">
    <Bilingual
      russianContent={payload[SUBTITLE_RUSSIAN_KEY]}
      englishContent={payload[SUBTITLE_ENGLISH_KEY]}
    />
  </Label>
);

Subtitle.propTypes = {
  payload: PropTypes.object.isRequired,
};

export default Subtitle;
