import createActionNames from './createActionNames';
import createActions from './createActions';
import createReducer from './createReducer';
import createSagas from './createSagas';
import createSelectors from './createSelectors';

export default (name, options) => {
  const actionNames = createActionNames(name);
  const actions = createActions(actionNames);
  const reducer = createReducer({
    actionNames,
    ...options,
  });
  const selectors = createSelectors(options);
  const sagas = createSagas({
    actionNames,
    actions,
    selectors,
    ...options,
  });

  return {
    actionNames,
    actions,
    reducer,
    sagas,
    selectors,
  };
};
