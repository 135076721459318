import { STATUS_VALUES } from '@esentai/core/features/merchants/consts/keys';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';

import Label from '../Label';

const Description = ({ company, bin, status }) => (
  <FieldRow name="Описание арендатора">
    <Grid container>
      <Grid item>
        <Label title="Компания">{company}</Label>

        <Label title="БИН/ИИН">{bin}</Label>

        <Label title="Статус">{STATUS_VALUES[status]}</Label>
      </Grid>
    </Grid>
  </FieldRow>
);

Description.propTypes = {
  company: PropTypes.string,
  bin: PropTypes.string,
  status: PropTypes.string,
};

Description.defaultProps = {
  company: null,
  bin: null,
  status: null,
};

export default Description;
