import { RESOURCE_NAME } from '@esentai/core/features/mallValets/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { MALL_VALET } from './consts/permissions';

export const canMallValet = authorizeIf(
  can(permission(RESOURCE_NAME, MALL_VALET)),
);
