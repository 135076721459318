import { connect } from 'react-redux';

import StatisticsTopBar from '@/components/StatisticsTopBar';
import {
  MAX_STATISTICS_DATE,
  MIN_STATISTICS_DATE,
} from '@/features/purchasesStatistics/consts/dates';
import { updateFilters } from '@/features/purchasesStatisticsPage/actions';
import { getFilters } from '@/features/purchasesStatisticsPage/selectors';
import stats from '@/features/purchasesStatisticsPage/stats';

const mapStateToProps = state => ({
  filters: getFilters(state),
  stats,
  minDate: MIN_STATISTICS_DATE,
  maxDate: MAX_STATISTICS_DATE,
});

const mapDispatchToProps = { updateFilters };

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTopBar);
