import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default ({ loadOnce = true } = {}) => WrappedComponent => {
  class WithItemApiRunner extends Component {
    static propTypes = {
      fetchItem: PropTypes.func.isRequired,
      isLoaded: PropTypes.bool.isRequired,
      isLoading: PropTypes.bool.isRequired,
      itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    };

    componentDidMount() {
      const { fetchItem, isLoaded, itemId } = this.props;

      if (this.shouldFetchItem() && !(loadOnce && isLoaded)) {
        fetchItem(itemId);
      }
    }

    componentDidUpdate(prevProps) {
      const { itemId, fetchItem } = this.props;
      const hasQueryHashChanged = itemId !== prevProps.itemId;

      if (hasQueryHashChanged && this.shouldFetchItem()) {
        fetchItem(itemId);
      }
    }

    shouldFetchItem() {
      const { isLoading } = this.props;

      return !isLoading;
    }

    render() {
      const { props } = this;

      return <WrappedComponent {...props} />;
    }
  }

  return WithItemApiRunner;
};
