import {
  AUTHOR_FULL_NAME_KEY,
  DISCOUNT_PERCENT_KEY,
  FILTER_KEY,
  START_DATETIME_KEY,
  STORE_NAME_KEY,
  TITLE_EN_KEY,
  TITLE_KEY,
  TITLE_KZ_KEY,
} from '@esentai/core/features/benefits/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <TableCell>
      <SortControl field={TITLE_KEY}>Название акции на русском</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={TITLE_EN_KEY}>
        Название акции на английском
      </SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={TITLE_KZ_KEY}>
        Название акции на казахском
      </SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={STORE_NAME_KEY}>Название магазина</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={START_DATETIME_KEY}>Период действия</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={DISCOUNT_PERCENT_KEY}>Скидка</SortControl>
    </TableCell>

    <TableCell>
      <SortControl field={FILTER_KEY}>Сегмент</SortControl>
    </TableCell>

    <TableCell>
      <SortControl field={AUTHOR_FULL_NAME_KEY}>Пользователь</SortControl>
    </TableCell>

    <TableCell>Комментарий</TableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
