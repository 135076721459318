import React, { Fragment } from 'react';

import Destination from './Destination';
import Push from './Push';
import Schedule from './Schedule';

const NotificationContent = props => (
  <Fragment>
    <Schedule {...props} />
    <Push {...props} />
    <Destination {...props} />
  </Fragment>
);

export default NotificationContent;
