import { compose } from 'ramda';

// TODO: Reduce duplcation with MyEsentai project
export const extractVideoId = url => {
  const result = /vimeo\.com\/external\/(\d+)/.exec(url);

  if (result && result[1]) {
    return result[1];
  }

  return null;
};

export const buildPlayerUrl = compose(
  x => `https://player.vimeo.com/video/${x}`,
  extractVideoId,
);
