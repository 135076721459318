import {
  ALL_USERS_SEGMENT,
  EXISTING_USERS_SEGMENT,
  NEW_USERS_SEGMENT,
} from '@esentai/core/features/campaigns/consts/segments';
import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  ARCHIVED_STATUS,
  DECLINED_STATUS,
  DRAFT_STATUS,
  MODERATING_STATUS,
  PAUSED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import {
  ACTION_RESULT_TYPE,
  ARTICLE_TYPE,
  BANNER_TYPE,
  BUTTON_TYPE,
  CALENDAR_EVENT_TYPE,
  NOTIFICATION_TYPE,
  PURCHASE_RESULT_TYPE,
  SHOW_TYPE,
} from '@esentai/core/features/campaigns/consts/types';

export const typeLabel = type => {
  const mapping = {
    [ACTION_RESULT_TYPE]: 'Результат поведения',
    [ARTICLE_TYPE]: 'Статья',
    [BANNER_TYPE]: 'Баннер',
    [BUTTON_TYPE]: 'Кнопка',
    [CALENDAR_EVENT_TYPE]: 'Календарное событие',
    [NOTIFICATION_TYPE]: 'Разовая рассылка',
    [SHOW_TYPE]: 'Онлайн витрина',
    [PURCHASE_RESULT_TYPE]: 'Результат покупки',
  };

  return mapping[type];
};

export const statusLabel = status => {
  const mapping = {
    [ACTIVE_STATUS]: 'Активна',
    [ARCHIVED_STATUS]: 'В архиве',
    [APPROVED_STATUS]: 'Ожидает старта',
    [DECLINED_STATUS]: 'Отклонена',
    [DRAFT_STATUS]: 'Черновик',
    [MODERATING_STATUS]: 'На модерации',
    [PAUSED_STATUS]: 'На паузе',
  };

  return mapping[status];
};

export const segmentType = type => {
  const segments = {
    [ALL_USERS_SEGMENT]: 'Все пользователи',
    [EXISTING_USERS_SEGMENT]: 'Существующие',
    [NEW_USERS_SEGMENT]: 'Новые',
  };

  return segments[type];
};
