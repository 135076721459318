import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { date, object } from 'yup';

import {
  CALCULATE,
  EXIT_DATETIME,
  ID_KEY,
  WITH_BILLING,
} from '@/features/parkingPage/consts/keys';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SimulateFinish from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: ({ item }) => ({
    [EXIT_DATETIME]: null,
    [ID_KEY]: item && item.id ? item.id : '',
    [WITH_BILLING]: false,
    [CALCULATE]: true,
  }),

  validationSchema: object().shape({
    [EXIT_DATETIME]: date().required(),
  }),

  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(SimulateFinish);
