import { CircularProgress, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { LoadErrorMessage } from '@/components/ErrorMessages';

const LazyForm = ({ children, error, isLoaded, isLoading }) => {
  if (isLoading) {
    return (
      <Grid container justify="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (isLoaded) {
    return children;
  }

  if (error) {
    return <LoadErrorMessage />;
  }

  return null;
};

LazyForm.defaultProps = {
  error: null,
};

LazyForm.propTypes = {
  error: PropTypes.any,
  isLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LazyForm;
