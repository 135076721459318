import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import DownloadButton from '@/features/executiveReportsPage/Page/Row/Actions/DownloadButton';

const Actions = ({ classes, url }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <DownloadButton url={url} />
  </Grid>
);

Actions.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Actions;
