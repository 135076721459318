import {
  getBenefitTitle,
  getBenefitUserLevel,
} from '@esentai/core/features/benefits/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Suggestion from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  title: getBenefitTitle(state, itemId),
  userLevel: getBenefitUserLevel(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Suggestion);
