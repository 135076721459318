// Metric
export { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';

export const AVERAGE_CHECK_KEY = 'avg_ticket';

export const AVERAGE_TIME_SPENT = 'avg_time_spent';

export const BUYERS_COUNT_KEY = 'nr_buyers';

export const CONVERSION_RATE_KEY = 'conversion_rate';

export const PURCHASES_COUNT_KEY = 'nr_purchases';

export const UNIQUE_LOCATION_LEADS_COUNT = 'nr_unique_location_leads';

export const USERS_COUNT_KEY = 'nr_all_customers';

export const VISITORS_COUNT_KEY = 'nr_visitors';

export const MALL_VISITORS_COUNT_KEY = 'nr_mall_visitors';

// TODO When is constant changed you should update these selectors getTotalUserLeadCount, getUserLeadCountForItem
export const MALL_LEADS_COUNT_KEY = '???';
