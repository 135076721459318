import { IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import {
  addDays,
  differenceInDays,
  endOfDay,
  isAfter,
  isBefore,
  isEqual,
  max,
  min,
  startOfDay,
  subDays,
} from 'date-fns';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ShiftControls = ({ minDate, maxDate, value, onChange, children }) => {
  const { from, to } = value;
  const difference = differenceInDays(to, from);
  const endOfMinDate = endOfDay(minDate);
  const startOfMaxDate = startOfDay(maxDate);
  const moveBack = () =>
    onChange({
      from: max([minDate, subDays(from, difference + 1)]),
      to: subDays(from, 1),
    });
  const moveForward = () =>
    onChange({
      from: addDays(to, 1),
      to: min([maxDate, addDays(to, difference + 1)]),
    });

  return (
    <Fragment>
      {children}
      <IconButton
        disabled={isBefore(from, endOfMinDate) || isEqual(from, endOfMinDate)}
        onClick={moveBack}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        disabled={isAfter(to, startOfMaxDate) || isEqual(to, startOfMaxDate)}
        onClick={moveForward}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Fragment>
  );
};

ShiftControls.propTypes = {
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default ShiftControls;
