import {
  COMMENT_KEY,
  DATE_CREATION_KEY,
  PERCENT_KEY,
  START_DATE_KEY,
  USER_KEY,
} from '@esentai/core/features/mallBonuses/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>Название акции</CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={USER_KEY}>Пользователь</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={DATE_CREATION_KEY}>Дата и время создания</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={PERCENT_KEY}>Процент начисления</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={START_DATE_KEY}>
        Период действия процентов начисления
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Магазин</CondenseTableCell>

    <CondenseTableCell>Сегмент</CondenseTableCell>

    <CondenseTableCell>Статус</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={COMMENT_KEY}>Комментарий</SortControl>
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
