import {
  getTotalActiveAmountKztForSalesTurnover,
  getTotalActiveAmountUsdForSalesTurnover,
  getTotalInactiveAmountKztForSalesTurnover,
  getTotalInactiveAmountUsdForSalesTurnover,
} from '@esentai/core/features/salesTurnovers/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Description from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  totalInactiveAmountKzt: getTotalInactiveAmountKztForSalesTurnover(
    state,
    itemId,
  ),
  totalInactiveAmountUsd: getTotalInactiveAmountUsdForSalesTurnover(
    state,
    itemId,
  ),
  totalActiveAmountKzt: getTotalActiveAmountKztForSalesTurnover(state, itemId),
  totalActiveAmountUsd: getTotalActiveAmountUsdForSalesTurnover(state, itemId),
  itemId,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Description);
