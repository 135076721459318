import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ChartBar from '@/components/Icon/ChartBar';
import Link from '@/components/Link';
import RadioTile from '@/components/RadioTile';
import { floorLabel } from '@/features/locationsStatisticsPage/labels';

class FloorOption extends Component {
  static propTypes = {
    floor: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    statisticsUrl: PropTypes.string.isRequired,
    updateFloor: PropTypes.func.isRequired,
  };

  handleClick = () => {
    const { floor, updateFloor } = this.props;

    updateFloor(floor);
  };

  stopProgapagation = event => {
    event.stopPropagation();
  };

  render() {
    const { classes, floor, selected, statisticsUrl } = this.props;

    return (
      <RadioTile
        checked={selected}
        classes={classes}
        className={classes.root}
        onClick={this.handleClick}
      >
        <div className={classes.content}>
          <Typography variant="h5" color="inherit">
            {floorLabel(floor)}
          </Typography>
          <Link
            to={statisticsUrl}
            className={classes.link}
            onClick={this.stopProgapagation}
          >
            <ChartBar className={classes.icon} />
          </Link>
        </div>
      </RadioTile>
    );
  }
}

export default FloorOption;
