import { addMinutes, addSeconds, format, isSameYear } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import { compose } from 'ramda';

import { week } from './labels';
import { capitalize } from './string';

const dateFnsOptions = { locale: ruLocale };
const numberFormatter = new Intl.NumberFormat('ru');

const nonBreakSpace = '\xa0';

export function almatyUtcOffset(date) {
  return moment(date).isBefore('2024-03-01') ? 12 * 60 : 10 * 60;
}

const replaceSpace = str => str.replace(/\s/g, nonBreakSpace);

export const convertToUTC = date => addMinutes(date, date.getTimezoneOffset());

export const formatNumber = number => numberFormatter.format(number);

export const formatMoney = compose(
  replaceSpace,
  amount => `${amount} ₸`,
  numberFormatter.format,
  Math.round,
);

export const formatMoneyUsd = compose(
  replaceSpace,
  amount => `${amount} $`,
  numberFormatter.format,
  Math.round,
);

export const formatPercentage = percentage => {
  const number = Number(percentage);

  return `${Math.round(number)}%`;
};

export const formatRateAsPercentage = percent =>
  formatPercentage(percent * 100);

export const formatRateAsRoundedPercentage = percent =>
  formatPercentage(Math.round(percent * 100));

export const formatConversion = conversion => {
  const number = Number(conversion);

  return formatPercentage(number * 100);
};

export const formatConversionRate = rate =>
  rate === null ? '—' : formatPercentage(rate * 100);

export const formatHumanDate = date =>
  date ? replaceSpace(format(date, 'd MMMM yyyy', dateFnsOptions)) : null;

export const dateTimeFormat = date =>
  date
    ? moment(date)
        .utcOffset(almatyUtcOffset(date))
        .format('DD.MM.YYYY, HH:mm:ss')
    : null;

export const dateTimeFormatExact = (date, type = 'DD.MM.YYYY, HH:mm:ss') =>
  date ? moment(date).format(type) : null;

export const dateTimeFormatNoSecs = date =>
  date
    ? moment(date)
        .utcOffset(almatyUtcOffset(date))
        .format('DD.MM.YYYY, HH:mm')
    : null;

export const formatHumanDateTime = date =>
  date
    ? replaceSpace(format(date, 'd MMMM yyyy, HH:mm', dateFnsOptions))
    : null;

export const formatShortHumanDate = date =>
  date ? replaceSpace(format(date, 'd MMMM', dateFnsOptions)) : null;

export const formatShortHumanDateTime = date =>
  date
    ? replaceSpace(
        replaceSpace(
          moment(date)
            .utcOffset(almatyUtcOffset(date))
            .format('D MMMM YYYY, HH:mm', dateFnsOptions),
        ),
      )
    : null;

export const formatTime = time =>
  time ? format(time, 'H:mm', dateFnsOptions) : null;

export const formatTimeUtc = time =>
  time
    ? moment(time)
        .utcOffset(10 * 60)
        .format('HH:mm')
    : null;

export const formatSeconds = seconds => {
  const date = addSeconds(convertToUTC(new Date(0)), seconds);

  return format(date, 'H:mm:ss');
};

export const formatDateTime = dateTime =>
  dateTime ? replaceSpace(format(dateTime, 'P HH:mm', dateFnsOptions)) : null;

export const formatMonth = date =>
  date ? capitalize(format(date, 'LLLL', dateFnsOptions)) : null;

export const formatMonthAndYear = date =>
  date
    ? replaceSpace(capitalize(format(date, 'LLLL yyyy', dateFnsOptions)))
    : null;

export const formatWeek = date =>
  date ? week(format(date, 'I', dateFnsOptions)) : null;

export const formatWeekAndYear = date =>
  date ? `${formatWeek(date)} ${format(date, 'RRRR', dateFnsOptions)}` : null;

export const formatDateRange = (firstDate, secondDate) => {
  if (!firstDate && !secondDate) {
    return null;
  }

  if (isSameYear(firstDate, new Date()) && isSameYear(secondDate, new Date())) {
    return `${formatShortHumanDate(firstDate)} — ${formatShortHumanDate(
      secondDate,
    )}`;
  }

  return `${formatHumanDate(firstDate)} — ${formatHumanDate(secondDate)}`;
};

export const formatDateTimeRange = (firstDate, secondDate) => {
  if (!firstDate && !secondDate) {
    return null;
  }

  if (isSameYear(firstDate, new Date()) && isSameYear(secondDate, new Date())) {
    return `${formatShortHumanDateTime(firstDate)} — ${formatShortHumanDateTime(
      secondDate,
    )}`;
  }

  return `${formatHumanDate(firstDate)} — ${formatHumanDate(secondDate)}`;
};

export const getCuttedText = (text, maxLength) => {
  if (text && text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }

  return text;
};
