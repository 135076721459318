import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { createCategory } from '@/features/productsCategoryPage/actions';
import queryDuck from '@/features/productsCategoryPage/ducks/query';
import {
  getError,
  getItemsIds,
  getItemsTotalCount,
  isLoadingItems,
} from '@/features/productsCategoryPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import ProductCategories from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getError(state),
  isLoading: isLoadingItems(state),
  itemsIds: getItemsIds(state),
  totalCount: getItemsTotalCount(state),
});

const mapDispatchToProps = { createCategory };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withQuerySync(queryDuck),
  withStyles(styles),
)(ProductCategories);
