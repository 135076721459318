import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { update } from '@esentai/core/features/brands/sagas';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ROUTE_PATH as BRANDS_PATH } from '@/features/brandsPage/consts';

import { getBrandId } from './selectors';
import { UPDATE_BRAND } from './types';

export function* updateBrand(action) {
  const { payload: attributes } = action;
  const brandId = yield select(getBrandId);

  yield call(update, brandId, attributes);
  yield put(push(BRANDS_PATH));
}

export default function*() {
  yield takeLatest(UPDATE_BRAND, createTaskSaga(updateBrand));
}
