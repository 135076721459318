import {
  __,
  chain,
  compose,
  converge,
  equals,
  evolve,
  filter,
  identity,
  intersection,
} from 'ramda';

import { createAnyOfAdapter } from '@/structs/pageFilterAdapter';

import {
  AFTERNOON_HOURS,
  AFTERNOON_KEY,
  EVENING_HOURS,
  EVENING_KEY,
  MORNING_HOURS,
  MORNING_KEY,
} from './consts';

const parts = [MORNING_KEY, AFTERNOON_KEY, EVENING_KEY];

const convertKeyToHours = key => {
  if (key === MORNING_KEY) {
    return MORNING_HOURS;
  } else if (key === AFTERNOON_KEY) {
    return AFTERNOON_HOURS;
  } else if (key === EVENING_KEY) {
    return EVENING_HOURS;
  }
};

const matchDayPart = values =>
  compose(
    converge(equals, [identity, intersection(values)]),
    convertKeyToHours,
  );

export const createDayPartAdapter = compose(
  evolve({
    pack: pack => compose(pack, chain(convertKeyToHours)),
    unpack: unpack => compose(filter(__, parts), matchDayPart, unpack),
  }),
  createAnyOfAdapter,
);
