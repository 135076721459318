import dao from '@esentai/core/features/merchants/dao';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import { FETCH_MERCHANT } from './consts/types';

export const { findById: findMerchantById } = dao.sagas;

export function* fetchMerchant(action) {
  const { payload: merchantId } = action;

  yield call(findMerchantById, merchantId);
}

export function* watchFetchMerchant() {
  yield takeEvery(FETCH_MERCHANT, fetchMerchant);
}

export default function*() {
  yield spawn(watchFetchMerchant);
}
