import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as React from 'react';

import api from '@/api';

import ReceiptDetails from './ReceiptDetails';
import ReceiptPurchasesTable from './ReceiptPurchasesTable';

class ReceiptModal extends React.Component {
  state = {
    purchases: [],
  };

  static propTypes = {
    id: PropTypes.number.isRequired,
    isOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    saleNumber: PropTypes.number.isRequired,
    totalCost: PropTypes.number.isRequired,
    cashMachineFns: PropTypes.number.isRequired,
    issuedAt: PropTypes.string.isRequired,
  };

  async loadData() {
    const { id } = this.props;
    const getUrl = `/sale/${id}?include=sale_products`;
    const data = await api.doGet(getUrl);

    if (data.sale_product) {
      this.setState({
        purchases: data.sale_product,
      });
    }
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.loadData();
    }
  }

  render() {
    const {
      isOpen,
      onClose,
      totalCost,
      saleNumber,
      cashMachineFns,
      issuedAt,
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="md"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">Чек №{saleNumber}</DialogTitle>

        <DialogContent>
          <ReceiptDetails
            totalCost={totalCost}
            kkmNumber={cashMachineFns}
            purchaseDate={issuedAt}
          />

          <Typography gutterBottom color="textSecondary" variant="h6">
            Покупка
          </Typography>
          {this.state.purchases.length > 0 ? (
            <ReceiptPurchasesTable items={this.state.purchases} />
          ) : (
            '-'
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size={'large'}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ReceiptModal;
