import { isFobiddenError, isNotFoundError } from '@esentai/core/api/utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Forbidden, NotFound } from '@/components/ErrorMessages';
import LoadErrorMessage from '@/components/ErrorMessages/LoadErrorMessage';

import withItemApiRunner from './withItemApiRunner';

export default () => WrappedComponent => {
  class WithItemPageRunner extends Component {
    static propTypes = {
      error: PropTypes.any,
    };

    static defaultProps = {
      error: null,
    };

    render() {
      const { error } = this.props;

      if (error) {
        return this.renderError();
      }

      return <WrappedComponent {...this.props} />;
    }

    renderError() {
      const { error } = this.props;

      if (isFobiddenError(error)) {
        return <Forbidden />;
      }

      if (isNotFoundError(error)) {
        return <NotFound />;
      }

      return <LoadErrorMessage error={error} />;
    }
  }

  return withItemApiRunner({ loadOnce: false })(WithItemPageRunner);
};
