export default {
  root: {
    backgroundColor: '#F8FBF6',
    padding: [20, 30],
  },

  wrapper: {
    borderTop: 'none',
  },
};
