import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  formatDescriptorLabel,
  formatDescriptorValue,
} from '@/features/statisticsPage/utils';

const Metric = ({ classes, descriptor, primary, value }) => (
  <div
    className={classNames(classes.metricRoot, {
      [classes.primaryRoot]: primary,
    })}
  >
    <Typography variant="h5" className={classes.value}>
      {formatDescriptorValue(descriptor, value)}
    </Typography>
    <Typography className={classes.label}>
      {formatDescriptorLabel(descriptor, value)}
    </Typography>
  </div>
);

Metric.defaultProps = {
  primary: false,
};

Metric.propTypes = {
  descriptor: PropTypes.object.isRequired,
  primary: PropTypes.bool,
  value: PropTypes.any.isRequired,
};

export default Metric;
