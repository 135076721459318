export default {
  root: {
    display: 'flex',
    position: 'relative',
  },

  content: {
    flex: 1,
  },

  title: {
    marginBottom: 8,
  },
};
