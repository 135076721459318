import {
  getPushEnglishMessage,
  getPushEnglishTitle,
  getPushRussianMessage,
  getPushRussianTitle,
  shouldSendPushNotifications,
} from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { connect } from 'react-redux';

import Push from './component';

const mapStateToProps = (state, { itemId }) => ({
  pushEnglishMessage: getPushEnglishMessage(state, itemId),
  pushEnglishTitle: getPushEnglishTitle(state, itemId),
  pushRussianMessage: getPushRussianMessage(state, itemId),
  pushRussianTitle: getPushRussianTitle(state, itemId),
  sendingPushNotifications: shouldSendPushNotifications(state, itemId),
});

export default connect(mapStateToProps)(Push);
