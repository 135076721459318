import api from '@esentai/core/features/users/api';
import {
  NAME_KEY,
  ROLE_KEY,
  STATE_KEY,
} from '@esentai/core/features/users/consts/keys';
import { equals, ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';
import { MALL_COMMISSAR_ROLE } from '@/features/personnel/consts/roles';

import CommissarChip from './CommissarChip';
import CommissarOption from './CommissarOption';

const buildQuery = queryString =>
  query(
    where(NAME_KEY, ilike(queryString)),
    where(ROLE_KEY, equals(MALL_COMMISSAR_ROLE)),
    where(STATE_KEY, equals('active')),
  );

const withStoreProps = defaultProps({
  api,
  buildQuery,
  Chip: CommissarChip,
  Option: CommissarOption,
});

export const CommissarAutocomplete = withStoreProps(AutocompleteAPI);

export const CommissarMultiAutocomplete = withStoreProps(
  AutocompleteAPIMultiple,
);
