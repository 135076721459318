import { TableRow } from '@material-ui/core';
// import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
// import { ROUTE_PATH } from '@/features/carListPage/consts';
// import { USER_KEY } from '@/features/carListPage/consts/keys';

// eslint-disable-next-line arrow-body-style
const HeadRow = () => {
  // const [sortFields, setSortFields] = useState({
  //   [USER_KEY]: [{}],
  // });

  // const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  // const currentUrlParams = new URLSearchParams(window.location.search);

  // const changeFilter = fieldName => {
  //   Object.keys(initialSortFields).forEach(field => {
  //     initialSortFields[field] = [{}];
  //   });

  //   const changedField = fieldName[0];

  //   initialSortFields[changedField.field] = [changedField];

  //   if (changedField.direction === 'asc') {
  //     currentUrlParams.set('sort', `${changedField.field}`);
  //   } else {
  //     currentUrlParams.set('sort', `-${changedField.field}`);
  //   }

  //   props.history.push({
  //     pathname: ROUTE_PATH,
  //     search: `?${decodeURIComponent(currentUrlParams.toString())}`,
  //   });

  //   setSortFields(initialSortFields);
  // };

  // useEffect(() => {
  //   const field = new URLSearchParams(window.location.search).get('sort');

  //   if (field) {
  //     const fieldName = field.replace(/-/g, '');

  //     initialSortFields[fieldName] = [
  //       {
  //         field: fieldName,
  //         direction: field.indexOf('-') !== -1 ? 'desc' : 'asc',
  //       },
  //     ];
  //     setSortFields(initialSortFields);
  //   }
  // }, []);

  return (
    <TableRow>
      <CondenseTableCell>
        {/* <SortControl
          sort={sortFields[USER_KEY]}
          field={USER_KEY}
          setSort={changeFilter}
        > */}
        ID пользователя
        {/* </SortControl> */}
      </CondenseTableCell>
      <CondenseTableCell>
        1-ая добавленная машина(Гос номер + Модель)
      </CondenseTableCell>
      <CondenseTableCell>Дата добавления</CondenseTableCell>
      <CondenseTableCell>
        2-ая добавленная машина(Гос номер + Модель)
      </CondenseTableCell>
      <CondenseTableCell>Дата добавления</CondenseTableCell>
      <CondenseTableCell>
        3-я добавленная машина(Гос номер + Модель)
      </CondenseTableCell>
      <CondenseTableCell>Дата добавления</CondenseTableCell>
    </TableRow>
  );
};

// HeadRow.propTypes = {
//   SortControl: PropTypes.func.isRequired,
//   history: PropTypes.object.isRequired,
// };

export default HeadRow;
