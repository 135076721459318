import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { SHOULD_SYNC_SITE_CONTENT_KEY } from '@esentai/core/features/applicationSettings/consts/keys';
import { updateSettings } from '@esentai/core/features/applicationSettings/sagas';
import { call, takeEvery } from 'redux-saga/effects';

import { SYNCHRONIZE } from './types';

export function* synchronize() {
  yield call(updateSettings, {
    [SHOULD_SYNC_SITE_CONTENT_KEY]: true,
  });
}

export default function*() {
  yield takeEvery(SYNCHRONIZE, createTaskSaga(synchronize));
}
