import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { createHistoryData } from '@esentai/core/features/history_data/sagas';
import dao from '@esentai/core/features/merchants/dao';
import { updateMerchant } from '@esentai/core/features/merchants/sagas';
import { select, takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';

import { getCurrentUserId } from '@/features/auth/selectors';
import { getMerchantId } from '@/features/merchantPage/selectors';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';

import { FETCH_MERCHANT, UPDATE_MERCHANT } from './consts/types';

export const { findById: findMerchantById } = dao.sagas;

export function* fetchMerchant(action) {
  const { payload: merchantId } = action;

  yield call(findMerchantById, merchantId);
}

export function* updateMerchantSaga(action) {
  const { payload: attributes } = action;
  const merchantId = yield select(getMerchantId);
  const history = {
    object_id: merchantId,
    model: 'merchant',
    user_id: yield select(getCurrentUserId),
    comment: attributes.comment,
    new_data: JSON.stringify(attributes),
    operation: 'update',
  };

  yield call(updateMerchant, merchantId, attributes);
  yield call(createHistoryData, history);
  yield put(push(MERCHANTS_PATH));
}

export function* watchFetchMerchant() {
  yield takeEvery(FETCH_MERCHANT, fetchMerchant);
}

export function* watchUpdateMerchant() {
  yield takeEvery(UPDATE_MERCHANT, updateMerchantSaga);
}

export default function*() {
  yield spawn(watchFetchMerchant);
  yield takeLatest(UPDATE_MERCHANT, createTaskSaga(updateMerchantSaga));
}
