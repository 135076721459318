export default {
  root: {
    height: 302,
    backgroundColor: '#707070',
    boxShadow: 'inset 0px -30px 60px -20px rgba(255, 255, 255, 1)',
  },

  topBar: {
    marginBottom: 24,
  },
};
