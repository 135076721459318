import { REWARD_TYPE_KEY } from '@esentai/core/features/campaigns/actionResult/consts/keys';
import {
  BENEFITS_REWARD,
  LOYALTY_POINTS_REWARD,
} from '@esentai/core/features/campaigns/actionResult/consts/rewards';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';

import { CanEdit, CanEditRadioBoxGroup } from '../../CanEdit';
import BenefitsForm from './BenefitsForm';
import LoyaltyPointsForm from './LoyaltyPointsForm';

class RewardForm extends PureComponent {
  static propTypes = {
    rewardType: PropTypes.any.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  };

  toggleRewardType = () => {
    const { rewardType } = this.props;

    if (rewardType) {
      this.clearReward();
    } else {
      this.setReward(LOYALTY_POINTS_REWARD);
    }
  };

  clearReward = () => {
    this.setReward(null);
  };

  handleRewardChange = event => {
    const { value } = event.target;

    this.setReward(value);
  };

  setReward = value => {
    const { setFieldValue } = this.props;

    setFieldValue(REWARD_TYPE_KEY, value);
  };

  render() {
    const { rewardType } = this.props;

    return (
      <FieldRow
        label="Награда"
        subLabel="Вознаграждение клиента за выполнение условия"
        sudDesc="Если клиент выполняет установленное условие, вы можете выдать ему вознаграждение в виде дополнительного бенефита, гифта или очков лояльности."
      >
        <CanEdit field={REWARD_TYPE_KEY}>
          <FormControlLabel
            label="Выдать клиенту вознаграждение"
            control={
              <Checkbox
                checked={Boolean(rewardType)}
                onChange={this.toggleRewardType}
              />
            }
          />
        </CanEdit>

        {rewardType ? (
          <div>
            <FieldDescription
              title="Тип вознаграждения"
              desc="Укажите, какой тип вознаграждения будет выдан клиенту."
            />
            <CanEditRadioBoxGroup field={REWARD_TYPE_KEY}>
              <RadioBoxGroup
                name={REWARD_TYPE_KEY}
                value={rewardType}
                onChange={this.handleRewardChange}
              >
                <RadioBox
                  value={LOYALTY_POINTS_REWARD}
                  label="Бонусы"
                  helperText="Клиенту будут начислены очки лояльности, которые он сможет в последующем использовать по назначению."
                />
                <RadioBox
                  value={BENEFITS_REWARD}
                  label="Бенефит"
                  helperText="Клиенту будет открыт доступ к дополнительному бенефиту. Присоединить можно только бенефит «Без статуса»."
                />
              </RadioBoxGroup>
            </CanEditRadioBoxGroup>
            <CanEdit field={REWARD_TYPE_KEY}>
              {rewardType === LOYALTY_POINTS_REWARD ? (
                <LoyaltyPointsForm {...this.props} />
              ) : null}

              {rewardType === BENEFITS_REWARD ? (
                <BenefitsForm {...this.props} />
              ) : null}
            </CanEdit>
          </div>
        ) : null}
      </FieldRow>
    );
  }
}

export default RewardForm;
