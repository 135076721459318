import { MAIN_FEED_LAYOUT_KEY } from '@esentai/core/features/applicationSettings/consts/keys';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import ConstructorAddButton from '@/components/ConstructorAddButton';
import { getBlockId } from '@/features/feedLayoutEditPage/structs/block';
import {
  addLayoutEmptyBlock,
  canMoveLayoutBlockDown,
  canMoveLayoutBlockUp,
  changeLayoutBlockType,
  getLayoutAvailableBlockOptions,
  moveLayoutBlockDown,
  moveLayoutBlockUp,
  removeLayoutBlock,
} from '@/features/feedLayoutEditPage/structs/layout';
import FormikPropTypes from '@/prop-types/formik';

import Block from './Block';
import BlockContent from './BlockContent';

const fieldNames = {
  [MAIN_FEED_LAYOUT_KEY]: 'компоновка',
};

class EditFeedLayoutForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
  };

  getLayout = () => {
    const { values } = this.props;

    return values[MAIN_FEED_LAYOUT_KEY];
  };

  setLayout = layout => {
    const { setFieldValue } = this.props;

    setFieldValue(MAIN_FEED_LAYOUT_KEY, layout);
  };

  handleAddBlock = () => {
    const layout = this.getLayout();
    const updatedLayout = addLayoutEmptyBlock(layout);

    this.setLayout(updatedLayout);
  };

  handleBlockMoveDown = block => {
    const layout = this.getLayout();
    const updatedLayout = moveLayoutBlockDown(layout, block);

    this.setLayout(updatedLayout);
  };

  handleBlockMoveUp = block => {
    const layout = this.getLayout();
    const updatedLayout = moveLayoutBlockUp(layout, block);

    this.setLayout(updatedLayout);
  };

  handleBlockRemove = block => {
    const layout = this.getLayout();
    const updatedLayout = removeLayoutBlock(layout, block);

    this.setLayout(updatedLayout);
  };

  handleBlockTypeChange = (block, newType) => {
    const layout = this.getLayout();
    const updatedLayout = changeLayoutBlockType(layout, block, newType);

    this.setLayout(updatedLayout);
  };

  render() {
    const { classes, Form, RequiredMessages, SubmitButton } = this.props;
    const layout = this.getLayout();

    return (
      <Form>
        {layout.map(block => (
          <div key={getBlockId(block)} className={classes.block}>
            <Block
              block={block}
              canMoveDown={canMoveLayoutBlockDown(layout, block)}
              canMoveUp={canMoveLayoutBlockUp(layout, block)}
              disabled={false}
              onMoveDown={this.handleBlockMoveDown}
              onMoveUp={this.handleBlockMoveUp}
              onRemove={this.handleBlockRemove}
              onTypeChange={this.handleBlockTypeChange}
              options={getLayoutAvailableBlockOptions(layout, block)}
              BlockContent={this.renderContent}
            />
          </div>
        ))}

        <ConstructorAddButton onClick={this.handleAddBlock}>
          Добавить еще один блок
        </ConstructorAddButton>

        <ButtonSet>
          <RequiredMessages fieldNames={fieldNames} />
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            Сохранить изменения
          </SubmitButton>
        </ButtonSet>
      </Form>
    );
  }

  renderContent = props => {
    const { setFieldValue, Field } = this.props;
    const layout = this.getLayout();
    const index = layout.indexOf(props.block);

    return (
      <BlockContent
        {...props}
        index={index}
        setFieldValue={setFieldValue}
        Field={Field}
      />
    );
  };
}

export default EditFeedLayoutForm;
