import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getCampaignType } from '@/features/campaignsStatisticsDetailsPage/selectors';

import CampaignFunnel from './component';
import styles from './styles';

const mapStateToProps = state => ({
  campaignType: getCampaignType(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CampaignFunnel);
