export const ACTIVATIONS_COUNT_METRIC = 'activations';

export const CLICKS_COUNT_METRIC = 'clicks';

export const CONVERSION_METRIC = 'conversion';

export const COVERAGE_METRIC = 'coverage';

export const PRE_PUSH_CLICKS_COUNT_METRIC = 'pre_push_clicks';

export const PUSH_CLICKS_COUNT_METRIC = 'push_clicks';

export const SEGMENT_METRIC = 'segment';

export const VIEWS_COUNT_METRIC = 'views';

export const ACTION_RESULT_METRICS_IDS = [
  SEGMENT_METRIC,
  PRE_PUSH_CLICKS_COUNT_METRIC,
  ACTIVATIONS_COUNT_METRIC,
  PUSH_CLICKS_COUNT_METRIC,
  CONVERSION_METRIC,
];

export const ARTICLE_METRICS_IDS = [
  SEGMENT_METRIC,
  VIEWS_COUNT_METRIC,
  COVERAGE_METRIC,
  CLICKS_COUNT_METRIC,
  CONVERSION_METRIC,
];

export const BANNER_METRICS_IDS = [
  SEGMENT_METRIC,
  VIEWS_COUNT_METRIC,
  COVERAGE_METRIC,
  CLICKS_COUNT_METRIC,
  CONVERSION_METRIC,
];

export const BUTTON_METRICS_IDS = [
  SEGMENT_METRIC,
  CLICKS_COUNT_METRIC,
  CONVERSION_METRIC,
];

export const CALENDAR_EVENT_METRICS_IDS = [
  SEGMENT_METRIC,
  VIEWS_COUNT_METRIC,
  COVERAGE_METRIC,
  CLICKS_COUNT_METRIC,
  CONVERSION_METRIC,
];

export const NOTIFICATION_METRICS_IDS = [
  SEGMENT_METRIC,
  CLICKS_COUNT_METRIC,
  CONVERSION_METRIC,
];

export const PURCHASE_RESULT_METRICS_IDS = [
  SEGMENT_METRIC,
  ACTIVATIONS_COUNT_METRIC,
  CLICKS_COUNT_METRIC,
  CONVERSION_METRIC,
];
