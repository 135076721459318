import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import {
  bannerType as bannerTypeLabel,
  priority as priorityLabel,
} from '@/features/campaignPage/labels';
import Label from '@/features/campaignPage/Page/Label';

class Placement extends Component {
  render() {
    const { placement, classes, priority } = this.props;

    return (
      <FieldRow label="Размещение">
        <Typography className={classes.placementText} color="inherit">
          {bannerTypeLabel(placement)}
        </Typography>
        <Label title="Приоритет баннера">{priorityLabel(priority)}</Label>
      </FieldRow>
    );
  }
}

Placement.propTypes = {
  placement: PropTypes.string,
  priority: PropTypes.number,
};

Placement.defaultProps = {
  placement: null,
  priority: null,
};

export default Placement;
