import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Link from '@/components/Link';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { dateTimeFormatNoSecs } from '@/utils/format';

import { createResourceIdURL } from '../../reconciliationReceiptBonusDetailsPage/utils';
import { ROUTE_PATH as RECONCILIATIONS_PATH } from '../../reconciliationStoreToCpPage/consts';
import AttachmentButton from './AttachmentButton';
import { CmTable } from './CmTable/CmTable';
import Description from './Description';
import { RepaymentTable } from './RepaymentTable/RepaymentTable';

const cmHeaders = ['Номер ККМ', 'Бонусы к начислению', 'Активные бонусы'];

const repaymentHeaders = ['Дата оплаты', 'Сумма оплаты', 'Документ'];

class ReconciliationPeriodBonusDetails extends Component {
  static propTypes = {
    itemId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    endDatetime: '-',
    startDatetime: '-',
  };

  render() {
    const { classes, itemId } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={RECONCILIATIONS_PATH}>
              Бонусы от магазина
            </Breadcrumb>
          </Breadcrumbs>
          <PageTitle gutterBottom={false}>
            Детализация бонусов на период
          </PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Description itemId={itemId} />
            <CmTable detailId={itemId} headers={cmHeaders}>
              {rows =>
                rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={createResourceIdURL(itemId)}>{row.fns_id}</Link>
                    </TableCell>
                    <TableCell>{row.pending_bonuses}</TableCell>
                    <TableCell>{row.active_bonuses}</TableCell>
                  </TableRow>
                ))
              }
            </CmTable>
            <RepaymentTable detailId={itemId} headers={repaymentHeaders}>
              {rows =>
                rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {dateTimeFormatNoSecs(row.record_created)}
                    </TableCell>
                    <TableCell>{row.paid}</TableCell>
                    <TableCell align="center">
                      {row.payment_file_url ? (
                        <AttachmentButton href={row.payment_file_url} />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                ))
              }
            </RepaymentTable>
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default ReconciliationPeriodBonusDetails;
