import { connect } from 'react-redux';

import {
  getTotalAverageCheck,
  getTotalAverageTimeSpent,
  getTotalBuyersCount,
  getTotalConversion,
  getTotalLeadsCount,
  getTotalMallVisitorsCount,
  getTotalPurchasesCount,
  getTotalUserLeadCount,
  getTotalUsersCount,
  getTotalVisitorsCount,
} from '@/features/usersStatisticsPage/selectors';

import TotalRow from './component';

const mapStateToProps = state => ({
  averageCheck: getTotalAverageCheck(state),
  averageTimeSpent: getTotalAverageTimeSpent(state),
  buyersCount: getTotalBuyersCount(state),
  conversion: getTotalConversion(state),
  leadsCount: getTotalLeadsCount(state),
  purchasesCount: getTotalPurchasesCount(state),
  usersCount: getTotalUsersCount(state),
  visitorsCount: getTotalVisitorsCount(state),
  mallVisitorsCount: getTotalMallVisitorsCount(state),
  usersLeadCount: getTotalUserLeadCount(state),
});

export default connect(mapStateToProps)(TotalRow);
