export default {
  fact: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  facts: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flexWrap: 'wrap',
    marginTop: 15,
  },
  row: {
    display: 'flex',
    gap: '8px',
    placeItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  typography: {
    width: 200,
  },
  divider: {
    margin: '30px 0',
  },
};
