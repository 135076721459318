import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/button/consts/destinations';
import {
  DESTINATION_KEY,
  ENGLISH_TITLE_KEY,
  PLACEMENT_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_STORE_ID_KEY,
  RUSSIAN_TITLE_KEY,
  SHOW_POINTER_KEY,
} from '@esentai/core/features/campaigns/button/consts/keys';
import { object, string } from 'yup';

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({
    articleId,
    benefitId,
    destination,
    englishTitle,
    placement,
    russianTitle,
    showPointer,
    storeId,
  }) => ({
    [DESTINATION_KEY]: destination,
    [ENGLISH_TITLE_KEY]: englishTitle || '',
    [RUSSIAN_TITLE_KEY]: russianTitle || '',
    [PLACEMENT_KEY]: placement || 'top',
    [REFERRED_ARTICLE_ID_KEY]: articleId,
    [REFERRED_BENEFIT_ID_KEY]: benefitId,
    [REFERRED_STORE_ID_KEY]: storeId,
    [SHOW_POINTER_KEY]: showPointer || false,
  }),

  validationSchema: object().shape({
    [DESTINATION_KEY]: string().nullable(),
    [ENGLISH_TITLE_KEY]: string()
      .max(50)
      .required(),
    [RUSSIAN_TITLE_KEY]: string()
      .max(50)
      .required(),
    [PLACEMENT_KEY]: string().required(),
    [REFERRED_ARTICLE_ID_KEY]: string().when(DESTINATION_KEY, {
      is: ARTICLE_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [REFERRED_BENEFIT_ID_KEY]: string().when(DESTINATION_KEY, {
      is: BENEFIT_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [REFERRED_STORE_ID_KEY]: string().when(DESTINATION_KEY, {
      is: STORE_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
