import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import MoveDownButton from './MoveDownButton';
import MoveUpButton from './MoveUpButton';

const SortControls = ({ classes, canMoveViewDown, canMoveViewUp, itemId }) => (
  <div>
    <MoveUpButton
      itemId={itemId}
      className={classNames({ [classes.invisible]: !canMoveViewUp })}
    />
    <MoveDownButton
      itemId={itemId}
      className={classNames({ [classes.invisible]: !canMoveViewDown })}
    />
  </div>
);

SortControls.propTypes = {
  canMoveViewDown: PropTypes.bool.isRequired,
  canMoveViewUp: PropTypes.bool.isRequired,
  itemId: PropTypes.number.isRequired,
};

export default SortControls;
