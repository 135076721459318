import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { array, date, number, object, string } from 'yup';

import {
  AMOUNT_KEY,
  FILE_URL_KEY,
  ISSUED_AT_DATETIME_KEY,
  KKM_KEY,
  NAME_KEY,
  PRICE_KEY,
  PURCHASE_KEY,
  STORE_KEY,
  UUID_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import AddPurchase from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: () => ({
    [UUID_KEY]: '',
    [ISSUED_AT_DATETIME_KEY]: null,
    [STORE_KEY]: '',
    [PURCHASE_KEY]: [
      {
        [AMOUNT_KEY]: '',
        [NAME_KEY]: '',
        [PRICE_KEY]: '',
      },
    ],
    [KKM_KEY]: '',
    [FILE_URL_KEY]: ['', '', ''],
  }),

  validationSchema: object().shape({
    [UUID_KEY]: string().required(),
    [ISSUED_AT_DATETIME_KEY]: date().required(),
    [STORE_KEY]: string().required(),
    [KKM_KEY]: string().required(),
    [PURCHASE_KEY]: array().of(
      object().shape({
        [AMOUNT_KEY]: number()
          .min(1, 'Невалидное значение')
          .required(),
        [NAME_KEY]: string()
          .min(10)
          .required(),
        [PRICE_KEY]: number()
          .min(1, 'Невалидное значение')
          .required(),
      }),
    ),
  }),

  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(AddPurchase);
