import { TOKEN_KEY } from '@esentai/core/features/benefitItems/consts/keys';
import { BENEFIT_RELATION } from '@esentai/core/features/benefitItems/consts/relations';
import { getBenefitItemBenefitId } from '@esentai/core/features/benefitItems/selectors';
import {
  getBenefitImageUrl,
  getBenefitTitle,
} from '@esentai/core/features/benefits/selectors';
import { equals, include, query, where } from '@esentai/core/query-dsl';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import api from '@/features/benefitGiveOutPage/api';
import withItemsFetcher from '@/hocs/withItemsFetcher';

import GiveOut from './component';
import styles from './styles';

const buildQuery = token =>
  query(include(BENEFIT_RELATION), where(TOKEN_KEY, equals(token)));

const mapStateToProps = (state, { suggestions }) => {
  const itemId = suggestions[0];
  const benefitId = getBenefitItemBenefitId(state, itemId);

  return {
    itemId,
    title: getBenefitTitle(state, benefitId),
    imageUrl: getBenefitImageUrl(state, benefitId),
  };
};

export default compose(
  withStyles(styles),
  defaultProps({ api, buildQuery }),
  withItemsFetcher,
  connect(mapStateToProps),
)(GiveOut);
