import { T } from 'ramda';
import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { getQuestionEditUrl } from '@/features/questionsPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  editPageUrl: getQuestionEditUrl(state, itemId),
  permission: T,
});

export default connect(mapStateToProps)(EditButton);
