export default {
  root: {
    marginRight: 20,
    userSelect: 'none',
    cursor: 'pointer',
  },

  active: {
    color: '#000000',
  },
};
