export default {
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  countdown: {
    flexShrink: 0,
    marginRight: 10,
  },
};
