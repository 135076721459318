export default () => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  dimmed: {
    backgroundColor: '#F5F5F5',
  },
});
