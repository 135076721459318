import createAPISerializer from '@esentai/core/serializers/api';
import { date, number, string } from '@esentai/core/serializers/params';

import {
  CUSTOMER_AGE_KEY,
  CUSTOMER_AVERAGE_CHECK_KEY,
  CUSTOMER_BIRTH_DATE_KEY,
  CUSTOMER_GENDER_KEY,
  CUSTOMER_STATUS_KEY,
  DATE_RANGE_KEY,
  LOCATION_ID_KEY,
} from './consts/filterKeys';
import { TIMEFRAME_KEY } from './consts/keys';

export const annotations = {
  [CUSTOMER_AGE_KEY]: number(),
  [CUSTOMER_AVERAGE_CHECK_KEY]: number(),
  [CUSTOMER_BIRTH_DATE_KEY]: date(),
  [CUSTOMER_GENDER_KEY]: string(),
  [CUSTOMER_STATUS_KEY]: string(),
  [DATE_RANGE_KEY]: date(),
  [TIMEFRAME_KEY]: date(),
  [LOCATION_ID_KEY]: string(),
};

export default createAPISerializer(annotations);
