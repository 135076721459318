import { path } from 'ramda';

import { ENTITIES_STORE_KEY } from '@/redux/constants';

import { ENTITY_NAME } from './consts';

export const percentage = (divisorSelector, dividerSelector) => (...args) => {
  const divisor = divisorSelector(...args);
  const divider = dividerSelector(...args);

  return (100 * divisor) / divider;
};

export const getDaoRoot = path([ENTITIES_STORE_KEY, ENTITY_NAME]);
