import api from '@esentai/core/features/history_data/api';
import { Button, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

import NoMatchMessage from '../../components/CommonTable/NoMatchMessage';
import SpinnerRow from '../../components/CommonTable/SpinnerRow';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import { downloadXls } from './utils';

interface RowData {
  id: number;
  operation: string;
  comment: string;
  user_id: number;
  user: string;
  new_data: any; // TODO: Define a generic for this
  record_created: string;
}

export interface HistoryPageProps {
  model: string;
  objectId: number;
  headers: string[];
  children: (data: any) => JSX.Element;
}

export interface HistoryPageState {
  limit: number;
  page: number;
  data: RowData[];
  total: number;
  loading: boolean;
}

const styles = {
  tbody: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
};

export class HistoryPage extends React.Component<
  HistoryPageProps,
  HistoryPageState
> {
  constructor(props: HistoryPageProps) {
    super(props);

    this.state = {
      limit: 10,
      page: 0,
      total: 0,
      data: [],
      loading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.loadData();
  }

  public render(): JSX.Element {
    const { headers, model, objectId } = this.props;
    const { data, page, total, limit } = this.state;

    const downloadXlsReport = () => {
      downloadXls(model, objectId);
    };

    const onChangePage = (event, newPage) => {
      this.setState(
        prev => ({ data: [], limit: prev.limit, total: 0, page: newPage }),
        this.loadData,
      );
    };

    const onChangeRows = event =>
      this.setState(
        { data: [], limit: event.target.value, total: 0, page: 0 },
        this.loadData,
      );

    return (
      <Page>
        <PageHeader gutterBottom={false}>
          <PageTitle>История изменений</PageTitle>
        </PageHeader>
        <PageContent>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadXlsReport}
          >
            Вывести в Excel
          </Button>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={styles.tbody}>{this.renderBody(data)}</TableBody>
            <TablePagination
              labelRowsPerPage="Показывать на странице:"
              count={total}
              page={page}
              rowsPerPage={limit}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRows}
            />
          </Table>
        </PageContent>
      </Page>
    );
  }

  public renderBody(data: any[]): JSX.Element {
    const { children } = this.props;

    if (data.length > 0) {
      return children(data);
    }

    if (this.state.loading) {
      return <SpinnerRow columnsCount={4} />;
    }

    return <NoMatchMessage columnsCount={100} />;
  }

  private async loadData(): Promise<void> {
    this.setState(prev => ({ ...prev, loading: true }));
    const { limit, page } = this.state;
    const { objectId, model } = this.props;
    const filters = [
      ['object_id', 'eq', objectId],
      ['model', 'eq', model],
    ];

    const data = await api.doQuery(filters, 'id', 'asc', limit, page, ['user']);

    if (data.payload.history_data.length > 0) {
      const users = data.payload.user.reduce(
        (a, b) => Object.assign({ [b.id]: b.full_name }, a),
        {},
      );

      this.setState({
        loading: false,
        total: data.meta.total,
        data: data.payload.history_data.map(h => ({
          ...h,
          user: users[h.user_id],
          data: JSON.parse(h.new_data),
        })),
      });
    } else {
      this.setState({ loading: false });
    }
  }
}
