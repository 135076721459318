import { FILTER_KEY } from '@esentai/core/features/benefits/consts/keys';
import api from '@esentai/core/features/users/api';
import { query as q } from '@esentai/core/query-dsl';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import Button from '@/components/Button';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FilterBar from '@/components/FilterBar';
import { SHOULD_SUBMIT_AFTER_SAVE_KEY } from '@/features/benefitCreatePage/consts/keys';
import { createFormStepURL } from '@/features/benefitCreatePage/utils';
import FormikPropTypes from '@/prop-types/formik';
import { removeFilters, updateFilters } from '@/redux/query/utils';

// import ContentForm from './ContentForm';
import { filterTypesShort } from './filters';

const fieldNames = {};

class SecondStep extends Component {
  static propTypes = FormikPropTypes;

  componentDidMount() {
    this.updateUsers();
  }

  constructor(props) {
    super(props);

    this.state = { users: 0 };
  }

  removeFilters = keys => {
    const { values, setFieldValue } = this.props;
    const query = values[FILTER_KEY];

    setFieldValue(FILTER_KEY, removeFilters(query, keys));
    setTimeout(() => this.updateUsers(), 500);
  };

  clearFilters = () => {
    const { setFieldValue } = this.props;

    setFieldValue(FILTER_KEY, q());
    setTimeout(() => this.updateUsers(), 500);
  };

  updateFilters = updates => {
    const { values, setFieldValue } = this.props;
    const query = values[FILTER_KEY];

    setFieldValue(FILTER_KEY, updateFilters(query, updates));
    setFieldValue('all', false);
    setTimeout(() => this.updateUsers(), 500);
  };

  toggleShouldSubmitAfterSave = event => {
    const { checked } = event.target;
    const { setFieldValue } = this.props;

    setFieldValue(SHOULD_SUBMIT_AFTER_SAVE_KEY, checked);
  };

  updateUsers() {
    const filters = [];

    for (const [filter, entry] of this.props.values[FILTER_KEY].get(
      'filters',
    ).entries()) {
      for (const [key, value] of entry.entries()) {
        const v = value;

        filters.push([filter, key, v]);
      }
    }
    api
      .doQuery(filters)
      .then(users => this.setState({ users: users.meta.total }));
  }

  render() {
    const {
      classes,
      handleSubmit,
      id,
      SubmitButton,
      RequiredMessages,
      values,
    } = this.props;
    const filters = [
      ...values[FILTER_KEY].get('filters').entries(),
    ].map(([key, value]) => ({ key, value }));

    const { users } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        {/* <ContentForm {...this.props} /> */}

        <FieldRow
          label="Запуск"
          subLabel="Запустить бенефит после сохранения?"
          sudDesc="Без включения этой настройки после нажатия кнопки «Сохранить» бенефит будет перенесена в черновики."
        >
          <FormControlLabel
            label="Запустить бенефит"
            control={
              <Checkbox
                checked={Boolean(values[SHOULD_SUBMIT_AFTER_SAVE_KEY])}
                onChange={this.toggleShouldSubmitAfterSave}
              />
            }
          />
        </FieldRow>
        <FieldRow label="Фильтр">
          <FieldDescription
            title="Фильтр сегмента"
            desc="Вы можете добавить также дополнительные фильтры, которые отделят пользователей с определенными характеристиками. К примеру, пользователей, обладающих Silver статусом, либо мужчин старше 35 лет."
          />
          <Typography variant="subtitle1" gutterBottom>
            Включить в сегмент посетителей, у которых:
          </Typography>

          <FilterBar
            filterTypes={filterTypesShort}
            filters={filters}
            removeFilters={this.removeFilters}
            updateFilters={this.updateFilters}
          />
        </FieldRow>
        <FieldRow label="Количество пользователей">
          <Typography>{users}</Typography>
        </FieldRow>

        <FieldRow>
          <div className={classes.footer}>
            <SubmitButton
              Target={AsyncButton}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
            >
              {values[SHOULD_SUBMIT_AFTER_SAVE_KEY]
                ? 'Запустить бенефит'
                : 'Сохранить черновик'}
            </SubmitButton>
            <Button
              href={createFormStepURL(id, 1)}
              color="primary"
              size="large"
              className={classes.backButton}
            >
              Вернуться
            </Button>
            <RequiredMessages
              fieldNames={fieldNames}
              className={classes.requiredMessages}
            />
          </div>
        </FieldRow>
      </form>
    );
  }
}

export default SecondStep;
