import {
  ACTIVE_KEY,
  BONUS_NAME_KEY,
  END_DATE_KEY,
  START_DATE_KEY,
  STORE_KEY,
} from '@esentai/core/features/storeBonuses/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createMallBonusActivityStateFilter } from '@/filters/MallBonusActivityState';
import { createTextFilter } from '@/filters/SearchInput';
import { createStoreFilter } from '@/filters/Store';

export const filterTypes = [
  createMallBonusActivityStateFilter({
    key: ACTIVE_KEY,
    label: 'Активность',
    quicklyAccessible: true,
  }),
  createStoreFilter({
    key: STORE_KEY,
    label: 'Магазин',
  }),
  createTextFilter({
    key: BONUS_NAME_KEY,
    label: 'Название акции',
  }),
  createDateRangeFilter({
    key: START_DATE_KEY,
    label: 'Дата начала',
  }),
  createDateRangeFilter({
    key: END_DATE_KEY,
    label: 'Дата окончания',
  }),
];
