import PropTypes from 'prop-types';
import React from 'react';

const ThirdFloor = ({ mapHeight, mapWidth, MapTransformer, ...props }) => (
  <MapTransformer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${mapWidth} ${mapHeight}`}
      {...props}
    >
      <g id="ee8052d4-d748-4faf-8472-24407d197857" data-name="Background">
        <rect width="1420.16" height="1001" fill="#ededed" />
        <rect x="879" y="188" width="78" height="432" fill="#f7f7f7" />
        <rect y="661" width="1415" height="24" fill="#f7f7f7" />
        <rect y="698" width="1415" height="24" fill="#f7f7f7" />
        <path
          d="M914.34,337.85v-3.74H915V337h4v-2.08h.69V337h4.14v-2.88h.69v3.74Z"
          fill="#888685"
        />
        <path
          d="M922.59,328.82a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.54,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84V332h.87a1.55,1.55,0,0,0,1-.28,1.11,1.11,0,0,0,.36-.92,1.21,1.21,0,0,0-.32-.94,1.43,1.43,0,0,0-1-.29h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.83,1.83,0,0,1-.51-1.44,1.94,1.94,0,0,1,.51-1.5,2.19,2.19,0,0,1,1.49-.45h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.08,1.08,0,0,0,.3.85,1.17,1.17,0,0,0,.81.27H918a1.61,1.61,0,0,0,.39,0,1.25,1.25,0,0,0,.33-.16,3.8,3.8,0,0,0,.33-.29c.11-.12.24-.28.39-.47l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.93,2.93,0,0,1,.63-.06Z"
          fill="#888685"
        />
        <path
          d="M921.57,323.25h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.55,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.41,2.41,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.34,1.28,1.28,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.32,1.16,1.16,0,0,0-.39,1,1.19,1.19,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M918,318.54a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.28,1.28,0,0,0,.35.91,1.35,1.35,0,0,0,1,.37h6.54v.82H916V321h.84v0a1.84,1.84,0,0,1-.66-.55,1.66,1.66,0,0,1-.27-1,1.61,1.61,0,0,1,.48-1.26,2.24,2.24,0,0,1,1.53-.43h6.59v.82Z"
          fill="#888685"
        />
        <path
          d="M916,316.53v-.66h-1.66v-.82H916v-1.19h.63v1.19h6.49a.71.71,0,0,0,.54-.16.75.75,0,0,0,.16-.56v-.47h.69v.56a1.59,1.59,0,0,1-.32,1.13,1.5,1.5,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M924.51,309.36h-.77v0a1.74,1.74,0,0,1,.6.53,1.59,1.59,0,0,1,.26.94,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.23.94.94,0,0,0-.35.85,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.8h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.31a1.23,1.23,0,0,0-1.35-1.31h-3a.57.57,0,0,1,.14.13l.17.23q.09.13.21.36c.08.15.18.34.29.57a5.89,5.89,0,0,0,.28.52,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.86,1.86,0,0,0,.56.07h.65a1.58,1.58,0,0,0,1-.27A1,1,0,0,0,923.91,310.67Z"
          fill="#888685"
        />
        <path
          d="M914.34,307.11v-.82h.75v.82Zm1.66,0v-.82h8.51v.82Z"
          fill="#888685"
        />
        <path
          d="M924.6,300.38a1.92,1.92,0,0,1-2.07,2.17H921v-.82h1.6a1.44,1.44,0,0,0,1-.33,1.35,1.35,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.54,1.54,0,0,0-1-.31H922a2.89,2.89,0,0,0-.53.05,1.77,1.77,0,0,0-.45.18,2.08,2.08,0,0,0-.44.31c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.44-.63.61a3.55,3.55,0,0,1-.57.43,2.27,2.27,0,0,1-.6.26,3.08,3.08,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.17,2.17,0,0,1,.5-1.58,2,2,0,0,1,1.49-.51h1.11v.81h-1.14a1.29,1.29,0,0,0-1,.32,1.35,1.35,0,0,0-.3,1,1.29,1.29,0,0,0,.31.91,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a3.52,3.52,0,0,1,.58-.42,2.78,2.78,0,0,1,.62-.25,3.11,3.11,0,0,1,.72-.08h.56a2.45,2.45,0,0,1,.83.13,1.57,1.57,0,0,1,.66.39,1.9,1.9,0,0,1,.44.68A2.53,2.53,0,0,1,924.6,300.38Z"
          fill="#888685"
        />
        <path
          d="M925.86,292.7v.82h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.48h-4.63a2,2,0,0,1-1.54-.52,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H916v-.82ZM922.54,296a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H918a1.43,1.43,0,0,0-1,.4,1.26,1.26,0,0,0-.36.91c0,.81.46,1.21,1.37,1.21Z"
          fill="#888685"
        />
        <path
          d="M922.56,290.43q1.35,0,1.35-1.17a1.29,1.29,0,0,0-.32-.88,1.33,1.33,0,0,0-1-.39H916v-.83h8.51V288h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.44H916v-.83Z"
          fill="#888685"
        />
        <path
          d="M924.51,282.53h-.77v0a1.71,1.71,0,0,1,.6.52,1.62,1.62,0,0,1,.26.95,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.46-.36-.73a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.92,1.92,0,0,1-.54-1.51,1.75,1.75,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57a5.58,5.58,0,0,0,.28.51,1.61,1.61,0,0,0,.3.37,1.27,1.27,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,923.91,283.83Z"
          fill="#888685"
        />
        <path
          d="M916.86,278v.14a1.37,1.37,0,0,0,.34,1,1.31,1.31,0,0,0,1,.37h6.33v.83H916v-.83h1v0a2.33,2.33,0,0,1-.39-.19,1.64,1.64,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.4,1.4,0,0,1-.1-.56Z"
          fill="#888685"
        />
        <path
          d="M921.57,272.51h.84A2.36,2.36,0,0,1,924,273a2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M914.34,583.56v-3.73H915v2.88h4v-2.09h.69v2.09h4.14v-2.88h.69v3.73Z"
          fill="#888685"
        />
        <path
          d="M922.59,574.53a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.55,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84v-.78h.87a1.56,1.56,0,0,0,1-.29,1.09,1.09,0,0,0,.36-.91,1.24,1.24,0,0,0-.32-.95,1.43,1.43,0,0,0-1-.28h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.85,1.85,0,0,1-.51-1.45,1.92,1.92,0,0,1,.51-1.49,2.19,2.19,0,0,1,1.49-.46h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.11,1.11,0,0,0,.3.86,1.17,1.17,0,0,0,.81.27H918a1.6,1.6,0,0,0,.39-.05,1.21,1.21,0,0,0,.33-.15,4.09,4.09,0,0,0,.33-.3c.11-.12.24-.27.39-.46l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.88,2.88,0,0,1,.63-.07Z"
          fill="#888685"
        />
        <path
          d="M921.57,569h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M918,564.26a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.29,1.29,0,0,0,.35.91,1.35,1.35,0,0,0,1,.36h6.54v.83H916v-.83h.84a1.87,1.87,0,0,1-.66-.56,1.93,1.93,0,0,1,.21-2.26,2.24,2.24,0,0,1,1.53-.44h6.59v.83Z"
          fill="#888685"
        />
        <path
          d="M916,562.25v-.66h-1.66v-.83H916v-1.18h.63v1.18h6.49a.76.76,0,0,0,.54-.15.79.79,0,0,0,.16-.57v-.46h.69v.55a1.63,1.63,0,0,1-.32,1.14,1.54,1.54,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M924.51,555.08h-.77v0a1.71,1.71,0,0,1,.6.52,1.64,1.64,0,0,1,.26,1,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.13,2.13,0,0,1-.41-.56c-.12-.23-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.9,1.9,0,0,1-.54-1.51,1.76,1.76,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57s.19.37.28.52a1.79,1.79,0,0,0,.3.36,1.09,1.09,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,923.91,556.38Z"
          fill="#888685"
        />
        <path
          d="M914.34,552.83V552h.75v.83Zm1.66,0V552h8.51v.83Z"
          fill="#888685"
        />
        <path
          d="M924.6,546.09a1.93,1.93,0,0,1-2.07,2.18H921v-.83h1.6a1.44,1.44,0,0,0,1-.33,1.33,1.33,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.48,1.48,0,0,0-1-.31H922a2.89,2.89,0,0,0-.53.05,1.74,1.74,0,0,0-.45.17,2.14,2.14,0,0,0-.44.32c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.45-.63.62a4.26,4.26,0,0,1-.57.43,2.24,2.24,0,0,1-.6.25,2.62,2.62,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.19,2.19,0,0,1,.5-1.58,2,2,0,0,1,1.49-.5h1.11v.81h-1.14a1.28,1.28,0,0,0-1,.31,1.35,1.35,0,0,0-.3,1,1.3,1.3,0,0,0,.31.92,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a4.22,4.22,0,0,1,.58-.42,2.34,2.34,0,0,1,.62-.24,2.63,2.63,0,0,1,.72-.09h.56a2.72,2.72,0,0,1,.83.13,1.7,1.7,0,0,1,.66.4,1.86,1.86,0,0,1,.44.67A2.53,2.53,0,0,1,924.6,546.09Z"
          fill="#888685"
        />
        <path
          d="M925.86,538.41v.83h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.47h-4.63a2.06,2.06,0,0,1-1.54-.51,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H916v-.83Zm-3.32,3.35a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H918a1.43,1.43,0,0,0-1,.4,1.22,1.22,0,0,0-.36.9c0,.81.46,1.22,1.37,1.22Z"
          fill="#888685"
        />
        <path
          d="M922.56,536.15q1.35,0,1.35-1.17a1.31,1.31,0,0,0-.32-.89,1.28,1.28,0,0,0-1-.38H916v-.83h8.51v.83h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.43H916v-.82Z"
          fill="#888685"
        />
        <path
          d="M924.51,528.25h-.77v0a1.74,1.74,0,0,1,.6.53,1.6,1.6,0,0,1,.26,1,1.7,1.7,0,0,1-.48,1.31,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.39,5.39,0,0,0-.63-1.17.71.71,0,0,0-.55-.29H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.11,1.11,0,0,0,.33.89,1.4,1.4,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.33,1.33,0,0,0-.34-.94,1.31,1.31,0,0,0-1-.36h-3a.39.39,0,0,1,.14.12l.17.23q.09.14.21.36c.08.15.18.34.29.58a5.58,5.58,0,0,0,.28.51,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.28A1,1,0,0,0,923.91,529.55Z"
          fill="#888685"
        />
        <path
          d="M916.86,523.67v.13a1.37,1.37,0,0,0,.34,1,1.28,1.28,0,0,0,1,.38h6.33V526H916v-.83h1v0a2.4,2.4,0,0,1-.39-.2,1.4,1.4,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.35,1.35,0,0,1-.1-.55Z"
          fill="#888685"
        />
        <path
          d="M921.57,518.22h.84a2.36,2.36,0,0,1,1.63.52,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.37,2.37,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.4h2.17a1.61,1.61,0,0,0,1.1-.35,1.27,1.27,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.4V519h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.22,1.22,0,0,0,.39,1,1.59,1.59,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <polygon
          points="1043.9 393.5 1014.2 383.5 984.5 393.5 984.5 261.5 1014.2 271.5 1043.9 261.5 1043.9 393.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1249.9 393.5 1220.2 383.5 1190.5 393.5 1190.5 261.5 1220.2 271.5 1249.9 261.5 1249.9 393.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1045.2 227.2 1055.2 197.5 1045.2 167.8 1177.2 167.8 1167.2 197.5 1177.2 227.2 1045.2 227.2"
          fill="#e1e0e0"
        />
        <polygon
          points="1175.09 531.52 1082.12 531.52 1082.12 455.5 1008.1 455.5 1008.1 474.19 986.91 474.19 986.91 620 1008.1 620 1082.12 620 1082.12 596.32 1175.09 596.32 1175.09 531.52"
          fill="#e1e0e0"
        />
        <path
          d="M1141,61.71V0h-26V56.61l-408.91,54.2a62,62,0,0,0-118,12.73L207,176.69V673h26V199.31l355.77-49.62A62,62,0,0,0,711,136.4l413.29-54.79L1292,214.29v261L1172,666.08l22,13.84,124-197.17v-281ZM649,171a36,36,0,1,1,36-36A36,36,0,0,1,649,171Z"
          fill="#f7f7f7"
        />
        <path
          d="M1005.48,552.33h3.74V553h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1014.51,560.58a2.19,2.19,0,0,1-.47,1.48,2,2,0,0,1-1.55.53A1.87,1.87,0,0,1,1011,562a2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.5,1.5,0,0,0,.29,1,1.07,1.07,0,0,0,.91.36,1.2,1.2,0,0,0,.94-.32,1.43,1.43,0,0,0,.29-1v-.26a1.5,1.5,0,0,0-.21-.81,3.78,3.78,0,0,0-.81-.81l-.87-.7a4.25,4.25,0,0,1-1-1,1.83,1.83,0,0,1-.31-1.1v-.24a1.86,1.86,0,0,1,.46-1.32,1.84,1.84,0,0,1,1.44-.51,1.93,1.93,0,0,1,1.5.51,2.15,2.15,0,0,1,.45,1.48v.62h-.77v-.65a1.51,1.51,0,0,0-.27-1,1.14,1.14,0,0,0-.91-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81V556a1.58,1.58,0,0,0,0,.38,1,1,0,0,0,.15.33,3.8,3.8,0,0,0,.29.33,5.71,5.71,0,0,0,.47.39l.87.7c.25.2.46.38.64.55a2.91,2.91,0,0,1,.41.5,2,2,0,0,1,.22.53,3,3,0,0,1,.06.63Z"
          fill="#888685"
        />
        <path
          d="M1020.08,559.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53A1.67,1.67,0,0,0,1019,555a1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1024.79,556a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V554h.82v.83h0a1.94,1.94,0,0,1,.56-.66,1.7,1.7,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.44,1.54v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1026.8,554h.66v-1.67h.82V554h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.61,1.61,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1034,562.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V556a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1032.66,561.9Z"
          fill="#888685"
        />
        <path
          d="M1036.22,552.33h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1040.64,552.33h4.19V553h-1.67v9.48h-.85V553h-1.67Z"
          fill="#888685"
        />
        <path
          d="M1050.26,560.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.58.61,2,2,0,0,1-1.57-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.57-.6,2,2,0,0,1,1.58.6,2.46,2.46,0,0,1,.54,1.68Zm-2.12,1.59a1.22,1.22,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.22,1.22,0,0,0-1-.39,1.19,1.19,0,0,0-1,.39,1.58,1.58,0,0,0-.34,1.09v4.34a1.58,1.58,0,0,0,.34,1.09A1.19,1.19,0,0,0,1048.14,561.9Z"
          fill="#888685"
        />
        <path
          d="M1058.12,554l-1.37,8.5h-.81l-1.18-6.52-1.22,6.52h-.81l-1.38-8.5h.83l1,6.58h0l1.23-6.58h.7l1.22,6.58h0l1-6.58Z"
          fill="#888685"
        />
        <path
          d="M1063.43,559.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1060v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.16,1.16,0,0,0-1-.38,1.18,1.18,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1067.19,554.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V554h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1073.33,552l-2.18,11.82h-.72l2.18-11.82Z" fill="#888685" />
        <path
          d="M1005.25,568.33h4.18V569h-1.67v9.48h-.85V569h-1.66Z"
          fill="#888685"
        />
        <path
          d="M1013.93,572a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V568.33h.82v2.5h0a2,2,0,0,1,.55-.66,1.73,1.73,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.43,1.54v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1020.37,575.56v.84a2.36,2.36,0,0,1-.52,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.54-1.67v-4.14a2.38,2.38,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1017v2.18a1.59,1.59,0,0,0,.35,1.09,1.27,1.27,0,0,0,1,.39c.84,0,1.25-.47,1.25-1.42v-.92Zm-3.4-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.16,1.16,0,0,0-1-.38,1.22,1.22,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1025,573.37v5.13h-.86V568.33h2a2.17,2.17,0,0,1,1.58.5,2,2,0,0,1,.49,1.42v1.5a2,2,0,0,1-.49,1.44,2.14,2.14,0,0,1-1.62.52h0l2.45,4.79h-1Zm0-4.35v4h1.06a1.34,1.34,0,0,0,1-.32,1.22,1.22,0,0,0,.31-.9v-1.59q0-1.2-1.29-1.2Z"
          fill="#888685"
        />
        <path
          d="M1029.66,568.33h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M1031.7,570h.66v-1.67h.83V570h1.18v.62h-1.18v6.49a.71.71,0,0,0,.16.54.75.75,0,0,0,.56.16h.46v.69h-.55a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.33-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1036.43,577.81h2.73v.69h-3.59v-.69l2.79-7.12h-2.59V570h3.45v.69Z"
          fill="#888685"
        />
        <path
          d="M1045.19,578.59a2.47,2.47,0,0,1-1-.2,1.93,1.93,0,0,1-.7-.55,2.41,2.41,0,0,1-.4-.8,3.87,3.87,0,0,1-.13-1v-5.28a4,4,0,0,1,.13-1,2.36,2.36,0,0,1,.4-.81,1.81,1.81,0,0,1,.7-.54,2.47,2.47,0,0,1,1-.2,2.69,2.69,0,0,1,1,.17,1.48,1.48,0,0,1,.66.47,1.87,1.87,0,0,1,.34.74,4.75,4.75,0,0,1,.09,1v1.2h-.84v-1.27a2.25,2.25,0,0,0-.25-1.16,1.13,1.13,0,0,0-1-.42,1.46,1.46,0,0,0-.66.14,1.29,1.29,0,0,0-.44.36,1.63,1.63,0,0,0-.25.55,2.81,2.81,0,0,0-.07.67v5.53a2.87,2.87,0,0,0,.07.67,1.63,1.63,0,0,0,.25.55,1.29,1.29,0,0,0,.44.36,1.34,1.34,0,0,0,.66.14,1.14,1.14,0,0,0,1-.44,2.29,2.29,0,0,0,.26-1.14v-1.81h.82v1.74a4.42,4.42,0,0,1-.09.92,1.86,1.86,0,0,1-.35.75,1.68,1.68,0,0,1-.66.49A2.59,2.59,0,0,1,1045.19,578.59Z"
          fill="#888685"
        />
        <path
          d="M1051.91,578.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V572a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1050.6,577.9Z"
          fill="#888685"
        />
        <path
          d="M1056.48,570.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V570h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1057.76,568.33h.82V578.5h-.82Z" fill="#888685" />
        <path
          d="M1059.8,570h.66v-1.67h.82V570h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.61,1.61,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1068,576.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.57.61,2,2,0,0,1-1.58-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.57.6,2.46,2.46,0,0,1,.54,1.68Zm-2.11,1.59a1.19,1.19,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.19,1.19,0,0,0-1-.39,1.22,1.22,0,0,0-1,.39,1.63,1.63,0,0,0-.34,1.09v4.34a1.63,1.63,0,0,0,.34,1.09A1.22,1.22,0,0,0,1065.89,577.9Z"
          fill="#888685"
        />
        <path
          d="M1072.71,572a1.63,1.63,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.33,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83V570h.83v.83h0a2.06,2.06,0,0,1,.56-.66,1.7,1.7,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.44,1.54v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1077.06,578.5l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M1083.35,568.33h.82V578.5h-.82Z" fill="#888685" />
        <path
          d="M1088.7,571.88a1.64,1.64,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.09,1.09,0,0,0-.85.35,1.41,1.41,0,0,0-.32,1v6.57h-.83V570h.83v.82h0a1.78,1.78,0,0,1,.52-.65,1.59,1.59,0,0,1,.94-.26,1.4,1.4,0,0,1,1.45,1h0a1.53,1.53,0,0,1,.57-.72,1.63,1.63,0,0,1,1-.28c1.07,0,1.61.65,1.61,2v6.64h-.83v-6.62a1.64,1.64,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.13,1.13,0,0,0-.81.32,1.33,1.33,0,0,0-.36.89v6.69h-.83Z"
          fill="#888685"
        />
        <path
          d="M1097.19,578.5v-.76h0a1.68,1.68,0,0,1-.52.59,1.62,1.62,0,0,1-1,.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.52,1.52,0,0,1,.37-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.46-.24.73-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V572a1.85,1.85,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.1,1.1,0,0,0-.89.32,1.42,1.42,0,0,0-.28.94v.82h-.79v-.76a2.13,2.13,0,0,1,.48-1.48,1.91,1.91,0,0,1,1.51-.53,1.75,1.75,0,0,1,1.46.53A2.48,2.48,0,0,1,1098,572v6.5Zm-1.3-.6a1.33,1.33,0,0,0,.94-.34,1.31,1.31,0,0,0,.36-1v-3a.53.53,0,0,1-.12.14l-.23.17-.37.22-.57.28a5.45,5.45,0,0,0-.51.29,1.2,1.2,0,0,0-.36.3,1.07,1.07,0,0,0-.22.39,1.91,1.91,0,0,0-.08.57v.64a1.58,1.58,0,0,0,.27,1A1.06,1.06,0,0,0,1095.89,577.9Z"
          fill="#888685"
        />
        <path
          d="M1099.19,570h.66v-1.67h.82V570h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.59,1.59,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1107.26,570l-2,8.61a2.63,2.63,0,0,1-.19.58,1,1,0,0,1-.73.6,2.93,2.93,0,0,1-.63.06h-.42v-.69h.41a.75.75,0,0,0,.56-.16,1.4,1.4,0,0,0,.28-.59l.19-.74h-.19L1102.8,570h.84l1.38,6.33h0l1.41-6.33Z"
          fill="#888685"
        />
        <path
          d="M1082.48,306.14h3.74v.69h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1091.51,314.39a2.17,2.17,0,0,1-.47,1.48,2,2,0,0,1-1.55.53,1.87,1.87,0,0,1-1.49-.56,2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.5,1.5,0,0,0,.29,1,1.07,1.07,0,0,0,.91.36,1.16,1.16,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-.95-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.84,1.84,0,0,1,1.44-.51,1.93,1.93,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.77v-.65a1.51,1.51,0,0,0-.27-1,1.14,1.14,0,0,0-.91-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.46,1.46,0,0,0,.05.38.89.89,0,0,0,.15.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M1097.08,313.37v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67V310a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1101.79,309.76a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.77,1.77,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1103.8,307.81h.66v-1.67h.82v1.67h1.19v.63h-1.19v6.48a.8.8,0,0,0,.16.55.82.82,0,0,0,.56.15h.47v.69h-.56a1.56,1.56,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1111,316.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1109.66,315.71Z"
          fill="#888685"
        />
        <path
          d="M1113.22,306.14h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1117.6,316.31l2-10.17h1.05l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path
          d="M1123.88,307.81h.83v.85h0a2.15,2.15,0,0,1,.57-.65,1.65,1.65,0,0,1,1-.29,1.62,1.62,0,0,1,1.28.49,2.19,2.19,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.57,1.57,0,0,1-1-.28,1.78,1.78,0,0,1-.52-.62h0v2.16h-.83Zm3.35,2a1.57,1.57,0,0,0-.29-1,1.17,1.17,0,0,0-.93-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1132.66,316.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1131.35,315.71Z"
          fill="#888685"
        />
        <path
          d="M1137.23,308.66h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M1138.25,307.81h.66v-1.67h.83v1.67h1.18v.63h-1.18v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1145.32,309.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.09,1.09,0,0,0-.84.35,1.38,1.38,0,0,0-.33,1v6.57h-.82v-8.5h.82v.82h0a1.59,1.59,0,0,1,.52-.64,1.47,1.47,0,0,1,.93-.27,1.41,1.41,0,0,1,1.46,1h0a1.58,1.58,0,0,1,.56-.72,1.69,1.69,0,0,1,1-.28c1.07,0,1.6.65,1.6,1.95v6.64h-.82V309.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.1,1.1,0,0,0-.81.33,1.29,1.29,0,0,0-.36.88v6.69h-.82Z"
          fill="#888685"
        />
        <path
          d="M1154.81,313.37v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.55-1.67V310a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2H1154v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1159.52,309.76a1.58,1.58,0,0,0-.27-1,1.1,1.1,0,0,0-.9-.34,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1161.53,307.81h.66v-1.67h.83v1.67h1.18v.63H1163v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1169.45,314.39a2.13,2.13,0,0,1-.48,1.48,2,2,0,0,1-1.54.53,1.87,1.87,0,0,1-1.49-.56,2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.18,1.18,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-1-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.78v-.65a1.45,1.45,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.48,1.48,0,0,0,0,.38,1.12,1.12,0,0,0,.16.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M220.42,679.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18H224l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M226.71,669.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M228.85,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M232.18,669.64h3.63v.69H233v4h2.08V675H233v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M240.21,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.16,1.16,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.57.57,0,0,1-.13.14,1.72,1.72,0,0,1-.23.17,2.59,2.59,0,0,1-.36.22l-.57.28a5.74,5.74,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,238.9,679.21Z"
          fill="#888685"
        />
        <path
          d="M244.78,672.16h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M249.22,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,247.92,679.21Z"
          fill="#888685"
        />
        <path
          d="M251.47,669.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.7,1.7,0,0,1,1-.29,1.63,1.63,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.52,1.52,0,0,1-1-.28,1.93,1.93,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M257.08,669.64h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M264.91,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,263.61,679.21Z"
          fill="#888685"
        />
        <path
          d="M271.24,671.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.39-6.93Z"
          fill="#888685"
        />
        <path
          d="M276.49,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11H273.1v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M281.2,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.36,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M284.26,677.86q0,1.35,1.17,1.35a1.27,1.27,0,0,0,.89-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.82v8.5h-.82V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.43-1.53v-6.58h.82Z"
          fill="#888685"
        />
        <path
          d="M293.17,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M697.81,679.81l2-10.17h1l2,10.17H702l-.48-2.49H699.1l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M704.1,669.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M706.24,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M709.57,669.64h3.63v.69h-2.77v4h2.08V675h-2.08v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M717.6,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.14,1.14,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.57.57,0,0,1-.13.14,1.72,1.72,0,0,1-.23.17,2.59,2.59,0,0,1-.36.22l-.57.28a4.63,4.63,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,716.29,679.21Z"
          fill="#888685"
        />
        <path
          d="M722.17,672.16H722a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M726.61,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.57,1.57,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.38,2.38,0,0,1,.56-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.15,2.15,0,0,1,.49-1.48,1.94,1.94,0,0,1,1.51-.53,1.79,1.79,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1,1,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,725.31,679.21Z"
          fill="#888685"
        />
        <path
          d="M728.86,669.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.68,1.68,0,0,1,1-.29,1.64,1.64,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.52,1.52,0,0,1-1-.28,1.93,1.93,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.21,1.21,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.31,1.31,0,0,0,.37,1,1.3,1.3,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M734.47,669.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M742.3,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.78,1.78,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,741,679.21Z"
          fill="#888685"
        />
        <path
          d="M748.63,671.31l-1.77,8.5h-.93l-1.77-8.5H745l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M753.88,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M758.59,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M761.65,677.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M770.56,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1175.2,679.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path d="M1181.48,669.64h.83v10.17h-.83Z" fill="#888685" />
        <path d="M1183.63,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M1187,669.64h3.63v.69h-2.78v4h2.09V675h-2.09v4.83H1187Z"
          fill="#888685"
        />
        <path
          d="M1195,679.81v-.76h0a1.73,1.73,0,0,1-.53.59,1.53,1.53,0,0,1-1,.26,1.68,1.68,0,0,1-1.31-.48,2.19,2.19,0,0,1-.46-1.53v-.69a2.49,2.49,0,0,1,.13-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,.94.94,0,0,0-.86-.34,1.14,1.14,0,0,0-.89.32,1.44,1.44,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.37,1.37,0,0,0,.94-.33,1.35,1.35,0,0,0,.36-1v-3a.39.39,0,0,1-.12.14,1.72,1.72,0,0,1-.23.17,2.25,2.25,0,0,1-.37.22c-.14.08-.34.17-.57.28a5.45,5.45,0,0,0-.51.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.79,1.79,0,0,0-.08.56v.64a1.7,1.7,0,0,0,.27,1A1.09,1.09,0,0,0,1193.68,679.21Z"
          fill="#888685"
        />
        <path
          d="M1199.56,672.16h-.14a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.37,1v6.33h-.83v-8.5h.83v1.05h0a2.4,2.4,0,0,1,.2-.39,1.21,1.21,0,0,1,.29-.35,1.64,1.64,0,0,1,.42-.26,1.43,1.43,0,0,1,.56-.1Z"
          fill="#888685"
        />
        <path
          d="M1204,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1202.69,679.21Z"
          fill="#888685"
        />
        <path
          d="M1206.25,669.64h.82v2.52h0a2.26,2.26,0,0,1,.56-.65,1.67,1.67,0,0,1,1-.29,1.65,1.65,0,0,1,1.29.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.82,1.82,0,0,1-1.34.48,1.52,1.52,0,0,1-1-.28,1.9,1.9,0,0,1-.52-.62h0v.81h-.82Zm3.34,3.63a1.51,1.51,0,0,0-.29-1,1.13,1.13,0,0,0-.92-.34,1.25,1.25,0,0,0-.92.36,1.45,1.45,0,0,0-.39,1v4.62a1.36,1.36,0,0,0,.38,1,1.29,1.29,0,0,0,.91.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1211.86,669.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1219.69,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1218.38,679.21Z"
          fill="#888685"
        />
        <path
          d="M1226,671.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M1231.27,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1236,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58H1236Z"
          fill="#888685"
        />
        <path
          d="M1239,677.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M1248,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <rect x="300.43" y="629.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="314.27"
          y="634.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="314.27"
          y1="637.1"
          x2="326.58"
          y2="637.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="314.27"
          y1="642.96"
          x2="326.58"
          y2="642.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="320.43"
          y1="637.1"
          x2="320.43"
          y2="642.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="316"
          cy="644.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="324.61"
          cy="644.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="315.38"
          y="646.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="323.99"
          y="646.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect x="145.43" y="737.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="159.27"
          y="742.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="159.27"
          y1="745.1"
          x2="171.58"
          y2="745.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="159.27"
          y1="750.96"
          x2="171.58"
          y2="750.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="165.43"
          y1="745.1"
          x2="165.43"
          y2="750.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="161"
          cy="752.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="169.61"
          cy="752.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="160.38"
          y="754.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="168.99"
          y="754.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="607.5"
          y1="609.5"
          x2="707.5"
          y2="609.5"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f781bdd5-f050-4d2b-a93c-57f9d9906cca" data-name="OUTLINE">
        <polygon
          points="394 514.5 394 609.5 431.5 609.5 431.5 515 394 514.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="835 192 835 617 386.5 617 386.5 252.5 538.5 252.5 835 192"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="707.5 563 707.5 609.5 827.5 609.5 827.5 199.5 722.5 222.5 722.5 563 707.5 563"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="540.5 271.5 540.5 260 722.5 222.5 722.5 271.5 540.5 271.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect
          x="513"
          y="260"
          width="27.5"
          height="47"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M758.73,390.05h3.95v.73h-3V395h2.2v.73h-2.2v4.38h3v.73h-3.95Z"
          fill="#bcbcbc"
        />
        <path
          d="M768.28,398.78a2.27,2.27,0,0,1-.5,1.56,2.1,2.1,0,0,1-1.64.57,2,2,0,0,1-1.58-.6,2.35,2.35,0,0,1-.51-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.3,1.05,1.19,1.19,0,0,0,1,.38,1.3,1.3,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.58,1.58,0,0,0-.22-.85,4,4,0,0,0-.86-.86l-.92-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.83v-.68a1.55,1.55,0,0,0-.28-1,1.2,1.2,0,0,0-1-.33,1.17,1.17,0,0,0-.91.32,1.23,1.23,0,0,0-.28.85v.26a1.64,1.64,0,0,0,0,.4,1.14,1.14,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,1.62,1.62,0,0,1,.23.56,2.7,2.7,0,0,1,.07.67Z"
          fill="#bcbcbc"
        />
        <path
          d="M774.17,397.7v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.1,2.1,0,0,1,1.66-.64,2.15,2.15,0,0,1,1.66.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.59v2.3a1.73,1.73,0,0,0,.37,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.59-2.08h2.73V394a1.75,1.75,0,0,0-.33-1.15,1.21,1.21,0,0,0-1-.41,1.26,1.26,0,0,0-1,.41,1.65,1.65,0,0,0-.36,1.15Z"
          fill="#bcbcbc"
        />
        <path
          d="M779.15,393.88a1.63,1.63,0,0,0-.29-1.07,1.15,1.15,0,0,0-.94-.36,1.3,1.3,0,0,0-1,.37,1.39,1.39,0,0,0-.39,1.07v6.92h-.88v-9h.88v.88h0a2.05,2.05,0,0,1,.59-.69,2,2,0,0,1,2.4.22,2.41,2.41,0,0,1,.46,1.62v7h-.88Z"
          fill="#bcbcbc"
        />
        <path
          d="M781.28,391.82h.7v-1.77h.87v1.77h1.25v.66h-1.25v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.49v.73h-.58a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#bcbcbc"
        />
        <path
          d="M788.86,400.81V400h0a1.72,1.72,0,0,1-.55.63,1.64,1.64,0,0,1-1,.28,1.82,1.82,0,0,1-1.4-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5.54,5.54,0,0,0,1.24-.67.76.76,0,0,0,.31-.58v-.62a2,2,0,0,0-.23-1.06,1,1,0,0,0-.91-.37,1.16,1.16,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.88h-.84v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.3,1.3,0,0,0,1.38-1.43v-3.17a.58.58,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.14-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.08,2.08,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,787.48,400.18Z"
          fill="#bcbcbc"
        />
        <path
          d="M791.24,390.05h.88v.8h-.88Zm0,1.77h.88v9h-.88Z"
          fill="#bcbcbc"
        />
        <path
          d="M761.16,415.91a2.53,2.53,0,0,1-1.11-.22,1.9,1.9,0,0,1-.75-.58,2.25,2.25,0,0,1-.44-.84,3.53,3.53,0,0,1-.14-1v-5.59a3.6,3.6,0,0,1,.14-1.05,2.3,2.3,0,0,1,.44-.85,1.79,1.79,0,0,1,.75-.57,2.68,2.68,0,0,1,1.11-.21,2.73,2.73,0,0,1,1.12.21,1.89,1.89,0,0,1,.76.57,2.45,2.45,0,0,1,.44.85,4,4,0,0,1,.14,1.05v5.59a3.9,3.9,0,0,1-.14,1,2.41,2.41,0,0,1-.44.84,2,2,0,0,1-.76.58A2.57,2.57,0,0,1,761.16,415.91Zm0-.73a1.58,1.58,0,0,0,.71-.15,1.29,1.29,0,0,0,.48-.39,1.49,1.49,0,0,0,.28-.58,2.64,2.64,0,0,0,.08-.7V407.5a2.59,2.59,0,0,0-.08-.7,1.65,1.65,0,0,0-.28-.58,1.53,1.53,0,0,0-.48-.39,1.73,1.73,0,0,0-.71-.14,1.62,1.62,0,0,0-.7.14,1.38,1.38,0,0,0-.47.39,1.65,1.65,0,0,0-.28.58,2.58,2.58,0,0,0-.09.7v5.86a2.63,2.63,0,0,0,.09.7,1.49,1.49,0,0,0,.28.58,1.19,1.19,0,0,0,.47.39A1.48,1.48,0,0,0,761.16,415.18Z"
          fill="#bcbcbc"
        />
        <path
          d="M764.9,406.82h.69v-.5a1.27,1.27,0,0,1,.31-1,1.51,1.51,0,0,1,1.05-.31h.65v.66h-.54a.62.62,0,0,0-.47.15.71.71,0,0,0-.13.48v.48h1.82v-.5a1.28,1.28,0,0,1,.32-1,1.5,1.5,0,0,1,1-.31h.59v.66h-.48a.62.62,0,0,0-.47.15.71.71,0,0,0-.13.48v.48h1.08v.66h-1.08v8.33h-.88v-8.33h-1.82v8.33h-.87v-8.33h-.69Z"
          fill="#bcbcbc"
        />
        <path
          d="M771.46,405.05h.87v.8h-.87Zm0,1.77h.87v9h-.87Z"
          fill="#bcbcbc"
        />
        <path
          d="M778.23,412.7v.89a2.56,2.56,0,0,1-.51,1.72,2,2,0,0,1-1.61.6,2.16,2.16,0,0,1-1.69-.65,2.59,2.59,0,0,1-.57-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.13,2.13,0,0,1,1.67-.64,2.07,2.07,0,0,1,1.62.58,2.46,2.46,0,0,1,.52,1.71v.65h-.85v-.72a1.78,1.78,0,0,0-.31-1.12,1.2,1.2,0,0,0-1-.37,1.26,1.26,0,0,0-1,.41,1.65,1.65,0,0,0-.36,1.15v4.6a1.73,1.73,0,0,0,.36,1.16,1.31,1.31,0,0,0,1,.41,1.19,1.19,0,0,0,1-.38,1.75,1.75,0,0,0,.3-1.13v-1Z"
          fill="#bcbcbc"
        />
        <path
          d="M784.07,412.7v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.52,2.52,0,0,1,3.32,0,2.51,2.51,0,0,1,.55,1.74v2.24h-3.58v2.3a1.73,1.73,0,0,0,.36,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.58-2.08h2.72V409a1.75,1.75,0,0,0-.33-1.15,1.2,1.2,0,0,0-1-.41,1.27,1.27,0,0,0-1,.41,1.65,1.65,0,0,0-.35,1.15Z"
          fill="#bcbcbc"
        />
        <path
          d="M789.74,413.78a2.22,2.22,0,0,1-.51,1.56,2.09,2.09,0,0,1-1.64.57,2,2,0,0,1-1.57-.6,2.31,2.31,0,0,1-.52-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.31,1.05,1.16,1.16,0,0,0,1,.38,1.3,1.3,0,0,0,1-.34,1.51,1.51,0,0,0,.31-1v-.29a1.59,1.59,0,0,0-.23-.85,4.15,4.15,0,0,0-.85-.86l-.92-.73a4.57,4.57,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.82v-.68a1.55,1.55,0,0,0-.29-1,1.2,1.2,0,0,0-.95-.33,1.18,1.18,0,0,0-.91.32,1.23,1.23,0,0,0-.28.85v.26a1.25,1.25,0,0,0,.05.4.93.93,0,0,0,.16.35,3.12,3.12,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,1.86,1.86,0,0,1,.24.56,3.28,3.28,0,0,1,.07.67Z"
          fill="#bcbcbc"
        />
      </g>
      <g id="acbb8bea-2beb-4f99-8921-01706aa211f6" data-name="3S.5">
        <rect
          x="431.5"
          y="515"
          width="31"
          height="28.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="abdf1708-cdf0-4924-a31e-ef0eb952ea1b" data-name="3S.4">
        <rect
          x="462.5"
          y="515"
          width="24"
          height="28.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a9007f2c-72bf-4af3-90aa-7dd77e352294" data-name="3S.3">
        <rect
          x="486"
          y="515"
          width="38"
          height="28.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f0c96584-8fa2-4cfd-9224-6e3bab54ffa5" data-name="3S.2">
        <rect
          x="523.5"
          y="515"
          width="32"
          height="28.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b8d1e7b7-190e-4c8c-b380-0365075115fc" data-name="3S.1">
        <rect
          x="555"
          y="515"
          width="49.5"
          height="28.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a1b8e3f7-8d5c-4b9f-95fc-d9551e1e7943" data-name="3C.1">
        <rect
          x="618.5"
          y="577.5"
          width="89"
          height="32"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a71258e1-4f1e-4841-a23b-06866069af50" data-name="3C.2">
        <polygon
          points="707.5 577.5 618.5 577.5 618.5 543.5 681.5 543.5 681.5 563 707.5 563 707.5 577.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bd33b7bb-71e8-4cee-8bde-a1fd6b7eecd4" data-name="3C.3">
        <polygon
          points="681.5 543.5 604.5 543.5 604.5 515 639.5 515 643.5 508.5 681.5 508.5 681.5 543.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bac1109b-cbc5-4d7e-a9eb-ab0ec05c89a1" data-name="3C.4">
        <polygon
          points="653.5 469.5 681.5 469.5 681.5 508.5 643.5 508.5 653.5 490.5 653.5 469.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e5e957d4-bd14-4959-94b1-805c69d37f17" data-name="3C.5">
        <rect
          x="653.5"
          y="436.5"
          width="28"
          height="33"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b4c563fb-4c94-4a17-a951-3148e757e67c" data-name="3C.6">
        <rect
          x="653.5"
          y="411.5"
          width="28"
          height="25"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ed621311-972e-439d-bb8b-9c88d024d029" data-name="3C.7">
        <polygon
          points="681.5 411.5 653.5 411.5 653.5 381.5 696.5 381.5 696.5 387.5 681.5 406.41 681.5 411.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bae23605-ec4e-444c-b367-cd45595135e8" data-name="3N.1">
        <polygon
          points="696.5 381.5 653.5 381.5 638.5 355.17 631.5 355.17 631.5 328.5 686.5 328.5 686.5 271.5 696.5 271.5 696.5 381.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect
          x="585.5"
          y="328.5"
          width="46"
          height="27"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b9f616f8-7e27-4e30-9c42-b2109add3b52" data-name="3N.2">
        <rect
          x="554.5"
          y="328.5"
          width="31"
          height="27"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ff5f8197-a110-4f17-89d7-6fb413aa1813" data-name="3N.3">
        <rect
          x="513"
          y="328.5"
          width="41.5"
          height="27"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="efa6cdfd-89be-4b83-ab4b-187f0a33ab0a" data-name="3.27b">
        <rect
          x="681.5"
          y="543.5"
          width="41"
          height="19.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="aeb0b847-21e7-4d7c-bb2e-e79c09606749" data-name="3.27a">
        <rect
          x="681.5"
          y="519.1"
          width="41"
          height="24.4"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b126c0ef-a6fe-42a3-921c-096b270bf416" data-name="3.10">
        <rect
          x="681.5"
          y="488.17"
          width="41"
          height="30.93"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bcb8a858-bb52-4771-9b37-2e290a6d72f1" data-name="3.11">
        <rect
          x="681.5"
          y="456.25"
          width="41"
          height="31.93"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bdad972f-f211-4fe0-8882-6833c5edf65d" data-name="3.12">
        <rect
          x="681.5"
          y="425.32"
          width="41"
          height="30.93"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a42a9b11-b27d-433c-ad93-ce2f346e93a2" data-name="3.13">
        <polygon
          points="722.5 387.5 722.5 425.32 681.5 425.32 681.5 406.41 696.5 387.5 722.5 387.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b79d9144-1918-4a9e-b4d1-b449c3f652fc" data-name="3.14">
        <rect
          x="696.5"
          y="358.5"
          width="26"
          height="29"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bff86c51-6327-4ae5-9a2c-25ce27057d61" data-name="3.15">
        <rect
          x="696.5"
          y="328.5"
          width="26"
          height="30"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a9d550db-bb51-4ca2-bce3-28f5980db690" data-name="3.16b">
        <rect
          x="667.5"
          y="271.5"
          width="19"
          height="57"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f9f6c15e-ecd3-4bab-9e6d-1246743ddcce" data-name="3.16a">
        <polygon
          points="637.5 328.5 667.5 328.5 667.5 271.5 620.5 271.5 620.5 295.5 637.5 295.5 637.5 328.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a4d41a8d-bc6d-49cc-ae93-262789f328e2" data-name="3.17">
        <polygon
          points="637.5 328.5 637.5 295.5 620.5 295.5 620.5 271.5 605.5 271.5 605.5 328.5 637.5 328.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ea6a426e-1980-4477-ae9f-a40a21f9a32c" data-name="3.18a">
        <rect
          x="572.5"
          y="271.5"
          width="33"
          height="57"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="feb2cdc3-e2d1-499a-b86c-35d87e54c831" data-name="3.18">
        <rect
          x="540.5"
          y="271.5"
          width="32"
          height="57"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e59e2acf-1f8c-474b-9e83-94a0f9908315" data-name="3.19">
        <rect
          x="513"
          y="307"
          width="27.5"
          height="21.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b63e50d0-eae7-4aa4-ab36-bd13cdbdeda7" data-name="3.20">
        <rect
          x="513"
          y="355.17"
          width="29.5"
          height="34.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b1c22219-9af7-4ef2-9558-be0cbdabc4ac" data-name="3.20a">
        <rect
          x="542.5"
          y="355.17"
          width="24"
          height="34.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ae92c7a7-6395-4a81-a788-5954b42243e1" data-name="3.21b">
        <rect
          x="566.5"
          y="355.17"
          width="23"
          height="34.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f8682a01-a82e-4090-9e68-8804ddee2e3c" data-name="3.21">
        <rect
          x="589.5"
          y="355.17"
          width="16"
          height="34.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="fb5de8a4-e914-401b-bb64-0d440fa6dc4e" data-name="3.23a">
        <rect
          x="605.5"
          y="355.17"
          width="15"
          height="34.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b933b017-f2b2-4198-8128-400c97ef4c38" data-name="3.23">
        <polygon
          points="620.5 355.17 638.5 355.17 653.5 381.5 653.5 390 620.5 390 620.5 355.17"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a316833d-1496-4afa-9406-b22451108332" data-name="3.24">
        <rect
          x="566.5"
          y="390"
          width="87"
          height="30"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b8206b86-283d-4695-9243-8b48cb44b5af" data-name="3.25b">
        <rect
          x="566.5"
          y="451"
          width="87"
          height="31.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e85e2125-de50-47ad-bde5-ccd71b6afd2f" data-name="3.25a">
        <rect
          x="566.5"
          y="420"
          width="87"
          height="31"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a797efb9-dedf-440c-ba9e-929c36cd0807" data-name="3.26">
        <polygon
          points="604.5 515 639.5 515 653.5 490.5 653.5 482.5 566.5 482.5 566.5 499.5 604.5 499.5 604.5 515"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b6ec9e4b-3c39-4f13-b996-0b7de9444a5b" data-name="3.26a">
        <rect
          x="566.5"
          y="499.5"
          width="38"
          height="15.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bc110c72-57c9-42c6-8635-5aa69978d5ef" data-name="3.9">
        <rect
          x="573.5"
          y="543.5"
          width="45"
          height="44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M587.51,570.18h3.24v.73h-4.19v-.73l3.33-9.3h-3v-.72h4v.72Z"
          fill="#1e0f00"
        />
        <path
          d="M594.28,571a1.91,1.91,0,0,1-1.56-.62,2.76,2.76,0,0,1-.5-1.77V567a2,2,0,0,1,.36-1.28,1.62,1.62,0,0,1,.91-.58,1.42,1.42,0,0,1-.78-.58,2,2,0,0,1-.3-1.18v-1.06q0-2.28,2.13-2.28a2.08,2.08,0,0,1,1.61.53,2.6,2.6,0,0,1,.47,1.72v.91h-.88v-.93a1.93,1.93,0,0,0-.26-1.16,1.07,1.07,0,0,0-.91-.34,1.21,1.21,0,0,0-1,.37,1.75,1.75,0,0,0-.32,1.19v1.16a1.38,1.38,0,0,0,.31,1,1.28,1.28,0,0,0,1,.34h2.81v.73h-.62v5.36H596v-.81a1.66,1.66,0,0,1-.56.65A2.11,2.11,0,0,1,594.28,571Zm.21-.73a1.28,1.28,0,0,0,1.11-.44,2.06,2.06,0,0,0,.35-1.14v-3.15h-1.43a1.22,1.22,0,0,0-1.39,1.37v1.71a2.05,2.05,0,0,0,.31,1.23A1.2,1.2,0,0,0,594.49,570.28Z"
          fill="#1e0f00"
        />
        <path
          d="M603.65,560.16l-2.1,10.75h-1l-2.13-10.75h.92l1.73,9.12h0l1.69-9.12Z"
          fill="#1e0f00"
        />
      </g>
      <g id="bc4972a6-6543-4649-b787-ef7871da8ae0" data-name="3.8">
        <rect
          x="542.5"
          y="543.5"
          width="31"
          height="44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e8a03741-c036-4f46-a64f-d1487b93cb74" data-name="3.7">
        <rect
          x="510.5"
          y="543.5"
          width="32"
          height="44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bce2e33a-5bd2-4fee-a0c9-5450f8c0c33d" data-name="3.6">
        <polygon
          points="478.5 543.5 510.5 543.5 510.5 587.5 618.5 587.5 618.5 609.5 478.5 609.5 478.5 543.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M515.76,596h-.06l-1.79,8.95h-.54l-1.8-8.95h0v8.95h-.8V594.16H512l1.65,8.29h0l1.64-8.29h1.38v10.75h-.91Z"
          fill="#1e0f00"
        />
        <path
          d="M522.63,601.8v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.58v2.3a1.71,1.71,0,0,0,.36,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.58-2.08h2.73v-1.61a1.77,1.77,0,0,0-.34-1.15,1.23,1.23,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.72,1.72,0,0,0-.35,1.15Z"
          fill="#1e0f00"
        />
        <path d="M524.16,594.16H525v10.75h-.87Z" fill="#1e0f00" />
        <path
          d="M531,602.6a2.54,2.54,0,0,1-.58,1.76,2.1,2.1,0,0,1-1.66.65,2.12,2.12,0,0,1-1.67-.65,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.15,2.15,0,0,1,1.67-.64,2.12,2.12,0,0,1,1.66.64,2.53,2.53,0,0,1,.58,1.77Zm-2.24,1.68a1.29,1.29,0,0,0,1-.41,1.76,1.76,0,0,0,.35-1.16v-4.59a1.76,1.76,0,0,0-.35-1.16,1.29,1.29,0,0,0-1-.41,1.27,1.27,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.27,1.27,0,0,0,528.79,604.28Z"
          fill="#1e0f00"
        />
        <path
          d="M535.79,597.92a1.63,1.63,0,0,0-.27-1,1,1,0,0,0-.86-.35,1.2,1.2,0,0,0-.9.37,1.53,1.53,0,0,0-.34,1v6.95h-.87v-9h.87v.87h0a1.69,1.69,0,0,1,.54-.68,1.61,1.61,0,0,1,1-.29,1.51,1.51,0,0,1,1.54,1.06h0a1.61,1.61,0,0,1,.59-.76,1.77,1.77,0,0,1,1.06-.3c1.13,0,1.7.69,1.7,2.06v7H539v-7a1.64,1.64,0,0,0-.26-1,1,1,0,0,0-.86-.35,1.18,1.18,0,0,0-.86.35,1.39,1.39,0,0,0-.38.94v7.07h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M544.77,604.91v-.81h0a1.75,1.75,0,0,1-.56.63,1.62,1.62,0,0,1-1,.28,1.84,1.84,0,0,1-1.4-.51,2.36,2.36,0,0,1-.47-1.62v-.73a2.51,2.51,0,0,1,.13-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44l.78-.37a4.78,4.78,0,0,0,1.24-.67.77.77,0,0,0,.32-.58V598a1.91,1.91,0,0,0-.24-1.06,1,1,0,0,0-.91-.37,1.21,1.21,0,0,0-.94.34,1.46,1.46,0,0,0-.29,1v.88h-.84V598a2.31,2.31,0,0,1,.5-1.57,2.1,2.1,0,0,1,1.61-.56,1.88,1.88,0,0,1,1.54.56,2.69,2.69,0,0,1,.44,1.66v6.87Zm-1.38-.63a1.4,1.4,0,0,0,1-.36,1.38,1.38,0,0,0,.39-1.07v-3.17a.64.64,0,0,1-.14.15l-.24.17a3.77,3.77,0,0,1-.38.23c-.16.09-.36.19-.61.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.42,1.42,0,0,0-.23.42,2.15,2.15,0,0,0-.07.6v.68a1.69,1.69,0,0,0,.28,1.07A1.12,1.12,0,0,0,543.39,604.28Z"
          fill="#1e0f00"
        />
        <path
          d="M550.61,598a1.63,1.63,0,0,0-.3-1.07,1.14,1.14,0,0,0-.94-.36,1.33,1.33,0,0,0-1,.37A1.4,1.4,0,0,0,548,598v6.91h-.87v-9H548v.88h0a1.92,1.92,0,0,1,.58-.69,1.77,1.77,0,0,1,1.07-.29,1.7,1.7,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path d="M617.73,461.16h.91v10h2.69v.73h-3.6Z" fill="#1e0f00" />
        <path
          d="M622.62,461.16h.87V462h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M625.89,469.85c0,.95.41,1.43,1.23,1.43a1.33,1.33,0,0,0,.94-.34,1.36,1.36,0,0,0,.41-1v-7h.88v9h-.88V471h0a1.63,1.63,0,0,1-1.65,1,1.69,1.69,0,0,1-1.33-.51,2.36,2.36,0,0,1-.47-1.62v-7h.88Z"
          fill="#1e0f00"
        />
        <path
          d="M637.42,461.16v8.63a2.45,2.45,0,0,1-.49,1.62,2,2,0,0,1-1.6.6,1.86,1.86,0,0,1-1.58-.6,2.47,2.47,0,0,1-.45-1.54v-2.18h.87v2.2a1.72,1.72,0,0,0,.27,1,1,1,0,0,0,.89.38,1.06,1.06,0,0,0,.91-.38,1.69,1.69,0,0,0,.28-1v-8.72Z"
          fill="#1e0f00"
        />
        <path
          d="M643.39,469.6a2.59,2.59,0,0,1-.57,1.76,2.12,2.12,0,0,1-1.67.65,2.1,2.1,0,0,1-1.66-.65,2.54,2.54,0,0,1-.58-1.76v-4.37a2.53,2.53,0,0,1,.58-1.77,2.12,2.12,0,0,1,1.66-.64,2.15,2.15,0,0,1,1.67.64,2.58,2.58,0,0,1,.57,1.77Zm-2.24,1.68a1.28,1.28,0,0,0,1-.41,1.71,1.71,0,0,0,.35-1.16v-4.59a1.71,1.71,0,0,0-.35-1.16,1.28,1.28,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.3,1.3,0,0,0,641.15,471.28Z"
          fill="#1e0f00"
        />
      </g>
      <g id="e335644c-1247-4836-b8de-d09d04c8a519" data-name="3.5">
        <rect
          x="431.5"
          y="543.5"
          width="47"
          height="66"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f567b9d6-38a9-4e1d-9008-3e25eae22f64" data-name="3.1">
        <polygon
          points="394 260 394 515 479 515 479 482 513 482 513 451 566.5 451 566.5 420 566.5 390 513 390 513 260 394 260"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect
          x="355"
          y="421.5"
          width="175"
          height="10"
          transform="translate(16 869) rotate(-90)"
          fill="#ededed"
        />
        <polygon
          points="512 338 437.5 338 437.5 348 512 348 512 338"
          fill="#ededed"
        />
        <path
          d="M439.92,312a2,2,0,0,1-2.3-2.19v-1.67h.87v1.69a1.54,1.54,0,0,0,.35,1.06,1.45,1.45,0,0,0,1.1.38,1.25,1.25,0,0,0,1-.42,1.61,1.61,0,0,0,.32-1v-.57a3.24,3.24,0,0,0-.05-.56,1.66,1.66,0,0,0-.19-.48,2,2,0,0,0-.33-.46c-.14-.16-.31-.33-.51-.52l-1.09-1.08c-.26-.24-.47-.47-.65-.67a3.91,3.91,0,0,1-.45-.6,1.9,1.9,0,0,1-.27-.64,2.83,2.83,0,0,1-.09-.74v-.41a2,2,0,0,1,.57-1.49,2.23,2.23,0,0,1,1.62-.56,2.29,2.29,0,0,1,1.67.53,2.13,2.13,0,0,1,.53,1.58v1.17h-.85v-1.21a1.32,1.32,0,0,0-.34-1,1.42,1.42,0,0,0-1-.32,1.39,1.39,0,0,0-1,.33,1.24,1.24,0,0,0-.36,1v.38a1.62,1.62,0,0,0,.3,1,7.32,7.32,0,0,0,.85.95l1.1,1.08.63.67a3.54,3.54,0,0,1,.45.61,2.8,2.8,0,0,1,.35,1.42v.59a2.8,2.8,0,0,1-.14.88,1.85,1.85,0,0,1-.42.7,2.08,2.08,0,0,1-.71.46A2.66,2.66,0,0,1,439.92,312Z"
          fill="#1e0f00"
        />
        <path
          d="M447,311.91v-.81h0a1.64,1.64,0,0,1-1.55.91,1.82,1.82,0,0,1-1.4-.51,2.31,2.31,0,0,1-.47-1.62v-.73a2.51,2.51,0,0,1,.13-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5,5,0,0,0,1.24-.67.76.76,0,0,0,.31-.58V305a1.92,1.92,0,0,0-.23-1.06,1,1,0,0,0-.91-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.88h-.84V305a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.28,1.28,0,0,0,1.38-1.43v-3.17a.58.58,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.13-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,445.63,311.28Z"
          fill="#1e0f00"
        />
        <path
          d="M452.65,311.91l-2.38-4.58v4.58h-.88V301.16h.88v6l2.33-4.23h.89l-2.35,4.23,2.44,4.76Z"
          fill="#1e0f00"
        />
        <path
          d="M458.88,309.88a2.31,2.31,0,0,1-.5,1.57,2.14,2.14,0,0,1-1.64.56,2,2,0,0,1-1.58-.6,2.34,2.34,0,0,1-.51-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.3,1,1.18,1.18,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.58,1.58,0,0,0-.22-.85,4,4,0,0,0-.86-.86l-.92-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.48-1.4,2.51,2.51,0,0,1,3.11,0,2.24,2.24,0,0,1,.49,1.57v.65H458v-.68a1.57,1.57,0,0,0-.28-1,1.2,1.2,0,0,0-.95-.33,1.17,1.17,0,0,0-.91.32,1.24,1.24,0,0,0-.28.86V305a1.64,1.64,0,0,0,0,.4,1,1,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,2,2,0,0,1,.23.56,2.7,2.7,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
        <path
          d="M462.87,301.16h3.84v.72h-2.94v4.19H466v.73h-2.21v5.11h-.9Z"
          fill="#1e0f00"
        />
        <path d="M468,301.16h.87V302H468Zm0,1.76h.87v9H468Z" fill="#1e0f00" />
        <path
          d="M470.18,302.92h.68v-.5a1.28,1.28,0,0,1,.32-1,1.5,1.5,0,0,1,1.05-.3h.71v.65h-.6a.62.62,0,0,0-.47.15.71.71,0,0,0-.14.48v.48h1.21v.66h-1.21v8.33h-.87v-8.33h-.68Z"
          fill="#1e0f00"
        />
        <path
          d="M474,302.92h.7v-1.76h.87v1.76h1.26v.66h-1.26v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.5v.73h-.59a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84H474Z"
          fill="#1e0f00"
        />
        <path
          d="M481.67,305a1.63,1.63,0,0,0-.3-1.07,1.14,1.14,0,0,0-.94-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.87V301.16h.87v2.64h0a1.92,1.92,0,0,1,.58-.69,1.77,1.77,0,0,1,1.07-.29,1.7,1.7,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M437.43,326.91l2.13-10.75h1.11l2.16,10.75h-.92l-.51-2.63h-2.6l-.5,2.63Zm1.51-3.36h2.31l-1.15-6h0Z"
          fill="#1e0f00"
        />
        <path
          d="M448.39,317.92l-1.87,9h-1l-1.87-9h.88l1.5,7.33h0l1.48-7.33Z"
          fill="#1e0f00"
        />
        <path
          d="M454,323.8v.89a2.46,2.46,0,0,1-.54,1.72,2.1,2.1,0,0,1-1.64.6,2.16,2.16,0,0,1-1.69-.65,2.59,2.59,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.58-1.76,2.09,2.09,0,0,1,1.66-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.56,1.74v2.24h-3.59v2.3a1.67,1.67,0,0,0,.37,1.16,1.34,1.34,0,0,0,1.06.41c.89,0,1.33-.5,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M458.93,320a1.69,1.69,0,0,0-.29-1.07,1.16,1.16,0,0,0-.95-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.87v-9h.87v.88h0a2,2,0,0,1,.59-.69,1.73,1.73,0,0,1,1.06-.29,1.69,1.69,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M462.17,324.85c0,.95.41,1.43,1.23,1.43a1.34,1.34,0,0,0,.94-.34,1.4,1.4,0,0,0,.41-1v-7h.88v9h-.88V326h0a1.63,1.63,0,0,1-1.65,1,1.73,1.73,0,0,1-1.34-.51,2.41,2.41,0,0,1-.46-1.62v-7h.88Z"
          fill="#1e0f00"
        />
        <path
          d="M471.59,323.8v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.24H468v2.3a1.71,1.71,0,0,0,.36,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1ZM468,321.72h2.72v-1.61a1.77,1.77,0,0,0-.33-1.15,1.23,1.23,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.35,1.15Z"
          fill="#1e0f00"
        />
      </g>
      <g id="a40281ba-d1e7-4b35-bd4e-03cccc62d43d" data-name="3.2">
        <rect
          x="479"
          y="482"
          width="15.5"
          height="33"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a03c0658-0443-4247-9aed-c7ad61b59d2e" data-name="3.2a">
        <rect
          x="494.5"
          y="482"
          width="18.5"
          height="33"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="babb39c8-f9c7-4ddd-9dff-1e6dfef50163" data-name="3.3">
        <rect
          x="513"
          y="482"
          width="29.5"
          height="33"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bc357e74-c6f0-4f72-b726-8b2a04c8be05" data-name="3.4">
        <polygon
          points="542.5 515 566.5 515 566.5 451 513 451 513 482 542.5 482 542.5 515"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b4c1beed-b715-43a3-ab10-9e9856a0ac5f" data-name="3.K1">
        <rect
          x="661.5"
          y="479.5"
          width="11"
          height="11"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e30ba18c-9907-4512-af31-39bacbe97188" data-name="WC">
        <rect
          x="696.5"
          y="271.5"
          width="26"
          height="57"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M706.69,298.75l-1.36,8.35h-1l-1.46-10.76h.89l1.13,8.66h0l1.38-8.66h.84L708.5,305h0l1.14-8.62h.83L709,307.1h-1l-1.33-8.35Z"
          fill="#ff4d00"
        />
        <path
          d="M714.17,307.19a2.65,2.65,0,0,1-1.11-.21,2,2,0,0,1-.73-.58,2.42,2.42,0,0,1-.42-.85,3.84,3.84,0,0,1-.14-1v-5.58a4,4,0,0,1,.14-1.05,2.47,2.47,0,0,1,.42-.86,2,2,0,0,1,.73-.57,2.65,2.65,0,0,1,1.11-.21,3,3,0,0,1,1.11.18,1.66,1.66,0,0,1,.7.5,2,2,0,0,1,.35.78,4.62,4.62,0,0,1,.11,1V300h-.89v-1.34a2.39,2.39,0,0,0-.27-1.23,1.18,1.18,0,0,0-1.1-.45,1.51,1.51,0,0,0-.7.15,1.16,1.16,0,0,0-.46.39,1.67,1.67,0,0,0-.27.57,3.17,3.17,0,0,0-.07.71v5.86a3.2,3.2,0,0,0,.07.7,1.82,1.82,0,0,0,.27.58,1.35,1.35,0,0,0,.46.39,1.51,1.51,0,0,0,.7.14,1.18,1.18,0,0,0,1.11-.47,2.37,2.37,0,0,0,.27-1.2v-1.91h.88v1.84a4.23,4.23,0,0,1-.11,1,2,2,0,0,1-.36.79,1.76,1.76,0,0,1-.7.52A2.71,2.71,0,0,1,714.17,307.19Z"
          fill="#ff4d00"
        />
      </g>
      <g id="be1e3fdd-fbd7-403a-9838-3260beaaca75" data-name="Icons">
        <circle
          cx="689.16"
          cy="592.78"
          r="1.64"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="687.47 606.88 687.47 596.25 690.85 596.25 690.85 606.88"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="682.02 583.19 685.22 580 685.31 580 688.5 583.19"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="696.3 580 693.11 583.19 693.01 583.19 689.82 580"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="681.5"
          y="587"
          width="15.32"
          height="20"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="454 372.5 460.94 372.5 474.33 358 481.27 358"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="465.78"
          cy="355.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="464.59 368.41 464.59 358.64 466.97 358.64 466.97 366.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="659 361.5 665.94 361.5 679.33 347 686.27 347"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="670.78"
          cy="344.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="669.59 357.41 669.59 347.64 671.97 347.64 671.97 355.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="635 565.5 641.94 565.5 655.33 551 662.27 551"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="646.78"
          cy="548.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="645.59 561.41 645.59 551.64 647.97 551.64 647.97 559.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  </MapTransformer>
);

ThirdFloor.defaultProps = {
  mapWidth: 1419.07,
  mapHeight: 1004.43,
};

ThirdFloor.propTypes = {
  mapHeight: PropTypes.number,
  mapWidth: PropTypes.number,
  MapTransformer: PropTypes.func.isRequired,
};

export default ThirdFloor;
