import {
  getEventAdditionalEnglishText,
  getEventAdditionalRussianText,
  getEventEndDatetime,
  getEventEnglishTitle,
  getEventImageURL,
  getEventRussianTitle,
  getEventStartDatetime,
  getEventTags,
} from '@esentai/core/features/campaigns/calendarEvent/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepThree } from '@/features/campaignCreatePage/actions';
import {
  getCampaignId,
  getEventLayoutWithRename,
} from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStepCalendarEvent from './component';
import form from './form';

const mapStateToProps = state => {
  const id = getCampaignId(state);

  return {
    tags: getEventTags(state, id),
    layout: getEventLayoutWithRename(state, id),
    imageUrl: getEventImageURL(state, id),
    russianTitle: getEventRussianTitle(state, id),
    englishTitle: getEventEnglishTitle(state, id),
    additionalRussianText: getEventAdditionalRussianText(state, id),
    additionalEnglishText: getEventAdditionalEnglishText(state, id),
    startDatetime: getEventStartDatetime(state, id),
    endDatetime: getEventEndDatetime(state, id),
  };
};

const mapDispatchToProps = { onSubmit: goToStepThree };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
)(SecondStepCalendarEvent);
