import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/storesPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getStoresError,
  getStoresIds,
  getStoresTotalCount,
  isLoadingStores,
} from '../selectors';
import Stores from './component';

const mapStateToProps = state => ({
  error: getStoresError(state),
  storesIds: getStoresIds(state),
  isLoading: isLoadingStores(state),
  totalCount: getStoresTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(Stores);
