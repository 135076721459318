import { compose } from 'ramda';
import { connect } from 'react-redux';
import { object, string } from 'yup';

import {
  ALLOWED_LOCATION_IDS_KEY,
  NAME_KEY,
  STORE_IDS_KEY,
} from '@/features/personnel/consts/keys';
import {
  getLocationsForPersonnel,
  getNameForPersonnel,
  getStoresForPersonnel,
} from '@/features/personnel/selectors';
import { updatePersonnel } from '@/features/personnelEditPage/actions';
import {
  getEditableFields,
  getPersonnelId,
} from '@/features/personnelEditPage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import EditForm from './component';

const errorsMapping = {};

export const formikOptions = {
  mapPropsToValues: ({ userName, stores, locations }) => ({
    [ALLOWED_LOCATION_IDS_KEY]: locations || [],
    [NAME_KEY]: userName || '',
    [STORE_IDS_KEY]: stores || [],
  }),

  validationSchema: object().shape({
    [NAME_KEY]: string().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

const mapStateToProps = state => {
  const personnelId = getPersonnelId(state);
  const fields = getEditableFields(state);

  return {
    userName: getNameForPersonnel(state, personnelId),
    stores: getStoresForPersonnel(state, personnelId),
    locations: getLocationsForPersonnel(state, personnelId),
    fields,
  };
};

const mapDispatchToProps = { onSubmit: updatePersonnel };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
)(EditForm);
