import { FEATURE_NAME } from './consts';

export const CLEAR_SELECTION = `${FEATURE_NAME}/CLEAR_SELECTION`;

export const FETCH_ITEMS = `${FEATURE_NAME}/FETCH_ITEMS`;

export const MOVE_BEACON = `${FEATURE_NAME}/MOVE_BEACON`;

export const SELECT_BEACON = `${FEATURE_NAME}/SELECT_BEACON`;

export const SELECT_FLOOR = `${FEATURE_NAME}/SELECT_FLOOR`;

export const SELECT_RENT_PLACE = `${FEATURE_NAME}/SELECT_RENT_PLACE`;
