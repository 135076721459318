import { ArrowUpward } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';

const MoveUpButton = ({ isVisible, ...props }) => (
  <ActionButton
    text="Поднять выше"
    button={AsyncButton}
    icon={ArrowUpward}
    disabled={isVisible}
    {...props}
  />
);

MoveUpButton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default MoveUpButton;
