import PropTypes from 'prop-types';
import React from 'react';

import StoreNameLazy from '@/containers/StoreNameLazy';

const FormatComponent = ({ value: id }) => <StoreNameLazy storeId={id} />;

FormatComponent.propTypes = {
  value: PropTypes.number.isRequired,
};

export default FormatComponent;
