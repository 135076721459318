export default {
  root: {
    marginBottom: 10,
  },

  patternLink: {
    marginRight: 26,
    borderBottom: [1, 'dotted'],
    cursor: 'pointer',
    pointerEvents: ({ disabled }) => (disabled ? 'none' : 'initial'),
  },
};
