export const MALL_COMMISSAR_ROLE = 'mall_commissar';

export const MALL_ADMINISTRATOR_ROLE = 'mall_admin';

export const MALL_MANAGER_ROLE = 'mall_manager';

export const MALL_SELLER_ROLE = 'mall_seller';

export const STORE_ADMINISTRATOR_ROLE = 'store_admin';

export const STORE_MANAGER_ROLE = 'store_manager';

export const STORE_SELLER_ROLE = 'store_seller';

export const CUSTOMER_ROLE = 'customer';

export const MALL_ACCOUNTANT_ROLE = 'mall_accountant';

export const MALL_VALET_ROLE = 'mall_valet';

export const GUEST_PARKING_ROLE = 'service_lpr_external';

export const BITRIX_ROLE = 'service_1c_external';

export const STORE_BENEFIT_MANAGER_ROLE = 'store_benefit_manager';

export const GUEST_EFS_ROLE = 'service_efs_external';

export const EFS_SALES_ROLE = 'sales_efs';

export const MALL_RECEPTION_ROLE = 'mall_reception';

export const MANAGING_TEAM_MEMBER_ROLE = 'managing_team_member';
