import { connect } from 'react-redux';

import {
  getActivationsCount,
  getActivationsToPrePushClicksPercentage,
  getConversionRate,
  getPrePushClicksCount,
  getPrePushClicksToSegmentPercentage,
  getPushClicksCount,
  getPushClicksToActivationsPercentage,
  getSegment,
} from '@/features/campaignsStatisticsDetailsPage/selectors';

import PurchaseResultFacts from './component';

const mapStateToProps = (state, { itemId }) => ({
  segmentSize: getSegment(state, itemId),
  prePushClickCount: getPrePushClicksCount(state, itemId),
  actionCount: getActivationsCount(state, itemId),
  pushClickCount: getPushClicksCount(state, itemId),
  conversion: getConversionRate(state, itemId),
  prePushClicksToSegmentPercentage: getPrePushClicksToSegmentPercentage(
    state,
    itemId,
  ),
  activationsToPrePushClicksPercentage: getActivationsToPrePushClicksPercentage(
    state,
    itemId,
  ),
  pushClicksToActivationsPercentage: getPushClicksToActivationsPercentage(
    state,
    itemId,
  ),
});

export default connect(mapStateToProps)(PurchaseResultFacts);
