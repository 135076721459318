import { findCampaignById } from '@esentai/core/features/campaigns/actions';
import {
  getCampaignTitle,
  isCampaignLoaded,
  isLoadingCampaign,
} from '@esentai/core/features/campaigns/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import CampaignTitleLazy from './component';

const mapStateToProps = (state, { id }) => ({
  isLoaded: isCampaignLoaded(state, id),
  isLoading: isLoadingCampaign(state, id),
  itemId: id,
  title: getCampaignTitle(state, id),
});

const mapDispatchToProps = { fetchItem: findCampaignById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(CampaignTitleLazy);
