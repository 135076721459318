export default theme => ({
  list: {
    minHeight: 30,
    marginBottom: 30,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 20,
    borderRadius: 10,
    width: '90%',
    height: '90%',
  },
  avatar: {
    width: '30%',
  },
  productName: {
    width: '50%',
  },
  price: {
    width: '20%',
  },
  actions: {
    width: '10%',
  },
  item: {
    backgroundColor: '#eee',
    marginBottom: 10,
    borderRadius: 10,
    boxShadow: theme.shadows[1],
  },
});
