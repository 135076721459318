import { path } from 'ramda';

import api from '@/api';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

import { FEATURE_NAME, ROUTE_PATH } from './consts';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const createMerchantDetailsPageUrl = itemId =>
  `${ROUTE_PATH.replace(':details_id', itemId)}`;

export const createResourceIdURL = id => ROUTE_PATH.replace(':details_id', id);

export const downloadXls = (historyId, bonusName) => {
  const urlParams = new URLSearchParams(window.location.search);
  const limit = Number(urlParams.get('limit')) || 10;
  const page = Number(urlParams.get('page'));
  const offset = page * limit;

  urlParams.set('limit', limit.toString());
  urlParams.set('offset', offset.toString());
  urlParams.set('store_bonus_history_id[eq]', historyId);
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'id');
  }

  api
    .doBlobGet(
      `/store-bonus-repayment-and-cm-details-xlsx-export/${historyId}?${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(
        response.data,
        `[${bonusName}],Детализация бонусов на период.xlsx`,
      );
    });
};

export const downloadMerchantHistoryDetailXls = (id, name) => {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set('merchant[eq]', id);

  if (!urlParams.get('sort')) {
    urlParams.set('sort', 'total_to_pay');
  }

  for (const param of urlParams.entries()) {
    const key = param[0];
    const value = param[1];

    urlParams.set(key, value);
  }

  api
    .doBlobGet(
      `/merchant-reconciliation-history-xlsx-export?${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(response.data, `Архив арендатора ${name}.xlsx`);
    });
};

export const downloadMerchantPayDetalizationXls = (id, name) => {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set('merchant[eq]', id);
  urlParams.set('sort', 'record_created');

  api
    .doBlobGet(
      `/merchant-reconciliation-payment-xlsx-export?${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(response.data, `Детализация по оплате ${name}.xlsx`);
    });
};
