import { multiply, pipe } from 'ramda';

import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';

import {
  AVG_NR_PURCHASES_KEY,
  AVG_NR_RECEIPTS_KEY,
  AVG_TICKET_KEY,
  BY_CAMPAIGN_KEY,
  NR_RECEIPTS_KEY,
  PERCENT_RECEIPTS_BLACK_LEVEL_KEY,
  PERCENT_RECEIPTS_DAY_KEY,
  PERCENT_RECEIPTS_EVENING_KEY,
  PERCENT_RECEIPTS_JETBLACK_LEVEL_KEY,
  PERCENT_RECEIPTS_MORNING_KEY,
  PERCENT_RECEIPTS_ORANGE_LEVEL_KEY,
  PERCENT_RECEIPTS_SILVER_LEVEL_KEY,
  PERCENT_RECEIPTS_WHITE_LEVEL_KEY,
} from './consts/keys';
import dao from './ducks/dao';

export const {
  getItemById,
  getItemPropById,
  isLoadingItem,
  isItemLoaded,
} = dao.selectors;

const getItemPercentagePropById = property =>
  pipe(getItemPropById(property), multiply(100));

export const getAveragePurchasesById = getItemPropById(AVG_NR_PURCHASES_KEY);
export const getAverageReceiptsById = getItemPropById(AVG_NR_RECEIPTS_KEY);
export const getAverageTicketById = getItemPropById(AVG_TICKET_KEY);

export const getPercentageReceiptsByCampaignById = getItemPropById(
  BY_CAMPAIGN_KEY,
);
export const getPurchasesCountById = getItemPropById(NR_RECEIPTS_KEY);
export const getPercentageReceiptsByBlackUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_BLACK_LEVEL_KEY,
);

export const getPercentageReceiptsBySilverUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_SILVER_LEVEL_KEY,
);
export const getPercentageReceiptsByWhiteUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_WHITE_LEVEL_KEY,
);
export const getPercentageReceiptsByJetBlackUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_JETBLACK_LEVEL_KEY,
);
export const getPercentageReceiptsByOrangeUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_ORANGE_LEVEL_KEY,
);
export const getPercentageReceiptsByMorningById = getItemPercentagePropById(
  PERCENT_RECEIPTS_MORNING_KEY,
);
export const getPercentageReceiptsByDayById = getItemPercentagePropById(
  PERCENT_RECEIPTS_DAY_KEY,
);
export const getPercentageReceiptsByEveningById = getItemPercentagePropById(
  PERCENT_RECEIPTS_EVENING_KEY,
);

export const getDateById = pipe(
  getItemPropById(TIMEFRAME_KEY),
  val => new Date(val),
);
