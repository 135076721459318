import {
  REGISTERED_STATUS,
  UNREGISTERED_STATUS,
} from '@esentai/core/features/giftCertificates/consts/statuses';

export const statusLabel = status => {
  const mapping = {
    [REGISTERED_STATUS]: 'Уведомление',
    [UNREGISTERED_STATUS]: 'Сообщение',
  };

  return mapping[status];
};
