import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/locationsStatisticsPage/ducks/query';
import { getDefaultMetricPageUrl } from '@/features/locationsStatisticsPage/selectors';
import { getStatsIds } from '@/features/statisticsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import LocationsStatistics from './component';

const mapStateToProps = state => ({
  defaultMetricPageUrl: getDefaultMetricPageUrl(state),
  statsIds: getStatsIds(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(LocationsStatistics);
