import {
  getBeaconMajorId,
  getBeaconMinorId,
  getNameForBeacon,
} from '@esentai/core/features/beacons/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import BeaconInfo from './component';
import styles from './styles';

const mapStateToProps = (state, { beaconId }) => ({
  majorId: getBeaconMajorId(state, beaconId),
  minorId: getBeaconMinorId(state, beaconId),
  beaconName: getNameForBeacon(state, beaconId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(BeaconInfo);
