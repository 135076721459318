import PropTypes from 'prop-types';
import React from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import LazyEditForm from '@/components/LazyEditForm';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import StoreNameLazy from '@/containers/StoreNameLazy';
import { createStorePageUrl } from '@/features/storePage/utils';
import { ROUTE_PATH as STORES_PATH } from '@/features/storesPage/consts';

import Form from './Form';

const PageEditStore = ({
  fetchItem,
  getItemLoadingError,
  isItemLoaded,
  isItemLoading,
  storeId,
  updateStore,
}) => (
  <Page>
    <PageHeader gutterBottom={false} xl={8}>
      <Breadcrumbs>
        <Breadcrumb to={STORES_PATH}>Магазины</Breadcrumb>
        <Breadcrumb to={createStorePageUrl(storeId)}>
          <StoreNameLazy storeId={storeId} />
        </Breadcrumb>
      </Breadcrumbs>
      <BackButton />
      <PageTitle>Редактирование магазина</PageTitle>
    </PageHeader>
    <PageContent xl={8}>
      <PaperSheet>
        <LazyEditForm
          fetchItem={fetchItem}
          getItemLoadingError={getItemLoadingError}
          isItemLoaded={isItemLoaded}
          isItemLoading={isItemLoading}
          itemId={storeId}
        >
          <Form storeId={storeId} onSubmit={updateStore} />
        </LazyEditForm>
      </PaperSheet>
    </PageContent>
  </Page>
);

PageEditStore.propTypes = {
  fetchItem: PropTypes.func.isRequired,
  getItemLoadingError: PropTypes.func.isRequired,
  isItemLoaded: PropTypes.func.isRequired,
  isItemLoading: PropTypes.func.isRequired,
  updateStore: PropTypes.func.isRequired,
  storeId: PropTypes.any.isRequired,
};

export default PageEditStore;
