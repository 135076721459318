import { cloneQuery } from '@esentai/core/queries';

export const removeFilters = (query, keys) => {
  const queryClone = cloneQuery(query);
  const filters = queryClone.get('filters');

  for (const key of keys) {
    filters.delete(key);
  }

  return queryClone;
};

export const updateFilters = (query, updates) => {
  const queryClone = cloneQuery(query);
  const filters = queryClone.get('filters');

  for (const [key, filter] of Object.entries(updates)) {
    if (filter.size > 0) {
      filters.set(key, filter);
    } else {
      filters.delete(key);
    }
  }

  return queryClone;
};
