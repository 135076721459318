import { DriveEta, HowToReg, Pool, Spa } from '@material-ui/icons';

import { ROUTE_PATH as EFS_USERS_PATH } from '@/features/efsUsersPage/consts';
import { ROUTE_PATH as EFS_VISIT_RECORDS_PATH } from '@/features/efsVisitRecordsPage/consts';
import { ROUTE_PATH as PARKING_PATH } from '@/features/parkingPage/consts';
import { ROUTE_PATH as PARKING_WHITELIST_PATH } from '@/features/parkingWhitelistPage/consts';

export const mallValetMenu = [
  {
    label: 'Паркинг',
    path: PARKING_PATH,
    Icon: DriveEta,
  },
  {
    label: 'Пользователи EFS',
    path: EFS_USERS_PATH,
    Icon: Spa,
  },
  {
    label: 'EFS',
    Icon: Pool,
    path: EFS_VISIT_RECORDS_PATH,
  },
  {
    label: 'Список доступов',
    path: PARKING_WHITELIST_PATH,
    Icon: HowToReg,
  },
];
