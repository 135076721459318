import {
  ARTICLE_TYPE,
  BANNER_TYPE,
  CALENDAR_EVENT_TYPE,
} from '@esentai/core/features/campaigns/consts/types';

import { getEntityPageUrl } from '@/features/statisticsPage/utils';

export const campaignsWithInfluence = [
  BANNER_TYPE,
  ARTICLE_TYPE,
  CALENDAR_EVENT_TYPE,
];

export const ENTITY_NAME = 'campaignsStatisticsDetailsPage';

export const CAMPAIGNS_ROUTE_PATH = getEntityPageUrl('campaigns');

export const ROUTE_PATH = `${CAMPAIGNS_ROUTE_PATH}/details`;
