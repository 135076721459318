import {
  DELAY_AFTER_PURCHASE_KEY,
  PERIOD_RECEIPTS_KEY,
  PRE_PUSH_ENGLISH_MESSAGE_KEY,
  PRE_PUSH_ENGLISH_TITLE_KEY,
  PRE_PUSH_RUSSIAN_MESSAGE_KEY,
  PRE_PUSH_RUSSIAN_TITLE_KEY,
  PRE_PUSH_SEND_DATETIME_KEY,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  RECEIPTS_COUNT_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_STORE_ID_KEY,
  REWARD_LOYALTY_POINTS_KEY,
  SINGLE_RECEIPT_ITEMS_COUNT_KEY,
  SINGLE_RECEIPT_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import React, { Component } from 'react';

import SecondStepForm from '@/features/campaignCreatePage/EditPage/SecondStepForm';
import FormikPropTypes from '@/prop-types/formik';

import DelayForm from './DelayForm';
import DestinationForm from './DestinationForm';
import PrePushForm from './PrePushForm';
import PushForm from './PushForm';
import RepetitionForm from './RepetitionForm';
import RewardForm from './RewardForm';
import StoresForm from './StoresForm';
import TriggersForm from './TriggersForm';

const fieldNames = {
  [DELAY_AFTER_PURCHASE_KEY]: 'размер таймаута',
  [PERIOD_RECEIPTS_KEY]: 'сумма покупок',
  [PRE_PUSH_ENGLISH_TITLE_KEY]:
    'заголовок предварительного пуш-уведомления на английском',
  [PRE_PUSH_RUSSIAN_TITLE_KEY]:
    'заголовок предварительного пуш-уведомления на русском',
  [PRE_PUSH_ENGLISH_MESSAGE_KEY]:
    'описание предварительного пуш-уведомления на английском',
  [PRE_PUSH_RUSSIAN_MESSAGE_KEY]:
    'описание предварительного пуш-уведомления на русском',
  [PRE_PUSH_SEND_DATETIME_KEY]:
    'дата отправки предварительного пуш-уведомления',
  [PUSH_ENGLISH_TITLE_KEY]: 'заголовок пуш-уведомления на английском',
  [PUSH_RUSSIAN_TITLE_KEY]: 'заголовок пуш-уведомления на русском',
  [PUSH_ENGLISH_MESSAGE_KEY]: 'описание пуш-уведомления на английском',
  [PUSH_RUSSIAN_MESSAGE_KEY]: 'описание пуш-уведомления на русском',
  [RECEIPTS_COUNT_KEY]: 'количество покупок',
  [REFERRED_ARTICLE_ID_KEY]: 'ссылка на статью',
  [REFERRED_CALENDAR_EVENT_ID_KEY]: 'ссылка на календарное событие',
  [REFERRED_STORE_ID_KEY]: 'ссылка на магазин',
  [REWARD_LOYALTY_POINTS_KEY]: 'бонусы',
  [SINGLE_RECEIPT_KEY]: 'сумма покупки',
  [SINGLE_RECEIPT_ITEMS_COUNT_KEY]: 'количество товаров в покупке',
};

class SecondStepPurchaseResult extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { handleSubmit, SubmitButton, RequiredMessages } = this.props;

    return (
      <SecondStepForm
        fieldNames={fieldNames}
        handleSubmit={handleSubmit}
        SubmitButton={SubmitButton}
        RequiredMessages={RequiredMessages}
      >
        <StoresForm {...this.props} />
        <TriggersForm {...this.props} />
        <DelayForm {...this.props} />
        <RepetitionForm {...this.props} />
        <PrePushForm {...this.props} />
        <PushForm {...this.props} />
        <DestinationForm {...this.props} />
        <RewardForm {...this.props} />
      </SecondStepForm>
    );
  }
}

export default SecondStepPurchaseResult;
