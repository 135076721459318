import { hasFilter } from '@esentai/core/query-dsl';
import { not, path, pipe } from 'ramda';

import { LOCATION_ID_KEY } from '@/features/usersStatistics/consts/filterKeys';
import { PAGES_STORE_KEY } from '@/redux/constants';

export const getItemsRoot = path([
  PAGES_STORE_KEY,
  'usersStatisticsPage',
  'items',
]);

export const getCurrentQuery = path([
  PAGES_STORE_KEY,
  'usersStatisticsPage',
  'query',
  'current',
]);

export const getPreviousQuery = path([
  PAGES_STORE_KEY,
  'usersStatisticsPage',
  'query',
  'previous',
]);

const hasLocation = hasFilter(LOCATION_ID_KEY);

export const hasLocationFilter = pipe(getCurrentQuery, hasLocation);

export const hasNotLocationFilter = pipe(hasLocationFilter, not);
