import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { VISIBILITY_KEY } from '@esentai/core/features/giftSets/consts/keys';
import {
  deleteGiftSet,
  updateGiftSet,
} from '@esentai/core/features/giftSets/sagas';
import { getVisibilityForGiftSet } from '@esentai/core/features/giftSets/selectors';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { DELETE_GIFT_SET, RETRY_FETCH, TOGGLE_VISIBILITY } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export function* removeGiftSet(action) {
  const { payload: giftSetId } = action;

  yield call(deleteGiftSet, giftSetId);
  yield call(fetchItems);
}

export function* toggleVisibility(action) {
  const { payload: giftSetId } = action;
  const visible = yield select(getVisibilityForGiftSet, giftSetId);

  yield call(updateGiftSet, giftSetId, {
    [VISIBILITY_KEY]: !visible,
  });
}

export function* watchUpdateVisibility() {
  yield takeEvery(TOGGLE_VISIBILITY, createTaskSaga(toggleVisibility));
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield takeEvery(DELETE_GIFT_SET, createTaskSaga(removeGiftSet));
  yield spawn(watchUpdateVisibility);
}
