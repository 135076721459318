import {
  CATEGORY_KEY,
  PRICE_KEY,
  SKU_KEY,
  STORE_ID_KEY,
  SUBTITLE_RU_KEY,
  TITLE_RU_KEY,
  VISIBILITY_KEY,
} from '@esentai/core/features/products/consts/keys';

import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createProductCategoryCheckboxFilter } from '@/filters/ProductCategoryCheckbox';
import { createVisibilityFilter } from '@/filters/ProductsVisibility';
import { createTextFilter } from '@/filters/SearchInput';
import { createStoreFilter } from '@/filters/Store';

export const filterTypes = [
  createTextFilter({
    key: TITLE_RU_KEY,
    label: 'Заголовок',
  }),
  createTextFilter({
    key: SUBTITLE_RU_KEY,
    label: 'Подзаголовок',
  }),
  createStoreFilter({
    key: STORE_ID_KEY,
    label: 'Магазин',
  }),
  createTextFilter({
    key: SKU_KEY,
    label: 'Артикул',
  }),
  createMoneyRangeFilter({
    key: PRICE_KEY,
    label: 'Цена товара',
  }),
  createVisibilityFilter({
    key: VISIBILITY_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),
  createProductCategoryCheckboxFilter({
    key: CATEGORY_KEY,
    label: 'Категория',
  }),
];
