import { TableRow } from '@material-ui/core';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = () => (
  <TableRow>
    <CondenseTableCell>Название вида</CondenseTableCell>
    <CondenseTableCell align="right">Сортировка</CondenseTableCell>
    <CondenseTableCell align="right" />
  </TableRow>
);

export default HeadRow;
