import PropTypes from 'prop-types';
import React from 'react';

import ButtonActivate from '@/components/ActionButtons/Activate';

const ActivateButton = ({ isActive, ...rest }) =>
  !isActive ? <ButtonActivate {...rest} /> : null;

ActivateButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default ActivateButton;
