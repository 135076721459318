export const ROUTE_PATH = '/reconciliation-history/store[eq]=:store_id';

export const FEATURE_NAME = 'reconciliationHistoryPage';

export const POST_URL = '/reconciliation/';

export const PATCH_URL = '/reconciliation-history/';

export const REPAY_CONFIRMATION_MSG =
  'Вы уверены, что хотите завершить данную реконсиляцию?';
