export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  tableBlock: {
    marginTop: 20,
  },
  buttonsWrapper: {
    marginBottom: 15,
  },
  excelButton: {
    marginTop: 15,
  },
  button: {
    marginRight: 15,
  },
};
