export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  excelButton: {
    marginTop: 15,
  },
  linkButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    fontFamily: 'arial, sans-serif',
    color: '#069',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};
