import {
  BONUSES_REFUNDED_KEY,
  BONUSES_SPENT_KEY,
  CREATED_TIMESTAMP_KEY,
} from '@esentai/core/features/reconciliation/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';

export const filterTypes = [
  createDateRangeFilter({
    key: CREATED_TIMESTAMP_KEY,
    label: 'Период реконсиляции',
  }),
  createMoneyRangeFilter({
    key: BONUSES_SPENT_KEY,
    label: 'Сумма потраченных бонусов',
  }),
  createMoneyRangeFilter({
    key: BONUSES_REFUNDED_KEY,
    label: 'Сумма бонусов на возврат',
  }),
];
