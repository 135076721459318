import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getReconciliationError,
  getItemsIds: getReconciliationIds,
  getItemsMeta: getReconciliationMeta,
  getItemsTotalCount: getReconciliationTotalCount,
  isLoadingItems: isLoadingReconciliation,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
