import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/campaignCreateUserFilterPage/ducks/query';
import withAsyncFormik from '@/hocs/withAsyncFormik';
import withQuerySync from '@/hocs/withQuerySync';

import CampaignCreateUserFilterPage from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => ({
  state,
});

export default compose(
  connect(mapStateToProps, () => ({})),
  withStyles(styles),
  withAsyncFormik(form),
  withQuerySync(queryDuck),
)(CampaignCreateUserFilterPage);
