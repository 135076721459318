import { connect } from 'react-redux';

import FiltersRow from '@/components/FiltersRow/index';
import {
  removeFilters,
  updateFilters,
} from '@/features/campaignsStatisticsPage/actions';
import {
  getFilters,
  getFiltersTypes,
} from '@/features/campaignsStatisticsPage/selectors';

const mapStateToProps = state => ({
  filters: getFilters(state),
  filterTypes: getFiltersTypes(state),
});

const mapDispatchToProps = { removeFilters, updateFilters };

export default connect(mapStateToProps, mapDispatchToProps)(FiltersRow);
