import { InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import RangeFilter, { createRangeFilterTemplate } from '@/filters/Range';
import { createFormatter } from '@/filters/Range/utils';

const MoneyRangeFilter = ({ fromInputProps, toInputProps, ...rest }) => (
  <RangeFilter
    {...rest}
    fromInputProps={{
      endAdornment: <InputAdornment position="end">₸</InputAdornment>,
      ...fromInputProps,
    }}
    toInputProps={{
      endAdornment: <InputAdornment position="end">₸</InputAdornment>,
      ...toInputProps,
    }}
  />
);

MoneyRangeFilter.defaultProps = {
  fromInputProps: {},
  toInputProps: {},
};

MoneyRangeFilter.propTypes = {
  fromInputProps: PropTypes.object,
  toInputProps: PropTypes.object,
};

export const createMoneyRangeFilter = createRangeFilterTemplate({
  FilterComponent: MoneyRangeFilter,
  FormatComponent: createFormatter({ unit: '₸' }),
});

export default MoneyRangeFilter;
