import { Button, TableCell, TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import api from '@/api';
import { BALANCE_KEY } from '@/features/efsUsersPage/consts/keys';

import AddBalance from '../Modal/AddBalance';

const Row = props => {
  const {
    id,
    user_id,
    first_name_from_app,
    last_name_from_app,
    first_name_from_efs,
    last_name_from_efs,
    phone_from_efs,
    phone_from_app,
    start_at,
    finish_at,
    type,
    is_registered,
    balance,
    parking_hours,
    is_active,
    record_created,
  } = props.item;

  const [isAddBalanceDialogOpen, setAddBalanceDialogOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const openAddBalanceDialog = () => {
    setSelectedItem(prevState => ({
      ...prevState,
      id,
      user_id,
    }));
    setAddBalanceDialogOpen(true);
  };

  const closeAddBalanceDialog = () => {
    setSelectedItem(null);
    setAddBalanceDialogOpen(false);
  };

  const addBalance = async values => {
    const payload = {
      subscription_efs: [
        {
          ...selectedItem,
          balance: Number(values[BALANCE_KEY]),
        },
      ],
    };

    try {
      await api.doPatch(`/subscription-efs`, payload);
      closeAddBalanceDialog();
      window.efsData.loadEfsUsersData();
    } catch (error) {
      throw error;
    }
  };

  const getFullName = (firstName, lastName) => {
    if (!firstName && !lastName) {
      return '-';
    }

    return `${firstName} ${lastName}`;
  };

  return (
    <TableRow>
      <TableCell>{user_id}</TableCell>
      <TableCell>
        {getFullName(first_name_from_efs, last_name_from_efs)}
      </TableCell>
      {!_get(window, 'efsData.isMallValet') && (
        <TableCell>
          {getFullName(first_name_from_app, last_name_from_app)}
        </TableCell>
      )}
      <TableCell>{phone_from_efs || '-'}</TableCell>
      <TableCell>{type || '-'}</TableCell>
      <TableCell>{`${format(new Date(start_at), 'dd.MM.yyyy')} - ${format(
        new Date(finish_at),
        'dd.MM.yyyy',
      )}`}</TableCell>
      <TableCell>{phone_from_app || '-'}</TableCell>{' '}
      <TableCell>
        {`${format(new Date(record_created), 'dd.MM.yyyy')}`}
      </TableCell>
      <TableCell>{parking_hours}</TableCell>
      <TableCell>{is_registered ? 'Да' : 'Нет'}</TableCell>
      <TableCell>{is_active ? 'Да' : 'Нет'}</TableCell>
      <TableCell>{balance}</TableCell>
      {_get(window, 'efsData.isMallValet') && (
        <TableCell>
          <Button disabled={balance > 0} onClick={openAddBalanceDialog}>
            Добавить баланс
          </Button>
        </TableCell>
      )}
      {isAddBalanceDialogOpen && (
        <AddBalance
          disableBackdropClick
          disableEscapeKeyDown
          open={isAddBalanceDialogOpen}
          onClose={closeAddBalanceDialog}
          onSubmit={addBalance}
          style={{ display: 'flex' }}
          item={selectedItem}
        />
      )}
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
