import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import RemoveButton from '@/components/ActionButtons/Remove';
import { remove } from '@/features/campaignsPage/actions';
import { canDeleteCampaign } from '@/features/campaignsPage/permissions';

const mapStateToProps = () => ({
  permission: canDeleteCampaign,
});

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(remove(itemId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(RemoveButton);
