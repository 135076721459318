import {
  HAS_TRIGGERS_KEY,
  TRIGGERS_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import {
  PERIOD_RECEIPT_TRIGGER,
  RECEIPTS_COUNT_TRIGGER,
  SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER,
  SINGLE_RECEIPT_TRIGGER,
} from '@esentai/core/features/campaigns/purchaseResult/consts/triggers';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import CheckboxTile, { CheckBoxGroup } from '@/components/CheckboxTile';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';

import { CanEdit, CanEditRadioBoxGroup } from '../../CanEdit';
import PeriodReceiptsForm from './PeriodReceiptsForm';
import ReceiptsCountForm from './ReceiptsCountForm';
import SingleReceiptForm from './SingleReceiptForm';
import SingleReceiptItemsCountForm from './SingleReceiptItemsCountForm';

class TriggersForm extends PureComponent {
  static propTypes = {
    hasTriggers: PropTypes.bool.isRequired,
    periodReceipts: PropTypes.any.isRequired,
    receiptsCount: PropTypes.any.isRequired,
    singleReceipt: PropTypes.any.isRequired,
    singleReceiptItemsCount: PropTypes.any.isRequired,
    triggers: PropTypes.any.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    Field: PropTypes.func.isRequired,
  };

  toggleTriggers = () => {
    const { hasTriggers, setFieldValue } = this.props;

    if (hasTriggers) {
      setFieldValue(HAS_TRIGGERS_KEY, false);
    } else {
      setFieldValue(HAS_TRIGGERS_KEY, true);
    }
  };

  handleTriggersChange = (_, triggers) => this.setTriggers(triggers);

  setTriggers = value => {
    const { setFieldValue } = this.props;

    setFieldValue(TRIGGERS_KEY, value);
  };

  render() {
    const { hasTriggers, triggers } = this.props;

    return (
      <FieldRow
        label="Условие покупки"
        subLabel="Условие активации кампании"
        sudDesc="Вы можете установить лимит на минимальную сумму покупки, накопленную сумму за период, количество покупок или средний чек. Без лимита триггером будет считаться сам факт покупки."
      >
        <CanEdit field={HAS_TRIGGERS_KEY}>
          <FormControlLabel
            label="Использовать свойства покупки для активации кампании"
            control={
              <Checkbox checked={hasTriggers} onChange={this.toggleTriggers} />
            }
          />
        </CanEdit>

        {hasTriggers ? (
          <div>
            <FieldDescription
              title="Тип активации"
              desc="Если установлен лимит на покупку, можно указать, какой именно тип активации может быть реализован. Триггеры работают совместно. Раздельное использование реализуется несколькими кампаниями."
            />
            <CanEditRadioBoxGroup field={TRIGGERS_KEY}>
              <CheckBoxGroup
                value={triggers}
                onChange={this.handleTriggersChange}
              >
                <CheckboxTile
                  value={SINGLE_RECEIPT_TRIGGER}
                  label="Сумма одной покупки"
                  helperText="Триггер кампании будет активироваться, когда клиент осуществит единоразовую покупку на указанную сумму."
                />
                <CheckboxTile
                  value={PERIOD_RECEIPT_TRIGGER}
                  label="Трата суммы за период"
                  helperText="Триггер кампании будет активироваться, когда клиент сделает покупки за период кампании на указанную сумму."
                />
                <CheckboxTile
                  value={RECEIPTS_COUNT_TRIGGER}
                  label="Количество покупок"
                  helperText="Триггер кампании будет активироваться, когда клиент сделает указанное количество  покупкок за указанный период."
                />
                <CheckboxTile
                  value={SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER}
                  label="Количество товаров"
                  helperText="Триггер кампании будет активироваться, когда клиент сделает покупку с указанным количеством товаров."
                />
              </CheckBoxGroup>
            </CanEditRadioBoxGroup>

            <CanEdit field={SINGLE_RECEIPT_TRIGGER}>
              {triggers.includes(SINGLE_RECEIPT_TRIGGER) && (
                <SingleReceiptForm {...this.props} />
              )}
            </CanEdit>
            <CanEdit field={PERIOD_RECEIPT_TRIGGER}>
              {triggers.includes(PERIOD_RECEIPT_TRIGGER) && (
                <PeriodReceiptsForm {...this.props} />
              )}
            </CanEdit>
            <CanEdit field={RECEIPTS_COUNT_TRIGGER}>
              {triggers.includes(RECEIPTS_COUNT_TRIGGER) && (
                <ReceiptsCountForm {...this.props} />
              )}
            </CanEdit>
            <CanEdit field={SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER}>
              {triggers.includes(SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER) && (
                <SingleReceiptItemsCountForm {...this.props} />
              )}
            </CanEdit>
          </div>
        ) : null}
      </FieldRow>
    );
  }
}

export default TriggersForm;
