import { Button, Grid, Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CalendarSelect from '../../CalendarSelect';
import SizesList from '../../SizesList';

const Popup = ({
  classes,
  popoverProps,
  sizesProps,
  calendarProps,
  submitChange,
}) => (
  <Popover {...popoverProps} classes={{ paper: classes.root }}>
    <SizesList {...sizesProps} />
    <div>
      <CalendarSelect {...calendarProps} />
      <Grid container justify="flex-end">
        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={submitChange}
        >
          Применить
        </Button>
      </Grid>
    </div>
  </Popover>
);

Popup.propTypes = {
  popoverProps: PropTypes.object.isRequired,
  sizesProps: PropTypes.object.isRequired,
  calendarProps: PropTypes.object.isRequired,
  submitChange: PropTypes.func.isRequired,
};

export default Popup;
