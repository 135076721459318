import { ENTITY_NAME as BRANDS_KEY } from '@esentai/core/features/brands/consts';
import dao from '@esentai/core/features/stores/dao';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import { FETCH_STORE } from './consts/types';

export const { findById: findStoreById } = dao.sagas;

export function* fetchStore(action) {
  const { payload: storeId } = action;

  yield call(findStoreById, storeId, { include: [BRANDS_KEY] });
}

export function* watchFetchStore() {
  yield takeEvery(FETCH_STORE, fetchStore);
}

export default function*() {
  yield spawn(watchFetchStore);
}
