import { Grid, Grow, IconButton, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Dropzone from 'react-dropzone';

import ImageThumb from '@/components/ImageThumb';
import apiUpload from '@/features/upload/api';

const DnDUploader = ({ classes, setImages, images, disabled, width }) => {
  const [previews, setPreviews] = useState([]);

  const dragEnd = result => {
    if (result.destination) {
      const [removed] = images.splice(result.source.index, 1);

      images.splice(result.destination.index, 0, removed);
      setImages(images);
    }
  };

  const removeImage = imgInd => {
    setImages(images.filter((_, index) => imgInd !== index));
  };

  const upload = async files => {
    for (const file of files) {
      const urls = await apiUpload.getUrl(file.name);
      const { upload_url: url, download_url } = urls;

      await apiUpload.upload({ url, file });

      setPreviews(prevState => prevState.filter(p => file.name !== p.id));
      images.push(download_url);
      setImages(images);
    }
  };

  return (
    <DragDropContext onDragEnd={result => dragEnd(result)}>
      <Grid container spacing={24}>
        <Droppable
          droppableId="droppable"
          direction="horizontal"
          isDropDisabled={disabled}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{
                backgroundColor: snapshot.isDraggingOver ? '#eeeeee' : '',
              }}
              {...provided.droppableProps}
            >
              <Dropzone
                onDrop={async acceptedFiles => {
                  setPreviews([
                    ...previews,
                    ...acceptedFiles.map(file => ({
                      id: file.name,
                      img: URL.createObjectURL(file),
                    })),
                  ]);

                  await upload(acceptedFiles);
                }}
                noClick
                accept={'.jpeg,.png,.jpg,.bmp'}
              >
                {({ getRootProps, getInputProps, open }) => (
                  <section style={{ width }}>
                    <div {...getRootProps()} className={classes.drop}>
                      <input {...getInputProps()} />
                      {images.length === 0 && previews.length === 0 && (
                        <Typography>Перетащите изображения сюда</Typography>
                      )}
                      {images.map((image, imgInd) => (
                        <Draggable
                          draggableId={`draggable-${image}`}
                          index={imgInd}
                          key={image}
                          position="left"
                          isDragDisabled={disabled}
                        >
                          {providedDrag => (
                            <div
                              ref={providedDrag.innerRef}
                              {...providedDrag.draggableProps}
                              {...providedDrag.dragHandleProps}
                            >
                              <ImageThumb
                                url={image}
                                remove={() => removeImage(imgInd)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {previews.map(prev => (
                        <Grow in key={prev.id}>
                          <ImageThumb url={prev.img} loading />
                        </Grow>
                      ))}
                      {provided.placeholder}

                      <IconButton
                        color="primary"
                        onClick={open}
                        disabled={disabled}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          )}
        </Droppable>
      </Grid>
    </DragDropContext>
  );
};

DnDUploader.defaultProps = {
  width: 800,
};

DnDUploader.propTypes = {
  images: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  setImages: PropTypes.func.isRequired,
  width: PropTypes.number,
};

export default DnDUploader;
