export default ({ spacing }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  image: {
    width: '100%',
    maxWidth: spacing.unit * 27,
    height: spacing.unit * 27,
    backgroundColor: '#C7C7C7',
    objectFit: 'cover',
  },
});
