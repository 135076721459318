import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';

// import { downloadXls } from '@/features/giftCertificatesPage/utils';
import Description from './Description';
import Table from './Table';

class GiftCertificates extends Component {
  static propTypes = {
    error: PropTypes.any,
    giftCertificatesIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const {
      error,
      giftCertificatesIds,
      isLoading,
      totalCount,
      classes,
    } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Подарочные сертификаты</PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            {/* <Button
              variant="contained"
              color="primary"
              className={classes.excelButton}
              onClick={downloadXls}
            >
              Вывести в Excel
            </Button> */}
            <Description itemId={giftCertificatesIds[0]} />
            <Table
              error={error}
              items={giftCertificatesIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default GiftCertificates;
