import {
  FILTERS_KEY,
  SEGMENT_TYPE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import { ALL_USERS_SEGMENT } from '@esentai/core/features/campaigns/consts/segments';
import {
  BIRTH_DATE_KEY,
  DATE_REGISTERED_KEY,
} from '@esentai/core/features/users/consts/keys';
import { query } from '@esentai/core/query-dsl';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { object } from 'yup';

import { SHOULD_SUBMIT_AFTER_SAVE_KEY } from '@/features/campaignCreatePage/consts/keys';

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({ filters = query(), segmentType }) => ({
    [FILTERS_KEY]: filters,
    [SEGMENT_TYPE_KEY]: segmentType || ALL_USERS_SEGMENT,
    [SHOULD_SUBMIT_AFTER_SAVE_KEY]: false,
  }),

  validationSchema: object().shape({}),

  handleSubmit(values, { props, setSubmitting }) {
    const filters = cloneDeep(values.filter);

    const dateFilters = [DATE_REGISTERED_KEY, BIRTH_DATE_KEY];

    filters.forEach(filter => {
      filter.forEach((valueMap, filterName) => {
        if (dateFilters.includes(filterName)) {
          valueMap.forEach((value, field) => {
            const date = new Date(moment(value).startOf('day'));

            if (field === 'gte') {
              valueMap.set(field, date);
            } else {
              valueMap.set(
                field,
                new Date(
                  moment(date).set({ hours: 23, minutes: 59, seconds: 59 }),
                ),
              );
            }
          });
        }
      });
    });

    props.onSubmit({ ...values, filter: filters });
    setSubmitting(false);
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
