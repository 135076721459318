import { updateFilters } from '@/redux/query/utils';

export default () => (state, { payload: filterUpdates }) => {
  const queryClone = updateFilters(state, filterUpdates);

  if (queryClone.has('page')) {
    queryClone.set('page', 0);
  }

  return queryClone;
};
