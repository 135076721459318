import {
  ADDITIONAL_ENGLISH_TEXT_KEY,
  ADDITIONAL_RUSSIAN_TEXT_KEY,
  ENGLISH_TITLE_KEY,
  EVENT_END_DATETIME_KEY,
  EVENT_START_DATETIME_KEY,
  IMAGE_URL_KEY,
  LAYOUT_KEY,
  RUSSIAN_TITLE_KEY,
  TAGS_KEY,
} from '@esentai/core/features/campaigns/calendarEvent/consts/keys';
import { isValid, startOfDay } from 'date-fns';
import { array, date, object, string } from 'yup';

import layoutSchema from '@/components/ArticleConstructorForm/schema';
import { getDefaultLayout } from '@/components/ArticleConstructorForm/utils';
import { replaceSubmitValues } from '@/features/campaignCreatePage/utils';

const asyncOptions = { runnerMethod: 'onSubmit' };

const today = startOfDay(new Date());

const formikOptions = {
  mapPropsToValues: props => ({
    [TAGS_KEY]: props.tags || [],
    [LAYOUT_KEY]: props.layout || getDefaultLayout(),
    [IMAGE_URL_KEY]: props.imageUrl,
    [RUSSIAN_TITLE_KEY]: props.russianTitle || '',
    [ENGLISH_TITLE_KEY]: props.englishTitle || '',
    [ADDITIONAL_RUSSIAN_TEXT_KEY]: props.additionalRussianText || '',
    [ADDITIONAL_ENGLISH_TEXT_KEY]: props.additionalEnglishText || '',
    [EVENT_START_DATETIME_KEY]: props.startDatetime,
    [EVENT_END_DATETIME_KEY]: props.endDatetime,
  }),

  validationSchema: object({
    [EVENT_START_DATETIME_KEY]: date()
      .min(
        today,
        'Календарное событие не может начинать действовать в прошедших числах',
      )
      .required(),
    [EVENT_END_DATETIME_KEY]: date().when(
      EVENT_START_DATETIME_KEY,
      (startDate, schema) => {
        const message = 'Дата завершения не может быть раньше, чем дата начала';

        return isValid(startDate) ? schema.min(startDate, message) : schema;
      },
    ),
    [LAYOUT_KEY]: layoutSchema,
    [TAGS_KEY]: array()
      .of(string())
      .min(1)
      .required(),
    [IMAGE_URL_KEY]: string()
      .url()
      .required(),
    [RUSSIAN_TITLE_KEY]: string()
      .max(50)
      .required(),
    [ENGLISH_TITLE_KEY]: string()
      .max(50)
      .required(),
    [ADDITIONAL_RUSSIAN_TEXT_KEY]: string()
      .max(200)
      .required(),
    [ADDITIONAL_ENGLISH_TEXT_KEY]: string()
      .max(200)
      .required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    const data = {
      ...values,
      [LAYOUT_KEY]: replaceSubmitValues(values[LAYOUT_KEY]),
    };

    props.onSubmit(data);
    setSubmitting(false);
  },
};

const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
