import {
  ORDER_IN_PROCESS,
  ORDER_IS_NOT_PAID,
  ORDER_IS_PAID,
  orderStatus,
} from '@/features/parkingPage/consts/keys';
import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [ORDER_IS_NOT_PAID, ORDER_IS_PAID, ORDER_IN_PROCESS].map(
  key => ({
    key,
    label: orderStatus(key),
  }),
);

export const createParkingOrderStatusFilter = createRadioFilterTemplate({
  options,
});

export default RadioGroupFilter;
