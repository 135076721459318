export default {
  wrapper: {
    maxWidth: 608,
    marginTop: 60,
  },
  label: {
    marginBottom: 38,
  },
  bar: {
    display: 'flex',
  },
};
