import { compose, find, propEq } from 'ramda';
import React, { Component } from 'react';

export default ({ strategies, indexKey = 'name' }) => WrappedComponent => {
  const findBy = (key, val) => compose(find, propEq)(key, val)(strategies);

  class WithStrategy extends Component {
    state = {};

    render() {
      const { strategy } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          strategy={strategy}
          setStrategy={this.setStrategy}
          findStrategyBy={findBy}
        />
      );
    }

    setStrategy = strategyName => {
      const strategy = findBy(indexKey, strategyName);

      this.setState({ strategy });
    };
  }

  return WithStrategy;
};
