import { push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';

import { getLocation } from '@/redux/router/selectors';

export default ({ selectors, serializer }) =>
  function*() {
    const { pathname } = yield select(getLocation);
    const query = yield select(selectors.getQuery);
    const urlSearchParams = serializer.serialize(query);
    const url = `${pathname}?${decodeURIComponent(urlSearchParams.toString())}`;

    yield put(push(url));
  };
