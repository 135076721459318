import red from '@material-ui/core/colors/red';

export default {
  forgotLinkWrap: {
    textAlign: 'right',
  },

  footer: {
    textAlign: 'center',
    marginTop: 35,
  },

  title: {
    color: '#000',
  },

  commonError: {
    color: red[500],
  },

  requiredErrors: {
    paddingTop: 16,
    // 20 is line-height
    marginBottom: -(16 + 20),
  },
};
