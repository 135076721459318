import {
  DATE_END_KEY,
  DATE_START_KEY,
  FILTERS_KEY,
  SEGMENT_TYPE_KEY,
  TITLE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import { ALL_USERS_SEGMENT } from '@esentai/core/features/campaigns/consts/segments';
import filtersSerializer from '@esentai/core/features/campaigns/filtersSerializer';
import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  SHOW_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/notification/consts/destinations';
import {
  DESTINATION_KEY,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_KAZAKH_MESSAGE_KEY,
  PUSH_KAZAKH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_SHOW_ID_KEY,
  REFERRED_STORE_ID_KEY,
  SEND_SCHEDULE_KEY,
  STATUS,
} from '@esentai/core/features/campaigns/notification/consts/keys';
import { query } from '@esentai/core/query-dsl';
import { addMinutes } from 'date-fns';
import moment from 'moment';
import { compose, head, tail } from 'ramda';
import { date, number, object, string } from 'yup';

import api from '@/api';

import {
  INITIAL_SEND_DATETIME,
  REPEAT_SEND_DATETIMES,
  SHOULD_REPEAT_SEND,
  SHOULD_SUBMIT_AFTER_SAVE_KEY,
} from './consts/keys';
import { cleanUpValues, computeStartAndEndDates, mergeSchedule } from './utils';

const asyncOptions = { runnerMethod: 'onSubmit' };

const formikOptions = {
  mapPropsToValues: ({
    status,
    destination,
    pushEnglishTitle,
    pushRussianTitle,
    pushEnglishMessage,
    pushRussianMessage,
    pushKazakhTitle,
    pushKazakhMessage,
    referredArticle,
    referredShow,
    sendSchedule,
    title,
    filters = query(),
  }) => ({
    [TITLE_KEY]: title,
    [STATUS]: status,
    [DESTINATION_KEY]: destination,

    [PUSH_RUSSIAN_TITLE_KEY]: pushRussianTitle,
    [PUSH_RUSSIAN_MESSAGE_KEY]: pushRussianMessage,

    [PUSH_ENGLISH_TITLE_KEY]: pushEnglishTitle,
    [PUSH_ENGLISH_MESSAGE_KEY]: pushEnglishMessage,

    [PUSH_KAZAKH_TITLE_KEY]: pushKazakhTitle,
    [PUSH_KAZAKH_MESSAGE_KEY]: pushKazakhMessage,

    [REFERRED_ARTICLE_ID_KEY]: referredArticle,
    [REFERRED_SHOW_ID_KEY]: referredShow,
    [INITIAL_SEND_DATETIME]: head(sendSchedule || []) || null,
    [REPEAT_SEND_DATETIMES]: tail(sendSchedule || []),
    [SHOULD_REPEAT_SEND]: (sendSchedule || []).length > 1,
    [FILTERS_KEY]: filters,
    [SEGMENT_TYPE_KEY]: ALL_USERS_SEGMENT,
    [SHOULD_SUBMIT_AFTER_SAVE_KEY]: false,
  }),

  validationSchema: object().shape({
    [TITLE_KEY]: string()
      .max(200)
      .required(),
    [DESTINATION_KEY]: string().nullable(),
    [INITIAL_SEND_DATETIME]: date().when(STATUS, {
      is: value => value !== 'paused',
      then: date()
        .min(
          addMinutes(new Date(), 5),
          'Время старта +5 мин от текущего времени',
        )
        .required(),
      otherwise: date().nullable(),
    }),

    [PUSH_RUSSIAN_TITLE_KEY]: string()
      .max(60)
      .required(),
    [PUSH_RUSSIAN_MESSAGE_KEY]: string()
      .max(150)
      .required(),

    [PUSH_ENGLISH_TITLE_KEY]: string()
      .max(60)
      .required(),
    [PUSH_ENGLISH_MESSAGE_KEY]: string()
      .max(150)
      .required(),

    [PUSH_KAZAKH_TITLE_KEY]: string()
      .max(60)
      .required(),
    [PUSH_KAZAKH_MESSAGE_KEY]: string()
      .max(150)
      .required(),

    [REFERRED_ARTICLE_ID_KEY]: number().when(DESTINATION_KEY, {
      is: ARTICLE_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_BENEFIT_ID_KEY]: number().when(DESTINATION_KEY, {
      is: BENEFIT_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_CALENDAR_EVENT_ID_KEY]: number().when(DESTINATION_KEY, {
      is: CALENDAR_EVENT_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_STORE_ID_KEY]: number().when(DESTINATION_KEY, {
      is: STORE_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_SHOW_ID_KEY]: number().when(DESTINATION_KEY, {
      is: SHOW_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
  }),

  handleSubmit(values, { props }) {
    const processValues = compose(
      computeStartAndEndDates,
      mergeSchedule,
      cleanUpValues,
    );
    const processedValues = processValues(values);

    const { history } = props;

    const notification = {
      [DATE_START_KEY]: moment(processedValues[DATE_START_KEY]).format(
        'YYYY-MM-DD',
      ),
      [DATE_END_KEY]: moment(processedValues[DATE_START_KEY]).format(
        'YYYY-MM-DD',
      ),
      [PUSH_RUSSIAN_TITLE_KEY]: processedValues[PUSH_RUSSIAN_TITLE_KEY],
      [PUSH_RUSSIAN_MESSAGE_KEY]: processedValues[PUSH_RUSSIAN_MESSAGE_KEY],

      [PUSH_ENGLISH_TITLE_KEY]: processedValues[PUSH_ENGLISH_TITLE_KEY],
      [PUSH_ENGLISH_MESSAGE_KEY]: processedValues[PUSH_ENGLISH_MESSAGE_KEY],

      [PUSH_KAZAKH_TITLE_KEY]: processedValues[PUSH_KAZAKH_TITLE_KEY],
      [PUSH_KAZAKH_MESSAGE_KEY]: processedValues[PUSH_KAZAKH_MESSAGE_KEY],

      [REFERRED_ARTICLE_ID_KEY]: processedValues[REFERRED_ARTICLE_ID_KEY],
      [REFERRED_SHOW_ID_KEY]: processedValues[REFERRED_SHOW_ID_KEY],
      [SEND_SCHEDULE_KEY]: processedValues[SEND_SCHEDULE_KEY],
      [SEGMENT_TYPE_KEY]: ALL_USERS_SEGMENT,
      [FILTERS_KEY]: filtersSerializer.serialize(processedValues[FILTERS_KEY]),
    };

    if (values[SHOULD_SUBMIT_AFTER_SAVE_KEY] === true) {
      notification.status = 'approved';
    }

    api
      .doPost('/campaign-notification', {
        campaign_notification: {
          title: values.title,
          location_ids: [],
        },
      })
      .then(data => {
        api
          .doPatch(`/campaign-notification`, {
            campaign_notification: [
              {
                ...notification,
                id: data.campaign_notification[0].id,
              },
            ],
          })
          .then(dataPatch => {
            history.push(
              `/campaigns/notification/${dataPatch.payload.campaign_notification[0].id}`,
            );
          });
      });
  },
};

const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
