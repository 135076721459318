import {
  SINGLE_IMAGE_LAYOUT,
  SLIDER_IMAGES_LAYOUT,
  STACK_IMAGES_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/imageLayouts';
import {
  IMAGES_LAYOUT_KEY,
  IMAGES_URLS_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ImageWrapper from '../ImageWrapper';
import { AMOUNT_IMAGES } from './consts';
import { getMinHeight } from './utils';

class ImagesBlock extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
    required: PropTypes.bool,
  };

  static defaultProps = {
    required: null,
  };

  imagesData = [];

  state = { heightImages: null };

  handleOnloadImage = event => {
    if (this.imagesData.length < AMOUNT_IMAGES) {
      const { width, height } = event.target;

      this.imagesData.push({ width, height });
    }

    if (this.imagesData.length === AMOUNT_IMAGES) {
      this.setState({ heightImages: getMinHeight(this.imagesData) });
    }
  };

  render() {
    const { classes, required, payload } = this.props;
    const { heightImages } = this.state;
    const layout = payload[IMAGES_LAYOUT_KEY];
    const urls = payload[IMAGES_URLS_KEY];

    if (layout === SINGLE_IMAGE_LAYOUT) {
      return (
        <div className={classes.imageWrapper}>
          <img
            className={cx(classes.single, {
              [classes.single_required]: required,
            })}
            src={urls[0]}
          />
        </div>
      );
    }

    if (layout === STACK_IMAGES_LAYOUT) {
      return (
        <div className={heightImages ? classes.stack : classes.stackHide}>
          <ImageWrapper
            height={heightImages}
            src={urls[0]}
            onLoad={this.handleOnloadImage}
          />
          <ImageWrapper
            height={heightImages}
            src={urls[1]}
            onLoad={this.handleOnloadImage}
          />
        </div>
      );
    }

    if (layout === SLIDER_IMAGES_LAYOUT) {
      return (
        <div className={classes.imageWrapper}>
          <img className={classes.slider} src={urls[0]} />
        </div>
      );
    }

    return null;
  }
}

export default ImagesBlock;
