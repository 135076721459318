import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CloneButton from '@/features/campaignsPage/Page/Row/buttons/CloneButton';
import EditButton from '@/features/campaignsPage/Page/Row/buttons/EditButton';
import RemoveButton from '@/features/campaignsPage/Page/Row/buttons/RemoveButton';

const DraftStatusActions = ({ itemId }) => (
  <Fragment>
    <RemoveButton itemId={itemId} small={false} color="primary" />
    <CloneButton itemId={itemId} small={false} color="primary" />
    <EditButton
      itemId={itemId}
      small={false}
      color="primary"
      variant="contained"
    />
  </Fragment>
);

DraftStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default DraftStatusActions;
