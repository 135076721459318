import { Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import Mall from '@/components/Icon/Mall';
import PageTitle from '@/components/PageTitle';
import { getAuthorizedStatus } from '@/features/auth/selectors';
import { canViewAnswer } from '@/features/commissarAnswerPage/permissions';

import styles from './styles';

const CommissarAnswer = props => {
  const { classes, authorized, canViewCommissarAnswer } = props;

  if (authorized && !canViewCommissarAnswer) {
    return <Redirect to={'/'} />;
  }

  const params = new URLSearchParams(window.location.search);
  const isAccepted =
    params.has('result') && params.get('result') === 'accepted';

  return (
    <div className={classes.paperWrap}>
      <Mall className={classes.logo} width="266" color="black" />
      <Paper className={classes.paper}>
        {isAccepted && <PageTitle gutterBottom={false}>Accepted</PageTitle>}
        {!isAccepted && <PageTitle gutterBottom={false}>Rejected</PageTitle>}
      </Paper>
    </div>
  );
};

CommissarAnswer.propTypes = {
  authorized: PropTypes.bool.isRequired,
  canViewCommissarAnswer: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  authorized: getAuthorizedStatus(state),
  canViewCommissarAnswer: canViewAnswer(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CommissarAnswer);
