import {
  getCompanyName,
  getSaldo,
  getTotalPaidAmount,
  getTotalToPayAmount,
} from '@esentai/core/features/merchantsReconciliationArchive/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { retryFetch } from '../../actions';
import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: merchantId }) => ({
  saldoAmount: getSaldo(state, merchantId),
  companyName: getCompanyName(state, merchantId),
  totalPaidAmount: getTotalPaidAmount(state, merchantId),
  totalToPayAmount: getTotalToPayAmount(state, merchantId),
  merchantId,
});

const mapDispatchToProps = {
  fetchItems: retryFetch,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Row);
