import React from 'react';

const nameCol = {
  width: '350px',
};

const actionsWidth = {
  width: '170px',
};

const ColGroup = () => (
  <colgroup>
    <col style={nameCol} />
    <col style={actionsWidth} />
  </colgroup>
);

export default ColGroup;
