import {
  DELAY_AFTER_TRIGGER_KEY,
  HAS_DELAY_AFTER_TRIGGER_KEY,
} from '@esentai/core/features/campaigns/actionResult/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { mapProps } from 'recompose';

import DelayForm from './component';
import styles from './styles';

export default compose(
  mapProps(({ setFieldValue, values, Field }) => ({
    delayAfterTrigger: values[DELAY_AFTER_TRIGGER_KEY],
    hasDelayAfterTrigger: values[HAS_DELAY_AFTER_TRIGGER_KEY],
    setFieldValue,
    Field,
  })),
  withStyles(styles),
)(DelayForm);
