import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { isMallManager } from '@/features/currentUser/selectors';
import { downloadXls } from '@/features/purchaseWithoutScanPage/utils';

import AddPurchase from './Modal/AddPurchase';
import Table from './Table';

const PurchaseWithoutScanPage = props => {
  const { classes, location, state } = props;

  const [isAddPurchaseModalOpen, openAddPurchaseModal] = useState(false);

  const [purchaseData, setPurchaseData] = useState([]);

  const [pageInfo, setPageInfo] = useState({
    limit: 10,
    totalCount: 0,
    page: 0,
    loading: false,
  });

  const loadPurchaseData = async (params = {}) => {
    setPageInfo(prevState => ({
      ...prevState,
      loading: true,
    }));

    const searchParams = new URLSearchParams(window.location.search);

    const { limit, page } = pageInfo;

    const filters = {
      limit,
      offset: limit * page,
      sort: searchParams.get('sort'),
      'is_manually_added[eq]': true,
      ...params,
    };

    try {
      const response = await api.doGet('/purchase-history', { ...filters });

      setPageInfo(prevState => ({
        ...prevState,
        totalCount: response.meta.total,
      }));

      setPurchaseData(response.payload.purchase_history);
    } catch (e) {
      throw e;
    } finally {
      setPageInfo(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onSetParams = () => {
    const searchList = decodeURIComponent(
      new URLSearchParams(window.location.search),
    );
    const filterList = searchList.toString().split('&');
    const params = {};

    filterList.map(filter => {
      const value = filter.split('=');

      params[`${value[0]}`] = value[1];

      return filter;
    });

    return { params };
  };

  const onSetPageSize = newPageSize => {
    setPageInfo(prevState => ({
      ...prevState,
      page: 0,
      limit: newPageSize,
    }));
  };

  const onSetPage = newPage => {
    setPageInfo(prevState => ({
      ...prevState,
      page: newPage,
    }));
  };

  const onDownloadExcel = (withParams = false) => {
    const { limit, page } = pageInfo;

    const { params } = onSetParams();

    const filters = {
      ...(withParams && {
        limit,
        offset: limit * page,
      }),
      ...params,
      'is_manually_added[eq]': true,
    };

    downloadXls(filters);
  };

  const submitPurchase = async values => {
    try {
      await api.doPost(`/receipt`, values);
      openAddPurchaseModal(false);
      loadPurchaseData();
    } catch (error) {
      throw error;
    }
  };

  const setWindowData = () => {
    window.purchaseData = {};
    window.purchaseData.loadPurchaseData = loadPurchaseData;
  };

  useEffect(() => {
    setWindowData();
    const { params } = onSetParams();

    loadPurchaseData(params);

    return () => {
      delete window.purchaseData;
    };
  }, [pageInfo.limit, pageInfo.page, location.search]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>Покупки без сканирования</PageTitle>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        {isMallManager(state) && (
          <div className={classes.buttonsWrapper}>
            <Button
              variant="outlined"
              color="default"
              size="small"
              className={classes.button}
              onClick={() => openAddPurchaseModal(true)}
            >
              Добавить покупку
            </Button>
            <Button
              variant="outlined"
              color="default"
              size="small"
              className={classes.button}
              onClick={() => onDownloadExcel(true)}
            >
              Вывести в EXCEL
            </Button>
            <Button
              variant="outlined"
              color="default"
              size="small"
              className={classes.button}
              onClick={() => onDownloadExcel(false)}
            >
              Вывести в EXCEL все
            </Button>
          </div>
        )}
        <Table
          limit={pageInfo.limit}
          items={purchaseData}
          isLoading={pageInfo.loading}
          totalCount={pageInfo.totalCount}
          page={pageInfo.page}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
        {isAddPurchaseModalOpen && (
          <AddPurchase
            open={isAddPurchaseModalOpen}
            disableBackdropClick
            disableEscapeKeyDown
            onClose={() => openAddPurchaseModal(false)}
            onSubmit={submitPurchase}
            confirmLabel={'Добавить'}
            state={state}
            cancelLabel={'Отменить'}
            style={{ display: 'flex' }}
          />
        )}
      </PageContent>
    </Page>
  );
};

PurchaseWithoutScanPage.propTypes = {
  location: PropTypes.object.isRequired,
  state: PropTypes.object,
};

PurchaseWithoutScanPage.defaultProps = {
  state: {},
};

export default PurchaseWithoutScanPage;
