import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { formatNumber } from '@/utils/format';
import { userStatus } from '@/utils/labels';

import Field from '../PersonalInfo/Field';

const PrimaryFacts = ({
  classes,
  activeBonuses,
  giftBonuses,
  pendingBonuses,
  status,
}) => (
  <div className={classes.wrapper}>
    <Typography
      gutterBottom
      color="textSecondary"
      variant="h5"
      className={classes.title}
    >
      Данные системы лояльности
    </Typography>

    <div className={classes.facts}>
      <Field label="Уровень пользователя" className={classes.fact}>
        {userStatus(status)}
      </Field>

      <Field label="Бонусы активные" className={classes.fact}>
        {formatNumber(activeBonuses)}
      </Field>

      <Field label="Бонусы к начислению" className={classes.fact}>
        {formatNumber(pendingBonuses)}
      </Field>

      {giftBonuses > 0 && (
        <Field label="Подарочные бонусы" className={classes.fact}>
          {giftBonuses}
        </Field>
      )}
    </div>
  </div>
);

PrimaryFacts.propTypes = {
  activeBonuses: PropTypes.number.isRequired,
  pendingBonuses: PropTypes.number.isRequired,
  giftBonuses: PropTypes.number,
  status: PropTypes.number.isRequired,
};

PrimaryFacts.defaultProps = {
  giftBonuses: 0,
};

export default PrimaryFacts;
