export default {
  root: {
    display: 'flex',
    borderTop: [1, 'solid', '#BDBDBD'],
    alignItems: 'center',
  },

  title: {
    flex: 3,
  },

  status: {
    flex: 2,
    textAlign: 'left',
  },
};
