export default ({ spacing }) => ({
  indicator: {
    marginLeft: -8,
  },

  emailLink: {
    display: 'inline',
  },

  storesContainer: {
    marginTop: spacing.unit / 2,
    marginBottom: spacing.unit / 2,
  },
});
