import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldDescription from '@/components/FieldDescription';

import DefaultOrderSwitcher from './OrderSwitcher';
import DefaultRemoveButton from './RemoveButton';

const Block = ({
  canMoveDown,
  canMoveUp,
  classes,
  disabled,
  onMoveDown,
  onMoveUp,
  onRemove,
  onTypeChange,
  type,
  Content,
  OrderSwitcher,
  RemoveButton,
  TypeLabel,
  TypeSelect,
}) => (
  <div className={classes.root}>
    <OrderSwitcher
      canMoveDown={canMoveDown}
      canMoveUp={canMoveUp}
      disabled={disabled}
      moveDown={onMoveDown}
      moveUp={onMoveUp}
    />

    <div className={classes.content}>
      <Grid container className={classes.title}>
        <Grid item xs>
          <FieldDescription title="Тип блока" gutterBottom={false} />

          {disabled ? (
            <TypeLabel type={type} />
          ) : (
            <TypeSelect onChange={onTypeChange} value={type} />
          )}
        </Grid>

        {!disabled && (
          <Grid item>
            <RemoveButton onClick={onRemove} />
          </Grid>
        )}
      </Grid>

      <Content />
    </div>
  </div>
);

Block.defaultProps = {
  RemoveButton: DefaultRemoveButton,
  OrderSwitcher: DefaultOrderSwitcher,
};

Block.propTypes = {
  canMoveDown: PropTypes.bool.isRequired,
  canMoveUp: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  Content: PropTypes.func.isRequired,
  OrderSwitcher: PropTypes.func.isRequired,
  RemoveButton: PropTypes.func,
  TypeLabel: PropTypes.func.isRequired,
  TypeSelect: PropTypes.func.isRequired,
};

export default Block;
