import PropTypes from 'prop-types';
import React from 'react';

import PlayPauseButton from '@/components/PlayPauseButton';

const ToggleStatusButton = ({ active, me, toggleStatus }) =>
  !me && <PlayPauseButton active={active} onChange={toggleStatus} />;

ToggleStatusButton.propTypes = {
  active: PropTypes.bool.isRequired,
  me: PropTypes.bool.isRequired,
  toggleStatus: PropTypes.func.isRequired,
};

export default ToggleStatusButton;
