export default {
  '@font-face': [
    {
      fontFamily: 'Giorgio Sans',
      fontWeight: 'bold',
      src: 'url("/fonts/GiorgioSans-Bold-Cy-App.ttf")',
    },
    {
      fontFamily: 'Giorgio Sans',
      fontWeight: 'bold',
      fontStyle: 'italic',
      src: 'url("/fonts/GiorgioSans-BoldItalic-Cy-App.ttf")',
    },
    {
      fontFamily: 'Graphik LCG',
      fontWeight: 'regular',
      src: 'url("/fonts/GraphikLCG-Regular.ttf")',
    },
  ],

  root: {
    position: 'relative',
    width: 241,
    minHeight: 489,

    '&:not($root_shortView, $bevel_longView) $content': {
      height: 370,
      // overflow: 'hidden',
    },
  },

  root_shortView: {
    minHeight: 360,
    height: 360,
    overflow: 'hidden',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background:
        'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
      left: 2,
      width: '100%',
      height: 45,
      bottom: 0,
    },
  },

  bevel: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  bevel_longView: {
    minHeight: 360,
    height: 360,

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background:
        'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
      left: 2,
      width: '100%',
      height: 45,
      bottom: 0,
    },
  },

  content: {
    position: 'relative',
    margin: [58, 17, 0],
    width: 208,
  },
};
