import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import FieldRow from '@/components/FieldRow';

import {
  EMAIL_FIELD,
  fieldNames,
  FULL_NAME_FIELD,
  PASSWORD_FIELD,
} from './consts';

const InviteForm = ({
  Form,
  Field,
  SubmitButton,
  error,
  RequiredMessages,
  isLoading,
  invite,
  classes,
}) => {
  const email = invite && invite.email;

  return (
    <Form>
      <FieldRow
        label="Email пользователя"
        subLabel="Электронный адрес пользователя"
        sudDesc="Данный электронный адрес используется в системе как логин пользователя. Его нельзя изменить."
      >
        <Field
          Target={TextField}
          id={EMAIL_FIELD}
          name={EMAIL_FIELD}
          label="Введите e-mail адрес"
          type="email"
          value={email || ''}
          disabled
          fullWidth
        />
      </FieldRow>
      <FieldRow
        label="ФИО"
        subLabel="Фамилия Имя Отчество пользователя"
        sudDesc="ФИО отображается внутри системы и для обращения к вам в интерфейсах. "
      >
        <Field
          Target={TextField}
          id={FULL_NAME_FIELD}
          name={FULL_NAME_FIELD}
          label="Введите ФИО пользователя"
          fullWidth
        />
      </FieldRow>
      <FieldRow
        label="Укажите новый пароль"
        subLabel="Пароль аккаунта"
        sudDesc="Пароль используется для входа в аккаунт. Вы можете сменить его на удобный для вас."
      >
        <Field
          Target={TextField}
          id={PASSWORD_FIELD}
          name={PASSWORD_FIELD}
          type="password"
          label="Введите пароль пользователя"
          fullWidth
        />
      </FieldRow>
      <FieldRow>
        <ButtonSet>
          <div className={classes.requiredMessagesWrapper}>
            <RequiredMessages fieldNames={fieldNames} />
          </div>
          <SubmitButton
            Target={AsyncButton}
            isLoading={isLoading}
            error={error}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            Сохранить
          </SubmitButton>
        </ButtonSet>
      </FieldRow>
    </Form>
  );
};

InviteForm.propTypes = {
  invite: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
  Form: PropTypes.func.isRequired,
  Field: PropTypes.func.isRequired,
  SubmitButton: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Error),
  RequiredMessages: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

InviteForm.defaultProps = {
  invite: null,
  isLoading: false,
  error: null,
};

export default InviteForm;
