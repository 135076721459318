import { RESOURCE_NAME as EFS_VISIT_RECORDS } from '@esentai/core/features/efsVisitRecords/consts';
import { RESOURCE_NAME as VALETS } from '@esentai/core/features/mallValets/consts';
import { RESOURCE_NAME as PARKING_LEVEl_THREE_WHITELIST } from '@esentai/core/features/parkingLevelThreeWhitelist/consts';
import { RESOURCE_NAME as PARKING_TOWER_STOP_LIST } from '@esentai/core/features/parkingTowerStopList/consts';
import { RESOURCE_NAME as PARKING_WHITELIST } from '@esentai/core/features/parkingWhitelist/consts';
import { RESOURCE_NAME as PARKING_WHITELIST_FILTER } from '@esentai/core/features/parkingWhitelistFilter/consts';
import { RESOURCE_NAME as PARKING_WHITELIST_GROUPS } from '@esentai/core/features/parkingWhitelistGroups/consts';
import {
  can,
  CREATE,
  DELETE,
  EDIT,
  permit,
  VIEW,
} from '@esentai/core/policy-dsl';

import { MODERATE } from '@/features/campaignsPage/consts/permissions';
import { MALL_VALET } from '@/features/mallValetPage/consts/permissions';
import { RESOURCE_NAME as PARKING } from '@/features/parkingPage/consts';

import { VIEW_NAVIGATION_BAR } from '../consts/permissions';

export default [
  can(VIEW_NAVIGATION_BAR),
  permit(EFS_VISIT_RECORDS, [VIEW]),
  permit(PARKING, [EDIT]),
  permit(PARKING_LEVEl_THREE_WHITELIST, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PARKING_TOWER_STOP_LIST, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PARKING_WHITELIST, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PARKING_WHITELIST_FILTER, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PARKING_WHITELIST_GROUPS, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(VALETS, [MALL_VALET]),
];
