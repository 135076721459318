import { ascending } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { ROLE_KEY } from '@/features/personnel/consts/keys';
import { annotations } from '@/features/personnel/serializer';
import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(ROLE_KEY),
  }),
  include: string({
    defaultValue: ['stores'],
  }),
});
