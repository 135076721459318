import { Typography, Zoom } from '@material-ui/core';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import labelFormatter from '@/utils/label-formatter';

import DefaultLabel from './Label';

const filedFormatter = count =>
  labelFormatter(count, {
    one: 'поле',
    few: 'поля',
    many: 'полей',
  });

const RequiredMessageList = ({
  fieldNames,
  Label,
  requiredErrors,
  classes,
  className,
  ...props
}) => {
  const errorsMap = Object.entries(requiredErrors);

  return (
    <Zoom in>
      <Typography
        color="textSecondary"
        className={cx(className, classes.requiredErrors)}
        {...props}
      >
        Заполните {filedFormatter(errorsMap.length)} :{' '}
        {errorsMap.map(([name], index) => (
          <Fragment key={name}>
            {Boolean(index) && ', '}
            <Label
              classes={classes}
              name={name}
              text={fieldNames[name] || name}
            />
          </Fragment>
        ))}
      </Typography>
    </Zoom>
  );
};

RequiredMessageList.defaultProps = {
  fieldNames: {},
  Label: DefaultLabel,
  className: null,
};

RequiredMessageList.propTypes = {
  requiredErrors: PropTypes.object.isRequired,
  fieldNames: PropTypes.object,
  Label: PropTypes.func,
  className: PropTypes.any,
};

export default RequiredMessageList;
