import {
  LEVEL_CHANGED,
  REWARD_BONUSES,
} from '@esentai/core/features/loyalties/consts/statuses';
import { path } from 'ramda';

import api from '@/api';
import { FEATURE_NAME } from '@/features/loyaltyHistoryPage/consts';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const downloadXls = () => {
  const urlParams = new URLSearchParams(window.location.search);
  // const limit = Number(urlParams.get('limit')) || 10;
  // const page = Number(urlParams.get('page'));
  // const offset = page * limit;

  // urlParams.set('limit', limit.toString());
  // urlParams.set('offset', offset.toString());
  urlParams.set('type[in]', `${REWARD_BONUSES},${LEVEL_CHANGED}`);

  // urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'id');
  }

  urlParams.delete('limit');
  urlParams.delete('offset');
  urlParams.delete('page');

  api
    .doBlobGet(
      `/customer-activity-history-xlsx-export?include=customer_id,commissar_id,user_id&${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(response.data, `Архив по бонусам/уровням.xlsx`);
    });
};
