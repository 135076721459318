import { createDateRangeFilter } from '@/filters/DateRange';
import { extendPageFilterTemplate } from '@/structs/pageFilter';

import BirthdayRangeFilter from './component';

export const createBirthdayRangeFilter = extendPageFilterTemplate(
  createDateRangeFilter,
  {
    FilterComponent: BirthdayRangeFilter,
  },
);

export default BirthdayRangeFilter;
