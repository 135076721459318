import {
  CATEGORY_KEY,
  PRICE_KEY,
  STORE_ID_KEY,
  SUBTITLE_RU_KEY,
  TITLE_RU_KEY,
} from '@esentai/core/features/products/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';

import { canUpdateProduct } from '../../permissions';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field="id">ID</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TITLE_RU_KEY}>Заголовок</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={SUBTITLE_RU_KEY}>Подзаголовок</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={STORE_ID_KEY}>Магазин</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Артикул</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={PRICE_KEY}>Цена товара</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={CATEGORY_KEY}>Категория</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Виды</CondenseTableCell>

    <CanView permission={canUpdateProduct}>
      <CondenseTableCell>Действия</CondenseTableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
