import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TopBar from '@/components/TopBar';
import CampaignTypeSelect from '@/features/campaignsStatisticsPage/Page/CampaignTypeSelect';

class InnerTopBar extends Component {
  render() {
    return <TopBar {...this.props} LeftComponent={this.renderLeftComponent} />;
  }

  renderLeftComponent = props => {
    const { LeftComponent } = this.props;

    return (
      <div>
        <LeftComponent {...props} />
        <CampaignTypeSelect />
      </div>
    );
  };
}

InnerTopBar.propTypes = {
  LeftComponent: PropTypes.func.isRequired,
};

InnerTopBar.defaultProps = {
  LeftComponent: () => null,
};

export default InnerTopBar;
