import { createAutocompleteFilterTemplate } from '@/filters/Autocomplete';

import CampaignFilter from './component';
import FormatComponent from './FormatComponent';

export const createCampaignFilter = createAutocompleteFilterTemplate({
  FilterComponent: CampaignFilter,
  FormatComponent,
});

export default CampaignFilter;
