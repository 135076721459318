import { findStoreById } from '@esentai/core/features/stores/actions';
import {
  getNameForStore,
  isStoreLoaded,
  isStoreLoading,
} from '@esentai/core/features/stores/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import StoreNameLazy from './component';

const mapStateToProps = (state, { storeId, onCutText, maxLength }) => ({
  isLoaded: isStoreLoaded(state, storeId),
  isLoading: isStoreLoading(state, storeId),
  itemId: storeId,
  title: getNameForStore(state, storeId),
  onCutText,
  maxLength,
});

const mapDispatchToProps = { fetchItem: findStoreById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(StoreNameLazy);
