import {
  BENEFITS_REWARD,
  LOYALTY_POINTS_REWARD,
} from '@esentai/core/features/campaigns/purchaseResult/consts/rewards';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import { reward as rewardLabel } from '@/features/campaignPage/labels';
import Label from '@/features/campaignPage/Page/Label';

import Benefits from './Benefits';
import Bonuses from './Bonuses';

const components = {
  [LOYALTY_POINTS_REWARD]: Bonuses,
  [BENEFITS_REWARD]: Benefits,
};

const Reward = ({ rewardType, itemId }) => {
  const Component = components[rewardType];

  if (isNil(rewardType)) {
    return null;
  }

  return (
    <FieldRow label="Награда">
      <Label title="Получение вознаграждения">
        При успешной активации кампании, пользователь получит вознаграждение{' '}
        <b>{rewardLabel(rewardType)}</b>:
        {Component && <Component itemId={itemId} />}
      </Label>
    </FieldRow>
  );
};

Reward.propTypes = {
  itemId: PropTypes.number.isRequired,
  rewardType: PropTypes.string,
};

Reward.defaultProps = {
  rewardType: null,
};

export default Reward;
