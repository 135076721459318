import {
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_KAZAKH_MESSAGE_KEY,
  PUSH_KAZAKH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/notification/consts/keys';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import Quadrolingual from '@/components/Quadrolingual';
import Preview from '@/features/campaignCreatePage/EditPage/SecondStepNotification/PushForm/Preview';
import Label from '@/features/campaignPage/Page/Label';

class Push extends Component {
  static propTypes = {
    pushEnglishMessage: PropTypes.string,
    pushEnglishTitle: PropTypes.string,
    pushRussianMessage: PropTypes.string,
    pushRussianTitle: PropTypes.string,
    pushKazakhMessage: PropTypes.string,
    pushKazakhTitle: PropTypes.string,
  };

  static defaultProps = {
    pushEnglishMessage: null,
    pushEnglishTitle: null,
    pushRussianMessage: null,
    pushRussianTitle: null,
    pushKazakhMessage: null,
    pushKazakhTitle: null,
  };

  renderPreview = () => {
    const {
      pushEnglishMessage,
      pushEnglishTitle,
      pushRussianMessage,
      pushRussianTitle,
      pushKazakhMessage,
      pushKazakhTitle,
    } = this.props;

    return (
      <Preview
        values={{
          [PUSH_RUSSIAN_TITLE_KEY]: pushRussianTitle,
          [PUSH_RUSSIAN_MESSAGE_KEY]: pushRussianMessage,

          [PUSH_ENGLISH_TITLE_KEY]: pushEnglishTitle,
          [PUSH_ENGLISH_MESSAGE_KEY]: pushEnglishMessage,

          [PUSH_KAZAKH_TITLE_KEY]: pushKazakhTitle,
          [PUSH_KAZAKH_MESSAGE_KEY]: pushKazakhMessage,
        }}
      />
    );
  };

  render() {
    const {
      pushEnglishMessage,
      pushEnglishTitle,
      pushRussianMessage,
      pushRussianTitle,
      pushKazakhMessage,
      pushKazakhTitle,
    } = this.props;

    return (
      <FieldRow label="Пуш-сообщение" Aside={this.renderPreview}>
        {Boolean(pushRussianTitle) && (
          <Label title="Заголовок сообщения">
            <Quadrolingual
              russianContent={pushRussianTitle}
              englishContent={pushEnglishTitle}
              kazakhContent={pushKazakhTitle}
            />
          </Label>
        )}
        {Boolean(pushRussianMessage) && (
          <Label title="Текст сообщения">
            <Quadrolingual
              russianContent={pushRussianMessage}
              englishContent={pushEnglishMessage}
              kazakhContent={pushKazakhMessage}
            />
          </Label>
        )}
      </FieldRow>
    );
  }
}

export default Push;
