import { Grid, TableRow, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import BookmarkCheckIcon from '@/components/Icon/BookmarkCheck';
import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import UserActivityToggler from '@/containers/UserActivityToggler';
import { ROUTE_PATH as USERS_PATH } from '@/features/usersPage/consts';
import { canUpdateUser } from '@/features/usersPage/permissions';
import { dateTimeFormat, formatMoney, formatNumber } from '@/utils/format';
import { userStatus } from '@/utils/labels';

const Row = ({
  classes,
  active,
  activeBonuses,
  deviceChangesCount,
  lastActivityDate,
  lastPurchaseDate,
  name,
  phone,
  receiptsCount,
  status,
  suspicious,
  moneySpent,
  userId,
  registrationDate,
}) => (
  <TableRow
    className={classNames({
      [classes.dimmed]: !active,
    })}
  >
    <CondenseTableCell>{userId}</CondenseTableCell>
    <CondenseTableCell>
      <Link to={`${USERS_PATH}/${userId}`}>{name || 'Аноним'}</Link>
      <Typography variant="caption">{phone}</Typography>
    </CondenseTableCell>
    <CondenseTableCell>{userStatus(status)}</CondenseTableCell>
    <CondenseTableCell>{formatMoney(moneySpent)}</CondenseTableCell>
    <CondenseTableCell>{formatNumber(receiptsCount)}</CondenseTableCell>
    <CondenseTableCell className={classes.secondaryColumn}>
      {lastPurchaseDate ? dateTimeFormat(lastPurchaseDate) : '–'}
    </CondenseTableCell>
    <CondenseTableCell>{formatNumber(activeBonuses)}</CondenseTableCell>
    <CondenseTableCell>{formatNumber(deviceChangesCount)}</CondenseTableCell>
    <CondenseTableCell>
      {suspicious && <BookmarkCheckIcon className={classes.suspiciousIcon} />}
    </CondenseTableCell>
    <CondenseTableCell>
      {registrationDate ? dateTimeFormat(registrationDate) : '-'}
    </CondenseTableCell>
    <CondenseTableCell>
      {lastActivityDate ? dateTimeFormat(lastActivityDate) : '–'}
    </CondenseTableCell>
    <CanView permission={canUpdateUser}>
      <CondenseTableCell>
        <Grid container wrap="nowrap" justify="flex-end">
          <UserActivityToggler userId={userId} />
        </Grid>
      </CondenseTableCell>
    </CanView>
  </TableRow>
);

Row.defaultProps = {
  name: '',
};

Row.propTypes = {
  active: PropTypes.bool.isRequired,
  activeBonuses: PropTypes.number.isRequired,
  deviceChangesCount: PropTypes.number.isRequired,
  lastActivityDate: PropTypes.instanceOf(Date).isRequired,
  lastPurchaseDate: PropTypes.instanceOf(Date).isRequired,
  name: PropTypes.string,
  phone: PropTypes.string.isRequired,
  receiptsCount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  suspicious: PropTypes.bool.isRequired,
  moneySpent: PropTypes.number.isRequired,
  userId: PropTypes.any.isRequired,
  registrationDate: PropTypes.instanceOf(Date).isRequired,
};

export default Row;
