import { getName } from '@esentai/core/features/brands/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';

const mapStateToProps = (state, { item: itemId }) => ({
  itemId,
  name: getName(state, itemId),
});

export default compose(connect(mapStateToProps))(Row);
