import {
  ADDITIONAL_ENGLISH_TEXT_KEY,
  ADDITIONAL_RUSSIAN_TEXT_KEY,
  ENGLISH_TITLE_KEY,
  EVENT_END_DATETIME_KEY,
  EVENT_START_DATETIME_KEY,
  IMAGE_URL_KEY,
  RUSSIAN_TITLE_KEY,
  TAGS_KEY,
} from '@esentai/core/features/campaigns/calendarEvent/consts/keys';
import React, { Component } from 'react';

import SecondStepForm from '@/features/campaignCreatePage/EditPage/SecondStepForm';
import FormikPropTypes from '@/prop-types/formik';

import ArticleForm from '../ArticleForm';
import ContentForm from './ContentForm';
import DateForm from './DateForm';
import TagsForm from './TagsForm';

const fieldNames = {
  [TAGS_KEY]: 'теги',
  [IMAGE_URL_KEY]: 'изображение',
  [RUSSIAN_TITLE_KEY]: 'заголовок на русском',
  [ENGLISH_TITLE_KEY]: 'заголовок на английском',
  [ADDITIONAL_RUSSIAN_TEXT_KEY]: 'дополнительный текст на русском',
  [ADDITIONAL_ENGLISH_TEXT_KEY]: 'дополнительный текст на английском',
  [EVENT_START_DATETIME_KEY]: 'дата и время начала',
  [EVENT_END_DATETIME_KEY]: 'дата и время завершения',
};

class SecondStepCalendarEvent extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { handleSubmit, SubmitButton, RequiredMessages } = this.props;

    return (
      <SecondStepForm
        fieldNames={fieldNames}
        handleSubmit={handleSubmit}
        SubmitButton={SubmitButton}
        RequiredMessages={RequiredMessages}
      >
        <DateForm {...this.props} />
        <TagsForm {...this.props} />
        <ContentForm {...this.props} />
        <ArticleForm {...this.props} />
      </SecondStepForm>
    );
  }
}

export default SecondStepCalendarEvent;
