import {
  doesBeaconHaveNoPosition,
  getBeaconMapXCoordinate,
  getBeaconMapYCoordinate,
  getBeaconMinorId,
} from '@esentai/core/features/beacons/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { moveBeacon, selectBeacon } from '@/features/mapPage/actions';
import { isBeaconDimmed, isBeaconSelected } from '@/features/mapPage/selectors';

import Beacon from './component';
import styles from './styles';

const mapStateToProps = (state, { beaconId }) => ({
  dimmed: isBeaconDimmed(state, beaconId),
  hasNoPosition: doesBeaconHaveNoPosition(state, beaconId),
  minorId: getBeaconMinorId(state, beaconId),
  x: getBeaconMapXCoordinate(state, beaconId),
  y: getBeaconMapYCoordinate(state, beaconId),
  selected: isBeaconSelected(state, beaconId),
});

const mapDispatchToProps = (dispatch, { beaconId, onMouseDown }) => ({
  onBeaconMove: (x, y) =>
    dispatch(
      moveBeacon({
        beaconId,
        x,
        y,
      }),
    ),
  onClick: () => dispatch(selectBeacon(beaconId)),
  onMouseDown: () => onMouseDown(beaconId),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onBeaconMove' }),
  injectSheet(styles),
)(Beacon);
