import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';

import Delay from '@/components/Delay';

const AppLoading = ({ classes }) => (
  <Delay wait={1000}>
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <CircularProgress size={80} thickness={1.5} />
      </Grid>
    </Grid>
  </Delay>
);

export default AppLoading;
