import { deleteParkingLevelThreeWhitelist } from '@esentai/core/features/parkingLevelThreeWhitelist/actions';
import { connect } from 'react-redux';

import { retryFetch } from '@/features/parkingLevelThreeWhitelistPage/actions';

import BulkActionsRow from './component';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, { selectedItems, deselectAll }) => ({
  deleteParkingLevelThreeWhitelist: () => {
    selectedItems.forEach(id => dispatch(deleteParkingLevelThreeWhitelist(id)));
    deselectAll();
    setTimeout(() => dispatch(retryFetch()), 400);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionsRow);
