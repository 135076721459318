import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

// import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import VisibilityButton from './VisibilityButton';

const Actions = ({ classes, giftSetId }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <EditButton giftSetId={giftSetId} />
    <VisibilityButton itemId={giftSetId} />
    {/* <DeleteButton productId={productId} /> */}
  </Grid>
);

Actions.propTypes = {
  giftSetId: PropTypes.number.isRequired,
};

export default Actions;
