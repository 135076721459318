import { NAME_KEY } from '@esentai/core/features/stores/consts/keys';
import { annotations } from '@esentai/core/features/stores/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(NAME_KEY),
  }),
  include: string({
    defaultValue: ['location', 'brands'],
  }),
});
