import {
  ENGLISH_TITLE_KEY,
  IMAGE_URL_KEY,
  KAZAKH_TITLE_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/banner/consts/keys';
import React, { Component } from 'react';

import SecondStepForm from '@/features/campaignCreatePage/EditPage/SecondStepForm';
import FormikPropTypes from '@/prop-types/formik';

import ContentForm from './ContentForm';
import DestinationForm from './DestinationForm';
import PlacementForm from './PlacementForm';

const fieldNames = {
  [ENGLISH_TITLE_KEY]: 'заголовок на английском',
  [RUSSIAN_TITLE_KEY]: 'заголовок на русском',
  [KAZAKH_TITLE_KEY]: 'заголовок на казахском',
  [IMAGE_URL_KEY]: 'изображение',
};

class SecondStepBanner extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { handleSubmit, RequiredMessages, SubmitButton } = this.props;

    return (
      <SecondStepForm
        fieldNames={fieldNames}
        handleSubmit={handleSubmit}
        SubmitButton={SubmitButton}
        RequiredMessages={RequiredMessages}
      >
        <PlacementForm {...this.props} />
        <ContentForm {...this.props} />
        <DestinationForm {...this.props} />
      </SecondStepForm>
    );
  }
}

export default SecondStepBanner;
