import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default WrappedComponent => {
  class WithSelection extends Component {
    static propTypes = {
      items: PropTypes.arrayOf(PropTypes.any).isRequired,
    };

    state = {
      selectedItems: [],
    };

    render() {
      const { selectedItems } = this.state;
      const isAnySelected = this.isAnySelected();
      const isAllSelected = this.isAllSelected();

      return (
        <WrappedComponent
          {...this.props}
          isAnySelected={isAnySelected}
          isAllSelected={isAllSelected}
          selectedItems={selectedItems}
          isSelected={this.isSelected}
          toggleAll={this.toggleAll}
          toggleItem={this.toggleItem}
          selectAll={this.selectAll}
          selectItem={this.selectItem}
          selectItems={this.selectItems}
          deselectAll={this.deselectAll}
          deselectItem={this.deselectItem}
          deselectItems={this.deselectItems}
        />
      );
    }

    isSelected = item => {
      const { selectedItems } = this.state;

      return selectedItems.includes(item);
    };

    isAnySelected = () => {
      const { items } = this.props;

      return items.some(this.isSelected);
    };

    isAllSelected = () => {
      const { items } = this.props;

      if (items.length === 0) {
        return false;
      }

      return items.every(this.isSelected);
    };

    toggleItem = item => {
      if (this.isSelected(item)) {
        this.deselectItem(item);
      } else {
        this.selectItem(item);
      }
    };

    toggleAll = () => {
      if (this.isAllSelected()) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    };

    selectItem = item => {
      this.selectItems([item]);
    };

    deselectItem = item => {
      this.deselectItems([item]);
    };

    selectItems = items => {
      const itemsToSelect = items.filter(item => !this.isSelected(item));
      const hasItemsToSelect = itemsToSelect.length;

      if (hasItemsToSelect) {
        this.setState(prevState => ({
          selectedItems: [...prevState.selectedItems, ...itemsToSelect],
        }));
      }
    };

    deselectItems = items => {
      const itemsToDeselect = items.filter(item => this.isSelected(item));
      const hasItemsToDeselect = itemsToDeselect.length;

      if (hasItemsToDeselect) {
        this.setState(prevState => ({
          selectedItems: prevState.selectedItems.filter(
            item => !itemsToDeselect.includes(item),
          ),
        }));
      }
    };

    selectAll = () => {
      const { items } = this.props;

      this.selectItems(items);
    };

    deselectAll = () => {
      const { items } = this.props;

      this.deselectItems(items);
    };
  }

  return WithSelection;
};
