import { cloneQuery } from '@esentai/core/queries';
import { call, select, takeLatest } from 'redux-saga/effects';

export default ({ actionNames, selectors }) => {
  const { getQuery } = selectors;
  const allQueryUpdates = [actionNames.mount, actionNames.sync];

  return (handlerSaga, types = allQueryUpdates) => {
    function* handleQueryChange() {
      const query = yield select(getQuery);

      yield call(handlerSaga, cloneQuery(query));
    }

    return function* watchQueryChange() {
      yield takeLatest(types, handleQueryChange);
    };
  };
};
