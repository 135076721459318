import { connect } from 'react-redux';

import { changeEntity, changeStats } from '@/features/statisticsPage/actions';
import { getEntityId, getStatsIds } from '@/features/statisticsPage/selectors';

import StatisticsTopBar from './component';

const mapStateToProps = state => ({
  entityId: getEntityId(state),
  entities: [
    {
      key: 'anonymous_purchases',
      label: 'Анонимные покупки',
    },
    {
      key: 'campaigns',
      label: 'Кампании',
    },
    {
      key: 'locations',
      label: 'Локации',
    },
    {
      key: 'purchases',
      label: 'Покупки',
    },
    {
      key: 'users',
      label: 'Пользователи',
    },
  ],
  statsIds: getStatsIds(state),
});

const mapDispatchToProps = {
  setEntityId: changeEntity,
  setStatsIds: changeStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTopBar);
