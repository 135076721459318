import { Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

class AddCarForm extends Component {
  render() {
    const { Form, Field, SubmitButton, RequiredMessages, onClose } = this.props;

    return (
      <Form>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Field
              Target={TextField}
              id="vehicle_gov_number"
              name="vehicle_gov_number"
              label="Гос. номер"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              rowsMax={4}
            />
          </Grid>
          <Grid item>
            <Field
              Target={TextField}
              id="vehicle_model"
              name="vehicle_model"
              label="Модель авто"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              rowsMax={4}
            />
          </Grid>
          <Grid item>
            <ButtonSet>
              <Button onClick={onClose} size="large">
                Отменить
              </Button>
              <SubmitButton
                Target={AsyncButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                Добавить
              </SubmitButton>
            </ButtonSet>
          </Grid>
          <Grid item>
            <RequiredMessages
              fieldNames={{
                vehicle_gov_number: 'Гос. номер',
                vehicle_model: 'Модель авто',
              }}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

AddCarForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default AddCarForm;
