import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';

import {
  CONFIRM_INVITE,
  CREATE_INVITE,
  CREATE_INVITE_AND_REDIRECT,
  FIND_INVITE,
} from './types';

export const createInvite = createTaskAction(CREATE_INVITE);

export const createInviteAndRedirect = createTaskAction(
  CREATE_INVITE_AND_REDIRECT,
);

export const findInvite = createTaskAction(FIND_INVITE);

export const confirmInvite = createTaskAction(CONFIRM_INVITE);
