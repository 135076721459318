import { defaultProps } from 'recompose';

import { createPageFilterTemplate } from '@/structs/pageFilter';
import { createEqualsAdapter } from '@/structs/pageFilterAdapter';

import RadioGroupFilter from './component';
import { createFormatter } from './utils';

export const createRadioFilterTemplate = ({ initialValue, options, ...rest }) =>
  createPageFilterTemplate({
    FilterComponent: defaultProps({ options })(RadioGroupFilter),
    FormatComponent: createFormatter(options),
    adapter: createEqualsAdapter(initialValue),
    quicklyAccessibleOptions: options,
    ...rest,
  });

export default RadioGroupFilter;
