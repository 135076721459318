import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import { createBrandPageUrl } from '@/features/brandPage/utils';

const BrandTitle = ({ classes, name, brandId }) => (
  <Link classes={classes} to={createBrandPageUrl(brandId)}>
    {name}
  </Link>
);

BrandTitle.propTypes = {
  name: PropTypes.string.isRequired,
  brandId: PropTypes.number.isRequired,
};

export default BrandTitle;
