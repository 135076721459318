import { array, boolean, object, string } from 'yup';

import { BAD_EMAIL } from '@/features/invites/consts/codes';
import {
  ACCESS_TO_STATISTIC_KEY,
  EMAIL_KEY,
  ROLE_KEY,
  STORE_IDS_KEY,
} from '@/features/invites/consts/keys';
import { STORE_SELLER_ROLE } from '@/features/personnel/consts/roles';

import { filterStoreAdminFields } from '../utils';

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

const formikOptions = {
  mapPropsToValues: ({ storeIds }) => ({
    [ACCESS_TO_STATISTIC_KEY]: false,
    [EMAIL_KEY]: '',
    [ROLE_KEY]: STORE_SELLER_ROLE,
    [STORE_IDS_KEY]: storeIds || [],
  }),

  validationSchema: object().shape({
    [ACCESS_TO_STATISTIC_KEY]: boolean(),
    [EMAIL_KEY]: string()
      .email()
      .required(),
    [ROLE_KEY]: string().required(),
    [STORE_IDS_KEY]: array()
      .min(1)
      .required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(filterStoreAdminFields(values));
    setSubmitting(false);
  },
};

const errorsMapping = {
  [BAD_EMAIL]: {
    field: EMAIL_KEY,
    message: 'Данный e-mail уже зарегистрирован в системе',
  },
};

export default { asyncOptions, formikOptions, errorsMapping };
