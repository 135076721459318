import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import {
  CLIENT_TIMESTAMP_KEY,
  CUSTOMER_EMAIL_KEY,
  CUSTOMER_FULL_NAME_KEY,
  CUSTOMER_ID_KEY,
  CUSTOMER_LEVEL_KEY,
  CUSTOMER_PHONE_KEY,
  CUSTOMER_STATE_KEY,
  GATE_ID_KEY,
  GATE_NAME_KEY,
  ID_KEY,
  RECORD_CREATED_KEY,
  VEHICLE_GOV_NUMBER_KEY,
} from '@/features/timiryazevParkingPage/consts/keys';

import { ROUTE_PATH } from '../../consts/index';

const HeadRow = ({ SortControl, ...props }) => {
  const [sortFields, setSortFields] = useState({
    [ID_KEY]: [{}],
    [GATE_ID_KEY]: [{}],
    [GATE_NAME_KEY]: [{}],
    [CUSTOMER_ID_KEY]: [{}],
    [CUSTOMER_FULL_NAME_KEY]: [{}],
    [CUSTOMER_LEVEL_KEY]: [{}],
    [CUSTOMER_STATE_KEY]: [{}],
    [CUSTOMER_EMAIL_KEY]: [{}],
    [CUSTOMER_PHONE_KEY]: [{}],
    [VEHICLE_GOV_NUMBER_KEY]: [{}],
    [CLIENT_TIMESTAMP_KEY]: [{}],
    [RECORD_CREATED_KEY]: [{}],
  });

  const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  const currentUrlParams = new URLSearchParams(window.location.search);

  const changeFilter = fieldName => {
    Object.keys(initialSortFields).forEach(field => {
      initialSortFields[field] = [{}];
    });

    const changedField = fieldName[0];

    initialSortFields[changedField.field] = [changedField];

    if (changedField.direction === 'asc') {
      currentUrlParams.set('sort', `${changedField.field},asc`);
    } else {
      currentUrlParams.set('sort', `${changedField.field},desc`);
    }

    props.history.push({
      pathname: `/${ROUTE_PATH}`,
      search: `?${decodeURIComponent(currentUrlParams.toString())}`,
    });

    setSortFields(initialSortFields);
  };

  useEffect(() => {
    const sortList = new URLSearchParams(window.location.search).getAll('sort');

    sortList.map(sort => {
      const sortElem = sort.split(',');

      const fieldName = sortElem[0];
      const fieldDirection = sortElem[1];

      initialSortFields[fieldName] = [
        {
          field: fieldName,
          direction: fieldDirection,
        },
      ];

      return sort;
    });
    setSortFields(initialSortFields);
  }, []);

  return (
    <TableRow>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ID_KEY]}
          field={ID_KEY}
          setSort={changeFilter}
        >
          ID
        </SortControl>
      </CondenseTableCell>

      <CondenseTableCell style={{ whiteSpace: 'nowrap' }}>
        <SortControl
          sort={sortFields[GATE_ID_KEY]}
          field={GATE_ID_KEY}
          setSort={changeFilter}
        >
          ID шлагбаума
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell style={{ whiteSpace: 'nowrap' }}>
        <SortControl
          sort={sortFields[GATE_NAME_KEY]}
          field={GATE_NAME_KEY}
          setSort={changeFilter}
        >
          Название шлагбаума
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell style={{ whiteSpace: 'nowrap' }}>
        <SortControl
          sort={sortFields[CUSTOMER_ID_KEY]}
          field={CUSTOMER_ID_KEY}
          setSort={changeFilter}
        >
          ID клиента
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell style={{ whiteSpace: 'nowrap' }}>
        Фамилия, имя
      </CondenseTableCell>
      <CondenseTableCell style={{ whiteSpace: 'nowrap' }}>
        <SortControl
          sort={sortFields[CUSTOMER_LEVEL_KEY]}
          field={CUSTOMER_LEVEL_KEY}
          setSort={changeFilter}
        >
          Уровень лояльности
        </SortControl>
      </CondenseTableCell>
      {/* <CondenseTableCell>*/}
      {/*  <SortControl*/}
      {/*    sort={sortFields[CUSTOMER_EMAIL_KEY]}*/}
      {/*    field={CUSTOMER_EMAIL_KEY}*/}
      {/*    setSort={changeFilter}*/}
      {/*  >*/}
      {/*    Почта*/}
      {/*  </SortControl>*/}
      {/* </CondenseTableCell>*/}
      <CondenseTableCell>
        <SortControl
          sort={sortFields[CUSTOMER_PHONE_KEY]}
          field={CUSTOMER_PHONE_KEY}
          setSort={changeFilter}
        >
          Телефон
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell style={{ whiteSpace: 'nowrap' }}>
        <SortControl
          sort={sortFields[VEHICLE_GOV_NUMBER_KEY]}
          field={VEHICLE_GOV_NUMBER_KEY}
          setSort={changeFilter}
        >
          Номер авто
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[RECORD_CREATED_KEY]}
          field={RECORD_CREATED_KEY}
          setSort={changeFilter}
        >
          Дата
        </SortControl>
      </CondenseTableCell>
    </TableRow>
  );
};

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default HeadRow;
