import PropTypes from 'prop-types';
import React from 'react';

const ReferenceItem = ({ classes, Component, ...props }) => (
  <div className={classes.root}>
    <Component
      {...props}
      classes={{ root: classes.wrapper }}
      SelectionControl={() => null}
    />
  </div>
);

ReferenceItem.propTypes = {
  Component: PropTypes.func.isRequired,
  destination: PropTypes.string,
};

ReferenceItem.defaultProps = {
  destination: null,
};

export default ReferenceItem;
