import MultiSelectFilter, {
  createMultiSelectFilterTemplate,
} from '@/filters/MultiSelect';

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const options = months.map((month, index) => ({
  key: String(index + 1),
  label: month,
  value: String(index + 1),
}));

export const createMonthFilter = createMultiSelectFilterTemplate({
  options,
  inputProps: { placeholder: 'Месяц рождения...' },
});

export default MultiSelectFilter;
