import { path } from 'ramda';

import { PAGES_STORE_KEY } from '@/redux/constants';

import { FEATURE_NAME, ROUTE_PATH } from './consts';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const createBonusDetailsPageUrl = itemId =>
  `${ROUTE_PATH.replace(':details_id', itemId)}`;

export const createResourceIdURL = id => ROUTE_PATH.replace(':details_id', id);
