const OFFSET_TOP = 10;
const OFFSET_LEFT = 10;

export default {
  root: {
    position: 'absolute',
    top: ({ pointerY }) => pointerY + OFFSET_TOP,
    left: ({ pointerX }) => pointerX + OFFSET_LEFT,
  },
};
