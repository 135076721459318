import { getSendSchedule } from '@esentai/core/features/campaigns/notification/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import SendSchedule from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  scheduleDateTime: getSendSchedule(state, itemId) || [],
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(SendSchedule);
