import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Variant from './Variant';

const Bilingual = ({ englishContent, russianContent, variant }) => (
  <Typography variant={variant}>
    <Variant content={russianContent} label="RU" />
    <Variant content={englishContent} label="EN" />
  </Typography>
);

Bilingual.propTypes = {
  englishContent: PropTypes.any.isRequired,
  russianContent: PropTypes.any.isRequired,
  variant: PropTypes.string,
};

Bilingual.defaultProps = {
  variant: 'h5',
};

export default Bilingual;
