import {
  FIRST_NAME_KEY,
  GENDER_KEY,
  ID_KEY,
  LAST_NAME_KEY,
  PHONE_KEY,
  RECORD_CREATED_KEY,
} from '@esentai/core/features/parkingLevelThreeWhitelist/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createGenderFilter } from '@/filters/Gender';
import { createTextFilter } from '@/filters/SearchInput';
import { createIncludeFilter } from '@/filters/SearchInputs';

export const filterTypes = [
  createIncludeFilter({
    key: ID_KEY,
    label: 'ID',
  }),
  createTextFilter({
    key: FIRST_NAME_KEY,
    label: 'Имя',
  }),
  createTextFilter({
    key: LAST_NAME_KEY,
    label: 'Фамилия',
  }),
  createTextFilter({
    key: PHONE_KEY,
    label: 'Номер телефона',
  }),
  createGenderFilter({
    key: GENDER_KEY,
    label: 'Пол пользователя',
  }),
  createDateRangeFilter({
    key: RECORD_CREATED_KEY,
    label: 'Дата добавления',
  }),
];
