import React, { Fragment } from 'react';

import Delay from './Delay';
import Destination from './Destination';
import Location from './Location';
import Push from './Push';
import Repetition from './Repetition';
import Reward from './Reward';

const ActionResultContent = props => (
  <Fragment>
    <Location {...props} />
    <Delay {...props} />
    <Repetition {...props} />
    <Push {...props} />
    <Destination {...props} />
    <Reward {...props} />
  </Fragment>
);

export default ActionResultContent;
