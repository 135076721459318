import { addMilliseconds } from 'date-fns';
import { union } from 'ramda';
import { handleActions } from 'redux-actions';

import { CANCELLATION_TIMEOUT } from './consts';
import {
  CANCEL_DEACTIVATE,
  DEACTIVATE_FAILUTE,
  DEACTIVATE_START,
  DEACTIVATE_SUCCESS,
  SCHEDULE_DEACTIVATE,
} from './types';

const defaultState = {
  error: null,
  ids: [],
  running: false,
  waiting: false,
};

export default handleActions(
  {
    [SCHEDULE_DEACTIVATE]: (state, { payload: ids }) => ({
      ...state,
      ids: union(state.ids, ids),
      waiting: true,
      waitUntil: addMilliseconds(new Date(), CANCELLATION_TIMEOUT),
    }),
    [CANCEL_DEACTIVATE]: state => ({
      ...state,
      ids: [],
      waiting: false,
    }),
    [DEACTIVATE_START]: state => ({
      ...state,
      running: true,
      waiting: false,
    }),
    [DEACTIVATE_FAILUTE]: (state, { payload: error }) => ({
      ...state,
      running: false,
      error,
    }),
    [DEACTIVATE_SUCCESS]: state => ({
      ...state,
      running: false,
      error: null,
      ids: [],
    }),
  },
  defaultState,
);
