import { RESOURCE_NAME } from '@esentai/core/features/parkingTowerStopList/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadParkingTowerStopListAdd = authorizeIf(
  canRead(RESOURCE_NAME),
);
export const canCreateParkingTowerStopListAdd = authorizeIf(
  canCreate(RESOURCE_NAME),
);
export const canUpdateParkingTowerStopListAdd = authorizeIf(
  canUpdate(RESOURCE_NAME),
);
