import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ActivateButton from '../../buttons/ActivateButton';
import ArchiveButton from '../../buttons/ArchiveButton';
import CloneButton from '../../buttons/CloneButton';
import EditButton from '../../buttons/EditButton';

const PausedStatusActions = ({ itemId }) => (
  <Fragment>
    <ActivateButton itemId={itemId} />
    <EditButton itemId={itemId} />
    <CloneButton itemId={itemId} />
    <ArchiveButton itemId={itemId} />
  </Fragment>
);

PausedStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default PausedStatusActions;
