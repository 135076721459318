import {
  CAMPAIGN_ID_KEY,
  CUSTOMER_AGE_KEY,
  CUSTOMER_AVERAGE_TICKET_KEY,
  CUSTOMER_BORN_AT_KEY,
  CUSTOMER_GENDER_KEY,
  CUSTOMER_LEVEL_KEY,
  CUSTOMER_RECEIPTS_NUMBER_KEY,
  CUSTOMER_STATE_KEY,
  LOCATION_ID_KEY,
} from '@/features/purchasesStatistics/consts/keys';
import { createBirthdayRangeFilter } from '@/filters/BirthdayRange';
import { createCampaignFilter } from '@/filters/Campaign';
import { createGenderFilter } from '@/filters/Gender';
import { createLocationFilter } from '@/filters/Location';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createRangeFilter } from '@/filters/Range';
import { createUserActivityStateFilter } from '@/filters/UserActivityState';
import { createUserAgeFilter } from '@/filters/UserAge';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createUserStatusFilter({
    key: CUSTOMER_LEVEL_KEY,
    label: 'Статус пользователя',
    quicklyAccessible: true,
  }),
  createGenderFilter({
    key: CUSTOMER_GENDER_KEY,
    label: 'Пол пользователя',
  }),
  createBirthdayRangeFilter({
    key: CUSTOMER_BORN_AT_KEY,
    label: 'Дата рождения пользователя',
  }),
  createUserAgeFilter({
    key: CUSTOMER_AGE_KEY,
    label: 'Возраст пользователя',
  }),
  createMoneyRangeFilter({
    key: CUSTOMER_AVERAGE_TICKET_KEY,
    label: 'Средний чек пользователя',
  }),
  createRangeFilter({
    key: CUSTOMER_RECEIPTS_NUMBER_KEY,
    label: 'Количество товаров в покупке',
  }),
  createCampaignFilter({
    key: CAMPAIGN_ID_KEY,
    label: 'Название кампании',
  }),
  createLocationFilter({
    key: LOCATION_ID_KEY,
    label: 'Название локации',
  }),
  createUserActivityStateFilter({
    key: CUSTOMER_STATE_KEY,
    label: 'Активность пользователя',
  }),
];
