import createDao from '@esentai/core/ducks/dao';
import { path, prop } from 'ramda';

import { ENTITIES_STORE_KEY } from '@/redux/constants';

import api from './api';
import { ENTITY_NAME, RESOURCE_NAME } from './consts';

export default createDao(RESOURCE_NAME, {
  api,
  getRoot: path([ENTITIES_STORE_KEY, ENTITY_NAME]),
  idProp: prop('token'),
});
