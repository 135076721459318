import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddLink from '@/components/AddLink';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';

import { ROUTE_PATH } from '../consts';
import Table from './Table';

class Campaigns extends Component {
  static propTypes = {
    error: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    itemsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, isLoading, itemsIds, totalCount } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <Grid container alignItems="baseline">
            <PageTitle gutterBottom={false}>Кампании</PageTitle>
          </Grid>
          <PageSubtitle>Вспомогательный текст</PageSubtitle>
        </PageHeader>
        <PageContent>
          <AddLink to={`${ROUTE_PATH}/new`} />
          <Table
            error={error}
            isLoading={isLoading}
            items={itemsIds}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default Campaigns;
