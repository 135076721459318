import {
  CASH_MACHINE_ID_KEY,
  HISTORY_ID_KEY,
} from '@esentai/core/features/reconciliationReceiptBonusDetails/consts/keys';

import api from '@/api';
import { saveAs } from '@/utils/file-saver';

export const downloadXls = (cmId, historyId, name) => {
  const urlParams = new URLSearchParams(window.location.search);
  const limit = Number(urlParams.get('limit')) || 10;
  const page = Number(urlParams.get('page'));
  const offset = page * limit;

  urlParams.set('limit', limit.toString());
  urlParams.set('offset', offset.toString());
  urlParams.set(`${CASH_MACHINE_ID_KEY}[eq]`, cmId);
  urlParams.set(`${HISTORY_ID_KEY}[eq]`, historyId);
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'id');
  }

  api
    .doBlobGet(
      `/store-bonus-active-receipt-details-xlsx-export?${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(response.data, `[${name}],Детализация по активным бонусам.xlsx`);
    });
};
