export default {
  root: {
    width: '420px',
  },

  title: {
    marginBottom: 20,
  },

  facts: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  fact: {
    flexBasis: '50%',
    marginBottom: 18,
  },
};
