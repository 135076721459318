import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  ARCHIVED_STATUS,
  MODERATING_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import PropTypes from 'prop-types';
import React from 'react';
import { defaultProps } from 'recompose';

import Message from './Message';

const ActiveStatusMessage = defaultProps({
  title: 'Активные кампании нельзя редактировать',
  subtitle: 'Вы можете поставить кампанию на паузу и отредактировать её.',
})(Message);

const ApprovedStatusMessage = defaultProps({
  title: 'Кампании, ожидающие старта, нельзя редактировать',
  subtitle: 'Вы можете создать копию этой кампании и редактировать её.',
})(Message);

const ArchivedStatusMessage = defaultProps({
  title: 'Архивированные кампании нельзя редактировать',
  subtitle: 'Вы можете создать копию этой кампании и редактировать её.',
})(Message);

const ModeratingStatusMessage = defaultProps({
  title: 'Модерируемые кампании нельзя редактировать',
  subtitle: 'Вы можете создать копию этой кампании и редактировать её.',
})(Message);

const components = {
  [ACTIVE_STATUS]: ActiveStatusMessage,
  [APPROVED_STATUS]: ApprovedStatusMessage,
  [ARCHIVED_STATUS]: ArchivedStatusMessage,
  [MODERATING_STATUS]: ModeratingStatusMessage,
};

const NonEditableMessage = ({ classes, status }) => {
  const MessageComponent = components[status];

  return (
    <div className={classes.root}>
      <MessageComponent />
    </div>
  );
};

NonEditableMessage.propTypes = {
  status: PropTypes.string.isRequired,
};

export default NonEditableMessage;
