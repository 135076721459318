import { PauseCircleOutline as PauseCircleOutlineIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';
import CanView from '@/containers/CanView';

const DeactivateButton = ({ permission, ...rest }) => (
  <CanView permission={permission}>
    <ActionButton
      text="Приостановить"
      button={AsyncButton}
      icon={PauseCircleOutlineIcon}
      {...rest}
    />
  </CanView>
);

DeactivateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.func.isRequired,
};

export default DeactivateButton;
