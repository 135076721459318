import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import OriginalCountdown from '@/components/Countdown';

const Countdown = ({ classes, waitUntil }) => (
  <div className={classes.root}>
    <CircularProgress size={50} classes={{ colorPrimary: classes.progress }} />
    <div className={classes.timer}>
      <OriginalCountdown to={waitUntil} />
    </div>
  </div>
);

Countdown.propTypes = {
  waitUntil: PropTypes.instanceOf(Date).isRequired,
};

export default Countdown;
