import { Link, TableCell, TableRow } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ReceiptModal from '../../ReceiptModal';

const Row = ({
  item,
  cashMachineFns,
  totalCost,
  saleNumber,
  issuedAt,
  saleProducts,
  cashMachine,
}) => {
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>{item}</TableCell>
        <TableCell>{cashMachineFns}</TableCell>
        <TableCell>
          <Link
            component="button"
            style={{ color: '#1e88e5' }}
            onClick={() => setIsReceiptDialogOpen(true)}
          >
            {saleNumber}
          </Link>
        </TableCell>

        <TableCell>{totalCost}</TableCell>
        <TableCell>
          {issuedAt ? format(new Date(issuedAt), 'dd-MM-yyyy HH:mm:ss') : '-'}
        </TableCell>
      </TableRow>
      {isReceiptDialogOpen && (
        <ReceiptModal
          id={item}
          saleNumber={saleNumber}
          isOpen={isReceiptDialogOpen}
          onClose={() => setIsReceiptDialogOpen(false)}
          totalCost={totalCost}
          cashMachineFns={cashMachineFns}
          issuedAt={issuedAt}
          saleProducts={saleProducts}
          cashMachine={cashMachine}
        />
      )}
    </>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
  cashMachineFns: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  saleNumber: PropTypes.number.isRequired,
  issuedAt: PropTypes.string.isRequired,
  saleProducts: PropTypes.array.isRequired,
  cashMachine: PropTypes.number.isRequired,
};

export default Row;
