import { compose } from 'ramda';
import { connect } from 'react-redux';

import { resetPassword } from '@/features/authPages/actions';

import ResetPasswordPage from './component';

const mapDispatchToProps = (dispatch, { location }) => {
  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  return {
    resetPassword: ({ password }) =>
      dispatch(resetPassword({ password, token })),
  };
};

export default compose(connect(null, mapDispatchToProps))(ResetPasswordPage);
