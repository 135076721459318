import {
  REPAY,
  RESOURCE_NAME,
} from '@esentai/core/features/reconciliation/consts';
import {
  can,
  canCreate,
  canRead,
  canUpdate,
  permission,
} from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadReconciliation = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateReconciliation = authorizeIf(canCreate(RESOURCE_NAME));
export const canUpdateReconciliation = authorizeIf(canUpdate(RESOURCE_NAME));
export const canRepayReconciliation = authorizeIf(
  can(permission(RESOURCE_NAME, REPAY)),
);
