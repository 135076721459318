import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';

import styles from '@/features/authPages/styles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import DeleteCarForm from './component';

const errorsMapping = {};
const formikOptions = {
  validationSchema: {},
  handleSubmit(
    { vehicle_gov_number, vehicle_model },
    { props, setSubmitting },
  ) {
    props.onSubmit({ vehicle_gov_number, vehicle_model });
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(DeleteCarForm);
