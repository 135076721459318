import { FEATURE_NAME } from './consts';
import query from './ducks/query';

export const { mount: MOUNT } = query.actionNames;

export const OPEN_RENT_PLACE = `${FEATURE_NAME}/OPEN_RENT_PLACE`;

export const RETRY_FETCH = `${FEATURE_NAME}/RETRY_FETCH`;

export const UPDATE_FLOOR = `${FEATURE_NAME}/UPDATE_FLOOR`;

export const UPDATE_STAT = `${FEATURE_NAME}/UPDATE_STAT`;
