import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getExpanded } from '@/features/auth/selectors';
import {
  getUserStores,
  isEfsSales,
  isMallAccountant,
  isMallAdministrator,
  isMallManager,
  isMallReception,
  isMallValet,
  isManagingTeamMember,
  isStoreAdministrator,
  isStoreManager,
  isStoreSeller,
  isStoreStaff,
} from '@/features/currentUser/selectors';

import Root from './component';
import styles from './styles';
import { getSections } from './utils';

const mapStateToProps = state => ({
  sections: getSections(
    isMallManager(state),
    isMallAdministrator(state),
    isStoreStaff(state),
    isStoreManager(state),
    isStoreAdministrator(state),
    isStoreSeller(state),
    isMallAccountant(state),
    isMallValet(state),
    isEfsSales(state),
    isMallReception(state),
    isManagingTeamMember(state),
    getUserStores(state),
  ),
  expanded: getExpanded(state),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Root);
