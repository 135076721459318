import { grey } from '@material-ui/core/colors';

export default ({ palette, spacing }) => ({
  row: {
    borderTop: [1, 'solid', grey[300]],
    paddingTop: spacing.unit * 2,
    marginTop: spacing.unit * 2,
    minHeight: spacing.unit * 45,
  },

  description: {
    color: palette.logo.white.esentaiColor,
    maxWidth: 500,
  },

  email: {
    fontWeight: 'normal',
    margin: [spacing.unit, 0],
  },

  emailLabel: {
    color: '#8a8a8a',
  },

  button: {
    margin: [spacing.unit * 5, 0, spacing.unit, 0],
  },
});
