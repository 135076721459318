import {
  CAMPAIGN_ID_URL_SECTION,
  CAMPAIGN_TYPE_URL_SECTION,
  ROUTE_PATH,
} from './consts';

export const createCampaignURL = (type, id) =>
  ROUTE_PATH.replace(CAMPAIGN_TYPE_URL_SECTION, type).replace(
    CAMPAIGN_ID_URL_SECTION,
    id,
  );
