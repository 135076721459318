export default {
  label: {
    padding: [0, 2],
    whiteSpace: 'nowrap',
    textAlign: 'left',
    fontSize: 9,
    color: '#b0b0b0',
  },

  bar: {
    padding: [3, 2],
    textAlign: 'center',
    backgroundColor: 'black',
    whiteSpace: 'nowrap',
  },
};
