import { Button as MuiButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import strategies from './strategies';

const Button = ({ classes, granted, isLoading, error, giveOut }) => {
  const { Icon, variant, text } = strategies({
    granted,
    isLoading,
    error,
  });

  return (
    <MuiButton
      color="primary"
      variant={variant}
      className={classes.root}
      onClick={giveOut}
    >
      <Typography color="inherit" variant="h4" className={classes.text}>
        <Icon fontSize="large" />
        {text}
      </Typography>
    </MuiButton>
  );
};

Button.propTypes = {
  granted: PropTypes.bool.isRequired,
  giveOut: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

Button.defaultProps = {
  isLoading: false,
  error: null,
};

export default Button;
