import { deleteParkingTowerStopList } from '@esentai/core/features/parkingTowerStopList/actions';
import { T } from 'ramda';
import { connect } from 'react-redux';

import RemoveButton from '@/components/ActionButtons/Remove';
import { retryFetch } from '@/features/parkingTowerStopListPage/actions';

const mapDispatchToProps = (dispatch, { parkingTowerStopListId }) => ({
  onClick: () => {
    dispatch(deleteParkingTowerStopList(parkingTowerStopListId));
    setTimeout(() => dispatch(retryFetch()), 400);
  },
  permission: T,
});

export default connect(null, mapDispatchToProps)(RemoveButton);
