import {
  BONUSES_REFUNDED_KEY,
  BONUSES_SPENT_KEY,
  END_DATE_KEY,
  START_DATE_KEY,
  TOTAL_TO_PAY_KEY,
} from '@esentai/core/features/merchantsReconciliationHistory/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={TOTAL_TO_PAY_KEY}>Сумма к оплате</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={BONUSES_SPENT_KEY}>
        Сумма потраченных бонусов
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={BONUSES_REFUNDED_KEY}>
        Сумма бонусов на возврат
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={START_DATE_KEY}>Период реконсиляции</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Реестр</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={END_DATE_KEY}>Дата и время реконсиляции</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Пользователь</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
