export const EMAIL_FIELD = 'email';

export const FULL_NAME_FIELD = 'fullName';

export const PASSWORD_FIELD = 'password';

export const fieldNames = {
  [FULL_NAME_FIELD]: 'Полное имя',
  [PASSWORD_FIELD]: 'Пароль',
};
