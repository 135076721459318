export default {
  radioBox: {
    backgroundColor: ({ highlighted, selected }) => {
      if (highlighted && selected) {
        return '#1E88E5';
      } else if (highlighted) {
        return '#7EB3F1';
      } else if (selected) {
        return '#eee';
      }

      return 'white';
    },
  },

  control: {
    '&$control': {
      color: ({ highlighted }) => (highlighted ? 'white' : '#616161'),
    },
  },

  content: {
    color: ({ highlighted }) => (highlighted ? 'white' : 'inherit'),
  },

  icon: {
    color: ({ highlighted }) => (highlighted ? 'white' : '#BDBDBD'),
  },
};
