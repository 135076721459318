// import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

// import CloneButton from '../../buttons/CloneButton';
// import StatisticButton from '../../buttons/StatisticButton';

const ArchivedStatusActions = () => (
  <Fragment>
    {/* <CloneButton itemId={itemId} />
    <StatisticButton itemId={itemId} /> */}
  </Fragment>
);

// ArchivedStatusActions.propTypes = {
//   itemId: PropTypes.string.isRequired,
// };

export default ArchivedStatusActions;
