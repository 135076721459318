import {
  FINISH_POST_URL,
  POST_URL,
} from '@esentai/core/features/reconciliationStoreBonusDetails/consts';
import {
  FINISH,
  TO_INVOICE,
  TO_PROCESSING,
} from '@esentai/core/features/reconciliationStoreBonusDetails/consts/actionTypes';
import { getPayload } from '@esentai/core/features/reconciliationStoreBonusDetails/utils';
import { TableCell, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import { createBonusDetailsPageUrl } from '@/features/reconciliationPeriodBonusDetailsPage/utils';
import {
  dateTimeFormatNoSecs,
  formatMoney,
  formatNumber,
} from '@/utils/format';

import { canRepayReconciliation } from '../../permissions';
import ConfirmationDialog from './ConfirmationDialog/ConfirmationDialog';

const Row = ({
  classes,
  totalToPayAmount,
  endDatetime,
  startDatetime,
  reconciliationId,
  paidAmount,
  processingAmount,
  invoicedAmount,
  percent,
  bonusName,
  fetchItems,
}) => {
  const [isRepayDialogOpen, setIsRepayDialogOpen] = useState(false);
  const [activeAction, setAction] = useState('');

  const handleRepayModal = () => {
    setIsRepayDialogOpen(!isRepayDialogOpen);
  };

  const setCurrentAction = action => {
    setAction(action);
    handleRepayModal();
  };

  return (
    <>
      <TableRow>
        {/* <TableCell>{storeName}</TableCell> */}
        <TableCell>
          <Link to={createBonusDetailsPageUrl(reconciliationId)}>
            {bonusName}
          </Link>
        </TableCell>
        <TableCell>
          {startDatetime && endDatetime ? (
            <span>
              {dateTimeFormatNoSecs(startDatetime)}-
              {dateTimeFormatNoSecs(endDatetime)}
            </span>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell>{formatNumber(percent)} %</TableCell>
        <TableCell>{formatMoney(totalToPayAmount)}</TableCell>
        <TableCell>{formatMoney(processingAmount)}</TableCell>
        <TableCell>{formatMoney(invoicedAmount)}</TableCell>
        <TableCell align="center">{formatMoney(paidAmount)}</TableCell>
        <CanView permission={canRepayReconciliation}>
          <TableCell align="center">
            <Button
              className={classes.linkButton}
              onClick={() => setCurrentAction(TO_PROCESSING)}
              disabled={totalToPayAmount <= 0}
              style={{ marginRight: '10px' }}
            >
              В обработку
            </Button>
            <Button
              className={classes.linkButton}
              onClick={() => setCurrentAction(TO_INVOICE)}
              disabled={processingAmount <= 0}
              style={{ marginRight: '10px' }}
            >
              Выставить
            </Button>
            <Button
              className={classes.linkButton}
              onClick={() => setCurrentAction(FINISH)}
              disabled={invoicedAmount <= 0}
            >
              Завершить
            </Button>
          </TableCell>
        </CanView>
      </TableRow>
      {isRepayDialogOpen && (
        <ConfirmationDialog
          isOpen={isRepayDialogOpen}
          onClose={handleRepayModal}
          itemId={reconciliationId}
          fetchItems={fetchItems}
          url={activeAction === FINISH ? FINISH_POST_URL : POST_URL}
          payload={getPayload(activeAction, reconciliationId)}
          totalToPayAmount={totalToPayAmount}
          processingAmount={processingAmount}
          invoicedAmount={invoicedAmount}
          action={activeAction}
        />
      )}
    </>
  );
};

Row.propTypes = {
  totalToPayAmount: PropTypes.number.isRequired,
  endDatetime: PropTypes.instanceOf(Date),
  startDatetime: PropTypes.instanceOf(Date),
  paidAmount: PropTypes.number.isRequired,
  processingAmount: PropTypes.number.isRequired,
  invoicedAmount: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  reconciliationId: PropTypes.number.isRequired,
  fetchItems: PropTypes.func.isRequired,
  bonusName: PropTypes.string.isRequired,
};

Row.defaultProps = {
  endDatetime: '-',
  startDatetime: '-',
};

export default Row;
