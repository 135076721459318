import {
  getLocationIdForStore,
  isStoreActive,
} from '@esentai/core/features/stores/selectors';

import { getLocationMapPageUrl } from '@/features/mapPage/selectors';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getStoresError,
  getItemsIds: getStoresIds,
  getItemsMeta: getStoresMeta,
  getItemsTotalCount: getStoresTotalCount,
  isLoadingItems: isLoadingStores,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;

export const isStoresActive = (state, ids) =>
  ids.some(id => isStoreActive(state, id));

export const isStoresInactive = (state, ids) =>
  ids.some(id => !isStoreActive(state, id));

export const getStoreMapPageUrl = (state, storeId) => {
  const locationId = getLocationIdForStore(state, storeId);

  return getLocationMapPageUrl(state, locationId);
};
