import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const StatusTooltipIndicator = ({ itemId, StatusIndicator, StatusLabel }) => (
  <Tooltip title={<StatusLabel itemId={itemId} />}>
    <span>
      <StatusIndicator itemId={itemId} />{' '}
    </span>
  </Tooltip>
);

StatusTooltipIndicator.propTypes = {
  itemId: PropTypes.number.isRequired,
  StatusIndicator: PropTypes.func.isRequired,
  StatusLabel: PropTypes.func.isRequired,
};

export default StatusTooltipIndicator;
