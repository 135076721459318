import { Children, cloneElement } from 'react';

const CanEdit = ({ canEdit, children }) =>
  Children.map(children, child =>
    child
      ? cloneElement(child, {
          disabled: !canEdit,
        })
      : null,
  );

export default CanEdit;
