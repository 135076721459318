import {
  BONUS_STATUS,
  MIXED_STATUS,
  MONEY_STATUS,
  PROCESSING_STATUS,
} from '@esentai/core/features/purchases/consts/statuses';

import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';
import { purchaseStatus } from '@/utils/labels';

export const options = [
  PROCESSING_STATUS,
  MONEY_STATUS,
  BONUS_STATUS,
  MIXED_STATUS,
].map(key => ({
  key,
  label: purchaseStatus(key),
}));

export const createPurchaseStatusFilter = createRadioFilterTemplate({
  options,
});

export default RadioGroupFilter;
