import { Grid } from '@material-ui/core';
import React from 'react';

import PageContent from '@/components/PageContent';

import CampaignFunnel from '../CampaignFunnel';
import CampaignInvolvement from '../CampaignInvolvement';
import StatusDistributionChart from '../StatusDistributionChart';

const PrimaryDetails = ({ classes }) => (
  <Grid container justify="center" className={classes.wrapper}>
    <Grid item sm={10} justify="center">
      <PageContent>
        <CampaignFunnel />
        <div className={classes.charts}>
          <StatusDistributionChart />
          <CampaignInvolvement />
        </div>
      </PageContent>
    </Grid>
  </Grid>
);

export default PrimaryDetails;
