import { BUTTON_PLACEMENT_KEY } from '@esentai/core/features/applicationSettings/homeLayout/consts/payloadKeys';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import Select from '@/components/Select';

const placements = [
  {
    id: 'top',
    label: 'Первый блок кнопки',
  },
  {
    id: 'bottom',
    label: 'Второй блок кнопки',
  },
];

class Button extends Component {
  static propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    Field: PropTypes.func.isRequired,
  };

  handleChange = placement => {
    const { setFieldValue } = this.props;

    setFieldValue(BUTTON_PLACEMENT_KEY, placement);
  };

  render() {
    const { Field } = this.props;

    return (
      <div>
        <FieldDescription title="Название блока" />
        <Field
          Target={Select}
          name={BUTTON_PLACEMENT_KEY}
          onChange={this.handleChange}
          options={placements}
        />
      </div>
    );
  }
}

export default Button;
