import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getMallStatError,
  getItemsIds: getMallStatIds,
  getItemsMeta: getMallStatsMeta,
  getItemsTotalCount: getMallStatsTotalCount,
  isLoadingItems: isLoadingMallStats,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
