import {
  PLACEMENT_KEY,
  PRIORITY_KEY,
} from '@esentai/core/features/campaigns/show/consts/keys';
import {
  ALL_FEED,
  MAIN_FEED,
  PSS_FEED,
} from '@esentai/core/features/campaigns/show/consts/popupPlacementTypes';
import React, { Component, Fragment } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import FormikPropTypes from '@/prop-types/formik';

import { CanEditRadioBoxGroup } from '../../CanEdit';
import PriorityControl from '../PriorityControl';

class PlacementForm extends Component {
  static propTypes = FormikPropTypes;

  renderPriorityControl() {
    const { handleChange, values } = this.props;

    return (
      <div>
        <FieldDescription
          title="Приоритет расположения"
          desc="Рекламное размещение с более высоким уровнем приоритета размещается первее в блоке витрин. Размещения с одинаковым приоритетом размещаются друг за другом в хронологическом порядке."
        />
        <PriorityControl
          name={PRIORITY_KEY}
          value={values[PRIORITY_KEY]}
          onChange={handleChange}
        />
      </div>
    );
  }

  renderPlacementControl() {
    const { handleChange, values } = this.props;

    return (
      <FieldRow
        label="Точка показа"
        subLabel="Место и время активации"
        sudDesc="Выберите место в приложении, в котором будет появляться витрина."
      >
        <CanEditRadioBoxGroup field={PLACEMENT_KEY}>
          <RadioBoxGroup
            name={PLACEMENT_KEY}
            value={values[PLACEMENT_KEY]}
            onChange={handleChange}
          >
            <RadioBox value={ALL_FEED} label="Все страницы" />
            <RadioBox value={MAIN_FEED} label="Главная страница" />
            <RadioBox value={PSS_FEED} label="Онлайн витрина" />
          </RadioBoxGroup>
        </CanEditRadioBoxGroup>
      </FieldRow>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderPlacementControl()}
        {this.renderPriorityControl()}
      </Fragment>
    );
  }
}

export default PlacementForm;
