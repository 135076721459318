import { Button, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Modal from '@/components/Modal';

const ConfirmModal = ({
  classes,
  cancelLabel,
  confirmLabel,
  description,
  onClose,
  onConfirm,
  title,
  ...rest
}) => {
  const onSubmitButtonClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal {...rest} onClose={onClose}>
      <Paper className={classes.wrapper}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {description}
        </Typography>

        <div className={classes.footer}>
          <Button onClick={onClose}>{cancelLabel}</Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            onClick={onSubmitButtonClick}
          >
            {confirmLabel}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmModal;
