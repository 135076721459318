import { Button, IconButton, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

class ActionButton extends React.Component {
  static propTypes = {
    button: PropTypes.func.isRequired,
    small: PropTypes.bool,
    text: PropTypes.string,
    icon: PropTypes.func.isRequired,
  };

  render() {
    const { small } = this.props;

    return small ? this.renderContentWithTooltip() : this.renderContent();
  }

  renderContentWithTooltip() {
    const { text } = this.props;

    return <Tooltip title={text}>{this.renderContent()}</Tooltip>;
  }

  renderContent() {
    const { small } = this.props;

    return small ? this.renderSmallButton() : this.renderRegularButton();
  }

  renderSmallButton() {
    const { button: Component, classes, icon: Icon, ...rest } = this.props;

    return (
      <Component
        ButtonComponent={IconButton}
        component={IconButton}
        className={classes.smallRoot}
        {...rest}
      >
        <Icon />
      </Component>
    );
  }

  renderRegularButton() {
    const {
      classes,
      button: Component,
      text,
      icon: Icon,
      ...rest
    } = this.props;

    return (
      <Component
        ButtonComponent={Button}
        component={Button}
        className={classes.root}
        {...rest}
      >
        {text}
        <Icon className={classes.icons} />
      </Component>
    );
  }
}

ActionButton.defaultProps = {
  small: true,
  text: null,
};

export default ActionButton;
