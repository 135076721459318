export const ROUTE_PATH = '/store-archive-reconciliations';

export const FEATURE_NAME = 'reconciliationArchivePage';

export const POST_URL = '/reconciliation/';

export const PATCH_URL = '/reconciliation-history/';

export const GET_URL = '/store-reconciliations/';

export const REPAY_CONFIRMATION_MSG =
  'Вы уверены, что хотите завершить данную реконсиляцию?';
