import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  SHOW_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/banner/consts/destinations';
import PropTypes from 'prop-types';
import React from 'react';

import ArticleReference from './ArticleReference';
import BenefitReference from './BenefitReference';
import CalendarEventReference from './CalendarEventReference';
import ReferenceItem from './ReferenceItem';
import ShowReference from './ShowReference';
import StoreReference from './StoreReference';

const components = {
  [ARTICLE_DESTINATION]: ArticleReference,
  [BENEFIT_DESTINATION]: BenefitReference,
  [CALENDAR_EVENT_DESTINATION]: CalendarEventReference,
  [STORE_DESTINATION]: StoreReference,
  [SHOW_DESTINATION]: ShowReference,
};

const Reference = ({ destination, ...props }) => {
  const Component = components[destination];

  if (Component) {
    return (
      <ReferenceItem Component={Component} type={destination} {...props} />
    );
  }

  return null;
};

Reference.propTypes = {
  destination: PropTypes.string,
};

Reference.defaultProps = {
  destination: null,
};

export default Reference;
