import { createRangeFilterTemplate } from '@/filters/Range';
import { createFormatter } from '@/filters/Range/utils';
import { formatTime } from '@/utils/format';

import TimeRangeFilter from './component';

export const createTimeRangeFilter = createRangeFilterTemplate({
  FilterComponent: TimeRangeFilter,
  FormatComponent: createFormatter({ format: formatTime }),
});

export default TimeRangeFilter;
