import { compose } from 'ramda';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import FloorOption from '@/components/MapWorkspace/FloorOption';
import { selectFloor } from '@/features/mapPage/actions';
import {
  getFloorStatisticsPageUrl,
  isFloorHighlighted,
  isSelectedFloor,
} from '@/features/mapPage/selectors';

import styles from './styles';

const mapStateToProps = (state, { floor }) => ({
  highlighted: isFloorHighlighted(state, floor),
  selected: isSelectedFloor(state, floor),
  statisticsUrl: getFloorStatisticsPageUrl(state, floor),
});

const mapDispatchToProps = { updateFloor: selectFloor };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectSheet(styles),
)(FloorOption);
