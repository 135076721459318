export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  button: {
    display: 'inline-flex',
    cursor: 'pointer',
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  fileInput: {
    position: 'absolute',
    width: 1,
    height: 1,
    opacity: 0,
  },
});
