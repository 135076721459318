import {
  BONUS_NAME_KEY,
  COMMENT_KEY,
  END_DATE_KEY,
  PERCENT_KEY,
  START_DATE_KEY,
  STORES_KEY,
} from '@esentai/core/features/mallBonuses/consts/keys';
import { Grid } from '@material-ui/core';
import React from 'react';

import ButtonCancel from '@/components/ActionButtons/Cancel';
import AsyncButton from '@/components/AsyncButton';
import FieldRow from '@/components/FieldRow';
import FormikPropTypes from '@/prop-types/formik';

import DescriptionForm from './DescriptionForm';

const fieldNames = {
  [START_DATE_KEY]: 'начало действия',
  [END_DATE_KEY]: 'окончание действия',
  [COMMENT_KEY]: 'комментарии',
  [STORES_KEY]: 'магазин',
  [PERCENT_KEY]: 'процент',
  [BONUS_NAME_KEY]: 'название акции',
};

const FirstStep = props => {
  const { classes, Form, RequiredMessages, SubmitButton, handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <DescriptionForm {...props} />

      <FieldRow>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <ButtonCancel className={classes.cancelButton}>Назад</ButtonCancel>
            <SubmitButton
              Target={AsyncButton}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
            >
              Далее
            </SubmitButton>
          </Grid>
          <Grid item>
            <RequiredMessages fieldNames={fieldNames} />
          </Grid>
        </Grid>
      </FieldRow>
    </Form>
  );
};

FirstStep.propTypes = FormikPropTypes;

export default FirstStep;
