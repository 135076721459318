import { ALLOWED_LOCATION_IDS_KEY } from '@esentai/core/features/users/consts/keys';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import { LocationMultiAutocomplete } from '@/containers/LocationAutocomplete';

class LocationsField extends Component {
  static propTypes = {
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const { handleBlur, values, classes } = this.props;

    return (
      <FieldRow
        label="Доступ к локациям"
        subLabel="Локации администратора"
        className={classes.row}
      >
        <LocationMultiAutocomplete
          id={ALLOWED_LOCATION_IDS_KEY}
          name={ALLOWED_LOCATION_IDS_KEY}
          onChange={this.handleLocationChange}
          onBlur={handleBlur}
          selectedItem={values[ALLOWED_LOCATION_IDS_KEY]}
          InputProps={{
            placeholder: 'Добавьте локацию',
          }}
        />
      </FieldRow>
    );
  }

  handleLocationChange = locationId =>
    this.props.setFieldValue(ALLOWED_LOCATION_IDS_KEY, locationId);
}

export default LocationsField;
