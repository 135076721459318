import createItemsDuck from '@esentai/core/ducks/items';

import api from '../api';
import { getItemsRoot } from '../utils';

const createDuck = createItemsDuck('reconciliation_history', {
  api,
  getRoot: getItemsRoot,
});

export default createDuck;
