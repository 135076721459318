import FirstFloorParking from '@esentai/core/components/Map/FirstFloorParking';
import SecondFloorParking from '@esentai/core/components/Map/SecondFloorParking';
import ThirdFloorParking from '@esentai/core/components/Map/ThirdFloorParking';
import {
  FIFTH_FLOOR_ID,
  FIRST_FLOOR_ID,
  FIRST_PARKING_FLOOR_ID,
  FOURTH_FLOOR_ID,
  MINUS_FIRST_FLOOR_ID,
  SECOND_FLOOR_ID,
  SECOND_PARKING_FLOOR_ID,
  THIRD_FLOOR_ID,
  THIRD_PARKING_FLOOR_ID,
} from '@esentai/core/features/beacons/consts/floors';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FifthFloor from './FifthFloor';
import FirstFloor from './FirstFloor';
import FourthFloor from './FourthFloor';
import MinusFirstFloor from './MinusFirstFloor';
import SecondFloor from './SecondFloor';
import ThirdFloor from './ThirdFloor';

const components = {
  [MINUS_FIRST_FLOOR_ID]: MinusFirstFloor,
  [FIRST_FLOOR_ID]: FirstFloor,
  [SECOND_FLOOR_ID]: SecondFloor,
  [THIRD_FLOOR_ID]: ThirdFloor,
  [FOURTH_FLOOR_ID]: FourthFloor,
  [FIFTH_FLOOR_ID]: FifthFloor,
  [THIRD_PARKING_FLOOR_ID]: ThirdFloorParking,
  [SECOND_PARKING_FLOOR_ID]: SecondFloorParking,
  [FIRST_PARKING_FLOOR_ID]: FirstFloorParking,
};

class Floor extends PureComponent {
  static propTypes = {
    floor: PropTypes.string.isRequired,
  };

  getFloorComponent = () => {
    const { floor } = this.props;

    return components[floor];
  };

  render() {
    const Component = this.getFloorComponent();

    return <Component {...this.props} />;
  }
}

export default Floor;
