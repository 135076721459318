import { grey } from '@material-ui/core/colors';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 240,
    margin: [0, 'auto'],
  },

  hint: {
    fontSize: 10,
    textAlign: 'center',
  },

  radioGroup: {
    flexDirection: 'row',
    border: [1, 'solid', grey[500]],
    borderRadius: 4,
    margin: [11, 'auto'],
    backgroundColor: grey[500],
  },

  radio: {
    backgroundColor: 'transparent',
    borderRadius: 3,
    padding: 5,
    color: '#ffffff',
    '&$checked': {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
  },
  checked: {},

  radioText: {
    lineHeight: '22px',
    fontWeight: 'normal',
    color: 'inherit',
  },
};
