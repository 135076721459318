import {
  getButtonArtcleId,
  getButtonBenefitId,
  getButtonDestination,
  getButtonEnglishTitle,
  getButtonPlacement,
  getButtonRussianTitle,
  getButtonStoreId,
  isShowButtonPointer,
} from '@esentai/core/features/campaigns/button/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepThree } from '@/features/campaignCreatePage/actions';
import {
  getCampaignId,
  getCampaignType,
} from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStepButton from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getCampaignId(state);
  const campaignType = getCampaignType(state);

  return {
    articleId: getButtonArtcleId(state, id),
    benefitId: getButtonBenefitId(state, id),
    destination: getButtonDestination(state, id),
    englishTitle: getButtonEnglishTitle(state, id),
    placement: getButtonPlacement(state, id),
    russianTitle: getButtonRussianTitle(state, id),
    showPointer: isShowButtonPointer(state, id),
    storeId: getButtonStoreId(state, id),
    id,
    campaignType,
  };
};

const mapDispatchToProps = { onSubmit: goToStepThree };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(SecondStepButton);
