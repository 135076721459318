import {
  MALL_BONUS_ID_URL_SECTION,
  STEP_ROUTE_PATH,
  STEP_URL_SECTION,
} from './consts';

export const createFormStepURL = (id, step) =>
  STEP_ROUTE_PATH.replace(MALL_BONUS_ID_URL_SECTION, id).replace(
    STEP_URL_SECTION,
    step,
  );

export const randomId = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);
