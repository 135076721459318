import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import api from '@/api';

import FileFiled from '../FileFiled';

export interface Item {
  itemId: number;
  itemImgUrl: string;
  itemCreatedDate: string;
  itemtotalToPayAmount: number;
}

export interface PayloadItem {
  id: number;
  payment_file_url: string;
}

export interface Payload {
  reconciliation_history: PayloadItem[];
}

export interface FinishReconciliationModalProps {
  isOpen: boolean;
  onClose: () => void;
  fetchItems: () => void;
  url: string;
  getUrl: string;
  payload: Payload;
  title: string;
  confirmationMessage: string;
  items: Item[];
  storeId: number;
}

export interface FinishReconciliationModalState {
  error: boolean;
  errText: string;
  payload: Payload;
  curItems: Item[];
}

class FinishReconciliationModal extends React.Component<
  FinishReconciliationModalProps,
  FinishReconciliationModalState
> {
  constructor(props: FinishReconciliationModalProps) {
    super(props);

    this.state = {
      error: false,
      errText: 'Необходимо прикрепить документ!',
      payload: props.payload,
      curItems: props.items,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (!this.props.items) {
      await this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    const { storeId, getUrl } = this.props;

    const data = await api.doGet(`${getUrl + storeId}?include=reconciliations`);

    if (data.reconciliation_history.length > 0) {
      this.setState({
        curItems: data.reconciliation_history.map(h => ({
          // ...h,
          itemId: h.id,
          itemImgUrl: h.payment_file_url,
          itemCreatedDate: h.record_created,
          itemtotalToPayAmount: h.total_to_pay,
        })),
      });
    }
  }

  onSubmit(itemId) {
    this.setState(
      prevState => ({
        ...prevState,
        payload: {
          reconciliation_history: [
            {
              id: itemId,
              payment_file_url: this.state.curItems.find(
                it => it.itemId === itemId,
              ).itemImgUrl,
            },
          ],
        },
      }),
      () => {
        api.doPatch(this.props.url, this.state.payload).then(() => {
          this.props.onClose();
          this.props.fetchItems();
        });
      },
    );
  }

  setImageUrl = (urls, idx) => {
    this.setState(prevcState => ({
      ...prevcState,
      curItems: [
        ...prevcState.curItems.map((v, indx) =>
          indx === idx ? { ...v, itemImgUrl: urls[0] } : v,
        ),
      ],
    }));
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { error, errText, curItems } = this.state;
    const newFileInputProps = { accept: 'application/pdf' };

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title" style={{ textAlign: 'center' }}>
          {'Для завершения реконсиляции нужно прикрепить документ'}
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          {curItems && curItems.length > 0 ? (
            curItems.map((item, idx) => (
              <>
                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                  {idx + 1}
                </Typography>
                <DialogContentText id="alert-dialog-description">
                  <div style={{ display: 'block', textAlign: 'center' }}>
                    <Typography
                      style={{ marginRight: 12, display: 'inline-block' }}
                    >
                      Дата реконсиляции
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{
                        marginRight: 'auto',
                        marginBottom: 12,
                        display: 'inline-block',
                      }}
                    >
                      {item.itemCreatedDate}
                    </Typography>
                  </div>
                  <div style={{ display: 'block', textAlign: 'center' }}>
                    <Typography
                      style={{ marginRight: 12, display: 'inline-block' }}
                    >
                      Сумма реконсиляции
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{
                        marginRight: 12,
                        marginBottom: 12,
                        display: 'inline-block',
                      }}
                    >
                      {item.itemtotalToPayAmount}
                    </Typography>
                  </div>
                  {error && <Typography color="error">{errText}</Typography>}
                </DialogContentText>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    style={{ marginRight: 12, display: 'inline-block' }}
                  >
                    Прикрепить документ
                  </Typography>
                  <FileFiled
                    ids={item.itemImgUrl ? [item.itemImgUrl] : []}
                    onChange={urls => this.setImageUrl(urls, idx)}
                    maxItemsCount={1}
                    fileInputProps={newFileInputProps}
                  />
                </div>
                <Button
                  disabled={!item.itemImgUrl}
                  onClick={() => this.onSubmit(item.itemId)}
                  autoFocus
                  style={{
                    alignSelf: 'center',
                    marginBottom: idx < curItems.length - 1 ? 20 : 0,
                  }}
                >
                  Завершить
                </Button>
              </>
            ))
          ) : (
            <Typography style={{ textAlign: 'center' }}>
              Нет данных для отображения
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size={'large'}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default FinishReconciliationModal;
