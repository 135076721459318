import PropTypes from 'prop-types';
import React from 'react';
import { defaultProps } from 'recompose';

import BackButton from '@/components/BackButton';
import Breadcrumbs from '@/components/Breadcrumbs';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import { ROUTE_PATH as KKMS_PATH } from '@/features/kkmsPage/consts';

import FormCreate from './Form';

const Form = defaultProps({
  company: '',
  identification_number: '',
  submitButtonText: 'Создать',
})(FormCreate);

const NewKkm = ({ createKkm }) => (
  <Page>
    <PageHeader borderBottom gutterBottom={false}>
      <Breadcrumbs>
        <Breadcrumb to={KKMS_PATH}>Справочник ККМ</Breadcrumb>
      </Breadcrumbs>
      <BackButton />
      <PageTitle>Создать ККМ</PageTitle>
    </PageHeader>

    <PageContent xl={8}>
      <PaperSheet>
        <Form onSubmit={createKkm} />
      </PaperSheet>
    </PageContent>
  </Page>
);

NewKkm.propTypes = {
  createKkm: PropTypes.func.isRequired,
};

export default NewKkm;
