import { RESOURCE_NAME } from '@esentai/core/features/giftSets/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadGiftSets = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateGiftSets = authorizeIf(canCreate(RESOURCE_NAME));
export const canUpdateGiftSets = authorizeIf(canUpdate(RESOURCE_NAME));

// TODO set permission for campaign statistic
export const canReadProductsStatistics = () => true;
