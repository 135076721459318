import { compose, map, min } from 'ramda';

import {
  AMOUNT_IMAGES,
  DEVICE_WIDTH,
  MAX_RATIO,
  SPACE_BETWEEN_IMAGES,
} from './consts';

export const getRatio = ({ height, width }) => min(height / width, MAX_RATIO);

export const getHeight = ratio =>
  ratio * (DEVICE_WIDTH / AMOUNT_IMAGES - SPACE_BETWEEN_IMAGES);

export const getMin = arr => (arr.length ? arr.reduce(min) : null);

export const getMinHeight = data => {
  const minHeight = compose(getMin, map(getHeight), map(getRatio))(data);

  return Number.isFinite(minHeight) ? minHeight : null;
};
