import { withStyles } from '@material-ui/core';

import { createPageFilterTemplate } from '@/structs/pageFilter';

import RangeFilter from './component';
import styles from './styles';
import {
  createFormatter,
  createRangeAdapter,
  processNaturalNumberRange,
} from './utils';

const FilterComponent = withStyles(styles)(RangeFilter);

export const createRangeFilterTemplate = definition =>
  createPageFilterTemplate({
    FilterComponent,
    FormatComponent: createFormatter({ unit: '' }),
    adapter: createRangeAdapter({}),
    postProcessValue: processNaturalNumberRange,
    ...definition,
  });

export const createRangeFilter = createRangeFilterTemplate({});

export default FilterComponent;
