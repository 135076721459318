import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Bevel from './bevel.svg';

const DevicePreview = ({ children, classes, variant }) => {
  const rootClassName = classNames(classes.root, {
    [classes.root_shortView]: variant === 'shortView',
  });

  return (
    <div className={rootClassName}>
      <div
        className={classNames(classes.bevel, {
          [classes.bevel_longView]: variant === 'longView',
        })}
      >
        <Bevel
          className={classNames({
            [classes.bevel_longView]: variant === 'longView',
          })}
        />
      </div>

      <div className={classes.content}>{children}</div>
    </div>
  );
};

DevicePreview.defaultProps = {
  variant: 'shortView',
};

DevicePreview.propTypes = {
  variant: PropTypes.string,
};

export default DevicePreview;
