// TODO add behavior of screen if width < 600px

export default ({ palette, spacing, breakpoints }) => {
  const iconSize = spacing.unit * 6;
  const paddingIcon = k => -1 * (k * spacing.unit + iconSize);

  return {
    icon: {
      position: 'absolute',
      width: iconSize,
      height: iconSize,
      left: paddingIcon(7),
      top: `calc(3.75rem - ${iconSize}px)`,
      color: palette.logo.white.esentaiColor,
      paddingLeft: 20,
      [breakpoints.up('sm')]: {
        left: paddingIcon(0.1),
      },
      [breakpoints.up('md')]: {
        left: paddingIcon(2),
      },
      [breakpoints.up('lg')]: {
        left: paddingIcon(7),
      },
    },

    container: {
      position: 'relative',
    },
  };
};
