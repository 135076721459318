import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getUsersError,
  getItemsIds: getUsersIds,
  getItemsMeta: getUsersMeta,
  getItemsTotalCount: getUsersTotalCount,
  isLoadingItems: isLoadingUsers,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
