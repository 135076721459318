import { IconButton } from '@material-ui/core';
import {
  PauseCircleOutline as PauseCircleOutlineIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const ToggleButton = ({
  active,
  ButtonComponent,
  buttonProps,
  onChange,
  ...props
}) => {
  const Icon = active ? PauseCircleOutlineIcon : PlayCircleOutlineIcon;

  return (
    <ButtonComponent
      {...props}
      {...buttonProps}
      onClick={() => onChange(!active)}
    >
      <Icon />
    </ButtonComponent>
  );
};

ToggleButton.defaultProps = {
  ButtonComponent: IconButton,
  buttonProps: {},
};

ToggleButton.propTypes = {
  active: PropTypes.bool.isRequired,
  ButtonComponent: PropTypes.func,
  buttonProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default ToggleButton;
