import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';

import withDevicePreview from '@/hocs/withDevicePreview';

import Preview from './component';
import styles from './styles';

export default compose(
  withStyles(styles),
  withDevicePreview({ variant: 'longView' }),
)(Preview);
