export {
  getAverageTicketById as getAverageTicket,
  getDateById as getTimeframe,
  getItemById,
  getItemPropById,
  getPurchasesCountById as getPurchasesCount,
  getPercentageReceiptsByMorningById as getPercentageReceiptsByMorning,
  getPercentageReceiptsByDayById as getPercentageReceiptsByDay,
  getPercentageReceiptsByEveningById as getPercentageReceiptsByEvening,
} from '@/features/purchasesStatistics/selectors';
