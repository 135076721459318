export default {
  pageTitle: {
    padding: 20,
  },

  stepperRoot: {
    backgroundColor: 'transparent',
  },

  step: {
    width: 330,
  },

  stepLabel: {
    fontSize: 20,
    fontWeight: 400,
  },
};
