export default {
  wrap: {
    textAlign: 'center',
  },
  root: {
    fontSize: 60,
  },
  colorPrimary: {
    color: 'green',
  },
};
