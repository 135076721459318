import api from '@esentai/core/features/campaigns/api';
import { TITLE_KEY } from '@esentai/core/features/campaigns/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import CampaignChip from './CampaignChip';
import CampaignOption from './CampaignOption';

const buildQuery = queryString => query(where(TITLE_KEY, ilike(queryString)));

const withStoreProps = defaultProps({
  api,
  buildQuery,
  Chip: CampaignChip,
  Option: CampaignOption,
});

export const CampaignAutocomplete = withStoreProps(AutocompleteAPI);

export const CampaignMultiAutocomplete = withStoreProps(
  AutocompleteAPIMultiple,
);
