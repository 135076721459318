import { KKM_NUMBER_KEY } from '@esentai/core/features/kkms/consts/keys';
import { annotations } from '@esentai/core/features/kkms/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(KKM_NUMBER_KEY),
  }),
  include: string({
    defaultValue: ['store', 'merchant'],
  }),
});
