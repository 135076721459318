import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from './DeleteButton';

const Actions = ({ classes, parkingWhitelistGroupId }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <DeleteButton parkingWhitelistGroupId={parkingWhitelistGroupId} />
  </Grid>
);

Actions.propTypes = {
  parkingWhitelistGroupId: PropTypes.number.isRequired,
};

export default Actions;
