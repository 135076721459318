import api from '@esentai/core/features/users/api';
import { PHONE_KEY } from '@esentai/core/features/users/consts/keys';
import { ilike, limit, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';

import PhoneChip from './PhoneChip';
import PhoneOption from './PhoneOption';

const buildQuery = queryString =>
  query(where(PHONE_KEY, ilike(queryString)), limit(100));

const withStoreProps = defaultProps({
  api,
  buildQuery,
  Chip: PhoneChip,
  Option: PhoneOption,
});

export const UserPhoneAutocomplete = withStoreProps(AutocompleteAPI);
