import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import StoreNameLazy from '@/containers/StoreNameLazy';
import UserNameLazy from '@/containers/UserNameLazy';
import { ROUTE_PATH as STORES_PATH } from '@/features/storesPage/consts';
import { dateTimeFormat } from '@/utils/format';

import { HistoryPage } from '../../HistoryPage/HistoryPage';
import Actions from './Actions';
import BinList from './BinList';
import { Bin } from './BinList/Bin/Bin';
import Brands from './Brands';
import Description from './Description';
import LoyaltyRepresentative from './LoyaltyRepresentative';

const historyHeaders = [
  'БИН/ИИН',
  'Пользователь',
  'Дата изменения',
  'Комментарий',
];

class Store extends Component {
  static propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    itemId: PropTypes.string.isRequired,
    merchantIds: PropTypes.arrayOf(PropTypes.any),
  };

  static defaultProps = {
    merchantIds: [],
  };

  render() {
    const { isLoaded, itemId, merchantIds } = this.props;

    if (!isLoaded) {
      return null;
    }

    return (
      <>
        <Page>
          <PageHeader gutterBottom={false}>
            <Breadcrumbs>
              <Breadcrumb to={STORES_PATH}>Магазины</Breadcrumb>
            </Breadcrumbs>
            <BackButton />
            <PageTitle>
              <StoreNameLazy storeId={itemId} />
              {/* <HeaderActions storeId={itemId} /> */}
            </PageTitle>
          </PageHeader>
          <PageContent>
            <PaperSheet>
              <Description storeId={itemId} />
              <Brands storeId={itemId} />
              <BinList merchantIds={merchantIds} />
              <LoyaltyRepresentative storeId={itemId} />
              <Actions />
            </PaperSheet>
          </PageContent>
        </Page>
        <HistoryPage model="store" objectId={itemId} headers={historyHeaders}>
          {rows =>
            rows.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  {row.data.merchants.map(merch => (
                    <>
                      <Bin key={merch} merchId={merch} /> &nbsp;
                    </>
                  ))}
                </TableCell>
                <TableCell>
                  <UserNameLazy userId={row.user_id} />
                </TableCell>
                <TableCell>{dateTimeFormat(row.record_created)}</TableCell>
                <TableCell>{row.comment}</TableCell>
              </TableRow>
            ))
          }
        </HistoryPage>
      </>
    );
  }
}

export default Store;
