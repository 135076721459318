export const ID_KEY = 'id';
export const ORDER_ID_KEY = 'order.id';
export const ORDER_CUSTOMER_ID_KEY = 'customer.id';
export const ORDER_CUSTOMER_LEVEL_KEY = 'order.customer.level';

export const RECORD_CREATED_KEY = 'recordCreated';
export const PARKING_RECORD_CREATED_KEY = 'parkingSession.recordCreated';
export const STATUS_KEY = 'status';
export const RECORD_PAYMENT_TIME_KEY = 'paymentTime';
export const PARKING_RECORD_PAYMENT_TIME_KEY = 'parkingSession.exitDatetime';

export const ORDER_AMOUNT_KEY = 'order.amount';
export const ORDER_STATUS_KEY = 'order.result';
export const ORDER_DEBT_KEY = 'parkingSession.debtAmount';
export const ORDER_ARRIVAL_KEY = 'order.arrival';
export const MAIN_CAR_KEY = 'mainCar';
export const VEHICLE_GOV_NUMBER_KEY = 'vehicleGovNumber';
export const VEHICLE_MODEL_KEY = 'vehicleModel';
export const VALET_FULLNAME_KEY = 'valet.fullName';
export const ORDER_PAYMENT_TYPE_KEY = 'paymentInitialized';
export const TEMPORARY_CAR_KEY = 'temporaryCar';
export const TEMPORARY_CAR_NUMBER_KEY = 'temporaryGovNumber';
export const TEMPORARY_CAR_MODEL_KEY = 'temporaryVehicleModel';
export const PARKING_PRICE_KEY = 'parkingPrice';
export const VALET_PRICE_KEY = 'valetPrice';
export const VALET_ONSTART_FULLNAME_KEY = 'valetOnStart.fullName';
export const RECEIVED_AMOUNT_KEY = 'receivedAmount';
export const VEHICLE_GOV_KEY = 'vehicleGov';

export const ORDER_IS_PAID = '1';
export const ORDER_IS_NOT_PAID = '0';
export const ORDER_IN_PROCESS = '';

export const ORDER_PAID_BY_APP = 'true';
export const ORDER_IS_NOT_PAID_BY_APP = 'false';

export const dateFilters = [
  RECORD_PAYMENT_TIME_KEY,
  PARKING_RECORD_CREATED_KEY,
  RECORD_CREATED_KEY,
  PARKING_RECORD_PAYMENT_TIME_KEY,
];

export const carFilters = [
  VEHICLE_GOV_NUMBER_KEY,
  VEHICLE_MODEL_KEY,
  TEMPORARY_CAR_NUMBER_KEY,
  TEMPORARY_CAR_MODEL_KEY,
];

export const paymentTypes = type => {
  const mapping = {
    [ORDER_PAID_BY_APP]: 'Приложение',
    [ORDER_IS_NOT_PAID_BY_APP]: 'Неприложение',
  };

  return mapping[type];
};
