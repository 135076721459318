import { call, delay, select } from 'redux-saga/effects';

import { runWhileAuthorized } from '@/features/auth/sagas';
import { getCurrentUserId } from '@/features/auth/selectors';
import { updateLastActivity } from '@/features/personnel/sagas';

import { LAST_ACTIVITY_UPDATE_PERIOD } from './consts';

function* updateCurrentUserLastActivity() {
  const currentUserId = yield select(getCurrentUserId);
  const now = new Date();

  yield call(updateLastActivity, currentUserId, now);
}

function* periodicallyUpdateLastActivity() {
  while (true) {
    try {
      yield call(updateCurrentUserLastActivity);
    } finally {
      yield delay(LAST_ACTIVITY_UPDATE_PERIOD);
    }
  }
}

export default function*() {
  yield runWhileAuthorized(periodicallyUpdateLastActivity);
}
