import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import {
  getMerchantId,
  getReconciliationError,
  getReconciliationIds,
  getReconciliationTotalCount,
  isLoadingReconciliation,
} from '../selectors';
import ReconciliationRefunds from './component';
import styles from './styles';

const mapStateToProps = state => {
  const merchantId = getMerchantId(state);

  return {
    error: getReconciliationError(state),
    reconciliationIds: getReconciliationIds(state),
    isLoading: isLoadingReconciliation(state),
    totalCount: getReconciliationTotalCount(state),
    merchantId,
  };
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(ReconciliationRefunds);
