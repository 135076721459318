import PropTypes from 'prop-types';
import React from 'react';

import Label from '../Label';
import Select from '../Select';

const StatSelect = ({ classes, setStatsIds, statsIds, stats }) => {
  const items = stats.map(stat => ({
    key: stat.url_key,
    label: stat.label,
  }));

  return (
    <div>
      <Label className={classes.label}>Сравнить</Label>
      <Select
        classes={{ selectMenu: classes.leftSelectMenu }}
        value={statsIds[0]}
        items={items}
        onChange={statId => setStatsIds([statId, statsIds[1]])}
      />
      <Label className={classes.label}>и</Label>
      <Select
        classes={{ selectMenu: classes.rightSelectMenu }}
        value={statsIds[1]}
        items={items}
        onChange={statId => setStatsIds([statsIds[0], statId])}
      />
    </div>
  );
};

StatSelect.propTypes = {
  setStatsIds: PropTypes.func.isRequired,
  statsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  stats: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StatSelect;
