import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';
import stats from '@/features/anonymousPurchasesStatisticsPage/stats';
import { getStatPageUrl } from '@/features/statisticsPage/utils';

import Chart from './Chart';
import Table from './Table';
import TopBar from './TopBar';

class AnonymousPurchasesStatistics extends Component {
  static propTypes = {
    error: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    itemsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    statsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, isLoading, itemsIds, statsIds, totalCount } = this.props;

    if (!statsIds.length) {
      return (
        <Redirect
          to={getStatPageUrl('anonymous_purchases', [
            stats[0].url_key,
            stats[1].url_key,
          ])}
        />
      );
    }

    return (
      <Page>
        <PageHeader borderBottom>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Статистика</PageTitle>
          <PageSubtitle>
            Статистика ограничена только вашим магазином.
          </PageSubtitle>
        </PageHeader>
        <PageContent>
          <TopBar />
          <Chart />
          <Table
            error={error}
            isLoading={isLoading}
            items={itemsIds}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default AnonymousPurchasesStatistics;
