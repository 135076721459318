import { getFiltersForMallBonus } from '@esentai/core/features/mallBonuses/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { submit } from '@/features/mallBonusCreatePage/actions';
import { getMallBonusId } from '@/features/mallBonusCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStep from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getMallBonusId(state);

  return {
    filters: getFiltersForMallBonus(state, id),
    id,
  };
};

const mapDispatchToProps = { onSubmit: submit };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(SecondStep);
