import PropTypes from 'prop-types';
import React, { Component } from 'react';

import InlineSearch from '../InlineSearch';
import SelectedItem from './SelectedItem';
import Suggestion from './Suggestion';
import { getKeyByType, getPropsByType } from './utils';

class CampaignInlineSearch extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isArticleStatus: PropTypes.bool,
    fieldName: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    isArticleStatus: false,
    fieldName: '',
  };

  onSelect = value => {
    const { onSelect, type, fieldName, isArticleStatus } = this.props;

    if (isArticleStatus) {
      onSelect(fieldName, value);
    } else {
      const key = getKeyByType(type);

      onSelect(key, value);
    }
  };

  render() {
    const { type, values, disabled } = this.props;
    const value = values[getKeyByType(type)];

    return (
      <InlineSearch
        {...getPropsByType(type)}
        key={type}
        value={value}
        disabled={disabled}
        onSelect={this.onSelect}
        Suggestion={this.renderSuggestion}
        SelectedItem={SelectedItem}
      />
    );
  }

  renderSuggestion = props => {
    const { type, isArticleStatus } = this.props;

    return (
      <Suggestion type={type} isArticleStatus={isArticleStatus} {...props} />
    );
  };
}

export default CampaignInlineSearch;
