export default () => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  dimmed: {
    backgroundColor: '#F5F5F5',
  },

  inactive: {
    backgroundColor: '#F5F5F5',
  },
  minWidth: {
    minWidth: 150,
  },
  lastHead: {
    textAlign: 'right',
  },
});
