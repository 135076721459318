import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { TOKEN_KEY } from '@esentai/core/features/benefitItems/consts/keys';
import { getBenefitItemToken } from '@esentai/core/features/benefitItems/selectors';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import dao from './dao';
import { GIVE_OUT } from './types';

const { update } = dao.sagas;

function* giveOut({ payload: id }) {
  const token = yield select(getBenefitItemToken, id);

  yield call(update, id, {
    [TOKEN_KEY]: token,
  });
}

function* watchGiveOut() {
  yield takeEvery(GIVE_OUT, createTaskSaga(giveOut));
}

export default function*() {
  yield spawn(watchGiveOut);
}
