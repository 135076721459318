import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import AsyncButton from '@/components/AsyncButton';

import ErrorMessage from '../ErrorMessage';
import RepairIcon from './repairIcon.svg';

const LoadErrorMessage = ({
  error,
  isLoading,
  retry,
  classes,
  title,
  subtitle,
  ...props
}) => (
  <Grid container justify="center" {...props}>
    <Grid item className={classes.content}>
      <ErrorMessage title={title} subtitle={subtitle} Icon={RepairIcon} />

      {retry && (
        <AsyncButton
          error={error}
          isLoading={isLoading}
          variant="outlined"
          onClick={retry}
          className={classes.retryButton}
        >
          Повторить попытку
        </AsyncButton>
      )}
    </Grid>
  </Grid>
);

LoadErrorMessage.propTypes = {
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  retry: PropTypes.func,
};

LoadErrorMessage.defaultProps = {
  error: null,
  isLoading: false,
  title: 'Что-то пошло не так',
  subtitle: 'При получении данных возникла непредвиденная ошибка',
  retry: null,
};

export default LoadErrorMessage;
