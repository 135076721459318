import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import { USER_NOT_FOUND_CODE } from '@/features/authPages/ForgotPassword/consts';
import styles from '@/features/authPages/styles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import { loginFieldName } from './names';

const errorsMapping = {
  [USER_NOT_FOUND_CODE]: {
    field: loginFieldName,
    message: 'Такого аккаунта нет',
  },
};
const formikOptions = {
  mapPropsToValues: ({ email }) => ({ [loginFieldName]: email }),

  validationSchema: object().shape({
    [loginFieldName]: string()
      .email()
      .required(),
  }),

  handleSubmit({ [loginFieldName]: email }, { props, setSubmitting }) {
    props.onSubmit({ email });
    setSubmitting(false);
  },
};
const asyncOptions = { runnerMethod: 'onSubmit' };

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
