import api from '@esentai/core/features/stores/api';
import { NAME_KEY } from '@esentai/core/features/stores/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import StoreChip from './StoreChip';
import StoreOption from './StoreOption';

const buildQuery = queryString => query(where(NAME_KEY, ilike(queryString)));

const withStoreProps = defaultProps({
  api,
  buildQuery,
  Chip: StoreChip,
  Option: StoreOption,
});

export const StoreAutocomplete = withStoreProps(AutocompleteAPI);

export const StoreMultiAutocomplete = withStoreProps(AutocompleteAPIMultiple);
