import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { formatNumber, formatPercentage } from '@/utils/format';

class FlowRateChart extends Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  };

  renderFormat = () => formatNumber(this.props.value);

  render() {
    const { classes, percentage } = this.props;
    const showPercentage = !isNaN(percentage);

    return showPercentage ? (
      <div className={classes.flowRateChart}>
        <div className={classes.text}>
          <div className={classes.percentage}>
            {formatPercentage(percentage)}
          </div>
          <div className={classes.expander} />
          <div className={classes.value}>{this.renderFormat()}</div>
        </div>
        <div className={classes.chart} />
      </div>
    ) : (
      this.renderFormat()
    );
  }
}

export default FlowRateChart;
