import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DotIndicator from '@/components/DotIndicator';

class StoreSuggestion extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    itemId: PropTypes.number.isRequired,
    SelectionControl: PropTypes.func.isRequired,
  };

  render() {
    const { classes, isActive, itemId, name, SelectionControl } = this.props;

    return (
      <div className={classes.root}>
        <SelectionControl itemId={itemId} />
        <Typography variant="subtitle1" className={classes.title}>
          {name}
        </Typography>
        <Typography variant="caption" className={classes.status}>
          <DotIndicator status={isActive ? 'enabled' : 'disabled'} />{' '}
          {isActive ? 'Активен' : 'Деактивирован'}
        </Typography>
      </div>
    );
  }
}

export default StoreSuggestion;
