import {
  BIRTH_DATE_KEY,
  DATE_REGISTERED_KEY,
  GENDER_KEY,
  STATUS_KEY,
} from '@esentai/core/features/storeBonuses/consts/keys';

import { createBirthdayRangeFilter } from '@/filters/BirthdayRange';
import { createDateRangeFilter } from '@/filters/DateRange';
import { createGenderFilter } from '@/filters/Gender';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createGenderFilter({
    key: GENDER_KEY,
    label: 'Пол',
  }),
  createBirthdayRangeFilter({
    key: BIRTH_DATE_KEY,
    label: 'Дата рождения',
  }),
  createDateRangeFilter({
    key: DATE_REGISTERED_KEY,
    label: 'Дата регистрации',
  }),
  createUserStatusFilter({
    key: STATUS_KEY,
    label: 'Статус',
  }),
];
