import { differenceInSeconds } from 'date-fns';
import PropTypes from 'prop-types';
import { Component } from 'react';

class Countdown extends Component {
  static propTypes = {
    getFrom: PropTypes.func,
    to: PropTypes.instanceOf(Date).isRequired,
    updatePeriod: PropTypes.number,
  };

  static defaultProps = {
    getFrom: () => new Date(),
    updatePeriod: 1000,
  };

  static getDerivedStateFromProps(props, state) {
    const secondsLeft = differenceInSeconds(props.to, props.getFrom());

    if (secondsLeft !== state.secondsLeft) {
      return { secondsLeft };
    }

    return null;
  }

  state = {
    secondsLeft: differenceInSeconds(this.props.to, this.props.getFrom()),
  };

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setTimer = () => {
    const { getFrom, to, updatePeriod } = this.props;

    const tick = () => {
      const secondsLeft = differenceInSeconds(to, getFrom());

      this.setState({ secondsLeft });
    };

    this.interval = setInterval(tick, updatePeriod);
  };

  render() {
    const { secondsLeft } = this.state;

    return secondsLeft;
  }
}

export default Countdown;
