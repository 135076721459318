import { findProductById } from '@esentai/core/features/products/actions';
import {
  getTitleRuForProduct,
  isProductLoaded,
  isProductLoading,
} from '@esentai/core/features/products/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import ProductNameLazy from './component';

const mapStateToProps = (state, { productId }) => ({
  isLoaded: isProductLoaded(state, productId),
  isLoading: isProductLoading(state, productId),
  itemId: productId,
  title: getTitleRuForProduct(state, productId),
});

const mapDispatchToProps = { fetchItem: findProductById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(ProductNameLazy);
