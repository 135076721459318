import { findArticleById } from '@esentai/core/features/campaigns/article/actions';
import { isLoadingArticle } from '@esentai/core/features/campaigns/article/selectors';
import { getBannerArticleId } from '@esentai/core/features/campaigns/banner/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import CampaignReference from '@/features/campaignCreatePage/EditPage/SecondStepBanner/CampaignInlineSearch/Suggestion';
import withItemApiRunner from '@/hocs/withItemApiRunner';

const mapStateToProps = (state, { itemId }) => {
  const articleId = getBannerArticleId(state, itemId);

  return {
    isLoading: isLoadingArticle(state, articleId),
    itemId: articleId,
  };
};

const mapDispatchToProps = { fetchItem: findArticleById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(CampaignReference);
