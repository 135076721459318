import { fetchSettings } from '@esentai/core/features/applicationSettings/actions';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { updateFeedLayout } from '@/features/feedLayoutEditPage/actions';

import EditContacts from './component';

const mapDispatchToProps = { fetchSettings, updateFeedLayout };

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'fetchSettings' }),
)(EditContacts);
