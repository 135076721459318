import { CircularProgress, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FilterBar from '@/components/FilterBar';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { filterTypes } from '@/features/campaignCreatePage/EditPage/ThirdStep/filters';
import ToolbarSelect from '@/features/parkingTowerStopListAddPage/Page/ToolbarSelect';
import { ROUTE_PATH as ROUTE_PARKING_TOWER_STOP_LIST_PATH } from '@/features/parkingTowerStopListPage/consts';

import { columns } from './columns';

export default class ParkingTowerStopListStaticFilterPage extends Component {
  static propTypes = {
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    usersIsLoading: PropTypes.bool.isRequired,
    usersTotalCount: PropTypes.number.isRequired,
    getUserById: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    updateLimit: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    removeFilters: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    updateParkingTowerStopList: PropTypes.func.isRequired,
  };

  state = {
    users: [],
    groupId: undefined,
  };

  loadUsers() {
    const { getUserById, userIds } = this.props;

    this.setState({ users: userIds.map(id => getUserById(id)) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userIds !== this.props.userIds) {
      this.loadUsers();
    }
  }

  render() {
    const {
      usersIsLoading,
      usersTotalCount,
      classes,
      page,
      updateLimit,
      updatePage,
      limit,
      removeFilters,
      updateFilters,
      filters,
      updateParkingTowerStopList,
      userIds,
    } = this.props;

    const { users, groupId } = this.state;

    const filtersList = [...filters.entries()].map(([key, value]) => ({
      key,
      value,
    }));

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={ROUTE_PARKING_TOWER_STOP_LIST_PATH}>
              Stoplist Tower
            </Breadcrumb>
          </Breadcrumbs>
          <PageTitle gutterBottom={false}>
            Добавить пользователей в стоп лист
          </PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper} />

          <div className={classes.facts}>
            <FieldRow label="Сегмент" subLabel="Сегментация аудитории">
              <FieldDescription
                title="Фильтр сегмента"
                desc="Вы можете добавить также дополнительные фильтры, которые отделят пользователей с определенными характеристиками. К примеру, пользователей, обладающих Silver статусом, либо мужчин старше 35 лет."
              />
              <Typography variant="subtitle1" gutterBottom>
                Включить в сегмент посетителей, у которых:
              </Typography>
              <FilterBar
                filterTypes={filterTypes}
                filters={filtersList}
                removeFilters={removeFilters}
                updateFilters={updateFilters}
              />
            </FieldRow>
            <FieldRow className={classes.fieldRow} label="Пользователи">
              <FieldDescription
                title="Список пользователей"
                desc="Здесь показаны все пользователи поподающие под выбранную фильтрацию сегмента"
              />
            </FieldRow>
            <div style={{ maxWidth: '100%', margin: '0 10px' }}>
              <MUIDataTable
                title=""
                data={usersIsLoading ? [''] : users}
                columns={columns}
                options={{
                  customRowRender: !usersIsLoading
                    ? undefined
                    : function() {
                        return (
                          <tr>
                            <td
                              colSpan="100%"
                              style={{ textAlign: 'center', padding: 40 }}
                            >
                              <CircularProgress size={30} color="secondary" />
                            </td>
                          </tr>
                        );
                      },
                  count: usersTotalCount,
                  page,
                  sort: false,
                  rowsPerPage: limit,
                  onChangePage: updatePage,
                  onChangeRowsPerPage: updateLimit,
                  textLabels: {
                    selectedRows: {
                      text: 'пользователь(ей) выбрано',
                    },
                    body: {
                      noMatch: 'Пользователей с выбранными данными не найдены',
                      columnHeaderTooltip: column => `Sort for ${column.label}`,
                    },
                    toolbar: {
                      viewColumns: 'Показать колонки',
                    },
                    viewColumns: {
                      title: 'Показать колонки',
                      titleAria: 'Показать/Скрыть колонки',
                    },
                    pagination: {
                      next: 'Следующая страница',
                      previous: 'Предыдущая страница',
                      rowsPerPage: 'Пользователей на странице',
                      displayRows: 'из',
                    },
                  },
                  search: false,
                  download: false,
                  serverSide: true,
                  filterType: 'checkbox',
                  print: false,
                  filter: false,
                  customToolbarSelect: (
                    selectedRows,
                    displayData,
                    setSelectedRows,
                  ) => (
                    <ToolbarSelect
                      itemIds={userIds}
                      selectedRows={selectedRows}
                      displayData={displayData}
                      setSelectedRows={setSelectedRows}
                      handleAddParkingTowerStopList={() => {
                        Object.keys(selectedRows.lookup).forEach(key => {
                          updateParkingTowerStopList({
                            ParkingTowerStopListsId: userIds[key],
                            ...(groupId ? { group_id: groupId } : {}),
                          });
                        });
                      }}
                    />
                  ),
                }}
              />
            </div>
          </div>
        </PageContent>
      </Page>
    );
  }
}
