import createDao from '@esentai/core/ducks/dao';
import { prop } from 'ramda';

import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';

import api from '../api';
import { RESOURCE_NAME } from '../consts';
import { getDaoRoot } from '../utils';

export default createDao(RESOURCE_NAME, {
  api,
  getRoot: getDaoRoot,
  idProp: prop(TIMEFRAME_KEY),
});
