import React from 'react';

import TopBar from './topBar.svg';

const MainPageTopBar = ({ classes }) => (
  <div>
    <div className={classes.topBarContainer}>
      <TopBar />
    </div>
  </div>
);

MainPageTopBar.propTypes = {};

export default MainPageTopBar;
