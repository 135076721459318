import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError,
  getItemsIds,
  getItemsMeta,
  getItemsTotalCount,
  isLoadingItems: isLoadingUsers,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
