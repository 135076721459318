export default {
  indicator: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: 8,
  },

  blocked: {
    backgroundColor: '#000000',
  },

  enabled: {
    backgroundColor: '#689F38',
  },

  errorred: {
    backgroundColor: '#c62828',
  },

  disabled: {
    backgroundColor: '#616161',
  },

  pending: {
    backgroundColor: '#1e88e5',
  },

  warning: {
    backgroundColor: '#FFC107',
  },
};
