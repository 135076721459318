import {
  INDEX_KEY,
  PRICE_KEY,
  SUBTITLE_RU_KEY,
  TITLE_RU_KEY,
} from '@esentai/core/features/giftSets/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';

import { canUpdateGiftSets } from '../../permissions';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field="id">ID</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TITLE_RU_KEY}>Заголовок</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={SUBTITLE_RU_KEY}>Подзаголовок</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={INDEX_KEY}>Очередность</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Артикул</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={PRICE_KEY}>Цена набора</SortControl>
    </CondenseTableCell>

    <CanView permission={canUpdateGiftSets}>
      <CondenseTableCell>Действия</CondenseTableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
