import CRUDResource from '@esentai/core/resources/crud';

import { RESOURCE_NAME } from './consts';
import serializer from './serializer';

export class InvitesResource extends CRUDResource {
  findById(token) {
    return super.findById(token).then(({ payload }) => {
      const [invite] = payload.invite;

      invite.token = token;

      return { payload };
    });
  }

  update(_, attributes) {
    return this.bulkUpdate([attributes]);
  }
}

export default new InvitesResource({
  resourceName: RESOURCE_NAME,
  endpoint: '/invite',
  serializer,
});
