import {
  getPlacement,
  getPriority,
} from '@esentai/core/features/campaigns/banner/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import PlacementForm from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  placement: getPlacement(state, itemId),
  priority: getPriority(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(PlacementForm);
