import { connect } from 'react-redux';

import TooltipContent from '@/components/StatisticsTooltipContent';
import {
  getDateBySelectedIndex,
  getMetrics,
  getPropBySelectedIndex,
  getScale,
} from '@/features/campaignsStatisticsPage/selectors';
import { getStatsIds } from '@/features/statisticsPage/selectors';

const mapStateToProps = (state, { selectedDateIndex }) => ({
  date: getDateBySelectedIndex(state, selectedDateIndex),
  selectedStatsIds: getStatsIds(state),
  scale: getScale(state),
  getValue: getPropBySelectedIndex,
  stats: getMetrics(state),
});

export default connect(mapStateToProps)(TooltipContent);
