import { JET_BLACK_STATUS } from '@esentai/core/features/users/consts/statuses';

import { getBarComponent } from './utils';

export const JetBlackBar = getBarComponent({
  styles: {
    bar: {
      backgroundColor: '#696969',
      color: '#fff',
    },
  },
  status: JET_BLACK_STATUS,
});
