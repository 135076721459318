import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';

const FinishButton = ({ classes, handleDialog, finishDisabled }) => {
  const preventDefault = event => {
    event.preventDefault();
    handleDialog();
  };

  return (
    <Grid className={classes.actions} container wrap="nowrap">
      <div className={classes.actionCell}>
        <Button
          className={classes.linkButton}
          onClick={preventDefault}
          disabled={finishDisabled}
        >
          Добавить оплату
        </Button>
      </div>
    </Grid>
  );
};

FinishButton.propTypes = {
  handleDialog: PropTypes.func.isRequired,
  finishDisabled: PropTypes.bool.isRequired,
};

export default FinishButton;
