import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/benefitItemsPage/ducks/query';
import {
  getBenefitsError,
  getBenefitsIds,
  getBenefitsTotalCount,
  isLoadingBenefits,
} from '@/features/benefitItemsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import Campaigns from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getBenefitsError(state),
  itemsIds: getBenefitsIds(state),
  totalCount: getBenefitsTotalCount(state),
  isLoading: isLoadingBenefits(state),
});

export default compose(
  withStyles(styles),
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(Campaigns);
