import PropTypes from 'prop-types';
import React from 'react';

import EditButton from '@/features/storesPage/Page/Row/Actions/EditButton';

import ActivateButton from './ActivateButton';
import ArchiveButton from './ArchiveButton';

const Actions = ({ classes, storeId }) => (
  <div className={classes.root}>
    <ActivateButton storeId={storeId} small={false} color="primary" />
    <ArchiveButton storeId={storeId} small={false} color="primary" />
    <EditButton
      storeId={storeId}
      small={false}
      color="primary"
      variant="contained"
    />
  </div>
);

Actions.propTypes = {
  storeId: PropTypes.number.isRequired,
};

export default Actions;
