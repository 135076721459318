const colorPreview = {
  display: 'inline-block',
  content: '""',
  width: 16,
  height: 16,
  borderRadius: 2,
  marginRight: 4,
};

export default {
  label: {
    marginRight: 10,
  },

  leftSelectMenu: {
    color: '#757575',

    '&:before': {
      ...colorPreview,
      backgroundColor: '#4A90E2',
    },
  },

  rightSelectMenu: {
    color: '#757575',

    '&:before': {
      ...colorPreview,
      backgroundColor: '#F5A623',
    },
  },
};
