import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import { createAction } from 'redux-actions';

import query from './ducks/query';
import { DELETE_PRODUCT, RETRY_FETCH, TOGGLE_VISIBILITY } from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const retryFetch = createAction(RETRY_FETCH);

export const deleteProduct = createAction(
  DELETE_PRODUCT,
  null,
  () => 'deleteProduct',
);

export const toggleVisibility = createTaskAction(TOGGLE_VISIBILITY);
