export default {
  name: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    color: 'inherit',
    marginLeft: 16,
  },

  actions: {
    marginLeft: 17,
  },
};
