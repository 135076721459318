import PropTypes from 'prop-types';
import React from 'react';

import CloneButton from '@/features/campaignsPage/Page/Row/buttons/CloneButton';

const ArchivedStatusActions = ({ itemId }) => (
  <CloneButton itemId={itemId} small={false} color="primary" />
);

ArchivedStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default ArchivedStatusActions;
