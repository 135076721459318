import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { uploadFile } from '@/features/upload/actions';

import FileFiled from './component';
import styles from './styles';

const mapDispatchToProps = { upload: uploadFile };

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(FileFiled);
