import {
  DATE_ISSUED_KEY,
  PURCHASES_COUNT_KEY,
  TOTAL_COST_KEY,
} from '@esentai/core/features/receipts/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={DATE_ISSUED_KEY}>Дата и время покупки</SortControl>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <SortControl field={TOTAL_COST_KEY}>Сумма покупки</SortControl>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <SortControl field={PURCHASES_COUNT_KEY}>Количество товаров</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>Касса</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
