import { STORE_ID_KEY } from '@esentai/core/features/reconciliation/consts/keys';

import { createStoreFilter } from '@/filters/Store';

export const filterTypes = [
  createStoreFilter({
    key: STORE_ID_KEY,
    label: 'Магазин',
  }),
];
