import {
  AGE_KEY,
  EMAIL_KEY,
  FIRST_NAME_KEY,
  GENDER_KEY,
  ID_KEY,
  LAST_NAME_KEY,
  PHONE_KEY,
  RECORD_CREATED_KEY,
  STATUS_KEY,
} from '@esentai/core/features/users/consts/keys';
import { Grid } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import ParkingLevelThreeWhitelistAddCell from '@/features/parkingLevelThreeWhitelistAddPage/Page/ParkingLevelThreeWhitelistAddCell';
import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormat } from '@/utils/format';
import { userGender, userStatus } from '@/utils/labels';

export const columns = [
  {
    name: ID_KEY,
    label: <span style={{ whiteSpace: 'nowrap' }}>ID клиента</span>,
    options: {
      customBodyRender: id => (
        <Grid container alignItems="center" direction="row" spacing={8}>
          <Grid item xs={6}>
            <Link to={createUserPageUrl(id)}>{id}</Link>
          </Grid>
          <Grid item xs={6}>
            <ParkingLevelThreeWhitelistAddCell itemId={id} />
          </Grid>
        </Grid>
      ),
    },
  },
  {
    name: LAST_NAME_KEY,
    label: 'Фамилия',
    options: { customBodyRender: name => name || '–' },
  },
  {
    name: FIRST_NAME_KEY,
    label: 'Имя',
    options: { customBodyRender: name => name || '–' },
  },
  {
    name: STATUS_KEY,
    label: 'Уровень',
    options: { customBodyRender: status => userStatus(status) || '–' },
  },
  {
    name: PHONE_KEY,
    label: <span style={{ whiteSpace: 'nowrap' }}>Номер телефона</span>,
    options: { customBodyRender: phone => phone || '–' },
  },
  {
    name: EMAIL_KEY,
    label: 'Почта',
    options: { customBodyRender: email => email || '–' },
  },
  {
    name: AGE_KEY,
    label: 'Возраст',
    options: { customBodyRender: age => age || '–' },
  },
  {
    name: GENDER_KEY,
    label: 'Пол',
    options: { customBodyRender: gender => userGender(gender) },
  },
  {
    name: RECORD_CREATED_KEY,
    label: <span style={{ whiteSpace: 'nowrap' }}>Дата регистрации</span>,
    options: {
      customBodyRender: date => (
        <span style={{ whiteSpace: 'nowrap' }}>{dateTimeFormat(date)}</span>
      ),
    },
  },
];
