import PropTypes from 'prop-types';
import { append, without } from 'ramda';
import React, { Component } from 'react';

import AutocompleteBase from '@/components/AutocompleteBase';

import Input from './Input';
import Selection from './Selection';

class AutocompleteMultipleBase extends Component {
  static propTypes = {
    selectedItem: PropTypes.array,
    options: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    selectedItem: [],
    options: [],
    Input,
    Selection,
    onChange: () => {},
  };

  onChange = item => {
    const { onChange, selectedItem } = this.props;
    const alreadySelected = selectedItem.includes(item);

    if (alreadySelected) {
      onChange(without([item], selectedItem));
    } else {
      onChange(append(item, selectedItem));
    }
  };

  getOptions = () => {
    const { options, selectedItem } = this.props;

    return selectedItem.length ? without(selectedItem, options) : options;
  };

  render() {
    const { onChange, ...props } = this.props;

    return (
      <AutocompleteBase
        {...props}
        options={this.getOptions()}
        onChange={onChange ? this.onChange : null}
      />
    );
  }
}

export default AutocompleteMultipleBase;
