import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import StoreNameLazy from '@/containers/StoreNameLazy';
import UserNameLazy from '@/containers/UserNameLazy';
import { dateTimeFormat } from '@/utils/format';

import { BonusFilters } from '../../../../components/BonusFIlters/BonusFilters';

const Row = ({
  creationDate,
  percent,
  startDate,
  endDate,
  stores,
  filters,
  user,
  comment,
  status,
  bonusName,
}) => (
  <TableRow>
    <CondenseTableCell>{bonusName}</CondenseTableCell>
    <CondenseTableCell>
      <UserNameLazy userId={user} />
    </CondenseTableCell>

    <CondenseTableCell>{dateTimeFormat(creationDate)}</CondenseTableCell>

    <CondenseTableCell>{percent}%</CondenseTableCell>

    <CondenseTableCell>
      {dateTimeFormat(startDate)} - {dateTimeFormat(endDate)}
    </CondenseTableCell>

    <CondenseTableCell>
      {stores &&
        stores.map(store => <StoreNameLazy key={store} storeId={store} />)}
    </CondenseTableCell>

    <CondenseTableCell>
      <BonusFilters filter={filters} />
    </CondenseTableCell>

    <CondenseTableCell>{status ? 'Активный' : 'Архив'}</CondenseTableCell>

    <CondenseTableCell>{comment}</CondenseTableCell>
  </TableRow>
);

Row.propTypes = {
  creationDate: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.func.isRequired,
  stores: PropTypes.array.isRequired,
  filters: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  bonusName: PropTypes.string.isRequired,
};

export default Row;
