import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Field = ({ children, className, label }) => (
  <div className={className}>
    <Typography variant="subtitle1">{children}</Typography>
    <Typography variant="caption">{label}</Typography>
  </div>
);

Field.defaultProps = {
  className: '',
};

Field.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Field;
