import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/actionResult/consts/destinations';
import {
  ACTIVATE_ONCE_KEY,
  DELAY_AFTER_TRIGGER_KEY,
  DESTINATION_KEY,
  HAS_DELAY_AFTER_TRIGGER_KEY,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_STORE_ID_KEY,
  REWARD_BENEFITS_IDS_KEY,
  REWARD_LOYALTY_POINTS_KEY,
  REWARD_TYPE_KEY,
  SEND_PUSH_KEY,
  TRACKED_LOCATION_IDS_KEY,
  TRIGGER_KEY,
} from '@esentai/core/features/campaigns/actionResult/consts/keys';
import { LOYALTY_POINTS_REWARD } from '@esentai/core/features/campaigns/actionResult/consts/rewards';
import {
  LEAD_TRIGGER,
  LOCATION_AWARE_TRIGGERS,
} from '@esentai/core/features/campaigns/actionResult/consts/triggers';
import { array, boolean, number, object, string } from 'yup';

import { cleanUpValues } from './utils';

const requiredForPush = creator =>
  creator().when(SEND_PUSH_KEY, {
    is: true,
    then: creator().required(),
    otherwise: creator().nullable(),
  });
const requiredForReward = (rewardType, creator) =>
  creator().when(REWARD_TYPE_KEY, {
    is: rewardType,
    then: creator().required(),
    otherwise: creator().nullable(),
  });

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  // eslint-disable-next-line complexity
  mapPropsToValues: ({
    activateOnce,
    benefitRewardIds,
    delayAfterTrigger,
    destination,
    hasDelayAfterPurchase,
    loyaltyPointsReward,
    pushEnglishTitle,
    pushRussianTitle,
    pushEnglishMessage,
    pushRussianMessage,
    referredArtcleId,
    referredBenefitId,
    referredCalendarEventId,
    referredStoreId,
    rewardType,
    sendPushNotifications,
    trackedLocationIds,
    trigger,
  }) => ({
    [ACTIVATE_ONCE_KEY]: activateOnce || false,
    [DELAY_AFTER_TRIGGER_KEY]: delayAfterTrigger || '',
    [DESTINATION_KEY]: destination,
    [HAS_DELAY_AFTER_TRIGGER_KEY]: hasDelayAfterPurchase || false,
    [PUSH_ENGLISH_TITLE_KEY]: pushEnglishTitle || '',
    [PUSH_RUSSIAN_TITLE_KEY]: pushRussianTitle || '',
    [PUSH_ENGLISH_MESSAGE_KEY]: pushEnglishMessage || '',
    [PUSH_RUSSIAN_MESSAGE_KEY]: pushRussianMessage || '',
    [REFERRED_ARTICLE_ID_KEY]: referredArtcleId || null,
    [REFERRED_BENEFIT_ID_KEY]: referredBenefitId || null,
    [REFERRED_CALENDAR_EVENT_ID_KEY]: referredCalendarEventId || null,
    [REFERRED_STORE_ID_KEY]: referredStoreId || null,
    [REWARD_BENEFITS_IDS_KEY]: benefitRewardIds || [],
    [REWARD_LOYALTY_POINTS_KEY]: loyaltyPointsReward || 0,
    [REWARD_TYPE_KEY]: rewardType || null,
    [SEND_PUSH_KEY]: sendPushNotifications || false,
    [TRACKED_LOCATION_IDS_KEY]: trackedLocationIds || [],
    [TRIGGER_KEY]: trigger || LEAD_TRIGGER,
  }),

  validationSchema: object().shape({
    [DELAY_AFTER_TRIGGER_KEY]: number().when(HAS_DELAY_AFTER_TRIGGER_KEY, {
      is: true,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [DESTINATION_KEY]: string().nullable(),
    [HAS_DELAY_AFTER_TRIGGER_KEY]: boolean(),
    [PUSH_ENGLISH_TITLE_KEY]: requiredForPush(string).max(60),
    [PUSH_RUSSIAN_TITLE_KEY]: requiredForPush(string).max(60),
    [PUSH_ENGLISH_MESSAGE_KEY]: requiredForPush(string).max(200),
    [PUSH_RUSSIAN_MESSAGE_KEY]: requiredForPush(string).max(200),
    [REFERRED_ARTICLE_ID_KEY]: number().when(DESTINATION_KEY, {
      is: ARTICLE_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_BENEFIT_ID_KEY]: string().when(DESTINATION_KEY, {
      is: BENEFIT_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [REFERRED_CALENDAR_EVENT_ID_KEY]: number().when(DESTINATION_KEY, {
      is: CALENDAR_EVENT_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_STORE_ID_KEY]: number().when(DESTINATION_KEY, {
      is: STORE_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REWARD_LOYALTY_POINTS_KEY]: requiredForReward(
      LOYALTY_POINTS_REWARD,
      number,
    ),
    [REWARD_TYPE_KEY]: string().nullable(),
    [SEND_PUSH_KEY]: boolean(),
    [TRACKED_LOCATION_IDS_KEY]: array().when(TRIGGER_KEY, {
      is: LOCATION_AWARE_TRIGGERS,
      then: array()
        .of(number())
        .required(),
      otherwise: array().nullable(),
    }),
    [TRIGGER_KEY]: string().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    const cleanValues = cleanUpValues(values);

    props.onSubmit(cleanValues);
    setSubmitting(false);
  },
};

const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
