import {
  COMPANY_NAME_KEY,
  LAST_RECONCILIATION_KEY,
  STORE_NAME_KEY,
  TOTAL_TO_PAY_KEY,
} from '@esentai/core/features/reconciliation/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={STORE_NAME_KEY}>Магазин</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={COMPANY_NAME_KEY}>Арендатор</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TOTAL_TO_PAY_KEY}>
        Итоговая сумма к оплате
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Сумма потраченных бонусов</CondenseTableCell>

    <CondenseTableCell>Сумма бонусов на возврат</CondenseTableCell>

    {/* <CondenseTableCell>
      <SortControl field={OVERPAYMENT_KEY}>
        Сумма переплаты магазину
      </SortControl>
    </CondenseTableCell> */}

    <CondenseTableCell>
      <SortControl field={LAST_RECONCILIATION_KEY}>
        Дата последней реконсиляции
      </SortControl>
    </CondenseTableCell>

    {/* <CondenseTableCell>Действия</CondenseTableCell> */}
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
