import PropTypes from 'prop-types';
import React from 'react';

import StorePageLink from '@/containers/StorePageLink';

const Stores = ({ storesIds }) =>
  storesIds.map(storeId => <StorePageLink key={storeId} storeId={storeId} />);

Stores.propTypes = {
  storesIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Stores;
