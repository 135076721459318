import PropTypes from 'prop-types';

const BrandTitleLazy = ({ isLoaded, title }) => (isLoaded ? title : '...');

BrandTitleLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

BrandTitleLazy.defaultProps = {
  title: null,
};

export default BrandTitleLazy;
