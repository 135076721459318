import PropTypes from 'prop-types';

import { INVALID_TOKEN_STRATEGY } from './consts';

const strategies = [
  {
    name: INVALID_TOKEN_STRATEGY,
    headline: 'Несуществующий токен',
    message: 'Обратитесь к администратору для прояснения возникшей ситуации.',
  },
];

export const propTypes = PropTypes.oneOf(strategies);

export default strategies;
