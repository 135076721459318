import PropTypes from 'prop-types';
import React from 'react';

const getTitle = (title, onCutText, maxLength) => {
  if (onCutText && maxLength) {
    return onCutText(title, maxLength);
  }

  return title;
};

const ProductViewNameLazy = ({
  isLoaded,
  isLoading,
  title,
  onCutText,
  maxLength,
}) => (
  <span title={title}>
    {isLoading ? '...' : ''}{' '}
    {isLoaded ? getTitle(title, onCutText, maxLength) : ''}
  </span>
);

ProductViewNameLazy.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onCutText: PropTypes.func,
  maxLength: PropTypes.number,
};

ProductViewNameLazy.defaultProps = {
  title: null,
  onCutText: null,
  maxLength: null,
  isLastItem: false,
};

export default ProductViewNameLazy;
