import { Button, Paper, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Modal from '@/components/Modal';
import { REAL_IIN_KEY } from '@/features/ipnReportsPage/consts/keys';

const EditIin = props => {
  const {
    classes,
    cancelLabel,
    confirmLabel,
    onClose,
    onSubmit,
    Field,
    values,
    setFieldValue,
    isValid,
    iin,
  } = props;

  useEffect(() => {
    if (iin) {
      setFieldValue(REAL_IIN_KEY, iin);
    }
  }, [iin]);

  return (
    <Modal {...props} onClose={onClose}>
      <Paper className={classes.wrapper}>
        <div className={classes.system}>
          <span>ИИН в системе</span>
          <span>{iin}</span>
        </div>

        <div className={classes.real}>
          <span>ИИН настоящий</span>
          <Field
            className={classes.real_input}
            id={REAL_IIN_KEY}
            margin="none"
            Target={TextField}
            fullWidth
            inputProps={{ maxLength: 12 }}
            name={REAL_IIN_KEY}
            placeholder="ИИН настоящий"
          />
        </div>

        <div className={classes.footer}>
          <Button onClick={onClose}>{cancelLabel}</Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid}
            style={{ marginLeft: 15 }}
            onClick={() => onSubmit(values)}
          >
            {confirmLabel}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

EditIin.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  Field: PropTypes.func.isRequired,
  Form: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  requiredErrors: PropTypes.object,
  touched: PropTypes.object,
  state: PropTypes.object,
  values: PropTypes.object,
  resetForm: PropTypes.func,
  iin: PropTypes.string,
};

EditIin.defaultProps = {
  values: {},
  state: {},
  touched: {},
  setFieldValue: () => {},
  requiredErrors: {},
  resetForm: () => {},
  iin: '',
};

export default EditIin;
