import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getGiftSetError,
  getItemsIds: getGiftSetIds,
  getItemsMeta: getGiftSetMeta,
  getItemsTotalCount: getGiftSetTotalCount,
  isLoadingItems: isLoadingGiftSet,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
