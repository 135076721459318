import tasks from '@esentai/core/ducks/tasks/reducer';
import applicationSettings from '@esentai/core/features/applicationSettings/reducer';
import beacons from '@esentai/core/features/beacons/reducer';
import benefitItems from '@esentai/core/features/benefitItems/reducer';
import benefits from '@esentai/core/features/benefits/reducer';
import bonusTransactions from '@esentai/core/features/bonus_transactions/reducer';
import brands from '@esentai/core/features/brands/reducer';
import campaigns from '@esentai/core/features/campaigns/reducer';
import creditCards from '@esentai/core/features/creditCards/reducer';
import efsVisitRecords from '@esentai/core/features/efsVisitRecords/reducer';
import giftCertificates from '@esentai/core/features/giftCertificates/reducer';
import giftSets from '@esentai/core/features/giftSets/reducer';
import historyData from '@esentai/core/features/history_data/reducer';
import kkms from '@esentai/core/features/kkms/reducer';
import locations from '@esentai/core/features/locations/reducer';
import loyalties from '@esentai/core/features/loyalties/reducer';
import mallBonuses from '@esentai/core/features/mallBonuses/reducer';
import mallStats from '@esentai/core/features/mallStats/reducer';
import merchants from '@esentai/core/features/merchants/reducer';
import merchantsReconciliation from '@esentai/core/features/merchantsReconciliation/reducer';
import merchantsReconciliationArchive from '@esentai/core/features/merchantsReconciliationArchive/reducer';
import merchantsReconciliationHistory from '@esentai/core/features/merchantsReconciliationHistory/reducer';
import merchantsReconciliationReceipts from '@esentai/core/features/merchantsReconciliationReceipts/reducer';
import merchantsReconciliationRefunds from '@esentai/core/features/merchantsReconciliationRefunds/reducer';
import parkingLevelThreeWhitelist from '@esentai/core/features/parkingLevelThreeWhitelist/reducer';
import parkingTowerStopList from '@esentai/core/features/parkingTowerStopList/reducer';
import parkingWhitelist from '@esentai/core/features/parkingWhitelist/reducer';
import parkingWhitelistFilter from '@esentai/core/features/parkingWhitelistFilter/reducer';
import parkingWhitelistGroups from '@esentai/core/features/parkingWhitelistGroups/reducer';
import productCategories from '@esentai/core/features/productCategories/reducer';
import productCategory from '@esentai/core/features/productCategory/reducer';
import products from '@esentai/core/features/products/reducer';
import productView from '@esentai/core/features/productView/reducer';
import purchases from '@esentai/core/features/purchases/reducer';
import questions from '@esentai/core/features/questions/reducer';
import receipts from '@esentai/core/features/receipts/reducer';
import reconciliations from '@esentai/core/features/reconciliation/reducer';
import reconciliationArchive from '@esentai/core/features/reconciliationArchive/reducer';
import reconciliationArchiveReceipts from '@esentai/core/features/reconciliationArchiveReceipts/reducer';
import reconciliationArchiveRefunds from '@esentai/core/features/reconciliationArchiveRefunds/reducer';
import reconciliationHistory from '@esentai/core/features/reconciliationHistory/reducer';
import reconciliationPeriodBonusDetails from '@esentai/core/features/reconciliationPeriodBonusDetails/reducer';
import reconciliationReceiptBonusDetails from '@esentai/core/features/reconciliationReceiptBonusDetails/reducer';
import reconciliationReceipts from '@esentai/core/features/reconciliationReceipts/reducer';
import reconciliationRefunds from '@esentai/core/features/reconciliationRefunds/reducer';
import reconciliationStoreBonusDetails from '@esentai/core/features/reconciliationStoreBonusDetails/reducer';
import reconciliationsStoreToCp from '@esentai/core/features/reconciliationStoreToCp/reducer';
import salesTurnoverDetails from '@esentai/core/features/salesTurnoverDetails/reducer';
import salesTurnovers from '@esentai/core/features/salesTurnovers/reducer';
import storeBonuses from '@esentai/core/features/storeBonuses/reducer';
import stores from '@esentai/core/features/stores/reducer';
import users from '@esentai/core/features/users/reducer';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import anonymousPurchasesStatisticsPage from '@/features/anonymousPurchasesStatisticsPage/reducer';
import auth from '@/features/auth/reducer';
import beaconsPage from '@/features/beaconsPage/reducer';
import benefitItemsPage from '@/features/benefitItemsPage/reducer';
import benefitsPage from '@/features/benefitsPage/reducer';
import bonusesHistoryPage from '@/features/bonusesHistoryPage/reducer';
import bonusTransactionLevelHistoryPage from '@/features/bonusTransactionLevelHistoryPage/reducer';
import brandsPage from '@/features/brandsPage/reducer';
import campaignCreateUserFilterPage from '@/features/campaignCreateUserFilterPage/reducer';
import campaignsPage from '@/features/campaignsPage/reducer';
import campaignsStatistics from '@/features/campaignsStatistics/reducer';
import campaignsStatisticsDetailsPage from '@/features/campaignsStatisticsDetailsPage/reducer';
import campaignsStatisticsPage from '@/features/campaignsStatisticsPage/reducer';
import efsVisitRecordsPage from '@/features/efsVisitRecordsPage/reducer';
import executiveReportsPage from '@/features/executiveReportsPage/reducer';
import giftCertificatesPage from '@/features/giftCertificatesPage/reducer';
import giftSetsPage from '@/features/giftSetsPage/reducer';
import invites from '@/features/invites/reducer';
import kkmsPage from '@/features/kkmsPage/reducer';
import locationsPage from '@/features/locationsPage/reducer';
import locationsStatistics from '@/features/locationsStatistics/reducer';
import locationsStatisticsPage from '@/features/locationsStatisticsPage/reducer';
import loyaltyHistoryPage from '@/features/loyaltyHistoryPage/reducer';
import mallBonusesPage from '@/features/mallBonusesPage/reducer';
import merchantsPage from '@/features/merchantsPage/reducer';
import merchantsReconciliationArchivePage from '@/features/merchantsReconciliationArchivePage/reducer';
import merchantsReconciliationDetailsPage from '@/features/merchantsReconciliationDetailsPage/reducer';
import merchantsReconciliationPage from '@/features/merchantsReconciliationPage/reducer';
import merchantsReconciliationReceiptsPage from '@/features/merchantsReconciliationReceiptsPage/reducer';
import merchantsReconciliationRefundsPage from '@/features/merchantsReconciliationRefundsPage/reducer';
import parkingLevelThreeWhitelistAddPage from '@/features/parkingLevelThreeWhitelistAddPage/reducer';
import parkingLevelThreeWhitelistPage from '@/features/parkingLevelThreeWhitelistPage/reducer';
import parkingTowerStopListAddPage from '@/features/parkingTowerStopListAddPage/reducer';
import parkingTowerStopListPage from '@/features/parkingTowerStopListPage/reducer';
import parkingWhitelistDynamicFilterPage from '@/features/parkingWhitelistDynamicFilterPage/reducer';
import parkingWhitelistGroupDetailsPage from '@/features/parkingWhitelistGroupDetailsPage/reducer';
import parkingWhitelistGroupsPage from '@/features/parkingWhitelistGroupsPage/reducer';
import parkingWhitelistPage from '@/features/parkingWhitelistPage/reducer';
import parkingWhitelistStaticFilterPage from '@/features/parkingWhitelistStaticFilterPage/reducer';
import personnel from '@/features/personnel/reducer';
import personnelPage from '@/features/personnelPage/reducer';
import productsCategoryPage from '@/features/productsCategoryPage/reducer';
import productsPage from '@/features/productsPage/reducer';
import productsViewPage from '@/features/productsViewPage/reducer';
import purchaseHistoryPage from '@/features/purchaseHistoryPage/reducer';
import purchasesStatistics from '@/features/purchasesStatistics/reducer';
import purchasesStatisticsPage from '@/features/purchasesStatisticsPage/reducer';
import questionsPage from '@/features/questionsPage/reducer';
import reconciliationArchivePage from '@/features/reconciliationArchivePage/reducer';
import reconciliationArchiveReceiptsPage from '@/features/reconciliationArchiveReceiptsPage/reducer';
import reconciliationArchiveRefundsPage from '@/features/reconciliationArchiveRefundsPage/reducer';
import reconciliationHistoryPage from '@/features/reconciliationHistoryPage/reducer';
import reconciliationPage from '@/features/reconciliationPage/reducer';
import reconciliationPeriodBonusDetailsPage from '@/features/reconciliationPeriodBonusDetailsPage/reducer';
import reconciliationPromotionsPage from '@/features/reconciliationPromotionsPage/reducer';
import reconciliationReceiptBonusDetailsPage from '@/features/reconciliationReceiptBonusDetailsPage/reducer';
import reconciliationReceiptsPage from '@/features/reconciliationReceiptsPage/reducer';
import reconciliationRefundsPage from '@/features/reconciliationRefundsPage/reducer';
import reconciliationStoreBonusDetailsPage from '@/features/reconciliationStoreBonusDetailsPage/reducer';
import reconciliationStoreToCpPage from '@/features/reconciliationStoreToCpPage/reducer';
import salesTurnoverDetailsPage from '@/features/salesTurnoverDetailsPage/reducer';
import salesTurnoversPage from '@/features/salesTurnoversPage/reducer';
import storeBonusesPage from '@/features/storeBonusesPage/reducer';
import storeReceiptsPage from '@/features/storeReceiptsPage/reducer';
import storesPage from '@/features/storesPage/reducer';
import upload from '@/features/upload/reducer';
import userDeactivate from '@/features/userDeactivate/reducer';
import userPage from '@/features/userPage/reducer';
import usersPage from '@/features/usersPage/reducer';
import usersStatistics from '@/features/usersStatistics/reducer';
import usersStatisticsPage from '@/features/usersStatisticsPage/reducer';

import { ENTITIES_STORE_KEY, PAGES_STORE_KEY, UI_KEY } from './constants';

export default history =>
  combineReducers({
    auth,

    [ENTITIES_STORE_KEY]: combineReducers({
      applicationSettings,
      beacons,
      benefitItems,
      benefits,
      bonusTransactions,
      brands,
      campaigns,
      campaignsStatistics,
      creditCards,
      efsVisitRecords,
      giftCertificates,
      giftSets,
      history_data: historyData,
      invites,
      kkms,
      locations,
      locationsStatistics,
      loyalties,
      mallBonuses,
      mallStats,
      merchants,
      merchantsReconciliation,
      merchantsReconciliationArchive,
      merchantsReconciliationHistory,
      merchantsReconciliationReceipts,
      merchantsReconciliationRefunds,
      parkingLevelThreeWhitelist,
      parkingTowerStopList,
      parkingWhitelist,
      parkingWhitelistFilter,
      parkingWhitelistGroups,
      personnel,
      productCategories,
      productCategory,
      productView,
      products,
      purchases,
      purchasesStatistics,
      questions,
      receipts,
      reconciliationArchive,
      reconciliationArchiveReceipts,
      reconciliationArchiveRefunds,
      reconciliationHistory,
      reconciliationPeriodBonusDetails,
      reconciliationReceiptBonusDetails,
      reconciliationReceipts,
      reconciliationRefunds,
      reconciliationStoreBonusDetails,
      reconciliations,
      reconciliationsStoreToCp,
      salesTurnoverDetails,
      salesTurnovers,
      storeBonuses,
      stores,
      upload,
      users,
      usersStatistics,
    }),

    [PAGES_STORE_KEY]: combineReducers({
      anonymousPurchasesStatisticsPage,
      beaconsPage,
      benefitItemsPage,
      benefitsPage,
      bonusTransactionLevelHistoryPage,
      bonusesHistoryPage,
      brandsPage,
      campaignCreateUserFilterPage,
      campaignsPage,
      campaignsStatisticsDetailsPage,
      campaignsStatisticsPage,
      efsVisitRecordsPage,
      executiveReportsPage,
      giftCertificatesPage,
      giftSetsPage,
      kkmsPage,
      locationsPage,
      locationsStatisticsPage,
      loyaltyHistoryPage,
      mallBonusesPage,
      merchantsPage,
      merchantsReconciliationArchivePage,
      merchantsReconciliationDetailsPage,
      merchantsReconciliationPage,
      merchantsReconciliationReceiptsPage,
      merchantsReconciliationRefundsPage,
      parkingLevelThreeWhitelistAddPage,
      parkingLevelThreeWhitelistPage,
      parkingTowerStopListAddPage,
      parkingTowerStopListPage,
      parkingWhitelistDynamicFilterPage,
      parkingWhitelistGroupDetailsPage,
      parkingWhitelistGroupsPage,
      parkingWhitelistPage,
      parkingWhitelistStaticFilterPage,
      personnelPage,
      productsCategoryPage,
      productsPage,
      productsViewPage,
      purchaseHistoryPage,
      purchasesStatisticsPage,
      questionsPage,
      reconciliationArchivePage,
      reconciliationArchiveReceiptsPage,
      reconciliationArchiveRefundsPage,
      reconciliationHistoryPage,
      reconciliationPage,
      reconciliationPeriodBonusDetailsPage,
      reconciliationPromotionsPage,
      reconciliationReceiptBonusDetailsPage,
      reconciliationReceiptsPage,
      reconciliationRefundsPage,
      reconciliationStoreBonusDetailsPage,
      reconciliationStoreToCpPage,
      salesTurnoverDetailsPage,
      salesTurnoversPage,
      storeBonusesPage,
      storeReceiptsPage,
      storesPage,
      userPage,
      usersPage,
      usersStatisticsPage,
    }),

    [UI_KEY]: combineReducers({
      userDeactivate,
    }),

    router: connectRouter(history),
    tasks,
  });
