import { Hidden } from '@material-ui/core';
import React from 'react';

const widthContentOnly = {
  width: '1px',
};

const ColGroup = () => (
  <colgroup>
    <col style={widthContentOnly} />
    <col style={widthContentOnly} />
    <col style={widthContentOnly} />
    <col style={widthContentOnly} />
    <Hidden lgDown>
      <col style={widthContentOnly} />
    </Hidden>
    <col style={widthContentOnly} />
    <col style={{ width: 240 }} />
  </colgroup>
);

export default ColGroup;
