import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Status = ({ itemId, StatusIndicator, StatusLabel }) => (
  <Typography>
    <StatusIndicator itemId={itemId} /> <StatusLabel itemId={itemId} />
  </Typography>
);

Status.propTypes = {
  itemId: PropTypes.number.isRequired,
  StatusIndicator: PropTypes.func.isRequired,
  StatusLabel: PropTypes.func.isRequired,
};

export default Status;
