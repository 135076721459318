import { handleActions } from 'redux-actions';

import mount from './mount';
import removeFilters from './removeFilters';
import sync from './sync';
import unmount from './unmount';
import updateFilters from './updateFilters';
import updateLimit from './updateLimit';
import updatePage from './updatePage';
import updateSort from './updateSort';

export default ({ actionNames, serializer }) =>
  handleActions(
    {
      [actionNames.mount]: mount({ serializer }),
      [actionNames.sync]: sync({ serializer }),
      [actionNames.unmount]: unmount(),
      [actionNames.removeFilters]: removeFilters(),
      [actionNames.updateFilters]: updateFilters(),
      [actionNames.updateLimit]: updateLimit(),
      [actionNames.updatePage]: updatePage(),
      [actionNames.updateSort]: updateSort(),
    },
    null,
  );
