export default {
  root: {
    display: 'inline-block',
    width: 160,
  },

  container: {
    display: 'flex',
  },
};
