import { findUserById } from '@esentai/core/features/users/actions';
import {
  getPhoneForUser,
  isLoadedUser,
  isLoadingUser,
} from '@esentai/core/features/users/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import UserPhoneLazy from './component';

const mapStateToProps = (state, { storeId, onCutText, maxLength }) => ({
  isLoaded: isLoadedUser(state, storeId),
  isLoading: isLoadingUser(state, storeId),
  itemId: storeId,
  title: getPhoneForUser(state, storeId),
  onCutText,
  maxLength,
});

const mapDispatchToProps = { fetchItem: findUserById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(UserPhoneLazy);
