import {
  BONUS_NAME_KEY,
  END_DATETIME_KEY,
  START_DATETIME_KEY,
  STORE_NAME_KEY,
} from '@esentai/core/features/reconciliationStoreBonusDetails/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: BONUS_NAME_KEY,
    label: 'Название акции',
  }),
  createDateRangeFilter({
    key: START_DATETIME_KEY,
    label: 'Период старта акции',
  }),
  createDateRangeFilter({
    key: END_DATETIME_KEY,
    label: 'Период завершения акции',
  }),
  createTextFilter({
    key: STORE_NAME_KEY,
    label: 'Магазин',
  }),
];
