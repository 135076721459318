import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose, T } from 'ramda';
import { connect } from 'react-redux';

import RemoveButton from '@/components/ActionButtons/Remove';
import { deleteBrand } from '@/features/brandsPage/actions';

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(deleteBrand(itemId)),
  permission: T,
});

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(RemoveButton);
