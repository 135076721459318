import {
  SINGLE_IMAGE_LAYOUT,
  SLIDER_IMAGES_LAYOUT,
  STACK_IMAGES_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/imageLayouts';
import {
  ID_KEY,
  IMAGES_LAYOUT_KEY,
  IMAGES_URLS_KEY,
  LOCATION_ID_KEY,
  ONLINE_SHOWCASE_KEY,
  PARAGRAPH_ENGLISH_TEXT_KEY,
  PARAGRAPH_KAZAKH_TEXT_KEY,
  PARAGRAPH_RUSSIAN_TEXT_KEY,
  PAYLOAD_KEY,
  TITLE_ENGLISH_KEY,
  TITLE_KAZAKH_KEY,
  TITLE_RUSSIAN_KEY,
  TYPE_KEY,
  VIDEO_VIMEO_URL_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import {
  EMPTY_LAYOUT,
  IMAGES_LAYOUT,
  LOCATION_LAYOUT,
  ONLINE_SHOWCASE_LAYOUT,
  PARAGRAPH_LAYOUT,
  TITLE_LAYOUT,
  VIDEO_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/layouts';
import { array, number, object, string } from 'yup';

import {
  SUBTITLE_ENGLISH_KEY,
  SUBTITLE_LAYOUT,
  SUBTITLE_RUSSIAN_KEY,
} from './consts';

const imagePayload = {
  [IMAGES_LAYOUT_KEY]: string()
    .oneOf([SINGLE_IMAGE_LAYOUT, STACK_IMAGES_LAYOUT, SLIDER_IMAGES_LAYOUT])
    .required(),
  [IMAGES_URLS_KEY]: array()
    // .of(string().matches(urlValidator, 'Введите валидный URL-адрес'))
    .min(1)
    .required(),
};

const titlePayload = {
  [TITLE_RUSSIAN_KEY]: string()
    .max(50)
    .required(),
  [TITLE_ENGLISH_KEY]: string()
    .max(50)
    .required(),
  [TITLE_KAZAKH_KEY]: string()
    .max(50)
    .required(),
};

const subTitlePayload = {
  [SUBTITLE_RUSSIAN_KEY]: string()
    .max(150)
    .required(),
  [SUBTITLE_ENGLISH_KEY]: string()
    .max(150)
    .required(),
};

const paragraphPayload = {
  [PARAGRAPH_RUSSIAN_TEXT_KEY]: string()
    .max(1500)
    .required(),
  [PARAGRAPH_ENGLISH_TEXT_KEY]: string()
    .max(1500)
    .required(),
  [PARAGRAPH_KAZAKH_TEXT_KEY]: string()
    .max(1500)
    .required(),
};

const videoPayload = {
  [VIDEO_VIMEO_URL_KEY]: string()
    .url()
    .required(),
};

const locationPayload = {
  [LOCATION_ID_KEY]: number().required(),
};

const onlineShowCasePayload = {
  [ONLINE_SHOWCASE_KEY]: number().nullable(true),
};

const typeMapping = {
  [EMPTY_LAYOUT]: {},
  [IMAGES_LAYOUT]: imagePayload,
  [TITLE_LAYOUT]: titlePayload,
  [SUBTITLE_LAYOUT]: subTitlePayload,
  [PARAGRAPH_LAYOUT]: paragraphPayload,
  [VIDEO_LAYOUT]: videoPayload,
  [LOCATION_LAYOUT]: locationPayload,
  [ONLINE_SHOWCASE_LAYOUT]: onlineShowCasePayload,
};

const block = object({
  [ID_KEY]: string().required(),
  [TYPE_KEY]: string()
    .oneOf([
      EMPTY_LAYOUT,
      IMAGES_LAYOUT,
      TITLE_LAYOUT,
      SUBTITLE_LAYOUT,
      PARAGRAPH_LAYOUT,
      VIDEO_LAYOUT,
      LOCATION_LAYOUT,
      ONLINE_SHOWCASE_LAYOUT,
    ])
    .required(),
  [PAYLOAD_KEY]: object()
    .when(TYPE_KEY, (type, schema) => schema.shape(typeMapping[type]))
    .required(),
});

export default array()
  .of(block)
  .min(3)
  .required();
