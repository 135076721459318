import { validateYupSchema } from 'formik';

export const getValidationErrors = async (...args) => {
  try {
    await validateYupSchema(...args);

    return null;
  } catch (err) {
    return err;
  }
};
