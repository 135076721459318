import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormatExact } from '@/utils/format';

import {
  CUSTOMER_KEY,
  GATE_ID_KEY,
  GATE_NAME_KEY,
  RECORD_CREATED_KEY,
  VEHICLE_GOV_NUMBER_KEY,
} from '../../consts/keys';

const loyaltyLevels = {
  1: 'Orange',
  2: 'Black',
  3: 'JetBlack',
};

const Row = props => {
  const {
    id,
    [CUSTOMER_KEY]: customer,
    // [CLIENT_TIMESTAMP_KEY]: timestamp,
    [VEHICLE_GOV_NUMBER_KEY]: vehicle_gov_number,
    [GATE_ID_KEY]: gate_id,
    [GATE_NAME_KEY]: gate_name,
    [RECORD_CREATED_KEY]: record_created,
  } = props.item;

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{gate_id}</TableCell>
      <TableCell>{gate_name}</TableCell>
      <TableCell>
        <Link to={createUserPageUrl(customer.id)}>{customer.id}</Link>
      </TableCell>
      <TableCell>
        {customer.last_name} {customer.first_name}
      </TableCell>
      <TableCell>{loyaltyLevels[customer.level]}</TableCell>
      {/* <TableCell>{customer.email}</TableCell>*/}
      <TableCell>{customer.phone}</TableCell>

      <TableCell>{vehicle_gov_number}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {record_created
          ? dateTimeFormatExact(record_created, 'DD-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
