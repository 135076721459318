import PropTypes from 'prop-types';
import React from 'react';

import ConversionFactoid from '@/features/campaignsStatisticsDetailsPage/Page/ConversionFactoid';
import Factoid from '@/features/campaignsStatisticsDetailsPage/Page/Factoid';
import { formatNumber } from '@/utils/format';

const ActionResultFacts = ({
  classes,
  segmentSize,
  prePushClicksCount,
  activationsCount,
  conversion,
  activationsToSegmentPercentage,
  pushClicksToActivationsPercentage,
}) => (
  <div className={classes.wrapper}>
    <Factoid
      name="Сегмент"
      value={formatNumber(segmentSize)}
      ratio={activationsToSegmentPercentage}
      description="Общее количество пользователей, для которых предназначается кампания."
    />
    <Factoid
      name="Активация"
      value={formatNumber(activationsCount)}
      ratio={pushClicksToActivationsPercentage}
      description="Сколько раз были выполнены условия кампании"
    />
    <Factoid
      name="Переходы"
      value={formatNumber(prePushClicksCount)}
      classes={classes}
      description="Количество пользователей, нажавших на предварительное push-уведомление"
    />
    <ConversionFactoid classes={classes} conversion={conversion} />
  </div>
);

ActionResultFacts.propTypes = {
  segmentSize: PropTypes.number.isRequired,
  prePushClicksCount: PropTypes.number.isRequired,
  activationsCount: PropTypes.number.isRequired,
  conversion: PropTypes.number.isRequired,
  activationsToSegmentPercentage: PropTypes.number.isRequired,
  pushClicksToActivationsPercentage: PropTypes.number.isRequired,
};

export default ActionResultFacts;
