import { spawn } from 'redux-saga/effects';

import { createStatisticsQueryWatcher } from '@/features/statisticsPage/sagas';

import items from './ducks/items';
import query from './ducks/query';

export const { fetchItems } = items.sagas;
export const { synchronizeQuery } = query.sagas;

export const watchQueryChange = createStatisticsQueryWatcher(query, fetchItems);

export default function*() {
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
