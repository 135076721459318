import {
  getAgeForParkingWhitelist,
  getEmailForParkingWhitelist,
  getFirstNameForParkingWhitelist,
  getGenderForParkingWhitelist,
  getGroupIdForParkingWhitelist,
  getIsPhoneVerifiedForParkingWhitelist,
  getLastNameForParkingWhitelist,
  getLevelForParkingWhitelist,
  getPhoneForParkingWhitelist,
  getRecordCreatedForParkingWhitelist,
  getStateForParkingWhitelist,
} from '@esentai/core/features/parkingWhitelist/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: parkingWhitelistId }) => ({
  age: getAgeForParkingWhitelist(state, parkingWhitelistId),
  email: getEmailForParkingWhitelist(state, parkingWhitelistId),
  firstName: getFirstNameForParkingWhitelist(state, parkingWhitelistId),
  lastName: getLastNameForParkingWhitelist(state, parkingWhitelistId),
  gender: getGenderForParkingWhitelist(state, parkingWhitelistId),
  recordCreated: getRecordCreatedForParkingWhitelist(state, parkingWhitelistId),
  isPhoneVerified: getIsPhoneVerifiedForParkingWhitelist(
    state,
    parkingWhitelistId,
  ),
  state: getStateForParkingWhitelist(state, parkingWhitelistId),
  level: getLevelForParkingWhitelist(state, parkingWhitelistId),
  phone: getPhoneForParkingWhitelist(state, parkingWhitelistId),
  groupId: getGroupIdForParkingWhitelist(state, parkingWhitelistId),
  parkingWhitelistId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
