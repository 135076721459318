import PropTypes from 'prop-types';
import React from 'react';

import { formatHumanDateTime, formatMoney } from '@/utils/format';

import Field from './Field';

const ReceiptDetails = ({
  bin,
  kkmNumber,
  totalCost,
  purchaseDate,
  activeBonuses,
  giftBonuses,
  activeBonusesRefund,
  giftBonusesRefund,
  expiredRefundBonuses,
  classes,
}) => (
  <div className={classes.facts}>
    <Field label="Номер ККМ" className={classes.fact}>
      {kkmNumber}
    </Field>
    <Field label="БИН/ИИН" className={classes.fact}>
      {bin}
    </Field>
    <Field label="Общая сумма чека" className={classes.fact}>
      {formatMoney(totalCost)}
    </Field>
    <Field label="Дата и время" className={classes.fact}>
      {formatHumanDateTime(new Date(purchaseDate))}
    </Field>
    {activeBonuses > 0 && (
      <Field label="Активные бонусы" className={classes.fact}>
        {formatMoney(activeBonuses)}
      </Field>
    )}
    {giftBonuses > 0 && (
      <Field label="Подарочные бонусы" className={classes.fact}>
        {formatMoney(giftBonuses)}
      </Field>
    )}
    {activeBonusesRefund > 0 && (
      <Field label="Возврат активных бонусов" className={classes.fact}>
        {formatMoney(activeBonusesRefund)}
      </Field>
    )}
    {giftBonusesRefund > 0 && (
      <Field label="Возврат подарочных бонусов" className={classes.fact}>
        {formatMoney(giftBonusesRefund)}
      </Field>
    )}
    {expiredRefundBonuses > 0 && (
      <Field
        label="Истекшие подарочные бонусы на возврат"
        className={classes.fact}
      >
        {formatMoney(expiredRefundBonuses)}
      </Field>
    )}
  </div>
);

ReceiptDetails.propTypes = {
  bin: PropTypes.string.isRequired,
  kkmNumber: PropTypes.string.isRequired,
  totalCost: PropTypes.number.isRequired,
  activeBonuses: PropTypes.number,
  giftBonuses: PropTypes.number,
  activeBonusesRefund: PropTypes.number,
  giftBonusesRefund: PropTypes.number,
  expiredRefundBonuses: PropTypes.number,
  purchaseDate: PropTypes.string.isRequired,
};

ReceiptDetails.defaultProps = {
  activeBonuses: 0,
  giftBonuses: 0,
  activeBonusesRefund: 0,
  giftBonusesRefund: 0,
  expiredRefundBonuses: 0,
};

export default ReceiptDetails;
