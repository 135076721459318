import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import CanView from '@/containers/CanView';
import WhitelistGroupNameLazy from '@/containers/WhitelistGroupNameLazy';
import Actions from '@/features/parkingWhitelistGroupDetailsPage/Page/Row/Actions';
import { canUpdateWhitelist } from '@/features/parkingWhitelistPage/permissions';
import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormat } from '@/utils/format';
import { userGender, userStatus } from '@/utils/labels';

const Row = ({
  classes,
  parkingWhitelistId,
  SelectionToggle,
  firstName,
  groupId,
  lastName,
  recordCreated,
  phone,
  gender,
  level,
}) => (
  <TableRow>
    <TableCell padding="checkbox">
      <SelectionToggle item={parkingWhitelistId} />
    </TableCell>
    <TableCell>
      <Link to={createUserPageUrl(parkingWhitelistId)}>
        {parkingWhitelistId}
      </Link>
    </TableCell>
    <TableCell>{lastName || '–'}</TableCell>
    <TableCell>{firstName || '–'}</TableCell>
    <TableCell className={classes.minWidth}>
      {userStatus(level) || '–'}
    </TableCell>
    <TableCell className={classes.minWidth}>{phone || '–'}</TableCell>
    {/* <TableCell>{email || '–'}</TableCell>*/}
    {/* <TableCell>{age || '–'}</TableCell>*/}
    <TableCell>{userGender(gender) || '–'}</TableCell>
    {/* <TableCell style={{ minWidth: 150 }}>*/}
    {/*  {isPhoneVerified ? 'Да' : 'Нет'}*/}
    {/* </TableCell>*/}
    {/* <TableCell>{activityStatus(state === 'active') || '–'}</TableCell>*/}
    <TableCell style={{ whiteSpace: 'nowrap' }}>
      {Boolean(groupId) && <WhitelistGroupNameLazy groupId={groupId} />}
    </TableCell>
    <TableCell style={{ whiteSpace: 'nowrap' }}>
      {dateTimeFormat(recordCreated) || '–'}
    </TableCell>
    <CanView permission={canUpdateWhitelist}>
      <TableCell>
        <Actions parkingWhitelistId={parkingWhitelistId} />
      </TableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  parkingWhitelistId: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  state: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.string,
  recordCreated: PropTypes.instanceOf(Date),
  isPhoneVerified: PropTypes.bool,
  email: PropTypes.string,
  age: PropTypes.number,
  level: PropTypes.string,
  phone: PropTypes.string,
  SelectionToggle: PropTypes.func.isRequired,
  groupId: PropTypes.number,
};
Row.defaultProps = {
  isActive: null,
  state: '',
  firstName: '',
  lastName: '',
  gender: '',
  groupId: null,
  recordCreated: null,
  isPhoneVerified: false,
  email: '',
  age: null,
  level: '',
  phone: '',
};

export default Row;
