import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CanView from '@/components/CanView';

const connected = connect((state, { permission, ...rest }) => ({
  isVisible: permission(state, rest),
}))(CanView);

connected.propTypes = {
  permission: PropTypes.func.isRequired,
};

export default connected;
