import { MenuItem, Select as MuiSelect } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const Select = ({ value, items, onChange, ...props }) => (
  <MuiSelect
    {...props}
    value={value}
    onChange={event => onChange(event.target.value)}
    IconComponent={KeyboardArrowDown}
    disableUnderline
  >
    {items.map(item => (
      <MenuItem key={item.key} value={item.key} dense>
        {item.label}
      </MenuItem>
    ))}
  </MuiSelect>
);

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
};

export default Select;
