import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Link from '@/components/Link';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { dateTimeFormat, formatMoney } from '@/utils/format';

import ReceiptModal from '../../purchaseHistoryPage/Page/ReceiptModal';
import { createResourceIdURL } from '../../reconciliationPeriodBonusDetailsPage/utils';
import { ActiveBonusesTable } from './ActiveBonusesTable/ActiveBonusesTable';
import Description from './Description';
import { PendingBonusesTable } from './PendingBonusesTable/PendingBonusesTable';

const activeBonusesTableHeaders = [
  'UUID',
  'Активные бонусы',
  'Дата и время покупки',
  'Общая сумма чека',
];

const pendingBonusesTableHeaders = [
  'UUID',
  'Бонусы к начислению',
  'Дата и время покупки',
  'Общая сумма чека',
];

class ReconciliationReceiptBonusDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReceiptDialogOpen: false,
      currentFiscalId: '',
    };
  }

  handleReceiptModal = () => {
    this.setState({ isReceiptDialogOpen: !this.state.isReceiptDialogOpen });
  };

  setCurrentFiscal = fiscal_id => {
    this.setState({
      currentFiscalId: fiscal_id,
      isReceiptDialogOpen: !this.state.isReceiptDialogOpen,
    });
  };

  static propTypes = {
    detailsId: PropTypes.string.isRequired,
    itemId: PropTypes.number.isRequired,
    historyName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    endDatetime: '-',
    startDatetime: '-',
  };

  render() {
    const { classes, detailsId, itemId, historyName } = this.props;
    const { currentFiscalId, isReceiptDialogOpen } = this.state;
    const backPath = createResourceIdURL(detailsId);

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={backPath}>Детализация за период</Breadcrumb>
          </Breadcrumbs>
          <PageTitle gutterBottom={false}>
            Детализация бонусов по чеку
          </PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Description itemId={itemId} />
            <PendingBonusesTable
              detailId={detailsId}
              cmId={itemId}
              headers={pendingBonusesTableHeaders}
              excelName={historyName}
            >
              {rows =>
                rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link
                        to="#"
                        onClick={() => this.setCurrentFiscal(row.fiscal_id)}
                      >
                        {row.fiscal_id}
                      </Link>
                    </TableCell>
                    <TableCell>{row.pending_bonuses}</TableCell>
                    <TableCell>{dateTimeFormat(row.receipt_created)}</TableCell>
                    <TableCell>{formatMoney(row.total_cost)}</TableCell>
                  </TableRow>
                ))
              }
            </PendingBonusesTable>
            <ActiveBonusesTable
              detailId={detailsId}
              cmId={itemId}
              headers={activeBonusesTableHeaders}
              excelName={historyName}
            >
              {rows =>
                rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link
                        to="#"
                        onClick={() => this.setCurrentFiscal(row.fiscal_id)}
                      >
                        {row.fiscal_id}
                      </Link>
                    </TableCell>
                    <TableCell>{row.active_bonuses}</TableCell>
                    <TableCell>{dateTimeFormat(row.receipt_created)}</TableCell>
                    <TableCell>{formatMoney(row.total_cost)}</TableCell>
                  </TableRow>
                ))
              }
            </ActiveBonusesTable>
            {isReceiptDialogOpen && (
              <ReceiptModal
                uuid={currentFiscalId}
                receipt={itemId}
                isOpen={isReceiptDialogOpen}
                onClose={this.handleReceiptModal}
              />
            )}
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default ReconciliationReceiptBonusDetails;
