import { connect } from 'react-redux';

import Select from '@/components/StatisticsTopBar/Select';
import { updateStat } from '@/features/locationsStatisticsPage/actions';
import { getStatId } from '@/features/locationsStatisticsPage/selectors';
import stats from '@/features/locationsStatisticsPage/stats';

const mapStateToProps = state => ({
  items: stats.map(stat => ({
    key: stat.url_key,
    label: stat.label,
  })),
  value: getStatId(state),
});

const mapDispatchToProps = { onChange: updateStat };

export default connect(mapStateToProps, mapDispatchToProps)(Select);
