import { MERCHANT_ID_KEY } from '@esentai/core/features/merchantsReconciliationReceipts/consts/keys';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { getMerchantId } from './selectors';
import { RETRY_FETCH } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export function* fetchItemsForCurrentStore(query) {
  const merchantId = yield select(getMerchantId);

  const merchantQuery = mergeQueriesRight(
    query,
    makeQuery(where(MERCHANT_ID_KEY, equals(merchantId))),
  );

  yield call(itemsDuck.sagas.fetchItems, merchantQuery);
}

export const fetchItems = createQuerySaga(fetchItemsForCurrentStore);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
