import PropTypes from 'prop-types';
import React from 'react';

import PageWrapper from '@/features/authPages/PageWrapper';

import Form from './Form';

const ResetPasswordPage = ({ resetPassword }) => (
  <PageWrapper>
    <Form onSubmit={resetPassword} />
  </PageWrapper>
);

ResetPasswordPage.propTypes = {
  resetPassword: PropTypes.func.isRequired,
};

export default ResetPasswordPage;
