import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const DotIndicator = ({ classes, status }) => (
  <span
    className={classNames(classes.indicator, {
      [classes.blocked]: status === 'blocked',
      [classes.enabled]: status === 'enabled',
      [classes.errorred]: status === 'errorred',
      [classes.disabled]: status === 'disabled',
      [classes.pending]: status === 'pending',
      [classes.warning]: status === 'warning',
    })}
  />
);

DotIndicator.propTypes = {
  status: PropTypes.oneOf([
    'blocked',
    'enabled',
    'errorred',
    'disabled',
    'pending',
    'warning',
  ]).isRequired,
};

export default DotIndicator;
