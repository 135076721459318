import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import Quadrolingual from '@/components/Quadrolingual';
import Preview from '@/features/campaignCreatePage/EditPage/SecondStepArticle/ContentForm/Preview';
import FormikPropTypes from '@/prop-types/formik';

import Image from '../../../Image';
import Label from '../../../Label';

class Banner extends Component {
  static propTypes = FormikPropTypes;

  renderPreview = () => <Preview {...this.props} />;

  render() {
    const { imageUrl, englishTitle, russianTitle, kazakhTitle } = this.props;

    return (
      <FieldRow label="Наполнение баннера" Aside={this.renderPreview}>
        <Label title="Изображение баннера">
          <Image src={imageUrl} />
        </Label>

        <Label title="Заголовок баннера">
          <Quadrolingual
            englishContent={englishTitle}
            russianContent={russianTitle}
            kazakhContent={kazakhTitle}
          />
        </Label>
      </FieldRow>
    );
  }
}

export default Banner;
