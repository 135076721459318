import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import DownloadButton from './component';

const mapDispatchToProps = (dispatch, { url }) => ({
  // onClick: () => dispatch(downloadXlsUrl(url)),
  onClick: () => window.open(url, '_blank'),
});

export default compose(
  connect(mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(DownloadButton);
