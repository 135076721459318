export const CREATE_DRAFT = 'campaignCreatePage/CREATE_DRAFT';

export const FETCH_ITEM = 'campaignCreatePage/FETCH_ITEM';

export const GO_TO_STEP_ONE = 'campaignCreatePage/GO_TO_STEP_ONE';

export const GO_TO_STEP_TWO = 'campaignCreatePage/GO_TO_STEP_TWO';

export const GO_TO_STEP_THREE = 'campaignCreatePage/GO_TO_STEP_THREE';

export const SUBMIT = 'campaignCreatePage/SUBMIT';

export const DELETE = 'campaignCreatePage/DELETE';
