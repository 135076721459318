import {
  DATE_START_KEY,
  SEGMENT_SIZE_KEY,
  STATUS_KEY,
  TITLE_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import { Hidden, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CanView from '@/containers/CanView';
import { getPermissionForActions } from '@/features/campaignsPage/selectors';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <TableCell>
      <SortControl field={TITLE_KEY}>Название</SortControl>
      <Hidden xlUp>
        <SortControl field={STATUS_KEY}>Статус</SortControl>
      </Hidden>
      <SortControl field={TYPE_KEY}>Тип кампании</SortControl>
    </TableCell>
    <Hidden lgDown>
      <TableCell>
        <SortControl field={SEGMENT_SIZE_KEY}>Сегмент</SortControl>
      </TableCell>
    </Hidden>
    <TableCell>
      <Hidden lgDown>
        <SortControl field={STATUS_KEY}>Статус</SortControl>
      </Hidden>
      <SortControl field={DATE_START_KEY}>Период проведения</SortControl>
    </TableCell>
    {/* <TableCell>Локация</TableCell> */}
    <CanView permission={getPermissionForActions}>
      <TableCell align="right">Действия</TableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
