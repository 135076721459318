import {
  BLACK_STATUS,
  JET_BLACK_STATUS,
  ORANGE_STATUS,
} from '@esentai/core/features/users/consts/statuses';
import { Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import { CommissarAutocomplete } from '@/containers/CommissarAutocomplete';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

import LevelSelect from './LevelSelect';

class AddBonusesForm extends Component {
  onChangeCommissar = userId =>
    this.props.setFieldValue('commissar_id', userId);

  onChangeLevel = level => this.props.setFieldValue('level', level);

  render() {
    const {
      Form,
      Field,
      SubmitButton,
      RequiredMessages,
      errors,
      values,
      onClose,
      currentStatus,
    } = this.props;

    const levelOptions = [
      {
        id: ORANGE_STATUS,
        label: 'Orange',
        disabled: currentStatus === Number(ORANGE_STATUS),
      },
      {
        id: BLACK_STATUS,
        label: 'Black',
        disabled: currentStatus === Number(BLACK_STATUS),
      },
      {
        id: JET_BLACK_STATUS,
        label: 'JetBlack',
        disabled: currentStatus === Number(JET_BLACK_STATUS),
      },
    ];

    return (
      <Form>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <LevelSelect
              name="level"
              id="level"
              onChange={this.onChangeLevel}
              value={values.level}
              options={levelOptions}
            />
          </Grid>
          <Grid item>
            <Field
              Target={TextField}
              id="comment"
              name="comment"
              label="Комментарий"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              rowsMax={4}
            />
          </Grid>
          <Grid item>
            <CommissarAutocomplete
              name="user_id"
              id="user_id"
              onChange={this.onChangeCommissar}
              selectedItem={values.commissar_id}
              InputProps={{
                error: errors.commissar_id,
                helperText: errors.commissar_id,
                placeholder: 'Комплаенс Менеджер',
              }}
            />
          </Grid>
          <Grid item>
            <ButtonSet>
              <Button onClick={onClose} size={'large'}>
                Отменить
              </Button>
              <SubmitButton
                Target={AsyncButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                Отправить
              </SubmitButton>
            </ButtonSet>
          </Grid>
          <Grid item>
            <RequiredMessages
              fieldNames={{
                level: 'уровень лояльности',
                comment: 'комментарий',
                commissar_id: 'комплаенс менеджер',
              }}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

AddBonusesForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default AddBonusesForm;
