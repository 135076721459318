import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import TableCellTypography from '@/components/TableCellTypography';

import CommonCells from '../CommonCells';
import TimeDistribution from './TimeDistribution';

const TotalRow = ({ averageTicket, purchasesCount }) => (
  <TableRow>
    <CondenseTableCell>
      <TableCellTypography>За весь период</TableCellTypography>
    </CondenseTableCell>
    <CommonCells averageTicket={averageTicket} purchasesCount={purchasesCount}>
      <CondenseTableCell>
        <TimeDistribution />
      </CondenseTableCell>
    </CommonCells>
  </TableRow>
);

TotalRow.propTypes = {
  averageTicket: PropTypes.string.isRequired,
  purchasesCount: PropTypes.number.isRequired,
};

export default TotalRow;
