import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getEmailForPersonnel } from '@/features/personnel/selectors';
import { resetPassword } from '@/features/personnelEditPage/actions';
import { getPersonnelId } from '@/features/personnelEditPage/selectors';

import ResetPasswordField from './component';
import styles from './styles';

const mapStateToProps = state => {
  const personnelId = getPersonnelId(state);

  return {
    email: getEmailForPersonnel(state, personnelId),
  };
};

const mapDispatchToProps = { onSubmit: resetPassword };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({
    runnerMethod: 'onSubmit',
  }),
  withStyles(styles),
)(ResetPasswordField);
