import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import {
  getReconciliationError,
  getReconciliationIds,
  getReconciliationTotalCount,
  isLoadingReconciliation,
} from '../selectors';
import ReconciliationPromotions from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getReconciliationError(state),
  reconciliationIds: getReconciliationIds(state),
  isLoading: isLoadingReconciliation(state),
  totalCount: getReconciliationTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(ReconciliationPromotions);
