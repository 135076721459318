export default theme => {
  const margin = theme.spacing.unit / 2;

  return {
    root: {
      marginRight: margin,
      marginBottom: margin,
    },
  };
};
