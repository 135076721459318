import {
  SALDO_KEY,
  TOTAL_PAID_KEY,
  TOTAL_TO_PAY_KEY,
} from '@esentai/core/features/merchantsReconciliationArchive/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>Арендаторы</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TOTAL_TO_PAY_KEY}>
        Итоговая сумма к оплате
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TOTAL_PAID_KEY}>
        Итоговая оплаченная сумма
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell align={'center'}>
      <SortControl field={SALDO_KEY}>Сальдо</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Действия</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
