import { connect } from 'react-redux';

import StatisticsButton from '@/components/ActionButtons/Statistic';
import { canReadUserStatistics } from '@/features/usersStatistics/permissions';
import { getLocationStatisticsPageUrl } from '@/features/usersStatisticsPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  statisticsPageUrl: getLocationStatisticsPageUrl(state, itemId),
  permission: canReadUserStatistics,
});

export default connect(mapStateToProps)(StatisticsButton);
