import PropTypes from 'prop-types';

const BenefitTitleLazy = ({ isLoaded, title }) => (isLoaded ? title : '...');

BenefitTitleLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

BenefitTitleLazy.defaultProps = {
  title: null,
};

export default BenefitTitleLazy;
