import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import {
  EMAIL_KEY,
  LAST_ACTIVITY_DATE_KEY,
  NAME_KEY,
  ROLE_KEY,
} from '@/features/personnel/consts/keys';

const HeadRow = ({ SortControl, isMallAdmin }) => (
  <TableRow>
    <TableCell padding="checkbox" />

    <CondenseTableCell>
      <SortControl field={EMAIL_KEY}>E-mail</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={NAME_KEY}>Имя пользователя</SortControl>
      <SortControl field={ROLE_KEY}>Роль</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>Привязан к магазинам</CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={LAST_ACTIVITY_DATE_KEY}>
        Последняя активность
      </SortControl>
    </CondenseTableCell>
    {isMallAdmin && <CondenseTableCell>Действия</CondenseTableCell>}
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
  isMallAdmin: PropTypes.bool.isRequired,
};

export default HeadRow;
