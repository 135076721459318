import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import FlowRateChart from '@/components/FlowRateChart';
import { formatNumber } from '@/utils/format';

const BannerArticleCalendarEvent = ({
  viewsCount,
  coverage,
  clicksCount,
  viewsToSegmentPercentage,
  clicksToCoveragePercentage,
}) => (
  <Fragment>
    <CondenseTableCell align="right">
      <FlowRateChart value={viewsCount} percentage={viewsToSegmentPercentage} />
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatNumber(coverage)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={clicksCount}
        percentage={clicksToCoveragePercentage}
      />
    </CondenseTableCell>
  </Fragment>
);

BannerArticleCalendarEvent.propTypes = {
  viewsCount: PropTypes.number.isRequired,
  coverage: PropTypes.number.isRequired,
  clicksCount: PropTypes.number.isRequired,
  viewsToSegmentPercentage: PropTypes.number.isRequired,
  clicksToCoveragePercentage: PropTypes.number.isRequired,
};

export default BannerArticleCalendarEvent;
