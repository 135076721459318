export default {
  root: {
    display: 'flex',
  },

  indicator: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    marginRight: 10,
  },

  text: {
    fontSize: 16,
  },
};
