import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import FlowRateChart from '@/components/FlowRateChart';

const PurchaseResult = ({
  prePushClicksCount,
  activationsCount,
  pushClicksCount,
  prePushClicksToSegmentPercentage,
  activationsToPrePushClicksPercentage,
  pushClicksToActivationsPercentage,
}) => (
  <Fragment>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={prePushClicksCount}
        percentage={prePushClicksToSegmentPercentage}
      />
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={activationsCount}
        percentage={activationsToPrePushClicksPercentage}
      />
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={pushClicksCount}
        percentage={pushClicksToActivationsPercentage}
      />
    </CondenseTableCell>
  </Fragment>
);

PurchaseResult.propTypes = {
  prePushClicksCount: PropTypes.number.isRequired,
  activationsCount: PropTypes.number.isRequired,
  pushClicksCount: PropTypes.number.isRequired,
  prePushClicksToSegmentPercentage: PropTypes.number.isRequired,
  activationsToPrePushClicksPercentage: PropTypes.number.isRequired,
  pushClicksToActivationsPercentage: PropTypes.number.isRequired,
};

export default PurchaseResult;
