import {
  FIFTH_FLOOR_ID,
  FIRST_FLOOR_ID,
  FIRST_PARKING_FLOOR_ID,
  FOURTH_FLOOR_ID,
  MINUS_FIRST_FLOOR_ID,
  SECOND_FLOOR_ID,
  SECOND_PARKING_FLOOR_ID,
  THIRD_FLOOR_ID,
  THIRD_PARKING_FLOOR_ID,
} from '@esentai/core/features/beacons/consts/floors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class FloorSelect extends Component {
  static propTypes = {
    FloorOption: PropTypes.func.isRequired,
  };

  render() {
    const { FloorOption } = this.props;

    return (
      <div>
        <FloorOption floor={THIRD_PARKING_FLOOR_ID} />
        <FloorOption floor={SECOND_PARKING_FLOOR_ID} />
        <FloorOption floor={FIRST_PARKING_FLOOR_ID} />
        <FloorOption floor={MINUS_FIRST_FLOOR_ID} />
        <FloorOption floor={FIRST_FLOOR_ID} />
        <FloorOption floor={SECOND_FLOOR_ID} />
        <FloorOption floor={THIRD_FLOOR_ID} />
        <FloorOption floor={FOURTH_FLOOR_ID} />
        <FloorOption floor={FIFTH_FLOOR_ID} />
      </div>
    );
  }
}

export default FloorSelect;
