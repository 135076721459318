import { HISTORY_ID_KEY } from '@esentai/core/features/reconciliationReceiptBonusDetails/consts/keys';
import dao from '@esentai/core/features/reconciliationReceiptBonusDetails/dao';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { getDetailsId } from './selectors';
import { FETCH_DETAILS } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const { findById: findDetailsById } = dao.sagas;

export function* fetchItemsForCurrentDetail(query) {
  const detailsId = yield select(getDetailsId);
  const detailQuery = mergeQueriesRight(
    query,
    makeQuery(where(HISTORY_ID_KEY, equals(detailsId))),
  );

  yield call(itemsDuck.sagas.fetchItems, detailQuery);
}

export const fetchItems = createQuerySaga(fetchItemsForCurrentDetail);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(FETCH_DETAILS, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
