import { either, equals, pipe } from 'ramda';

import { getCurrentUserId } from '@/features/auth/selectors';
import {
  EFS_SALES_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_ADMINISTRATOR_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_VALET_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
} from '@/features/personnel/consts/roles';
import {
  getNameForPersonnel,
  getRoleForPersonnel,
  getStoresForPersonnel,
} from '@/features/personnel/selectors';

const getCurrentUserWith = func => state => {
  const currentUserId = getCurrentUserId(state);

  return func(state, currentUserId);
};

export const getName = getCurrentUserWith(getNameForPersonnel);

export const getRole = getCurrentUserWith(getRoleForPersonnel);

export const getUserStores = getCurrentUserWith(getStoresForPersonnel);

export const isMallAdministrator = pipe(
  getRole,
  equals(MALL_ADMINISTRATOR_ROLE),
);

export const isMallManager = pipe(getRole, equals(MALL_MANAGER_ROLE));

export const isStoreAdministrator = pipe(
  getRole,
  equals(STORE_ADMINISTRATOR_ROLE),
);

export const isMallCommissar = pipe(getRole, equals(MALL_COMMISSAR_ROLE));

export const notMallManager = either(isMallAdministrator, isStoreAdministrator);

export const isStoreManager = pipe(getRole, equals(STORE_MANAGER_ROLE));

export const isStoreSeller = pipe(getRole, equals(STORE_SELLER_ROLE));

export const isMallAccountant = pipe(getRole, equals(MALL_ACCOUNTANT_ROLE));

export const isMallStaff = either(isMallAdministrator, isMallManager);

export const isStoreStaff = either(isStoreAdministrator, isStoreManager);

export const isMallValet = pipe(getRole, equals(MALL_VALET_ROLE));

export const isEfsSales = pipe(getRole, equals(EFS_SALES_ROLE));

export const isMallReception = pipe(getRole, equals(MALL_RECEPTION_ROLE));

export const isManagingTeamMember = pipe(
  getRole,
  equals(MANAGING_TEAM_MEMBER_ROLE),
);
