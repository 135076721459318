import {
  anyOf,
  contains,
  equals,
  filter,
  ilike,
  readAnyOf,
  readContains,
  readEquals,
  readILike,
} from '@esentai/core/query-dsl';
import { compose, curry, head, isEmpty, isNil, prop } from 'ramda';

export const createAdapter = curry((pack, unpack, initialValue) => ({
  pack,
  unpack,
  initialValue,
}));

export const createAnyOfAdapter = createAdapter(value => {
  if (Array.isArray(value) && !isEmpty(value)) {
    return filter(anyOf(value));
  } else if (typeof value === 'string') {
    // TODO: Remove once convenient method like 'wrapQuickFilterValue' is ready
    return filter(anyOf([value]));
  }

  return filter();
}, readAnyOf);

export const createContainsAdapter = createAdapter(value => {
  if (Array.isArray(value) && !isEmpty(value)) {
    return filter(contains(value));
  } else if (typeof value === 'string') {
    // TODO: Remove once convenient method like 'wrapQuickFilterValue' is ready
    return filter(contains([value]));
  }

  return filter();
}, readContains);

export const createIncludeAdapter = createAdapter(value => {
  if (isNil(value)) {
    return filter();
  }
  const ar = [];

  ar.push(value);
  // TODO: Remove once convenient method like 'wrapQuickFilterValue' is ready

  return filter(anyOf([ar]));
}, readAnyOf);

export const createEqualsAdapter = createAdapter(
  value => (isNil(value) ? filter() : filter(equals(value))),
  readEquals,
);

export const createILikeAdapter = createAdapter(
  value => (value ? filter(ilike(value)) : filter()),
  readILike,
);

export const createMultipleValuesRelationAdapter = createAdapter(
  value => (isNil(value) ? filter() : filter(contains([value]))),
  compose(value => (isNil(value) ? null : head(value)), readContains),
);

const getAdapterInitialValue = prop('initialValue');

export const doesPageFilterHaveNoValue = (adapter, value) => {
  const { unpack } = adapter;

  return typeof unpack(value) === 'undefined';
};

export const packPageFilterValue = (adapter, value) => {
  const { pack } = adapter;

  return pack(value);
};

export const unpackPageFilterValue = (adapter, value) => {
  const { unpack } = adapter;

  if (doesPageFilterHaveNoValue(adapter, value)) {
    return getAdapterInitialValue(adapter);
  }

  return unpack(value);
};
