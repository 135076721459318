import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Link from '@/components/Link';
import { createArchiveReceiptsPageUrl } from '@/features/reconciliationReceiptsPage/utils';
import { createArchiveRefundsPageUrl } from '@/features/reconciliationRefundsPage/utils';
import {
  dateTimeFormat,
  dateTimeFormatNoSecs,
  formatMoney,
  formatNumber,
} from '@/utils/format';

import FinishReconciliationModal from '../../../../components/FinishReconciliationModal/FinishReconciliationModal';
import { PATCH_URL } from '../../consts';

const Row = ({
  reconciliationCreatedDate,
  reconciliationEndDate,
  reconciliationStartDate,
  bonusesRefundedAmount,
  bonusesSpentAmount,
  totalToPayAmount,
  paymentFileUrl,
  reconciliationId,
  storeId,
  fetchItems,
  merchantName,
}) => {
  const [isFinishDialogOpen, handleFinishModal] = useState(false);

  const setFinishModal = () => {
    handleFinishModal(!isFinishDialogOpen);
  };

  const payload = {
    reconciliation_history: [
      {
        id: reconciliationId,
        payment_file_url: paymentFileUrl,
      },
    ],
  };

  const items = [
    {
      itemId: reconciliationId,
      itemImgUrl: paymentFileUrl,
      itemCreatedDate: dateTimeFormat(reconciliationCreatedDate),
      itemtotalToPayAmount: totalToPayAmount,
    },
  ];

  return (
    <>
      <TableRow>
        <TableCell>{formatMoney(totalToPayAmount)}</TableCell>
        <TableCell>
          <Link to={createArchiveReceiptsPageUrl(reconciliationId, storeId)}>
            {formatNumber(bonusesSpentAmount)}
          </Link>
        </TableCell>
        <TableCell>
          <Link to={createArchiveRefundsPageUrl(reconciliationId, storeId)}>
            {formatNumber(bonusesRefundedAmount)}
          </Link>
        </TableCell>
        <TableCell>{merchantName}</TableCell>
        <TableCell align={'right'}>
          {reconciliationStartDate
            ? dateTimeFormatNoSecs(reconciliationStartDate)
            : '-'}{' '}
          -{' '}
          {reconciliationEndDate
            ? dateTimeFormatNoSecs(reconciliationEndDate)
            : '-'}
        </TableCell>
        {/* <CanView permission={canRepayReconciliation}>
          <TableCell>
            <FinishButton
              finishDisabled={paymentDatetime && paymentFileUrl}
              handleDialog={setFinishModal}
            />
          </TableCell>
        </CanView> */}
      </TableRow>
      {isFinishDialogOpen && (
        <FinishReconciliationModal
          isOpen={isFinishDialogOpen}
          onClose={setFinishModal}
          fetchItems={fetchItems}
          url={PATCH_URL}
          payload={payload}
          items={items}
        />
      )}
    </>
  );
};

Row.propTypes = {
  totalToPayAmount: PropTypes.number.isRequired,
  bonusesSpentAmount: PropTypes.number.isRequired,
  bonusesRefundedAmount: PropTypes.number.isRequired,
  reconciliationCreatedDate: PropTypes.instanceOf(Date),
  reconciliationId: PropTypes.number.isRequired,
  storeId: PropTypes.number.isRequired,
  paymentFileUrl: PropTypes.string,
  reconciliationStartDate: PropTypes.any.isRequired,
  reconciliationEndDate: PropTypes.any.isRequired,
  fetchItems: PropTypes.func.isRequired,
  merchantName: PropTypes.string.isRequired,
};

Row.defaultProps = {
  reconciliationCreatedDate: '-',
  paymentDatetime: '-',
  paymentFileUrl: '',
};

export default Row;
