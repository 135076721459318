import { getNameRuForCategory } from '@esentai/core/features/productCategories/selectors';
import { connect } from 'react-redux';

import Option from '@/components/AutocompleteBase/Option';

const mapStateToProps = (state, { value }) => ({
  label: getNameRuForCategory(state, value),
});

export default connect(mapStateToProps, () => ({}))(Option);
