import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import TableCellTypography from '@/components/TableCellTypography';

import RowCellsContent from '../RowCellsContent';
import TickFormatter from './TickFormatter';

const Row = ({ itemId, classes, detailsPageUrl, ...rest }) => (
  <TableRow className={classes.rowRoot}>
    <RowCellsContent
      {...rest}
      FirstCell={
        <Link to={detailsPageUrl}>
          <TickFormatter
            component={TableCellTypography}
            itemId={itemId}
            color="inherit"
          />
        </Link>
      }
    />
  </TableRow>
);

Row.propTypes = {
  itemId: PropTypes.any.isRequired,
  detailsPageUrl: PropTypes.string.isRequired,
};

export default Row;
