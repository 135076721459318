import { handleActions } from 'redux-actions';

import {
  UPLOAD_FAILURE,
  UPLOAD_PROGRESS,
  UPLOAD_RETRY,
  UPLOAD_START,
  UPLOAD_SUCCESS,
} from './types';

const initialState = {};

const reducerMap = {
  [UPLOAD_START]: (state, { payload: [{ id, file }] }) => ({
    ...state,
    [id]: {
      id,
      file,
      isFinished: false,
      isUploading: true,
      error: null,
      response: null,
    },
  }),
  [UPLOAD_RETRY]: (state, { payload: id }) => ({
    ...state,
    [id]: {
      ...state[id],
      isUploading: true,
      error: null,
      response: null,
    },
  }),
  [UPLOAD_FAILURE]: (state, { payload: { id, error } }) => ({
    ...state,
    [id]: {
      ...state[id],
      isUploading: false,
      error,
    },
  }),
  [UPLOAD_SUCCESS]: (state, { payload: { id, url, response } }) => ({
    ...state,
    [id]: {
      ...state[id],
      isFinished: true,
      isUploading: false,
      url,
      response,
    },
  }),
  [UPLOAD_PROGRESS]: (state, { payload: { id, progress } }) => ({
    ...state,
    [id]: {
      ...state[id],
      progress,
    },
  }),
};

export default handleActions(reducerMap, initialState);
