import { T } from 'ramda';
import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { createEditLocationLink } from '@/features/locationEditPage/serializer';

const mapStateToProps = (state, { itemId }) => ({
  editPageUrl: createEditLocationLink(itemId),
  permission: T,
});

export default connect(mapStateToProps)(EditButton);
