import createItemsDuck from '@esentai/core/ducks/items';

import { getIdProp } from '@/features/statistics/utils';

import api from '../api';
import { getItemsRoot } from '../utils';

export default createItemsDuck('campaign_aggregation', {
  api,
  getRoot: getItemsRoot,
  getIdProp,
});
