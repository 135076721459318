import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';
import StoreNameLazy from '@/containers/StoreNameLazy';
import { createStorePageUrl } from '@/features/storePage/utils';
import { ROUTE_PATH as STORES_PATH } from '@/features/storesPage/consts';

import Table from './Table';

class StoreReceipts extends Component {
  static propTypes = {
    error: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    receiptsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    storeId: PropTypes.string.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, receiptsIds, isLoading, storeId, totalCount } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={STORES_PATH}>Магазины</Breadcrumb>
            <Breadcrumb to={createStorePageUrl(storeId)}>
              <StoreNameLazy storeId={storeId} />
            </Breadcrumb>
          </Breadcrumbs>
          <BackButton />
          <PageTitle gutterBottom={false}>
            Список покупок: <StoreNameLazy storeId={storeId} />
          </PageTitle>
          <PageSubtitle>Вспомогательный текст</PageSubtitle>
        </PageHeader>
        <PageContent>
          <Table
            error={error}
            items={receiptsIds}
            isLoading={isLoading}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default StoreReceipts;
