import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Mall from '@/components/Icon/Mall';

import Description from './Description';
import GiveOut from './GiveOut';
import UserMenu from './UserMenu';

const BenefitGiveOut = ({ classes, storeNames, match }) => {
  const { token } = match.params;

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.logoWrapper}>
          <Mall width={213} color="black" />
        </div>

        <Typography color="primary" variant="h5" align="center">
          {storeNames.join(', ')}
        </Typography>
      </div>

      {token ? <GiveOut token={token} /> : <Description />}

      <UserMenu />
    </div>
  );
};

BenefitGiveOut.propTypes = {
  match: PropTypes.object.isRequired,
  storeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BenefitGiveOut;
