import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { defaultProps } from 'recompose';

import { createPageFilterTemplate } from '@/structs/pageFilter';
import { createEqualsAdapter } from '@/structs/pageFilterAdapter';

import Autocomplete from './component';
import styles from './styles';

const FilterComponent = compose(
  defaultProps({
    InputProps: {
      autoFocus: true,
      fullWidth: true,
    },
  }),
  withStyles(styles),
)(Autocomplete);

export const createAutocompleteFilterTemplate = definition =>
  createPageFilterTemplate({
    FilterComponent,
    adapter: createEqualsAdapter(null),
    ...definition,
  });

export default FilterComponent;
