import { connect } from 'react-redux';

import RentPlace from '@/components/HeatMap/RentPlace';
import { openRentPlace } from '@/features/locationsStatisticsPage/actions';
import { getAmountForRentPlaceCurrentMetric } from '@/features/locationsStatisticsPage/selectors';

const mapStateToProps = (state, { rentId }) => ({
  amount: getAmountForRentPlaceCurrentMetric(state, rentId),
});

const mapDispatchToProps = { onOpen: openRentPlace };

export default connect(mapStateToProps, mapDispatchToProps)(RentPlace);
