import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { push } from 'connected-react-router';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';

import { authorizeByToken } from '@/features/auth/sagas';
import { ROUTE_PATH as INVITE_ERROR_ROOT_PATH } from '@/features/invitePages/InviteError/consts';

import api from './api';
import { FULL_NAME_KEY, PASSWORD_KEY, TOKEN_KEY } from './consts/keys';
import dao from './dao';
import {
  CONFIRM_INVITE,
  CREATE_INVITE,
  CREATE_INVITE_AND_REDIRECT,
  FIND_INVITE,
} from './types';

const { create, findById } = dao.sagas;

export const fetchInvite = function*({ payload: token }) {
  yield call(findById, token);
};

export const fetchInviteAndRedirect = function*(...args) {
  try {
    yield call(fetchInvite, ...args);
  } catch (e) {
    yield put(push(`${INVITE_ERROR_ROOT_PATH}/${e.response.data.code}`));
  }
};

export const createInvite = function*({ payload }) {
  yield call(create, payload);
};

export const createInviteAndRedirect = function*({ payload }) {
  const { invite, path } = payload;

  yield call(createInvite, { payload: invite });
  yield put(push(path));
};

export const confirmInvite = function*({ payload }) {
  const { token, fullName, password } = payload;
  const { auth_token: authToken } = yield call([api, api.update], null, {
    [TOKEN_KEY]: token,
    [FULL_NAME_KEY]: fullName,
    [PASSWORD_KEY]: password,
  });

  yield call(authorizeByToken, { payload: authToken });
  yield put(push('/'));
};

export const watchCreateInvite = function*() {
  yield takeEvery(CREATE_INVITE, createTaskSaga(createInvite));
  yield takeEvery(
    CREATE_INVITE_AND_REDIRECT,
    createTaskSaga(createInviteAndRedirect),
  );
};

export const watchFindInvite = function*() {
  yield takeEvery(FIND_INVITE, createTaskSaga(fetchInviteAndRedirect));
};

export const watchConfirmInvite = function*() {
  yield takeEvery(CONFIRM_INVITE, createTaskSaga(confirmInvite));
};

export default function*() {
  yield spawn(watchCreateInvite);
  yield spawn(watchFindInvite);
  yield spawn(watchConfirmInvite);
}
