import {
  DESCRIPTION_EN_KEY,
  DESCRIPTION_KZ_KEY,
  DESCRIPTION_RU_KEY,
  IMAGES_URL_KEY,
  INDEX_KEY,
  PRICE_KEY,
  SKU_KEY,
  SUBTITLE_EN_KEY,
  SUBTITLE_KZ_KEY,
  SUBTITLE_RU_KEY,
  TITLE_EN_KEY,
  TITLE_KZ_KEY,
  TITLE_RU_KEY,
  VISIBILITY_KEY,
} from '@esentai/core/features/giftSets/consts/keys';
import {
  getDescriptionEnForGiftSet,
  getDescriptionKzForGiftSet,
  getDescriptionRuForGiftSet,
  getImagesForGiftSet,
  getIndexForGiftSet,
  getPriceForGiftSet,
  getSkuForGiftSet,
  getSubtitleEnForGiftSet,
  getSubtitleKzForGiftSet,
  getSubtitleRuForGiftSet,
  getTitleEnForGiftSet,
  getTitleKzForGiftSet,
  getTitleRuForGiftSet,
  getVisibilityForGiftSet,
} from '@esentai/core/features/giftSets/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { array, boolean, number, object, string } from 'yup';

import withFormikValidation from '@/hocs/withFormikValidation';

import ProductForm from './component';
import styles from './styles';

const formikOptions = {
  mapPropsToValues: ({
    titleRu,
    titleEn,
    titleKz,

    subtitleRu,
    subtitleEn,
    subtitleKz,

    descriptionRu,
    descriptionEn,
    descriptionKz,

    images,
    index,
    sku,
    price,
    visible,
  }) => ({
    [TITLE_RU_KEY]: titleRu,
    [TITLE_EN_KEY]: titleEn,
    [TITLE_KZ_KEY]: titleKz,

    [SUBTITLE_RU_KEY]: subtitleRu,
    [SUBTITLE_EN_KEY]: subtitleEn,
    [SUBTITLE_KZ_KEY]: subtitleKz,

    [DESCRIPTION_RU_KEY]: descriptionRu,
    [DESCRIPTION_EN_KEY]: descriptionEn,
    [DESCRIPTION_KZ_KEY]: descriptionKz,

    [IMAGES_URL_KEY]: images,
    [INDEX_KEY]: index,
    [SKU_KEY]: sku,
    [PRICE_KEY]: price,
    [VISIBILITY_KEY]: visible,
  }),

  validationSchema: object().shape({
    [TITLE_RU_KEY]: string()
      .max(200)
      .required(),
    [TITLE_EN_KEY]: string().max(200),
    [TITLE_KZ_KEY]: string().max(200),

    [SUBTITLE_RU_KEY]: string().max(200),
    [SUBTITLE_EN_KEY]: string().max(200),
    [SUBTITLE_KZ_KEY]: string().max(200),

    [DESCRIPTION_RU_KEY]: string().max(500),
    [DESCRIPTION_EN_KEY]: string().max(500),
    [DESCRIPTION_KZ_KEY]: string().max(500),

    [SKU_KEY]: string().required(),
    [INDEX_KEY]: number(),
    [PRICE_KEY]: number(),
    [IMAGES_URL_KEY]: array(string()),
    [VISIBILITY_KEY]: boolean().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };
const mapStateToProps = (state, { giftSetId }) => ({
  titleRu: getTitleRuForGiftSet(state, giftSetId),
  titleEn: getTitleEnForGiftSet(state, giftSetId),
  titleKz: getTitleKzForGiftSet(state, giftSetId),

  subtitleRu: getSubtitleRuForGiftSet(state, giftSetId),
  subtitleEn: getSubtitleEnForGiftSet(state, giftSetId),
  subtitleKz: getSubtitleKzForGiftSet(state, giftSetId),

  descriptionRu: getDescriptionRuForGiftSet(state, giftSetId),
  descriptionEn: getDescriptionEnForGiftSet(state, giftSetId),
  descriptionKz: getDescriptionKzForGiftSet(state, giftSetId),

  images: getImagesForGiftSet(state, giftSetId),
  index: getIndexForGiftSet(state, giftSetId),
  sku: getSkuForGiftSet(state, giftSetId),
  price: getPriceForGiftSet(state, giftSetId),
  visible: getVisibilityForGiftSet(state, giftSetId),
});

export default compose(
  connect(mapStateToProps),
  withAsyncRunner(asyncOptions),
  withFormikValidation(formikOptions),
  withStyles(styles),
)(ProductForm);
