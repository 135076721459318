import {
  SUM_IN_PROCESSING_KEY,
  SUM_INVOICED_KEY,
  TOTAL_PAID_KEY,
  TOTAL_TO_PAY_KEY,
} from '@esentai/core/features/reconciliationStoreToCp/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>Магазин</CondenseTableCell>
    <TableCell>
      <SortControl field={TOTAL_PAID_KEY}>Общая оплаченная сумма</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={TOTAL_TO_PAY_KEY}>Общая сумма к оплате</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={SUM_IN_PROCESSING_KEY}>
        Общая сумма в обработке
      </SortControl>
    </TableCell>
    <CondenseTableCell>
      <SortControl field={SUM_INVOICED_KEY}>
        Общая выставленная сумма
      </SortControl>
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
