import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import MapViewport from '@/components/Map/Viewport';

import { createAmountScale, createColorScale } from '../utils';

class Viewport extends PureComponent {
  static propTypes = {
    maximumAmount: PropTypes.number.isRequired,
    minimumAmount: PropTypes.number.isRequired,
    resetSelectedRentPlace: PropTypes.func.isRequired,
    selectRentPlace: PropTypes.func.isRequired,
    RentPlace: PropTypes.func.isRequired,
  };

  getColorScale = () => {
    const { minimumAmount, maximumAmount } = this.props;
    const amountScale = createAmountScale(minimumAmount, maximumAmount);

    return createColorScale(amountScale);
  };

  render() {
    return <MapViewport {...this.props} RentPlace={this.renderRentPlace} />;
  }

  renderRentPlace = props => {
    const { resetSelectedRentPlace, selectRentPlace, RentPlace } = this.props;
    const colorScale = this.getColorScale();

    return (
      <RentPlace
        {...props}
        colorScale={colorScale}
        onDeselect={resetSelectedRentPlace}
        onSelect={selectRentPlace}
      />
    );
  };
}

export default Viewport;
