import React from 'react';

import TopBar from './topBar.svg';

const CalendarTopBar = ({ classes }) => (
  <div className={classes.container}>
    <TopBar />
  </div>
);

export default CalendarTopBar;
