import { FilterList as FilterListIcon } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FilterBar from '@/components/FilterBar';

const SelectRow = ({
  classes,
  filterTypes,
  filters,
  updateFilters,
  removeFilters,
}) => {
  const filtersList = [...filters.entries()].map(([key, value]) => ({
    key,
    value,
  }));

  return (
    <div className={classNames(classes.root, classes.filtersContent)}>
      <FilterListIcon className={classes.filterIcon} />
      <FilterBar
        filters={filtersList}
        filterTypes={filterTypes}
        updateFilters={updateFilters}
        removeFilters={removeFilters}
      />
    </div>
  );
};

SelectRow.propTypes = {
  filterTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  filters: PropTypes.object.isRequired,
  removeFilters: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default SelectRow;
