import {
  getEndDatetime,
  getFnsId,
  getPercent,
  getStartDatetime,
  getStoreName,
} from '@esentai/core/features/reconciliationReceiptBonusDetails/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Description from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  storeName: getStoreName(state, itemId),
  endDatetime: getEndDatetime(state, itemId),
  startDatetime: getStartDatetime(state, itemId),
  fnsId: getFnsId(state, itemId),
  percent: getPercent(state, itemId),
  itemId,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Description);
