import { RESOURCE_NAME } from '@esentai/core/features/kkms/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadKkms = authorizeIf(canRead(RESOURCE_NAME));
export const canReadKkm = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateKkm = authorizeIf(canCreate(RESOURCE_NAME));
export const canUpdateKkm = authorizeIf(canUpdate(RESOURCE_NAME));

// TODO set permission for campaign statistic
export const canReadKkmsStatistics = () => true;
