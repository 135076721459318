import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { array, number, object } from 'yup';

import {
  CURRENT_AMOUNT_KEY,
  CUSTOMER_ID_KEY,
  FILE_URL_KEY,
  ISSUED_AT_DATETIME_KEY,
  KKM_KEY,
  NAME_KEY,
  PRICE_KEY,
  PURCHASE_KEY,
  REFUND_AMOUNT_KEY,
  STORE_KEY,
  UUID_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import RefundPurchase from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: () => ({
    [CUSTOMER_ID_KEY]: '',
    [UUID_KEY]: '',
    [ISSUED_AT_DATETIME_KEY]: null,
    [STORE_KEY]: '',
    [PURCHASE_KEY]: [
      {
        [CURRENT_AMOUNT_KEY]: '',
        [NAME_KEY]: '',
        [PRICE_KEY]: '',
        [REFUND_AMOUNT_KEY]: '',
        isSelected: false,
      },
    ],
    [KKM_KEY]: '',
    [FILE_URL_KEY]: ['', '', ''],
  }),

  validationSchema: object().shape({
    [PURCHASE_KEY]: array().of(
      object().shape({
        [REFUND_AMOUNT_KEY]: number()
          .test({
            name: 'check with current_amount value',
            test(value) {
              if (value > this.parent[CURRENT_AMOUNT_KEY]) {
                return this.createError({
                  message:
                    'Кол-во возвращаемых продуктов не должно превышать общего кол-ва',
                  path: `${PURCHASE_KEY}[${this.parent.index}].${REFUND_AMOUNT_KEY}`,
                });
              } else if (
                this.parent.isSelected &&
                (value === undefined || value === '')
              ) {
                return this.createError({
                  message: 'Поле не заполнено',
                  path: `${PURCHASE_KEY}[${this.parent.index}].${REFUND_AMOUNT_KEY}`,
                });
              }

              return true;
            },
          })
          .min(1, 'Невалидное значение')
          .transform((cv, ov) => (ov === '' ? undefined : cv)),
      }),
    ),
  }),

  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(RefundPurchase);
