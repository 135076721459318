import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

// import CloneButton from '../../buttons/CloneButton';
import DeactivateButton from '../../buttons/DeactivateButton';
// import EditButton from '../../buttons/EditButton';
// import StatisticButton from '../../buttons/StatisticButton';

const ActiveStatusActions = ({ itemId }) => (
  <Fragment>
    <DeactivateButton itemId={itemId} />
    {/* <EditButton itemId={itemId} />
    <CloneButton itemId={itemId} />
    <StatisticButton itemId={itemId} /> */}
  </Fragment>
);

ActiveStatusActions.propTypes = {
  itemId: PropTypes.string.isRequired,
};

export default ActiveStatusActions;
