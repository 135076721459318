import { map, times, zipWith } from 'ramda';

import { createAmountScale, createColorScale } from '../utils';

export const generateTicks = (count, min, max) => {
  if (min === max) {
    return [min, max];
  }

  const step = (max - min) / (count - 1);

  return times(index => min + step * index, count);
};

export const generateGradientTicks = (count, min, max) => {
  const amountScale = createAmountScale(min, max);
  const colorScale = createColorScale(amountScale);
  const ticks = generateTicks(count, min, max);
  const colors = map(colorScale, ticks);
  const percentages = generateTicks(count, 0, 100);

  return zipWith(
    (color, percentage) => `${color} ${percentage}%`,
    colors,
    percentages,
  );
};
