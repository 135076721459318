import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import styles from '@/features/authPages/styles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import { newPasswordFieldName } from './names';

const formikOptions = {
  mapPropsToValues: () => ({ [newPasswordFieldName]: '' }),

  validationSchema: object().shape({
    [newPasswordFieldName]: string().required(),
  }),

  handleSubmit({ [newPasswordFieldName]: password }, { props, setSubmitting }) {
    props.onSubmit({ password });
    setSubmitting(false);
  },
};

const errorsMapping = {};
const asyncOptions = { runnerMethod: 'onSubmit' };

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
