import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getCampaignType,
  getEffeciency,
} from '@/features/campaignsStatisticsDetailsPage/selectors';

import CampaignsInfluence from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  campaignType: getCampaignType(state),
  effeciency: getEffeciency(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CampaignsInfluence);
