import {
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  SEND_PUSH_KEY,
} from '@esentai/core/features/campaigns/actionResult/consts/keys';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import TextField from '@/components/TextField';

import { CanEdit } from '../../CanEdit';
import Preview from './Preview';

class PushForm extends PureComponent {
  static propTypes = {
    pushEnglishTitle: PropTypes.any.isRequired,
    pushRussianTitle: PropTypes.any.isRequired,
    pushEnglishMessage: PropTypes.any.isRequired,
    pushRussianMessage: PropTypes.any.isRequired,
    sendingPush: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    Field: PropTypes.func.isRequired,
  };

  setPrePush = event => {
    const { checked } = event.target;
    const { setFieldValue } = this.props;

    setFieldValue(SEND_PUSH_KEY, checked);
  };

  render() {
    const { sendingPush } = this.props;

    return (
      <FieldRow
        label="Пуш-сообщение"
        subLabel="Пуш-сообщения"
        sudDesc="Это сообщение будет отправляться пользователю, когда он выполнит условие триггера."
        Aside={this.renderPreview}
      >
        <CanEdit field={SEND_PUSH_KEY}>
          <FormControlLabel
            label="Отправлять пуш-сообщение"
            control={
              <Checkbox checked={sendingPush} onChange={this.setPrePush} />
            }
          />
        </CanEdit>

        {sendingPush && this.renderPushContrustor()}
      </FieldRow>
    );
  }

  renderPushContrustor() {
    const { classes, Field } = this.props;

    return (
      <div>
        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Заголовок сообщения"
            desc="Заголовок сообщения, которое пользователь увидит при активации триггера."
          />
          <CanEdit field={PUSH_RUSSIAN_TITLE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_RUSSIAN_TITLE_KEY}
              symbolsMax={60}
              helperText="На русском"
              fullWidth
            />
          </CanEdit>
          <CanEdit field={PUSH_ENGLISH_TITLE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_ENGLISH_TITLE_KEY}
              symbolsMax={60}
              helperText="На английском"
              fullWidth
            />
          </CanEdit>
        </div>

        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Описание"
            desc="Текст сообщения, которое пользователь увидит при активации триггера в списке уведомлений. Не более 100 символов."
          />
          <CanEdit field={PUSH_RUSSIAN_MESSAGE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_RUSSIAN_MESSAGE_KEY}
              helperText="На русском"
              rowsMax={15}
              symbolsMax={200}
              multiline
              fullWidth
            />
          </CanEdit>
          <CanEdit field={PUSH_ENGLISH_MESSAGE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_ENGLISH_MESSAGE_KEY}
              helperText="На английском"
              rowsMax={15}
              symbolsMax={200}
              multiline
              fullWidth
            />
          </CanEdit>
        </div>
      </div>
    );
  }

  renderPreview = () => {
    const {
      pushEnglishTitle,
      pushRussianTitle,
      pushEnglishMessage,
      pushRussianMessage,
      sendingPush,
    } = this.props;

    if (!sendingPush) {
      return null;
    }

    return (
      <Preview
        values={{
          [PUSH_ENGLISH_TITLE_KEY]: pushEnglishTitle,
          [PUSH_RUSSIAN_TITLE_KEY]: pushRussianTitle,
          [PUSH_ENGLISH_MESSAGE_KEY]: pushEnglishMessage,
          [PUSH_RUSSIAN_MESSAGE_KEY]: pushRussianMessage,
        }}
      />
    );
  };
}

export default PushForm;
