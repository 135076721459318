import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/actionResult/consts/destinations';
import {
  DELAY_AFTER_TRIGGER_KEY,
  DESTINATION_KEY,
  HAS_DELAY_AFTER_TRIGGER_KEY,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_STORE_ID_KEY,
  REWARD_BENEFITS_IDS_KEY,
  REWARD_LOYALTY_POINTS_KEY,
  REWARD_TYPE_KEY,
  SEND_PUSH_KEY,
  TRACKED_LOCATION_IDS_KEY,
  TRIGGER_KEY,
} from '@esentai/core/features/campaigns/actionResult/consts/keys';
import {
  BENEFITS_REWARD,
  LOYALTY_POINTS_REWARD,
} from '@esentai/core/features/campaigns/actionResult/consts/rewards';
import { LOCATION_AWARE_TRIGGERS } from '@esentai/core/features/campaigns/actionResult/consts/triggers';
import {
  __,
  always,
  compose,
  empty,
  equals,
  evolve,
  includes,
  not,
  prop,
  propSatisfies,
  when,
} from 'ramda';

const isDisabled = propSatisfies(not);
const isRewardDisabled = rewardType =>
  compose(not, equals(rewardType), prop(REWARD_TYPE_KEY));
const isReferenceUnselected = referredType =>
  compose(not, equals(referredType), prop(DESTINATION_KEY));
const isLocationUnawareTrigger = compose(
  not,
  includes(__, LOCATION_AWARE_TRIGGERS),
  prop(TRIGGER_KEY),
);
const reset = always(null);

const resetBenefitsReward = evolve({
  [REWARD_BENEFITS_IDS_KEY]: empty,
});

const resetDelay = evolve({
  [DELAY_AFTER_TRIGGER_KEY]: always(10),
});

const resetLoyaltyPointsReward = evolve({
  [REWARD_LOYALTY_POINTS_KEY]: reset,
});

const resetPush = evolve({
  [PUSH_ENGLISH_TITLE_KEY]: reset,
  [PUSH_RUSSIAN_TITLE_KEY]: reset,
  [PUSH_ENGLISH_MESSAGE_KEY]: reset,
  [PUSH_RUSSIAN_MESSAGE_KEY]: reset,
});

const resetReferences = evolve({
  [REFERRED_ARTICLE_ID_KEY]: reset,
  [REFERRED_CALENDAR_EVENT_ID_KEY]: reset,
  [REFERRED_STORE_ID_KEY]: reset,
});

const resetRewards = evolve({
  [REWARD_BENEFITS_IDS_KEY]: empty,
  [REWARD_LOYALTY_POINTS_KEY]: reset,
});

const resetLocationIds = evolve({
  [TRACKED_LOCATION_IDS_KEY]: reset,
});

const resetReferredCalendarEvent = evolve({
  [REFERRED_CALENDAR_EVENT_ID_KEY]: reset,
});

const resetReferredStore = evolve({
  [REFERRED_STORE_ID_KEY]: reset,
});

const resetReferredArticle = evolve({
  [REFERRED_ARTICLE_ID_KEY]: reset,
});

const resetReferredBenefit = evolve({
  [REFERRED_BENEFIT_ID_KEY]: reset,
});

export const cleanUpValues = compose(
  when(isDisabled(DESTINATION_KEY), resetReferences),
  when(isDisabled(HAS_DELAY_AFTER_TRIGGER_KEY), resetDelay),
  when(isDisabled(REWARD_TYPE_KEY), resetRewards),
  when(isDisabled(SEND_PUSH_KEY), resetPush),
  when(isRewardDisabled(BENEFITS_REWARD), resetBenefitsReward),
  when(isRewardDisabled(LOYALTY_POINTS_REWARD), resetLoyaltyPointsReward),
  when(isReferenceUnselected(ARTICLE_DESTINATION), resetReferredArticle),
  when(
    isReferenceUnselected(CALENDAR_EVENT_DESTINATION),
    resetReferredCalendarEvent,
  ),
  when(isReferenceUnselected(STORE_DESTINATION), resetReferredStore),
  when(isReferenceUnselected(BENEFIT_DESTINATION), resetReferredBenefit),
  when(isLocationUnawareTrigger, resetLocationIds),
);
