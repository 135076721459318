import { IconButton } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';

class BackButton extends Component {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
  };

  handlerClick = () => {
    const { goBack } = this.props;

    goBack();
  };

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div className={classes.container}>
        <IconButton
          className={classes.icon}
          {...omit(['redirect', 'location'], rest)}
          onClick={this.handlerClick}
        >
          <ArrowBackIos />
        </IconButton>
      </div>
    );
  }
}

export default BackButton;
