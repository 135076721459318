import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const GridSection = ({ children, classes, name, Aside, ...props }) => (
  <Grid container className={classes.formSection} {...props}>
    <Grid item xs={2}>
      <Typography
        variant="button"
        color="textSecondary"
        gutterBottom
        className={classes.formSectionName}
      >
        {name}
      </Typography>
    </Grid>
    <Grid item xs={Aside ? 6 : 10} className={classes.sectionBody}>
      {children}
    </Grid>
    {Aside && (
      <Grid item xs={4}>
        <Aside />
      </Grid>
    )}
  </Grid>
);

GridSection.defaultProps = {
  Aside: null,
  name: '',
};

GridSection.propTypes = {
  Aside: PropTypes.func,
  name: PropTypes.string,
};

export default GridSection;
