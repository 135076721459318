import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CloneButton from '../../buttons/CloneButton';
import EditButton from '../../buttons/EditButton';
import RemoveButton from '../../buttons/RemoveButton';

const DraftStatusActions = ({ itemId }) => (
  <Fragment>
    <EditButton itemId={itemId} />
    <CloneButton itemId={itemId} />
    <RemoveButton itemId={itemId} />
  </Fragment>
);

DraftStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default DraftStatusActions;
