import { T } from 'ramda';
import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { createEditKkmLink } from '@/features/kkmEditPage/serializer';

const mapStateToProps = (state, { kkmId }) => ({
  editPageUrl: createEditKkmLink(kkmId),
  permission: T,
});

export default connect(mapStateToProps)(EditButton);
