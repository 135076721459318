import {
  DATETIME_FILTER_KEY,
  MERCHANT_NAME_KEY,
  SALDO_KEY,
  TOTAL_PAID_KEY,
  TOTAL_TO_PAY_KEY,
} from '@esentai/core/features/merchantsReconciliationArchive/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: MERCHANT_NAME_KEY,
    label: 'Арендатор',
  }),
  createDateRangeFilter({
    key: DATETIME_FILTER_KEY,
    label: 'Дата реконсиляции',
  }),
  createMoneyRangeFilter({
    key: TOTAL_TO_PAY_KEY,
    label: 'Итоговая сумма к оплате',
  }),
  createMoneyRangeFilter({
    key: TOTAL_PAID_KEY,
    label: 'Итоговая оплаченная сумма',
  }),
  createMoneyRangeFilter({
    key: SALDO_KEY,
    label: 'Сальдо',
  }),
];
