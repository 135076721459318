import { STATUS_VALUES } from '@esentai/core/features/kkms/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import MerchantBinLazy from '@/containers/MerchantBinLazy';
import StoreNameLazy from '@/containers/StoreNameLazy';
import { canUpdateKkm } from '@/features/kkmsPage/permissions';

import Actions from './Actions';

const Row = ({
  status,
  // eslint-disable-next-line camelcase
  fns_id,
  store,
  kkmId,
  merchant,
  code,
  kkmPageUrl,
}) => (
  <TableRow>
    <CondenseTableCell>
      <Link to={kkmPageUrl}>{kkmId}</Link>
    </CondenseTableCell>

    <CondenseTableCell>
      {/* eslint-disable-next-line camelcase */}
      <Link to={kkmPageUrl}>{fns_id}</Link>
    </CondenseTableCell>

    <CondenseTableCell>{code}</CondenseTableCell>

    <CondenseTableCell>
      <MerchantBinLazy merchantId={merchant} />
    </CondenseTableCell>

    <CondenseTableCell>
      <StoreNameLazy storeId={store} />
    </CondenseTableCell>

    <CondenseTableCell>{STATUS_VALUES[status]}</CondenseTableCell>

    <CanView permission={canUpdateKkm} kkmId={kkmId}>
      <CondenseTableCell>
        <Actions kkmId={kkmId} />
      </CondenseTableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  status: PropTypes.string.isRequired,
  fns_id: PropTypes.string.isRequired,
  store: PropTypes.string.isRequired,
  kkmId: PropTypes.any.isRequired,
  kkmPageUrl: PropTypes.string.isRequired,
  merchant: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

Row.defaultProps = {
  categoryKeys: [],
  brandsIds: [],
};

export default Row;
