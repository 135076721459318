import { IMAGES_URLS_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import PropTypes from 'prop-types';
import React from 'react';

import Image from '@/features/campaignPage/Page/Image';
import Label from '@/features/campaignPage/Page/Label';

const Images = ({ payload }) => {
  const urls = payload[IMAGES_URLS_KEY];

  return (
    <Label title="Изображение">
      {urls.map(url => (
        <Image key={url} src={url} />
      ))}
    </Label>
  );
};

Images.propTypes = {
  payload: PropTypes.object.isRequired,
};

export default Images;
