export default {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    backgroundColor: 'rgba(50, 50, 50, 0.5)',
  },

  closeIcon: {
    position: 'absolute',
    top: 6,
    right: 5,
    zIndex: 1,
  },

  modal: {
    position: 'relative',
    margin: [28, 16],
  },

  image: {
    maxWidth: '100%',
    margin: '0 auto',
  },

  placeholder: {
    width: 177,
    height: 250,
    backgroundColor: '#C7C7C7',
  },

  title: {
    position: 'absolute',
    left: 8,
    bottom: 16,
    fontFamily: 'Giorgio Sans',
    fontWeight: 'bold',
    fontSize: 26,
    lineHeight: 1,
    textTransform: 'uppercase',
    letterSpacing: 0.03,
  },
};
