import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import CommonTable from '@/components/CommonTable';

import { updateLimit, updatePage, updateSort } from '../../actions';
import { getLimit, getPage, getSort } from '../../selectors';
import BulkActionsRow from '../BulkActionsRow';
import ColGroup from '../ColGroup';
import ErrorMessage from '../ErrorMessage';
import FiltersRow from '../FiltersRow';
import HeadRow from '../HeadRow';
import Row from '../Row';

const mapStateToProps = state => ({
  limit: getLimit(state),
  page: getPage(state),
  sort: getSort(state),
});

const mapDispatchToProps = { updateLimit, updatePage, updateSort };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    ErrorMessage,
    HeadRow,
    Row,
    TopRow: FiltersRow,
    ColGroup,
    BulkActionsRow,
  }),
)(CommonTable);
