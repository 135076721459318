import { BONUSES_COST_KEY } from '@esentai/core/features/merchantsReconciliationReceipts/consts/keys';
import { annotations } from '@esentai/core/features/merchantsReconciliationReceipts/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(BONUSES_COST_KEY),
  }),
});
