import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FiltersRow from '@/components/FiltersRow';
import { ROUTE_PATH } from '@/features/carListPage/consts';
import { filterPrefixes, filterTypes } from '@/features/carListPage/filters';

const PurchaseWithoutScanFiltersRow = props => {
  const [filters, setFilters] = useState(new Map());
  const currentUrlParams = new URLSearchParams(window.location.search);

  const changeUrlParams = params => {
    props.history.push({
      pathname: ROUTE_PATH,
      search: `?${decodeURIComponent(params.toString())}`,
    });
  };

  const getFilterDuplicate = () => {
    const map = new Map();

    filters.forEach((value, key) => {
      map.set(key, value);
    });

    return map;
  };

  const updateFilters = payload => {
    const initMap = getFilterDuplicate();

    Object.keys(payload).forEach(fieldName => {
      const mapValue = payload[fieldName];

      const { prefix } = filterPrefixes.find(
        filter => filter.field === fieldName,
      );
      const value = mapValue.get(prefix);

      currentUrlParams.set(`${fieldName}[${prefix}]`, value);
      initMap.set(fieldName, mapValue);
    });

    changeUrlParams(currentUrlParams);
    setFilters(initMap);
  };

  const removeFilters = payload => {
    const params = new URLSearchParams(window.location.search);
    const initMap = getFilterDuplicate();

    payload.map(filter => {
      initMap.delete(filter);

      const { prefix } = filterPrefixes.find(({ field }) => field === filter);

      params.delete(`${filter}[${prefix}]`);

      return filter;
    });

    changeUrlParams(params);
    setFilters(initMap);
  };

  useEffect(() => {
    const initMap = new Map();
    let valueMap = new Map();
    let canClean = false;

    for (const param of currentUrlParams) {
      const name = param[0];
      const value = param[1];

      if (canClean) {
        valueMap = new Map();
        canClean = false;
      }

      if (name !== 'sort') {
        let filteredName = name.replace('[gte]', '').replace('[lte]', '');

        const filter = filterPrefixes.find(
          ({ field, prefix }) => `${field}[${prefix}]` === filteredName,
        );

        filteredName = filteredName.replace(`[${filter.prefix}]`, '');

        valueMap.set(filter.prefix, value);
        initMap.set(filteredName, valueMap);
      }
    }
    setFilters(initMap);
  }, []);

  return (
    <FiltersRow
      filters={filters}
      updateFilters={updateFilters}
      removeFilters={removeFilters}
      filterTypes={filterTypes}
    />
  );
};

PurchaseWithoutScanFiltersRow.propTypes = {
  history: PropTypes.object.isRequired,
};

export default PurchaseWithoutScanFiltersRow;
