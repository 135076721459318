import {
  getEnglishName,
  getImages,
  getKazakhName,
  getRussianName,
} from '@esentai/core/features/productView/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';

const mapStateToProps = (state, { item: itemId }) => ({
  itemId,
  russianName: getRussianName(state, itemId),
  englishName: getEnglishName(state, itemId),
  kazakhName: getKazakhName(state, itemId),
  images: getImages(state, itemId),
});

export default compose(connect(mapStateToProps))(Row);
