export default ({ spacing, palette }) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },

  icon: {
    margin: spacing.unit,
    marginLeft: 0,
    color: palette.secondary.main,
  },

  root: {
    position: 'relative',
    width: 220,
  },

  isOpenContainer: {},

  paper: {},

  container: {
    marginTop: spacing.unit / 2,
  },
});
