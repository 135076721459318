import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormatExact, formatMoney } from '@/utils/format';

const loyaltyLevels = {
  1: 'Orange',
  2: 'Black',
  3: 'JetBlack',
};

const paymentStatuses = {
  0: 'Не оплачено',
  1: 'Оплачено',
};

const Row = props => {
  const {
    id,
    customer,
    order,
    record_created,
    exit_datetime,
    // valet,
    vehicle_model,
    vehicle_gov_number,
    debt_amount,
    // temporary_gov_number,
    chargedFromEfsBalance,
    discount_type,
    discount_details,
  } = props.item;

  const getAmountMoney = () => (order.amount ? formatMoney(order.amount) : '-');

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{order.id}</TableCell>
      <TableCell>
        <Link to={createUserPageUrl(customer.id)}>{customer.id}</Link>
      </TableCell>
      <TableCell>{loyaltyLevels[customer.level]}</TableCell>
      <TableCell>
        {vehicle_model} {vehicle_gov_number}
      </TableCell>
      <TableCell>
        {record_created
          ? dateTimeFormatExact(record_created, 'DD-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>
        {exit_datetime
          ? dateTimeFormatExact(exit_datetime, 'DD-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>{getAmountMoney()}</TableCell>
      <TableCell>{debt_amount ? formatMoney(debt_amount) : '-'}</TableCell>
      {/* <TableCell>{valet ? 'Valet' : '-'}</TableCell> */}
      <TableCell>{paymentStatuses[order.result] || 'В процессе'}</TableCell>
      <TableCell>{chargedFromEfsBalance}</TableCell>
      <TableCell>{discount_type}</TableCell>
      <TableCell>
        {discount_details !== null ? discount_details.total_hours : ''}
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
