import { Close as CloseIcon } from '@material-ui/icons';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';

const RevokeButton = props => (
  <ActionButton
    text="Отозвать приглашение"
    button={AsyncButton}
    icon={CloseIcon}
    {...props}
  />
);

export default RevokeButton;
