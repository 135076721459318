export default theme => ({
  icon: {
    display: 'none',
  },
  active: {
    '& $icon': {
      display: 'block',
    },
  },
  root: {
    '& + &::before': {
      content: "'/'",
      display: 'inline-block',
      padding: [0, '1ch'],
      color: theme.palette.text.secondary,
    },
  },
});
