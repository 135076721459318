import Grid from '@material-ui/core/Grid';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';

import Table from './Table';

const ArchiveBalancePage = ({ classes, location }) => {
  const [archiveBalanceInfo, setArchiveBalanceInfo] = useState({
    loading: false,
    data: [],
    error: null,
  });

  const [pageInfo, setPageInfo] = useState({
    limit: 10,
    totalCount: 0,
    page: 0,
  });

  const loadArchiveBalanceData = async (params = {}) => {
    setArchiveBalanceInfo(prevState => ({
      ...prevState,
      loading: true,
    }));

    const searchParams = new URLSearchParams(window.location.search);

    const { limit, page } = pageInfo;

    const filters = {
      limit,
      offset: limit * page,
      sort: searchParams.get('sort'),
      ...params,
    };

    try {
      const response = await api.doGet(`/transaction-efs`, { ...filters });

      setArchiveBalanceInfo(prevState => ({
        ...prevState,
        data: _get(response, `payload.efs_balance_transaction`, []),
      }));

      setPageInfo(prevState => ({
        ...prevState,
        totalCount: response.meta.total,
      }));
    } catch (error) {
      setArchiveBalanceInfo(prevState => ({
        ...prevState,
        error,
      }));

      throw error;
    } finally {
      setArchiveBalanceInfo(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onSetParams = () => {
    const searchList = decodeURIComponent(
      new URLSearchParams(window.location.search),
    );
    const filterList = searchList.toString().split('&');
    const params = {};

    filterList.map(filter => {
      const value = filter.split('=');

      params[`${value[0]}`] = value[1];

      return filter;
    });

    return { params };
  };

  const onSetPageSize = newPageSize => {
    setPageInfo(prevState => ({
      ...prevState,
      page: 0,
      limit: newPageSize,
    }));
  };

  const onSetPage = newPage => {
    setPageInfo(prevState => ({
      ...prevState,
      page: newPage,
    }));
  };

  useEffect(() => {
    const { params } = onSetParams();

    loadArchiveBalanceData(params);
  }, [pageInfo.limit, pageInfo.page, location.search]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>
            Архив по добавлению баланса
          </PageTitle>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        <Table
          limit={pageInfo.limit}
          items={archiveBalanceInfo.data}
          isLoading={archiveBalanceInfo.loading}
          totalCount={pageInfo.totalCount}
          page={pageInfo.page}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
      </PageContent>
    </Page>
  );
};

ArchiveBalancePage.propTypes = {
  location: PropTypes.object.isRequired,
};
export default ArchiveBalancePage;
