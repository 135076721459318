import { findCampaignById } from '@esentai/core/features/benefits/actions';
import { isLoadingBenefit } from '@esentai/core/features/benefits/selectors';
import { getBannerBenefitId } from '@esentai/core/features/campaigns/banner/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import BenefitReference from '@/features/campaignCreatePage/EditPage/SecondStepBanner/BenefitInlineSearch/Suggestion';
import withItemApiRunner from '@/hocs/withItemApiRunner';

const mapStateToProps = (state, { itemId }) => {
  const benefitId = getBannerBenefitId(state, itemId);

  return {
    isLoading: isLoadingBenefit(state, benefitId),
    itemId: benefitId,
  };
};

const mapDispatchToProps = { fetchItem: findCampaignById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(BenefitReference);
