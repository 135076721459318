import {
  NAME_KEY,
  ROLE_KEY,
  STATE_KEY,
  STORE_IDS_KEY,
} from '@/features/personnel/consts/keys';
import { createPersonnelActivityStateFilter } from '@/filters/PersonnelActivityState';
import { createPersonnelRoleFilter } from '@/filters/PersonnelRole';
import { createTextFilter } from '@/filters/SearchInput';
import { createStoreFilter } from '@/filters/Store';
import { createIncludeAdapter } from '@/structs/pageFilterAdapter';

export const filterTypes = [
  createPersonnelActivityStateFilter({
    key: STATE_KEY,
    label: 'Состояние пользователя',
    quicklyAccessible: true,
  }),
  createTextFilter({
    key: NAME_KEY,
    label: 'Имя пользователя',
  }),
  createStoreFilter({
    key: STORE_IDS_KEY,
    label: 'Магазин',
    adapter: createIncludeAdapter(null),
  }),
  createPersonnelRoleFilter({
    key: ROLE_KEY,
    label: 'Роль пользователя',
  }),
];
