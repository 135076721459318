import { Avatar, List } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import RootRef from '@material-ui/core/RootRef';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const ProductsList = ({ products, images, items, setProducts }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: 'rgb(235,235,235)',
    }),
  });

  const getListStyle = () => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  });

  const dragEnd = result => {
    const curProducts = products;
    const curImgs = [];

    if (result.destination) {
      const [removed] = curProducts.splice(result.source.index, 1);

      curProducts.splice(result.destination.index, 0, removed);

      curProducts.reduce((imgs, cur) => {
        imgs.push(...items[cur].images);

        return imgs;
      }, curImgs);

      setProducts(curProducts, curImgs);
    }
  };

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <RootRef rootRef={provided.innerRef}>
            <Paper style={{ maxHeight: 200, maxWidth: 500, overflow: 'auto' }}>
              <List style={getListStyle(snapshot.isDraggingOver)}>
                {products.length > 0 ? (
                  products
                    .filter(p => p && items[p])
                    .map((prod, index) => (
                      /* eslint-disable */
                      <Draggable key={prod} draggableId={prod} index={index}>
                        {(provided, snapshot) => (
                          <ListItem
                            ContainerComponent="li"
                            ContainerProps={{ ref: provided.innerRef }}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={
                                  items[prod] && items[prod].images.length > 0
                                    ? items[prod].images[0]
                                    : ''
                                }
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={items[prod].title_ru}
                              secondary={`${items[prod].price} Тенге`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  let tempProd = products.slice();
                                  tempProd.splice(index, 1);
                                  setProducts(
                                    tempProd,
                                    images.filter(
                                      img => !items[prod].images.includes(img),
                                    ),
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                      </Draggable>
                      /* eslint-enable */
                    ))
                ) : (
                  <ListItem ContainerComponent="li">
                    <ListItemText
                      style={{ textAlign: 'center' }}
                      primary={'Вы еще не добавили товары'}
                    />
                  </ListItem>
                )}
                {provided.placeholder}
              </List>
            </Paper>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  );
};

ProductsList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  items: PropTypes.object.isRequired,
  selectProduct: PropTypes.func.isRequired,
  setProducts: PropTypes.func.isRequired,
};

export default ProductsList;
