import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { getCuttedText } from '@/utils/format';

const Option = ({ isHighlighted, label, bin, ...props }) => (
  <MenuItem
    {...props}
    selected={isHighlighted}
    component="div"
    title={`${bin ? `${label} (${bin})` : label}`}
  >
    {getCuttedText(bin ? `${label} (${bin})` : label, 40)}
  </MenuItem>
);

Option.propTypes = {
  isHighlighted: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  bin: PropTypes.string.isRequired,
};

export default Option;
