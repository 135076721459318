import {
  BRANDS_IDS_KEY,
  CATEGORIES_KEY,
  DESCRIPTION_ENGLISH_KEY,
  DESCRIPTION_KAZAKH_KEY,
  DESCRIPTION_RUSSIAN_KEY,
  LOCATION_ID_KEY,
  LOGO_URL_KEY,
  LOYALTY_REP_KEY,
  MERCHANTS_IDS_KEY,
  NAME_ENGLISH_KEY,
  NAME_KAZAKH_KEY,
  NAME_KEY,
} from '@esentai/core/features/stores/consts/keys';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import CategoriesInput from '@/components/CategoriesInput';
import FieldRow from '@/components/FieldRow';
import { BrandMultiAutocomplete } from '@/containers/BrandAutocomplete';
import { MerchantMultiAutocomplete } from '@/containers/MerchantAutocomplete';
import StoreImageFormControl from '@/containers/StoreImageFormControl';
import FormikPropTypes from '@/prop-types/formik';

const fieldNames = {
  [NAME_KEY]: 'название магазина',
  [NAME_ENGLISH_KEY]: 'название на английском',
  [NAME_KAZAKH_KEY]: 'название на казахском',

  [DESCRIPTION_RUSSIAN_KEY]: 'краткое описание на русском',
  [DESCRIPTION_ENGLISH_KEY]: 'краткое описание на английском',
  [DESCRIPTION_KAZAKH_KEY]: 'краткое описание на казахском',

  [CATEGORIES_KEY]: 'категория',
  [BRANDS_IDS_KEY]: 'бренды',
  [LOCATION_ID_KEY]: 'локация',
  [LOYALTY_REP_KEY]: 'партнер программы лояльности',
};

class NewStoreForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
  };

  setCategories = categories => {
    const { setFieldValue } = this.props;

    setFieldValue(CATEGORIES_KEY, categories);
  };

  onChangeBrand = brandId => this.props.setFieldValue(BRANDS_IDS_KEY, brandId);

  onChangeLocation = locationId =>
    this.props.setFieldValue(LOCATION_ID_KEY, locationId);

  setLoyaltyRep = event => {
    const { checked } = event.target;

    this.props.setFieldValue(LOYALTY_REP_KEY, checked);
  };

  onChangeMerchant = merchantId =>
    this.props.setFieldValue(MERCHANTS_IDS_KEY, merchantId);

  onValidationError = errors => {
    if (Array.isArray(errors)) {
      this.props.setFieldError(LOGO_URL_KEY, errors.join(', '));
    }
  };

  handleChangeImage = ([url]) => {
    const { setFieldValue } = this.props;

    setFieldValue(LOGO_URL_KEY, url);
  };

  render() {
    const {
      classes,
      values,
      submitButtonText,
      errors,
      Field,
      Form,
      RequiredMessages,
      SubmitButton,
    } = this.props;

    return (
      <Form>
        <FieldRow label="Название магазина">
          <Field
            id={NAME_KEY}
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={NAME_KEY}
            label="Название магазина"
          />
          <Field
            id={NAME_ENGLISH_KEY}
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={NAME_ENGLISH_KEY}
            label="Название магазина на английском"
          />
          <Field
            id={NAME_KAZAKH_KEY}
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={NAME_KAZAKH_KEY}
            label="Название магазина на казахском"
          />
        </FieldRow>

        <FieldRow label="Описание магазина">
          <Field
            multiline
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={DESCRIPTION_RUSSIAN_KEY}
            rows={4}
            label="Краткое описание магазина на русском"
            helperText="Данное описание будет отображаться на странице магазина в Mall Guide"
          />
          <Field
            multiline
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={DESCRIPTION_ENGLISH_KEY}
            rows={4}
            label="Краткое описание магазина на английском"
            helperText="Данное описание будет отображаться на странице магазина в Mall Guide"
          />
          <Field
            multiline
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={DESCRIPTION_KAZAKH_KEY}
            rows={4}
            label="Краткое описание магазина на казахском"
            helperText="Данное описание будет отображаться на странице магазина в Mall Guide"
          />

          <StoreImageFormControl
            value={values[LOGO_URL_KEY]}
            onChange={this.handleChangeImage}
            margin="normal"
            error={Boolean(errors[LOGO_URL_KEY])}
            className={classes.imageControl}
            onValidationError={this.onValidationError}
            storeImageError={errors[LOGO_URL_KEY]}
          />
        </FieldRow>

        <FieldRow
          label="Категория"
          subLabel="Категория магазина"
          sudDesc="Выберите категории магазина, какие типы товаров в нем продаются. Это поможет пользователям искать магаины."
        >
          <CategoriesInput
            selectedItem={values[CATEGORIES_KEY]}
            onChange={this.setCategories}
            InputProps={{
              placeholder: 'Выберите категорию',
              error: errors[CATEGORIES_KEY],
            }}
          />
        </FieldRow>

        <FieldRow
          label="Бренд"
          subLabel="Бренды магазина"
          sudDesc="Выберите бренды магазина, которые в нем реализуются. Это поможет пользователям искать магазины."
        >
          <BrandMultiAutocomplete
            id={BRANDS_IDS_KEY}
            className={classes.wrap}
            name={BRANDS_IDS_KEY}
            onChange={this.onChangeBrand}
            selectedItem={values[BRANDS_IDS_KEY]}
            InputProps={{
              placeholder: 'Выберите бренд',
              error: errors[BRANDS_IDS_KEY],
            }}
          />
        </FieldRow>

        <FieldRow
          label="БИН/ИИН"
          subLabel="БИН/ИИН"
          sudDesc="Выберите БИН/ИИН арендатора."
        >
          <MerchantMultiAutocomplete
            id={MERCHANTS_IDS_KEY}
            className={classes.wrap}
            name={MERCHANTS_IDS_KEY}
            onChange={this.onChangeMerchant}
            selectedItem={values[MERCHANTS_IDS_KEY]}
            InputProps={{
              placeholder: 'БИН/ИИН',
              error: errors[MERCHANTS_IDS_KEY],
            }}
          />
        </FieldRow>

        <FieldRow
          label="Партнер программы лояльности"
          subLabel="Отобразить магазин как партнер программы лояльности?"
        >
          <FormControlLabel
            label="Отображать иконку об участии в программе лояльности"
            control={
              <Checkbox
                checked={values[LOYALTY_REP_KEY]}
                onChange={this.setLoyaltyRep}
              />
            }
          />
        </FieldRow>

        <ButtonSet>
          <RequiredMessages fieldNames={fieldNames} />
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            {submitButtonText}
          </SubmitButton>
        </ButtonSet>
      </Form>
    );
  }
}

export default NewStoreForm;
