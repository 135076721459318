export default {
  application: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },

  applicationName: {
    fontSize: 7,
    marginTop: 1,
    textTransform: 'uppercase',
    opacity: 0.75,
  },

  body: {
    textAlign: 'left',
  },

  icon: {
    flexShrink: 0,
    flexBasis: 11,
    height: 11,
    backgroundColor: '#9B9B9B',
    marginRight: 5,
    borderRadius: 3,
  },

  root: {
    padding: 9,
    margin: [0, 6],
    fontFamily: 'Helvetica',
    borderRadius: 8,
    backgroundColor: 'rgba(248, 248, 248, 0.75)',
  },

  title: {
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 1,
    minHeight: '1.5em',
  },

  message: {
    fontSize: 8,
    lineHeight: 1.2,
    minHeight: '1.2em',
    wordWrap: 'break-word',
  },
};
