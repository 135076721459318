import { DATE_ISSUED_KEY } from '@esentai/core/features/receipts/consts/keys';
import { annotations } from '@esentai/core/features/receipts/serializer';
import { descending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(DATE_ISSUED_KEY),
  }),
});
