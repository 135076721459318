import { annotations } from '@/features/locationsStatistics/serializer';
import { createPageSerializer, getLinkCreator } from '@/serializers';

import { ROUTE_PATH } from './consts';

const serializer = createPageSerializer({
  ...annotations,
});

export const createLink = getLinkCreator(ROUTE_PATH, serializer);

export default serializer;
