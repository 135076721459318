import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = ['MALL', 'TOWER'].map(key => ({
  key,
  label: key,
}));

export const createParkingBuildingFilter = createRadioFilterTemplate({
  options,
});

export default RadioGroupFilter;
