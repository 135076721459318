import { Button as MaterialUiButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';

class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string,
    redirect: PropTypes.func.isRequired,
    component: PropTypes.func,
  };

  static defaultProps = {
    onClick: () => null,
    href: null,
    component: MaterialUiButton,
  };

  render() {
    const { component: ComponentButton, ...props } = this.props;

    return (
      <ComponentButton {...omit(['redirect'], props)} onClick={this.onClick} />
    );
  }

  onClick = event => {
    const { href, onClick, redirect } = this.props;

    onClick(event);

    if (href && !(event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      redirect(href);
    }
  };
}

export default Button;
