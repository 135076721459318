import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { formatPercentage } from '@/utils/format';

const FlowRateChart = ({ classes, percentage, label }) => (
  <div className={classes.wrapper}>
    <Typography className={classes.label}>{label}</Typography>
    <div className={classes.chart} />
    <Typography variant="caption" className={classes.percentage}>
      {formatPercentage(percentage)}
    </Typography>
  </div>
);

FlowRateChart.propTypes = {
  label: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};
export default FlowRateChart;
