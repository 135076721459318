import { findParkingLevelThreeWhitelistById } from '@esentai/core/features/parkingLevelThreeWhitelist/actions';
import {
  getLastNameForParkingLevelThreeWhitelist,
  isParkingLevelThreeWhitelistLoaded,
  isParkingLevelThreeWhitelistLoading,
} from '@esentai/core/features/parkingLevelThreeWhitelist/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import ParkingLevelThreeWhitelistAddCell from './component';

const mapStateToProps = (state, { itemId, isLoaded }) => ({
  isLoaded: !isLoaded
    ? isParkingLevelThreeWhitelistLoaded(state, itemId)
    : isLoaded(state, itemId),
  isLoading: isParkingLevelThreeWhitelistLoading(state, itemId),
  itemId,
  name: getLastNameForParkingLevelThreeWhitelist(state, itemId),
});

const mapDispatchToProps = { fetchItem: findParkingLevelThreeWhitelistById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(ParkingLevelThreeWhitelistAddCell);
