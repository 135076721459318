import PropTypes from 'prop-types';

const WhitelistNameLazy = ({ isLoading, title }) =>
  !isLoading ? title : '...';

WhitelistNameLazy.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

WhitelistNameLazy.defaultProps = {
  title: null,
};

export default WhitelistNameLazy;
