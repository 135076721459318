export default {
  root: {
    flexGrow: 1,
    maxWidth: 350,
    paddingBottom: 4,
  },

  icon: {
    display: 'none',
  },
};
