import { findBrandById } from '@esentai/core/features/brands/actions';
import {
  getName,
  isBrandLoaded,
  isBrandLoading,
} from '@esentai/core/features/brands/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import BrandTitleLazy from './component';

const mapStateToProps = (state, { itemId }) => ({
  isLoaded: isBrandLoaded(state, itemId),
  isLoading: isBrandLoading(state, itemId),
  itemId,
  title: getName(state, itemId),
});

const mapDispatchToProps = { fetchItem: findBrandById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(BrandTitleLazy);
