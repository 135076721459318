import api from '@esentai/core/features/salesTurnovers/api';
import { CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  dateTimeFormatNoSecs,
  formatMoney,
  formatMoneyUsd,
} from '@/utils/format';

import Field from '../Field';

const Description = ({ itemId }) => {
  const [storeData, setStoreData] = useState(null);
  const [isloading, setIsLoading] = useState(true);

  const fetchDetails = async () => {
    const filters = [['store', 'eq', itemId]];
    const data = await api.doQuery(filters, 'index', 'asc', 9999);

    if (data.payload.sales_turnover && data.payload.sales_turnover.length > 0) {
      setStoreData(data.payload.sales_turnover[0]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      {isloading ? (
        <CircularProgress />
      ) : (
        <>
          {storeData ? (
            <>
              <Field label="Магазин">{storeData.store_name}</Field>
              <Field label="Арендатор">{storeData.merchant_name}</Field>
              <Field label="Дата подключения">
                {dateTimeFormatNoSecs(storeData.starting_from)}
              </Field>
              <Field label="Обороты остановились с">
                {dateTimeFormatNoSecs(storeData.last_sync)}
              </Field>
              <Field label="Количество покупок">{storeData.sales_count}</Field>
              <Field label="Оборот KZT">
                {formatMoney(storeData.amount_kzt)}
              </Field>
              <Field label="Оборот USD">
                {formatMoneyUsd(storeData.amount_usd)}
              </Field>
            </>
          ) : (
            <Typography variant="body1">Данные не найдены</Typography>
          )}
        </>
      )}
    </>
  );
};

Description.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default Description;
