export default ({ palette, spacing }) => ({
  root: {
    width: '100%',
    display: 'flex',
    paddingTop: spacing.unit * 2,
    borderTop: [1, 'solid', palette.chart.stroke],
  },

  userInfo: {
    flexGrow: 1,
  },

  userIcon: {
    flex: 'none',
    marginRight: spacing.unit,
    marginTop: spacing.unit,
    color: palette.logo.white.esentaiColor,
  },

  exitButton: {
    flex: 'none',
    alignSelf: 'center',
    marginLeft: spacing.unit,
  },
});
