import React from 'react';

import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';

import { AFTERNOON_KEY, EVENING_KEY, MORNING_KEY } from './consts';
import { createDayPartAdapter } from './utils';

export const options = [
  {
    key: MORNING_KEY,
    label: 'Утро',
  },
  {
    key: AFTERNOON_KEY,
    label: 'День',
  },
  {
    key: EVENING_KEY,
    label: 'Вечер',
  },
];

const DayPartStatus = props => (
  <CheckboxGroupFilter {...props} options={options} />
);

export const createDayPartFilter = createCheckboxFilterTemplate({
  FilterComponent: DayPartStatus,
  options,
  adapter: createDayPartAdapter([]),
});

export default DayPartStatus;
