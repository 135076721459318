import {
  getMallStatCreationDate,
  getMallStatFilename,
  getMallStatFileUrl,
} from '@esentai/core/features/mallStats/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: mallStatId }) => ({
  filename: getMallStatFilename(state, mallStatId),
  fileUrl: getMallStatFileUrl(state, mallStatId),
  creationDate: getMallStatCreationDate(state, mallStatId),
  mallStatId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
