import { getBenefitTitle } from '@esentai/core/features/benefits/selectors';
import { connect } from 'react-redux';

import Option from '@/components/AutocompleteBase/Option';

const mapStateToProps = (state, { value }) => ({
  label: getBenefitTitle(state, value),
});

export default connect(mapStateToProps)(Option);
