import { deleteParkingWhiteList } from '@esentai/core/features/parkingWhitelist/actions';
import { T } from 'ramda';
import { connect } from 'react-redux';

import RemoveButton from '@/components/ActionButtons/Remove';
import { retryFetch } from '@/features/parkingWhitelistGroupDetailsPage/actions';

const mapDispatchToProps = (dispatch, { parkingWhitelistId }) => ({
  onClick: () => {
    dispatch(deleteParkingWhiteList(parkingWhitelistId));
    setTimeout(() => dispatch(retryFetch()), 400);
  },
  permission: T,
});

export default connect(null, mapDispatchToProps)(RemoveButton);
