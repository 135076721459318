import { FormControl } from '@material-ui/core';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import { ROLE_KEY } from '@/features/invites/consts/keys';
import {
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
} from '@/features/personnel/consts/roles';
import FormikPropTypes from '@/prop-types/formik';
import { userRole } from '@/utils/labels';

import ButtonsField from '../ButtonsField';
import { getFieldComponent, getFields } from '../utils';

class StoreAdminForm extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { Form, values, ...rest } = this.props;
    const role = values[ROLE_KEY];
    const fields = getFields(role);

    return (
      <Form>
        <FieldRow
          label="Тип пользователя"
          subLabel="Тип аккаунта пользователя"
          sudDesc="Тип аккаунта определяет, какими правами будет обладать новый пользователь."
        >
          <FormControl component="fieldset">
            <RadioBoxGroup
              name={ROLE_KEY}
              value={values[ROLE_KEY]}
              onChange={this.handleRoleChange}
            >
              <RadioBox
                value={STORE_MANAGER_ROLE}
                label={userRole(STORE_MANAGER_ROLE)}
                helperText="Сотрудник будет отвечать за создание кампаний Магазина."
              />
              <RadioBox
                value={STORE_SELLER_ROLE}
                label={userRole(STORE_SELLER_ROLE)}
                helperText="Сотрудник будет отвечать за возврат товаров и проводить оплату бонусами. Не имеет доступа к панели управления."
              />
            </RadioBoxGroup>
          </FormControl>
        </FieldRow>
        {fields.map(field => {
          const Field = getFieldComponent(field);

          return <Field key={field} values={values} {...rest} />;
        })}
        <ButtonsField {...rest} />
      </Form>
    );
  }

  handleRoleChange = event =>
    this.props.setFieldValue(ROLE_KEY, event.target.value);
}

export default StoreAdminForm;
