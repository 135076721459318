import Draggable from '@esentai/core/components/Draggable';
import DefaultMapTransformer from '@esentai/core/components/MapTransformer';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import BackgroundTrimmer from './BackgroundTrimmer';
import Floor from './Floor';
import ParasiteCursorAreasTrimmer from './ParasiteCursorAreasTrimmer';
import Zoomable from './Zoomable';

const OFFSET_TOP = 140;
const OFFSET_RIGHT = 30;
const OFFSET_BOTTOM = 30;
const OFFSET_LEFT = 220;

class Viewport extends PureComponent {
  static propTypes = {
    floor: PropTypes.string.isRequired,
    height: PropTypes.any.isRequired,
    lock: PropTypes.bool,
    onOffsetChange: PropTypes.func,
    width: PropTypes.any.isRequired,
    MapTransformer: PropTypes.func,
    RentPlace: PropTypes.func.isRequired,
  };

  static defaultProps = {
    lock: false,
    onOffsetChange: () => {},
    MapTransformer: DefaultMapTransformer,
  };

  getWidth = () => 700 + OFFSET_RIGHT;

  getHeight = () => 520 + OFFSET_BOTTOM;

  getViewBox = ({ canvasHeight, canvasWidth, offsetX, offsetY }) => {
    const startX = OFFSET_LEFT + offsetX;
    const startY = OFFSET_TOP + offsetY;

    return `${startX} ${startY} ${canvasWidth} ${canvasHeight}`;
  };

  render() {
    const canvasHeight = this.getHeight();
    const canvasWidth = this.getWidth();

    return (
      <Zoomable
        canvasHeight={canvasHeight}
        canvasWidth={canvasWidth}
        renderContent={this.renderDraggable}
      />
    );
  }

  renderDraggable = props => {
    const { lock, onOffsetChange } = this.props;

    return (
      <Draggable
        {...props}
        lock={lock}
        renderContent={this.renderFloor}
        onOffsetChange={onOffsetChange}
      />
    );
  };

  renderFloor = ({ canvasHeight, canvasWidth, offsetX, offsetY }) => {
    const { floor, height, width } = this.props;
    const viewBox = this.getViewBox({
      canvasHeight,
      canvasWidth,
      offsetX,
      offsetY,
    });

    return (
      <Floor
        floor={floor}
        height={height}
        width={width}
        viewBox={viewBox}
        MapTransformer={this.renderTransformer}
      />
    );
  };

  renderTransformer = ({ children }) => {
    const { MapTransformer, RentPlace } = this.props;

    return (
      <BackgroundTrimmer>
        <ParasiteCursorAreasTrimmer>
          <MapTransformer RentPlaceComponent={RentPlace}>
            {children}
          </MapTransformer>
        </ParasiteCursorAreasTrimmer>
      </BackgroundTrimmer>
    );
  };
}

export default Viewport;
