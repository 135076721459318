import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PageTitleLink from '@/components/PageTitleLink';
import { ROUTE_PATH } from '@/features/reconciliationArchivePage/consts';
import { downloadXls } from '@/features/reconciliationPage/utils';

import Table from './Table';

class Reconciliation extends Component {
  static propTypes = {
    error: PropTypes.any,
    reconciliationIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const {
      error,
      reconciliationIds,
      isLoading,
      totalCount,
      classes,
    } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <Grid container alignItems="baseline">
            <PageTitle gutterBottom={false}>Действующая реконсиляция</PageTitle>
            <PageTitleLink to={ROUTE_PATH}>Архив</PageTitleLink>
          </Grid>
          {/* <PageTitle gutterBottom={false}>Реконсиляция</PageTitle> */}
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.excelButton}
              onClick={downloadXls}
            >
              Вывести в Excel
            </Button>
            <Table
              error={error}
              items={reconciliationIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default Reconciliation;
