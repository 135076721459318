import { getLocation } from 'connected-react-router';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';

export const pureWithQuerySync = WrappedComponent => {
  class WithQuerySync extends Component {
    static propTypes = {
      location: PropTypes.any.isRequired,
      mount: PropTypes.func.isRequired,
      mounted: PropTypes.bool.isRequired,
      sync: PropTypes.func.isRequired,
      unmount: PropTypes.func.isRequired,
    };

    componentDidMount() {
      const { location, mount } = this.props;

      mount(location);
    }

    componentDidUpdate(prevProps) {
      const { location, sync } = this.props;

      if (location !== prevProps.location) {
        sync(location);
      }
    }

    componentWillUnmount() {
      const { unmount } = this.props;

      unmount();
    }

    render() {
      const { mounted } = this.props;

      if (mounted) {
        return <WrappedComponent {...this.props} />;
      }

      return null;
    }
  }

  return WithQuerySync;
};

export default queryDuck => {
  const { mount, sync, unmount } = queryDuck.actions;
  const { isMounted } = queryDuck.selectors;

  const mapStateToProps = state => ({
    location: getLocation(state),
    mounted: isMounted(state),
  });
  const mapDispatchToProps = { mount, sync, unmount };

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    pureWithQuerySync,
  );
};
