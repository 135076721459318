import { connect } from 'react-redux';

import StoreBonusCanEdit from '@/components/CanEdit';
import StoreBonusCanEditRadioGroup from '@/components/CanEditRadioBoxGroup';

const mapStateToProps = () => ({
  canEdit: true,
});

export const CanEdit = connect(mapStateToProps)(StoreBonusCanEdit);
export const CanEditRadioBoxGroup = connect(mapStateToProps)(
  StoreBonusCanEditRadioGroup,
);
