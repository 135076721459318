import PropTypes from 'prop-types';
import React from 'react';
import { defaultProps } from 'recompose';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import { ROUTE_PATH as STORES_PATH } from '@/features/storesPage/consts';

import FormCreate from './Form';

const Form = defaultProps({
  categories: [],
  russianDescription: '',
  englishDescription: '',
  kazakhDescription: '',
  imageUrl: '',
  name: '',
  name_en: '',
  storesIds: [],
  submitButtonText: 'Создать магазин',
})(FormCreate);

const NewStore = ({ createStore }) => (
  <Page>
    <PageHeader gutterBottom={false} xl={8}>
      <Breadcrumbs>
        <Breadcrumb to={STORES_PATH}>Магазины</Breadcrumb>
      </Breadcrumbs>
      <BackButton />
      <PageTitle>Создание магазина</PageTitle>
    </PageHeader>
    <PageContent xl={8}>
      <PaperSheet>
        <Form onSubmit={createStore} />
      </PaperSheet>
    </PageContent>
  </Page>
);

NewStore.propTypes = {
  createStore: PropTypes.func.isRequired,
};

export default NewStore;
