import {
  ID_KEY,
  PHONE_NUMBER,
  RECORD_CREATED_KEY,
  USER_ID_KEY,
} from '@esentai/core/features/efsVisitRecords/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={ID_KEY}>ID</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={PHONE_NUMBER}>Номер телефона</SortControl>
    </CondenseTableCell>

    {/* <CanView permission={canReadUser}>*/}
    <CondenseTableCell>
      <SortControl field={USER_ID_KEY}>ID пользователя</SortControl>
    </CondenseTableCell>
    {/* </CanView>*/}

    {/* <CanView permission={canReadUser}>*/}
    <CondenseTableCell>Пользователь</CondenseTableCell>
    {/* </CanView>*/}

    <CondenseTableCell style={{ textAlign: 'left' }}>
      <SortControl field={RECORD_CREATED_KEY}>Дата и время входа</SortControl>
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
