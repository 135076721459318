import PropTypes from 'prop-types';
import React from 'react';

import EditButton from '@/features/giftSetsPage/Page/Row/Actions/EditButton';

const Actions = ({ classes, giftSetId }) => (
  <div className={classes.root}>
    <EditButton
      giftSetId={giftSetId}
      small={false}
      color="primary"
      variant="contained"
    />
  </div>
);

Actions.propTypes = {
  giftSetId: PropTypes.number.isRequired,
};

export default Actions;
