import {
  AppBar,
  Button,
  IconButton,
  Popover,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { pick } from 'ramda';
import React from 'react';

const Popup = ({
  classes,
  anchorEl,
  name,
  isOpen,
  renderFilter,
  onChange,
  onClose,
  onSubmit,
  value,
  ...props
}) => {
  if (name === 'Роль пользователя') {
    classes.paper = classes.paperOverflow;
    classes.filterContent = classes.filterContentOverflow;
  }

  return (
    <Popover
      {...props}
      classes={pick(['paper'], classes)}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="subtitle1" color="inherit">
            {name}
          </Typography>

          <IconButton
            color="inherit"
            className={classes.closeIcon}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <form
        onSubmit={submitEvent => {
          submitEvent.preventDefault();
          submitEvent.stopPropagation();

          onSubmit();
        }}
        className={classes.filterContent}
      >
        {renderFilter({ onChange, value })}

        <div className={classes.footer}>
          <Button color="primary" type="submit">
            Применить
          </Button>
        </div>
      </form>
    </Popover>
  );
};

Popup.defaultProps = {
  anchorEl: null,
};

Popup.propTypes = {
  anchorEl: PropTypes.object,
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderFilter: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

export default Popup;
