import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getPersonnelError,
  getItemsIds: getPersonnelIds,
  getItemsMeta: getPersonnelMeta,
  getItemsTotalCount: getPersonnelTotalCount,
  isLoadingItems: isLoadingPersonnel,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
