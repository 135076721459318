import { defaultProps } from 'recompose';

import StatusIndicator from '../StatusIndicator';
import StatusLabel from '../StatusLabel';
import StatusTooltipIndicator from './component';

export default defaultProps({
  StatusIndicator,
  StatusLabel,
})(StatusTooltipIndicator);
