import { withStyles } from '@material-ui/core';

import { createRangeFilterTemplate } from '@/filters/Range';
import { createFormatter } from '@/filters/Range/utils';
import { formatHumanDate } from '@/utils/format';

import DateRangeFilter from './component';
import styles from './styles';

const FilterComponent = withStyles(styles)(DateRangeFilter);

export const createDateRangeFilter = createRangeFilterTemplate({
  FilterComponent,
  FormatComponent: createFormatter({ format: formatHumanDate }),
});

export default FilterComponent;
