import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { Component } from 'react';

import Bilingual from '@/components/Bilingual';
import FieldRow from '@/components/FieldRow';
import Preview from '@/features/campaignCreatePage/EditPage/SecondStepPurchaseResult/PrePushForm/Preview';
import Label from '@/features/campaignPage/Page/Label';
import { formatDateTime } from '@/utils/format';

class PrePush extends Component {
  static propTypes = {
    prePushEnglishMessage: PropTypes.string,
    prePushEnglishTitle: PropTypes.string,
    prePushRussianMessage: PropTypes.string,
    prePushRussianTitle: PropTypes.string,
    prePushSendDate: PropTypes.instanceOf(Date),
    sendingPrePush: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    prePushEnglishMessage: null,
    prePushEnglishTitle: null,
    prePushRussianMessage: null,
    prePushRussianTitle: null,
    prePushSendDate: null,
  };

  renderPreview = () => {
    const {
      prePushEnglishMessage,
      prePushEnglishTitle,
      prePushRussianMessage,
      prePushRussianTitle,
    } = this.props;

    return (
      <Preview
        prePushEnglishMessage={prePushEnglishMessage}
        prePushEnglishTitle={prePushEnglishTitle}
        prePushRussianMessage={prePushRussianMessage}
        prePushRussianTitle={prePushRussianTitle}
      />
    );
  };

  render() {
    const {
      prePushEnglishMessage,
      prePushEnglishTitle,
      prePushRussianMessage,
      prePushRussianTitle,
      prePushSendDate,
      sendingPrePush,
    } = this.props;

    if (!sendingPrePush) {
      return null;
    }

    return (
      <FieldRow label="Рекламное пуш-сообщение" Aside={this.renderPreview}>
        {!isNil(prePushRussianTitle) && (
          <Label title="Заголовок сообщения">
            <Bilingual
              russianContent={prePushRussianTitle}
              englishContent={prePushEnglishTitle}
            />
          </Label>
        )}
        {!isNil(prePushRussianMessage) && (
          <Label title="Текст сообщения">
            <Bilingual
              russianContent={prePushRussianMessage}
              englishContent={prePushEnglishMessage}
            />
          </Label>
        )}
        {!isNil(prePushSendDate) && (
          <Label title="Дата предварительной отправки">
            {formatDateTime(prePushSendDate)}
          </Label>
        )}
      </FieldRow>
    );
  }
}

export default PrePush;
