import PropTypes from 'prop-types';
import React from 'react';

import {
  SUBTITLE_ENGLISH_KEY,
  SUBTITLE_RUSSIAN_KEY,
} from '@/components/ArticleConstructorForm/consts';
import { LOCALE_EN, LOCALE_RU } from '@/hocs/withDevicePreview/consts';

const SubtitleBlock = ({ classes, payload, selectByLocale }) => {
  const title = selectByLocale({
    [LOCALE_RU]: payload[SUBTITLE_RUSSIAN_KEY],
    [LOCALE_EN]: payload[SUBTITLE_ENGLISH_KEY],
  });

  return <div className={classes.text}>{title}</div>;
};

SubtitleBlock.propTypes = {
  payload: PropTypes.object.isRequired,
  selectByLocale: PropTypes.func.isRequired,
};

export default SubtitleBlock;
