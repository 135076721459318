import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { createDraft } from '@/features/storeBonusCreatePage/actions';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import FirstStep from '../FirstStep/component';
import form from '../FirstStep/form';
import styles from '../FirstStep/styles';

const mapDispatchToProps = { onSubmit: createDraft };

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(FirstStep);
