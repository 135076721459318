import { STORE_IDS_KEY } from '@esentai/core/features/users/consts/keys';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import { StoreMultiAutocomplete } from '@/containers/StoreAutocomplete';

class StoresField extends Component {
  static propTypes = {
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const { handleBlur, values, classes } = this.props;

    return (
      <FieldRow
        label="Привязка к магазину"
        subLabel="Магазин"
        sudDesc="Пользователь всегда привязан к определенному магазину и ограничен его пространством и правами доступа."
        className={classes.row}
      >
        <StoreMultiAutocomplete
          id={STORE_IDS_KEY}
          name={STORE_IDS_KEY}
          onChange={this.handleStoreChange}
          onBlur={handleBlur}
          selectedItem={values[STORE_IDS_KEY]}
          InputProps={{
            placeholder: 'Добавьте магазин',
          }}
        />
      </FieldRow>
    );
  }

  handleStoreChange = storeIds =>
    this.props.setFieldValue(STORE_IDS_KEY, storeIds);
}

export default StoresField;
