import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import FlowRateChart from '@/components/FlowRateChart';
import TableCellTypography from '@/components/TableCellTypography';
import {
  formatConversion,
  formatMoney,
  formatNumber,
  formatSeconds,
} from '@/utils/format';

const ratioToPercentage = x => Math.round(x * 1000) / 10;

const CommonCells = ({
  averageCheck,
  averageTimeSpent,
  buyersCount,
  conversion,
  leadsCount,
  purchasesCount,
  usersCount,
  visitorsCount,
  mallVisitorsCount,
}) => (
  <Fragment>
    <CondenseTableCell align="right">
      {formatNumber(usersCount)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatNumber(mallVisitorsCount)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={leadsCount}
        percentage={ratioToPercentage(leadsCount / usersCount)}
      />
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={visitorsCount}
        percentage={ratioToPercentage(visitorsCount / leadsCount)}
      />
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatNumber(buyersCount)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <TableCellTypography>{formatConversion(conversion)}</TableCellTypography>
    </CondenseTableCell>

    <CondenseTableCell align="right">
      {formatNumber(purchasesCount)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatMoney(averageCheck)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatSeconds(averageTimeSpent)}
    </CondenseTableCell>
  </Fragment>
);

export const propTypes = {
  averageCheck: PropTypes.string.isRequired,
  averageTimeSpent: PropTypes.number.isRequired,
  buyersCount: PropTypes.number.isRequired,
  conversion: PropTypes.string.isRequired,
  leadsCount: PropTypes.number.isRequired,
  purchasesCount: PropTypes.number.isRequired,
  usersCount: PropTypes.number.isRequired,
  visitorsCount: PropTypes.number.isRequired,
  mallVisitorsCount: PropTypes.number.isRequired,
};

CommonCells.propTypes = propTypes;

export default CommonCells;
