import {
  BIN_KEY,
  COMPANY_KEY,
  FACT_ADDRESS_KEY,
  LEGAL_ADDRESS_KEY,
  MOBILE_PHONE_KEY,
  PARTNERSHIP_AGREEMENT_KEY,
  PARTNERSHIP_START_DATE_KEY,
  PRESIDENT_FULLNAME_KEY,
  RENT_AREA_INFO_KEY,
  STORES,
  WORK_PHONE_KEY,
} from '@esentai/core/features/merchants/consts/keys';
import { Button, Checkbox, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import LinkAsButton from '@/components/Button';
import ButtonSet from '@/components/ButtonSet';
import DatePicker from '@/components/DatePicker';
import FieldRow from '@/components/FieldRow';
import { StoreMultiAutocomplete } from '@/containers/StoreAutocomplete';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';

import InputListField from './InputListField';

const fieldNames = {
  [COMPANY_KEY]: 'название компании',
  [BIN_KEY]: 'БИН/ИИН',
  [PARTNERSHIP_AGREEMENT_KEY]: 'Договор партнерства',
  [PARTNERSHIP_START_DATE_KEY]: 'Дата договора',
  [MOBILE_PHONE_KEY]: 'Сотовый телефон',
  [WORK_PHONE_KEY]: 'Рабочий телефон',
  [LEGAL_ADDRESS_KEY]: 'Юридический адрес',
  [FACT_ADDRESS_KEY]: 'Фактический адрес',
  [PRESIDENT_FULLNAME_KEY]: 'ЛПР/Директор',
  [RENT_AREA_INFO_KEY]: 'Информация об арендуемой площади: кв. м.',
  comment: 'комментарий',
};

export default class EditMerchantForm extends Component {
  static propTypes = {
    Field: PropTypes.func.isRequired,
    Form: PropTypes.func.isRequired,
    RequiredMessages: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    SubmitButton: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(Error),
    isLoading: PropTypes.bool,
    merchantStatus: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      merchantStatus: props.merchantStatus === 'active',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.setFieldValue('merchantStatus', this.state.merchantStatus);
    }, 1000);
  }

  static getDerivedStateFromProps(props) {
    if (
      props &&
      props.values.partnership_start_date &&
      props.values.partnership_start_date.getTime() === new Date(null).getTime()
    ) {
      props.setFieldValue(PARTNERSHIP_START_DATE_KEY, null);
    }

    return null;
  }

  onChangeStore = storeId => this.props.setFieldValue(STORES, storeId);

  onValidationError = errors => {
    if (Array.isArray(errors)) {
      this.props.setFieldError(COMPANY_KEY, errors.join(', '));
    }
  };

  renderInput = ({
    preUrl,
    key,
    handlerRemoveItem,
    helperText,
    inputLabel,
  }) => {
    const { Field, setFieldValue } = this.props;

    return (
      <InputListField
        Field={Field}
        name={key}
        preUrl={preUrl}
        setFieldValue={setFieldValue}
        handlerRemoveItem={handlerRemoveItem}
        helperText={helperText}
        label={inputLabel}
      />
    );
  };

  render() {
    const {
      Form,
      Field,
      RequiredMessages,
      SubmitButton,
      classes,
      error,
      isLoading,
      setFieldValue,
      values,
      errors,
    } = this.props;

    const { merchantStatus } = this.state;

    return (
      <Form>
        <FieldRow label="Название компании">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={COMPANY_KEY}
            required
            rows={4}
            label="Название компании"
            helperText="Данное описание будет отображаться на странице арендатора"
          />
        </FieldRow>
        <FieldRow label="БИН/ИИН">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={BIN_KEY}
            rows={4}
            label="БИН/ИИН"
            disabled
          />
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name={PARTNERSHIP_AGREEMENT_KEY}
            rows={4}
            label="Договор партнерства"
          />
          <div className={classes.datepicker}>
            <Field
              margin="normal"
              Target={DatePicker}
              clearable
              required
              label="Дата договора"
              name={PARTNERSHIP_START_DATE_KEY}
              className={classes.wrap}
            />
          </div>
        </FieldRow>
        <FieldRow label="Телефон">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={MOBILE_PHONE_KEY}
            rows={4}
            label="Cотовый"
          />
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={WORK_PHONE_KEY}
            rows={4}
            label="Рабочий"
          />
        </FieldRow>
        <FieldRow label="ЛПР/Директор">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={PRESIDENT_FULLNAME_KEY}
            rows={4}
            label="Лицо принимающее решение/Директор"
          />
        </FieldRow>
        <FieldRow label="Информация об арендуемой площади">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={RENT_AREA_INFO_KEY}
            rows={4}
            label="Информация об арендуемой площади: кв. м."
          />
        </FieldRow>
        <FieldRow label="Адрес">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={LEGAL_ADDRESS_KEY}
            rows={4}
            label="Юридический"
          />
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={FACT_ADDRESS_KEY}
            rows={4}
            label="Фактический"
          />
        </FieldRow>

        <FieldRow
          label="Магазин"
          subLabel="Магазины аредатора"
          sudDesc="Выберите магазины арендатора."
          className={classes.hidden}
        >
          <StoreMultiAutocomplete
            id={STORES}
            className={classes.wrap}
            name={STORES}
            onChange={this.onChangeStore}
            selectedItem={values[STORES]}
            InputProps={{
              placeholder: 'Выберите магазин',
              error: errors[STORES],
            }}
          />
        </FieldRow>

        <FieldRow label="Активен">
          <Field
            margin="normal"
            Target={Checkbox}
            className={classes.checkbox}
            checked={merchantStatus}
            name="merchantStatus"
            rows={4}
            label="Активен"
            onChange={() => {
              setFieldValue('merchantStatus', !merchantStatus);
              this.setState({ merchantStatus: !merchantStatus });
            }}
          />
        </FieldRow>

        <FieldRow label="Комментарий">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name="comment"
            multiline
            rows={2}
            rowsMax={4}
            label="Комментарий"
          />
        </FieldRow>

        <FieldRow>
          <ButtonSet>
            <RequiredMessages fieldNames={fieldNames} />
            <LinkAsButton size="large" href={MERCHANTS_PATH}>
              Назад
            </LinkAsButton>
            <SubmitButton
              Target={AsyncButton}
              ButtonComponent={Button}
              error={error}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
            >
              Сохранить
            </SubmitButton>
          </ButtonSet>
        </FieldRow>
      </Form>
    );
  }
}
