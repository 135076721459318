import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import api from '@/api';
import AddLink from '@/components/AddLink';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { ROUTE_PATH as CATEGORY_PATH } from '@/features/productsCategoryPage/consts';

import CategoryModal from './CategoryModal/component';
import Table from './Table';

class ProductsViewPage extends Component {
  state = {
    isOpen: false,
    categories: null,
  };

  static propTypes = {
    error: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    itemsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    totalCount: PropTypes.number.isRequired,
    createView: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  loadCategoryData = async () => {
    try {
      await api
        .doGet(`/product-category/${this.props.match.params.categoryId}`)
        .then(res =>
          this.setState({
            categories: res.product_category,
          }),
        );
    } catch (e) {
      throw e;
    }
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isOpen: true,
    });
  };

  componentDidMount() {
    this.loadCategoryData();
  }

  render() {
    const {
      error,
      isLoading,
      itemsIds,
      totalCount,
      classes,
      createView,
    } = this.props;

    const { isOpen, categories } = this.state;

    const currentCategory = categories
      ? categories.find(
          c => c.id === Number(this.props.match.params.categoryId),
        )
      : null;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={CATEGORY_PATH}>Категоризация товаров</Breadcrumb>
          </Breadcrumbs>
          <Grid container alignItems="baseline" justify="space-between">
            <PageTitle style={{ maxWidth: '90%' }}>
              {categories && currentCategory
                ? `Виды для категории "${currentCategory.name_ru}"`
                : `Виды для категории ...`}
            </PageTitle>
            <AddLink onClick={this.handleOpenModal} to={null} />
          </Grid>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Table
              error={error}
              isLoading={isLoading}
              items={itemsIds}
              totalCount={totalCount}
            />
          </div>
          <CategoryModal
            isOpen={isOpen}
            onClose={this.handleCloseModal}
            onSubmit={createView}
            initialValues={null}
          />
        </PageContent>
      </Page>
    );
  }
}

export default ProductsViewPage;
