import { MAIN_FEED_LAYOUT_KEY } from '@esentai/core/features/applicationSettings/consts/keys';
import { PAYLOAD_KEY } from '@esentai/core/features/applicationSettings/homeLayout/consts/blockKeys';
import {
  ARTICLES_BLOCK,
  BUTTON_BLOCK,
  CALENDAR_BLOCK,
} from '@esentai/core/features/applicationSettings/homeLayout/consts/blocks';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getBlockType } from '@/features/feedLayoutEditPage/structs/block';

import Articles from './Articles';
import Button from './Button';
import CalendarEvents from './CalendarEvents';

const components = {
  [ARTICLES_BLOCK]: Articles,
  [BUTTON_BLOCK]: Button,
  [CALENDAR_BLOCK]: CalendarEvents,
};

class BlockContent extends Component {
  static propTypes = {
    block: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    Field: PropTypes.func.isRequired,
  };

  getPayloadFieldFullPath = field => {
    const { index } = this.props;

    return `${MAIN_FEED_LAYOUT_KEY}[${index}].${PAYLOAD_KEY}.${field}`;
  };

  setFieldValue = (field, value) => {
    const { setFieldValue } = this.props;
    const fullField = this.getPayloadFieldFullPath(field);

    setFieldValue(fullField, value);
  };

  render() {
    const { block, ...rest } = this.props;
    const type = getBlockType(block);
    const Content = components[type];

    if (Content) {
      return (
        <Content
          {...rest}
          block={block}
          setFieldValue={this.setFieldValue}
          Field={this.renderPayloadField}
        />
      );
    }

    return null;
  }

  renderPayloadField = ({ name, ...rest }) => {
    const { Field } = this.props;
    const fullName = this.getPayloadFieldFullPath(name);

    return <Field {...rest} name={fullName} />;
  };
}

export default BlockContent;
