import {
  getCategoryForProduct,
  getPriceForProduct,
  getSkuForProduct,
  getStoreIdProduct,
  getSubtitleRuForProduct,
  getTitleRuForProduct,
  getViewForProduct,
} from '@esentai/core/features/products/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { createProductPageUrl } from '@/features/productPage/utils';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: productId }) => ({
  store: getStoreIdProduct(state, productId),
  titleRu: getTitleRuForProduct(state, productId),
  subtitleRu: getSubtitleRuForProduct(state, productId),
  sku: getSkuForProduct(state, productId),
  price: getPriceForProduct(state, productId),
  category: getCategoryForProduct(state, productId),
  productId,
  productPageUrl: createProductPageUrl(productId),
  views: getViewForProduct(state, productId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
