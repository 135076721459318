import { getBenefitItemUsedDate } from '@esentai/core/features/benefitItems/selectors';
import { getNameForStore } from '@esentai/core/features/stores/selectors';
import { pipe } from 'ramda';

import { getRole, getUserStores } from '@/features/currentUser/selectors';
import { userRole } from '@/utils/labels';

export const getUserRole = pipe(getRole, userRole);

export const getStoreNames = state => {
  const ids = getUserStores(state);

  return ids.map(id => getNameForStore(state, id));
};

export const getGrantedStatus = pipe(getBenefitItemUsedDate, Boolean);
