import { Radio } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class SelectionControl extends Component {
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    itemId: PropTypes.number.isRequired,
  };

  onSelect = () => {
    const { itemId, onSelect } = this.props;

    onSelect(itemId);
  };

  render() {
    return <Radio onChange={this.onSelect} />;
  }
}

export default SelectionControl;
