import { T } from 'ramda';
import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { createEditGiftSetLink } from '@/features/giftSetEditPage/serializer';

const mapStateToProps = (state, { giftSetId }) => ({
  editPageUrl: createEditGiftSetLink(giftSetId),
  permission: T,
});

export default connect(mapStateToProps)(EditButton);
