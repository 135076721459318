import {
  ORDER_IS_NOT_PAID_BY_APP,
  ORDER_PAID_BY_APP,
  paymentTypes,
} from '@/features/valetParkingPage/consts/keys';
import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [ORDER_PAID_BY_APP, ORDER_IS_NOT_PAID_BY_APP].map(
  key => ({
    key,
    label: paymentTypes(key),
  }),
);

export const createParkingOrderPaymentFilter = createRadioFilterTemplate({
  options,
});

export default RadioGroupFilter;
