import { findLocationById } from '@esentai/core/features/locations/actions';
import {
  getLocationLoadingError,
  isLocationLoaded,
  isLocationLoading,
} from '@esentai/core/features/locations/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import {
  fetchLocation as fetchItem,
  updateLocation,
} from '@/features/locationEditPage/actions';
import { getRouterParameterByMatch } from '@/redux/router/selectors';

import component from './component';

const ID_KEY = 'locationId';

const mapStateToProps = (state, { match }) => {
  const locationId = getRouterParameterByMatch(match, ID_KEY);

  return {
    error: getLocationLoadingError(state, locationId),
    isLoaded: isLocationLoaded(state, locationId),
    isLoading: isLocationLoading(state, locationId),
    locationId,
  };
};

const mapDispatchToProps = (dispatch, { match }) => ({
  updateLocation: values =>
    dispatch(
      updateLocation({
        ...values,
        locationId: getRouterParameterByMatch(match, ID_KEY),
      }),
    ),
  fetchItem,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    fetchItem: findLocationById,
    getItemLoadingError: getLocationLoadingError,
    isItemLoaded: isLocationLoaded,
    isItemLoading: isLocationLoading,
  }),
)(component);
