import { TAGS_KEY } from '@esentai/core/features/campaigns/calendarEvent/consts/keys';
import { Typography } from '@material-ui/core';
import { without } from 'ramda';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';
import AutocompleteInput from './AutocompleteInput';

class TagsForm extends Component {
  static propTypes = FormikPropTypes;

  handleChange = tags => {
    const { setFieldValue } = this.props;

    setFieldValue(TAGS_KEY, tags);
  };

  handleDelete = tag => {
    const { values } = this.props;
    const tags = without([tag], values[TAGS_KEY]);

    this.handleChange(tags);
  };

  render() {
    const { values, classes } = this.props;
    const tags = values[TAGS_KEY];

    return (
      <FieldRow
        label="Свойства"
        subLabel="Тематические теги"
        sudDesc="Все календарные события должны иметь привязку к какой-либо тематике. Вы можете использовать один или несколько тегов из уже существующих, либо добавить свои теги."
      >
        <Typography className={classes.label} gutterBottom>
          Добавить к календарному событию тематические теги:
        </Typography>

        <div className={classes.tags}>
          <CanEdit field={TAGS_KEY}>
            <AutocompleteInput
              onChange={this.handleChange}
              selectedItem={tags}
            />
          </CanEdit>
        </div>
      </FieldRow>
    );
  }
}

export default TagsForm;
