export default {
  root: {
    display: 'inline-block',
    padding: [4, 7],
    borderRadius: 2,
    border: 'solid 1px #ededed',
    margin: 2,
  },

  label: {
    color: '#1e88e5',
    fontSize: 13,
    lineHeight: 1,
  },
};
