import PropTypes from 'prop-types';
import React from 'react';
import { defaultProps } from 'recompose';

import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';

import FormCreate from './Form';

const Form = defaultProps({
  chineseDescription: '',
  categories: [],
  englishDescription: '',
  imageUrl: '',
  kazakhDescription: '',
  name: '',
  russianDescription: '',
  storesIds: [],
  submitButtonText: 'Создать бренд',
})(FormCreate);

const NewBrand = ({ createBrand }) => (
  <SettingsPage>
    <SettingsPageHeader>
      <SettingsPageTitle>Создать бренд</SettingsPageTitle>
    </SettingsPageHeader>
    <SettingsPageContent>
      <Form onSubmit={createBrand} />
    </SettingsPageContent>
  </SettingsPage>
);

NewBrand.propTypes = {
  createBrand: PropTypes.func.isRequired,
};

export default NewBrand;
