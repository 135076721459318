import {
  ENGLISH_TITLE_KEY,
  RUSSIAN_TITLE_KEY,
  SHOW_POINTER_KEY,
} from '@esentai/core/features/campaigns/button/consts/keys';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import MainPageTopBar from '@/components/DevicePreview/MainPageTopBar';
import { LOCALE_EN, LOCALE_RU } from '@/hocs/withDevicePreview/consts';

import ArrowIcon from './icon.svg';

const Preview = ({ classes, selectByLocale, values }) => {
  const title = selectByLocale({
    [LOCALE_EN]: values[ENGLISH_TITLE_KEY],
    [LOCALE_RU]: values[RUSSIAN_TITLE_KEY],
  });
  const showPointer = values[SHOW_POINTER_KEY];

  return (
    <Fragment>
      <MainPageTopBar />
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        {showPointer && <ArrowIcon className={classes.pointer} />}
      </div>
    </Fragment>
  );
};

Preview.propTypes = {
  selectByLocale: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default Preview;
