import PropTypes from 'prop-types';

const formik = {
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default formik;

export const FormikWithValidationPropTypes = {
  ...formik,
  requiredErrors: PropTypes.object.isRequired,
  hasRequiredErrors: PropTypes.bool.isRequired,
};
