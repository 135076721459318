import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import React, { Component, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

class MenuItemButton extends Component {
  render() {
    const { onClick, link, children, classes } = this.props;

    if (!link) {
      return (
        <ListItem button onClick={onClick}>
          {children}
        </ListItem>
      );
    }

    /* eslint-disable-next-line react/display-name */
    const forward = forwardRef((props, ref) => (
      <NavLink {...props} innerRef={ref} />
    ));

    return (
      <ListItem button className={classes.item} component={forward} to={link}>
        {children}
      </ListItem>
    );
  }
}

MenuItemButton.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
};

MenuItemButton.defaultProps = {
  link: '',
  onClick: () => {},
};

export default MenuItemButton;
