export default theme => ({
  dimmed: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  tbody: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
  thead: {
    '& > tr > th': {
      borderBottomColor: '#eee',
    },
  },
});
