import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import {
  activate,
  moveToModeration,
} from '@esentai/core/features/campaigns/actions';
import { TYPE_KEY } from '@esentai/core/features/campaigns/consts/keys';
import { SHOW_TYPE } from '@esentai/core/features/campaigns/consts/types';
import {
  activateCampaign,
  createCampaign,
  fetchCampaign,
  moveCampaignToModeration,
  removeCampaign,
  updateCampaign,
} from '@esentai/core/features/campaigns/sagas';
import { push } from 'connected-react-router';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { ROUTE_PATH as CAMPAIGNS_PAGE } from '@/features/campaignsPage/consts';
import { canModerateCampaign } from '@/features/campaignsPage/permissions';
import { createCampaignsPageURL } from '@/features/campaignsPage/utils';

import { SHOULD_SUBMIT_AFTER_SAVE_KEY } from './consts/keys';
import {
  getCampaignId,
  getCampaignType,
  getEditableAttributes,
} from './selectors';
import {
  CREATE_DRAFT,
  DELETE,
  FETCH_ITEM,
  GO_TO_STEP_ONE,
  GO_TO_STEP_THREE,
  GO_TO_STEP_TWO,
  SUBMIT,
} from './types';
import { createFormStepURL } from './utils';

export function* goToStep(step) {
  const type = yield select(getCampaignType);
  const id = yield select(getCampaignId);
  const url = yield call(createFormStepURL, type, id, step);

  yield put(push(url));
}

export function* updateOrMigrateCampaign(type, id, attributes) {
  const existingType = yield select(getCampaignType);
  const editableAttributes = yield select(getEditableAttributes, attributes);

  if (type !== existingType) {
    throw Error('Migrating a campaign to another type is not supported yet');
  }

  yield call(updateCampaign, type, id, editableAttributes);
}

export function* createDraft(action) {
  const { payload } = action;
  const { [TYPE_KEY]: type, ...attributes } = payload;

  if (type === SHOW_TYPE) {
    attributes.push_enabled = false;
  }

  const { id } = yield call(createCampaign, type, attributes);
  const url = yield call(createFormStepURL, type, id, 2);

  yield put(push(url));
}

export function* fetchItem(action) {
  const { payload: id } = action;
  const type = yield select(getCampaignType);

  yield call(fetchCampaign, type, id);
}

export function* goToStepOne() {
  yield call(goToStep, 1);
}

export function* goToStepTwo(action) {
  const { payload } = action;
  const { [TYPE_KEY]: type, ...attributes } = payload;
  const id = yield select(getCampaignId);

  yield call(updateOrMigrateCampaign, type, id, attributes);
  yield call(goToStep, 2);
}

export function* goToStepThree(action) {
  const { payload: attributes } = action;
  const id = yield select(getCampaignId);
  const type = yield select(getCampaignType);

  yield call(updateOrMigrateCampaign, type, id, attributes);
  yield call(goToStep, 3);
}

export function* deleteCampaign() {
  const id = yield select(getCampaignId);
  const type = yield select(getCampaignType);

  yield call(removeCampaign, type, id);
  yield put(push(CAMPAIGNS_PAGE));
}

export function* setSubmitCampaignStatus(id) {
  const canModerate = yield select(canModerateCampaign, id);

  if (canModerate) {
    const activateAction = activate(id);

    yield call(activateCampaign, activateAction);
  } else {
    const moveToModerationAction = moveToModeration(id);

    yield call(moveCampaignToModeration, moveToModerationAction);
  }
}

export function* submitCampaign(action) {
  const { payload: attributes } = action;
  const {
    [SHOULD_SUBMIT_AFTER_SAVE_KEY]: shouldSubmitAfterSave,
    ...restAttributes
  } = attributes;
  const id = yield select(getCampaignId);
  const type = yield select(getCampaignType);

  yield call(updateOrMigrateCampaign, type, id, restAttributes);

  if (shouldSubmitAfterSave) {
    yield call(setSubmitCampaignStatus, id);
  }

  const campaignsPageURL = yield call(createCampaignsPageURL);

  yield put(push(campaignsPageURL));
}

export default function*() {
  yield takeEvery(CREATE_DRAFT, createTaskSaga(createDraft));
  yield takeEvery(FETCH_ITEM, createTaskSaga(fetchItem));
  yield takeEvery(GO_TO_STEP_ONE, createTaskSaga(goToStepOne));
  yield takeEvery(GO_TO_STEP_TWO, createTaskSaga(goToStepTwo));
  yield takeEvery(GO_TO_STEP_THREE, createTaskSaga(goToStepThree));
  yield takeEvery(SUBMIT, createTaskSaga(submitCampaign));
  yield takeEvery(DELETE, createTaskSaga(deleteCampaign));
}
