import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepTwo } from '@/features/storeBonusCreatePage/actions';
import { getStoreBonusId } from '@/features/storeBonusCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import FirstStep from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getStoreBonusId(state);
  const cached = `store-bonus-${id}`;
  const payload = JSON.parse(localStorage.getItem(cached) || '{}');

  return {
    comment: payload.comment,
    startDate: payload.start_datetime,
    endDate: payload.end_datetime,
    percent: payload.percent,
    store: payload.store,
    cashMachines: payload.cash_machines,
    repeatSendDatetimes: payload.scheduled_reconciliations_dates,
  };
};

const mapDispatchToProps = { onSubmit: goToStepTwo };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(FirstStep);
