import {
  FIRST_NAME_KEY,
  GENDER_KEY,
  GROUP_ID_KEY,
  ID_KEY,
  LAST_NAME_KEY,
  LEVEL_KEY,
  PHONE_KEY,
  RECORD_CREATED_KEY,
} from '@esentai/core/features/parkingWhitelist/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createGenderFilter } from '@/filters/Gender';
import { createTextFilter } from '@/filters/SearchInput';
import { createIncludeFilter } from '@/filters/SearchInputs';
import { createUserStatusFilter } from '@/filters/UserStatus';
import { createWhitelistGroupFilter } from '@/filters/WhitelistGroupSearchInputs';

export const filterTypes = [
  createIncludeFilter({
    key: ID_KEY,
    label: 'ID',
  }),
  createTextFilter({
    key: FIRST_NAME_KEY,
    label: 'Имя',
  }),
  createTextFilter({
    key: LAST_NAME_KEY,
    label: 'Фамилия',
  }),
  createTextFilter({
    key: PHONE_KEY,
    label: 'Номер телефона',
  }),
  createGenderFilter({
    key: GENDER_KEY,
    label: 'Пол пользователя',
  }),
  // createTextFilter({
  //   key: EMAIL_KEY,
  //   label: 'Почта',
  // }),
  // createUserAgeFilter({
  //   key: AGE_KEY,
  //   label: 'Возраст',
  // }),
  createDateRangeFilter({
    key: RECORD_CREATED_KEY,
    label: 'Дата добавления',
  }),
  // createUserActivityStateFilter({
  //   key: STATE_KEY,
  //   label: 'Активность пользователя',
  //   quicklyAccessible: true,
  // }),
  createUserStatusFilter({
    key: LEVEL_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),
  createWhitelistGroupFilter({
    key: GROUP_ID_KEY,
    label: 'Группа',
  }),
];
