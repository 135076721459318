export default ({ spacing }) => ({
  datepickers: {
    display: 'flex',
    margin: [16, 0, 8],
    alignItems: 'center',
  },

  datepicker: {
    flex: 'none',
    width: 310,
    marginRight: 30,
  },

  titleField: {
    width: 651,
  },

  cancelButton: {
    marginRight: spacing.unit * 2,
  },
});
