import { findProductViewById } from '@esentai/core/features/productView/actions';
import {
  getRussianName,
  isProductViewLoaded,
  isProductViewLoading,
} from '@esentai/core/features/productView/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import ProductViewNameLazy from './component';

const mapStateToProps = (state, { productViewId, onCutText, maxLength }) => ({
  isLoaded: isProductViewLoaded(state, productViewId),
  isLoading: isProductViewLoading(state, productViewId),
  title: getRussianName(state, productViewId),
  itemId: productViewId,
  onCutText,
  maxLength,
});

const mapDispatchToProps = { fetchItem: findProductViewById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(ProductViewNameLazy);
