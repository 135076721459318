import { RESOURCE_NAME } from '@esentai/core/features/bonuses/consts';
import {
  FULLNAME_KEY,
  ROLE_KEY,
} from '@esentai/core/features/bonuses/consts/keys';
import {
  RECEIPT_BONUSES,
  RECEIPT_BONUSES_CANCELLATION,
} from '@esentai/core/features/bonuses/consts/statuses';
import serializer from '@esentai/core/features/bonuses/serializer';
import { MALL_MANAGER_ROLE } from '@esentai/core/features/users/consts/roles';
import { mergeQueriesRight } from '@esentai/core/queries';
import {
  anyOf,
  equals,
  hasFilter,
  query as makeQuery,
  where,
} from '@esentai/core/query-dsl';
import VirtualResource from '@esentai/core/resources/virtual';

export class BonusesResource extends VirtualResource {
  query(query) {
    const typeQuery = mergeQueriesRight(
      query,
      makeQuery(
        where('type', anyOf([RECEIPT_BONUSES, RECEIPT_BONUSES_CANCELLATION])),
      ),
    );

    if (hasFilter(FULLNAME_KEY, typeQuery)) {
      const roleQuery = mergeQueriesRight(
        typeQuery,
        makeQuery(where(ROLE_KEY, equals(MALL_MANAGER_ROLE))),
      );

      return super.query(roleQuery);
    }

    return super.query(typeQuery);
  }
}

export default new BonusesResource({
  aliasResourceName: 'bonuses',
  resourceName: RESOURCE_NAME,
  endpoint: '/customer-activity-history',
  serializer,
});
