import { FirstPage, LastPage } from '@material-ui/icons';
import { isAfter, isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import ShowcaseTooltip from '@/components/ShowcaseTooltip';

const DateComparer = ({
  dateStart,
  dateEnd,
  campaignDateStart,
  campaignDateEnd,
}) => {
  const startsAfter = useMemo(() => isAfter(dateStart, campaignDateStart), [
    dateStart,
    campaignDateStart,
  ]);
  const endsBefore = useMemo(() => isBefore(dateEnd, campaignDateEnd), [
    dateEnd,
    campaignDateEnd,
  ]);

  if (startsAfter && !endsBefore) {
    return (
      <ShowcaseTooltip title="Данная Онлайн-витрина начинается позже">
        <LastPage color="error" />
      </ShowcaseTooltip>
    );
  }

  if (endsBefore && !startsAfter) {
    return (
      <ShowcaseTooltip title="Данная Онлайн-витрина заканчивается раньше">
        <FirstPage color="error" />
      </ShowcaseTooltip>
    );
  }

  if (startsAfter && endsBefore) {
    return (
      <ShowcaseTooltip title="Данная Онлайн-витрина начинается позже и заканчивается раньше">
        <FirstPage color="error" />
        <LastPage color="error" />
      </ShowcaseTooltip>
    );
  }

  return null;
};

DateComparer.propTypes = {
  dateStart: PropTypes.instanceOf(Date).isRequired,
  dateEnd: PropTypes.instanceOf(Date).isRequired,
  campaignDateStart: PropTypes.instanceOf(Date).isRequired,
  campaignDateEnd: PropTypes.instanceOf(Date).isRequired,
};

export default DateComparer;
