import PropTypes from 'prop-types';
import React from 'react';

import FieldDescription from '@/components/FieldDescription';
import FormSection from '@/components/FormSection';

const FieldRow = ({
  children,
  classes,
  label,
  subLabel,
  sudDesc,
  ...props
}) => (
  <FormSection classes={classes} name={label} {...props}>
    <FieldDescription title={subLabel} desc={sudDesc} />
    {children}
  </FormSection>
);

FieldRow.defaultProps = {
  label: '',
  subLabel: '',
  sudDesc: '',
};

FieldRow.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  sudDesc: PropTypes.string,
};

export default FieldRow;
