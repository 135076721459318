export const CUSTOMER_AGE_KEY = 'customer_age';

export const CUSTOMER_BIRTH_DATE_KEY = 'customer_born_at';

export const CUSTOMER_GENDER_KEY = 'customer_gender';

export const CUSTOMER_STATUS_KEY = 'customer_status';

export const DATE_RANGE_KEY = 'ts';

export const LOCATION_ID_KEY = 'location_id';

export const CUSTOMER_AVERAGE_CHECK_KEY = 'customer_average_ticket';
