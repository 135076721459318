import { readEquals } from '@esentai/core/query-dsl';
import { __, divide, innerJoin, path, pipe, prop } from 'ramda';

import { CAMPAIGN_TYPE_KEY } from '@/features/campaignsStatistics/consts/dimensions';
import {
  ACTIVATIONS_COUNT_KEY,
  CLICKS_COUNT_KEY,
  CLICKS_PERCENTAGE_BY_BLACK_LEVELED_USERS_KEY,
  CLICKS_PERCENTAGE_BY_JETBLACK_LEVELED_USERS_KEY,
  CLICKS_PERCENTAGE_BY_ORANGE_LEVELED_USERS_KEY,
  CLICKS_PERCENTAGE_BY_SILVER_LEVELED_USERS_KEY,
  CLICKS_PERCENTAGE_BY_WHITE_LEVELED_USERS_KEY,
  CONVERSION_KEY,
  COVERAGE_KEY,
  EFFICIENCY_KEY,
  PRE_PUSH_CLICKS_COUNT_KEY,
  PUSH_CLICKS_COUNT_KEY,
  SCROLLS_COUNT_TO_25_PERCENT_KEY,
  SCROLLS_COUNT_TO_50_PERCENT_KEY,
  SCROLLS_COUNT_TO_75_PERCENT_KEY,
  SCROLLS_COUNT_TO_100_PERCENT_KEY,
  SEGMENT_KEY,
  VIEWS_COUNT_KEY,
} from '@/features/campaignsStatistics/consts/keys';
import { percentage } from '@/features/campaignsStatistics/utils';
import { getFiltersIdsByCampaignType } from '@/features/campaignsStatisticsPage/utils';
import {
  META_TIME_SCALE_KEY,
  META_TOTAL_HEADER,
  TIMEFRAME_KEY,
} from '@/features/statistics/consts/keys';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { filterTypes } from './filters';

export const {
  getError,
  isLoadingItems,
  getItemsMeta,
  getItemsTotalCount,
} = itemsDuck.selectors;

export const {
  getFilters,
  getPage,
  getLimit,
  getQuery,
  getSort,
} = queryDuck.selectors;

export const getCampaignType = state => {
  const filters = getFilters(state);
  const campaignType = readEquals(filters.get(CAMPAIGN_TYPE_KEY));

  return campaignType;
};

export const getScale = pipe(getItemsMeta, path([META_TIME_SCALE_KEY]));

export const isItemsLoaded = pipe(getItemsTotalCount, Boolean);

export const getPropFromMeta = itemProp =>
  pipe(getItemsMeta, prop(META_TOTAL_HEADER), prop(itemProp));

export const getAvailableFiltersIds = state => {
  const campaignType = getCampaignType(state);

  return getFiltersIdsByCampaignType(campaignType);
};

export const getFiltersTypes = state => {
  const filtersIds = getAvailableFiltersIds(state);

  return innerJoin(
    (filter, filterId) => filter.key === filterId,
    filterTypes,
    filtersIds,
  );
};

export const getActivationsCount = getPropFromMeta(ACTIVATIONS_COUNT_KEY);

export const getClicksCount = getPropFromMeta(CLICKS_COUNT_KEY);

export const getClicksPercentageByBlackLeveledUsers = getPropFromMeta(
  CLICKS_PERCENTAGE_BY_BLACK_LEVELED_USERS_KEY,
);

export const getClicksPercentageByOrangeLeveledUsers = getPropFromMeta(
  CLICKS_PERCENTAGE_BY_ORANGE_LEVELED_USERS_KEY,
);

export const getClicksPercentageBySilverLeveledUsers = getPropFromMeta(
  CLICKS_PERCENTAGE_BY_SILVER_LEVELED_USERS_KEY,
);

export const getClicksPercentageByWhiteLeveledUsers = getPropFromMeta(
  CLICKS_PERCENTAGE_BY_WHITE_LEVELED_USERS_KEY,
);

export const getClicksPercentageByJetBlackLeveledUsers = getPropFromMeta(
  CLICKS_PERCENTAGE_BY_JETBLACK_LEVELED_USERS_KEY,
);

export const getConversionRate = pipe(
  getPropFromMeta(CONVERSION_KEY),
  divide(__, 100),
);

export const getCoverage = getPropFromMeta(COVERAGE_KEY);

export const getEffeciency = getPropFromMeta(EFFICIENCY_KEY);

export const getPrePushClicksCount = getPropFromMeta(PRE_PUSH_CLICKS_COUNT_KEY);

export const getPushClicksCount = getPropFromMeta(PUSH_CLICKS_COUNT_KEY);

export const getScrollsCountTo25Percent = getPropFromMeta(
  SCROLLS_COUNT_TO_25_PERCENT_KEY,
);

export const getScrollsCountTo50Percent = getPropFromMeta(
  SCROLLS_COUNT_TO_50_PERCENT_KEY,
);

export const getScrollsCountTo75Percent = getPropFromMeta(
  SCROLLS_COUNT_TO_75_PERCENT_KEY,
);

export const getScrollsCountTo100Percent = getPropFromMeta(
  SCROLLS_COUNT_TO_100_PERCENT_KEY,
);

export const getSegment = getPropFromMeta(SEGMENT_KEY);

export const getTimeframe = getPropFromMeta(TIMEFRAME_KEY);

export const getViewsCount = getPropFromMeta(VIEWS_COUNT_KEY);

export const getActivationsToPrePushClicksPercentage = percentage(
  getActivationsCount,
  getPrePushClicksCount,
);
export const getActivationsToSegmentPercentage = percentage(
  getActivationsCount,
  getSegment,
);

export const getClicksToCoveragePercentage = percentage(
  getClicksCount,
  getCoverage,
);

export const getPrePushClicksToSegmentPercentage = percentage(
  getPrePushClicksCount,
  getSegment,
);

export const getPushClicksToSegmentPercentage = percentage(
  getPushClicksCount,
  getSegment,
);

export const getPrePushClicksToActivationsPercentage = percentage(
  getPrePushClicksCount,
  getActivationsCount,
);

export const getPushClicksToActivationsPercentage = percentage(
  getPushClicksCount,
  getActivationsCount,
);

export const getViewsToSegmentPercentage = percentage(
  getViewsCount,
  getSegment,
);
