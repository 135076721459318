import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { create } from '@esentai/core/features/brands/sagas';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTE_PATH as BRANDS_PATH } from '@/features/brandsPage/consts';

import { CREATE_BRAND } from './types';

export function* createBrand(action) {
  const { payload: attributes } = action;

  yield call(create, attributes);
  yield put(push(BRANDS_PATH));
}

export default function*() {
  yield takeLatest(CREATE_BRAND, createTaskSaga(createBrand));
}
