import {
  BANNER_POPUP_DELAY_KEY,
  PLACEMENT_KEY,
  PRIORITY_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/banner/consts/keys';
import { MAIN_FEED } from '@esentai/core/features/campaigns/banner/consts/popupPlacementTypes';
import {
  MAIN_PAGE_TYPE,
  POPUP_TYPE,
} from '@esentai/core/features/campaigns/banner/consts/types';
import { TextField } from '@material-ui/core';
import React, { Component, Fragment } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit, CanEditRadioBoxGroup } from '../../CanEdit';
import PriorityControl from '../PriorityControl';

class PlacementForm extends Component {
  static propTypes = FormikPropTypes;

  renderPriorityControl() {
    const { handleChange, values } = this.props;

    return (
      <div>
        <FieldDescription
          title="Приоритет расположения"
          desc="Рекламное размещение с более высоким уровнем приоритета размещается первее в блоке баннеров. Размещения с одинаковым приоритетом размещаются друг за другом в хронологическом порядке."
        />
        {/* <CanEdit field={PRIORITY_KEY}> */}
        <PriorityControl
          name={PRIORITY_KEY}
          value={values[PRIORITY_KEY]}
          onChange={handleChange}
        />
        {/* </CanEdit> */}
      </div>
    );
  }

  renderPlacementControl() {
    const { handleChange, values, Field } = this.props;

    return (
      <FieldRow
        label="Точка показа"
        subLabel="Место и время активации"
        sudDesc="Выберите место в приложении, в котором будет появляться баннер и при каких условиях."
      >
        <CanEditRadioBoxGroup field={PLACEMENT_KEY}>
          <RadioBoxGroup
            name={PLACEMENT_KEY}
            value={values[PLACEMENT_KEY]}
            onChange={handleChange}
          >
            <RadioBox
              value={MAIN_FEED}
              label="Главная лента"
              helperText="Баннер будет отображаться при открытии основной ленты приложения."
            />
          </RadioBoxGroup>
        </CanEditRadioBoxGroup>
        <FieldDescription
          title="Задержка перед открытием баннера"
          desc="Вы можете установить задержку перед открытием баннера при переходе по указанному экрану приложения. Если задержка не установлена, баннер будет открываться сразу."
        />
        <CanEdit field={PLACEMENT_KEY}>
          <Field
            Target={TextField}
            name={BANNER_POPUP_DELAY_KEY}
            helperText="Продолжительность задержки в секундах."
            type="number"
          />
        </CanEdit>
      </FieldRow>
    );
  }

  render() {
    const { classes, handleChange, values } = this.props;

    return (
      <Fragment>
        <FieldRow label="Тип размещения" subLabel="Тип рекламного размещения">
          <CanEditRadioBoxGroup field={TYPE_KEY}>
            <RadioBoxGroup
              name={TYPE_KEY}
              value={values[TYPE_KEY]}
              onChange={handleChange}
              classes={{ radioBoxGroup: classes.typeSelect }}
            >
              <RadioBox
                value={MAIN_PAGE_TYPE}
                label="Главный баннер"
                helperText="Располагается на главном экране мобильного приложения."
              />
            </RadioBoxGroup>
          </CanEditRadioBoxGroup>
          {values[TYPE_KEY] !== POPUP_TYPE && this.renderPriorityControl()}
        </FieldRow>

        {values[TYPE_KEY] === POPUP_TYPE && this.renderPlacementControl()}
      </Fragment>
    );
  }
}

export default PlacementForm;
