import {
  ID_KEY,
  PHONE_NUMBER,
  RECORD_CREATED_KEY,
  USER_ID_KEY,
} from '@esentai/core/features/efsVisitRecords/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createEqualsFilter, createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createEqualsFilter({
    key: ID_KEY,
    label: 'ID',
  }),
  createEqualsFilter({
    key: USER_ID_KEY,
    label: 'ID пользователя',
  }),
  createTextFilter({
    key: PHONE_NUMBER,
    label: 'Телефон пользователя',
  }),
  createDateRangeFilter({
    key: RECORD_CREATED_KEY,
    label: 'Дата и время входа',
  }),
];
