import { createAction } from 'redux-actions';

import {
  UPLOAD_CANCEL,
  UPLOAD_FAILURE,
  UPLOAD_PROGRESS,
  UPLOAD_RETRY,
  UPLOAD_START,
  UPLOAD_SUCCESS,
} from './types';

export const uploadFile = createAction(UPLOAD_START);
export const retryUpload = createAction(UPLOAD_RETRY);
export const cancelUpload = fileId => ({ type: `${UPLOAD_CANCEL}/${fileId}` });
export const uploadSuccess = createAction(UPLOAD_SUCCESS);
export const uploadFailure = createAction(UPLOAD_FAILURE);
export const uploadProgress = createAction(UPLOAD_PROGRESS);
