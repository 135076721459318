import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { boolean, object, string } from 'yup';

import styles from '@/features/authPages/styles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import EditAndroidVersionForm from './component';

const errorsMapping = {};
const formikOptions = {
  mapPropsToValues: ({ currentAppVersion, forcedUpdate }) => ({
    version_android: currentAppVersion || '',
    version_android_forced_update: forcedUpdate,
  }),

  validationSchema: object().shape({
    version_android: string()
      .min(1, 'Версия приложения должна содержать менее 1 символа')
      .max(10, 'Версия приложения должна содержать не более 10 символов')
      .required(),
    version_android_forced_update: boolean().required(),
  }),
  // eslint-disable-next-line camelcase
  handleSubmit(
    { version_android, version_android_forced_update },
    { props, setSubmitting },
  ) {
    props.onSubmit({ version_android, version_android_forced_update });
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(EditAndroidVersionForm);
