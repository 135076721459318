import {
  BRANDS_IDS_KEY,
  CATEGORIES_KEY,
  DESCRIPTION_ENGLISH_KEY,
  DESCRIPTION_KAZAKH_KEY,
  DESCRIPTION_RUSSIAN_KEY,
  LOCATION_ID_KEY,
  LOGO_URL_KEY,
  LOYALTY_REP_KEY,
  MERCHANTS_IDS_KEY,
  NAME_ENGLISH_KEY,
  NAME_KAZAKH_KEY,
  NAME_KEY,
} from '@esentai/core/features/stores/consts/keys';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import LinkAsButton from '@/components/Button';
import ButtonSet from '@/components/ButtonSet';
import CategoriesInput from '@/components/CategoriesInput';
import FieldRow from '@/components/FieldRow';
import { BrandMultiAutocomplete } from '@/containers/BrandAutocomplete';
import { MerchantMultiAutocomplete } from '@/containers/MerchantAutocomplete';
import StoreImageFormControl from '@/containers/StoreImageFormControl';
import { ROUTE_PATH as STORES_PATH } from '@/features/storesPage/consts';

import InputListField from './InputListField';

const fieldNames = {
  [NAME_KEY]: 'название магазина',
  [NAME_ENGLISH_KEY]: 'название на английском',
  [NAME_KAZAKH_KEY]: 'название на казахском',

  [DESCRIPTION_RUSSIAN_KEY]: 'краткое описание на русском',
  [DESCRIPTION_ENGLISH_KEY]: 'краткое описание на английском',
  [DESCRIPTION_KAZAKH_KEY]: 'краткое описание на казахском',

  [CATEGORIES_KEY]: 'категория',
  [BRANDS_IDS_KEY]: 'бренды',
  [LOCATION_ID_KEY]: 'локация',
  [LOYALTY_REP_KEY]: 'партнер программы лояльности',
  comment: 'комментарий',
};

export default class EditStoreForm extends Component {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    Field: PropTypes.func.isRequired,
    Form: PropTypes.func.isRequired,
    RequiredMessages: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    SubmitButton: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(Error),
    isLoading: PropTypes.bool,
    // socialKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    values: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    error: null,
  };

  onChangeCategories = category =>
    this.props.setFieldValue(CATEGORIES_KEY, category);

  onChangeBrand = brandId => this.props.setFieldValue(BRANDS_IDS_KEY, brandId);
  onChangeMerchant = merchantId =>
    this.props.setFieldValue(MERCHANTS_IDS_KEY, merchantId);

  onChangeLocation = locationId =>
    this.props.setFieldValue(LOCATION_ID_KEY, locationId);

  setLoyaltyRep = event => {
    const { checked } = event.target;

    this.props.setFieldValue(LOYALTY_REP_KEY, checked);
  };

  onValidationError = errors => {
    if (Array.isArray(errors)) {
      this.props.setFieldError(LOGO_URL_KEY, errors.join(', '));
    }
  };

  handleChangeImage = ([url]) => {
    const { setFieldValue } = this.props;

    setFieldValue(LOGO_URL_KEY, url);
  };

  renderInput = ({
    preUrl,
    key,
    handlerRemoveItem,
    helperText,
    inputLabel,
  }) => {
    const { Field, setFieldValue } = this.props;

    return (
      <InputListField
        Field={Field}
        name={key}
        preUrl={preUrl}
        setFieldValue={setFieldValue}
        handlerRemoveItem={handlerRemoveItem}
        helperText={helperText}
        label={inputLabel}
      />
    );
  };

  render() {
    const {
      Form,
      Field,
      RequiredMessages,
      SubmitButton,
      classes,
      error,
      errors,
      isLoading,
      values,
    } = this.props;

    return (
      <Form>
        <FieldRow label="Название магазина">
          <Field
            id={NAME_KEY}
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={NAME_KEY}
            label="Название магазина"
          />
          <Field
            id={NAME_ENGLISH_KEY}
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={NAME_ENGLISH_KEY}
            label="Название магазина на английском"
          />
          <Field
            id={NAME_KAZAKH_KEY}
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={NAME_KAZAKH_KEY}
            label="Название магазина на казахском"
          />
        </FieldRow>

        <FieldRow label="Описание магазина">
          <Field
            multiline
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={DESCRIPTION_RUSSIAN_KEY}
            rows={4}
            label="Краткое описание магазина на русском"
            helperText="Данное описание будет отображаться на странице магазина в Mall Guide"
          />
          <Field
            multiline
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={DESCRIPTION_ENGLISH_KEY}
            rows={4}
            label="Краткое описание магазина на английском"
            helperText="Данное описание будет отображаться на странице магазина в Mall Guide"
          />
          <Field
            multiline
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={DESCRIPTION_KAZAKH_KEY}
            rows={4}
            label="Краткое описание магазина на казахском"
            helperText="Данное описание будет отображаться на странице магазина в Mall Guide"
          />

          <StoreImageFormControl
            value={values[LOGO_URL_KEY]}
            onChange={this.handleChangeImage}
            margin="normal"
            error={Boolean(errors[LOGO_URL_KEY])}
            className={classes.imageControl}
            onValidationError={this.onValidationError}
            storeImageError={errors[LOGO_URL_KEY]}
          />
        </FieldRow>
        <FieldRow
          label="Категория"
          subLabel="Категория магазина"
          sudDesc="Выберите категории магазина, какие типы товаров в нем продаются. Это поможет пользователям искать магаины."
        >
          <CategoriesInput
            selectedItem={values[CATEGORIES_KEY]}
            onChange={this.onChangeCategories}
            InputProps={{
              placeholder: 'Выберите категорию',
              error: errors[CATEGORIES_KEY],
            }}
          />
        </FieldRow>
        <FieldRow
          label="Бренд"
          subLabel="Бренды магазина"
          sudDesc="Выберите бренды магазина, которые в нем реализуются. Это поможет пользователям искать магазины."
        >
          <BrandMultiAutocomplete
            id={BRANDS_IDS_KEY}
            className={classes.wrap}
            name={BRANDS_IDS_KEY}
            onChange={this.onChangeBrand}
            selectedItem={values[BRANDS_IDS_KEY]}
            InputProps={{
              placeholder: 'Выберите бренд',
              error: errors[BRANDS_IDS_KEY],
            }}
          />
        </FieldRow>
        <FieldRow
          label="БИН/ИИН"
          subLabel="БИН/ИИН"
          sudDesc="Выберите БИН/ИИН арендатора."
        >
          <MerchantMultiAutocomplete
            id={MERCHANTS_IDS_KEY}
            className={classes.wrap}
            name={MERCHANTS_IDS_KEY}
            onChange={this.onChangeMerchant}
            selectedItem={values[MERCHANTS_IDS_KEY]}
            InputProps={{
              placeholder: 'БИН/ИИН',
              error: errors[MERCHANTS_IDS_KEY],
            }}
          />
        </FieldRow>
        <FieldRow
          label="Партнер программы лояльности"
          subLabel="Отобразить магазин как партнер программы лояльности?"
        >
          <FormControlLabel
            label="Отображать иконку об участии в программе лояльности"
            control={
              <Checkbox
                checked={values[LOYALTY_REP_KEY]}
                onChange={this.setLoyaltyRep}
              />
            }
          />
        </FieldRow>
        <FieldRow label="Комментарий">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name="comment"
            multiline
            rows={2}
            rowsMax={4}
            label="Комментарий"
          />
        </FieldRow>
        <FieldRow>
          <ButtonSet>
            <RequiredMessages fieldNames={fieldNames} />
            <LinkAsButton size="large" href={STORES_PATH}>
              Назад
            </LinkAsButton>
            <SubmitButton
              Target={AsyncButton}
              ButtonComponent={Button}
              error={error}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
            >
              Сохранить магазин
            </SubmitButton>
          </ButtonSet>
        </FieldRow>
      </Form>
    );
  }
}
