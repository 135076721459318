import { RESOURCE_NAME as EFS_SALES } from '@esentai/core/features/efsSales/consts';
import { RESOURCE_NAME as EFS_VISIT_RECORDS } from '@esentai/core/features/efsVisitRecords/consts';
import { can, permit, VIEW } from '@esentai/core/policy-dsl';

import { SALES_EFS } from '@/features/efsSalesPage/consts/permissions';

import { VIEW_NAVIGATION_BAR } from '../consts/permissions';

export default [
  can(VIEW_NAVIGATION_BAR),
  permit(EFS_VISIT_RECORDS, [VIEW]),
  permit(EFS_SALES, [SALES_EFS]),
];
