// Metric
export { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';
export const AVG_NR_PURCHASES_KEY = 'avg_nr_purchases';
export const AVG_NR_RECEIPTS_KEY = 'avg_nr_receipts';
export const AVG_TICKET_KEY = 'avg_ticket';
export const BY_CAMPAIGN_KEY = 'by_campaign';
export const NR_RECEIPTS_KEY = 'nr_receipts';
export const PERCENT_RECEIPTS_WHITE_LEVEL_KEY = 'percent_receipts_white_level';
export const PERCENT_RECEIPTS_DAY_KEY = 'percent_receipts_day';
export const PERCENT_RECEIPTS_EVENING_KEY = 'percent_receipts_evening';
export const PERCENT_RECEIPTS_BLACK_LEVEL_KEY = 'percent_receipts_level_two';
export const PERCENT_RECEIPTS_MORNING_KEY = 'percent_receipts_morning';
export const PERCENT_RECEIPTS_ORANGE_LEVEL_KEY = 'percent_receipts_level_one';
export const PERCENT_RECEIPTS_SILVER_LEVEL_KEY =
  'percent_receipts_silver_level';

export const PERCENT_RECEIPTS_JETBLACK_LEVEL_KEY =
  'percent_receipts_level_three';

// Dimension
export const CAMPAIGN_ID_KEY = 'campaign_id';
export const CUSTOMER_AGE_KEY = 'customer_age';
export const CUSTOMER_AVERAGE_TICKET_KEY = 'customer_average_ticket';
export const CUSTOMER_BORN_AT_KEY = 'customer_born_at';
export const CUSTOMER_GENDER_KEY = 'customer_gender';
export const CUSTOMER_ID_KEY = 'customer_id';
export const CUSTOMER_LEVEL_KEY = 'customer_level';
export const CUSTOMER_RECEIPTS_NUMBER_KEY = 'customer_nr_receipts';
export const LOCATION_ID_KEY = 'location_id';
export const TIME_SERIES_KEY = 'ts';
export const CUSTOMER_STATE_KEY = 'customer_state';
