import { SEND_SCHEDULE_KEY } from '@esentai/core/features/campaigns/notification/consts/keys';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { append, remove, update } from 'ramda';
import React, { Component } from 'react';

import AddButton from '@/components/ArticleConstructorForm/AddButton';

import { MAX_SCHEDULE_SIZE } from '../../../../consts';
import { REPEAT_SEND_DATETIMES } from '../../../../consts/keys';
import { CanEdit } from '../../../CanEdit';
import Patterns from './Patterns';
import RepeatTick from './RepeatTick';

class RepeatSchedule extends Component {
  static propTypes = {
    initialSendDatetime: PropTypes.instanceOf(Date),
    finalSendDatetime: PropTypes.instanceOf(Date),
    repeatSendDatetimes: PropTypes.arrayOf(PropTypes.instanceOf(Date))
      .isRequired,
    setFieldValue: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialSendDatetime: null,
    finalSendDatetime: null,
  };

  appendTick = () => {
    const { repeatSendDatetimes, setFieldValue } = this.props;

    setFieldValue(REPEAT_SEND_DATETIMES, append(null, repeatSendDatetimes));
  };

  changeTick = (index, value) => {
    const { repeatSendDatetimes, setFieldValue } = this.props;

    setFieldValue(
      REPEAT_SEND_DATETIMES,
      update(index, value, repeatSendDatetimes),
    );
  };

  removeTick = index => {
    const { repeatSendDatetimes, setFieldValue } = this.props;

    setFieldValue(REPEAT_SEND_DATETIMES, remove(index, 1, repeatSendDatetimes));
  };

  updateTicks = ticks => {
    const { setFieldValue } = this.props;
    const formatted = ticks.map(tick => format(tick, 'yyyy-MM-dd'));

    setFieldValue(REPEAT_SEND_DATETIMES, formatted);
  };

  render() {
    // const { shouldRepeatSend } = this.props;

    return (
      <div>
        {/* {this.renderShouldRepeatSend()} */}

        {[
          this.renderPatterns(),
          this.renderSchedule(),
          this.renderAppendButton(),
        ]}
      </div>
    );
  }

  renderShouldRepeatSend() {
    const { classes } = this.props;

    return (
      <div className={classes.checkboxContainer}>{this.renderLimitCount()}</div>
    );
  }

  renderPatterns() {
    const { initialSendDatetime, finalSendDatetime } = this.props;

    return (
      <CanEdit field={SEND_SCHEDULE_KEY}>
        <Patterns
          initialSendDatetime={initialSendDatetime}
          finalSendDatetime={finalSendDatetime}
          updateTicks={this.updateTicks}
        />
      </CanEdit>
    );
  }

  renderAppendButton() {
    const { repeatSendDatetimes } = this.props;

    if (repeatSendDatetimes.length >= MAX_SCHEDULE_SIZE) {
      return null;
    }

    return (
      <CanEdit field={SEND_SCHEDULE_KEY}>
        <AddButton onClick={this.appendTick}>Добавить еще одну дату</AddButton>
      </CanEdit>
    );
  }

  renderSchedule() {
    const { repeatSendDatetimes } = this.props;

    const repeatTicks = repeatSendDatetimes.map((sendDatetime, index) => (
      <RepeatTick
        key={String(index)}
        id={index}
        value={sendDatetime}
        onChange={this.changeTick}
        onRemove={this.removeTick}
      />
    ));

    return <CanEdit field={SEND_SCHEDULE_KEY}>{repeatTicks}</CanEdit>;
  }

  renderLimitCount() {
    const { classes, repeatSendDatetimes } = this.props;

    return (
      <Typography color="textSecondary" className={classes.limitCount}>
        {repeatSendDatetimes.length} из {MAX_SCHEDULE_SIZE} доступных сообщений
      </Typography>
    );
  }
}

export default RepeatSchedule;
