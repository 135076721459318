import {
  Checkbox,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import CanView from '@/components/CanView';
import EmptyComponent from '@/components/EmptyComponent';

import DefaultErrorMessage from './ErrorMessage';
import DefaultNoMatchMessage from './NoMatchMessage';
import SortControl from './SortControl';
import SpinnerRow from './SpinnerRow';

const ALL_COLUMNS_SPAN = 100;

class CommonTable extends Component {
  static propTypes = {
    isAnySelected: PropTypes.bool.isRequired,
    isAllSelected: PropTypes.bool.isRequired,
    isSelected: PropTypes.func.isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
    toggleAll: PropTypes.func.isRequired,
    toggleItem: PropTypes.func.isRequired,
    selectAll: PropTypes.func.isRequired,
    deselectAll: PropTypes.func.isRequired,

    ErrorMessage: PropTypes.func,
    NoMatchMessage: PropTypes.func,
    HeadRow: PropTypes.func.isRequired,
    TotalRow: PropTypes.func,
    Row: PropTypes.func.isRequired,
    TopRow: PropTypes.func.isRequired,
    ColGroup: PropTypes.func,
    BulkActionsRow: PropTypes.func,

    error: PropTypes.any,
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    sort: PropTypes.arrayOf(PropTypes.object),
    updateLimit: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
    updateSort: PropTypes.func,
    totalCount: PropTypes.number.isRequired,
    campaignType: PropTypes.string,
    showTopRow: PropTypes.bool,
    history: PropTypes.object,
  };

  static defaultProps = {
    error: null,
    isLoading: false,
    sort: null,
    updateSort: null,
    ErrorMessage: DefaultErrorMessage,
    NoMatchMessage: DefaultNoMatchMessage,
    TopRow: EmptyComponent,
    TotalRow: EmptyComponent,
    ColGroup: EmptyComponent,
    BulkActionsRow: null,
    campaignType: null,
    showTopRow: true,
    history: {},
  };

  renderSortControl = ({ children, field, ...props }) => (
    <SortControl
      field={field}
      sort={this.props.sort}
      setSort={this.props.updateSort}
      {...props}
    >
      {children}
    </SortControl>
  );

  render() {
    const {
      classes,
      HeadRow,
      limit,
      Row,
      error,
      isLoading,
      isSelected,
      items,
      page,
      selectedItems,
      ErrorMessage,
      NoMatchMessage,
      TopRow,
      TotalRow,
      totalCount,
      ColGroup,
      BulkActionsRow,
      campaignType,
      showTopRow,
      history,
      selectAll,
      deselectAll,
    } = this.props;

    const hasItems = Boolean(items.length);

    console.log(error, ErrorMessage);

    return (
      <Fragment>
        {Boolean(!selectedItems.length) && showTopRow && (
          <TopRow history={history} />
        )}
        <Table>
          <ColGroup />
          <TableHead className={classes.thead}>
            {Boolean(selectedItems.length) && (
              <BulkActionsRow
                columnsCount={ALL_COLUMNS_SPAN}
                selectedItems={selectedItems}
                deselectAll={deselectAll}
                selectAll={selectAll}
              />
            )}
            <HeadRow
              campaignType={campaignType}
              history={history}
              GlobalSelectionToggle={this.renderGlobalSelectionToggle}
              SortControl={this.renderSortControl}
            />
            <CanView isVisible={hasItems && !error}>
              <TotalRow campaignType={campaignType} />
            </CanView>
          </TableHead>
          <TableBody
            className={classNames(classes.tbody, {
              [classes.dimmed]: isLoading,
            })}
          >
            <CanView isVisible={!hasItems && isLoading}>
              <SpinnerRow columnsCount={ALL_COLUMNS_SPAN} />
            </CanView>
            <CanView isVisible={!hasItems && !error && !isLoading}>
              <NoMatchMessage columnsCount={ALL_COLUMNS_SPAN} />
            </CanView>
            <CanView isVisible={Boolean(error) && !isLoading}>
              <ErrorMessage columnsCount={ALL_COLUMNS_SPAN} />
            </CanView>
            <CanView isVisible={!error}>
              {items.map((itemId, index) => (
                <Row
                  campaignType={campaignType}
                  key={
                    typeof itemId === 'object'
                      ? itemId.id
                      : `${itemId}-${index}`
                  }
                  item={itemId}
                  selected={isSelected(itemId)}
                  SelectionToggle={this.renderSelectionToggle}
                />
              ))}
            </CanView>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="Показывать на странице:"
                labelDisplayedRows={this.labelDisplayedRows}
                rowsPerPageOptions={this.rowsPerPageOptions}
                colSpan={ALL_COLUMNS_SPAN}
                count={totalCount}
                rowsPerPage={limit}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeLimit}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Fragment>
    );
  }

  rowsPerPageOptions = [10, 20, 30, 50, 100, 200];

  labelDisplayedRows = ({ from, to, count }) => `${from}-${to} из ${count}`;

  renderGlobalSelectionToggle = () => {
    const { isAnySelected, isAllSelected, toggleAll } = this.props;

    return (
      <Checkbox
        indeterminate={isAnySelected && !isAllSelected}
        checked={isAllSelected}
        onChange={toggleAll}
      />
    );
  };

  renderSelectionToggle = ({ item }) => {
    const { isSelected, toggleItem } = this.props;

    return (
      <Checkbox checked={isSelected(item)} onChange={() => toggleItem(item)} />
    );
  };

  handleChangePage = (_, page) => {
    const { updatePage } = this.props;

    updatePage(page);
  };

  handleChangeLimit = ({ target: { value: limit } }) => {
    const { updateLimit } = this.props;

    updateLimit(limit);
  };
}

export default CommonTable;
