export default {
  thumb: {
    width: 150,
    height: 150,
    borderRadius: 5,
    border: '1px solid #ccc',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 40,
    marginRight: 40,
    backgroundColor: '#fff',
  },
  img: {
    maxWidth: 140,
    maxHeight: 140,
  },
  faded: {
    // opacity: 0.4,
    animation: 'fadein 4s',
  },
  rotate: {
    animation: 'spin 4s linear infinite',
  },
  fab: {
    position: 'absolute',
    bottom: -20,
    right: -20,
  },
};
