import { withStyles } from '@material-ui/core';

import Dropdown from './component';

const styles = {
  root: {},
  trigger: {},
  popup: {},
};

export default withStyles(styles)(Dropdown);
