import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney, formatMoneyUsd } from '@/utils/format';

import Field from '../Field';

const Description = ({
  classes,
  totalInactiveAmountKzt,
  totalInactiveAmountUsd,
  totalActiveAmountKzt,
  totalActiveAmountUsd,
}) => (
  <>
    <div className={classes.facts}>
      <Field label="Общий итог:" className={classes.fact}>
        {formatMoney(totalInactiveAmountKzt)}
      </Field>
      <Field label="Общий итог:" className={classes.fact}>
        {formatMoneyUsd(totalInactiveAmountUsd)}
      </Field>
      <Field label="Активный итог:" className={classes.fact}>
        {formatMoney(totalActiveAmountKzt)}
      </Field>
      <Field label="Активный итог:" className={classes.fact}>
        {formatMoneyUsd(totalActiveAmountUsd)}
      </Field>
    </div>
  </>
);

Description.propTypes = {
  totalInactiveAmountKzt: PropTypes.number,
  totalInactiveAmountUsd: PropTypes.number,
  totalActiveAmountKzt: PropTypes.number,
  totalActiveAmountUsd: PropTypes.number,
};

Description.defaultProps = {
  totalInactiveAmountKzt: 0,
  totalInactiveAmountUsd: 0,
  totalActiveAmountKzt: 0,
  totalActiveAmountUsd: 0,
};

export default Description;
