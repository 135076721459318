export default {
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },

  requiredMessages: {
    marginRight: 30,
  },

  textField: {
    width: 500,
  },
};
