import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import StoreNameLazy from '@/containers/StoreNameLazy';

const StoreChip = ({ value, ...props }) => (
  <Chip {...props} id={value} label={<StoreNameLazy storeId={value.store} />} />
);

StoreChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default StoreChip;
