import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getStoreNames } from '@/features/benefitGiveOutPage/selectors';

import BenefitGiveOut from './component';
import styles from './styles';

const mapStateToProps = state => ({
  storeNames: getStoreNames(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(BenefitGiveOut);
