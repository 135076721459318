import PropTypes from 'prop-types';
import { Component } from 'react';

class Delay extends Component {
  static propTypes = {
    wait: PropTypes.number.isRequired,
  };

  state = {
    isShow: false,
  };

  constructor(props) {
    super(props);

    this.timer = setTimeout(this.show, this.props.wait);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return this.state.isShow ? this.props.children : null;
  }

  show = () => this.setState({ isShow: true });
}

export default Delay;
