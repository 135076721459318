import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import { createAction } from 'redux-actions';

import {
  AUTHENTICATE,
  AUTHENTICATE_FAILURE,
  AUTHENTICATE_SUCCESS,
  AUTHORIZE,
  AUTHORIZE_BY_TOKEN,
  AUTHORIZE_FAILURE,
  AUTHORIZE_SUCCESS,
  CHANGE_CURRENT_USER,
  DEAUTHORIZE,
  EXPAND,
} from './types';

export const authenticate = createTaskAction(AUTHENTICATE);
export const authenticateSuccess = createAction(AUTHENTICATE_SUCCESS);
export const authenticateFailure = createAction(AUTHENTICATE_FAILURE);
export const authorize = createAction(AUTHORIZE);
export const authorizeByToken = createAction(AUTHORIZE_BY_TOKEN);
export const authorizeSuccess = createAction(AUTHORIZE_SUCCESS);
export const authorizeFailure = createAction(AUTHORIZE_FAILURE);
export const changeCurrentUser = createAction(CHANGE_CURRENT_USER);
export const deauthorize = createAction(DEAUTHORIZE);
export const expand = createAction(EXPAND);
