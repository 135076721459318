import {
  getCategories,
  getCreationDateForStore,
  getNameForStore,
  isStoreActive,
} from '@esentai/core/features/stores/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { createStorePageUrl } from '@/features/storePage/utils';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: storeId }) => ({
  active: isStoreActive(state, storeId),
  creationDate: getCreationDateForStore(state, storeId),
  name: getNameForStore(state, storeId),
  categoryKeys: getCategories(state, storeId),
  storeId,
  storePageUrl: createStorePageUrl(storeId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
