import {
  PRE_PUSH_ENGLISH_MESSAGE_KEY,
  PRE_PUSH_ENGLISH_TITLE_KEY,
  PRE_PUSH_RUSSIAN_MESSAGE_KEY,
  PRE_PUSH_RUSSIAN_TITLE_KEY,
  PRE_PUSH_SEND_DATETIME_KEY,
  SEND_PRE_PUSH_PUSH_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';

import DateTimePicker from '@/components/DateTimePicker';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import TextField from '@/components/TextField';

import { CanEdit } from '../../CanEdit';
import Preview from './Preview';

class PrePushForm extends PureComponent {
  static propTypes = {
    dateStart: PropTypes.instanceOf(Date).isRequired,
    prePushEnglishTitle: PropTypes.any.isRequired,
    prePushRussianTitle: PropTypes.any.isRequired,
    prePushEnglishMessage: PropTypes.any.isRequired,
    prePushRussianMessage: PropTypes.any.isRequired,
    canSendPrePush: PropTypes.bool.isRequired,
    campaignStarted: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    Field: PropTypes.func.isRequired,
  };

  setPrePush = event => {
    const { checked } = event.target;
    const { setFieldValue } = this.props;

    setFieldValue(SEND_PRE_PUSH_PUSH_KEY, checked);
  };

  render() {
    const { campaignStarted, canSendPrePush } = this.props;

    return (
      <FieldRow
        label="Рекламное пуш-сообщение"
        subLabel="Пуш-сообщение"
        sudDesc="Это предварительное сообщение, которое будет отправлено в указанные вами дату и время для осведомления потенциальных покупателей о предстоящей акции. Оно отправляется лишь один раз, будьте внимательны."
        Aside={this.renderPreview}
      >
        <CanEdit field={SEND_PRE_PUSH_PUSH_KEY}>
          <FormControlLabel
            label="Отправить предварительное пуш-сообщение"
            control={
              <Checkbox
                disabled={campaignStarted}
                checked={canSendPrePush}
                onChange={this.setPrePush}
              />
            }
          />
        </CanEdit>
        {canSendPrePush && this.renderPushContrustor()}
      </FieldRow>
    );
  }

  renderPushContrustor() {
    const { classes, dateStart, Field } = this.props;

    return (
      <div>
        <FieldDescription
          title="Текст пуш-сообщения"
          desc="Это предварительное сообщение, которое будет отправлено в указанные вами дату и время для осведомления потенциальных покупателей о предстоящей акции. Оно отправляется лишь один раз, будьте внимательны."
        />

        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Заголовок сообщения"
            desc="Заголовок сообщения, которое пользователь увидит при активации триггера. Не более 60 символов."
          />
          <CanEdit field={PRE_PUSH_RUSSIAN_TITLE_KEY}>
            <Field
              Target={TextField}
              name={PRE_PUSH_RUSSIAN_TITLE_KEY}
              symbolsMax={60}
              helperText="На русском"
              fullWidth
            />
          </CanEdit>
          <CanEdit field={PRE_PUSH_ENGLISH_TITLE_KEY}>
            <Field
              Target={TextField}
              name={PRE_PUSH_ENGLISH_TITLE_KEY}
              symbolsMax={60}
              helperText="На английском"
              fullWidth
            />
          </CanEdit>
        </div>

        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Описание"
            desc="Текст сообщения, которое пользователь увидит при активации триггера в списке уведомлений. Не более 100 символов."
          />

          <CanEdit field={PRE_PUSH_RUSSIAN_MESSAGE_KEY}>
            <Field
              Target={TextField}
              name={PRE_PUSH_RUSSIAN_MESSAGE_KEY}
              helperText="На русском"
              fullWidth
              symbolsMax={100}
              rowsMax={15}
              multiline
            />
          </CanEdit>
          <CanEdit field={PRE_PUSH_ENGLISH_MESSAGE_KEY}>
            <Field
              Target={TextField}
              name={PRE_PUSH_ENGLISH_MESSAGE_KEY}
              helperText="На английском"
              symbolsMax={100}
              rowsMax={15}
              multiline
              fullWidth
            />
          </CanEdit>
        </div>

        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Дата отправки сообщения"
            desc="Обратите внимание! Технически невозможно отправить сообщения большому количеству пользователей одновременно. Поэтому сообщения будут рассылаться в течение нескольких часов начиная с указанного времени."
          />
          <CanEdit field={PRE_PUSH_SEND_DATETIME_KEY}>
            <Field
              Target={DateTimePicker}
              disablePast
              maxDate={dateStart}
              helperText="Точный день, в который будет разослано сообщение"
              name={PRE_PUSH_SEND_DATETIME_KEY}
            />
          </CanEdit>
        </div>
      </div>
    );
  }

  renderPreview = () => {
    const {
      prePushEnglishTitle,
      prePushRussianTitle,
      prePushEnglishMessage,
      prePushRussianMessage,
      canSendPrePush,
    } = this.props;

    if (!canSendPrePush) {
      return null;
    }

    return (
      <Preview
        prePushEnglishTitle={prePushEnglishTitle}
        prePushRussianTitle={prePushRussianTitle}
        prePushEnglishMessage={prePushEnglishMessage}
        prePushRussianMessage={prePushRussianMessage}
      />
    );
  };
}

export default PrePushForm;
