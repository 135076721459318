import { findBrandById } from '@esentai/core/features/brands/actions';
import {
  getCategories,
  getChineseDescription,
  getEnglishDescription,
  getImageUrl,
  getKazakhDescription,
  getName,
  getRussianDescription,
  getStoresIds,
  isBrandLoaded,
  isBrandLoading,
} from '@esentai/core/features/brands/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import { getBrandId } from '../selectors';
import BrandPage from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getBrandId(state);

  return {
    isLoaded: isBrandLoaded(state, itemId),
    isLoading: isBrandLoading(state, itemId),
    itemId,
    name: getName(state, itemId),
    imageUrl: getImageUrl(state, itemId),
    chineseDescription: getChineseDescription(state, itemId),
    englishDescription: getEnglishDescription(state, itemId),
    kazakhDescription: getKazakhDescription(state, itemId),
    russianDescription: getRussianDescription(state, itemId),
    storesIds: getStoresIds(state, itemId),
    categories: getCategories(state, itemId),
  };
};

const mapDispatchToProps = { fetchItem: findBrandById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
  withStyles(styles),
)(BrandPage);
