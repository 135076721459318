/* eslint-disable complexity */
import { TableCell, TableRow } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import api from '@/api';
import { formatMoney } from '@/utils/format';

import EditFullName from '../Modal/EditFullName';
import EditIin from '../Modal/EditIin';

const Row = props => {
  const { item, classes } = props;

  const {
    id,
    real_full_name,
    first_name,
    last_name,
    iin,
    real_iin,
    bonuses_spent,
    total_ipn_to_pay,
  } = item;

  const [editFullnameModalOpen, setEditFullnameModalOpen] = useState(false);
  const [editIinModalOpen, setEditIinModalOpen] = useState(false);

  const handleUpdateFullName = async values => {
    const payload = {
      'ipn-set-fullname': [
        {
          id,
          ...values,
        },
      ],
    };

    try {
      await api.doPatch(`/ipn-report/set-real-fullname`, payload);
      setEditFullnameModalOpen(false);
      window.ipnReportsData.loadIpnReportsData();
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateIin = async values => {
    const payload = {
      'ipn-set-iin': [
        {
          id,
          ...values,
        },
      ],
    };

    try {
      await api.doPatch(`/ipn-report/set-real-iin`, payload);
      setEditIinModalOpen(false);
      window.ipnReportsData.loadIpnReportsData();
    } catch (error) {
      throw error;
    }
  };

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>
        {first_name && last_name ? (
          <div className={classes.editWrapper}>
            <span className={classes.editText}>
              {`${last_name} ${first_name}`}
            </span>
            {!real_full_name && (
              <EditIcon
                className={classes.editIcon}
                fontSize="small"
                onClick={() => setEditFullnameModalOpen(true)}
              />
            )}
          </div>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{real_full_name || '-'}</TableCell>
      <TableCell>
        {iin ? (
          <div className={classes.editWrapper}>
            <span
              className={classes.editText}
              onClick={() => setEditIinModalOpen(true)}
            >
              {iin || '-'}
            </span>
            {!real_iin && (
              <EditIcon
                className={classes.editIcon}
                fontSize="small"
                onClick={() => setEditIinModalOpen(true)}
              />
            )}
          </div>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{real_iin || '-'}</TableCell>
      <TableCell>{bonuses_spent ? formatMoney(bonuses_spent) : '-'}</TableCell>
      <TableCell>{total_ipn_to_pay}</TableCell>

      {editFullnameModalOpen && (
        <EditFullName
          open={editFullnameModalOpen}
          disableBackdropClick
          disableEscapeKeyDown
          fullName={`${last_name} ${first_name}`}
          onClose={() => setEditFullnameModalOpen(false)}
          onSubmit={handleUpdateFullName}
          confirmLabel={'Сохранить'}
          cancelLabel={'Отменить'}
          style={{ display: 'flex' }}
        />
      )}

      {editIinModalOpen && (
        <EditIin
          open={editIinModalOpen}
          disableBackdropClick
          disableEscapeKeyDown
          iin={iin}
          onClose={() => setEditIinModalOpen(false)}
          onSubmit={handleUpdateIin}
          confirmLabel={'Сохранить'}
          cancelLabel={'Отменить'}
          style={{ display: 'flex' }}
        />
      )}
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
