import { equals, query, where } from '@esentai/core/query-dsl';
import { path, pipe } from 'ramda';

import {
  META_TIME_SCALE_KEY,
  META_TOTAL_HEADER,
} from '@/features/statistics/consts/keys';
import { createItemsSelectors } from '@/features/statisticsPage/selectors';
import { getStatPageUrl } from '@/features/statisticsPage/utils';
import { LOCATION_ID_KEY } from '@/features/usersStatistics/consts/filterKeys';
import {
  AVERAGE_CHECK_KEY,
  AVERAGE_TIME_SPENT,
  BUYERS_COUNT_KEY,
  CONVERSION_RATE_KEY,
  MALL_LEADS_COUNT_KEY,
  MALL_VISITORS_COUNT_KEY,
  PURCHASES_COUNT_KEY,
  UNIQUE_LOCATION_LEADS_COUNT,
  USERS_COUNT_KEY,
  VISITORS_COUNT_KEY,
} from '@/features/usersStatistics/consts/keys';
import daoDuck from '@/features/usersStatistics/ducks/dao';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { createPageUrl } from './serializer';
import stats from './stats';

export const {
  getError,
  isLoadingItems,
  getItemsMeta,
  getItemsTotalCount,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;

export const {
  getItemsIds,
  getXValues,
  getLeftYValues,
  getRightYValues,
  getDateBySelectedIndex,
  getPropBySelectedIndex,
} = createItemsSelectors(itemsDuck, queryDuck, daoDuck, stats);

export const getScale = pipe(getItemsMeta, path([META_TIME_SCALE_KEY]));

export const getTotalAverageCheck = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, AVERAGE_CHECK_KEY]),
);

export const getTotalAverageTimeSpent = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, AVERAGE_TIME_SPENT]),
);

export const getTotalBuyersCount = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, BUYERS_COUNT_KEY]),
);

export const getTotalConversion = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, CONVERSION_RATE_KEY]),
);

export const getTotalLeadsCount = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, UNIQUE_LOCATION_LEADS_COUNT]),
);

export const getTotalPurchasesCount = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, PURCHASES_COUNT_KEY]),
);

export const getTotalUsersCount = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, USERS_COUNT_KEY]),
);

export const getTotalVisitorsCount = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, VISITORS_COUNT_KEY]),
);

export const getTotalMallVisitorsCount = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, MALL_VISITORS_COUNT_KEY]),
);

export const getTotalUserLeadCount = pipe(
  getItemsMeta,
  path([META_TOTAL_HEADER, MALL_LEADS_COUNT_KEY]),
  () => 0,
);

export const getDefaultMetricsPageUrl = () =>
  getStatPageUrl('users', [stats[0].url_key, stats[1].url_key]);

export const getLocationStatisticsPageUrl = (state, locationId) => {
  const defaultMetricsPageUrl = getDefaultMetricsPageUrl(state);
  const pageQuery = query(where(LOCATION_ID_KEY, equals(locationId)));

  return createPageUrl(defaultMetricsPageUrl, pageQuery);
};
