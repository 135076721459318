import { Button, Grid } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

class DeleteCarForm extends Component {
  render() {
    const { Form, SubmitButton, onClose } = this.props;

    return (
      <Form>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignContent="space-around"
          spacing={0}
          style={{ marginTop: '24px', marginBottom: '24px' }}
        >
          <Button onClick={onClose} size="large">
            Отменить
          </Button>
          <SubmitButton
            Target={AsyncButton}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Удалить
          </SubmitButton>
        </Grid>
      </Form>
    );
  }
}

DeleteCarForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default DeleteCarForm;
