import {
  TITLE_ENGLISH_KEY,
  TITLE_RUSSIAN_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import {
  EMPTY_LAYOUT,
  TITLE_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/layouts';
import { pipe } from 'ramda';

import {
  SUBTITLE_ENGLISH_KEY,
  SUBTITLE_LAYOUT,
  SUBTITLE_RUSSIAN_KEY,
} from '@/components/ArticleConstructorForm/consts';

import {
  CAMPAIGN_ID_URL_SECTION,
  CAMPAIGN_TYPE_URL_SECTION,
  STEP_ROUTE_PATH,
  STEP_URL_SECTION,
} from './consts';

export const createFormStepURL = (type, id, step) =>
  STEP_ROUTE_PATH.replace(CAMPAIGN_TYPE_URL_SECTION, type)
    .replace(CAMPAIGN_ID_URL_SECTION, id)
    .replace(STEP_URL_SECTION, step);

export const renameTitleToSubtitle = values => {
  if (!values) {
    return null;
  }
  let title;

  return values.map(item => {
    const { type, payload } = item;

    if (type === TITLE_LAYOUT && !title) {
      title = true;

      return item;
    }

    if (type === TITLE_LAYOUT && title) {
      return {
        ...item,
        type: SUBTITLE_LAYOUT,
        payload: {
          [SUBTITLE_RUSSIAN_KEY]: payload[TITLE_RUSSIAN_KEY],
          [SUBTITLE_ENGLISH_KEY]: payload[TITLE_ENGLISH_KEY],
        },
      };
    }

    return item;
  });
};

export const renameSubtitleToTitle = values =>
  values.map(item => {
    const { type, payload } = item;

    return type === SUBTITLE_LAYOUT
      ? {
          ...item,
          type: TITLE_LAYOUT,
          payload: {
            [TITLE_RUSSIAN_KEY]: payload[SUBTITLE_RUSSIAN_KEY],
            [TITLE_ENGLISH_KEY]: payload[SUBTITLE_ENGLISH_KEY],
          },
        }
      : item;
  });

export const clearEmptyBlocks = layout =>
  layout.filter(block => block[TYPE_KEY] !== EMPTY_LAYOUT);

export const replaceSubmitValues = pipe(
  renameSubtitleToTitle,
  clearEmptyBlocks,
);
