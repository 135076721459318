import { Chip as MuiChip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import LocationNameLazy from '@/containers/LocationNameLazy';

const Chip = ({ locationID, ...rest }) => (
  <MuiChip
    id={locationID}
    label={<LocationNameLazy locationId={locationID} />}
    {...rest}
  />
);

Chip.propTypes = {
  locationID: PropTypes.number.isRequired,
};

export default Chip;
