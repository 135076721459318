import { Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

class CategoryForm extends Component {
  render() {
    const {
      Form,
      Field,
      SubmitButton,
      RequiredMessages,
      onClose,
      isEdit,
    } = this.props;

    return (
      <Form>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Field
              Target={TextField}
              id="name_ru"
              name="name_ru"
              label="Название RU"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              rowsMax={4}
            />
          </Grid>
          <Grid item>
            <Field
              Target={TextField}
              id="name_en"
              name="name_en"
              label="Название EN"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              rowsMax={4}
            />
          </Grid>
          <Grid item>
            <Field
              Target={TextField}
              id="name_kz"
              name="name_kz"
              label="Название KZ"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              rowsMax={4}
            />
          </Grid>
          <Grid item>
            <ButtonSet>
              <Button onClick={onClose} size="large">
                Отменить
              </Button>
              <SubmitButton
                Target={AsyncButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                {isEdit ? 'Сохранить изменения' : 'Создать'}
              </SubmitButton>
            </ButtonSet>
          </Grid>
          <Grid item>
            <RequiredMessages
              fieldNames={{
                name_ru: 'Название RU',
                name_en: 'Название EN',
                name_kz: 'Название KZ',
              }}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

CategoryForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default CategoryForm;
