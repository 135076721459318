import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { moveQuestionUp } from '@/features/questionsPage/actions';

import MoveUpButton from './component';

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(moveQuestionUp(itemId)),
});

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(MoveUpButton);
