import {
  FIFTH_FLOOR_ID,
  FIRST_FLOOR_ID,
  FIRST_PARKING_FLOOR_ID,
  FOURTH_FLOOR_ID,
  MINUS_FIRST_FLOOR_ID,
  SECOND_FLOOR_ID,
  SECOND_PARKING_FLOOR_ID,
  THIRD_FLOOR_ID,
  THIRD_PARKING_FLOOR_ID,
} from '@esentai/core/features/beacons/consts/floors';

export const floorLabel = floor => {
  const mapping = {
    [THIRD_PARKING_FLOOR_ID]: 'P3',
    [SECOND_PARKING_FLOOR_ID]: 'P2',
    [FIRST_PARKING_FLOOR_ID]: 'P1',
    [MINUS_FIRST_FLOOR_ID]: '-1',
    [FIRST_FLOOR_ID]: '1',
    [SECOND_FLOOR_ID]: '2',
    [THIRD_FLOOR_ID]: '3',
    [FOURTH_FLOOR_ID]: '4',
    [FIFTH_FLOOR_ID]: '5',
  };

  return mapping[floor];
};
