import { scaleLinear } from 'd3-scale';
import { interpolateRdYlBu } from 'd3-scale-chromatic';
import { compose } from 'ramda';

const invert = amount => 1 - amount;

export const createAmountScale = (min, max) => scaleLinear().domain([min, max]);

export const createColorScale = scale =>
  compose(interpolateRdYlBu, invert, scale);
