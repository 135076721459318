import PropTypes from 'prop-types';
import React, { useState } from 'react';

import BaseBulkActionsRow from '@/components/BulkActionsRow';
import ActionItem from '@/components/BulkActionsRow/ActionItem';
import ConfirmModal from '@/components/ConfirmModal';

const BulkActionsRow = ({ deleteParkingLevelThreeWhitelist, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BaseBulkActionsRow {...props}>
      {!isOpen ? (
        <ActionItem onClick={() => setIsOpen(true)}>
          Убрать выбранных пользователей из списка
        </ActionItem>
      ) : (
        <ConfirmModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onConfirm={deleteParkingLevelThreeWhitelist}
          cancelLabel="Отмена"
          confirmLabel="Удалить"
          title="Вы уверены, что удалить данных пользователей из списка?"
          style={{ display: 'flex' }}
        />
      )}
    </BaseBulkActionsRow>
  );
};

BulkActionsRow.propTypes = {
  deleteParkingLevelThreeWhitelist: PropTypes.func.isRequired,
};

export default BulkActionsRow;
