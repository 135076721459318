import { takeEvery } from 'redux-saga/effects';

import createUpdateUrl from './updateUrl';

export default options => {
  const { actionNames } = options;
  const updateUrl = createUpdateUrl(options);

  const {
    removeFilters,
    updateFilters,
    updateLimit,
    updatePage,
    updateSort,
  } = actionNames;

  return function*() {
    yield takeEvery(
      [removeFilters, updateFilters, updateLimit, updatePage, updateSort],
      updateUrl,
    );
  };
};
