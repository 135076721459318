import {
  ID_KEY,
  PAYLOAD_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import PropTypes from 'prop-types';
import React from 'react';

import mapping from './mapping';

const Preview = ({ classes, layout, selectByLocale }) => (
  <div className={classes.root}>
    {layout.map(block => {
      const type = block[TYPE_KEY];
      const Block = mapping[type];

      return Block ? (
        <Block
          key={block[ID_KEY]}
          payload={block[PAYLOAD_KEY]}
          selectByLocale={selectByLocale}
        />
      ) : null;
    })}
  </div>
);

Preview.propTypes = {
  layout: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectByLocale: PropTypes.func.isRequired,
};

export default Preview;
