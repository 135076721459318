import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';

import TooltipPopper from './TooltipPopper';

class Tooltip extends Component {
  anchorElement = createRef();

  render() {
    const {
      classes,
      rightYScale,
      leftYScale,
      rightYs,
      leftYs,
      selectedDateIndex,
      maxY,
      TooltipContent,
    } = this.props;

    const rightPointerPosition = rightYScale(rightYs[selectedDateIndex]);
    const leftPointerPosition = leftYScale(leftYs[selectedDateIndex]);
    const startTooltipLine = Math.min(
      rightPointerPosition,
      leftPointerPosition,
    );

    return (
      <g className={classes.g}>
        <line
          className={classes.line}
          y1={startTooltipLine}
          y2={maxY}
          ref={this.anchorElement}
        />
        <circle
          className={classes.circle}
          cy={leftPointerPosition}
          fill="#4A90E2"
        />
        <circle
          className={classes.circle}
          cy={rightPointerPosition}
          fill="#F5A623"
        />
        <foreignObject>
          <TooltipPopper
            selectedDateIndex={selectedDateIndex}
            anchorEl={this.anchorElement}
          >
            <TooltipContent
              selectedDateIndex={selectedDateIndex}
              rightFirst={this.isRightYValueFirst(
                leftPointerPosition,
                rightPointerPosition,
              )}
            />
          </TooltipPopper>
        </foreignObject>
      </g>
    );
  }

  isRightYValueFirst = (left, right) => right > left;
}

Tooltip.propTypes = {
  maxY: PropTypes.number,
  rightYScale: PropTypes.func.isRequired,
  leftYScale: PropTypes.func.isRequired,
  rightYs: PropTypes.arrayOf(PropTypes.number).isRequired,
  leftYs: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedDateIndex: PropTypes.number,
  TooltipContent: PropTypes.func.isRequired,
};

Tooltip.defaultProps = {
  selectedDateIndex: null,
  maxY: null,
};

export default Tooltip;
