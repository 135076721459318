import { RadioGroup, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './styles';

const RadioBoxGroup = ({ classes, ...rest }) => (
  <RadioGroup className={classes.radioBoxGroup} {...rest} />
);

export default withStyles(styles)(RadioBoxGroup);
