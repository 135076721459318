import { VIDEO_VIMEO_URL_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import axios, { CancelToken } from 'axios';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { Component } from 'react';

import { extractVideoId } from './utils';

export default class VideoBlock extends Component {
  static propTypes = {
    payload: PropTypes.object.isRequired,
  };

  state = {
    id: null,
    url: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const id = extractVideoId(nextProps.payload[VIDEO_VIMEO_URL_KEY]);

    if (id !== prevState.id) {
      return { id };
    }

    return null;
  }

  getImageUrl(id) {
    this.source = CancelToken.source();

    axios
      .get(`https://vimeo.com/api/v2/video/${id}.json`, {
        cancelToken: this.source.token,
      })
      .then(response => {
        const imgUrl = path(['data', 0, 'thumbnail_large'], response);

        if (imgUrl) {
          this.setState({
            url: imgUrl,
          });
        }

        this.source = null;
      })
      .catch(() => {});
  }

  componentDidUpdate(_, prevState) {
    const { id } = this.state;

    if (id && prevState.id !== id) {
      this.getImageUrl(id);
    }
  }

  componentDidMount() {
    const { id } = this.state;

    if (id) {
      this.getImageUrl(id);
    }
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel();
    }
  }

  render() {
    const { url } = this.state;
    const { classes } = this.props;

    return url && <img className={classes.thumbnail} src={url} />;
  }
}
