import { axisRight } from 'd3-axis';
import { select } from 'd3-selection';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';

import { formatDescriptorValue } from '@/features/statisticsPage/utils';

class RightYAxis extends Component {
  static propTypes = {
    descriptor: PropTypes.object.isRequired,
    maxX: PropTypes.number.isRequired,
    scale: PropTypes.func.isRequired,
  };

  gElement = createRef();

  getAxis = () => {
    const { scale } = this.props;

    return axisRight(scale)
      .tickFormat(this.formatValue)
      .ticks(6)
      .tickSize(0);
  };

  componentDidMount() {
    this.renderD3();
  }

  componentDidUpdate() {
    this.renderD3();
  }

  formatValue = datum => {
    const { descriptor } = this.props;

    return formatDescriptorValue(descriptor, datum);
  };

  render() {
    return <g ref={this.gElement} />;
  }

  renderD3() {
    const element = select(this.gElement.current);
    const { classes, maxX } = this.props;
    const rightYAxis = this.getAxis();

    element
      .attr('class', classes.rightYAxis)
      .attr('transform', `translate(${maxX},0)`)
      .call(rightYAxis);
  }
}

export default RightYAxis;
