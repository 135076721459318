import createDao from '@esentai/core/ducks/dao';

import { getIdProp } from '@/features/statistics/utils';

import api from '../api';
import { getDaoRoot } from '../utils';

export default createDao('user_aggregation', {
  api,
  getRoot: getDaoRoot,
  idProp: getIdProp,
});
