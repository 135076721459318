import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getKkmsError,
  getItemsIds: getKkmIds,
  getItemsMeta: getKkmsMeta,
  getItemsTotalCount: getKkmsTotalCount,
  isLoadingItems: isLoadingKkms,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
