import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = ['SYNERGY', 'EFS', 'NONE', 'MASTERPASS'].map(key => ({
  key,
  label: key,
}));

export const createParkingDiscountTypeFilter = createRadioFilterTemplate({
  options,
});

export default RadioGroupFilter;
