import { RESOURCE_NAME as BENEFITS } from '@esentai/core/features/benefits/consts';
import { RESOURCE_NAME as GIFT_SETS } from '@esentai/core/features/giftSets/consts';
import { RESOURCE_NAME as KKMS } from '@esentai/core/features/kkms/consts';
import { RESOURCE_NAME as MALL_BONUSES } from '@esentai/core/features/mallBonuses/consts';
import { RESOURCE_NAME as PURCHASE_HISTORY } from '@esentai/core/features/purchases/consts';
import { RESOURCE_NAME as RECONCILIATIONS_CP } from '@esentai/core/features/reconciliation/consts';
import { RESOURCE_NAME as RECONCILIATIONS_HISTORY } from '@esentai/core/features/reconciliationHistory/consts';
import { RESOURCE_NAME as RECONCILIATION_PERIOD_BONUS_DETAILS } from '@esentai/core/features/reconciliationPeriodBonusDetails/consts';
import { RESOURCE_NAME as RECONCILIATION_RECEIPTS } from '@esentai/core/features/reconciliationReceipts/consts';
import { RESOURCE_NAME as RECONCILIATION_REFUNDS } from '@esentai/core/features/reconciliationRefunds/consts';
import { RESOURCE_NAME as RECONCILIATION_STORE_BONUS_DETAILS } from '@esentai/core/features/reconciliationStoreBonusDetails/consts';
import { RESOURCE_NAME as RECONCILIATION_STORE_TO_CP } from '@esentai/core/features/reconciliationStoreToCp/consts';
import { RESOURCE_NAME as STORE_BONUSES } from '@esentai/core/features/storeBonuses/consts';
import {
  can,
  CREATE,
  DELETE,
  EDIT,
  permit,
  VIEW,
} from '@esentai/core/policy-dsl';

import { RESOURCE_NAME as INVITES } from '@/features/invites/consts';
import { RESOURCE_NAME as PERSONNEL } from '@/features/personnel/consts';

import { VIEW_NAVIGATION_BAR } from '../consts/permissions';

export default [
  can(VIEW_NAVIGATION_BAR),
  permit(INVITES, [CREATE]),
  permit(BENEFITS, [VIEW]),
  permit(PERSONNEL, [VIEW, EDIT, DELETE]),
  permit(KKMS, [VIEW]),
  permit(PURCHASE_HISTORY, [VIEW]),
  permit(MALL_BONUSES, [VIEW]),
  permit(STORE_BONUSES, [VIEW]),
  permit(RECONCILIATIONS_CP, [VIEW]),
  permit(RECONCILIATIONS_HISTORY, [VIEW, EDIT]),
  permit(RECONCILIATION_RECEIPTS, [VIEW]),
  permit(RECONCILIATION_REFUNDS, [VIEW]),
  permit(RECONCILIATION_STORE_TO_CP, [VIEW]),
  permit(RECONCILIATION_STORE_BONUS_DETAILS, [VIEW]),
  permit(RECONCILIATION_PERIOD_BONUS_DETAILS, [VIEW]),
  permit(GIFT_SETS, [CREATE, VIEW, EDIT, DELETE]),
];
