import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';

import Table from './Table';

class BonusTransactionLevelHistory extends Component {
  static propTypes = {
    error: PropTypes.any,
    historyIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, historyIds, isLoading, totalCount } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>
            История сгоревших бонусов при смене уровня
          </PageTitle>
        </PageHeader>
        <PageContent>
          <Table
            error={error}
            items={historyIds}
            isLoading={isLoading}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default BonusTransactionLevelHistory;
