import {
  EMPTY_LAYOUT,
  IMAGES_LAYOUT,
  LOCATION_LAYOUT,
  ONLINE_SHOWCASE_LAYOUT,
  PARAGRAPH_LAYOUT,
  TITLE_LAYOUT,
  VIDEO_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/layouts';

import { SUBTITLE_LAYOUT } from '@/components/ArticleConstructorForm/consts';

import {
  createEmptyBlock,
  createImageBlock,
  createLocationBlock,
  createOnlineShowCaseBlock,
  createParagraphBlock,
  createSubtitleBlock,
  createTitleBlock,
  createVideoBlock,
} from '../utils';
import ImagesBlock from './ImagesBlock';
import LocationBlock from './LocationBlock';
import OnlineShowcaseBlock from './OnlineShowcaseBlock';
import ParagraphBlock from './ParagraphBlock';
import SubtitleBlock from './SubtitleBlock';
import TitleBlock from './TitleBlock';
import VideoBlock from './VideoBlock';

export const typeMapping = {
  [IMAGES_LAYOUT]: ImagesBlock,
  [TITLE_LAYOUT]: TitleBlock,
  [SUBTITLE_LAYOUT]: SubtitleBlock,
  [PARAGRAPH_LAYOUT]: ParagraphBlock,
  [VIDEO_LAYOUT]: VideoBlock,
  [LOCATION_LAYOUT]: LocationBlock,
  [ONLINE_SHOWCASE_LAYOUT]: OnlineShowcaseBlock,
};

export const dataCreatorMapping = {
  [EMPTY_LAYOUT]: createEmptyBlock,
  [IMAGES_LAYOUT]: createImageBlock,
  [TITLE_LAYOUT]: createTitleBlock,
  [SUBTITLE_LAYOUT]: createSubtitleBlock,
  [PARAGRAPH_LAYOUT]: createParagraphBlock,
  [VIDEO_LAYOUT]: createVideoBlock,
  [LOCATION_LAYOUT]: createLocationBlock,
  [ONLINE_SHOWCASE_LAYOUT]: createOnlineShowCaseBlock,
};
