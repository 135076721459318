export default {
  infoBlock: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#1E88E5',
    color: '#FFFFFF',
    zIndex: 100,
    justifyContent: 'space-between',
  },

  name: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    color: 'inherit',
    marginLeft: 16,
  },

  closeButton: {
    color: 'inherit',
  },
};
