import {
  PUSH_KAZAKH_MESSAGE_KEY,
  PUSH_KAZAKH_TITLE_KEY,
} from '@esentai/core/features/campaigns/notification/consts/keys';
import {
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/show/consts/keys';
import PropTypes from 'prop-types';
import React from 'react';

import OriginalPushNotification from '@/components/PushNotificationDevicePreview/PushNotification';
import {
  LOCALE_EN,
  LOCALE_KZ,
  LOCALE_RU,
} from '@/hocs/withDevicePreview/consts';

const PushNotification = ({ selectByLocale, values }) => {
  const title = selectByLocale({
    [LOCALE_EN]: values[PUSH_ENGLISH_TITLE_KEY],
    [LOCALE_RU]: values[PUSH_RUSSIAN_TITLE_KEY],
    [LOCALE_KZ]: values[PUSH_KAZAKH_TITLE_KEY],
  });
  const message = selectByLocale({
    [LOCALE_EN]: values[PUSH_ENGLISH_MESSAGE_KEY],
    [LOCALE_RU]: values[PUSH_RUSSIAN_MESSAGE_KEY],
    [LOCALE_KZ]: values[PUSH_KAZAKH_MESSAGE_KEY],
  });

  return <OriginalPushNotification title={title} message={message} />;
};

PushNotification.propTypes = {
  selectByLocale: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default PushNotification;
