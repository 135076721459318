import { path, pick, pipe } from 'ramda';

import {
  ALLOWED_LOCATION_IDS_KEY,
  NAME_KEY,
  STORE_IDS_KEY,
} from '@/features/personnel/consts/keys';
import {
  BITRIX_ROLE,
  EFS_SALES_ROLE,
  GUEST_EFS_ROLE,
  GUEST_PARKING_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_ADMINISTRATOR_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_SELLER_ROLE,
  MALL_VALET_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
} from '@/features/personnel/consts/roles';
import { canEditLocationsAccess } from '@/features/personnel/permissions';
import { getRoleForPersonnel } from '@/features/personnel/selectors';
import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';

export const getPersonnelId = pipe(
  createMatchSelector(ROUTE_PATH),
  path(['params', 'personalityId']),
);

export const canEditField = (state, field) => {
  if (field === ALLOWED_LOCATION_IDS_KEY) {
    return canEditLocationsAccess(state);
  }

  return true;
};

export const getEditableFields = state => {
  const personnelId = getPersonnelId(state);
  const role = getRoleForPersonnel(state, personnelId);
  const mapping = {
    [MALL_ADMINISTRATOR_ROLE]: [NAME_KEY],
    [MALL_MANAGER_ROLE]: [NAME_KEY],
    [MALL_SELLER_ROLE]: [NAME_KEY],
    [STORE_ADMINISTRATOR_ROLE]: [
      NAME_KEY,
      STORE_IDS_KEY,
      ALLOWED_LOCATION_IDS_KEY,
    ],
    [STORE_MANAGER_ROLE]: [NAME_KEY],
    [STORE_SELLER_ROLE]: [NAME_KEY],
    [MALL_COMMISSAR_ROLE]: [NAME_KEY],
    [MALL_ACCOUNTANT_ROLE]: [NAME_KEY],
    [MALL_VALET_ROLE]: [NAME_KEY],
    [GUEST_PARKING_ROLE]: [NAME_KEY],
    [BITRIX_ROLE]: [NAME_KEY],
    [GUEST_EFS_ROLE]: [NAME_KEY],
    [EFS_SALES_ROLE]: [NAME_KEY],
    [MALL_RECEPTION_ROLE]: [NAME_KEY],
    [MANAGING_TEAM_MEMBER_ROLE]: [NAME_KEY],
  };

  return mapping[role].filter(field => canEditField(state, field));
};

export const getEditableAttributes = (state, attributes) => {
  const editableFields = getEditableFields(state);

  return pick(editableFields, attributes);
};
