import {
  getActiveForStoreBonus,
  getBonusName,
  getCommentForStoreBonus,
  getCreationDateForStoreBonus,
  getEndDateForStoreBonus,
  getFiltersForStoreBonus,
  getOneTimeDatetime,
  getPercentForStoreBonus,
  getPeriodicDay,
  getReconciliationType,
  getStartDateForStoreBonus,
  getStoreForStoreBonus,
  getUserForStoreBonus,
} from '@esentai/core/features/storeBonuses/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: storeBonusId }) => ({
  creationDate: getCreationDateForStoreBonus(state, storeBonusId),
  percent: getPercentForStoreBonus(state, storeBonusId),
  startDate: getStartDateForStoreBonus(state, storeBonusId),
  endDate: getEndDateForStoreBonus(state, storeBonusId),
  store: getStoreForStoreBonus(state, storeBonusId),
  filters: getFiltersForStoreBonus(state, storeBonusId),
  user: getUserForStoreBonus(state, storeBonusId),
  comment: getCommentForStoreBonus(state, storeBonusId),
  status: getActiveForStoreBonus(state, storeBonusId),
  bonusName: getBonusName(state, storeBonusId),
  reconciliationType: getReconciliationType(state, storeBonusId),
  periodicDay: getPeriodicDay(state, storeBonusId),
  oneTimeDatetime: getOneTimeDatetime(state, storeBonusId),
  storeBonusId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
