import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const BeaconInfo = ({ classes, minorId, majorId, beaconName, beaconId }) =>
  beaconId ? (
    <Fragment>
      {beaconName}
      <div className={classes.beaconIds}>
        <Typography className={classes.id}>Minor ID: {minorId}</Typography>
        <Typography className={classes.id}>Major ID: {majorId}</Typography>
      </div>
    </Fragment>
  ) : null;

BeaconInfo.propTypes = {
  majorId: PropTypes.string.isRequired,
  minorId: PropTypes.string.isRequired,
  beaconName: PropTypes.string.isRequired,
  beaconId: PropTypes.number.isRequired,
};

export default BeaconInfo;
