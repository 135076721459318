import { withStyles } from '@material-ui/core';
import { defaultProps } from 'recompose';

import { createCheckboxFilterTemplate } from '@/filters/CheckboxGroup';
import { createEqualsAdapter } from '@/structs/pageFilterAdapter';

import SingleSelect from './component';
import styles from './styles';
import { createFormatter } from './utils';

const SingleSelectFilter = withStyles(styles)(SingleSelect);

export const createSingleSelectFilterTemplate = ({ options, inputProps }) =>
  createCheckboxFilterTemplate({
    FilterComponent: defaultProps({
      options,
      inputProps,
    })(SingleSelectFilter),
    FormatComponent: createFormatter(options),
    adapter: createEqualsAdapter(null),
    options,
  });

export default SingleSelectFilter;
