import {
  FACTORY_NUMBER_KEY,
  KKM_NUMBER_KEY,
  MERCHANT_ID_KEY,
  STATUS_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/kkms/consts/keys';
import { Button, Checkbox, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import LinkAsButton from '@/components/Button';
import ButtonSet from '@/components/ButtonSet';
import FieldRow from '@/components/FieldRow';
import MerchantBinLazy from '@/containers/MerchantBinLazy';
import { StoreWithMerchantsAutocomplete } from '@/containers/StoreWithMechantsAutocomplete';
import Label from '@/features/campaignPage/Page/Label';
import { ROUTE_PATH as KKMS_PATH } from '@/features/kkmsPage/consts';

import InputListField from './InputListField';

const fieldNames = {
  [KKM_NUMBER_KEY]: 'Номер ККМ',
  [STORE_ID_KEY]: 'Выберите магазин',
  [MERCHANT_ID_KEY]: 'БИН/ИИН',
  [STATUS_KEY]: 'Статус',
  comment: 'комментарий',
};

export default class EditKkmForm extends Component {
  static propTypes = {
    Field: PropTypes.func.isRequired,
    Form: PropTypes.func.isRequired,
    RequiredMessages: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    SubmitButton: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(Error),
    isLoading: PropTypes.bool,
    kkmStatus: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    error: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      kkmStatus: props.kkmStatus === 'active',
      // eslint-disable-next-line react/prop-types
      selectedStore: { merchant: props.merchant, store: props.storeId },
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.setFieldValue('kkmStatus', this.state.kkmStatus);
    }, 1000);
  }

  onChangeStore = option => {
    this.setState({ selectedStore: option });
    if (option) {
      this.props.setFieldValue(MERCHANT_ID_KEY, option.merchant);
      this.props.setFieldValue(STORE_ID_KEY, option.store);
    }
  };

  renderInput = ({
    preUrl,
    key,
    handlerRemoveItem,
    helperText,
    inputLabel,
  }) => {
    const { Field, setFieldValue } = this.props;

    return (
      <InputListField
        Field={Field}
        name={key}
        preUrl={preUrl}
        setFieldValue={setFieldValue}
        handlerRemoveItem={handlerRemoveItem}
        helperText={helperText}
        label={inputLabel}
      />
    );
  };

  render() {
    const {
      Form,
      Field,
      RequiredMessages,
      SubmitButton,
      classes,
      error,
      isLoading,
      setFieldValue,
      values,
      errors,
    } = this.props;
    const { selectedStore } = this.state;

    const { kkmStatus } = this.state;

    return (
      <Form>
        <FieldRow label="Номер ККМ">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={KKM_NUMBER_KEY}
            required
            rows={4}
            label="Номер ККМ"
            disabled
          />
        </FieldRow>

        <FieldRow label="ЗНМ">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={FACTORY_NUMBER_KEY}
            required
            rows={4}
            label="ЗНМ"
          />
        </FieldRow>

        <FieldRow label="Магазин" sudDesc="Выберите магазин">
          <StoreWithMerchantsAutocomplete
            id={STORE_ID_KEY}
            className={classes.wrap}
            name={STORE_ID_KEY}
            onChange={this.onChangeStore}
            selectedItem={selectedStore}
            InputProps={{
              placeholder: 'Магазин',
              error: errors[STORE_ID_KEY],
            }}
            disabled
          />
        </FieldRow>

        {values.merchant_id && (
          <FieldRow label="БИН/ИИН">
            <Label>
              <MerchantBinLazy merchantId={values.merchant_id} />
            </Label>
          </FieldRow>
        )}

        <FieldRow label="Активен">
          <Field
            margin="normal"
            Target={Checkbox}
            className={classes.checkbox}
            checked={kkmStatus}
            name="kkmStatus"
            rows={4}
            label="Активен"
            onChange={() => {
              setFieldValue('kkmStatus', !kkmStatus);
              this.setState({ kkmStatus: !kkmStatus });
            }}
          />
        </FieldRow>

        <FieldRow label="Комментарий">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name="comment"
            multiline
            rows={2}
            rowsMax={4}
            label="Комментарий"
          />
        </FieldRow>

        <FieldRow>
          <ButtonSet>
            <RequiredMessages fieldNames={fieldNames} />
            <LinkAsButton size="large" href={KKMS_PATH}>
              Назад
            </LinkAsButton>
            <SubmitButton
              Target={AsyncButton}
              ButtonComponent={Button}
              error={error}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
            >
              Сохранить
            </SubmitButton>
          </ButtonSet>
        </FieldRow>
      </Form>
    );
  }
}
