import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from './Button';

class GiveOut extends Component {
  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    itemId: PropTypes.number,
  };

  static defaultProps = {
    title: null,
    imageUrl: null,
    error: null,
    itemId: null,
  };

  componentDidMount() {
    const { fetchItems, token } = this.props;

    fetchItems(token);
  }

  render() {
    const { classes, isLoading, title, imageUrl, itemId } = this.props;

    return isLoading ? null : (
      <div className={classes.root}>
        <div className={classes.imageWrapper}>
          <img src={imageUrl} className={classes.image} />
        </div>

        <Typography variant="h6" align="center">
          {title}
        </Typography>

        <Button itemId={itemId} />
      </div>
    );
  }
}

export default GiveOut;
