import {
  ID_KEY,
  PAYLOAD_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { ONLINE_SHOWCASE_LAYOUT } from '@esentai/core/features/campaigns/article/consts/layouts';
import React, { Component } from 'react';

import Preview from '@/components/ArticleConstructorForm/Preview';
import FieldRow from '@/components/FieldRow';
import Label from '@/features/campaignPage/Page/Label';
import FormikPropTypes from '@/prop-types/formik';

import Block from './Block';

class Banner extends Component {
  static propTypes = FormikPropTypes;

  renderPreview = () => {
    const { layout } = this.props;

    return <Preview layout={layout} />;
  };

  render() {
    const { layout } = this.props;

    const isShowcase =
      layout && layout.find(item => item.type === ONLINE_SHOWCASE_LAYOUT);

    if (!layout) {
      return null;
    }

    return (
      <FieldRow label="Наполнение статьи" Aside={this.renderPreview}>
        {layout.map(block => (
          <Block
            key={block[ID_KEY]}
            payload={block[PAYLOAD_KEY]}
            type={block[TYPE_KEY]}
          />
        ))}
        {!isShowcase && <Label title="Онлайн витрина">Отсутствует</Label>}
      </FieldRow>
    );
  }
}

export default Banner;
