import { connect } from 'react-redux';

import {
  getActivationsCount,
  getActivationsToSegmentPercentage,
  getConversionRate,
  getPrePushClicksCount,
  getPushClicksToActivationsPercentage,
  getSegment,
} from '@/features/campaignsStatisticsDetailsPage/selectors';

import ActionResultFacts from './component';

const mapStateToProps = (state, { itemId }) => ({
  segmentSize: getSegment(state, itemId),
  activationsCount: getActivationsCount(state, itemId),
  prePushClicksCount: getPrePushClicksCount(state, itemId),
  conversion: getConversionRate(state, itemId),
  activationsToSegmentPercentage: getActivationsToSegmentPercentage(
    state,
    itemId,
  ),
  pushClicksToActivationsPercentage: getPushClicksToActivationsPercentage(
    state,
    itemId,
  ),
});

export default connect(mapStateToProps)(ActionResultFacts);
