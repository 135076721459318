const styles = theme =>
  Object.keys(theme.palette.logo).reduce(
    (acc, colorType) => ({
      ...acc,
      [colorType]: {
        '& .icon_svg__esentai-text-path': {
          fill: theme.palette.logo[colorType].esentaiColor,
        },
        '& .icon_svg__mall-text-path': {
          fill: theme.palette.logo[colorType].mallColor,
        },
      },
    }),
    {},
  );

export default styles;
