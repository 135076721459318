import {
  ENGLISH_TITLE_KEY,
  IMAGE_URL_KEY,
  KAZAKH_TITLE_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/banner/consts/keys';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import ImageField from '@/components/ImageField';
import TextField from '@/components/TextField';
import limits from '@/features/campaignCreatePage/EditPage/limits';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../../CanEdit';
import Preview from './Preview';

class MainPageContentForm extends Component {
  static propTypes = FormikPropTypes;

  setImageUrl = urls => {
    const { setFieldValue } = this.props;
    const [url] = urls;

    setFieldValue(IMAGE_URL_KEY, url);
  };

  render() {
    const { Field, values } = this.props;

    return (
      <FieldRow
        disabled
        label="Наполнение"
        subLabel="Изображение баннера"
        sudDesc="Баннер обязательно должен иметь изображение."
        Aside={this.renderPreview}
      >
        <CanEdit field={IMAGE_URL_KEY}>
          <ImageField
            ids={values[IMAGE_URL_KEY] ? [values[IMAGE_URL_KEY]] : []}
            onChange={this.setImageUrl}
          />
        </CanEdit>
        <FieldDescription
          title="Заголовок баннера"
          desc="Заголовок будет отображаться под баннером. Не более 50 символов."
        />

        <CanEdit field={RUSSIAN_TITLE_KEY}>
          <Field
            Target={TextField}
            name={RUSSIAN_TITLE_KEY}
            symbolsMax={limits.title}
            helperText="На русском"
          />
        </CanEdit>
        <CanEdit field={ENGLISH_TITLE_KEY}>
          <Field
            Target={TextField}
            name={ENGLISH_TITLE_KEY}
            symbolsMax={limits.title}
            helperText="На английском"
          />
        </CanEdit>
        <CanEdit field={KAZAKH_TITLE_KEY}>
          <Field
            Target={TextField}
            name={KAZAKH_TITLE_KEY}
            symbolsMax={limits.title}
            helperText="На казахском"
          />
        </CanEdit>
      </FieldRow>
    );
  }

  renderPreview = () => {
    const { values } = this.props;

    return (
      <Preview
        imageUrl={values[IMAGE_URL_KEY]}
        englishTitle={values[ENGLISH_TITLE_KEY]}
        russianTitle={values[RUSSIAN_TITLE_KEY]}
        kazakhTitle={values[KAZAKH_TITLE_KEY]}
      />
    );
  };
}

export default MainPageContentForm;
