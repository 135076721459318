import {
  ADDITIONAL_ENGLISH_TEXT_KEY,
  ADDITIONAL_RUSSIAN_TEXT_KEY,
  ENGLISH_TITLE_KEY,
  IMAGE_URL_KEY,
  RUSSIAN_TITLE_KEY,
  TAGS_KEY,
} from '@esentai/core/features/campaigns/calendarEvent/consts/keys';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CalendarTopBar from '@/components/DevicePreview/CalendarTopBar';
import { LOCALE_RU } from '@/hocs/withDevicePreview/consts';

import Tags from './Tags';

const Preview = ({ classes, values, locale }) => {
  const tags = values[TAGS_KEY];
  const titleKey = locale === LOCALE_RU ? RUSSIAN_TITLE_KEY : ENGLISH_TITLE_KEY;
  const additionalTextKey =
    locale === LOCALE_RU
      ? ADDITIONAL_RUSSIAN_TEXT_KEY
      : ADDITIONAL_ENGLISH_TEXT_KEY;

  return (
    <Fragment>
      <CalendarTopBar />
      <Tags selectedTags={tags} />

      <div className={classes.root}>
        <div className={classes.imageWrapper}>
          <img className={classes.image} src={values[IMAGE_URL_KEY]} />
        </div>
        <div className={classes.title}>{values[titleKey]}</div>
        <div className={classes.additionalText}>
          {values[additionalTextKey]}
        </div>
      </div>
    </Fragment>
  );
};

Preview.propTypes = {
  values: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

export default Preview;
