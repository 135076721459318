import {
  BIN_KEY,
  COMPANY_KEY,
  FACT_ADDRESS_KEY,
  LEGAL_ADDRESS_KEY,
  MOBILE_PHONE_KEY,
  PARTNERSHIP_AGREEMENT_KEY,
  PARTNERSHIP_START_DATE_KEY,
  PRESIDENT_FULLNAME_KEY,
  RENT_AREA_INFO_KEY,
  STORES,
  WORK_PHONE_KEY,
} from '@esentai/core/features/merchants/consts/keys';
import {
  getBinForMerchant,
  getCompanyForMerchant,
  getFactAddressForMerchant,
  getLegalAddressForMerchant,
  getMobilePhoneForMerchant,
  getPartnershipAgreementForMerchant,
  getPartnershipStartDateForMerchant,
  getPresidentFullNameForMerchant,
  getRentAreaInfoForMerchant,
  getStatusForMerchant,
  getStoreIds,
  getWorkPhoneForMerchant,
} from '@esentai/core/features/merchants/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { array, boolean, date, number, object, string } from 'yup';

import withFormikValidation from '@/hocs/withFormikValidation';

import MerchantForm from './component';
import styles from './styles';

const formikOptions = {
  mapPropsToValues: ({
    company,
    bin,
    partnershipStartDate,
    partnershipAgreement,
    status,
    merchantStores,
    mobilePhone,
    workPhone,
    legalAddress,
    factAddress,
    presidentFullName,
    rentAreaInfo,
  }) => ({
    [COMPANY_KEY]: company,
    [MOBILE_PHONE_KEY]: mobilePhone,
    [WORK_PHONE_KEY]: workPhone,
    [LEGAL_ADDRESS_KEY]: legalAddress,
    [FACT_ADDRESS_KEY]: factAddress,
    [PRESIDENT_FULLNAME_KEY]: presidentFullName,
    [RENT_AREA_INFO_KEY]: rentAreaInfo,
    [BIN_KEY]: bin,
    [PARTNERSHIP_START_DATE_KEY]: partnershipStartDate,
    [PARTNERSHIP_AGREEMENT_KEY]: partnershipAgreement,
    merchantStatus: status,
    [STORES]: merchantStores || [],
    comment: '',
  }),

  validationSchema: object().shape({
    [COMPANY_KEY]: string()
      .max(200)
      .required(),
    [BIN_KEY]: string()
      .matches(/^[0-9]{12}$/, 'БИН/ИИН должен состоять из 12-ти цифр')
      .required(),
    [PARTNERSHIP_AGREEMENT_KEY]: string()
      .max(200)
      .required(),
    [PARTNERSHIP_START_DATE_KEY]: date().required(),
    merchantStatus: boolean().nullable(),
    [STORES]: array().of(number()),
    comment: string()
      .min(10, 'комментарий должен быть не менее 10 символов')
      .max(200)
      .required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };
const mapStateToProps = (state, { merchantId }) => ({
  company: getCompanyForMerchant(state, merchantId),
  mobilePhone: getMobilePhoneForMerchant(state, merchantId),
  workPhone: getWorkPhoneForMerchant(state, merchantId),
  legalAddress: getLegalAddressForMerchant(state, merchantId),
  factAddress: getFactAddressForMerchant(state, merchantId),
  presidentFullName: getPresidentFullNameForMerchant(state, merchantId),
  rentAreaInfo: getRentAreaInfoForMerchant(state, merchantId),
  bin: getBinForMerchant(state, merchantId),
  merchantStatus: getStatusForMerchant(state, merchantId),
  merchantStores: getStoreIds(state, merchantId),
  partnershipAgreement: getPartnershipAgreementForMerchant(state, merchantId),
  partnershipStartDate: getPartnershipStartDateForMerchant(state, merchantId),
});

export default compose(
  connect(mapStateToProps),
  withAsyncRunner(asyncOptions),
  withFormikValidation(formikOptions),
  withStyles(styles),
)(MerchantForm);
