import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import React from 'react';

import PageContent from '@/components/PageContent';
import Factoid from '@/features/campaignsStatisticsDetailsPage/Page/Factoid';
import { formatConversion } from '@/utils/format';

import { campaignsWithInfluence } from '../../consts';

const PurchaseResultFacts = ({ classes, effeciency, campaignType }) => {
  const showEffeciency = includes(campaignType, campaignsWithInfluence);

  return (
    showEffeciency && (
      <Grid container justify="center" className={classes.background}>
        <PageContent>
          <div className={classes.wrapper}>
            <Typography variant="h4" className={classes.label}>
              Влияние кампании
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.subLabel}
            >
              Все показатели, размещенные ниже, являются косвенными, так как нет
              технической возможности определить точно, является ли совершенная
              пользователем покупка смотивированной именно данной кампанией.
            </Typography>
            <Factoid
              name="Эффективность кампании"
              value={formatConversion(effeciency)}
              classes={classes}
              description="Соотношение посетителей к охвату"
            />
          </div>
        </PageContent>
      </Grid>
    )
  );
};

PurchaseResultFacts.propTypes = {
  effeciency: PropTypes.number.isRequired,
  campaignType: PropTypes.any.isRequired,
};

export default PurchaseResultFacts;
