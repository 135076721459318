export default {
  root: {
    display: 'flex',
    borderTop: [1, 'solid', '#BDBDBD'],
    alignItems: 'center',
  },

  title: {
    flex: 4,
  },

  status: {
    flex: 3,
    textAlign: 'left',
    marginRight: 16,
  },

  comparer: {
    flex: 1,
    align: 'right',
  },
};
