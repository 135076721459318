import {
  getGiftSetLoadingError,
  isGiftSetLoaded,
  isGiftSetLoading,
} from '@esentai/core/features/giftSets/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchGiftSet as fetchItem } from '@/features/giftSetPage/actions';
import { getGiftSetId } from '@/features/giftSetPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import GiftSet from './component';

const mapStateToProps = state => {
  const itemId = getGiftSetId(state);

  return {
    error: getGiftSetLoadingError(state, itemId),
    isLoaded: isGiftSetLoaded(state, itemId),
    isLoading: isGiftSetLoading(state, itemId),
    itemId,
  };
};

const mapDispatchToProps = {
  fetchItem,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(GiftSet);
