import { connect } from 'react-redux';

import { getLandingUrl } from '@/features/homePage/selectors';

import Home from './component';

const mapStateToProps = state => ({
  landingUrl: getLandingUrl(state),
});

export default connect(mapStateToProps)(Home);
