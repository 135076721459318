import PropTypes from 'prop-types';
import React from 'react';

import FormatterLayout from '../FormatterLayout';
import { formatDayTick } from '../utils';

const DayTickFormatter = ({ date, Layout, ...rest }) => {
  const text = formatDayTick(date);

  return <Layout text={text} {...rest} />;
};

DayTickFormatter.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  Layout: PropTypes.func,
};

DayTickFormatter.defaultProps = {
  Layout: FormatterLayout,
};

export default DayTickFormatter;
