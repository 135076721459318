import { connect } from 'react-redux';

import { getLocationMapPageUrl } from '@/features/mapPage/selectors';

import LocationPageTagLink from './component';

const mapStateToProps = (state, { locationId }) => ({
  pageUrl: getLocationMapPageUrl(state, locationId),
});

export default connect(mapStateToProps)(LocationPageTagLink);
