import {
  NAME_KEY,
  USERS_KEY,
} from '@esentai/core/features/parkingWhitelistGroups/consts/keys';
import { Grid, TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import FormikPropTypes from '@/prop-types/formik';

const fieldNames = {
  // [USERS_KEY]: 'пользователи',
  [NAME_KEY]: 'название',
};

class NewWhitelistGroupForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
  };

  setUsers = ids => {
    const { setFieldValue } = this.props;

    setFieldValue(USERS_KEY, ids);
  };

  render() {
    const { Field, Form, RequiredMessages, SubmitButton } = this.props;

    return (
      <Form style={{ margin: 10, width: '50%' }}>
        <Typography variant="h5" color="secondary" gutterBottom>
          Название группы
        </Typography>
        <Grid item>
          <Field
            placeholder="Введите название"
            style={{ width: '100%' }}
            Target={TextField}
            id={NAME_KEY}
            name={NAME_KEY}
          />
        </Grid>

        <ButtonSet>
          <RequiredMessages fieldNames={fieldNames} />
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            Создать
          </SubmitButton>
        </ButtonSet>
      </Form>
    );
  }
}

export default NewWhitelistGroupForm;
