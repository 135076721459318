import {
  EMPTY_LAYOUT,
  IMAGES_LAYOUT,
  LOCATION_LAYOUT,
  ONLINE_SHOWCASE_LAYOUT,
  PARAGRAPH_LAYOUT,
  TITLE_LAYOUT,
  VIDEO_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/layouts';

import { SUBTITLE_LAYOUT } from '@/components/ArticleConstructorForm/consts';

export default [
  {
    id: EMPTY_LAYOUT,
    label: 'Выберите тип блока',
  },
  {
    id: IMAGES_LAYOUT,
    label: 'Изображение',
  },
  {
    id: TITLE_LAYOUT,
    label: 'Заголовок',
  },
  {
    id: SUBTITLE_LAYOUT,
    label: 'Подзаголовок',
  },
  {
    id: PARAGRAPH_LAYOUT,
    label: 'Абзац текста',
  },
  {
    id: VIDEO_LAYOUT,
    label: 'Видеоролик',
  },
  {
    id: LOCATION_LAYOUT,
    label: 'Локация',
  },
  {
    id: ONLINE_SHOWCASE_LAYOUT,
    label: 'Онлайн витрина',
  },
];
