import { connect } from 'react-redux';

import {
  getTotalAverageTicket,
  getTotalPurchasesCount,
} from '@/features/anonymousPurchasesStatisticsPage/selectors';

import TotalRow from './component';

const mapStateToProps = state => ({
  averageTicket: getTotalAverageTicket(state),
  purchasesCount: getTotalPurchasesCount(state),
});

export default connect(mapStateToProps)(TotalRow);
