import classNames from 'classnames';
import { axisBottom } from 'd3-axis';
import { select } from 'd3-selection';
import { isWeekend } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';

import { formatDescriptorValue } from '@/features/statisticsPage/utils';

class XAxis extends Component {
  static propTypes = {
    descriptor: PropTypes.object.isRequired,
    maxY: PropTypes.number.isRequired,
    minY: PropTypes.number.isRequired,
    scale: PropTypes.func.isRequired,
  };

  gElement = createRef();

  getAxis = () => {
    const { scale, maxY, minY } = this.props;

    return axisBottom(scale)
      .tickFormat(this.formatValue)
      .tickSize(-(maxY - minY))
      .tickPadding(8);
  };

  componentDidMount() {
    this.renderD3();
  }

  componentDidUpdate() {
    this.renderD3();
  }

  formatValue = datum => {
    const { descriptor } = this.props;

    return formatDescriptorValue(descriptor, datum);
  };

  render() {
    return <g ref={this.gElement} />;
  }

  renderD3() {
    const element = select(this.gElement.current);
    const { classes, maxY } = this.props;
    const xAxis = this.getAxis();

    element
      .attr('class', classes.xAxis)
      .attr('transform', `translate(0,${maxY})`)
      .call(xAxis)
      .selectAll('text')
      .attr('class', datum =>
        classNames(classes.text, { [classes.text_weekend]: isWeekend(datum) }),
      );
  }
}

export default XAxis;
