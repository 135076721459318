import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { update } from '@esentai/core/features/questions/sagas';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ROUTE_PATH as QUESTIONS_PATH } from '@/features/questionsPage/consts';

import { getQuestionId } from './selectors';
import { UPDATE_QUESTION } from './types';

export function* updateQuestion(action) {
  const { payload: attributes } = action;
  const questionId = yield select(getQuestionId);

  yield call(update, questionId, attributes);
  yield put(push(QUESTIONS_PATH));
}

export default function*() {
  yield takeLatest(UPDATE_QUESTION, createTaskSaga(updateQuestion));
}
