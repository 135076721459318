import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import UserNameLazy from '@/containers/UserNameLazy';
// eslint-disable-next-line import/no-unresolved
import ReceiptModal from '@/features/purchaseHistoryPage/Page/ReceiptModal';
import { dateTimeFormat } from '@/utils/format';
import { activityType, loyaltyAssignStatuses } from '@/utils/labels';

const Row = ({
  customer,
  classes,
  comissarId,
  comment,
  userId,
  activity,
  loyaltyProcessDate,
  status,
  uuid,
  id,
  recordCreatedDate,
}) => {
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);

  const handleReceiptModal = () => {
    setIsReceiptDialogOpen(!isReceiptDialogOpen);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <UserNameLazy userId={customer} />
        </TableCell>
        <TableCell>
          <UserNameLazy userId={userId} />
        </TableCell>
        <TableCell>
          <UserNameLazy userId={comissarId} />
        </TableCell>
        <TableCell>
          {recordCreatedDate ? dateTimeFormat(recordCreatedDate) : '-'}
        </TableCell>
        <TableCell>
          {loyaltyProcessDate ? dateTimeFormat(loyaltyProcessDate) : '-'}
        </TableCell>
        <TableCell>
          <div className={classes.link} onClick={handleReceiptModal}>
            {uuid}
          </div>
        </TableCell>
        <TableCell>{comment}</TableCell>
        <TableCell>{activityType(activity)}</TableCell>
        <TableCell>{loyaltyAssignStatuses(status)}</TableCell>
      </TableRow>
      {isReceiptDialogOpen && (
        <ReceiptModal
          uuid={uuid}
          receipt={id}
          isOpen={isReceiptDialogOpen}
          onClose={handleReceiptModal}
        />
      )}
    </>
  );
};

Row.propTypes = {
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  uuid: PropTypes.number.isRequired,
  activity: PropTypes.string.isRequired,
  customer: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  comissarId: PropTypes.number.isRequired,
  loyaltyProcessDate: PropTypes.instanceOf(Date),
  recordCreatedDate: PropTypes.instanceOf(Date).isRequired,
  status: PropTypes.string.isRequired,
};

Row.defaultProps = {
  loyaltyLevel: '-',
  loyaltyBonuses: 0,
  loyaltyProcessDate: '-',
};

export default Row;
