import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import * as productCategoriesSagas from '@esentai/core/features/productCategories/sagas';
import dao from '@esentai/core/features/products/dao';
import { updateProduct } from '@esentai/core/features/products/sagas';
import { select, takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';

import { getProductId } from '@/features/productPage/selectors';
import { ROUTE_PATH as PRODUCT_PATH } from '@/features/productsPage/consts';

import { FETCH_PRODUCT, UPDATE_PRODUCT } from './consts/types';

export const { findById: findProductById } = dao.sagas;

export function* fetchProduct(action) {
  const { payload: productId } = action;

  yield call(findProductById, productId);
}

export function* updateProductSaga(action) {
  const { payload: attributes } = action;
  const productId = yield select(getProductId);

  yield call(updateProduct, productId, {
    ...attributes,
  });
  yield put(push(PRODUCT_PATH));
}

export function* watchFetchProduct() {
  yield takeEvery(FETCH_PRODUCT, fetchProduct);
}

export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProductSaga);
}

export default function*() {
  yield spawn(watchFetchProduct);
  yield spawn(productCategoriesSagas.default);
  yield takeLatest(UPDATE_PRODUCT, createTaskSaga(updateProductSaga));
}
