import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import MoveDownButton from './MoveDownButton';
import MoveUpButton from './MoveUpButton';

const SortControls = ({
  classes,
  canMoveCategoryDown,
  canMoveCategoryUp,
  itemId,
}) => (
  <div>
    <MoveUpButton
      itemId={itemId}
      className={classNames({ [classes.invisible]: !canMoveCategoryUp })}
    />
    <MoveDownButton
      itemId={itemId}
      className={classNames({ [classes.invisible]: !canMoveCategoryDown })}
    />
  </div>
);

SortControls.propTypes = {
  canMoveCategoryDown: PropTypes.bool.isRequired,
  canMoveCategoryUp: PropTypes.bool.isRequired,
  itemId: PropTypes.number.isRequired,
};

export default SortControls;
