import {
  DATE_END_KEY,
  DATE_START_KEY,
  SEGMENT_SIZE_KEY,
  STATUS_KEY,
  TITLE_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';

import { createCampaignStatusFilter } from '@/filters/CampaignStatus';
import { createCampaignTypeFilter } from '@/filters/CampaignType';
import { createDateRangeFilter } from '@/filters/DateRange';
import { createRangeFilter } from '@/filters/Range';
import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: TITLE_KEY,
    label: 'Название кампании',
  }),
  createDateRangeFilter({
    key: DATE_START_KEY,
    label: 'Период старта кампании',
  }),
  createDateRangeFilter({
    key: DATE_END_KEY,
    label: 'Период завершения кампании',
  }),
  createRangeFilter({
    key: SEGMENT_SIZE_KEY,
    label: 'Размер аудитории сегмента',
  }),
  createCampaignStatusFilter({
    key: STATUS_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),

  createCampaignTypeFilter({
    key: TYPE_KEY,
    label: 'Тип кампании',
  }),
];
