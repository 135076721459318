import {
  LOCATION_ID_KEY,
  PAYLOAD_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { Typography } from '@material-ui/core';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FieldDescription from '@/components/FieldDescription';

import { getName } from '../utils';
import Chip from './Chip';
import Select from './Select';

class LocationBlock extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    errors: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  handleChange = value => {
    const { setFieldValue } = this.props;

    setFieldValue(this.getName(), value);
  };

  handleDelete = () => {
    const { setFieldValue } = this.props;

    setFieldValue(this.getName(), null);
  };

  render() {
    const { data, errors, classes, disabled } = this.props;
    const name = this.getName();
    const error = getIn(errors, name);
    const id = data[PAYLOAD_KEY][LOCATION_ID_KEY];

    return (
      <Fragment>
        <FieldDescription desc="Тип блока «Локация» позволяет вставить ссылку на локация в разделе Mall Guide. Пройдя по этой ссылке пользователь увидит навигацию по Моллу, которая поможет ему добраться до указанной локации. " />
        {id ? (
          <Chip
            locationID={id}
            onDelete={!disabled ? this.handleDelete : null}
          />
        ) : (
          <Fragment>
            <Typography className={classes.label} gutterBottom>
              Добавить к статье локацию:
            </Typography>
            <Select
              name={name}
              error={error}
              onChange={this.handleChange}
              selectedItem={id}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }

  getName() {
    const { index } = this.props;

    return getName(index, LOCATION_ID_KEY);
  }
}

export default LocationBlock;
