import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ChipProgressIndicator from './ChipProgressIndicator';

const CustomAvatar = ({
  preview,
  progress,
  classes,
  isUploading,
  ...props
}) => (
  <Fragment>
    {isUploading && (
      <ChipProgressIndicator
        className={classes.chipProgress}
        progress={progress}
        hasPreview={Boolean(preview)}
      />
    )}
    {preview && <Avatar src={preview} {...props} />}
  </Fragment>
);

CustomAvatar.defaultProps = {
  preview: null,
  progress: null,
};
CustomAvatar.propTypes = {
  preview: PropTypes.any,
  progress: PropTypes.number,
  isUploading: PropTypes.bool.isRequired,
};

export default CustomAvatar;
