import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import Button from '@/components/Button';
import FieldRow from '@/components/FieldRow';
import { createFormStepURL } from '@/features/campaignCreatePage/utils';

class SecondStepForm extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    campaignType: PropTypes.string.isRequired,
    fieldNames: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    SubmitButton: PropTypes.func.isRequired,
    RequiredMessages: PropTypes.func.isRequired,
  };

  render() {
    const {
      children,
      fieldNames,
      classes,
      id,
      campaignType,
      handleSubmit,
      SubmitButton,
      RequiredMessages,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {children}

        <FieldRow>
          <div className={classes.footer}>
            <SubmitButton
              Target={AsyncButton}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
            >
              Перейти к указанию сегмента
            </SubmitButton>
            <Button
              href={createFormStepURL(campaignType, id, 1)}
              color="primary"
              size="large"
              className={classes.backButton}
            >
              Вернуться
            </Button>
            <RequiredMessages
              fieldNames={fieldNames}
              className={classes.requiredMessages}
            />
          </div>
        </FieldRow>
      </form>
    );
  }
}

export default SecondStepForm;
