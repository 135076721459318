import { getCampaignStatus } from '@esentai/core/features/campaigns/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getCampaignId } from '@/features/campaignPage/selectors';

import Actions from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getCampaignId(state);

  return {
    status: getCampaignStatus(state, itemId),
    itemId,
  };
};

export default compose(connect(mapStateToProps), withStyles(styles))(Actions);
