import { getIsLoyaltyRep } from '@esentai/core/features/stores/selectors';
import { connect } from 'react-redux';

import LoyaltyRepresentative from './component';

const mapStateToProps = (state, { storeId }) => ({
  isLoyaltyRep: getIsLoyaltyRep(state, storeId),
});

export default connect(mapStateToProps)(LoyaltyRepresentative);
