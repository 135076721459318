import {
  CATEGORY_KEY,
  DESCRIPTION_EN_KEY,
  DESCRIPTION_KZ_KEY,
  DESCRIPTION_RU_KEY,
  IMAGES_KEY,
  PRICE_KEY,
  SKU_KEY,
  STORE_ID_KEY,
  SUBTITLE_EN_KEY,
  SUBTITLE_KZ_KEY,
  SUBTITLE_RU_KEY,
  TITLE_EN_KEY,
  TITLE_KZ_KEY,
  TITLE_RU_KEY,
  VIEWS_KEY,
  VISIBILITY_KEY,
} from '@esentai/core/features/products/consts/keys';
import {
  getCategoryForProduct,
  getDescriptionEnForProduct,
  getDescriptionKzForProduct,
  getDescriptionRuForProduct,
  getImagesForProduct,
  getPriceForProduct,
  getSkuForProduct,
  getStoreIdProduct,
  getSubtitleEnForProduct,
  getSubtitleKzForProduct,
  getSubtitleRuForProduct,
  getTitleEnForProduct,
  getTitleKzForProduct,
  getTitleRuForProduct,
  getViewForProduct,
  getVisibilityForProduct,
} from '@esentai/core/features/products/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { array, boolean, number, object, string } from 'yup';

import withFormikValidation from '@/hocs/withFormikValidation';

import ProductForm from './component';
import styles from './styles';

const formikOptions = {
  mapPropsToValues: ({
    titleRu,
    titleEn,
    titleKz,

    subtitleRu,
    subtitleEn,
    subtitleKz,

    descriptionRu,
    descriptionEn,
    descriptionKz,

    images,
    store_id,
    sku,
    price,
    category,
    views,
    visible,
  }) => ({
    [TITLE_RU_KEY]: titleRu,
    [TITLE_EN_KEY]: titleEn,
    [TITLE_KZ_KEY]: titleKz,

    [SUBTITLE_RU_KEY]: subtitleRu,
    [SUBTITLE_EN_KEY]: subtitleEn,
    [SUBTITLE_KZ_KEY]: subtitleKz,

    [DESCRIPTION_RU_KEY]: descriptionRu,
    [DESCRIPTION_EN_KEY]: descriptionEn,
    [DESCRIPTION_KZ_KEY]: descriptionKz,

    [IMAGES_KEY]: images,
    [STORE_ID_KEY]: store_id,
    [SKU_KEY]: sku,
    [PRICE_KEY]: price,
    [CATEGORY_KEY]: category,
    [VIEWS_KEY]: views,
    [VISIBILITY_KEY]: visible,
  }),

  validationSchema: object().shape({
    [TITLE_RU_KEY]: string()
      .max(200)
      .required(),
    [TITLE_EN_KEY]: string().max(200),

    [SUBTITLE_RU_KEY]: string().max(200),
    [SUBTITLE_EN_KEY]: string().max(200),

    [DESCRIPTION_RU_KEY]: string().max(500),
    [DESCRIPTION_EN_KEY]: string().max(500),

    [SKU_KEY]: string().required(),
    [STORE_ID_KEY]: number().required(),
    [PRICE_KEY]: number(),
    [IMAGES_KEY]: array(string()),
    [CATEGORY_KEY]: number().required(),
    [VIEWS_KEY]: array(number()).required,
    [VISIBILITY_KEY]: boolean().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };

const mapStateToProps = (state, { productId }) => ({
  titleRu: getTitleRuForProduct(state, productId),
  titleEn: getTitleEnForProduct(state, productId),
  titleKz: getTitleKzForProduct(state, productId),

  subtitleRu: getSubtitleRuForProduct(state, productId),
  subtitleEn: getSubtitleEnForProduct(state, productId),
  subtitleKz: getSubtitleKzForProduct(state, productId),

  descriptionRu: getDescriptionRuForProduct(state, productId),
  descriptionEn: getDescriptionEnForProduct(state, productId),
  descriptionKz: getDescriptionKzForProduct(state, productId),

  images: getImagesForProduct(state, productId),
  store_id: getStoreIdProduct(state, productId),
  sku: getSkuForProduct(state, productId),
  price: getPriceForProduct(state, productId),
  category: getCategoryForProduct(state, productId),
  views: getViewForProduct(state, productId),
  visible: getVisibilityForProduct(state, productId),
});

export default compose(
  connect(mapStateToProps),
  withFormikValidation(formikOptions),
  withAsyncRunner(asyncOptions),
  withStyles(styles),
)(ProductForm);
