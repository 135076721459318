import { grey, red } from '@material-ui/core/colors';

export default {
  typography: {
    useNextVariants: true,

    caption: {
      color: 'rgba(0, 0, 0, 0.54)',
      lineHeight: '1.375em',
      letterSpacing: 0,
    },
  },

  palette: {
    tabIndicator: grey[50],

    primary: {
      light: '#9e9e9e',
      main: '#616161',
      dark: '#424242',
    },

    secondary: {
      light: '#9e9e9e',
      main: '#616161',
      dark: '#424242',
    },

    chart: {
      gridLine: 'rgb(224,224,224, 0.7)',
      axis: grey[300],
      stroke: '#979797',
      area: '#E6E6E6',
      median: '#b4b4b4',
    },

    logo: {
      white: {
        esentaiColor: '#bdbdbd',
        mallColor: '#fff',
      },
      black: {
        esentaiColor: '#757575',
        mallColor: '#000',
      },
    },

    text: {
      chart: {
        weekend: red[900],
        median: grey[50],
      },
    },
  },
};
