import { closestIndexTo } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ChartOverlay extends Component {
  static propTypes = {
    minX: PropTypes.number.isRequired,
    xScale: PropTypes.func.isRequired,
    setSelectedDateIndex: PropTypes.func.isRequired,
    xs: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  };

  mouseMoveHandler = e => {
    const { setSelectedDateIndex, minX, xScale, xs } = this.props;

    const rectBoundaries = e.target.getBoundingClientRect();
    const xAxisMousePosition = e.clientX - rectBoundaries.left;
    const selectedDate = xScale.invert(xAxisMousePosition + minX);
    const closeDateIndex = closestIndexTo(selectedDate, xs);

    setSelectedDateIndex(closeDateIndex);
  };

  mouseOutHandler = () => {
    const { setSelectedDateIndex } = this.props;

    setSelectedDateIndex(null);
  };

  render() {
    const { classes } = this.props;

    return (
      <rect
        className={classes.chartOverlay}
        onMouseMove={this.mouseMoveHandler}
        onMouseOut={this.mouseOutHandler}
      />
    );
  }
}

export default ChartOverlay;
