import {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
} from '@esentai/core/features/users/consts/stateTypes';

import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [
  {
    key: ACTIVE_STATUS,
    label: 'Активен',
  },
  {
    key: INACTIVE_STATUS,
    label: 'Деактивирован',
  },
];

export const createUserActivityStateFilter = createRadioFilterTemplate({
  initialValue: ACTIVE_STATUS,
  options,
});

export default RadioGroupFilter;
