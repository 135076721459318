import { SINGLE_IMAGE_LAYOUT } from '@esentai/core/features/campaigns/article/consts/imageLayouts';
import {
  ID_KEY,
  IMAGES_LAYOUT_KEY,
  IMAGES_URLS_KEY,
  LOCATION_ID_KEY,
  ONLINE_SHOWCASE_KEY,
  PARAGRAPH_ENGLISH_TEXT_KEY,
  PARAGRAPH_KAZAKH_TEXT_KEY,
  PARAGRAPH_RUSSIAN_TEXT_KEY,
  PAYLOAD_KEY,
  TITLE_ENGLISH_KEY,
  TITLE_KAZAKH_KEY,
  TITLE_RUSSIAN_KEY,
  TYPE_KEY,
  VIDEO_VIMEO_URL_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import {
  EMPTY_LAYOUT,
  IMAGES_LAYOUT,
  LOCATION_LAYOUT,
  ONLINE_SHOWCASE_LAYOUT,
  PARAGRAPH_LAYOUT,
  TITLE_LAYOUT,
  VIDEO_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/layouts';
import nanoid from 'nanoid';
import { countBy, prop } from 'ramda';

import {
  SUBTITLE_ENGLISH_KEY,
  SUBTITLE_LAYOUT,
  SUBTITLE_RUSSIAN_KEY,
} from './consts';

const createCreator = (type, payload) => id => ({
  [ID_KEY]: id || nanoid(),
  [TYPE_KEY]: type,
  [PAYLOAD_KEY]: { ...payload },
});

export const createEmptyBlock = createCreator(EMPTY_LAYOUT, {});

export const createImageBlock = createCreator(IMAGES_LAYOUT, {
  [IMAGES_LAYOUT_KEY]: SINGLE_IMAGE_LAYOUT,
  [IMAGES_URLS_KEY]: [],
});

export const createTitleBlock = createCreator(TITLE_LAYOUT, {
  [TITLE_RUSSIAN_KEY]: '',
  [TITLE_ENGLISH_KEY]: '',
  [TITLE_KAZAKH_KEY]: '',
});

export const createSubtitleBlock = createCreator(SUBTITLE_LAYOUT, {
  [SUBTITLE_RUSSIAN_KEY]: '',
  [SUBTITLE_ENGLISH_KEY]: '',
});

export const createParagraphBlock = createCreator(PARAGRAPH_LAYOUT, {
  [PARAGRAPH_RUSSIAN_TEXT_KEY]: '',
  [PARAGRAPH_ENGLISH_TEXT_KEY]: '',
  [PARAGRAPH_KAZAKH_TEXT_KEY]: '',
});

export const createVideoBlock = createCreator(VIDEO_LAYOUT, {
  [VIDEO_VIMEO_URL_KEY]: '',
});

export const createLocationBlock = createCreator(LOCATION_LAYOUT, {
  [LOCATION_ID_KEY]: null,
});

export const createOnlineShowCaseBlock = createCreator(ONLINE_SHOWCASE_LAYOUT, {
  [ONLINE_SHOWCASE_KEY]: null,
});

export const getDefaultLayout = () => [
  createImageBlock(),
  createTitleBlock(),
  createParagraphBlock(),
];

export const getCountByType = countBy(prop(TYPE_KEY));

// eslint-disable-next-line
// export const urlValidator = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
