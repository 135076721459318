import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { formatHumanDate, formatMoney, formatNumber } from '@/utils/format';

import Field from '../PersonalInfo/Field';

const SecondaryFacts = ({
  classes,
  receiptsCount,
  activeSpent,
  pendingSpent,
  moneySpent,
  billingStartDate,
}) => (
  <div className={classes.wrapper}>
    <Typography
      gutterBottom
      color="textSecondary"
      variant="h5"
      className={classes.title}
    >
      Данные по покупкам
    </Typography>

    <div className={classes.facts}>
      <Field label="Сумма покупок общая к начислению" className={classes.fact}>
        {formatMoney(pendingSpent)}
      </Field>

      <Field label="Сумма покупок общая активных" className={classes.fact}>
        {formatMoney(activeSpent)}
      </Field>

      <Field label="Общее количество покупок" className={classes.fact}>
        {formatNumber(receiptsCount)}
      </Field>

      <Field
        label="Итоговая сумма чеков за весь период"
        className={classes.fact}
      >
        {formatMoney(moneySpent)}
      </Field>

      <Field label="Дата начала расчетного периода" className={classes.fact}>
        {formatHumanDate(billingStartDate)}
      </Field>
    </div>
  </div>
);

SecondaryFacts.propTypes = {
  activeSpent: PropTypes.number.isRequired,
  pendingSpent: PropTypes.number.isRequired,
  moneySpent: PropTypes.number.isRequired,
  receiptsCount: PropTypes.number.isRequired,
  billingStartDate: PropTypes.instanceOf(Date).isRequired,
};

export default SecondaryFacts;
