export default {
  wrapper: {
    flexBasis: ({ percentage }) => `${percentage}%`,
  },

  label: {
    padding: [8, 2, 0, 2],
    whiteSpace: 'nowrap',
    fontSize: 14,
  },

  element: {
    height: 55,
    padding: [3, 2],
  },
};
