import api from '@esentai/core/features/brands/api';
import { NAME_KEY } from '@esentai/core/features/brands/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import BrandChip from './BrandChip';
import BrandOption from './BrandOption';

const buildQuery = queryString => query(where(NAME_KEY, ilike(queryString)));

const withBrandProps = defaultProps({
  api,
  buildQuery,
  Chip: BrandChip,
  Option: BrandOption,
});

export const BrandAutocomplete = withBrandProps(AutocompleteAPI);

export const BrandMultiAutocomplete = withBrandProps(AutocompleteAPIMultiple);
