import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import {
  ACTIVATIONS_COUNT_KEY,
  CLICKS_COUNT_KEY,
  CONVERSION_KEY,
  COVERAGE_KEY,
  PRE_PUSH_CLICKS_COUNT_KEY,
  PUSH_CLICKS_COUNT_KEY,
  SEGMENT_KEY,
  TIMEFRAME_KEY,
  VIEWS_COUNT_KEY,
} from '@/features/campaignsStatistics/consts/keys';

import {
  campaignsWithActivation,
  campaignsWithPrePush,
  campaignsWithPush,
  campaignsWithViews,
} from './consts';

const HeadRow = ({ classes, SortControl, campaignType }) => (
  <TableRow className={classes.rowRoot}>
    <CondenseTableCell className={classes.dateColumn}>
      <SortControl field={TIMEFRAME_KEY}>Дата</SortControl>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <SortControl field={SEGMENT_KEY}>Сегмент</SortControl>
    </CondenseTableCell>
    {includes(campaignType, campaignsWithPrePush) && (
      <CondenseTableCell align="right">
        <SortControl field={PRE_PUSH_CLICKS_COUNT_KEY}>Переходы 1</SortControl>
      </CondenseTableCell>
    )}
    {includes(campaignType, campaignsWithActivation) && (
      <CondenseTableCell align="right">
        <SortControl field={ACTIVATIONS_COUNT_KEY}>Активация</SortControl>
      </CondenseTableCell>
    )}
    {includes(campaignType, campaignsWithPush) && (
      <CondenseTableCell align="right">
        <SortControl field={PUSH_CLICKS_COUNT_KEY}>Переходы</SortControl>
      </CondenseTableCell>
    )}
    {includes(campaignType, campaignsWithPrePush) && (
      <CondenseTableCell align="right">
        <SortControl field={PUSH_CLICKS_COUNT_KEY}>Переходы 2</SortControl>
      </CondenseTableCell>
    )}
    {includes(campaignType, campaignsWithViews) && (
      <CondenseTableCell align="right">
        <SortControl field={VIEWS_COUNT_KEY}>Просмотры</SortControl>
      </CondenseTableCell>
    )}

    {includes(campaignType, campaignsWithViews) && (
      <CondenseTableCell align="right">
        <SortControl field={COVERAGE_KEY}>Охват</SortControl>
      </CondenseTableCell>
    )}
    {includes(campaignType, campaignsWithViews) && (
      <CondenseTableCell align="right">
        <SortControl field={CLICKS_COUNT_KEY}>Переходы</SortControl>
      </CondenseTableCell>
    )}
    <CondenseTableCell align="right">
      <SortControl field={CONVERSION_KEY}>Конверсия</SortControl>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      Дистрибьюция переходов по статусу
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
  campaignType: PropTypes.string.isRequired,
};

export default HeadRow;
