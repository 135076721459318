import { SvgIcon } from '@material-ui/core';
import React from 'react';

const Target = ({ ...rest }) => (
  <SvgIcon {...rest}>
    <path d="M12.245 9.876l-.837.837c-.25.25-.389.584-.389.94 0 .354.138.688.39.939.25.251.584.39.939.39s.688-.139.94-.39l.837-.837c.076-.077.158-.053.173.055a2.46 2.46 0 1 1-2.107-2.107c.107.014.131.096.054.173zm7.38-1.81c.038-.039.09-.031.114.017A8.863 8.863 0 0 1 11.861 21a8.832 8.832 0 0 1-6.266-2.595 8.86 8.86 0 0 1 0-12.53 8.832 8.832 0 0 1 6.265-2.596c1.394 0 2.788.328 4.057.982.048.025.056.076.018.115l-1.057 1.057a.156.156 0 0 1-.16.03 7.231 7.231 0 0 0-2.858-.584c-1.939 0-3.762.756-5.133 2.127-2.831 2.83-2.831 7.437 0 10.268A7.212 7.212 0 0 0 11.86 19.4c1.939 0 3.762-.755 5.134-2.126a7.21 7.21 0 0 0 2.126-5.134c0-1-.202-1.967-.584-2.858a.155.155 0 0 1 .03-.16l1.057-1.057zm-5.923.353a.151.151 0 0 1-.159.027 4.035 4.035 0 0 0-1.682-.368 4.034 4.034 0 0 0-2.872 1.19 4.066 4.066 0 0 0 0 5.743 4.036 4.036 0 0 0 2.872 1.19 4.035 4.035 0 0 0 2.871-1.19 4.033 4.033 0 0 0 1.19-2.871c0-.591-.13-1.161-.369-1.683a.151.151 0 0 1 .029-.16l1.04-1.039c.038-.038.092-.031.12.015a5.662 5.662 0 1 1-2.015-2.015c.047.028.053.082.015.12l-1.04 1.04zm7.1-2.9c.214.043.262.202.108.355L18.264 8.52a.412.412 0 0 1-.332.1l-1.315-.263-3.782 3.783a.69.69 0 0 1-.975-.975l3.782-3.782-.262-1.315a.41.41 0 0 1 .1-.333l2.646-2.645c.153-.154.313-.106.355.108l.368 1.837a.16.16 0 0 0 .116.116l1.838.368z" />
  </SvgIcon>
);

export default Target;
