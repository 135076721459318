import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import { destination as destinationLabel } from '@/features/campaignPage/labels';
import Label from '@/features/campaignPage/Page/Label';
import Reference from '@/features/campaignPage/Page/Reference';

const Destination = ({ destination, itemId }) =>
  Boolean(destination) && (
    <FieldRow label="Пуш-сообщение">
      <Label title="Ссылка пуш-сообщения">
        Пуш-сообщения ссылаются на <b>{destinationLabel(destination)}</b>:
        <Reference itemId={itemId} destination={destination} />
      </Label>
    </FieldRow>
  );

Destination.propTypes = {
  destination: PropTypes.string,
  itemId: PropTypes.number.isRequired,
};

Destination.defaultProps = {
  destination: null,
};

export default Destination;
