/* eslint-disable react/require-default-props */
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';

const TextField = props => {
  const excludeProps = omit(['select', 'SelectProps', 'variant']);
  const {
    autoComplete,
    autoFocus,
    className,
    defaultValue,
    error,
    FormHelperTextProps,
    fullWidth,
    helperText,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    label,
    multiline,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    rows,
    rowsMax,
    type,
    value,
    symbolsMax,
    ...other
  } = excludeProps(props);

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const symbolsCount = (value || '').length;

  return (
    <FormControl
      variant="standard"
      aria-describedby={helperTextId}
      className={className}
      error={error}
      fullWidth={fullWidth}
      required={required}
      {...other}
    >
      {label && (
        <InputLabel htmlFor={id} {...InputLabelProps}>
          {label}
        </InputLabel>
      )}

      <Input
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        multiline={multiline}
        name={name}
        rows={rows}
        rowsMax={rowsMax}
        type={type}
        value={value}
        id={id}
        inputRef={inputRef}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        inputProps={inputProps}
        {...InputProps}
      />

      {helperText && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          <Grid container justify="space-between" {...props}>
            <Grid item>{helperText}</Grid>

            {Boolean(symbolsMax) && (
              <Grid item>{`${symbolsCount} / ${symbolsMax}`}</Grid>
            )}
          </Grid>
        </FormHelperText>
      )}
    </FormControl>
  );
};

TextField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  label: PropTypes.node,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ]),
};

TextField.defaultProps = {
  required: false,
};

export default TextField;
