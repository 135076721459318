export default {
  title: {
    color: '#666666',
    lineHeight: 1.2,
  },

  subtitle: {
    color: '#9e9e9e',
  },
};
