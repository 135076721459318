import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import Tag from '@/components/Tag';
import LocationNameLazy from '@/containers/LocationNameLazy';

import Actions from './Actions';

class Row extends Component {
  static propTypes = {
    item: PropTypes.any.isRequired,
    rentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  render() {
    const { item: locationID, rentIds } = this.props;

    return (
      <TableRow>
        <CondenseTableCell>
          <LocationNameLazy locationId={locationID} />
        </CondenseTableCell>
        <CondenseTableCell>
          {rentIds.map(rentId => (
            <Tag key={rentId} label={rentId} />
          ))}
        </CondenseTableCell>
        <CondenseTableCell>
          <Actions itemId={locationID} />
        </CondenseTableCell>
      </TableRow>
    );
  }
}

export default Row;
