import { addDays, addMonths, subDays } from 'date-fns';
import { __, divide, pipe } from 'ramda';

import {
  DAY_SCALE,
  MONTH_SCALE,
  WEEK_SCALE,
} from '@/features/statistics/consts/scales';

import {
  ACTIVATIONS_COUNT_KEY,
  CLICKS_COUNT_KEY,
  CLICKS_PERCENTAGE_BY_BLACK_LEVELED_USERS_KEY,
  CLICKS_PERCENTAGE_BY_JETBLACK_LEVELED_USERS_KEY,
  CLICKS_PERCENTAGE_BY_ORANGE_LEVELED_USERS_KEY,
  CLICKS_PERCENTAGE_BY_SILVER_LEVELED_USERS_KEY,
  CLICKS_PERCENTAGE_BY_WHITE_LEVELED_USERS_KEY,
  CONVERSION_KEY,
  COVERAGE_KEY,
  EFFICIENCY_KEY,
  PRE_PUSH_CLICKS_COUNT_KEY,
  PUSH_CLICKS_COUNT_KEY,
  SCROLLS_COUNT_TO_25_PERCENT_KEY,
  SCROLLS_COUNT_TO_50_PERCENT_KEY,
  SCROLLS_COUNT_TO_75_PERCENT_KEY,
  SCROLLS_COUNT_TO_100_PERCENT_KEY,
  SEGMENT_KEY,
  TIMEFRAME_KEY,
  VIEWS_COUNT_KEY,
} from './consts/keys';
import dao from './ducks/dao';
import { percentage } from './utils';

export const {
  getItemById,
  getItemPropById,
  isLoadingItem,
  isItemLoaded,
} = dao.selectors;

export const getActivationsCount = getItemPropById(ACTIVATIONS_COUNT_KEY);

export const getClicksCount = getItemPropById(CLICKS_COUNT_KEY);

export const getClicksPercentageByBlackLeveledUsers = getItemPropById(
  CLICKS_PERCENTAGE_BY_BLACK_LEVELED_USERS_KEY,
);

export const getClicksPercentageByOrangeLeveledUsers = getItemPropById(
  CLICKS_PERCENTAGE_BY_ORANGE_LEVELED_USERS_KEY,
);

export const getClicksPercentageBySilverLeveledUsers = getItemPropById(
  CLICKS_PERCENTAGE_BY_SILVER_LEVELED_USERS_KEY,
);

export const getClicksPercentageByWhiteLeveledUsers = getItemPropById(
  CLICKS_PERCENTAGE_BY_WHITE_LEVELED_USERS_KEY,
);

export const getClicksPercentageByJetBlackLeveledUsers = getItemPropById(
  CLICKS_PERCENTAGE_BY_JETBLACK_LEVELED_USERS_KEY,
);

export const getConversionRate = pipe(
  getItemPropById(CONVERSION_KEY),
  divide(__, 100),
);

export const getCoverage = getItemPropById(COVERAGE_KEY);

export const getEffeciency = getItemPropById(EFFICIENCY_KEY);

export const getPrePushClicksCount = getItemPropById(PRE_PUSH_CLICKS_COUNT_KEY);

export const getPushClicksCount = getItemPropById(PUSH_CLICKS_COUNT_KEY);

export const getScrollsCountTo25Percent = getItemPropById(
  SCROLLS_COUNT_TO_25_PERCENT_KEY,
);

export const getScrollsCountTo50Percent = getItemPropById(
  SCROLLS_COUNT_TO_50_PERCENT_KEY,
);

export const getScrollsCountTo75Percent = getItemPropById(
  SCROLLS_COUNT_TO_75_PERCENT_KEY,
);

export const getScrollsCountTo100Percent = getItemPropById(
  SCROLLS_COUNT_TO_100_PERCENT_KEY,
);

export const getSegment = getItemPropById(SEGMENT_KEY);

export const getTimeframe = getItemPropById(TIMEFRAME_KEY);

export const getViewsCount = getItemPropById(VIEWS_COUNT_KEY);

export const getActivationsToPrePushClicksPercentage = percentage(
  getActivationsCount,
  getPrePushClicksCount,
);
export const getActivationsToSegmentPercentage = percentage(
  getActivationsCount,
  getSegment,
);

export const getClicksToCoveragePercentage = percentage(
  getClicksCount,
  getCoverage,
);

export const getPrePushClicksToSegmentPercentage = percentage(
  getPrePushClicksCount,
  getSegment,
);

export const getPrePushClicksToActivationsPercentage = percentage(
  getPrePushClicksCount,
  getActivationsCount,
);

export const getPushClicksToActivationsPercentage = percentage(
  getPushClicksCount,
  getActivationsCount,
);

export const getPushClicksToASegmentPercentage = percentage(
  getPushClicksCount,
  getSegment,
);

export const getViewsToSegmentPercentage = percentage(
  getViewsCount,
  getSegment,
);

export const getTickStartByScale = (state, scale, itemId) =>
  getTimeframe(state, itemId);

export const getTickEndByScale = (state, scale, itemId) => {
  const tickStart = getTickStartByScale(state, scale, itemId);

  if (scale === DAY_SCALE) {
    return tickStart;
  } else if (scale === WEEK_SCALE) {
    return addDays(tickStart, 6);
  } else if (scale === MONTH_SCALE) {
    return subDays(addMonths(tickStart, 1), 1);
  }
};
