import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Children, cloneElement, Component } from 'react';

import MapRentPlace from '@/components/Map/RentPlace';

class RentPlace extends Component {
  static propTypes = {
    highlighted: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
  };

  render() {
    const { children, ...rest } = this.props;
    const shapeProps = this.getShapeProps();

    return (
      <MapRentPlace
        {...omit(['highlighted', 'selected'], rest)}
        {...shapeProps}
      >
        {Children.map(children, child => cloneElement(child, shapeProps))}
      </MapRentPlace>
    );
  }

  getShapeProps = () => {
    const { highlighted, selected } = this.props;

    if (highlighted) {
      return { fill: '#B0B0B0' };
    }

    if (selected) {
      return { fill: '#7EB3F1' };
    }

    return {};
  };
}

export default RentPlace;
