import {
  BIN_KEY,
  COMPANY_KEY,
  FACT_ADDRESS_KEY,
  LEGAL_ADDRESS_KEY,
  MOBILE_PHONE_KEY,
  PARTNERSHIP_AGREEMENT_KEY,
  PARTNERSHIP_START_DATE_KEY,
  PRESIDENT_FULLNAME_KEY,
  RENT_AREA_INFO_KEY,
  WORK_PHONE_KEY,
} from '@esentai/core/features/merchants/consts/keys';
import { Checkbox, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import LinkAsButton from '@/components/Button';
import ButtonSet from '@/components/ButtonSet';
import DatePicker from '@/components/DatePicker';
import FieldRow from '@/components/FieldRow';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';
import FormikPropTypes from '@/prop-types/formik';

const fieldNames = {
  [COMPANY_KEY]: 'Компания',
  [BIN_KEY]: 'БИН/ИИН',
  [PARTNERSHIP_AGREEMENT_KEY]: 'Договор партнерства',
  [PARTNERSHIP_START_DATE_KEY]: 'Дата договора',
  [LEGAL_ADDRESS_KEY]: 'Юридический адрес',
  [FACT_ADDRESS_KEY]: 'Фактический адрес',
  [MOBILE_PHONE_KEY]: 'Сотовый телефон',
  [WORK_PHONE_KEY]: 'Рабочий телефон',
  [PRESIDENT_FULLNAME_KEY]: 'ЛПР/Директор',
  [RENT_AREA_INFO_KEY]: 'Информация об арендуемой площади: кв. м.',
};

class NewMerchantForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      merchantStatus: true,
    };
  }

  render() {
    const {
      classes,
      submitButtonText,
      Field,
      Form,
      RequiredMessages,
      SubmitButton,
      setFieldValue,
    } = this.props;

    const { merchantStatus } = this.state;

    return (
      <Form>
        <FieldRow label="Название компании">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={COMPANY_KEY}
            required
            rows={4}
            label="Название компании"
            helperText="Данное описание будет отображаться на странице арендатора"
          />
        </FieldRow>
        <FieldRow label="БИН/ИИН">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name={BIN_KEY}
            rows={4}
            label="БИН/ИИН"
          />
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name={PARTNERSHIP_AGREEMENT_KEY}
            rows={4}
            label="Договор партнерства"
          />
          <div className={classes.datepicker}>
            <Field
              margin="normal"
              Target={DatePicker}
              clearable
              required
              label="Дата договора"
              name={PARTNERSHIP_START_DATE_KEY}
              className={classes.datepicker}
            />
          </div>
        </FieldRow>
        <FieldRow label="Телефон">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={MOBILE_PHONE_KEY}
            rows={4}
            label="Cотовый"
          />
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={WORK_PHONE_KEY}
            rows={4}
            label="Рабочий"
          />
        </FieldRow>
        <FieldRow label="ЛПР/Директор">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={PRESIDENT_FULLNAME_KEY}
            rows={4}
            label="Лицо принимающее решение/директор"
          />
        </FieldRow>
        <FieldRow label="Информация об арендуемой площади">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={RENT_AREA_INFO_KEY}
            rows={4}
            label="Информация об арендуемой площади: кв. м."
          />
        </FieldRow>
        <FieldRow label="Адрес">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={LEGAL_ADDRESS_KEY}
            rows={4}
            label="Юридический"
          />
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={FACT_ADDRESS_KEY}
            rows={4}
            label="Фактический"
          />
        </FieldRow>

        <FieldRow label="Активен" className={classes.hidden}>
          <Field
            margin="normal"
            Target={Checkbox}
            className={classes.wrap}
            checked={merchantStatus}
            name="merchantStatus"
            rows={4}
            label="Активен"
            onChange={() => {
              setFieldValue('merchantStatus', !merchantStatus);
              this.setState({ merchantStatus: !merchantStatus });
            }}
          />
        </FieldRow>

        <ButtonSet>
          <RequiredMessages fieldNames={fieldNames} />
          <LinkAsButton size="large" href={MERCHANTS_PATH}>
            Назад
          </LinkAsButton>
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            {submitButtonText}
          </SubmitButton>
        </ButtonSet>
      </Form>
    );
  }
}

export default NewMerchantForm;
