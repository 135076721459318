import { RESOURCE_NAME } from '@esentai/core/features/bonusTransfer/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { BONUS_TRANSFER } from './consts/permissions';

export const canTransferBonuses = authorizeIf(
  can(permission(RESOURCE_NAME, BONUS_TRANSFER)),
);
