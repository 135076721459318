import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import UserPhoneLazy from '@/containers/UserPhoneLazy';
import { getCuttedText } from '@/utils/format';

const StoreChip = ({ value, ...props }) => (
  <Chip
    {...props}
    id={value}
    label={
      <UserPhoneLazy storeId={value} onCutText={getCuttedText} maxLength={40} />
    }
  />
);

StoreChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default StoreChip;
