import { toggleSuspiciouty } from '@esentai/core/features/users/actions';
import { isUserSuspicious } from '@esentai/core/features/users/selectors';
import { connect } from 'react-redux';

import SuspiciotyToggler from './component';

const mapStateToProps = (state, { userId }) => ({
  suspicious: isUserSuspicious(state, userId),
});

const mapDispatchToProps = (dispatch, { userId }) => ({
  toggleSuspiciouty: () => dispatch(toggleSuspiciouty(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuspiciotyToggler);
