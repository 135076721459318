import { grey } from '@material-ui/core/colors';

export default theme => ({
  formSection: {
    '&:not(:first-child)': {
      borderTop: [1, 'solid', grey[300]],
      paddingTop: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
    },

    '&:not(:last-child)': {
      minHeight: theme.spacing.unit * 20,
    },
  },

  formSectionName: {
    paddingTop: 5,
  },
});
