import {
  ACCESSORIES,
  ANCHORS,
  CHILDREN,
  FOOD,
  HEALTH_AND_BEAUTY,
  HOME,
  JEWELRY_AND_WATCHES,
  LUXURY,
  PREMIUM,
  RESTAURANTS_AND_CAFE,
  SERVICES,
} from '@esentai/core/features/stores/consts/categories';

import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';
import { storeCategory } from '@/utils/labels';

export const options = [
  LUXURY,
  PREMIUM,
  FOOD,
  RESTAURANTS_AND_CAFE,
  ANCHORS,
  SERVICES,
  ACCESSORIES,
  HOME,
  HEALTH_AND_BEAUTY,
  JEWELRY_AND_WATCHES,
  CHILDREN,
  // ELECTRONICS,
  // ENTERTAINMENT,
  // FINANCIAL_SERVICES,
  // MEN,
  // SHOES,
  // WOMEN,
].map(key => ({ key, label: storeCategory(key) }));

export const createStoreCategoryFilter = createCheckboxFilterTemplate({
  initialValue: ACCESSORIES,
  options,
});

export default CheckboxGroupFilter;
