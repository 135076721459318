import {
  ANSWER_ENGLISH_KEY,
  ANSWER_KAZAKH_KEY,
  ANSWER_RUSSIAN_KEY,
  QUESTION_ENGLISH_KEY,
  QUESTION_KAZAKH_KEY,
  QUESTION_RUSSIAN_KEY,
} from '@esentai/core/features/questions/consts/keys';
import { TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import FormikPropTypes from '@/prop-types/formik';

const fieldNames = {
  [ANSWER_ENGLISH_KEY]: 'ответ на английском',
  [ANSWER_RUSSIAN_KEY]: 'ответ на русском',
  [ANSWER_KAZAKH_KEY]: 'ответ на казахском',

  [QUESTION_ENGLISH_KEY]: 'вопрос на английском',
  [QUESTION_RUSSIAN_KEY]: 'вопрос на русском',
  [QUESTION_KAZAKH_KEY]: 'вопрос на казахском',
};

class NewQuestionForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
  };

  render() {
    const {
      classes,
      submitButtonText,
      Field,
      Form,
      RequiredMessages,
      SubmitButton,
    } = this.props;

    return (
      <Form>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary">
            Вопрос
          </Typography>
          <Field
            Target={TextField}
            id={QUESTION_RUSSIAN_KEY}
            name={QUESTION_RUSSIAN_KEY}
            helperText="На русском"
            required
            fullWidth
          />
          <Field
            Target={TextField}
            id={QUESTION_ENGLISH_KEY}
            name={QUESTION_ENGLISH_KEY}
            helperText="На английском"
            required
            fullWidth
          />
          <Field
            Target={TextField}
            id={QUESTION_KAZAKH_KEY}
            name={QUESTION_KAZAKH_KEY}
            helperText="На казахском"
            required
            fullWidth
          />
        </div>

        <div className={classes.section}>
          <Typography variant="h5" color="secondary">
            Ответ
          </Typography>
          <Field
            Target={TextField}
            id={ANSWER_RUSSIAN_KEY}
            name={ANSWER_RUSSIAN_KEY}
            helperText="На русском"
            required
            fullWidth
          />
          <Field
            Target={TextField}
            id={ANSWER_ENGLISH_KEY}
            name={ANSWER_ENGLISH_KEY}
            helperText="На английском"
            required
            fullWidth
          />
          <Field
            Target={TextField}
            id={ANSWER_KAZAKH_KEY}
            name={ANSWER_KAZAKH_KEY}
            helperText="На казахском"
            required
            fullWidth
          />
        </div>

        <ButtonSet>
          <RequiredMessages fieldNames={fieldNames} />
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            {submitButtonText}
          </SubmitButton>
        </ButtonSet>
      </Form>
    );
  }
}

export default NewQuestionForm;
