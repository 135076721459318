import { ID_KEY } from '@esentai/core/features/bonuses/consts/keys';
import { annotations } from '@esentai/core/features/bonuses/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(ID_KEY),
  }),
  include: string({
    defaultValue: ['user_id', 'commissar_id', 'customer_id', 'receipt_id'],
  }),
  // type: string({
  //   defaultValue: ['REWARD BONUSES', 'LEVEL CHANGED'],
  // }),
});
