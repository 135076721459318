import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { dateTimeFormat } from '@/utils/format';

const Row = props => {
  const {
    staff_full_name,
    record_created,
    amount,
    customer_first_name,
    customer_last_name,
  } = props.item;

  const getCustomerName = (first_name, last_name) => {
    if (!first_name && !last_name) {
      return '-';
    }

    return `${customer_first_name} ${customer_last_name}`;
  };

  return (
    <TableRow>
      <TableCell>{staff_full_name || '-'}</TableCell>
      <TableCell>{dateTimeFormat(record_created)}</TableCell>
      <TableCell>{amount}</TableCell>
      <TableCell>
        {getCustomerName(customer_first_name, customer_last_name)}
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
