import { path } from 'ramda';

import api from '@/api';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

import { ROUTE_PATH } from './consts';

export const getQueryRoot = path([PAGES_STORE_KEY, 'userPage', 'query']);

export const getReceiptsRoot = path([PAGES_STORE_KEY, 'userPage', 'receipts']);

export const getPurchasesRoot = path([
  PAGES_STORE_KEY,
  'userPage',
  'purchases',
]);

export const createUserPageUrl = userId =>
  ROUTE_PATH.replace(':userId', userId);

export const downloadXls = userId => {
  const urlParams = new URLSearchParams(window.location.search);
  const limit = Number(urlParams.get('limit')) || 10;
  const page = Number(urlParams.get('page'));
  const offset = page * limit;

  urlParams.set('limit', limit.toString());
  urlParams.set('offset', offset.toString());
  urlParams.set('for_customer[eq]', 'true');
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'id');
  }

  api
    .doBlobGet(
      `/purchase-history-xlsx-export?customer[eq]=${userId}&${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(response.data, `История покупок пользователя.xlsx`);
    });
};
