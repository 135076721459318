import { createQuery } from '@esentai/core/queries';
import { mutablyDefaultQueryTo } from 'awokado/structs/query';
import { compose, filter, isNil, map, not, pipe, prop, toPairs } from 'ramda';

export default annotations => next => {
  const optionsProp = prop('options');
  const defaultValueProp = compose(prop('defaultValue'), optionsProp);
  const hasDefaultValue = compose(not, isNil, defaultValueProp);
  const buildPredicates = pipe(
    filter(hasDefaultValue),
    map(defaultValueProp),
    toPairs,
  );
  const defaultQuery = createQuery(...buildPredicates(annotations));

  const parse = (urlSearchParams, parsedParams) => {
    mutablyDefaultQueryTo(defaultQuery, parsedParams);

    return next.parse(urlSearchParams, parsedParams);
  };

  return { parse, serialize: next.serialize };
};
