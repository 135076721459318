import { createAction } from 'redux-actions';

import query from './ducks/query';
import {
  OPEN_RENT_PLACE,
  RETRY_FETCH,
  UPDATE_FLOOR,
  UPDATE_STAT,
} from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const openRentPlace = createAction(OPEN_RENT_PLACE);

export const retryFetch = createAction(RETRY_FETCH);

export const updateFloor = createAction(UPDATE_FLOOR);

export const updateStat = createAction(UPDATE_STAT);
