import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getUserStores } from '@/features/currentUser/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import StoreAdminForm from './component';
import form from './form';

const mapStateToProps = state => ({
  storeIds: getUserStores(state),
});

export default compose(
  connect(mapStateToProps),
  withAsyncFormik(form),
)(StoreAdminForm);
