// @flow
import { cancel, fork, take } from 'redux-saga/effects';

export const createRunnerBetween = (startActionType, endActionType, saga) =>
  function* runnerBetween() {
    while (true) {
      yield take(startActionType);

      const taskId = yield fork(saga);

      yield take(endActionType);
      yield cancel(taskId);
    }
  };

export const runBetween = (...args) => fork(createRunnerBetween(...args));
