export default theme => ({
  paper: {
    maxHeight: theme.spacing.unit * 40,
    overflowY: 'auto',
  },

  popper: {
    margin: [theme.spacing.unit, 0],
    zIndex: theme.zIndex.drawer,
    position: 'absolute',
  },
});
