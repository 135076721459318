import createAction from 'redux-actions/es/createAction';

import query from './ducks/query';
import { FETCH_DETAILS } from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const fetchDetails = createAction(FETCH_DETAILS);
