import { RESOURCE_NAME } from '@esentai/core/features/guestEfs/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { GUEST_EFS } from './consts/permissions';

export const canGuestEfs = authorizeIf(
  can(permission(RESOURCE_NAME, GUEST_EFS)),
);
