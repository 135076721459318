import { VIDEO_VIMEO_URL_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import FieldDescription from '@/components/FieldDescription';

import { getName } from '../utils';

class VideoBlock extends Component {
  static propTypes = {
    Field: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  render() {
    const { index, Field, disabled } = this.props;

    return (
      <Fragment>
        <FieldDescription desc="Тип блока «Видеоролик» позволяет вставить видео, размещенное на хостинге Vimeo. Видео должно быть открыто для просмотра всем, у кого есть ссылка, с возможность просматривать при интеграции в страницы. Либо должно быть публичным." />

        <Field
          disabled={disabled}
          Target={TextField}
          name={getName(index, VIDEO_VIMEO_URL_KEY)}
          label="Ссылка на видеоролик"
          helperText="Видеоролик должен быть загружен на хостинг Vimeo. Сюда вам необходимо вставить прямую ссылку на видео файл. Как это сделать, вы можете прочитать здесь."
          fullWidth
        />
      </Fragment>
    );
  }
}

export default VideoBlock;
