import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { compose } from 'ramda';
import { spawn, takeEvery } from 'redux-saga/effects';

import { LOGIN_PATH } from '@/features/auth/consts';
import { resetPassword } from '@/features/passwordRecovery/sagas';
import redirectSaga from '@/redux/router/redirectSaga';

import { RESET_PASSWORD_ACTION } from './consts';

export const watchResetPassword = function*() {
  yield takeEvery(
    RESET_PASSWORD_ACTION,
    compose(createTaskSaga, redirectSaga)(resetPassword, LOGIN_PATH),
  );
};

export default function*() {
  yield spawn(watchResetPassword);
}
