import { UPDATE_PARKING_WHITELIST_FILTER } from '@esentai/core/features/parkingWhitelistFilter/types';
import { push } from 'connected-react-router';
import { put, spawn, takeEvery } from 'redux-saga/effects';

import { ROUTE_PATH as WHITELIST_PATH } from '@/features/parkingWhitelistPage/consts';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { RETRY_FETCH } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export function* redirect() {
  yield put(push(WHITELIST_PATH));
}

export function* watchUpdate() {
  yield takeEvery(UPDATE_PARKING_WHITELIST_FILTER, redirect);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(watchUpdate);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
