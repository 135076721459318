import { includes } from 'ramda';

import { campaignsWithInfluence } from '../consts';

export default {
  page: {
    marginTop: 24,
    paddingBottom: 0,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flex: ({ campaignType }) =>
      includes(campaignType, campaignsWithInfluence) ? '1 0 auto' : null,
  },
};
