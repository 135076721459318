import {
  getBinForMerchant,
  getCompanyForMerchant,
  getFactAddressForMerchant,
  getLegalAddressForMerchant,
  getMobilePhoneForMerchant,
  getPresidentFullNameForMerchant,
  getRentAreaInfoForMerchant,
  getStatusForMerchant,
  getWorkPhoneForMerchant,
} from '@esentai/core/features/merchants/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { createMerchantPageUrl } from '@/features/merchantPage/utils';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: merchantId }) => ({
  status: getStatusForMerchant(state, merchantId),
  legalAddress: getLegalAddressForMerchant(state, merchantId),
  factAddress: getFactAddressForMerchant(state, merchantId),
  mobilePhone: getMobilePhoneForMerchant(state, merchantId),
  workPhone: getWorkPhoneForMerchant(state, merchantId),
  rentAreaInfo: getRentAreaInfoForMerchant(state, merchantId),
  presidentFullName: getPresidentFullNameForMerchant(state, merchantId),
  bin: getBinForMerchant(state, merchantId),
  company: getCompanyForMerchant(state, merchantId),
  merchantId,
  merchantPageUrl: createMerchantPageUrl(merchantId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
