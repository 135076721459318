export default {
  selectMenu: {
    fontSize: 24,
    textDecoration: 'underline',
  },

  icon: {
    top: `calc(50% - 15px)`,
    right: 5,
  },
};
