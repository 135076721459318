import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import { formatMoney, formatNumber } from '@/utils/format';

const CommonCells = ({ averageTicket, children, purchasesCount }) => (
  <Fragment>
    <CondenseTableCell align="right">
      {formatNumber(purchasesCount)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatMoney(averageTicket)}
    </CondenseTableCell>
    {children}
  </Fragment>
);

CommonCells.propTypes = {
  averageTicket: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  purchasesCount: PropTypes.number.isRequired,
};

export default CommonCells;
