import {
  FLOOR_KEY,
  MAJOR_ID_KEY,
  MINOR_ID_KEY,
  NAME_KEY,
} from '@esentai/core/features/beacons/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <TableCell padding="none" />
    <CondenseTableCell>
      <SortControl field={NAME_KEY}>Название</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={MAJOR_ID_KEY}>Major ID</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={MINOR_ID_KEY}>Minor ID</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={FLOOR_KEY}>Этаж</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>Локации</CondenseTableCell>
    <CondenseTableCell>Действия</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
