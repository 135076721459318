import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { updateSettings } from '@esentai/core/features/applicationSettings/sagas';
import { call, takeLatest } from 'redux-saga/effects';

import { UPDATE_FEED_LAYOUT } from './types';

export function* updateFeedLayout(action) {
  const { payload: attributes } = action;

  yield call(updateSettings, attributes);
}

export default function*() {
  yield takeLatest(UPDATE_FEED_LAYOUT, createTaskSaga(updateFeedLayout));
}
