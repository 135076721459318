import purchasesDuck from './ducks/purchases';
import queryDuck from './ducks/query';

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;

export const {
  getError: getPurchasesError,
  getItemsIds: getPurchasesIds,
  getItemsMeta: getPurchasesMeta,
  getItemsTotalCount: getPurchasesTotalCount,
  isLoadingItems: isLoadingPurchases,
} = purchasesDuck.selectors;
