import { findParkingWhitelistGroupById } from '@esentai/core/features/parkingWhitelistGroups/actions';
import {
  getNameForParkingWhitelistGroup,
  getUsersForParkingWhitelistGroup,
  isParkingWhitelistGroupLoaded,
  isParkingWhitelistGroupLoading,
} from '@esentai/core/features/parkingWhitelistGroups/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/parkingWhitelistGroupDetailsPage/ducks/query';
import withItemApiRunner from '@/hocs/withItemApiRunner';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getGroupId,
  getParkingWhitelistError,
  getParkingWhitelistIds,
  getParkingWhitelistTotalCount,
  isLoadingParkingWhitelist,
} from '../selectors';
import WhitelistGroupDetailsPage from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getGroupId(state);

  return {
    error: getParkingWhitelistError(state),
    parkingWhitelistIds: getParkingWhitelistIds(state),
    isLoading: isLoadingParkingWhitelist(state),
    totalCount: getParkingWhitelistTotalCount(state),
    isLoaded: isParkingWhitelistGroupLoaded(state, itemId),
    groupIsLoading: isParkingWhitelistGroupLoading(state, itemId),
    itemId,
    name: getNameForParkingWhitelistGroup(state, itemId),
    userIds: getUsersForParkingWhitelistGroup(state, itemId),
  };
};

const mapDispatchToProps = { fetchItem: findParkingWhitelistGroupById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
  withStyles(styles),
  withQuerySync(queryDuck),
)(WhitelistGroupDetailsPage);
