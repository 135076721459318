import productsApi from '@esentai/core/features/products/api';
import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import CanView from '@/components/CanView';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PageTitleLink from '@/components/PageTitleLink';
import PaperSheet from '@/components/PaperSheet';
import SortableGallery from '@/components/SortableGallery';
import { StoreAutocomplete } from '@/containers/StoreAutocomplete';
import { ROUTE_PATH as PRODUCTS_PATH } from '@/features/productsPage/consts';

import { PATCH_URL } from '../consts';
import NotFoundIcon from './notFoundIcon.svg';

const ProductsArrangement = ({ classes }) => {
  const [storeId, setStore] = useState(null);
  const [productsByStore, setProducts] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const maxCheckboxCount = 2;

  const onChangeStore = curStoreId => {
    setStore(curStoreId);
    if (curStoreId) {
      setIsVisible(true);
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const onSetProducts = products => {
    setProducts(
      products.map((cur, idx) => {
        cur.index = idx + 1;
        cur.src = cur.images.length > 0 ? cur.images[0] : '';
        cur.width = 200;
        cur.height = 200;
        cur.checked = false;

        return cur;
      }),
    );
  };

  const getCheckedProducts = () =>
    productsByStore.filter(cur => cur.checked === true);

  const checkItem = (curProduct, checkedProducts) => {
    curProduct.checked = !curProduct.checked;
    setProducts(checkedProducts);
  };

  const setCheckedItem = itemId => {
    const tempProducts = [...productsByStore];
    const checkedProducts = getCheckedProducts();
    let curItem = productsByStore.find(res => res.id === itemId);

    if (checkedProducts.length < maxCheckboxCount) {
      if (curItem) {
        checkItem(curItem, tempProducts);
      }
    } else if (checkedProducts.length === maxCheckboxCount) {
      curItem = checkedProducts.find(res => res.id === itemId);
      if (curItem) {
        checkItem(curItem, tempProducts);
      }
    }
  };

  const filters = [['store_id', 'eq', storeId]];

  filters.push(['visible', 'eq', true]);
  const fetchProductsByStore = async () => {
    const data = await productsApi.doQuery(filters, 'index', 'asc', 9999);

    if (data.payload.product && data.payload.product.length > 0) {
      onSetProducts(data.payload.product);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    if (storeId && productsByStore.length === 0) {
      fetchProductsByStore();
    } else {
      onSetProducts([]);
      setIsVisible(false);
    }
  }, [storeId]);

  const saveNewArrangement = () => {
    setIsVisible(true);
    const data = {};

    data.product = productsByStore.map(cur => {
      const temp = {};

      temp.id = cur.id;
      temp.index = cur.index;

      return temp;
    });
    api.doPatch(PATCH_URL + storeId, data).then(() => {
      fetchProductsByStore();
    });
  };

  const swapItems = () => {
    const checkedProducts = productsByStore.filter(cur => cur.checked === true);
    const tempIdx1 = checkedProducts[0].index;
    const tempIdx2 = checkedProducts[1].index;

    const tempList = [...productsByStore];

    tempList[tempIdx1 - 1].index = tempIdx2;
    tempList[tempIdx2 - 1].index = tempIdx1;
    [tempList[tempIdx1 - 1], tempList[tempIdx2 - 1]] = [
      tempList[tempIdx2 - 1],
      tempList[tempIdx1 - 1],
    ];

    setProducts(tempList);
  };

  return (
    <Page>
      <PageHeader borderBottom gutterBottom={false} xl={8}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle>Компоновка товаров</PageTitle>
          <PageTitleLink to={PRODUCTS_PATH}>Товары</PageTitleLink>
        </Grid>
      </PageHeader>
      <PageContent xl={8}>
        <PaperSheet>
          <div className={classes.store}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ alignSelf: 'center', marginRight: 15 }}
            >
              Магазин
            </Typography>
            <StoreAutocomplete
              onChange={onChangeStore}
              selectedItem={storeId || null}
              InputProps={{
                placeholder: 'Выберите магазин',
                error: 'Ошибка',
              }}
            />
          </div>
          <div className={classes.saveButton}>
            <Button
              variant="outlined"
              color="secondary"
              disabled={buttonDisabled || getCheckedProducts().length < 2}
              onClick={() => swapItems()}
            >
              Поменять местами
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={buttonDisabled}
              onClick={() => saveNewArrangement()}
              style={{ marginTop: 15 }}
            >
              Сохранить
            </Button>
          </div>
          {!isVisible && productsByStore && productsByStore.length > 0 && (
            <SortableGallery
              items={productsByStore}
              setItems={onSetProducts}
              setCheckedItem={setCheckedItem}
              axis={'xy'}
            />
          )}
          <div className={classes.spinner}>
            <CanView isVisible={isVisible}>
              <CircularProgress />
            </CanView>
            <CanView isVisible={productsByStore.length === 0}>
              <NotFoundIcon className={classes.repairIcon} />
              <Typography
                variant="h4"
                color="textSecondary"
                className={classes.title}
              >
                Ничего не найдено
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                По вашему запросу ничего не найдено, пожалуйста измените
                критерии поиска
              </Typography>
            </CanView>
          </div>
        </PaperSheet>
      </PageContent>
    </Page>
  );
};

export default ProductsArrangement;
