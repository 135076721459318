import {
  getIndexForGiftSet,
  getPriceForGiftSet,
  getSkuForGiftSet,
  getSubtitleRuForGiftSet,
  getTitleRuForGiftSet,
} from '@esentai/core/features/giftSets/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { createGiftSetPageUrl } from '@/features/giftSetPage/utils';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: giftSetId }) => ({
  index: getIndexForGiftSet(state, giftSetId),
  titleRu: getTitleRuForGiftSet(state, giftSetId),
  subtitleRu: getSubtitleRuForGiftSet(state, giftSetId),
  sku: getSkuForGiftSet(state, giftSetId),
  price: getPriceForGiftSet(state, giftSetId),
  giftSetId,
  giftSetPageUrl: createGiftSetPageUrl(giftSetId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
