import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  EXTERNAL_PROMO_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/banner/consts/destinations';
import {
  BANNER_POPUP_DELAY_KEY,
  DESTINATION_KEY,
  ENGLISH_TITLE_KEY,
  IMAGE_URL_KEY,
  KAZAKH_TITLE_KEY,
  PLACEMENT_KEY,
  PRIORITY_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_EXTERNAL_PROMO_ID_KEY,
  REFERRED_STORE_ID_KEY,
  RUSSIAN_TITLE_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/banner/consts/keys';
import { MAIN_FEED } from '@esentai/core/features/campaigns/banner/consts/popupPlacementTypes';
import {
  MAIN_PAGE_TYPE,
  POPUP_TYPE,
  STORE_LIST_TYPE,
} from '@esentai/core/features/campaigns/banner/consts/types';
import { VERY_LOW_PRIORITY } from '@esentai/core/features/campaigns/consts/priorities';
import { __, includes } from 'ramda';
import { number, object, string } from 'yup';

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({
    articleId,
    benefitId,
    calendarEventId,
    externalPromoId,
    destination = null,
    russianTitle = '',
    englishTitle = '',
    kazakhTitle = '',
    imageUrl = null,
    placement,
    popupDelay,
    priority,
    storeId = null,
    type,
  }) => ({
    [BANNER_POPUP_DELAY_KEY]: popupDelay || 0,
    [DESTINATION_KEY]: destination,
    [ENGLISH_TITLE_KEY]: englishTitle || '',
    [IMAGE_URL_KEY]: imageUrl,
    [PLACEMENT_KEY]: placement || MAIN_FEED,
    [PRIORITY_KEY]: priority || VERY_LOW_PRIORITY,
    [RUSSIAN_TITLE_KEY]: russianTitle || '',
    [KAZAKH_TITLE_KEY]: kazakhTitle || '',
    [REFERRED_ARTICLE_ID_KEY]: articleId,
    [REFERRED_BENEFIT_ID_KEY]: benefitId,
    [REFERRED_EXTERNAL_PROMO_ID_KEY]: externalPromoId,
    [REFERRED_CALENDAR_EVENT_ID_KEY]: calendarEventId,
    [REFERRED_STORE_ID_KEY]: storeId,
    [TYPE_KEY]: type || MAIN_PAGE_TYPE,
  }),

  validationSchema: object().shape({
    [DESTINATION_KEY]: string().nullable(),
    [IMAGE_URL_KEY]: string()
      .url()
      .required(),
    [BANNER_POPUP_DELAY_KEY]: number().when(TYPE_KEY, {
      is: POPUP_TYPE,
      then: number()
        .min(0, 'Задержка не может быть меньше 0')
        .integer(),
    }),
    [PRIORITY_KEY]: number().when(TYPE_KEY, {
      is: [MAIN_PAGE_TYPE, STORE_LIST_TYPE],
      then: number().required(),
    }),
    [RUSSIAN_TITLE_KEY]: string().when(TYPE_KEY, {
      is: includes(__, [MAIN_PAGE_TYPE, POPUP_TYPE]),
      then: string().max(50),
      otherwise: string().nullable(),
    }),
    [ENGLISH_TITLE_KEY]: string().when(TYPE_KEY, {
      is: includes(__, [MAIN_PAGE_TYPE, POPUP_TYPE]),
      then: string().max(50),
      otherwise: string().nullable(),
    }),
    [KAZAKH_TITLE_KEY]: string().when(TYPE_KEY, {
      is: includes(__, [MAIN_PAGE_TYPE, POPUP_TYPE]),
      then: string().max(50),
      otherwise: string().nullable(),
    }),
    [REFERRED_ARTICLE_ID_KEY]: string().when(DESTINATION_KEY, {
      is: ARTICLE_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [REFERRED_EXTERNAL_PROMO_ID_KEY]: string().when(DESTINATION_KEY, {
      is: EXTERNAL_PROMO_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [REFERRED_BENEFIT_ID_KEY]: string().when(DESTINATION_KEY, {
      is: BENEFIT_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [REFERRED_CALENDAR_EVENT_ID_KEY]: string().when(DESTINATION_KEY, {
      is: CALENDAR_EVENT_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [REFERRED_STORE_ID_KEY]: string().when(DESTINATION_KEY, {
      is: STORE_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [TYPE_KEY]: string().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
