import {
  AMOUNT_KEY,
  CUSTOMER_ID_KEY,
  ID_KEY,
  RECORD_CREATED_KEY,
} from '@esentai/core/features/bonus_transactions/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={ID_KEY}>ID</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={CUSTOMER_ID_KEY}>ID пользователя</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Пользователь</CondenseTableCell>

    {/* <CondenseTableCell>*/}
    {/*  <SortControl field={USER_ID_KEY}>Менеджер молла</SortControl>*/}
    {/* </CondenseTableCell>*/}

    {/* <CondenseTableCell>*/}
    {/*  <SortControl field={COMMISSAR_ID_KEY}>Комплаенс Менеджер</SortControl>*/}
    {/* </CondenseTableCell>*/}

    {/* <CondenseTableCell>*/}
    {/*  <SortControl field={TYPE_KEY}>ТИП</SortControl>*/}
    {/* </CondenseTableCell>*/}

    <CondenseTableCell>
      <SortControl field={AMOUNT_KEY}>Кол-во сгоревших бонусов</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={RECORD_CREATED_KEY}>
        Дата изменения уровня
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Статус</CondenseTableCell>

    {/* <CondenseTableCell>Действия</CondenseTableCell>*/}
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
