import PropTypes from 'prop-types';
import React from 'react';

import BaseBulkActionsRow from '@/components/BulkActionsRow';
import ActionItem from '@/components/BulkActionsRow/ActionItem';

const BulkActionsRow = ({
  activateStores,
  disableStores,
  isStoresActive,
  isStoresInactive,
  ...props
}) => (
  <BaseBulkActionsRow {...props}>
    {isStoresInactive && (
      <ActionItem onClick={activateStores}>
        Активировать выбранные магазины
      </ActionItem>
    )}

    {isStoresActive && (
      <ActionItem onClick={disableStores}>
        Остановить выбранные магазины
      </ActionItem>
    )}
  </BaseBulkActionsRow>
);

BulkActionsRow.propTypes = {
  activateStores: PropTypes.func.isRequired,
  disableStores: PropTypes.func.isRequired,
  isStoresActive: PropTypes.bool.isRequired,
  isStoresInactive: PropTypes.bool.isRequired,
};

export default BulkActionsRow;
