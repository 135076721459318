import { path } from 'ramda';

import api from '@/api';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

import { ARCHIVE_ROUTE_PATH, FEATURE_NAME, ROUTE_PATH } from './consts';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const createReceiptsPageUrl = storeId =>
  `${ROUTE_PATH.replace(':store_id', storeId)}`;

export const createArchiveReceiptsPageUrl = (archiveId, storeId) =>
  `${ARCHIVE_ROUTE_PATH.replace(':reconciliation_id', archiveId).replace(
    ':store_id',
    storeId,
  )}`;

export const downloadXls = storeId => {
  const urlParams = new URLSearchParams(window.location.search);
  const limit = Number(urlParams.get('limit')) || 10;
  const page = Number(urlParams.get('page'));
  const offset = page * limit;

  urlParams.set('limit', limit.toString());
  urlParams.set('offset', offset.toString());
  urlParams.set('store_id[eq]', storeId);
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'bonus_cost');
  }

  api
    .doBlobGet(
      `/reconciliation-receipts-xlsx-export?${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(response.data, `Сумма потраченных бонусов магазина.xlsx`);
    });
};
