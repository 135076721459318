import {
  HIGH_PRIORITY,
  LOW_PRIORITY,
  MEDIUM_PRIORITY,
  VERY_HIGH_PRIORITY,
  VERY_LOW_PRIORITY,
} from '@esentai/core/features/campaigns/consts/priorities';
import { MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const priorities = [
  {
    key: VERY_LOW_PRIORITY,
    label: 'Очень низкий',
  },
  {
    key: LOW_PRIORITY,
    label: 'Низкий',
  },
  {
    key: MEDIUM_PRIORITY,
    label: 'Средний',
  },
  {
    key: HIGH_PRIORITY,
    label: 'Высокий',
  },
  {
    key: VERY_HIGH_PRIORITY,
    label: 'Очень высокий',
  },
];

class PriorityControl extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
  };

  render() {
    const { classes, onChange, value, ...rest } = this.props;

    return (
      <div className={classes.root}>
        <Select {...rest} value={value} onChange={onChange}>
          {priorities.map(priority => (
            <MenuItem key={priority.key} value={priority.key} dense>
              {priority.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
}

export default PriorityControl;
