export const ROUTE_PATH = '/merchants-archive-reconciliations';

export const FEATURE_NAME = 'merchantsReconciliationArchivePage';

export const POST_URL = '/reconciliation/';

export const PATCH_URL = '/merchant-reconciliation-payment/';

export const GET_URL = '/merchant-reconciliation-history/';

export const REPAY_CONFIRMATION_MSG =
  'Вы уверены, что хотите завершить данную реконсиляцию?';
