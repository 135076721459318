import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import Label from '@/features/campaignPage/Page/Label';
import labelFormatter from '@/utils/label-formatter';

export const seconds = count =>
  labelFormatter(count, {
    one: 'секунду',
    few: 'секунды',
    many: 'секунд',
  });

const Delay = ({ delay }) => (
  <FieldRow label="Задержка">
    <Label title="После выполнения триггеров кампании">
      {delay
        ? `Кампания сработает через ${seconds(delay)}`
        : 'Кампания сработает сразу'}
    </Label>
  </FieldRow>
);

Delay.propTypes = {
  delay: PropTypes.number,
};

Delay.defaultProps = {
  delay: null,
};

export default Delay;
