export default theme => ({
  root: {
    fontSize: 20,
    lineHeight: 1.2,

    [theme.breakpoints.down('lg')]: {
      fontSize: '0.875rem',
    },
  },
});
