import { BONUSES_COST_KEY } from '@esentai/core/features/merchantsReconciliationRefunds/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <TableCell>Арендатор</TableCell>
    <TableCell>UUID</TableCell>
    <CondenseTableCell>
      <SortControl field={BONUSES_COST_KEY}>
        Сумма бонусов оплаченные по чеку
      </SortControl>
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
