import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { number, object, string } from 'yup';

import styles from '@/features/authPages/styles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import AddBonusesForm from './component';

const errorsMapping = {};
const formikOptions = {
  // eslint-disable-next-line camelcase
  mapPropsToValues: ({ bonuses, comment, commissar_id }) => ({
    bonuses,
    comment,
    commissar_id,
  }),

  validationSchema: object().shape({
    bonuses: number()
      .integer('Сумма должна быть целым числом')
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? NaN : value,
      )
      .positive('Сумма должна быть больше нуля')
      .max(999999, 'Значение бонусов не может быть больше 999999')
      .required(),
    comment: string()
      .min(10, 'Комментарий должен быть не менее 10 символов')
      .max(200, 'Комментарий должен быть не более 200 символов')
      .required(),
    commissar_id: number().required(),
  }),
  // eslint-disable-next-line camelcase
  handleSubmit({ bonuses, comment, commissar_id }, { props, setSubmitting }) {
    props.onSubmit({ bonuses, comment, commissar_id });
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(AddBonusesForm);
