import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';
import { createFormatter } from '@/filters/RadioGroup/utils';

import { ANONYMOUS, PERSONALIZED } from './consts';
import { createAnonymityAdapter } from './utils';

export const options = [
  {
    key: ANONYMOUS,
    label: 'Анонимные',
  },
  {
    key: PERSONALIZED,
    label: 'Неанонимные',
  },
];

export const createPurchaseAnonymityFilter = createRadioFilterTemplate({
  options,
  FormatComponent: createFormatter(options),
  adapter: createAnonymityAdapter(ANONYMOUS),
});

export default RadioGroupFilter;
