import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TopBar from '@/components/TopBar';
import { DATE_RANGE_KEY } from '@/features/usersStatistics/consts/filterKeys';
import { createDateRangeFilter } from '@/filters/DateRange';
import {
  createPageFilterUpdate,
  readPageFilterValue,
} from '@/structs/pageFilter';

import DateRangeSelect from './DateRangeSelect';
import Select from './Select';
import StatSelect from './StatSelect';

const dateRangeFilter = createDateRangeFilter({
  key: DATE_RANGE_KEY,
  label: '?',
});

class StatisticsTopBar extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    updateFilters: PropTypes.func.isRequired,
    entityId: PropTypes.any.isRequired,
    entities: PropTypes.arrayOf(PropTypes.object).isRequired,
    setEntityId: PropTypes.func.isRequired,
    statsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    setStatsIds: PropTypes.func.isRequired,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    minDate: PropTypes.instanceOf(Date).isRequired,
    InnerTopBarComponent: PropTypes.func,
  };

  static defaultProps = {
    InnerTopBarComponent: TopBar,
  };

  render() {
    const { InnerTopBarComponent, ...rest } = this.props;

    return (
      <InnerTopBarComponent
        LeftComponent={this.renderEntitySelect}
        CenterComponent={this.renderStatSelect}
        RightComponent={this.renderDateRangeSelect}
        {...rest}
      />
    );
  }

  getDateRange = () => {
    const { filters } = this.props;

    return readPageFilterValue(dateRangeFilter, filters.get(DATE_RANGE_KEY));
  };

  setDateRange = newDateRange => {
    const { updateFilters } = this.props;

    updateFilters(createPageFilterUpdate(dateRangeFilter, newDateRange));
  };

  renderEntitySelect = () => {
    const { entityId, entities, setEntityId } = this.props;

    return <Select value={entityId} items={entities} onChange={setEntityId} />;
  };

  renderStatSelect = ({ stats }) => {
    const { statsIds, setStatsIds } = this.props;

    return (
      <StatSelect statsIds={statsIds} stats={stats} setStatsIds={setStatsIds} />
    );
  };

  renderDateRangeSelect = () => {
    const { maxDate, minDate } = this.props;
    const dateRange = this.getDateRange();

    return (
      <DateRangeSelect
        minDate={minDate}
        maxDate={maxDate}
        onChange={this.setDateRange}
        value={dateRange}
      />
    );
  };
}

export default StatisticsTopBar;
