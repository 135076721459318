import {
  FILENAME_KEY,
  RECORD_CREATED_KEY,
} from '@esentai/core/features/mallStats/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={FILENAME_KEY}>Название документа</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={RECORD_CREATED_KEY}>
        Дата создания и время создания отчета
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Действия</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
