import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import BrandTitleLazy from '@/containers/BrandTitleLazy';

const BrandChip = ({ value, ...props }) => (
  <Chip {...props} id={value} label={<BrandTitleLazy itemId={value} />} />
);

BrandChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default BrandChip;
