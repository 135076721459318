import { getBarComponent } from './utils';

const DayBar = getBarComponent({
  styles: {
    bar: {
      backgroundColor: '#bfbfbf',
    },
  },
});

export default DayBar;
