import { shouldActivateOnce } from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { connect } from 'react-redux';

import Repetition from './component';

const mapStateToProps = (state, { itemId }) => ({
  activateOnce: shouldActivateOnce(state, itemId),
});

export default connect(mapStateToProps)(Repetition);
