import { findParkingTowerStopListById } from '@esentai/core/features/parkingTowerStopList/actions';
import {
  getLastNameForParkingTowerStopList,
  isParkingTowerStopListLoaded,
  isParkingTowerStopListLoading,
} from '@esentai/core/features/parkingTowerStopList/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import ParkingTowerStopListAddCell from './component';

const mapStateToProps = (state, { itemId, isLoaded }) => ({
  isLoaded: !isLoaded
    ? isParkingTowerStopListLoaded(state, itemId)
    : isLoaded(state, itemId),
  isLoading: isParkingTowerStopListLoading(state, itemId),
  itemId,
  name: getLastNameForParkingTowerStopList(state, itemId),
});

const mapDispatchToProps = { fetchItem: findParkingTowerStopListById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(ParkingTowerStopListAddCell);
