import { findGiftSetByById } from '@esentai/core/features/giftSets/actions';
import {
  getGiftSetLoadingError,
  isGiftSetLoaded,
  isGiftSetLoading,
} from '@esentai/core/features/giftSets/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import { updateGiftSet } from '@/features/giftSetEditPage/actions';
import { fetchGiftSet as fetchItem } from '@/features/giftSetPage/actions';
import { getRouterParameterByMatch } from '@/redux/router/selectors';

import component from './component';

const ID_KEY = 'giftSetId';

const mapStateToProps = (state, { match }) => {
  const giftSetId = getRouterParameterByMatch(match, ID_KEY);

  return {
    error: getGiftSetLoadingError(state, giftSetId),
    isLoaded: isGiftSetLoaded(state, giftSetId),
    isLoading: isGiftSetLoading(state, giftSetId),
    giftSetId,
  };
};

const mapDispatchToProps = (dispatch, { match }) => ({
  updateGiftSet: values =>
    dispatch(
      updateGiftSet({
        ...values,
        giftSetId: getRouterParameterByMatch(match, ID_KEY),
      }),
    ),
  fetchItem,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    fetchItem: findGiftSetByById,
    getItemLoadingError: getGiftSetLoadingError,
    isItemLoaded: isGiftSetLoaded,
    isItemLoading: isGiftSetLoading,
  }),
)(component);
