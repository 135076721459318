import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from './DeleteButton';

const Actions = ({ classes, parkingWhitelistId }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <DeleteButton parkingWhitelistId={parkingWhitelistId} />
  </Grid>
);

Actions.propTypes = {
  parkingWhitelistId: PropTypes.number.isRequired,
};

export default Actions;
