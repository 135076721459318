import createDao from '@esentai/core/ducks/dao';

import api from '../api';
import { RESOURCE_NAME } from '../consts';
import { getDaoRoot, getIdProp } from '../utils';

export default createDao(RESOURCE_NAME, {
  api,
  getRoot: getDaoRoot,
  idProp: getIdProp,
});
