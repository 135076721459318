export default {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  centerColumn: {
    flex: 1,
  },

  leftColumn: {
    marginRight: 20,
  },
};
