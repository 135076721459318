import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { TURN_OFF_STRATEGY, TURN_ON_STRATEGY } from './consts';

export const turnOnStrategy = {
  name: TURN_ON_STRATEGY,
  type: 'text',
  Icon: VisibilityOff,
};

export const turnOffStrategy = {
  name: TURN_OFF_STRATEGY,
  type: 'password',
  Icon: Visibility,
};

const strategies = [turnOnStrategy, turnOffStrategy];

export const propTypes = PropTypes.oneOf(strategies);

export default strategies;
