import api from '@esentai/core/features/benefits/api';
import {
  CAMPAIGN_ID_KEY,
  TITLE_KEY,
  USER_LEVEL_KEY,
} from '@esentai/core/features/benefits/consts/keys';
import { equals, ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import BenefitChip from './BenefitChip';
import BenefitOption from './BenefitOption';

const buildQuery = queryString =>
  query(
    where(TITLE_KEY, ilike(queryString)),
    where(CAMPAIGN_ID_KEY, equals(null)),
    where(USER_LEVEL_KEY, equals(null)),
  );

const withBenefitProps = defaultProps({
  api,
  buildQuery,
  Chip: BenefitChip,
  Option: BenefitOption,
});

export const BenefitAutocomplete = withBenefitProps(AutocompleteAPI);

export const BenefitMultiAutocomplete = withBenefitProps(
  AutocompleteAPIMultiple,
);
