import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import FiltersRow from '@/components/FiltersRow';
import { removeFilters, updateFilters } from '@/features/merchantsPage/actions';
import { filterTypes } from '@/features/merchantsPage/filters';
import { getFilters } from '@/features/merchantsPage/selectors';

const mapStateToProps = state => ({
  filters: getFilters(state),
});

const mapDispatchToProps = { removeFilters, updateFilters };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({ filterTypes }),
)(FiltersRow);
