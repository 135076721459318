import {
  AVERAGE_CHECK_KEY,
  AVERAGE_TIME_SPENT,
  BUYERS_COUNT_KEY,
  CONVERSION_RATE_KEY,
  PURCHASES_COUNT_KEY,
  UNIQUE_LOCATION_LEADS_COUNT,
  USERS_COUNT_KEY,
  VISITORS_COUNT_KEY,
} from '@/features/usersStatistics/consts/keys';
import {
  formatConversion,
  formatMoney,
  formatNumber,
  formatSeconds,
} from '@/utils/format';
import pluralize from '@/utils/pluralize';

export default [
  {
    key: USERS_COUNT_KEY,
    url_key: 'all',
    label: 'Все пользователи',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'пользователь',
        few: 'пользователя',
        many: 'пользователей',
      }),
  },
  {
    key: UNIQUE_LOCATION_LEADS_COUNT,
    url_key: 'leads',
    label: 'Лиды',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'лид',
        few: 'лида',
        many: 'лидов',
      }),
  },
  {
    key: VISITORS_COUNT_KEY,
    url_key: 'visitors',
    label: 'Посетители',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'посетитель',
        few: 'посетителя',
        many: 'посетителей',
      }),
  },
  {
    key: BUYERS_COUNT_KEY,
    url_key: 'buyers',
    label: 'Покупатели',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'покупатель',
        few: 'покупателя',
        many: 'покупателей',
      }),
  },
  {
    key: CONVERSION_RATE_KEY,
    url_key: 'conversion',
    label: 'Конверсия',
    formatter: formatConversion,
    labelFormatter: () => 'конверсия',
  },
  {
    key: PURCHASES_COUNT_KEY,
    url_key: 'purchases',
    label: 'Покупки',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'покупка',
        few: 'покупки',
        many: 'покупкок',
      }),
  },
  {
    key: AVERAGE_CHECK_KEY,
    url_key: 'average_sum',
    label: 'Средний чек',
    formatter: formatMoney,
    labelFormatter: () => 'средний чек',
  },
  {
    key: AVERAGE_TIME_SPENT,
    url_key: 'average_session_duration',
    label: 'Среднее время пребывания',
    formatter: formatSeconds,
    labelFormatter: () => 'среднее время пребывания',
  },
];
