import { connect } from 'react-redux';

import StatisticsTickDate from '@/components/StatisticsTickDate';
import { getTimeframe } from '@/features/campaignsStatistics/selectors';
import { getScale } from '@/features/campaignsStatisticsPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  date: getTimeframe(state, itemId),
  scale: getScale(state),
});

export default connect(mapStateToProps)(StatisticsTickDate);
