import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { IS_ACTIVE_KEY } from '@esentai/core/features/stores/consts/keys';
import dao from '@esentai/core/features/stores/dao';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { ACTIVATE_STORES, DISABLE_STORES, RETRY_FETCH } from './types';

const { bulkUpdate: bulkUpdateStore } = dao.sagas;

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

function* disableStores({ payload: ids }) {
  const items = ids.map(id => ({ id, [IS_ACTIVE_KEY]: false }));

  yield call(bulkUpdateStore, items);
}

function* watchDisableStores() {
  yield takeEvery(DISABLE_STORES, createTaskSaga(disableStores));
}

function* activateStores({ payload: ids }) {
  const items = ids.map(id => ({ id, [IS_ACTIVE_KEY]: true }));

  yield call(bulkUpdateStore, items);
}

function* watchActivateStores() {
  yield takeEvery(ACTIVATE_STORES, createTaskSaga(activateStores));
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield spawn(watchDisableStores);
  yield spawn(watchActivateStores);
}
