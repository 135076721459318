import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import { defaultProps } from 'recompose';

import CommonTable from '@/components/CommonTable';

import ColGroup from '../ColGroup';
// import FiltersRow from '../FiltersRow';
import HeadRow from '../HeadRow';
import Row from '../Row';

export default compose(
  withRouter,
  defaultProps({
    HeadRow,
    Row,
    ColGroup,
    // TopRow: FiltersRow,
  }),
)(CommonTable);
