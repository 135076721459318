import { ALL_TAGS } from '@esentai/core/features/campaigns/calendarEvent/consts/tags';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { union } from 'ramda';
import React from 'react';

const Tags = ({ classes, selectedTags }) => {
  const tags = union(selectedTags, ALL_TAGS);

  return (
    <div className={classes.root}>
      {tags.map(tag => (
        <div
          key={tag}
          className={cx(classes.tag, {
            [classes.tag_selected]: selectedTags.includes(tag),
          })}
        >
          {tag}
        </div>
      ))}
    </div>
  );
};

Tags.propTypes = {
  selectedTags: PropTypes.array.isRequired,
};

export default Tags;
