import { CircularProgress } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import React from 'react';

const Spinner = () => <CircularProgress color="inherit" />;

export default ({ granted, isLoading, error }) => {
  if (isLoading) {
    return {
      Icon: Spinner,
      text: null,
      variant: 'contained',
    };
  }

  if (error) {
    return {
      Icon: Close,
      text: 'Ошибка',
      variant: 'outlined',
    };
  }

  if (granted) {
    return {
      Icon: Check,
      text: 'Выдан',
      variant: 'outlined',
    };
  }

  return {
    Icon: () => null,
    text: 'Выдать',
    variant: 'contained',
  };
};
