export default ({ spacing }) => ({
  root: {
    margin: [spacing.unit * 4],
    paddingLeft: spacing.unit * 3,
  },

  titleItem: {
    marginBottom: spacing.unit * 2,
  },

  item: {
    display: 'list-item',

    '&:not(:last-child)': {
      marginBottom: spacing.unit * 4,
    },
  },

  listItem: {
    display: 'list-item',
  },

  caption: {
    marginTop: spacing.unit,
  },
});
