import api from '@/api';
import { saveAs } from '@/utils/file-saver';

const getUrl = model => {
  if (model === 'merchant') {
    return 'merchant-history-data-xlsx-export';
  } else if (model === 'cash_machine') {
    return 'cash-machine-history-data-xlsx-export';
  } else if (model === 'store') {
    return 'store-merchant-history-data-xlsx-export';
  }

  return '';
};

const getFileName = model => {
  if (model === 'merchant') {
    return 'Исторические данные по арендатору';
  } else if (model === 'cash_machine') {
    return 'Исторические данные по изменению статуса ККМ';
  } else if (model === 'store') {
    return 'История изменения БИН/ИИН магазина';
  }

  return '';
};

export const downloadXls = (model, objectId) => {
  api
    .doBlobGet(
      `/${getUrl(
        model,
      )}?model[eq]=${model}&object_id[eq]=${objectId}&sort=-record_created&include=user`,
    )
    .then(response => {
      saveAs(response.data, getFileName(model));
    });
};
