import api from '@esentai/core/features/receipts/api';
import {
  CUSTOMER_KEY,
  PURCHASES_KEY,
  REFUND_PURCHASES_KEY,
} from '@esentai/core/features/receipts/consts/keys';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import * as React from 'react';

import ReceiptDetails from './ReceiptDetails';
import ReceiptPurchases from './ReceiptPurchasesTable';
import ReceiptRefunds from './ReceiptRefundPurchasesTable';

export interface ReceiptModalProps {
  isOpen: boolean;
  onClose: () => void;
  uuid: string;
  id: number;
  receipt: number;
  userId: string;
}

export interface ReceiptModalState {
  purchases: ReceiptPurchase[];
  refunds: ReceiptRefundPurchase[];
  receipt: Receipt | undefined;
}

export interface ReceiptPurchase {
  price: number;
  receipt: number;
  amount: number;
  current_amount: number;
  cost: number;
  id: number;
  name: string;
  record_created: string;
}

export interface ReceiptRefundPurchase extends ReceiptPurchase {
  refund_id: number;
}

export interface Receipt {
  purchases_count: number;
  total_cost: number;
  current_bonus_cost: number;
  current_gift_bonus_cost: number;
  refund_bonus_cost: number;
  refund_gift_bonus_cost: number;
  expired_refund_bonus_cost: number;
  store: number;
  issued_at: string;
  store_name: string;
  tax_payer_xin: string;
  id: number;
  receipt: number;
  cash_machine_fns_id: string;
  refund_purchases: [];
  record_created: string;
}

class ReceiptModal extends React.Component<
  ReceiptModalProps,
  ReceiptModalState
> {
  constructor(props: ReceiptModalProps) {
    super(props);

    this.state = {
      purchases: [],
      refunds: [],
      receipt: undefined,
    };
  }

  async loadData() {
    const { receipt, userId } = this.props;

    const includes = [PURCHASES_KEY, REFUND_PURCHASES_KEY];
    const filters = [['id', 'eq', receipt]];

    if (userId) {
      filters.push([CUSTOMER_KEY, 'eq', userId]);
    }

    const data = await api.doQuery(filters, 'id', 'asc', 100, 0, includes);

    this.setState({
      receipt: data.payload.receipt[0],
    });

    if (data.payload.purchase) {
      this.setState({
        purchases: data.payload.purchase,
      });
    }

    if (data.payload.refund_purchase) {
      this.setState({
        refunds: data.payload.refund_purchase,
      });
    }
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.loadData();
    }
  }

  render() {
    const { isOpen, onClose, uuid } = this.props;
    const { purchases, receipt, refunds } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="md"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">Чек № {uuid}</DialogTitle>

        <DialogContent>
          {receipt && (
            <ReceiptDetails
              totalCost={receipt.total_cost}
              kkmNumber={receipt.cash_machine_fns_id}
              bin={receipt.tax_payer_xin}
              purchaseDate={receipt.issued_at}
              activeBonuses={receipt.current_bonus_cost}
              giftBonuses={receipt.current_gift_bonus_cost}
              activeBonusesRefund={receipt.refund_bonus_cost}
              giftBonusesRefund={receipt.refund_gift_bonus_cost}
              expiredRefundBonuses={receipt.expired_refund_bonus_cost}
            />
          )}

          <Typography gutterBottom color="textSecondary" variant="h6">
            Покупка
          </Typography>
          {purchases.length > 0 && <ReceiptPurchases items={purchases} />}

          {refunds.length > 0 && (
            <>
              <br />

              <Typography gutterBottom color="textSecondary" variant="h6">
                Возврат
              </Typography>
              <ReceiptRefunds items={refunds} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size={'large'}>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ReceiptModal;
