import {
  CATEGORIES_KEY,
  IMAGE_URL_KEY,
  NAME_KEY,
  STORES_KEY,
} from '@esentai/core/features/brands/consts/keys';
import { TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
// import CategoriesInput from '@/components/CategoriesInput';
// import ImageField from '@/components/ImageField';
import { StoreMultiAutocomplete } from '@/containers/StoreAutocomplete';
import FormikPropTypes from '@/prop-types/formik';

const fieldNames = {
  [IMAGE_URL_KEY]: 'изображение бренда',
  [NAME_KEY]: 'название',
  [STORES_KEY]: 'магазины',
};

class NewBrandForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
  };

  setCategories = categories => {
    const { setFieldValue } = this.props;

    setFieldValue(CATEGORIES_KEY, categories);
  };

  setImageUrl = urls => {
    const { setFieldValue } = this.props;
    const [url] = urls;

    setFieldValue(IMAGE_URL_KEY, url);
  };

  setStoresIds = storeIds => {
    const { setFieldValue } = this.props;

    setFieldValue(STORES_KEY, storeIds);
  };

  render() {
    const {
      classes,
      values,
      submitButtonText,
      Field,
      Form,
      RequiredMessages,
      SubmitButton,
    } = this.props;

    return (
      <Form>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Название бренда
          </Typography>
          <Field Target={TextField} id={NAME_KEY} name={NAME_KEY} />
        </div>

        {/* <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Категории бренда
          </Typography>
          <CategoriesInput
            selectedItem={values[CATEGORIES_KEY]}
            onChange={this.setCategories}
            InputProps={{
              fullWidth: true,
              InputProps: {
                disableUnderline: false,
              },
            }}
          />
        </div> */}

        {/* <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Описание бренда
          </Typography>
          <Field
            Target={TextField}
            id={DESCRIPTION_RUSSIAN_KEY}
            name={DESCRIPTION_RUSSIAN_KEY}
            multiline
            rows={4}
            helperText="На русском"
            fullWidth
          />
          <Field
            Target={TextField}
            id={DESCRIPTION_ENGLISH_KEY}
            name={DESCRIPTION_ENGLISH_KEY}
            multiline
            rows={4}
            helperText="На английском"
            fullWidth
          />
          <Field
            Target={TextField}
            id={DESCRIPTION_KAZAKH_KEY}
            name={DESCRIPTION_KAZAKH_KEY}
            multiline
            rows={4}
            helperText="На казахском (только для сайта молла)"
            fullWidth
          />
          <Field
            Target={TextField}
            id={DESCRIPTION_CHINESE_KEY}
            name={DESCRIPTION_CHINESE_KEY}
            multiline
            rows={4}
            helperText="На китайском (только для сайта молла)"
            fullWidth
          />
        </div> */}

        {/* <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Изображение бренда
          </Typography>
          <ImageField
            ids={values[IMAGE_URL_KEY] ? [values[IMAGE_URL_KEY]] : []}
            onChange={this.setImageUrl}
          />
        </div> */}

        <div className={classes.section}>
          <Typography variant="h5" color="secondary" gutterBottom>
            Магазины, в которых присутствует бренд
          </Typography>
          <StoreMultiAutocomplete
            selectedItem={values[STORES_KEY]}
            onChange={this.setStoresIds}
            InputProps={{
              fullWidth: true,
              InputProps: {
                disableUnderline: false,
              },
            }}
          />
        </div>

        <ButtonSet>
          <RequiredMessages fieldNames={fieldNames} />
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            {submitButtonText}
          </SubmitButton>
        </ButtonSet>
      </Form>
    );
  }
}

export default NewBrandForm;
