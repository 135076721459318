import { isBenefitFieldEditable } from '@esentai/core/features/benefits/selectors';
import { isNil, not, pipe, prop } from 'ramda';

// import { renameTitleToSubtitle } from '@/features/campaignCreatePage/utils';
import { createMatchSelector } from '@/redux/router/selectors';

import { NEW_BENEFIT_ROUTE_PATH, STEP_ROUTE_PATH } from './consts';

const getParams = pipe(createMatchSelector(STEP_ROUTE_PATH), prop('params'));

export const getBenefitId = pipe(getParams, prop('id'));

// export const getBenefitLayoutWithRename = pipe(
//   getBenefitLayout,
//   renameTitleToSubtitle,
// );

export const isNewBenefit = pipe(
  createMatchSelector(NEW_BENEFIT_ROUTE_PATH),
  isNil,
  not,
);

export const isAttributeEditable = (state, attribute) => {
  if (isNewBenefit(state)) {
    return true;
  }

  const benefitId = getBenefitId(state);

  return isBenefitFieldEditable(state, benefitId, attribute);
};
