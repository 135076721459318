import { ascending, descending } from '@esentai/core/query-dsl';
import { TableSortLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const SortControl = ({ classes, children, field, sort, setSort, ...props }) => {
  const [firstSort] = sort;
  const isActive = firstSort.field === field;
  const onClick = () => {
    if (isActive) {
      if (firstSort.direction === 'asc') {
        setSort(descending(field));
      } else {
        setSort(ascending(field));
      }
    } else {
      setSort(ascending(field));
    }
  };

  return (
    <TableSortLabel
      classes={classes}
      active={isActive}
      direction={firstSort.direction}
      onClick={onClick}
      {...props}
    >
      {children}
    </TableSortLabel>
  );
};

SortControl.propTypes = {
  field: PropTypes.string.isRequired,
  sort: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSort: PropTypes.func.isRequired,
};

export default SortControl;
