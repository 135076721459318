import {
  getStoreBonusLoadingError,
  isStoreBonusLoaded,
  isStoreBonusLoading,
} from '@esentai/core/features/storeBonuses/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getStoreBonusId } from '@/features/storeBonusCreatePage/selectors';

import EditCampaign from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getStoreBonusId(state);

  return {
    error: getStoreBonusLoadingError(state, itemId),
    isLoaded: isStoreBonusLoaded(state, itemId),
    isLoading: isStoreBonusLoading(state, itemId),
    isFirstStepEditable: true,
    itemId,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(EditCampaign);
