import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getDetailsId,
  isLoadingReconciliation,
} from '@/features/reconciliationPeriodBonusDetailsPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';
import withQuerySync from '@/hocs/withQuerySync';

import { fetchDetails as fetchItem } from '../actions';
import queryDuck from '../ducks/query';
import ReconciliationPeriodBonusDetails from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getDetailsId(state);

  return {
    itemId,
    isLoading: isLoadingReconciliation(state),
  };
};

const mapDispatchToProps = {
  fetchItem,
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
  withStyles(styles),
)(ReconciliationPeriodBonusDetails);
