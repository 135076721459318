import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const RadioGroupFilter = ({ value, options, onChange }) => (
  <RadioGroup value={value}>
    {options.map(option => (
      <FormControlLabel
        key={option.key}
        value={option.key}
        label={option.label}
        control={<Radio />}
        onChange={() => onChange(option.key)}
      />
    ))}
  </RadioGroup>
);

RadioGroupFilter.propTypes = {
  value: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioGroupFilter;
