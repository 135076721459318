import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import TableCellTypography from '@/components/TableCellTypography';

import CommonCells from '../CommonCells';
import StatusDistribution from './StatusDistribution';
import TickFormatter from './TickFormatter';
import TimeDistribution from './TimeDistribution';

const Row = ({
  averagePurchases,
  averageReceipts,
  averageTicket,
  purchasesCount,
  percentageReceiptsByCampaign,
  itemId,
}) => (
  <TableRow>
    <CondenseTableCell>
      <TickFormatter itemId={itemId} component={TableCellTypography} />
    </CondenseTableCell>
    <CommonCells
      averagePurchases={averagePurchases}
      averageReceipts={averageReceipts}
      averageTicket={averageTicket}
      purchasesCount={purchasesCount}
      percentageReceiptsByCampaign={percentageReceiptsByCampaign}
    >
      <CondenseTableCell align="right">
        <StatusDistribution itemId={itemId} />
      </CondenseTableCell>
      <CondenseTableCell align="right">
        <TimeDistribution itemId={itemId} />
      </CondenseTableCell>
    </CommonCells>
  </TableRow>
);

Row.propTypes = {
  averagePurchases: PropTypes.number.isRequired,
  averageReceipts: PropTypes.number.isRequired,
  averageTicket: PropTypes.string.isRequired,
  purchasesCount: PropTypes.number.isRequired,
  percentageReceiptsByCampaign: PropTypes.number.isRequired,
  itemId: PropTypes.any.isRequired,
};

export default Row;
