import {
  CATEGORY_KEY,
  DESCRIPTION_EN_KEY,
  DESCRIPTION_KZ_KEY,
  DESCRIPTION_RU_KEY,
  IMAGES_KEY,
  PRICE_KEY,
  SKU_KEY,
  STORE_ID_KEY,
  SUBTITLE_EN_KEY,
  SUBTITLE_KZ_KEY,
  SUBTITLE_RU_KEY,
  TITLE_EN_KEY,
  TITLE_KZ_KEY,
  TITLE_RU_KEY,
  VIEWS_KEY,
  VISIBILITY_KEY,
} from '@esentai/core/features/products/consts/keys';
import apiStores from '@esentai/core/features/stores/api';
import { Button, TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import { Add as AddIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Dropzone from 'react-dropzone';

import AsyncButton from '@/components/AsyncButton';
import LinkAsButton from '@/components/Button';
import ButtonSet from '@/components/ButtonSet';
import FieldRow from '@/components/FieldRow';
import ImageThumb from '@/components/ImageThumb';
import { ProductCategoryAutocomplete } from '@/containers/ProductCategoryAutocomplete';
import { ProductViewMultiAutocompleteWithCategory } from '@/containers/ProductViewAutocomplete';
import { StoreAutocomplete } from '@/containers/StoreAutocomplete';
import { ROUTE_PATH as PRODUCTS_PATH } from '@/features/productsPage/consts';
import apiUpload from '@/features/upload/api';

import InputListField from './InputListField';

const fieldNames = {
  [TITLE_RU_KEY]: 'Заголовок Ru',
  [TITLE_EN_KEY]: 'Заголовок En',
  [TITLE_KZ_KEY]: 'Заголовок Kz',

  [SUBTITLE_RU_KEY]: 'Подзаголовок Ru',
  [SUBTITLE_EN_KEY]: 'Подзаголовок En',
  [SUBTITLE_KZ_KEY]: 'Подзаголовок Kz',

  [DESCRIPTION_RU_KEY]: 'Описание Ru',
  [DESCRIPTION_EN_KEY]: 'Описание En',
  [DESCRIPTION_KZ_KEY]: 'Описание Kz',

  [IMAGES_KEY]: 'Изображения',
  [STORE_ID_KEY]: 'Магазин',
  [SKU_KEY]: 'Артикул',
  [PRICE_KEY]: 'Цена',
  [CATEGORY_KEY]: 'Категория',
  [VIEWS_KEY]: 'Вид',
  [VISIBILITY_KEY]: 'Отображение',
};

export default class EditProductForm extends Component {
  static propTypes = {
    Field: PropTypes.func.isRequired,
    Form: PropTypes.func.isRequired,
    RequiredMessages: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    SubmitButton: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(Error),
    isLoading: PropTypes.bool,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isLoading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      images: props.values.images,
      previews: [],
    };
  }

  componentDidMount() {
    this.loadStores();
  }

  loadStores() {
    apiStores.doQuery([], 'id', 'asc', 9999).then(data => {
      this.setState({
        stores: data.payload.store.filter(
          s => !s.categories || !s.categories.includes('food'),
        ),
      });
    });
  }

  async upload(files) {
    for (const file of files) {
      const { images, previews } = this.state;
      const urls = await apiUpload.getUrl(file.name);
      const { upload_url: url, download_url } = urls;

      await apiUpload.upload({ url, file });
      const pId = previews.findIndex(p => p.id === file.name);

      previews.splice(pId, 1);
      images.push(download_url);
      this.setState({ previews, images });
    }

    this.updateImages();
  }

  dragEnd(result) {
    const { images } = this.state;

    if (result.destination) {
      const [removed] = images.splice(result.source.index, 1);

      images.splice(result.destination.index, 0, removed);
      this.setState({ images });
    }
  }

  updateImages() {
    setTimeout(() => {
      const { images } = this.state;
      const { setFieldValue } = this.props;

      setFieldValue(IMAGES_KEY, images);
    }, 500);
  }

  renderInput = ({
    preUrl,
    key,
    handlerRemoveItem,
    helperText,
    inputLabel,
  }) => {
    const { Field, setFieldValue } = this.props;

    return (
      <InputListField
        Field={Field}
        name={key}
        preUrl={preUrl}
        setFieldValue={setFieldValue}
        handlerRemoveItem={handlerRemoveItem}
        helperText={helperText}
        label={inputLabel}
      />
    );
  };

  render() {
    const { previews, images } = this.state;
    const {
      Form,
      Field,
      RequiredMessages,
      SubmitButton,
      classes,
      error,
      isLoading,
      setFieldValue,
      values,
    } = this.props;

    return (
      <Form>
        <FieldRow label="Заголовок">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={TITLE_RU_KEY}
            required
            rows={4}
            label={fieldNames[TITLE_RU_KEY]}
          />

          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={TITLE_EN_KEY}
            rows={4}
            label={fieldNames[TITLE_EN_KEY]}
          />

          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={TITLE_KZ_KEY}
            rows={4}
            label={fieldNames[TITLE_KZ_KEY]}
          />
        </FieldRow>

        <FieldRow label="Подзаголовок">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={SUBTITLE_RU_KEY}
            rows={4}
            label={fieldNames[SUBTITLE_RU_KEY]}
          />

          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={SUBTITLE_EN_KEY}
            rows={4}
            label={fieldNames[SUBTITLE_EN_KEY]}
          />

          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={SUBTITLE_KZ_KEY}
            rows={4}
            label={fieldNames[SUBTITLE_KZ_KEY]}
          />
        </FieldRow>

        <FieldRow label="Описание">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            multiline
            name={DESCRIPTION_RU_KEY}
            rows={4}
            label={fieldNames[DESCRIPTION_RU_KEY]}
          />

          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={DESCRIPTION_EN_KEY}
            multiline
            rows={4}
            label={fieldNames[DESCRIPTION_EN_KEY]}
          />

          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={DESCRIPTION_KZ_KEY}
            multiline
            rows={4}
            label={fieldNames[DESCRIPTION_KZ_KEY]}
          />
        </FieldRow>

        <FieldRow label="Магазин" sudDesc="Выберите магазин">
          <StoreAutocomplete
            onChange={val => {
              setFieldValue(STORE_ID_KEY, val);
            }}
            selectedItem={values[STORE_ID_KEY]}
            InputProps={{
              fullWidth: true,
              placeholder: 'Добавьте магазин',
              InputProps: { disableUnderline: true },
            }}
          />
        </FieldRow>

        <FieldRow label="Категория" sudDesc="Выберите категорию">
          <ProductCategoryAutocomplete
            onChange={val => {
              setFieldValue(CATEGORY_KEY, val);
              if (!val) {
                setFieldValue(VIEWS_KEY, []);
              }
            }}
            selectedItem={values[CATEGORY_KEY]}
            InputProps={{
              fullWidth: true,
              placeholder: 'Выберите категорию',
              InputProps: { disableUnderline: true },
            }}
          />
        </FieldRow>

        <FieldRow label="Вид(ы)" sudDesc="Выберите вид(ы)">
          {values[CATEGORY_KEY] && (
            <ProductViewMultiAutocompleteWithCategory
              onChange={val => {
                setFieldValue(VIEWS_KEY, val);
              }}
              selectedItem={values[VIEWS_KEY] || []}
              InputProps={{
                fullWidth: true,
                placeholder: 'Выберите вид(ы)',
                InputProps: { disableUnderline: true },
              }}
              id={values[CATEGORY_KEY]}
              categoryId={values[CATEGORY_KEY]}
            />
          )}
        </FieldRow>

        <FieldRow label="Цена">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={PRICE_KEY}
            rows={4}
            label={fieldNames[PRICE_KEY]}
          />
        </FieldRow>

        <FieldRow label="Артикул">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={SKU_KEY}
            required
            rows={4}
            label={fieldNames[SKU_KEY]}
          />
        </FieldRow>

        <DragDropContext onDragEnd={result => this.dragEnd(result)}>
          <FieldRow label="Изображения">
            <Grid container spacing={16}>
              <Droppable
                droppableId={`Product-img`}
                direction="horizontal"
                style={{ width: '100%' }}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={{
                      backgroundColor: snapshot.isDraggingOver ? '#eeeeee' : '',
                    }}
                    {...provided.droppableProps}
                  >
                    <Dropzone
                      onDrop={async acceptedFiles => {
                        const newPreviews = [
                          ...previews,
                          ...acceptedFiles.map(file => ({
                            id: file.name,
                            img: URL.createObjectURL(file),
                          })),
                        ];

                        this.setState({ previews: newPreviews });
                        await this.upload(acceptedFiles);
                      }}
                      noClick
                      accept={'.jpeg,.png,.jpg,.bmp'}
                    >
                      {({ getRootProps, getInputProps, open }) => (
                        <section style={{ width: 800 }}>
                          <div {...getRootProps()} className={classes.drop}>
                            <input {...getInputProps()} />
                            {images.length === 0 && previews.length === 0 && (
                              <p>
                                Перетащите изображения сюда (.jpeg, .png, .jpg,
                                .bmp)
                              </p>
                            )}
                            {images.map((image, imgInd) => (
                              <Draggable
                                draggableId={`draggable-${image}`}
                                index={imgInd}
                                key={image}
                                position="left"
                              >
                                {providedDrag => (
                                  <div
                                    ref={providedDrag.innerRef}
                                    {...providedDrag.draggableProps}
                                    {...providedDrag.dragHandleProps}
                                  >
                                    <ImageThumb
                                      url={image}
                                      remove={() => {
                                        images.splice(imgInd, 1);
                                        this.setState({ images });
                                        this.updateImages();
                                      }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}

                            {previews.map(prev => (
                              <Grow in key={prev.id}>
                                <ImageThumb url={prev.img} loading />
                              </Grow>
                            ))}
                            {provided.placeholder}

                            <IconButton color="primary" onClick={open}>
                              <AddIcon />
                            </IconButton>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                )}
              </Droppable>
            </Grid>
          </FieldRow>
        </DragDropContext>

        <FieldRow
          label="Отображение"
          subLabel="Отобразить товар после сохранения?"
          sudDesc="Без включения этой настройки после нажатия кнопки ''Сохранить'' товар не будет виден пользователям"
        >
          <Field
            margin="normal"
            Target={Checkbox}
            className={classes.checkbox}
            checked={values[VISIBILITY_KEY]}
            name="visible"
            rows={4}
            label="Показать товар пользователям"
            onChange={e => {
              setFieldValue(VISIBILITY_KEY, e.target.checked);
            }}
          />
        </FieldRow>

        <FieldRow>
          <ButtonSet>
            <RequiredMessages fieldNames={fieldNames} />
            <LinkAsButton size="large" href={PRODUCTS_PATH}>
              Назад
            </LinkAsButton>
            <SubmitButton
              Target={AsyncButton}
              ButtonComponent={Button}
              error={error}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
            >
              Сохранить
            </SubmitButton>
          </ButtonSet>
        </FieldRow>
      </Form>
    );
  }
}
