import { RESOURCE_NAME as BENEFITS } from '@esentai/core/features/benefits/consts';
import { RESOURCE_NAME as CREDIT_CARDS } from '@esentai/core/features/creditCards/consts';
import {
  can,
  CREATE,
  DELETE,
  EDIT,
  permit,
  VIEW,
} from '@esentai/core/policy-dsl';

import { GIVE_OUT } from '@/features/benefitGiveOutPage/consts/permissions';
import { MODERATE } from '@/features/campaignsPage/consts/permissions';
import { VIEW_COMMISSAR_ANSWER } from '@/features/currentUser/permissions/consts/permissions';

export default [
  can(VIEW_COMMISSAR_ANSWER),
  permit(CREDIT_CARDS, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(BENEFITS, [GIVE_OUT]),
];
