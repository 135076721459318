import {
  LEVEL_CHANGED,
  REWARD_BONUSES,
} from '@esentai/core/features/loyalties/consts/statuses';

import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';
import { activityType } from '@/utils/labels';

export const options = [REWARD_BONUSES, LEVEL_CHANGED].map(key => ({
  key,
  label: activityType(key),
}));

export const createActivityTypeFilter = createRadioFilterTemplate({
  options,
});

export default RadioGroupFilter;
