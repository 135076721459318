import { grey } from '@material-ui/core/colors';

export default {
  xAxis: {
    '& line': {
      color: grey[300],
    },
  },

  text: {
    color: grey[600],
  },

  text_weekend: {
    color: '#B71C1C',
  },
};
