export default {
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: [0, 10],
    paddingTop: 11,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    border: [2, 'solid', '#FF4D00'],
    minHeight: 50,
  },

  title: {
    width: '100%',
    flex: 1,
    fontFamily: 'Giorgio Sans',
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: 22,
    lineHeight: 1.1,
    textTransform: 'uppercase',
    letterSpacing: 0.05,
    color: '#FF4D00',
    wordWrap: 'break-word',
  },

  pointer: {
    marginLeft: 10,
    width: 20,
    height: 20,
    float: 'right',
  },
};
