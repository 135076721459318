import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import EditButton from './EditButton';
import RevokeButton from './RevokeButton';
import ToggleStatusButton from './ToggleStatusButton';

const Actions = ({ invited, personnelId }) => (
  <Grid container wrap="nowrap" justify="flex-end">
    {invited ? (
      <RevokeButton personnelId={personnelId} />
    ) : (
      <Fragment>
        <ToggleStatusButton personnelId={personnelId} />
        <EditButton personnelId={personnelId} />
      </Fragment>
    )}
  </Grid>
);

Actions.propTypes = {
  invited: PropTypes.bool.isRequired,
  personnelId: PropTypes.string.isRequired,
};

export default Actions;
