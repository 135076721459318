import {
  getAgeForParkingTowerStopList,
  getEmailForParkingTowerStopList,
  getFirstNameForParkingTowerStopList,
  getGenderForParkingTowerStopList,
  getIsPhoneVerifiedForParkingTowerStopList,
  getLastNameForParkingTowerStopList,
  getPhoneForParkingTowerStopList,
  getRecordCreatedForParkingTowerStopList,
  getStateForParkingTowerStopList,
} from '@esentai/core/features/parkingTowerStopList/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: parkingTowerStopListId }) => ({
  age: getAgeForParkingTowerStopList(state, parkingTowerStopListId),
  email: getEmailForParkingTowerStopList(state, parkingTowerStopListId),
  firstName: getFirstNameForParkingTowerStopList(state, parkingTowerStopListId),
  lastName: getLastNameForParkingTowerStopList(state, parkingTowerStopListId),
  gender: getGenderForParkingTowerStopList(state, parkingTowerStopListId),
  recordCreated: getRecordCreatedForParkingTowerStopList(
    state,
    parkingTowerStopListId,
  ),
  isPhoneVerified: getIsPhoneVerifiedForParkingTowerStopList(
    state,
    parkingTowerStopListId,
  ),
  state: getStateForParkingTowerStopList(state, parkingTowerStopListId),
  phone: getPhoneForParkingTowerStopList(state, parkingTowerStopListId),
  parkingTowerStopListId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
