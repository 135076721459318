export default {
  formSection: {
    marginBottom: 16,
  },

  formSectionName: {
    paddingTop: 15,
  },

  sectionBody: {
    display: 'flex',
    flexDirection: 'column',
  },
};
