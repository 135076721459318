import {
  ACTION_RESULT_TYPE,
  ARTICLE_TYPE,
  BANNER_TYPE,
  BUTTON_TYPE,
  CALENDAR_EVENT_TYPE,
  NOTIFICATION_TYPE,
  PURCHASE_RESULT_TYPE,
  SHOW_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import PropTypes from 'prop-types';
import React from 'react';

import ActionResultContent from './ActionResultContent';
import ArticleContent from './ArticleContent';
import BannerContent from './BannerContent';
import ButtonContent from './ButtonContent';
import CalendarEventContent from './CalendarEventContent';
import NotificationContent from './NotificationContent';
import PurchaseResultContent from './PurchaseResultContent';
import ShowContent from './ShowContent';

const components = {
  [ARTICLE_TYPE]: ArticleContent,
  [CALENDAR_EVENT_TYPE]: CalendarEventContent,
  [PURCHASE_RESULT_TYPE]: PurchaseResultContent,
  [BUTTON_TYPE]: ButtonContent,
  [ACTION_RESULT_TYPE]: ActionResultContent,
  [NOTIFICATION_TYPE]: NotificationContent,
  [BANNER_TYPE]: BannerContent,
  [SHOW_TYPE]: ShowContent,
};

const Content = ({ type, ...rest }) => {
  const Component = components[type];

  if (Component) {
    return <Component {...rest} />;
  }

  return null;
};

Content.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Content;
