import { path } from 'ramda';

import { PAGES_STORE_KEY } from '@/redux/constants';

export const getItemsRoot = path([
  PAGES_STORE_KEY,
  'purchasesStatisticsPage',
  'items',
]);

export const getCurrentQuery = path([
  PAGES_STORE_KEY,
  'purchasesStatisticsPage',
  'query',
  'current',
]);

export const getPreviousQuery = path([
  PAGES_STORE_KEY,
  'purchasesStatisticsPage',
  'query',
  'previous',
]);
