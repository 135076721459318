import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddLink from '@/components/AddLink';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';
import { ROUTE_PATH as NEW_STORE_PATH } from '@/features/storeCreatePage/consts';

import Table from './Table';

class Stores extends Component {
  static propTypes = {
    error: PropTypes.any,
    storesIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, storesIds, isLoading, totalCount } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Магазины</PageTitle>
          <PageSubtitle>Вспомогательный текст</PageSubtitle>
        </PageHeader>
        <PageContent>
          <AddLink to={NEW_STORE_PATH} />
          <Table
            error={error}
            items={storesIds}
            isLoading={isLoading}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default Stores;
