import PropTypes from 'prop-types';
import React from 'react';

const ColGroup = ({ isMallAdmin }) => (
  <>
    <colgroup width="1" />
    <colgroup width="150px" />
    <colgroup span={3} width="1*" />
    {isMallAdmin && <colgroup span={2} width="1" />}
  </>
);

ColGroup.propTypes = {
  isMallAdmin: PropTypes.bool.isRequired,
};

export default ColGroup;
