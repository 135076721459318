import { always, map, prop } from 'ramda';

import {
  doesPageFilterHaveNoValue,
  packPageFilterValue,
  unpackPageFilterValue,
} from './pageFilterAdapter';
// eslint-disable-next-line import/no-cycle
import { createQuickFilter } from './quickFilter';

export const createPageFilter = definition => {
  if (!definition.key) {
    throw TypeError('"key" is a mandatory page filter field');
  }

  if (!definition.label) {
    throw TypeError('"label" is a mandatory page filter field');
  }

  if (!definition.adapter) {
    throw TypeError('"adapter" is a mandatory page filter field');
  }

  if (!definition.FormatComponent) {
    throw TypeError('"FormatComponent" is a mandatory page filter field');
  }

  if (!definition.FilterComponent) {
    throw TypeError('"FilterComponent" is a mandatory page filter field');
  }

  return definition;
};

export const createPageFilterTemplate = initialDefinition => definition =>
  createPageFilter({ ...initialDefinition, ...definition });

export const extendPageFilterTemplate = (
  template,
  initialDefinition,
) => definition =>
  createPageFilter({
    ...template(definition),
    ...initialDefinition,
    ...definition,
  });

export const getPageFilterField = prop('key');

export const getPageFilterLabel = prop('label');

export const getPageFilterQuicklyAccessibleOptions = pageFilter => {
  const { quicklyAccessibleOptions } = pageFilter;

  return map(
    option => createQuickFilter(option.key, option.label, pageFilter),
    quicklyAccessibleOptions,
  );
};

export const getPageFilterComponent = prop('FilterComponent');

export const getPageFilterFormatComponent = prop('FormatComponent');

export const getPageFilterInitialValue = always(null);

export const isQuicklyAccessiblePageFilter = prop('quicklyAccessible');

export const isPageFilterValueEmpty = (pageFilter, filter) => {
  const { adapter } = pageFilter;

  return doesPageFilterHaveNoValue(adapter, filter);
};

export const readPageFilterValue = (pageFilter, filter) => {
  const { adapter } = pageFilter;

  return unpackPageFilterValue(adapter, filter);
};

export const createPageFilterUpdate = (pageFilter, value) => {
  const key = getPageFilterField(pageFilter);
  const { adapter, postProcessValue = x => x } = pageFilter;

  return { [key]: packPageFilterValue(adapter, postProcessValue(value)) };
};
