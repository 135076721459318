import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepTwo } from '@/features/mallBonusCreatePage/actions';
import { getMallBonusId } from '@/features/mallBonusCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import FirstStep from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getMallBonusId(state);
  const cached = `mall-bonus-${id}`;
  const payload = JSON.parse(localStorage.getItem(cached) || '{}');

  return {
    comment: payload.comment,
    startDate: payload.start_datetime,
    endDate: payload.end_datetime,
    percent: payload.percent,
    stores: payload.stores,
  };
};

const mapDispatchToProps = { onSubmit: goToStepTwo };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(FirstStep);
