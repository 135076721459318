import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import StatisticsTopBar from '@/components/StatisticsTopBar';
import { updateFilters } from '@/features/campaignsStatisticsDetailsPage/actions';
import { getFilters } from '@/features/campaignsStatisticsDetailsPage/selectors';
import stats from '@/features/campaignsStatisticsPage/stats';

import InnerTopBar from './InnerTopBar';

const mapStateToProps = state => ({
  filters: getFilters(state),
  stats,
});

const mapDispatchToProps = { updateFilters };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({ stats, InnerTopBarComponent: InnerTopBar }),
)(StatisticsTopBar);
