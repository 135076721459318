import {
  CASH_MACHINE_FNS_ID_KEY,
  ID_KEY,
  ISSUED_AT_KEY,
  SALE_NUMBER_KEY,
  TOTAL_COST_KEY,
} from '@esentai/core/features/salesTurnoverDetails/consts/keys';

import { createDateTimeRangeFilter } from '@/filters/DateTimeRange';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createEqualsFilter, createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createEqualsFilter({
    key: ID_KEY,
    label: 'ID покупки',
  }),
  createTextFilter({
    key: CASH_MACHINE_FNS_ID_KEY,
    label: 'Номер ККМ',
  }),
  createEqualsFilter({
    key: SALE_NUMBER_KEY,
    label: 'UUID',
  }),
  createMoneyRangeFilter({
    key: TOTAL_COST_KEY,
    label: 'Итого чека',
  }),
  createDateTimeRangeFilter({
    key: ISSUED_AT_KEY,
    label: 'Дата и время покупки',
  }),
];
