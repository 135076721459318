import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import {
  decreasePriority,
  increasePriority,
  remove,
} from '@esentai/core/features/questions/sagas';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import {
  DELETE_QUESTION,
  MOVE_QUESTION_DOWN,
  MOVE_QUESTION_UP,
  RETRY_FETCH,
} from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* deleteQuestion(action) {
  const { payload: questionId } = action;

  yield call(remove, questionId);
  yield call(fetchItems);
}

export function* moveQuestionDown(action) {
  yield call(decreasePriority, action);
  yield call(fetchItems);
}

export function* moveQuestionUp(action) {
  yield call(increasePriority, action);
  yield call(fetchItems);
}

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield takeEvery(DELETE_QUESTION, createTaskSaga(deleteQuestion));
  yield takeEvery(MOVE_QUESTION_DOWN, createTaskSaga(moveQuestionDown));
  yield takeEvery(MOVE_QUESTION_UP, createTaskSaga(moveQuestionUp));
}
