import { query } from '@esentai/core/query-dsl';
import { path } from 'ramda';

import { PAGES_STORE_KEY } from '@/redux/constants';

import { createLink } from './serializer';

export const getItemsRoot = path([PAGES_STORE_KEY, 'campaignsPage', 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, 'campaignsPage', 'query']);

export const createCampaignsPageURL = () => createLink(query());
