import { FormControl } from '@material-ui/core';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import { ROLE_KEY } from '@/features/invites/consts/keys';
import {
  BITRIX_ROLE,
  EFS_SALES_ROLE,
  GUEST_EFS_ROLE,
  GUEST_PARKING_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_SELLER_ROLE,
  MALL_VALET_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_BENEFIT_MANAGER_ROLE,
} from '@/features/personnel/consts/roles';
import FormikPropTypes from '@/prop-types/formik';
import { userRole } from '@/utils/labels';

import ButtonsField from '../ButtonsField';
import { getFieldComponent, getFields } from '../utils';

class MallAdminForm extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { Form, values, ...rest } = this.props;
    const role = values[ROLE_KEY];
    const fields = getFields(role);

    return (
      <Form>
        <FieldRow
          label="Тип пользователя"
          subLabel="Тип аккаунта пользователя"
          sudDesc="Тип аккаунта определяет, какими правами будет обладать новый пользователь."
        >
          <FormControl component="fieldset">
            <RadioBoxGroup
              name={ROLE_KEY}
              value={values[ROLE_KEY]}
              onChange={this.handleRoleChange}
            >
              <RadioBox
                value={MALL_MANAGER_ROLE}
                label={userRole(MALL_MANAGER_ROLE)}
                helperText="Маркетинг Менеджер или Администратор Молла будет отвечать за создание кампаний Молла и модерацию кампаний магазинов."
              />
              <RadioBox
                value={MALL_SELLER_ROLE}
                label={userRole(MALL_SELLER_ROLE)}
                helperText="Отвечает за выдачу бенефитов молла пользователям. Не имеет доступа к CMS"
              />
              <RadioBox
                value={MALL_COMMISSAR_ROLE}
                label={userRole(MALL_COMMISSAR_ROLE)}
                helperText="Отвечает за начисление бонусов и изменение уровня пользователей. Не имеет доступа к CMS"
              />
              <RadioBox
                value={STORE_ADMINISTRATOR_ROLE}
                label={userRole(STORE_ADMINISTRATOR_ROLE)}
                helperText="Аккаунт магазина предоставляет полный доступ к возможностям системы, ограниченный его Локацией."
              />
              <RadioBox
                value={MALL_ACCOUNTANT_ROLE}
                label={userRole(MALL_ACCOUNTANT_ROLE)}
                helperText="Бухгалтер отвечает за Реконсиляцию."
              />
              <RadioBox
                value={MALL_VALET_ROLE}
                label={userRole(MALL_VALET_ROLE)}
                helperText="Car Park. Отвечает за доступ для Valet-парковщиков. Имеет доступ к данным по паркингу"
              />
              <RadioBox
                value={GUEST_PARKING_ROLE}
                label={userRole(GUEST_PARKING_ROLE)}
                helperText="Доступ в API для Smart Parking Technologies"
              />
              <RadioBox
                value={BITRIX_ROLE}
                label={userRole(BITRIX_ROLE)}
                helperText="Guest Bitrix. Доступ в API для Битрикса"
              />
              <RadioBox
                value={STORE_BENEFIT_MANAGER_ROLE}
                label={userRole(STORE_BENEFIT_MANAGER_ROLE)}
                helperText="Имеет доступ в мобильное приложение для сканирования бенефитов. Также имеет доступ в веб сайт"
              />
              <RadioBox
                value={GUEST_EFS_ROLE}
                label={userRole(GUEST_EFS_ROLE)}
                helperText="Доступ в API для передачи данных. Просмотр в админ панели списка клиентов EFS. Настройка бесплатных часов парковки"
              />
              <RadioBox
                value={EFS_SALES_ROLE}
                label={userRole(EFS_SALES_ROLE)}
                helperText=""
              />
              <RadioBox
                value={MALL_RECEPTION_ROLE}
                label={userRole(MALL_RECEPTION_ROLE)}
                helperText=""
              />
              <RadioBox
                value={MANAGING_TEAM_MEMBER_ROLE}
                label={userRole(MANAGING_TEAM_MEMBER_ROLE)}
                helperText=""
              />
            </RadioBoxGroup>
          </FormControl>
        </FieldRow>
        {fields.map(field => {
          const Field = getFieldComponent(field);

          return <Field key={field} values={values} {...rest} />;
        })}
        <ButtonsField {...rest} />
      </Form>
    );
  }

  handleRoleChange = event => {
    this.props.setFieldValue(ROLE_KEY, event.target.value);
  };
}

export default MallAdminForm;
