import PropTypes from 'prop-types';
import React from 'react';

const Label = ({ classes, name, text }) => (
  <label className={classes.labelItem} htmlFor={name}>
    {text}
  </label>
);

Label.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Label;
