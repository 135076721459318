import { LOCATION_ID_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { getRussianName } from '@esentai/core/features/locations/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import LocationBlock from './component';
import styles from './styles';

const mapStateToProps = (state, { payload }) => ({
  name: getRussianName(state, payload[LOCATION_ID_KEY]),

  // TODO: @exeto add real data
  floor: '1st Floor',
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(LocationBlock);
