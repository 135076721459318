import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ErrorMessage = ({ classes, title, subtitle, Icon }) => (
  <div className={classes.root}>
    <Icon className={classes.icon} />
    <Typography variant="h4" color="textSecondary" className={classes.title}>
      {title}
    </Typography>
    <Typography variant="subtitle1" color="textSecondary">
      {subtitle}
    </Typography>
  </div>
);

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
};

export default ErrorMessage;
