import { findCampaignById } from '@esentai/core/features/benefits/actions';
import {
  isBenefitLoaded,
  isLoadingBenefit,
} from '@esentai/core/features/benefits/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import BenefitReference from '@/features/campaignCreatePage/EditPage/SecondStepBanner/BenefitInlineSearch/Suggestion';
import withItemApiRunner from '@/hocs/withItemApiRunner';

const mapStateToProps = (state, { benefitId }) => ({
  isLoading: isLoadingBenefit(state, benefitId),
  isLoaded: isBenefitLoaded(state, benefitId),
  itemId: benefitId,
});

const mapDispatchToProps = { fetchItem: findCampaignById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(BenefitReference);
