import PropTypes from 'prop-types';
import React from 'react';

const MinusFirstFloor = ({ mapHeight, mapWidth, MapTransformer, ...props }) => (
  <MapTransformer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${mapWidth} ${mapHeight}`}
      {...props}
    >
      <g id="ab257056-1488-4f3c-a6e8-de1647d85fbd" data-name="Background">
        <rect y="-1" width="1420.16" height="1001" fill="#ededed" />
        <rect x="879" y="185" width="78" height="432" fill="#f7f7f7" />
        <rect y="658" width="1415" height="24" fill="#f7f7f7" />
        <rect y="695" width="1415" height="24" fill="#f7f7f7" />
        <path
          d="M914.34,334.85v-3.74H915V334h4v-2.08h.69V334h4.14v-2.88h.69v3.74Z"
          fill="#888685"
        />
        <path
          d="M922.59,325.82a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.54,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84V329h.87a1.55,1.55,0,0,0,1-.28,1.11,1.11,0,0,0,.36-.92,1.21,1.21,0,0,0-.32-.94,1.43,1.43,0,0,0-1-.29h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.83,1.83,0,0,1-.51-1.44,1.94,1.94,0,0,1,.51-1.5,2.19,2.19,0,0,1,1.49-.45h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.08,1.08,0,0,0,.3.85,1.17,1.17,0,0,0,.81.27H918a1.61,1.61,0,0,0,.39,0,1.25,1.25,0,0,0,.33-.16,3.8,3.8,0,0,0,.33-.29c.11-.12.24-.28.39-.47l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.93,2.93,0,0,1,.63-.06Z"
          fill="#888685"
        />
        <path
          d="M921.57,320.25h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.55,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.41,2.41,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.34,1.28,1.28,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.32,1.16,1.16,0,0,0-.39,1,1.19,1.19,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M918,315.54a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.28,1.28,0,0,0,.35.91,1.35,1.35,0,0,0,1,.37h6.54v.82H916V318h.84v0a1.84,1.84,0,0,1-.66-.55,1.66,1.66,0,0,1-.27-1,1.61,1.61,0,0,1,.48-1.26,2.24,2.24,0,0,1,1.53-.43h6.59v.82Z"
          fill="#888685"
        />
        <path
          d="M916,313.53v-.66h-1.66v-.82H916v-1.19h.63v1.19h6.49a.71.71,0,0,0,.54-.16.75.75,0,0,0,.16-.56v-.47h.69v.56a1.59,1.59,0,0,1-.32,1.13,1.5,1.5,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M924.51,306.36h-.77v0a1.74,1.74,0,0,1,.6.53,1.59,1.59,0,0,1,.26.94,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.23.94.94,0,0,0-.35.85,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.8h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.31a1.23,1.23,0,0,0-1.35-1.31h-3a.57.57,0,0,1,.14.13l.17.23q.09.13.21.36c.08.15.18.34.29.57a5.89,5.89,0,0,0,.28.52,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.86,1.86,0,0,0,.56.07h.65a1.58,1.58,0,0,0,1-.27A1,1,0,0,0,923.91,307.67Z"
          fill="#888685"
        />
        <path
          d="M914.34,304.11v-.82h.75v.82Zm1.66,0v-.82h8.51v.82Z"
          fill="#888685"
        />
        <path
          d="M924.6,297.38a1.92,1.92,0,0,1-2.07,2.17H921v-.82h1.6a1.44,1.44,0,0,0,1-.33,1.35,1.35,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.54,1.54,0,0,0-1-.31H922a3,3,0,0,0-.53.05,2,2,0,0,0-.46.18,2.31,2.31,0,0,0-.43.31c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.44-.63.61a3.55,3.55,0,0,1-.57.43,2.27,2.27,0,0,1-.6.26,3.08,3.08,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.17,2.17,0,0,1,.5-1.58,2,2,0,0,1,1.49-.51h1.11v.81h-1.14a1.29,1.29,0,0,0-1,.32,1.35,1.35,0,0,0-.3,1,1.29,1.29,0,0,0,.31.91,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a3.52,3.52,0,0,1,.58-.42,2.78,2.78,0,0,1,.62-.25,3.11,3.11,0,0,1,.72-.08h.56a2.45,2.45,0,0,1,.83.13,1.57,1.57,0,0,1,.66.39,1.9,1.9,0,0,1,.44.68A2.53,2.53,0,0,1,924.6,297.38Z"
          fill="#888685"
        />
        <path
          d="M925.86,289.7v.82h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.48h-4.63a2,2,0,0,1-1.54-.52,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H916v-.82ZM922.54,293a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H918a1.43,1.43,0,0,0-1,.4,1.26,1.26,0,0,0-.36.91c0,.81.46,1.21,1.37,1.21Z"
          fill="#888685"
        />
        <path
          d="M922.56,287.43q1.35,0,1.35-1.17a1.29,1.29,0,0,0-.32-.88,1.33,1.33,0,0,0-1-.39H916v-.83h8.51V285h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.44H916v-.83Z"
          fill="#888685"
        />
        <path
          d="M924.51,279.53h-.77v0a1.71,1.71,0,0,1,.6.52,1.62,1.62,0,0,1,.26.95,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.46-.36-.73a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.92,1.92,0,0,1-.54-1.51,1.75,1.75,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57a5.58,5.58,0,0,0,.28.51,1.61,1.61,0,0,0,.3.37,1.27,1.27,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,923.91,280.83Z"
          fill="#888685"
        />
        <path
          d="M916.86,275v.14a1.37,1.37,0,0,0,.34,1,1.31,1.31,0,0,0,1,.37h6.33v.83H916v-.83h1v0a2.33,2.33,0,0,1-.39-.19,1.64,1.64,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.4,1.4,0,0,1-.1-.56Z"
          fill="#888685"
        />
        <path
          d="M921.57,269.51h.84A2.36,2.36,0,0,1,924,270a2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M914.34,580.56v-3.73H915v2.88h4v-2.09h.69v2.09h4.14v-2.88h.69v3.73Z"
          fill="#888685"
        />
        <path
          d="M922.59,571.53a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.55,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84v-.78h.87a1.56,1.56,0,0,0,1-.29,1.09,1.09,0,0,0,.36-.91,1.24,1.24,0,0,0-.32-.95,1.43,1.43,0,0,0-1-.28h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.85,1.85,0,0,1-.51-1.45,1.92,1.92,0,0,1,.51-1.49,2.19,2.19,0,0,1,1.49-.46h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.11,1.11,0,0,0,.3.86,1.17,1.17,0,0,0,.81.27H918a1.6,1.6,0,0,0,.39-.05,1.21,1.21,0,0,0,.33-.15,4.09,4.09,0,0,0,.33-.3c.11-.12.24-.27.39-.46l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.88,2.88,0,0,1,.63-.07Z"
          fill="#888685"
        />
        <path
          d="M921.57,566h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M918,561.26a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.29,1.29,0,0,0,.35.91,1.35,1.35,0,0,0,1,.36h6.54v.83H916v-.83h.84a1.87,1.87,0,0,1-.66-.56,1.93,1.93,0,0,1,.21-2.26,2.24,2.24,0,0,1,1.53-.44h6.59v.83Z"
          fill="#888685"
        />
        <path
          d="M916,559.25v-.66h-1.66v-.83H916v-1.18h.63v1.18h6.49a.76.76,0,0,0,.54-.15.79.79,0,0,0,.16-.57v-.46h.69v.55a1.63,1.63,0,0,1-.32,1.14,1.54,1.54,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M924.51,552.08h-.77v0a1.71,1.71,0,0,1,.6.52,1.64,1.64,0,0,1,.26,1,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.13,2.13,0,0,1-.41-.56c-.12-.23-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.9,1.9,0,0,1-.54-1.51,1.76,1.76,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57s.19.37.28.52a1.79,1.79,0,0,0,.3.36,1.09,1.09,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,923.91,553.38Z"
          fill="#888685"
        />
        <path
          d="M914.34,549.83V549h.75v.83Zm1.66,0V549h8.51v.83Z"
          fill="#888685"
        />
        <path
          d="M924.6,543.09a1.93,1.93,0,0,1-2.07,2.18H921v-.83h1.6a1.44,1.44,0,0,0,1-.33,1.33,1.33,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.48,1.48,0,0,0-1-.31H922a3,3,0,0,0-.53.05,2,2,0,0,0-.46.17,2.39,2.39,0,0,0-.43.32c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.45-.63.62a4.26,4.26,0,0,1-.57.43,2.24,2.24,0,0,1-.6.25,2.62,2.62,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.19,2.19,0,0,1,.5-1.58,2,2,0,0,1,1.49-.5h1.11v.81h-1.14a1.28,1.28,0,0,0-1,.31,1.35,1.35,0,0,0-.3,1,1.3,1.3,0,0,0,.31.92,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a4.22,4.22,0,0,1,.58-.42,2.34,2.34,0,0,1,.62-.24,2.63,2.63,0,0,1,.72-.09h.56a2.72,2.72,0,0,1,.83.13,1.7,1.7,0,0,1,.66.4,1.86,1.86,0,0,1,.44.67A2.53,2.53,0,0,1,924.6,543.09Z"
          fill="#888685"
        />
        <path
          d="M925.86,535.41v.83h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.47h-4.63a2.06,2.06,0,0,1-1.54-.51,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H916v-.83Zm-3.32,3.35a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H918a1.43,1.43,0,0,0-1,.4,1.22,1.22,0,0,0-.36.9c0,.81.46,1.22,1.37,1.22Z"
          fill="#888685"
        />
        <path
          d="M922.56,533.15q1.35,0,1.35-1.17a1.31,1.31,0,0,0-.32-.89,1.28,1.28,0,0,0-1-.38H916v-.83h8.51v.83h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.43H916v-.82Z"
          fill="#888685"
        />
        <path
          d="M924.51,525.25h-.77v0a1.74,1.74,0,0,1,.6.53,1.6,1.6,0,0,1,.26,1,1.7,1.7,0,0,1-.48,1.31,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.39,5.39,0,0,0-.63-1.17.71.71,0,0,0-.55-.29H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.11,1.11,0,0,0,.33.89,1.4,1.4,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.33,1.33,0,0,0-.34-.94,1.31,1.31,0,0,0-1-.36h-3a.39.39,0,0,1,.14.12l.17.23q.09.14.21.36c.08.15.18.34.29.58a5.58,5.58,0,0,0,.28.51,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.28A1,1,0,0,0,923.91,526.55Z"
          fill="#888685"
        />
        <path
          d="M916.86,520.67v.13a1.37,1.37,0,0,0,.34,1,1.28,1.28,0,0,0,1,.38h6.33V523H916v-.83h1v0a2.4,2.4,0,0,1-.39-.2,1.4,1.4,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.35,1.35,0,0,1-.1-.55Z"
          fill="#888685"
        />
        <path
          d="M921.57,515.22h.84a2.36,2.36,0,0,1,1.63.52,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.37,2.37,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.4h2.17a1.61,1.61,0,0,0,1.1-.35,1.27,1.27,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.4V516h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.22,1.22,0,0,0,.39,1,1.59,1.59,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <polygon
          points="1043.9 390.5 1014.2 380.5 984.5 390.5 984.5 258.5 1014.2 268.5 1043.9 258.5 1043.9 390.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1249.9 390.5 1220.2 380.5 1190.5 390.5 1190.5 258.5 1220.2 268.5 1249.9 258.5 1249.9 390.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1045.2 224.2 1055.2 194.5 1045.2 164.8 1177.2 164.8 1167.2 194.5 1177.2 224.2 1045.2 224.2"
          fill="#e1e0e0"
        />
        <polygon
          points="1175.09 528.52 1082.12 528.52 1082.12 452.5 1008.1 452.5 1008.1 471.19 986.91 471.19 986.91 617 1008.1 617 1082.12 617 1082.12 593.32 1175.09 593.32 1175.09 528.52"
          fill="#e1e0e0"
        />
        <path
          d="M1141,58.71V-3h-26V53.61l-408.91,54.2a62,62,0,0,0-118,12.73L207,173.69V670h26V196.31l355.77-49.62A62,62,0,0,0,711,133.4l413.29-54.79L1292,211.29v261L1172,663.08l22,13.84,124-197.17v-281ZM649,168a36,36,0,1,1,36-36A36,36,0,0,1,649,168Z"
          fill="#f7f7f7"
        />
        <path
          d="M1005.48,549.33h3.74V550h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1014.51,557.58a2.14,2.14,0,0,1-.48,1.48,2,2,0,0,1-1.54.53A1.87,1.87,0,0,1,1011,559a2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.21,1.21,0,0,0,.94-.32,1.43,1.43,0,0,0,.29-1v-.26a1.5,1.5,0,0,0-.21-.81,3.78,3.78,0,0,0-.81-.81l-.87-.7a4.25,4.25,0,0,1-1-1,1.83,1.83,0,0,1-.31-1.1v-.24a1.86,1.86,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.15,2.15,0,0,1,.45,1.48v.62h-.77v-.65a1.45,1.45,0,0,0-.28-1,1.11,1.11,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81V553a1.2,1.2,0,0,0,0,.38,1,1,0,0,0,.15.33,3.8,3.8,0,0,0,.29.33,5.71,5.71,0,0,0,.47.39l.87.7c.25.2.46.38.64.55a2.91,2.91,0,0,1,.41.5,2,2,0,0,1,.22.53,3,3,0,0,1,.06.63Z"
          fill="#888685"
        />
        <path
          d="M1020.08,556.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53A1.67,1.67,0,0,0,1019,552a1.16,1.16,0,0,0-1-.38,1.18,1.18,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1024.79,553a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V551h.82v.83h0a2,2,0,0,1,.55-.66,1.73,1.73,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.43,1.54v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1026.8,551h.66v-1.67h.82V551h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.61,1.61,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1034,559.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V553a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1032.66,558.9Z"
          fill="#888685"
        />
        <path
          d="M1036.22,549.33h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1040.64,549.33h4.19V550h-1.67v9.48h-.85V550h-1.67Z"
          fill="#888685"
        />
        <path
          d="M1050.26,557.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.58.61,2,2,0,0,1-1.57-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.57-.6,2,2,0,0,1,1.58.6,2.46,2.46,0,0,1,.54,1.68Zm-2.12,1.59a1.22,1.22,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.22,1.22,0,0,0-1-.39,1.19,1.19,0,0,0-1,.39,1.58,1.58,0,0,0-.34,1.09v4.34a1.58,1.58,0,0,0,.34,1.09A1.19,1.19,0,0,0,1048.14,558.9Z"
          fill="#888685"
        />
        <path
          d="M1058.12,551l-1.37,8.5h-.81l-1.18-6.52-1.22,6.52h-.81l-1.38-8.5h.83l1,6.58h0l1.23-6.58h.7l1.22,6.58h0l1-6.58Z"
          fill="#888685"
        />
        <path
          d="M1063.43,556.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1060v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.16,1.16,0,0,0-1-.38,1.18,1.18,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1067.19,551.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V551h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1073.33,549l-2.18,11.82h-.72l2.18-11.82Z" fill="#888685" />
        <path
          d="M1005.24,565.33h4.19V566h-1.67v9.48h-.85V566h-1.67Z"
          fill="#888685"
        />
        <path
          d="M1013.93,569a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V565.33h.82v2.5h0a2,2,0,0,1,.55-.66,1.73,1.73,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.43,1.54v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1020.36,572.56v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.05,2.05,0,0,1,1.58-.59,2,2,0,0,1,1.56.57,2.37,2.37,0,0,1,.52,1.64v2.12H1017v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.16,1.16,0,0,0-1-.38,1.22,1.22,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1025,570.37v5.13h-.86V565.33h2a2.17,2.17,0,0,1,1.58.5,2,2,0,0,1,.49,1.42v1.5a2,2,0,0,1-.49,1.44,2.14,2.14,0,0,1-1.62.52h0l2.45,4.79h-1Zm0-4.35v4h1.06a1.34,1.34,0,0,0,1-.32,1.22,1.22,0,0,0,.31-.9v-1.59q0-1.2-1.29-1.2Z"
          fill="#888685"
        />
        <path
          d="M1029.66,565.33h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M1031.7,567h.66v-1.67h.83V567h1.18v.62h-1.18v6.49a.71.71,0,0,0,.16.54.75.75,0,0,0,.56.16h.46v.69h-.55a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.33-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1036.43,574.81h2.73v.69h-3.59v-.69l2.79-7.12h-2.59V567h3.45v.69Z"
          fill="#888685"
        />
        <path
          d="M1045.19,575.59a2.47,2.47,0,0,1-1-.2,1.93,1.93,0,0,1-.7-.55,2.41,2.41,0,0,1-.4-.8,3.87,3.87,0,0,1-.13-1v-5.28a4,4,0,0,1,.13-1,2.36,2.36,0,0,1,.4-.81,1.81,1.81,0,0,1,.7-.54,2.47,2.47,0,0,1,1-.2,2.69,2.69,0,0,1,1,.17,1.48,1.48,0,0,1,.66.47,1.87,1.87,0,0,1,.34.74,4.75,4.75,0,0,1,.09,1v1.2h-.84v-1.27a2.25,2.25,0,0,0-.25-1.16,1.13,1.13,0,0,0-1-.42,1.46,1.46,0,0,0-.66.14,1.29,1.29,0,0,0-.44.36,1.63,1.63,0,0,0-.25.55,2.81,2.81,0,0,0-.07.67v5.53a2.87,2.87,0,0,0,.07.67,1.63,1.63,0,0,0,.25.55,1.29,1.29,0,0,0,.44.36,1.34,1.34,0,0,0,.66.14,1.14,1.14,0,0,0,1-.44,2.29,2.29,0,0,0,.26-1.14v-1.81h.82v1.74a4.42,4.42,0,0,1-.09.92,1.86,1.86,0,0,1-.35.75,1.68,1.68,0,0,1-.66.49A2.59,2.59,0,0,1,1045.19,575.59Z"
          fill="#888685"
        />
        <path
          d="M1051.91,575.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V569a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1050.6,574.9Z"
          fill="#888685"
        />
        <path
          d="M1056.48,567.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V567h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1057.76,565.33h.82V575.5h-.82Z" fill="#888685" />
        <path
          d="M1059.8,567h.66v-1.67h.82V567h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1068,573.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.57.61,2,2,0,0,1-1.58-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.57.6,2.46,2.46,0,0,1,.54,1.68Zm-2.11,1.59a1.19,1.19,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.19,1.19,0,0,0-1-.39,1.22,1.22,0,0,0-1,.39,1.58,1.58,0,0,0-.34,1.09v4.34a1.58,1.58,0,0,0,.34,1.09A1.22,1.22,0,0,0,1065.89,574.9Z"
          fill="#888685"
        />
        <path
          d="M1072.71,569a1.63,1.63,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.33,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83V567h.83v.83h0a2.06,2.06,0,0,1,.56-.66,1.7,1.7,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.44,1.54v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1077.06,575.5l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M1083.35,565.33h.82V575.5h-.82Z" fill="#888685" />
        <path
          d="M1088.7,568.88a1.57,1.57,0,0,0-.25-1,1,1,0,0,0-.81-.32,1.09,1.09,0,0,0-.85.35,1.36,1.36,0,0,0-.32,1v6.57h-.83V567h.83v.82h0a1.78,1.78,0,0,1,.52-.65,1.56,1.56,0,0,1,.94-.26,1.4,1.4,0,0,1,1.45,1h0a1.58,1.58,0,0,1,.56-.72,1.69,1.69,0,0,1,1-.28c1.07,0,1.61.65,1.61,2v6.64h-.83v-6.62a1.64,1.64,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.13,1.13,0,0,0-.81.32,1.33,1.33,0,0,0-.36.89v6.69h-.83Z"
          fill="#888685"
        />
        <path
          d="M1097.19,575.5v-.76h0a1.68,1.68,0,0,1-.52.59,1.62,1.62,0,0,1-1,.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.52,1.52,0,0,1,.37-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.46-.24.73-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V569a1.85,1.85,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.79v-.76a2.13,2.13,0,0,1,.48-1.48,1.91,1.91,0,0,1,1.51-.53,1.75,1.75,0,0,1,1.46.53A2.48,2.48,0,0,1,1098,569v6.5Zm-1.3-.6a1.23,1.23,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.72,1.72,0,0,1-.22.17l-.37.22-.57.28a5.45,5.45,0,0,0-.51.29,1.39,1.39,0,0,0-.37.3,1.22,1.22,0,0,0-.21.39,1.91,1.91,0,0,0-.08.57v.64a1.58,1.58,0,0,0,.27,1A1.06,1.06,0,0,0,1095.89,574.9Z"
          fill="#888685"
        />
        <path
          d="M1099.19,567h.66v-1.67h.82V567h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1107.26,567l-2,8.61a2.63,2.63,0,0,1-.19.58,1.17,1.17,0,0,1-.29.39,1,1,0,0,1-.45.21,2.77,2.77,0,0,1-.62.06h-.42v-.69h.41a.75.75,0,0,0,.56-.16,1.54,1.54,0,0,0,.28-.59l.19-.74h-.19L1102.8,567h.84l1.38,6.33h0l1.41-6.33Z"
          fill="#888685"
        />
        <path
          d="M1082.48,303.14h3.74v.69h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1091.51,311.39a2.13,2.13,0,0,1-.48,1.48,2,2,0,0,1-1.54.53,1.87,1.87,0,0,1-1.49-.56,2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.18,1.18,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-.95-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.77v-.65a1.45,1.45,0,0,0-.28-1,1.11,1.11,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.13,1.13,0,0,0,.05.38.89.89,0,0,0,.15.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M1097.08,310.37v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67V307a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1101.79,306.76a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1103.8,304.81h.66v-1.67h.82v1.67h1.19v.63h-1.19v6.48a.8.8,0,0,0,.16.55.82.82,0,0,0,.56.15h.47v.69h-.56a1.56,1.56,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1111,313.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1109.66,312.71Z"
          fill="#888685"
        />
        <path
          d="M1113.22,303.14h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1117.6,313.31l2-10.17h1.05l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path
          d="M1123.88,304.81h.83v.85h0a2.15,2.15,0,0,1,.57-.65,1.65,1.65,0,0,1,1-.29,1.62,1.62,0,0,1,1.28.49,2.19,2.19,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.57,1.57,0,0,1-1-.28,1.78,1.78,0,0,1-.52-.62h0v2.16h-.83Zm3.35,2a1.57,1.57,0,0,0-.29-1,1.17,1.17,0,0,0-.93-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1132.66,313.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1131.35,312.71Z"
          fill="#888685"
        />
        <path
          d="M1137.23,305.66h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M1138.25,304.81h.66v-1.67h.83v1.67h1.18v.63h-1.18v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1145.32,306.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.09,1.09,0,0,0-.84.35,1.38,1.38,0,0,0-.33,1v6.57h-.82v-8.5h.82v.82h0a1.59,1.59,0,0,1,.52-.64,1.47,1.47,0,0,1,.93-.27,1.41,1.41,0,0,1,1.46,1h0a1.58,1.58,0,0,1,.56-.72,1.69,1.69,0,0,1,1-.28c1.07,0,1.6.65,1.6,1.95v6.64h-.82V306.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.1,1.1,0,0,0-.81.33,1.29,1.29,0,0,0-.36.88v6.69h-.82Z"
          fill="#888685"
        />
        <path
          d="M1154.81,310.37v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.55-1.67V307a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2H1154v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1159.52,306.76a1.58,1.58,0,0,0-.27-1,1.1,1.1,0,0,0-.9-.34,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1161.53,304.81h.66v-1.67h.83v1.67h1.18v.63H1163v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1169.45,311.39a2.13,2.13,0,0,1-.48,1.48,2,2,0,0,1-1.54.53,1.87,1.87,0,0,1-1.49-.56,2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.18,1.18,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-1-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.78v-.65a1.45,1.45,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.48,1.48,0,0,0,0,.38,1.12,1.12,0,0,0,.16.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M220.42,676.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18H224l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M226.71,666.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M228.85,671.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M232.18,666.64h3.63v.69H233v4h2.08V672H233v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M240.21,676.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.16,1.16,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V671h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.87.87,0,0,1-.13.15l-.23.16a2.59,2.59,0,0,1-.36.22l-.57.28a5.74,5.74,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,238.9,676.21Z"
          fill="#888685"
        />
        <path
          d="M244.78,669.16h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.54,1.54,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M249.22,676.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V671h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.58.58,0,0,1-.13.15l-.22.16a3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,247.92,676.21Z"
          fill="#888685"
        />
        <path
          d="M251.47,666.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.7,1.7,0,0,1,1-.29,1.63,1.63,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.58,1.58,0,0,1-1-.27,2.07,2.07,0,0,1-.53-.63h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M257.08,666.64h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M264.91,676.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V671h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.58.58,0,0,1-.13.15l-.22.16a3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,263.61,676.21Z"
          fill="#888685"
        />
        <path
          d="M271.24,668.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.39-6.93Z"
          fill="#888685"
        />
        <path
          d="M276.49,673.87v.84a2.34,2.34,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.56.58,2.34,2.34,0,0,1,.52,1.64v2.11H273.1v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.68,1.68,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.58,1.58,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M281.2,670.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.36,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M284.26,674.86q0,1.35,1.17,1.35a1.27,1.27,0,0,0,.89-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.82v8.5h-.82V676h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.43-1.53v-6.58h.82Z"
          fill="#888685"
        />
        <path
          d="M293.17,673.87v.84a2.34,2.34,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.56.58,2.34,2.34,0,0,1,.52,1.64v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.68,1.68,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.58,1.58,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M697.81,676.81l2-10.17h1l2,10.17H702l-.48-2.49H699.1l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M704.1,666.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M706.24,671.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M709.57,666.64h3.63v.69h-2.77v4h2.08V672h-2.08v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M717.6,676.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.14,1.14,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V671h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.87.87,0,0,1-.13.15l-.23.16a2.59,2.59,0,0,1-.36.22l-.57.28a4.63,4.63,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,716.29,676.21Z"
          fill="#888685"
        />
        <path
          d="M722.17,669.16H722a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.54,1.54,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M726.61,676.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.57,1.57,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.38,2.38,0,0,1,.56-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V671h-.8v-.76a2.15,2.15,0,0,1,.49-1.48,1.94,1.94,0,0,1,1.51-.53,1.79,1.79,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.58.58,0,0,1-.13.15l-.22.16a3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1,1,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,725.31,676.21Z"
          fill="#888685"
        />
        <path
          d="M728.86,666.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.68,1.68,0,0,1,1-.29,1.64,1.64,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.58,1.58,0,0,1-1-.27,2.07,2.07,0,0,1-.53-.63h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.21,1.21,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.31,1.31,0,0,0,.37,1,1.3,1.3,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M734.47,666.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M742.3,676.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.78,1.78,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V671h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.58.58,0,0,1-.13.15l-.22.16a3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,741,676.21Z"
          fill="#888685"
        />
        <path
          d="M748.63,668.31l-1.77,8.5h-.93l-1.77-8.5H745l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M753.88,673.87v.84a2.34,2.34,0,0,1-.51,1.63,2,2,0,0,1-1.55.56,2,2,0,0,1-1.59-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.37,2.37,0,0,1,3.13,0,2.34,2.34,0,0,1,.53,1.64v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.68,1.68,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.58,1.58,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M758.59,670.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M761.65,674.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V676h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M770.56,673.87v.84a2.34,2.34,0,0,1-.51,1.63,2,2,0,0,1-1.55.56,2,2,0,0,1-1.59-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.37,2.37,0,0,1,3.13,0,2.34,2.34,0,0,1,.53,1.64v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.68,1.68,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.58,1.58,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1175.2,676.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path d="M1181.48,666.64h.83v10.17h-.83Z" fill="#888685" />
        <path d="M1183.63,671.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M1187,666.64h3.63v.69h-2.78v4h2.09V672h-2.09v4.83H1187Z"
          fill="#888685"
        />
        <path
          d="M1195,676.81v-.76h0a1.73,1.73,0,0,1-.53.59,1.53,1.53,0,0,1-1,.26,1.68,1.68,0,0,1-1.31-.48,2.19,2.19,0,0,1-.46-1.53v-.69a2.49,2.49,0,0,1,.13-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,.94.94,0,0,0-.86-.34,1.14,1.14,0,0,0-.89.32,1.44,1.44,0,0,0-.28.94V671h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.37,1.37,0,0,0,.94-.33,1.35,1.35,0,0,0,.36-1v-3a.53.53,0,0,1-.12.15l-.23.16a2.25,2.25,0,0,1-.37.22c-.14.08-.34.17-.57.28a5.45,5.45,0,0,0-.51.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.79,1.79,0,0,0-.08.56v.64a1.7,1.7,0,0,0,.27,1A1.09,1.09,0,0,0,1193.68,676.21Z"
          fill="#888685"
        />
        <path
          d="M1199.56,669.16h-.14a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.37,1v6.33h-.83v-8.5h.83v1.05h0a2.4,2.4,0,0,1,.2-.39,1.21,1.21,0,0,1,.29-.35,1.42,1.42,0,0,1,1-.36Z"
          fill="#888685"
        />
        <path
          d="M1204,676.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V671h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.58.58,0,0,1-.13.15l-.22.16a3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1202.69,676.21Z"
          fill="#888685"
        />
        <path
          d="M1206.25,666.64h.82v2.52h0a2.26,2.26,0,0,1,.56-.65,1.67,1.67,0,0,1,1-.29,1.65,1.65,0,0,1,1.29.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.82,1.82,0,0,1-1.34.48,1.58,1.58,0,0,1-1-.27,2,2,0,0,1-.52-.63h0v.81h-.82Zm3.34,3.63a1.51,1.51,0,0,0-.29-1,1.13,1.13,0,0,0-.92-.34,1.25,1.25,0,0,0-.92.36,1.45,1.45,0,0,0-.39,1v4.62a1.36,1.36,0,0,0,.38,1,1.29,1.29,0,0,0,.91.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1211.86,666.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1219.69,676.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V671h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.58.58,0,0,1-.13.15l-.22.16a3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1218.38,676.21Z"
          fill="#888685"
        />
        <path
          d="M1226,668.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M1231.27,673.87v.84a2.34,2.34,0,0,1-.51,1.63,2,2,0,0,1-1.55.56,2,2,0,0,1-1.59-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.37,2.37,0,0,1,3.13,0,2.34,2.34,0,0,1,.53,1.64v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.69,1.69,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.58,1.58,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1236,670.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58H1236Z"
          fill="#888685"
        />
        <path
          d="M1239,674.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V676h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M1248,673.87v.84a2.34,2.34,0,0,1-.51,1.63,2,2,0,0,1-1.55.56,2,2,0,0,1-1.59-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.37,2.37,0,0,1,3.13,0,2.34,2.34,0,0,1,.53,1.64v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.69,1.69,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.58,1.58,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <rect x="300.43" y="626.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="314.27"
          y="631.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="314.27"
          y1="634.1"
          x2="326.58"
          y2="634.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="314.27"
          y1="639.96"
          x2="326.58"
          y2="639.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="320.43"
          y1="634.1"
          x2="320.43"
          y2="639.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="316"
          cy="641.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="324.61"
          cy="641.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="315.38"
          y="643.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="323.99"
          y="643.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect x="145.43" y="734.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="159.27"
          y="739.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="159.27"
          y1="742.1"
          x2="171.58"
          y2="742.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="159.27"
          y1="747.96"
          x2="171.58"
          y2="747.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="165.43"
          y1="742.1"
          x2="165.43"
          y2="747.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="161"
          cy="749.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="169.61"
          cy="749.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="160.38"
          y="751.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="168.99"
          y="751.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="509.5"
          y1="611.5"
          x2="754.5"
          y2="611.75"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a70710bd-3360-4280-a21d-bebf7c1142b7" data-name="Outline">
        <polygon
          points="757.67 214.44 424.25 284.45 424.25 459.92 551.58 459.92 551.58 345.27 583.96 345.27 583.96 319.02 758.11 319.02 757.67 214.44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="864.5 618.5 417.5 618.5 417.5 279.51 864.13 185 864.5 618.5"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="af649574-b529-4fc4-9621-02bd79e854d1" data-name="B0.1">
        <rect
          x="468.5"
          y="538.5"
          width="53"
          height="28.56"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e8cacdd4-be55-4570-84e5-cd6ee2940d0e" data-name="B0.2">
        <rect
          x="521.5"
          y="538.5"
          width="31"
          height="28.56"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ad349718-0609-46af-9164-f860736ab5ca" data-name="B0.3">
        <rect
          x="552.5"
          y="538.5"
          width="42"
          height="28.56"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="af608eb7-e325-479d-a182-b45f019682c3" data-name="B0.4">
        <rect
          x="594.5"
          y="538.5"
          width="42"
          height="28.56"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a7da5fdd-437a-4fd8-82b8-3388786ba5e8" data-name="B0.5">
        <polygon
          points="636.5 538.5 660.5 538.5 660.5 511.5 695.5 511.5 695.5 567.06 636.5 567.06 636.5 538.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a8d646b8-2f34-4519-ac06-fc062f0998ad" data-name="B0.6">
        <rect
          x="695.5"
          y="511.5"
          width="26.73"
          height="55.56"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a319c6d3-e294-4954-a4b3-411eb5e7e608" data-name="B0.7">
        <rect
          x="722.23"
          y="511.5"
          width="59.95"
          height="55.56"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="534.5 611.5 509.58 611.75 509.58 567.06 534.95 567.06 534.5 611.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a1b23845-cfb2-4730-807c-ee17a0019ff8" data-name="B0.8">
        <polygon
          points="709.11 611.5 682 611.75 682 567.06 709.11 567.06 709.11 611.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="eef43d7a-a60f-4183-be1a-9744e1a6027a" data-name="B0.9">
        <rect
          x="535"
          y="585"
          width="147"
          height="26.75"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b1713e92-9d4f-47a3-bd25-3ac32d223e2c" data-name="B1.8">
        <rect
          x="594.62"
          y="567.06"
          width="87.38"
          height="18.38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a0ccbe90-4d1b-456b-8b25-46d4968869de" data-name="B1.24">
        <rect
          x="722.23"
          y="538.68"
          width="32.38"
          height="46.76"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f5663c04-7799-4eff-9157-451a600fbd09" data-name="B1.6a">
        <rect
          x="569.08"
          y="567.06"
          width="25.54"
          height="18.38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a8b6e480-4399-49d3-85f9-40f9e0df223c" data-name="B1.5">
        <rect
          x="534.95"
          y="567.06"
          width="34.13"
          height="18.38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="be281b9c-fecb-4bc2-9d78-455bbe38c0a3" data-name="B1.K5">
        <rect
          x="568.96"
          y="551.8"
          width="11.38"
          height="7.88"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b06a86b7-de62-4e21-8836-e3e9722906a4" data-name="B1.K7">
        <rect
          x="600.3"
          y="551.8"
          width="11.38"
          height="7.88"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e9ffcbaa-518d-4a96-8cd0-2b23ef827233" data-name="B1.K4">
        <rect
          x="631.64"
          y="551.8"
          width="11.38"
          height="7.88"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ff261d7a-15db-4393-beaa-16bd8f3f82ea" data-name="B1.19">
        <rect
          x="754.61"
          y="567.06"
          width="14.63"
          height="44.69"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b591c90c-35e5-472c-8998-4f240c9b7c14" data-name="B1.19a">
        <rect
          x="769.24"
          y="567.06"
          width="12.94"
          height="44.69"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ece3afe7-6c55-4e62-b163-3134908cf196" data-name="B1.18a">
        <rect
          x="782.18"
          y="538.68"
          width="75.26"
          height="73.07"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M794,569.66a2.85,2.85,0,0,1,1.15.2,2.09,2.09,0,0,1,.76.57,2.53,2.53,0,0,1,.43.85,3.85,3.85,0,0,1,.13,1v5.43a3.9,3.9,0,0,1-.13,1,2.39,2.39,0,0,1-.43.84,2.09,2.09,0,0,1-.76.57,2.85,2.85,0,0,1-1.15.21h-2.26V569.66Zm-1.35,10h1.23a1.54,1.54,0,0,0,1.31-.51,2.12,2.12,0,0,0,.38-1.27v-5.73a2.5,2.5,0,0,0-.09-.69,1.4,1.4,0,0,0-.29-.57,1.28,1.28,0,0,0-.52-.38,1.89,1.89,0,0,0-.79-.15h-1.23Z"
          fill="#1e0f00"
        />
        <path
          d="M802.41,577.3v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.16,2.16,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.59v2.3a1.72,1.72,0,0,0,.37,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.23,1.23,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M806.39,572.32h-.14a1.49,1.49,0,0,0-1,.36,1.41,1.41,0,0,0-.39,1v6.69h-.88v-9h.88v1.11h0a2.67,2.67,0,0,1,.2-.42,1.85,1.85,0,0,1,.32-.37,1.68,1.68,0,0,1,.45-.27,1.5,1.5,0,0,1,.58-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M811,573.42a1.7,1.7,0,0,0-.26-1,1,1,0,0,0-.87-.35,1.16,1.16,0,0,0-.89.37,1.49,1.49,0,0,0-.35,1v6.95h-.87v-9h.87v.87h0a1.71,1.71,0,0,1,.55-.68,1.6,1.6,0,0,1,1-.29,1.51,1.51,0,0,1,1.54,1.06h0a1.7,1.7,0,0,1,.6-.76,1.76,1.76,0,0,1,1-.3c1.13,0,1.7.69,1.7,2.06v7h-.87v-7a1.63,1.63,0,0,0-.27-1,1,1,0,0,0-.86-.35,1.18,1.18,0,0,0-.86.35,1.39,1.39,0,0,0-.38.94v7.07H811Z"
          fill="#1e0f00"
        />
        <path
          d="M820,580.41v-.81h0a1.66,1.66,0,0,1-.56.63,1.6,1.6,0,0,1-1,.28A1.8,1.8,0,0,1,817,580a2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.66,1.66,0,0,1,.38-.61,2.58,2.58,0,0,1,.61-.44l.77-.37a4.78,4.78,0,0,0,1.24-.67.77.77,0,0,0,.32-.58v-.62a1.91,1.91,0,0,0-.24-1.06,1,1,0,0,0-.9-.37,1.22,1.22,0,0,0-1,.34,1.52,1.52,0,0,0-.29,1v.88h-.84v-.81a2.26,2.26,0,0,1,.51-1.57,2.06,2.06,0,0,1,1.6-.56,1.88,1.88,0,0,1,1.54.56,2.69,2.69,0,0,1,.44,1.66v6.87Zm-1.38-.63a1.4,1.4,0,0,0,1-.36,1.38,1.38,0,0,0,.39-1.07v-3.17a.64.64,0,0,1-.14.15,2,2,0,0,1-.23.17,3.1,3.1,0,0,1-.39.23c-.16.09-.36.19-.61.3l-.54.3a1.78,1.78,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,1.82,1.82,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.28,1.07A1.14,1.14,0,0,0,818.58,579.78Z"
          fill="#1e0f00"
        />
        <path d="M822.34,569.66h.87v10.75h-.87Z" fill="#1e0f00" />
        <path
          d="M829.21,578.1a2.59,2.59,0,0,1-.57,1.76,2.12,2.12,0,0,1-1.67.65,2.1,2.1,0,0,1-1.66-.65,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.12,2.12,0,0,1,1.66-.64,2.15,2.15,0,0,1,1.67.64,2.58,2.58,0,0,1,.57,1.77ZM827,579.78a1.28,1.28,0,0,0,1-.41,1.71,1.71,0,0,0,.35-1.16v-4.59a1.71,1.71,0,0,0-.35-1.16,1.28,1.28,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.3,1.3,0,0,0,827,579.78Z"
          fill="#1e0f00"
        />
        <path
          d="M835.11,571.42v8.29a2.41,2.41,0,0,1-.52,1.62,2.12,2.12,0,0,1-1.67.61,2.26,2.26,0,0,1-1.66-.54,1.93,1.93,0,0,1-.53-1.4v-.43h.86V580a1.28,1.28,0,0,0,.31.93,1.33,1.33,0,0,0,1,.33,1.26,1.26,0,0,0,1-.38,1.53,1.53,0,0,0,.31-1v-1.7h0a2.06,2.06,0,0,1-.54.62,1.6,1.6,0,0,1-1,.3,1.87,1.87,0,0,1-1.42-.52,2.26,2.26,0,0,1-.5-1.62v-3.45a2.1,2.1,0,0,1,.55-1.62,1.89,1.89,0,0,1,1.37-.52,2.13,2.13,0,0,1,.6.08,1.55,1.55,0,0,1,.45.21,1.28,1.28,0,0,1,.32.29,4,4,0,0,1,.22.34h0v-.82Zm-3.54,5.46a1.64,1.64,0,0,0,.3,1.07,1.15,1.15,0,0,0,1,.37,1.39,1.39,0,0,0,1-.37,1.52,1.52,0,0,0,.41-1V573.5a1.54,1.54,0,0,0-.42-1.07,1.34,1.34,0,0,0-1-.38c-.86,0-1.29.48-1.29,1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M836.65,569.66h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M843.43,577.3v.89a2.56,2.56,0,0,1-.52,1.72,2,2,0,0,1-1.61.6,2.13,2.13,0,0,1-1.68-.65,2.59,2.59,0,0,1-.57-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.1,2.1,0,0,1,1.66-.64,2.07,2.07,0,0,1,1.62.58,2.48,2.48,0,0,1,.53,1.71v.65h-.86v-.72a1.78,1.78,0,0,0-.3-1.12,1.22,1.22,0,0,0-1-.37,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15v4.6a1.71,1.71,0,0,0,.36,1.16,1.33,1.33,0,0,0,1,.41,1.2,1.2,0,0,0,1-.37,1.74,1.74,0,0,0,.31-1.14v-1Z"
          fill="#1e0f00"
        />
        <path
          d="M848.2,580.41v-.81h0a1.72,1.72,0,0,1-.55.63,1.64,1.64,0,0,1-1,.28,1.82,1.82,0,0,1-1.4-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5.16,5.16,0,0,0,1.24-.67.76.76,0,0,0,.31-.58v-.62a1.91,1.91,0,0,0-.24-1.06,1,1,0,0,0-.9-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.88H845v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.88,1.88,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.4,1.4,0,0,0,1-.36,1.38,1.38,0,0,0,.39-1.07v-3.17a.58.58,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23l-.6.3-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,846.82,579.78Z"
          fill="#1e0f00"
        />
      </g>
      <g id="b14c6596-712f-4944-b031-2dd1339ffb00" data-name="B1.K1">
        <rect
          x="709.1"
          y="567.06"
          width="13.13"
          height="18.38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a8336eaf-dcfe-4857-9765-c04f04795813" data-name="B1.17">
        <polygon
          points="857.44 538.68 857.44 192.56 757.67 214.44 757.67 511.61 782.18 511.61 782.18 538.68 857.44 538.68"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M790.73,374.66h3.95v.72h-3v4.19h2.2v.73h-2.2v4.38h3v.73h-3.95Z"
          fill="#1e0f00"
        />
        <path
          d="M800.28,383.38a2.31,2.31,0,0,1-.5,1.57,2.14,2.14,0,0,1-1.64.56,2,2,0,0,1-1.58-.6,2.34,2.34,0,0,1-.51-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.3,1,1.19,1.19,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.58,1.58,0,0,0-.22-.85,4,4,0,0,0-.86-.86l-.92-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.83v-.68a1.57,1.57,0,0,0-.28-1,1.2,1.2,0,0,0-1-.33,1.17,1.17,0,0,0-.91.32,1.24,1.24,0,0,0-.28.86v.25a1.64,1.64,0,0,0,0,.4,1.14,1.14,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,1.7,1.7,0,0,1,.23.56,2.7,2.7,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
        <path
          d="M806.17,382.3v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.1,2.1,0,0,1,1.66-.64,2.18,2.18,0,0,1,1.66.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.59v2.3a1.72,1.72,0,0,0,.37,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M811.15,378.48a1.63,1.63,0,0,0-.29-1.07,1.15,1.15,0,0,0-.94-.36,1.3,1.3,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.88v-9h.88v.88h0a2.05,2.05,0,0,1,.59-.69,2,2,0,0,1,2.4.22,2.41,2.41,0,0,1,.46,1.62v7h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M813.28,376.42h.7v-1.76h.87v1.76h1.25v.66h-1.25v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.49v.73h-.58a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path
          d="M820.86,385.41v-.81h0a1.72,1.72,0,0,1-.55.63,1.64,1.64,0,0,1-1,.28,1.82,1.82,0,0,1-1.4-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5.16,5.16,0,0,0,1.24-.67.76.76,0,0,0,.31-.58v-.62a2,2,0,0,0-.23-1.06,1,1,0,0,0-.91-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.88h-.84v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.3,1.3,0,0,0,1.38-1.43v-3.17a.58.58,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.13-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,819.48,384.78Z"
          fill="#1e0f00"
        />
        <path
          d="M823.24,374.66h.88v.79h-.88Zm0,1.76h.88v9h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M790.73,388.66h3.84v.72h-2.93v4.19h2.2v.73h-2.2v5.11h-.91Z"
          fill="#1e0f00"
        />
        <path
          d="M795.87,388.66h.88v.79h-.88Zm0,1.76h.88v9h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M798,390.42h.7v-1.76h.87v1.76h1.25v.66H799.6v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.49v.73h-.58a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84H798Z"
          fill="#1e0f00"
        />
        <path
          d="M804.68,393.72h2v-2.27h.75v2.27h2v.73h-2v2.25h-.75v-2.25h-2Z"
          fill="#1e0f00"
        />
        <path
          d="M815.63,399.51a2,2,0,0,1-2.3-2.19v-1.67h.87v1.69a1.54,1.54,0,0,0,.35,1.06,1.43,1.43,0,0,0,1.09.38,1.22,1.22,0,0,0,1-.42,1.56,1.56,0,0,0,.33-1v-.57a2.47,2.47,0,0,0-.06-.56,1.64,1.64,0,0,0-.18-.48,2.31,2.31,0,0,0-.33-.46c-.14-.16-.31-.33-.51-.52l-1.1-1.08c-.25-.24-.47-.47-.65-.67a3.91,3.91,0,0,1-.45-.6,2.42,2.42,0,0,1-.27-.64,2.83,2.83,0,0,1-.09-.74v-.41a1.94,1.94,0,0,1,.58-1.49,2.22,2.22,0,0,1,1.61-.56,2.32,2.32,0,0,1,1.68.53,2.17,2.17,0,0,1,.53,1.58v1.17h-.86v-1.21a1.36,1.36,0,0,0-.33-1,1.43,1.43,0,0,0-1-.32,1.37,1.37,0,0,0-1,.33,1.21,1.21,0,0,0-.37,1V391a1.62,1.62,0,0,0,.3,1,7.5,7.5,0,0,0,.86.95l1.09,1.08c.25.25.46.47.64.67a3,3,0,0,1,.44.61,2.63,2.63,0,0,1,.27.65,3.57,3.57,0,0,1,.08.77v.59a2.8,2.8,0,0,1-.13.88,1.85,1.85,0,0,1-.42.7,2,2,0,0,1-.72.46A2.57,2.57,0,0,1,815.63,399.51Z"
          fill="#1e0f00"
        />
        <path
          d="M819.37,390.42h.87v.9h0a2.4,2.4,0,0,1,.6-.69,1.68,1.68,0,0,1,1.1-.31,1.71,1.71,0,0,1,1.35.52,2.31,2.31,0,0,1,.47,1.62v4.91a2.22,2.22,0,0,1-.51,1.62,1.84,1.84,0,0,1-1.41.52,1.64,1.64,0,0,1-1.05-.3,1.86,1.86,0,0,1-.55-.65h0v2.28h-.87Zm3.54,2.08a1.6,1.6,0,0,0-.31-1.08,1.2,1.2,0,0,0-1-.37,1.3,1.3,0,0,0-1,.38,1.58,1.58,0,0,0-.42,1.07v4.88a1.41,1.41,0,0,0,.4,1,1.37,1.37,0,0,0,1,.36c.87,0,1.3-.48,1.3-1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M828.65,399.41v-.81h0a1.64,1.64,0,0,1-1.55.91,1.82,1.82,0,0,1-1.4-.51,2.31,2.31,0,0,1-.47-1.62v-.73a2.51,2.51,0,0,1,.13-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5,5,0,0,0,1.24-.67.76.76,0,0,0,.31-.58v-.62a1.92,1.92,0,0,0-.23-1.06,1,1,0,0,0-.91-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.88h-.84v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.28,1.28,0,0,0,1.38-1.43v-3.17a.58.58,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.13-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,827.27,398.78Z"
          fill="#1e0f00"
        />
      </g>
      <g id="f52b6542-0220-4624-8257-b4c58b571138" data-name="B1.3">
        <polygon
          points="757.67 511.61 681.6 511.61 681.6 413.53 646.97 413.53 646.97 386.4 625.97 386.4 625.97 319.02 757.67 319.02 757.67 511.61"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M690.92,385.51a2,2,0,0,1-2.3-2.19v-1.67h.87v1.69a1.54,1.54,0,0,0,.35,1.06,1.45,1.45,0,0,0,1.1.38,1.25,1.25,0,0,0,1-.42,1.61,1.61,0,0,0,.32-1v-.57a3.24,3.24,0,0,0,0-.56,1.66,1.66,0,0,0-.19-.48,2,2,0,0,0-.33-.46c-.14-.16-.31-.33-.51-.52l-1.09-1.08c-.26-.24-.47-.47-.65-.67a3.91,3.91,0,0,1-.45-.6,1.9,1.9,0,0,1-.27-.64,2.83,2.83,0,0,1-.09-.74v-.41a2,2,0,0,1,.57-1.49,2.23,2.23,0,0,1,1.62-.56,2.29,2.29,0,0,1,1.67.53,2.13,2.13,0,0,1,.53,1.58v1.17h-.85v-1.21a1.32,1.32,0,0,0-.34-1,1.42,1.42,0,0,0-1-.32,1.39,1.39,0,0,0-1,.33,1.24,1.24,0,0,0-.36,1V377a1.62,1.62,0,0,0,.3,1,7.32,7.32,0,0,0,.85.95l1.1,1.08.63.67a3.54,3.54,0,0,1,.45.61,2.8,2.8,0,0,1,.35,1.42v.59a2.8,2.8,0,0,1-.14.88,1.85,1.85,0,0,1-.42.7,2.08,2.08,0,0,1-.71.46A2.66,2.66,0,0,1,690.92,385.51Z"
          fill="#1e0f00"
        />
        <path
          d="M695.51,383.35c0,.95.41,1.43,1.23,1.43a1.34,1.34,0,0,0,.94-.34,1.36,1.36,0,0,0,.41-1v-7H699v9h-.88v-.89h0a1.63,1.63,0,0,1-1.65,1,1.73,1.73,0,0,1-1.34-.51,2.41,2.41,0,0,1-.46-1.62v-7h.88Z"
          fill="#1e0f00"
        />
        <path d="M700.52,374.66h.87v10.75h-.87Z" fill="#1e0f00" />
        <path
          d="M703,376.42h.87v.9h0a2.22,2.22,0,0,1,.59-.69,1.73,1.73,0,0,1,1.11-.31,1.71,1.71,0,0,1,1.35.52,2.36,2.36,0,0,1,.47,1.62v4.91a2.22,2.22,0,0,1-.51,1.62,1.87,1.87,0,0,1-1.41.52,1.67,1.67,0,0,1-1.06-.3,1.83,1.83,0,0,1-.54-.65h0v2.28H703Zm3.54,2.08a1.66,1.66,0,0,0-.31-1.08,1.2,1.2,0,0,0-1-.37,1.34,1.34,0,0,0-1,.38,1.58,1.58,0,0,0-.41,1.07v4.88a1.38,1.38,0,0,0,.4,1,1.34,1.34,0,0,0,1,.36c.86,0,1.3-.48,1.3-1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M712.23,385.41v-.81h0a1.66,1.66,0,0,1-.56.63,1.6,1.6,0,0,1-1,.28,1.81,1.81,0,0,1-1.39-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.52,2.52,0,0,1,.6-.44c.23-.12.49-.24.78-.37a4.85,4.85,0,0,0,1.23-.67.77.77,0,0,0,.32-.58v-.62a1.91,1.91,0,0,0-.24-1.06,1,1,0,0,0-.9-.37,1.22,1.22,0,0,0-1,.34,1.52,1.52,0,0,0-.29,1v.88H709v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.88,1.88,0,0,1,1.54.56,2.63,2.63,0,0,1,.44,1.66v6.87Zm-1.38-.63a1.4,1.4,0,0,0,1-.36,1.38,1.38,0,0,0,.39-1.07v-3.17a.87.87,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.13-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.28,1.07A1.14,1.14,0,0,0,710.85,384.78Z"
          fill="#1e0f00"
        />
        <path
          d="M717.86,385.41l-2.38-4.58v4.58h-.87V374.66h.87v6l2.34-4.23h.88l-2.34,4.23,2.44,4.76Z"
          fill="#1e0f00"
        />
      </g>
      <g id="b79cc9a2-3f45-4fa5-899f-8cd66e223310" data-name="B1.4">
        <polygon
          points="681.6 511.61 660.6 511.61 660.6 538.68 424.25 538.68 424.25 459.92 551.58 459.92 551.58 345.27 583.96 345.27 583.96 319.02 625.97 319.02 625.97 386.4 646.97 386.4 646.97 413.53 681.6 413.53 681.6 511.61"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M511.73,490.66h3.95v.72h-3v4.19h2.2v.73h-2.2v4.38h3v.73h-3.95Z"
          fill="#1e0f00"
        />
        <path
          d="M521.28,499.38a2.31,2.31,0,0,1-.5,1.57,2.14,2.14,0,0,1-1.64.56,2,2,0,0,1-1.58-.6,2.34,2.34,0,0,1-.51-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.3,1,1.19,1.19,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.58,1.58,0,0,0-.22-.85,4,4,0,0,0-.86-.86l-.92-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.83v-.68a1.57,1.57,0,0,0-.28-1,1.2,1.2,0,0,0-1-.33,1.17,1.17,0,0,0-.91.32,1.24,1.24,0,0,0-.28.86v.25a1.64,1.64,0,0,0,0,.4,1.14,1.14,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,1.7,1.7,0,0,1,.23.56,2.7,2.7,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
        <path
          d="M527.17,498.3v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.1,2.1,0,0,1,1.66-.64,2.18,2.18,0,0,1,1.66.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.59v2.3a1.72,1.72,0,0,0,.37,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M532.15,494.48a1.63,1.63,0,0,0-.29-1.07,1.15,1.15,0,0,0-.94-.36,1.3,1.3,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.88v-9h.88v.88h0a2.05,2.05,0,0,1,.59-.69,2,2,0,0,1,2.4.22,2.41,2.41,0,0,1,.46,1.62v7h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M534.28,492.42h.7v-1.76h.87v1.76h1.25v.66h-1.25v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.49v.73h-.58a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path
          d="M541.86,501.41v-.81h0a1.72,1.72,0,0,1-.55.63,1.64,1.64,0,0,1-1,.28,1.82,1.82,0,0,1-1.4-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5.16,5.16,0,0,0,1.24-.67.76.76,0,0,0,.31-.58v-.62a2,2,0,0,0-.23-1.06,1,1,0,0,0-.91-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.88h-.84v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.3,1.3,0,0,0,1.38-1.43v-3.17a.58.58,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.13-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,540.48,500.78Z"
          fill="#1e0f00"
        />
        <path
          d="M544.24,490.66h.88v.79h-.88Zm0,1.76h.88v9h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M551.7,496h2.22v5.45h-.76v-.93a2,2,0,0,1-.55.72,1.79,1.79,0,0,1-1.15.31,2.26,2.26,0,0,1-1.05-.22,2,2,0,0,1-.72-.58,2.34,2.34,0,0,1-.4-.84,3.9,3.9,0,0,1-.13-1v-5.59a4,4,0,0,1,.14-1.05,2.28,2.28,0,0,1,.43-.85,2,2,0,0,1,.76-.57,2.6,2.6,0,0,1,1.1-.21,3.32,3.32,0,0,1,1.13.17,1.86,1.86,0,0,1,.71.5,1.88,1.88,0,0,1,.38.79,4.09,4.09,0,0,1,.11,1V494h-.87v-1a2.26,2.26,0,0,0-.29-1.23,1.28,1.28,0,0,0-1.15-.45,1.7,1.7,0,0,0-.71.14,1.33,1.33,0,0,0-.48.39,1.82,1.82,0,0,0-.27.58,3.06,3.06,0,0,0-.08.7V499a2.64,2.64,0,0,0,.08.7,1.59,1.59,0,0,0,.26.59,1.26,1.26,0,0,0,.47.38,1.47,1.47,0,0,0,.69.15,1.27,1.27,0,0,0,1.14-.49,2.16,2.16,0,0,0,.32-1.24v-2.37H551.7Z"
          fill="#1e0f00"
        />
        <path
          d="M560,499.1a2.59,2.59,0,0,1-.57,1.76,2.12,2.12,0,0,1-1.67.65,2.1,2.1,0,0,1-1.66-.65,2.59,2.59,0,0,1-.58-1.76v-4.37a2.58,2.58,0,0,1,.58-1.77,2.12,2.12,0,0,1,1.66-.64,2.15,2.15,0,0,1,1.67.64,2.58,2.58,0,0,1,.57,1.77Zm-2.24,1.68a1.28,1.28,0,0,0,1-.41,1.71,1.71,0,0,0,.35-1.16v-4.59a1.71,1.71,0,0,0-.35-1.16,1.28,1.28,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.3,1.3,0,0,0,557.76,500.78Z"
          fill="#1e0f00"
        />
        <path
          d="M562.36,499.35c0,.95.42,1.43,1.24,1.43a1.36,1.36,0,0,0,.94-.34,1.4,1.4,0,0,0,.41-1v-7h.87v9H565v-.89h0a1.81,1.81,0,0,1-.6.73,1.85,1.85,0,0,1-1.05.26A1.7,1.7,0,0,1,562,501a2.36,2.36,0,0,1-.46-1.62v-7h.87Z"
          fill="#1e0f00"
        />
        <path
          d="M569.84,493.32h-.15a1.45,1.45,0,0,0-1,.36,1.38,1.38,0,0,0-.4,1v6.69h-.87v-9h.87v1.11h0a2.06,2.06,0,0,1,.21-.42,1.58,1.58,0,0,1,.32-.37,1.48,1.48,0,0,1,.44-.27,1.52,1.52,0,0,1,.59-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M574.42,494.42a1.64,1.64,0,0,0-.26-1,1,1,0,0,0-.86-.35,1.2,1.2,0,0,0-.9.37,1.48,1.48,0,0,0-.34,1v7h-.87v-9h.87v.87h0a1.71,1.71,0,0,1,.55-.68,1.6,1.6,0,0,1,1-.29,1.49,1.49,0,0,1,1.54,1.06h0a1.7,1.7,0,0,1,.6-.76,1.76,1.76,0,0,1,1.05-.3c1.14,0,1.7.69,1.7,2.06v7h-.87v-7a1.7,1.7,0,0,0-.26-1,1,1,0,0,0-.87-.35,1.15,1.15,0,0,0-.85.35,1.34,1.34,0,0,0-.38.94v7.07h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M584.47,498.3v.89a2.46,2.46,0,0,1-.54,1.72,2.1,2.1,0,0,1-1.64.6,2.16,2.16,0,0,1-1.69-.65,2.59,2.59,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.52,2.52,0,0,1,3.32,0,2.51,2.51,0,0,1,.56,1.74v2.24h-3.59v2.3a1.67,1.67,0,0,0,.37,1.16,1.34,1.34,0,0,0,1.06.41c.89,0,1.33-.5,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.25,1.25,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M585.72,492.42h.7v-1.76h.87v1.76h1.25v.66h-1.25v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.49v.73H588a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
      </g>
      <g id="b0f0cd06-463f-4bb9-9c6d-449d831ecbe7" data-name="B1.23">
        <rect
          x="424.25"
          y="538.68"
          width="44.25"
          height="28.38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e954b64c-a0e4-4192-954a-a4869f3e920c" data-name="B1.22">
        <rect
          x="486.82"
          y="567.06"
          width="22.75"
          height="44.69"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a811b573-a199-49d0-9d2a-150b1f1037f7" data-name="B1.17C">
        <polygon
          points="807.5 538.68 782.18 538.68 782.18 511.61 757.5 511.5 757.5 421.5 807.5 421.5 807.5 538.68"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bcf6d106-670c-4dfa-8b76-edee80ef154e" data-name="WC">
        <rect
          x="424.25"
          y="567.06"
          width="62.57"
          height="44.69"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M453.69,587.75l-1.36,8.35h-1l-1.46-10.76h.89l1.13,8.66h0l1.38-8.66h.84L455.5,594h0l1.14-8.62h.83L456,596.1h-1l-1.33-8.35Z"
          fill="#ff4d00"
        />
        <path
          d="M461.17,596.19a2.65,2.65,0,0,1-1.11-.21,2,2,0,0,1-.73-.58,2.42,2.42,0,0,1-.42-.85,3.84,3.84,0,0,1-.14-1v-5.58a4,4,0,0,1,.14-1,2.47,2.47,0,0,1,.42-.86,2,2,0,0,1,.73-.57,2.65,2.65,0,0,1,1.11-.21,3,3,0,0,1,1.11.18,1.66,1.66,0,0,1,.7.5,2,2,0,0,1,.35.78,4.62,4.62,0,0,1,.11,1V589h-.89v-1.34a2.39,2.39,0,0,0-.27-1.23,1.18,1.18,0,0,0-1.1-.45,1.51,1.51,0,0,0-.7.15,1.16,1.16,0,0,0-.46.39,1.67,1.67,0,0,0-.27.57,3.17,3.17,0,0,0-.07.71v5.86a3.2,3.2,0,0,0,.07.7,1.82,1.82,0,0,0,.27.58,1.35,1.35,0,0,0,.46.39,1.51,1.51,0,0,0,.7.14,1.18,1.18,0,0,0,1.11-.47,2.37,2.37,0,0,0,.27-1.2v-1.91h.88v1.84a4.23,4.23,0,0,1-.11,1,2,2,0,0,1-.36.79,1.76,1.76,0,0,1-.7.52A2.71,2.71,0,0,1,461.17,596.19Z"
          fill="#ff4d00"
        />
      </g>
      <g id="ebaf63a1-70c2-403b-8ccc-c33bec703cb9" data-name="Icons">
        <polyline
          points="668 535.5 674.94 535.5 688.33 521 695.27 521"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="679.78"
          cy="518.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="678.59 531.41 678.59 521.64 680.97 521.64 680.97 529.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="654.73 607.5 661.67 607.5 675.06 593 682 593"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="666.51"
          cy="590.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="665.32 603.41 665.32 593.64 667.7 593.64 667.7 601.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="569.08 607.5 576.02 607.5 589.41 593 596.35 593"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="580.86"
          cy="590.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="579.68 603.41 579.68 593.64 582.05 593.64 582.05 601.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="687.1"
          y="596.37"
          width="19.96"
          height="12.38"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <path
          d="M690.6,606.6l1.44-7.44h1.13l1.45,7.44h-1l-.28-1.56H691.8l-.28,1.56Zm1.34-2.35h1.26l-.62-3.5h0Z"
          fill="#ff4d00"
        />
        <path
          d="M694.56,599.16h3.21V600h-1.11v6.65h-1V600h-1.12Z"
          fill="#ff4d00"
        />
        <path
          d="M702,601.14h0l-1,5.46h-.49l-1.07-5.46h0v5.46h-.81v-7.44h1.18l1,4.9h0l.91-4.9h1.29v7.44H702Z"
          fill="#ff4d00"
        />
        <rect
          x="535.1"
          y="596.37"
          width="19.96"
          height="12.38"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <path
          d="M538.6,606.6l1.44-7.44h1.13l1.45,7.44h-1l-.28-1.56H539.8l-.28,1.56Zm1.34-2.35h1.26l-.62-3.5h0Z"
          fill="#ff4d00"
        />
        <path
          d="M542.56,599.16h3.21V600h-1.11v6.65h-1V600h-1.12Z"
          fill="#ff4d00"
        />
        <path
          d="M550,601.14h0l-1,5.46h-.49l-1.07-5.46h0v5.46h-.81v-7.44h1.18l1,4.9h0l.91-4.9h1.29v7.44H550Z"
          fill="#ff4d00"
        />
        <circle
          cx="718.31"
          cy="598.3"
          r="1.35"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="716.92 609.9 716.92 601.15 719.7 601.15 719.7 609.9"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="712.43 590.4 715.06 587.78 715.14 587.78 717.76 590.4"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="724.18 587.78 721.55 590.4 721.47 590.4 718.85 587.78"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="712"
          y="593.54"
          width="12.61"
          height="16.46"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <circle
          cx="769.31"
          cy="439.3"
          r="1.35"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="767.92 450.9 767.92 442.15 770.7 442.15 770.7 450.9"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="763.43 431.4 766.06 428.78 766.14 428.78 768.76 431.4"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="775.18 428.78 772.55 431.4 772.47 431.4 769.85 428.78"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="763"
          y="434.54"
          width="12.61"
          height="16.46"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  </MapTransformer>
);

MinusFirstFloor.defaultProps = {
  mapWidth: 1420,
  mapHeight: 1001,
};

MinusFirstFloor.propTypes = {
  mapHeight: PropTypes.number,
  mapWidth: PropTypes.number,
  MapTransformer: PropTypes.func.isRequired,
};

export default MinusFirstFloor;
