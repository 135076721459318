import api from '@esentai/core/features/merchants/api';
import * as React from 'react';

import NoMatchMessage from '../../../../../components/CommonTable/NoMatchMessage';

export interface BinProps {
  merchId: number;
  children: (data: any) => JSX.Element;
}

export interface BinState {
  limit: number;
  page: number;
  merchBin: string;
  total: number;
}

export class Bin extends React.Component<BinProps, BinState> {
  constructor(props: BinProps) {
    super(props);

    this.state = {
      limit: 0,
      page: 0,
      total: 0,
      merchBin: '...',
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.loadData();
  }

  public render(): JSX.Element {
    const { merchBin } = this.state;

    return <>{merchBin};</>;
  }

  public renderBody(data: any[]): JSX.Element {
    const { children } = this.props;

    if (data.length > 0) {
      return children(data);
    }

    return <NoMatchMessage columnsCount={100} />;
  }

  private async loadData(): Promise<void> {
    const { limit, page } = this.state;
    const { merchId } = this.props;

    if (merchId) {
      const filters = [['id', 'eq', merchId]];

      const data = await api.doQuery(filters, 'id', 'asc', limit, page);

      if (data.payload.merchant.length > 0) {
        this.setState({
          total: data.meta.total,
          merchBin: data.payload.merchant[0].identification_number,
        });
      }
    }
  }
}
