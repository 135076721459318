import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import IconTooltip from '@/components/IconTooltip';
import {
  AVG_NR_PURCHASES_KEY,
  AVG_NR_RECEIPTS_KEY,
  AVG_TICKET_KEY,
  BY_CAMPAIGN_KEY,
  NR_RECEIPTS_KEY,
} from '@/features/purchasesStatistics/consts/keys';
import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={TIMEFRAME_KEY}>Дата</SortControl>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Количество покупок">
        <SortControl field={NR_RECEIPTS_KEY}>Покупки</SortControl>
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="В средней на покупателя">
        <SortControl field={AVG_NR_RECEIPTS_KEY}>В среднем покупок</SortControl>
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Среднее количество товаров">
        <SortControl field={AVG_NR_PURCHASES_KEY}>
          В среднем товаров
        </SortControl>
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Средний чек">
        <SortControl field={AVG_TICKET_KEY}>Средний чек</SortControl>
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Распределение по статусу покупателя">
        Распределение по статусу покупателя
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Распределение по времени покупки">
        Распределение по времени покупки
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Количество покупок по кампаниям">
        <SortControl field={BY_CAMPAIGN_KEY}>Покупки по кампаниям</SortControl>
      </IconTooltip>
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
