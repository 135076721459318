import { Grid } from '@material-ui/core';
import React, { Children } from 'react';

const ButtonSet = ({ classes, children, ...props }) => (
  <Grid justify="flex-end" {...props} container>
    {Children.map(children, button => (
      <Grid className={classes.buttonItem} item>
        {button}
      </Grid>
    ))}
  </Grid>
);

export default ButtonSet;
