import {
  ADDITIONAL_ENGLISH_TEXT_KEY,
  ADDITIONAL_RUSSIAN_TEXT_KEY,
  ENGLISH_TITLE_KEY,
  IMAGE_URL_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/calendarEvent/consts/keys';
import { Grid } from '@material-ui/core';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import ImageField from '@/components/ImageField';
import TextField from '@/components/TextField';
import limits from '@/features/campaignCreatePage/EditPage/limits';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';
import Preview from './Preview';

class ContentForm extends Component {
  static propTypes = FormikPropTypes;

  setImageUrl = urls => {
    const { setFieldValue } = this.props;
    const [url] = urls;

    setFieldValue(IMAGE_URL_KEY, url);
  };

  renderPreview = () => <Preview values={this.props.values} />;

  render() {
    const { Field, values } = this.props;
    const imageId = values[IMAGE_URL_KEY];

    return (
      <FieldRow
        label="Наполнение баннера"
        subLabel="Изображение баннера"
        sudDesc="Баннер обязательно должен иметь изображение"
        Aside={this.renderPreview}
      >
        <Grid container spacing={40}>
          <Grid item xs={12}>
            <CanEdit field={IMAGE_URL_KEY}>
              <ImageField
                ids={imageId ? [imageId] : []}
                onChange={this.setImageUrl}
              />
            </CanEdit>
          </Grid>

          <Grid item xs={12}>
            <FieldDescription
              title="Заголовок баннера"
              desc="Заголовок будет отображаться под баннером. Не более 50 символов."
            />
            <CanEdit field={RUSSIAN_TITLE_KEY}>
              <Field
                Target={TextField}
                name={RUSSIAN_TITLE_KEY}
                symbolsMax={limits.title}
                helperText="На русском"
                fullWidth
              />
            </CanEdit>
            <CanEdit field={ENGLISH_TITLE_KEY}>
              <Field
                Target={TextField}
                name={ENGLISH_TITLE_KEY}
                symbolsMax={limits.title}
                helperText="На английском"
                fullWidth
              />
            </CanEdit>
          </Grid>

          <Grid item xs={12}>
            <FieldDescription
              title="Дополнительный текст баннера"
              desc="Дополнительный текст будет отображаться под заголовком баннера. Не более 100 символов."
            />
            <CanEdit field={ADDITIONAL_RUSSIAN_TEXT_KEY}>
              <Field
                Target={TextField}
                name={ADDITIONAL_RUSSIAN_TEXT_KEY}
                helperText="На русском"
                rowsMax={10}
                symbolsMax={200}
                multiline
                fullWidth
              />
            </CanEdit>
            <CanEdit field={ADDITIONAL_ENGLISH_TEXT_KEY}>
              <Field
                Target={TextField}
                name={ADDITIONAL_ENGLISH_TEXT_KEY}
                helperText="На английском"
                rowsMax={10}
                symbolsMax={200}
                multiline
                fullWidth
              />
            </CanEdit>
          </Grid>
        </Grid>
      </FieldRow>
    );
  }
}

export default ContentForm;
