import { compose } from 'ramda';
import { defaultProps } from 'recompose';

import withStrategy from '@/hocs/withStrategy';

import PasswordField from './component';
import strategies, { turnOffStrategy } from './strategies';

export default compose(
  withStrategy({ strategies }),
  defaultProps({
    strategy: turnOffStrategy,
  }),
)(PasswordField);
