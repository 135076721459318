import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Description = ({ isStoreSeller, isMallCommissar, classes }) => {
  if (isStoreSeller) {
    return (
      <ol className={classes.root}>
        <Typography gutterBottom component="a" className={classes.item}>
          Вам открыт доступ в приложение “Esentai Admin“
        </Typography>

        <Typography component="li" className={classes.item}>
          Вы можете провести процесс оплаты бонусами
        </Typography>

        <Typography component="li" className={classes.item}>
          Вы можете провести процесс возврата товара(ов)
        </Typography>
      </ol>
    );
  } else if (isMallCommissar) {
    return (
      <ol className={classes.root}>
        <Typography className={classes.titleItem}>
          Возможности Комплаенс Менеджера:
        </Typography>

        <Typography gutterBottom component="li" className={classes.listItem}>
          Отвечать на заявки по следующим категориям:
        </Typography>
        <ol type="A">
          <Typography gutterBottom component="li" className={classes.listItem}>
            Присвоение уровня лояльности и/или бонусов
          </Typography>
          <Typography gutterBottom component="li" className={classes.item}>
            Отклонение бонусов по чеку
          </Typography>
        </ol>
        <Typography component="li" className={classes.item}>
          Доступа на сайт не имеется
        </Typography>
      </ol>
    );
  }

  return (
    <ol className={classes.root}>
      <Typography gutterBottom component="li" className={classes.item}>
        Отсканируйте QR-код бенефита с телефона клиента
        <Typography variant="caption" className={classes.caption}>
          Используйте ХХХХ приложение, чтобы отсканировать QR-код, который
          направит вас в браузер.
        </Typography>
      </Typography>

      <Typography component="li" className={classes.item}>
        Проверьте корректность бенефита
      </Typography>

      <Typography component="li" className={classes.item}>
        Выдайте бенефит клиенту
      </Typography>

      <Typography component="li" className={classes.item}>
        Отметьте выдачу бенефита нажатием кнопки «Выдать»
      </Typography>
    </ol>
  );
};

Description.propTypes = {
  isStoreSeller: PropTypes.bool.isRequired,
  isMallCommissar: PropTypes.bool.isRequired,
};

export default Description;
