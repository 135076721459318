import {
  EXIT_DATETIME_KEY,
  ID_KEY,
  ORDER_AMOUNT_KEY,
  ORDER_CUSTOMER_ID_KEY,
  ORDER_CUSTOMER_LEVEL_KEY,
  ORDER_DEBT_KEY,
  ORDER_STATUS_KEY,
  RECORD_CREATED_KEY,
  VEHICLE_GOV_NUMBER_KEY,
  VEHICLE_MODEL_KEY,
} from '@/features/efsParkingPage/consts/keys';
import { createDateTimeRangeFilter } from '@/filters/DateTimeRange';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createParkingOrderStatusFilter } from '@/filters/ParkingOrderStatus';
import { createEqualsFilter, createTextFilter } from '@/filters/SearchInput';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createEqualsFilter({
    key: ID_KEY,
    label: 'ID заезда/выезда',
  }),
  createEqualsFilter({
    key: ORDER_CUSTOMER_ID_KEY,
    label: 'ID пользователя',
  }),
  createTextFilter({
    key: VEHICLE_MODEL_KEY,
    label: 'Модель авто',
  }),
  createTextFilter({
    key: VEHICLE_GOV_NUMBER_KEY,
    label: 'Гос. номер авто',
  }),
  createDateTimeRangeFilter({
    key: RECORD_CREATED_KEY,
    label: 'Дата и время заезда',
  }),
  createDateTimeRangeFilter({
    key: EXIT_DATETIME_KEY,
    label: 'Дата и время выезда',
  }),
  createMoneyRangeFilter({
    key: ORDER_AMOUNT_KEY,
    label: 'Стоимость заезда',
  }),
  createMoneyRangeFilter({
    key: ORDER_DEBT_KEY,
    label: 'Долг',
  }),
  createParkingOrderStatusFilter({
    key: ORDER_STATUS_KEY,
    label: 'Статус оплаты',
  }),
  createUserStatusFilter({
    key: ORDER_CUSTOMER_LEVEL_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),
];

export const filterPrefixes = [
  {
    field: ID_KEY,
    prefix: 'eq',
  },
  {
    field: ORDER_CUSTOMER_ID_KEY,
    prefix: 'eq',
  },
  {
    field: ORDER_STATUS_KEY,
    prefix: 'eq',
  },
  {
    field: ORDER_AMOUNT_KEY,
    prefix: 'in',
  },
  {
    field: ORDER_CUSTOMER_LEVEL_KEY,
    prefix: 'in',
  },
  {
    field: ORDER_DEBT_KEY,
    prefix: 'in',
  },
  {
    field: RECORD_CREATED_KEY,
    prefix: 'eq',
  },
  {
    field: EXIT_DATETIME_KEY,
    prefix: 'eq',
  },
  {
    field: VEHICLE_GOV_NUMBER_KEY,
    prefix: 'ilike',
  },
  {
    field: VEHICLE_MODEL_KEY,
    prefix: 'ilike',
  },
];

export const multipleFilterList = [
  ORDER_DEBT_KEY,
  ORDER_AMOUNT_KEY,
  EXIT_DATETIME_KEY,
  RECORD_CREATED_KEY,
];
