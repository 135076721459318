import storeAPI from '@esentai/core/features/stores/api';
import { NAME_KEY } from '@esentai/core/features/stores/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { identity } from 'ramda';
import { defaultProps } from 'recompose';

import pluralize from '@/utils/pluralize';

import InlineSearch from '../InlineSearch';
import SelectedItem from './SelectedItem';
import Suggestion from './Suggestion';

const buildQuery = queryString => query(where(NAME_KEY, ilike(queryString)));

const countCaptionFormatter = count =>
  pluralize(count, {
    one: `Найдена ${count} подходящий магазин`,
    few: `Найдено ${count} подходящих магазина`,
    many: `Найдено ${count} подходящих магазинов`,
  });

export default defaultProps({
  inputProps: {
    label: 'Выбор магазина',
    helperText:
      'Укажите конкретный магазин, на который будет ссылаться баннер.',
  },
  api: storeAPI,
  buildQuery,
  countCaptionFormatter,
  convertResourceItem: identity,
  selectedItemLabel: 'Выбор магазина',
  SelectedItem,
  Suggestion,
})(InlineSearch);
