import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getClicksPercentageByBlackLeveledUsers,
  getClicksPercentageByJetBlackLeveledUsers,
  getClicksPercentageByOrangeLeveledUsers,
} from '@/features/campaignsStatisticsDetailsPage/selectors';

import StatusDistributionChart from './component';
import styles from './styles';

const mapStateToProps = state => ({
  orangeCampaignsPercentage: getClicksPercentageByOrangeLeveledUsers(state),
  blackCampaignsPercentage: getClicksPercentageByBlackLeveledUsers(state),
  jetBlackCampaignsPercentage: getClicksPercentageByJetBlackLeveledUsers(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(StatusDistributionChart);
