import createQueryDuck from '@/redux/query';

import { FEATURE_NAME } from '../consts';
import serializer from '../serializer';
import { getQueryRoot } from '../utils';

export default createQueryDuck(FEATURE_NAME, {
  getRoot: getQueryRoot,
  serializer,
});
