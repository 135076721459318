import { compose, prop } from 'ramda';

import { getStorePurchasesStatisticsUrlById } from '@/features/purchasesStatisticsPage/selectors';
import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';

const getParams = createMatchSelector(ROUTE_PATH);

export const getStoreId = compose(prop('storeId'), prop('params'), getParams);

export const getStorePurchasesStatisticsUrl = state => {
  const storeId = getStoreId(state);

  return getStorePurchasesStatisticsUrlById(state, storeId);
};
