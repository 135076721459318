import {
  BONUSES_REFUNDED_KEY,
  BONUSES_SPENT_KEY,
} from '@esentai/core/features/reconciliation/consts/keys';
import { TOTAL_TO_PAY_KEY } from '@esentai/core/features/reconciliationArchive/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>Магазин</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TOTAL_TO_PAY_KEY}>
        Итоговая сумма к оплате
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={BONUSES_SPENT_KEY}>
        Итоговая сумма потраченных бонусов
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={BONUSES_REFUNDED_KEY}>
        Итоговая сумма бонусов на возврат
      </SortControl>
    </CondenseTableCell>

    {/* <CondenseTableCell>Действия</CondenseTableCell> */}
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
