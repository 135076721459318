import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Link from '@/components/Link';
import {
  generateDailySchedule,
  generateDayAfterDaySchedule,
  generateMonthlySchedule,
  generateWeeklySchedule,
} from '@/features/campaignCreatePage/EditPage/SecondStepNotification/utils';

class Patterns extends Component {
  static propTypes = {
    initialSendDatetime: PropTypes.instanceOf(Date),
    updateTicks: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialSendDatetime: null,
  };

  fillWithDailyPattern = () => this.fillWithPattern(generateDailySchedule);

  fillWithDayAfterDayPattern = () =>
    this.fillWithPattern(generateDayAfterDaySchedule);

  fillWithWeeklyPattern = () => this.fillWithPattern(generateWeeklySchedule);

  fillWithMonthlyPattern = () => this.fillWithPattern(generateMonthlySchedule);

  fillWithPattern = creator => {
    const { initialSendDatetime, updateTicks } = this.props;

    if (!initialSendDatetime) {
      return;
    }

    updateTicks(creator(initialSendDatetime));
  };

  render() {
    const { PatternLink } = this;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <PatternLink onClick={this.fillWithDailyPattern}>Ежедневно</PatternLink>
        <PatternLink onClick={this.fillWithDayAfterDayPattern}>
          Через день
        </PatternLink>
        <PatternLink onClick={this.fillWithWeeklyPattern}>
          Еженедельно
        </PatternLink>
        <PatternLink onClick={this.fillWithMonthlyPattern}>
          Ежемесячно
        </PatternLink>
      </div>
    );
  }

  PatternLink = props => {
    const { classes } = this.props;

    return <Link component="span" className={classes.patternLink} {...props} />;
  };
}

export default Patterns;
