import { History as HistoryIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import Button from '@/components/Button';
import CanView from '@/containers/CanView';

const HistoryButton = ({ permission, ...rest }) => (
  <CanView permission={permission}>
    <ActionButton
      text="Исторические данные"
      button={Button}
      icon={HistoryIcon}
      href={rest.historyPageUrl}
      {...rest}
    />
  </CanView>
);

HistoryButton.propTypes = {
  permission: PropTypes.func.isRequired,
};

export default HistoryButton;
