import {
  getCampaignFilters,
  getCampaignSegmentType,
} from '@esentai/core/features/campaigns/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { submit } from '@/features/campaignCreatePage/actions';
import {
  getCampaignId,
  getCampaignType,
} from '@/features/campaignCreatePage/selectors';
import { canModerateCampaign } from '@/features/campaignsPage/permissions';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import ThirdStep from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getCampaignId(state);
  const type = getCampaignType(state);

  return {
    canModerate: canModerateCampaign(state, id),
    campaignType: type,
    filters: getCampaignFilters(state, id),
    segmentType: getCampaignSegmentType(state, id),
    id,
  };
};

const mapDispatchToProps = { onSubmit: submit };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(ThirdStep);
