import {
  ACTIVE_BONUSES_KEY,
  AGE_KEY,
  BIRTH_DATE_KEY,
  DATE_REGISTERED_KEY,
  DEVICES_CHANGES_COUNT_KEY,
  FIRST_NAME_KEY,
  GENDER_KEY,
  ID_KEY,
  IS_SUSPICIOUS_KEY,
  LAST_NAME_KEY,
  PHONE_KEY,
  RECEIPTS_COUNT_KEY,
  STATE_KEY,
  STATUS_KEY,
  TOTAL_SPENT_KEY,
} from '@esentai/core/features/users/consts/keys';

import { createBirthdayRangeFilter } from '@/filters/BirthdayRange';
import { createDateRangeFilter } from '@/filters/DateRange';
import { createGenderFilter } from '@/filters/Gender';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createRangeFilter } from '@/filters/Range';
import { createTextFilter } from '@/filters/SearchInput';
import { createIncludeFilter } from '@/filters/SearchInputs';
import { createSuspiciousUserFilter } from '@/filters/SuspiciousUser';
import { createUserActivityStateFilter } from '@/filters/UserActivityState';
import { createUserAgeFilter } from '@/filters/UserAge';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createIncludeFilter({
    key: ID_KEY,
    label: 'ID Клиента',
  }),
  createTextFilter({
    key: FIRST_NAME_KEY,
    label: 'Имя',
  }),
  createTextFilter({
    key: LAST_NAME_KEY,
    label: 'Фамилия',
  }),
  createMoneyRangeFilter({
    key: TOTAL_SPENT_KEY,
    label: 'Сумма покупок',
  }),
  createMoneyRangeFilter({
    key: ACTIVE_BONUSES_KEY,
    label: 'Количество бонусов',
  }),
  createRangeFilter({
    key: RECEIPTS_COUNT_KEY,
    label: 'Количество покупок',
  }),
  createGenderFilter({
    key: GENDER_KEY,
    label: 'Пол пользователя',
  }),
  createTextFilter({
    key: PHONE_KEY,
    label: 'Телефон',
  }),
  createBirthdayRangeFilter({
    key: BIRTH_DATE_KEY,
    label: 'Дата рождения',
  }),
  createDateRangeFilter({
    key: DATE_REGISTERED_KEY,
    label: 'Дата регистрации',
  }),
  createUserAgeFilter({
    key: AGE_KEY,
    label: 'Возраст',
  }),
  createUserActivityStateFilter({
    key: STATE_KEY,
    label: 'Активность пользователя',
  }),
  createRangeFilter({
    key: DEVICES_CHANGES_COUNT_KEY,
    label: 'Смен устройств',
  }),
  createSuspiciousUserFilter({
    key: IS_SUSPICIOUS_KEY,
    label: 'Подозрительность',
  }),
  createUserStatusFilter({
    key: STATUS_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),
];
