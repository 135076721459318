import { Paper } from '@material-ui/core';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';

import Outlet from './Outlet';

class Settings extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Page>
        <PageHeader>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Настройки</PageTitle>
        </PageHeader>
        <PageContent>
          <Paper className={classes.content}>
            <Outlet />
          </Paper>
        </PageContent>
      </Page>
    );
  }
}

export default Settings;
