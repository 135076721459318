import {
  getFactoryNumberForKkm,
  getKkmNumberForKkm,
  getMerchantIdForKkm,
  getStatusForKkm,
  getStoreIdForKkm,
} from '@esentai/core/features/kkms/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { createKkmPageUrl } from '@/features/kkmPage/utils';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: kkmId }) => ({
  status: getStatusForKkm(state, kkmId),
  fns_id: getKkmNumberForKkm(state, kkmId),
  store: getStoreIdForKkm(state, kkmId),
  merchant: getMerchantIdForKkm(state, kkmId),
  kkmId,
  kkmPageUrl: createKkmPageUrl(kkmId),
  code: getFactoryNumberForKkm(state, kkmId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
