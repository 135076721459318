import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/usersPage/ducks/query';
import {
  getUsersError,
  getUsersIds,
  getUsersTotalCount,
  isLoadingUsers,
} from '@/features/usersPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import Users from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getUsersError(state),
  usersIds: getUsersIds(state),
  isLoading: isLoadingUsers(state),
  totalCount: getUsersTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(Users);
