import Resource from '@esentai/core/resources/base';

export class PasswordRecoveryResource extends Resource {
  recover = ({ email }) => {
    const { apiInstance } = this;

    return apiInstance.fetcher({
      url: '/staff-password-recovery',
      method: 'POST',
      data: { email },
    });
  };

  reset = ({ password, token }) => {
    const { apiInstance } = this;

    return apiInstance.fetcher({
      url: '/reset-password',
      method: 'POST',
      data: { password, token },
    });
  };
}

export default new PasswordRecoveryResource();
