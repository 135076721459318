import { connect } from 'react-redux';

import HeatMap from '@/components/HeatMap';
import {
  getFloor,
  getMaximumAmount,
  getMinimumAmount,
} from '@/features/locationsStatisticsPage/selectors';

import RentPlace from './RentPlace';
import TooltipContent from './TooltipContent';

const mapStateToProps = state => ({
  floor: getFloor(state),
  maximumAmount: getMaximumAmount(state),
  minimumAmount: getMinimumAmount(state),
  RentPlace,
  TooltipContent,
});

export default connect(mapStateToProps)(HeatMap);
