import { getMonth, isFirstDayOfMonth, startOfYear } from 'date-fns';

import {
  formatHumanDate,
  formatMonth,
  formatMonthAndYear,
  formatShortHumanDate,
  formatTime,
  formatWeek,
  formatWeekAndYear,
} from '@/utils/format';

const JANUARY = 0;

export const isFirstOfJanuary = date => {
  const isJanuary = getMonth(date) === JANUARY;
  const isFirstDay = isFirstDayOfMonth(date);

  return isJanuary && isFirstDay;
};

export const formatDayTick = date =>
  isFirstOfJanuary(date) ? formatHumanDate(date) : formatShortHumanDate(date);

export const formatHourTick = date =>
  `${formatTime(date)} ${formatDayTick(date)}`;

export const formatMonthTick = date =>
  isFirstOfJanuary(date) ? formatMonthAndYear(date) : formatMonth(date);

export const formatWeekTick = date =>
  isFirstOfJanuary(date)
    ? formatWeekAndYear(startOfYear(date))
    : formatWeek(date);
