import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import FieldRow from '@/components/FieldRow';

class ResetPasswordField extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    error: PropTypes.instanceOf(Error),
  };

  static defaultProps = {
    isLoading: false,
    error: null,
  };

  render() {
    const { email, error, isLoading, classes } = this.props;

    return (
      <FieldRow
        label="Пароль"
        subLabel="Смена пароля аккаунта"
        sudDesc="Для того, чтобы сменить пароль аккаунта пользователя, необходимо запросить письмо с ссылкой для сброса пароля. Письмо будет отправлено на электронную  почту, указанную пользователем при регистрации."
        className={classes.row}
      >
        <Typography variant="h6" className={classes.email}>
          <label className={classes.emailLabel}>Email:</label> {email}
        </Typography>
        <Typography variant="caption" className={classes.description}>
          На данный email будет выслано письмо. Перейдите по ссылке, указанной в
          нем, чтобы сменить пароль.
        </Typography>
        <div className={classes.button}>
          <AsyncButton
            ButtonComponent={Button}
            error={error}
            isLoading={isLoading}
            color="primary"
            size="large"
            variant="contained"
            onClick={this.onSubmit}
          >
            Сбросить пароль и выслать письмо
          </AsyncButton>
        </div>
        <Typography variant="caption" className={classes.description}>
          Обратите внимание, что ссылка, высланная в письме, будет активна в
          течение 1 часа и доступна для использования всего один раз. После
          этого вам будет необходимо выслать письмо повторно.
        </Typography>
      </FieldRow>
    );
  }

  onSubmit = e => {
    e.preventDefault();

    const { onSubmit } = this.props;

    onSubmit();
  };
}

export default ResetPasswordField;
