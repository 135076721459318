import { Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class SearchInput extends Component {
  onChange = event => this.props.onChange(event.target.value);

  render() {
    const { value, ...props } = this.props;

    return (
      <Input {...props} autoFocus onChange={this.onChange} value={value} />
    );
  }
}

SearchInput.defaultProps = {
  value: '',
};

SearchInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default SearchInput;
