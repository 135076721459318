import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const GridComponent = ({
  appVersions,
  handleToggleEditIOSModal,
  handleToggleEditAndroidModal,
}) => {
  const {
    version_android,
    version_android_forced_update,
    version_ios,
    version_ios_forced_update,
  } = appVersions;

  return (
    <Grid container spacing={24}>
      <List style={{ width: '100%' }}>
        <ListItem>
          <Grid item xs={4}>
            <Typography component="h3" variant="h5">
              Текущая версия
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="h3" variant="h5">
              Тип обновления
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component="h3" variant="h5">
              Действия
            </Typography>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Grid item xs={4}>
            <Typography variant="body1">{`IOS: ${version_ios ||
              '...'}`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {version_ios_forced_update ? 'Обязательное' : 'Опциональное'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleToggleEditIOSModal}
            >
              Изменить IOS
            </Button>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item xs={4}>
            <Typography variant="body1">{`Android: ${version_android ||
              '...'}`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              {version_android_forced_update ? 'Обязательное' : 'Опциональное'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleToggleEditAndroidModal}
            >
              Изменить Android
            </Button>
          </Grid>
        </ListItem>
      </List>
    </Grid>
  );
};

GridComponent.propTypes = {
  appVersions: PropTypes.object.isRequired,
  handleToggleEditIOSModal: PropTypes.func.isRequired,
  handleToggleEditAndroidModal: PropTypes.func.isRequired,
};

export default GridComponent;
