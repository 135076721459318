import { isNil } from 'ramda';

export default {
  circle: {
    strokeWidth: 1,
    r: 5,
    pointerEvents: 'none',
  },
  line: {
    stroke: '#000000',
    strokeWidth: 1,
    pointerEvents: 'none',
  },
  g: {
    opacity: ({ selectedDateIndex }) => (isNil(selectedDateIndex) ? 0 : 1),
    transform: ({ xScale, xs, selectedDateIndex }) =>
      `translateX(${xScale(xs[selectedDateIndex])}px)`,
  },
};
