import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { isMallAdministrator } from '@/features/currentUser/selectors';
import {
  getEmailForPersonnel,
  getLastActivityDateForPersonnel,
  getNameForPersonnel,
  getRoleForPersonnel,
  getStoresForPersonnel,
  isPersonnelActive,
  isPersonnelInvited,
} from '@/features/personnel/selectors';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: personnelId }) => ({
  email: getEmailForPersonnel(state, personnelId),
  isActive: isPersonnelActive(state, personnelId),
  isInvited: isPersonnelInvited(state, personnelId),
  lastActivityDate: getLastActivityDateForPersonnel(state, personnelId),
  name: getNameForPersonnel(state, personnelId),
  role: getRoleForPersonnel(state, personnelId),
  storeIds: getStoresForPersonnel(state, personnelId),
  isMallAdmin: isMallAdministrator(state),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
