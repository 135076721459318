import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { pick } from 'ramda';
import React, { Component } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import DotIndicator from '@/components/DotIndicator';
import Link from '@/components/Link/component';
import StorePageLink from '@/containers/StorePageLink';
import { dateTimeFormatNoSecs } from '@/utils/format';
import { userRole } from '@/utils/labels';

import Actions from './Actions';

class Row extends Component {
  convertStatus = () => {
    if (this.props.isInvited) {
      return 'warning';
    }
    if (this.props.isActive) {
      return 'enabled';
    }

    return 'disabled';
  };

  renderStoresList = stores => {
    if (!stores || stores.length === 0) {
      return '—';
    }
    const { classes } = this.props;

    return (
      <Grid container spacing={8} className={classes.storesContainer}>
        {stores.map(store => (
          <Grid item key={store}>
            <StorePageLink storeId={store} />
          </Grid>
        ))}
      </Grid>
    );
  };

  render() {
    const {
      classes,
      email,
      item,
      lastActivityDate,
      name,
      role,
      storeIds,
      isMallAdmin,
    } = this.props;

    return (
      <TableRow>
        <TableCell padding="checkbox">
          <DotIndicator
            status={this.convertStatus()}
            classes={pick(['indicator'], classes)}
          />
        </TableCell>
        <CondenseTableCell>
          {email ? (
            <Link
              className={classes.emailLink}
              href={`mailto:${email}`}
              component="a"
            >
              {email}
            </Link>
          ) : (
            '—'
          )}
        </CondenseTableCell>
        <CondenseTableCell>
          {name}
          <Typography variant="caption">{userRole(role)}</Typography>
        </CondenseTableCell>

        <CondenseTableCell>{this.renderStoresList(storeIds)}</CondenseTableCell>
        <CondenseTableCell>
          {lastActivityDate ? dateTimeFormatNoSecs(lastActivityDate) : '–'}
        </CondenseTableCell>
        {isMallAdmin && (
          <CondenseTableCell>
            <Actions personnelId={item} />
          </CondenseTableCell>
        )}
      </TableRow>
    );
  }
}

Row.defaultProps = {
  lastActivityDate: null,
  name: '',
  email: '',
  storeIds: null,
};

Row.propTypes = {
  email: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isInvited: PropTypes.bool.isRequired,
  isMallAdmin: PropTypes.bool.isRequired,
  lastActivityDate: PropTypes.instanceOf(Date),
  item: PropTypes.any.isRequired,
  name: PropTypes.string,
  role: PropTypes.string.isRequired,
  storeIds: PropTypes.arrayOf(PropTypes.number),
};

export default Row;
