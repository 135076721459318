import {
  AccountBox,
  Business,
  InsertDriveFile,
  Money,
  Payment,
  Store,
} from '@material-ui/icons';

import { ROUTE_PATH as DOCUMENTS_PATH } from '@/features/documentsPage/consts';
import { ROUTE_PATH as IPN_REPORTS_PATH } from '@/features/ipnReportsPage/consts';
import { ROUTE_PATH as KKM_PATH } from '@/features/kkmsPage/consts';
import { ROUTE_PATH as MALL_BONUSES_PATH } from '@/features/mallBonusesPage/consts';
import { ROUTE_PATH as PERSONNEL_PATH } from '@/features/personnelPage/consts';
import { ROUTE_PATH as PURCHASE_HISTORY_PATH } from '@/features/purchaseHistoryPage/consts';
import { ROUTE_PATH as RECONCILIATION_PATH } from '@/features/reconciliationPage/consts';
import { ROUTE_PATH as RECONCILIATION_STORE_CP_PATH } from '@/features/reconciliationStoreToCpPage/consts';

export const storeAdministratorMenu = (storeAdministratorStores = []) => [
  {
    label: 'Персонал',
    path: PERSONNEL_PATH,
    Icon: AccountBox,
  },
  {
    label: 'Справочник ККМ',
    path: KKM_PATH,
    Icon: Payment,
  },
  {
    label: 'Настройка бонусов',
    path: MALL_BONUSES_PATH,
    Icon: Store,
  },
  {
    label: 'История покупок',
    path: PURCHASE_HISTORY_PATH,
    Icon: Money,
  },
  {
    label: 'Реконсиляция',
    Icon: AccountBox,
    items: [
      {
        label: 'Бонусы от Esentai Mall',
        path: RECONCILIATION_PATH,
        Icon: Business,
      },
      {
        label: 'Бонусы от имени магазина',
        path: RECONCILIATION_STORE_CP_PATH,
        Icon: Store,
      },
    ],
  },
  {
    label: 'Документы',
    Icon: InsertDriveFile,
    path: DOCUMENTS_PATH,
  },
  {
    ...(storeAdministratorStores.includes(
      Number(process.env.SAKS_FIFTH_AVENUE_ID),
    )
      ? {
          label: 'Отчеты',
          Icon: AccountBox,
          items: [
            {
              label: 'ИПН',
              Icon: Business,
              path: IPN_REPORTS_PATH,
            },
          ],
        }
      : {}),
  },
];
