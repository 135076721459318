export const AUTHENTICATE = 'auth/AUTHENTICATE';
export const AUTHENTICATE_FAILURE = 'auth/AUTHENTICATE_FAILURE';
export const AUTHENTICATE_SUCCESS = 'auth/AUTHENTICATE_SUCCESS';
export const AUTHORIZE = 'auth/AUTHORIZE';
export const AUTHORIZE_BY_TOKEN = 'auth/AUTHORIZE_BY_TOKEN';
export const AUTHORIZE_FAILURE = 'auth/AUTHORIZE_FAILURE';
export const AUTHORIZE_SUCCESS = 'auth/AUTHORIZE_SUCCESS';
export const CHANGE_CURRENT_USER = 'auth/CHANGE_CURRENT_USER';
export const DEAUTHORIZE = 'auth/DEAUTHORIZE';
export const EXPAND = 'auth/EXPAND';
