import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import DefaultFloorSelect from './FloorSelect';

const MapWorkspace = ({ classes, FloorOption, FloorSelect, Map }) => (
  <div className={classes.root}>
    <div className={classes.floorSelectContainer}>
      <Typography variant="subtitle1" className={classes.label}>
        Этаж
      </Typography>
      <FloorSelect FloorOption={FloorOption} />
    </div>
    <div className={classes.heatMapContainer}>
      <Typography variant="subtitle1" className={classes.label}>
        Карта
      </Typography>
      <div className={classes.heatMap}>
        <Map height="689px" width="100%" />
      </div>
    </div>
  </div>
);

MapWorkspace.defaultProps = {
  FloorSelect: DefaultFloorSelect,
};

MapWorkspace.propTypes = {
  FloorOption: PropTypes.func.isRequired,
  FloorSelect: PropTypes.func,
  Map: PropTypes.func.isRequired,
};

export default MapWorkspace;
