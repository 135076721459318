export const CUSTOMER_ID_KEY = 'customer';

export const ID_KEY = 'id';

export const LEVEL_KEY = 'user_level';

export const PHONE_KEY = 'phone';

export const UUID_KEY = 'fiscal_id';

export const ISSUED_AT_DATETIME_KEY = 'issued_at';

export const STORE_KEY = 'store';

export const STORE_NAME_KEY = 'store_name';

export const PURCHASE_KEY = 'purchases';

export const AMOUNT_KEY = 'amount';

export const PRICE_KEY = 'price';

export const NAME_KEY = 'name';

export const KKM_KEY = 'cash_machine_id';

export const FILE_URL_KEY = 'files';

export const CALCULATE_PENDING_BONUSES_KEY = 'calculate_pending_bonuses';

export const TOTAL_COST_KEY = 'total_cost';

export const TIMESTAMP_KEY = 'timestamp';

export const BONUS_PERCENTAGE_KEY = 'bonus_percent';

export const ACTIVE_BONUSES_KEY = 'active_bonuses';

export const PENDING_BONUSES_KEY = 'pending_bonuses';

export const RECORD_CREATED_KEY = 'record_created';

export const STAFF_FULLNAME_KEY = 'staff_full_name';

export const REFUND_DATETIME_KEY = 'refund_datetime';

export const REFUND_AMOUNT_KEY = 'refund_amount';

export const CURRENT_AMOUNT_KEY = 'current_amount';

export const HMS_KEY = 'hms';
