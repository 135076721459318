import { call, put, spawn, takeEvery } from 'redux-saga/effects';

import { deletePersonnel } from '@/features/personnel/sagas';

import { reload } from './actions';
import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { CANCEL_INVITE, RELOAD, RETRY_FETCH } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export const cancelInvite = function*(...args) {
  yield call(deletePersonnel, ...args);

  yield put(reload());
};

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export function* watchReloadList() {
  yield takeEvery(RELOAD, fetchItems);
}

export function* watchCancelInvite() {
  yield takeEvery(CANCEL_INVITE, cancelInvite);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield spawn(watchReloadList);
  yield spawn(watchCancelInvite);
}
