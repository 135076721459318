export default ({ spacing }) => ({
  datepickers: {
    display: 'flex',
    margin: [16, 0, 8],
    alignItems: 'center',
  },

  datepicker: {
    flex: 'none',
    width: 310,
    margin: [0, 30, 30, 0],
  },

  titleField: {
    width: 651,
    marginBottom: 15,
  },

  cancelButton: {
    marginRight: spacing.unit * 2,
  },

  goBackLink: {
    marginRight: spacing.unit * 2,
    textDecoration: 'none',
  },
});
