import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getParkingTowerStopListError,
  getItemsIds: getParkingTowerStopListIds,
  getItemsMeta: getParkingTowerStopListMeta,
  getItemsTotalCount: getParkingTowerStopListTotalCount,
  isLoadingItems: isLoadingParkingTowerStopList,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
