import api from '@esentai/core/features/merchants/api';
import { BIN_KEY } from '@esentai/core/features/merchants/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import MerchantChip from './MerchantChip';
import MerchantOption from './MerchantOption';

const buildQuery = queryString => query(where(BIN_KEY, ilike(queryString)));

const withMerchantProps = defaultProps({
  api,
  buildQuery,
  Chip: MerchantChip,
  Option: MerchantOption,
});

export const MerchantAutocomplete = withMerchantProps(AutocompleteAPI);

export const MerchantMultiAutocomplete = withMerchantProps(
  AutocompleteAPIMultiple,
);
