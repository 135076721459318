import PropTypes from 'prop-types';
import React from 'react';

import { Consumer } from '../Map/context';
import Beacon from './Beacon';
import { NON_POSITIONED_LABEL_X, NON_POSITIONED_LABEL_Y } from './styles';

const Beacons = ({ beaconIds, classes, hasBeaconsWithoutPosition }) => (
  <g>
    <g>
      {beaconIds.map(beaconId => (
        <Consumer key={beaconId}>
          {value => <Beacon beaconId={beaconId} {...value} />}
        </Consumer>
      ))}
    </g>
    {hasBeaconsWithoutPosition && (
      <text
        x={NON_POSITIONED_LABEL_X}
        y={NON_POSITIONED_LABEL_Y}
        className={classes.nonPositionedBeaconsLabel}
      >
        Маяки с неуказанным положением
      </text>
    )}
  </g>
);

Beacons.propTypes = {
  beaconIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  hasBeaconsWithoutPosition: PropTypes.bool.isRequired,
};

export default Beacons;
