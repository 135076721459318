import { mergeQueriesRight } from '@esentai/core/queries';
import {
  anyOf,
  hasFilter,
  query as makeQuery,
  where,
} from '@esentai/core/query-dsl';
import VirtualResource from '@esentai/core/resources/virtual';

import { ROLE_KEY } from '@/features/personnel/consts/keys';

import { RESOURCE_NAME } from './consts';
import {
  BITRIX_ROLE,
  EFS_SALES_ROLE,
  GUEST_EFS_ROLE,
  GUEST_PARKING_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_ADMINISTRATOR_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_SELLER_ROLE,
  MALL_VALET_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_BENEFIT_MANAGER_ROLE,
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
} from './consts/roles';
import serializer from './serializer';

export class PersonnelResource extends VirtualResource {
  query(query) {
    if (!hasFilter(ROLE_KEY, query)) {
      const roleQuery = mergeQueriesRight(
        query,
        makeQuery(
          where(
            ROLE_KEY,
            anyOf([
              MALL_ADMINISTRATOR_ROLE,
              MALL_MANAGER_ROLE,
              MALL_SELLER_ROLE,
              STORE_ADMINISTRATOR_ROLE,
              STORE_MANAGER_ROLE,
              STORE_SELLER_ROLE,
              MALL_COMMISSAR_ROLE,
              MALL_ACCOUNTANT_ROLE,
              MALL_VALET_ROLE,
              GUEST_PARKING_ROLE,
              BITRIX_ROLE,
              STORE_BENEFIT_MANAGER_ROLE,
              GUEST_EFS_ROLE,
              EFS_SALES_ROLE,
              MALL_RECEPTION_ROLE,
              MANAGING_TEAM_MEMBER_ROLE,
            ]),
          ),
        ),
      );

      return super.query(roleQuery);
    }

    return super.query(query);
  }
}

export default new PersonnelResource({
  aliasResourceName: 'user',
  resourceName: RESOURCE_NAME,
  endpoint: '/user',
  serializer,
});
