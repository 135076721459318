import {
  ENGLISH_TITLE_KEY,
  KAZAKH_TITLE_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/show/consts/keys';
import React, { Component } from 'react';

import SecondStepForm from '@/features/campaignCreatePage/EditPage/SecondStepForm';
import FormikPropTypes from '@/prop-types/formik';

import ContentForm from './ContentForm';
import PlacementForm from './PlacementForm';
import ProductsForm from './ProductsForm';

const fieldNames = {
  [ENGLISH_TITLE_KEY]: 'заголовок на английском',
  [RUSSIAN_TITLE_KEY]: 'заголовок на русском',
  [KAZAKH_TITLE_KEY]: 'заголовок на казахском',
};

class SecondStepBanner extends Component {
  static propTypes = FormikPropTypes;

  constructor(props) {
    super(props);

    this.state = {
      images: [],
    };
  }

  setImageUrls(urls) {
    this.setState({ images: urls });
  }

  render() {
    const { handleSubmit, RequiredMessages, SubmitButton } = this.props;
    const { images } = this.state;

    return (
      <SecondStepForm
        fieldNames={fieldNames}
        handleSubmit={handleSubmit}
        SubmitButton={SubmitButton}
        RequiredMessages={RequiredMessages}
      >
        <PlacementForm {...this.props} />
        <ContentForm {...this.props} imageUrls={images} />
        <ProductsForm
          {...this.props}
          setImages={this.setImageUrls.bind(this)}
        />
      </SecondStepForm>
    );
  }
}

export default SecondStepBanner;
