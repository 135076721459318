import { withProps } from 'recompose';

import { StoreAutocomplete } from '@/containers/StoreAutocomplete';
import Autocomplete from '@/filters/Autocomplete';

import InputSelectedItem from './InputSelectedItem';

export default withProps(({ value, ...ownProps }) => ({
  ...ownProps,
  Component: StoreAutocomplete,
  selectedItem: value,
  Chip: InputSelectedItem,
  InputProps: {
    placeholder: 'Название магазина...',
  },
}))(Autocomplete);
