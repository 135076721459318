import {
  getMallBonusLoadingError,
  isMallBonusLoaded,
  isMallBonusLoading,
} from '@esentai/core/features/mallBonuses/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getMallBonusId } from '@/features/mallBonusCreatePage/selectors';

import EditCampaign from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getMallBonusId(state);

  return {
    error: getMallBonusLoadingError(state, itemId),
    isLoaded: isMallBonusLoaded(state, itemId),
    isLoading: isMallBonusLoading(state, itemId),
    isFirstStepEditable: true,
    itemId,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(EditCampaign);
