import { connect } from 'react-redux';

import { getUsersIds } from '../../selectors';
import WarningMessageContent from './component';

const mapStateToProps = state => ({
  ids: getUsersIds(state),
});

export default connect(mapStateToProps)(WarningMessageContent);
