import React, { Fragment } from 'react';

import Delay from './Delay';
import Destination from './Destination';
import PrePush from './PrePush';
import Push from './Push';
import Repetition from './Repetition';
import Reward from './Reward';
import Stores from './Stores';
import Triggers from './Triggers';

const PurchaseResultContent = props => (
  <Fragment>
    <Stores {...props} />
    <Triggers {...props} />
    <Delay {...props} />
    <Repetition {...props} />
    <PrePush {...props} />
    <Push {...props} />
    <Reward {...props} />
    <Destination {...props} />
  </Fragment>
);

export default PurchaseResultContent;
