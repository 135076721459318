import { TRIGGER_KEY } from '@esentai/core/features/campaigns/actionResult/consts/keys';
import { connect } from 'react-redux';

import TriggerForm from './component';

const mapStateToProps = (state, { setFieldValue, values, Field }) => ({
  trigger: values[TRIGGER_KEY],
  setFieldValue,
  Field,
});

export default connect(mapStateToProps)(TriggerForm);
