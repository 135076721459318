import PropTypes from 'prop-types';
import React, { Component } from 'react';

import pluralize from '@/utils/pluralize';

import UserName from '../UserName';

const pluralizeUsers = length =>
  pluralize(length, {
    few: 'пользователя',
    many: `пользователей`,
  });

class WarningMessageContent extends Component {
  shouldComponentUpdate(nextProps) {
    // Fixes an issue where snackbar loses its content for some short time when cancelled
    const { ids } = nextProps;
    const { length } = ids;

    return length > 0;
  }

  render() {
    const { ids } = this.props;
    const { length } = ids;

    if (length > 3) {
      const [firstUserId, secondUserId, ...restUserIds] = ids;
      const restUsersLength = restUserIds.length;

      return (
        <span>
          После деактивации <UserName userId={firstUserId} />,{' '}
          <UserName userId={secondUserId} /> и еще {restUsersLength}{' '}
          {pluralizeUsers(restUsersLength)} потеряют доступ к приложению.
        </span>
      );
    }

    if (length === 3) {
      const [firstUserId, secondUserId, thirdUserId] = ids;

      return (
        <span>
          После деактивации <UserName userId={firstUserId} />,{' '}
          <UserName userId={secondUserId} /> и <UserName userId={thirdUserId} />{' '}
          потеряют доступ к приложению.
        </span>
      );
    }

    if (length === 2) {
      const [firstUserId, secondUserId] = ids;

      return (
        <span>
          После деактивации <UserName userId={firstUserId} /> и{' '}
          <UserName userId={secondUserId} /> потеряют доступ к приложению.
        </span>
      );
    }

    return (
      <span>
        После деактивации <UserName userId={ids[0]} /> потеряет доступ к
        приложению.
      </span>
    );
  }
}

WarningMessageContent.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default WarningMessageContent;
