import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SelectDropdown from './SelectDropdown';
import ShiftControls from './ShiftControls';

class DateRangeSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    minDate: PropTypes.instanceOf(Date).isRequired,
  };

  render() {
    const { classes, onChange, value, maxDate, minDate } = this.props;

    return (
      <div className={classes.root}>
        <ShiftControls
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
        >
          <SelectDropdown
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            value={value}
          />
        </ShiftControls>
      </div>
    );
  }
}

export default DateRangeSelect;
