import { compose } from 'ramda';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import {
  getCampaignType,
  getError,
  getScale,
  isItemsLoaded,
  isLoadingItems,
} from '@/features/campaignsStatisticsDetailsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import DetailsPage from './component';
import styles from './styles';

const mapStateToProps = state => ({
  campaignType: getCampaignType(state),
  error: getError(state),
  isLoading: isLoadingItems(state),
  scale: getScale(state),
  isLoaded: isItemsLoaded(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  injectSheet(styles),
)(DetailsPage);
