import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getActivationsCount,
  getActivationsToPrePushClicksPercentage,
  getActivationsToSegmentPercentage,
  getClicksCount,
  getClicksPercentageByBlackLeveledUsers,
  getClicksPercentageByJetBlackLeveledUsers,
  getClicksPercentageByOrangeLeveledUsers,
  getClicksToCoveragePercentage,
  getConversionRate,
  getCoverage,
  getPrePushClicksCount,
  getPrePushClicksToSegmentPercentage,
  getPushClicksCount,
  getPushClicksToActivationsPercentage,
  getPushClicksToASegmentPercentage,
  getSegment,
  getViewsCount,
  getViewsToSegmentPercentage,
} from '@/features/campaignsStatistics/selectors';
import { getDetailsPageUrl } from '@/features/campaignsStatisticsPage/selectors';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: itemId }) => ({
  conversion: getConversionRate(state, itemId),
  coverage: getCoverage(state, itemId),
  clicksCount: getClicksCount(state, itemId),
  clicksPercentageByBlackLeveledUsers: getClicksPercentageByBlackLeveledUsers(
    state,
    itemId,
  ),
  clicksPercentageByOrangeLeveledUsers: getClicksPercentageByOrangeLeveledUsers(
    state,
    itemId,
  ),
  clicksPercentageByJetBlackLeveledUsers: getClicksPercentageByJetBlackLeveledUsers(
    state,
    itemId,
  ),
  clicksToCoveragePercentage: getClicksToCoveragePercentage(state, itemId),
  detailsPageUrl: getDetailsPageUrl(state, itemId),
  itemId,
  segment: getSegment(state, itemId),
  viewsCount: getViewsCount(state, itemId),
  viewsToSegmentPercentage: getViewsToSegmentPercentage(state, itemId),
  prePushClicksCount: getPrePushClicksCount(state, itemId),
  pushClicksCount: getPushClicksCount(state, itemId),
  activationsCount: getActivationsCount(state, itemId),
  activationsToSegmentPercentage: getActivationsToSegmentPercentage(
    state,
    itemId,
  ),
  activationsToPrePushClicksPercentage: getActivationsToPrePushClicksPercentage(
    state,
    itemId,
  ),
  pushClicksToActivationsPercentage: getPushClicksToActivationsPercentage(
    state,
    itemId,
  ),
  pushClicksToASegmentPercentage: getPushClicksToASegmentPercentage(
    state,
    itemId,
  ),
  prePushClicksToSegmentPercentage: getPrePushClicksToSegmentPercentage(
    state,
    itemId,
  ),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
