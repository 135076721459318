import createAPISerializer from '@esentai/core/serializers/api';
import { date, number, string } from '@esentai/core/serializers/params';

import {
  CAMPAIGN_ID_KEY,
  CUSTOMER_AGE_KEY,
  CUSTOMER_AVERAGE_TICKET_KEY,
  CUSTOMER_BORN_AT_KEY,
  CUSTOMER_GENDER_KEY,
  CUSTOMER_LEVEL_KEY,
  CUSTOMER_RECEIPTS_NUMBER_KEY,
  CUSTOMER_STATE_KEY,
  LOCATION_ID_KEY,
  TIME_SERIES_KEY,
  TIMEFRAME_KEY,
} from '@/features/purchasesStatistics/consts/keys';

import { defaultDateRange } from './consts/filters';

export const annotations = {
  [TIME_SERIES_KEY]: date({
    defaultValue: defaultDateRange,
  }),
  [TIMEFRAME_KEY]: date(),

  [CUSTOMER_AVERAGE_TICKET_KEY]: number(),
  [CUSTOMER_RECEIPTS_NUMBER_KEY]: number(),
  [LOCATION_ID_KEY]: string(),
  [CUSTOMER_AGE_KEY]: number(),
  [CUSTOMER_GENDER_KEY]: string(),
  [CUSTOMER_LEVEL_KEY]: string(),
  [CUSTOMER_BORN_AT_KEY]: date(),
  [CUSTOMER_STATE_KEY]: string(),
  [CAMPAIGN_ID_KEY]: number(),
};

export default createAPISerializer(annotations);
