export default {
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    right: '5%',
  },
  title: {
    marginBottom: 5,
  },
  repairIcon: {
    marginBottom: 10,
  },
};
