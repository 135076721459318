import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { Component } from 'react';

import ChartOverlay from './ChartOverlay';
import LeftYAxis from './LeftYAxis';
import Line from './Line';
import RightYAxis from './RightYAxis';
import Tooltip from './Tooltip';
import { StatDescriptor } from './types';
import { getXScale, getYScale } from './utils';
import XAxis from './XAxis';

class AreaChart extends Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    margins: PropTypes.object,
    width: PropTypes.number.isRequired,
    xDescriptor: StatDescriptor.isRequired,
    leftYDescriptor: StatDescriptor.isRequired,
    rightYDescriptor: StatDescriptor.isRequired,
    xs: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    leftYs: PropTypes.arrayOf(PropTypes.number).isRequired,
    rightYs: PropTypes.arrayOf(PropTypes.number).isRequired,
    TooltipContent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    margins: { top: 30, right: 30, bottom: 30, left: 30 },
  };

  state = {
    selectedDateIndex: null,
  };

  setSelectedDateIndex = selectedDateIndex => {
    this.setState({
      selectedDateIndex,
    });
  };

  getBounds = () => {
    const { height, margins, width } = this.props;

    return {
      minX: margins.left,
      maxX: width - margins.right,
      minY: margins.bottom,
      maxY: height - margins.top,
    };
  };

  getXScale = () => {
    const { xs, xDescriptor } = this.props;
    const { minX, maxX } = this.getBounds();
    const scale = getXScale(xDescriptor, xs);

    return scale.range([minX, maxX]);
  };

  getLeftYScale = () => {
    const { leftYs, leftYDescriptor } = this.props;
    const { minY, maxY } = this.getBounds();
    const scale = getYScale(leftYDescriptor, leftYs);

    return scale.range([maxY, minY]);
  };

  getRightYScale = () => {
    const { rightYs, rightYDescriptor } = this.props;
    const { minY, maxY } = this.getBounds();
    const scale = getYScale(rightYDescriptor, rightYs);

    return scale.range([maxY, minY]);
  };

  render() {
    const {
      classes,
      height,
      width,
      xDescriptor,
      xs,
      leftYs,
      leftYDescriptor,
      rightYs,
      rightYDescriptor,
      TooltipContent,
    } = this.props;
    const { selectedDateIndex } = this.state;

    const bounds = this.getBounds();
    const xScale = this.getXScale();
    const leftYScale = this.getLeftYScale();
    const rightYScale = this.getRightYScale();

    return (
      <div className={classes.root}>
        <svg width={width} height={height}>
          <XAxis {...bounds} descriptor={xDescriptor} scale={xScale} />
          <LeftYAxis
            {...bounds}
            descriptor={leftYDescriptor}
            scale={leftYScale}
          />
          <RightYAxis
            {...bounds}
            descriptor={rightYDescriptor}
            scale={rightYScale}
          />
          <Line
            color="#4A90E2"
            xs={xs}
            ys={leftYs}
            xScale={xScale}
            yScale={leftYScale}
          />
          <Line
            color="#F5A623"
            xs={xs}
            ys={rightYs}
            xScale={xScale}
            yScale={rightYScale}
          />
          <ChartOverlay
            setSelectedDateIndex={this.setSelectedDateIndex}
            {...bounds}
            xScale={xScale}
            xs={xs}
          />
          {!isNil(selectedDateIndex) && (
            <Tooltip
              selectedDateIndex={selectedDateIndex}
              maxY={bounds.maxY}
              xScale={xScale}
              leftYScale={leftYScale}
              rightYScale={rightYScale}
              rightYs={rightYs}
              leftYs={leftYs}
              xs={xs}
              TooltipContent={TooltipContent}
            />
          )}
        </svg>
      </div>
    );
  }
}

export default AreaChart;
