import { createPageFilterTemplate } from '@/structs/pageFilter';
import {
  createEqualsAdapter,
  createILikeAdapter,
} from '@/structs/pageFilterAdapter';

import SearchInput from './component';

export const createTextFilter = createPageFilterTemplate({
  FilterComponent: SearchInput,
  FormatComponent: ({ value }) => value,
  adapter: createILikeAdapter(null),
});

export const createEqualsFilter = createPageFilterTemplate({
  FilterComponent: SearchInput,
  FormatComponent: ({ value }) => value,
  adapter: createEqualsAdapter(null),
});

export default SearchInput;
