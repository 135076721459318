import { LAYOUT_KEY } from '@esentai/core/features/campaigns/article/consts/keys';
import React from 'react';

import AddButton from '@/components/ArticleConstructorForm/AddButton';

import { CanEdit } from '../../CanEdit';

const AddButtonWithPermissions = props => (
  <CanEdit field={LAYOUT_KEY}>
    <AddButton {...props} />
  </CanEdit>
);

export default AddButtonWithPermissions;
