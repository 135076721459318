import { getDestination } from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Destination from './component';
import style from './styles';

const mapStateToProps = (state, { itemId }) => ({
  destination: getDestination(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(style),
)(Destination);
