import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Actions from '@/features/executiveReportsPage/Page/Row/Actions';
import { formatShortHumanDateTime } from '@/utils/format';

const Row = ({ filename, fileUrl, creationDate }) => (
  <>
    <TableRow>
      <TableCell>{filename}</TableCell>
      <TableCell>{formatShortHumanDateTime(creationDate)}</TableCell>
      <TableCell>
        <Actions url={fileUrl} />
      </TableCell>
    </TableRow>
  </>
);

Row.propTypes = {
  filename: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  creationDate: PropTypes.instanceOf(Date).isRequired,
};

export default Row;
