import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { clone } from '@esentai/core/features/campaigns/actions';
import {
  cloneCampaign,
  removeCampaign,
} from '@esentai/core/features/campaigns/sagas';
import { getCampaignType } from '@esentai/core/features/campaigns/selectors';
import { push } from 'connected-react-router';
import { call, put, select, spawn, takeEvery } from 'redux-saga/effects';

import { createFormStepURL } from '@/features/campaignCreatePage/utils';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { CLONE_AND_EDIT, REMOVE } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* cloneCampaignAndEdit({ payload: id }) {
  const cloneAction = clone(id);
  const type = yield select(getCampaignType, id);
  const { id: newId } = yield call(cloneCampaign, cloneAction);
  const url = yield call(createFormStepURL, type, newId, 1);

  yield put(push(url));
}

export function* removeCampaignAndRefresh({ payload: id }) {
  const type = yield select(getCampaignType, id);

  yield call(removeCampaign, type, id);
  yield call(fetchItems);
}

export default function*() {
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield takeEvery(CLONE_AND_EDIT, createTaskSaga(cloneCampaignAndEdit));
  yield takeEvery(REMOVE, createTaskSaga(removeCampaignAndRefresh));
}
