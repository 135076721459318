import { getPhoneForUser } from '@esentai/core/features/users/selectors';
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AddCircleOutline,
  DeleteOutline,
  ErrorOutline,
} from '@material-ui/icons';
import { FieldArray } from 'formik';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import DateTimePicker from '@/components/DateTimePicker';
import ImageField from '@/components/ImageField';
import Modal from '@/components/Modal';
import { KkmAutocompleteWithStore } from '@/containers/KkmAutocomplete';
import { StoreAutocomplete } from '@/containers/StoreAutocomplete';
import { UserPhoneAutocomplete } from '@/containers/UserPhoneAutocomplete';
import {
  CALCULATE_PENDING_BONUSES_KEY,
  CUSTOMER_ID_KEY,
  FILE_URL_KEY,
  ISSUED_AT_DATETIME_KEY,
  KKM_KEY,
  PHONE_KEY,
  PURCHASE_KEY,
  STORE_KEY,
  UUID_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';
import { loyaltyLevels } from '@/features/purchaseWithoutScanPage/utils';
import { validate } from '@/utils/validate';

import ProductItem from '../ProductItem';

// eslint-disable-next-line complexity
const AddPurchase = props => {
  const {
    classes,
    cancelLabel,
    confirmLabel,
    onClose,
    onSubmit,
    Field,
    Form,
    // handleBlur,
    values,
    setFieldValue,
    requiredErrors,
    validateForm,
    state,
    isValid,
    resetForm,
    touched,
  } = props;

  const [customer, setCustomer] = useState({
    data: {},
    loading: false,
    error: null,
  });

  const [customerForm, setCustomerForm] = useState({
    [CUSTOMER_ID_KEY]: '',
    [PHONE_KEY]: '',
  });

  const [bonusPercentage, setBonusPercentage] = useState(0);

  const [totalFiles, setTotalFiles] = useState(0);

  const isUserAvailable = Boolean(Object.keys(customer.data).length);

  const searchCustomer = async () => {
    setCustomer(prevState => ({
      ...prevState,
      data: {},
      loading: true,
      error: null,
    }));

    try {
      const params = {
        'phone[ilike]': getPhoneForUser(state, customerForm[PHONE_KEY]),
        'id[eq]': customerForm[CUSTOMER_ID_KEY],
        'role[eq]': 'customer',
      };

      const response = await api.doGet(`/user`, params);

      const userData = _get(response, 'payload.user', []);

      setCustomer(prevState => ({
        ...prevState,
        data: _get(userData, '0', {}),
        error: userData.length ? null : 'User not found',
      }));

      resetForm();
    } catch (error) {
      setCustomer(prevState => ({
        ...prevState,
        error,
      }));
      throw error;
    } finally {
      setCustomer(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const submitPurchase = () => {
    const payloadValues = JSON.parse(JSON.stringify(values));

    delete payloadValues.phone;
    validateForm(payloadValues);

    payloadValues[FILE_URL_KEY] = payloadValues[FILE_URL_KEY].filter(
      file => file !== '' && file,
    );

    if (isValid) {
      const payload = {
        receipt: {
          ...payloadValues,
          [CUSTOMER_ID_KEY]: _get(customer, 'data.id'),
          [KKM_KEY]: payloadValues[KKM_KEY] || '',
        },
      };

      onSubmit(payload);
    }
  };

  const calculatePurchaseTotal = () => {
    const products = values[PURCHASE_KEY];
    let total = 0;

    Object.values(products).map(
      product => (total += Number(product.amount * product.price)),
    );

    return total;
  };

  const calculatePurchaseBonus = async () => {
    if (isUserAvailable && isValid) {
      const payloadValues = JSON.parse(JSON.stringify(values));

      delete payloadValues.phone;
      delete payloadValues.files;

      try {
        const response = await api.doPost(`/receipt`, {
          receipt: {
            ...payloadValues,
            [CUSTOMER_ID_KEY]: _get(customer, 'data.id'),
            [CALCULATE_PENDING_BONUSES_KEY]: true,
          },
        });

        setBonusPercentage(response.pending_bonuses_amount);
      } catch (error) {
        throw error;
      }
    }
  };

  const addProduct = () => {
    const outputProducts = [...values[PURCHASE_KEY]];

    outputProducts.push({ amount: '', name: '', price: '' });

    setFieldValue(PURCHASE_KEY, outputProducts);
  };

  const setImageUrl = (urls, index) => {
    const [url] = urls;

    const outputFiles = [...values[FILE_URL_KEY]];

    outputFiles[index] = url;

    setFieldValue(FILE_URL_KEY, outputFiles);
    setTotalFiles(outputFiles.filter(file => file !== '' && file).length);
  };

  const removeProduct = deleteIndex => {
    values[PURCHASE_KEY].splice(deleteIndex, 1);

    setFieldValue(PURCHASE_KEY, values[PURCHASE_KEY]);
  };

  const renderProducts = products =>
    products.map((item, index) => (
      <div key={item}>
        <ProductItem {...props} index={index}>
          {products.length === 1 ||
          (products.length > 1 && products.length === index + 1) ? (
            <IconButton className={classes.productButton} onClick={addProduct}>
              <AddCircleOutline />
            </IconButton>
          ) : (
            <IconButton
              className={classes.productButton}
              onClick={() => removeProduct(index)}
            >
              <DeleteOutline />
            </IconButton>
          )}
        </ProductItem>
      </div>
    ));

  useEffect(() => {
    const timer = setTimeout(() => {
      calculatePurchaseBonus();
    }, 1500);

    return () => clearTimeout(timer);
  }, [isValid, values]);

  return (
    <Modal {...props} onClose={onClose}>
      <Paper className={classes.wrapper}>
        <Typography variant="h4" gutterBottom>
          Добавить покупку
        </Typography>

        <span className={classes.notice}>
          Заполните ID пользователя либо номер телефона
        </span>

        <div style={{ display: 'flex' }}>
          <div className={classes.searchCustomerInfo}>
            <div
              style={{ width: '100%', display: 'flex', alignItems: 'center' }}
            >
              <TextField
                label="ID покупателя"
                value={customerForm[CUSTOMER_ID_KEY]}
                margin="normal"
                type="number"
                className={classes.hideArrows}
                style={{ marginRight: 15, marginBottom: 20 }}
                onChange={event => {
                  setCustomerForm({
                    ...customerForm,
                    [CUSTOMER_ID_KEY]: event.target.value,
                  });
                }}
                onKeyPress={event => {
                  validate(event, 'positiveInt');

                  if (
                    event.key === 'Enter' &&
                    (customerForm[CUSTOMER_ID_KEY] || customerForm[PHONE_KEY])
                  ) {
                    searchCustomer();
                  }
                }}
              />

              <UserPhoneAutocomplete
                onChange={event =>
                  setCustomerForm(prevState => ({
                    ...prevState,
                    [PHONE_KEY]: event,
                  }))
                }
                selectedItem={customerForm[PHONE_KEY]}
                InputProps={{
                  placeholder: 'Телефон',
                }}
                isSmall
              />
            </div>

            <Button
              disabled={
                !customerForm[CUSTOMER_ID_KEY] && !customerForm[PHONE_KEY]
              }
              onClick={searchCustomer}
            >
              Поиск покупателя
            </Button>
          </div>

          {isUserAvailable && (
            <div className={classes.customerInfo}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                  ФИО покупателя:
                </Typography>
                <Typography variant="subtitle1">{`${_get(
                  customer,
                  'data.first_name',
                  '-',
                )} ${_get(customer, 'data.last_name', '-')}`}</Typography>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                  Уровень лояльности покупателя:
                </Typography>
                <Typography variant="subtitle1">
                  {loyaltyLevels[_get(customer, 'data.level', '-')]}
                </Typography>
              </div>
            </div>
          )}
        </div>

        {customer.loading && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
        )}

        {customer.error && (
          <div style={{ textAlign: 'center' }}>
            <IconButton>
              <ErrorOutline />
            </IconButton>
            <span>Данный пользователь не найден</span>
          </div>
        )}

        {isUserAvailable && (
          <Form>
            <div className={classes.uuid_block}>
              <Field
                id={UUID_KEY}
                margin="normal"
                Target={TextField}
                fullWidth
                name={UUID_KEY}
                label="UUID (ФП/Номер чека)"
              />
            </div>

            <div className={classes.purchases_block}>
              <FieldArray
                name={values[PURCHASE_KEY]}
                render={() => renderProducts(values[PURCHASE_KEY])}
              />
            </div>

            <div className={classes.storeKkmWrapper}>
              <div
                className={requiredErrors[STORE_KEY] ? classes.errorField : ''}
                style={{ marginRight: 15 }}
              >
                <StoreAutocomplete
                  id={values[STORE_KEY]}
                  name={values[STORE_KEY]}
                  onChange={value => {
                    setFieldValue(STORE_KEY, value);
                    if (
                      !Object.prototype.hasOwnProperty.call(values, KKM_KEY)
                    ) {
                      setFieldValue(KKM_KEY, '');
                    } else {
                      setFieldValue(KKM_KEY, undefined);
                    }
                  }}
                  // onBlur={handleBlur}
                  selectedItem={values[STORE_KEY]}
                  InputProps={{
                    placeholder: 'Магазин',
                  }}
                />
              </div>

              <div
                className={
                  requiredErrors[KKM_KEY] || requiredErrors[KKM_KEY] === ''
                    ? classes.errorField
                    : ''
                }
              >
                <KkmAutocompleteWithStore
                  id={values[KKM_KEY]}
                  name={values[KKM_KEY]}
                  onChange={id => setFieldValue(KKM_KEY, id)}
                  selectedItem={values[KKM_KEY]}
                  storeId={values[STORE_KEY]}
                  InputProps={{
                    placeholder: 'Номер ККМ',
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <TextField
                disabled
                id="standard-disabled"
                label="Сумма покупки (KZT)"
                value={calculatePurchaseTotal()}
                style={{ marginRight: 15 }}
              />

              <div className={classes.centerAlign}>
                <TextField
                  disabled
                  id="standard-disabled"
                  label="Сумма бонусов к начислению"
                  value={bonusPercentage}
                  className={classes.bonusInput}
                />
                <span className={classes.notice}>
                  Сумма бонусов к начислению отобразится после заполнения всех
                  полей
                </span>
              </div>
            </div>

            <div className={classes.issuedDatetime}>
              <Field
                id={ISSUED_AT_DATETIME_KEY}
                clearable
                disableFuture
                Target={DateTimePicker}
                helperText="Дата и время покупки"
                name={ISSUED_AT_DATETIME_KEY}
              />
            </div>

            <div>
              <div className={classes.filesWrapper}>
                {[...Array(3).keys()].map((item, index) => (
                  <ImageField
                    key={item}
                    includePdf
                    ids={
                      values[FILE_URL_KEY] && values[FILE_URL_KEY][index]
                        ? [values[FILE_URL_KEY][index]]
                        : []
                    }
                    onChange={url => setImageUrl(url, index)}
                  />
                ))}
              </div>
              <span className={classes.notice}>
                Пожалуйста прикрепите файл в формате: JPG, PDF, PNG
              </span>
              <br />
              {Object.keys(touched).length && totalFiles === 0 ? (
                <span className={classes.fileError}>
                  Прикрепите хотя бы 1 файл
                </span>
              ) : (
                ''
              )}
            </div>
          </Form>
        )}
        <div className={classes.footer}>
          <Button onClick={onClose}>{cancelLabel}</Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            disabled={!isValid || !isUserAvailable}
            onClick={submitPurchase}
          >
            {confirmLabel}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

AddPurchase.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  Field: PropTypes.func.isRequired,
  Form: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  requiredErrors: PropTypes.object,
  touched: PropTypes.object,
  state: PropTypes.object,
  values: PropTypes.object,
  resetForm: PropTypes.func,
};

AddPurchase.defaultProps = {
  values: {},
  state: {},
  touched: {},
  setFieldValue: () => {},
  requiredErrors: {},
  resetForm: () => {},
};

export default AddPurchase;
