import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { createHistoryData } from '@esentai/core/features/history_data/sagas';
import { createKkm } from '@esentai/core/features/kkms/sagas';
import { select } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getCurrentUserId } from '@/features/auth/selectors';
import { ROUTE_PATH as KKMS_PATH } from '@/features/kkmsPage/consts';

import { CREATE_KKM } from './types';

export function* createKkmAction(action) {
  const { payload: attributes } = action;

  attributes.status = 'active';
  delete attributes.kkmStatus;

  const { cash_machine } = yield call(createKkm, {
    ...attributes,
    fns_id: attributes.fns_id.trim(),
  });

  const history = {
    object_id: cash_machine[0].id,
    model: 'cash_machine',
    user_id: yield select(getCurrentUserId),
    comment: 'Новая запись',
    new_data: JSON.stringify(attributes),
    operation: 'create',
  };

  yield call(createHistoryData, history);
  yield put(push(KKMS_PATH));
}

export default function*() {
  yield takeLatest(CREATE_KKM, createTaskSaga(createKkmAction));
}
