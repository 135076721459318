import {
  getInProcessingAmount,
  getInvoicedAmount,
  getStoreName,
  getTotalPaidAmount,
  getTotalToPayAmount,
} from '@esentai/core/features/reconciliationStoreToCp/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: reconciliationId }) => ({
  storeName: getStoreName(state, reconciliationId),
  totalToPayAmount: getTotalToPayAmount(state, reconciliationId),
  totalPaidAmount: getTotalPaidAmount(state, reconciliationId),
  sumInProcessing: getInProcessingAmount(state, reconciliationId),
  invoicedAmount: getInvoicedAmount(state, reconciliationId),
  reconciliationId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
