import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { formatPercentage } from '@/utils/format';

const Factoid = ({ classes, description, name, value, ratio }) => (
  <div className={classes.root}>
    <Typography variant="h2" color="inherit" className={classes.value}>
      {value}
      {Boolean(ratio) && (
        <Typography
          variant="headline"
          color="textSecondary"
          className={classes.ratio}
        >
          {formatPercentage(ratio)}
        </Typography>
      )}
    </Typography>
    <Typography
      variant="headline"
      color="textSecondary"
      className={classes.name}
    >
      {name}
    </Typography>
    <Typography
      variant="body2"
      color="textSecondary"
      className={classes.description}
    >
      {description}
    </Typography>
  </div>
);

Factoid.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  ratio: PropTypes.string.isRequired,
};

export default Factoid;
