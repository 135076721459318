import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getMallBonusesError,
  getItemsIds: getMallBonusIds,
  getItemsMeta: getMallBonusesMeta,
  getItemsTotalCount: getMallBonusesTotalCount,
  isLoadingItems: isLoadingMallBonuses,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
