import { complement, isNil } from 'ramda';

import {
  BEACON_AND_FLOOR_ROUTE_PATH,
  BEACON_ROUTE_PATH,
  FLOOR_ROUTE_PATH,
  LOCATION_AND_FLOOR_ROUTE_PATH,
  LOCATION_ROUTE_PATH,
} from './consts';

const isPresent = complement(isNil);

export const createMapPageUrl = ({ beaconId, floor, locationId }) => {
  const hasBeacon = isPresent(beaconId);
  const hasFloor = isPresent(floor);
  const hasLocation = isPresent(locationId);

  if (hasBeacon && hasFloor) {
    return BEACON_AND_FLOOR_ROUTE_PATH.replace(':floor', floor).replace(
      ':beacon',
      beaconId,
    );
  } else if (hasLocation && hasFloor) {
    return LOCATION_AND_FLOOR_ROUTE_PATH.replace(':floor', floor).replace(
      ':location',
      locationId,
    );
  } else if (hasBeacon) {
    return BEACON_ROUTE_PATH.replace(':beacon', beaconId);
  } else if (hasLocation) {
    return LOCATION_ROUTE_PATH.replace(':location', locationId);
  } else if (hasFloor) {
    return FLOOR_ROUTE_PATH.replace(':floor', floor);
  }

  return null;
};
