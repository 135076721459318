import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { canUpdateKkm } from '@/features/kkmsPage/permissions';

import EditButton from './EditButton';
import HistoryButton from './HistoryButton';

const Actions = ({ classes, kkmId }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <EditButton kkmId={kkmId} />
    <HistoryButton kkmId={kkmId} permission={canUpdateKkm} />
  </Grid>
);

Actions.propTypes = {
  kkmId: PropTypes.number.isRequired,
};

export default Actions;
