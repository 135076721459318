import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/questionsPage/ducks/query';
import {
  getError,
  getItemsIds,
  getItemsTotalCount,
  isLoadingItems,
} from '@/features/questionsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import Questions from './component';

const mapStateToProps = state => ({
  error: getError(state),
  isLoading: isLoadingItems(state),
  itemsIds: getItemsIds(state),
  totalCount: getItemsTotalCount(state),
});

export default compose(
  connect(mapStateToProps),
  withQuerySync(queryDuck),
)(Questions);
