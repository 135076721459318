import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import StoreNameLazy from '@/containers/StoreNameLazy';
import UserNameLazy from '@/containers/UserNameLazy';
import { RECONCILIATION_ONE_TIME_TYPE } from '@/features/storeBonusCreatePage/consts/keys';
import { dateTimeFormat } from '@/utils/format';

import { BonusFilters } from '../../../../components/BonusFIlters/BonusFilters';
import { reconciliationType as getReconciliationType } from '../../labels';

const Row = ({
  creationDate,
  percent,
  startDate,
  endDate,
  store,
  filters,
  user,
  comment,
  status,
  bonusName,
  periodicDay,
  oneTimeDatetime,
  reconciliationType,
}) => (
  <TableRow>
    <CondenseTableCell>
      <UserNameLazy userId={user} />
    </CondenseTableCell>

    <CondenseTableCell>
      {<StoreNameLazy key={store} storeId={store} />}
    </CondenseTableCell>

    <CondenseTableCell>{bonusName}</CondenseTableCell>

    <CondenseTableCell>
      {dateTimeFormat(startDate)} - {dateTimeFormat(endDate)}
    </CondenseTableCell>

    <CondenseTableCell>{percent}%</CondenseTableCell>

    <CondenseTableCell>{dateTimeFormat(creationDate)}</CondenseTableCell>

    <CondenseTableCell>
      <BonusFilters filter={filters} />
    </CondenseTableCell>

    <CondenseTableCell>{status ? 'Активный' : 'Архив'}</CondenseTableCell>

    <CondenseTableCell>
      {getReconciliationType(reconciliationType)}
    </CondenseTableCell>

    <CondenseTableCell>
      {reconciliationType === RECONCILIATION_ONE_TIME_TYPE
        ? dateTimeFormat(oneTimeDatetime)
        : periodicDay}
    </CondenseTableCell>

    <CondenseTableCell>{comment}</CondenseTableCell>
  </TableRow>
);

Row.propTypes = {
  creationDate: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.func.isRequired,
  store: PropTypes.array.isRequired,
  filters: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  bonusName: PropTypes.string.isRequired,
  reconciliationType: PropTypes.string.isRequired,
  periodicDay: PropTypes.number,
  oneTimeDatetime: PropTypes.instanceOf(Date),
};

Row.defaultProps = {
  periodicDay: 0,
  oneTimeDatetime: '-',
};

export default Row;
