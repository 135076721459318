import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import UserNameLazy from '@/containers/UserNameLazy';
import { dateTimeFormat, formatMoney } from '@/utils/format';

import AttachmentButton from '../../AttachmentButton';

const Row = ({
  totalToPay,
  bonusesSpent,
  bonusesRefunded,
  startDate,
  endDate,
  userId,
  registryUrl,
}) => (
  <TableRow>
    <CondenseTableCell>{formatMoney(totalToPay)}</CondenseTableCell>

    <CondenseTableCell>{bonusesSpent}</CondenseTableCell>

    <CondenseTableCell>{bonusesRefunded}</CondenseTableCell>

    <CondenseTableCell>
      {dateTimeFormat(startDate)} - {dateTimeFormat(endDate)}
    </CondenseTableCell>

    <CondenseTableCell>
      {registryUrl ? <AttachmentButton href={registryUrl} /> : '-'}
    </CondenseTableCell>

    <CondenseTableCell>{dateTimeFormat(endDate)}</CondenseTableCell>

    <CondenseTableCell>
      <UserNameLazy userId={userId} />
    </CondenseTableCell>
  </TableRow>
);

Row.propTypes = {
  totalToPay: PropTypes.number.isRequired,
  bonusesSpent: PropTypes.number.isRequired,
  bonusesRefunded: PropTypes.number.isRequired,
  startDate: PropTypes.any.isRequired,
  endDate: PropTypes.any.isRequired,
  userId: PropTypes.number.isRequired,
  registryUrl: PropTypes.string.isRequired,
};

export default Row;
