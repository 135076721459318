export default {
  indiceBlock: {
    marginBottom: 50,
  },

  indice: {
    marginBottom: 8,
  },

  brandsNameBlock: {
    columns: 'auto',
    maxHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
};
