import PropTypes from 'prop-types';
import { __, compose, equals, includes, not, prop } from 'ramda';
import React, { Component } from 'react';

import Autocomplete from '@/components/Autocomplete';
import Chip from '@/components/AutocompleteBase/Chip';
import AutocompleteMultipleBase from '@/components/AutocompleteMultipleBase';

class AutocompleteMultiple extends Component {
  static propTypes = {
    keyProp: PropTypes.func,
    labelProp: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedItem: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    keyProp: prop('key'),
    labelProp: prop('label'),
    Component: AutocompleteMultipleBase,
  };

  getLabel = key => {
    const { keyProp, options } = this.props;
    const matching = compose(equals(key), keyProp);
    const option = options.find(matching);

    if (option) {
      const { labelProp } = this.props;

      return labelProp(option);
    }

    return null;
  };

  getUnselectedOptions = () => {
    const { keyProp, options, selectedItem } = this.props;
    const isSelected = compose(includes(__, selectedItem), keyProp);
    const notSelected = compose(not, isSelected);

    return options.filter(notSelected);
  };

  render() {
    return (
      <Autocomplete
        Chip={this.renderChip}
        {...this.props}
        options={this.getUnselectedOptions()}
      />
    );
  }

  renderChip = ({ value, ...rest }) => {
    const label = this.getLabel(value);

    if (!label) {
      return null;
    }

    return <Chip {...rest} id={value} label={label} />;
  };
}

export default AutocompleteMultiple;
