export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  label: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    minWidth: 92,
  },
  chart: {
    height: 4,
    backgroundColor: 'black',
    opacity: 0.3,
    margin: [0, 10],
    flexBasis: ({ percentage }) => `${percentage}%`,
  },
  percentage: {
    fontSize: 14,
  },
};
