import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import { createAction } from 'redux-actions';

import {
  CLEAR_SELECTION,
  FETCH_ITEMS,
  MOVE_BEACON,
  SELECT_BEACON,
  SELECT_FLOOR,
  SELECT_RENT_PLACE,
} from './types';

export const fetchItems = createTaskAction(
  FETCH_ITEMS,
  null,
  () => FETCH_ITEMS,
);

export const clearSelection = createAction(CLEAR_SELECTION);

export const moveBeacon = createTaskAction(
  MOVE_BEACON,
  null,
  ({ beaconId }) => beaconId,
);

export const selectBeacon = createAction(SELECT_BEACON);

export const selectFloor = createAction(SELECT_FLOOR);

export const selectRentPlace = createAction(SELECT_RENT_PLACE);
