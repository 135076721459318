import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import AsyncButton from '@/components/AsyncButton';
import PlayPauseButton from '@/components/PlayPauseButton';

const UserActivityToggler = ({ active, error, isLoading, toggleActivity }) => (
  <PlayPauseButton
    ButtonComponent={AsyncButton}
    buttonProps={{
      ButtonComponent: IconButton,
      color: 'inherit',
      error,
      isLoading,
    }}
    active={active}
    onChange={toggleActivity}
  />
);

UserActivityToggler.defaultProps = {
  error: null,
};

UserActivityToggler.propTypes = {
  active: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  isLoading: PropTypes.bool,
  toggleActivity: PropTypes.func.isRequired,
};

UserActivityToggler.defaultProps = {
  isLoading: false,
};

export default UserActivityToggler;
