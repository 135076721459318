import { getStoreName } from '@esentai/core/features/reconciliationStoreBonusDetails/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import {
  getReconciliationError,
  getReconciliationIds,
  getReconciliationTotalCount,
  getStoreId,
  isLoadingReconciliation,
} from '../selectors';
import ReconciliationStoreToCp from './component';
import styles from './styles';

const mapStateToProps = state => {
  const reconciliationIds = getReconciliationIds(state);

  return {
    error: getReconciliationError(state),
    reconciliationIds,
    isLoading: isLoadingReconciliation(state),
    totalCount: getReconciliationTotalCount(state),
    storeId: getStoreId(state),
    storeName: getStoreName(state, reconciliationIds[0]),
  };
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(ReconciliationStoreToCp);
