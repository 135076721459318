import PropTypes from 'prop-types';
import React from 'react';

import DotIndicator from '@/components/DotIndicator';

const StatusIndicator = ({ active }) => {
  const status = active ? 'enabled' : 'disabled';

  return <DotIndicator status={status} />;
};

StatusIndicator.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default StatusIndicator;
