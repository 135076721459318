import { connect } from 'react-redux';

import BenefitCanEdit from '@/components/CanEdit';
import BenefitCanEditRadioGroup from '@/components/CanEditRadioBoxGroup';
import { isAttributeEditable } from '@/features/benefitCreatePage/selectors';

const mapStateToProps = (state, { field }) => ({
  canEdit: isAttributeEditable(state, field),
});

export const CanEdit = connect(mapStateToProps)(BenefitCanEdit);
export const CanEditRadioBoxGroup = connect(mapStateToProps)(
  BenefitCanEditRadioGroup,
);
