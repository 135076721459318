import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import PurchaseWithoutScanPage from './component';
import styles from './styles';

const mapStateToProps = state => ({
  state,
});

export default compose(
  connect(mapStateToProps, () => ({})),
  withStyles(styles),
)(PurchaseWithoutScanPage);
