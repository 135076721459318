import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { userStatus } from '@/utils/labels';

class BenefitSuggestion extends Component {
  static propTypes = {
    itemId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    userLevel: PropTypes.string,
    SelectionControl: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userLevel: null,
  };

  render() {
    const { classes, itemId, title, userLevel, SelectionControl } = this.props;

    return (
      <div className={classes.root}>
        <SelectionControl itemId={itemId} />
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="caption" className={classes.status}>
          {userLevel && `Необходимый статус: ${userStatus(userLevel)}`}
        </Typography>
      </div>
    );
  }
}

export default BenefitSuggestion;
