import { RESOURCE_NAME } from '@esentai/core/features/reconciliationReceipts/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadReconciliationReceipts = authorizeIf(
  canRead(RESOURCE_NAME),
);
export const canCreateReconciliationReceipts = authorizeIf(
  canCreate(RESOURCE_NAME),
);
export const canUpdateReconciliationReceipts = authorizeIf(
  canUpdate(RESOURCE_NAME),
);
