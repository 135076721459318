import {
  getActiveBonusesForUser,
  getDeviceChangesCountForUser,
  getLastActivityDateForUser,
  getLastPurchaseDateForUser,
  getMoneySpentForUser,
  getPhoneForUser,
  getReceiptsCountForUser,
  getRegistrationDateForUser,
  getReverseFullNameForUser,
  getStatusForUser,
  isUserActive,
  isUserSuspicious,
} from '@esentai/core/features/users/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: userId }) => ({
  active: isUserActive(state, userId),
  activeBonuses: getActiveBonusesForUser(state, userId),
  deviceChangesCount: getDeviceChangesCountForUser(state, userId),
  itemId: userId,
  lastActivityDate: getLastActivityDateForUser(state, userId),
  registrationDate: getRegistrationDateForUser(state, userId),
  lastPurchaseDate: getLastPurchaseDateForUser(state, userId),
  name: getReverseFullNameForUser(state, userId),
  phone: getPhoneForUser(state, userId),
  receiptsCount: getReceiptsCountForUser(state, userId),
  status: getStatusForUser(state, userId),
  suspicious: isUserSuspicious(state, userId),
  moneySpent: getMoneySpentForUser(state, userId),
  userId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
