import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';

import Chart from './Chart';
import Table from './Table';
import TopBar from './TopBar';

class CampaignsStatisticsPage extends Component {
  static propTypes = {
    defaultMetricsPageUrl: PropTypes.string.isRequired,
    error: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    itemsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    shouldRedirectToDefaultMetricsPage: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const {
      defaultMetricsPageUrl,
      itemsIds,
      error,
      isLoading,
      shouldRedirectToDefaultMetricsPage,
      totalCount,
    } = this.props;

    if (shouldRedirectToDefaultMetricsPage) {
      return <Redirect to={defaultMetricsPageUrl} />;
    }

    return (
      <Page>
        <PageHeader borderBottom>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Статистика</PageTitle>
          <PageSubtitle>
            Статистика ограничена только вашим магазином.
          </PageSubtitle>
        </PageHeader>
        <PageContent>
          <TopBar />
          <Chart />
          <Table
            error={error}
            items={itemsIds}
            isLoading={isLoading}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default CampaignsStatisticsPage;
