import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldDescription from '@/components/FieldDescription';

const Label = ({ classes, children, title }) => (
  <div className={classes.root}>
    <FieldDescription title={title} gutterBottom={false} />
    <Typography variant="h5">{children}</Typography>
  </div>
);

Label.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Label;
