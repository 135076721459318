import { STORE_KEY } from '@esentai/core/features/salesTurnoverDetails/consts/keys';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';
import { RETRY_FETCH } from './consts/types';
import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

const { getQuery } = queryDuck.selectors;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const fetchTurnoverDetails = function*() {
  const { params } = yield select(createMatchSelector({ path: ROUTE_PATH }));
  const { storeId } = params;
  const query = yield select(getQuery);
  const newQuery = mergeQueriesRight(
    query,
    makeQuery(where(STORE_KEY, equals(storeId))),
  );

  yield call(itemsDuck.sagas.fetchItems, newQuery);
};

export const watchQueryChange = createQueryChangeWatcher(fetchTurnoverDetails);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchTurnoverDetails);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
