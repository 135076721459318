import PropTypes from 'prop-types';
import React from 'react';

import { Forbidden } from '@/components/ErrorMessages';
import ProtectedRoute from '@/components/ProtectedRoute';

const CheckAccessRoute = ({ component, hasAccess, ...rest }) => {
  const targetComponent = hasAccess ? component : Forbidden;

  return <ProtectedRoute {...rest} component={targetComponent} />;
};

CheckAccessRoute.propTypes = {
  component: PropTypes.func.isRequired,
  hasAccess: PropTypes.bool.isRequired,
};

export default CheckAccessRoute;
