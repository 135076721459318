import { indexResources as indexResourcesAction } from '@esentai/core/ducks/dao/actions';
import CRUDResource from '@esentai/core/resources/crud';
import PropTypes from 'prop-types';
import { compose, flatten } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapDispatchToProps = { indexResources: indexResourcesAction };

export const pureWithItemsFetcher = WrappedComponent => {
  class WithItemsFetcher extends Component {
    static propTypes = {
      api: PropTypes.instanceOf(CRUDResource).isRequired,
      buildQuery: PropTypes.func.isRequired,
      indexResources: PropTypes.func.isRequired,
      reducer: PropTypes.func,
      suggestionMapper: PropTypes.func,
      storeId: PropTypes.number.isRequired,
    };

    static defaultProps = {
      reducer: undefined,
      suggestionMapper: x => x.id,
    };

    state = {
      isLoading: false,
      suggestions: [],
      error: null,
    };

    fetchItems = async (...args) => {
      const {
        api,
        buildQuery,
        indexResources,
        reducer,
        suggestionMapper,
        storeId,
      } = this.props;

      this.setState({ isLoading: true, error: null });

      try {
        const query = buildQuery(args[0], storeId);
        const { payload } = await api.query(query);
        const filtered = reducer ? reducer(payload) : payload;

        indexResources(filtered);

        const suggestions = flatten(
          filtered[api.resourceName].map(suggestionMapper),
        );

        this.setState({ suggestions });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        this.setState({ error });
      } finally {
        this.setState({ isLoading: false });
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          fetchItems={this.fetchItems}
        />
      );
    }
  }

  return WithItemsFetcher;
};

export default compose(connect(null, mapDispatchToProps), pureWithItemsFetcher);
