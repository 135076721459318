import React, { Component } from 'react';

import api from '@/api';
import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';

import EditAppVersionModal from './EditAppVersionModal/component';
import GridComponent from './Grid';

class AppVersionPage extends Component {
  state = {
    appId: 1,
    isEditIOSVersionOpened: false,
    isEditAndroidVersionOpened: false,
    appVersions: {
      version_android: '',
      version_ios: '',
    },
  };

  loadAppVersions = async () => {
    try {
      const response = await api.doGet(`/application-settings`);
      const appSettings = response.payload.application_settings[0];

      this.setState({
        appVersions: {
          version_android: appSettings.version_android,
          version_android_forced_update:
            appSettings.version_android_forced_update,
          version_ios: appSettings.version_ios,
          version_ios_forced_update: appSettings.version_ios_forced_update,
          appId: appSettings.id,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  editAppVersion = async values => {
    try {
      await api
        .doPatch(`/application-settings`, {
          application_settings: [
            {
              ...values,
              id: this.state.appId,
            },
          ],
        })
        .then(() => {
          this.handleCloseModals();
          this.loadAppVersions();
        });
    } catch (err) {
      throw err;
    }
  };

  hanldeToggleIOSVersionModal = () => {
    this.setState({
      isEditIOSVersionOpened: !this.state.isEditIOSVersionOpened,
    });
  };

  hanldeToggleAndroidVersionModal = () => {
    this.setState({
      isEditAndroidVersionOpened: !this.state.isEditAndroidVersionOpened,
    });
  };

  handleCloseModals = () => {
    this.setState({
      isEditIOSVersionOpened: false,
      isEditAndroidVersionOpened: false,
    });
  };

  componentDidMount() {
    this.loadAppVersions();
  }

  render() {
    return (
      <SettingsPage>
        <SettingsPageHeader>
          <SettingsPageTitle>Версия Приложения</SettingsPageTitle>
        </SettingsPageHeader>
        <SettingsPageContent>
          <GridComponent
            appVersions={this.state.appVersions}
            handleToggleEditIOSModal={this.hanldeToggleIOSVersionModal}
            handleToggleEditAndroidModal={this.hanldeToggleAndroidVersionModal}
          />
          <EditAppVersionModal
            isOpen={this.state.isEditIOSVersionOpened}
            onClose={this.hanldeToggleIOSVersionModal}
            onSubmit={this.editAppVersion}
            currentAppVersion={this.state.appVersions.version_ios}
            forcedUpdate={this.state.appVersions.version_ios_forced_update}
            isIOS
          />
          <EditAppVersionModal
            isOpen={this.state.isEditAndroidVersionOpened}
            onClose={this.hanldeToggleAndroidVersionModal}
            onSubmit={this.editAppVersion}
            currentAppVersion={this.state.appVersions.version_android}
            forcedUpdate={this.state.appVersions.version_android_forced_update}
          />
        </SettingsPageContent>
      </SettingsPage>
    );
  }
}

export default AppVersionPage;
