import {
  getBannerArticleId,
  getBannerBenefitId,
  getBannerCalendarEventId,
  getBannerDestination,
  getBannerEnglishTitle,
  getBannerExternalPromoId,
  getBannerImageURL,
  getBannerKazakhTitle,
  getBannerRussianTitle,
  getBannerStoreId,
  getBannerType,
  getCampaignPopupDelay,
  getPlacement,
  getPriority,
} from '@esentai/core/features/campaigns/banner/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepThree } from '@/features/campaignCreatePage/actions';
import { getCampaignId } from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import FirstStep from './component';
import form from './form';

const mapStateToProps = state => {
  const id = getCampaignId(state);

  return {
    articleId: getBannerArticleId(state, id),
    benefitId: getBannerBenefitId(state, id),
    calendarEventId: getBannerCalendarEventId(state, id),
    externalPromoId: getBannerExternalPromoId(state, id),
    destination: getBannerDestination(state, id),
    englishTitle: getBannerEnglishTitle(state, id),
    russianTitle: getBannerRussianTitle(state, id),
    kazakhTitle: getBannerKazakhTitle(state, id),
    imageUrl: getBannerImageURL(state, id),
    popupDelay: getCampaignPopupDelay(state, id),
    placement: getPlacement(state, id),
    priority: getPriority(state, id),
    storeId: getBannerStoreId(state, id),
    type: getBannerType(state, id),
  };
};

const mapDispatchToProps = { onSubmit: goToStepThree };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
)(FirstStep);
