import {
  getDateCreated,
  getExpiredAmount,
  getIssuedAmount,
  getRecipientId,
  getRecipientStatus,
  getSenderId,
} from '@esentai/core/features/giftCertificates/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: giftCertificateId }) => ({
  senderId: getSenderId(state, giftCertificateId),
  recipientId: getRecipientId(state, giftCertificateId),
  dateCreated: getDateCreated(state, giftCertificateId),
  expiredAmount: getExpiredAmount(state, giftCertificateId),
  issuedAmount: getIssuedAmount(state, giftCertificateId),
  recipientStatus: getRecipientStatus(state, giftCertificateId),
  giftCertificateId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
