import { getCampaignStatus } from '@esentai/core/features/campaigns/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchItem } from '@/features/campaignCreatePage/actions';
import { getCampaignId } from '@/features/campaignCreatePage/selectors';

import NonEditableMessage from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getCampaignId(state);

  return {
    status: getCampaignStatus(state, itemId),
  };
};

const mapDispatchToProps = { fetchItem };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(NonEditableMessage);
