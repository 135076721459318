import { STATUS_VALUES } from '@esentai/core/features/merchants/consts/keys';
import {
  getCompanyForMerchant,
  isMerchantLoaded,
} from '@esentai/core/features/merchants/selectors';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import MerchantNameLazy from '@/containers/MerchantNameLazy';
import UserNameLazy from '@/containers/UserNameLazy';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';
import { dateTimeFormat } from '@/utils/format';

import { HistoryPage } from '../../HistoryPage/HistoryPage';
import Actions from './Actions';
import Description from './Description';

const historyHeaders = [
  'БИН/ИИН',
  'Компания',
  'Статус',
  'Пользователь',
  'Дата изменения',
  'Комментарий',
];

class Merchant extends Component {
  static propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    itemId: PropTypes.string.isRequired,
  };

  render() {
    const { isLoaded, itemId } = this.props;

    if (!isLoaded) {
      return null;
    }

    return (
      <>
        <Page>
          <PageHeader gutterBottom={false}>
            <Breadcrumbs>
              <Breadcrumb to={MERCHANTS_PATH}>Арендаторы</Breadcrumb>
            </Breadcrumbs>
            <BackButton />
            <PageTitle>
              <MerchantNameLazy
                getTitle={getCompanyForMerchant}
                isLoaded={isMerchantLoaded}
                merchantId={itemId}
              />
            </PageTitle>
          </PageHeader>
          <PageContent>
            <PaperSheet>
              <Description merchantId={itemId} />
              <Actions />
            </PaperSheet>
          </PageContent>
        </Page>
        <HistoryPage
          model="merchant"
          objectId={itemId}
          headers={historyHeaders}
        >
          {rows =>
            rows.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.data.identification_number}</TableCell>
                <TableCell>{row.data.company}</TableCell>
                <TableCell>{STATUS_VALUES[row.data.status]}</TableCell>
                <TableCell>
                  <UserNameLazy userId={row.user_id} />
                </TableCell>
                <TableCell>{dateTimeFormat(row.record_created)}</TableCell>
                <TableCell>{row.comment}</TableCell>
              </TableRow>
            ))
          }
        </HistoryPage>
      </>
    );
  }
}

export default Merchant;
