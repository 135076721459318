import {
  ARTICLE_TYPE,
  CALENDAR_EVENT_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import React from 'react';

import FlowRateChart from './FlowRateChart';

const CampaignInvolvement = ({
  classes,
  scrollCountTo25Percent,
  scrollCountTo50Percent,
  scrollCountTo75Percent,
  scrollCountTo100Percent,
  campaignType,
}) => {
  const isVisible = includes(campaignType, [CALENDAR_EVENT_TYPE, ARTICLE_TYPE]);

  return (
    isVisible && (
      <div className={classes.wrapper}>
        <Typography variant="h4" className={classes.label}>
          Вовлеченность
        </Typography>
        <div className={classes.bar}>
          <FlowRateChart
            percentage={scrollCountTo25Percent}
            label="до 1/4 статьи"
          />
          <FlowRateChart
            percentage={scrollCountTo50Percent}
            label="до 1/2 статьи"
          />
          <FlowRateChart
            percentage={scrollCountTo75Percent}
            label="до 3/4 статьи"
          />
          <FlowRateChart
            percentage={scrollCountTo100Percent}
            label="вся статья"
          />
        </div>
      </div>
    )
  );
};

CampaignInvolvement.propTypes = {
  scrollCountTo25Percent: PropTypes.number.isRequired,
  scrollCountTo50Percent: PropTypes.number.isRequired,
  scrollCountTo75Percent: PropTypes.number.isRequired,
  scrollCountTo100Percent: PropTypes.number.isRequired,
  campaignType: PropTypes.string.isRequired,
};

export default CampaignInvolvement;
