import {
  FINISH,
  TO_INVOICE,
  TO_PROCESSING,
} from '@esentai/core/features/reconciliationStoreBonusDetails/consts/actionTypes.js';
import {
  actionHeading,
  buttonLabel,
  payAmountLabel,
  totalAmountLabel,
} from '@esentai/core/features/reconciliationStoreBonusDetails/labels.js';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import api from '@/api';

import FileFiled from '../../../../../components/FileFiled';

export interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  fetchItems: () => void;
  url: string;
  itemId: number;
  payload: Record<string, any>;
  title: string;
  confirmationMessage: string;
  totalToPayAmount: number;
  processingAmount: number;
  invoicedAmount: number;
  action: string;
}

export interface ConfirmationDialogState {
  paidAmount: number;
  error: boolean;
  errText: string;
  action: string;
  imgUrl: string;
}

class ConfirmationDialog extends React.Component<
  ConfirmationDialogProps,
  ConfirmationDialogState
> {
  constructor(props: ConfirmationDialogProps) {
    super(props);

    this.state = {
      paidAmount: 0,
      error: false,
      errText: 'Максимальное количество символов 7!',
      action: props.action,
      imgUrl: '',
    };
  }

  onSubmit() {
    if (this.state.action === FINISH) {
      this.props.payload.store_bonus_repayment_details.paid = this.state.paidAmount;
      this.props.payload.store_bonus_repayment_details.payment_file_url = this.state.imgUrl;
    } else if (this.state.action === TO_INVOICE) {
      this.props.payload.store_bonus_details.sum = this.state.paidAmount;
    }

    api.doPost(this.props.url, this.props.payload).then(() => {
      this.props.onClose();
      this.props.fetchItems();
    });
  }

  handleChange = event => {
    if (event.target.value > 9999999) {
      this.setState({
        error: true,
        errText: 'Максимальное количество символов 7!',
      });
    } else if (
      event.target.value > this.getAmountForAction(this.state.action)
    ) {
      this.setState({
        error: true,
        errText: 'Значение не может быть больше общей суммы к оплате!',
      });
    } else {
      this.setState({ paidAmount: event.target.value, error: false });
    }
  };

  setImageUrl = urls => {
    this.setState({ imgUrl: urls[0] });
  };

  getAmountForAction(action) {
    const labels = {
      [TO_PROCESSING]: this.props.totalToPayAmount,
      [TO_INVOICE]: this.props.processingAmount,
      [FINISH]: this.props.invoicedAmount,
    };

    return labels[action] || null;
  }

  isSubmitDisabled() {
    if (this.state.action === TO_PROCESSING) {
      return false;
    } else if (this.state.action === TO_INVOICE) {
      if (this.state.paidAmount <= 0) {
        return true;
      }

      return false;
    } else if (this.state.action === FINISH) {
      if (
        this.state.paidAmount <= 0 ||
        this.state.imgUrl === '' ||
        this.state.imgUrl == undefined
      ) {
        return true;
      }

      return false;
    }
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { paidAmount, error, errText, action, imgUrl } = this.state;
    const newFileInputProps = { accept: 'application/pdf' };

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">
          {actionHeading(action)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                display: 'block',
                textAlign: 'center',
                marginBottom: 12,
              }}
            >
              <Typography
                variant="subtitle1"
                style={{ marginRight: 12, display: 'inline-block' }}
              >
                {totalAmountLabel(action)}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  marginRight: 12,
                  display: 'inline-block',
                }}
              >
                {this.getAmountForAction(action)}
              </Typography>
            </div>
            {action === TO_INVOICE && (
              <div
                style={{
                  display: 'block',
                  textAlign: 'center',
                  marginBottom: 12,
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ marginRight: 12, display: 'inline-block' }}
                >
                  {payAmountLabel(action)}
                </Typography>
                <Input
                  id="component-simple"
                  value={paidAmount}
                  type="number"
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            )}
            {action === FINISH && (
              <>
                <div
                  style={{
                    display: 'block',
                    textAlign: 'center',
                    marginBottom: 12,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ marginRight: 12, display: 'inline-block' }}
                  >
                    {payAmountLabel(action)}
                  </Typography>
                  <Input
                    id="component-simple"
                    value={paidAmount}
                    type="number"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    style={{ marginRight: 12, display: 'inline-block' }}
                  >
                    Прикрепить документ
                  </Typography>
                  <FileFiled
                    ids={imgUrl ? [imgUrl] : []}
                    onChange={urls => this.setImageUrl(urls)}
                    maxItemsCount={1}
                    fileInputProps={newFileInputProps}
                  />
                </div>
              </>
            )}
            {error && <Typography color="error">{errText}</Typography>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size={'large'}>
            Отмена
          </Button>
          <Button
            disabled={this.isSubmitDisabled()}
            onClick={this.onSubmit.bind(this)}
            autoFocus
          >
            {buttonLabel(action)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationDialog;
