import {
  ARTICLES_BLOCK,
  BANNERS_BLOCK,
  BUTTON_BLOCK,
  EMPTY_BLOCK,
  SHOWCASES_BLOCK,
} from '@esentai/core/features/applicationSettings/homeLayout/consts/blocks';
import {
  always,
  any,
  append,
  compose,
  dec,
  equals,
  findIndex,
  length,
  remove,
  update,
} from 'ramda';

import moveItem from '@/utils/move-item';

import {
  createArticlesBlock,
  createBannersBlock,
  createBlock,
  createButtonBlock,
  createEmptyBlock,
  createShowCaseBlock,
  getBlockId,
  getBlockType,
  getBlockTypeLabel,
  getButtonBlockPlacement,
  isBannerBlock,
} from './block';

const getLayoutBlockIndex = (layout, targetBlock) => {
  const targetBlockId = getBlockId(targetBlock);
  const isTargetBlock = compose(equals(targetBlockId), getBlockId);

  return findIndex(isTargetBlock, layout);
};

const getLayoutSize = length;

const getLayoutLastBlockIndex = compose(dec, getLayoutSize);

const hasLayoutButtonWithPlacement = (layout, placement) => {
  const hasTargetPlacement = compose(
    equals(placement),
    getButtonBlockPlacement,
  );

  return any(hasTargetPlacement, layout);
};

const isLayoutFirstBlock = (layout, block) =>
  getLayoutBlockIndex(layout, block) === 1;

const isLayoutLastBlock = (layout, block) =>
  getLayoutBlockIndex(layout, block) === getLayoutLastBlockIndex(layout);

export const createLayout = (...blocks) => blocks.map(createBlock);

export const createLayoutBlockWithType = (layout, type) => {
  if (type === ARTICLES_BLOCK) {
    return createArticlesBlock(10);
  } else if (type === BUTTON_BLOCK) {
    const hasTopPlacement = hasLayoutButtonWithPlacement(layout, 'top');
    const placement = hasTopPlacement ? 'bottom' : 'top';

    return createButtonBlock(placement);
  } else if (type === BANNERS_BLOCK) {
    return createBannersBlock();
  } else if (type === EMPTY_BLOCK) {
    return createEmptyBlock();
  } else if (type === SHOWCASES_BLOCK) {
    return createShowCaseBlock();
  }
};

export const isLayoutBlockEditable = (layout, block) => !isBannerBlock(block);

export const canMoveLayoutBlockUp = (layout, block) =>
  !isLayoutFirstBlock(layout, block);

export const canMoveLayoutBlockDown = (layout, block) =>
  !isLayoutLastBlock(layout, block);

export const moveLayoutBlockDown = (layout, block) =>
  moveItem(layout, block, 'down');

export const moveLayoutBlockUp = (layout, block) =>
  moveItem(layout, block, 'up');

export const removeLayoutBlock = (layout, block) => {
  const blockIndex = getLayoutBlockIndex(layout, block);

  return remove(blockIndex, 1, layout);
};

export const replaceLayoutBlock = (layout, block, newBlock) => {
  const blockIndex = getLayoutBlockIndex(layout, block);

  return update(blockIndex, newBlock, layout);
};

export const changeLayoutBlockType = (layout, block, newType) => {
  const blockType = getBlockType(block);

  if (blockType === newType) {
    return layout;
  }

  const newBlock = createLayoutBlockWithType(layout, newType);

  return replaceLayoutBlock(layout, block, newBlock);
};

const getTypeLabel = type => {
  const block = createLayoutBlockWithType(createLayout(), type);

  return getBlockTypeLabel(block);
};

export const getLayoutAvailableBlockOptions = always([
  {
    id: EMPTY_BLOCK,
    label: 'Выберите тип блока',
  },
  {
    id: ARTICLES_BLOCK,
    label: getTypeLabel(ARTICLES_BLOCK),
  },
  {
    id: BUTTON_BLOCK,
    label: getTypeLabel(BUTTON_BLOCK),
  },
  {
    id: BANNERS_BLOCK,
    label: getTypeLabel(BANNERS_BLOCK),
  },
  {
    id: SHOWCASES_BLOCK,
    label: getTypeLabel(SHOWCASES_BLOCK),
  },
]);

export const addLayoutEmptyBlock = layout => {
  const newBlock = createEmptyBlock();

  return append(newBlock, layout);
};
