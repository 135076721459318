import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  isMallCommissar,
  isStoreSeller,
} from '@/features/currentUser/selectors';

import Description from './component';
import styles from './styles';

const mapStateToProps = state => ({
  isStoreSeller: isStoreSeller(state),
  isMallCommissar: isMallCommissar(state),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(Description);
