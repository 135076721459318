import PropTypes from 'prop-types';
import React from 'react';

import ConversionFactoid from '@/features/campaignsStatisticsDetailsPage/Page/ConversionFactoid';
import Factoid from '@/features/campaignsStatisticsDetailsPage/Page/Factoid';
import { formatNumber } from '@/utils/format';

const AllFacts = ({
  classes,
  segmentSize,
  viewsCount,
  coverage,
  clicksCount,
  viewsToSegmentPercentage,
  clicksToCoveragePercentage,
  conversion,
}) => (
  <div className={classes.wrapper}>
    <Factoid
      name="Сегмент"
      value={formatNumber(segmentSize)}
      ratio={viewsToSegmentPercentage}
      description="Общее количество пользователей, для которых предназначается кампания."
    />
    <Factoid
      name="Показы"
      value={formatNumber(viewsCount)}
      description="Количество демонстраций рекламного баннера"
    />

    <Factoid
      name="Охват"
      value={formatNumber(coverage)}
      ratio={clicksToCoveragePercentage}
      description="Количество уникальных пользователей, увидевших баннер"
    />

    <Factoid
      name="Переходы"
      value={formatNumber(clicksCount)}
      description="Количество уникальных пользователей, нажавших на баннер"
    />
    <ConversionFactoid
      classes={classes}
      conversion={conversion}
      description="Соотношение переходов к охвату"
    />
  </div>
);

AllFacts.propTypes = {
  segmentSize: PropTypes.number.isRequired,
  viewsCount: PropTypes.number.isRequired,
  coverage: PropTypes.number.isRequired,
  clicksCount: PropTypes.number.isRequired,
  conversion: PropTypes.number.isRequired,
  viewsToSegmentPercentage: PropTypes.number.isRequired,
  clicksToCoveragePercentage: PropTypes.number.isRequired,
};

export default AllFacts;
