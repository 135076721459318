import {
  DELAY_AFTER_TRIGGER_KEY,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_STORE_ID_KEY,
  REWARD_LOYALTY_POINTS_KEY,
} from '@esentai/core/features/campaigns/actionResult/consts/keys';
import React, { Component } from 'react';

import SecondStepForm from '@/features/campaignCreatePage/EditPage/SecondStepForm';
import FormikPropTypes from '@/prop-types/formik';

import DelayForm from './DelayForm';
import DestinationForm from './DestinationForm';
import LocationsForm from './LocationsForm';
import PushForm from './PushForm';
import RepetitionForm from './RepetitionForm';
import RewardForm from './RewardForm';
import TriggerForm from './TriggerForm';

const fieldNames = {
  [DELAY_AFTER_TRIGGER_KEY]: 'размер таймаута',
  [PUSH_ENGLISH_TITLE_KEY]: 'заголовок пуш-уведомления на английском',
  [PUSH_RUSSIAN_TITLE_KEY]: 'заголовок пуш-уведомления на русском',
  [PUSH_ENGLISH_MESSAGE_KEY]: 'описание пуш-уведомления на английском',
  [PUSH_RUSSIAN_MESSAGE_KEY]: 'описание пуш-уведомления на русском',
  [REFERRED_ARTICLE_ID_KEY]: 'ссылка на статью',
  [REFERRED_CALENDAR_EVENT_ID_KEY]: 'ссылка на календарное событие',
  [REFERRED_STORE_ID_KEY]: 'ссылка на магазин',
  [REWARD_LOYALTY_POINTS_KEY]: 'бонусы',
};

class SecondStepPurchaseResult extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { handleSubmit, SubmitButton, RequiredMessages } = this.props;

    return (
      <SecondStepForm
        fieldNames={fieldNames}
        handleSubmit={handleSubmit}
        SubmitButton={SubmitButton}
        RequiredMessages={RequiredMessages}
      >
        <TriggerForm {...this.props} />
        <LocationsForm {...this.props} />
        <DelayForm {...this.props} />
        <RepetitionForm {...this.props} />
        <PushForm {...this.props} />
        <DestinationForm {...this.props} />
        <RewardForm {...this.props} />
      </SecondStepForm>
    );
  }
}

export default SecondStepPurchaseResult;
