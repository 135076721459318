import {
  FLOOR_KEY,
  ISSUED_AT_DATE_KEY,
  ISSUED_AT_TIME_KEY,
  MERCHANT_NAME_KEY,
  STORE_CATEGORIES_KEY,
  STORE_NAME_KEY,
} from '@esentai/core/features/salesTurnovers/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createFloorCheckboxFilter } from '@/filters/FloorCheckbox';
import { createTextFilter } from '@/filters/SearchInput';
import { createStoreCategoryFilter } from '@/filters/StoreCategory';
import { createTimeRangeFilter } from '@/filters/TimeRange';
import { createContainsAdapter } from '@/structs/pageFilterAdapter';

export const filterTypes = [
  createTextFilter({
    key: MERCHANT_NAME_KEY,
    label: 'Арендатор',
  }),
  createTextFilter({
    key: STORE_NAME_KEY,
    label: 'Название магазина',
  }),
  createDateRangeFilter({
    key: ISSUED_AT_DATE_KEY,
    label: 'Дата покупки',
  }),
  createTimeRangeFilter({
    key: ISSUED_AT_TIME_KEY,
    label: 'Время покупки',
  }),
  createFloorCheckboxFilter({
    key: FLOOR_KEY,
    label: 'Этаж',
  }),
  createStoreCategoryFilter({
    key: STORE_CATEGORIES_KEY,
    label: 'Категория',
    adapter: createContainsAdapter([]),
  }),
];
