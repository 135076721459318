import { connect } from 'react-redux';

import StatisticsTopBar from '@/components/StatisticsTopBar';
import {
  MAX_STATISTICS_DATE,
  MIN_STATISTICS_DATE,
} from '@/features/locationsStatistics/consts/dates';
import { updateFilters } from '@/features/locationsStatisticsPage/actions';
import { getFilters } from '@/features/locationsStatisticsPage/selectors';
import stats from '@/features/locationsStatisticsPage/stats';

import InnerTopBar from './InnerTopBar';

const mapStateToProps = state => ({
  filters: getFilters(state),
  InnerTopBarComponent: InnerTopBar,
  minDate: MIN_STATISTICS_DATE,
  maxDate: MAX_STATISTICS_DATE,
  stats,
});

const mapDispatchToProps = { updateFilters };

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTopBar);
