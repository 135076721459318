import React from 'react';

import Button from '@/components/Button';
import { EnhancedBaseError } from '@/components/ErrorMessages';

import { propTypes as strategyPropTypes } from './strategies';

const InviteError = ({ strategy, classes }) => {
  const { message, headline } = strategy;

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <div className={classes.infoWrapper}>
          <EnhancedBaseError headline={headline} message={message} />
        </div>
        <Button variant="contained" color="primary" size="large" href="/">
          Вернуться на главную
        </Button>
      </div>
    </div>
  );
};

InviteError.propTypes = {
  strategy: strategyPropTypes.isRequired,
};

export default InviteError;
