import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { withRouter } from 'react-router';
import { array, object, string } from 'yup';

import styles from '@/features/authPages/styles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import CategoryForm from './component';

const errorsMapping = {};
const formikOptions = {
  mapPropsToValues: ({ initialValues }) =>
    initialValues
      ? {
          name_ru: initialValues ? initialValues.russianName : null,
          name_en: initialValues ? initialValues.englishName : null,
          name_kz: initialValues ? initialValues.kazakhName : null,
          images: initialValues ? initialValues.images : [],
        }
      : null,

  validationSchema: object().shape({
    name_ru: string()
      .min(1, 'Название RU должно содержать менее 1 символа')
      .max(50, 'Название RU должно содержать не более 50 символов')
      .required(),
    name_en: string()
      .min(1, 'Название EN должно содержать менее 1 символа')
      .max(50, 'Название EN должно содержать не более 50 символов')
      .required(),
    name_kz: string()
      .min(1, 'Название KZ должно содержать менее 1 символа')
      .max(50, 'Название KZ должно содержать не более 50 символов')
      .required(),
    images: array()
      .min(1)
      .required(),
  }),
  // eslint-disable-next-line camelcase
  handleSubmit(
    { name_ru, name_en, name_kz, images },
    { props, setSubmitting },
  ) {
    props.onSubmit(
      props.initialValues
        ? {
            name_ru,
            name_en,
            name_kz,
            images,
            category: props.match.params.categoryId,
            id: props.initialValues.itemId,
          }
        : {
            name_ru,
            name_en,
            name_kz,
            images,
            category: props.match.params.categoryId,
            visible: false,
          },
    );
    props.onClose();
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };

export default compose(
  withRouter,
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(CategoryForm);
