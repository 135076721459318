import { grey } from '@material-ui/core/colors';

export default {
  background: {
    backgroundColor: grey[200],
    marginTop: 60,
    paddingBottom: 56,
    flex: '1',
  },

  wrapper: {
    borderTop: [1, 'solid', '#000000'],
    paddingTop: 56,
  },

  label: {
    marginBottom: 8,
  },

  subLabel: {
    marginBottom: 32,
    maxWidth: 994,
  },

  value: {
    fontWeight: 500,
  },
};
