import { randomIntegerInRange } from '@/utils/rand';

const uniqueId = randomIntegerInRange(0, Number.MAX_SAFE_INTEGER);
const notableAnimationName = `notable-${uniqueId}`;

export default {
  [`@keyframes ${notableAnimationName}`]: {
    from: {
      color: '#FF0000',
    },
    to: {
      color: '#FFFFFF',
    },
  },

  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
  },

  timer: {
    fontSize: '20px',
  },

  progress: {
    position: 'absolute',
    animationName: notableAnimationName,
    animationDuration: '.4s',
    animationDirection: 'alternate',
  },
};
