import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';

const VisibilityButton = ({ visible, ...rest }) => (
  <ActionButton
    text={visible ? 'Скрыть от пользователей' : 'Показать пользователям'}
    button={AsyncButton}
    icon={!visible ? VisibilityOff : Visibility}
    {...rest}
  />
);

VisibilityButton.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default VisibilityButton;
