import { Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FormikPropTypes from '@/prop-types/formik';

import Preview from './Preview';

class ContentForm extends Component {
  static propTypes = FormikPropTypes;

  renderPreview = () => {
    const { russianTitle, englishTitle, kazakhTitle, values } = this.props;

    return (
      <Preview
        values={values}
        englishTitle={englishTitle}
        russianTitle={russianTitle}
        kazakhTitle={kazakhTitle}
      />
    );
  };

  render() {
    const {
      russianTitle,
      englishTitle,
      kazakhTitle,
      russianDescription,
      englishDescription,
      kazakhDescription,
    } = this.props;

    return (
      <>
        <FieldRow label="Наполнение витрины" Aside={this.renderPreview}>
          <Grid container spacing={40} style={{ marginBottom: '24px' }}>
            <Grid item xs={12}>
              <FieldDescription
                title="Заголовок"
                desc="Заголовок будет отображаться над онлайн-витриной. Не более 50 символов."
              />
              <Typography variant="subtitle1">{russianTitle}</Typography>
              <Typography variant="caption">RU</Typography>

              <Typography variant="subtitle1">{englishTitle}</Typography>
              <Typography variant="caption">ENG</Typography>

              <Typography variant="subtitle1">{kazakhTitle}</Typography>
              <Typography variant="caption">KZ</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={40}>
            <Grid item xs={12}>
              <FieldDescription title="Описание" />
              <Typography variant="subtitle1">{russianDescription}</Typography>
              <Typography variant="caption">RU</Typography>

              <Typography variant="subtitle1">{englishDescription}</Typography>
              <Typography variant="caption">ENG</Typography>

              <Typography variant="subtitle1">{kazakhDescription}</Typography>
              <Typography variant="caption">KZ</Typography>
            </Grid>
          </Grid>
        </FieldRow>
      </>
    );
  }
}

export default ContentForm;
