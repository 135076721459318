import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { create } from '@esentai/core/features/questions/sagas';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ROUTE_PATH as QUESTIONS_PATH } from '@/features/questionsPage/consts';

import { CREATE_QUESTION } from './types';

export function* createQuestion(action) {
  const { payload: attributes } = action;

  yield call(create, attributes);
  yield put(push(QUESTIONS_PATH));
}

export default function*() {
  yield takeLatest(CREATE_QUESTION, createTaskSaga(createQuestion));
}
