import { findMerchantById } from '@esentai/core/features/merchants/actions';
import {
  getBinForMerchant,
  isMerchantLoaded,
  isMerchantLoading,
} from '@esentai/core/features/merchants/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import MerchantBinLazy from './component';

const mapStateToProps = (state, { merchantId }) => ({
  isLoaded: isMerchantLoaded(state, merchantId),
  isLoading: isMerchantLoading(state, merchantId),
  itemId: merchantId,
  title: getBinForMerchant(state, merchantId),
});

const mapDispatchToProps = { fetchItem: findMerchantById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(MerchantBinLazy);
