import PropTypes from 'prop-types';
import React from 'react';
import { defaultProps } from 'recompose';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';
import { ROUTE_PATH as WHITELIST_GROUPS_PATH } from '@/features/parkingWhitelistGroupsPage/consts';

import FormCreate from './Form';

const Form = defaultProps({
  name: '',
  // users: [],
})(FormCreate);

const NewWhitelistGroup = ({ createParkingWhitelistGroup }) => (
  <SettingsPage style={{ margin: 30 }}>
    <SettingsPageHeader>
      <Breadcrumbs>
        <Breadcrumb to={WHITELIST_GROUPS_PATH}>
          Whitelist Тимирязева группы
        </Breadcrumb>
      </Breadcrumbs>
      <SettingsPageTitle>Создать группу</SettingsPageTitle>
    </SettingsPageHeader>
    <SettingsPageContent>
      <Form onSubmit={createParkingWhitelistGroup} />
    </SettingsPageContent>
  </SettingsPage>
);

NewWhitelistGroup.propTypes = {
  createParkingWhitelistGroup: PropTypes.func.isRequired,
};

export default NewWhitelistGroup;
