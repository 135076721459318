import { withStyles } from '@material-ui/core';
import React from 'react';

import ChartBar from '@/components/DistributionChart/ChartBar';

export const getBarComponent = ({ styles }) => {
  const CustomBar = props => <ChartBar {...props} />;

  return withStyles(styles)(CustomBar);
};
