import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

class EditIOSVersionForm extends Component {
  render() {
    const {
      Form,
      Field,
      SubmitButton,
      RequiredMessages,
      onClose,
      setFieldValue,
      values,
    } = this.props;

    return (
      <Form>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Field
              Target={TextField}
              id="version_ios"
              name="version_ios"
              label="Версия iOS"
              margin="normal"
              fullWidth
            />
          </Grid>
          <FormControlLabel
            label="Обязательное обновление"
            name="version_android_forced_update"
            control={
              <Checkbox
                checked={values.version_ios_forced_update}
                onChange={e =>
                  setFieldValue('version_ios_forced_update', e.target.checked)
                }
              />
            }
          />
          <Grid item>
            <ButtonSet>
              <Button onClick={onClose} size="large">
                Отменить
              </Button>
              <SubmitButton
                Target={AsyncButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                Изменить
              </SubmitButton>
            </ButtonSet>
          </Grid>
          <Grid item>
            <RequiredMessages
              fieldNames={{
                version_ios: 'Версия iOS',
              }}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

EditIOSVersionForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default EditIOSVersionForm;
