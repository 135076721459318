import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import {
  getPageFilterField,
  getPageFilterQuicklyAccessibleOptions,
  isQuicklyAccessiblePageFilter,
} from '@/structs/pageFilter';

import QuickFiltersRow from './QuickFiltersRow';
import SelectRow from './SelectRow';

class FiltersRow extends Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    filterTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
    removeFilters: PropTypes.func.isRequired,
    selectRowClassName: PropTypes.string,
    updateFilters: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectRowClassName: null,
  };

  state = {
    open: this.getAllKnowFilters().size > 0,
  };

  render() {
    const {
      filterTypes,
      removeFilters,
      selectRowClassName,
      updateFilters,
    } = this.props;
    const quickFilterOptions = this.getQuickFilterOptions();
    const quickFilterKey = this.getQuickFilterKey();

    const filters = this.getAllKnowFilters();
    const { open } = this.state;
    const clear = !filters.has(quickFilterKey);
    const value = filters.get(quickFilterKey);

    return (
      <Fragment>
        <QuickFiltersRow
          clear={clear}
          onChange={this.onQuickFilterChange}
          onClear={this.onQuickFilterClear}
          onClose={this.close}
          onOpen={this.open}
          open={open}
          options={quickFilterOptions}
          value={value}
        />
        {open ? (
          <SelectRow
            filterTypes={filterTypes}
            filters={filters}
            updateFilters={updateFilters}
            removeFilters={removeFilters}
            classes={{ root: selectRowClassName }}
          />
        ) : null}
      </Fragment>
    );
  }

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  getQuickFilterKey = () => {
    const quickFilterDefinition = this.getQuickFilterDefinition();

    if (quickFilterDefinition) {
      return getPageFilterField(quickFilterDefinition);
    }

    return null;
  };

  getQuickFilterOptions = () => {
    const quickFilterDefinition = this.getQuickFilterDefinition();

    if (quickFilterDefinition) {
      return getPageFilterQuicklyAccessibleOptions(quickFilterDefinition);
    }

    return [];
  };

  getQuickFilterDefinition = () => {
    const { filterTypes } = this.props;

    return filterTypes.find(isQuicklyAccessiblePageFilter);
  };

  onQuickFilterChange = update => {
    const { updateFilters } = this.props;

    updateFilters(update);
    this.open();
  };

  onQuickFilterClear = () => {
    const { removeFilters } = this.props;
    const quickFilterKey = this.getQuickFilterKey();

    removeFilters([quickFilterKey]);
  };

  getAllKnowFilters() {
    const { filters, filterTypes } = this.props;
    const result = new Map();

    for (const [key, value] of filters) {
      if (filterTypes.some(item => getPageFilterField(item) === key)) {
        result.set(key, value);
      }
    }

    return result;
  }
}

export default FiltersRow;
