import MultiSelectFilter, {
  createMultiSelectFilterTemplate,
} from '@/filters/MultiSelect';
import { createSingleSelectFilterTemplate } from '@/filters/SingleSelect';

import { createOptions } from './utils';

export const options = createOptions();

export const createMultipleDaySelectFilter = createMultiSelectFilterTemplate({
  options,
});

export const createSingleDaySelectFilter = createSingleSelectFilterTemplate({
  options,
  inputProps: { placeholder: 'Календарный день рождения...' },
});

export default MultiSelectFilter;
