import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { RESOURCE_NAME, RESOURCE_RATE_NAME } from './consts';

export const canReadParkings = authorizeIf(canRead(RESOURCE_NAME));
export const canReadParking = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateParking = authorizeIf(canCreate(RESOURCE_NAME));
export const canUpdateParking = authorizeIf(canUpdate(RESOURCE_NAME));

export const canUpdateParkingRate = authorizeIf(canUpdate(RESOURCE_RATE_NAME));
export const canReadParkingRate = authorizeIf(canUpdate(RESOURCE_RATE_NAME));
