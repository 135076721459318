import PropTypes from 'prop-types';
import React from 'react';

import OriginalPushNotification from '@/components/PushNotificationDevicePreview/PushNotification';
import { LOCALE_EN, LOCALE_RU } from '@/hocs/withDevicePreview/consts';

const PushNotification = ({
  selectByLocale,
  pushEnglishTitle,
  pushRussianTitle,
  pushEnglishMessage,
  pushRussianMessage,
}) => {
  const title = selectByLocale({
    [LOCALE_EN]: pushEnglishTitle,
    [LOCALE_RU]: pushRussianTitle,
  });
  const message = selectByLocale({
    [LOCALE_EN]: pushEnglishMessage,
    [LOCALE_RU]: pushRussianMessage,
  });

  return <OriginalPushNotification title={title} message={message} />;
};

PushNotification.propTypes = {
  selectByLocale: PropTypes.func.isRequired,
  pushEnglishTitle: PropTypes.string.isRequired,
  pushRussianTitle: PropTypes.string.isRequired,
  pushEnglishMessage: PropTypes.string.isRequired,
  pushRussianMessage: PropTypes.string.isRequired,
};

export default PushNotification;
