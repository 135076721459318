import { RESOURCE_NAME } from '@esentai/core/features/efsSales/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { SALES_EFS } from './consts/permissions';

export const canEfsSales = authorizeIf(
  can(permission(RESOURCE_NAME, SALES_EFS)),
);
