import {
  getBeaconFloor,
  getBeaconMajorId,
  getBeaconMinorId,
  getNameForBeacon,
} from '@esentai/core/features/beacons/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';

const mapStateToProps = (state, { item: itemId }) => ({
  majorId: getBeaconMajorId(state, itemId),
  minorId: getBeaconMinorId(state, itemId),
  name: getNameForBeacon(state, itemId),
  floor: getBeaconFloor(state, itemId),
  itemId,
});

export default compose(connect(mapStateToProps))(Row);
