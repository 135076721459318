import PropTypes from 'prop-types';
import { isNil, mergeRight, pipe, reject } from 'ramda';
import React, { Component } from 'react';

import DatePicker from '@/components/DatePicker';

const dateFormat = 'PPP';

class DateRangeFilter extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    fromInputProps: PropTypes.object,
    toInputProps: PropTypes.object,
  };

  static defaultProps = {
    fromInputProps: {},
    toInputProps: {},
  };

  handleChange = (key, date) => {
    const { onChange, value } = this.props;

    const mergeValue = pipe(mergeRight(value), reject(isNil));

    onChange(mergeValue({ [key]: date }));
  };

  handleChangeFrom = date => {
    const fromDate = date.target.value;

    fromDate.setHours(0, 0, 0, 0);
    this.handleChange('from', fromDate);
  };

  handleChangeTo = date => {
    const toDate = date.target.value;

    toDate.setHours(23, 59, 59, 999);
    this.handleChange('to', toDate);
  };

  render() {
    const { classes, value, fromInputProps, toInputProps } = this.props;
    const from = value.from || null;
    const to = value.to || null;

    return (
      <div>
        <DatePicker
          clearable
          label="от"
          className={classes.input}
          format={dateFormat}
          value={from}
          onChange={this.handleChangeFrom}
          {...fromInputProps}
        />
        <DatePicker
          clearable
          label="до"
          className={classes.input}
          format={dateFormat}
          value={to}
          onChange={this.handleChangeTo}
          {...toInputProps}
        />
      </div>
    );
  }
}

export default DateRangeFilter;
