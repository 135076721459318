import { getStoreBrandsIndices } from '@esentai/core/features/stores/selectors';
import { connect } from 'react-redux';

import Brands from './component';

const mapStateToProps = (state, { storeId }) => ({
  storeBrandsIndices: getStoreBrandsIndices(state, storeId),
});

export default connect(mapStateToProps)(Brands);
