import { createCalendarDate } from '@esentai/core/features/campaigns/utils';
import { addDays, getDate, getDaysInYear, getMonth } from 'date-fns';
import { times } from 'ramda';

import { formatShortHumanDate } from '@/utils/format';

export const createOptions = () => {
  const leapYear = 2012;
  const pivot = new Date(`${leapYear}-01-01`);
  const daysInLeapYear = getDaysInYear(pivot);

  return times(offset => {
    const calendarDate = addDays(pivot, offset);
    const calendarDay = getDate(calendarDate);
    const calendarMonth = getMonth(calendarDate) + 1;

    return {
      key: createCalendarDate(calendarDay, calendarMonth),
      label: formatShortHumanDate(calendarDate),
      value: createCalendarDate(calendarDay, calendarMonth),
    };
  }, daysInLeapYear);
};
