import React, { Component, Fragment } from 'react';

import FieldRow from '@/components/FieldRow';
import Quadrolingual from '@/components/Quadrolingual';
import Preview from '@/features/campaignCreatePage/EditPage/SecondStepArticle/ContentForm/Preview';
import Image from '@/features/campaignPage/Page/Image';
import Label from '@/features/campaignPage/Page/Label';
import FormikPropTypes from '@/prop-types/formik';

import Destination from '../Destination';

class Banner extends Component {
  static propTypes = FormikPropTypes;

  renderPreview = () => <Preview {...this.props} />;

  render() {
    const { englishTitle, russianTitle, imageUrl, kazakhTitle } = this.props;

    return (
      <Fragment>
        <FieldRow label="Наполнение" Aside={this.renderPreview}>
          <Label title="Изображение баннера">
            <Image src={imageUrl} />
          </Label>

          <Label title="Заголовок баннера">
            <Quadrolingual
              englishContent={englishTitle}
              russianContent={russianTitle}
              kazakhContent={kazakhTitle}
            />
          </Label>
        </FieldRow>
        <Destination {...this.props} />
      </Fragment>
    );
  }
}

export default Banner;
