import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { withProps } from 'recompose';

import withStrategy from '@/hocs/withStrategy';

import InviteError from './component';
import strategies from './strategies';
import styles from './styles';

export default compose(
  withStrategy({ strategies }),
  withProps(({ findStrategyBy, match }) => {
    const { errorCode } = match.params;

    return {
      strategy: findStrategyBy('name', errorCode),
    };
  }),
  withStyles(styles),
)(InviteError);
