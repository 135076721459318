import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Field from '../PersonalInfo/Field';

const SecondaryFacts = ({ classes, address, street, house, apartment }) => (
  <div className={classes.wrapper}>
    <Typography
      gutterBottom
      color="textSecondary"
      variant="h5"
      className={classes.title}
    >
      Адрес доставки
    </Typography>

    <div className={classes.facts}>
      <Field label="Улица/Район" className={classes.fact}>
        {street}
      </Field>

      <Field label="№ дома" className={classes.fact}>
        {house}
      </Field>

      <Field label="№ квартиры" className={classes.fact}>
        {apartment}
      </Field>

      <Field label="Комментарий" className={classes.fact}>
        {address}
      </Field>
    </div>
  </div>
);

SecondaryFacts.propTypes = {
  street: PropTypes.string.isRequired,
  house: PropTypes.string.isRequired,
  apartment: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default SecondaryFacts;
