import PropTypes from 'prop-types';
import React from 'react';

import LockScreenTopBar from '@/components/DevicePreview/LockScreenTopBar';

const PushNotificationDevicePreview = ({
  classes,
  PushNotification,
  ...rest
}) => (
  <div className={classes.root}>
    <div className={classes.topBar}>
      <LockScreenTopBar />
    </div>
    <PushNotification {...rest} />
  </div>
);

PushNotificationDevicePreview.propTypes = {
  PushNotification: PropTypes.func.isRequired,
};

export default PushNotificationDevicePreview;
