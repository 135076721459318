import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FiltersRow from '@/components/FiltersRow';
import { RECORD_CREATED_KEY } from '@/features/timiryazevParkingPage/consts/keys';
import {
  filterPrefixes,
  filterTypes,
  multipleFilterList,
} from '@/features/timiryazevParkingPage/filters';

const TimiryazevParkingFiltersRow = props => {
  const [filters, setFilters] = useState(new Map());
  const currentUrlParams = new URLSearchParams(window.location.search);

  const changeUrlParams = params => {
    props.history.push({
      pathname: `/timiryazev-parking`,
      search: `?${decodeURIComponent(params.toString())}`,
    });
  };

  const getFilterDuplicate = () => {
    const map = new Map();

    filters.forEach((value, key) => {
      map.set(key, value);
    });

    return map;
  };

  const updateFilters = payload => {
    const initMap = getFilterDuplicate();

    Object.keys(payload).forEach(fieldName => {
      const mapValue = payload[fieldName];
      const { prefix } = filterPrefixes.find(
        filter => filter.field === fieldName,
      );
      const value = mapValue.get(prefix);

      if (mapValue && (mapValue.has('gte') || mapValue.has('lte'))) {
        mapValue.forEach((val, key) => {
          const isDate = val instanceof Date;

          if (isDate) {
            currentUrlParams.set(`${fieldName}[${key}]`, val.toISOString());
          } else {
            currentUrlParams.set(`${fieldName}[${key}]`, val);
          }
        });
      } else {
        currentUrlParams.set(fieldName, value);
      }
      initMap.set(fieldName, mapValue);
    });

    changeUrlParams(currentUrlParams);
    setFilters(initMap);
  };

  const removeFilters = payload => {
    const params = new URLSearchParams(window.location.search);
    const initMap = getFilterDuplicate();

    payload.map(filter => {
      initMap.delete(filter);

      if (multipleFilterList.includes(filter)) {
        params.delete(`${filter}[gte]`);
        params.delete(`${filter}[lte]`);
      } else {
        params.delete(filter);
      }

      return filter;
    });

    changeUrlParams(params);
    setFilters(initMap);
  };

  useEffect(() => {
    const initMap = new Map();
    let valueMap = new Map();
    let canClean = false;

    for (const param of currentUrlParams) {
      const name = param[0];
      const value = param[1];

      if (canClean) {
        valueMap = new Map();
        canClean = false;
      }

      if (name !== 'sort') {
        const filteredName = name.replace('[gte]', '').replace('[lte]', '');

        if (multipleFilterList.includes(filteredName)) {
          const isDate = filteredName === RECORD_CREATED_KEY;

          const fieldValue = isDate ? new Date(value) : value;

          if (name.indexOf('gte') !== -1) {
            valueMap.set('gte', fieldValue);
          } else {
            valueMap.set('lte', fieldValue);
            canClean = true;
          }
        } else {
          const { prefix } = filterPrefixes.find(
            filter => filter.field === filteredName,
          );

          valueMap.set(prefix, value);
        }
        initMap.set(filteredName, valueMap);
      }
    }
    setFilters(initMap);
  }, []);

  return (
    <FiltersRow
      filters={filters}
      updateFilters={updateFilters}
      removeFilters={removeFilters}
      filterTypes={filterTypes}
    />
  );
};

TimiryazevParkingFiltersRow.propTypes = {
  history: PropTypes.object.isRequired,
};

export default TimiryazevParkingFiltersRow;
