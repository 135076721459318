import { descending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { annotations } from '@/features/anonymousPurchasesStatistics/serializer';
import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';
import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(TIMEFRAME_KEY),
  }),
});
