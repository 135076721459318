import PropTypes from 'prop-types';
import React from 'react';

import ConversionFactoid from '@/features/campaignsStatisticsDetailsPage/Page/ConversionFactoid';
import Factoid from '@/features/campaignsStatisticsDetailsPage/Page/Factoid';
import { formatNumber } from '@/utils/format';

const NotificationFacts = ({
  classes,
  segmentSize,
  prePushClicksCount,
  conversion,
  pushClicksToSegmentPercentage,
}) => (
  <div className={classes.wrapper}>
    <Factoid
      name="Сегмент"
      value={formatNumber(segmentSize)}
      ratio={pushClicksToSegmentPercentage}
      description="Общее количество пользователей, для которых предназначается кампания."
    />
    <Factoid
      name="Переходы"
      classes={classes}
      value={formatNumber(prePushClicksCount)}
      description="Количество пользователей, нажавших на предварительное push-уведомление"
    />
    <ConversionFactoid classes={classes} conversion={conversion} />
  </div>
);

NotificationFacts.propTypes = {
  segmentSize: PropTypes.number.isRequired,
  prePushClicksCount: PropTypes.number.isRequired,
  pushClicksToSegmentPercentage: PropTypes.number.isRequired,
  conversion: PropTypes.number.isRequired,
};

export default NotificationFacts;
