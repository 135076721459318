import {
  getBonusTransactionAmount,
  getBonusTransactionCommissarId,
  getBonusTransactionCustomerId,
  getBonusTransactionRecordCreated,
  getBonusTransactionType,
  getBonusTransactionUserId,
} from '@esentai/core/features/bonus_transactions/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: id }) => ({
  amount: getBonusTransactionAmount(state, id),
  commissarId: getBonusTransactionCommissarId(state, id),
  userId: getBonusTransactionUserId(state, id),
  customerId: getBonusTransactionCustomerId(state, id),
  type: getBonusTransactionType(state, id),
  recordCreated: getBonusTransactionRecordCreated(state, id),
  id,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
