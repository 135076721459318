import { STATUS_VALUES } from '@esentai/core/features/kkms/consts/keys';

import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = ['active', 'inactive'].map(key => ({
  key,
  label: STATUS_VALUES[key],
}));

export const createKkmStateFilter = createRadioFilterTemplate({
  initialValue: options[0].key,
  options,
});

export default RadioGroupFilter;
