import benefitAPI from '@esentai/core/features/benefits/api';
import { TITLE_KEY } from '@esentai/core/features/benefits/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import pluralize from '@/utils/pluralize';

import InlineSearch from '../InlineSearch';
import SelectedItem from './SelectedItem';
import Suggestion from './Suggestion';

const buildQuery = queryString => query(where(TITLE_KEY, ilike(queryString)));

const countCaptionFormatter = count =>
  pluralize(count, {
    one: `Найден ${count} подходящий бенефит`,
    few: `Найдено ${count} подходящих бенефита`,
    many: `Найдено ${count} подходящих бенефитов`,
  });

export default defaultProps({
  api: benefitAPI,
  inputProps: {
    label: 'Выбор бенефита',
    helperText: 'Укажите конкретный бенефит.',
  },
  buildQuery,
  countCaptionFormatter,
  selectedItemLabel: 'Выбор бенефита',
  SelectedItem,
  Suggestion,
})(InlineSearch);
