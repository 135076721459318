import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose, T } from 'ramda';
import { connect } from 'react-redux';

import { deleteQuestion } from '@/features/questionsPage/actions';

import DeleteButton from './component';

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(deleteQuestion(itemId)),
  permission: T,
});

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(DeleteButton);
