import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Link from '@/components/Link';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { downloadXls } from '@/features/reconciliationHistoryPage/utils';
import { formatMoney, formatNumber } from '@/utils/format';

import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { createReceiptsPageUrl } from '../../reconciliationReceiptsPage/utils';
import { createRefundsPageUrl } from '../../reconciliationRefundsPage/utils';
import { POST_URL, REPAY_CONFIRMATION_MSG } from '../consts';
import { ActiveReconciliation } from './ActiveReconciliation/ActiveReconciliation';
import Table from './Table';

const historyHeaders = [
  'Арендатор',
  'Итоговая сумма к оплате',
  'Сумма потраченных бонусов',
  'Сумма бонусов на возврат',
];

class ReconciliationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRepayDialogOpen: false,
    };
  }

  static propTypes = {
    error: PropTypes.any,
    reconciliationIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    storeId: PropTypes.string.isRequired,
    storeName: PropTypes.string.isRequired,
    fetchItems: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  handleRepayModal = () => {
    this.setState({ isRepayDialogOpen: !this.state.isRepayDialogOpen });
  };

  preventDefault = event => {
    event.preventDefault();
    this.handleRepayModal();
  };

  render() {
    const {
      error,
      reconciliationIds,
      isLoading,
      totalCount,
      classes,
      storeId,
      storeName,
      fetchItems,
    } = this.props;

    const payload = {
      reconciliation: {
        store: storeId,
      },
    };

    return (
      <>
        <ActiveReconciliation
          model="merchant"
          storeId={storeId}
          storeName={storeName}
          headers={historyHeaders}
          totalCount={totalCount}
        >
          {rows =>
            rows.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.company}</TableCell>
                <TableCell>{formatMoney(row.total_to_pay)}</TableCell>
                <TableCell>
                  <Link to={createReceiptsPageUrl(storeId)}>
                    {formatNumber(row.bonuses_spent)}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={createRefundsPageUrl(storeId)}>
                    {formatNumber(row.bonuses_refunded)}
                  </Link>
                </TableCell>
              </TableRow>
            ))
          }
        </ActiveReconciliation>
        <Page>
          <PageHeader borderBottom gutterBottom={false}>
            <PageTitle gutterBottom={false}>Архив</PageTitle>
          </PageHeader>
          <PageContent>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                className={classes.excelButton}
                onClick={() => {
                  downloadXls(storeId, storeName);
                }}
              >
                Вывести в Excel
              </Button>
              <Table
                error={error}
                items={reconciliationIds}
                isLoading={isLoading}
                totalCount={totalCount}
              />
            </div>
          </PageContent>
        </Page>
        {this.state.isRepayDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isRepayDialogOpen}
            onClose={this.handleRepayModal}
            storeId={storeId}
            fetchItems={fetchItems}
            url={POST_URL}
            payload={payload}
            confirmationMessage={REPAY_CONFIRMATION_MSG}
          />
        )}
      </>
    );
  }
}

export default ReconciliationHistory;
