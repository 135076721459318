import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';

import Actions from './Actions';
import CampaignStatus from './CampaignStatus';
import Content from './Content';
import Moderation from './Moderation';
import Primary from './Primary';
import Segmentation from './Segmentation';

class Campaign extends Component {
  static propTypes = {
    canModerate: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    Form: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
  };

  render() {
    const { canModerate, handleSubmit, isLoaded, Form } = this.props;

    if (!isLoaded) {
      return null;
    }

    return (
      <Form onSubmit={handleSubmit}>
        <Page>
          <PageHeader gutterBottom={false}>
            <Breadcrumbs />
            <BackButton />
            <PageTitle>
              Просмотр кампании
              <CampaignStatus />
            </PageTitle>
          </PageHeader>
          <PageContent>
            <PaperSheet>
              <Primary {...this.props} />
              <Content {...this.props} />
              <Segmentation {...this.props} />
              <Actions />
              {canModerate && <Moderation {...this.props} />}
            </PaperSheet>
          </PageContent>
        </Page>
      </Form>
    );
  }
}

export default Campaign;
