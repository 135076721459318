import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { object, string } from 'yup';

import { getInviteByToken } from '@/features/invites/selectors';
import withFormikValidation from '@/hocs/withFormikValidation';

import Form from './component';
import { FULL_NAME_FIELD, PASSWORD_FIELD } from './consts';
import styles from './styles';

export const options = {
  mapPropsToValues: () => ({
    [FULL_NAME_FIELD]: '',
    [PASSWORD_FIELD]: '',
  }),

  validationSchema: object().shape({
    [FULL_NAME_FIELD]: string().required(),
    [PASSWORD_FIELD]: string().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const mapStateToProps = (state, { token }) => ({
  invite: getInviteByToken(state, token),
});

export default compose(
  connect(mapStateToProps),
  withAsyncRunner({
    runnerMethod: 'onSubmit',
  }),
  withFormikValidation(options),
  withStyles(styles),
)(Form);
