import {
  BONUS_PERCENT_KEY,
  FISCAL_ID_KEY,
  HMS_KEY,
  STATUS_KEY,
  STORE_NAME_KEY,
  TIMESTAMP_KEY,
  TOTAL_COST_KEY,
} from '@esentai/core/features/purchases/consts/keys';
import { descending } from '@esentai/core/query-dsl';
import {
  datetime,
  number,
  sort,
  string,
} from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

const time = function(options) {
  return {
    options,
    parse: function parse(newDate) {
      if (newDate) {
        const parts = newDate.split(':');
        const date = new Date();

        date.setHours(parts[0], parts[1], parts[2], 0);

        return date;
      }

      return new Date();
    },
    serialize: function serialize(date) {
      if (date) {
        return `${date.getHours()}:${date.getMinutes()}:00`;
      }

      return '';
    },
  };
};

export default createPageSerializer({
  [TIMESTAMP_KEY]: datetime(),
  [STATUS_KEY]: string(),
  [TOTAL_COST_KEY]: number(),
  [STORE_NAME_KEY]: string(),
  [FISCAL_ID_KEY]: string(),
  [BONUS_PERCENT_KEY]: number(),
  [HMS_KEY]: time(),
  sort: sort({
    defaultValue: descending('key'),
  }),
});
