import { connect } from 'react-redux';

import { createMerchantPageUrl } from '@/features/merchantPage/utils';

import HistoryButton from './component';

const mapStateToProps = (state, { merchantId }) => ({
  historyPageUrl: createMerchantPageUrl(merchantId),
});

export default connect(mapStateToProps)(HistoryButton);
