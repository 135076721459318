import { TextField, Typography } from '@material-ui/core';
import React from 'react';

import AsyncButton from '@/components/AsyncButton';
import Link from '@/components/Link';
import PasswordField from '@/components/PasswordField';
import { FORGOT_PATH } from '@/features/auth/consts';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

import { LOGIN_NAME, PASSWORD_NAME } from './consts';

const fieldNames = {
  [LOGIN_NAME]: 'логин',
  [PASSWORD_NAME]: 'пароль',
};

const emailInputProps = {
  tabIndex: 1,
};

const passwordInputProps = {
  tabIndex: 2,
};

const LoginForm = ({
  Form,
  Field,
  SubmitButton,
  values,
  classes,
  RequiredMessages,
}) => {
  const forgotPath = `${FORGOT_PATH}?email=${encodeURIComponent(
    values[LOGIN_NAME],
  )}`;

  return (
    <Form noValidate>
      <Typography
        align="center"
        className={classes.title}
        gutterBottom
        variant="h4"
      >
        Панель управления
      </Typography>
      <Field
        Target={TextField}
        id={LOGIN_NAME}
        name={LOGIN_NAME}
        label="Введите email"
        type="email"
        fullWidth
        inputProps={emailInputProps}
      />
      <Field
        Target={PasswordField}
        id={PASSWORD_NAME}
        name={PASSWORD_NAME}
        label="Введите пароль"
        margin="normal"
        fullWidth
        inputProps={passwordInputProps}
      />
      <div className={classes.forgotLinkWrap}>
        <Link to={forgotPath}>Забыли пароль?</Link>
      </div>
      <div className={classes.footer}>
        <SubmitButton
          Target={AsyncButton}
          tabIndex={3}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
        >
          Войти
        </SubmitButton>
        <RequiredMessages
          fieldNames={fieldNames}
          className={classes.requiredErrors}
        />
      </div>
    </Form>
  );
};

LoginForm.propTypes = FormikWithValidationPropTypes;

export default LoginForm;
