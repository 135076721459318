import { findBrandById } from '@esentai/core/features/brands/actions';
import {
  getBrandLoadingError,
  isBrandLoaded,
  isBrandLoading,
} from '@esentai/core/features/brands/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { updateBrand } from '@/features/brandEditPage/actions';
import { getBrandId } from '@/features/brandEditPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import EditBrand from './component';

const mapStateToProps = state => {
  const itemId = getBrandId(state);

  return {
    error: getBrandLoadingError(state, itemId),
    isLoaded: isBrandLoaded(state, itemId),
    isLoading: isBrandLoading(state, itemId),
    itemId,
  };
};

const mapDispatchToProps = { fetchItem: findBrandById, updateBrand };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(EditBrand);
