import {
  INDEX_KEY,
  PRICE_KEY,
  SKU_KEY,
  SUBTITLE_RU_KEY,
  TITLE_RU_KEY,
  VISIBILITY_KEY,
} from '@esentai/core/features/giftSets/consts/keys';

import { createVisibilityFilter } from '@/filters/GiftSetsVisibility';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: INDEX_KEY,
    label: 'Очередность',
  }),
  createTextFilter({
    key: TITLE_RU_KEY,
    label: 'Заголовок',
  }),
  createTextFilter({
    key: SUBTITLE_RU_KEY,
    label: 'Подзаголовок',
  }),
  createTextFilter({
    key: SKU_KEY,
    label: 'Артикул',
  }),
  createMoneyRangeFilter({
    key: PRICE_KEY,
    label: 'Цена товара',
  }),
  createVisibilityFilter({
    key: VISIBILITY_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),
];
