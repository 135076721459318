import PropTypes from 'prop-types';
import React from 'react';

const TopBar = props => {
  const {
    classes,
    CenterComponent,
    LeftComponent,
    RightComponent,
    ...rest
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        <LeftComponent {...rest} />
      </div>
      <div className={classes.centerColumn}>
        <CenterComponent {...rest} />
      </div>
      <div>
        <RightComponent {...rest} />
      </div>
    </div>
  );
};

TopBar.propTypes = {
  CenterComponent: PropTypes.func.isRequired,
  LeftComponent: PropTypes.func.isRequired,
  RightComponent: PropTypes.func.isRequired,
};

export default TopBar;
