export default {
  root: {
    color: '#fff',
    textAlign: 'center',
    lineHeight: '71px',
    height: 56,
    width: 56,
    float: 'right',
    marginTop: -28,
    marginBottom: -28,
  },
};
