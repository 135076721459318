import { TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SelectionControl from './SelectionControl';

class InlineSearch extends Component {
  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    inputProps: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    suggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
    value: PropTypes.any,
    countCaptionFormatter: PropTypes.func.isRequired,
    selectedItemLabel: PropTypes.string.isRequired,
    SelectedItem: PropTypes.func.isRequired,
    Suggestion: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    value: null,
  };

  state = {
    queryString: '',
  };

  componentDidMount() {
    const { fetchItems } = this.props;
    const { queryString } = this.state;

    fetchItems(queryString);
  }

  handleQueryChange = event => {
    const { value: queryString } = event.target;
    const { fetchItems } = this.props;

    this.setState({ queryString });

    fetchItems(queryString);
  };

  handleSelect = value => {
    const { onSelect } = this.props;

    onSelect(value);
  };

  handleUnselect = () => {
    const { onSelect } = this.props;

    onSelect(null);
  };

  renderChip = () => {
    const {
      classes,
      value,
      selectedItemLabel,
      SelectedItem,
      disabled,
    } = this.props;

    return (
      <div>
        <Typography variant="caption" className={classes.selectedItemLabel}>
          {selectedItemLabel}
        </Typography>
        <SelectedItem
          disabled={disabled}
          value={value}
          onDelete={this.handleUnselect}
        />
      </div>
    );
  };

  renderSelect = () => {
    const {
      countCaptionFormatter,
      classes,
      inputProps,
      suggestions,
      Suggestion,
    } = this.props;
    const { queryString } = this.state;

    return (
      <div>
        <TextField
          {...inputProps}
          onChange={this.handleQueryChange}
          value={queryString}
        />

        <div className={classes.suggestionsHeading}>
          <Typography variant="caption" className={classes.suggestionsCaption}>
            Результаты поиска
          </Typography>
          <Typography variant="caption">
            {countCaptionFormatter(suggestions.length)}
          </Typography>
        </div>

        <div>
          {suggestions.map(suggestion => (
            <Suggestion
              key={suggestion}
              itemId={suggestion}
              SelectionControl={this.renderSelectionControl}
            />
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { classes, value } = this.props;

    return (
      <div className={classes.root}>
        {value ? this.renderChip() : this.renderSelect()}
      </div>
    );
  }

  renderSelectionControl = props => (
    <SelectionControl {...props} onSelect={this.handleSelect} />
  );
}

export default InlineSearch;
