import {
  CUSTOMER_EMAIL_KEY,
  CUSTOMER_ID_KEY,
  CUSTOMER_LEVEL_KEY,
  ID_KEY,
  RECORD_CREATED_KEY,
  VEHICLE_GOV_NUMBER_KEY,
} from '@/features/timiryazevParkingPage/consts/keys';
import { createDateTimeRangeFilter } from '@/filters/DateTimeRange';
import { createEqualsFilter, createTextFilter } from '@/filters/SearchInput';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createEqualsFilter({
    key: ID_KEY,
    label: 'ID',
  }),
  createEqualsFilter({
    key: CUSTOMER_ID_KEY,
    label: 'ID клиента',
  }),
  createTextFilter({
    key: VEHICLE_GOV_NUMBER_KEY,
    label: 'Номер авто',
  }),
  createTextFilter({
    key: CUSTOMER_EMAIL_KEY,
    label: 'Почта',
  }),
  createUserStatusFilter({
    key: CUSTOMER_LEVEL_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),
  createDateTimeRangeFilter({
    key: RECORD_CREATED_KEY,
    label: 'Дата',
  }),
];

export const filterPrefixes = [
  {
    field: ID_KEY,
    prefix: 'eq',
  },
  {
    field: CUSTOMER_ID_KEY,
    prefix: 'eq',
  },
  {
    field: VEHICLE_GOV_NUMBER_KEY,
    prefix: 'ilike',
  },
  {
    field: CUSTOMER_EMAIL_KEY,
    prefix: 'ilike',
  },

  {
    field: RECORD_CREATED_KEY,
    prefix: 'eq',
  },
  {
    field: CUSTOMER_LEVEL_KEY,
    prefix: 'in',
  },
];

export const multipleFilterList = [];
