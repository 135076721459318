import {
  getBonusesRefundedAmount,
  getBonusesSpentAmount,
  getCompanyName,
  getLastReconciliationDate,
  getMerchantId,
  getMerchantName,
  getOverpaymentAmount,
  getPaymentDatetime,
  getPaymentFileUrl,
  getReconciliationCreatedDate,
  getReconciliationEndDate,
  getReconciliationStartDate,
  getStoreName,
  getTotalToPayAmount,
} from '@esentai/core/features/reconciliationHistory/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { retryFetch } from '../../actions';
import { getStoreId } from '../../selectors';
import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: reconciliationId }) => ({
  lastReconciliationDate: getLastReconciliationDate(state, reconciliationId),
  reconciliationCreatedDate: getReconciliationCreatedDate(
    state,
    reconciliationId,
  ),
  reconciliationEndDate: getReconciliationEndDate(state, reconciliationId),
  reconciliationStartDate: getReconciliationStartDate(state, reconciliationId),
  bonusesRefundedAmount: getBonusesRefundedAmount(state, reconciliationId),
  bonusesSpentAmount: getBonusesSpentAmount(state, reconciliationId),
  overpaymentAmount: getOverpaymentAmount(state, reconciliationId),
  storeId: getStoreId(state),
  storeName: getStoreName(state, reconciliationId),
  totalToPayAmount: getTotalToPayAmount(state, reconciliationId),
  paymentDatetime: getPaymentDatetime(state, reconciliationId),
  paymentFileUrl: getPaymentFileUrl(state, reconciliationId),
  companyName: getCompanyName(state, reconciliationId),
  merchantId: getMerchantId(state, reconciliationId),
  merchantName: getMerchantName(state, reconciliationId),
  reconciliationId,
});

const mapDispatchToProps = {
  fetchItems: retryFetch,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Row);
