import PropTypes from 'prop-types';
import React from 'react';

const Image = ({ classes, src, ...rest }) => (
  <a href={src} target="_blank" rel="noopener noreferrer">
    <img {...rest} src={src} className={classes.root} />
  </a>
);

Image.propTypes = {
  src: PropTypes.string,
};

Image.defaultProps = {
  src: null,
};

export default Image;
