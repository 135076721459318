import { FIRST_FLOOR_ID } from '@esentai/core/features/beacons/consts/floors';
import {
  doesBeaconHaveNoPosition,
  getBeaconFloor,
  getBeaconLocationIds,
  getBeaconsByFloor,
} from '@esentai/core/features/beacons/selectors';
import {
  getBeaconsIds,
  getFloorLocationId,
  getLocationFloors,
  getRentIds,
} from '@esentai/core/features/locations/selectors';
import { any, chain, head, includes, isEmpty, isNil, pipe, prop } from 'ramda';

import { getLocationStatisticsPageUrl } from '@/features/usersStatisticsPage/selectors';
import { createMatchSelector } from '@/redux/router/selectors';

import {
  BEACON_AND_FLOOR_ROUTE_PATH,
  BEACON_ROUTE_PATH,
  FLOOR_ROUTE_PATH,
  LOCATION_AND_FLOOR_ROUTE_PATH,
  LOCATION_ROUTE_PATH,
} from './consts';
import { createMapPageUrl } from './utils';

const getBeaconIdFromBeaconAndFloorRoute = pipe(
  createMatchSelector(BEACON_AND_FLOOR_ROUTE_PATH),
  prop('params'),
  prop('beacon'),
);

const getBeaconIdFromJustBeaconRoute = pipe(
  createMatchSelector(BEACON_ROUTE_PATH),
  prop('params'),
  prop('beacon'),
);

const getLocationIdFromJustLocationRoute = pipe(
  createMatchSelector(LOCATION_ROUTE_PATH),
  prop('params'),
  prop('location'),
);

const getLocationIdFromLocationAndFloorRoute = pipe(
  createMatchSelector(LOCATION_AND_FLOOR_ROUTE_PATH),
  prop('params'),
  prop('location'),
);

export const getBeaconIdFromRoute = state =>
  getBeaconIdFromJustBeaconRoute(state) ||
  getBeaconIdFromBeaconAndFloorRoute(state);

export const getLocationIdFromRoute = state =>
  getLocationIdFromJustLocationRoute(state) ||
  getLocationIdFromLocationAndFloorRoute(state);

const getFloorFromRoute = pipe(
  createMatchSelector(FLOOR_ROUTE_PATH),
  prop('params'),
  prop('floor'),
);

export const getBeaconMapPageUrl = (state, beaconId) =>
  createMapPageUrl({ beaconId });

export const getLocationMapPageUrl = (state, locationId) =>
  createMapPageUrl({ locationId });

export const getSelectedFloor = state => {
  const routeBeaconId = getBeaconIdFromRoute(state);
  const routeFloor = getFloorFromRoute(state);
  const routeLocationId = getLocationIdFromRoute(state);

  if (!isNil(routeFloor)) {
    return routeFloor;
  }

  if (!isNil(routeLocationId)) {
    const floors = getLocationFloors(state, routeLocationId);

    if (floors && !isEmpty(floors)) {
      return head(floors);
    }
  }

  if (!isNil(routeBeaconId)) {
    return getBeaconFloor(state, routeBeaconId);
  }

  return FIRST_FLOOR_ID;
};

export const isSelectedFloor = (state, floor) => {
  const selectedFloor = getSelectedFloor(state);

  return floor === selectedFloor;
};

export const getDisplayingBeaconIds = state => {
  const selectedFloor = getSelectedFloor(state);

  return getBeaconsByFloor(state, selectedFloor);
};

export const hasBeaconsWithoutPosition = state => {
  const beaconIds = getDisplayingBeaconIds(state);

  return any(beaconId => doesBeaconHaveNoPosition(state, beaconId), beaconIds);
};

export const isBeaconDimmed = (state, beaconId) => {
  const routeBeaconId = getBeaconIdFromRoute(state);
  const routeLocationId = getLocationIdFromRoute(state);

  if (!isNil(routeBeaconId)) {
    return String(beaconId) !== routeBeaconId;
  }

  if (!isNil(routeLocationId)) {
    const beaconIds = getBeaconsIds(state, routeLocationId);

    return !includes(beaconId, beaconIds);
  }

  return false;
};

export const isBeaconSelected = (state, beaconId) => {
  const routeBeaconId = getBeaconIdFromRoute(state);
  const routeLocationId = getLocationIdFromRoute(state);

  if (!isNil(routeBeaconId)) {
    return String(beaconId) === routeBeaconId;
  }

  if (!isNil(routeLocationId)) {
    const beaconIds = getBeaconsIds(state, routeLocationId);

    return includes(beaconId, beaconIds);
  }

  return false;
};

export const isFloorHighlighted = (state, floor) => {
  const routeBeaconId = getBeaconIdFromRoute(state);
  const routeLocationId = getLocationIdFromRoute(state);

  if (!isNil(routeLocationId)) {
    const routeLocationFloors = getLocationFloors(state, routeLocationId);

    return includes(floor, routeLocationFloors);
  }

  if (!isNil(routeBeaconId)) {
    const routeBeaconFloor = getBeaconFloor(state, routeBeaconId);

    return floor === routeBeaconFloor;
  }

  return false;
};

export const isRentPlaceHighlighted = (state, rentPlaceId) => {
  const routeBeaconId = getBeaconIdFromRoute(state);

  if (!isNil(routeBeaconId)) {
    const locationIds = getBeaconLocationIds(state, routeBeaconId);
    const rentPlaceIds = chain(
      locationId => getRentIds(state, locationId),
      locationIds,
    );

    return includes(rentPlaceId, rentPlaceIds);
  }

  return false;
};

export const isRentPlaceSelected = (state, rentPlaceId) => {
  const routeLocationId = getLocationIdFromRoute(state);

  if (isNil(routeLocationId)) {
    return false;
  }

  const rentIds = getRentIds(state, routeLocationId);

  return includes(rentPlaceId, rentIds);
};

export const getFloorStatisticsPageUrl = (state, floor) => {
  const locationId = getFloorLocationId(state, floor);

  return getLocationStatisticsPageUrl(state, locationId);
};
