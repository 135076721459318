// Dimensions
//
// DO NOT USE THESE KEYS FOR DISPLAYING AND SORTING.
// ONLY FILTERING ALLOWED.
//
// If you need fields for displaying and sorting, see 'keys.js'

export const CUSTOMER_AGE_KEY = 'customer_age';

export const CUSTOMER_AVERAGE_CHECK_KEY = 'customer_average_ticket';

export const CUSTOMER_BIRTH_DATE_KEY = 'customer_born_at';

export const CUSTOMER_GENDER_KEY = 'customer_gender';

export const CUSTOMER_STATUS_KEY = 'customer_status';

export const TIME_SERIES_KEY = 'ts';
