import { multiply, path, pipe, prop } from 'ramda';

import {
  AVG_TICKET_KEY,
  NR_RECEIPTS_KEY,
  PERCENT_RECEIPTS_DAY_KEY,
  PERCENT_RECEIPTS_EVENING_KEY,
  PERCENT_RECEIPTS_MORNING_KEY,
} from '@/features/anonymousPurchasesStatistics/consts/keys';
import daoDuck from '@/features/anonymousPurchasesStatistics/ducks/dao';
import {
  META_TIME_SCALE_KEY,
  META_TOTAL_HEADER,
} from '@/features/statistics/consts/keys';
import { createItemsSelectors } from '@/features/statisticsPage/selectors';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import stats from './stats';

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;

export const {
  getError,
  isLoadingItems,
  getItemsMeta,
  getItemsTotalCount,
} = itemsDuck.selectors;

export const getScale = pipe(getItemsMeta, prop(META_TIME_SCALE_KEY));

export const {
  getItemsIds,
  getXValues,
  getLeftYValues,
  getRightYValues,
  getDateBySelectedIndex,
  getPropBySelectedIndex,
} = createItemsSelectors(itemsDuck, queryDuck, daoDuck, stats);

const getPropFromTotalMeta = key => state =>
  path([META_TOTAL_HEADER, key], getItemsMeta(state));

const getItemPercentagePropById = property =>
  pipe(getPropFromTotalMeta(property), multiply(100));

export const getTotalAverageTicket = getPropFromTotalMeta(AVG_TICKET_KEY);

export const getTotalPurchasesCount = getPropFromTotalMeta(NR_RECEIPTS_KEY);

export const getTotalPercentageReceiptsByMorning = getItemPercentagePropById(
  PERCENT_RECEIPTS_MORNING_KEY,
);

export const getTotalPercentageReceiptsByDay = getItemPercentagePropById(
  PERCENT_RECEIPTS_DAY_KEY,
);

export const getTotalPercentageReceiptsByEvening = getItemPercentagePropById(
  PERCENT_RECEIPTS_EVENING_KEY,
);
