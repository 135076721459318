import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { createHistoryData } from '@esentai/core/features/history_data/sagas';
import { createMerchant } from '@esentai/core/features/merchants/sagas';
import { select } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getCurrentUserId } from '@/features/auth/selectors';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';

import { CREATE_MERCHANT } from './types';

export function* createMerchantAction(action) {
  const { payload: attributes } = action;

  attributes.status = 'active';
  delete attributes.merchantStatus;

  const { merchant } = yield call(createMerchant, attributes);

  const history = {
    object_id: merchant[0].id,
    model: 'merchant',
    user_id: yield select(getCurrentUserId),
    comment: 'Новая запись',
    new_data: JSON.stringify(attributes),
    operation: 'create',
  };

  yield call(createHistoryData, history);

  yield put(push(MERCHANTS_PATH));
}

export default function*() {
  yield takeLatest(CREATE_MERCHANT, createTaskSaga(createMerchantAction));
}
