import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';

const SortableItem = ({
  classes,
  margin,
  onClick,
  direction,
  top,
  left,
  setCheckedItem,
  ...rest
}) => {
  const imgWithClick = { cursor: 'pointer' };

  const imgStyle = { margin };

  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    // eslint-disable-next-line
    onClick(event, { photo, index });
  };

  return (
    <div className={classes.item}>
      <div>
        <Checkbox
          checked={rest.checked}
          onChange={() => setCheckedItem(rest.id)}
        />
      </div>
      <div className={classes.innerItem}>
        <img
          style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
          src={rest.src}
          width={rest.width}
          height={rest.height}
          onClick={onClick ? handleClick : null}
          alt="img"
        />
      </div>
      <div className={classes.innerItem}>
        <Typography variant="subtitle1" color="textSecondary">
          {rest.title_ru}
        </Typography>
      </div>
      <div className={classes.innerItem}>
        <Typography variant="subtitle2" color="textSecondary">
          {rest.subtitle_ru}
        </Typography>
      </div>
      <div className={classes.innerItem}>
        <Typography variant="body2" color="textSecondary">
          {rest.index}
        </Typography>
      </div>
    </div>
  );
};

SortableItem.propTypes = {
  margin: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  setCheckedItem: PropTypes.func.isRequired,
  direction: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
};

export default SortableItem;
