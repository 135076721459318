import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getRentPlaceName,
  getStatDescriptor,
} from '@/features/locationsStatisticsPage/selectors';
import stats from '@/features/locationsStatisticsPage/stats';

import TooltipContent from './component';
import styles from './styles';

const mapStateToProps = (state, { rentId }) => ({
  currentStatDescriptor: getStatDescriptor(state),
  rentPlaceName: getRentPlaceName(state, rentId),
  stats,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(TooltipContent);
