import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import { LocationMultiAutocomplete } from '@/containers/LocationAutocomplete';
import { LOCATIONS_IDS_KEY } from '@/features/invites/consts/keys';

class LocationsField extends Component {
  static propTypes = {
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const { handleBlur, values, classes } = this.props;

    return (
      <FieldRow
        label="Доступ к локациям"
        subLabel="Локации администратора"
        sudDesc="Пользователь получет доступ к статистике посещений, покупок указанных локаций."
        className={classes.row}
      >
        <LocationMultiAutocomplete
          id={LOCATIONS_IDS_KEY}
          name={LOCATIONS_IDS_KEY}
          onChange={this.handleLocationChange}
          onBlur={handleBlur}
          selectedItem={values[LOCATIONS_IDS_KEY]}
          InputProps={{
            placeholder: 'Добавьте локацию',
          }}
        />
      </FieldRow>
    );
  }

  handleLocationChange = locationId =>
    this.props.setFieldValue(LOCATIONS_IDS_KEY, locationId);
}

export default LocationsField;
