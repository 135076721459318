import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import DeleteCarForm from './Form';

class DeleteCarModal extends Component {
  static defaultProps = {
    currentCarDetails: null,
  };

  render() {
    const { isOpen, onClose, currentCarDetails, errorMessage } = this.props;

    const errorLink = errorMessage && errorMessage.errorLink;
    const errorText = errorMessage && errorMessage.errorMessage;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="xs"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">Удаление авто</DialogTitle>
        {currentCarDetails && (
          <DialogContent>
            <Typography>{`Вы уверены, что хотите удалить авто: `}</Typography>
            <Typography color="error">{`Гос. номер: ${currentCarDetails.vehicle_gov_number}, модель: ${currentCarDetails.vehicle_model}?`}</Typography>
            <DeleteCarForm
              currentCarDetails={currentCarDetails}
              onClose={onClose}
              onSubmit={this.props.onSubmit.bind(this)}
            />
            {errorMessage !== null && (
              <Typography variant="caption">
                {errorText}
                {' - '}
                <Link to={errorLink} onClick={onClose}>
                  перейти по ссылке.
                </Link>
              </Typography>
            )}
          </DialogContent>
        )}
      </Dialog>
    );
  }
}

DeleteCarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.object.isRequired,
  currentCarDetails: PropTypes.string,
};

export default DeleteCarModal;
