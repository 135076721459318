import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';

import {
  CREATE_DRAFT,
  FETCH_ITEM,
  GO_TO_STEP_ONE,
  GO_TO_STEP_TWO,
  SUBMIT,
} from './types';

export const createDraft = createTaskAction(CREATE_DRAFT);

export const fetchItem = createTaskAction(FETCH_ITEM);

export const goToStepOne = createTaskAction(GO_TO_STEP_ONE);

export const goToStepTwo = createTaskAction(GO_TO_STEP_TWO);

export const submit = createTaskAction(SUBMIT);
