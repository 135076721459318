import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../../ducks/query';
import {
  getDefaultMetricsPageUrl,
  getError,
  getItemsIds,
  getItemsTotalCount,
  getMetrics,
  getScale,
  isLoadingItems,
  shouldRedirectToDefaultMetricsPage,
} from '../../selectors';
import CampaignsStatisticsPage from './component';
import styles from './styles';

const mapStateToProps = state => ({
  defaultMetricsPageUrl: getDefaultMetricsPageUrl(state),
  error: getError(state),
  isLoading: isLoadingItems(state),
  itemsIds: getItemsIds(state),
  metrics: getMetrics(state),
  scale: getScale(state),
  shouldRedirectToDefaultMetricsPage: shouldRedirectToDefaultMetricsPage(state),
  totalCount: getItemsTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(CampaignsStatisticsPage);
