import {
  getKkmNumberForKkm,
  getMerchantIdForKkm,
  getStatusForKkm,
  getStoreIdForKkm,
} from '@esentai/core/features/kkms/selectors';
import { connect } from 'react-redux';

import Description from './component';

const mapStateToProps = (state, { kkmId }) => ({
  kkmNumber: getKkmNumberForKkm(state, kkmId),
  status: getStatusForKkm(state, kkmId),
  store: getStoreIdForKkm(state, kkmId),
  merchant: getMerchantIdForKkm(state, kkmId),
});

export default connect(mapStateToProps)(Description);
