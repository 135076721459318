import { compose, path, prop } from 'ramda';

import { UI_KEY } from '@/redux/constants';

export const getRoot = path([UI_KEY, 'userDeactivate']);

export const getError = compose(prop('error'), getRoot);

export const getUsersIds = compose(prop('ids'), getRoot);

export const getWaitUntil = compose(prop('waitUntil'), getRoot);

export const isWaiting = compose(prop('waiting'), getRoot);

export const isRunning = compose(prop('running'), getRoot);

export const isDeactivatingUser = (state, id) =>
  isRunning(state) && getUsersIds(state).includes(id);
