import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import AutocompleteMultiple from '@/components/AutocompleteMultiple';
import EmptyComponent from '@/components/EmptyComponent';

import { getOptions } from './utils';

const InputProps = {
  startAdornment: <Search />,
  disableUnderline: true,
};

const AutocompleteInput = ({ selectedItem, ...rest }) => (
  <AutocompleteMultiple
    {...rest}
    isOpenOnFocus
    isOpenOnChange
    selectedItem={selectedItem}
    options={getOptions()}
    renderCleanAll={EmptyComponent}
    InputProps={{
      fullWidth: true,
      placeholder: 'Добавьте тег',
      InputProps,
    }}
  />
);

AutocompleteInput.propTypes = {
  selectedItem: PropTypes.array,
};

AutocompleteInput.defaultProps = {
  selectedItem: [],
};

export default AutocompleteInput;
