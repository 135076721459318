import {
  getMerchantName,
  getReconciliatioBonusCost,
  getReconciliationFiscalId,
} from '@esentai/core/features/merchantsReconciliationReceipts/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: reconciliationId }) => ({
  reconciliatioBonusCost: getReconciliatioBonusCost(state, reconciliationId),
  reconciliationFiscalId: getReconciliationFiscalId(state, reconciliationId),
  merchantName: getMerchantName(state, reconciliationId),
  reconciliationId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
