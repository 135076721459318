import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { formatPercentage } from '@/utils/format';

const ChartBar = ({ classes, percentage, label, ...props }) => (
  <div className={classes.wrapper} {...props}>
    <div className={classNames(classes.bar, classes.element)} />
    <Typography className={classes.label}>
      {label} {formatPercentage(percentage)}
    </Typography>
  </div>
);

ChartBar.propTypes = {
  label: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default ChartBar;
