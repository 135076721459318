import {
  getMerchantIds,
  getStoreLoadingError,
  isStoreLoaded,
  isStoreLoading,
} from '@esentai/core/features/stores/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchStore as fetchItem } from '@/features/storePage/actions';
import { getStoreId } from '@/features/storePage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import Store from './component';

const mapStateToProps = state => {
  const itemId = getStoreId(state);

  return {
    error: getStoreLoadingError(state, itemId),
    isLoaded: isStoreLoaded(state, itemId),
    isLoading: isStoreLoading(state, itemId),
    merchantIds: getMerchantIds(state, itemId),
    itemId,
  };
};

const mapDispatchToProps = {
  fetchItem,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(Store);
