import {
  BRANDS_IDS_KEY,
  CATEGORIES_KEY,
  DESCRIPTION_CHINESE_KEY,
  DESCRIPTION_ENGLISH_KEY,
  DESCRIPTION_KAZAKH_KEY,
  DESCRIPTION_RUSSIAN_KEY,
  LOCATION_ID_KEY,
  LOGO_URL_KEY,
  LOYALTY_REP_KEY,
  MERCHANTS_IDS_KEY,
  NAME_ENGLISH_KEY,
  NAME_KAZAKH_KEY,
  NAME_KEY,
  PHONE_KEY,
  URL_KEY,
} from '@esentai/core/features/stores/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { array, boolean, number, object, string } from 'yup';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: ({
    categories,
    englishDescription,
    russianDescription,
    kazakhDescription,
  }) => ({
    [CATEGORIES_KEY]: categories,
    [DESCRIPTION_ENGLISH_KEY]: englishDescription,
    [DESCRIPTION_RUSSIAN_KEY]: russianDescription,
    [DESCRIPTION_KAZAKH_KEY]: kazakhDescription,
  }),

  validationSchema: object().shape({
    [NAME_KEY]: string().required(),
    [NAME_ENGLISH_KEY]: string().required(),
    [NAME_KAZAKH_KEY]: string().required(),

    [BRANDS_IDS_KEY]: array().of(number()),
    [MERCHANTS_IDS_KEY]: array().of(number()),
    [CATEGORIES_KEY]: array().of(string()),

    [DESCRIPTION_CHINESE_KEY]: string().nullable(),
    [DESCRIPTION_ENGLISH_KEY]: string().nullable(),
    [DESCRIPTION_KAZAKH_KEY]: string().nullable(),
    [DESCRIPTION_RUSSIAN_KEY]: string().nullable(),

    [LOCATION_ID_KEY]: number().nullable(),
    [LOGO_URL_KEY]: string().nullable(),
    [LOYALTY_REP_KEY]: boolean(),
    [PHONE_KEY]: string().nullable(),
    [URL_KEY]: string()
      .url()
      .nullable(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
