import api from '@esentai/core/features/products/api';
import {
  Avatar,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Products extends Component {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.any).isRequired,
    images: PropTypes.arrayOf(PropTypes.any).isRequired,
    setProducts: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      curProducts: [],
      images: [],
      addProduct: -1,
      items: [],
      isLoading: true,
      totalCount: 0,
      rowsSelected: [],
    };
  }

  componentDidMount() {
    this.loadProducts();
  }

  componentDidUpdate(prevProps) {
    if (this.props.products.length !== prevProps.products.length) {
      this.loadProducts();
    }
  }

  loadProducts() {
    const { products } = this.props;

    api.doQuery([['visible', 'eq', true]], 'id', 'asc', 9999).then(data => {
      const allProducts = data.payload.product.reduce((d, c) => {
        d[c.id] = c;

        return d;
      }, {});

      const curProducts =
        data.payload.product && data.payload.product.length > 0
          ? data.payload.product.filter(item => !products.includes(item.id))
          : [];

      this.setState({
        items: allProducts,
        curProducts,
        isLoading: false,
        totalCount: data.meta.total,
      });
    });
  }

  /* eslint-disable */
  renderValue = (value, meta) => (
    <MenuItem
      key={meta.rowData[2]}
      value={meta.rowData[2]}
      component="div"
      selected={false}
      disableGutters
      style={{
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: 24,
        maxHeight: 100,
        height: 'auto',
      }}
    >
      {meta.rowData[7] && <Avatar src={meta.rowData[7][0]} />}
      <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2" component="span">
          {meta.rowData[1]}
        </Typography>
        <Typography variant="body2">{`${meta.rowData[6]} Тенге`}</Typography>
      </div>
    </MenuItem>
  );
  /* eslint-enable */

  addProducts = rows => {
    const { products, images, setProducts } = this.props;
    const { curProducts } = this.state;
    const addedProds = rows.reduce((prods, cur) => {
      prods.push(curProducts[cur.dataIndex].id);

      return prods;
    }, []);
    const addedImgs = rows.reduce((imgs, cur) => {
      imgs.push(...curProducts[cur.dataIndex].images);

      return imgs;
    }, []);

    this.setState(
      { rowsSelected: [] },
      setProducts(products.concat(addedProds), images.concat(addedImgs)),
    );
  };

  /* eslint-disable */
  customToolbarSelect = (selectedRows, displayData, setSelectedRows) => {
    const { buttonRef } = this;
    return (
      <Tooltip id="button-add" title="Добавить товары">
        <IconButton
          buttonRef={buttonRef}
          onClick={() => this.addProducts(selectedRows.data)}
        >
          <AddCircleOutlineIcon fontSize={'large'} />
        </IconButton>
      </Tooltip>
    );
  };

  /* eslint-enable */

  render() {
    const { curProducts } = this.state;
    const options = {
      filter: true,
      filterType: 'textField',
      download: false,
      print: false,
      viewColumns: false,
      selectableRowsOnClick: true,
      search: false,
      customToolbarSelect: this.customToolbarSelect,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
      },
      textLabels: {
        body: {
          noMatch: 'Данные не найдены',
          toolTip: 'Сортировка',
        },
        toolbar: {
          filterTable: 'Фильтры',
        },
        filter: {
          all: 'Все',
          title: 'ФИЛЬТРЫ',
          reset: 'Сбросить',
        },
        selectedRows: {
          text: 'строк выбрано',
        },
      },
    };
    const columns = [
      {
        label: 'Товар',
        name: 'title_ru',
        options: { filter: false, customBodyRender: this.renderValue },
      },
      {
        label: 'Заголовок',
        name: 'title_ru',
        options: { display: false, viewColumns: false },
      },
      {
        label: 'Value',
        name: 'id',
        options: { display: false, viewColumns: false, filter: false },
      },
      {
        label: 'Подзаголовок',
        name: 'subtitle_ru',
        options: { display: false, viewColumns: false },
      },
      {
        label: 'Магазин',
        name: 'store_name',
        options: { display: false, viewColumns: false },
      },
      {
        label: 'Артикул',
        name: 'sku',
        options: { display: false, viewColumns: false },
      },
      {
        label: 'Цена',
        name: 'price',
        options: {
          display: false,
          viewColumns: false,
          filter: true,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1]) {
                return [`Цена от: ${v[0]}`, `Цена до: ${v[1]}`];
              } else if (v[0]) {
                return `Цена от: ${v[0]}`;
              } else if (v[1]) {
                return `Цена до: ${v[1]}`;
              }

              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(price, filters) {
              if (filters[0] && filters[1]) {
                return price < filters[0] || price > filters[1];
              } else if (filters[0]) {
                return price < filters[0];
              } else if (filters[1]) {
                return price > filters[1];
              }

              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Цена</FormLabel>
                <FormGroup row>
                  <TextField
                    placeholder="От"
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    placeholder="До"
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        label: 'Value',
        name: 'images',
        options: { display: false, viewColumns: false, filter: false },
      },
    ];

    return (
      <Paper style={{ marginTop: 50 }}>
        <MUIDataTable
          title={'Выберите товары'}
          data={curProducts}
          columns={columns}
          options={options}
        />
      </Paper>
    );
  }
}

export default Products;
