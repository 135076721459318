import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import { canUpdateGiftSets } from '@/features/giftSetsPage/permissions';
import { formatMoney } from '@/utils/format';

import Actions from './Actions';

const Row = ({
  index,
  titleRu,
  subtitleRu,
  giftSetId,
  giftSetPageUrl,
  sku,
  price,
}) => (
  <TableRow>
    <CondenseTableCell>
      <Link to={giftSetPageUrl}>{giftSetId}</Link>
    </CondenseTableCell>

    <CondenseTableCell>{titleRu}</CondenseTableCell>

    <CondenseTableCell>{subtitleRu}</CondenseTableCell>

    <CondenseTableCell>{index}</CondenseTableCell>

    <CondenseTableCell>{sku}</CondenseTableCell>

    <CondenseTableCell>{formatMoney(price)}</CondenseTableCell>

    <CanView permission={canUpdateGiftSets} productId={giftSetId}>
      <CondenseTableCell>
        <Actions giftSetId={giftSetId} />
      </CondenseTableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  titleRu: PropTypes.string.isRequired,
  subtitleRu: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  giftSetId: PropTypes.any.isRequired,
  giftSetPageUrl: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

Row.defaultProps = {
  categoryKeys: [],
  brandsIds: [],
};

export default Row;
