import {
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import { FieldArray } from 'formik';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Modal from '@/components/Modal';
import {
  CUSTOMER_ID_KEY,
  PURCHASE_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';
import { formatMoney } from '@/utils/format';

import ProductItem from '../ProductItem';

const RefundPurchase = props => {
  const {
    classes,
    cancelLabel,
    confirmLabel,
    onClose,
    Form,
    values,
    setFieldValue,
    selectedItem,
    isValid,
  } = props;

  const [receipt, setReceipt] = useState({
    products: [],
    loading: false,
    error: null,
    issued_at: null,
    id: null,
  });

  const [bonusTotal, setBonusTotal] = useState(0);

  const calculatePurchaseTotal = () => {
    const products = values[PURCHASE_KEY];
    let total = 0;

    Object.values(products).map(product => {
      if (product.isSelected) {
        total += Number(product.refund_amount * product.price);
      }

      return product;
    });

    return total || 0;
  };

  const loadProducts = async () => {
    setReceipt(prevState => ({
      ...prevState,
      products: [],
      loading: true,
    }));

    try {
      const params = {
        include: 'purchases,refund_purchases',
        limit: 100,
        sort: 'id',
        'id[eq]': selectedItem.receipt,
      };

      const response = await api.doGet(`/receipt`, params);
      const products = _get(response, 'payload.purchase', []);

      const productsList = products.map((product, index) => ({
        ...product,
        index,
        isSelected: false,
        refund_amount: '',
      }));

      setReceipt(prevState => ({
        ...prevState,
        products: productsList,
        issued_at: _get(response, 'payload.receipt.0.issued_at'),
        id: _get(response, 'payload.receipt.0.id'),
      }));

      setFieldValue(CUSTOMER_ID_KEY, selectedItem.id);
      setFieldValue(PURCHASE_KEY, productsList);
    } catch (error) {
      setReceipt(prevState => ({
        ...prevState,
        error,
      }));
      throw error;
    } finally {
      setReceipt(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const submitRefund = async () => {
    const purchasesToRefund = values[PURCHASE_KEY].filter(
      ({ isSelected }) => isSelected,
    ).map(({ id, refund_amount }) => ({
      id,
      amount: refund_amount,
    }));

    const payload = {
      refund: {
        receipt_id: receipt.id,
        purchases_to_refund: purchasesToRefund,
        force: true,
      },
    };

    try {
      await api.doPost(`/refund`, payload);
      onClose();
      window.purchaseData.loadPurchaseData();
    } catch (error) {
      throw error;
    }
  };

  const calculateBonusTotal = async () => {
    if (isValid) {
      const purchasesToRefund = values[PURCHASE_KEY].filter(
        ({ isSelected }) => isSelected,
      ).map(({ id, refund_amount }) => ({
        id,
        amount: refund_amount,
      }));

      const payload = {
        refund: {
          receipt_id: receipt.id,
          purchases_to_refund: purchasesToRefund,
        },
      };

      try {
        const response = await api.doPost(`/refund?calculate=true`, payload);

        setBonusTotal(Number(response.after_refund_pending_bonuses));
      } catch (error) {
        throw error;
      }
    }
  };

  const toggleItem = (event, index) => {
    setFieldValue(`${PURCHASE_KEY}[${index}].isSelected`, event.target.checked);
  };

  const renderProducts = products =>
    products.map((item, index) => (
      <div key={item}>
        <ProductItem {...props} index={index} isRefund>
          <Checkbox
            value={_get(values, `${index}.isSelected`, false)}
            onChange={event => toggleItem(event, index)}
          />
        </ProductItem>
      </div>
    ));

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      calculateBonusTotal();
    }, 1500);

    return () => clearTimeout(timer);
  }, [values, isValid]);

  return (
    <Modal {...props} onClose={onClose}>
      <Paper className={classes.wrapper}>
        <Typography variant="h4" gutterBottom>
          Оформить возврат
        </Typography>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                UUID:
              </Typography>
              <Typography variant="subtitle1">
                {selectedItem.fiscal_id}
              </Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                Магазин:
              </Typography>
              <Typography variant="subtitle1">
                {selectedItem.store_name}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                Общая сумма покупки:
              </Typography>
              <Typography variant="subtitle1">
                {formatMoney(calculatePurchaseTotal())}
              </Typography>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                Номер ККМ:
              </Typography>
              <Typography variant="subtitle1">
                {selectedItem.cash_machine}
              </Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                Дата и время покупки:
              </Typography>
              <Typography variant="subtitle1">
                {receipt.issued_at
                  ? format(new Date(receipt.issued_at), 'dd-MM-yyyy HH:mm:ss')
                  : '-'}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                Сумма бонусов к начислению:
              </Typography>
              <Typography variant="subtitle1">
                {formatMoney(bonusTotal)}
              </Typography>
            </div>
          </div>
        </div>

        {receipt.loading && (
          <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
            <CircularProgress color="secondary" />
          </div>
        )}

        {Boolean(receipt.products.length) && (
          <Form style={{ marginTop: 15, marginBottom: 15 }}>
            <FieldArray
              name={values[PURCHASE_KEY]}
              render={() => renderProducts(values[PURCHASE_KEY])}
            />
          </Form>
        )}

        <div className={classes.footer}>
          <Button onClick={onClose}>{cancelLabel}</Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            disabled={!isValid}
            onClick={submitRefund}
          >
            {confirmLabel}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

RefundPurchase.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  Form: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object,
  selectedItem: PropTypes.object,
};

RefundPurchase.defaultProps = {
  values: {},
  setFieldValue: () => {},
  selectedItem: {},
};

export default RefundPurchase;
