export default {
  wrapper: {
    // display: 'flex',
  },

  deviceChanges: {
    marginLeft: 'auto',
  },

  initialFact: {
    marginRight: 56,
  },
  facts: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  fact: {
    flexBasis: '25%',
    marginBottom: 18,
  },
};
