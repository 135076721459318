const SMALL = 3;
const MEDIUM = 4;
const LARGE = 6;
const GIANT = 12;

const GREEN = '#689F38';
const BLUE = '#4A90E2';
const GRAY = '#B3B3B3';
const WHITE = 'white';

export default {
  circle: {
    r: SMALL,
    fill: GREEN,
    stroke: WHITE,
    strokeWidth: 1,
    cursor: 'pointer',
    transitionProperty: ['fill', 'opacity', 'r'],
    transitionDuration: '0.1s',
    transitionTimingFunction: 'ease-out',

    '&:hover': {
      r: MEDIUM,
    },
  },

  circle_dimmed: {
    fill: GRAY,
    opacity: 0.5,

    '&:hover': {
      fill: GREEN,
      opacity: 1,
    },
  },

  circle_selected: {
    r: LARGE,
    fill: BLUE,

    '&:hover': {
      r: LARGE,
    },
  },

  backgroundCircle: {
    r: 0,
    fill: BLUE,
    fillOpacity: 0,
    transitionProperty: ['opacity', 'r'],
    transitionDuration: '0.1s',
    transitionTimingFunction: 'ease-out',
  },

  backgroundCircle_selected: {
    r: GIANT,
    fillOpacity: 0.45,
  },

  label: {
    fontFamily: 'Roboto',
    fontSize: 8,
    textAnchor: 'middle',
    fill: '#A0A0A0',
    pointerEvents: 'none',
    userSelect: 'none',
    transitionProperty: ['fill', 'transform'],
    transitionDuration: '0.1s',
    transitionTimingFunction: 'ease-in-out',
    transform: 'translateY(-6px)',
  },

  label_selected: {
    fill: 'white',
    cursor: 'pointer',
    pointerEvents: 'inherit',
    transform: 'translateY(3px)',
  },
};
