import { Button, Typography } from '@material-ui/core';
import { DateTimeFormat } from 'intl';
import PropTypes from 'prop-types';
import React from 'react';

import { downloadXls } from '@/features/reconciliationPeriodBonusDetailsPage/utils';
import { dateTimeFormatNoSecs, formatMoney } from '@/utils/format';

import Field from '../Field';

const Description = ({
  classes,
  storeName,
  startDatetime,
  endDatetime,
  percent,
  totalToPayAmount,
  paidAmount,
  pendingBonuses,
  activeBonuses,
  bonusName,
  lastInvoice,
  itemId,
}) => (
  <>
    <Typography
      style={{ textAlign: 'left', marginTop: 12 }}
      variant="subtitle1"
    >
      Детализация акции
    </Typography>
    <div className={classes.facts}>
      <Field label="Магазин" className={classes.fact}>
        {storeName}
      </Field>
      <Field label="Название акции" className={classes.fact}>
        {bonusName}
      </Field>
      <Field label="Период действия акции" className={classes.fact}>
        {dateTimeFormatNoSecs(startDatetime)} -{' '}
        {dateTimeFormatNoSecs(endDatetime)}
      </Field>
      <Field label="Процент начисления бонусов" className={classes.fact}>
        {percent} %
      </Field>
    </div>
    <Typography
      style={{ textAlign: 'left', marginTop: 12 }}
      variant="subtitle1"
    >
      Детализация платежей
    </Typography>
    <div className={classes.facts}>
      <Field label="Итог к оплате" className={classes.fact}>
        {formatMoney(totalToPayAmount)}
      </Field>
      <Field label="Оплаченная сумма" className={classes.fact}>
        {formatMoney(paidAmount)}
      </Field>
      <Field label="Сумма бонусов к начислению" className={classes.fact}>
        {pendingBonuses}
      </Field>
      <Field label="Сумма активных бонусов" className={classes.fact}>
        {activeBonuses}
      </Field>
      <Field label="Последняя дата выставления" className={classes.fact}>
        {lastInvoice ? dateTimeFormatNoSecs(lastInvoice) : '-'}
      </Field>
    </div>
    <Button
      variant="contained"
      color="primary"
      className={classes.excelButton}
      onClick={() => {
        downloadXls(itemId, bonusName);
      }}
    >
      Вывести в Excel
    </Button>
  </>
);

Description.defaultProps = {
  pendingBonuses: 0,
  storeName: '',
  totalToPayAmount: 0,
  paidAmount: 0,
  percent: 0,
  activeBonuses: 0,
};

Description.propTypes = {
  storeName: PropTypes.string,
  totalToPayAmount: PropTypes.number,
  endDatetime: PropTypes.instanceOf(Date).isRequired,
  startDatetime: PropTypes.instanceOf(Date).isRequired,
  paidAmount: PropTypes.number,
  percent: PropTypes.number,
  pendingBonuses: PropTypes.number,
  activeBonuses: PropTypes.number,
  itemId: PropTypes.number.isRequired,
  bonusName: PropTypes.string.isRequired,
  lastInvoice: PropTypes.instanceOf(DateTimeFormat).isRequired,
};

export default Description;
