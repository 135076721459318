import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import CloneButton from '@/components/ActionButtons/Clone';
import { cloneAndEdit } from '@/features/campaignsPage/actions';
import { canCreateCampaign } from '@/features/campaignsPage/permissions';

const mapStateToProps = () => ({
  permission: canCreateCampaign,
});

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(cloneAndEdit(itemId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(CloneButton);
