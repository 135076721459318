import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import ImageThumb from '@/components/ImageThumb';
import ProductCategoryNameLazy from '@/containers/ProductCategoryNameLazy';
import ProductViewNameLazy from '@/containers/ProductViewNameLazy';
import StoreNameLazy from '@/containers/StoreNameLazy';

import Label from '../Label';

const Description = ({
  titleRu,
  titleEn,
  titleKz,
  subtitleRu,
  subtitleEn,
  subtitleKz,
  descriptionRu,
  descriptionEn,
  descriptionKz,
  images,
  store,
  sku,
  price,
  category,
  views,
}) => (
  <FieldRow name="Описание Товара">
    <Grid container spacing={16}>
      <Grid item spacing={16} style={{ width: '100%' }}>
        <Label title="Заголовок RU">{titleRu}</Label>
        <Label title="Заголовок EN">{titleEn}</Label>
        <Label title="Заголовок KZ">{titleKz}</Label>

        <Label title="Подзаголовок RU">{subtitleRu}</Label>
        <Label title="Подзаголовок EN">{subtitleEn}</Label>
        <Label title="Подзаголовок KZ">{subtitleKz}</Label>

        <Label title="Описание RU">{descriptionRu}</Label>
        <Label title="Описание EN">{descriptionEn}</Label>
        <Label title="Описание KZ">{descriptionKz}</Label>

        <Label title="Магазин">
          <StoreNameLazy storeId={store} />
        </Label>

        <Label title="Артикул">{sku}</Label>
        <Label title="Цена">{price}</Label>

        <Label title="Категория">
          {category > 0 ? (
            <ProductCategoryNameLazy productCategoryId={category} />
          ) : (
            <span>-</span>
          )}
        </Label>

        <Label title="Виды">
          {views && views.length > 0
            ? views.map((view, index) => (
                // eslint-disable-next-line react/jsx-indent
                <>
                  <ProductViewNameLazy productViewId={view} key={view} />
                  {index + 1 !== views.length && ','}
                </>
              ))
            : ''}
        </Label>

        <Label title="Изображения" />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {images.map(image => (
            <ImageThumb url={image} key={image} />
          ))}
        </div>
      </Grid>
    </Grid>
  </FieldRow>
);

Description.propTypes = {
  titleRu: PropTypes.string,
  titleEn: PropTypes.string,
  titleKz: PropTypes.string,

  subtitleRu: PropTypes.string,
  subtitleEn: PropTypes.string,
  subtitleKz: PropTypes.string,

  descriptionKz: PropTypes.string,
  descriptionEn: PropTypes.string,
  descriptionRu: PropTypes.string,

  images: PropTypes.string,
  store: PropTypes.string,
  sku: PropTypes.string,
  price: PropTypes.string,
  category: PropTypes.number,
  views: PropTypes.array.isRequired,
};

Description.defaultProps = {
  titleKz: null,
  titleRu: null,
  titleEn: null,

  subtitleRu: null,
  subtitleEn: null,
  subtitleKz: null,

  descriptionRu: null,
  descriptionEn: null,
  descriptionKz: null,

  images: null,
  store: null,
  sku: null,
  price: null,
  category: null,
  views: null,
};

export default Description;
