import {
  getActiveBonuses,
  getBonusName,
  getEndDatetime,
  getLastInvoiceDatetime,
  getPaidAmount,
  getPendingBonuses,
  getPercent,
  getStartDatetime,
  getStoreId,
  getStoreName,
  getTotalToPayAmount,
} from '@esentai/core/features/reconciliationPeriodBonusDetails/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Description from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  storeName: getStoreName(state, itemId),
  totalToPayAmount: getTotalToPayAmount(state, itemId),
  endDatetime: getEndDatetime(state, itemId),
  startDatetime: getStartDatetime(state, itemId),
  storeId: getStoreId(state, itemId),
  paidAmount: getPaidAmount(state, itemId),
  percent: getPercent(state, itemId),
  pendingBonuses: getPendingBonuses(state, itemId),
  activeBonuses: getActiveBonuses(state, itemId),
  bonusName: getBonusName(state, itemId),
  lastInvoice: getLastInvoiceDatetime(state, itemId),
  itemId,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Description);
