// Dimensions
//
// DO NOT USE THESE KEYS FOR DISPLAYING AND SORTING.
// ONLY FILTERING ALLOWED.
//
// If you need fields for displaying and sorting, see 'keys.js'

export const CAMPAIGN_ACTION_RESULT_TRIGGER_KEY = 'campaign_trigger_type';

export const CAMPAIGN_BANNER_TYPE_KEY = 'campaign_banner_type';

// An artifical key, it doesn't present at the backend.
// It's purpose is to make it easier to create 'Rewards Type'
// filter with the existing backend, which provides
// only 'campaign_has_benefit_reward' and 'campaign_has_bonus_reward' keys.
export const CAMPAIGN_REWARD_TYPE_KEY = 'campaign_reward_type';

export const CAMPAIGN_HAS_BENEFIT_REWARD_KEY = 'campaign_has_benefit_reward';

export const CAMPAIGN_HAS_BONUS_REWARD_KEY = 'campaign_has_bonus_reward';

export const CAMPAIGN_LOCATION_ID_KEY = 'campaign_location_id';

export const CAMPAIGN_BUTTON_PLACEMENT_KEY = 'campaign_placement';

export const CAMPAIGN_TYPE_KEY = 'campaign_type';

export const TIME_SERIES_KEY = 'ts';
