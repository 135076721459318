import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import CanView from '@/components/CanView';
import Dimmed from '@/components/Dimmed';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';
import { ROUTE_PATH as CAMPAIGNS_STATISTICS_PATH } from '@/features/campaignsStatisticsDetailsPage/consts';
import Spinner from '@/features/campaignsStatisticsDetailsPage/Page/Spinner';

import CampaignsInfluence from './CampaignsInfluence';
import Filters from './Filters';
import PrimaryDetails from './PrimaryDetails';
import TopBar from './TopBar';

class DetailsPage extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { classes, isLoading, isLoaded } = this.props;

    return (
      <Page classes={classes}>
        <Grid container justify="center">
          <PageHeader borderBottom>
            <Breadcrumbs>
              <Breadcrumb to={CAMPAIGNS_STATISTICS_PATH}>
                Статистика по кампаниям
              </Breadcrumb>
            </Breadcrumbs>
            <BackButton />
            <PageTitle gutterBottom={false}>Статистика по кампании</PageTitle>
            <PageSubtitle>
              Статистика ограничена только вашим магазином.
            </PageSubtitle>
          </PageHeader>
        </Grid>
        <Grid container justify="center">
          <PageContent>
            <TopBar />
            <Filters />
          </PageContent>
        </Grid>
        <CanView isVisible={isLoading && !isLoaded}>
          <Spinner />
        </CanView>
        <CanView isVisible={isLoaded}>
          <Dimmed dimmed={isLoading}>
            <PrimaryDetails isLoading={isLoading} isLoaded={isLoaded} />
            <CampaignsInfluence isLoading={isLoading} isLoaded={isLoaded} />
          </Dimmed>
        </CanView>
      </Page>
    );
  }
}

export default DetailsPage;
