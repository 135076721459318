import {
  getCategoryForProduct,
  getDescriptionEnForProduct,
  getDescriptionKzForProduct,
  getDescriptionRuForProduct,
  getImagesForProduct,
  getPriceForProduct,
  getSkuForProduct,
  getStoreIdProduct,
  getSubtitleEnForProduct,
  getSubtitleKzForProduct,
  getSubtitleRuForProduct,
  getTitleEnForProduct,
  getTitleKzForProduct,
  getTitleRuForProduct,
  getViewForProduct,
} from '@esentai/core/features/products/selectors';
import { connect } from 'react-redux';

import Description from './component';

const mapStateToProps = (state, { productId }) => ({
  titleRu: getTitleRuForProduct(state, productId),
  titleEn: getTitleEnForProduct(state, productId),
  titleKz: getTitleKzForProduct(state, productId),

  subtitleRu: getSubtitleRuForProduct(state, productId),
  subtitleEn: getSubtitleEnForProduct(state, productId),
  subtitleKz: getSubtitleKzForProduct(state, productId),

  descriptionRu: getDescriptionRuForProduct(state, productId),
  descriptionEn: getDescriptionEnForProduct(state, productId),
  descriptionKz: getDescriptionKzForProduct(state, productId),

  images: getImagesForProduct(state, productId),
  store: getStoreIdProduct(state, productId),
  sku: getSkuForProduct(state, productId),
  price: getPriceForProduct(state, productId),
  category: getCategoryForProduct(state, productId),

  views: getViewForProduct(state, productId),
});

export default connect(mapStateToProps)(Description);
