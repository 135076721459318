import { DATE_START_KEY } from '@esentai/core/features/campaigns/consts/keys';
import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/purchaseResult/consts/destinations';
import {
  ACTIVATE_ONCE_KEY,
  DELAY_AFTER_PURCHASE_KEY,
  DESTINATION_KEY,
  HAS_DELAY_AFTER_PURCHASE_KEY,
  HAS_TRIGGERS_KEY,
  PERIOD_RECEIPTS_KEY,
  PRE_PUSH_ENGLISH_MESSAGE_KEY,
  PRE_PUSH_ENGLISH_TITLE_KEY,
  PRE_PUSH_RUSSIAN_MESSAGE_KEY,
  PRE_PUSH_RUSSIAN_TITLE_KEY,
  PRE_PUSH_SEND_DATETIME_KEY,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  RECEIPTS_COUNT_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_STORE_ID_KEY,
  REWARD_BENEFITS_IDS_KEY,
  REWARD_LOYALTY_POINTS_KEY,
  REWARD_TYPE_KEY,
  SEND_PRE_PUSH_PUSH_KEY,
  SEND_PUSH_KEY,
  SINGLE_RECEIPT_ITEMS_COUNT_KEY,
  SINGLE_RECEIPT_KEY,
  TRACKED_STORE_IDS_KEY,
  TRIGGERS_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { LOYALTY_POINTS_REWARD } from '@esentai/core/features/campaigns/purchaseResult/consts/rewards';
import {
  PERIOD_RECEIPT_TRIGGER,
  RECEIPTS_COUNT_TRIGGER,
  SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER,
  SINGLE_RECEIPT_TRIGGER,
} from '@esentai/core/features/campaigns/purchaseResult/consts/triggers';
import { includes } from 'ramda';
import { array, boolean, date, number, object, Reference, string } from 'yup';

import { cleanUpValues } from './utils';

const requiredForPrePush = creator =>
  creator().when(SEND_PRE_PUSH_PUSH_KEY, {
    is: true,
    then: creator().required(),
    otherwise: creator().nullable(),
  });
const requiredForPush = creator =>
  creator().when(SEND_PUSH_KEY, {
    is: true,
    then: creator().required(),
    otherwise: creator().nullable(),
  });
const requiredForTrigger = (triggerType, creator) =>
  creator().when(TRIGGERS_KEY, {
    is: includes(triggerType),
    then: creator().required(),
    otherwise: creator().nullable(),
  });
const requiredForReward = (rewardType, creator) =>
  creator().when(REWARD_TYPE_KEY, {
    is: rewardType,
    then: creator().required(),
    otherwise: creator().nullable(),
  });

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  // eslint-disable-next-line complexity
  mapPropsToValues: ({
    activateOnce,
    benefitRewardIds,
    dateStart,
    delayAfterPurchase,
    destination,
    enabledTriggers,
    hasDelayAfterPurchase,
    hasTriggers,
    loyaltyPointsReward,
    periodReceipts,
    prePushEnglishTitle,
    prePushRussianTitle,
    prePushEnglishMessage,
    prePushRussianMessage,
    prePushSendDate,
    pushEnglishTitle,
    pushRussianTitle,
    pushEnglishMessage,
    pushRussianMessage,
    receiptsCount,
    referredArtcleId,
    referredBenefitId,
    referredCalendarEventId,
    referredStoreId,
    rewardType,
    sendPrePush,
    sendPushNotifications,
    singleReceipt,
    singleReceiptItemsCount,
    trackedStoreIds,
  }) => ({
    [ACTIVATE_ONCE_KEY]: activateOnce || false,
    [DATE_START_KEY]: dateStart,
    [DELAY_AFTER_PURCHASE_KEY]: delayAfterPurchase || '',
    [DESTINATION_KEY]: destination,
    [HAS_DELAY_AFTER_PURCHASE_KEY]: hasDelayAfterPurchase || false,
    [HAS_TRIGGERS_KEY]: hasTriggers || false,
    [PERIOD_RECEIPTS_KEY]: periodReceipts || '',
    [PRE_PUSH_ENGLISH_TITLE_KEY]: prePushEnglishTitle || '',
    [PRE_PUSH_RUSSIAN_TITLE_KEY]: prePushRussianTitle || '',
    [PRE_PUSH_ENGLISH_MESSAGE_KEY]: prePushEnglishMessage || '',
    [PRE_PUSH_RUSSIAN_MESSAGE_KEY]: prePushRussianMessage || '',
    [PRE_PUSH_SEND_DATETIME_KEY]: prePushSendDate || null,
    [PUSH_ENGLISH_TITLE_KEY]: pushEnglishTitle || '',
    [PUSH_RUSSIAN_TITLE_KEY]: pushRussianTitle || '',
    [PUSH_ENGLISH_MESSAGE_KEY]: pushEnglishMessage || '',
    [PUSH_RUSSIAN_MESSAGE_KEY]: pushRussianMessage || '',
    [RECEIPTS_COUNT_KEY]: receiptsCount || '',
    [REFERRED_ARTICLE_ID_KEY]: referredArtcleId || null,
    [REFERRED_BENEFIT_ID_KEY]: referredBenefitId || null,
    [REFERRED_CALENDAR_EVENT_ID_KEY]: referredCalendarEventId || null,
    [REFERRED_STORE_ID_KEY]: referredStoreId || null,
    [REWARD_BENEFITS_IDS_KEY]: benefitRewardIds || [],
    [REWARD_LOYALTY_POINTS_KEY]: loyaltyPointsReward || '',
    [REWARD_TYPE_KEY]: rewardType || null,
    [SEND_PRE_PUSH_PUSH_KEY]: sendPrePush || false,
    [SEND_PUSH_KEY]: sendPushNotifications || false,
    [SINGLE_RECEIPT_KEY]: singleReceipt || '',
    [SINGLE_RECEIPT_ITEMS_COUNT_KEY]: singleReceiptItemsCount || '',
    [TRACKED_STORE_IDS_KEY]: trackedStoreIds || [],
    [TRIGGERS_KEY]: enabledTriggers || null,
  }),

  validationSchema: object().shape({
    [DATE_START_KEY]: date().required(),
    [DELAY_AFTER_PURCHASE_KEY]: number().when(HAS_DELAY_AFTER_PURCHASE_KEY, {
      is: true,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [DESTINATION_KEY]: string().nullable(),
    [HAS_DELAY_AFTER_PURCHASE_KEY]: boolean(),
    [HAS_TRIGGERS_KEY]: boolean(),
    [PRE_PUSH_ENGLISH_TITLE_KEY]: requiredForPrePush(string).max(60),
    [PRE_PUSH_RUSSIAN_TITLE_KEY]: requiredForPrePush(string).max(60),
    [PRE_PUSH_ENGLISH_MESSAGE_KEY]: requiredForPrePush(string).max(100),
    [PRE_PUSH_RUSSIAN_MESSAGE_KEY]: requiredForPrePush(string).max(100),
    [PRE_PUSH_SEND_DATETIME_KEY]: date().when(SEND_PRE_PUSH_PUSH_KEY, {
      is: true,
      then: date()
        .required()
        .max(new Reference(DATE_START_KEY)),
      otherwise: date().nullable(),
    }),
    [PERIOD_RECEIPTS_KEY]: requiredForTrigger(PERIOD_RECEIPT_TRIGGER, number),
    [PUSH_ENGLISH_TITLE_KEY]: requiredForPush(string).max(60),
    [PUSH_RUSSIAN_TITLE_KEY]: requiredForPush(string).max(60),
    [PUSH_ENGLISH_MESSAGE_KEY]: requiredForPush(string).max(100),
    [PUSH_RUSSIAN_MESSAGE_KEY]: requiredForPush(string).max(100),
    [RECEIPTS_COUNT_KEY]: requiredForTrigger(RECEIPTS_COUNT_TRIGGER, number),
    [REFERRED_ARTICLE_ID_KEY]: number().when(DESTINATION_KEY, {
      is: ARTICLE_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_BENEFIT_ID_KEY]: string().when(DESTINATION_KEY, {
      is: BENEFIT_DESTINATION,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [REFERRED_CALENDAR_EVENT_ID_KEY]: number().when(DESTINATION_KEY, {
      is: CALENDAR_EVENT_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_STORE_ID_KEY]: number().when(DESTINATION_KEY, {
      is: STORE_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REWARD_LOYALTY_POINTS_KEY]: requiredForReward(
      LOYALTY_POINTS_REWARD,
      number,
    ),
    [REWARD_TYPE_KEY]: string().nullable(),
    [SEND_PRE_PUSH_PUSH_KEY]: boolean(),
    [SEND_PUSH_KEY]: boolean(),
    [SINGLE_RECEIPT_KEY]: requiredForTrigger(SINGLE_RECEIPT_TRIGGER, number),
    [SINGLE_RECEIPT_ITEMS_COUNT_KEY]: requiredForTrigger(
      SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER,
      number,
    ),
    [TRIGGERS_KEY]: array().of(string()),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    const cleanValues = cleanUpValues(values);

    props.onSubmit(cleanValues);
    setSubmitting(false);
  },
};

const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
