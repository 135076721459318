import {
  LOCATION_IDS_KEY,
  NAME_KEY,
} from '@esentai/core/features/beacons/consts/keys';
import { LOCATIONS_RELATION } from '@esentai/core/features/beacons/consts/relations';
import { annotations } from '@esentai/core/features/beacons/serializer';
import { anyOf, ascending, query, where } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer, getLinkCreator } from '@/serializers';

import { ROUTE_PATH } from './consts';

const serializer = createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(NAME_KEY),
  }),
  include: string({
    defaultValue: LOCATIONS_RELATION,
  }),
});

export const createLink = getLinkCreator(ROUTE_PATH, serializer);

export const createBeaconsForLocationLink = locationID =>
  createLink(query(where(LOCATION_IDS_KEY, anyOf([[locationID]]))));

export default serializer;
