import {
  DATE_CREATED_KEY,
  ISSUED_AMOUNT_KEY,
} from '@esentai/core/features/giftCertificates/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>Отправитель</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={ISSUED_AMOUNT_KEY}>Сумма сертификата</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={DATE_CREATED_KEY}>Дата и время отправки</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Получатель</CondenseTableCell>

    <CondenseTableCell>Статус получателя</CondenseTableCell>

    <CondenseTableCell>Не использованные бонусы</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
