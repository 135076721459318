import { RECORD_CREATED_KEY } from '@esentai/core/features/mallStats/consts/keys';
import { annotations } from '@esentai/core/features/mallStats/serializer';
import { descending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    // defaultValue: [{}],
    defaultValue: descending(RECORD_CREATED_KEY),
  }),
});
