import {
  FILTERS_KEY,
  SEGMENT_TYPE_KEY,
  TITLE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import {
  ARTICLE_TYPE,
  SHOW_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import {
  ARTICLE_DESTINATION,
  SHOW_DESTINATION,
} from '@esentai/core/features/campaigns/notification/consts/destinations';
import {
  DESTINATION_KEY,
  INITIAL_SEND_DATETIME,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_KAZAKH_MESSAGE_KEY,
  PUSH_KAZAKH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_SHOW_ID_KEY,
  REFERRED_STORE_ID_KEY,
  SEND_SCHEDULE_KEY,
} from '@esentai/core/features/campaigns/notification/consts/keys';
import api from '@esentai/core/features/users/api';
import { cloneQuery } from '@esentai/core/queries';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Person } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

import AsyncButton from '@/components/AsyncButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import DateTimePicker from '@/components/DateTimePicker';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FilterBar from '@/components/FilterBar';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import {
  CanEdit,
  CanEditRadioBoxGroup,
} from '@/features/campaignCreatePage/EditPage/CanEdit';
import CampaignInlineSearch from '@/features/campaignCreatePage/EditPage/SecondStepBanner/CampaignInlineSearch';
import Preview from '@/features/campaignCreatePage/EditPage/SecondStepNotification/PushForm/Preview';
import { filterTypes } from '@/features/campaignCreatePage/EditPage/ThirdStep/filters';
import { SHOULD_SUBMIT_AFTER_SAVE_KEY } from '@/features/campaignCreateUserFilterPage/consts/keys';
import { getFilters } from '@/features/campaignCreateUserFilterPage/selectors';
import { ROUTE_PATH as USERS_PATH } from '@/features/usersPage/consts';
import FormikPropTypes from '@/prop-types/formik';
import { removeFilters, updateFilters } from '@/redux/query/utils';

const fieldNames = {
  [TITLE_KEY]: 'название кампании',
  [INITIAL_SEND_DATETIME]: 'дата отправки сообщения',
  [PUSH_ENGLISH_TITLE_KEY]: 'заголовок пуш-уведомления на английском',
  [PUSH_RUSSIAN_TITLE_KEY]: 'заголовок пуш-уведомления на русском',
  [PUSH_ENGLISH_MESSAGE_KEY]: 'описание пуш-уведомления на английском',
  [PUSH_RUSSIAN_MESSAGE_KEY]: 'описание пуш-уведомления на русском',
  [REFERRED_ARTICLE_ID_KEY]: 'ссылка на статью',
  [REFERRED_BENEFIT_ID_KEY]: 'ссылка на бенефит',
  [REFERRED_CALENDAR_EVENT_ID_KEY]: 'ссылка на календарное событие',
  [REFERRED_STORE_ID_KEY]: 'ссылка на магазин',
  [REFERRED_SHOW_ID_KEY]: 'ссылка на онлайн витрину',
};

const CampaignCreateUserFilterPage = ({
  classes,
  Form,
  Field,
  setFieldValue,
  values,
  handleSubmit,
  SubmitButton,
  RequiredMessages,
  state,
}) => {
  const destination = values[DESTINATION_KEY];
  const filters = [
    ...values[FILTERS_KEY].get('filters').entries(),
  ].map(([key, value]) => ({ key, value }));

  const [users, setUsers] = useState(0);
  const initialFilters = getFilters(state);
  const [userFilter, setUserFilter] = useState({
    isUser: false,
    userId: '',
  });

  const renderPreview = () => (
    <Preview
      values={{
        [PUSH_RUSSIAN_TITLE_KEY]: values[PUSH_RUSSIAN_TITLE_KEY],
        [PUSH_RUSSIAN_MESSAGE_KEY]: values[PUSH_RUSSIAN_MESSAGE_KEY],

        [PUSH_ENGLISH_TITLE_KEY]: values[PUSH_ENGLISH_TITLE_KEY],
        [PUSH_ENGLISH_MESSAGE_KEY]: values[PUSH_ENGLISH_MESSAGE_KEY],

        [PUSH_KAZAKH_TITLE_KEY]: values[PUSH_KAZAKH_TITLE_KEY],
        [PUSH_KAZAKH_MESSAGE_KEY]: values[PUSH_KAZAKH_MESSAGE_KEY],
      }}
    />
  );

  const setReferredCampaignId = (key, value) => {
    setFieldValue(key, value);
  };

  const handleRemoveFilters = keys => {
    setFieldValue(FILTERS_KEY, removeFilters(values[FILTERS_KEY], keys));
  };

  const handleUpdateFilters = updates => {
    setFieldValue(FILTERS_KEY, updateFilters(values[FILTERS_KEY], updates));
  };

  const updateUsers = () => {
    const queryFilters = [];

    for (const [filter, entry] of values[FILTERS_KEY].get(
      'filters',
    ).entries()) {
      for (const [key, value] of entry.entries()) {
        const v = value;

        queryFilters.push([filter, key, v]);
      }
    }
    api.doQuery(queryFilters).then(data => setUsers(data.meta.total));
  };

  useEffect(() => {
    updateUsers();
  }, [filters]);

  useEffect(() => {
    if (initialFilters.size > 0) {
      const queryClone = cloneQuery(values[FILTERS_KEY]);

      queryClone.set('filters', initialFilters);
      setFieldValue(FILTERS_KEY, queryClone);

      if (
        initialFilters.size === 1 &&
        initialFilters.has('id') &&
        initialFilters.get('id').has('in')
      ) {
        const userIds = initialFilters.get('id').get('in');

        if (Array.isArray(userIds) && userIds.length === 1) {
          const userId = userIds[0];

          if (Number.isInteger(userId) && userId > 0) {
            setUserFilter({ isUser: true, userId });
          }
        }
      }
    }
  }, [initialFilters]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs>
          <Breadcrumb to={`${USERS_PATH}/${state.router.location.search}`}>
            Пользователи
          </Breadcrumb>
          {userFilter.isUser ? (
            <Breadcrumb to={`${USERS_PATH}/${userFilter.userId}`}>
              Пользователь #{userFilter.userId}
            </Breadcrumb>
          ) : null}
        </Breadcrumbs>

        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>
            Создание кампании с фильтром
          </PageTitle>
        </Grid>
      </PageHeader>
      <PageContent className={classes.pushFormSegment}>
        <PaperSheet className={classes.paper}>
          <div>
            <Form onSubmit={handleSubmit}>
              <FieldRow label="Описание">
                <Field
                  Target={TextField}
                  label="Название кампании"
                  helperText="Название кампании будет отображаться только для вас и модераторов в списке кампаний"
                  name={TITLE_KEY}
                  symbolsMax={200}
                  margin="none"
                  className={classes.titleField}
                />
              </FieldRow>
              <FieldRow
                label="Пуш-сообщение"
                subLabel="Пуш-сообщения"
                sudDesc="Это сообщение будет отправляться пользователю по указанному расписанию."
                Aside={renderPreview}
              >
                <div>
                  <div className={classes.pushFormSegment}>
                    <FieldDescription
                      title="Заголовок сообщения"
                      desc="Заголовок сообщения, которое увидит пользователь. Не более 60 символов."
                    />
                    <CanEdit field={PUSH_RUSSIAN_TITLE_KEY}>
                      <Field
                        Target={TextField}
                        name={PUSH_RUSSIAN_TITLE_KEY}
                        helperText="На русском"
                        fullWidth
                      />
                    </CanEdit>
                    <CanEdit field={PUSH_ENGLISH_TITLE_KEY}>
                      <Field
                        Target={TextField}
                        name={PUSH_ENGLISH_TITLE_KEY}
                        helperText="На английском"
                        fullWidth
                      />
                    </CanEdit>
                    <CanEdit field={PUSH_KAZAKH_TITLE_KEY}>
                      <Field
                        Target={TextField}
                        name={PUSH_KAZAKH_TITLE_KEY}
                        helperText="На казахском"
                        fullWidth
                      />
                    </CanEdit>
                  </div>

                  <div className={classes.pushFormSegment}>
                    <FieldDescription
                      title="Дата отправки сообщения"
                      desc="Обратите внимание! Технически невозможно отправить сообщения большому количеству пользователей одновременно. Поэтому сообщения будут рассылаться в течение нескольких часов начиная с указанного времени."
                    />
                    <CanEdit field={SEND_SCHEDULE_KEY}>
                      <Field
                        clearable
                        disablePast
                        Target={DateTimePicker}
                        helperText="Точный день, в который будет разослано сообщение"
                        name={INITIAL_SEND_DATETIME}
                      />
                    </CanEdit>
                  </div>

                  <div className={classes.pushFormSegment}>
                    <FieldDescription
                      title="Описание"
                      desc="Текст сообщения, которое пользователь увидит в списке уведомлений. Не более 150 символов."
                    />
                    <CanEdit field={PUSH_RUSSIAN_MESSAGE_KEY}>
                      <Field
                        Target={TextField}
                        name={PUSH_RUSSIAN_MESSAGE_KEY}
                        helperText="На русском"
                        fullWidth
                      />
                    </CanEdit>
                    <CanEdit field={PUSH_ENGLISH_MESSAGE_KEY}>
                      <Field
                        Target={TextField}
                        name={PUSH_ENGLISH_MESSAGE_KEY}
                        helperText="На английском"
                        fullWidth
                      />
                    </CanEdit>
                    <CanEdit field={PUSH_KAZAKH_MESSAGE_KEY}>
                      <Field
                        Target={TextField}
                        name={PUSH_KAZAKH_MESSAGE_KEY}
                        helperText="На казахском"
                        fullWidth
                      />
                    </CanEdit>
                  </div>
                </div>
              </FieldRow>
              <FieldRow
                label="Точка назначения"
                subLabel="Является ли пуш-сообщение ссылкой?"
                sudDesc="Пуш-сообщение может быть просто рекламным сообщением, отображающим какую-то информацию самодостаточно само по себе. Либо оно может вести на статью или экран магазина."
              >
                <CanEdit field={DESTINATION_KEY}>
                  <FormControlLabel
                    label="Пуш-сообщение ссылается на другую сущность в приложении"
                    control={
                      <Checkbox
                        checked={Boolean(destination)}
                        onChange={() =>
                          setFieldValue(
                            DESTINATION_KEY,
                            destination ? null : ARTICLE_DESTINATION,
                          )
                        }
                      />
                    }
                  />
                </CanEdit>

                {destination ? (
                  <div>
                    <FieldDescription
                      title="Точка назначения пуш-сообщения"
                      desc="Укажите, куда именно ссылается баннер."
                    />
                    <CanEditRadioBoxGroup field={REFERRED_STORE_ID_KEY}>
                      <RadioBoxGroup
                        name={REFERRED_STORE_ID_KEY}
                        value={destination}
                        onChange={e =>
                          setFieldValue(DESTINATION_KEY, e.target.value)
                        }
                      >
                        <RadioBox
                          value={ARTICLE_DESTINATION}
                          label="Статья"
                          helperText="Баннер будет ссылаться на другое, уже существующее и опубликованное, рекламное размещение-статью."
                        />
                        <RadioBox
                          value={SHOW_DESTINATION}
                          label="Онлайн витрина"
                          helperText="Баннер будет ссылаться на другое, уже существующую и опубликованную, онлайн витрину."
                        />
                      </RadioBoxGroup>
                    </CanEditRadioBoxGroup>
                    <CanEdit field={REFERRED_STORE_ID_KEY}>
                      {destination === ARTICLE_DESTINATION ? (
                        <CampaignInlineSearch
                          type={ARTICLE_TYPE}
                          values={values}
                          onSelect={setReferredCampaignId}
                        />
                      ) : null}

                      {destination === SHOW_DESTINATION ? (
                        <CampaignInlineSearch
                          type={SHOW_TYPE}
                          values={values}
                          onSelect={setReferredCampaignId}
                        />
                      ) : null}
                    </CanEdit>
                  </div>
                ) : null}
              </FieldRow>
              <FieldRow
                label="Сегмент"
                subLabel="Фильтр сегмента"
                sudDesc="Вы можете указать фильтры, которые отделят пользователей с определенными характеристиками. К примеру, пользователей, обладающих Silver статусом, либо мужчин старше 35 лет"
              >
                <CanEdit field={SEGMENT_TYPE_KEY}>
                  <FilterBar
                    filterTypes={filterTypes}
                    filters={filters}
                    removeFilters={handleRemoveFilters}
                    updateFilters={handleUpdateFilters}
                  />
                </CanEdit>
                <div className={classes.pushFormSegment}>
                  <FieldDescription
                    title="Количество пользователей"
                    desc="Общее количество пользователей, для которых будет предназначена кампания"
                  />
                  <Grid container direction="row" alignItems="center">
                    <Grid>
                      <Person className={classes.userIcon} />
                    </Grid>
                    <Grid className={classes.usersCount}>{users}</Grid>
                  </Grid>
                </div>
              </FieldRow>

              <FieldRow
                label="Запуск"
                subLabel="Запустить кампанию после сохранения?"
                sudDesc="Без включения этой настройки после нажатия кнопки «Сохранить» кампания будет перенесена в черновики."
              >
                <FormControlLabel
                  label="Запустить кампанию немедленно"
                  control={
                    <Checkbox
                      checked={Boolean(values[SHOULD_SUBMIT_AFTER_SAVE_KEY])}
                      onChange={event => {
                        setFieldValue(
                          SHOULD_SUBMIT_AFTER_SAVE_KEY,
                          event.target.checked,
                        );
                      }}
                    />
                  }
                />
              </FieldRow>
              <FieldRow>
                <div className={classes.footer}>
                  <SubmitButton
                    Target={AsyncButton}
                    type="submit"
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    {values[SHOULD_SUBMIT_AFTER_SAVE_KEY]
                      ? 'Запустить кампанию'
                      : 'Сохранить черновик'}
                  </SubmitButton>
                  <RequiredMessages
                    fieldNames={fieldNames}
                    className={classes.requiredMessages}
                  />
                </div>
              </FieldRow>
            </Form>
          </div>
        </PaperSheet>
      </PageContent>
    </Page>
  );
};

CampaignCreateUserFilterPage.propTypes = FormikPropTypes;

CampaignCreateUserFilterPage.defaultProps = {
  state: { users: 0 },
};

export default CampaignCreateUserFilterPage;
