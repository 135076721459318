export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  tableBlock: {
    marginTop: 20,
    minHeight: 500,
    overflowX: 'auto',
  },
  buttonsWrapper: {
    height: 0,
    position: 'relative',
    top: 12,
    float: 'right',
  },
  excelButton: {
    marginTop: 15,
  },
};
