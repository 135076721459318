import { Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import { CommissarAutocomplete } from '@/containers/CommissarAutocomplete';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

class AddBonusesForm extends Component {
  onChangeCommissar = userId =>
    this.props.setFieldValue('commissar_id', userId);

  render() {
    const {
      Form,
      Field,
      SubmitButton,
      RequiredMessages,
      errors,
      values,
      onClose,
    } = this.props;

    return (
      <Form>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Field
              Target={TextField}
              id="bonuses"
              name="bonuses"
              label="Введите сумму бонусов"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Field
              Target={TextField}
              id="comment"
              name="comment"
              label="Комментарий"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              rowsMax={4}
            />
          </Grid>
          <Grid item>
            <CommissarAutocomplete
              name="user_id"
              id="user_id"
              onChange={this.onChangeCommissar}
              selectedItem={values.commissar_id}
              InputProps={{
                error: errors.commissar_id,
                helperText: errors.commissar_id,
                placeholder: 'Комплаенс Менеджер',
              }}
            />
          </Grid>
          <Grid item>
            <ButtonSet>
              <Button onClick={onClose} size={'large'}>
                Отменить
              </Button>
              <SubmitButton
                Target={AsyncButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                Отправить
              </SubmitButton>
            </ButtonSet>
          </Grid>
          <Grid item>
            <RequiredMessages
              fieldNames={{
                bonuses: 'сумма бонусов',
                comment: 'комментарий',
                commissar_id: 'Комплаенс Менеджер',
              }}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

AddBonusesForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default AddBonusesForm;
