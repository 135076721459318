import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Link from '@/components/Link';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';
import { downloadAllUsers, downloadXls } from '@/features/usersPage/utils';

import Table from './Table';

class Users extends Component {
  static propTypes = {
    error: PropTypes.any,
    usersIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const {
      error,
      usersIds,
      isLoading,
      classes,
      totalCount,
      history,
    } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Пользователи</PageTitle>
          <PageSubtitle>Вспомогательный текст</PageSubtitle>
        </PageHeader>
        <PageContent className={classes.tableBlock}>
          <div className={classes.buttonsWrapper}>
            <div className={classes.reportWrapper}>
              <Link to={`/campaigns/new-filter${history.location.search}`}>
                <Button
                  variant="outlined"
                  color="default"
                  size="small"
                  className={classes.button}
                >
                  Отправить Push уведомление
                </Button>
              </Link>
              <Button
                variant="outlined"
                color="default"
                size="small"
                className={classes.button}
                onClick={downloadAllUsers}
              >
                Вывести всех
              </Button>
              <Button
                variant="outlined"
                color="default"
                size="small"
                className={classes.button}
                onClick={downloadXls}
              >
                Вывести в EXCEL
              </Button>
            </div>
          </div>
          <Table
            error={error}
            items={usersIds}
            isLoading={isLoading}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default Users;
