import { ArrowDownward } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';

const MoveDownButton = ({ isVisible, ...props }) => (
  <ActionButton
    text="Опустить ниже"
    button={AsyncButton}
    icon={ArrowDownward}
    disabled={isVisible}
    {...props}
  />
);

MoveDownButton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default MoveDownButton;
