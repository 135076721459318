export default {
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16,
  },

  closeIcon: {
    marginLeft: 'auto',
  },

  filterContent: {
    padding: [16, 24],
  },
  filterContentOverflow: {
    padding: [16, 24],
    overflowY: 'scroll',
  },
  paper: {
    overflow: 'inherit',
  },
  paperOverflow: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
};
