import { compose, prop } from 'ramda';

import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';

const getParams = createMatchSelector(ROUTE_PATH);

export const getLocationId = compose(
  prop('locationId'),
  prop('params'),
  getParams,
);
