import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AutocompleteMultiple from '@/components/AutocompleteMultiple';

class MultiSelect extends Component {
  render() {
    const { onChange, options, inputProps, value, classes } = this.props;

    return (
      <div className={classes.root}>
        <AutocompleteMultiple
          isOpenOnFocus
          options={options}
          selectedItem={value}
          onChange={onChange}
          InputProps={{
            fullWidth: true,
            placeholder: 'Добавить фильтр',
            ...inputProps,
          }}
        />
      </div>
    );
  }
}

MultiSelect.defaultProps = {
  inputProps: {},
};

MultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputProps: PropTypes.object,
};

export default MultiSelect;
