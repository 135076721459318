import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import NotFoundIcon from './notFoundIcon.svg';

const NoMatchMessage = ({ classes, columnsCount, title, subtitle }) => (
  <TableRow>
    <TableCell className={classes.root} colSpan={columnsCount}>
      <Grid container justify="center">
        <Grid item className={classes.container}>
          <NotFoundIcon className={classes.repairIcon} />
          <Typography
            variant="h4"
            color="textSecondary"
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </TableCell>
  </TableRow>
);

NoMatchMessage.propTypes = {
  columnsCount: PropTypes.number.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

NoMatchMessage.defaultProps = {
  title: 'Ничего не найдено',
  subtitle:
    'По вашему запросу ничего не найдено, пожалуйста измените критерии поиска',
};

export default NoMatchMessage;
