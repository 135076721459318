import { CancelToken, isCancel } from 'axios';
import { buffers, END, eventChannel } from 'redux-saga';
import {
  call,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { uploadFailure, uploadProgress, uploadSuccess } from './actions';
import apiResource from './api';
import { getFileObjectForFile } from './selectors';
import { UPLOAD_CANCEL, UPLOAD_RETRY, UPLOAD_START } from './types';

export const createUploadFileChannel = (
  uploadApiMethod,
  { file, id, preview, url },
) =>
  eventChannel(emitter => {
    const onUploadProgress = progressEvent => {
      const progress = progressEvent.loaded / progressEvent.total;

      emitter(uploadProgress({ id, progress }));
    };

    const source = CancelToken.source();

    uploadApiMethod({
      url,
      file,
      onUploadProgress,
      cancelToken: source.token,
    })
      .then(response => {
        emitter(uploadSuccess({ id, url: preview, response }));
        emitter(END);
      })
      .catch(error => {
        if (!isCancel(error)) {
          emitter(uploadFailure({ id, error }));
        }
        emitter(END);
      });

    return () => source.cancel('closed channel');
  }, buffers.sliding(2));

export function* upload({ url, file, preview, id }) {
  try {
    const channel = yield call(createUploadFileChannel, apiResource.upload, {
      id,
      file,
      preview,
      url,
    });

    while (true) {
      const action = yield take(channel);

      yield put(action);
    }
  } catch (error) {
    yield put(uploadFailure({ id, error }));
  }
}

export function* cancelableUpload({ payload: files }) {
  const [{ id, file }] = files;

  try {
    const { upload_url: url, download_url: preview } = yield call(
      apiResource.getUrl,
      id,
    );

    yield race([
      yield call(upload, { id, file, preview, url }),
      yield take(`${UPLOAD_CANCEL}/${id}`),
    ]);
  } catch (error) {
    yield put(uploadFailure({ id, error }));
  }
}

export function* retryUpload({ payload: fileId }) {
  const file = yield select(getFileObjectForFile, fileId);

  yield call(cancelableUpload, {
    payload: [
      {
        id: fileId,
        file,
      },
    ],
  });
}

export function* watchRetryUpload() {
  yield takeEvery(UPLOAD_RETRY, retryUpload);
}

export function* watchUpload() {
  yield takeEvery(UPLOAD_START, cancelableUpload);
}

export default function*() {
  yield spawn(watchUpload);
  yield spawn(watchRetryUpload);
}
