import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/beaconsPage/ducks/query';
import {
  getBeaconsError,
  getBeaconsIds,
  getBeaconsTotalCount,
  isLoadingBeacons,
} from '@/features/beaconsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import Beacons from './component';

const mapStateToProps = state => ({
  error: getBeaconsError(state),
  itemsIds: getBeaconsIds(state),
  totalCount: getBeaconsTotalCount(state),
  isLoading: isLoadingBeacons(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(Beacons);
