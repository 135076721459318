import { TableRow } from '@material-ui/core';
// import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
// import {
//   ACTIVE_BONUSES_KEY,
//   BONUS_PERCENTAGE_KEY,
//   CUSTOMER_ID_KEY,
//   ID_KEY,
//   ISSUED_AT_DATETIME_KEY,
//   KKM_KEY,
//   LEVEL_KEY,
//   PENDING_BONUSES_KEY,
//   RECORD_CREATED_KEY,
//   REFUND_DATETIME_KEY,
//   STAFF_FULLNAME_KEY,
//   STORE_NAME_KEY,
//   TIMESTAMP_KEY,
//   TOTAL_COST_KEY,
//   UUID_KEY,
// } from '@/features/purchaseWithoutScanPage/consts/keys';

// import { ROUTE_PATH } from '@/features/ipnReportsPage/consts';

const HeadRow = () => (
  // const [sortFields, setSortFields] = useState({
  //   [ID_KEY]: [{}],
  // });

  // const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  // const currentUrlParams = new URLSearchParams(window.location.search);

  // const changeFilter = fieldName => {
  //   Object.keys(initialSortFields).forEach(field => {
  //     initialSortFields[field] = [{}];
  //   });

  //   const changedField = fieldName[0];

  //   initialSortFields[changedField.field] = [changedField];

  //   if (changedField.direction === 'asc') {
  //     currentUrlParams.set('sort', `${changedField.field}`);
  //   } else {
  //     currentUrlParams.set('sort', `-${changedField.field}`);
  //   }

  //   props.history.push({
  //     pathname: ROUTE_PATH,
  //     search: `?${decodeURIComponent(currentUrlParams.toString())}`,
  //   });

  //   setSortFields(initialSortFields);
  // };

  // useEffect(() => {
  //   const field = new URLSearchParams(window.location.search).get('sort');

  //   if (field) {
  //     const fieldName = field.replace(/-/g, '');

  //     initialSortFields[fieldName] = [
  //       {
  //         field: fieldName,
  //         direction: field.indexOf('-') !== -1 ? 'desc' : 'asc',
  //       },
  //     ];
  //     setSortFields(initialSortFields);
  //   }
  // }, []);

  <TableRow>
    <CondenseTableCell>
      {/* <SortControl
          sort={sortFields[ID_KEY]}
          field={ID_KEY}
          setSort={changeFilter}
        >
          ID
        </SortControl> */}
      ID
    </CondenseTableCell>
    <CondenseTableCell>
      {/* <SortControl
          sort={sortFields[CUSTOMER_ID_KEY]}
          field={CUSTOMER_ID_KEY}
          setSort={changeFilter}
        >
          ФИО в Системе
        </SortControl> */}
      ФИО в Системе
    </CondenseTableCell>
    <CondenseTableCell>
      {/* <SortControl
          sort={sortFields[LEVEL_KEY]}
          field={LEVEL_KEY}
          setSort={changeFilter}
        >
          ФИО настоящий
        </SortControl> */}
      ФИО настоящий
    </CondenseTableCell>
    <CondenseTableCell>
      {/* <SortControl
          sort={sortFields[KKM_KEY]}
          field={KKM_KEY}
          setSort={changeFilter}
        >
          ИИН в системе
        </SortControl> */}
      ИИН в системе
    </CondenseTableCell>
    <CondenseTableCell>
      {/* <SortControl
          sort={sortFields[UUID_KEY]}
          field={UUID_KEY}
          setSort={changeFilter}
        >
          ИИН настоящий
        </SortControl> */}
      ИИН настоящий
    </CondenseTableCell>
    <CondenseTableCell>
      {/* <SortControl
          sort={sortFields[STORE_NAME_KEY]}
          field={STORE_NAME_KEY}
          setSort={changeFilter}
        >
          Начислено доходов
        </SortControl> */}
      Начислено доходов
    </CondenseTableCell>
    <CondenseTableCell>
      {/* <SortControl
          sort={sortFields[TIMESTAMP_KEY]}
          field={TIMESTAMP_KEY}
          setSort={changeFilter}
        >
          ИИН подлежащий уплате в бюджет
        </SortControl> */}
      ИИН подлежащий уплате в бюджет
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  // SortControl: PropTypes.func.isRequired,
  // history: PropTypes.object.isRequired,
};

export default HeadRow;
