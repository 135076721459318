import { CUSTOMER_KEY } from '@esentai/core/features/purchases/consts/keys';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, select, spawn } from 'redux-saga/effects';

import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';
import purchasesDuck from './ducks/purchases';
import queryDuck from './ducks/query';

export const {
  synchronizeQuery,
  createQuerySaga,
  createQueryChangeWatcher,
} = queryDuck.sagas;

const { getQuery } = queryDuck.selectors;

export const fetchReceipts = function*() {
  const { params } = yield select(createMatchSelector({ path: ROUTE_PATH }));
  const { userId } = params;
  const query = yield select(getQuery);
  const newQuery = mergeQueriesRight(
    query,
    makeQuery(where(CUSTOMER_KEY, equals(userId))),
  );

  yield call(purchasesDuck.sagas.fetchItems, newQuery);
};

export const watchQueryChange = createQueryChangeWatcher(fetchReceipts);

export default function*() {
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
