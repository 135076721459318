import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getProductsError,
  getItemsIds: getProductIds,
  getItemsMeta: getProductsMeta,
  getItemsTotalCount: getProductsTotalCount,
  isLoadingItems: isLoadingProducts,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
