import PropTypes from 'prop-types';
import React from 'react';

import CampaignTitleLazy from '@/containers/CampaignTitleLazy';

const FormatComponent = ({ value }) => <CampaignTitleLazy id={value} />;

FormatComponent.propTypes = {
  value: PropTypes.number.isRequired,
};

export default FormatComponent;
