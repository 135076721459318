import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { downloadXls } from '@/features/reconciliationStoreBonusDetailsPage/utils';

import { ROUTE_PATH as RECONCILIATIONS_PATH } from '../../reconciliationStoreToCpPage/consts';
import Table from './Table';

class ReconciliationStoreToCp extends Component {
  static propTypes = {
    error: PropTypes.any,
    reconciliationIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    storeId: PropTypes.number.isRequired,
    storeName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const {
      error,
      reconciliationIds,
      isLoading,
      totalCount,
      classes,
      storeId,
      storeName,
    } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={RECONCILIATIONS_PATH}>
              Бонусы от магазина
            </Breadcrumb>
          </Breadcrumbs>
          <PageTitle gutterBottom={false}>
            Реконсиляция, детализация бонусов {storeName}
          </PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.excelButton}
              onClick={() => {
                downloadXls(storeId, storeName);
              }}
            >
              Вывести в Excel
            </Button>
            <Table
              error={error}
              items={reconciliationIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default ReconciliationStoreToCp;
