import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const sizes = [
  {
    label: 'Произвольный',
    value: null,
  },
  {
    label: 'День',
    value: 1,
  },
  {
    label: 'Неделя',
    value: 7,
  },
  {
    label: 'Две недели',
    value: 14,
  },
  {
    label: '30 дней',
    value: 30,
  },
  {
    label: '90 дней',
    value: 90,
  },
];

const SizesList = ({ classes, onChange, value }) => (
  <div className={classes.root}>
    {sizes.map(size => (
      <MenuItem
        dense
        selected={size.value === value}
        key={size.label}
        onClick={() => onChange(size.value)}
      >
        {size.label}
      </MenuItem>
    ))}
  </div>
);

SizesList.defaultProps = {
  value: null,
};

SizesList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default SizesList;
