import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { canUpdatePersonnel } from '@/features/personnel/permissions';
import { createEditPersonnelLink } from '@/features/personnelEditPage/utils';

const mapStateToProps = (state, { personnelId }) => ({
  editPageUrl: createEditPersonnelLink(personnelId),
  permission: canUpdatePersonnel,
});

export default connect(mapStateToProps)(EditButton);
