import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import EditButton from './EditButton';

const Actions = ({ classes, storeId }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <EditButton storeId={storeId} />
  </Grid>
);

Actions.propTypes = {
  storeId: PropTypes.number.isRequired,
};

export default Actions;
