import { Button } from '@material-ui/core';
import { subDays } from 'date-fns';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';

import Dropdown from '@/components/Dropdown';
import { formatShortHumanDate } from '@/utils/format';

import Popup from './Popup';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

class SelectDropdown extends Component {
  static propTypes = {
    minDate: PropTypes.instanceOf(Date).isRequired,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
  };

  state = {
    rangeSize: null,
    value: this.props.value,
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (value !== prevProps.value) {
      this.updateStagingValue(value);
    }
  }

  renderTrigger = ({ label, ref, ...rest }) => (
    <Button variant="outlined" buttonRef={ref} {...rest}>
      {label}
    </Button>
  );

  renderPopup = ({ isOpen, onClose, ...popoverProps }) => {
    const { minDate, maxDate } = this.props;
    const { rangeSize, value } = this.state;

    return (
      <Popup
        popoverProps={{
          anchorOrigin,
          ...popoverProps,
          open: isOpen,
          onClose,
        }}
        sizesProps={{
          value: rangeSize,
          onChange: this.setRangeSize,
        }}
        calendarProps={{
          minDate,
          maxDate,
          value,
          onChange: this.updateStagingValueFromCalendar,
        }}
        submitChange={() => {
          this.submitChange();
          onClose();
        }}
      />
    );
  };

  render() {
    const { value, ...rest } = this.props;
    const { from, to } = value;
    const label = `${formatShortHumanDate(from)} – ${formatShortHumanDate(to)}`;

    return (
      <Dropdown
        label={label}
        renderTrigger={this.renderTrigger}
        renderPopup={this.renderPopup}
        {...omit(['onChange', 'minDate', 'maxDate'], rest)}
      />
    );
  }

  setRangeSize = rangeSize => {
    this.setState({
      rangeSize,
    });

    if (rangeSize) {
      const { maxDate } = this.props;
      const rangeStart = subDays(maxDate, rangeSize);

      this.updateStagingValue({ from: rangeStart, to: maxDate });
    }
  };

  updateStagingValue = value => this.setState({ value });

  updateStagingValueFromCalendar = value =>
    this.setState({ rangeSize: null, value });

  submitChange = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    onChange(value);
  };
}

export default SelectDropdown;
