import api from '@esentai/core/features/merchantsReconciliationReceipts/api';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { downloadXls } from '@/features/merchantsReconciliationReceiptsPage/utils';
import { formatNumber } from '@/utils/format';

import Table from './Table';

class ReconciliationReceipts extends Component {
  constructor(props) {
    super(props);

    this.state = { totalBonuses: 0 };
  }

  static propTypes = {
    error: PropTypes.any,
    reconciliationIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    merchantId: PropTypes.string,
    archiveId: PropTypes.string,
  };

  static defaultProps = {
    error: null,
    merchantId: null,
    archiveId: null,
  };

  componentDidMount() {
    this.getTotalBonusCost();
  }

  getTotalBonusCost() {
    const filters = this.props.merchantId
      ? [['merchant_id', 'eq', this.props.merchantId]]
      : [['reconciliation_id', 'eq', this.props.archiveId]];

    filters.push(['total_sum', 'eq', true]);
    api
      .doQuery(filters)
      .then(res => this.setState({ totalBonuses: res.total_bonus_cost }));
  }

  render() {
    const {
      error,
      reconciliationIds,
      isLoading,
      totalCount,
      classes,
    } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>
            Сумма потраченных бонусов: {formatNumber(this.state.totalBonuses)}
          </PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.excelButton}
              onClick={() => {
                downloadXls(this.props.merchantId);
              }}
            >
              Вывести в Excel
            </Button>
            <Table
              error={error}
              items={reconciliationIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default ReconciliationReceipts;
