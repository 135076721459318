import { defaultMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/default';
import { efsSalesMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/efsSales';
import { mallAccountantMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/mallAccountant';
import { mallAdministratorMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/mallAdministrator';
import { mallManagerMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/mallManager';
import { mallReceptionMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/mallReception';
import { mallValetMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/mallValet';
import { managingTeamMemberMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/managingTeamMember';
import { storeAdministratorMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/storeAdministrator';
import { storeManagerMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/storeManager';
import { storeSellerMenu } from '@/components/Sidebar/SidebarMenu/MenuRoles/storeSeller';

export const getSections = (
  isMallManager,
  isMallAdministrator,
  isStoreStaff,
  isStoreManager,
  isStoreAdministrator,
  isStoreSeller,
  isMallAccountant,
  isMallValet,
  isEfsSales,
  isMallReception,
  isManagingTeamMember,
  storeAdministratorStores,
) => {
  if (isMallManager) {
    return mallManagerMenu;
  }

  if (isMallAdministrator) {
    return mallAdministratorMenu;
  }

  if (isStoreManager) {
    return storeManagerMenu;
  }

  if (isStoreAdministrator) {
    return storeAdministratorMenu(storeAdministratorStores);
  }

  if (isStoreSeller) {
    return storeSellerMenu;
  }

  if (isMallAccountant) {
    return mallAccountantMenu;
  }

  if (isMallValet) {
    return mallValetMenu;
  }

  if (isEfsSales) {
    return efsSalesMenu;
  }

  if (isMallReception) {
    return mallReceptionMenu;
  }

  if (isManagingTeamMember) {
    return managingTeamMemberMenu;
  }

  return defaultMenu(isStoreStaff);
};
