import { Fab } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React from 'react';

import Link from '@/components/Link';

const AddLink = props => (
  <Fab color="primary" {...props} component={Link}>
    <AddIcon />
  </Fab>
);

export default AddLink;
