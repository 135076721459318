import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TopBar from '@/components/TopBar';

import Legend from './Legend';
import StatSelect from './StatSelect';

class InnerTopBar extends Component {
  render() {
    return (
      <TopBar
        {...this.props}
        LeftComponent={this.renderLeftComponent}
        CenterComponent={Legend}
      />
    );
  }

  renderLeftComponent = props => {
    const { LeftComponent } = this.props;

    return (
      <div>
        <LeftComponent {...props} />
        <StatSelect />
      </div>
    );
  };
}

InnerTopBar.propTypes = {
  LeftComponent: PropTypes.func,
};

InnerTopBar.defaultProps = {
  LeftComponent: () => null,
};

export default InnerTopBar;
