import {
  getActiveForMallBonus,
  getBonusName,
  getCommentForMallBonus,
  getCreationDateForMallBonus,
  getEndDateForMallBonus,
  getFiltersForMallBonus,
  getPercentForMallBonus,
  getStartDateForMallBonus,
  getStoreIds,
  getUserForMallBonus,
} from '@esentai/core/features/mallBonuses/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: mallBonusId }) => ({
  creationDate: getCreationDateForMallBonus(state, mallBonusId),
  percent: getPercentForMallBonus(state, mallBonusId),
  startDate: getStartDateForMallBonus(state, mallBonusId),
  endDate: getEndDateForMallBonus(state, mallBonusId),
  stores: getStoreIds(state, mallBonusId),
  filters: getFiltersForMallBonus(state, mallBonusId),
  user: getUserForMallBonus(state, mallBonusId),
  comment: getCommentForMallBonus(state, mallBonusId),
  status: getActiveForMallBonus(state, mallBonusId),
  bonusName: getBonusName(state, mallBonusId),
  mallBonusId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
