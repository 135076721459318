import {
  getCashMachineFnsIdForSalesTurnoverDetails,
  getCashMachineForSalesTurnoverDetails,
  getIssuedAtForSalesTurnoverDetails,
  getIssuedDateForSalesTurnoverDetails,
  getIssuedTimeForSalesTurnoverDetails,
  getSaleNumberForSalesTurnoverDetails,
  getSaleProductsForSalesTurnoverDetails,
  getStoreForSalesTurnoverDetails,
  getStoreIsActiveForSalesTurnoverDetails,
  getStoreNameForSalesTurnoverDetails,
  getTotalCostForSalesTurnoverDetails,
} from '@esentai/core/features/salesTurnoverDetails/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';

const mapStateToProps = (state, { item: detailsId }) => ({
  cashMachineFns: getCashMachineFnsIdForSalesTurnoverDetails(state, detailsId),
  cashMachine: getCashMachineForSalesTurnoverDetails(state, detailsId),
  store: getStoreForSalesTurnoverDetails(state, detailsId),
  issuedDate: getIssuedDateForSalesTurnoverDetails(state, detailsId),
  issuedAt: getIssuedAtForSalesTurnoverDetails(state, detailsId),
  issuedTime: getIssuedTimeForSalesTurnoverDetails(state, detailsId),
  saleNumber: getSaleNumberForSalesTurnoverDetails(state, detailsId),
  saleProducts: getSaleProductsForSalesTurnoverDetails(state, detailsId),
  isActive: getStoreIsActiveForSalesTurnoverDetails(state, detailsId),
  storeName: getStoreNameForSalesTurnoverDetails(state, detailsId),
  totalCost: getTotalCostForSalesTurnoverDetails(state, detailsId),
});

export default compose(connect(mapStateToProps))(Row);
