import { RESOURCE_NAME as MALL_RECEPTIONS } from '@esentai/core/features/mallReceptions/consts';
import { can, permit } from '@esentai/core/policy-dsl';

import { MALL_RECEPTION } from '@/features/mallReceptionPage/consts/permissions';

import { VIEW_NAVIGATION_BAR } from '../consts/permissions';

export default [
  can(VIEW_NAVIGATION_BAR),
  permit(MALL_RECEPTIONS, [MALL_RECEPTION]),
];
