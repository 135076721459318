export const YesNoFormatter = ({ yes, no }) => ({ value = yes }) => {
  if (value === yes) {
    return 'да';
  }

  if (value === no) {
    return 'нет';
  }

  return null;
};

export const createFormatter = options => ({ value }) => {
  const option = options.find(({ key }) => key === value);

  if (option) {
    return option.label;
  }

  return null;
};
