import { omit } from 'ramda';

import { DEVICE_WIDTH } from './consts';

const single = {
  backgroundColor: '#c7c7c7',
  width: '100%',
  display: 'block',
  height: DEVICE_WIDTH,
  objectFit: 'cover',
};

export default {
  single,

  single_required: {
    height: DEVICE_WIDTH * 1.25,
    marginBottom: 14,
    objectFit: 'cover',
  },

  stack: {
    display: 'flex',
    margin: [0, -10, 12],
  },

  stackHide: {
    display: 'none',
  },

  slider: {
    ...omit(['height'], single),

    width: DEVICE_WIDTH,
    objectFit: 'cover',
    height: 194,
  },

  imageWrapper: {
    margin: [0, -10, 12],
  },
};
