// Metrics
//
// DO NOT USE THESE KEYS FOR FILTERING.
// ONLY DISPLAYING AND SORTING ALLOWED.
//
// If you need fields for filtering, see 'dimensions.js'

export {
  AVG_TICKET_KEY,
  NR_RECEIPTS_KEY,
  PERCENT_RECEIPTS_DAY_KEY,
  PERCENT_RECEIPTS_EVENING_KEY,
  PERCENT_RECEIPTS_MORNING_KEY,
} from '@/features/purchasesStatistics/consts/keys';
