import {
  ACTIVITY_TYPE_KEY,
  BONUSES_KEY,
  CUSTOMER_KEY,
  FIRSTNAME_KEY,
  FULLNAME_KEY,
  ID_KEY,
  LASTNAME_KEY,
  TIMESTAMP_KEY,
  USER_LEVEL_KEY,
} from '@esentai/core/features/loyalties/consts/keys';

import { createActivityTypeFilter } from '@/filters/ActivityType';
import { createDateRangeFilter } from '@/filters/DateRange';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createTextFilter } from '@/filters/SearchInput';
import { createIncludeFilter } from '@/filters/SearchInputs';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createIncludeFilter({
    key: ID_KEY,
    label: 'ID',
  }),
  createIncludeFilter({
    key: CUSTOMER_KEY,
    label: 'ID пользователя',
  }),
  createTextFilter({
    key: FIRSTNAME_KEY,
    label: 'Имя пользователя',
  }),
  createTextFilter({
    key: LASTNAME_KEY,
    label: 'Фамилия пользователя',
  }),
  createTextFilter({
    key: FULLNAME_KEY,
    label: 'ФИО менеджера',
  }),
  createMoneyRangeFilter({
    key: BONUSES_KEY,
    label: 'Количество присвоенных бонусов',
  }),
  createDateRangeFilter({
    key: TIMESTAMP_KEY,
    label: 'Дата и время ответа Комплаенс Менеджера',
  }),
  createActivityTypeFilter({
    key: ACTIVITY_TYPE_KEY,
    label: 'Статус',
  }),
  createUserStatusFilter({
    key: USER_LEVEL_KEY,
    label: 'Уровень',
    quicklyAccessible: true,
  }),
];
