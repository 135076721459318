import React, { Fragment } from 'react';

import ContentForm from './ContentForm';
import Placement from './PlacementForm';
import ProductsForm from './ProductsForm';

const ShowContent = props => (
  <Fragment>
    <Placement {...props} />
    <ContentForm {...props} />
    <ProductsForm {...props} />
  </Fragment>
);

export default ShowContent;
