import React, { Component, Fragment } from 'react';

import Bilingual from '@/components/Bilingual';
import FieldRow from '@/components/FieldRow';
import Preview from '@/features/campaignCreatePage/EditPage/SecondStepArticle/ContentForm/Preview';
import Label from '@/features/campaignPage/Page/Label';
import FormikPropTypes from '@/prop-types/formik';

import Destination from '../Destination';

class Banner extends Component {
  static propTypes = FormikPropTypes;

  renderPreview = () => <Preview {...this.props} />;

  render() {
    const { englishTitle, russianTitle } = this.props;

    return (
      <Fragment>
        <FieldRow label="Наполнение" Aside={this.renderPreview}>
          <Label title="Заголовок баннера">
            <Bilingual
              englishContent={englishTitle}
              russianContent={russianTitle}
            />
          </Label>
        </FieldRow>
        <Destination {...this.props} />
      </Fragment>
    );
  }
}

export default Banner;
