import { ORANGE_STATUS } from '@esentai/core/features/users/consts/statuses';

import { getBarComponent } from './utils';

export const OrangeBar = getBarComponent({
  styles: {
    bar: {
      backgroundColor: '#FFE0AE',
    },
  },
  status: ORANGE_STATUS,
});
