import { withStyles } from '@material-ui/core';
import React from 'react';

import Link from '@/components/Link';

import styles from './styles';

const PageTitleLink = props => <Link variant="h4" {...props} />;

export default withStyles(styles)(PageTitleLink);
