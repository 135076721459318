import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';

import MapWorkspace from './MapWorkspace';

class Map extends Component {
  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    isFinished: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { fetchItems } = this.props;

    fetchItems();
  }

  render() {
    const { isFinished } = this.props;

    if (!isFinished) {
      return null;
    }

    return (
      <SettingsPage>
        <SettingsPageHeader>
          <SettingsPageTitle>Карта</SettingsPageTitle>
        </SettingsPageHeader>
        <SettingsPageContent>
          <MapWorkspace />
        </SettingsPageContent>
      </SettingsPage>
    );
  }
}

export default Map;
