import { deleteParkingLevelThreeWhitelist } from '@esentai/core/features/parkingLevelThreeWhitelist/actions';
import { T } from 'ramda';
import { connect } from 'react-redux';

import RemoveButton from '@/components/ActionButtons/Remove';
import { retryFetch } from '@/features/parkingLevelThreeWhitelistPage/actions';

const mapDispatchToProps = (dispatch, { parkingLevelThreeWhitelistId }) => ({
  onClick: () => {
    dispatch(deleteParkingLevelThreeWhitelist(parkingLevelThreeWhitelistId));
    setTimeout(() => dispatch(retryFetch()), 400);
  },
  permission: T,
});

export default connect(null, mapDispatchToProps)(RemoveButton);
