import {
  BENEFITS_REWARD,
  LOYALTY_POINTS_REWARD,
} from '@esentai/core/features/campaigns/purchaseResult/consts/rewards';

import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [
  {
    key: BENEFITS_REWARD,
    label: 'Бенефит',
  },
  {
    key: LOYALTY_POINTS_REWARD,
    label: 'Бонусы',
  },
];

export const createCampaignRewardTypeFilter = createRadioFilterTemplate({
  initialValue: BENEFITS_REWARD,
  options,
});

export default RadioGroupFilter;
