export const EMAIL_KEY = 'email';

export const STORE_IDS_KEY = 'store_ids';

export const ROLE_KEY = 'role';

export const FULL_NAME_KEY = 'full_name';

export const PASSWORD_KEY = 'password';

export const TOKEN_KEY = 'token';

export const LOCATIONS_IDS_KEY = 'allowed_location_ids';

// TODO the key isn't exist on the backend & should change out
export const ACCESS_TO_STATISTIC_KEY = 'allowed_statistics';
