import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import {
  MAP_X_COORDINATE_KEY,
  MAP_Y_COORDINATE_KEY,
} from '@esentai/core/features/beacons/consts/keys';
import {
  query as queryBeacons,
  update as updateBeacon,
} from '@esentai/core/features/beacons/sagas';
import { query as queryLocations } from '@esentai/core/features/locations/sagas';
import { getRentPlaceLocationId } from '@esentai/core/features/locations/selectors';
import { query } from '@esentai/core/query-dsl';
import { push } from 'connected-react-router';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { clearSelection as clearSelectionAction } from './actions';
import {
  getBeaconIdFromRoute,
  getLocationIdFromRoute,
  getSelectedFloor,
  isBeaconSelected,
  isRentPlaceSelected,
} from './selectors';
import {
  CLEAR_SELECTION,
  FETCH_ITEMS,
  MOVE_BEACON,
  SELECT_BEACON,
  SELECT_FLOOR,
  SELECT_RENT_PLACE,
} from './types';
import { createMapPageUrl } from './utils';

export function* fetchItems() {
  yield all([call(queryBeacons, query()), call(queryLocations, query())]);
}

export function* clearSelection() {
  const floor = yield select(getSelectedFloor);
  const path = yield call(createMapPageUrl, { floor });

  yield put(push(path));
}

export function* moveBeacon(action) {
  const { payload } = action;
  const { beaconId, x, y } = payload;

  yield call(updateBeacon, beaconId, {
    [MAP_X_COORDINATE_KEY]: x,
    [MAP_Y_COORDINATE_KEY]: y,
  });
}

export function* selectFloor(action) {
  const { payload: floor } = action;
  const beaconId = yield select(getBeaconIdFromRoute);
  const locationId = yield select(getLocationIdFromRoute);
  const path = yield call(createMapPageUrl, { beaconId, floor, locationId });

  yield put(push(path));
}

export function* selectBeacon(action) {
  const { payload: beaconId } = action;
  const floor = yield select(getSelectedFloor);
  const selected = yield select(isBeaconSelected, beaconId);

  if (selected) {
    return yield put(clearSelectionAction());
  }

  const path = yield call(createMapPageUrl, { beaconId, floor });

  yield put(push(path));
}

export function* selectRentPlace(action) {
  const { payload: rentPlaceId } = action;
  const selected = yield select(isRentPlaceSelected, rentPlaceId);

  if (selected) {
    return yield put(clearSelectionAction());
  }

  const floor = yield select(getSelectedFloor);
  const locationId = yield select(getRentPlaceLocationId, rentPlaceId);
  const path = yield call(createMapPageUrl, { floor, locationId });

  yield put(push(path));
}

export default function*() {
  yield takeEvery(CLEAR_SELECTION, clearSelection);
  yield takeEvery(FETCH_ITEMS, createTaskSaga(fetchItems));
  yield takeEvery(MOVE_BEACON, createTaskSaga(moveBeacon));
  yield takeEvery(SELECT_BEACON, selectBeacon);
  yield takeEvery(SELECT_FLOOR, selectFloor);
  yield takeEvery(SELECT_RENT_PLACE, selectRentPlace);
}
