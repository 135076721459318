import {
  Button,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateTimePicker } from 'material-ui-pickers';
import React from 'react';

export interface ModalParkingRateProps {
  open: boolean;
  selectedValue: string;
  parkingRate?: number | string;
  parkingRateTower?: number | string;
  date?: string;
  dateTower?: string;
  onClose: (value: string) => void;
  onSubmit: (
    rate: string | number,
    date: string,
    building: 'MALL' | 'TOWER',
  ) => void;
}

export interface ModalParkingState {
  date?: string;
  newParkingRate?: string | number;
  type?: 'TOWER' | 'MALL';
}

export class ModalParkingRate extends React.Component<
  ModalParkingRateProps,
  ModalParkingState
> {
  constructor(props: ModalParkingRateProps) {
    super(props);
    this.state = {
      date: null,
      newParkingRate: this.props.parkingRate.toString(),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.parkingRate !== this.props.parkingRate ||
        prevProps.parkingRateTower !== this.props.parkingRateTower) &&
      this.props.parkingRate > 0 &&
      this.props.parkingRateTower > 0
    ) {
      this.setState({
        newParkingRate: null,
        date: null,
        type: undefined,
      });
    }
  }

  public render(): JSX.Element {
    const {
      onClose,
      selectedValue,
      open,
      parkingRate,
      parkingRateTower,
      onSubmit,
    } = this.props;
    const { date, newParkingRate, type } = this.state;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleChangeType = newType => {
      this.setState({
        type: newType.target.value,
        newParkingRate:
          (newType.target.value === 'MALL' ? parkingRate : parkingRateTower) ||
          0,
      });
    };

    const handleSubmit = () => {
      onSubmit(newParkingRate, date, type);
      onClose(selectedValue);
    };

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        key={`rate_${parkingRate}`}
      >
        <DialogTitle id="simple-dialog-title">
          Изменение тарифа паркинга
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            minWidth: 600,
          }}
        >
          <RadioGroup name="type" row value={type}>
            <FormControlLabel
              onClick={handleChangeType}
              value="MALL"
              label="MALL"
              control={<Radio />}
            />
            <FormControlLabel
              onClick={handleChangeType}
              value="TOWER"
              label="TOWER"
              control={<Radio />}
            />
          </RadioGroup>
          {Boolean(type) && (
            <>
              <TextField
                fullWidth
                label="Введите новый тариф"
                value={newParkingRate}
                type="number"
                aria-valuemin={1}
                onChange={e => {
                  this.setState({ newParkingRate: e.target.value });
                }}
              />
              <DateTimePicker
                clearable
                label="Дата начала нового тарифа"
                format="Pp — cccc"
                onChange={newDate => {
                  this.setState({ date: newDate });
                }}
                value={date}
                minDate={Date.now()}
              />
            </>
          )}

          <Grid container justify="flex-end">
            <Button onClick={handleClose} color="secondary">
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ marginLeft: '5px' }}
              disabled={
                !type ||
                (typeof newParkingRate === 'string'
                  ? parseInt(newParkingRate, 10)
                  : newParkingRate) < 1 ||
                (typeof newParkingRate === 'string'
                  ? parseInt(newParkingRate, 10)
                  : newParkingRate) ===
                  (type === 'MALL' ? parkingRate : parkingRateTower) ||
                !date
              }
            >
              Применить
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}
