import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class UserInfo extends Component {
  render() {
    const { classes, name, logOut, expand, expanded } = this.props;

    return (
      <div className={classes.root}>
        {!expanded && (
          <div>
            {name}
            <Tooltip title="Выход">
              <Button
                color="default"
                size="small"
                className={classes.exitButton}
                onClick={logOut}
              >
                <ExitToAppIcon />
              </Button>
            </Tooltip>
          </div>
        )}

        <Tooltip title={expanded ? 'Открыть' : 'Закрыть'}>
          <ChevronLeftIcon
            className={classNames(classes.icon, {
              [classes.expanded]: expanded,
            })}
            onClick={expand}
          />
        </Tooltip>
      </div>
    );
  }
}

UserInfo.propTypes = {
  name: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  expand: PropTypes.func.isRequired,
};

export default UserInfo;
