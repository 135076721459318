import {
  getPushEnglishMessage,
  getPushEnglishTitle,
  getPushKazakhMessage,
  getPushKazakhTitle,
  getPushRussianMessage,
  getPushRussianTitle,
} from '@esentai/core/features/campaigns/notification/selectors';
import { connect } from 'react-redux';

import Push from './component';

const mapStateToProps = (state, { itemId }) => ({
  pushRussianTitle: getPushRussianTitle(state, itemId),
  pushRussianMessage: getPushRussianMessage(state, itemId),

  pushEnglishTitle: getPushEnglishTitle(state, itemId),
  pushEnglishMessage: getPushEnglishMessage(state, itemId),

  pushKazakhTitle: getPushKazakhTitle(state, itemId),
  pushKazakhMessage: getPushKazakhMessage(state, itemId),
});

export default connect(mapStateToProps)(Push);
