import {
  USER_KEY,
  VEHICLE_GOV_NUMBERS_KEY,
  VERHICLE_MODULES_KEY,
} from '@/features/carListPage/consts/keys';
import { createEqualsFilter, createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createEqualsFilter({
    key: USER_KEY,
    label: 'ID Пользователя',
  }),
  createTextFilter({
    key: VEHICLE_GOV_NUMBERS_KEY,
    label: 'Гос номер авто',
  }),
  createTextFilter({
    key: VERHICLE_MODULES_KEY,
    label: 'Модель авто',
  }),
];

export const filterPrefixes = [
  {
    field: USER_KEY,
    prefix: 'eq',
  },
  {
    field: VEHICLE_GOV_NUMBERS_KEY,
    prefix: 'ilike',
  },
  {
    field: VERHICLE_MODULES_KEY,
    prefix: 'ilike',
  },
];
