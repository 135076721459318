import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import api from '@/api';

import AddBonusesForm from './Form';

class AddBonusesModal extends Component {
  submitData(values) {
    api
      .doPost('/customer-activity-history', {
        customer_activity_history: {
          ...values,
          customer_id: this.props.userId,
          type: 'REWARD BONUSES',
        },
      })
      .then(() => {
        this.props.onClose();
        this.props.fetchItem(this.props.userId);
      });
  }

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">Добавить бонусы</DialogTitle>

        <DialogContent>
          <AddBonusesForm
            onClose={onClose}
            onSubmit={this.submitData.bind(this)}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

AddBonusesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  fetchItem: PropTypes.func.isRequired,
};

export default AddBonusesModal;
