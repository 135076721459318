import { IconButton, InputAdornment } from '@material-ui/core';
import {
  AccessTime,
  CalendarToday,
  Event,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { DateTimePicker as OriginalDateTimePicker } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class DateTimePicker extends Component {
  static defaultProps = {
    name: null,
    onChange: () => null,
    InputProps: {},
  };

  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    InputProps: PropTypes.object,
  };

  handlerChange = value => {
    const { onChange, name } = this.props;

    onChange({ target: { value, name } });
  };

  renderEndAdornment = () => (
    <InputAdornment position="end">
      <IconButton>
        <Event />
      </IconButton>
    </InputAdornment>
  );

  render() {
    const { InputProps } = this.props;

    return (
      <OriginalDateTimePicker
        autoOk
        format="Pp — cccc"
        cancelLabel="Отмена"
        clearLabel="Сбросить"
        ampm={false}
        timeIcon={<AccessTime />}
        dateRangeIcon={<CalendarToday />}
        leftArrowIcon={<KeyboardArrowLeft />}
        rightArrowIcon={<KeyboardArrowRight />}
        {...this.props}
        InputProps={{
          endAdornment: this.renderEndAdornment(),
          ...InputProps,
        }}
        onChange={this.handlerChange}
      />
    );
  }
}

export default DateTimePicker;
