import api from '@esentai/core/features/storeBonuses/api';
import { FILTERS_KEY } from '@esentai/core/features/storeBonuses/consts/keys';
import { query } from '@esentai/core/query-dsl';
import moment from 'moment';
import { object } from 'yup';

import { ROUTE_PATH as STORE_BONUSES_PATH } from '@/features/storeBonusesPage/consts';

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({ filter = query() }) => ({
    all: true,
    [FILTERS_KEY]: filter,
  }),

  validationSchema: object().shape({}),

  async handleSubmit(values, { props, setSubmitting }) {
    const filters = [];

    for (const [filter, entry] of values.filter.get('filters').entries()) {
      for (const [key, value] of entry.entries()) {
        let v = value;

        if (filter.includes('date') || filter.includes('created')) {
          v = moment(v).format('YYYY-MM-DD');
        }

        filters.push(`${filter}[${key}]=${v}`);
      }
    }

    const cached = `store-bonus-${props.id}`;
    const payload = JSON.parse(localStorage.getItem(cached));

    await api.httpAdapter.doPost(api.endpoint, {
      [api.resourceName]: {
        filter: JSON.stringify(filters.length > 0 ? { user: filters } : {}),
        ...payload,
      },
    });
    delete localStorage[cached];

    setSubmitting(false);
    window.location.href = STORE_BONUSES_PATH;
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
