import {
  CASH_MACHINE_FNS_ID_KEY,
  ID_KEY,
  ISSUED_AT_KEY,
  SALE_NUMBER_KEY,
  TOTAL_COST_KEY,
} from '@esentai/core/features/salesTurnoverDetails/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={ID_KEY}>ID покупки</SortControl>
    </CondenseTableCell>
    <TableCell align="left">
      <SortControl field={CASH_MACHINE_FNS_ID_KEY}>Номер ККМ</SortControl>
    </TableCell>
    <TableCell align="left">
      <SortControl field={SALE_NUMBER_KEY}>UUID</SortControl>
    </TableCell>
    <TableCell align="left">
      <SortControl field={TOTAL_COST_KEY}>Итого чека</SortControl>
    </TableCell>
    <TableCell align="left">
      <SortControl field={ISSUED_AT_KEY}>Дата и время покупки</SortControl>
    </TableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
