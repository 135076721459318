import { SINGLE_RECEIPT_KEY } from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { Input, InputAdornment, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldDescription from '@/components/FieldDescription';

const SingleReceiptForm = ({ classes, Field, disabled }) => (
  <div>
    <FieldDescription
      title="Сумма покупки"
      desc="Пользователь активирует триггер, если совершит покупку на сумму, большую или равную указанной сумме."
    />
    <Typography className={classes.fromLabel}>от</Typography>{' '}
    <Field
      disabled={disabled}
      Target={Input}
      name={SINGLE_RECEIPT_KEY}
      endAdornment={<InputAdornment position="end">₸</InputAdornment>}
    />
  </div>
);

SingleReceiptForm.propTypes = {
  Field: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SingleReceiptForm;
