import { grey } from '@material-ui/core/colors';

document.documentElement.style.setProperty(
  '--vh100',
  `${window.innerHeight}px`,
);

export default ({ palette, spacing }) => ({
  '@global body': {
    backgroundColor: grey[100],
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: [0, 'auto'],
    width: '100%',
    maxWidth: spacing.unit * 56,
    padding: spacing.unit * 2,
    minHeight: 'var(--vh100)',

    fallbacks: {
      height: '100vh',
    },
  },

  logoWrapper: {
    margin: [spacing.unit * 2, 'auto', spacing.unit],
    textAlign: 'center',

    '&::after': {
      content: '""',
      borderBottom: [1, 'solid', palette.chart.stroke],
      display: 'block',
      marginTop: -spacing.unit * 2,
    },
  },
});
