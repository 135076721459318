import { number } from '@esentai/core/serializers/params';

import { annotations as purchasesStatisticsAnnotations } from '@/features/purchasesStatistics/serializer';

import { CUSTOMER_ID_KEY } from './consts/dimensions';
import { defaultCustomerId } from './consts/filters';

export const annotations = {
  ...purchasesStatisticsAnnotations,

  [CUSTOMER_ID_KEY]: number({
    defaultValue: defaultCustomerId,
  }),
};
