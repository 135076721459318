import { ButtonBase } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const RemoveButton = ({ classes, onClick }) => (
  <ButtonBase
    className={classes.removeButton}
    onClick={onClick}
    disableTouchRipple
  >
    Удалить блок
    <Close />
  </ButtonBase>
);

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RemoveButton;
