import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import DefaultFilterChip from '@/components/FilterChip';
import {
  getPageFilterComponent,
  getPageFilterFormatComponent,
  getPageFilterLabel,
  readPageFilterValue,
} from '@/structs/pageFilter';

import { getDescriptor } from '../utils';

class FiltersList extends Component {
  static propTypes = {
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    filterTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    FilterChip: PropTypes.func,
  };

  static defaultProps = {
    FilterChip: DefaultFilterChip,
  };

  render() {
    const { filters } = this.props;

    return (
      <Fragment>{filters.map(filter => this.renderFilter(filter))}</Fragment>
    );
  }

  renderFilter = record => {
    const { filterTypes, FilterChip } = this.props;
    const descriptor = getDescriptor(filterTypes, record.key);
    const label = getPageFilterLabel(descriptor);
    const value = readPageFilterValue(descriptor, record.value);
    const FilterComponent = getPageFilterComponent(descriptor);
    const FormatComponent = getPageFilterFormatComponent(descriptor);

    return (
      <FilterChip
        key={record.key}
        filter={record}
        value={value}
        name={label}
        FormatComponent={FormatComponent}
        renderFilter={props => <FilterComponent {...props} />}
      />
    );
  };
}

export default FiltersList;
