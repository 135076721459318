import { USED_KEY } from '@esentai/core/features/benefitItems/consts/keys';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, spawn } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

const { createQueryChangeWatcher, synchronizeQuery } = queryDuck.sagas;

export function* fetchItems(query) {
  const newQuery = mergeQueriesRight(
    query,
    makeQuery(where(USED_KEY, equals(true))),
  );

  yield call(itemsDuck.sagas.fetchItems, newQuery);
}

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export default function*() {
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
