import api from '@esentai/core/features/stores/api';
import { NAME_KEY } from '@esentai/core/features/stores/consts/keys';
import { ilike, include, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import StoreChip from './StoreChip';
import StoreOption from './StoreOption';

const buildQuery = queryString =>
  query(include('merchants'), where(NAME_KEY, ilike(queryString)));

const withStoreProps = defaultProps({
  api,
  buildQuery,
  reducer: payload => ({
    merchant: payload.merchant,
    store: payload.store.filter(s => s.is_active && s.merchants.length > 0),
  }),
  suggestionMapper: s => s.merchants.map(m => ({ merchant: m, store: s.id })),
  Chip: StoreChip,
  Option: StoreOption,
});

export const StoreWithMerchantsAutocomplete = withStoreProps(AutocompleteAPI);

export const StoreWithMerchantsMultiAutocomplete = withStoreProps(
  AutocompleteAPIMultiple,
);
