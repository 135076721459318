import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { activate } from '@esentai/core/features/benefits/actions';
import {
  activateBenefit,
  createBenefit,
  fetchBenefit,
  updateBenefit,
} from '@esentai/core/features/benefits/sagas';
import { push } from 'connected-react-router';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { createBenefitsPageURL } from '@/features/benefitsPage/utils';

import { SHOULD_SUBMIT_AFTER_SAVE_KEY } from './consts/keys';
import { getBenefitId } from './selectors';
import {
  CREATE_DRAFT,
  FETCH_ITEM,
  GO_TO_STEP_ONE,
  GO_TO_STEP_TWO,
  SUBMIT,
} from './types';
import { createFormStepURL } from './utils';

export function* goToStep(step) {
  const id = yield select(getBenefitId);
  const url = yield call(createFormStepURL, id, step);

  yield put(push(url));
}

export function* createDraft(action) {
  const { payload: attributes } = action;

  const { id } = yield call(createBenefit, attributes);
  const url = yield call(createFormStepURL, id, 2);

  yield put(push(url));
}

export function* fetchItem(action) {
  const { payload: id } = action;

  yield call(fetchBenefit, id);
}

export function* goToStepOne() {
  yield call(goToStep, 1);
}

export function* goToStepTwo(action) {
  const { payload: attributes } = action;
  const id = yield select(getBenefitId);

  yield call(updateBenefit, id, attributes);
  yield call(goToStep, 2);
}

export function* submitCampaign(action) {
  const { payload: attributes } = action;
  const {
    [SHOULD_SUBMIT_AFTER_SAVE_KEY]: shouldSubmitAfterSave,
    ...restAttributes
  } = attributes;
  const id = yield select(getBenefitId);

  yield call(updateBenefit, id, restAttributes);

  if (shouldSubmitAfterSave) {
    const activateAction = activate(id);

    yield call(activateBenefit, activateAction);
  }

  const campaignsPageURL = yield call(createBenefitsPageURL);

  yield put(push(campaignsPageURL));
}

export default function*() {
  yield takeEvery(CREATE_DRAFT, createTaskSaga(createDraft));
  yield takeEvery(FETCH_ITEM, createTaskSaga(fetchItem));
  yield takeEvery(GO_TO_STEP_ONE, createTaskSaga(goToStepOne));
  yield takeEvery(GO_TO_STEP_TWO, createTaskSaga(goToStepTwo));
  yield takeEvery(SUBMIT, createTaskSaga(submitCampaign));
}
