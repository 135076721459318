import { withProps } from 'recompose';

import { LocationAutocomplete } from '@/containers/LocationAutocomplete';
import Autocomplete from '@/filters/Autocomplete';

export default withProps(({ value, ...ownerProps }) => ({
  ...ownerProps,
  Component: LocationAutocomplete,
  selectedItem: value,
  InputProps: {
    placeholder: 'Название локации...',
  },
}))(Autocomplete);
