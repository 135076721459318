import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import { createCampaignURL } from '@/features/campaignPage/utils';
import DateComparer from '@/features/campaignsPage/Page/Row/DateComparer';
import Status from '@/features/campaignsPage/Page/Row/Status';

const StoreSuggestion = ({
  classes,
  itemId,
  title,
  type,
  SelectionControl,
  isArticleStatus,
}) => (
  <div className={classes.root}>
    <SelectionControl itemId={itemId} />
    <Link
      to={createCampaignURL(type, itemId)}
      variant="subtitle1"
      target="_blank"
      className={classes.title}
    >
      {title}
    </Link>

    <div className={classes.status}>
      <Status itemId={itemId} />
    </div>

    {isArticleStatus && (
      <div className={classes.comparer}>
        <DateComparer itemId={itemId} />
      </div>
    )}
  </div>
);

StoreSuggestion.propTypes = {
  itemId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  SelectionControl: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  isArticleStatus: PropTypes.bool,
};

StoreSuggestion.defaultProps = {
  isArticleStatus: false,
};

export default StoreSuggestion;
