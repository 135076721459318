import {
  getAddressCommentForUser,
  getApartmentForUser,
  getHouseForUser,
  getStreetForUser,
} from '@esentai/core/features/users/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import SecondaryFacts from './component';
import styles from './styles';

const mapStateToProps = (state, { userId }) => ({
  street: getStreetForUser(state, userId),
  house: getHouseForUser(state, userId),
  apartment: getApartmentForUser(state, userId),
  address: getAddressCommentForUser(state, userId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(SecondaryFacts);
