import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { NON_POSITIONED_LABEL_X, NON_POSITIONED_LABEL_Y } from '../styles';

const OFFSET_X = NON_POSITIONED_LABEL_X + 3;
const OFFSET_Y = NON_POSITIONED_LABEL_Y + 16;
const PER_ROW = 5;
const HORIZONTAL_SPACING = 18;
const VERTICAL_SPACING = 18;

const IDLE = 'idle';
const DRAGGING = 'dragging';
const UPDATING = 'updating';

class Beacon extends Component {
  static defaultProps = {
    draggingBeaconId: null,
    offsetX: null,
    offsetY: null,
    x: null,
    y: null,
  };

  static propTypes = {
    beaconId: PropTypes.number.isRequired,
    dimmed: PropTypes.bool.isRequired,
    draggingBeaconId: PropTypes.number,
    hasNoPosition: PropTypes.bool.isRequired,
    isFinished: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    minorId: PropTypes.string.isRequired,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
    onBeaconMove: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
  };

  static getDerivedStateFromProps(props, state) {
    const { beaconId, draggingBeaconId, isFinished, offsetX, offsetY } = props;
    const { status } = state;

    if (beaconId === draggingBeaconId) {
      return { status: DRAGGING, offsetX, offsetY };
    }

    if (status === DRAGGING) {
      return { status: UPDATING };
    }

    if (status === UPDATING && isFinished) {
      return { status: IDLE, offsetX: null, offsetY: null };
    }

    return null;
  }

  state = {
    status: IDLE,
    offsetX: null,
    offsetY: null,
  };

  getX() {
    const x = this.getInitialX();
    const dragging = this.isDragging();

    if (dragging) {
      const { offsetX } = this.state;

      return x - offsetX;
    }

    return x;
  }

  getInitialX() {
    const { hasNoPosition, x } = this.props;

    if (hasNoPosition) {
      return this.getFallbackX();
    }

    return x;
  }

  getFallbackX() {
    const index = this.getSequentialIndex();
    const column = index % PER_ROW;

    return OFFSET_X + column * HORIZONTAL_SPACING;
  }

  getY() {
    const y = this.getInitialY();
    const dragging = this.isDragging();

    if (dragging) {
      const { offsetY } = this.state;

      return y - offsetY;
    }

    return y;
  }

  getInitialY() {
    const { hasNoPosition, y } = this.props;

    if (hasNoPosition) {
      return this.getFallbackY();
    }

    return y;
  }

  getFallbackY() {
    const index = this.getSequentialIndex();
    const row = Math.floor(index / PER_ROW);

    return OFFSET_Y + row * VERTICAL_SPACING;
  }

  getSequentialIndex() {
    const { minorId } = this.props;

    return minorId - 1;
  }

  isDragging() {
    const { status } = this.state;

    return status === DRAGGING || status === UPDATING;
  }

  componentDidUpdate() {
    const { isLoading } = this.props;
    const { status } = this.state;

    if (!isLoading && status === UPDATING) {
      const { onBeaconMove } = this.props;
      const x = this.getX();
      const y = this.getY();

      onBeaconMove(x, y);
    }
  }

  render() {
    const {
      classes,
      dimmed,
      minorId,
      onClick,
      onMouseDown,
      selected,
    } = this.props;
    const x = this.getX();
    const y = this.getY();

    return (
      <Fragment>
        <circle
          cx={x}
          cy={y}
          onClick={onClick}
          onMouseDown={onMouseDown}
          className={classNames(classes.backgroundCircle, {
            [classes.backgroundCircle_selected]: selected,
          })}
        />
        <circle
          cx={x}
          cy={y}
          onClick={onClick}
          onMouseDown={onMouseDown}
          className={classNames(classes.circle, {
            [classes.circle_dimmed]: dimmed,
            [classes.circle_selected]: selected,
          })}
        />
        <text
          x={x}
          y={y}
          onClick={onClick}
          onMouseDown={onMouseDown}
          className={classNames(classes.label, {
            [classes.label_selected]: selected,
          })}
        >
          {minorId}
        </text>
      </Fragment>
    );
  }
}

export default Beacon;
