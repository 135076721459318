import { connect } from 'react-redux';

import TimeDistributionChart from '@/components/TimeDistributionChart';
import {
  getTotalPercentageReceiptsByDayById,
  getTotalPercentageReceiptsByEveningById,
  getTotalPercentageReceiptsByMorningById,
} from '@/features/purchasesStatisticsPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  percentageByMorning: getTotalPercentageReceiptsByMorningById(state, itemId),
  percentageByDay: getTotalPercentageReceiptsByDayById(state, itemId),
  percentageByEvening: getTotalPercentageReceiptsByEveningById(state, itemId),
});

export default connect(mapStateToProps)(TimeDistributionChart);
