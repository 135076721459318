export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  tableBlock: {
    marginTop: 20,
  },
  buttonsWrapper: {
    display: 'flex',
    float: 'right',
    alignItems: 'center',
  },
  excelButton: {
    marginLeft: 15,
    height: 'fit-content',
  },
  inputLast: {
    marginLeft: 8,
  },
  reportWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
};
