import { PlayCircleOutline as PlayCircleOutlineIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';
import CanView from '@/containers/CanView';

const ActivateButton = ({ permission, ...rest }) => (
  <CanView permission={permission}>
    <ActionButton
      text="Запустить"
      button={AsyncButton}
      icon={PlayCircleOutlineIcon}
      {...rest}
    />
  </CanView>
);

ActivateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.func.isRequired,
};

export default ActivateButton;
