import { activate } from '@esentai/core/features/campaigns/actions';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import ActivateButton from '@/components/ActionButtons/Activate';
import { canUpdateCampaign } from '@/features/campaignsPage/permissions';

const mapStateToProps = () => ({
  permission: canUpdateCampaign,
});

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(activate(itemId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(ActivateButton);
