import { getTrackedLocationIds } from '@esentai/core/features/campaigns/actionResult/selectors';
import { connect } from 'react-redux';

import Location from './component';

const mapStateToProps = (state, { itemId }) => ({
  locations: getTrackedLocationIds(state, itemId) || [],
});

export default connect(mapStateToProps)(Location);
