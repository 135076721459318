import { getTrackedStoreIds } from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { connect } from 'react-redux';

import Stores from './component';

const mapStateToProps = (state, { itemId }) => ({
  trackedStoreIds: getTrackedStoreIds(state, itemId),
});

export default connect(mapStateToProps)(Stores);
