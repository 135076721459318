import api from '@/api';
import {
  FILE_URL_KEY,
  ISSUED_AT_DATETIME_KEY,
  KKM_KEY,
  PURCHASE_KEY,
  STORE_KEY,
  UUID_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';
import { saveAs } from '@/utils/file-saver';

export const loyaltyLevels = {
  '-': '-',
  1: 'Orange',
  2: 'Black',
  3: 'JetBlack',
};

export const classList = (...list) =>
  list.filter(item => Boolean(item)).join(' ');

export const formFields = [
  PURCHASE_KEY,
  UUID_KEY,
  KKM_KEY,
  ISSUED_AT_DATETIME_KEY,
  FILE_URL_KEY,
  STORE_KEY,
];

export const downloadXls = (params = {}) => {
  api.doBlobGet(`/ipn-report-xlsx-export`, params).then(response => {
    saveAs(response.data, `Отчеты по ИПН.xlsx`);
  });
};
