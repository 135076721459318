import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ConstructorBlockOrderSwitcher from '@/components/ConstructorBlock/OrderSwitcher';

class OrderSwitcher extends Component {
  static propTypes = {
    currentCount: PropTypes.number,
    maxCount: PropTypes.number,
  };

  static defaultProps = {
    currentCount: 0,
    maxCount: 0,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.orderSwitcher}>
        <ConstructorBlockOrderSwitcher {...this.props} />
        {this.renderCount()}
      </div>
    );
  }

  renderCount() {
    const { classes, currentCount, maxCount } = this.props;
    const isShow = Boolean(currentCount) && Boolean(maxCount);

    return (
      isShow && (
        <Typography className={classes.count}>
          {currentCount} из {maxCount}
        </Typography>
      )
    );
  }
}

export default OrderSwitcher;
