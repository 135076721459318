export default {
  container: {
    margin: 'auto',
  },

  contentWrapper: {
    display: 'flex',
    width: 400,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  infoWrapper: {
    position: 'relative',
    width: '100%',
    minHeight: 200,
  },
};
