import { path, pipe } from 'ramda';

import itemsDuck from '@/features/parkingWhitelistGroupDetailsPage/ducks/items';
import queryDuck from '@/features/parkingWhitelistGroupDetailsPage/ducks/query';
import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';

export const {
  getError: getParkingWhitelistError,
  getItemsIds: getParkingWhitelistIds,
  getItemsMeta: getParkingWhitelistMeta,
  getItemsTotalCount: getParkingWhitelistTotalCount,
  isLoadingItems: isLoadingParkingWhitelist,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;

export const getGroupId = pipe(
  createMatchSelector(ROUTE_PATH),
  path(['params', 'groupId']),
);
