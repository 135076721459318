import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import VisibilityButton from './VisibilityButton';

const Actions = ({ itemId }) => (
  <div>
    <EditButton itemId={itemId} />
    <VisibilityButton itemId={itemId} />
    <DeleteButton itemId={itemId} />
  </div>
);

Actions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default Actions;
