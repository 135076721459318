import { connect } from 'react-redux';

import StatisticsDoubleLineChart from '@/components/StatisticsDoubleLineChart';
import {
  getLeftYValues,
  getRightYValues,
  getScale,
  getXValues,
} from '@/features/anonymousPurchasesStatisticsPage/selectors';
import stats from '@/features/anonymousPurchasesStatisticsPage/stats';
import { getStatsIds } from '@/features/statisticsPage/selectors';

import TooltipContent from '../TooltipContent';

const mapStateToProps = state => ({
  xs: getXValues(state),
  leftYs: getLeftYValues(state),
  rightYs: getRightYValues(state),
  scale: getScale(state),
  selectedStatsIds: getStatsIds(state),
  TooltipContent,
  stats,
});

export default connect(mapStateToProps)(StatisticsDoubleLineChart);
