import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import TableCellTypography from '@/components/TableCellTypography';

import CommonCells from '../CommonCells';
import StatusDistribution from './StatusDistribution';
import TimeDistribution from './TimeDistribution';

const TotalRow = ({
  averagePurchases,
  averageReceipts,
  averageTicket,
  percentageReceiptsByCampaign,
  purchasesCount,
}) => (
  <TableRow>
    <CondenseTableCell>
      <TableCellTypography>За весь период</TableCellTypography>
    </CondenseTableCell>
    <CommonCells
      averagePurchases={averagePurchases}
      averageReceipts={averageReceipts}
      averageTicket={averageTicket}
      purchasesCount={purchasesCount}
      percentageReceiptsByCampaign={percentageReceiptsByCampaign}
    >
      <CondenseTableCell align="right">
        <StatusDistribution />
      </CondenseTableCell>
      <CondenseTableCell align="right">
        <TimeDistribution />
      </CondenseTableCell>
    </CommonCells>
  </TableRow>
);

TotalRow.propTypes = {
  averagePurchases: PropTypes.number.isRequired,
  averageReceipts: PropTypes.number.isRequired,
  averageTicket: PropTypes.string.isRequired,
  percentageReceiptsByCampaign: PropTypes.number.isRequired,
  purchasesCount: PropTypes.number.isRequired,
};

export default TotalRow;
