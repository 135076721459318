/* eslint-disable react/no-array-index-key */

import { SINGLE_IMAGE_LAYOUT } from '@esentai/core/features/campaigns/article/consts/imageLayouts';
import {
  IMAGES_LAYOUT_KEY,
  IMAGES_URLS_KEY,
  PAYLOAD_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import DnDUploader from '@/components/DnDUploader';
import FieldDescription from '@/components/FieldDescription';

import { getName } from '../utils';

class ImagesBlock extends Component {
  state = {
    images: this.props.data[PAYLOAD_KEY][IMAGES_URLS_KEY] || [],
  };

  setImages = urls => {
    this.setState({
      images: urls,
    });
    this.handleChange(urls);
  };

  handleChange = urls => {
    const { setFieldValue } = this.props;

    setFieldValue(this.getName(IMAGES_URLS_KEY), urls);
    setFieldValue(this.getName(IMAGES_LAYOUT_KEY), SINGLE_IMAGE_LAYOUT);
  };

  getName(field) {
    const { index } = this.props;

    return getName(index, field);
  }

  render() {
    const { required, disabled } = this.props;
    const { images } = this.state;

    return (
      <Fragment>
        <FieldDescription
          desc={
            required
              ? 'Тип блока «Изображение» позволяет вставить изображение.'
              : 'Тип блока «Изображение» позволяет вставить от 1 до 5 изображений. В зависимости от количества изображений, блок может принимать формат одиночного изображения, двойного изображения, либо слайдера.'
          }
        />
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <DnDUploader
              disabled={disabled}
              setImages={this.setImages}
              images={images}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  static propTypes = {
    required: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  };
}

export default ImagesBlock;
