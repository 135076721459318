import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { updatePage } from '@/features/giftSetsPage/actions';
import queryDuck from '@/features/giftSetsPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getGiftSetError,
  getGiftSetIds,
  getGiftSetTotalCount,
  isLoadingGiftSet,
} from '../selectors';
import GiftSets from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getGiftSetError(state),
  giftSetIds: getGiftSetIds(state),
  isLoading: isLoadingGiftSet(state),
  totalCount: getGiftSetTotalCount(state),
});

const mapDispatchToProps = { updatePage };

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(GiftSets);
