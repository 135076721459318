import { T } from 'ramda';
import { connect } from 'react-redux';

import MapButton from '@/components/ActionButtons/Map';
import { getBeaconMapPageUrl } from '@/features/mapPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  mapPageUrl: getBeaconMapPageUrl(state, itemId),
  permission: T,
});

export default connect(mapStateToProps)(MapButton);
