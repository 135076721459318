export default {
  root: {
    display: 'flex',
    overflow: 'hidden',
    fontFamily: 'Giorgio Sans',
    textTransform: 'uppercase',
    fontSize: 12,
    letterSpacing: 0.5,
    marginTop: 8,
  },

  tag: {
    border: [2, 'solid', '#000'],
    padding: [4, 12],
    lineHeight: 1,

    '&:not(:last-child)': {
      marginRight: 4,
    },
  },

  tag_selected: {
    backgroundColor: '#000',
    color: '#fff',
  },
};
