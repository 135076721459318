import { isSessionExpirationError } from '@esentai/core/api/utils';
import { path, pipe } from 'ramda';

import { AUTH_STORE_KEY } from '@/redux/constants';

export const getCurrentUserId = path([AUTH_STORE_KEY, 'currentUserId']);

export const getAuthorizeError = path([AUTH_STORE_KEY, 'authorizeError']);

export const isSessionExpired = pipe(
  getAuthorizeError,
  isSessionExpirationError,
);

export const getAuthorizedStatus = path([AUTH_STORE_KEY, 'authorized']);

export const getAuthorizingStatus = path([AUTH_STORE_KEY, 'authorizing']);

export const getExpanded = path([AUTH_STORE_KEY, 'menuExpanded']);
