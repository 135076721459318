import { Button, TableCell, TableRow, Typography } from '@material-ui/core';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import {
  POST_URL,
  REPAY_CONFIRMATION_MSG,
} from '@/features/merchantsReconciliationDetailsPage/consts';
import { ROUTE_PATH as RECONCILIATIONS_PATH } from '@/features/merchantsReconciliationPage/consts';
import {
  dateTimeFormatNoSecs,
  formatMoney,
  formatNumber,
} from '@/utils/format';

import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import {
  downloadMerchantHistoryDetailXls,
  downloadMerchantPayDetalizationXls,
} from '../utils';
import Actions from './Actions';
import AttachmentButton from './AttachmentButton';
import { CmTable } from './CmTable/CmTable';
import Table from './CommonTable/Table';
import { Description } from './Description/Description';
import { RepaymentTable } from './RepaymentTable/RepaymentTable';

const cmHeaders = [
  'Сумма к оплате',
  'Сумма потраченных бонусов',
  'Сумма бонусов на возврат',
  'Действия',
];

const repaymentHeaders = [
  'Дата оплаты',
  'Сумма оплаты',
  'Чек оплаты',
  'Пользователь',
];

class MerchantReconciliationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      merchant: null,
      isRepayDialogOpen: false,
    };

    this.RepayTable = React.createRef();
    this.CmTableRef = React.createRef();
  }

  static propTypes = {
    itemId: PropTypes.string.isRequired,
    reconciliationIds: PropTypes.any.isRequired,
    error: PropTypes.any.isRequired,
    isLoading: PropTypes.any.isRequired,
    totalCount: PropTypes.any.isRequired,
    fetchItem: PropTypes.any.isRequired,
  };

  static defaultProps = {
    endDatetime: '-',
    startDatetime: '-',
  };

  fetchAllDetails() {
    const { fetchItem } = this.props;

    fetchItem();
    this.RepayTable.current.loadData();
    this.CmTableRef.current.loadData();
  }

  handleRepayModal = () => {
    this.setState({ isRepayDialogOpen: !this.state.isRepayDialogOpen });
  };

  loadMerchantHistoryDetailData = () => {
    const { itemId } = this.props;
    const { merchant } = this.state;
    const companyName = _get(merchant, '0.company');

    downloadMerchantHistoryDetailXls(itemId, companyName);
  };

  loadMerchantPayDetalizationData = () => {
    const { itemId } = this.props;
    const { merchant } = this.state;
    const companyName = _get(merchant, '0.company');

    downloadMerchantPayDetalizationXls(itemId, companyName);
  };

  render() {
    const {
      classes,
      itemId,
      reconciliationIds,
      error,
      isLoading,
      totalCount,
    } = this.props;

    const { isRepayDialogOpen } = this.state;

    const payload = {
      merchant_reconciliation: {
        merchant: itemId,
      },
    };

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={RECONCILIATIONS_PATH}>
              Бонусы от Esentai Mall
            </Breadcrumb>
          </Breadcrumbs>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Description
              detailId={itemId}
              getMerchant={merchant =>
                this.setState({
                  merchant: merchant.merchant_reconciliations_archive,
                })
              }
              fetchItem={this.fetchAllDetails.bind(this)}
            />
            <CmTable
              detailId={itemId}
              headers={cmHeaders}
              ref={this.CmTableRef}
            >
              {rows =>
                rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>{formatMoney(row.total_to_pay)}</TableCell>
                    <TableCell>{formatNumber(row.bonuses_spent)}</TableCell>
                    <TableCell>{formatNumber(row.bonuses_refunded)}</TableCell>
                    <TableCell>
                      <Actions
                        handleDialog={this.handleRepayModal}
                        repayDisabled={row.total_to_pay === 0}
                      />
                    </TableCell>
                  </TableRow>
                ))
              }
            </CmTable>
            <div style={{ marginTop: 15 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5">Архив</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size={'large'}
                  onClick={this.loadMerchantHistoryDetailData}
                >
                  Вывести в EXCEL
                </Button>
              </div>
              <Table
                error={error}
                items={reconciliationIds}
                isLoading={isLoading}
                totalCount={totalCount}
              />
            </div>
            <RepaymentTable
              detailId={itemId}
              headers={repaymentHeaders}
              uploadExcel={this.loadMerchantPayDetalizationData}
              ref={this.RepayTable}
            >
              {rows =>
                rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {dateTimeFormatNoSecs(row.record_created)}
                    </TableCell>
                    <TableCell>{row.paid}</TableCell>
                    <TableCell align="left">
                      {row.payment_file_url ? (
                        <AttachmentButton href={row.payment_file_url} />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>{row.user_name}</TableCell>
                  </TableRow>
                ))
              }
            </RepaymentTable>
          </div>
          {isRepayDialogOpen && (
            <ConfirmationDialog
              isOpen={isRepayDialogOpen}
              onClose={this.handleRepayModal}
              storeId={itemId}
              fetchItems={this.fetchAllDetails.bind(this)}
              url={POST_URL}
              payload={payload}
              confirmationMessage={REPAY_CONFIRMATION_MSG}
            />
          )}
        </PageContent>
      </Page>
    );
  }
}

export default MerchantReconciliationDetails;
