import { connect } from 'react-redux';

import Select from '@/components/StatisticsTopBar/Select';
import { updateCampaignType } from '@/features/campaignsStatisticsPage/actions';
import { getCampaignType } from '@/features/campaignsStatisticsPage/selectors';
import { options } from '@/filters/CampaignType';

const mapStateToProps = state => ({
  items: options,
  value: getCampaignType(state),
});

const mapDispatchToProps = { onChange: updateCampaignType };

export default connect(mapStateToProps, mapDispatchToProps)(Select);
