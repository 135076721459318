export default {
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#1e88e5',
  },
  editWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  editText: {
    marginRight: 4,
  },
  editIcon: {
    cursor: 'pointer',
  },
};
