import { number, sort, string } from '@esentai/core/serializers/params';
import { applyMiddleware } from 'awokado/serializers';
import createFilters from 'awokado/serializers/middlewares/filters';
import createWellKnownParams from 'awokado/serializers/middlewares/wellKnownParams';
import createOperators from 'awokado/serializers/operators';
import { createUrlParamsSerializer } from 'awokado/serializers/params';

import createDefaults from './middlewares/defaults';
import createOmitDefaults from './middlewares/omitDefaultValuesOnSerialize';

const defaultAnnotations = {
  limit: number({
    defaultValue: 10,
  }),
  page: number({
    defaultValue: 0,
  }),
  sort: sort(),
  include: string(),
};

const wellKnownKeys = ['limit', 'page', 'sort', 'include'];

export default userAnnotations => {
  const annotations = {
    ...defaultAnnotations,
    ...userAnnotations,
  };

  const params = createUrlParamsSerializer(annotations);
  const operators = createOperators(params);
  const filters = createFilters(operators);
  const wellKnownParams = createWellKnownParams(wellKnownKeys, params);
  const defaults = createDefaults(annotations);
  const omitDefaultValuesOnSerialize = createOmitDefaults(annotations);

  const middlewares = [
    omitDefaultValuesOnSerialize,
    wellKnownParams,
    filters,
    defaults,
  ];

  return applyMiddleware(middlewares);
};
