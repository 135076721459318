import { Button, Paper, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Modal from '@/components/Modal';
import { REAL_FULL_NAME_KEY } from '@/features/ipnReportsPage/consts/keys';

const EditFullName = props => {
  const {
    classes,
    cancelLabel,
    confirmLabel,
    onClose,
    onSubmit,
    Field,
    values,
    setFieldValue,
    isValid,
    fullName,
  } = props;

  useEffect(() => {
    if (fullName) {
      setFieldValue(REAL_FULL_NAME_KEY, fullName);
    }
  }, [fullName]);

  return (
    <Modal {...props} onClose={onClose}>
      <Paper className={classes.wrapper}>
        <div className={classes.system}>
          <span>ФИО в системе</span>
          <span>{fullName}</span>
        </div>

        <div className={classes.real}>
          <span>ФИО настоящий</span>
          <Field
            className={classes.real_input}
            id={REAL_FULL_NAME_KEY}
            margin="none"
            Target={TextField}
            fullWidth
            name={REAL_FULL_NAME_KEY}
            placeholder="ФИО настоящий"
          />
        </div>

        <div className={classes.footer}>
          <Button onClick={onClose}>{cancelLabel}</Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid}
            style={{ marginLeft: 15 }}
            onClick={() => onSubmit(values)}
          >
            {confirmLabel}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

EditFullName.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  Field: PropTypes.func.isRequired,
  Form: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object,
  resetForm: PropTypes.func,
  fullName: PropTypes.string.isRequired,
};

EditFullName.defaultProps = {
  values: {},
  setFieldValue: () => {},
  resetForm: () => {},
};

export default EditFullName;
