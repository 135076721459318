import { FIRST_FLOOR_ID } from '@esentai/core/features/beacons/consts/floors';
import {
  getFloorLocationId,
  getRentPlaceLocationId,
  getRussianName,
  isRentPlaceOnFloor,
} from '@esentai/core/features/locations/selectors';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query, where } from '@esentai/core/query-dsl';
import { isEmpty } from 'ramda';

import { getItemPropById } from '@/features/locationsStatistics/selectors';
import { getStatsIds } from '@/features/statisticsPage/selectors';
import { findStatById, getStatField } from '@/features/statisticsPage/utils';
import { LOCATION_ID_KEY } from '@/features/usersStatistics/consts/filterKeys';
import { createStatPageUrl as createUsersStatisticsLink } from '@/features/usersStatisticsPage/serializer';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import stats from './stats';
import { createStatPageUrl } from './utils';

export const {
  getError,
  isLoadingItems,
  getItemsIds,
  getItemsMeta,
  getItemsTotalCount,
} = itemsDuck.selectors;

export const {
  getFilters,
  getPage,
  getLimit,
  getQuery,
  getSort,
} = queryDuck.selectors;

export const getStatId = state => {
  const statsIds = getStatsIds(state);

  return statsIds[0];
};

export const getStatDescriptor = state => {
  const statId = getStatId(state);

  return findStatById(stats, statId);
};

export const getFloor = state => {
  const statsIds = getStatsIds(state);

  return statsIds[1];
};

export const isCurrentFloor = (state, floor) => {
  const currentFloor = getFloor(state);

  return floor === currentFloor;
};

export const hasDataForRentPlace = (state, rentId) => {
  const rentIds = getItemsIds(state);

  return rentIds.includes(rentId);
};

export const getRentPlaceName = (state, rentId) => {
  const locationId = getRentPlaceLocationId(state, rentId);

  return getRussianName(state, locationId);
};

export const getAmountForRentPlaceMetric = (state, descriptor, rentId) => {
  const locationId = getRentPlaceLocationId(state, rentId);
  const field = getStatField(descriptor);
  const amountSelector = getItemPropById(field);
  const hasData = hasDataForRentPlace(state, rentId);

  if (!hasData) {
    return 0;
  }

  return amountSelector(state, locationId) || 0;
};

export const getAmountForRentPlaceCurrentMetric = (state, rentId) => {
  const currentDescriptor = getStatDescriptor(state);

  return getAmountForRentPlaceMetric(state, currentDescriptor, rentId);
};

export const getCurrentFloorRentIds = state => {
  const floor = getFloor(state);
  const rentIds = getItemsIds(state);

  return rentIds.filter(rentId => isRentPlaceOnFloor(state, rentId, floor));
};

export const getCurrentFloorAmounts = state => {
  const rentIds = getCurrentFloorRentIds(state);

  return rentIds.map(rentId =>
    getAmountForRentPlaceCurrentMetric(state, rentId),
  );
};

export const getMinimumAmount = state => {
  const amounts = getCurrentFloorAmounts(state);

  if (isEmpty(amounts)) {
    return 0;
  }

  return Math.min(...amounts);
};

export const getMaximumAmount = state => {
  const amounts = getCurrentFloorAmounts(state);

  if (isEmpty(amounts)) {
    return 0;
  }

  return Math.max(...amounts);
};

export const getDefaultMetricPageUrl = () => {
  const defaultQuery = query();

  return createStatPageUrl(stats[0].url_key, FIRST_FLOOR_ID, defaultQuery);
};

export const getFloorStatisticsUrl = (state, floor) => {
  const statId = getStatId(state);
  const currentQuery = getQuery(state);

  return createStatPageUrl(statId, floor, currentQuery);
};

export const getMetricStatisticsUrl = (state, statId) => {
  const floor = getFloor(state);
  const currentQuery = getQuery(state);

  return createStatPageUrl(statId, floor, currentQuery);
};

export const getUsersStatisticsLinkWithLocation = (state, locationId) => {
  const statId = getStatId(state);
  const currentQuery = getQuery(state);
  const usersStatisticsQuery = mergeQueriesRight(
    currentQuery,
    query(where(LOCATION_ID_KEY, equals(locationId))),
  );

  return createUsersStatisticsLink([statId, statId], usersStatisticsQuery);
};

export const getFloorUsersStatisticsUrl = (state, floor) => {
  const locationId = getFloorLocationId(state, floor);

  return getUsersStatisticsLinkWithLocation(state, locationId);
};

export const getRentPlaceUsersStatisticsUrl = (state, rentId) => {
  const locationId = getRentPlaceLocationId(state, rentId);

  return getUsersStatisticsLinkWithLocation(state, locationId);
};
