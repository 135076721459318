import { ISSUED_AT_KEY } from '@esentai/core/features/salesTurnoverDetails/consts/keys';
import { annotations } from '@esentai/core/features/salesTurnoverDetails/serializer';
import { descending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(ISSUED_AT_KEY),
  }),
});
