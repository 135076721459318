export default {
  wrapper: {
    outline: 0,
    width: 920,
    maxHeight: '80%',
    overflowY: 'auto',
    padding: 27,
    fontFamily: 'Roboto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 50,
  },
  system: {
    display: 'flex',
    flexDirection: 'column',
  },
  real: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  real_input: {
    maxWidth: 350,
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
  },
};
