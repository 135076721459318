import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';
import RemoveIcon from '@/components/Icon/Remove';

const CreditCardDeleteButton = ({ removeCard }) => (
  <ActionButton
    text="Удалить"
    button={AsyncButton}
    icon={RemoveIcon}
    onClick={removeCard}
  />
);

CreditCardDeleteButton.propTypes = {
  removeCard: PropTypes.func.isRequired,
};

export default CreditCardDeleteButton;
