import { BANNER_TYPE } from '@esentai/core/features/campaigns/consts/types';
import { equals, filter, gte, lte } from '@esentai/core/query-dsl';
import { subDays } from 'date-fns';

import {
  CAMPAIGN_ACTION_RESULT_TRIGGER_KEY,
  CAMPAIGN_BANNER_TYPE_KEY,
  CAMPAIGN_BUTTON_PLACEMENT_KEY,
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_REWARD_TYPE_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
} from '@/features/campaignsStatistics/consts/dimensions';

const today = new Date();
const yesterday = subDays(today, 1);
const twoWeeksAgo = subDays(today, 14);

export const defaultCampaignType = filter(equals(BANNER_TYPE));

export const defaultDateRange = filter(gte(twoWeeksAgo), lte(yesterday));

export const ACTION_RESULT_FILTERS_IDS = [
  CAMPAIGN_ACTION_RESULT_TRIGGER_KEY,
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_REWARD_TYPE_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
];

export const ARTICLE_FILTERS_IDS = [
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
];

export const BANNER_FILTERS_IDS = [
  CAMPAIGN_BANNER_TYPE_KEY,
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
];

export const BUTTON_FILTERS_IDS = [
  CAMPAIGN_BUTTON_PLACEMENT_KEY,
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
];

export const CALENDAR_EVENT_FILTERS_IDS = [
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
];

export const NOTIFICATION_FILTERS_IDS = [
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
];

export const PURCHASE_RESULT_FILTERS_IDS = [
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_REWARD_TYPE_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
];
