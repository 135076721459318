import { getDestination } from '@esentai/core/features/campaigns/actionResult/selectors';
import { connect } from 'react-redux';

import Destination from './component';

const mapStateToProps = (state, { itemId }) => ({
  destination: getDestination(state, itemId),
});

export default connect(mapStateToProps)(Destination);
