import Fab from '@material-ui/core/Fab';
import { Remove as RemoveIcon } from '@material-ui/icons';
import LoopIcon from '@material-ui/icons/Loop';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class ImageThumb extends React.Component {
  static propTypes = {
    remove: PropTypes.func,
    loading: PropTypes.bool,
    url: PropTypes.string.isRequired,
  };

  render() {
    const { url, remove, loading, classes } = this.props;

    return (
      <div className={classNames(classes.thumb, { [classes.faded]: loading })}>
        <img src={url} className={classes.img} />
        {remove && (
          <Fab
            size="small"
            color="secondary"
            aria-label="add"
            className={classes.fab}
            onClick={remove}
          >
            <RemoveIcon />
          </Fab>
        )}
        {loading && (
          <Fab
            size="small"
            color="secondary"
            aria-label="add"
            className={classNames(classes.fab, classes.rotate)}
          >
            <LoopIcon />
          </Fab>
        )}
      </div>
    );
  }
}

ImageThumb.defaultProps = {
  remove: null,
  loading: false,
};

export default ImageThumb;
