import {
  INITIAL_SEND_DATETIME,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_KAZAKH_MESSAGE_KEY,
  PUSH_KAZAKH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_SHOW_ID_KEY,
  REFERRED_STORE_ID_KEY,
} from '@esentai/core/features/campaigns/notification/consts/keys';
import React, { Component } from 'react';

import SecondStepForm from '@/features/campaignCreatePage/EditPage/SecondStepForm';
import FormikPropTypes from '@/prop-types/formik';

import DestinationForm from './DestinationForm';
import PushForm from './PushForm';

const fieldNames = {
  [INITIAL_SEND_DATETIME]: 'дата отправки сообщения',

  [PUSH_ENGLISH_TITLE_KEY]: 'заголовок пуш-уведомления на английском',
  [PUSH_ENGLISH_MESSAGE_KEY]: 'описание пуш-уведомления на английском',

  [PUSH_RUSSIAN_TITLE_KEY]: 'заголовок пуш-уведомления на русском',
  [PUSH_RUSSIAN_MESSAGE_KEY]: 'описание пуш-уведомления на русском',

  [PUSH_KAZAKH_TITLE_KEY]: 'заголовок пуш-уведомления на казахском',
  [PUSH_KAZAKH_MESSAGE_KEY]: 'описание пуш-уведомления на казахском',

  [REFERRED_ARTICLE_ID_KEY]: 'ссылка на статью',
  [REFERRED_BENEFIT_ID_KEY]: 'ссылка на бенефит',
  [REFERRED_CALENDAR_EVENT_ID_KEY]: 'ссылка на календарное событие',
  [REFERRED_STORE_ID_KEY]: 'ссылка на магазин',
  [REFERRED_SHOW_ID_KEY]: 'ссылка на онлайн витрину',
};

class SecondStepNotification extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { handleSubmit, SubmitButton, RequiredMessages } = this.props;

    return (
      <SecondStepForm
        fieldNames={fieldNames}
        handleSubmit={handleSubmit}
        SubmitButton={SubmitButton}
        RequiredMessages={RequiredMessages}
      >
        <PushForm {...this.props} />
        <DestinationForm {...this.props} />
      </SecondStepForm>
    );
  }
}

export default SecondStepNotification;
