import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getStatsIds } from '@/features/statisticsPage/selectors';
import queryDuck from '@/features/usersStatisticsPage/ducks/query';
import {
  getDefaultMetricsPageUrl,
  getError,
  getItemsIds,
  getItemsTotalCount,
  isLoadingItems,
} from '@/features/usersStatisticsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import UsersStatistics from './component';

const mapStateToProps = state => ({
  defaultMetricsPageUrl: getDefaultMetricsPageUrl(state),
  error: getError(state),
  itemsIds: getItemsIds(state),
  isLoading: isLoadingItems(state),
  totalCount: getItemsTotalCount(state),
  statsIds: getStatsIds(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(UsersStatistics);
