import { findCampaignById } from '@esentai/core/features/benefits/actions';
import {
  getBenefitTitle,
  isBenefitLoaded,
  isLoadingBenefit,
} from '@esentai/core/features/benefits/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import BenefitTitleLazy from './component';

const mapStateToProps = (state, { itemId }) => ({
  isLoaded: isBenefitLoaded(state, itemId),
  isLoading: isLoadingBenefit(state, itemId),
  itemId,
  title: getBenefitTitle(state, itemId),
});

const mapDispatchToProps = { fetchItem: findCampaignById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(BenefitTitleLazy);
