import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import { recoverPassword as recoverPasswordSaga } from '@/features/passwordRecovery/sagas';

import { LAST_ACTIVITY_DATE_KEY, STATE_KEY } from './consts/keys';
import { ACTIVE_STATUS, INACTIVE_STATUS } from './consts/stateTypes';
import dao from './dao';
import { getEmailForPersonnel, getStateForPersonnel } from './selectors';
import { RESET_PASSWORD, TOGGLE_ACTIVITY } from './types';

export const {
  findById: fetchPersonnel,
  update: updatePersonnel,
  remove,
  root: daoSaga,
} = dao.sagas;

export function* deletePersonnel({ payload: personnelId }) {
  yield call(remove, personnelId);
}

export function* updateLastActivity(personnelId, lastActivityDate) {
  yield call(updatePersonnel, personnelId, {
    [LAST_ACTIVITY_DATE_KEY]: lastActivityDate,
  });
}

export function* updateState({ payload: userId }) {
  const prevStatus = yield select(getStateForPersonnel, userId);

  if (prevStatus === ACTIVE_STATUS || prevStatus === INACTIVE_STATUS) {
    const attributes = {
      [STATE_KEY]:
        prevStatus === ACTIVE_STATUS ? INACTIVE_STATUS : ACTIVE_STATUS,
    };

    yield call(updatePersonnel, userId, attributes);
  }
}

export function* resetPassword({ payload: userId }) {
  const email = yield select(getEmailForPersonnel, userId);

  yield call(recoverPasswordSaga, { payload: { email } });
}

export function* watchToggleActivity() {
  yield takeEvery(TOGGLE_ACTIVITY, createTaskSaga(updateState));
}

export function* watchDeletePersonnel() {
  yield takeEvery(dao.actionNames.delete, deletePersonnel);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, createTaskSaga(resetPassword));
}

export default function*() {
  yield spawn(daoSaga);
  yield spawn(watchToggleActivity);
  yield spawn(watchDeletePersonnel);
  yield spawn(watchResetPassword);
}
