export default {
  wrapper: {
    maxWidth: 608,
    marginTop: 60,
    width: '100%',
  },
  label: {
    marginBottom: 38,
  },
  bar: {
    display: 'flex',
    flexDirection: 'column',
  },
};
