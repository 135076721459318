import {
  ANSWER_ENGLISH_KEY,
  ANSWER_KAZAKH_KEY,
  ANSWER_RUSSIAN_KEY,
  QUESTION_ENGLISH_KEY,
  QUESTION_KAZAKH_KEY,
  QUESTION_RUSSIAN_KEY,
} from '@esentai/core/features/questions/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: ({
    englishAnswer,
    englishQuestion,

    russianAnswer,
    russianQuestion,

    kazakhAnswer,
    kazakhQuestion,
  }) => ({
    [ANSWER_ENGLISH_KEY]: englishAnswer,
    [ANSWER_RUSSIAN_KEY]: russianAnswer,
    [ANSWER_KAZAKH_KEY]: kazakhAnswer,

    [QUESTION_ENGLISH_KEY]: englishQuestion,
    [QUESTION_RUSSIAN_KEY]: russianQuestion,
    [QUESTION_KAZAKH_KEY]: kazakhQuestion,
  }),

  validationSchema: object().shape({
    [ANSWER_ENGLISH_KEY]: string()
      .max(500)
      .required(),
    [ANSWER_RUSSIAN_KEY]: string()
      .max(500)
      .required(),
    [ANSWER_KAZAKH_KEY]: string()
      .max(500)
      .required(),
    [QUESTION_ENGLISH_KEY]: string()
      .max(150)
      .required(),
    [QUESTION_RUSSIAN_KEY]: string()
      .max(150)
      .required(),
    [QUESTION_KAZAKH_KEY]: string()
      .max(150)
      .required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
