import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { downloadXls } from '@/features/executiveReportsPage/utils';

import Table from './Table';

class ExecutiveReports extends Component {
  static propTypes = {
    error: PropTypes.any,
    mallStatIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, mallStatIds, isLoading, totalCount, classes } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Executive reports</PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.excelButton}
                onClick={downloadXls}
              >
                Вывести в Excel
              </Button>
              {/* <Button*/}
              {/*  variant="contained"*/}
              {/*  color="primary"*/}
              {/*  className={classes.excelButton}*/}
              {/*  style={{ marginLeft: 16 }}*/}
              {/*  onClick={() => downloadXls(false)}*/}
              {/* >*/}
              {/*  Вывести в Excel все*/}
              {/* </Button>*/}
            </div>
            <Table
              error={error}
              items={mallStatIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default ExecutiveReports;
