import { Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  compose,
  isEmpty,
  map,
  mergeRight,
  path,
  reject,
  uncurryN,
} from 'ramda';
import React from 'react';

const RangeFilter = ({
  classes,
  InputComponent,
  combineValues,
  value,
  onChange,
  fromInputProps,
  toInputProps,
}) => (
  <div>
    <InputComponent
      autoFocus
      placeholder="от"
      value={value.from || ''}
      type="number"
      onChange={event => {
        onChange(combineValues(value, { from: event }));
      }}
      {...fromInputProps}
    />
    <div className={classes.delimiter} />
    <InputComponent
      placeholder="до"
      value={value.to || ''}
      type="number"
      onChange={event => onChange(combineValues(value, { to: event }))}
      {...toInputProps}
    />
  </div>
);

RangeFilter.defaultProps = {
  fromInputProps: {},
  toInputProps: {},
  InputComponent: Input,
  combineValues: uncurryN(2, value =>
    compose(
      map(Number),
      reject(isEmpty),
      mergeRight(value),
      map(path(['target', 'value'])),
    ),
  ),
};

RangeFilter.propTypes = {
  combineValues: PropTypes.func,
  InputComponent: PropTypes.func,
  fromInputProps: PropTypes.object,
  toInputProps: PropTypes.object,
  value: PropTypes.shape({
    from: PropTypes.any,
    to: PropTypes.any,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RangeFilter;
