import PropTypes from 'prop-types';
import React from 'react';

import { formatPercentage } from '@/utils/format';

const getWidthStyles = percentage => ({ flexBasis: `${percentage}%` });

const ChartBar = ({ classes, percentage, label, ...props }) =>
  percentage > 0 && (
    <div style={getWidthStyles(percentage)} {...props}>
      <div className={classes.label}>{label}</div>
      <div className={classes.bar}>{formatPercentage(percentage)}</div>
    </div>
  );

ChartBar.propTypes = {
  label: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default ChartBar;
