export default {
  root: {
    padding: [12, 12, 0],
  },

  imageWrapper: {
    margin: [0, -12, 20],
  },

  image: {
    backgroundColor: '#c7c7c7',
    width: '100%',
    display: 'block',
    height: 194,
    marginBottom: 12,
    objectFit: 'cover',
  },

  title: {
    marginBottom: 4,
    fontFamily: 'Giorgio Sans',
    fontWeight: 'bold',
    fontSize: 27,
    lineHeight: 1.1,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    wordWrap: 'break-word',
  },

  additionalText: {
    fontFamily: 'Graphik LCG',
    fontSize: 10,
    lineHeight: 1.1,
    wordWrap: 'break-word',
  },
};
