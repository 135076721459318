import { createSection } from '../structs/section';

export const BEACONS_SECTION = createSection('beacons', 'Маяки');

export const BRANDS_SECTION = createSection('brands', 'Бренды');

export const CONTACTS_SECTION = createSection(
  'contacts',
  'Контактная информация',
);

export const HOME_FEED_SECTION = createSection('feed', 'Компоновка ленты');

export const LOCATIONS_SECTION = createSection('locations', 'Локации');

export const MAP_SECTION = createSection('map', 'Карта');

export const SITE_SYNC_SECTION = createSection('sync', 'Синхронизация');

export const QUESTIONS_SECTION = createSection('faq', 'FAQ');

export const VERSION_SECTION = createSection(
  'app-version',
  'Версия приложения',
);
