import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [
  {
    key: true,
    label: 'Активные',
  },
  {
    key: false,
    label: 'Неактивные',
  },
];

export const createStoreActivityStateFilter = createRadioFilterTemplate({
  initialValue: options[0].key,
  options,
});

export default RadioGroupFilter;
