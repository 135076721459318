import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import MerchantBinLazy from '@/containers/MerchantBinLazy';
import StoreNameLazy from '@/containers/StoreNameLazy';

import Label from '../Label';

const Description = ({ kkmNumber, merchant, store }) => (
  <FieldRow name="Описание ККМ">
    <Grid container>
      <Grid item>
        <Label title="Номер ККМ">{kkmNumber}</Label>

        <Label title="БИН/ИИН">
          <MerchantBinLazy merchantId={merchant} />
        </Label>

        <Label title="Магазин">
          <StoreNameLazy storeId={store} />
        </Label>
      </Grid>
    </Grid>
  </FieldRow>
);

Description.propTypes = {
  kkmNumber: PropTypes.string,
  merchant: PropTypes.string,
  store: PropTypes.string,
};

Description.defaultProps = {
  kkmNumber: null,
  merchant: null,
  store: null,
};

export default Description;
