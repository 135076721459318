import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getLoyaltyHistoryError,
  getItemsIds: getLoyaltyHistoryIds,
  getItemsMeta: getLoyaltiesMeta,
  getItemsTotalCount: getLoyaltyHistoryTotalCount,
  isLoadingItems: isLoadingHistory,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
