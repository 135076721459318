import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getDetailsId,
  getReconciliationError,
  getReconciliationIds,
  getReconciliationTotalCount,
  isLoadingReconciliation,
} from '@/features/merchantsReconciliationDetailsPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';
import withQuerySync from '@/hocs/withQuerySync';

import { fetchDetails as fetchItem } from '../actions';
import queryDuck from '../ducks/query';
import MerchantReconciliationDetails from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getDetailsId(state);

  return {
    itemId,
    error: getReconciliationError(state),
    reconciliationIds: getReconciliationIds(state),
    isLoading: isLoadingReconciliation(state),
    totalCount: getReconciliationTotalCount(state),
  };
};

const mapDispatchToProps = {
  fetchItem,
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
  withStyles(styles),
)(MerchantReconciliationDetails);
