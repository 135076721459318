import PropTypes from 'prop-types';
import React from 'react';

import CanView from '@/containers/CanView';
import { canUpdateLocation } from '@/features/locationsPage/permissions';

import EditButton from './EditButton';
import MapButton from './MapButton';
import StatisticsButton from './StatisticsButton';

const Actions = ({ itemId }) => (
  <div>
    <CanView permission={canUpdateLocation}>
      <EditButton itemId={itemId} />
    </CanView>
    <MapButton itemId={itemId} />
    <StatisticsButton itemId={itemId} />
  </div>
);

Actions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default Actions;
