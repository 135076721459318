import {
  getBenefitFilter,
  getBenefitStatus,
} from '@esentai/core/features/benefits/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { submit } from '@/features/benefitCreatePage/actions';
import { getBenefitId } from '@/features/benefitCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStep from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getBenefitId(state);

  return {
    filters: getBenefitFilter(state, id),
    status: getBenefitStatus(state, id),
    id,
  };
};

const mapDispatchToProps = { onSubmit: submit };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(SecondStep);
