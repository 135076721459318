import { TableRow } from '@material-ui/core';
import React from 'react';

import TableCellTypography from '@/components/TableCellTypography';

import RowCellsContent from '../RowCellsContent';

const TotalRow = ({ classes, ...rest }) => (
  <TableRow className={classes.rowRoot}>
    <RowCellsContent
      {...rest}
      FirstCell={
        <TableCellTypography align="left">За весь период</TableCellTypography>
      }
    />
  </TableRow>
);

export default TotalRow;
