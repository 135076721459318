import { connect } from 'react-redux';

import Metric from '@/components/Metric';
import { getStatField } from '@/features/statisticsPage/utils';

const mapStateToProps = (
  state,
  { selectedDateIndex, descriptor, getValue },
) => ({
  value: getValue(state, selectedDateIndex, getStatField(descriptor)),
});

export default connect(mapStateToProps)(Metric);
