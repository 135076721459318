import {
  DELAY_AFTER_TRIGGER_KEY,
  HAS_DELAY_AFTER_TRIGGER_KEY,
} from '@esentai/core/features/campaigns/actionResult/consts/keys';
import {
  Checkbox,
  FormControlLabel,
  Input,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FieldRow from '@/components/FieldRow';
import pluralize from '@/utils/pluralize';

import { CanEdit } from '../../CanEdit';

const secondsLabel = seconds =>
  pluralize(seconds, {
    one: 'секунда',
    few: 'секунды',
    many: 'секунд',
  });

class DelayForm extends PureComponent {
  static propTypes = {
    delayAfterTrigger: PropTypes.any.isRequired,
    hasDelayAfterTrigger: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    Field: PropTypes.func.isRequired,
  };

  setHasDelay = event => {
    const { checked } = event.target;
    const { setFieldValue } = this.props;

    setFieldValue(HAS_DELAY_AFTER_TRIGGER_KEY, checked);
  };

  render() {
    const {
      classes,
      delayAfterTrigger,
      hasDelayAfterTrigger,
      Field,
    } = this.props;

    return (
      <FieldRow
        label="Задержка"
        subLabel="Задержка активации после срабатывания триггера"
        sudDesc="При установленном таймауте триггера, он сработает через установленное время, после своей активации. Это значит, что если, к примеру, клиент войдет в локацию, а таймаут установлен на 30 минут, то выполнение кампании активируется через 30 минут, как клиент вошел в локацию. Это важно, чтобы не надоедать клиентам. 1 минута = 60 сек. 1 час = 3600 сек. 1 день = 86400 сек. Обратите внимание, что отправка пуш-уведомление может происходить также с задержкой."
      >
        <CanEdit field={HAS_DELAY_AFTER_TRIGGER_KEY}>
          <FormControlLabel
            label="Установить таймаут активации кампании"
            control={
              <Checkbox
                checked={hasDelayAfterTrigger}
                onChange={this.setHasDelay}
              />
            }
          />
        </CanEdit>

        {hasDelayAfterTrigger && (
          <CanEdit field={DELAY_AFTER_TRIGGER_KEY}>
            <Field
              type="number"
              Target={Input}
              name={DELAY_AFTER_TRIGGER_KEY}
              label="Размер таймаута"
              endAdornment={
                <InputAdornment position="end">
                  <Typography className={classes.measure}>
                    {secondsLabel(delayAfterTrigger)}
                  </Typography>
                </InputAdornment>
              }
              className={classes.delayInput}
            />
          </CanEdit>
        )}
      </FieldRow>
    );
  }
}

export default DelayForm;
