import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import {
  DOCUMENT_NAME_KEY,
  UPLOAD_DATE_KEY,
} from '@/features/documentsPage/consts/keys';

const HeadRow = ({ SortControl }) => {
  const [sortFields, setSortFields] = useState({
    [DOCUMENT_NAME_KEY]: [{}],
    [UPLOAD_DATE_KEY]: [{}],
  });

  // const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  // let currentUrlParams = new URLSearchParams(window.location.search);

  const changeFilter = fieldName => {
    // eslint-disable-next-line no-console
    setSortFields(fieldName, [{}]);
    // Object.keys(initialSortFields).forEach(field => {
    //   initialSortFields[field] = [{}];
    // });

    // const changedField = fieldName[0];

    // initialSortFields[changedField.field] = [changedField];

    // if (changedField.direction === 'asc') {
    //   currentUrlParams.set('sort', `${changedField.field},asc`);
    // } else {
    //   currentUrlParams.set('sort', `${changedField.field},desc`);
    // }

    // props.history.push({
    //   pathname: '/parking',
    //   search: `?${decodeURIComponent(currentUrlParams.toString())}`,
    // });

    // setSortFields(initialSortFields);
  };

  return (
    <TableRow>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[DOCUMENT_NAME_KEY]}
          field={DOCUMENT_NAME_KEY}
          setSort={changeFilter}
        >
          Название документа
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[UPLOAD_DATE_KEY]}
          field={UPLOAD_DATE_KEY}
          setSort={changeFilter}
        >
          Дата загрузки
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell style={{ textAlign: 'center' }}>
        Действия
      </CondenseTableCell>
    </TableRow>
  );
};

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
