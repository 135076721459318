import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';

class RentPlace extends Component {
  static propTypes = {
    onDeselect: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    rentId: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    Shape: PropTypes.any.isRequired,
  };

  render() {
    const { children, classes, onDeselect, Shape, ...rest } = this.props;

    return (
      <Shape
        {...omit(['onOpen', 'rentId'], rest)}
        className={classes.root}
        onClick={this.handleClick}
        onMouseMove={this.handleHover}
        onMouseOut={onDeselect}
      >
        {children}
      </Shape>
    );
  }

  handleClick = () => {
    const { onOpen, rentId } = this.props;

    onOpen(rentId);
  };

  handleHover = event => {
    const { onSelect, rentId } = this.props;
    const { clientX, clientY } = event;

    onSelect(rentId, clientX, clientY);
  };
}

export default RentPlace;
