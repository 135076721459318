import {
  getNameForParkingWhitelistGroup,
  getUsersForParkingWhitelistGroup,
} from '@esentai/core/features/parkingWhitelistGroups/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: parkingWhitelistGroupId }) => ({
  name: getNameForParkingWhitelistGroup(state, parkingWhitelistGroupId),
  users: getUsersForParkingWhitelistGroup(state, parkingWhitelistGroupId),
  parkingWhitelistGroupId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
