import {
  CAMPAIGN_ACTION_RESULT_TRIGGER_KEY,
  CAMPAIGN_BANNER_TYPE_KEY,
  CAMPAIGN_BUTTON_PLACEMENT_KEY,
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_REWARD_TYPE_KEY,
} from '@/features/campaignsStatistics/consts/dimensions';
import { createActionResultTriggerFilter } from '@/filters/CampaignActionResultTrigger';
import { createBannerTypeFilter } from '@/filters/CampaignBannerType';
import { createButtonPlacementFilter } from '@/filters/CampaignButtonPlacement';
import { createCampaignRewardTypeFilter } from '@/filters/CampaignRewardType';
import { createLocationFilter } from '@/filters/Location';

export const filterTypes = [
  createLocationFilter({
    key: CAMPAIGN_LOCATION_ID_KEY,
    label: 'Локация проведения',
  }),
  createButtonPlacementFilter({
    key: CAMPAIGN_BUTTON_PLACEMENT_KEY,
    label: 'Место размещения кнопки',
  }),
  createCampaignRewardTypeFilter({
    key: CAMPAIGN_REWARD_TYPE_KEY,
    label: 'Награда',
  }),
  createBannerTypeFilter({
    key: CAMPAIGN_BANNER_TYPE_KEY,
    label: 'Тип баннера',
  }),
  createActionResultTriggerFilter({
    key: CAMPAIGN_ACTION_RESULT_TRIGGER_KEY,
    label: 'Условие поведения',
  }),
];
