import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';
import { formatDateTime } from '@/utils/format';

import SynchronizeButton from './SynchronizeButton';

class SiteSyncPage extends Component {
  static defaultProps = {
    lastSync: null,
  };

  static propTypes = {
    fetchSettings: PropTypes.func.isRequired,
    isFinished: PropTypes.bool.isRequired,
    lastSync: PropTypes.instanceOf(Date),
  };

  componentDidMount() {
    const { fetchSettings } = this.props;

    fetchSettings();
  }

  render() {
    const { isFinished, lastSync } = this.props;

    if (!isFinished) {
      return null;
    }

    return (
      <SettingsPage>
        <SettingsPageHeader>
          <SettingsPageTitle>Синхронизация</SettingsPageTitle>
        </SettingsPageHeader>
        <SettingsPageContent>
          <Typography gutterBottom>
            Нажмите на кнопку, чтобы отправить изменения брендов и магазинов на
            сайт <a href="https://esentaimall.com/">esentaimall.com</a>.{' '}
            {lastSync &&
              `Дата последней синхронизации: ${formatDateTime(lastSync)}`}
          </Typography>
          <SynchronizeButton />
        </SettingsPageContent>
      </SettingsPage>
    );
  }
}

export default SiteSyncPage;
