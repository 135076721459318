import {
  STORE_NAME_KEY,
  TITLE_RU_KEY,
  USED_DATETIME_KEY,
  USER_ID_KEY,
} from '@esentai/core/features/benefitItems/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <TableCell>ID</TableCell>
    <TableCell>
      <SortControl field={USER_ID_KEY}>ID пользователя</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={TITLE_RU_KEY}>Название акции</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={STORE_NAME_KEY}>Название магазина</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={USED_DATETIME_KEY}>
        Дата и время использования
      </SortControl>
    </TableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
