import {
  getBenefitRewardIds,
  getDelayAfterTrigger,
  getDestination,
  getLoyaltyPointsReward,
  getPushEnglishMessage,
  getPushEnglishTitle,
  getPushRussianMessage,
  getPushRussianTitle,
  getReferredArtcleId,
  getReferredBenefitId,
  getReferredCalendarEventId,
  getReferredStoreId,
  getRewardType,
  getTrackedLocationIds,
  getTrigger,
  hasDelayAfterTrigger,
  shouldActivateOnce,
  shouldSendPushNotifications,
} from '@esentai/core/features/campaigns/actionResult/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepThree } from '@/features/campaignCreatePage/actions';
import { getCampaignId } from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStepArticle from './component';
import form from './form';

const mapStateToProps = state => {
  const id = getCampaignId(state);

  return {
    activateOnce: shouldActivateOnce(state, id),
    benefitRewardIds: getBenefitRewardIds(state, id),
    delayAfterTrigger: getDelayAfterTrigger(state, id),
    destination: getDestination(state, id),
    hasDelayAfterTrigger: hasDelayAfterTrigger(state, id),
    loyaltyPointsReward: getLoyaltyPointsReward(state, id),
    pushEnglishTitle: getPushEnglishTitle(state, id),
    pushRussianTitle: getPushRussianTitle(state, id),
    pushEnglishMessage: getPushEnglishMessage(state, id),
    pushRussianMessage: getPushRussianMessage(state, id),
    referredArtcleId: getReferredArtcleId(state, id),
    referredBenefitId: getReferredBenefitId(state, id),
    referredStoreId: getReferredStoreId(state, id),
    referredCalendarEventId: getReferredCalendarEventId(state, id),
    sendPushNotifications: shouldSendPushNotifications(state, id),
    rewardType: getRewardType(state, id),
    trackedLocationIds: getTrackedLocationIds(state, id),
    trigger: getTrigger(state, id),
    id,
  };
};

const mapDispatchToProps = { onSubmit: goToStepThree };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
)(SecondStepArticle);
