import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose, isNil, not } from 'ramda';
import React from 'react';

import CategoriesInput from '@/components/CategoriesInput';
import FieldRow from '@/components/FieldRow';
import Quadrolingual from '@/components/Quadrolingual';

import Image from '../Image';
import Label from '../Label';

const isPresent = compose(not, isNil);

const Description = ({
  categories,
  chineseDescription,
  englishDescription,
  kazakhDescription,
  logoUrl,
  russianDescription,
}) => (
  <FieldRow name="Описание магазина">
    <Grid container>
      <Grid item>{isPresent(logoUrl) && <Image src={logoUrl} />}</Grid>
      <Grid item>
        {(isPresent(englishDescription) || isPresent(russianDescription)) && (
          <Label title="Краткое описание">
            <Quadrolingual
              chineseContent={chineseDescription}
              englishContent={englishDescription}
              kazakhContent={kazakhDescription}
              russianContent={russianDescription}
            />
          </Label>
        )}
        {isPresent(categories) && (
          <Label title="Категории">
            <CategoriesInput disabled selectedItem={categories} />
          </Label>
        )}
      </Grid>
    </Grid>
  </FieldRow>
);

Description.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  chineseDescription: PropTypes.string,
  englishDescription: PropTypes.string,
  kazakhDescription: PropTypes.string,
  logoUrl: PropTypes.string,
  russianDescription: PropTypes.string,
};

Description.defaultProps = {
  categories: null,
  chineseDescription: null,
  englishDescription: null,
  kazakhDescription: null,
  logoUrl: null,
  russianDescription: null,
};

export default Description;
