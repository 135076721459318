import {
  BIN_KEY,
  COMPANY_KEY,
  FACT_ADDRESS_KEY,
  LEGAL_ADDRESS_KEY,
  MOBILE_PHONE_KEY,
  PRESIDENT_FULLNAME_KEY,
  RENT_AREA_INFO_KEY,
  STATUS,
  WORK_PHONE_KEY,
} from '@esentai/core/features/merchants/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';
import { canUpdateMerchant } from '@/features/merchantsPage/permissions';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={BIN_KEY}>БИН/ИИН</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={COMPANY_KEY}>Компания</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={MOBILE_PHONE_KEY}>Сотовый телефон</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={WORK_PHONE_KEY}>Рабочий телефон</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={PRESIDENT_FULLNAME_KEY}>ЛПР/Директор</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={RENT_AREA_INFO_KEY}>
        Информация об арендуемой площади
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={LEGAL_ADDRESS_KEY}>Юридический адрес</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={FACT_ADDRESS_KEY}>Фактический адрес</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={STATUS}>Статус</SortControl>
    </CondenseTableCell>

    <CanView permission={canUpdateMerchant}>
      <CondenseTableCell>Действия</CondenseTableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
