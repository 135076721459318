import { descending } from '@esentai/core/query-dsl';
import { date, sort } from '@esentai/core/serializers/params';

import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';
import { getStatPageUrl } from '@/features/statisticsPage/utils';
import { DATE_RANGE_KEY } from '@/features/usersStatistics/consts/filterKeys';
import { annotations } from '@/features/usersStatistics/serializer';
import { createPageSerializer, getLinkCreator } from '@/serializers';

import { ROUTE_PATH } from './consts';
import { defaultDateRange } from './consts/filters';

const serializer = createPageSerializer({
  ...annotations,

  [DATE_RANGE_KEY]: date({
    defaultValue: defaultDateRange,
  }),

  sort: sort({
    defaultValue: descending(TIMEFRAME_KEY),
  }),
});

export default serializer;

export const createLink = getLinkCreator(ROUTE_PATH, serializer);

export const createPageUrl = (path, query) => {
  const linkCreator = getLinkCreator(path, serializer);

  return linkCreator(query);
};

export const createStatPageUrl = (stats, query) => {
  const path = getStatPageUrl('users', stats);
  const linkCreator = getLinkCreator(path, serializer);

  return linkCreator(query);
};
