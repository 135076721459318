import { getLocation } from 'connected-react-router';
import { connect } from 'react-redux';
import { Switch } from 'react-router';

/**
 * Original Switch gets current `location` from Router's context by default,
 * which is OK for most cases, but once user pressed browser's back button,
 * store's `location` and router's `location` become different for some time,
 * which means that improper route with improper match params could be rendered.
 * This `connect` solves this problem.
 */

const mapStateToProps = state => ({
  location: getLocation(state),
});

export default connect(mapStateToProps, {})(Switch);
