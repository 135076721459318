export const ID_KEY = 'id';
export const VEHICLE_GOV_NUMBER_KEY = 'vehicle_gov_number';
export const GATE_ID_KEY = 'gate_id';
export const GATE_NAME_KEY = 'gate_name';
export const CLIENT_TIMESTAMP_KEY = 'client_timestamp';
export const CUSTOMER_KEY = 'customer';
export const CUSTOMER_ID_KEY = 'customer.id';
export const CUSTOMER_EMAIL_KEY = 'customer.email';
export const CUSTOMER_FIRST_NAME_KEY = 'customer.first_name';
export const CUSTOMER_FULL_NAME_KEY = 'customer.full_name';
export const CUSTOMER_LAST_NAME_KEY = 'customer.last_name';
export const CUSTOMER_LEVEL_KEY = 'customer.level';
export const CUSTOMER_PHONE_KEY = 'customer.phone';
export const CUSTOMER_STATE_KEY = 'customer.state';
export const RECORD_CREATED_KEY = 'record_created';
