import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Field = ({ children, classes, label }) => (
  <div className={classes.field}>
    <Typography variant="subtitle2">{label}:</Typography>
    <Typography
      variant="subtitle1"
      style={{ marginLeft: '16px', fontStyle: 'italic' }}
    >
      {children}
    </Typography>
  </div>
);

Field.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Field;
