import React from 'react';

const MallReception = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <div className={classes.logoWrapper} />
    </div>
  </div>
);

export default MallReception;
