import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { isEmpty } from 'ramda';
import React from 'react';

import { formatHumanDateTime } from '../../utils/format';
import { userStatus } from '../../utils/labels';

interface UserFilter {
  filter: 'birth_date' | 'gender' | 'record_created' | 'level';
  values: {
    operator: 'eq' | 'lte' | 'gte' | 'in';
    value: any;
  }[];
}

interface BonusFiltersProps {
  filter: any;
}

interface BonusFiltersState {
  user?: UserFilter[];
}

export class BonusFilters extends React.Component<
  BonusFiltersProps,
  BonusFiltersState
> {
  constructor(props: BonusFiltersProps) {
    super(props);

    let obj;

    if (
      Boolean(props.filter) &&
      Object.prototype.toString.call(props.filter) === '[object Map]'
    ) {
      obj = Array.from(props.filter.get('filters')).reduce(
        (obj, [key, value]) => {
          obj[key] = value;

          return obj;
        },
        {},
      );
    } else {
      obj = JSON.parse(props.filter);
    }

    if (obj && obj.user) {
      const dict = {};

      obj.user.forEach(u => {
        const [left, value] = u.split('=');
        const [filter, operator] = left.replace(']', '').split('[');

        if (dict[filter]) {
          dict[filter].push({ operator, value });
        } else {
          dict[filter] = [{ operator, value }];
        }
      });

      const tempArr = Object.keys(dict).map(filter => ({
        filter,
        values: dict[filter],
      }));

      this.state = {
        // @ts-ignore
        user: [tempArr] as UserFilter[],
      };
    } else if (!isEmpty(obj) && obj && !obj.user) {
      // convert from Map to array
      const filters = Object.keys(obj).reduce((acc, cur) => {
        const temp = {};

        temp[cur] = Array.from(obj[cur]).reduce((obj, [key, value]) => {
          obj[key] = value;

          return obj;
        }, {});
        acc.push(temp);

        return acc;
      }, []);

      const tempFilter = [];

      filters.map(filter => {
        const temp = Object.keys(filter).map(item => {
          const values = Object.keys(filter[item]).reduce((acc, cur) => {
            const tempObj = {};

            tempObj.operator = cur;
            tempObj.value = filter[item][cur];
            acc.push(tempObj);

            return acc;
          }, []);

          return {
            filter: item,
            values,
          };
        });

        tempFilter.push(temp);

        return temp;
      });

      this.state = {
        user: tempFilter as UserFilter[],
      };
    } else {
      this.state = {};
    }
  }

  render(): React.ReactNode {
    const { user = [] } = this.state;

    if (!user || user.length === 0) {
      return <Chip label="Все пользователи" icon={<DoneIcon />} />;
    }

    return user.map(u => (
      <Chip key={u[0].filter} label={`${this.filterProps(u[0])} `} />
    ));
  }

  private filterProps(filter: UserFilter): string {
    switch (filter.filter) {
      case 'gender':
        return `Пол: ${
          filter.values[0].value === 'male' ? 'Мужской' : 'Женский'
        }`;
      case 'level':
        return `Статус: ${filter.values
          .map(v =>
            // let items = v.value.split(',');
            v.value.map(val => userStatus(val)),
          )
          .join(', ')}`;
      case 'birth_date':
        return `День рождения: ${filter.values.map(v => v.value).join(' - ')}`;
      case 'record_created':
        return `Дата регистрации: ${filter.values
          .map(v => formatHumanDateTime(v.value))
          .join(' - ')}`;
      default:
        return '';
    }
  }
}
