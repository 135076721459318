import { getLocationIdForStore } from '@esentai/core/features/stores/selectors';
import { equals, query, where } from '@esentai/core/query-dsl';
import { multiply, path, pipe, prop } from 'ramda';

import {
  AVG_NR_PURCHASES_KEY,
  AVG_NR_RECEIPTS_KEY,
  AVG_TICKET_KEY,
  BY_CAMPAIGN_KEY,
  LOCATION_ID_KEY,
  NR_RECEIPTS_KEY,
  PERCENT_RECEIPTS_BLACK_LEVEL_KEY,
  PERCENT_RECEIPTS_DAY_KEY,
  PERCENT_RECEIPTS_EVENING_KEY,
  PERCENT_RECEIPTS_JETBLACK_LEVEL_KEY,
  PERCENT_RECEIPTS_MORNING_KEY,
  PERCENT_RECEIPTS_ORANGE_LEVEL_KEY,
  PERCENT_RECEIPTS_SILVER_LEVEL_KEY,
  PERCENT_RECEIPTS_WHITE_LEVEL_KEY,
} from '@/features/purchasesStatistics/consts/keys';
import daoDuck from '@/features/purchasesStatistics/ducks/dao';
import {
  META_TIME_SCALE_KEY,
  META_TOTAL_HEADER,
} from '@/features/statistics/consts/keys';
import { createItemsSelectors } from '@/features/statisticsPage/selectors';
import { getStatPageUrl } from '@/features/statisticsPage/utils';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { createFullPageUrl } from './serializer';
import stats from './stats';

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;

export const {
  getError,
  isLoadingItems,
  getItemsMeta,
  getItemsTotalCount,
} = itemsDuck.selectors;

export const getScale = pipe(getItemsMeta, prop(META_TIME_SCALE_KEY));

export const {
  getItemsIds,
  getXValues,
  getLeftYValues,
  getRightYValues,
  getDateBySelectedIndex,
  getPropBySelectedIndex,
} = createItemsSelectors(itemsDuck, queryDuck, daoDuck, stats);

const getPropFromTotalMeta = key => state =>
  path([META_TOTAL_HEADER, key], getItemsMeta(state));

const getItemPercentagePropById = property =>
  pipe(getPropFromTotalMeta(property), multiply(100));

export const getTotalAveragePurchasesById = getPropFromTotalMeta(
  AVG_NR_PURCHASES_KEY,
);
export const getTotalAverageReceiptsById = getPropFromTotalMeta(
  AVG_NR_RECEIPTS_KEY,
);
export const getTotalAverageTicketById = getPropFromTotalMeta(AVG_TICKET_KEY);

export const getTotalPercentageReceiptsByCampaignById = getPropFromTotalMeta(
  BY_CAMPAIGN_KEY,
);
export const getTotalPurchasesCountById = getItemPercentagePropById(
  NR_RECEIPTS_KEY,
);
export const getTotalPercentageReceiptsByBlackUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_BLACK_LEVEL_KEY,
);
export const getTotalPercentageReceiptsBySilverUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_SILVER_LEVEL_KEY,
);
export const getTotalPercentageReceiptsByWhiteUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_WHITE_LEVEL_KEY,
);
export const getTotalPercentageReceiptsByJetBlackUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_JETBLACK_LEVEL_KEY,
);
export const getTotalPercentageReceiptsByOrangeUserStatusById = getItemPercentagePropById(
  PERCENT_RECEIPTS_ORANGE_LEVEL_KEY,
);
export const getTotalPercentageReceiptsByMorningById = getItemPercentagePropById(
  PERCENT_RECEIPTS_MORNING_KEY,
);
export const getTotalPercentageReceiptsByDayById = getItemPercentagePropById(
  PERCENT_RECEIPTS_DAY_KEY,
);
export const getTotalPercentageReceiptsByEveningById = getItemPercentagePropById(
  PERCENT_RECEIPTS_EVENING_KEY,
);

export const getDefaultMetricsPageUrl = () =>
  getStatPageUrl('purchases', [stats[0].url_key, stats[1].url_key]);

export const createPageUrl = (state, queryObject) => {
  const url = getDefaultMetricsPageUrl(state);

  return createFullPageUrl(url, queryObject);
};

export const getStorePurchasesStatisticsUrlById = (state, storeId) => {
  const locationId = getLocationIdForStore(state, storeId);

  return createPageUrl(
    state,
    query(where(LOCATION_ID_KEY, equals(locationId))),
  );
};
