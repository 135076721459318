import { findParkingWhitelistGroupById } from '@esentai/core/features/parkingWhitelistGroups/actions';
import {
  getNameForParkingWhitelistGroup,
  isParkingWhitelistGroupLoaded,
  isParkingWhitelistGroupLoading,
} from '@esentai/core/features/parkingWhitelistGroups/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import WhitelistNameLazy from './component';

const mapStateToProps = (state, { groupId }) => ({
  isLoaded: isParkingWhitelistGroupLoaded(state, groupId),
  isLoading: isParkingWhitelistGroupLoading(state, groupId),
  itemId: groupId,
  title: getNameForParkingWhitelistGroup(state, groupId),
});

const mapDispatchToProps = { fetchItem: findParkingWhitelistGroupById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(WhitelistNameLazy);
