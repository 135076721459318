import {
  FACTORY_NUMBER_KEY,
  KKM_NUMBER_KEY,
  MERCHANT_ID_KEY,
  STATUS_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/kkms/consts/keys';
import {
  getFactoryNumberForKkm,
  getKkmNumberForKkm,
  getMerchantIdForKkm,
  getStatusForKkm,
  getStoreIdForKkm,
} from '@esentai/core/features/kkms/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { boolean, number, object, string } from 'yup';

import withFormikValidation from '@/hocs/withFormikValidation';

import KkmForm from './component';
import styles from './styles';

const formikOptions = {
  mapPropsToValues: ({ kkmNumber, kkmStatus, storeId, merchant, code }) => ({
    [KKM_NUMBER_KEY]: kkmNumber,
    [STORE_ID_KEY]: storeId,
    [MERCHANT_ID_KEY]: merchant,
    [STATUS_KEY]: kkmStatus,
    [FACTORY_NUMBER_KEY]: code,
  }),

  validationSchema: object().shape({
    [KKM_NUMBER_KEY]: string()
      .max(30)
      .required(),
    [MERCHANT_ID_KEY]: number().required(),
    [STORE_ID_KEY]: number().required(),
    [FACTORY_NUMBER_KEY]: string().max(9),
    kkmStatus: boolean(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };
const mapStateToProps = (state, { kkmId }) => ({
  kkmNumber: getKkmNumberForKkm(state, kkmId),
  merchant: getMerchantIdForKkm(state, kkmId),
  storeId: getStoreIdForKkm(state, kkmId),
  kkmStatus: getStatusForKkm(state, kkmId),
  code: getFactoryNumberForKkm(state, kkmId),
});

export default compose(
  connect(mapStateToProps),
  withAsyncRunner(asyncOptions),
  withFormikValidation(formikOptions),
  withStyles(styles),
)(KkmForm);
