import { select } from 'd3-selection';
import { curveMonotoneX, line } from 'd3-shape';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';

class Line extends Component {
  static propTypes = {
    xScale: PropTypes.func.isRequired,
    xs: PropTypes.arrayOf(PropTypes.any).isRequired,
    yScale: PropTypes.func.isRequired,
    ys: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  pathElement = createRef();

  componentDidMount() {
    this.renderD3();
  }

  componentDidUpdate() {
    this.renderD3();
  }

  getLineScale = () => {
    const { xScale, xs, yScale, ys } = this.props;

    return line()
      .curve(curveMonotoneX)
      .x((_, i) => xScale(xs[i]))
      .y((_, i) => yScale(ys[i]));
  };

  render() {
    return <path ref={this.pathElement} />;
  }

  renderD3() {
    const element = select(this.pathElement.current);
    const { classes, xs } = this.props;
    const lineScale = this.getLineScale();

    element.attr('class', classes.line).attr('d', lineScale(xs));
  }
}

export default Line;
