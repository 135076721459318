import {
  REPAY,
  RESOURCE_NAME as RECONCILIATION_RESOURCE,
} from '@esentai/core/features/reconciliation/consts';
import { RESOURCE_NAME } from '@esentai/core/features/reconciliationPeriodBonusDetails/consts';
import {
  can,
  canCreate,
  canRead,
  canUpdate,
  permission,
} from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadReconciliationPeriodBonusDetails = authorizeIf(
  canRead(RESOURCE_NAME),
);
export const canCreateReconciliationPeriodBonusDetails = authorizeIf(
  canCreate(RESOURCE_NAME),
);
export const canUpdateReconciliationPeriodBonusDetails = authorizeIf(
  canUpdate(RESOURCE_NAME),
);

export const canRepayReconciliation = authorizeIf(
  can(permission(RECONCILIATION_RESOURCE, REPAY)),
);
