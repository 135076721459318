import {
  getReceiptCashMachineName,
  getReceiptIssueDate,
  getReceiptPurchasesCount,
  getReceiptTotalCost,
} from '@esentai/core/features/receipts/selectors';
import { connect } from 'react-redux';

import Row from './component';

const mapStateToProps = (state, { item: receiptId }) => ({
  cashMachineName: getReceiptCashMachineName(state, receiptId),
  issueDate: getReceiptIssueDate(state, receiptId),
  purchasesCount: getReceiptPurchasesCount(state, receiptId),
  totalCost: getReceiptTotalCost(state, receiptId),
});

export default connect(mapStateToProps)(Row);
