import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import TransferBonusesForm from './component';
import styles from './styles';

const errorsMapping = {};
const formikOptions = {};

const asyncOptions = { runnerMethod: 'onSubmit' };

const mapStateToProps = state => ({
  state,
});

export default compose(
  connect(mapStateToProps, () => ({})),
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(TransferBonusesForm);
