import { connect } from 'react-redux';

import TooltipContent from '@/components/StatisticsTooltipContent';
import { getStatsIds } from '@/features/statisticsPage/selectors';
import {
  getDateBySelectedIndex,
  getPropBySelectedIndex,
  getScale,
} from '@/features/usersStatisticsPage/selectors';
import stats from '@/features/usersStatisticsPage/stats';

const mapStateToProps = (state, { selectedDateIndex }) => ({
  date: getDateBySelectedIndex(state, selectedDateIndex),
  selectedStatsIds: getStatsIds(state),
  scale: getScale(state),
  getValue: getPropBySelectedIndex,
  stats,
});

export default connect(mapStateToProps)(TooltipContent);
