import {
  DELAY_AFTER_PURCHASE_KEY,
  HAS_DELAY_AFTER_PURCHASE_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { mapProps } from 'recompose';

import DelayForm from './component';
import styles from './styles';

export default compose(
  mapProps(({ setFieldValue, values, Field }) => ({
    delayAfterPurchase: values[DELAY_AFTER_PURCHASE_KEY],
    hasDelayAfterPurchase: values[HAS_DELAY_AFTER_PURCHASE_KEY],
    setFieldValue,
    Field,
  })),
  withStyles(styles),
)(DelayForm);
