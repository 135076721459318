import { Button, Snackbar, SnackbarContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import WarningMessage from './WarningMessage';

const CancelSnackbar = ({ classes, open, cancel }) => (
  <Snackbar open={open} variant="error">
    <SnackbarContent
      message={<WarningMessage />}
      action={
        <Button size="small" color="inherit" onClick={cancel}>
          Отменить
        </Button>
      }
      className={classes.root}
    />
  </Snackbar>
);

CancelSnackbar.propTypes = {
  cancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CancelSnackbar;
