import { PLACEMENT_KEY } from '@esentai/core/features/campaigns/button/consts/keys';
import React, { Component, Fragment } from 'react';

import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import FormikPropTypes from '@/prop-types/formik';

import { CanEditRadioBoxGroup } from '../../CanEdit';

class PlacementForm extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { classes, handleChange, values } = this.props;

    return (
      <Fragment>
        <FieldRow
          label="Размещение"
          subLabel="Размещение кнопки"
          sudDesc="Кнопка может размещаться на установленных заранее позициях. Установить позиции можно в настройках главной ленты мобильного приложения."
        >
          <CanEditRadioBoxGroup field={PLACEMENT_KEY}>
            <RadioBoxGroup
              name={PLACEMENT_KEY}
              value={values[PLACEMENT_KEY]}
              onChange={handleChange}
              classes={{ radioBoxGroup: classes.typeSelect }}
            >
              <RadioBox value="top" label="Первый блок кнопки" />
              <RadioBox value="bottom" label="Второй блок кнопки" />
            </RadioBoxGroup>
          </CanEditRadioBoxGroup>
        </FieldRow>
      </Fragment>
    );
  }
}

export default PlacementForm;
