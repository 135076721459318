import { grey } from '@material-ui/core/colors';

export default {
  typographyRoot: {
    textDecoration: 'none',
    '&:hover': {
      color: grey[800],
    },
  },

  arrowIcon: {
    margin: [0, 8],
    fontSize: 20,
    color: grey[600],
  },
};
