export default {
  wrapper: {
    outline: 0,
    width: 740,
    padding: 54,
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 50,
  },
};
