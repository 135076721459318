import {
  ID_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { ONLINE_SHOWCASE_LAYOUT } from '@esentai/core/features/campaigns/article/consts/layouts';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ConstructorBlock from '@/components/ConstructorBlock';
import ConstructorBlockTypeLabel from '@/components/ConstructorBlock/TypeLabel';
import ConstructorBlockTypeSelect from '@/components/ConstructorBlock/TypeSelect';

import limits from './limits';
import { dataCreatorMapping, typeMapping } from './mappings';
import OrderSwitcher from './OrderSwitcher';
import { getLabelByType, getOptions } from './utils';

class Block extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    countByType: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    Field: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onUp: PropTypes.func,
    onDown: PropTypes.func,
    position: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    position: null,
    required: false,
    countByType: {},
    onRemove: () => {},
    onUp: () => {},
    onDown: () => {},
  };

  getType = () => {
    const { data } = this.props;

    return data[TYPE_KEY];
  };

  handleRemove = () => {
    const { data, onRemove } = this.props;

    onRemove(data);
  };

  handleChangeType = newType => {
    const { onChange, data } = this.props;
    const type = data[TYPE_KEY];

    if (newType === type) {
      return;
    }

    const dataCreator = dataCreatorMapping[newType];
    const newData = dataCreator(data[ID_KEY]);

    onChange(newData);
  };

  handleUp = () => this.props.onUp(this.props.data);

  handleDown = () => this.props.onDown(this.props.data);

  isDisabled = () => {
    const { disabled, required } = this.props;

    return required || disabled;
  };

  renderBlockByType = () => {
    const {
      data,
      errors,
      Field,
      index,
      required,
      setFieldValue,
      disabled,
    } = this.props;
    const type = this.getType();
    const Component = typeMapping[type];

    return Component ? (
      <Component
        data={data}
        errors={errors}
        Field={Field}
        index={index}
        required={required}
        setFieldValue={setFieldValue}
        disabled={disabled}
      />
    ) : null;
  };

  renderOrderSwitcher = props => {
    const { countByType } = this.props;
    const selectedType = this.getType();

    return (
      <OrderSwitcher
        {...props}
        currentCount={countByType[selectedType]}
        maxCount={limits[selectedType]}
      />
    );
  };

  renderTypeLabel = ({ type, ...rest }) => (
    <ConstructorBlockTypeLabel {...rest} type={type}>
      {getLabelByType(type)}
    </ConstructorBlockTypeLabel>
  );

  renderTypeSelect = props => {
    const { countByType, data } = this.props;

    if (data.type === ONLINE_SHOWCASE_LAYOUT) {
      return null;
    }

    return (
      <ConstructorBlockTypeSelect
        {...props}
        options={getOptions(countByType)}
      />
    );
  };

  render() {
    const { position } = this.props;
    const disabled = this.isDisabled();
    const type = this.getType();

    return (
      <ConstructorBlock
        canMoveDown={['start', 'middle'].includes(position)}
        canMoveUp={['end', 'middle'].includes(position)}
        disabled={disabled}
        type={type}
        onMoveDown={this.handleDown}
        onMoveUp={this.handleUp}
        onRemove={this.handleRemove}
        onTypeChange={this.handleChangeType}
        Content={this.renderBlockByType}
        OrderSwitcher={this.renderOrderSwitcher}
        TypeLabel={this.renderTypeLabel}
        TypeSelect={this.renderTypeSelect}
      />
    );
  }
}

export default Block;
