import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router';

import AppLoading from '@/components/AppLoading';
import LoadErrorMessage from '@/components/ErrorMessages/LoadErrorMessage';
import { LOGIN_PATH } from '@/features/auth/consts';

const ProtectedRoute = ({
  authorize,
  authorized,
  authorizeError,
  sessionExpired,
  authorizing,
  classes,
  component: Component,
  ...rest
}) => {
  const renderOrRedirect = props => {
    if (authorizeError && !sessionExpired) {
      return (
        <div className={classes.error}>
          <LoadErrorMessage
            error={authorizeError}
            isLoading={authorizing}
            title="Не удалось войти в приложение"
            subtitle="Возникла ошибка, попробуйте снова"
            retry={authorize}
          />
        </div>
      );
    }

    if (authorizing) {
      return <AppLoading />;
    }

    if (authorized) {
      return <Component {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: LOGIN_PATH,
          // eslint-disable-next-line react/prop-types
          state: { from: props.location },
        }}
      />
    );
  };

  renderOrRedirect.propTypes = {
    location: PropTypes.isRequired,
  };

  return <Route {...rest} render={renderOrRedirect} />;
};

ProtectedRoute.propTypes = {
  authorize: PropTypes.func.isRequired,
  authorized: PropTypes.bool.isRequired,
  authorizeError: PropTypes.any,
  sessionExpired: PropTypes.bool.isRequired,
  authorizing: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

ProtectedRoute.defaultProps = {
  authorizeError: null,
};

export default ProtectedRoute;
