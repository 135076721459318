import { REWARD_LOYALTY_POINTS_KEY } from '@esentai/core/features/campaigns/actionResult/consts/keys';
import { TextField } from '@material-ui/core';
import React, { Component } from 'react';

import FormikPropTypes from '@/prop-types/formik';

class LoyaltyPointsForm extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { Field, disabled } = this.props;

    return (
      <Field
        disabled={disabled}
        Target={TextField}
        name={REWARD_LOYALTY_POINTS_KEY}
        label="Количество бонусов"
      />
    );
  }
}

export default LoyaltyPointsForm;
