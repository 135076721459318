import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CloneButton from '@/features/campaignsPage/Page/Row/buttons/CloneButton';
import DeactivateButton from '@/features/campaignsPage/Page/Row/buttons/DeactivateButton';

const ActiveStatusActions = ({ itemId }) => (
  <Fragment>
    <CloneButton itemId={itemId} small={false} color="primary" />
    <DeactivateButton
      itemId={itemId}
      small={false}
      color="primary"
      variant="contained"
    />
  </Fragment>
);

ActiveStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default ActiveStatusActions;
