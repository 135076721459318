export default {
  root: {
    border: [3, 'solid', '#ff4d00'],
    padding: [12, 16],
    marginBottom: 20,
  },

  name: {
    textDecoration: 'underline',
    fontFamily: 'Giorgio Sans',
    fontWeight: 'bold',
    fontStyle: 'italic',
    textTransform: 'uppercase',
    fontSize: 21,
    marginBottom: 8,
    color: '#ff4d00',
    letterSpacing: 1,
  },

  floor: {
    fontSize: 10,
    fontFamily: 'Graphik LCG',
  },
};
