import {
  getBonusesRefundedAmount,
  getBonusesSpentAmount,
  getLastReconciliationDate,
  getMerchantId,
  getReconciliationCreatedDate,
  getTotalToPayAmount,
} from '@esentai/core/features/merchantsReconciliation/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { retryFetch } from '../../actions';
import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: reconciliationId }) => ({
  lastReconciliationDate: getLastReconciliationDate(state, reconciliationId),
  reconciliationCreatedDate: getReconciliationCreatedDate(
    state,
    reconciliationId,
  ),
  bonusesRefundedAmount: getBonusesRefundedAmount(state, reconciliationId),
  bonusesSpentAmount: getBonusesSpentAmount(state, reconciliationId),
  merchantId: getMerchantId(state, reconciliationId),
  totalToPayAmount: getTotalToPayAmount(state, reconciliationId),
  reconciliationId,
});

const mapDispatchToProps = {
  fetchItems: retryFetch,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Row);
