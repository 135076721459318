import { Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import DnDUploader from '@/components/DnDUploader';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

class CategoryForm extends Component {
  state = {
    images: this.props.initialValues.images || [],
  };

  setImages = urls => {
    this.setState({
      images: urls,
    });
    this.handleChange(urls);
  };

  handleChange = urls => {
    const { setFieldValue } = this.props;

    setFieldValue('images', urls);
  };

  render() {
    const {
      Form,
      Field,
      SubmitButton,
      RequiredMessages,
      onClose,
      isEdit,
    } = this.props;

    const { images } = this.state;

    return (
      <Form>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Field
              Target={TextField}
              id="name_ru"
              name="name_ru"
              label="Название RU"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Field
              Target={TextField}
              id="name_en"
              name="name_en"
              label="Название EN"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Field
              Target={TextField}
              id="name_kz"
              name="name_kz"
              label="Название KZ"
              fullWidth
            />
          </Grid>
          <Grid item alignContent="center" justify="center">
            <DnDUploader
              setImages={this.setImages}
              images={images}
              disabled={false}
              width={925}
            />
          </Grid>
          <Grid item>
            <ButtonSet>
              <Button onClick={onClose} size="large">
                Отменить
              </Button>
              <SubmitButton
                Target={AsyncButton}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                {isEdit ? 'Сохранить изменения' : 'Создать'}
              </SubmitButton>
            </ButtonSet>
          </Grid>
          <Grid item>
            <RequiredMessages
              fieldNames={{
                name_ru: 'Название RU',
                name_en: 'Название EN',
                name_kz: 'Название KZ',
                images: 'Изображения',
              }}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

CategoryForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default CategoryForm;
