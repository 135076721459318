import { findProductById } from '@esentai/core/features/products/actions';
import {
  getProductLoadingError,
  isProductLoaded,
  isProductLoading,
} from '@esentai/core/features/products/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import { updateProduct } from '@/features/productEditPage/actions';
import { fetchProduct as fetchItem } from '@/features/productPage/actions';
import { getRouterParameterByMatch } from '@/redux/router/selectors';

import component from './component';

const ID_KEY = 'productId';

const mapStateToProps = (state, { match }) => {
  const productId = getRouterParameterByMatch(match, ID_KEY);

  return {
    error: getProductLoadingError(state, productId),
    isLoaded: isProductLoaded(state, productId),
    isLoading: isProductLoading(state, productId),
    productId,
  };
};

const mapDispatchToProps = (dispatch, { match }) => ({
  updateProduct: values =>
    dispatch(
      updateProduct({
        ...values,
        productId: getRouterParameterByMatch(match, ID_KEY),
      }),
    ),
  fetchItem,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    fetchItem: findProductById,
    getItemLoadingError: getProductLoadingError,
    isItemLoaded: isProductLoaded,
    isItemLoading: isProductLoading,
  }),
)(component);
