import PropTypes from 'prop-types';
import React from 'react';

import FormatterLayout from '../FormatterLayout';
import { formatMonthTick } from '../utils';

const MonthTickFormatter = ({ date, Layout, ...rest }) => {
  const text = formatMonthTick(date);

  return <Layout text={text} {...rest} />;
};

MonthTickFormatter.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  Layout: PropTypes.func,
};

MonthTickFormatter.defaultProps = {
  Layout: FormatterLayout,
};

export default MonthTickFormatter;
