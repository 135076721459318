import Grid from '@material-ui/core/Grid';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { isMallValet } from '@/features/currentUser/selectors';

import Table from './Table';

const EfsUsersPage = ({ classes, state }) => {
  const [efsUsersInfo, setEfsUsersInfo] = useState({
    loading: false,
    data: [],
    error: null,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const loadEfsUsersData = async () => {
    setEfsUsersInfo(prevState => ({
      ...prevState,
      loading: true,
    }));

    try {
      const response = await api.doGet(`/subscription-efs`);

      setEfsUsersInfo(prevState => ({
        ...prevState,
        data: _get(response, `payload.subscription_efs`, []),
      }));

      setTotalCount(response.meta.total);
    } catch (error) {
      setEfsUsersInfo(prevState => ({
        ...prevState,
        error,
      }));

      throw error;
    } finally {
      setEfsUsersInfo(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onSetPage = newPage => {
    setPage(newPage);
  };

  const onSetPageSize = newPageSize => {
    setPage(0);
    setPageSize(newPageSize);
  };

  const setWindowData = () => {
    window.efsData = {};
    window.efsData.loadEfsUsersData = loadEfsUsersData;
    window.efsData.isMallValet = isMallValet(state);
  };

  useEffect(() => {
    setWindowData();
    loadEfsUsersData();

    return () => {
      delete window.efsData;
    };
  }, [pageSize, page]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>Пользователи EFS</PageTitle>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        <Table
          limit={10}
          items={efsUsersInfo.data}
          isLoading={efsUsersInfo.loading}
          totalCount={totalCount}
          page={0}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
      </PageContent>
    </Page>
  );
};

EfsUsersPage.propTypes = {
  state: PropTypes.object,
};

EfsUsersPage.defaultProps = {
  state: {},
};

export default EfsUsersPage;
