import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import NotFoundIcon from '@/components/Icon/NotFound';

import ErrorMessage from './ErrorMessage';
import LoadError from './LoadErrorMessage';
import styles from './styles';

const BaseError = ({ classes, headline, message, Icon }) => (
  <div className={classes.wrapper}>
    <ErrorMessage title={headline} subtitle={message} Icon={Icon} />
  </div>
);

BaseError.defaultProps = {
  Icon: NotFoundIcon,
};

BaseError.propTypes = {
  headline: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
};

export const EnhancedBaseError = withStyles(styles)(BaseError);

export const Forbidden = () => (
  <EnhancedBaseError
    headline="Доступ запрещен"
    message="Данная страница существует, но у вас недостаточно прав для ее просмотра. Возможно, вы забыли сменить пользователя?"
  />
);

export const NotFound = () => (
  <EnhancedBaseError
    headline="Страница не найдена"
    message="Скорее всего, она была удалена или перемещена."
  />
);

export const LoadErrorMessage = LoadError;
