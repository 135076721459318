import { LAYOUT_KEY } from '@esentai/core/features/campaigns/article/consts/keys';
import { PAYLOAD_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { TITLE_LAYOUT } from '@esentai/core/features/campaigns/article/consts/layouts';
import { pick } from 'ramda';

import limits from './limits';
import options from './options';

export const getLabelByType = type =>
  options.find(option => option.id === type).label;

export const getName = (index, field) =>
  `${LAYOUT_KEY}[${index}].${PAYLOAD_KEY}.${field}`;

export const getOptions = countByType =>
  options
    .filter(({ id }) => id !== TITLE_LAYOUT)
    .map(option => {
      const count = countByType[option.id];
      const limit = limits[option.id];
      const result = pick(['id', 'label'], option);

      result.disabled = count >= limit;

      return result;
    });
