import {
  getPlacement,
  getPriority,
} from '@esentai/core/features/campaigns/banner/selectors';
import { getProductIds } from '@esentai/core/features/campaigns/show/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getProductId } from '../../../../selectors';
import ProductsComponent from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getProductId(state);

  return {
    placement: getPlacement(state, itemId),
    priority: getPriority(state, itemId),
    productIds: getProductIds(state, itemId),
    itemId,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(ProductsComponent);
