import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [
  {
    key: 'top',
    label: 'Блок 1',
  },
  {
    key: 'bottom',
    label: 'Блок 2',
  },
];

export const createButtonPlacementFilter = createRadioFilterTemplate({
  initialValue: options[0].key,
  options,
});

export default RadioGroupFilter;
