import { Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ classes, children, withoutArrow, ...rest }) => (
  <Fragment>
    {!withoutArrow && <KeyboardArrowRight className={classes.arrowIcon} />}

    <Typography
      classes={{ root: classes.typographyRoot }}
      component={Link}
      variant="subtitle1"
      color="textSecondary"
      {...rest}
    >
      {children}
    </Typography>
  </Fragment>
);

Breadcrumb.defaultProps = {
  withoutArrow: false,
};

Breadcrumb.propTypes = {
  to: PropTypes.string.isRequired,
  withoutArrow: PropTypes.bool,
};

export default Breadcrumb;
