/* eslint-disable react/no-array-index-key */

import { ONLINE_SHOWCASE_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { SHOW_TYPE } from '@esentai/core/features/campaigns/consts/types';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import ConstructorBlockTypeLabel from '@/components/ConstructorBlock/TypeLabel';
import CampaignInlineSearch from '@/features/campaignCreatePage/EditPage/SecondStepBanner/CampaignInlineSearch';

import { getName } from '../utils';

const OnlineShowcaseBlock = ({ setFieldValue, data, index }) => {
  const showcaseData = data.payload[ONLINE_SHOWCASE_KEY];
  const field = getName(index, ONLINE_SHOWCASE_KEY);

  const handleFieldValue = value => {
    setFieldValue(field, value);
    setFieldValue(ONLINE_SHOWCASE_KEY, value);
  };

  return (
    <>
      <ConstructorBlockTypeLabel>Онлайн витрина</ConstructorBlockTypeLabel>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <CampaignInlineSearch
            type={SHOW_TYPE}
            values={{
              [ONLINE_SHOWCASE_KEY]: showcaseData,
            }}
            onSelect={(_, value) => handleFieldValue(value)}
            fieldName={field}
            isArticleStatus
          />
        </Grid>
      </Grid>
    </>
  );
};

OnlineShowcaseBlock.propTypes = {
  data: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default OnlineShowcaseBlock;
