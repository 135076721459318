import { InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const Select = ({ value, options, onChange, ...props }) => (
  <>
    <InputLabel id="levelLabel">Уровень лояльности</InputLabel>
    <MuiSelect
      style={{ width: '100%' }}
      {...props}
      value={value}
      labelId="levelLabel"
      IconComponent={KeyboardArrowDown}
      onChange={event => onChange(event.target.value)}
    >
      {options.map(item => (
        <MenuItem
          key={item.id}
          value={item.id}
          disabled={Boolean(item.disabled)}
        >
          {item.label}
        </MenuItem>
      ))}
    </MuiSelect>
  </>
);

Select.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Select;
