import {
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_KAZAKH_MESSAGE_KEY,
  PUSH_KAZAKH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  SEND_SCHEDULE_KEY,
} from '@esentai/core/features/campaigns/notification/consts/keys';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import DateTimePicker from '@/components/DateTimePicker';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';

import { CanEdit } from '../../CanEdit';
import { INITIAL_SEND_DATETIME } from '../consts/keys';
import Preview from './Preview';
import RepeatSchedule from './RepeatSchedule';

class PushForm extends PureComponent {
  static propTypes = {
    values: PropTypes.object.isRequired,
    Field: PropTypes.func.isRequired,
  };

  static defaultProps = {
    values: {},
  };

  render() {
    return (
      <FieldRow
        label="Пуш-сообщение"
        subLabel="Пуш-сообщения"
        sudDesc="Это сообщение будет отправляться пользователю по указанному расписанию."
        Aside={this.renderPreview}
      >
        {this.renderPushContrustor()}
      </FieldRow>
    );
  }

  renderPushContrustor() {
    const { classes, Field } = this.props;

    return (
      <div>
        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Заголовок сообщения"
            desc="Заголовок сообщения, которое увидит пользователь. Не более 60 символов."
          />
          <CanEdit field={PUSH_RUSSIAN_TITLE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_RUSSIAN_TITLE_KEY}
              helperText="На русском"
              fullWidth
            />
          </CanEdit>
          <CanEdit field={PUSH_ENGLISH_TITLE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_ENGLISH_TITLE_KEY}
              helperText="На английском"
              fullWidth
            />
          </CanEdit>
          <CanEdit field={PUSH_KAZAKH_TITLE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_KAZAKH_TITLE_KEY}
              helperText="На казахском"
              fullWidth
            />
          </CanEdit>
        </div>

        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Дата отправки сообщения"
            desc="Обратите внимание! Технически невозможно отправить сообщения большому количеству пользователей одновременно. Поэтому сообщения будут рассылаться в течение нескольких часов начиная с указанного времени."
          />
          <CanEdit field={SEND_SCHEDULE_KEY}>
            <Field
              clearable
              disablePast
              Target={DateTimePicker}
              helperText="Точный день, в который будет разослано сообщение"
              name={INITIAL_SEND_DATETIME}
            />
          </CanEdit>
        </div>

        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Описание"
            desc="Текст сообщения, которое пользователь увидит в списке уведомлений. Не более 150 символов."
          />
          <CanEdit field={PUSH_RUSSIAN_MESSAGE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_RUSSIAN_MESSAGE_KEY}
              helperText="На русском"
              fullWidth
            />
          </CanEdit>
          <CanEdit field={PUSH_ENGLISH_MESSAGE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_ENGLISH_MESSAGE_KEY}
              helperText="На английском"
              fullWidth
            />
          </CanEdit>
          <CanEdit field={PUSH_KAZAKH_MESSAGE_KEY}>
            <Field
              Target={TextField}
              name={PUSH_KAZAKH_MESSAGE_KEY}
              helperText="На казахском"
              fullWidth
            />
          </CanEdit>
        </div>

        <div className={classes.pushFormSegment}>
          <FieldDescription
            title="Повторение сообщения"
            desc="Сообщение будет повторяться каждый раз в указанное время через обозначенный промежуток времени на протяжении действия кампании."
          />

          <RepeatSchedule {...this.props} />
        </div>
      </div>
    );
  }

  renderPreview = () => (
    <Preview
      values={{
        [PUSH_RUSSIAN_TITLE_KEY]: this.props.values[PUSH_RUSSIAN_TITLE_KEY],
        [PUSH_RUSSIAN_MESSAGE_KEY]: this.props.values[PUSH_RUSSIAN_MESSAGE_KEY],

        [PUSH_ENGLISH_TITLE_KEY]: this.props.values[PUSH_ENGLISH_TITLE_KEY],
        [PUSH_ENGLISH_MESSAGE_KEY]: this.props.values[PUSH_ENGLISH_MESSAGE_KEY],

        [PUSH_KAZAKH_TITLE_KEY]: this.props.values[PUSH_KAZAKH_TITLE_KEY],
        [PUSH_KAZAKH_MESSAGE_KEY]: this.props.values[PUSH_KAZAKH_MESSAGE_KEY],
      }}
    />
  );
}

export default PushForm;
