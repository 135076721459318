import {
  CASH_MACHINE_ID_KEY,
  HISTORY_ID_KEY,
} from '@esentai/core/features/reconciliationReceiptBonusDetails/consts/keys';
import api from '@esentai/core/features/storeBonusPendingReceiptDetails/api';
import { Button, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

import NoMatchMessage from '../../../../components/CommonTable/NoMatchMessage';
import { downloadXls } from './utils';

interface RowData {
  id: number;
  operation: string;
  comment: string;
  user_id: number;
  user: string;
  new_data: any; // TODO: Define a generic for this
  record_created: string;
}

export interface PendingBonusesTableProps {
  detailId: number;
  cmId: number;
  headers: string[];
  excelName: string;
  children: (data: any) => JSX.Element;
}

export interface PendingBonusesTableState {
  limit: number;
  page: number;
  data: RowData[];
  total: number;
  totalSum: number;
}

const styles = {
  tbody: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
};

export class PendingBonusesTable extends React.Component<
  PendingBonusesTableProps,
  PendingBonusesTableState
> {
  constructor(props: PendingBonusesTableProps) {
    super(props);

    this.state = {
      limit: 0,
      page: 0,
      total: 0,
      totalSum: 0,
      data: [],
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.cmId !== prevProps.cmId) {
      this.loadData();
    }
  }

  public render(): JSX.Element {
    const { headers, excelName } = this.props;
    const { data, totalSum } = this.state;

    return (
      <div style={{ marginTop: 15 }}>
        <Typography variant="h5">
          Сумма бонусов к начислению: {totalSum}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.downloadExcel(excelName)}
        >
          Вывести в Excel
        </Button>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={styles.tbody}>{this.renderBody(data)}</TableBody>
        </Table>
      </div>
    );
  }

  public renderBody(data: any[]): JSX.Element {
    const { children } = this.props;

    if (data.length > 0) {
      return children(data);
    }

    return <NoMatchMessage columnsCount={100} />;
  }

  private downloadExcel(excelName): void {
    const { detailId, cmId } = this.props;

    if (cmId) {
      downloadXls(cmId, detailId, excelName);
    }
  }

  private async loadData(): Promise<void> {
    const { limit, page } = this.state;
    const { detailId, cmId } = this.props;

    if (cmId) {
      const filters = [[HISTORY_ID_KEY, 'eq', detailId]];

      filters.push([CASH_MACHINE_ID_KEY, 'eq', cmId]);

      const data = await api.doQuery(filters, 'id', 'asc', limit, page);

      if (data.payload.store_bonus_pending_receipt_details.length > 0) {
        this.setState({
          total: data.meta.total,
          data: data.payload.store_bonus_pending_receipt_details,
          totalSum: data.payload.total_sum,
        });
      }
    }
  }
}
