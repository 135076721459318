import {
  ACTIVITY_TYPE_KEY,
  COMISSAR_KEY,
  COMMENT_KEY,
  CUSTOMER_KEY,
  STATUS_KEY,
  TIMESTAMP_KEY,
  USER_ID_KEY,
} from '@esentai/core/features/bonuses/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={CUSTOMER_KEY}>Имя пользователя МП_1</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={USER_ID_KEY}>Менеджер молла</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={COMISSAR_KEY}>Комплаенс Менеджер</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TIMESTAMP_KEY}>Дата и время создания</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TIMESTAMP_KEY}>
        Дата и время ответа Комплаенс Менеджера
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={'fiscal_id'}>UUID</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={COMMENT_KEY}>Комментарий</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={ACTIVITY_TYPE_KEY}>Статус</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={STATUS_KEY}>Ответ Комплаенс Менеджера</SortControl>
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
