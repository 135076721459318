import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getDisplayingBeaconIds,
  hasBeaconsWithoutPosition,
} from '@/features/mapPage/selectors';

import Beacons from './component';
import styles from './styles';

const mapStateToProps = state => ({
  hasBeaconsWithoutPosition: hasBeaconsWithoutPosition(state),
  beaconIds: getDisplayingBeaconIds(state),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Beacons);
