export const RECOVER = 'passwordRecovery/RECOVER';

export const RECOVER_SUCCESS = 'passwordRecovery/RECOVER_SUCCESS';

export const RECOVER_FAILURE = 'passwordRecovery/RECOVER_FAILURE';

export const RESET = 'passwordRecovery/RESET';

export const RESET_SUCCESS = 'passwordRecovery/RESET_SUCCESS';

export const RESET_FAILURE = 'passwordRecovery/RESET_FAILURE';
