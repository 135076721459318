import {
  getActivityType,
  getComissarId,
  getComment,
  getCustomer,
  getLoyaltyBonuses,
  getLoyaltyCreatedDate,
  getLoyaltyProcessedDate,
  getLoyaltyStatus,
  getUserId,
  getUserLoyaltyLevel,
} from '@esentai/core/features/loyalties/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: id }) => ({
  loyaltyLevel: getUserLoyaltyLevel(state, id),
  loyaltyProcessDate: getLoyaltyProcessedDate(state, id),
  comissarId: getComissarId(state, id),
  comment: getComment(state, id),
  userId: getUserId(state, id),
  activity: getActivityType(state, id),
  loyaltyBonuses: getLoyaltyBonuses(state, id),
  customer: getCustomer(state, id),
  status: getLoyaltyStatus(state, id),
  createdDate: getLoyaltyCreatedDate(state, id),
  type: getActivityType(state, id),
  id,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
