import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import IconTooltip from '@/components/IconTooltip';
import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';
import {
  AVERAGE_CHECK_KEY,
  AVERAGE_TIME_SPENT,
  BUYERS_COUNT_KEY,
  CONVERSION_RATE_KEY,
  MALL_VISITORS_COUNT_KEY,
  PURCHASES_COUNT_KEY,
  UNIQUE_LOCATION_LEADS_COUNT,
  USERS_COUNT_KEY,
  VISITORS_COUNT_KEY,
} from '@/features/usersStatistics/consts/keys';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={TIMEFRAME_KEY}>Дата</SortControl>
    </CondenseTableCell>

    <CondenseTableCell align="right">
      <IconTooltip title="Кол-во пользователей в приложении на момент окончания выбранного масштаба (т.е. если масштаб неделя, то будет указано общее кол-во зарегистрированных пользователей в приложении в конце недели)">
        <SortControl field={USERS_COUNT_KEY}>Все пользователи</SortControl>
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Кол-во уникальных пользователей посетивших локацию Esentai Mall в выбранный масштаб с минимальным временем нахождения 1 секунда">
        <SortControl field={MALL_VISITORS_COUNT_KEY}>
          Посетители молла
        </SortControl>
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Пользователи, у которых зарегистрирован факт посещения локации">
        <SortControl field={UNIQUE_LOCATION_LEADS_COUNT}>Лиды</SortControl>
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title=" Кол-во уникальных пользователей посетивших локацию (Esentai Mall или указанные в фильтре) в выбранный масштаб с минимальным временем нахождения 120 секунд">
        <SortControl field={VISITORS_COUNT_KEY}>Посетители</SortControl>
      </IconTooltip>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <IconTooltip title="Кол-во уникальных пользователей совершивших покупки в выбранный масштаб">
        <SortControl field={BUYERS_COUNT_KEY}>Покупатели</SortControl>
      </IconTooltip>
    </CondenseTableCell>

    <CondenseTableCell align="right">
      <IconTooltip title="Отношение уникальных покупателей к посетителям молла">
        <SortControl field={CONVERSION_RATE_KEY}>Конверсия</SortControl>
      </IconTooltip>
    </CondenseTableCell>

    <CondenseTableCell align="right">
      <IconTooltip title="Кол-во покупок (чеков) совершенных пользователями в выбранный масштаб">
        <SortControl field={PURCHASES_COUNT_KEY}>Покупки</SortControl>
      </IconTooltip>
    </CondenseTableCell>

    <CondenseTableCell align="right">
      <IconTooltip title="Сумма всех покупок (чеков) в выбранный масштаб времени деленная на кол-во покупок">
        <SortControl field={AVERAGE_CHECK_KEY}>Средний чек</SortControl>
      </IconTooltip>
    </CondenseTableCell>

    <CondenseTableCell align="right">
      <IconTooltip title="Среднее время нахождения пользователя в локации (Esentai Mall или указанные в фильтре) в выбранный масштаб времени">
        <SortControl field={AVERAGE_TIME_SPENT}>
          Среднее время пребывания
        </SortControl>
      </IconTooltip>
    </CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
