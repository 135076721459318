export default {
  wrapper: {
    outline: 0,
    width: 920,
    maxHeight: '80%',
    overflowY: 'auto',
    padding: 27,
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,
  },

  product: {
    marginRight: 15,
  },
  productButton: {
    height: 50,
    width: 50,
  },

  storeKkmWrapper: {
    display: 'flex',
    margin: '15px 0',
  },

  customerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  searchCustomerInfo: {
    width: '50%',
    display: 'flex',
    flexWrap: 'wrap',
  },

  issuedDatetime: {
    margin: '24px 0',
  },

  filesWrapper: {
    display: 'flex',
  },
  bonusInput: {
    width: 275,
    marginRight: 15,
  },

  uuid_block: {
    width: 275,
  },

  centerAlign: {
    display: 'flex',
    alignItems: 'center',
  },

  hideArrows: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  errorField: {
    borderBottom: '1px solid red',
  },
  fileError: {
    color: 'red',
    fontSize: '0.70rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  notice: {
    color: 'rgba(0, 0, 0, 0.54);',
    fontSize: '0.70rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
};
