import {
  STORE_ID_KEY,
  USED_DATETIME_KEY,
} from '@esentai/core/features/benefitItems/consts/keys';

// import { createBenefitStatusFilter } from '@/filters/BenefitStatus';
import { createDateTimeRangeFilter } from '@/filters/DateTimeRange';
import { createStoreFilter } from '@/filters/Store';
import { createIncludeAdapter } from '@/structs/pageFilterAdapter';
// import { createLocationFilter } from '@/filters/Location';
// import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createDateTimeRangeFilter({
    key: USED_DATETIME_KEY,
    label: 'Дата и время использования',
  }),
  createStoreFilter({
    key: STORE_ID_KEY,
    label: 'Магазин',
    adapter: createIncludeAdapter(null),
  }),
  // createDateRangeFilter({
  //   key: DATE_END_KEY,
  //   label: 'Дата окончания кампании',
  // }),
  // createLocationFilter({
  //   key: LOCATION_ID_KEY,
  //   label: 'Локация',
  // }),
  // createTextFilter({
  //   key: TITLE_KEY,
  //   label: 'Название',
  // }),
  // createBenefitStatusFilter({
  //   key: STATUS_KEY,
  //   label: 'Статус',
  //   quicklyAccessible: true,
  // }),
];
