import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  canMoveViewDown,
  canMoveViewUp,
} from '@/features/productsViewPage/selectors';

import SortControls from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  canMoveViewDown: canMoveViewDown(state, itemId),
  canMoveViewUp: canMoveViewUp(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(SortControls);
