import { getHistoryName } from '@esentai/core/features/reconciliationReceiptBonusDetails/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemPageRunner from '@/hocs/withItemPageRunner';
import withQuerySync from '@/hocs/withQuerySync';

import { fetchDetails as fetchItem } from '../actions';
import queryDuck from '../ducks/query';
import {
  getDetailsId,
  getReconciliationIds,
  isLoadingReconciliation,
} from '../selectors';
import ReconciliationReceiptBonusDetails from './component';
import styles from './styles';

const mapStateToProps = state => {
  const detailsId = getDetailsId(state);
  const itemId = getReconciliationIds(state)[0];

  return {
    detailsId,
    isLoading: isLoadingReconciliation(state),
    itemId,
    historyName: getHistoryName(state, itemId),
  };
};

const mapDispatchToProps = {
  fetchItem,
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
  withStyles(styles),
)(ReconciliationReceiptBonusDetails);
