import { connect } from 'react-redux';

import StatusDistributionChart from '@/components/StatusDistributionChart';
import {
  getPercentageReceiptsByBlackUserStatusById,
  getPercentageReceiptsByJetBlackUserStatusById,
  getPercentageReceiptsByOrangeUserStatusById,
} from '@/features/purchasesStatistics/selectors';

const mapStateToProps = (state, { itemId }) => ({
  orangeUsersPercentage: getPercentageReceiptsByOrangeUserStatusById(
    state,
    itemId,
  ),
  blackUsersPercentage: getPercentageReceiptsByBlackUserStatusById(
    state,
    itemId,
  ),
  jetBlackUsersPercentage: getPercentageReceiptsByJetBlackUserStatusById(
    state,
    itemId,
  ),
});

export default connect(mapStateToProps)(StatusDistributionChart);
