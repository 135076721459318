import {
  FIRST_NAME_KEY,
  ID_KEY,
  LAST_NAME_KEY,
  LEVEL_KEY,
  PHONE_KEY,
} from '@/features/mallReceptionUsersPage/consts/keys';
import { createEqualsFilter, createTextFilter } from '@/filters/SearchInput';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createEqualsFilter({
    key: ID_KEY,
    label: 'ID Клиента',
  }),
  createTextFilter({
    key: FIRST_NAME_KEY,
    label: 'Имя пользователя',
  }),
  createTextFilter({
    key: LAST_NAME_KEY,
    label: 'Фамилия пользователя',
  }),
  createTextFilter({
    key: PHONE_KEY,
    label: 'Номер телефона',
  }),
  createUserStatusFilter({
    key: LEVEL_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),
];

export const filterPrefixes = [
  {
    field: ID_KEY,
    prefix: 'eq',
  },
  {
    field: FIRST_NAME_KEY,
    prefix: 'ilike',
  },
  {
    field: LAST_NAME_KEY,
    prefix: 'ilike',
  },
  {
    field: PHONE_KEY,
    prefix: 'ilike',
  },
  {
    field: LEVEL_KEY,
    prefix: 'in',
  },
];
