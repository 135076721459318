import { DriveEta, DriveEtaOutlined, Group } from '@material-ui/icons';

import { ROUTE_PATH as CAR_LIST_PATH } from '@/features/carListPage/consts';
import { ROUTE_PATH as MALL_RECEPTION_USERS_PATH } from '@/features/mallReceptionUsersPage/consts';

export const mallReceptionMenu = [
  {
    label: 'Пользователи',
    path: MALL_RECEPTION_USERS_PATH,
    Icon: Group,
  },
  {
    label: 'Паркинг',
    Icon: DriveEta,
    items: [
      {
        label: 'Список авто',
        path: CAR_LIST_PATH,
        Icon: DriveEtaOutlined,
      },
    ],
  },
];
