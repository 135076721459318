export {
  AVERAGE_CHECK_KEY,
  BIRTH_DATE_KEY,
  CONVERSION_RATE_KEY,
  DEVICES_CHANGES_COUNT_KEY,
  EMAIL_KEY,
  GENDER_KEY,
  IS_PHONE_VERIFIED_KEY,
  IS_SUSPICIOUS_KEY,
  LAST_ACTIVITY_DATE_KEY,
  LOYALTY_POINTS_KEY,
  PENALTIES_KEY,
  PHONE_KEY,
  PURCHASES_COUNT_KEY,
  REWARDS_KEY,
  ROLE_KEY,
  STATUS_KEY,
  STORE_IDS_KEY,
  AGE_KEY,
  STATE_KEY,
  ALLOWED_LOCATION_IDS_KEY,
} from '@esentai/core/features/users/consts/keys';

export const NAME_KEY = 'full_name';
