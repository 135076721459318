export default {
  root: {
    padding: [80, 0],
  },

  container: {
    textAlign: 'center',
  },

  title: {
    marginBottom: 5,
  },

  repairIcon: {
    marginBottom: 10,
  },
};
