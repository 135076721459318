import {
  getBinForMerchant,
  getCompanyForMerchant,
  getStatusForMerchant,
} from '@esentai/core/features/merchants/selectors';
import { connect } from 'react-redux';

import Description from './component';

const mapStateToProps = (state, { merchantId }) => ({
  company: getCompanyForMerchant(state, merchantId),
  bin: getBinForMerchant(state, merchantId),
  status: getStatusForMerchant(state, merchantId),
});

export default connect(mapStateToProps)(Description);
