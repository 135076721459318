export default {
  section: {
    marginBottom: 30,
  },
  hidden: {
    display: 'none',
  },
  datepicker: {
    flex: 'none',
    width: 310,
    marginRight: 30,
  },
};
