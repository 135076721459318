import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import styles from '@/features/authPages/styles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import EditCarInfoForm from './component';

const errorsMapping = {};
const formikOptions = {
  // eslint-disable-next-line camelcase
  mapPropsToValues: ({ currentCarDetails }) => ({
    vehicle_gov_number: currentCarDetails
      ? currentCarDetails.vehicle_gov_number
      : '',
    vehicle_model: currentCarDetails ? currentCarDetails.vehicle_model : '',
  }),

  validationSchema: object().shape({
    vehicle_gov_number: string()
      .min(3, 'Гос. номер должен содержать более 3 символов')
      .max(15, 'Гос. номер должен содержать не более 15 символов')
      .required(),
    vehicle_model: string()
      .min(2, 'Модель авто должна содержать более 2 символов')
      .max(30, 'Модель авто должна содержать не более 30 символов')
      .required(),
  }),
  // eslint-disable-next-line camelcase
  handleSubmit(
    { vehicle_gov_number, vehicle_model },
    { props, setSubmitting },
  ) {
    props.onSubmit({ vehicle_gov_number, vehicle_model });
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(EditCarInfoForm);
