import {
  ENGLISH_TITLE_KEY,
  REFERRED_STORE_ID_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/button/consts/keys';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import Button from '@/components/Button';
import FieldRow from '@/components/FieldRow';
import { createFormStepURL } from '@/features/campaignCreatePage/utils';
import FormikPropTypes from '@/prop-types/formik';

import ContentForm from './ContentForm';
import DestinationForm from './DestinationForm';
import PlacementForm from './PlacementForm';

const fieldNames = {
  [ENGLISH_TITLE_KEY]: 'заголовок на английском',
  [RUSSIAN_TITLE_KEY]: 'заголовок на русском',
  [REFERRED_STORE_ID_KEY]: 'магазин',
};

class SecondStepButton extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const {
      classes,
      handleSubmit,
      RequiredMessages,
      SubmitButton,
      id,
      campaignType,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <PlacementForm {...this.props} />
        <ContentForm {...this.props} />
        <DestinationForm {...this.props} />
        <FieldRow>
          <div className={classes.footer}>
            <SubmitButton
              Target={AsyncButton}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
            >
              Перейти к указанию сегмента
            </SubmitButton>
            <Button
              href={createFormStepURL(campaignType, id, 1)}
              color="primary"
              size="large"
              className={classes.backButton}
            >
              Вернуться
            </Button>
            <RequiredMessages
              fieldNames={fieldNames}
              className={classes.requiredMessages}
            />
          </div>
        </FieldRow>
      </form>
    );
  }
}

export default SecondStepButton;
