import { getRewardType } from '@esentai/core/features/campaigns/actionResult/selectors';
import { connect } from 'react-redux';

import Reward from './component';

const mapStateToProps = (state, { itemId }) => ({
  rewardType: getRewardType(state, itemId),
});

export default connect(mapStateToProps)(Reward);
