import { compose, prop } from 'ramda';

import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';
import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

const getParams = createMatchSelector(ROUTE_PATH);

export const {
  getError: getSaleError,
  getItemsIds: getSaleIds,
  getItemsMeta: getSaleMeta,
  getItemsTotalCount: getSaleTotalCount,
  isLoadingItems: isLoadingSale,
} = itemsDuck.selectors;

export const getStoreId = compose(prop('storeId'), prop('params'), getParams);

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
