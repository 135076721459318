import { Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { without } from 'ramda';
import React, { Component } from 'react';

import { getStatId } from '@/features/statisticsPage/utils';

import Metric from './Metric';

const FIRST_LINE_STATS_COUNT = 3;
const REST_STATS_COUNT = 4;

class TooltipContent extends Component {
  static propTypes = {
    currentStatDescriptor: PropTypes.object.isRequired,
    rentId: PropTypes.string.isRequired,
    rentPlaceName: PropTypes.string.isRequired,
    stats: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  render() {
    const {
      classes,
      currentStatDescriptor,
      rentId,
      rentPlaceName,
    } = this.props;
    const statsDescriptors = this.getStatsDescriptors();
    const firstLineDescriptors = statsDescriptors.slice(
      0,
      FIRST_LINE_STATS_COUNT,
    );
    const secondLineDescriptors = statsDescriptors.slice(
      FIRST_LINE_STATS_COUNT,
    );
    const isPrimaryStat = descriptor => descriptor === currentStatDescriptor;

    return (
      <Paper square className={classes.root}>
        <div className={classes.rentPlaceName}>
          <Typography variant="h5">{rentPlaceName}</Typography>
        </div>
        <div>
          {firstLineDescriptors.map(descriptor => (
            <Metric
              classes={classes}
              key={getStatId(descriptor)}
              descriptor={descriptor}
              primary={isPrimaryStat(descriptor)}
              rentId={rentId}
            />
          ))}
        </div>
        <div>
          {secondLineDescriptors.map(descriptor => (
            <Metric
              classes={classes}
              key={getStatId(descriptor)}
              descriptor={descriptor}
              primary={isPrimaryStat(descriptor)}
              rentId={rentId}
            />
          ))}
        </div>
      </Paper>
    );
  }

  getStatsDescriptors() {
    const { currentStatDescriptor, stats } = this.props;

    return [
      currentStatDescriptor,
      ...without([currentStatDescriptor], stats).slice(0, REST_STATS_COUNT),
    ];
  }
}

export default TooltipContent;
