import { Search } from '@material-ui/icons';
import cx from 'classnames';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import React, { Component, createRef } from 'react';

import DefaultInput from './Input';
import DefaultMenu from './Menu';
import DefaultSelection from './Selection';

class AutocompleteBase extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.any),
    Chip: PropTypes.func.isRequired,
    Input: PropTypes.func,
    InputProps: PropTypes.object,
    Option: PropTypes.func.isRequired,
    Menu: PropTypes.func,
    isOpenOnFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    Selection: PropTypes.func.isRequired,
    isSmall: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    itemToString: always(''),
    options: null,
    selectedItem: null,
    Input: DefaultInput,
    InputProps: {},
    Selection: DefaultSelection,
    Menu: DefaultMenu,
    isOpenOnFocus: false,
    isSmall: false,
  };

  inputRef = createRef();

  state = {
    inputFocus: false,
  };

  onBlur = () => {
    this.setState({ inputFocus: false });
  };

  unInputFocus = () => {
    const { current } = this.inputRef;

    if (current) {
      current.blur();
    }
  };

  render() {
    const { classes, disabled, ...rest } = this.props;
    const { inputFocus } = this.state;

    return (
      <div className={classes.wrapper}>
        {!disabled && <Search className={classes.icon} />}
        <Downshift
          isOpen={inputFocus}
          onSelect={this.unInputFocus}
          disabled={disabled}
          {...rest}
        >
          {this.renderAutocomplete}
        </Downshift>
      </div>
    );
  }

  renderAutocomplete = ({
    clearSelection,
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    openMenu,
    selectItem,
    selectedItem,
  }) => {
    const {
      classes,
      options,
      Input,
      InputProps,
      isOpenOnFocus,
      Option,
      Chip,
      Selection,
      Menu,
      disabled,
      isSmall,
    } = this.props;

    const onFocus = () => {
      if (isOpenOnFocus) {
        openMenu();
      }
      this.setState({ inputFocus: true });
    };

    return (
      <div className={classes.root}>
        <Selection
          clearSelection={disabled ? null : clearSelection}
          selectItem={disabled ? null : selectItem}
          selectedItem={selectedItem}
          Chip={Chip}
        />
        <Input
          {...getInputProps({ onFocus, onBlur: this.onBlur })}
          InputProps={{
            disableUnderline: true,
          }}
          {...InputProps}
          disabled={disabled}
          selectedItem={selectedItem}
          inputRef={this.inputRef}
          className={classes.container}
        />
        <Menu
          {...getMenuProps()}
          getItemProps={getItemProps}
          highlightedIndex={highlightedIndex}
          options={options}
          Option={Option}
          isOpen={isOpen}
          inputRef={this.inputRef}
          className={cx(classes.paper, isOpen && classes.isOpenContainer)}
          isSmall={isSmall}
        />
      </div>
    );
  };
}

export default AutocompleteBase;
