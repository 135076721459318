import { Button, Typography } from '@material-ui/core';
import { ExitToAppOutlined, Person } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const UserMenu = ({ name, role, logOut, classes }) => (
  <div className={classes.root}>
    <Person className={classes.userIcon} />

    <div className={classes.userInfo}>
      <Typography>{name}</Typography>
      <Typography variant="caption">{role}</Typography>
    </div>

    <Button className={classes.exitButton} onClick={logOut}>
      <ExitToAppOutlined color="primary" />
    </Button>
  </div>
);

UserMenu.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
};

export default UserMenu;
