import { NAME_KEY } from '@esentai/core/features/parkingWhitelistGroups/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: ({ name }) => ({
    [NAME_KEY]: name,
  }),

  validationSchema: object().shape({
    [NAME_KEY]: string().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    console.log(values);
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
