import CRUDResource from '@esentai/core/resources/crud';

import { omitPagination } from './utils';

export default class StatisticsResource extends CRUDResource {
  query = query => {
    const queryCopy = omitPagination(query);

    return super.query(queryCopy);
  };
}
