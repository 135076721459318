import { Fade, Paper, Popper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Menu extends Component {
  static propTypes = {
    getItemProps: PropTypes.func.isRequired,
    highlightedIndex: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    isOpen: PropTypes.bool,
    Option: PropTypes.func.isRequired,
    inputRef: PropTypes.object.isRequired,
    isSmall: PropTypes.bool,
  };

  static defaultProps = {
    highlightedIndex: null,
    isOpen: false,
    isSmall: false,
  };

  render() {
    const {
      classes,
      getItemProps,
      highlightedIndex,
      options,
      Option,
      isOpen,
      inputRef,
      isSmall,
      ...rest
    } = this.props;

    return (
      <Popper
        open={isOpen}
        anchorEl={inputRef.current}
        placement="bottom-start"
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
        }}
        className={classes.popper}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper
              {...rest}
              className={classes.paper}
              square
              style={isSmall && { maxHeight: '150px' }}
            >
              {options.map((option, index) => (
                <Option
                  {...getItemProps({ key: option, index, item: option })}
                  key={option}
                  value={option}
                  isHighlighted={highlightedIndex === index}
                />
              ))}
            </Paper>
          </Fade>
        )}
      </Popper>
    );
  }
}

export default Menu;
