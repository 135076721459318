import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import { createAction } from 'redux-actions';

import query from './ducks/query';
import { DELETE_BRAND, RETRY_FETCH } from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const deleteBrand = createTaskAction(DELETE_BRAND);

export const retryFetch = createAction(RETRY_FETCH);
