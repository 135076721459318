import PropTypes from 'prop-types';
import { map } from 'ramda';
import React, { Component } from 'react';

import DoubleLineChart from '@/components/DoubleLineChart';
import { getStrategy } from '@/components/StatisticsTickDate/strategies';
import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';
import { HOUR_SCALE } from '@/features/statistics/consts/scales';
import { getStatsInnerJoin } from '@/features/statisticsPage/utils';
import { convertToUTC } from '@/utils/format';

import { HEIGHT, MARGINS } from './styles';

class StatisticsDoubleLineChart extends Component {
  static propTypes = {
    leftYs: PropTypes.arrayOf(PropTypes.any).isRequired,
    rightYs: PropTypes.arrayOf(PropTypes.any).isRequired,
    scale: PropTypes.string,
    selectedStatsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    stats: PropTypes.arrayOf(PropTypes.object).isRequired,
    xs: PropTypes.arrayOf(PropTypes.any).isRequired,
    TooltipContent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    scale: null,
  };

  render() {
    const {
      classes,
      leftYs,
      rightYs,
      scale,
      selectedStatsIds,
      stats,
      xs,
      TooltipContent,
    } = this.props;
    const xDescriptor = this.getXDescriptor();
    const selectedStats = getStatsInnerJoin(stats, selectedStatsIds);

    if (xs.length === 0) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div className={classes.chartContainer}>
          <DoubleLineChart
            xs={this.getConvertedDates()}
            leftYs={leftYs}
            rightYs={rightYs}
            margins={MARGINS}
            height={HEIGHT}
            leftYDescriptor={selectedStats[0]}
            rightYDescriptor={selectedStats[1]}
            xDescriptor={xDescriptor}
            scaleType={scale}
            TooltipContent={TooltipContent}
          />
        </div>
      </div>
    );
  }

  getConvertedDates = () => {
    const { xs, scale } = this.props;

    return scale !== HOUR_SCALE ? map(convertToUTC, xs) : xs;
  };

  getXDescriptor = () => ({
    key: TIMEFRAME_KEY,
    formatter: this.formatXTick,
  });

  formatXTick = date => {
    const { scale } = this.props;

    if (!scale) {
      return null;
    }

    const { format } = getStrategy(scale);

    return format(date);
  };
}

export default StatisticsDoubleLineChart;
