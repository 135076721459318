import {
  ID_KEY,
  NAME_KEY,
  USERS_KEY,
} from '@esentai/core/features/parkingWhitelistGroups/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';
import { canUpdateWhitelistGroups } from '@/features/parkingWhitelistGroupsPage/permissions';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    {/* <TableCell padding="checkbox">*/}
    {/*  <GlobalSelectionToggle />*/}
    {/* </TableCell>*/}
    <CondenseTableCell>
      <SortControl field={ID_KEY}>ID</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={NAME_KEY}>Название</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={USERS_KEY}>Пользователи</SortControl>
    </CondenseTableCell>
    {/* <CondenseTableCell>*/}
    {/*  <SortControl field={RECORD_CREATED_KEY}>Дата добавления</SortControl>*/}
    {/* </CondenseTableCell>*/}
    <CanView permission={canUpdateWhitelistGroups}>
      <CondenseTableCell style={{ textAlign: 'left' }}>
        Действия
      </CondenseTableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  GlobalSelectionToggle: PropTypes.func.isRequired,
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
