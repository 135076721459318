import { getRussianName } from '@esentai/core/features/locations/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import LocationInfo from './component';
import styles from './styles';

const mapStateToProps = (state, { locationId }) => ({
  locationName: getRussianName(state, locationId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(LocationInfo);
