import PropTypes from 'prop-types';
import React from 'react';

import DistributionChart from '@/components/DistributionChart';

import { BlackBar } from './BlackBar';
import { JetBlackBar } from './JetBlackBar';
import { OrangeBar } from './OrangeBar';

const StatusDistributionChart = ({
  orangeUsersPercentage,
  jetBlackUsersPercentage,
  blackUsersPercentage,
}) => {
  const total =
    orangeUsersPercentage + jetBlackUsersPercentage + blackUsersPercentage;

  if (total === 0) {
    return '—';
  }

  return (
    <DistributionChart>
      <OrangeBar percentage={orangeUsersPercentage} />
      <JetBlackBar percentage={jetBlackUsersPercentage} />
      <BlackBar percentage={blackUsersPercentage} />
    </DistributionChart>
  );
};

StatusDistributionChart.propTypes = {
  orangeUsersPercentage: PropTypes.number.isRequired,
  jetBlackUsersPercentage: PropTypes.number.isRequired,
  blackUsersPercentage: PropTypes.number.isRequired,
};

export default StatusDistributionChart;
