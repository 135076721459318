export default {
  root: {
    display: 'flex',
    padding: [10, 20],
    border: [1, 'solid', '#E0E0E0'],
  },

  floorSelectContainer: {
    marginRight: 20,
  },

  heatMapContainer: {
    flexGrow: 1,
  },

  heatMap: {
    border: [1, 'solid', '#E0E0E0'],
    position: 'relative',
  },

  label: {
    marginBottom: 10,
  },
};
