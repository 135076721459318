import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [
  {
    key: true,
    label: 'Отображаемые наборы',
  },
  {
    key: false,
    label: 'Скрытые наборы',
  },
];

export const createVisibilityFilter = createRadioFilterTemplate({
  initialValue: options[0].key,
  options,
});

export default RadioGroupFilter;
