import { REWARD_BENEFITS_IDS_KEY } from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { Typography } from '@material-ui/core';
import React, { Component } from 'react';

import { BenefitMultiAutocomplete } from '@/containers/BenefitAutocomplete';
import FormikPropTypes from '@/prop-types/formik';

class BenefitsForm extends Component {
  static propTypes = FormikPropTypes;

  setBenefitsIds = values => {
    const { setFieldValue } = this.props;

    setFieldValue(REWARD_BENEFITS_IDS_KEY, values);
  };

  render() {
    const { rewardBenefitsIds, disabled } = this.props;

    return (
      <div>
        <Typography gutterBottom>
          Назначить в качестве вознаграждения бенефит:
        </Typography>
        <BenefitMultiAutocomplete
          disabled={disabled}
          onChange={this.setBenefitsIds}
          selectedItem={rewardBenefitsIds}
          InputProps={{
            fullWidth: true,
            placeholder: 'Добавьте бенефит',
            InputProps: { disableUnderline: true },
          }}
        />
      </div>
    );
  }
}

export default BenefitsForm;
