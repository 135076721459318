import { range } from 'ramda';
import React, { Fragment } from 'react';

import MallGuideTopBar from '@/components/DevicePreview/MallGuideTopBar';

const placeholders = range(0, 7);

const Preview = ({ classes }) => (
  <Fragment>
    <MallGuideTopBar />
    <div className={classes.container}>
      <div className={classes.image} />

      {placeholders.map(placeholder => (
        <div key={placeholder} className={classes.placeholder} />
      ))}
    </div>
  </Fragment>
);

export default Preview;
