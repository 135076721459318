import { findProductCategoryById } from '@esentai/core/features/productCategories/actions';
import {
  getNameRuForCategory,
  isProductCategoryLoaded,
  isProductCategoryLoading,
} from '@esentai/core/features/productCategories/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import ProductCategoryNameLazy from './component';

const mapStateToProps = (
  state,
  { productCategoryId, onCutText, maxLength },
) => ({
  isLoaded: isProductCategoryLoaded(state, productCategoryId),
  isLoading: isProductCategoryLoading(state, productCategoryId),
  itemId: productCategoryId,
  title: getNameRuForCategory(state, productCategoryId),
  onCutText,
  maxLength,
});

const mapDispatchToProps = { fetchItem: findProductCategoryById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(ProductCategoryNameLazy);
