import {
  ACTION_RESULT_TYPE,
  ARTICLE_TYPE,
  BANNER_TYPE,
  BUTTON_TYPE,
  CALENDAR_EVENT_TYPE,
  NOTIFICATION_TYPE,
  PURCHASE_RESULT_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ActionResultFacts from './ActionResultFacts';
import AllFacts from './AllFactoids';
import NotificationFacts from './NotificationFacts';
import PurchaseResultFacts from './PurchaseResultFacts';

const components = {
  [ACTION_RESULT_TYPE]: ActionResultFacts,
  [ARTICLE_TYPE]: AllFacts,
  [BANNER_TYPE]: AllFacts,
  [BUTTON_TYPE]: NotificationFacts,
  [CALENDAR_EVENT_TYPE]: AllFacts,
  [NOTIFICATION_TYPE]: NotificationFacts,
  [PURCHASE_RESULT_TYPE]: PurchaseResultFacts,
};

const CampaignFunnel = ({ classes, campaignType, ...rest }) => {
  const Component = components[campaignType];

  if (Component) {
    return (
      <Fragment>
        <Typography variant="h4" className={classes.label}>
          Воронка кампании
        </Typography>
        <Component classes={classes} {...rest} />
      </Fragment>
    );
  }

  return null;
};

CampaignFunnel.propTypes = {
  campaignType: PropTypes.string.isRequired,
};

export default CampaignFunnel;
