export default {
  text: {
    fontSize: 15,
    marginBottom: 6,
    fontWeight: 'bold',
    fontFamily: 'Graphik LCG',
    lineHeight: 1.1,
    wordWrap: 'break-word',
  },
};
