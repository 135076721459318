import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import defaultHistory from '@/utils/history';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const composeEnhancers =
  process.env.NODE_ENV === 'production'
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (history = defaultHistory) => {
  const saga = createSagaMiddleware();
  const router = routerMiddleware(history);
  const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(saga, router)),
  );

  saga.run(rootSaga);

  if (module.hot) {
    window.__STORE__ = store;

    module.hot.accept(() => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};
