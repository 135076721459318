export default {
  root: {
    height: 86,
  },

  active: {
    backgroundColor: '#f7faf5',
  },

  archived: {
    backgroundColor: '#f5f5f5',
  },

  amountContainer: {
    display: 'flex',
  },

  amountIncreaseButton: {
    marginTop: -9,
    marginLeft: 10,
  },
};
