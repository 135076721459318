import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';
import CanView from '@/containers/CanView';

const CloneButton = ({ permission, ...rest }) => (
  <CanView permission={permission}>
    <ActionButton
      text="Скопировать"
      button={AsyncButton}
      icon={FileCopyIcon}
      {...rest}
    />
  </CanView>
);

CloneButton.propTypes = {
  permission: PropTypes.func.isRequired,
};

export default CloneButton;
