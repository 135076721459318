import { PRODUCTS_IDS_KEY } from '@esentai/core/features/campaigns/show/consts/keys';
import api from '@esentai/core/features/products/api';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FormikPropTypes from '@/prop-types/formik';

import Products from './AddProducts';
import ProductsList from './ProductsList';

class ContentForm extends Component {
  static propTypes = FormikPropTypes;

  constructor(props) {
    super(props);

    this.state = {
      products: props.values.product_ids || [],
      images: props.values.image_urls || [],
      addProduct: false,
      items: [],
    };
  }

  componentDidMount() {
    this.loadStores();
  }

  addNew() {
    this.setState({ addProduct: !this.state.addProduct });
  }

  loadStores() {
    const { images } = this.state;

    api.doQuery([], 'id', 'asc', 9999).then(data => {
      const curProducts = data.payload.product.reduce((d, c) => {
        d[c.id] = c;

        return d;
      }, {});

      this.props.values.product_ids.reduce((imgs, cur) => {
        imgs.push(...curProducts[cur].images);

        return imgs;
      }, images);

      this.setState({ items: curProducts, images }, this.saveImages(images));
    });
  }

  selectProduct(products, images) {
    this.setState({ products, images }, () => this.saveImages(images));
  }

  saveProduct() {
    const { products, images } = this.state;
    const { setFieldValue } = this.props;

    setFieldValue(PRODUCTS_IDS_KEY, products);
    this.saveImages(images);
  }

  saveImages(images) {
    const { setImages } = this.props;

    setImages(images);
  }

  setProducts(prods, imgs) {
    this.setState({ products: prods, images: imgs }, () => {
      this.saveProduct();
    });
  }

  render() {
    const { classes } = this.props;
    const { products, items, addProduct, images } = this.state;
    const itemsCount = Object.keys(items).length;

    return (
      <>
        <FieldRow label="Товары">
          <Grid container spacing={40}>
            <Grid item xs={12}>
              <FieldDescription title="" />
              <div className={classes.list}>
                {itemsCount > 0 && (
                  <>
                    <ProductsList
                      products={products}
                      images={images}
                      selectProduct={this.selectProduct.bind(this)}
                      items={items}
                      setProducts={this.setProducts.bind(this)}
                    />
                    {addProduct && (
                      <Products
                        products={products}
                        images={images}
                        items={items}
                        selectProduct={this.selectProduct.bind(this)}
                        setProducts={this.setProducts.bind(this)}
                      />
                    )}
                  </>
                )}
                {itemsCount === 0 && <LinearProgress />}
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.addNew();
                }}
              >
                Добавить товар
              </Button>
            </Grid>
          </Grid>
        </FieldRow>
      </>
    );
  }
}

export default ContentForm;
