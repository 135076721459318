import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import {
  StoreAutocomplete,
  StoreMultiAutocomplete,
} from '@/containers/StoreAutocomplete';
import { STORE_IDS_KEY } from '@/features/invites/consts/keys';
import { STORE_BENEFIT_MANAGER_ROLE } from '@/features/personnel/consts/roles';

class StoresField extends Component {
  static propTypes = {
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const { handleBlur, values, classes } = this.props;

    return (
      <FieldRow
        label="Привязка к магазину"
        subLabel="Магазин администратора"
        className={classes.row}
      >
        {values.role && values.role === STORE_BENEFIT_MANAGER_ROLE ? (
          <StoreAutocomplete
            id={values[STORE_IDS_KEY][0]}
            name={values[STORE_IDS_KEY][0]}
            onChange={this.handleStoreChange}
            onBlur={handleBlur}
            selectedItem={values[STORE_IDS_KEY][0]}
            InputProps={{
              placeholder: 'Добавьте магазин',
            }}
          />
        ) : (
          <StoreMultiAutocomplete
            id={STORE_IDS_KEY}
            name={STORE_IDS_KEY}
            onChange={this.handleStoresChange}
            onBlur={handleBlur}
            selectedItem={values[STORE_IDS_KEY]}
            InputProps={{
              placeholder: 'Добавьте магазин',
            }}
          />
        )}
      </FieldRow>
    );
  }

  handleStoresChange = storeIds => {
    this.props.setFieldValue(STORE_IDS_KEY, storeIds);
  };

  handleStoreChange = storeId => {
    const { values } = this.props;
    let tempStoreIds = [];

    if (storeId) {
      tempStoreIds = values[STORE_IDS_KEY];
      tempStoreIds.push(storeId);
    }
    this.props.setFieldValue(STORE_IDS_KEY, tempStoreIds);
  };
}

export default StoresField;
