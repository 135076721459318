import { SEND_SCHEDULE_KEY } from '@esentai/core/features/campaigns/notification/consts/keys';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { append, remove, update } from 'ramda';
import React, { Component } from 'react';

import AddButton from '@/components/ArticleConstructorForm/AddButton';

import { CanEdit } from '../../../CanEdit';
import { MAX_SCHEDULE_SIZE } from '../../consts';
import { REPEAT_SEND_DATETIMES, SHOULD_REPEAT_SEND } from '../../consts/keys';
import Patterns from './Patterns';
import RepeatTick from './RepeatTick';

class RepeatSchedule extends Component {
  static propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  static defaultProps = {
    values: {},
  };

  appendTick = () => {
    const { values, setFieldValue } = this.props;

    const repeatSendDatetimes = values.repeat_send_datetimes;

    setFieldValue(REPEAT_SEND_DATETIMES, append(null, repeatSendDatetimes));
  };

  changeTick = (index, value) => {
    const { values, setFieldValue } = this.props;

    const repeatSendDatetimes = values.repeat_send_datetimes;

    setFieldValue(
      REPEAT_SEND_DATETIMES,
      update(index, value, repeatSendDatetimes),
    );
  };

  removeTick = index => {
    const { values, setFieldValue } = this.props;

    const repeatSendDatetimes = values.repeat_send_datetimes;

    setFieldValue(REPEAT_SEND_DATETIMES, remove(index, 1, repeatSendDatetimes));
  };

  updateTicks = ticks => {
    const { setFieldValue } = this.props;

    setFieldValue(REPEAT_SEND_DATETIMES, ticks);
  };

  toggleShouldRepeat = (_, value) => {
    const { setFieldValue } = this.props;

    setFieldValue(SHOULD_REPEAT_SEND, value);
  };

  render() {
    const { values } = this.props;

    const shouldRepeatSend = values.should_repeat_send;

    return (
      <div>
        {this.renderShouldRepeatSend()}

        {shouldRepeatSend && [
          this.renderPatterns(),
          this.renderSchedule(),
          this.renderAppendButton(),
        ]}
      </div>
    );
  }

  renderShouldRepeatSend() {
    const { classes, values } = this.props;

    const shouldRepeatSend = values.should_repeat_send;

    return (
      <div className={classes.checkboxContainer}>
        <CanEdit field={SEND_SCHEDULE_KEY}>
          <FormControlLabel
            label="Повторять сообщение"
            control={
              <Checkbox
                checked={shouldRepeatSend}
                onChange={this.toggleShouldRepeat}
              />
            }
          />
        </CanEdit>

        {shouldRepeatSend && this.renderLimitCount()}
      </div>
    );
  }

  renderPatterns() {
    const { values } = this.props;

    const initialSendDatetime = values.initial_send_datetime;

    return (
      <CanEdit field={SEND_SCHEDULE_KEY}>
        <Patterns
          initialSendDatetime={initialSendDatetime}
          updateTicks={this.updateTicks}
        />
      </CanEdit>
    );
  }

  renderAppendButton() {
    const { values } = this.props;

    const repeatSendDatetimes = values.repeat_send_datetimes;

    if (repeatSendDatetimes.length >= MAX_SCHEDULE_SIZE) {
      return null;
    }

    return (
      <CanEdit field={SEND_SCHEDULE_KEY}>
        <AddButton onClick={this.appendTick}>Добавить еще одну дату</AddButton>
      </CanEdit>
    );
  }

  renderSchedule() {
    const { values } = this.props;

    const repeatSendDatetimes = values.repeat_send_datetimes;

    const repeatTicks = repeatSendDatetimes.map((sendDatetime, index) => (
      <RepeatTick
        key={String(index)}
        id={index}
        value={sendDatetime}
        onChange={this.changeTick}
        onRemove={this.removeTick}
      />
    ));

    return <CanEdit field={SEND_SCHEDULE_KEY}>{repeatTicks}</CanEdit>;
  }

  renderLimitCount() {
    const { classes, values } = this.props;

    const repeatSendDatetimes = values.repeat_send_datetimes;

    return (
      <Typography color="textSecondary" className={classes.limitCount}>
        {repeatSendDatetimes.length} из {MAX_SCHEDULE_SIZE} доступных сообщений
      </Typography>
    );
  }
}

export default RepeatSchedule;
