import { Grid, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './styles';

const Page = ({ classes, ...props }) => (
  <Grid container className={classes.page} {...props} />
);

export default withStyles(styles)(Page);
