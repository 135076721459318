import { connect } from 'react-redux';

import TimeDistributionChart from '@/components/TimeDistributionChart';
import {
  getTotalPercentageReceiptsByDay,
  getTotalPercentageReceiptsByEvening,
  getTotalPercentageReceiptsByMorning,
} from '@/features/anonymousPurchasesStatisticsPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  percentageByMorning: getTotalPercentageReceiptsByMorning(state, itemId),
  percentageByDay: getTotalPercentageReceiptsByDay(state, itemId),
  percentageByEvening: getTotalPercentageReceiptsByEvening(state, itemId),
});

export default connect(mapStateToProps)(TimeDistributionChart);
