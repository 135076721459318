import {
  ACTIVATIONS_COUNT_KEY,
  CLICKS_COUNT_KEY,
  CONVERSION_KEY,
  COVERAGE_KEY,
  PRE_PUSH_CLICKS_COUNT_KEY,
  PUSH_CLICKS_COUNT_KEY,
  SEGMENT_KEY,
  VIEWS_COUNT_KEY,
} from '@/features/campaignsStatistics/consts/keys';
import { formatConversion, formatNumber } from '@/utils/format';
import pluralize from '@/utils/pluralize';

import {
  ACTIVATIONS_COUNT_METRIC,
  CLICKS_COUNT_METRIC,
  CONVERSION_METRIC,
  COVERAGE_METRIC,
  PRE_PUSH_CLICKS_COUNT_METRIC,
  PUSH_CLICKS_COUNT_METRIC,
  SEGMENT_METRIC,
  VIEWS_COUNT_METRIC,
} from './consts/metrics';

export default [
  {
    key: SEGMENT_KEY,
    url_key: SEGMENT_METRIC,
    label: 'Сегмент',
    formatter: formatNumber,
    labelFormatter: () => 'сегмент',
  },
  {
    key: PRE_PUSH_CLICKS_COUNT_KEY,
    url_key: PRE_PUSH_CLICKS_COUNT_METRIC,
    label: 'Переходы по предварительнорму пуш-уведомлению',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'переход по предварительнорму пуш-уведомлению',
        few: 'перехода по предварительнорму пуш-уведомлению',
        many: 'переходов по предварительнорму пуш-уведомлению',
      }),
  },
  {
    key: PUSH_CLICKS_COUNT_KEY,
    url_key: PUSH_CLICKS_COUNT_METRIC,
    label: 'Переходы по пуш-уведомлению',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'переход по пуш-уведомлению',
        few: 'перехода по пуш-уведомлению',
        many: 'переходов по пуш-уведомлению',
      }),
  },
  {
    key: CLICKS_COUNT_KEY,
    url_key: CLICKS_COUNT_METRIC,
    label: 'Переходы',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'переход',
        few: 'перехода',
        many: 'переходов',
      }),
  },
  {
    key: ACTIVATIONS_COUNT_KEY,
    url_key: ACTIVATIONS_COUNT_METRIC,
    label: 'Активация',
    formatter: formatNumber,
    labelFormatter: () => 'активация',
  },
  {
    key: VIEWS_COUNT_KEY,
    url_key: VIEWS_COUNT_METRIC,
    label: 'Просмотры',
    formatter: formatNumber,
    labelFormatter: count =>
      pluralize(count, {
        one: 'просмотр',
        few: 'просмотра',
        many: 'просмотров',
      }),
  },
  {
    key: COVERAGE_KEY,
    url_key: COVERAGE_METRIC,
    label: 'Охват',
    formatter: formatNumber,
    labelFormatter: () => 'охват',
  },
  {
    key: CONVERSION_KEY,
    url_key: CONVERSION_METRIC,
    label: 'Конверсия',
    formatter: formatConversion,
    labelFormatter: () => 'конверсия',
  },
];
