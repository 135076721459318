import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { pick } from 'ramda';
import React from 'react';

import { LoadErrorMessage } from '@/components/ErrorMessages';

const errorMessageProps = ['title', 'subtitle', 'retry'];

const ErrorMessageRow = ({ classes, columnsCount, ...props }) => (
  <TableRow>
    <TableCell className={classes.root} colSpan={columnsCount}>
      <LoadErrorMessage {...pick(errorMessageProps, props)} />
    </TableCell>
  </TableRow>
);

ErrorMessageRow.propTypes = {
  columnsCount: PropTypes.number.isRequired,
};

export default ErrorMessageRow;
