import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import EditCarInfoForm from './Form';

class EditCarInfoModal extends Component {
  static defaultProps = {
    currentCarDetails: null,
  };

  render() {
    const { isOpen, onClose, currentCarDetails, errorMessage } = this.props;

    const errorLink = errorMessage && errorMessage.errorLink;
    const errorText = errorMessage && errorMessage.errorMessage;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">
          Редактировать данные авто
        </DialogTitle>

        <DialogContent>
          <EditCarInfoForm
            currentCarDetails={currentCarDetails}
            onClose={onClose}
            onSubmit={this.props.onSubmit.bind(this)}
          />
          {errorMessage !== null && (
            <Typography variant="caption">
              {errorText}
              {' - '}
              <Link to={errorLink} onClick={onClose}>
                перейти по ссылке.
              </Link>
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

EditCarInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.object.isRequired,
  currentCarDetails: PropTypes.string,
};

export default EditCarInfoModal;
