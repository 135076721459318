import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getBeaconsError,
  getItemsIds: getBeaconsIds,
  getItemsMeta: getBeaconsMeta,
  getItemsTotalCount: getBeaconsTotalCount,
  isLoadingItems: isLoadingBeacons,
} = itemsDuck.selectors;

export const {
  getFilters,
  getPage,
  getLimit,
  getSort,
  getQuery,
} = queryDuck.selectors;
