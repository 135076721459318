import { findUserById } from '@esentai/core/features/users/actions';
import { getStatusForUser } from '@esentai/core/features/users/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Root from './component';
import styles from './styles';

const mapStateToProps = (state, { userId }) => ({
  status: getStatusForUser(state, userId),
});

const mapDispatchToProps = {
  fetchItem: findUserById,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Root);
