import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { createParkingWhitelistGroup } from '@esentai/core/features/parkingWhitelistGroups/sagas';
import { takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';

import { ROUTE_PATH as PARKING_GROUPS_PATH } from '@/features/parkingWhitelistGroupsPage/consts';

import { CREATE_PARKING_WHITELIST_GROUP } from './types';

export function* createParkingGroup(action) {
  const { payload: attributes } = action;

  yield call(createParkingWhitelistGroup, attributes);
  yield put(push(PARKING_GROUPS_PATH));
}

export default function*() {
  yield takeLatest(
    CREATE_PARKING_WHITELIST_GROUP,
    createTaskSaga(createParkingGroup),
  );
}
