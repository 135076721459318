import { RESOURCE_NAME as BENEFITS } from '@esentai/core/features/benefits/consts';
import { RESOURCE_NAME as PURCHASE_HISTORY } from '@esentai/core/features/purchases/consts';
import { can, permit, VIEW } from '@esentai/core/policy-dsl';

import { GIVE_OUT } from '@/features/benefitGiveOutPage/consts/permissions';

import { VIEW_NAVIGATION_BAR } from '../consts/permissions';

export default [
  can(VIEW_NAVIGATION_BAR),
  permit(BENEFITS, [GIVE_OUT]),
  permit(PURCHASE_HISTORY, [VIEW]),
];
