export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  excelButton: {
    marginTop: 15,
  },
  header: {
    fontSize: '3.5rem',
  },
};
