import { empty, filter, notEmpty, readEmpty } from '@esentai/core/query-dsl';

import { createAdapter } from '@/structs/pageFilterAdapter';

import { ANONYMOUS, PERSONALIZED } from './consts';

export const createAnonymityAdapter = createAdapter(
  value => {
    if (value === ANONYMOUS) {
      return filter(empty());
    } else if (value === PERSONALIZED) {
      return filter(notEmpty());
    }
  },
  filterValue => {
    const isEmpty = readEmpty(filterValue);

    if (isEmpty === true) {
      return ANONYMOUS;
    } else if (isEmpty === false) {
      return PERSONALIZED;
    }

    return null;
  },
);
