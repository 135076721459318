import {
  getMerchantName,
  isReconciliationLoaded,
} from '@esentai/core/features/merchantsReconciliation/selectors';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Link from '@/components/Link';
import MerchantNameLazy from '@/containers/MerchantNameLazy';
import { createMerchantDetailsPageUrl } from '@/features/merchantsReconciliationDetailsPage/utils';
import { createReceiptsPageUrl } from '@/features/merchantsReconciliationReceiptsPage/utils';
import { createRefundsPageUrl } from '@/features/merchantsReconciliationRefundsPage/utils';
import { dateTimeFormat, formatMoney, formatNumber } from '@/utils/format';

import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import { POST_URL, REPAY_CONFIRMATION_MSG } from '../../consts';
import Actions from './Actions';

const Row = ({
  lastReconciliationDate,
  bonusesRefundedAmount,
  bonusesSpentAmount,
  merchantId,
  totalToPayAmount,
  fetchItems,
}) => {
  const [isRepayDialogOpen, setIsRepayDialogOpen] = useState(false);

  const handleRepayModal = () => {
    setIsRepayDialogOpen(!isRepayDialogOpen);
  };

  const payload = {
    merchant_reconciliation: {
      merchant: merchantId,
    },
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Link to={createMerchantDetailsPageUrl(merchantId)}>
            <MerchantNameLazy
              getTitle={getMerchantName}
              isLoaded={isReconciliationLoaded}
              merchantId={merchantId}
            />
          </Link>
        </TableCell>
        <TableCell>{formatMoney(totalToPayAmount)}</TableCell>
        <TableCell>
          <Link to={createReceiptsPageUrl(merchantId)}>
            {formatNumber(bonusesSpentAmount)}
          </Link>
        </TableCell>
        <TableCell>
          <Link to={createRefundsPageUrl(merchantId)}>
            {formatNumber(bonusesRefundedAmount)}
          </Link>
        </TableCell>
        <TableCell>
          {lastReconciliationDate
            ? dateTimeFormat(lastReconciliationDate)
            : '-'}
        </TableCell>
        <TableCell>
          <Actions
            handleDialog={handleRepayModal}
            repayDisabled={totalToPayAmount === 0}
          />
        </TableCell>
      </TableRow>
      {isRepayDialogOpen && (
        <ConfirmationDialog
          isOpen={isRepayDialogOpen}
          onClose={handleRepayModal}
          storeId={merchantId}
          fetchItems={fetchItems}
          url={POST_URL}
          payload={payload}
          confirmationMessage={REPAY_CONFIRMATION_MSG}
        />
      )}
    </>
  );
};

Row.propTypes = {
  merchantId: PropTypes.number.isRequired,
  totalToPayAmount: PropTypes.number.isRequired,
  bonusesSpentAmount: PropTypes.number.isRequired,
  bonusesRefundedAmount: PropTypes.number.isRequired,
  lastReconciliationDate: PropTypes.instanceOf(Date),
  fetchItems: PropTypes.func.isRequired,
};

Row.defaultProps = {
  lastReconciliationDate: '',
};

export default Row;
