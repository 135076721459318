import {
  getCampaignLoadingError,
  isCampaignLoaded,
  isLoadingCampaign,
} from '@esentai/core/features/campaigns/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchItem, submitReview } from '@/features/campaignPage/actions';
import {
  canModerate,
  getCampaignId,
  getCampaignType,
} from '@/features/campaignPage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import EditCampaign from './component';
import form from './form';

const mapStateToProps = state => {
  const itemId = getCampaignId(state);
  const type = getCampaignType(state);

  return {
    canModerate: canModerate(state),
    error: getCampaignLoadingError(state, itemId),
    isLoaded: isCampaignLoaded(state, itemId),
    isLoading: isLoadingCampaign(state, itemId),
    itemId,
    type,
  };
};

const mapDispatchToProps = { fetchItem, onSubmit: submitReview };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
  withAsyncFormik(form),
)(EditCampaign);
