import { connect } from 'react-redux';

import StatisticsTickDate from '@/components/StatisticsTickDate';
import { getDate } from '@/features/usersStatistics/selectors';
import { getScale } from '@/features/usersStatisticsPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  date: getDate(state, itemId),
  scale: getScale(state),
});

export default connect(mapStateToProps)(StatisticsTickDate);
