import { DRAFT_STATUS } from '@esentai/core/features/benefits/consts/statuses';
import {
  getBenefitLoadingError,
  getBenefitStatus,
  isBenefitLoaded,
  isLoadingBenefit,
} from '@esentai/core/features/benefits/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchItem } from '@/features/benefitCreatePage/actions';
import { getBenefitId } from '@/features/benefitCreatePage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import EditCampaign from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getBenefitId(state);
  const status = getBenefitStatus(state, itemId);

  return {
    error: getBenefitLoadingError(state, itemId),
    isFirstStepEditable: status === DRAFT_STATUS,
    isLoaded: isBenefitLoaded(state, itemId),
    isLoading: isLoadingBenefit(state, itemId),
    itemId,
  };
};

const mapDispatchToProps = { fetchItem };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
  withStyles(styles),
)(EditCampaign);
