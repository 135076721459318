import { array, object, string } from 'yup';

import { BAD_EMAIL } from '@/features/invites/consts/codes';
import {
  EMAIL_KEY,
  LOCATIONS_IDS_KEY,
  ROLE_KEY,
  STORE_IDS_KEY,
} from '@/features/invites/consts/keys';
import {
  MALL_SELLER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_BENEFIT_MANAGER_ROLE,
} from '@/features/personnel/consts/roles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import { filterMallAdminFields } from '../utils';
import MallAdminForm from './component';

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export const formikOptions = {
  mapPropsToValues: () => ({
    [ROLE_KEY]: MALL_SELLER_ROLE,
    [EMAIL_KEY]: '',
    [STORE_IDS_KEY]: [],
    [LOCATIONS_IDS_KEY]: [],
  }),

  validationSchema: object({
    [ROLE_KEY]: string().required(),
    [EMAIL_KEY]: string()
      .email()
      .required(),
    [STORE_IDS_KEY]: array().when(ROLE_KEY, (role, schema) =>
      role === STORE_ADMINISTRATOR_ROLE || role === STORE_BENEFIT_MANAGER_ROLE
        ? schema.min(1).required()
        : schema,
    ),

    [LOCATIONS_IDS_KEY]: array().when(ROLE_KEY, (role, schema) =>
      role === STORE_ADMINISTRATOR_ROLE ? schema.min(1).required() : schema,
    ),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(filterMallAdminFields(values));
    setSubmitting(false);
  },
};

const errorsMapping = {
  [BAD_EMAIL]: {
    field: EMAIL_KEY,
    message: 'Данный e-mail уже зарегистрирован в системе',
  },
};

export default withAsyncFormik({ asyncOptions, formikOptions, errorsMapping })(
  MallAdminForm,
);
