import { range } from 'ramda';

import MultiSelectFilter, {
  createMultiSelectFilterTemplate,
} from '@/filters/MultiSelect';

const minYear = 1920;
const maxYear = 2002;

export const options = range(minYear, maxYear)
  .map(String)
  .map(value => ({
    key: value,
    label: value,
    value,
  }));

export const createYearFilter = createMultiSelectFilterTemplate({
  options,
  inputProps: { placeholder: 'Год рождения...' },
});

export default MultiSelectFilter;
