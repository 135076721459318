import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Component from './component';

const mapStateToProps = (state, { permission, computedMatch }) => ({
  hasAccess: permission(state, computedMatch.params),
});

const CheckAccessRoute = connect(mapStateToProps)(Component);

CheckAccessRoute.propTypes = {
  permission: PropTypes.func.isRequired,
};

export default CheckAccessRoute;
