import { Button, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TextField from '@/components/TextField';

class SearchInputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
    };
  }

  changeValue = event => {
    this.setState({ input: event.target.value });
  };

  addValue = () => {
    this.props.onChange(this.props.value.concat(this.state.input));
    this.setState({ input: '' });
  };

  deleteValue = key => {
    const newValues = this.props.value;

    newValues.splice(key, 1);
    this.props.onChange(newValues);
  };

  isDisabled() {
    const num = Math.floor(Number(this.state.input));

    return num === Infinity || String(num) !== this.state.input || num < 1;
  }

  render() {
    const { value, classes } = this.props;
    const { input } = this.state;

    return (
      <div>
        <div className={classes.chips}>
          {value.map((val, key) => (
            <Chip
              key={`chip${val}`}
              label={val}
              onDelete={() => this.deleteValue(key)}
              className={classes.chip}
            />
          ))}
        </div>
        <div>
          <TextField
            autoFocus
            placeholder="Введите ID"
            value={input}
            onChange={this.changeValue}
          />
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            disabled={this.isDisabled()}
            onClick={this.addValue}
          >
            Добавить
          </Button>
        </div>
      </div>
    );
  }
}

SearchInputs.defaultProps = {
  value: [],
};

SearchInputs.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default SearchInputs;
