import {
  NAME_ENGLISH_KEY,
  NAME_RUSSIAN_KEY,
} from '@esentai/core/features/locations/consts/keys';
import {
  getEnglishName,
  getRussianName,
} from '@esentai/core/features/locations/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { object, string } from 'yup';

import withFormikValidation from '@/hocs/withFormikValidation';

import LocationForm from './component';
import styles from './styles';

const formikOptions = {
  mapPropsToValues: ({ nameRu, nameEn }) => ({
    [NAME_RUSSIAN_KEY]: nameRu,
    [NAME_ENGLISH_KEY]: nameEn,
  }),

  validationSchema: object().shape({
    [NAME_RUSSIAN_KEY]: string()
      .min(1)
      .max(50)
      .required(),
    [NAME_ENGLISH_KEY]: string()
      .min(1)
      .max(50)
      .required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };
const mapStateToProps = (state, { locationId }) => ({
  nameRu: getRussianName(state, locationId),
  nameEn: getEnglishName(state, locationId),
});

export default compose(
  connect(mapStateToProps),
  withAsyncRunner(asyncOptions),
  withFormikValidation(formikOptions),
  withStyles(styles),
)(LocationForm);
