export default {
  icon: {
    fontSize: 12,
    opacity: 0.5,
    marginLeft: 2,
  },

  tooltip: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};
