import PropTypes from 'prop-types';

const MerchantBinLazy = ({ isLoaded, title }) => (isLoaded ? title : '...');

MerchantBinLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
};

MerchantBinLazy.defaultProps = {
  title: null,
  subTitle: null,
  showSubtitle: false,
};

export default MerchantBinLazy;
