export default {
  wrapper: {
    outline: 0,
    maxHeight: '80%',
    overflowY: 'auto',
    padding: 27,
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,
  },

  datepicker: {
    flex: 'none',
    width: 320,
    margin: [0, 10, 30, 0],
  },

  titleField: {
    width: 651,
    marginBottom: 15,
  },
};
