export const NON_POSITIONED_LABEL_X = 250;

export const NON_POSITIONED_LABEL_Y = 200;

export default {
  nonPositionedBeaconsLabel: {
    fontFamily: 'Roboto',
    fontSize: 10,
    userSelect: 'none',
  },
};
