import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { lastDayOfMonth, set, sub } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { ROUTE_PATH } from '@/features/ipnReportsPage/consts';
import { downloadXls } from '@/features/ipnReportsPage/utils';
import { formatMonthAndYear } from '@/utils/format';

import Table from './Table';

const PurchaseWithoutScanPage = props => {
  const { classes, location } = props;

  const [ipnReportsData, setIpnReportsData] = useState([]);
  const [timeframes, setTimeframes] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState(null);
  const urlSearchParams = new URLSearchParams(window.location.search);

  const [pageInfo, setPageInfo] = useState({
    limit: 10,
    totalCount: 0,
    page: 0,
    loading: false,
  });

  const getDateRange = () => {
    const date = urlSearchParams.get('timeframe');

    const gte = sub(new Date(date), {
      hours: 6,
    });

    const endDate = lastDayOfMonth(new Date(date));

    const lte = set(new Date(endDate), { hours: 23, minutes: 59 });

    return {
      gte,
      lte,
    };
  };

  const loadIpnReportsData = async (params = {}) => {
    setPageInfo(prevState => ({
      ...prevState,
      loading: true,
    }));

    const { gte, lte } = getDateRange();

    // const searchParams = new URLSearchParams(window.location.search);
    const { limit, page } = pageInfo;

    try {
      const response = await api.doGet('/ipn-report', {
        limit,
        offset: limit * page,
        'datetime_filter[gte]': gte,
        'datetime_filter[lte]': lte,
        ...params,
      });

      setPageInfo(prevState => ({
        ...prevState,
        totalCount: response.meta.total,
      }));

      setIpnReportsData(response.payload.ipn);
    } catch (e) {
      throw e;
    } finally {
      setPageInfo(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const loadIpnTimeframes = async () => {
    try {
      const response = await api.doGet(`/ipn-report/timeframes`);

      setTimeframes(response.payload['ipn-timeframes']);
    } catch (error) {
      throw error;
    }
  };

  // const onSetParams = () => {
  //   const searchList = decodeURIComponent(urlSearchParams);
  //   const filterList = searchList.toString().split('&');
  //   const params = {};

  //   filterList.map(filter => {
  //     const value = filter.split('=');

  //     params[`${value[0]}`] = value[1];

  //     return filter;
  //   });

  //   return { params };
  // };

  const onSetPageSize = newPageSize => {
    setPageInfo(prevState => ({
      ...prevState,
      page: 0,
      limit: newPageSize,
    }));
  };

  const onSetPage = newPage => {
    setPageInfo(prevState => ({
      ...prevState,
      page: newPage,
    }));
  };

  const onDownloadExcel = () => {
    // const { params } = onSetParams();

    const { gte, lte } = getDateRange();

    const filters = {
      'datetime_filter[gte]': gte,
      'datetime_filter[lte]': lte,
    };

    downloadXls(filters);
  };

  const setWindowData = () => {
    window.ipnReportsData = {};
    window.ipnReportsData.loadIpnReportsData = loadIpnReportsData;
  };

  useEffect(() => {
    if (selectedTimeframe) {
      urlSearchParams.set('timeframe', selectedTimeframe);

      props.history.push({
        pathname: ROUTE_PATH,
        search: `?${decodeURIComponent(urlSearchParams.toString())}`,
      });

      // const { params } = onSetParams();

      loadIpnReportsData();
    } else {
      loadIpnTimeframes();
    }
  }, [pageInfo.limit, pageInfo.page, location.search, selectedTimeframe]);

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(props.location.search);
    const initialTimeframe = currentUrlParams.get('timeframe');

    if (initialTimeframe) {
      setSelectedTimeframe(initialTimeframe);
    }

    setWindowData();

    return () => {
      delete window.ipnReportsData;
    };
  }, []);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs>
          <Breadcrumb onClick={() => setSelectedTimeframe(null)}>
            Отчеты
          </Breadcrumb>
          <Breadcrumb onClick={() => setSelectedTimeframe(null)}>
            Отчеты по ИПН
          </Breadcrumb>
          {selectedTimeframe && (
            <Breadcrumb>
              {formatMonthAndYear(new Date(selectedTimeframe))}
            </Breadcrumb>
          )}
        </Breadcrumbs>
        <Grid container alignItems="baseline">
          {selectedTimeframe && (
            <PageTitle gutterBottom={false}>
              Отчеты по ИПН за {formatMonthAndYear(new Date(selectedTimeframe))}
            </PageTitle>
          )}
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        {selectedTimeframe ? (
          <>
            <div className={classes.buttonsWrapper}>
              <Button
                variant="outlined"
                color="default"
                size="small"
                className={classes.button}
                onClick={() => onDownloadExcel(true)}
              >
                Вывести в EXCEL
              </Button>
            </div>
            <Table
              limit={pageInfo.limit}
              items={ipnReportsData}
              isLoading={pageInfo.loading}
              totalCount={pageInfo.totalCount}
              page={pageInfo.page}
              updatePage={onSetPage}
              updateLimit={onSetPageSize}
            />
          </>
        ) : (
          <div className={classes.timeframeBlock}>
            <h1>Период</h1>
            <hr />
            <div>
              {timeframes.map(timeframe => (
                <div className={classes.timeframeItem} key={timeframe}>
                  <span onClick={() => setSelectedTimeframe(timeframe)}>
                    {formatMonthAndYear(new Date(timeframe))}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </PageContent>
    </Page>
  );
};

PurchaseWithoutScanPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
};

PurchaseWithoutScanPage.defaultProps = {
  history: {},
};

export default PurchaseWithoutScanPage;
