import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { defaultProps } from 'recompose';

import EditPage from '../EditPage/component';
import styles from '../EditPage/styles';

export default compose(
  defaultProps({
    isFirstStepEditable: true,
    isEditable: true,
    isLoaded: true,
  }),
  withStyles(styles),
)(EditPage);
