import { cloneQuery } from '@esentai/core/queries';
import { getTime } from 'date-fns';
import { pipe, prop, sortBy } from 'ramda';

import { TIMEFRAME_KEY } from './consts/keys';

export const omitPagination = queryObject => {
  const queryCopy = cloneQuery(queryObject);

  queryCopy.delete('page');
  queryCopy.delete('limit');

  return queryCopy;
};

export const getIdProp = prop(TIMEFRAME_KEY);

export const sortChartItems = sortBy(pipe(getIdProp, getTime));
