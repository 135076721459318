export default {
  gallery: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    flexWrap: 'wrap',
    maxWidth: '75%',
    overflow: 'auto',
    justifyContent: 'space-around',
  },
  sortableHelper: {
    zIndex: '10000 !important',
  },
};
