import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddLink from '@/components/AddLink';
import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';
import { ROUTE_PATH as NEW_QUESTION_PATH } from '@/features/questionCreatePage/consts';

import Table from './Table';

class Questions extends Component {
  static propTypes = {
    error: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    itemsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, isLoading, itemsIds, totalCount } = this.props;

    return (
      <SettingsPage>
        <SettingsPageHeader borderBottom gutterBottom={false}>
          <Grid container alignItems="baseline" justify="space-between">
            <SettingsPageTitle>Список вопросов</SettingsPageTitle>
            <AddLink to={NEW_QUESTION_PATH} />
          </Grid>
        </SettingsPageHeader>
        <SettingsPageContent>
          <Table
            error={error}
            isLoading={isLoading}
            items={itemsIds}
            totalCount={totalCount}
          />
        </SettingsPageContent>
      </SettingsPage>
    );
  }
}

export default Questions;
