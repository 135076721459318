import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';

const LoyaltyRepresentative = ({ isLoyaltyRep }) => (
  <FieldRow name="Партнер программы лояльности">
    <Grid container>
      <Grid item>
        <Typography variant="h5">
          {isLoyaltyRep
            ? 'Магазин партнер программы лояльности'
            : 'Магазин не является партнером программы лояльности'}
        </Typography>
      </Grid>
    </Grid>
  </FieldRow>
);

LoyaltyRepresentative.propTypes = {
  isLoyaltyRep: PropTypes.bool.isRequired,
};

export default LoyaltyRepresentative;
