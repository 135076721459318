import { IconButton, InputAdornment } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import EmptyComponent from '@/components/EmptyComponent';
import FilterChip from '@/components/FilterChip';
import {
  createPageFilterUpdate,
  getPageFilterInitialValue,
} from '@/structs/pageFilter';

import AutocompleteInput from './AutocompleteInput';
import FiltersList from './FiltersList';
import { getDescriptor, isDefaultValue } from './utils';

const InputProps = {
  placeholder: 'Добавить фильтр',
  InputProps: {
    disableUnderline: true,
  },
};

export default class FilterBar extends Component {
  static propTypes = {
    filterTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
    removeFilters: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    updateFilters: PropTypes.func.isRequired,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.any,
      }),
    ).isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  state = {
    addDescriptor: null,
  };

  render() {
    const { classes, filterTypes, disabled } = this.props;
    const unusedFilterTypes = this.getUnusedFilterTypes();
    const hasUnusedFilterTypes = unusedFilterTypes.length > 0;

    return (
      <Fragment>
        <div className={classes.filterBar}>
          <FiltersList
            filters={this.getValuesList()}
            filterTypes={filterTypes}
            FilterChip={this.renderFilter}
          />
          {hasUnusedFilterTypes && (
            <AutocompleteInput
              disabled={disabled}
              isOpenOnFocus
              className={classes.input}
              InputProps={InputProps}
              options={unusedFilterTypes}
              onChange={this.handlerOnChange}
              Selection={EmptyComponent}
              renderCleanAll={this.CleanAll}
              selectedItem={null}
            />
          )}
        </div>
      </Fragment>
    );
  }

  handlerOnChange = key => {
    const { filterTypes } = this.props;

    this.openAddDropdown(getDescriptor(filterTypes, key));
  };

  clearAllFilters = () => {
    this.props.removeFilters(this.getValuesList().map(({ key }) => key));
  };

  CleanAll = () =>
    this.getAllKnowFilters().length > 1 && (
      <InputAdornment position="end">
        <IconButton aria-label="Удалить все" onClick={this.clearAllFilters}>
          <CancelIcon />
        </IconButton>
      </InputAdornment>
    );

  renderFilter = ({ filter, FormatComponent, ...props }) => {
    const statefulProps = this.getStatefulProps(filter.key);
    const { filterTypes, removeFilters, updateFilters, disabled } = this.props;
    const descriptor = getDescriptor(filterTypes, filter.key);

    return (
      <FilterChip
        {...props}
        {...statefulProps}
        disabled={disabled}
        FormatComponent={
          isDefaultValue(filter) ? EmptyComponent : FormatComponent
        }
        onChange={value =>
          updateFilters(createPageFilterUpdate(descriptor, value))
        }
        onRemove={() => removeFilters([filter.key])}
      />
    );
  };

  getUnusedFilterTypes() {
    const { filters, filterTypes } = this.props;
    const hasFilterForType = type =>
      filters.find(filter => filter.key === type.key);

    return filterTypes.filter(
      type => !hasFilterForType(type) && !type.quicklyAccessible,
    );
  }

  isKnownFilter = filter => getDescriptor(this.props.filterTypes, filter.key);

  getAllKnowFilters() {
    return this.props.filters.filter(this.isKnownFilter);
  }

  getValuesList() {
    const { addDescriptor } = this.state;

    if (addDescriptor) {
      const emptyFilter = {
        key: addDescriptor.key,
        value: getPageFilterInitialValue(addDescriptor),
        isEmpty: true,
      };

      return [...this.getAllKnowFilters(), emptyFilter];
    }

    return this.getAllKnowFilters();
  }

  getStatefulProps(key) {
    const { addDescriptor } = this.state;

    if (addDescriptor && key === addDescriptor.key) {
      return {
        isOpen: true,
        onClose: this.closeAddDropdown,
      };
    }

    return {};
  }

  openAddDropdown(descriptor) {
    this.setState({ addDescriptor: descriptor });
  }

  closeAddDropdown = () => this.setState({ addDescriptor: null });
}
