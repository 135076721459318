import {
  getCompanyForMerchant,
  isMerchantLoaded,
} from '@esentai/core/features/merchants/selectors';
import PropTypes from 'prop-types';
import React from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import LazyEditForm from '@/components/LazyEditForm';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import MerchantNameLazy from '@/containers/MerchantNameLazy';
import { createMerchantPageUrl } from '@/features/merchantPage/utils';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';

import Form from './Form';

const PageEditMerchant = ({
  fetchItem,
  getItemLoadingError,
  isItemLoaded,
  isItemLoading,
  merchantId,
  updateMerchant,
}) => (
  <Page>
    <PageHeader gutterBottom={false} xl={8}>
      <Breadcrumbs>
        <Breadcrumb to={MERCHANTS_PATH}>Арендаторы</Breadcrumb>
        <Breadcrumb to={createMerchantPageUrl(merchantId)}>
          <MerchantNameLazy
            getTitle={getCompanyForMerchant}
            isLoaded={isMerchantLoaded}
            merchantId={merchantId}
          />
        </Breadcrumb>
      </Breadcrumbs>
      <BackButton />
      <PageTitle>Редактирование арендатора</PageTitle>
    </PageHeader>
    <PageContent xl={8}>
      <PaperSheet>
        <LazyEditForm
          fetchItem={fetchItem}
          getItemLoadingError={getItemLoadingError}
          isItemLoaded={isItemLoaded}
          isItemLoading={isItemLoading}
          itemId={merchantId}
        >
          <Form merchantId={merchantId} onSubmit={updateMerchant} />
        </LazyEditForm>
      </PaperSheet>
    </PageContent>
  </Page>
);

PageEditMerchant.propTypes = {
  fetchItem: PropTypes.func.isRequired,
  getItemLoadingError: PropTypes.func.isRequired,
  isItemLoaded: PropTypes.func.isRequired,
  isItemLoading: PropTypes.func.isRequired,
  updateMerchant: PropTypes.func.isRequired,
  merchantId: PropTypes.any.isRequired,
};

export default PageEditMerchant;
