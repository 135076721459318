import PropTypes from 'prop-types';
import React from 'react';

import { dateTimeFormatNoSecs } from '@/utils/format';

import Field from '../Field';

const Description = ({
  classes,
  storeName,
  startDatetime,
  endDatetime,
  percent,
  fnsId,
}) => (
  <>
    <div className={classes.facts}>
      <Field label="Магазин" className={classes.fact}>
        {storeName}
      </Field>
      <Field label="Процент начисления бонусов" className={classes.fact}>
        {percent} %
      </Field>
    </div>
    <div className={classes.facts}>
      <Field label="Номер ККМ" className={classes.fact}>
        {fnsId}
      </Field>
      <Field label="Период действия акции" className={classes.fact}>
        {dateTimeFormatNoSecs(startDatetime)} -{' '}
        {dateTimeFormatNoSecs(endDatetime)}
      </Field>
    </div>
  </>
);

Description.defaultProps = {
  fnsId: '',
  storeName: '',
  percent: 0,
};

Description.propTypes = {
  storeName: PropTypes.string,
  endDatetime: PropTypes.instanceOf(Date).isRequired,
  startDatetime: PropTypes.instanceOf(Date).isRequired,
  fnsId: PropTypes.string,
  percent: PropTypes.number,
};

export default Description;
