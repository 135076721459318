import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import FlowRateChart from '@/components/FlowRateChart';

const Notification = ({ pushClicksCount, pushClicksToSegmentPercentage }) => (
  <Fragment>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={pushClicksCount}
        percentage={pushClicksToSegmentPercentage}
      />
    </CondenseTableCell>
  </Fragment>
);

Notification.propTypes = {
  pushClicksToSegmentPercentage: PropTypes.number.isRequired,
  pushClicksCount: PropTypes.number.isRequired,
};

export default Notification;
