import {
  DAY_SCALE,
  HOUR_SCALE,
  MONTH_SCALE,
  WEEK_SCALE,
} from '@/features/statistics/consts/scales';

import DayTickFormatter from './DayTickFormatter';
import HourTickFormatter from './HourTickFormatter';
import MonthTickFormatter from './MonthTickFormatter';
import {
  formatDayTick,
  formatHourTick,
  formatMonthTick,
  formatWeekTick,
} from './utils';
import WeekTickFormatter from './WeekTickFormatter';

const mapping = {
  [HOUR_SCALE]: {
    name: HOUR_SCALE,
    Formatter: HourTickFormatter,
    format: formatHourTick,
  },
  [DAY_SCALE]: {
    name: DAY_SCALE,
    Formatter: DayTickFormatter,
    format: formatDayTick,
  },
  [WEEK_SCALE]: {
    name: WEEK_SCALE,
    Formatter: WeekTickFormatter,
    format: formatWeekTick,
  },
  [MONTH_SCALE]: {
    name: MONTH_SCALE,
    Formatter: MonthTickFormatter,
    format: formatMonthTick,
  },
};

export const getStrategy = scale => mapping[scale];
