import { toggleVisibility } from '@esentai/core/features/productCategory/actions';
import { isVisibleToCustomers } from '@esentai/core/features/productCategory/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import VisibilityButton from './component';

const mapStateToProps = (state, { itemId }) => ({
  visible: isVisibleToCustomers(state, itemId),
});

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(toggleVisibility(itemId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(VisibilityButton);
