import dao from '@esentai/core/features/kkms/dao';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import { FETCH_KKM } from './consts/types';

export const { findById: findKkmById } = dao.sagas;

export function* fetchKkm(action) {
  const { payload: kkmId } = action;

  yield call(findKkmById, kkmId);
}

export function* watchFetchKkm() {
  yield takeEvery(FETCH_KKM, fetchKkm);
}

export default function*() {
  yield spawn(watchFetchKkm);
}
