import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { loyaltyLevels } from '@/features/mallReceptionUsersPage/utils';

const Row = props => {
  const { id, first_name, last_name, phone, level } = props.item;

  const getFullName = () => {
    if (!first_name && !last_name) {
      return '-';
    }

    return `${first_name} ${last_name}`;
  };

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{getFullName()}</TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>{loyaltyLevels[level]}</TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
