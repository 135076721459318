import { NAME_KEY } from '@esentai/core/features/brands/consts/keys';

import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: NAME_KEY,
    label: 'Название',
  }),
];
