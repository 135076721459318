import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import TableCellTypography from '@/components/TableCellTypography';

import CommonCells from '../CommonCells';
import { propTypes as CommonCellsPropTypes } from '../CommonCells/component';
import TickFormatter from './TickFormatter';

const Row = ({
  averageCheck,
  averageTimeSpent,
  buyersCount,
  conversion,
  itemId,
  leadsCount,
  purchasesCount,
  usersCount,
  visitorsCount,
  mallVisitorsCount,
}) => (
  <TableRow>
    <CondenseTableCell>
      <TickFormatter component={TableCellTypography} itemId={itemId} />
    </CondenseTableCell>
    <CommonCells
      averageCheck={averageCheck}
      averageTimeSpent={averageTimeSpent}
      buyersCount={buyersCount}
      conversion={conversion}
      leadsCount={leadsCount}
      purchasesCount={purchasesCount}
      usersCount={usersCount}
      visitorsCount={visitorsCount}
      mallVisitorsCount={mallVisitorsCount}
    />
  </TableRow>
);

Row.propTypes = {
  ...CommonCellsPropTypes,
  itemId: PropTypes.any.isRequired,
};

export default Row;
