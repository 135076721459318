import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import MainPageTopBar from '@/components/DevicePreview/MainPageTopBar';
import {
  LOCALE_EN,
  LOCALE_KZ,
  LOCALE_RU,
} from '@/hocs/withDevicePreview/consts';

import CloseIcon from './close.svg';

const Preview = ({
  classes,
  englishTitle,
  russianTitle,
  kazakhTitle,
  selectByLocale,
  imageUrl,
}) => {
  const title = selectByLocale({
    [LOCALE_EN]: englishTitle,
    [LOCALE_RU]: russianTitle,
    [LOCALE_KZ]: kazakhTitle,
  });

  return (
    <Fragment>
      <MainPageTopBar />
      <div className={classes.container}>
        <div className={classes.modal}>
          <CloseIcon className={classes.closeIcon} />
          {imageUrl ? (
            <img src={imageUrl} className={classes.image} />
          ) : (
            <div className={classes.placeholder} />
          )}
          <div className={classes.title}>{title}</div>
        </div>
      </div>
    </Fragment>
  );
};

Preview.propTypes = {
  imageUrl: PropTypes.string,
  englishTitle: PropTypes.string,
  russianTitle: PropTypes.string,
  kazakhTitle: PropTypes.string,
  selectByLocale: PropTypes.func.isRequired,
};
Preview.defaultProps = {
  imageUrl: null,
  englishTitle: null,
  russianTitle: null,
  kazakhTitle: null,
};

export default Preview;
