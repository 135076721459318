import createItemsDuck from '@esentai/core/ducks/items';

import { RESOURCE_NAME } from '@/features/campaignsStatistics/consts';
import { getIdProp } from '@/features/statistics/utils';

import api from '../api';
import { ENTITY_NAME } from '../consts';
import { getItemsRoot } from '../utils';

export default createItemsDuck(ENTITY_NAME, {
  api,
  resourceName: RESOURCE_NAME,
  getRoot: getItemsRoot,
  getIdProp,
});
