import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getPurchasesError,
  getItemsIds: getPurchaseIds,
  getItemsMeta: getPurchasesMeta,
  getItemsTotalCount: getPurchasesTotalCount,
  isLoadingItems: isLoadingPurchases,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
