import {
  CUSTOMER_KEY,
  DATE_ISSUED_KEY,
  PURCHASES_COUNT_KEY,
  TOTAL_COST_KEY,
} from '@esentai/core/features/receipts/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createPurchaseAnonymityFilter } from '@/filters/PurchaseAnonymity';
import { createRangeFilter } from '@/filters/Range';

export const filterTypes = [
  createPurchaseAnonymityFilter({
    key: CUSTOMER_KEY,
    label: 'Покупатели',
    quicklyAccessible: true,
  }),
  createDateRangeFilter({
    key: DATE_ISSUED_KEY,
    label: 'Дата покупки',
  }),
  createRangeFilter({
    key: PURCHASES_COUNT_KEY,
    label: 'Количество покупок',
  }),
  createMoneyRangeFilter({
    key: TOTAL_COST_KEY,
    label: 'Сумма покупки',
  }),
];
