import { FEATURE_NAME } from '@/features/bonusTransactionLevelHistoryPage/consts';
import createQueryDuck from '@/redux/query';

import serializer from '../serializer';
import { getQueryRoot } from '../utils';

export default createQueryDuck(FEATURE_NAME, {
  getRoot: getQueryRoot,
  serializer,
});
