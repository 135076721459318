import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { deauthorize, expand } from '@/features/auth/actions';
import { getExpanded } from '@/features/auth/selectors';
import { getName } from '@/features/currentUser/selectors';

import Root from './component';
import styles from './styles';

const mapStateToProps = state => ({
  name: getName(state),
  expanded: getExpanded(state),
});

const mapDispatchToProps = {
  logOut: deauthorize,
  expand,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Root);
