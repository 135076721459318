import { KAZAKH_TITLE_KEY } from '@esentai/core/features/campaigns/banner/consts/keys';
import { VERY_LOW_PRIORITY } from '@esentai/core/features/campaigns/consts/priorities';
import {
  ENGLISH_DESCRIPTION_KEY,
  ENGLISH_TITLE_KEY,
  KAZAKH_DESCRIPTION_KEY,
  PLACEMENT_KEY,
  PRIORITY_KEY,
  PRODUCTS_IDS_KEY,
  PUSH_ENABLED,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_KAZAKH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  RUSSIAN_DESCRIPTION_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/show/consts/keys';
import { ALL_FEED } from '@esentai/core/features/campaigns/show/consts/popupPlacementTypes';
import { boolean, number, object, string } from 'yup';

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({
    headerRu,
    headerEn,
    headerKz,
    pushEnabled,
    pushTitleRu,
    pushTitleEn,
    pushTitleKz,
    englishDescription,
    russianDescription,
    kazakhDescription,
    pushMessageRu,
    pushMessageEn,
    pushMessageKz,
    placement,
    priority,
    products,
  }) => ({
    [ENGLISH_DESCRIPTION_KEY]: englishDescription || '',
    [RUSSIAN_DESCRIPTION_KEY]: russianDescription || '',
    [KAZAKH_DESCRIPTION_KEY]: kazakhDescription || '',
    [RUSSIAN_TITLE_KEY]: headerRu || '',
    [ENGLISH_TITLE_KEY]: headerEn || '',
    [KAZAKH_TITLE_KEY]: headerKz || '',
    [PLACEMENT_KEY]: placement || ALL_FEED,
    [PRIORITY_KEY]: priority || VERY_LOW_PRIORITY,
    [PUSH_ENABLED]: pushEnabled || false,
    [PUSH_RUSSIAN_TITLE_KEY]: pushTitleRu,
    [PUSH_ENGLISH_TITLE_KEY]: pushTitleEn,
    [PUSH_KAZAKH_TITLE_KEY]: pushTitleKz,
    [PUSH_RUSSIAN_MESSAGE_KEY]: pushMessageRu,
    [PUSH_ENGLISH_MESSAGE_KEY]: pushMessageEn,
    [PUSH_ENGLISH_MESSAGE_KEY]: pushMessageKz,
    [PRODUCTS_IDS_KEY]: products || [],
  }),

  validationSchema: object().shape({
    [PRIORITY_KEY]: number().required(),
    [RUSSIAN_TITLE_KEY]: string()
      .max(50)
      .required(),
    [ENGLISH_TITLE_KEY]: string()
      .max(50)
      .required(),
    [KAZAKH_TITLE_KEY]: string()
      .max(50)
      .required(),
    [ENGLISH_DESCRIPTION_KEY]: string().max(1000),
    [RUSSIAN_DESCRIPTION_KEY]: string().max(1000),
    [KAZAKH_DESCRIPTION_KEY]: string().max(1000),
    [PUSH_ENABLED]: boolean(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
