import { getStoresIds } from '@esentai/core/features/brands/selectors';
import { connect } from 'react-redux';

import Stores from './component';

const mapStateToProps = (state, { itemId }) => ({
  storesIds: getStoresIds(state, itemId),
});

export default connect(mapStateToProps)(Stores);
