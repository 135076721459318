import { withStyles } from '@material-ui/core';
import React from 'react';

import { userStatus } from '@/utils/labels';

import ChartBar from './ChartBar';

export const getBarComponent = ({ status, styles }) => {
  const CustomBar = props => <ChartBar label={userStatus(status)} {...props} />;

  return withStyles(styles)(CustomBar);
};
