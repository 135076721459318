import { connect } from 'react-redux';

import { confirmInvite, findInvite } from '@/features/invites/actions';
import { getRouterParameterByMatch } from '@/redux/router/selectors';

import Invite from './component';

const mapStateToProps = (_, { match }) => ({
  token: getRouterParameterByMatch(match, 'token'),
});

const mapDispatchToProps = (dispatch, { match }) => {
  const token = getRouterParameterByMatch(match, 'token');

  return {
    findInvite: () => dispatch(findInvite(token)),
    confirmInvite: attributes =>
      dispatch(confirmInvite({ token, ...attributes })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
