import { connect } from 'react-redux';

import { authenticate } from '@/features/auth/actions';

import Login from './component';

const mapStateToProps = ({ auth: { authorized } }) => ({
  authorized,
});

const mapDispatchToProps = {
  authenticate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
