import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  getQuickFilterId,
  getQuickFilterLabel,
  getQuickFilterUpdate,
  isQuickFilterSelected,
} from '@/structs/quickFilter';

import Option from './Option';

class QuickFiltersRow extends Component {
  static defaultProps = {
    value: null,
  };

  static propTypes = {
    clear: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    value: PropTypes.any,
  };

  handleChange = option => {
    const { onChange } = this.props;
    const update = getQuickFilterUpdate(option);

    onChange(update);
  };

  render() {
    const {
      classes,
      clear,
      onClear,
      onClose,
      onOpen,
      open,
      options,
      value,
    } = this.props;

    return (
      <div className={classes.root}>
        <Option active={clear} onClick={onClear}>
          Все
        </Option>
        {options.map(option => (
          <Option
            key={getQuickFilterId(option)}
            active={isQuickFilterSelected(option, value)}
            option={option}
            onClick={this.handleChange}
          >
            {getQuickFilterLabel(option)}
          </Option>
        ))}
        <Option active={open} onClick={open ? onClose : onOpen}>
          Фильтры
          <KeyboardArrowDown className={classes.arrowDownIcon} />
        </Option>
      </div>
    );
  }
}

export default QuickFiltersRow;
