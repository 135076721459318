import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FormatComponent from '../FormatComponent';

const InputSelectedItem = ({ value, onDelete, ...props }) => (
  <Chip
    {...props}
    onDelete={() => onDelete(value)}
    label={<FormatComponent value={[value]} />}
  />
);

InputSelectedItem.defaultProps = {
  value: null,
};

InputSelectedItem.propTypes = {
  value: PropTypes.any,
  onDelete: PropTypes.func.isRequired,
};

export default InputSelectedItem;
