import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import UserNameLazy from '@/containers/UserNameLazy';
import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormat } from '@/utils/format';

const Row = ({ efsVisitRecordId, userId, recordCreated, phoneNumber }) => (
  <>
    <TableRow>
      <TableCell>{efsVisitRecordId}</TableCell>
      <TableCell>{phoneNumber || '-'}</TableCell>

      {/* <CanView permission={canReadUser}>*/}
      <TableCell>
        {!userId ? '-' : <Link to={createUserPageUrl(userId)}>{userId}</Link>}
      </TableCell>
      {/* </CanView>*/}
      {/* <CanView permission={canReadUser}>*/}
      <TableCell>{userId ? <UserNameLazy userId={userId} /> : '-'}</TableCell>
      {/* </CanView>*/}
      <TableCell>{dateTimeFormat(recordCreated)}</TableCell>
    </TableRow>
  </>
);

Row.propTypes = {
  efsVisitRecordId: PropTypes.number.isRequired,
  phoneNumber: PropTypes.string,
  recordCreated: PropTypes.instanceOf(Date).isRequired,
  userId: PropTypes.number,
};

Row.defaultProps = {
  userId: null,
  phoneNumber: '',
};

export default Row;
