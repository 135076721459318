import { updateParkingWhiteListFilter } from '@esentai/core/features/parkingWhitelistFilter/actions';
import {
  getAddedUsersForParkingWhitelistFilter,
  getFilterMapForParkingWhitelistFilter,
  getStableForParkingWhitelistFilter,
} from '@esentai/core/features/parkingWhitelistFilter/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Description from './component';
import styles from './styles';

const mapDispatchToProps = dispatch => ({
  updateFilter: values =>
    dispatch(
      updateParkingWhiteListFilter({
        ...values,
      }),
    ),
});

const mapStateToProps = (state, { itemId }) => ({
  stable: getStableForParkingWhitelistFilter(state, itemId),
  filterMap: getFilterMapForParkingWhitelistFilter(state, itemId),
  addedUsers: getAddedUsersForParkingWhitelistFilter(state, itemId),
  itemId,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Description);
