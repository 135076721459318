import { canDeleteCreditCard } from '@esentai/core/features/creditCards/permissions';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import 'toastr/build/toastr.min.css';

import CanView from '@/containers/CanView';
import CreditCardDeleteButton from '@/features/userPage/Page/CreditCardDeleteButton';
import { dateTimeFormatNoSecs } from '@/utils/format';

import { statusLabel } from '../../labels';

const CreditCardsInfo = ({ classes, cards, onSuccess }) => (
  <div className={classes.root}>
    <Typography
      gutterBottom
      color="textSecondary"
      variant="h5"
      className={classes.title}
    >
      Данные по карте
    </Typography>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Маска</TableCell>
          <TableCell>Статус</TableCell>
          <TableCell>Добавлена</TableCell>
          <CanView permission={canDeleteCreditCard}>
            <TableCell>Действия</TableCell>
          </CanView>
        </TableRow>
      </TableHead>
      <TableBody>
        {cards.map(item => (
          <TableRow key={item.card_id}>
            <TableCell>{item.card_hash}</TableCell>
            <TableCell>{statusLabel(item.is_for_parking)}</TableCell>
            <TableCell>{dateTimeFormatNoSecs(item.created_date)}</TableCell>
            <CanView permission={canDeleteCreditCard}>
              <TableCell>
                <CreditCardDeleteButton
                  cardInfo={{
                    cardId: item.card_id,
                    mpToken: item.mp_token,
                    onSuccess,
                  }}
                />
              </TableCell>
            </CanView>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

CreditCardsInfo.propTypes = {
  cards: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CreditCardsInfo;
