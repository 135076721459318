import { compose } from 'ramda';
import { defaultProps } from 'recompose';

import PushNotificationDevicePreview from '@/components/PushNotificationDevicePreview';
import withDevicePreview from '@/hocs/withDevicePreview';

import PushNotification from './PushNotification';

export default compose(
  withDevicePreview({ variant: 'shortView' }),
  defaultProps({ PushNotification }),
)(PushNotificationDevicePreview);
