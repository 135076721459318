export default {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '400px',
    margin: 15,
  },
  item: {
    display: 'flex',
    padding: '16px',
    position: 'relative',
    width: '100px',
    // height: '100px',
    maxWidth: '40%',
    flexBasis: '100%',
    borderRadius: '2px',
    flexDirection: 'column',
  },
  innerItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
  },
};
