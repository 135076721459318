import {
  getBonusName,
  getEndDatetime,
  getInvoicedAmount,
  getPaidAmount,
  getPercent,
  getProcessingAmount,
  getStartDatetime,
  getStoreId,
  getStoreName,
  getTotalToPayAmount,
} from '@esentai/core/features/reconciliationStoreBonusDetails/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { retryFetch } from '../../actions';
import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: reconciliationId }) => ({
  storeName: getStoreName(state, reconciliationId),
  totalToPayAmount: getTotalToPayAmount(state, reconciliationId),
  endDatetime: getEndDatetime(state, reconciliationId),
  startDatetime: getStartDatetime(state, reconciliationId),
  storeId: getStoreId(state, reconciliationId),
  paidAmount: getPaidAmount(state, reconciliationId),
  processingAmount: getProcessingAmount(state, reconciliationId),
  invoicedAmount: getInvoicedAmount(state, reconciliationId),
  percent: getPercent(state, reconciliationId),
  bonusName: getBonusName(state, reconciliationId),
  reconciliationId,
});

const mapDispatchToProps = {
  fetchItems: retryFetch,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Row);
