import { TRACKED_STORE_IDS_KEY } from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { Typography } from '@material-ui/core';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import { StoreMultiAutocomplete } from '@/containers/StoreAutocomplete';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';

class StoresForm extends Component {
  static propTypes = FormikPropTypes;

  setTrackedStoreIds = values => {
    const { setFieldValue } = this.props;

    setFieldValue(TRACKED_STORE_IDS_KEY, values);
  };

  render() {
    const { values } = this.props;

    return (
      <FieldRow
        label="Магазины"
        subLabel="Триггеры магазина"
        sudDesc="Триггер магазина срабатывает, когда пользователь совершает покупку в указанных магазинах. Без указания будут отслеживаться все покупки в Молле. Все триггеры работают совместно, поэтому если вы укажите условия триггеров, которые противоречат друг другу, то они не сработают никогда."
      >
        <Typography gutterBottom>
          Активировать отправку сообщения, если пользователь купил в одном из
          магазинов:
        </Typography>
        <CanEdit field={TRACKED_STORE_IDS_KEY}>
          <StoreMultiAutocomplete
            onChange={this.setTrackedStoreIds}
            selectedItem={values[TRACKED_STORE_IDS_KEY]}
            InputProps={{
              fullWidth: true,
              placeholder: 'Добавьте магазин триггера',
              InputProps: { disableUnderline: true },
            }}
          />
        </CanEdit>
      </FieldRow>
    );
  }
}

export default StoresForm;
