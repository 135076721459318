import {
  FACTORY_NUMBER_KEY,
  KKM_NUMBER_KEY,
  MERCHANT_ID_KEY,
  STATUS_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/kkms/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';

import { canUpdateKkm } from '../../permissions';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field="id">ID KKM</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={KKM_NUMBER_KEY}>Номер ККМ</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={FACTORY_NUMBER_KEY}>ЗНМ</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={MERCHANT_ID_KEY}>БИН/ИИН</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={STORE_ID_KEY}>Магазин</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={STATUS_KEY}>Статус</SortControl>
    </CondenseTableCell>

    <CanView permission={canUpdateKkm}>
      <CondenseTableCell>Действия</CondenseTableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
