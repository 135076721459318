import { RECORD_CREATED_KEY } from '@esentai/core/features/mallStats/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';

export const filterTypes = [
  createDateRangeFilter({
    key: RECORD_CREATED_KEY,
    label: 'Дата создания и время отчета',
  }),
];
