import api from '@esentai/core/features/locations/api';
import { NAME_RUSSIAN_KEY } from '@esentai/core/features/locations/consts/keys';
import { ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';

import LocationChip from './LocationChip';
import LocationOption from './LocationOption';

const buildQuery = queryString =>
  query(where(NAME_RUSSIAN_KEY, ilike(queryString)));

const withStoreProps = defaultProps({
  api,
  buildQuery,
  Chip: LocationChip,
  Option: LocationOption,
});

export const LocationAutocomplete = withStoreProps(AutocompleteAPI);

export const LocationMultiAutocomplete = withStoreProps(
  AutocompleteAPIMultiple,
);
