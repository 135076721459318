import { WAITING } from '@esentai/core/features/loyalties/consts/statuses';
import { TableCell, TableRow } from '@material-ui/core';
// import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import UserNameLazy from '@/containers/UserNameLazy';
import { dateTimeFormat, formatNumber } from '@/utils/format';
import {
  activityType,
  loyaltyAssignStatuses,
  userStatus,
} from '@/utils/labels';

import CancelRequestButton from './Buttons/CancelRequestButton';

// const differenceBetweenToday = date => {
//   const today = moment().utc();
//   const currentDate = moment(`${date}Z`);
//   const difference = today.diff(currentDate, 'hours');

//   return difference;
// };

const Row = ({
  id,
  loyaltyLevel,
  customer,
  comissarId,
  comment,
  userId,
  activity,
  loyaltyBonuses,
  loyaltyProcessDate,
  createdDate,
  status,
  // type,
}) => (
  <TableRow>
    <TableCell>{id}</TableCell>
    <TableCell>{customer}</TableCell>
    <TableCell>
      <UserNameLazy userId={customer} />
    </TableCell>
    <TableCell>
      <UserNameLazy userId={userId} />
    </TableCell>
    <TableCell>
      <UserNameLazy userId={comissarId} />
    </TableCell>
    <TableCell>{dateTimeFormat(createdDate)}</TableCell>
    <TableCell>
      {loyaltyProcessDate ? dateTimeFormat(loyaltyProcessDate) : '-'}
    </TableCell>
    <TableCell>{activityType(activity)}</TableCell>
    <TableCell align="right">
      {loyaltyBonuses ? formatNumber(loyaltyBonuses) : '-'}
    </TableCell>
    <TableCell>{userStatus(loyaltyLevel) || '-'}</TableCell>
    <TableCell>{comment}</TableCell>
    <TableCell>{loyaltyAssignStatuses(status)}</TableCell>
    <TableCell>
      <CancelRequestButton
        itemId={id}
        disabled={
          // differenceBetweenToday(createdDate) < 24 ||
          // type === LEVEL_CHANGED ||
          status !== WAITING
        }
      >
        Отменить запрос
      </CancelRequestButton>
    </TableCell>
  </TableRow>
);

Row.propTypes = {
  loyaltyLevel: PropTypes.string,
  userId: PropTypes.number.isRequired,
  activity: PropTypes.string.isRequired,
  customer: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  comissarId: PropTypes.number.isRequired,
  loyaltyBonuses: PropTypes.number,
  loyaltyProcessDate: PropTypes.instanceOf(Date),
  createdDate: PropTypes.instanceOf(Date).isRequired,
  status: PropTypes.string.isRequired,
  // type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

Row.defaultProps = {
  loyaltyLevel: '-',
  loyaltyBonuses: 0,
  loyaltyProcessDate: '-',
};

export default Row;
