import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';
import ConfirmModal from '@/components/ConfirmModal';
import RemoveIcon from '@/components/Icon/Remove';
import CanView from '@/containers/CanView';

const RemoveButton = ({ permission, title, onClick, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CanView permission={permission}>
      {!isOpen ? (
        <ActionButton
          text="Удалить"
          button={AsyncButton}
          icon={RemoveIcon}
          {...rest}
          onClick={() => setIsOpen(true)}
        />
      ) : (
        <ConfirmModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onConfirm={onClick}
          cancelLabel="Отмена"
          confirmLabel="Удалить"
          title={
            title || 'Вы уверены, что удалить данного пользователя из списка?'
          }
          style={{ display: 'flex' }}
        />
      )}
    </CanView>
  );
};

RemoveButton.defaultProps = {
  onClick: null,
  title: '',
};

RemoveButton.propTypes = {
  permission: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default RemoveButton;
