import React from 'react';
import { wrapDisplayName } from 'recompose';

import DevicePreview from '@/components/DevicePreview';

import Container from './Container';

const withDevicePreview = options => WrappedComponent => {
  const WithDevicePreview = props => (
    <Container>
      {deviceProps => (
        <DevicePreview {...options}>
          <WrappedComponent {...props} {...deviceProps} />
        </DevicePreview>
      )}
    </Container>
  );

  WithDevicePreview.displayName = wrapDisplayName(
    WrappedComponent,
    'withDevicePreview',
  );

  return WithDevicePreview;
};

export default withDevicePreview;
