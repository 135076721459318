import {
  DATETIME_END_KEY,
  DATETIME_START_KEY,
  LOCATION_IDS_KEY,
  TITLE_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import { BANNER_TYPE } from '@esentai/core/features/campaigns/consts/types';
import { addMinutes, isValid } from 'date-fns';
import { __, includes } from 'ramda';
import { date, object, string } from 'yup';

import {
  TYPES_WITHOUT_END_DATE,
  TYPES_WITHOUT_START_DATE,
} from '@/features/campaignCreatePage/consts/types';

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({
    dateStart = null,
    dateEnd = null,
    title = '',
    type = BANNER_TYPE,
    locations = [],
  }) => ({
    [DATETIME_START_KEY]: dateStart,
    [DATETIME_END_KEY]: dateEnd,
    [TITLE_KEY]: title,
    [TYPE_KEY]: type,
    [LOCATION_IDS_KEY]: locations,
  }),

  validationSchema: ({ getEditableAttributes }) => {
    const validateObj = {
      [TYPE_KEY]: string(),
      [DATETIME_START_KEY]: date().when(TYPE_KEY, {
        is: includes(__, TYPES_WITHOUT_START_DATE),
        then: schema => schema.nullable(),
        otherwise: date()
          .min(
            addMinutes(new Date(), 5),
            'Время старта +5 мин от текущего времени',
          )
          .required(),
      }),
      [DATETIME_END_KEY]: date()
        .when([TYPE_KEY, DATETIME_START_KEY], (type, dateStart, schema) => {
          if (includes(type, TYPES_WITHOUT_END_DATE)) {
            return schema.nullable();
          }
          const message =
            'Дата завершения не может быть раньше, чем дата начала';

          return isValid(dateStart)
            ? schema.min(dateStart, message).required()
            : schema.required();
        })
        .test({
          name: 'is end date between start date and system date',
          test(value) {
            const endDate = value;
            const nowDate = new Date();
            const startDate = this.parent[DATETIME_START_KEY];

            if (
              startDate.getTime() < endDate.getTime() &&
              nowDate.getTime() > endDate.getTime()
            ) {
              return this.createError({
                message: `Дата завершения не может быть ниже системного времени`,
                path: DATETIME_END_KEY,
              });
            }

            return true;
          },
        }),
      [TITLE_KEY]: string()
        .max(200)
        .required(),
    };

    return object(getEditableAttributes(validateObj));
  },

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
