import PropTypes from 'prop-types';
import React from 'react';

import { bonuses as bonusesLabel } from '@/utils/labels';

const Bonuses = ({ bonusesReward }) => <div>{bonusesLabel(bonusesReward)}</div>;

Bonuses.propTypes = {
  bonusesReward: PropTypes.number.isRequired,
};

export default Bonuses;
