import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { moveQuestionDown } from '@/features/questionsPage/actions';

import MoveDownButton from './component';

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(moveQuestionDown(itemId)),
});

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(MoveDownButton);
