import Resource from '@esentai/core/resources/base';
import axios from 'axios';

export class UploadResource extends Resource {
  getUrl = filename => {
    const { apiInstance } = this;

    return apiInstance
      .fetcher({
        url: '/generate-presigned-url',
        method: 'POST',
        data: {
          filename,
        },
      })
      .then(res => res.data);
  };

  upload = ({ url, file, onUploadProgress, ...rest }) =>
    axios({
      url,
      method: 'PUT',
      headers: { 'Content-Type': file.type },
      data: file,
      onUploadProgress,
      ...rest,
    });
}

export default new UploadResource();
