// import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

// import CloneButton from '../../buttons/CloneButton';
// import EditButton from '../../buttons/EditButton';
// import RemoveButton from '../../buttons/RemoveButton';

const DraftStatusActions = () => (
  <Fragment>
    {/* <EditButton itemId={itemId} />
    <CloneButton itemId={itemId} />
    <RemoveButton itemId={itemId} /> */}
  </Fragment>
);

// DraftStatusActions.propTypes = {
//   itemId: PropTypes.string.isRequired,
// };

export default DraftStatusActions;
