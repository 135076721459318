import {
  BONUS_NAME_KEY,
  CASH_MACHINES_KEY,
  COMMENT_KEY,
  END_DATE_KEY,
  ONE_TIME_DATETIME_KEY,
  PERCENT_KEY,
  PERIODIC_DAY_KEY,
  RECONCILIATION_TYPE_KEY,
  START_DATE_KEY,
  STORE_KEY,
} from '@esentai/core/features/storeBonuses/consts/keys';
import { FormHelperText, MenuItem, Select } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import addDays from 'date-fns/addDays';
import React from 'react';

import DateTimePicker from '@/components/DateTimePicker';
import FieldRow from '@/components/FieldRow';
import TextField from '@/components/TextField';
import { KkmMultiAutocompleteWithStore } from '@/containers/KkmAutocomplete';
import { StoreAutocomplete } from '@/containers/StoreAutocomplete';
import FormikPropTypes from '@/prop-types/formik';

import {
  RECONCILIATION_ONE_TIME_TYPE,
  RECONCILIATION_PERIODIC_TYPE,
} from '../../../consts/keys';
import RepeatSchedule from './RepeatSchedule';

class DestinationForm extends React.Component {
  static propTypes = FormikPropTypes;

  constructor(props) {
    super(props);

    this.state = {
      store: props.store > 0 ? props.store : 0,
      reconciliationType: props.reconciliationType
        ? props.reconciliationType
        : '',
      startDate: null,
      endDate: null,
    };
  }

  onChangeStore = storeId => {
    this.props.setFieldValue(STORE_KEY, storeId);
    this.props.setFieldValue(CASH_MACHINES_KEY, undefined);
    this.setState({ store: storeId });
  };

  onChangeStartDate = val => {
    this.setState({ startDate: val.target.value });
    this.props.setFieldValue(START_DATE_KEY, val.target.value);
  };

  onChangeEndDate = val => {
    this.setState({ endDate: val.target.value });
    this.props.setFieldValue(END_DATE_KEY, val.target.value);
    this.props.setFieldValue(ONE_TIME_DATETIME_KEY, undefined);
  };

  onChangereconciliationType = type => {
    this.props.setFieldValue(RECONCILIATION_TYPE_KEY, type);
    if (type === RECONCILIATION_PERIODIC_TYPE) {
      this.props.setFieldValue(ONE_TIME_DATETIME_KEY, undefined);
    } else {
      this.props.setFieldValue(PERIODIC_DAY_KEY, undefined);
    }
    this.setState({ reconciliationType: type });
  };

  onChangePeriodicDay = val => {
    this.props.setFieldValue(PERIODIC_DAY_KEY, val);
  };

  getReturnDays = () =>
    [...Array(28).keys()].map(x => ({
      id: x + 1,
      label: x + 1,
    }));

  onChangeKkm = kkmId => this.props.setFieldValue(CASH_MACHINES_KEY, kkmId);

  render() {
    const { classes, Field, values, errors } = this.props;
    const { store, reconciliationType, startDate, endDate } = this.state;
    const reconciliationTypes = [
      {
        id: RECONCILIATION_PERIODIC_TYPE,
        label: 'Периодичное погашение',
      },
      {
        id: RECONCILIATION_ONE_TIME_TYPE,
        label: 'Одноразовое погашение',
      },
    ];

    return (
      <>
        <FieldRow label="Название акции">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name={BONUS_NAME_KEY}
            multiline
            rows={2}
            rowsMax={4}
            label="Название акции"
          />
        </FieldRow>
        <FieldRow label="Магазин" sudDesc="Выберите магазин.">
          <StoreAutocomplete
            id={STORE_KEY}
            className={classes.wrap}
            name={STORE_KEY}
            onChange={id => this.onChangeStore(id)}
            selectedItem={values[STORE_KEY]}
            InputProps={{
              placeholder: 'Магазин',
              error: errors[STORE_KEY],
            }}
          />
        </FieldRow>
        <FieldRow label="ККМ">
          {store > 0 && (
            <KkmMultiAutocompleteWithStore
              id={CASH_MACHINES_KEY}
              className={classes.wrap}
              name={CASH_MACHINES_KEY}
              onChange={id => this.onChangeKkm(id)}
              selectedItem={values[CASH_MACHINES_KEY]}
              storeId={store}
              InputProps={{
                placeholder: 'ККМ',
                error: errors[CASH_MACHINES_KEY],
              }}
            />
          )}
        </FieldRow>
        <FieldRow label="Даты">
          <div className={classes.datepickers}>
            <Field
              disablePast
              Target={DateTimePicker}
              label="Дата начала акции"
              name={START_DATE_KEY}
              value={startDate}
              onChange={val => this.onChangeStartDate(val)}
              className={classes.datepicker}
            />
            <Field
              clearable
              disablePast
              Target={DateTimePicker}
              onChange={val => this.onChangeEndDate(val)}
              value={endDate}
              label="Дата окончания акции"
              minDate={values[START_DATE_KEY]}
              name={END_DATE_KEY}
              className={classes.datepicker}
            />
          </div>
        </FieldRow>
        <FieldRow label="Процент начисления бонусов за покупку">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name={PERCENT_KEY}
            multiline
            rows={2}
            rowsMax={4}
            label="Процент"
          />
        </FieldRow>
        <FieldRow label="Способ реконсиляции">
          <div className={classes.reconciliationSelect}>
            <Select
              style={{ width: '100%' }}
              value={values[RECONCILIATION_TYPE_KEY]}
              labelId="levelLabel"
              IconComponent={KeyboardArrowDown}
              onChange={event =>
                this.onChangereconciliationType(event.target.value)
              }
              required
              error={!values[RECONCILIATION_TYPE_KEY]}
              disabled={!startDate || !endDate}
            >
              {reconciliationTypes.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            {!values[RECONCILIATION_TYPE_KEY] ? (
              <FormHelperText
                style={{
                  display: 'block',
                  alignSelf: 'flex-start',
                  color: '#f44336',
                }}
              >
                Поле не заполнено
              </FormHelperText>
            ) : null}
          </div>
        </FieldRow>
        {reconciliationType === RECONCILIATION_ONE_TIME_TYPE && (
          <FieldRow label="Выберите дату реконсиляции">
            <div className={classes.datepickers}>
              <Field
                clearable
                disablePast
                Target={DateTimePicker}
                minDate={addDays(values[END_DATE_KEY], 30)}
                label="Дата реконсиляции"
                name={ONE_TIME_DATETIME_KEY}
                className={classes.datepicker}
              />
            </div>
          </FieldRow>
        )}
        {reconciliationType === RECONCILIATION_PERIODIC_TYPE && (
          <FieldRow label="Повторение сообщения">
            <RepeatSchedule {...this.props} />
          </FieldRow>
        )}
        <FieldRow label="Комментарий">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            required
            name={COMMENT_KEY}
            multiline
            rows={2}
            rowsMax={4}
            label="Комментарий"
          />
        </FieldRow>
      </>
    );
  }
}

DestinationForm.propTypes = FormikPropTypes;

export default DestinationForm;
