import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { number, object } from 'yup';

import { REAL_IIN_KEY } from '@/features/ipnReportsPage/consts/keys';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import EditIin from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: () => ({
    [REAL_IIN_KEY]: null,
  }),

  validationSchema: object().shape({
    [REAL_IIN_KEY]: number()
      .nullable()
      .required(),
  }),

  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(EditIin);
