import PropTypes from 'prop-types';
import React from 'react';

const getTitle = (title, onCutText, maxLength) => {
  if (onCutText && maxLength) {
    return onCutText(title, maxLength);
  }

  return title;
};

const UserPhoneLazy = ({ isLoaded, title, onCutText, maxLength }) => (
  <span title={title}>
    {isLoaded ? getTitle(title, onCutText, maxLength) : '...'}
  </span>
);

UserPhoneLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onCutText: PropTypes.func,
  maxLength: PropTypes.number,
};

UserPhoneLazy.defaultProps = {
  title: null,
  onCutText: null,
  maxLength: null,
};

export default UserPhoneLazy;
