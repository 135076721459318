import { setLocale } from 'yup';

setLocale({
  string: {
    email: 'Введите валидный e-mail',
    url: 'Введите валидный URL-адрес',
    max: 'Количество символов превышает допустимое',
    min: 'Количество символов меньше допустимого',
  },
});
