import PropTypes from 'prop-types';
import React from 'react';

import ConversionFactoid from '@/features/campaignsStatisticsDetailsPage/Page/ConversionFactoid';
import Factoid from '@/features/campaignsStatisticsDetailsPage/Page/Factoid';
import { formatNumber } from '@/utils/format';

const PurchaseResultFacts = ({
  classes,
  segmentSize,
  prePushClickCount,
  actionCount,
  pushClickCount,
  conversion,
  prePushClicksToSegmentPercentage,
  activationsToPrePushClicksPercentage,
  pushClicksToActivationsPercentage,
}) => (
  <div className={classes.wrapper}>
    <Factoid
      name="Сегмент"
      value={formatNumber(segmentSize)}
      ratio={prePushClicksToSegmentPercentage}
      description="Общее количество пользователей, для которых предназначается кампания."
    />
    <Factoid
      name="Переходы 1"
      value={formatNumber(prePushClickCount)}
      ratio={activationsToPrePushClicksPercentage}
      description="Количество пользователей, нажавших на предварительное push-уведомление"
    />

    <Factoid
      name="Активация"
      value={formatNumber(actionCount)}
      ratio={pushClicksToActivationsPercentage}
      description="Сколько раз были выполнены условия кампании"
    />
    <Factoid
      name="Переходы 2"
      value={formatNumber(pushClickCount)}
      classes={classes}
      description="Количество пользователей, нажавших на push-уведомление при покупке"
    />

    <ConversionFactoid classes={classes} conversion={conversion} />
  </div>
);

PurchaseResultFacts.propTypes = {
  segmentSize: PropTypes.number.isRequired,
  prePushClickCount: PropTypes.number.isRequired,
  actionCount: PropTypes.number.isRequired,
  pushClickCount: PropTypes.number.isRequired,
  conversion: PropTypes.number.isRequired,
  prePushClicksToSegmentPercentage: PropTypes.number.isRequired,
  activationsToPrePushClicksPercentage: PropTypes.number.isRequired,
  pushClicksToActivationsPercentage: PropTypes.number.isRequired,
};

export default PurchaseResultFacts;
