import { RESOURCE_NAME } from '@esentai/core/features/loyalties/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadPurchases = authorizeIf(canRead(RESOURCE_NAME));
export const canReadPurchase = authorizeIf(canRead(RESOURCE_NAME));
export const canCreatePurchase = authorizeIf(canCreate(RESOURCE_NAME));
export const canUpdatePurchase = authorizeIf(canUpdate(RESOURCE_NAME));

export const canCancelRequest = () => true;
