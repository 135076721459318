import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';
import CategoryModal from '@/features/productsViewPage/Page/CategoryModal/component';

const EditButton = ({
  onClick,
  russianName,
  englishName,
  kazakhName,
  itemId,
  images,
  isVisible,
  ...props
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleModal = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <ActionButton
        text="Редактировать"
        button={AsyncButton}
        icon={EditIcon}
        onClick={handleModal}
        disabled={isVisible}
        {...props}
      />
      {isDialogOpen && (
        <CategoryModal
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSubmit={onClick}
          initialValues={{
            russianName,
            englishName,
            kazakhName,
            images,
            itemId,
          }}
        />
      )}
    </>
  );
};

EditButton.propTypes = {
  permission: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  russianName: PropTypes.string.isRequired,
  englishName: PropTypes.string.isRequired,
  kazakhName: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  itemId: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default EditButton;
