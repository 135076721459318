import StatisticsResource from '@/features/statistics/resources';

import { RESOURCE_NAME } from './consts';
import serializer from './serializer';

export default new StatisticsResource({
  resourceName: RESOURCE_NAME,
  endpoint: '/purchase-aggregation',
  serializer,
});
