import PropTypes from 'prop-types';
import React from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import LazyEditForm from '@/components/LazyEditForm';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import { ROUTE_PATH as LOCATIONS_PATH } from '@/features/locationsPage/consts';

import Form from './Form';

const PageEditLocation = ({
  fetchItem,
  getItemLoadingError,
  isItemLoaded,
  isItemLoading,
  locationId,
  updateLocation,
}) => (
  <Page>
    <PageHeader gutterBottom={false} xl={8}>
      <Breadcrumbs>
        <Breadcrumb to={LOCATIONS_PATH}>Локации</Breadcrumb>
      </Breadcrumbs>
      <BackButton />
      <PageTitle>Редактирование локации</PageTitle>
    </PageHeader>
    <PageContent xl={8}>
      <PaperSheet>
        <LazyEditForm
          fetchItem={fetchItem}
          getItemLoadingError={getItemLoadingError}
          isItemLoaded={isItemLoaded}
          isItemLoading={isItemLoading}
          itemId={locationId}
        >
          <Form locationId={locationId} onSubmit={updateLocation} />
        </LazyEditForm>
      </PaperSheet>
    </PageContent>
  </Page>
);

PageEditLocation.propTypes = {
  fetchItem: PropTypes.func.isRequired,
  getItemLoadingError: PropTypes.func.isRequired,
  isItemLoaded: PropTypes.func.isRequired,
  isItemLoading: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
  locationId: PropTypes.any.isRequired,
};

export default PageEditLocation;
