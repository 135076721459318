import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddLink from '@/components/AddLink';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';

import CategoryModal from './CategoryModal/component';
import Table from './Table';

class ProductCategories extends Component {
  state = {
    isOpen: false,
  };

  static propTypes = {
    error: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    itemsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    totalCount: PropTypes.number.isRequired,
    createCategory: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isOpen: true,
    });
  };

  render() {
    const {
      error,
      isLoading,
      itemsIds,
      totalCount,
      classes,
      createCategory,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <Grid container alignItems="baseline" justify="space-between">
            <PageTitle style={{ maxWidth: '90%' }}>
              Категоризация товаров
            </PageTitle>
            <AddLink onClick={this.handleOpenModal} to={null} />
          </Grid>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Table
              error={error}
              isLoading={isLoading}
              items={itemsIds}
              totalCount={totalCount}
            />
          </div>
          <CategoryModal
            isOpen={isOpen}
            onClose={this.handleCloseModal}
            onSubmit={createCategory}
            initialValues={null}
          />
        </PageContent>
      </Page>
    );
  }
}

export default ProductCategories;
