import {
  TITLE_ENGLISH_KEY,
  TITLE_KAZAKH_KEY,
  TITLE_RUSSIAN_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import PropTypes from 'prop-types';
import React from 'react';

import Quadrolingual from '@/components/Quadrolingual';
import Label from '@/features/campaignPage/Page/Label';

const Title = ({ payload }) => (
  <Label title="Заголовок статьи">
    <Quadrolingual
      russianContent={payload[TITLE_RUSSIAN_KEY]}
      englishContent={payload[TITLE_ENGLISH_KEY]}
      kazakhContent={payload[TITLE_KAZAKH_KEY]}
    />
  </Label>
);

Title.propTypes = {
  payload: PropTypes.object.isRequired,
};

export default Title;
