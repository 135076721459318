import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { cancelDeactivate } from '../actions';
import { isWaiting } from '../selectors';
import CancelSnackbar from './component';
import styles from './styles';

const mapStateToProps = state => ({
  open: isWaiting(state),
});

const mapDispatchToProps = { cancel: cancelDeactivate };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CancelSnackbar);
