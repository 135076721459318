import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import KkmNameLazy from '@/containers/KkmNameLazy';

const KkmChip = ({ value, ...props }) => (
  <Chip {...props} id={value} label={<KkmNameLazy kkmId={value} />} />
);

KkmChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default KkmChip;
