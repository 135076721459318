import { GetApp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';

const VisibilityButton = ({ ...rest }) => (
  <ActionButton text={'Скачать'} button={AsyncButton} icon={GetApp} {...rest} />
);

VisibilityButton.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default VisibilityButton;
