export default {
  name: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    color: 'inherit',
    marginLeft: 16,
  },

  beaconIds: {
    fontSize: 20,
    display: 'flex',
  },

  id: {
    color: '#8FBEE7',
    '&:not(:last-child)': {
      margin: [0, 17, 0, 41],
    },
  },
};
