import {
  getEnglishDescription,
  getEnglishTitle,
  getKazakhDescription,
  getKazakhTitle,
  getPlacement,
  getPriority,
  getProductIds,
  getPushEnabled,
  getPushEnglishMessage,
  getPushEnglishTitle,
  getPushKazakhMessage,
  getPushKazakhTitle,
  getPushRussianMessage,
  getPushRussianTitle,
  getRussianDescription,
  getRussianTitle,
} from '@esentai/core/features/campaigns/show/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepThree } from '@/features/campaignCreatePage/actions';
import { getCampaignId } from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import FirstStep from './component';
import form from './form';

const mapStateToProps = state => {
  const id = getCampaignId(state);

  return {
    headerRu: getRussianTitle(state, id),
    headerEn: getEnglishTitle(state, id),
    headerKz: getKazakhTitle(state, id),

    russianDescription: getRussianDescription(state, id),
    englishDescription: getEnglishDescription(state, id),
    kazakhDescription: getKazakhDescription(state, id),

    pushEnabled: getPushEnabled(state, id),
    pushTitleRu: getPushRussianTitle(state, id),
    pushTitleEn: getPushEnglishTitle(state, id),
    pushTitleKz: getPushKazakhTitle(state, id),

    pushMessageRu: getPushRussianMessage(state, id),
    pushMessageEn: getPushEnglishMessage(state, id),
    pushMessageKz: getPushKazakhMessage(state, id),

    placement: getPlacement(state, id),
    priority: getPriority(state, id),
    products: getProductIds(state, id),
  };
};

const mapDispatchToProps = { onSubmit: goToStepThree };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
)(FirstStep);
