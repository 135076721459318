import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import { formatDateTime, formatMoney, formatNumber } from '@/utils/format';

const Row = ({ cashMachineName, issueDate, purchasesCount, totalCost }) => (
  <TableRow>
    <CondenseTableCell>{formatDateTime(issueDate)}</CondenseTableCell>
    <CondenseTableCell align="right">
      {formatMoney(totalCost)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatNumber(purchasesCount)}
    </CondenseTableCell>
    <CondenseTableCell>{cashMachineName}</CondenseTableCell>
  </TableRow>
);

Row.defaultProps = {
  purchasesCount: null,
};

Row.propTypes = {
  cashMachineName: PropTypes.string.isRequired,
  issueDate: PropTypes.instanceOf(Date).isRequired,
  purchasesCount: PropTypes.number,
  totalCost: PropTypes.number.isRequired,
};

export default Row;
