import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import { LocationMultiAutocomplete } from '@/containers/LocationAutocomplete/';
import { typeLabel } from '@/features/campaignsPage/labels';
import { formatHumanDate } from '@/utils/format';
import { days } from '@/utils/labels';

import Label from '../Label';

const Primary = ({
  classes,
  dateStart,
  dateEnd,
  duration,
  title,
  type,
  locations,
}) => (
  <FieldRow name="Описание">
    <Typography variant="h4" className={classes.typeLabel}>
      Кампания «{typeLabel(type)}»
    </Typography>

    <Label title="Название кампании">{title}</Label>

    <div className={classes.dates}>
      {!isNil(dateStart) && (
        <Label title="Дата начала кампании">{formatHumanDate(dateStart)}</Label>
      )}
      {!isNil(dateEnd) && (
        <Label title="Дата завершения кампании">
          {formatHumanDate(dateEnd)}
        </Label>
      )}
      {!isNil(duration) && (
        <Label title="Продолжительность">{days(duration)}</Label>
      )}
    </div>
    {locations.length && (
      <Label title="Компания привязана к локации">
        <LocationMultiAutocomplete
          multiple
          fullWidth
          disabled
          selectedItem={locations}
        />
      </Label>
    )}
  </FieldRow>
);

Primary.propTypes = {
  dateStart: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  duration: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  locations: PropTypes.string.isRequired,
};

Primary.defaultProps = {
  dateStart: null,
  dateEnd: null,
  duration: null,
  locations: [],
};

export default Primary;
