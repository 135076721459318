import { createAutocompleteFilterTemplate } from '@/filters/Autocomplete';

import FilterComponent from './component';
import FormatComponent from './FormatComponent';

export const createLocationFilter = createAutocompleteFilterTemplate({
  FilterComponent,
  FormatComponent,
});

export default FilterComponent;
