import { isUserActive } from '@esentai/core/features/users/selectors';
import { compose, prop } from 'ramda';
import { connect } from 'react-redux';
import { branch } from 'recompose';

import whenActive from './connects/whenActive';
import whenInactive from './connects/whenInactive';

const mapStateToProps = (state, { userId }) => ({
  active: isUserActive(state, userId),
});

export default compose(
  connect(mapStateToProps),
  branch(prop('active'), whenActive, whenInactive),
);
