import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [
  {
    key: 'male',
    label: 'Мужской',
  },
  {
    key: 'female',
    label: 'Женский',
  },
  {
    key: 'null',
    label: 'Не указан',
  },
];

export const createGenderFilter = createRadioFilterTemplate({
  initialValue: options[0].key,
  options,
});

export default RadioGroupFilter;
