import { connect } from 'react-redux';

import Legend from '@/components/HeatMap/Legend';
import {
  getMaximumAmount,
  getMinimumAmount,
  getStatDescriptor,
} from '@/features/locationsStatisticsPage/selectors';

const mapStateToProps = state => ({
  descriptor: getStatDescriptor(state),
  maximumAmount: getMaximumAmount(state),
  minimumAmount: getMinimumAmount(state),
});

export default connect(mapStateToProps)(Legend);
