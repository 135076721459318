import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TransferBonusesForm from './Form';

const ERROR_MESSAGE =
  'Ошибка: указаны идентичные пользователи, пожалуйста, выберите другого пользолвателя в одной из колонок.';

const TransferBonusesModal = ({ isOpen, onClose, onSubmit }) => {
  const [formCustomerId, setFromCustomerId] = useState(null);
  const [toCustomerId, setToCustomerId] = useState(null);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (formCustomerId && toCustomerId && formCustomerId === toCustomerId) {
      setSubmitDisabled(true);
      setErrorMessage(ERROR_MESSAGE);
    } else if (
      formCustomerId &&
      toCustomerId &&
      formCustomerId !== toCustomerId
    ) {
      setSubmitDisabled(false);
      setErrorMessage(null);
    }
  }, [formCustomerId, toCustomerId]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      maxWidth="lg"
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">
        Перенести бонусы и покупки пользователя
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={16} justify="space-between">
          <Grid item xs={5}>
            <Typography variant="subtitle2">
              Пользователь, с которого вы хотите перенести бонусы и покупки
            </Typography>
            <TransferBonusesForm setCustomerId={id => setFromCustomerId(id)} />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowForward fontSize="large" />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle2">
              Пользователь, на которого вы хотите перенести бонусы и покупки
            </Typography>
            <TransferBonusesForm setCustomerId={id => setToCustomerId(id)} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={16}
          justify="flex-end"
          style={{ marginTop: '24px' }}
        >
          <Grid item>
            <Button onClick={onClose} size="large">
              Отмена
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() =>
                onSubmit({
                  from_customer_id: formCustomerId,
                  to_customer_id: toCustomerId,
                })
              }
              disabled={!formCustomerId || !toCustomerId || isSubmitDisabled}
              variant="contained"
              color="primary"
              size="large"
            >
              Перенести
            </Button>
          </Grid>
        </Grid>
        {errorMessage !== null && (
          <Typography variant="caption">{errorMessage}</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

TransferBonusesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TransferBonusesModal;
