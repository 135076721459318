import { connect } from 'react-redux';

import { getSelectedFloor } from '@/features/mapPage/selectors';

import Map from './component';
import RentPlace from './RentPlace';
import TooltipContent from './TooltipContent';
import Viewport from './Viewport';

const mapStateToProps = state => ({
  floor: getSelectedFloor(state),
  RentPlace,
  TooltipContent,
  Viewport,
});

export default connect(mapStateToProps)(Map);
