import { handleActions } from 'redux-actions';

import {
  AUTHENTICATE,
  AUTHENTICATE_FAILURE,
  AUTHENTICATE_SUCCESS,
  AUTHORIZE,
  AUTHORIZE_FAILURE,
  AUTHORIZE_SUCCESS,
  CHANGE_CURRENT_USER,
  DEAUTHORIZE,
  EXPAND,
} from './types';

const initialState = {
  authenticating: false,
  authenticateError: null,
  authorized: false,
  authorizeError: null,
  authorizing: false,
  currentUserId: null,
  menuExpanded: localStorage.getItem('esentai-expanded') === '1' || false,
};

const reducerMap = {
  [AUTHENTICATE]: state => ({
    ...state,
    authenticating: true,
  }),
  [AUTHENTICATE_FAILURE]: (state, { payload: error }) => ({
    ...state,
    authenticating: false,
    authenticateError: error,
  }),
  [AUTHENTICATE_SUCCESS]: state => ({
    ...state,
    authenticating: false,
    authenticateError: null,
  }),
  [AUTHORIZE]: state => ({
    ...state,
    authorizing: true,
  }),
  [AUTHORIZE_SUCCESS]: state => ({
    ...state,
    authorized: true,
    authorizeError: null,
    authorizing: false,
  }),
  [AUTHORIZE_FAILURE]: (state, { payload: error }) => ({
    ...state,
    authorizing: false,
    authorizeError: error,
  }),
  [CHANGE_CURRENT_USER]: (state, { payload: currentUserId }) => ({
    ...state,
    currentUserId,
  }),
  [DEAUTHORIZE]: state => ({
    ...state,
    authorized: false,
    currentUserId: null,
  }),
  [EXPAND]: state => {
    const menuExpanded = !state.menuExpanded;

    localStorage.setItem('esentai-expanded', menuExpanded ? '1' : '0');

    return {
      ...state,
      menuExpanded,
    };
  },
};

export default handleActions(reducerMap, initialState);
