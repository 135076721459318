import { connect } from 'react-redux';

import StatusDistributionChart from '@/components/StatusDistributionChart';
import {
  getTotalPercentageReceiptsByBlackUserStatusById,
  getTotalPercentageReceiptsByJetBlackUserStatusById,
  getTotalPercentageReceiptsByOrangeUserStatusById,
} from '@/features/purchasesStatisticsPage/selectors';

const mapStateToProps = (state, { itemId }) => ({
  orangeUsersPercentage: getTotalPercentageReceiptsByOrangeUserStatusById(
    state,
    itemId,
  ),
  blackUsersPercentage: getTotalPercentageReceiptsByBlackUserStatusById(
    state,
    itemId,
  ),
  jetBlackUsersPercentage: getTotalPercentageReceiptsByJetBlackUserStatusById(
    state,
    itemId,
  ),
});

export default connect(mapStateToProps)(StatusDistributionChart);
