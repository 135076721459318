import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import UserActivityToggler from '@/containers/UserActivityToggler';
import { activityStatus } from '@/utils/labels';

const UserActivity = ({ classes, active, userId }) => (
  <div className={classes.root}>
    <Typography variant="body2">{activityStatus(active)}</Typography>
    <UserActivityToggler userId={userId} />
  </div>
);

UserActivity.defaultProps = {
  error: null,
};

UserActivity.propTypes = {
  active: PropTypes.bool.isRequired,
  userId: PropTypes.any.isRequired,
};

export default UserActivity;
