import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  SHOW_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/notification/consts/destinations';
import {
  DESTINATION_KEY,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_KAZAKH_MESSAGE_KEY,
  PUSH_KAZAKH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_SHOW_ID_KEY,
  REFERRED_STORE_ID_KEY,
  STATUS,
} from '@esentai/core/features/campaigns/notification/consts/keys';
import { addMinutes } from 'date-fns';
import { compose, head, tail } from 'ramda';
import { date, number, object, string } from 'yup';

import {
  INITIAL_SEND_DATETIME,
  REPEAT_SEND_DATETIMES,
  SHOULD_REPEAT_SEND,
} from './consts/keys';
import { cleanUpValues, computeStartAndEndDates, mergeSchedule } from './utils';

const asyncOptions = { runnerMethod: 'onSubmit' };

const formikOptions = {
  mapPropsToValues: ({
    status,
    destination,
    pushEnglishTitle,
    pushRussianTitle,
    pushEnglishMessage,
    pushRussianMessage,
    pushKazakhTitle,
    pushKazakhMessage,
    referredArticle,
    referredBenefit,
    referredCalendarEvent,
    referredStore,
    referredShow,
    sendSchedule,
  }) => ({
    [STATUS]: status,
    [DESTINATION_KEY]: destination,

    [PUSH_RUSSIAN_TITLE_KEY]: pushRussianTitle,
    [PUSH_RUSSIAN_MESSAGE_KEY]: pushRussianMessage,

    [PUSH_ENGLISH_TITLE_KEY]: pushEnglishTitle,
    [PUSH_ENGLISH_MESSAGE_KEY]: pushEnglishMessage,

    [PUSH_KAZAKH_TITLE_KEY]: pushKazakhTitle,
    [PUSH_KAZAKH_MESSAGE_KEY]: pushKazakhMessage,

    [REFERRED_ARTICLE_ID_KEY]: referredArticle,
    [REFERRED_BENEFIT_ID_KEY]: referredBenefit,
    [REFERRED_CALENDAR_EVENT_ID_KEY]: referredCalendarEvent,
    [REFERRED_STORE_ID_KEY]: referredStore,
    [REFERRED_SHOW_ID_KEY]: referredShow,
    [INITIAL_SEND_DATETIME]: head(sendSchedule || []) || null,
    [REPEAT_SEND_DATETIMES]: tail(sendSchedule || []),
    [SHOULD_REPEAT_SEND]: (sendSchedule || []).length > 1,
  }),

  validationSchema: object().shape({
    [DESTINATION_KEY]: string().nullable(),
    [INITIAL_SEND_DATETIME]: date().when(STATUS, {
      is: value => value !== 'paused',
      then: date()
        .min(
          addMinutes(new Date(), 5),
          'Время старта +5 мин от текущего времени',
        )
        .required(),
      otherwise: date().nullable(),
    }),
    [PUSH_RUSSIAN_TITLE_KEY]: string()
      .max(60)
      .required(),
    [PUSH_RUSSIAN_MESSAGE_KEY]: string()
      .max(150)
      .required(),

    [PUSH_ENGLISH_TITLE_KEY]: string()
      .max(60)
      .required(),
    [PUSH_ENGLISH_MESSAGE_KEY]: string()
      .max(150)
      .required(),

    [PUSH_KAZAKH_TITLE_KEY]: string()
      .max(60)
      .required(),
    [PUSH_KAZAKH_MESSAGE_KEY]: string()
      .max(150)
      .required(),

    [REFERRED_ARTICLE_ID_KEY]: number().when(DESTINATION_KEY, {
      is: ARTICLE_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_BENEFIT_ID_KEY]: number().when(DESTINATION_KEY, {
      is: BENEFIT_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_CALENDAR_EVENT_ID_KEY]: number().when(DESTINATION_KEY, {
      is: CALENDAR_EVENT_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_STORE_ID_KEY]: number().when(DESTINATION_KEY, {
      is: STORE_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
    [REFERRED_SHOW_ID_KEY]: number().when(DESTINATION_KEY, {
      is: SHOW_DESTINATION,
      then: number().required(),
      otherwise: number().nullable(),
    }),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    const processValues = compose(
      computeStartAndEndDates,
      mergeSchedule,
      cleanUpValues,
    );
    const processedValues = processValues(values);

    props.onSubmit(processedValues);
    setSubmitting(false);
  },
};

const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
