import {
  getActiveBonusesForUser,
  getGiftBonusesForUser,
  getPenaltyPointsForUser,
  getPendingBonusesForUser,
  getRewardPointsForUser,
  getStatusForUser,
} from '@esentai/core/features/users/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { canUpdateUser } from '@/features/usersPage/permissions';

import PrimatyFacts from './component';
import styles from './styles';

const mapStateToProps = (state, { userId }) => ({
  activeBonuses: getActiveBonusesForUser(state, userId),
  giftBonuses: getGiftBonusesForUser(state, userId),
  pendingBonuses: getPendingBonusesForUser(state, userId),
  penaltyPoints: getPenaltyPointsForUser(state, userId),
  rewardPoints: getRewardPointsForUser(state, userId),
  status: getStatusForUser(state, userId),
  showIncrementButton: canUpdateUser(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(PrimatyFacts);
