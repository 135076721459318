import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import { any } from 'ramda';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import SortableItem from '@/components/SortableItem';

const SortableGallery = ({ classes, items, setItems, setCheckedItem }) => {
  const SortPhoto = SortableElement(item => (
    <SortableItem setCheckedItem={setCheckedItem} {...item} />
  ));
  // eslint-disable-next-line
  const SortGallery = SortableContainer(({ items }) => (
    <div className={classes.container}>
      {items.map((val, idx) => (
        <SortPhoto margin={0} key={`${val.id}`} index={idx} {...val} />
      ))}
    </div>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex - 1, newIndex - 1));
  };

  return (
    <div className={classes.gallery}>
      <SortGallery
        items={items}
        onSortEnd={onSortEnd}
        axis={'xy'}
        helperClass={classes.sortableHelper}
        useWindowAsScrollContainer
        lockToContainerEdges
      />
    </div>
  );
};

SortableGallery.propTypes = {
  items: PropTypes.arrayOf(any).isRequired,
  setItems: PropTypes.func.isRequired,
  setCheckedItem: PropTypes.func.isRequired,
};

export default SortableGallery;
