import { REPAY } from '@esentai/core/features/reconciliation/consts';
import { RESOURCE_NAME } from '@esentai/core/features/reconciliationStoreBonusDetails/consts';
import {
  can,
  canCreate,
  canRead,
  canUpdate,
  permission,
} from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadReconciliationStoreBonusDetails = authorizeIf(
  canRead(RESOURCE_NAME),
);
export const canCreateReconciliationStoreBonusDetails = authorizeIf(
  canCreate(RESOURCE_NAME),
);
export const canUpdateReconciliationStoreBonusDetails = authorizeIf(
  canUpdate(RESOURCE_NAME),
);
export const canRepayReconciliation = authorizeIf(
  can(permission(RESOURCE_NAME, REPAY)),
);
