import {
  FIRST_NAME_KEY,
  GENDER_KEY,
  GROUP_ID_KEY,
  ID_KEY,
  LAST_NAME_KEY,
  LEVEL_KEY,
  PHONE_KEY,
  RECORD_CREATED_KEY,
} from '@esentai/core/features/parkingWhitelist/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';
import { canUpdateWhitelist } from '@/features/parkingWhitelistPage/permissions';

const HeadRow = ({ GlobalSelectionToggle, SortControl }) => (
  <TableRow>
    <TableCell padding="checkbox">
      <GlobalSelectionToggle />
    </TableCell>
    <CondenseTableCell>
      <SortControl field={ID_KEY}>ID</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={LAST_NAME_KEY}>Фамилия</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={FIRST_NAME_KEY}>Имя</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={LEVEL_KEY}>Уровень лояльности</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={PHONE_KEY}>Номер телефона</SortControl>
    </CondenseTableCell>
    {/* <CondenseTableCell>*/}
    {/*  <SortControl field={EMAIL_KEY}>Почта</SortControl>*/}
    {/* </CondenseTableCell>*/}
    {/* <CondenseTableCell>*/}
    {/*  <SortControl field={AGE_KEY}>Возраст</SortControl>*/}
    {/* </CondenseTableCell>*/}
    <CondenseTableCell>
      <SortControl field={GENDER_KEY}>Пол</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={GROUP_ID_KEY}>Группа</SortControl>
    </CondenseTableCell>
    {/* <CondenseTableCell>*/}
    {/*  <SortControl field={IS_PHONE_VERIFIED_KEY}>*/}
    {/*    Телефон подтвержден*/}
    {/*  </SortControl>*/}
    {/* </CondenseTableCell>*/}
    {/* <CondenseTableCell>*/}
    {/*  <SortControl field={STATE_KEY}>Статус</SortControl>*/}
    {/* </CondenseTableCell>*/}
    <CondenseTableCell>
      <SortControl field={RECORD_CREATED_KEY}>Дата добавления</SortControl>
    </CondenseTableCell>
    <CanView permission={canUpdateWhitelist}>
      <CondenseTableCell style={{ textAlign: 'left' }}>
        Действия
      </CondenseTableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  GlobalSelectionToggle: PropTypes.func.isRequired,
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
