import { Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import React from 'react';
import { Redirect } from 'react-router';

import Mall from '@/components/Icon/Mall';

import Form from './Form';
import styles from './styles';

const Login = props => {
  const { authorized, authenticate, classes } = props;

  if (authorized) {
    const from = path(['state', 'from'], props.location);

    return <Redirect to={from || { pathname: '/' }} />;
  }

  return (
    <div className={classes.paperWrap}>
      <Mall className={classes.logo} width="266" color="black" />
      <Paper className={classes.paper}>
        <Form onSubmit={authenticate} />
      </Paper>
    </div>
  );
};

Login.propTypes = {
  authorized: PropTypes.bool.isRequired,
  authenticate: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

export default withStyles(styles)(Login);
