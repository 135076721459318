export default {
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
  },
  centered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
    overflowY: 'auto',
  },
};
