import { connect } from 'react-redux';

import { activateStores, disableStores } from '@/features/storesPage/actions';
import {
  isStoresActive,
  isStoresInactive,
} from '@/features/storesPage/selectors';

import BulkActionsRow from './component';

const mapStateToProps = (state, { selectedItems }) => ({
  isStoresActive: isStoresActive(state, selectedItems),
  isStoresInactive: isStoresInactive(state, selectedItems),
});

const mapDispatchToProps = (dispatch, { selectedItems }) => ({
  activateStores: () => dispatch(activateStores(selectedItems)),
  disableStores: () => dispatch(disableStores(selectedItems)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionsRow);
