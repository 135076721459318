import {
  COMMENT_KEY,
  STATUS_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import {
  APPROVED_STATUS,
  DECLINED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import { IMG_RLS_KEY } from '@esentai/core/features/campaigns/show/consts/keys';
import { object, string } from 'yup';

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({
    // products,
    images,
  }) => ({
    [COMMENT_KEY]: '',
    [STATUS_KEY]: APPROVED_STATUS,
    // [PRODUCTS_IDS_KEY]: products || [],
    [IMG_RLS_KEY]: images || [],
  }),

  validationSchema: object({
    [COMMENT_KEY]: string().when(STATUS_KEY, {
      is: DECLINED_STATUS,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    [STATUS_KEY]: string(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
