import api from '@/api';
import { saveAs } from '@/utils/file-saver';

export const downloadXls = ({ sort, filter, limit }) => {
  // page, limit - Pagination params
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.set('size', limit.toString());

  api
    .doBlobGetParkingRequest(`/parking-session/all-sessions-xlsx-export`, {
      size: limit,
      sort,
      filter,
    })
    .then(response => {
      saveAs(response.data, `Паркинг(SPT).xlsx`);
    });
};

export const downloadReport = params => {
  api.doBlobPostParkingRequest(`/report/mall`, { ...params }).then(response => {
    saveAs(response.data, `Отчет по паркингу.xlsx`);
  });
};
