import PropTypes from 'prop-types';

import { MALL_ADMIN_STRATEGY, STORE_ADMIN_STRATEGY } from './consts';
import MallAdminForm from './MallAdminForm';
import StoreAdminForm from './StoreAdminForm';

const strategies = [
  {
    name: MALL_ADMIN_STRATEGY,
    Form: MallAdminForm,
  },
  {
    name: STORE_ADMIN_STRATEGY,
    Form: StoreAdminForm,
  },
];

export const propTypes = PropTypes.oneOf(strategies);

export default strategies;
