import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import {
  getGiftCertificatesError,
  getGiftCertificatesIds,
  getGiftCertificatesTotalCount,
  isLoadingGiftCertificates,
} from '../selectors';
import GiftCertificates from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getGiftCertificatesError(state),
  giftCertificatesIds: getGiftCertificatesIds(state),
  isLoading: isLoadingGiftCertificates(state),
  totalCount: getGiftCertificatesTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(GiftCertificates);
