export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  excelButton: {
    marginTop: 15,
  },
  root: {
    width: '420px',
  },
  fact: {
    marginRight: 12,
    display: 'inline-block',
    marginBottom: 10,
  },
};
