import { path } from 'ramda';

import api from '@/api';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

import { FEATURE_NAME } from './consts';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const downloadXls = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const limit = Number(urlParams.get('limit')) || 10;
  const page = Number(urlParams.get('page'));
  const offset = page * limit;

  urlParams.set('limit', limit.toString());
  urlParams.set('offset', offset.toString());
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'total_to_pay');
  }

  api
    .doBlobGet(
      `/merchant-reconciliation-archive-xlsx-export?${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(response.data, `Архив по арендаторам.xlsx`);
    });
};
