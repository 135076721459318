import { connect } from 'react-redux';

import TimeDistributionChart from '@/components/TimeDistributionChart';
import {
  getPercentageReceiptsByDayById,
  getPercentageReceiptsByEveningById,
  getPercentageReceiptsByMorningById,
} from '@/features/purchasesStatistics/selectors';

const mapStateToProps = (state, { itemId }) => ({
  percentageByMorning: getPercentageReceiptsByMorningById(state, itemId),
  percentageByDay: getPercentageReceiptsByDayById(state, itemId),
  percentageByEvening: getPercentageReceiptsByEveningById(state, itemId),
});

export default connect(mapStateToProps)(TimeDistributionChart);
