import {
  TITLE_ENGLISH_KEY,
  TITLE_KAZAKH_KEY,
  TITLE_RUSSIAN_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import PropTypes from 'prop-types';
import React from 'react';

import {
  LOCALE_EN,
  LOCALE_KZ,
  LOCALE_RU,
} from '@/hocs/withDevicePreview/consts';

const TitleBlock = ({ classes, payload, selectByLocale }) => {
  const title = selectByLocale({
    [LOCALE_RU]: payload[TITLE_RUSSIAN_KEY],
    [LOCALE_EN]: payload[TITLE_ENGLISH_KEY],
    [LOCALE_KZ]: payload[TITLE_KAZAKH_KEY],
  });

  return <div className={classes.text}>{title}</div>;
};

TitleBlock.propTypes = {
  payload: PropTypes.object.isRequired,
  selectByLocale: PropTypes.func.isRequired,
};

export default TitleBlock;
