import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import EmptyComponent from '@/components/EmptyComponent';

class Dropdown extends Component {
  static propTypes = {
    label: PropTypes.string,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    renderTrigger: PropTypes.func,
    renderPopup: PropTypes.func,
  };

  static defaultProps = {
    isOpen: null,
    label: '',
    onOpen: () => {},
    onClose: () => {},
    renderTrigger: ({ label, ref, ...rest }) => (
      <Button buttonRef={ref} {...rest}>
        {label}
      </Button>
    ),
    renderPopup: EmptyComponent,
  };

  state = {
    anchorEl: null,
  };

  componentDidMount() {
    const { isOpen } = this.props;

    if (isOpen) {
      this.open();
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen !== prevProps.isOpen) {
      const { anchorEl } = this.state;

      if (isOpen && !anchorEl) {
        // should be opened, but it's close
        this.open();
      } else if (!isOpen && anchorEl) {
        // should be closed, but it's opened
        this.close();
      }
    }
  }

  render() {
    const { classes, label, renderPopup, renderTrigger } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.root}>
        {renderTrigger({
          ref: this.setTrigger,
          className: classes.trigger,
          label,
          onClick: this.open,
        })}
        {renderPopup({
          anchorEl,
          className: classes.popup,
          isOpen: Boolean(anchorEl),
          onClose: this.close,
        })}
      </div>
    );
  }

  open = () => {
    const { onOpen } = this.props;
    const { triggerEl } = this;

    this.setState({ anchorEl: triggerEl });
    onOpen();
  };

  close = () => {
    const { onClose } = this.props;

    this.setState({ anchorEl: null });
    onClose();
  };

  setTrigger = ref => {
    this.triggerEl = ref;
  };
}

export default Dropdown;
