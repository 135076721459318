import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import FiltersRow from '@/components/FiltersRow';
import {
  removeFilters,
  updateFilters,
} from '@/features/purchasesStatisticsPage/actions';
import { filterTypes } from '@/features/purchasesStatisticsPage/filters';
import { getFilters } from '@/features/purchasesStatisticsPage/selectors';

const mapStateToProps = state => ({
  filters: getFilters(state),
});

const mapDispatchToProps = { removeFilters, updateFilters };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({ filterTypes }),
)(FiltersRow);
