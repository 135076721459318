import React from 'react';

import Mall from '@/components/Icon/Mall';

import Description from './Description';
import UserMenu from './UserMenu';

const Bitrix = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <div className={classes.logoWrapper}>
        <Mall width={213} color="black" />
      </div>
      <Description />

      <UserMenu />
    </div>
  </div>
);

export default Bitrix;
