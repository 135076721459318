import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getEfsVisitRecordError,
  getItemsIds: getEfsVisitRecordIds,
  getItemsMeta: getEfsVisitRecordsMeta,
  getItemsTotalCount: getEfsVisitRecordsTotalCount,
  isLoadingItems: isLoadingEfsVisitRecords,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
