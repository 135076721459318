import { Money } from '@material-ui/icons';

import { ROUTE_PATH as PURCHASE_HISTORY_PATH } from '@/features/purchaseHistoryPage/consts';

export const storeSellerMenu = [
  {
    label: 'История покупок',
    path: PURCHASE_HISTORY_PATH,
    Icon: Money,
  },
];
