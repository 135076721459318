import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import SettingsCommonTable from '@/components/SettingsCommonTable';
import {
  updateLimit,
  updatePage,
  updateSort,
} from '@/features/beaconsPage/actions';
import FiltersRow from '@/features/beaconsPage/Page/FiltersRow';
import { getLimit, getPage, getSort } from '@/features/beaconsPage/selectors';

import ColGroup from '../ColGroup';
import ErrorMessage from '../ErrorMessage';
import HeadRow from '../HeadRow';
import Row from '../Row';

const mapStateToProps = state => ({
  limit: getLimit(state),
  page: getPage(state),
  sort: getSort(state),
});

const mapDispatchToProps = { updateLimit, updatePage, updateSort };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({ ErrorMessage, HeadRow, Row, ColGroup, TopRow: FiltersRow }),
)(SettingsCommonTable);
