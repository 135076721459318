import { RECEIPTS_COUNT_KEY } from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { Input, InputAdornment, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldDescription from '@/components/FieldDescription';
import pluralize from '@/utils/pluralize';

const purchasesLabel = purchases =>
  pluralize(purchases, {
    one: 'покупки',
    few: 'покупок',
    many: 'покупок',
  });

const ReceiptsCountForm = ({ classes, receiptsCount, Field, disabled }) => (
  <div>
    <FieldDescription
      title="Количество покупок"
      desc="Пользователь активирует триггер, если за период действия кампании совершит указанное количество покупок."
    />
    <Typography className={classes.fromLabel}>от</Typography>{' '}
    <Field
      disabled={disabled}
      type="number"
      Target={Input}
      name={RECEIPTS_COUNT_KEY}
      endAdornment={
        <InputAdornment position="end">
          {purchasesLabel(receiptsCount)}
        </InputAdornment>
      }
    />
  </div>
);

ReceiptsCountForm.propTypes = {
  receiptsCount: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
  Field: PropTypes.func.isRequired,
};

export default ReceiptsCountForm;
