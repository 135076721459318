import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import api from '@/api';

export interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  fetchItems: () => void;
  url: string;
  storeId: number;
  payload: Record<string, any>;
  title: string;
  confirmationMessage: string;
}

class ConfirmationDialog extends React.Component<ConfirmationDialogProps> {
  onSubmit() {
    api.doPost(this.props.url, this.props.payload).then(() => {
      this.props.onClose();
      this.props.fetchItems();
    });
  }

  render() {
    const { isOpen, onClose, title, confirmationMessage } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">
          {title || 'Подтверждение'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmationMessage || 'Вы уверены?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size={'large'}>
            Отмена
          </Button>
          <Button onClick={this.onSubmit.bind(this)} autoFocus>
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationDialog;
