import {
  BONUS_NAME_KEY,
  COMMENT_KEY,
  END_DATE_KEY,
  PERCENT_KEY,
  START_DATE_KEY,
  STORES_KEY,
} from '@esentai/core/features/mallBonuses/consts/keys';
import React from 'react';

import DateTimePicker from '@/components/DateTimePicker';
import FieldRow from '@/components/FieldRow';
import TextField from '@/components/TextField';
import { StoreMultiAutocomplete } from '@/containers/StoreAutocomplete';
import FormikPropTypes from '@/prop-types/formik';

const DestinationForm = props => {
  const { classes, Field, values, errors } = props;
  const onChangeStore = merchantId =>
    props.setFieldValue(STORES_KEY, merchantId);

  return (
    <>
      <FieldRow label="Название акции">
        <Field
          margin="normal"
          Target={TextField}
          className={classes.wrap}
          required
          name={BONUS_NAME_KEY}
          multiline
          rows={2}
          rowsMax={4}
          label="Название акции"
        />
      </FieldRow>
      <FieldRow label="Магазин" sudDesc="Выберите магазин.">
        <StoreMultiAutocomplete
          id={STORES_KEY}
          className={classes.wrap}
          name={STORES_KEY}
          onChange={onChangeStore}
          selectedItem={values[STORES_KEY]}
          InputProps={{
            placeholder: 'Магазин',
            error: errors[STORES_KEY],
          }}
        />
      </FieldRow>
      <FieldRow label="Даты">
        <div className={classes.datepickers}>
          <Field
            disablePast
            Target={DateTimePicker}
            label="Дата начала действия бонусов"
            name={START_DATE_KEY}
            className={classes.datepicker}
          />
          <Field
            clearable
            disablePast
            Target={DateTimePicker}
            label="Дата завершения действия бонусов"
            minDate={values[START_DATE_KEY]}
            name={END_DATE_KEY}
            className={classes.datepicker}
          />
        </div>
      </FieldRow>
      <FieldRow label="Процент начисления бонусов за покупку">
        <Field
          margin="normal"
          Target={TextField}
          className={classes.wrap}
          required
          name={PERCENT_KEY}
          multiline
          rows={2}
          rowsMax={4}
          label="Процент"
        />
      </FieldRow>
      <FieldRow label="Комментарий">
        <Field
          margin="normal"
          Target={TextField}
          className={classes.wrap}
          required
          name={COMMENT_KEY}
          multiline
          rows={2}
          rowsMax={4}
          label="Комментарий"
        />
      </FieldRow>
    </>
  );
};

DestinationForm.propTypes = FormikPropTypes;

export default DestinationForm;
