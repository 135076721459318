import { map, path, pipe, prop, sortBy } from 'ramda';

import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';
import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH, ROUTE_STAT_PATH } from './consts';
import { getStatsInnerJoin, parseStats } from './utils';

export const getEntityId = pipe(
  createMatchSelector(ROUTE_PATH),
  path(['params', 'entityId']),
);

export const getStatsIds = pipe(
  createMatchSelector(ROUTE_STAT_PATH),
  path(['params', 'statId']),
  parseStats,
);

const getCurrentProps = (state, stats) => {
  const statsIds = getStatsIds(state);
  const currentStats = getStatsInnerJoin(stats, statsIds);

  return map(prop('key'), currentStats);
};

export const createItemsSelectors = (itemsDuck, queryDuck, daoDuck, stats) => {
  const { getItemsIds: getAllIds } = itemsDuck.selectors;
  const { getLimit, getPage } = queryDuck.selectors;
  const { getItemPropById } = daoDuck.selectors;

  const getTimeframe = getItemPropById(TIMEFRAME_KEY);

  const getItemsIds = state => {
    const page = getPage(state);
    const limit = getLimit(state);
    const ids = getAllIds(state);

    return ids.slice(page * limit, (page + 1) * limit);
  };

  const getAllSortedIds = state => {
    const ids = getAllIds(state);

    return sortBy(itemId => getTimeframe(state, itemId), ids);
  };

  const getLeftYValues = state => {
    const itemsIds = getAllSortedIds(state);
    const currentProps = getCurrentProps(state, stats);

    return map(
      itemId => getItemPropById(currentProps[0])(state, itemId),
      itemsIds,
    );
  };

  const getRightYValues = state => {
    const itemsIds = getAllSortedIds(state);
    const currentProps = getCurrentProps(state, stats);

    return map(
      itemId => getItemPropById(currentProps[1])(state, itemId),
      itemsIds,
    );
  };

  const getXValues = state => {
    const itemsIds = getAllSortedIds(state);

    return map(itemId => getTimeframe(state, itemId), itemsIds);
  };

  const getDateBySelectedIndex = (state, index) => {
    const allIds = getAllSortedIds(state);

    return getTimeframe(state, allIds[index]);
  };

  const getPropBySelectedIndex = (state, index, property) => {
    const allIds = getAllSortedIds(state);

    return getItemPropById(property)(state, allIds[index]);
  };

  return {
    getItemsIds,
    getLeftYValues,
    getRightYValues,
    getXValues,
    getDateBySelectedIndex,
    getPropBySelectedIndex,
  };
};
