import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getGiftCertificatesError,
  getItemsIds: getGiftCertificatesIds,
  getItemsMeta: getGiftCertificatesMeta,
  getItemsTotalCount: getGiftCertificatesTotalCount,
  isLoadingItems: isLoadingGiftCertificates,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
