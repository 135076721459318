import {
  getBenefitItemUsedDate,
  getBenefitItemUserId,
  getBenefitStoreName,
  getBenefitTitleRussian,
} from '@esentai/core/features/benefitItems/selectors';
import { compose } from 'ramda';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: itemId }) =>
  // const locationId = getBenefitLocationId(state, itemId);

  ({
    itemId,
    userId: getBenefitItemUserId(state, itemId),
    title: getBenefitTitleRussian(state, itemId),
    storeName: getBenefitStoreName(state, itemId),
    dateUsed: getBenefitItemUsedDate(state, itemId),
  });

export default compose(connect(mapStateToProps), injectSheet(styles))(Row);
