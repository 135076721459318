import createItemsDuck from '@esentai/core/ducks/items';

import { RESOURCE_NAME } from '@/features/locationsStatistics/consts';
import { getIdProp } from '@/features/locationsStatistics/utils';

import api from '../api';
import { getItemsRoot } from '../utils';

export default createItemsDuck(RESOURCE_NAME, {
  api,
  getRoot: getItemsRoot,
  getIdProp,
});
