import createItemsDuck from '@esentai/core/ducks/items';
import api from '@esentai/core/features/purchases/api';
import { RESOURCE_NAME } from '@esentai/core/features/purchases/consts';

import { getPurchasesRoot } from '../utils';

export default createItemsDuck(RESOURCE_NAME, {
  api,
  getRoot: getPurchasesRoot,
});
