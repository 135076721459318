import {
  ADDITIONAL_ENGLISH_TEXT_KEY,
  ADDITIONAL_KAZAKH_TEXT_KEY,
  ADDITIONAL_RUSSIAN_TEXT_KEY,
  ENGLISH_TITLE_KEY,
  IMAGE_URL_KEY,
  KAZAKH_TITLE_KEY,
  LAYOUT_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/article/consts/keys';
import { ONLINE_SHOWCASE_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { number, object, string } from 'yup';

import layoutSchema from '@/components/ArticleConstructorForm/schema';
import { getDefaultLayout } from '@/components/ArticleConstructorForm/utils';
import { replaceSubmitValues } from '@/features/campaignCreatePage/utils';

const asyncOptions = { runnerMethod: 'onSubmit' };

const formikOptions = {
  mapPropsToValues: props => ({
    [LAYOUT_KEY]: props.layout || getDefaultLayout(),
    [IMAGE_URL_KEY]: props.imageUrl,
    [RUSSIAN_TITLE_KEY]: props.russianTitle || '',
    [ENGLISH_TITLE_KEY]: props.englishTitle || '',
    [KAZAKH_TITLE_KEY]: props.kazakhTitle || '',
    [ADDITIONAL_RUSSIAN_TEXT_KEY]: props.additionalRussianText || '',
    [ADDITIONAL_ENGLISH_TEXT_KEY]: props.additionalEnglishText || '',
    [ADDITIONAL_KAZAKH_TEXT_KEY]: props.additionalKazakhText || '',
    [ONLINE_SHOWCASE_KEY]: props.referredShow || null,
  }),

  validationSchema: object({
    [LAYOUT_KEY]: layoutSchema,
    [IMAGE_URL_KEY]: string()
      .url()
      .required(),
    [RUSSIAN_TITLE_KEY]: string()
      .max(50)
      .required(),
    [ENGLISH_TITLE_KEY]: string()
      .max(50)
      .required(),
    [KAZAKH_TITLE_KEY]: string()
      .max(50)
      .required(),
    [ADDITIONAL_RUSSIAN_TEXT_KEY]: string().max(150),
    // .required(),
    [ADDITIONAL_ENGLISH_TEXT_KEY]: string().max(150),
    // .required(),
    [ADDITIONAL_KAZAKH_TEXT_KEY]: string().max(150),
    // .required(),
    [ONLINE_SHOWCASE_KEY]: number().nullable(true),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    const data = {
      ...values,
      [LAYOUT_KEY]: replaceSubmitValues(values[LAYOUT_KEY]),
    };

    props.onSubmit(data);
    setSubmitting(false);
  },
};

const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
