import { connect } from 'react-redux';

import { retryFetch } from '@/features/locationsStatisticsPage/actions';
import {
  getError,
  isLoadingItems,
} from '@/features/locationsStatisticsPage/selectors';

import ErrorMessage from './component';

const mapStateToProps = state => ({
  error: getError(state),
  isLoading: isLoadingItems(state),
});

const mapDispatchToProps = { retry: retryFetch };

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
