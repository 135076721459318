import { Typography } from '@material-ui/core';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const FieldDescription = ({ title, desc, gutterBottom, classes }) => (
  <div
    className={cx({ [classes.withGutter]: gutterBottom && (title || desc) })}
  >
    {title && (
      <Typography className={cx(classes.label, classes.label_title)}>
        {title}
      </Typography>
    )}

    {desc && (
      <Typography className={cx(classes.label, classes.label_desc)}>
        {desc}
      </Typography>
    )}
  </div>
);

FieldDescription.defaultProps = {
  title: '',
  desc: '',
  gutterBottom: true,
};

FieldDescription.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  gutterBottom: PropTypes.bool,
};

export default FieldDescription;
