import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getSalesTurnoversError,
  getItemsIds: getSalesTurnoversIds,
  getItemsMeta: getSalesTurnoversMeta,
  getItemsTotalCount: getSalesTurnoversTotalCount,
  isLoadingItems: isLoadingSalesTurnovers,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
