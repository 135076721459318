export const getImageSize = blob =>
  new Promise((res, rej) => {
    const imgReader = new FileReader();

    imgReader.addEventListener('error', rej);
    imgReader.addEventListener('load', () => {
      const image = new Image();

      image.addEventListener('error', rej);
      image.addEventListener('load', () => {
        const { width, height } = image;

        res({
          height,
          width,
        });
      });
      image.src = imgReader.result;
    });

    imgReader.readAsDataURL(blob);
  });
