import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from './DeleteButton';

const Actions = ({ classes, parkingTowerStopListId, ...props }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <DeleteButton parkingTowerStopListId={parkingTowerStopListId} {...props} />
  </Grid>
);

Actions.propTypes = {
  parkingTowerStopListId: PropTypes.number.isRequired,
};

export default Actions;
