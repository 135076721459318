import { descending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { annotations } from '@/features/purchasesStatistics/serializer';
import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';
import { createPageSerializer, getLinkCreator } from '@/serializers';

const serializer = createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(TIMEFRAME_KEY),
  }),
});

export const createFullPageUrl = (path, query) => {
  const linkCreator = getLinkCreator(path, serializer);

  return linkCreator(query);
};

export default serializer;
