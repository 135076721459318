import {
  BIN_KEY,
  COMPANY_KEY,
} from '@esentai/core/features/merchants/consts/keys';

import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: BIN_KEY,
    label: 'БИН/ИИН',
  }),
  createTextFilter({
    key: COMPANY_KEY,
    label: 'Компания',
  }),
];
