import {
  getBenefitAmount,
  getBenefitAuthorFullName,
  getBenefitAuthorUserId,
  getBenefitComment,
  getBenefitEndDatetime,
  getBenefitFilter,
  getBenefitPercent,
  getBenefitStartDatetime,
  getBenefitStatus,
  getBenefitStoreId,
  getBenefitTitle,
  getBenefitTitleEn,
  getBenefitTitleKz,
  getBenefitType,
  getStoreName,
} from '@esentai/core/features/benefits/selectors';
import { compose } from 'ramda';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: itemId }) => ({
  itemId,
  comment: getBenefitComment(state, itemId),
  amount: getBenefitAmount(state, itemId),
  dateStart: getBenefitStartDatetime(state, itemId),
  dateEnd: getBenefitEndDatetime(state, itemId),
  status: getBenefitStatus(state, itemId),
  storeId: getBenefitStoreId(state, itemId),
  title: getBenefitTitle(state, itemId),
  titleEn: getBenefitTitleEn(state, itemId),
  titleKz: getBenefitTitleKz(state, itemId),
  type: getBenefitType(state, itemId),
  authorId: getBenefitAuthorUserId(state, itemId),
  authorName: getBenefitAuthorFullName(state, itemId),
  storeName: getStoreName(state, itemId),
  discountPercent: getBenefitPercent(state, itemId),
  filters: getBenefitFilter(state, itemId),
});

export default compose(connect(mapStateToProps), injectSheet(styles))(Row);
