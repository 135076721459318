import { Cancel as CancelIcon, Loop as RetryIcon } from '@material-ui/icons';
import React, { Fragment } from 'react';

import { REMOVE_ACTION, RETRY_ACTION } from './consts';

const ErrorButtonSet = props => (
  <Fragment>
    <RetryIcon data-action={RETRY_ACTION} {...props} />
    <CancelIcon data-action={REMOVE_ACTION} {...props} />
  </Fragment>
);

export default ErrorButtonSet;
