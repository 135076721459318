import {
  AMOUNT_KEY,
  COMMENT_KEY,
  DISCOUNT_PERCENT_KEY,
  END_DATETIME_KEY,
  ENGLISH_DESCRIPTION_KEY,
  KAZAKH_DESCRIPTION_KEY,
  LOCATION_ID_KEY,
  RUSSIAN_DESCRIPTION_KEY,
  START_DATETIME_KEY,
  STORE_ID_KEY,
  TITLE_EN_KEY,
  TITLE_KEY,
  TITLE_KZ_KEY,
} from '@esentai/core/features/benefits/consts/keys';
import { Grid } from '@material-ui/core';
import React from 'react';

import ButtonCancel from '@/components/ActionButtons/Cancel';
import AsyncButton from '@/components/AsyncButton';
import FieldRow from '@/components/FieldRow';
import FormikPropTypes from '@/prop-types/formik';

import DescriptionForm from './DescriptionForm';
import StoreForm from './StoreForm';

const fieldNames = {
  [AMOUNT_KEY]: 'количество бенефитов',
  [START_DATETIME_KEY]: 'начало действия',
  [END_DATETIME_KEY]: 'окончание действия',
  [LOCATION_ID_KEY]: 'локация',
  [STORE_ID_KEY]: 'магазин-спонсор',

  [TITLE_KEY]: 'название на русском',
  [TITLE_EN_KEY]: 'название на английском',
  [TITLE_KZ_KEY]: 'название на казахском',

  [RUSSIAN_DESCRIPTION_KEY]: 'описание на русском',
  [ENGLISH_DESCRIPTION_KEY]: 'описание на английском',
  [KAZAKH_DESCRIPTION_KEY]: 'описание на казахском',

  [DISCOUNT_PERCENT_KEY]: 'процент',
  [COMMENT_KEY]: 'комментарий',
};

const FirstStep = props => {
  const { classes, Form, RequiredMessages, SubmitButton, handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <DescriptionForm {...props} />
      <StoreForm {...props} />

      <FieldRow>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <ButtonCancel className={classes.cancelButton}>
              Отменить создание бенефита
            </ButtonCancel>
            <SubmitButton
              Target={AsyncButton}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
            >
              Перейти к указанию сегмента
            </SubmitButton>
          </Grid>
          <Grid item>
            <RequiredMessages fieldNames={fieldNames} />
          </Grid>
        </Grid>
      </FieldRow>
    </Form>
  );
};

FirstStep.propTypes = FormikPropTypes;

export default FirstStep;
