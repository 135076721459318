import {
  BENEFITS_REWARD,
  LOYALTY_POINTS_REWARD,
} from '@esentai/core/features/campaigns/purchaseResult/consts/rewards';
import { cloneQuery } from '@esentai/core/queries';
import { equals, filter, hasFilter, readEquals } from '@esentai/core/query-dsl';
import createAPISerializer from '@esentai/core/serializers/api';
import { bool, date, number, string } from '@esentai/core/serializers/params';
import { evolve } from 'ramda';

import {
  CAMPAIGN_ACTION_RESULT_TRIGGER_KEY,
  CAMPAIGN_BANNER_TYPE_KEY,
  CAMPAIGN_BUTTON_PLACEMENT_KEY,
  CAMPAIGN_HAS_BENEFIT_REWARD_KEY,
  CAMPAIGN_HAS_BONUS_REWARD_KEY,
  CAMPAIGN_LOCATION_ID_KEY,
  CAMPAIGN_REWARD_TYPE_KEY,
  CAMPAIGN_TYPE_KEY,
  TIME_SERIES_KEY,
} from './consts/dimensions';
import { defaultDateRange } from './consts/filters';
import { TIMEFRAME_KEY } from './consts/keys';

export const annotations = {
  [CAMPAIGN_ACTION_RESULT_TRIGGER_KEY]: string(),
  [CAMPAIGN_BANNER_TYPE_KEY]: string(),
  [CAMPAIGN_BUTTON_PLACEMENT_KEY]: string(),
  [CAMPAIGN_HAS_BENEFIT_REWARD_KEY]: bool(),
  [CAMPAIGN_HAS_BONUS_REWARD_KEY]: bool(),
  [CAMPAIGN_LOCATION_ID_KEY]: number(),
  [CAMPAIGN_REWARD_TYPE_KEY]: string(),
  [CAMPAIGN_TYPE_KEY]: string(),
  [TIMEFRAME_KEY]: date(),
  [TIME_SERIES_KEY]: date({
    defaultValue: defaultDateRange,
  }),
};

const enhanceQueryParamsSerialize = nextSerialize => query => {
  const clonedQuery = cloneQuery(query);

  if (hasFilter(CAMPAIGN_REWARD_TYPE_KEY, clonedQuery)) {
    const filters = clonedQuery.get('filters');
    const typeFilter = filters.get(CAMPAIGN_REWARD_TYPE_KEY);
    const type = readEquals(typeFilter);

    if (type === BENEFITS_REWARD) {
      filters.set(CAMPAIGN_HAS_BENEFIT_REWARD_KEY, filter(equals(true)));
    } else if (type === LOYALTY_POINTS_REWARD) {
      filters.set(CAMPAIGN_HAS_BONUS_REWARD_KEY, filter(equals(true)));
    }

    filters.delete(CAMPAIGN_REWARD_TYPE_KEY);

    return nextSerialize(clonedQuery);
  }

  return nextSerialize(clonedQuery);
};

const enhanceSerializer = evolve({
  queryParamsSerializer: {
    serialize: enhanceQueryParamsSerialize,
  },
});

export default enhanceSerializer(createAPISerializer(annotations));
