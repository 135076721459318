import {
  getPrePushEnglishMessage,
  getPrePushEnglishTitle,
  getPrePushRussianMessage,
  getPrePushRussianTitle,
  getPrePushSendDate,
  shouldSendPrePush,
} from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { connect } from 'react-redux';

import PrePush from './component';

const mapStateToProps = (state, { itemId }) => ({
  prePushEnglishMessage: getPrePushEnglishMessage(state, itemId),
  prePushEnglishTitle: getPrePushEnglishTitle(state, itemId),
  prePushRussianMessage: getPrePushRussianMessage(state, itemId),
  prePushRussianTitle: getPrePushRussianTitle(state, itemId),
  prePushSendDate: getPrePushSendDate(state, itemId),
  sendingPrePush: shouldSendPrePush(state, itemId),
});

export default connect(mapStateToProps)(PrePush);
