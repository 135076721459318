import { DATE_START_KEY } from '@esentai/core/features/campaigns/consts/keys';
import {
  PRE_PUSH_ENGLISH_MESSAGE_KEY,
  PRE_PUSH_ENGLISH_TITLE_KEY,
  PRE_PUSH_RUSSIAN_MESSAGE_KEY,
  PRE_PUSH_RUSSIAN_TITLE_KEY,
  PRE_PUSH_SEND_DATETIME_KEY,
  SEND_PRE_PUSH_PUSH_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { mapProps } from 'recompose';

import PrePushForm from './component';
import styles from './styles';

export default compose(
  mapProps(({ setFieldValue, values, Field, campaignStarted }) => ({
    dateStart: values[DATE_START_KEY],
    prePushEnglishTitle: values[PRE_PUSH_ENGLISH_TITLE_KEY],
    prePushRussianTitle: values[PRE_PUSH_RUSSIAN_TITLE_KEY],
    prePushEnglishMessage: values[PRE_PUSH_ENGLISH_MESSAGE_KEY],
    prePushRussianMessage: values[PRE_PUSH_RUSSIAN_MESSAGE_KEY],
    prePushSendDateTime: values[PRE_PUSH_SEND_DATETIME_KEY],
    sendingPrePush: values[SEND_PRE_PUSH_PUSH_KEY],
    canSendPrePush: !campaignStarted && values[SEND_PRE_PUSH_PUSH_KEY],
    campaignStarted,
    setFieldValue,
    Field,
  })),
  withStyles(styles),
)(PrePushForm);
