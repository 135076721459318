import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { CANCELED } from '@esentai/core/features/loyalties/consts/statuses';
import { updateCancel } from '@esentai/core/features/loyalties/sagas';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { CANCEL_SUB, RETRY_FETCH } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export function* cancelRequestAndRefresh(action) {
  const { payload: id } = action;

  yield call(updateCancel, id, { status: CANCELED });
  yield call(fetchItems);
}

export function* watchCancel() {
  yield takeEvery(CANCEL_SUB, createTaskSaga(cancelRequestAndRefresh));
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield spawn(watchCancel);
}
