import PropTypes from 'prop-types';

const ProductNameLazy = ({ isLoaded, title }) => (isLoaded ? title : '...');

ProductNameLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
};

ProductNameLazy.defaultProps = {
  title: null,
  subTitle: null,
  showSubtitle: false,
};

export default ProductNameLazy;
