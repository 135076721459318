import PropTypes from 'prop-types';

const CampaignTitleLazy = ({ isLoaded, title }) => (isLoaded ? title : '...');

CampaignTitleLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

CampaignTitleLazy.defaultProps = {
  title: null,
};

export default CampaignTitleLazy;
