import { Typography } from '@material-ui/core';
import React from 'react';

const Description = ({ classes }) => (
  <ol className={classes.root}>
    <Typography gutterBottom component="li" className={classes.item}>
      Принимать транспорт клиентов Esentai Mall
    </Typography>

    <Typography component="li" className={classes.item}>
      Принимать оплату
    </Typography>

    <Typography component="li" className={classes.item}>
      Доступа на сайт не имеется
    </Typography>
  </ol>
);

export default Description;
