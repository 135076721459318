import { canReadLevelThreeWhitelist } from '@esentai/core/features/parkingLevelThreeWhitelist/permissions';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Link from '@/components/Link';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PageTitleLink from '@/components/PageTitleLink';
import CanView from '@/containers/CanView';
import { ROUTE_PATH as ROUTE_PARKING_LEVEL_THREE_WHITELIST_PATH } from '@/features/parkingLevelThreeWhitelistPage/consts';
import { ROUTE_PATH as ROUTE_PARKING_STOP_LIST_ADD_PATH } from '@/features/parkingTowerStopListAddPage/consts';
import { ROUTE_PATH as ROUTE_PARKING_TOWER_STOP_LIST_PATH } from '@/features/parkingTowerStopListPage/consts';
import { ROUTE_PATH as ROUTE_PARKING_WHITELIST_PATH } from '@/features/parkingWhitelistPage/consts';

import Table from './Table';

class ParkingTowerStopListPage extends Component {
  static propTypes = {
    error: PropTypes.any,
    parkingTowerStopListIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const {
      error,
      parkingTowerStopListIds,
      isLoading,
      totalCount,
      classes,
    } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle
            style={{
              marginBottom: 30,
              display: 'flex',
              gap: 20,
              justifyContent: 'start',
              placeItems: 'end',
              flexWrap: 'wrap',
            }}
            gutterBottom={false}
          >
            <PageTitleLink
              style={{ margin: 0 }}
              to={ROUTE_PARKING_WHITELIST_PATH}
            >
              Whitelist Тимирязева
            </PageTitleLink>
            <PageTitle
              to={ROUTE_PARKING_TOWER_STOP_LIST_PATH}
              style={{ margin: 0 }}
            >
              Stoplist Tower
            </PageTitle>
            <CanView permission={canReadLevelThreeWhitelist}>
              <PageTitleLink
                style={{ margin: 0 }}
                to={ROUTE_PARKING_LEVEL_THREE_WHITELIST_PATH}
              >
                Whitelist P3
              </PageTitleLink>
            </CanView>
          </PageTitle>
        </PageHeader>

        <PageContent className={classes.tableBlock}>
          <div className={classes.buttonsWrapper}>
            <div className={classes.reportWrapper}>
              <Link to={ROUTE_PARKING_STOP_LIST_ADD_PATH}>
                <Button
                  variant="outlined"
                  color="default"
                  size="small"
                  className={classes.button}
                >
                  Добавить пользователей
                </Button>
              </Link>
            </div>
          </div>
          <Table
            error={error}
            items={parkingTowerStopListIds}
            isLoading={isLoading}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default ParkingTowerStopListPage;
