import {
  getArticleAdditionalEnglishText,
  getArticleAdditionalKazakhText,
  getArticleAdditionalRussianText,
  getArticleEnglishTitle,
  getArticleImageURL,
  getArticleKazakhTitle,
  getArticleRussianTitle,
  getShowcaseId,
} from '@esentai/core/features/campaigns/article/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepThree } from '@/features/campaignCreatePage/actions';
import {
  getArticleLayoutWithRename,
  getCampaignId,
} from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStepArticle from './component';
import form from './form';

const mapStateToProps = state => {
  const id = getCampaignId(state);

  return {
    layout: getArticleLayoutWithRename(state, id),
    imageUrl: getArticleImageURL(state, id),
    russianTitle: getArticleRussianTitle(state, id),
    englishTitle: getArticleEnglishTitle(state, id),
    kazakhTitle: getArticleKazakhTitle(state, id),
    additionalRussianText: getArticleAdditionalRussianText(state, id),
    additionalEnglishText: getArticleAdditionalEnglishText(state, id),
    additionalKazakhText: getArticleAdditionalKazakhText(state, id),
    referredShow: getShowcaseId(state, id),
    id,
  };
};

const mapDispatchToProps = { onSubmit: goToStepThree };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
)(SecondStepArticle);
