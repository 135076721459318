import { FILTER_STR_KEY } from '@esentai/core/features/parkingWhitelistFilter/consts/keys';
import { Button, Grid, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import ButtonSet from '@/components/ButtonSet';
import CheckboxTile, { CheckBoxGroup } from '@/components/CheckboxTile';
import Link from '@/components/Link';
import { ROUTE_PATH as ROUTE_WHITELIST_PATH } from '@/features/parkingWhitelistPage/consts';
import { options } from '@/filters/UserStatus';
import { userStatus } from '@/utils/labels';

const Description = ({
  classes,
  filterMap = new Map(),
  updateFilter,
  itemId,
  // addedUsers,
  // stable,
}) => {
  const [levels, setLevels] = useState([]);

  const filterLevels = useMemo(() => {
    let array = [];

    if (filterMap) {
      [...filterMap.get('filters').entries()].forEach(
        ([key, value = new Map()]) => {
          if (key === 'level') {
            const levelsFromMap = value.entries().next().value[1];

            if (Array.isArray(levelsFromMap)) {
              array = levelsFromMap.filter(val => Boolean(val));
            }
          }
        },
      );
    }

    return array;
  }, [filterMap]);

  useEffect(() => {
    setLevels(filterLevels);
  }, [filterLevels]);

  return (
    <div className={classes.column}>
      <div className={classes.row}>
        <Typography
          className={classes.typography}
          gutterBottom
          color="textSecondary"
        >
          Текущие Статусы
        </Typography>

        <div className={classes.row}>
          {!filterLevels.length && '-'}
          {filterLevels.map((level, index) => (
            <Typography gutterBottom variant="h6" key={level}>
              {userStatus(level)}
              {index !== filterLevels.length - 1 ? ', ' : ''}
            </Typography>
          ))}
        </div>
      </div>
      <Divider className={classes.divider} />

      <Typography gutterBottom color="textSecondary" variant="h4">
        Изменить динамичный список
      </Typography>
      <CheckBoxGroup
        value={levels}
        onChange={(_, value) => {
          setLevels(value);
        }}
      >
        {options.map(option => (
          <CheckboxTile
            key={option.key}
            value={option.key}
            label={option.label}
            helperText={`Включить в сегмент посетителей, у которых статус пользователя ${option.label}`}
          />
        ))}
      </CheckBoxGroup>

      <Grid item>
        <ButtonSet>
          <Link to={ROUTE_WHITELIST_PATH}>
            <Button size="large">Отменить</Button>
          </Link>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={() => {
              const map = new Map().set(
                'filters',
                new Map().set('level', new Map().set('in', levels)),
              );

              updateFilter({
                ParkingWhitelistFilterId: itemId,
                [FILTER_STR_KEY]: map,
              });
            }}
          >
            Изменить
          </Button>
        </ButtonSet>
      </Grid>
    </div>
  );
};

Description.propTypes = {
  filterMap: PropTypes.instanceOf(Map),
  // addedUsers: PropTypes.any,
  // stable: PropTypes.bool,
  updateFilter: PropTypes.func.isRequired,
  itemId: PropTypes.number,
};

Description.defaultProps = {
  filterMap: null,
  // addedUsers: null,
  // stable: false,
  itemId: 0,
};

export default Description;
