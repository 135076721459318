export default {
  popper: {
    margin: [0, 11],
    pointerEvents: 'none',
  },

  paper: {
    boxSizing: 'border-box',
    padding: 12,
    boxShadow:
      '0 6px 6px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.14)',
  },
};
