export default theme => ({
  wrapper: {
    margin: [0, 'auto', 40],
  },
  excelButton: {
    marginTop: 15,
  },
  tbody: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
  },
  top_field: {
    marginBottom: 10,
  },
  quarter: {
    width: '25%',
    padding: '0px 20px',
  },
  half: {
    width: '50%',
    padding: '0 20px',
  },
  third: {
    width: '40%',
    padding: '0 20px',
  },
  prod: {
    padding: '15px',
  },
  card: {
    marginBottom: 15,
  },
  drop: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: '15px 15px',
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  plus: {
    fontSize: 50,
  },
});
