import { FormControlLabel, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';

class ControlTile extends Component {
  inputRef = createRef();

  delegateClick = event => {
    const { inputRef } = this;

    if (event.target !== inputRef.current) {
      event.stopPropagation();

      inputRef.current.click();
    }
  };

  render() {
    const { inputRef } = this;
    const {
      classes,
      checked,
      helperText,
      children,
      Control,
      disabled,
      ...rest
    } = this.props;

    return (
      <div
        className={classNames(classes.radioBox, {
          [classes.checked]: checked,
          [classes.disabled]: disabled,
        })}
        onClick={this.delegateClick}
      >
        <FormControlLabel
          control={<Control inputRef={inputRef} className={classes.control} />}
          checked={checked}
          disabled={disabled}
          classes={{ label: classes.label }}
          {...rest}
        />

        {(helperText || children) && (
          <div className={classes.body}>
            <Typography variant="caption">{helperText}</Typography>
            {children}
          </div>
        )}
      </div>
    );
  }
}

ControlTile.defaultProps = {
  helperText: null,
  disabled: false,
};

ControlTile.propTypes = {
  checked: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  Control: PropTypes.func.isRequired,
};

export default ControlTile;
