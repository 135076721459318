import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/bonusTransactionLevelHistoryPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getBonusTransactionError,
  getBonusTransactionIds,
  getBonusTransactionTotalCount,
  isLoadingBonusTransaction,
} from '../selectors';
import BonusTransactionLevelHistory from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getBonusTransactionError(state),
  historyIds: getBonusTransactionIds(state),
  isLoading: isLoadingBonusTransaction(state),
  totalCount: getBonusTransactionTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(BonusTransactionLevelHistory);
