import { path } from 'ramda';

import api from '@/api';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

export const getItemsRoot = path([PAGES_STORE_KEY, 'usersPage', 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, 'usersPage', 'query']);

export const downloadXls = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const limit = Number(urlParams.get('limit')) || 10;
  const page = Number(urlParams.get('page'));
  const offset = page * limit;

  urlParams.set('limit', limit.toString());
  urlParams.set('offset', offset.toString());
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'last_name');
  }

  api
    .doBlobGet(`/user-xlsx-export?role[in]=customer&${urlParams.toString()}`)
    .then(response => {
      saveAs(response.data, `Пользователи.xlsx`);
    });
};

export const downloadAllUsers = () => {
  const urlParams = new URLSearchParams(window.location.search);

  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'last_name');
  }

  api
    .doBlobGet(
      `/user-xlsx-export?role[in]=customer&sort=last_name&${urlParams.toString()}`,
    )
    .then(response => {
      saveAs(response.data, `Список всех пользователей.xlsx`);
    });
};
