import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import DatePicker from '@/components/DatePicker';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PageTitleLink from '@/components/PageTitleLink';
import CanView from '@/containers/CanView';
import { carFilters, dateFilters } from '@/features/parkingPage/consts/keys';
import { canUpdateParkingRate } from '@/features/parkingPage/permissions';
import { downloadReport, downloadXls } from '@/features/parkingPage/utils';
import { ROUTE_PATH as TIMIRYAZEV_ROUTE_PATH } from '@/features/timiryazevParkingPage/consts';
import { ROUTE_PATH } from '@/features/valetParkingPage/consts';

import { ModalParkingRate } from '../../../components/ModalParkingRate/ModalParkingRate';
import Table from './Table';

const ParkingPage = props => {
  const [parkingData, setParkingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [excelDates, setExcelDates] = useState({ from: null, to: null });
  const [parkingRate, setParkingRate] = useState(0);
  const [parkingRateTower, setParkingRateTower] = useState(0);
  const [parkingVisible, setParkingVisible] = useState(false);

  const { location, classes } = props;

  const loadParkingRate = async (building = 'MALL') => {
    try {
      const response = await api.doGetParkingRequest(
        `/parking-rate/current/${building === 'TOWER' ? 'tower' : ''}`,
      );

      if (building === 'MALL') {
        setParkingRate(response.price);
      } else {
        setParkingRateTower(response.price);
      }
    } catch (e) {
      throw e;
    }
  };

  const updateParkingRate = async (rate, date, building = 'MALL') => {
    try {
      const response = await api.doPostParkingRequest('/parking-rate', {
        price: rate,
        start_date: date,
        building,
      });

      if (building === 'MALL') {
        setParkingRate(response.price);
      } else {
        setParkingRateTower(response.price);
      }
    } catch (e) {
      throw e;
    }
  };

  const onSetParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filter = [];
    const sort = [];

    for (const param of searchParams) {
      let filterStr = '';

      const name = param[0];
      const value = param[1];
      const filteredName = name.replace('[gte]', '').replace('[lte]', '');

      const isDate = dateFilters.includes(filteredName);
      const isCar = carFilters.includes(filteredName);

      if (name !== 'sort') {
        if (name.indexOf('gte') !== -1) {
          const fieldValue = isDate
            ? moment(new Date(value))
                .utc()
                .format(`YYYYMMDDTHHmmss`)
            : value;

          filterStr = `${filteredName}>${fieldValue}`;
        } else if (name.indexOf('lte') !== -1) {
          const fieldValue = isDate
            ? moment(new Date(value))
                .utc()
                .format(`YYYYMMDDTHHmmss`)
            : value;

          filterStr = `${filteredName}<${fieldValue}`;
        } else if (isCar) {
          filterStr = `${filteredName}~${value}`;
        } else {
          filterStr = `${filteredName}:${value}`;
        }
        filter.push(filterStr);
      } else {
        sort.push(value);
      }
    }

    return {
      sort,
      filter,
    };
  };

  const loadParkingData = async () => {
    const { sort, filter } = onSetParams();

    setLoading(true);

    const filters = {
      page,
      size: pageSize,
    };

    try {
      const response = await api.doGetParkingRequest(
        '/parking-session/all-sessions',
        { ...filters, sort, filter },
      );

      setTotalCount(response.total_elements);
      setParkingData(response.content);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const onSetPageSize = newPageSize => {
    setPage(0);
    setPageSize(newPageSize);
  };

  const onSetPage = newPage => {
    setPage(newPage);
  };

  const onDownloadExcel = () => {
    const { sort, filter } = onSetParams();

    downloadXls({ limit: totalCount, sort, filter });
  };

  const onDownloadReport = () => {
    const dateFilter = {
      from: format(new Date(excelDates.from), 'yyyy-MM-dd'),
      to: format(new Date(excelDates.to), 'yyyy-MM-dd'),
    };

    downloadReport({ ...dateFilter });
  };

  const setWindowData = () => {
    window.parkingData = {};
    window.parkingData.loadParkingData = loadParkingData;
  };

  useEffect(() => {
    loadParkingData();
    loadParkingRate();
    loadParkingRate('TOWER');
    setWindowData();

    return () => {
      delete window.ipnReportsData;
      delete window.parkingData;
    };
  }, [pageSize, page, location.search]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>Паркинг(SPT)</PageTitle>
          <PageTitleLink to={ROUTE_PATH}>Valet</PageTitleLink>
          <PageTitleLink to={TIMIRYAZEV_ROUTE_PATH}>Тимирязева</PageTitleLink>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        <div className={classes.buttonsWrapper}>
          <div className={classes.reportWrapper}>
            <DatePicker
              clearable
              label="от"
              className={classes.input}
              format={'PPP'}
              value={excelDates.from}
              onChange={event => {
                setExcelDates(prevState => ({
                  ...prevState,
                  ...{
                    from: event.target.value,
                  },
                }));
              }}
            />
            <DatePicker
              clearable
              label="до"
              className={classes.inputLast}
              format={'PPP'}
              value={excelDates.to}
              onChange={event => {
                setExcelDates(prevState => ({
                  ...prevState,
                  ...{
                    to: event.target.value,
                  },
                }));
              }}
            />
            <CanView permission={canUpdateParkingRate}>
              <Button
                variant="outlined"
                color="default"
                size="small"
                className={classes.excelButton}
                // disabled={!excelDates.from || !excelDates.to}
                onClick={() => {
                  setParkingVisible(true);
                }}
                onSubmit={() => {}}
              >
                Изменить тариф
              </Button>
            </CanView>
            <Button
              variant="outlined"
              color="default"
              size="small"
              className={classes.excelButton}
              disabled={!excelDates.from || !excelDates.to}
              onClick={onDownloadReport}
            >
              Вывести отчет
            </Button>
          </div>
          <Button
            variant="outlined"
            color="default"
            size="small"
            className={classes.excelButton}
            onClick={onDownloadExcel}
          >
            Скачать все
          </Button>
        </div>
        <Table
          limit={pageSize}
          items={parkingData}
          isLoading={loading}
          totalCount={totalCount}
          page={page}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
        <ModalParkingRate
          open={parkingVisible}
          onClose={() => setParkingVisible(false)}
          parkingRate={parkingRate}
          parkingRateTower={parkingRateTower}
          onSubmit={updateParkingRate}
        />
      </PageContent>
    </Page>
  );
};

ParkingPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ParkingPage;
