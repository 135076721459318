import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DefaultMapWorkspace from '@/components/MapWorkspace';

import ErrorMessage from './ErrorMessage';
import FloorOption from './FloorOption';
import HeatMap from './HeatMap';

const MapWorkspace = ({ classes, error, loading }) => (
  <div className={classNames({ [classes.loading]: loading })}>
    {error ? (
      <ErrorMessage />
    ) : (
      <DefaultMapWorkspace FloorOption={FloorOption} Map={HeatMap} />
    )}
  </div>
);

MapWorkspace.defaultProps = {
  error: null,
};

MapWorkspace.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

export default MapWorkspace;
