import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/kkmsPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getKkmIds,
  getKkmsError,
  getKkmsTotalCount,
  isLoadingKkms,
} from '../selectors';
import Kkms from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getKkmsError(state),
  kkmIds: getKkmIds(state),
  isLoading: isLoadingKkms(state),
  totalCount: getKkmsTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(Kkms);
