import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddLink from '@/components/AddLink';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';
import CanView from '@/containers/CanView';
import { ROUTE_PATH as MAKE_INVITE_PATH } from '@/features/invitePages/MakeInvite/consts';
import { canCreateInvite } from '@/features/invites/permissions';

import Table from './Table';

class Personnel extends Component {
  render() {
    const { error, personnelIds, classes, isLoading, totalCount } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Персонал</PageTitle>
          <PageSubtitle>Вспомогательный текст</PageSubtitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <CanView permission={canCreateInvite}>
              <AddLink to={MAKE_INVITE_PATH} />
            </CanView>
            <Table
              error={error}
              items={personnelIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

Personnel.propTypes = {
  error: PropTypes.any,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  personnelIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Personnel.defaultProps = {
  error: null,
};

export default Personnel;
