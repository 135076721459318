import {
  BRANDS_IDS_KEY,
  CATEGORIES_KEY,
  DESCRIPTION_CHINESE_KEY,
  DESCRIPTION_ENGLISH_KEY,
  DESCRIPTION_KAZAKH_KEY,
  DESCRIPTION_RUSSIAN_KEY,
  LOGO_URL_KEY,
  LOYALTY_REP_KEY,
  MERCHANTS_IDS_KEY,
  NAME_ENGLISH_KEY,
  NAME_KAZAKH_KEY,
  NAME_KEY,
  PHONE_KEY,
  SOCIAL_FACEBOOK_KEY,
  SOCIAL_INSTAGRAM_KEY,
  SOCIAL_TWITTER_KEY,
  URL_KEY,
} from '@esentai/core/features/stores/consts/keys';
import {
  getBrandIds,
  getCategories,
  getChineseDescription,
  getEmail,
  getEnglishDescription,
  getEnglishNameForStore,
  getFacebookId,
  getInstagramId,
  getIsLoyaltyRep,
  getKazakhDescription,
  getKazakhNameForStore,
  getLogoUrl,
  getMerchantIds,
  getNameForStore,
  getPhone,
  getRussianDescription,
  getSiteUrl,
  getStoreNumber,
  getTwitterId,
} from '@esentai/core/features/stores/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { array, boolean, number, object, string } from 'yup';

import withFormikValidation from '@/hocs/withFormikValidation';

import StoreForm from './component';
import styles from './styles';

const formikOptions = {
  mapPropsToValues: ({
    // locationId,
    socialFacebookId,
    socialInstagramId,
    socialTwitterId,
    storeChineseDescription,
    storeEnglishDescription,
    storeKazakhDescription,
    storeRussianDescription,
    storeLogoUrl,
    storePhone,
    storeSiteUrl,
    storeBrands,
    storeCategories,
    storeMerchants,
    storeName,
    storeEnglishName,
    storeKazakhName,
    loyaltyRep,
  }) => ({
    [BRANDS_IDS_KEY]: storeBrands || [],
    [MERCHANTS_IDS_KEY]: storeMerchants || [],
    [CATEGORIES_KEY]: storeCategories || [],

    [DESCRIPTION_CHINESE_KEY]: storeChineseDescription,
    [DESCRIPTION_ENGLISH_KEY]: storeEnglishDescription,
    [DESCRIPTION_KAZAKH_KEY]: storeKazakhDescription,
    [DESCRIPTION_RUSSIAN_KEY]: storeRussianDescription,

    [LOGO_URL_KEY]: storeLogoUrl,
    [PHONE_KEY]: storePhone,
    [SOCIAL_FACEBOOK_KEY]: socialFacebookId,
    [SOCIAL_INSTAGRAM_KEY]: socialInstagramId,
    [SOCIAL_TWITTER_KEY]: socialTwitterId,
    [URL_KEY]: storeSiteUrl,

    [NAME_KEY]: storeName,
    [NAME_ENGLISH_KEY]: storeEnglishName,
    [NAME_KAZAKH_KEY]: storeKazakhName,

    [LOYALTY_REP_KEY]: loyaltyRep,
    comment: '',
  }),

  validationSchema: object().shape({
    [BRANDS_IDS_KEY]: array().of(number()),
    [MERCHANTS_IDS_KEY]: array().of(number()),
    [CATEGORIES_KEY]: array().of(string()),

    [DESCRIPTION_CHINESE_KEY]: string().nullable(),
    [DESCRIPTION_ENGLISH_KEY]: string().nullable(),
    [DESCRIPTION_KAZAKH_KEY]: string().nullable(),
    [DESCRIPTION_RUSSIAN_KEY]: string().nullable(),

    [LOGO_URL_KEY]: string().nullable(),
    [PHONE_KEY]: string().nullable(),
    [SOCIAL_FACEBOOK_KEY]: string().nullable(),
    [SOCIAL_INSTAGRAM_KEY]: string().nullable(),
    [SOCIAL_TWITTER_KEY]: string().nullable(),

    [NAME_KEY]: string().required(),
    [NAME_ENGLISH_KEY]: string().required(),
    [NAME_KAZAKH_KEY]: string().required(),

    [LOYALTY_REP_KEY]: boolean(),
    [URL_KEY]: string()
      .url()
      .nullable(),
    comment: string()
      .min(10, 'комментарий должен быть не менее 10 символов')
      .max(200)
      .required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };
const mapStateToProps = (state, { storeId }) => {
  const socialTwitterId = getTwitterId(state, storeId);
  const socialInstagramId = getInstagramId(state, storeId);
  const socialFacebookId = getFacebookId(state, storeId);

  return {
    socialKeys: [
      socialTwitterId && SOCIAL_TWITTER_KEY,
      socialFacebookId && SOCIAL_FACEBOOK_KEY,
      socialInstagramId && SOCIAL_INSTAGRAM_KEY,
    ].filter(Boolean),

    socialFacebookId,
    socialInstagramId,
    socialTwitterId,

    storeChineseDescription: getChineseDescription(state, storeId),
    storeEnglishDescription: getEnglishDescription(state, storeId),
    storeKazakhDescription: getKazakhDescription(state, storeId),
    storeRussianDescription: getRussianDescription(state, storeId),

    storeEmail: getEmail(state, storeId),
    storeLogoUrl: getLogoUrl(state, storeId),
    storePhone: getPhone(state, storeId),
    storeSiteUrl: getSiteUrl(state, storeId),
    storeStoreNumber: getStoreNumber(state, storeId),
    storeBrands: getBrandIds(state, storeId),
    storeMerchants: getMerchantIds(state, storeId),
    storeCategories: getCategories(state, storeId),

    storeName: getNameForStore(state, storeId),
    storeEnglishName: getEnglishNameForStore(state, storeId),
    storeKazakhName: getKazakhNameForStore(state, storeId),

    loyaltyRep: getIsLoyaltyRep(state, storeId),
  };
};

export default compose(
  connect(mapStateToProps),
  withAsyncRunner(asyncOptions),
  withFormikValidation(formikOptions),
  withStyles(styles),
)(StoreForm);
