import {
  getArticleAdditionalEnglishText,
  getArticleAdditionalKazakhText,
  getArticleAdditionalRussianText,
  getArticleEnglishTitle,
  getArticleImageURL,
  getArticleKazakhTitle,
  getArticleRussianTitle,
} from '@esentai/core/features/campaigns/article/selectors';
import { connect } from 'react-redux';

import Banner from './component';

const mapStateToProps = (state, { itemId }) => ({
  imageUrl: getArticleImageURL(state, itemId),
  russianTitle: getArticleRussianTitle(state, itemId),
  englishTitle: getArticleEnglishTitle(state, itemId),
  kazakhTitle: getArticleKazakhTitle(state, itemId),
  additionalRussianText: getArticleAdditionalRussianText(state, itemId),
  additionalEnglishText: getArticleAdditionalEnglishText(state, itemId),
  additionalKazakhText: getArticleAdditionalKazakhText(state, itemId),
});

export default connect(mapStateToProps)(Banner);
