import {
  DELAY_AFTER_PURCHASE_KEY,
  DESTINATION_KEY,
  HAS_DELAY_AFTER_PURCHASE_KEY,
  HAS_TRIGGERS_KEY,
  PERIOD_RECEIPTS_KEY,
  PRE_PUSH_ENGLISH_MESSAGE_KEY,
  PRE_PUSH_ENGLISH_TITLE_KEY,
  PRE_PUSH_RUSSIAN_MESSAGE_KEY,
  PRE_PUSH_RUSSIAN_TITLE_KEY,
  PRE_PUSH_SEND_DATETIME_KEY,
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  RECEIPTS_COUNT_KEY,
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
  REFERRED_STORE_ID_KEY,
  REWARD_BENEFITS_IDS_KEY,
  REWARD_LOYALTY_POINTS_KEY,
  REWARD_TYPE_KEY,
  SEND_PRE_PUSH_PUSH_KEY,
  SEND_PUSH_KEY,
  SINGLE_RECEIPT_ITEMS_COUNT_KEY,
  SINGLE_RECEIPT_KEY,
  TRIGGERS_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import {
  BENEFITS_REWARD,
  LOYALTY_POINTS_REWARD,
} from '@esentai/core/features/campaigns/purchaseResult/consts/rewards';
import {
  PERIOD_RECEIPT_TRIGGER,
  RECEIPTS_COUNT_TRIGGER,
  SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER,
  SINGLE_RECEIPT_TRIGGER,
} from '@esentai/core/features/campaigns/purchaseResult/consts/triggers';
import {
  always,
  compose,
  empty,
  equals,
  evolve,
  includes,
  not,
  prop,
  propSatisfies,
  when,
} from 'ramda';

const isDisabled = propSatisfies(not);
const isRewardDisabled = rewardType =>
  compose(not, equals(rewardType), prop(REWARD_TYPE_KEY));
const isTriggerDisabled = key =>
  compose(not, includes(key), prop(TRIGGERS_KEY));
const reset = always(null);

const resetBenefitsReward = evolve({
  [REWARD_BENEFITS_IDS_KEY]: empty,
});

const resetDelay = evolve({
  [DELAY_AFTER_PURCHASE_KEY]: always(10),
});

const resetLoyaltyPointsReward = evolve({
  [REWARD_LOYALTY_POINTS_KEY]: reset,
});

const resetPeriodReceipt = evolve({
  [PERIOD_RECEIPTS_KEY]: reset,
});

const resetReceiptsCount = evolve({
  [RECEIPTS_COUNT_KEY]: reset,
});

const resetSingleReceiptCount = evolve({
  [SINGLE_RECEIPT_KEY]: reset,
});

const resetSingleReceiptItemsCount = evolve({
  [SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER]: reset,
});

const resetPrePush = evolve({
  [PRE_PUSH_ENGLISH_TITLE_KEY]: reset,
  [PRE_PUSH_RUSSIAN_TITLE_KEY]: reset,
  [PRE_PUSH_ENGLISH_MESSAGE_KEY]: reset,
  [PRE_PUSH_RUSSIAN_MESSAGE_KEY]: reset,
  [PRE_PUSH_SEND_DATETIME_KEY]: reset,
});

const resetPush = evolve({
  [PUSH_ENGLISH_TITLE_KEY]: reset,
  [PUSH_RUSSIAN_TITLE_KEY]: reset,
  [PUSH_ENGLISH_MESSAGE_KEY]: reset,
  [PUSH_RUSSIAN_MESSAGE_KEY]: reset,
});

const resetReferences = evolve({
  [REFERRED_ARTICLE_ID_KEY]: reset,
  [REFERRED_CALENDAR_EVENT_ID_KEY]: reset,
  [REFERRED_STORE_ID_KEY]: reset,
});

const resetRewards = evolve({
  [REWARD_BENEFITS_IDS_KEY]: empty,
  [REWARD_LOYALTY_POINTS_KEY]: reset,
});

const resetTriggers = evolve({
  [PERIOD_RECEIPTS_KEY]: reset,
  [RECEIPTS_COUNT_KEY]: reset,
  [SINGLE_RECEIPT_KEY]: reset,
  [SINGLE_RECEIPT_ITEMS_COUNT_KEY]: reset,
  [TRIGGERS_KEY]: () => [],
});

export const cleanUpValues = compose(
  when(isDisabled(DESTINATION_KEY), resetReferences),
  when(isDisabled(HAS_DELAY_AFTER_PURCHASE_KEY), resetDelay),
  when(isDisabled(HAS_TRIGGERS_KEY), resetTriggers),
  when(isDisabled(REWARD_TYPE_KEY), resetRewards),
  when(isDisabled(SEND_PRE_PUSH_PUSH_KEY), resetPrePush),
  when(isDisabled(SEND_PUSH_KEY), resetPush),
  when(isRewardDisabled(BENEFITS_REWARD), resetBenefitsReward),
  when(isRewardDisabled(LOYALTY_POINTS_REWARD), resetLoyaltyPointsReward),
  when(isTriggerDisabled(PERIOD_RECEIPT_TRIGGER), resetPeriodReceipt),
  when(isTriggerDisabled(RECEIPTS_COUNT_TRIGGER), resetReceiptsCount),
  when(isTriggerDisabled(SINGLE_RECEIPT_TRIGGER), resetSingleReceiptCount),
  when(
    isTriggerDisabled(SINGLE_RECEIPT_ITEMS_COUNT_TRIGGER),
    resetSingleReceiptItemsCount,
  ),
);
