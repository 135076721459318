import {
  ADDED_DIRECTLY_KEY,
  ID_KEY,
} from '@esentai/core/features/parkingWhitelist/consts/keys';
import { annotations } from '@esentai/core/features/parkingWhitelist/serializer';
import { descending, equals, filter } from '@esentai/core/query-dsl';
import { bool, sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,
  [ADDED_DIRECTLY_KEY]: bool({
    defaultValue: filter(equals(true)),
  }),
  sort: sort({
    defaultValue: descending(ID_KEY),
  }),
});
