import { findUserById } from '@esentai/core/features/users/actions';
import {
  getFullNameForPersonnel,
  getFullNameForUser,
  isLoadedUser,
  isLoadingUser,
} from '@esentai/core/features/users/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import UserNameLazy from './component';

const mapStateToProps = (state, { userId }) => ({
  isLoaded: isLoadedUser(state, userId),
  isLoading: isLoadingUser(state, userId),
  itemId: userId,
  title: getFullNameForPersonnel(state, userId)
    ? getFullNameForPersonnel(state, userId)
    : getFullNameForUser(state, userId),
});

const mapDispatchToProps = { fetchItem: findUserById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(UserNameLazy);
