import PropTypes from 'prop-types';
import React from 'react';

import FormatterLayout from '../FormatterLayout';
import { formatWeekTick } from '../utils';

const WeekTickFormatter = ({ date, Layout, ...rest }) => {
  const text = formatWeekTick(date);

  return <Layout text={text} {...rest} />;
};

WeekTickFormatter.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  Layout: PropTypes.func,
};

WeekTickFormatter.defaultProps = {
  Layout: FormatterLayout,
};

export default WeekTickFormatter;
