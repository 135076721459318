import { clamp } from 'ramda';

const moveItem = (arr, item, where) => {
  const delta = where === 'up' ? -1 : 1;
  const index = arr.indexOf(item);

  if (index === -1) {
    return arr;
  }

  const result = [...arr];
  const whereIndex = clamp(0, arr.length - 1, index + delta);
  const tmpItem = arr[whereIndex];

  result[whereIndex] = item;
  result[index] = tmpItem;

  return result;
};

export default moveItem;
