import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';
import { YesNoFormatter } from '@/filters/RadioGroup/utils';

const SUSPICIOUS_TYPE = true;
const NOT_SUSPICIOUS_TYPE = false;

const options = [
  {
    key: SUSPICIOUS_TYPE,
    label: 'Подозрительный',
  },
  {
    key: NOT_SUSPICIOUS_TYPE,
    label: 'Не подозрительный',
  },
];

export const createSuspiciousUserFilter = createRadioFilterTemplate({
  initialValue: SUSPICIOUS_TYPE,
  options,
  FormatComponent: YesNoFormatter({
    yes: SUSPICIOUS_TYPE,
    no: NOT_SUSPICIOUS_TYPE,
  }),
});

export default RadioGroupFilter;
