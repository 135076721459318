import { compose, prop } from 'ramda';

import { createMatchSelector } from '@/redux/router/selectors';

import { ARCHIVE_ROUTE_PATH, ROUTE_PATH } from './consts';
import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getReconciliationError,
  getItemsIds: getReconciliationIds,
  getItemsMeta: getReconciliationMeta,
  getItemsTotalCount: getReconciliationTotalCount,
  isLoadingItems: isLoadingReconciliation,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;

const getParams = createMatchSelector(ROUTE_PATH);

const getArchiveParams = createMatchSelector(ARCHIVE_ROUTE_PATH);

export const getStoreId = compose(prop('store_id'), prop('params'), getParams);

export const getArchiveStoreId = compose(
  prop('store_id'),
  prop('params'),
  getArchiveParams,
);

export const getArchiveId = compose(
  prop('reconciliation_id'),
  prop('params'),
  getArchiveParams,
);
