import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import { LocationMultiAutocomplete } from '@/containers/LocationAutocomplete/';
import Label from '@/features/campaignPage/Page/Label';

const Location = ({ locations }) =>
  locations.length && (
    <FieldRow label="Локация">
      <Label title="Компания сработает в этих локациях">
        <LocationMultiAutocomplete
          multiple
          fullWidth
          disabled
          selectedItem={locations}
        />
      </Label>
    </FieldRow>
  );

Location.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Location;
