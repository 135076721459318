import PropTypes from 'prop-types';
import React from 'react';

import {
  ALLOWED_LOCATION_IDS_KEY,
  NAME_KEY,
  STORE_IDS_KEY,
} from '@/features/personnel/consts/keys';

import ButtonsField from '../ButtonsField';
import LocationsField from '../LocationsField';
import ResetPasswordField from '../ResetPasswordField';
import StoresField from '../StoresField';
import UserNameField from '../UserNameField';

const components = {
  [ALLOWED_LOCATION_IDS_KEY]: LocationsField,
  [NAME_KEY]: UserNameField,
  [STORE_IDS_KEY]: StoresField,
};

const EditForm = ({ Form, fields, ...rest }) => (
  <Form>
    {fields.map(key => {
      const Component = components[key];

      return <Component key={key} {...rest} />;
    })}
    <ResetPasswordField />
    <ButtonsField {...rest} />
  </Form>
);

EditForm.propTypes = {
  Form: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
};

export default EditForm;
