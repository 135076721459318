export default {
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
  },
  linkButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    fontFamily: 'arial, sans-serif',
    color: '#069',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};
