import { addDays, differenceInMonths, differenceInWeeks } from 'date-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Link from '@/components/Link';

import {
  generateMonthlySchedule,
  generateWeeklySchedule,
} from '../../../utils';

class Patterns extends Component {
  static propTypes = {
    initialSendDatetime: PropTypes.instanceOf(Date),
    finalSendDatetime: PropTypes.instanceOf(Date),
    updateTicks: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialSendDatetime: null,
    finalSendDatetime: null,
  };

  fillWithWeeklyPattern = () => {
    const { initialSendDatetime, finalSendDatetime } = this.props;

    const diff = differenceInWeeks(finalSendDatetime, initialSendDatetime);

    this.fillWithPattern(generateWeeklySchedule, diff + 5);
  };

  fillWithMonthlyPattern = () => {
    const { initialSendDatetime, finalSendDatetime } = this.props;
    const diff = differenceInMonths(finalSendDatetime, initialSendDatetime);

    this.fillWithPattern(generateMonthlySchedule, diff + 3);
  };

  fillWithPattern = (creator, diff) => {
    const { initialSendDatetime, finalSendDatetime, updateTicks } = this.props;

    if (!initialSendDatetime || !finalSendDatetime) {
      return;
    }
    updateTicks(creator(addDays(initialSendDatetime, 30), diff));
  };

  render() {
    const { PatternLink } = this;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <PatternLink onClick={this.fillWithWeeklyPattern}>
          Еженедельно
        </PatternLink>
        <PatternLink onClick={this.fillWithMonthlyPattern}>
          Ежемесячно
        </PatternLink>
      </div>
    );
  }

  PatternLink = props => {
    const { classes } = this.props;

    return <Link component="span" className={classes.patternLink} {...props} />;
  };
}

export default Patterns;
