import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';

import Tooltip from './Tooltip';
import DefaultViewport from './Viewport';

class Map extends Component {
  static propTypes = {
    floor: PropTypes.string.isRequired,
    height: PropTypes.any.isRequired,
    lock: PropTypes.bool,
    onOffsetChange: PropTypes.func,
    width: PropTypes.any.isRequired,
    RentPlace: PropTypes.func.isRequired,
    TooltipContent: PropTypes.func.isRequired,
    Viewport: PropTypes.func,
  };

  static defaultProps = {
    lock: false,
    onOffsetChange: () => null,
    Viewport: DefaultViewport,
  };

  state = {
    pointerX: null,
    pointerY: null,
    selectedRentId: null,
  };

  mapRef = createRef();

  render() {
    const {
      floor,
      height,
      lock,
      onOffsetChange,
      width,
      TooltipContent,
      Viewport,
    } = this.props;
    const { pointerX, pointerY, selectedRentId } = this.state;

    return (
      <div ref={this.mapRef}>
        <Viewport
          floor={floor}
          height={height}
          lock={lock}
          onOffsetChange={onOffsetChange}
          selectRentPlace={this.selectRentPlace}
          resetSelectedRentPlace={this.resetSelectedRentPlace}
          width={width}
          RentPlace={this.renderRentPlace}
        />
        {selectedRentId !== null && (
          <Tooltip
            pointerX={pointerX}
            pointerY={pointerY}
            selectedRentId={selectedRentId}
            TooltipContent={TooltipContent}
          />
        )}
      </div>
    );
  }

  getBoundaries = () => {
    const boundaries = this.mapRef.current.getBoundingClientRect();

    return {
      top: boundaries.top,
      left: boundaries.left,
    };
  };

  renderRentPlace = props => {
    const { RentPlace } = this.props;

    return (
      <RentPlace
        {...props}
        onSelect={this.selectRentPlace}
        onDeselect={this.resetSelectedRentPlace}
      />
    );
  };

  selectRentPlace = (selectedRentId, pointerX, pointerY) => {
    const { top, left } = this.getBoundaries();

    this.setState({
      pointerX: pointerX - left,
      pointerY: pointerY - top,
      selectedRentId,
    });
  };

  resetSelectedRentPlace = () => {
    this.setState({
      pointerX: null,
      pointerY: null,
      selectedRentId: null,
    });
  };
}

export default Map;
