import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import {
  getParkingWhitelistFilterError,
  getParkingWhitelistFilterIds,
  getParkingWhitelistFilterTotalCount,
  isLoadingParkingWhitelistFilter,
} from '../selectors';
import ParkingWhitelistFilter from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getParkingWhitelistFilterError(state),
  parkingWhitelistFilterIds: getParkingWhitelistFilterIds(state),
  isLoading: isLoadingParkingWhitelistFilter(state),
  totalCount: getParkingWhitelistFilterTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(ParkingWhitelistFilter);
