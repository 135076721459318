import moment from 'moment';
import { path } from 'ramda';

import api from '@/api';
import { FEATURE_NAME } from '@/features/executiveReportsPage/consts';
import { PAGES_STORE_KEY } from '@/redux/constants';
import saveAs from '@/utils/file-saver';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const downloadXls = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const sendUrlParams = new URLSearchParams();

  const RECORD_GTE_KEY = 'record_created[gte]';
  const RECORD_LTE_KEY = 'record_created[lte]';

  if (urlParams.has(RECORD_GTE_KEY)) {
    sendUrlParams.set(
      'from',
      moment(urlParams.get(RECORD_GTE_KEY)).format('DD-MM-y'),
    );
  }

  if (urlParams.has(RECORD_LTE_KEY)) {
    sendUrlParams.set(
      'to',
      moment(urlParams.get(RECORD_LTE_KEY)).format('DD-MM-y'),
    );
  }

  api
    .doBlobGet(`/mall-stats-xlsx-export?${sendUrlParams.toString()}`)
    .then(response => {
      saveAs(response.data, `Executive reports.xlsx`);
    });
};
