// Metrics
//
// DO NOT USE THESE KEYS FOR FILTERING.
// ONLY DISPLAYING AND SORTING ALLOWED.
//
// If you need fields for filtering, see 'dimensions.js'

export const AVERAGE_CHECK_KEY = 'avg_ticket';

export const AVERAGE_TIME_SPENT = 'avg_time_spent';

export const BUYERS_COUNT_KEY = 'nr_buyers';

export const CONVERSION_RATE_KEY = 'conversion_rate';

export const LOCATION_ID_KEY = 'location_id';

export const PURCHASES_COUNT_KEY = 'nr_purchases';

export const UNIQUE_LOCATION_LEADS_COUNT = 'nr_unique_location_leads';

export const VISITORS_COUNT_KEY = 'nr_visitors';
