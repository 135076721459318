import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import ImageThumb from '@/components/ImageThumb';

import Label from '../Label';

const Description = ({
  titleRu,
  titleEn,
  titleKz,
  subtitleRu,
  subtitleEn,
  subtitleKz,
  descriptionRu,
  descriptionEn,
  descriptionKz,
  images,
  index,
  sku,
  price,
}) => (
  <FieldRow name="Описание Товара">
    <Grid container spacing={16}>
      <Grid item spacing={16} style={{ width: '100%' }}>
        <Label title="Заголовок RU">{titleRu}</Label>
        <Label title="Заголовок EN">{titleEn}</Label>
        <Label title="Заголовок KZ">{titleKz}</Label>
      </Grid>

      <Grid item spacing={16} style={{ width: '100%' }}>
        <Label title="Подзаголовок RU">{subtitleRu}</Label>
        <Label title="Подзаголовок EN">{subtitleEn}</Label>
        <Label title="Подзаголовок KZ">{subtitleKz}</Label>
      </Grid>

      <Grid item spacing={16} style={{ width: '100%' }}>
        <Label title="Описание RU">{descriptionRu}</Label>
        <Label title="Описание EN">{descriptionEn}</Label>
        <Label title="Описание KZ">{descriptionKz}</Label>
      </Grid>

      <Grid item spacing={16} style={{ width: '100%' }}>
        <Label title="Очередность">{index}</Label>

        <Label title="Артикул">{sku}</Label>
        <Label title="Цена">{price}</Label>

        <Label title="Изображения" />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {images.map(image => (
            <ImageThumb url={image} key={image} />
          ))}
        </div>
      </Grid>
    </Grid>
  </FieldRow>
);

Description.propTypes = {
  titleRu: PropTypes.string,
  titleEn: PropTypes.string,
  titleKz: PropTypes.string,

  subtitleRu: PropTypes.string,
  subtitleEn: PropTypes.string,
  subtitleKz: PropTypes.string,

  descriptionRu: PropTypes.string,
  descriptionEn: PropTypes.string,
  descriptionKz: PropTypes.string,

  images: PropTypes.string,
  index: PropTypes.string,
  sku: PropTypes.string,
  price: PropTypes.string,
};

Description.defaultProps = {
  titleRu: null,
  titleEn: null,
  titleKz: null,

  subtitleRu: null,
  subtitleEn: null,
  subtitleKz: null,

  descriptionRu: null,
  descriptionEn: null,
  descriptionKz: null,

  images: null,
  index: null,
  sku: null,
  price: null,
};

export default Description;
