import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import BrandTitle from './BrandTitle';

const BrandsIndiceList = ({ classes, indice, storeBrandsIdsByIndice }) => (
  <div className={classes.indiceBlock}>
    <Typography className={classes.indice} variant="h6">
      {indice}
    </Typography>
    <div className={classes.brandsNameBlock}>
      {storeBrandsIdsByIndice.map(brandId => (
        <BrandTitle key={brandId} brandId={brandId} />
      ))}
    </div>
  </div>
);

BrandsIndiceList.propTypes = {
  indice: PropTypes.string.isRequired,
  storeBrandsIdsByIndice: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default BrandsIndiceList;
