import {
  getCampaignDateEnd,
  getCampaignDateStart,
  getCampaignDateTimeEnd,
  getCampaignDateTimeStart,
  getCampaignSegmentSize,
  getCampaignStatus,
  getCampaignTitle,
  getCampaignType,
} from '@esentai/core/features/campaigns/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: itemId }) => ({
  itemId,
  dateStart: getCampaignDateStart(state, itemId),
  dateTimeStart: getCampaignDateTimeStart(state, itemId),
  dateTimeEnd: getCampaignDateTimeEnd(state, itemId),
  dateEnd: getCampaignDateEnd(state, itemId),
  segmentSize: getCampaignSegmentSize(state, itemId),
  status: getCampaignStatus(state, itemId),
  title: getCampaignTitle(state, itemId),
  type: getCampaignType(state, itemId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
