import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchItems } from '@/features/mapPage/actions';

import Map from './component';

const mapDispatchToProps = { fetchItems };

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'fetchItems' }),
)(Map);
