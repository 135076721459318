import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Bilingual from '@/components/Bilingual';
import FieldRow from '@/components/FieldRow';
import Preview from '@/features/campaignCreatePage/EditPage/SecondStepPurchaseResult/PushForm/Preview';
import Label from '@/features/campaignPage/Page/Label';

class Push extends Component {
  static propTypes = {
    pushEnglishMessage: PropTypes.string,
    pushEnglishTitle: PropTypes.string,
    pushRussianMessage: PropTypes.string,
    pushRussianTitle: PropTypes.string,
    sendingPushNotifications: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    pushEnglishMessage: PropTypes.string,
    pushEnglishTitle: PropTypes.string,
    pushRussianMessage: PropTypes.string,
    pushRussianTitle: PropTypes.string,
  };

  renderPreview = () => {
    const {
      pushEnglishMessage,
      pushEnglishTitle,
      pushRussianMessage,
      pushRussianTitle,
    } = this.props;

    return (
      <Preview
        pushEnglishMessage={pushEnglishMessage}
        pushEnglishTitle={pushEnglishTitle}
        pushRussianMessage={pushRussianMessage}
        pushRussianTitle={pushRussianTitle}
      />
    );
  };

  render() {
    const {
      pushEnglishMessage,
      pushEnglishTitle,
      pushRussianMessage,
      pushRussianTitle,
      sendingPushNotifications,
    } = this.props;

    if (!sendingPushNotifications) {
      return null;
    }

    return (
      <FieldRow label="Пуш-сообщение" Aside={this.renderPreview}>
        {Boolean(pushRussianTitle) && (
          <Label title="Заголовок сообщения">
            <Bilingual
              russianContent={pushRussianTitle}
              englishContent={pushEnglishTitle}
            />
          </Label>
        )}
        {Boolean(pushRussianMessage) && (
          <Label title="Текст сообщения">
            <Bilingual
              russianContent={pushRussianMessage}
              englishContent={pushEnglishMessage}
            />
          </Label>
        )}
      </FieldRow>
    );
  }
}

export default Push;
