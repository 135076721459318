export default {
  withGutter: {
    marginBottom: 16,
  },

  label: {
    fontSize: 12,
  },

  label_title: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  label_desc: {
    color: '#bdbdbd',
    maxWidth: 500,
  },
};
