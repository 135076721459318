import {
  BONUS_NAME_KEY,
  CASH_MACHINES_KEY,
  COMMENT_KEY,
  END_DATE_KEY,
  ONE_TIME_DATETIME_KEY,
  PERCENT_KEY,
  RECONCILIATION_TYPE_KEY,
  START_DATE_KEY,
  STORE_KEY,
} from '@esentai/core/features/storeBonuses/consts/keys';
import { isValid, startOfDay } from 'date-fns';
import { tail } from 'ramda';
import { array, date, number, object, string } from 'yup';

import {
  RECONCILIATION_ONE_TIME_TYPE,
  RECONCILIATION_PERIODIC_TYPE,
  REPEAT_SEND_DATETIMES,
} from '../../consts/keys';

const today = startOfDay(new Date());

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({
    comment,
    startDate,
    endDate,
    percent,
    store,
    cashMachines,
    bonusName,
    reconciliationType,
    oneTimeDatetime,
    sendSchedule,
  }) => ({
    [COMMENT_KEY]: comment,
    [START_DATE_KEY]: startDate,
    [END_DATE_KEY]: endDate,
    [PERCENT_KEY]: percent,
    [STORE_KEY]: store,
    [CASH_MACHINES_KEY]: cashMachines,
    [BONUS_NAME_KEY]: bonusName,
    [RECONCILIATION_TYPE_KEY]: reconciliationType,
    [ONE_TIME_DATETIME_KEY]: oneTimeDatetime,
    [REPEAT_SEND_DATETIMES]: tail(sendSchedule || []),
  }),

  validationSchema: object({
    [COMMENT_KEY]: string()
      .min(10, 'Количество символов меньше допустимого(10)')
      .max(200, 'Количество символов больше допустимого(200)')
      .required(),
    [START_DATE_KEY]: date()
      .min(today, 'Бонус не может начинать действовать в прошедших числах')
      .required(),
    [END_DATE_KEY]: date().when(START_DATE_KEY, (dateStart, schema) => {
      const message = 'Дата завершения не может быть раньше, чем дата начала';

      return isValid(dateStart) ? schema.min(dateStart, message) : schema;
    }),
    [CASH_MACHINES_KEY]: array()
      .of(number())
      .required(),
    [STORE_KEY]: number().required(),
    [PERCENT_KEY]: number()
      .max(40, 'Процент может быть не более 40')
      .required(),
    [BONUS_NAME_KEY]: string().required(),
    [RECONCILIATION_TYPE_KEY]: string().required(),
    [REPEAT_SEND_DATETIMES]: array()
      .of(string())
      .when(RECONCILIATION_TYPE_KEY, {
        is: RECONCILIATION_PERIODIC_TYPE,
        then: array()
          .of(string())
          .required(),
      }),
    [ONE_TIME_DATETIME_KEY]: date().when(RECONCILIATION_TYPE_KEY, {
      is: RECONCILIATION_ONE_TIME_TYPE,
      then: date().required(),
    }),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
