import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { cancelUpload, retryUpload } from '@/features/upload/actions';
import {
  getNameForFile,
  getPreviewForFile,
  getUploadErrorForFile,
  getUploadProgressForFile,
  getURLForFile,
  isFinishedUploadingFile,
  isUploadingFile,
} from '@/features/upload/selectors';

import FileChip from './component';
import styles from './styles';

const mapStateToProps = (state, { fileId }) => ({
  error: getUploadErrorForFile(state, fileId),
  isUploading: isUploadingFile(state, fileId),
  isFinished: isFinishedUploadingFile(state, fileId),
  name: getNameForFile(state, fileId),
  preview: getPreviewForFile(state, fileId),
  progress: getUploadProgressForFile(state, fileId),
  url: getURLForFile(state, fileId),
});

const mapDispatchToProps = { cancelUpload, retryUpload };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(FileChip);
