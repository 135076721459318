import PropTypes from 'prop-types';

import { statusLabel } from '@/features/campaignsPage/labels';

import { daysLeftCaption } from './utils';

const StatusLabel = ({ dateStart, dateEnd, status }) =>
  `${statusLabel(status)} ${daysLeftCaption({ dateStart, dateEnd, status }) ||
    ''}`;

StatusLabel.propTypes = {
  dateStart: PropTypes.instanceOf(Date).isRequired,
  dateEnd: PropTypes.instanceOf(Date).isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusLabel;
