import {
  getBenefitRewardIds,
  getDelayAfterPurchase,
  getDestination,
  getEnabledTriggers,
  getLoyaltyPointsReward,
  getPeriodReceipts,
  getPrePushEnglishMessage,
  getPrePushEnglishTitle,
  getPrePushRussianMessage,
  getPrePushRussianTitle,
  getPrePushSendDate,
  getPushEnglishMessage,
  getPushEnglishTitle,
  getPushRussianMessage,
  getPushRussianTitle,
  getReceiptsCount,
  getReferredArtcleId,
  getReferredBenefitId,
  getReferredCalendarEventId,
  getReferredStoreId,
  getRewardType,
  getSingleReceipt,
  getSingleReceiptItemsCount,
  getTrackedStoreIds,
  hasDelayAfterPurchase,
  hasTriggers,
  shouldActivateOnce,
  shouldSendPrePush,
  shouldSendPushNotifications,
} from '@esentai/core/features/campaigns/purchaseResult/selectors';
import {
  getCampaignDateStart,
  hasCampaignStarted,
} from '@esentai/core/features/campaigns/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepThree } from '@/features/campaignCreatePage/actions';
import { getCampaignId } from '@/features/campaignCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStepArticle from './component';
import form from './form';

const mapStateToProps = state => {
  const id = getCampaignId(state);

  return {
    activateOnce: shouldActivateOnce(state, id),
    benefitRewardIds: getBenefitRewardIds(state, id),
    dateStart: getCampaignDateStart(state, id),
    delayAfterPurchase: getDelayAfterPurchase(state, id),
    destination: getDestination(state, id),
    enabledTriggers: getEnabledTriggers(state, id),
    hasDelayAfterPurchase: hasDelayAfterPurchase(state, id),
    hasTriggers: hasTriggers(state, id),
    loyaltyPointsReward: getLoyaltyPointsReward(state, id),
    periodReceipts: getPeriodReceipts(state, id),
    prePushEnglishTitle: getPrePushEnglishTitle(state, id),
    prePushRussianTitle: getPrePushRussianTitle(state, id),
    prePushEnglishMessage: getPrePushEnglishMessage(state, id),
    prePushRussianMessage: getPrePushRussianMessage(state, id),
    prePushSendDate: getPrePushSendDate(state, id),
    pushEnglishTitle: getPushEnglishTitle(state, id),
    pushRussianTitle: getPushRussianTitle(state, id),
    pushEnglishMessage: getPushEnglishMessage(state, id),
    pushRussianMessage: getPushRussianMessage(state, id),
    receiptsCount: getReceiptsCount(state, id),
    referredArtcleId: getReferredArtcleId(state, id),
    referredBenefitId: getReferredBenefitId(state, id),
    referredStoreId: getReferredStoreId(state, id),
    referredCalendarEventId: getReferredCalendarEventId(state, id),
    sendPrePush: shouldSendPrePush(state, id),
    rewardType: getRewardType(state, id),
    sendPushNotifications: shouldSendPushNotifications(state, id),
    singleReceipt: getSingleReceipt(state, id),
    singleReceiptItemsCount: getSingleReceiptItemsCount(state, id),
    trackedStoreIds: getTrackedStoreIds(state, id),
    campaignStarted: hasCampaignStarted(state, id),
    id,
  };
};

const mapDispatchToProps = { onSubmit: goToStepThree };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
)(SecondStepArticle);
