import { TRIGGER_KEY } from '@esentai/core/features/campaigns/actionResult/consts/keys';
import {
  BOUNCE_TRIGGER,
  LEAD_TRIGGER,
  OPEN_APP_TRIGGER,
  REGISTRATION_TRIGGER,
  VISITOR_TRIGGER,
} from '@esentai/core/features/campaigns/actionResult/consts/triggers';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FieldRow from '@/components/FieldRow';
import RadioTile, { RadioBoxGroup } from '@/components/RadioTile';

import { CanEditRadioBoxGroup } from '../../CanEdit';

class DestinationForm extends PureComponent {
  static propTypes = {
    trigger: PropTypes.any.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  };

  handleTriggersChange = (_, trigger) => this.setTrigger(trigger);

  setTrigger = value => {
    const { setFieldValue } = this.props;

    setFieldValue(TRIGGER_KEY, value);
  };

  render() {
    const { trigger } = this.props;

    return (
      <FieldRow
        label="Триггер"
        subLabel="Тип триггера"
        sudDesc="Укажите действие, которое нужно будет совершить пользователю, чтобы получить пуш-сообщение или бонус."
      >
        <CanEditRadioBoxGroup field={TRIGGER_KEY}>
          <RadioBoxGroup value={trigger} onChange={this.handleTriggersChange}>
            <RadioTile
              value={LEAD_TRIGGER}
              label="Лид"
              helperText="Триггер активируется, когда пользователь прошел мимо установленной локации."
            />
            <RadioTile
              value={VISITOR_TRIGGER}
              label="Посетитель"
              helperText="Триггер активируется, когда пользователь вошел в установленную локацию."
            />
            <RadioTile
              value={BOUNCE_TRIGGER}
              label="Отказ"
              helperText="Триггер активируется, когда посещение пользователем локации было обозначено отказом."
            />
            <RadioTile
              value={REGISTRATION_TRIGGER}
              label="Регистрация в приложении"
              helperText="Триггер активируется, когда пользователь регистрируется в мобильном приложении."
            />
            <RadioTile
              value={OPEN_APP_TRIGGER}
              label="Открыто приложение"
              helperText="Триггер активируется, когда пользователь открывает мобильное приложение."
            />
          </RadioBoxGroup>
        </CanEditRadioBoxGroup>
      </FieldRow>
    );
  }
}

export default DestinationForm;
