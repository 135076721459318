import { Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const TooltipContent = ({ classes, rentId, rentPlaceName }) => (
  <Paper square className={classes.root}>
    <Typography variant="h5">{rentPlaceName}</Typography>
    <Typography variant="caption">Место на карте: {rentId}</Typography>
  </Paper>
);

TooltipContent.propTypes = {
  rentId: PropTypes.string.isRequired,
  rentPlaceName: PropTypes.string.isRequired,
};

export default TooltipContent;
