import React from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import { ROUTE_PATH as PERSONNEL_PATH } from '@/features/personnelPage/consts';

import EditForm from './EditForm';
import LazyEditForm from './LazyEditForm';

const EditProfile = () => (
  <Page>
    <PageHeader gutterBottom={false}>
      <Breadcrumbs>
        <Breadcrumb to={PERSONNEL_PATH}>Персонал</Breadcrumb>
      </Breadcrumbs>
      <BackButton />
      <PageTitle>Профиль пользователя</PageTitle>
    </PageHeader>
    <PageContent>
      <PaperSheet>
        <LazyEditForm>
          <EditForm />
        </LazyEditForm>
      </PaperSheet>
    </PageContent>
  </Page>
);

export default EditProfile;
