import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import LazyEditForm from '@/components/LazyEditForm';
import { findPersonalityById } from '@/features/personnel/actions';
import {
  getPersonnelLoadingError,
  isLoadedPersonality,
  isLoadingPersonality,
} from '@/features/personnel/selectors';
import { getPersonnelId } from '@/features/personnelEditPage/selectors';

const mapStateToProps = state => ({
  itemId: getPersonnelId(state),
});

export default compose(
  connect(mapStateToProps),
  defaultProps({
    fetchItem: findPersonalityById,
    getItemLoadingError: getPersonnelLoadingError,
    isItemLoaded: isLoadedPersonality,
    isItemLoading: isLoadingPersonality,
  }),
)(LazyEditForm);
