import { toggleActivity } from '@esentai/core/features/users/actions';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch, { userId }) => ({
  toggleActivity: () => dispatch(toggleActivity(userId)),
});

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncRunner({
    runnerMethod: 'toggleActivity',
  }),
);
