import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MenuItem from './MenuItem';

class Sidebar extends Component {
  render() {
    const { sections, classes, expanded } = this.props;

    return (
      <Drawer
        className={classes.fullHeight}
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, {
            [classes.expanded]: expanded,
          }),
        }}
      >
        <List component="nav">
          {sections.map(section => (
            <MenuItem {...section} expanded={expanded} key={section.label} />
          ))}
        </List>
      </Drawer>
    );
  }
}

Sidebar.propTypes = {
  sections: PropTypes.array.isRequired,
  location: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
};

Sidebar.defaultProps = {
  location: null,
};

export default Sidebar;
