import { IconButton, Typography } from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import Field from '../PersonalInfo/Field';

const carLabels = [
  '1-ая добавленная машина',
  '2-ая добавленная машина',
  '3-яя добавленная машина',
];

const RegisteredCarsInfo = ({
  classes,
  cars,
  handleToggleEditModal,
  handleToggleDeleteModal,
  handleToggleAddModal,
  isMallManager,
}) => {
  const getCarInfo = car =>
    car ? `${car.vehicle_model} ${car.vehicle_gov_number}` : '-';

  return (
    <div className={classes.wrapper}>
      <Typography
        gutterBottom
        color="textSecondary"
        variant="h5"
        className={classes.title}
      >
        Автомобили
      </Typography>
      <div className={classes.cars}>
        {carLabels.map((label, index) => {
          const car = cars[index];

          return (
            <div
              className={classes.fieldContainer}
              key={`${car && car.id}-${label}`}
            >
              <Field label={label} className={classes.car}>
                {getCarInfo(car)}
              </Field>
              {isMallManager && (
                <div className={classes.buttonContainer}>
                  {car ? (
                    <>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleToggleEditModal(car)}
                        size="small"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleToggleDeleteModal(car)}
                        size="small"
                        color="inherit"
                        variant="outlined"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleToggleAddModal()}
                      size="small"
                      color="inherit"
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

RegisteredCarsInfo.propTypes = {
  cars: PropTypes.array.isRequired,
  handleToggleEditModal: PropTypes.func.isRequired,
  handleToggleDeleteModal: PropTypes.func.isRequired,
  handleToggleAddModal: PropTypes.func.isRequired,
  isMallManager: PropTypes.bool.isRequired,
};

export default RegisteredCarsInfo;
