import React, { Fragment } from 'react';

import Banner from './Banner';
import Layout from './Layout';

const ArticleContent = props => (
  <Fragment>
    <Banner {...props} />
    <Layout {...props} />
  </Fragment>
);

export default ArticleContent;
