import DefaultMapTransformer from '@esentai/core/components/MapTransformer';
import Traverser from '@esentai/core/components/Traverser';
import React from 'react';

import Beacons from '@/features/mapPage/Page/MapWorkspace/Beacons';

// eslint-disable-next-line react/prop-types
const injectBeacons = ({ props, type: Component }) => {
  if (Component === 'svg') {
    return (
      <Component {...props}>
        {props.children}
        <Beacons />
      </Component>
    );
  }

  return <Component {...props} />;
};

const SvgTransformer = props => (
  <Traverser traverseNode={injectBeacons}>
    <DefaultMapTransformer {...props} />
  </Traverser>
);

export default SvgTransformer;
