import applicationSettingsResource from '@esentai/core/features/applicationSettings/api';
import beaconsResource from '@esentai/core/features/beacons/api';
import benefitItemsResource from '@esentai/core/features/benefitItems/api';
import benefitsResource from '@esentai/core/features/benefits/api';
import bonusTransactionsResource from '@esentai/core/features/bonus_transactions/api';
import brandsResource from '@esentai/core/features/brands/api';
import campaignsResource from '@esentai/core/features/campaigns/api';
import creditCardsResource from '@esentai/core/features/creditCards/api';
import efsVisitRecords from '@esentai/core/features/efsVisitRecords/api';
import giftSetsResource from '@esentai/core/features/giftSets/api';
import historyDataResource from '@esentai/core/features/history_data/api';
import kkmsResource from '@esentai/core/features/kkms/api';
import locationsResource from '@esentai/core/features/locations/api';
import loyaltiesResource from '@esentai/core/features/loyalties/api';
import mallBonusesResource from '@esentai/core/features/mallBonuses/api';
import mallStatsResource from '@esentai/core/features/mallStats/api';
import merchantsResource from '@esentai/core/features/merchants/api';
import merchantsReconciliationResource from '@esentai/core/features/merchantsReconciliation/api';
import merchantsReconciliationArchiveResource from '@esentai/core/features/merchantsReconciliationArchive/api';
import merchantsReconciliationHistoryResource from '@esentai/core/features/merchantsReconciliationHistory/api';
import merchantsReconciliationPaymentResource from '@esentai/core/features/merchantsReconciliationPayment/api';
import merchantsReconciliationReceiptsResource from '@esentai/core/features/merchantsReconciliationReceipts/api';
import merchantsReconciliationRefundsResource from '@esentai/core/features/merchantsReconciliationRefunds/api';
import parkingLevelThreeWhitelistListResource from '@esentai/core/features/parkingLevelThreeWhitelist/api';
import parkingTowerStopListResource from '@esentai/core/features/parkingTowerStopList/api';
import parkingWhitelistResource from '@esentai/core/features/parkingWhitelist/api';
import parkingWhitelistFilterResource from '@esentai/core/features/parkingWhitelistFilter/api';
import parkingWhitelistGroupsResource from '@esentai/core/features/parkingWhitelistGroups/api';
import productCategoriesResource from '@esentai/core/features/productCategories/api';
import productCategoryResource from '@esentai/core/features/productCategory/api';
import productsResource from '@esentai/core/features/products/api';
import productViewResource from '@esentai/core/features/productView/api';
import purchasesResource from '@esentai/core/features/purchases/api';
import questionsResource from '@esentai/core/features/questions/api';
import receiptsResource from '@esentai/core/features/receipts/api';
import saleResource from '@esentai/core/features/sale/api';
import salesTurnoverDetailsResource from '@esentai/core/features/salesTurnoverDetails/api';
import storeBonusActiveReceiptResource from '@esentai/core/features/storeBonusActiveReceiptDetails/api';
import storeBonusCmResource from '@esentai/core/features/storeBonusCmDetails/api';
import storeBonusesResource from '@esentai/core/features/storeBonuses/api';
import storeBonusPendingReceiptResource from '@esentai/core/features/storeBonusPendingReceiptDetails/api';
import storeBonusRepaymentResource from '@esentai/core/features/storeBonusRepaymentDetails/api';
import storesResource from '@esentai/core/features/stores/api';
import usersResource from '@esentai/core/features/users/api';

import authResource from '@/features/auth/api';
import bonusesResource from '@/features/bonusesHistoryPage/api';
import campaignsStatisticsResource from '@/features/campaignsStatistics/api';
import giftCertificatesResource from '@/features/giftCertificatesPage/api';
import invitesResource from '@/features/invites/api';
import locationsStatisticsResource from '@/features/locationsStatistics/api';
import loyaltyHistoryResource from '@/features/loyaltyHistoryPage/api';
import passwordRecoveryResource from '@/features/passwordRecovery/api';
import personnelResource from '@/features/personnel/api';
import purchasesStatisticsResource from '@/features/purchasesStatistics/api';
import reconciliationArchive from '@/features/reconciliationArchivePage/api';
import reconciliationArchiveReceiptsResource from '@/features/reconciliationArchiveReceiptsPage/api';
import reconciliationArchiveRefundsResource from '@/features/reconciliationArchiveRefundsPage/api';
import reconciliationHistoryResource from '@/features/reconciliationHistoryPage/api';
import reconciliationResource from '@/features/reconciliationPage/api';
import reconciliationPeriodDetailsResource from '@/features/reconciliationPeriodBonusDetailsPage/api';
import reconciliationReceiptBonusDetailsResource from '@/features/reconciliationReceiptBonusDetailsPage/api';
import reconciliationReceiptsResource from '@/features/reconciliationReceiptsPage/api';
import reconciliationRefundsResource from '@/features/reconciliationRefundsPage/api';
import reconciliationStoreBonusDetailsResource from '@/features/reconciliationStoreBonusDetailsPage/api';
import reconciliationStoreToCpResource from '@/features/reconciliationStoreToCpPage/api';
import salesTurnoversResource from '@/features/salesTurnoversPage/api';
import uploadResource from '@/features/upload/api';
import usersStatisticsResource from '@/features/usersStatistics/api';

export default [
  applicationSettingsResource,
  authResource,
  beaconsResource,
  benefitItemsResource,
  benefitsResource,
  bonusTransactionsResource,
  bonusesResource,
  brandsResource,
  campaignsResource,
  campaignsStatisticsResource,
  creditCardsResource,
  efsVisitRecords,
  giftCertificatesResource,
  giftSetsResource,
  historyDataResource,
  invitesResource,
  kkmsResource,
  locationsResource,
  locationsStatisticsResource,
  loyaltiesResource,
  loyaltyHistoryResource,
  mallBonusesResource,
  mallStatsResource,
  merchantsReconciliationArchiveResource,
  merchantsReconciliationHistoryResource,
  merchantsReconciliationPaymentResource,
  merchantsReconciliationReceiptsResource,
  merchantsReconciliationRefundsResource,
  merchantsReconciliationResource,
  merchantsResource,
  parkingLevelThreeWhitelistListResource,
  parkingTowerStopListResource,
  parkingWhitelistFilterResource,
  parkingWhitelistGroupsResource,
  parkingWhitelistResource,
  passwordRecoveryResource,
  personnelResource,
  productCategoriesResource,
  productCategoryResource,
  productViewResource,
  productsResource,
  purchasesResource,
  purchasesStatisticsResource,
  questionsResource,
  receiptsResource,
  reconciliationArchive,
  reconciliationArchiveReceiptsResource,
  reconciliationArchiveRefundsResource,
  reconciliationHistoryResource,
  reconciliationPeriodDetailsResource,
  reconciliationReceiptBonusDetailsResource,
  reconciliationReceiptsResource,
  reconciliationRefundsResource,
  reconciliationResource,
  reconciliationStoreBonusDetailsResource,
  reconciliationStoreToCpResource,
  saleResource,
  salesTurnoverDetailsResource,
  salesTurnoversResource,
  storeBonusActiveReceiptResource,
  storeBonusCmResource,
  storeBonusPendingReceiptResource,
  storeBonusRepaymentResource,
  storeBonusesResource,
  storesResource,
  uploadResource,
  usersResource,
  usersStatisticsResource,
];
