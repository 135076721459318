import PropTypes from 'prop-types';
import React from 'react';

import MerchantBinLazy from '@/containers/MerchantBinLazy';

const FormatComponent = ({ value }) => <MerchantBinLazy locationId={value} />;

FormatComponent.propTypes = {
  value: PropTypes.string.isRequired,
};

export default FormatComponent;
