import { AccountBox } from '@material-ui/icons';

import ChartBar from '@/components/Icon/ChartBar';
import Target from '@/components/Icon/Target';
import { ROUTE_PATH as BENEFITS_PATH } from '@/features/benefitsPage/consts';
import { ROUTE_PATH as CAMPAIGNS_PATH } from '@/features/campaignsPage/consts';
import { ROUTE_PATH as PERSONNEL_PATH } from '@/features/personnelPage/consts';
import { getEntityPageUrl } from '@/features/statisticsPage/utils';

export const defaultMenu = (isStoreStaff = false) => [
  {
    label: 'Статистика',
    path: getEntityPageUrl('users'),
    Icon: ChartBar,
    alias: [getEntityPageUrl('purchases')],
  },
  {
    label: isStoreStaff ? 'Бенефиты' : 'Кампании',
    path: isStoreStaff ? BENEFITS_PATH : CAMPAIGNS_PATH,
    Icon: Target,
    alias: [BENEFITS_PATH],
  },
  {
    label: 'Персонал',
    path: PERSONNEL_PATH,
    Icon: AccountBox,
  },
];
