import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MenuItemButton from './MenuItemButton';

class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  isExpandable = false;

  handleClick() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  renderRoot() {
    const { label, path, Icon, classes, expanded } = this.props;

    return (
      <MenuItemButton link={path} onClick={() => this.handleClick()}>
        {expanded && (
          <Tooltip title={label}>
            {Boolean(Icon) && (
              <ListItemIcon classes={{ root: classes.icon }}>
                <Icon />
              </ListItemIcon>
            )}
          </Tooltip>
        )}

        {!expanded && (
          <>
            {Boolean(Icon) && (
              <ListItemIcon classes={{ root: classes.icon }}>
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText
              primary={label}
              inset={!Icon}
              classes={{ primary: classes.text }}
            />
          </>
        )}
        {this.isExpandable && !this.state.isOpen && <IconExpandMore />}
        {this.isExpandable && this.state.isOpen && <IconExpandLess />}
      </MenuItemButton>
    );
  }

  renderChildren() {
    const { items, classes, expanded } = this.props;

    return (
      this.isExpandable && (
        <Collapse in={this.state.isOpen} timeout="auto" unmountOnExit>
          <Divider />
          <List
            component="div"
            disablePadding
            classes={{ root: classes.submenu }}
          >
            {items.map(item => (
              <MenuItem
                {...item}
                key={item.label}
                classes={classes}
                expanded={expanded}
              />
            ))}
          </List>
        </Collapse>
      )
    );
  }

  render() {
    const { items } = this.props;

    this.isExpandable = items && items.length > 0;

    return (
      <>
        {this.renderRoot()}
        {this.renderChildren()}
      </>
    );
  }
}

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  path: PropTypes.string,
  items: PropTypes.array,
  expanded: PropTypes.bool.isRequired,
};

MenuItem.defaultProps = {
  Icon: undefined,
  path: '',
  items: [],
};

export default MenuItem;
