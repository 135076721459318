import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';

const placementFormatter = {
  top: 'Первый блок',
  bottom: 'Второй блок',
};

const Placement = ({ placement, classes }) => (
  <FieldRow label="Размещение">
    <Typography className={classes.placementText} color="inherit">
      {placementFormatter[placement]}
    </Typography>
  </FieldRow>
);

Placement.propTypes = {
  placement: PropTypes.string,
};

Placement.defaultProps = {
  placement: 'top',
};

export default Placement;
