import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

// import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import VisibilityButton from './VisibilityButton';

const Actions = ({ classes, productId }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <EditButton productId={productId} />
    <VisibilityButton itemId={productId} />
    {/* <DeleteButton productId={productId} /> */}
  </Grid>
);

Actions.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default Actions;
