import { filter, gte, lte } from '@esentai/core/query-dsl';
import { subDays } from 'date-fns';

import { MAX_STATISTICS_DATE } from './dates';

const today = new Date();
const twoWeeksAgo = subDays(today, 14);

export const defaultDateRange = filter(
  gte(twoWeeksAgo),
  lte(MAX_STATISTICS_DATE),
);
