import {
  COMMENT_KEY,
  CUSTOM_TOKEN_KEY,
  DISCOUNT_PERCENT_KEY,
  END_DATETIME_KEY,
  ENGLISH_DESCRIPTION_KEY,
  KAZAKH_DESCRIPTION_KEY,
  RUSSIAN_DESCRIPTION_KEY,
  START_DATETIME_KEY,
  STORE_ID_KEY,
  TITLE_EN_KEY,
  TITLE_KEY,
  TITLE_KZ_KEY,
  TYPE_KEY,
} from '@esentai/core/features/benefits/consts/keys';
import { FOOD_AND_DRINKS_TYPE } from '@esentai/core/features/benefits/consts/types';
import { isValid, startOfDay } from 'date-fns';
import { date, number, object, string } from 'yup';

const today = startOfDay(new Date());

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({
    comment,
    dateStart,
    dateEnd,
    storeId,
    title,
    titleEn,
    titleKz,
    type,
    russianDescription,
    englishDescription,
    kazakhDescription,
    percent,
    customToken,
  }) => ({
    [COMMENT_KEY]: comment,
    [START_DATETIME_KEY]: dateStart || null,
    [END_DATETIME_KEY]: dateEnd || null,
    [STORE_ID_KEY]: storeId,

    [TITLE_KEY]: title || '',
    [TITLE_EN_KEY]: titleEn || '',
    [TITLE_KZ_KEY]: titleKz || '',

    [TYPE_KEY]: type || FOOD_AND_DRINKS_TYPE,

    [RUSSIAN_DESCRIPTION_KEY]: russianDescription,
    [ENGLISH_DESCRIPTION_KEY]: englishDescription,
    [KAZAKH_DESCRIPTION_KEY]: kazakhDescription,

    [DISCOUNT_PERCENT_KEY]: percent,
    [CUSTOM_TOKEN_KEY]: customToken,
  }),

  validationSchema: object({
    [COMMENT_KEY]: string().required(),
    [START_DATETIME_KEY]: date()
      .min(today, 'Бенефит не может начинать действовать в прошедших числах')
      .required(),
    [END_DATETIME_KEY]: date().when(START_DATETIME_KEY, (dateStart, schema) => {
      const message = 'Дата завершения не может быть раньше, чем дата начала';

      return isValid(dateStart) ? schema.min(dateStart, message) : schema;
    }),
    [STORE_ID_KEY]: number().required(),
    [TITLE_KEY]: string().required(),

    [TYPE_KEY]: string(),
    [TITLE_EN_KEY]: string().required(),
    [TITLE_KZ_KEY]: string().required(),

    [ENGLISH_DESCRIPTION_KEY]: string().required(),
    [RUSSIAN_DESCRIPTION_KEY]: string().required(),
    [KAZAKH_DESCRIPTION_KEY]: string().required(),

    [DISCOUNT_PERCENT_KEY]: number()
      .max(100, 'Процент может быть не более 100')
      .required(),
    [CUSTOM_TOKEN_KEY]: string(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
