import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import {
  ACTIVE_BONUSES_KEY,
  BONUS_PERCENTAGE_KEY,
  CUSTOMER_ID_KEY,
  ID_KEY,
  ISSUED_AT_DATETIME_KEY,
  KKM_KEY,
  LEVEL_KEY,
  PENDING_BONUSES_KEY,
  RECORD_CREATED_KEY,
  REFUND_DATETIME_KEY,
  STAFF_FULLNAME_KEY,
  STORE_NAME_KEY,
  TIMESTAMP_KEY,
  TOTAL_COST_KEY,
  UUID_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';

const HeadRow = ({ SortControl, ...props }) => {
  const [sortFields, setSortFields] = useState({
    [ID_KEY]: [{}],
    [CUSTOMER_ID_KEY]: [{}],
    [LEVEL_KEY]: [{}],
    [KKM_KEY]: [{}],
    [UUID_KEY]: [{}],
    [STORE_NAME_KEY]: [{}],
    [ISSUED_AT_DATETIME_KEY]: [{}],
    [TOTAL_COST_KEY]: [{}],
    [TIMESTAMP_KEY]: [{}],
    [BONUS_PERCENTAGE_KEY]: [{}],
    [PENDING_BONUSES_KEY]: [{}],
    [RECORD_CREATED_KEY]: [{}],
    [STAFF_FULLNAME_KEY]: [{}],
    [REFUND_DATETIME_KEY]: [{}],
    [ACTIVE_BONUSES_KEY]: [{}],
  });

  const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  const currentUrlParams = new URLSearchParams(window.location.search);

  const changeFilter = fieldName => {
    Object.keys(initialSortFields).forEach(field => {
      initialSortFields[field] = [{}];
    });

    const changedField = fieldName[0];

    initialSortFields[changedField.field] = [changedField];

    if (changedField.direction === 'asc') {
      currentUrlParams.set('sort', `${changedField.field}`);
    } else {
      currentUrlParams.set('sort', `-${changedField.field}`);
    }

    props.history.push({
      pathname: '/purchases-without-scan',
      search: `?${decodeURIComponent(currentUrlParams.toString())}`,
    });

    setSortFields(initialSortFields);
  };

  useEffect(() => {
    const field = new URLSearchParams(window.location.search).get('sort');

    if (field) {
      const fieldName = field.replace(/-/g, '');

      initialSortFields[fieldName] = [
        {
          field: fieldName,
          direction: field.indexOf('-') !== -1 ? 'desc' : 'asc',
        },
      ];
      setSortFields(initialSortFields);
    }
  }, []);

  return (
    <TableRow>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ID_KEY]}
          field={ID_KEY}
          setSort={changeFilter}
        >
          ID покупки
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[CUSTOMER_ID_KEY]}
          field={CUSTOMER_ID_KEY}
          setSort={changeFilter}
        >
          ID покупателя
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[LEVEL_KEY]}
          field={LEVEL_KEY}
          setSort={changeFilter}
        >
          Уровень лояльности покупателя
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[KKM_KEY]}
          field={KKM_KEY}
          setSort={changeFilter}
        >
          Номер ККМ
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[UUID_KEY]}
          field={UUID_KEY}
          setSort={changeFilter}
        >
          UUID
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[STORE_NAME_KEY]}
          field={STORE_NAME_KEY}
          setSort={changeFilter}
        >
          Магазин
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[TIMESTAMP_KEY]}
          field={TIMESTAMP_KEY}
          setSort={changeFilter}
        >
          Дата и время покупки
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[TOTAL_COST_KEY]}
          field={TOTAL_COST_KEY}
          setSort={changeFilter}
        >
          Общая сумма покупки
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[BONUS_PERCENTAGE_KEY]}
          field={BONUS_PERCENTAGE_KEY}
          setSort={changeFilter}
        >
          Процент начисления бонуса
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[PENDING_BONUSES_KEY]}
          field={PENDING_BONUSES_KEY}
          setSort={changeFilter}
        >
          Сумма бонусов к начислению
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ACTIVE_BONUSES_KEY]}
          field={ACTIVE_BONUSES_KEY}
          setSort={changeFilter}
        >
          Сумма начисленных бонусов
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[RECORD_CREATED_KEY]}
          field={RECORD_CREATED_KEY}
          setSort={changeFilter}
        >
          Дата и время добавления покупки (Менеджер молла)
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[STAFF_FULLNAME_KEY]}
          field={STAFF_FULLNAME_KEY}
          setSort={changeFilter}
        >
          Добавлено (Менеджер молла)
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[REFUND_DATETIME_KEY]}
          field={REFUND_DATETIME_KEY}
          setSort={changeFilter}
        >
          Дата и время возврата (Менеджер молла)
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>Файл</CondenseTableCell>
      <CondenseTableCell>Возврат</CondenseTableCell>
    </TableRow>
  );
};

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default HeadRow;
