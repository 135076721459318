import { IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

export default class ToolbarSelect extends React.Component {
  static propTypes = {
    selectedRows: PropTypes.object,
    displayData: PropTypes.array,
    setSelectedRows: PropTypes.func.isRequired,
    handleAddWhitelist: PropTypes.func.isRequired,
    // handleRemoveWhitelist: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedRows: { data: [] },
    displayData: [],
    setSelectedRows: () => {},
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title="Добавить в список">
          <IconButton
            className={classes.iconButton}
            onClick={this.props.handleAddWhitelist}
          >
            <Add className={classes.icon} />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Убрать из списка">*/}
        {/*  <IconButton*/}
        {/*    className={classes.iconButton}*/}
        {/*    onClick={this.props.handleRemoveWhitelist}*/}
        {/*  >*/}
        {/*    <Remove className={classes.icon} />*/}
        {/*  </IconButton>*/}
        {/* </Tooltip>*/}
      </div>
    );
  }
}
