export default {
  count: {
    color: '#BDBDBD',
    fontSize: 12,
    textAlign: 'center',
    marginRight: 12,
  },

  orderSwitcher: {
    position: 'absolute',
    left: -46,
    top: 0,
  },
};
