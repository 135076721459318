import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddLink from '@/components/AddLink';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageSubtitle from '@/components/PageSubtitle';
import PageTitle from '@/components/PageTitle';
import { downloadXls } from '@/features/benefitItemsPage/utils';

// import PageTitleLink from '@/components/PageTitleLink';
// import { createCampaignsPageURL } from '@/features/campaignsPage/utils';
import { ROUTE_PATH } from '../consts';
import Table from './Table';

class Campaigns extends Component {
  static propTypes = {
    error: PropTypes.any,
    isLoading: PropTypes.bool.isRequired,
    itemsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    totalCount: PropTypes.number.isRequired,
    canCreateBenefit: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const {
      error,
      isLoading,
      itemsIds,
      totalCount,
      canCreateBenefit,
      classes,
    } = this.props;

    const classList = (...list) => list.filter(item => Boolean(item)).join(' ');

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <PageTitle gutterBottom={false}>Архив по купонам</PageTitle>
            </Grid>
            {canCreateBenefit && (
              <Grid item>
                <AddLink to={`${ROUTE_PATH}/new`} />
              </Grid>
            )}
          </Grid>
          <PageSubtitle>Вспомогательный текст</PageSubtitle>
        </PageHeader>
        <PageContent>
          <div
            className={classList(
              classes.buttonsWrapper,
              canCreateBenefit && classes.rightSpacing,
            )}
          >
            <Button
              variant="outlined"
              color="default"
              size="small"
              className={classes.button}
              onClick={downloadXls}
            >
              Вывести в EXCEL
            </Button>
          </div>
          <Table
            error={error}
            isLoading={isLoading}
            items={itemsIds}
            totalCount={totalCount}
          />
        </PageContent>
      </Page>
    );
  }
}

export default Campaigns;
