import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { CATEGORY_KEY } from '@esentai/core/features/productView/consts/keys';
import {
  create,
  decreasePriority,
  increasePriority,
  remove,
  update,
} from '@esentai/core/features/productView/sagas';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import { ROUTE_PATH } from '@/features/productsViewPage/consts';
import { createMatchSelector } from '@/redux/router/selectors';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import {
  CREATE_VIEW,
  DELETE_VIEW,
  MOVE_VIEW_DOWN,
  MOVE_VIEW_UP,
  RETRY_FETCH,
  UPDATE_VIEW,
} from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

const { getQuery } = queryDuck.selectors;

export const fetchView = function*() {
  const { params } = yield select(createMatchSelector({ path: ROUTE_PATH }));
  const { categoryId } = params;

  const query = yield select(getQuery);
  const newQuery = mergeQueriesRight(
    query,
    makeQuery(where(CATEGORY_KEY, equals(categoryId))),
  );

  yield call(itemsDuck.sagas.fetchItems, newQuery);
};

export const fetchItems = createQuerySaga(fetchView);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* deleteView(action) {
  const { payload: viewId } = action;

  yield call(remove, viewId);
  yield call(fetchItems);
}

export function* moveViewDown(action) {
  yield call(decreasePriority, action);
  yield call(fetchItems);
}

export function* moveViewUp(action) {
  yield call(increasePriority, action);
  yield call(fetchItems);
}

export function* createView(action) {
  const { payload: attributes } = action;

  yield call(create, attributes);
  yield call(fetchItems);
}

export function* updateView(action) {
  const { payload: attributes } = action;

  yield call(update, null, attributes);
  yield call(fetchItems);
}

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield takeEvery(CREATE_VIEW, createTaskSaga(createView));
  yield takeEvery(UPDATE_VIEW, createTaskSaga(updateView));
  yield takeEvery(DELETE_VIEW, createTaskSaga(deleteView));
  yield takeEvery(MOVE_VIEW_DOWN, createTaskSaga(moveViewDown));
  yield takeEvery(MOVE_VIEW_UP, createTaskSaga(moveViewUp));
}
