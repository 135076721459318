import {
  DATE_CREATION_KEY,
  NAME_KEY,
} from '@esentai/core/features/stores/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';
import { canUpdateStore } from '@/features/storesPage/permissions';

const HeadRow = ({ GlobalSelectionToggle, SortControl }) => (
  <TableRow>
    <TableCell padding="checkbox">
      <GlobalSelectionToggle />
    </TableCell>

    <CondenseTableCell>
      <SortControl field={NAME_KEY}>Название</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Категория</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={DATE_CREATION_KEY}>Дата создания</SortControl>
    </CondenseTableCell>

    <CanView permission={canUpdateStore}>
      <CondenseTableCell>Действия</CondenseTableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  GlobalSelectionToggle: PropTypes.func.isRequired,
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
