import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getGiftSetId } from '../../selectors';
import Actions from './component';
import styles from './styles';

const mapStateToProps = state => ({
  giftSetId: getGiftSetId(state),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Actions);
