import { pause } from '@esentai/core/features/benefits/actions';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import DeactivateButton from '@/components/ActionButtons/Deactivate';
import { canUpdateBenefit } from '@/features/benefitsPage/permissions';

const mapStateToProps = () => ({
  permission: canUpdateBenefit,
});

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(pause(itemId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(DeactivateButton);
