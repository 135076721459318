import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { WhitelistGroupMultiAutocomplete } from '@/containers/WhitelistGroupAutocomplete';

class WhitelistGroupSearchInputs extends Component {
  render() {
    const { onChange, value } = this.props;

    return (
      <WhitelistGroupMultiAutocomplete
        autoFocus
        placeholder="Введите ID"
        selectedItem={value}
        onChange={onChange}
      />
    );
  }
}

WhitelistGroupSearchInputs.defaultProps = {
  value: [],
};

WhitelistGroupSearchInputs.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default WhitelistGroupSearchInputs;
