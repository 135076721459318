import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import sizeMe from 'react-sizeme';
import { mapProps } from 'recompose';

import AreaChart from './component';
import styles from './styles';

export default compose(
  sizeMe(),
  withStyles(styles),
  mapProps(({ size, ...rest }) => ({
    width: size.width,
    ...rest,
  })),
)(AreaChart);
