import {
  BLACK_STATUS,
  JET_BLACK_STATUS,
  ORANGE_STATUS,
} from '@esentai/core/features/users/consts/statuses';

import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';
import { userStatus } from '@/utils/labels';

export const options = [ORANGE_STATUS, BLACK_STATUS, JET_BLACK_STATUS].map(
  key => ({
    key,
    label: userStatus(key),
  }),
);

export const createUserStatusFilter = createCheckboxFilterTemplate({
  options,
});

export default CheckboxGroupFilter;
