import {
  COMMENT_KEY,
  CUSTOM_TOKEN_KEY,
  DISCOUNT_PERCENT_KEY,
  END_DATETIME_KEY,
  ENGLISH_DESCRIPTION_KEY,
  KAZAKH_DESCRIPTION_KEY,
  RUSSIAN_DESCRIPTION_KEY,
  START_DATETIME_KEY,
  TITLE_EN_KEY,
  TITLE_KEY,
  TITLE_KZ_KEY,
} from '@esentai/core/features/benefits/consts/keys';
import React from 'react';

import DateTimePicker from '@/components/DateTimePicker';
import FieldRow from '@/components/FieldRow';
import TextField from '@/components/TextField';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';

const DestinationForm = props => {
  const { classes, Field, values } = props;

  return (
    <>
      <FieldRow label="Описание">
        <CanEdit field={TITLE_KEY}>
          <Field
            Target={TextField}
            label="Название бенефита на русском"
            helperText="Название бенефита будет отображаться только для вас и модераторов в списке бенефитов"
            name={TITLE_KEY}
            margin="none"
            className={classes.titleField}
            symbolsMax={200}
          />
          <Field
            Target={TextField}
            label="Название бенефита на английском"
            helperText="Название бенефита будет отображаться только для вас и модераторов в списке бенефитов"
            name={TITLE_EN_KEY}
            margin="none"
            className={classes.titleField}
            symbolsMax={200}
          />
          <Field
            Target={TextField}
            label="Название бенефита на казахском"
            helperText="Название бенефита будет отображаться только для вас и модераторов в списке бенефитов"
            name={TITLE_KZ_KEY}
            margin="none"
            className={classes.titleField}
            symbolsMax={200}
          />
        </CanEdit>
      </FieldRow>
      <FieldRow label="Даты">
        <CanEdit field={START_DATETIME_KEY}>
          <Field
            disablePast
            Target={DateTimePicker}
            label="Дата начала действия бенефита"
            name={START_DATETIME_KEY}
            className={classes.datepicker}
          />
        </CanEdit>
        <CanEdit field={END_DATETIME_KEY}>
          <Field
            clearable
            disablePast
            Target={DateTimePicker}
            label="Дата завершения действия бенефита"
            minDate={values[START_DATETIME_KEY]}
            name={END_DATETIME_KEY}
            className={classes.datepicker}
          />
        </CanEdit>
      </FieldRow>

      <FieldRow label="Описание">
        <CanEdit field={RUSSIAN_DESCRIPTION_KEY}>
          <Field
            Target={TextField}
            label="Описание скидки на русском"
            // helperText="Название бенефита будет отображаться только для вас и модераторов в списке бенефитов"
            name={RUSSIAN_DESCRIPTION_KEY}
            margin="none"
            className={classes.titleField}
            symbolsMax={200}
          />
        </CanEdit>

        <CanEdit field={ENGLISH_DESCRIPTION_KEY}>
          <Field
            Target={TextField}
            label="Описание скидки на английском"
            // helperText="Название бенефита будет отображаться только для вас и модераторов в списке бенефитов"
            name={ENGLISH_DESCRIPTION_KEY}
            margin="none"
            className={classes.titleField}
            symbolsMax={200}
          />
        </CanEdit>

        <CanEdit field={KAZAKH_DESCRIPTION_KEY}>
          <Field
            Target={TextField}
            label="Описание скидки на казахском"
            // helperText="Название бенефита будет отображаться только для вас и модераторов в списке бенефитов"
            name={KAZAKH_DESCRIPTION_KEY}
            margin="none"
            className={classes.titleField}
            symbolsMax={200}
          />
        </CanEdit>
      </FieldRow>

      <FieldRow label="Процент скидки">
        <Field
          margin="normal"
          Target={TextField}
          className={classes.titleField}
          required
          name={DISCOUNT_PERCENT_KEY}
          multiline
          rows={2}
          rowsMax={4}
          label="Процент"
        />
      </FieldRow>

      <FieldRow label="Значение QR токена">
        <Field
          margin="normal"
          Target={TextField}
          className={classes.titleField}
          name={CUSTOM_TOKEN_KEY}
          multiline
          rows={2}
          rowsMax={4}
          label="Значение"
        />
      </FieldRow>

      <FieldRow label="Комментарий">
        <CanEdit field={COMMENT_KEY}>
          <Field
            Target={TextField}
            multiline
            rows={2}
            rowsMax={4}
            label="Комментарий"
            // helperText="Название бенефита будет отображаться только для вас и модераторов в списке бенефитов"
            name={COMMENT_KEY}
            margin="none"
            className={classes.titleField}
            symbolsMax={200}
          />
        </CanEdit>
      </FieldRow>
    </>
  );
};

DestinationForm.propTypes = FormikPropTypes;

export default DestinationForm;
