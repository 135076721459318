import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// import CanView from '@/containers/CanView';
// import { createHistoryPageUrl } from '@/features/reconciliationHistoryPage/utils';
import Link from '@/components/Link';
import { createReconciliationArchiveReceiptsPageUrl } from '@/features/reconciliationArchiveReceiptsPage/utils';
import { createReconciliationArchiveRefundsPageUrl } from '@/features/reconciliationArchiveRefundsPage/utils';
import { createHistoryPageUrl } from '@/features/reconciliationHistoryPage/utils';
import { formatMoney, formatNumber } from '@/utils/format';

import FinishReconciliationModal from '../../../../components/FinishReconciliationModal/FinishReconciliationModal';
import { GET_URL, PATCH_URL } from '../../consts';
// import { canRepayReconciliation } from '../../permissions';
// import FinishButton from './Actions/FinishButton';
// import HistoryButton from './Actions/HistoryButton';

const Row = ({
  storeName,
  totalToPayAmount,
  bonusesSpent,
  bonusesRefunded,
  storeId,
  fetchItems,
}) => {
  const [isFinishDialogOpen, handleFinishModal] = useState(false);

  const setFinishModal = () => {
    handleFinishModal(!isFinishDialogOpen);
  };

  const payload = {
    reconciliation_history: [
      {
        id: 0,
        payment_file_url: '',
      },
    ],
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Link to={createHistoryPageUrl(storeId)}>{storeName}</Link>
        </TableCell>
        <TableCell>{formatMoney(totalToPayAmount)}</TableCell>
        <TableCell>
          <Link to={createReconciliationArchiveReceiptsPageUrl(storeId)}>
            {formatNumber(bonusesSpent)}
          </Link>
        </TableCell>
        <TableCell align={'right'}>
          <Link to={createReconciliationArchiveRefundsPageUrl(storeId)}>
            {formatNumber(bonusesRefunded)}
          </Link>
        </TableCell>
        {/* <TableCell>
          <Grid className={classes.actions} container wrap="nowrap">
            <div className={classes.actionCell}>
              <HistoryButton
                storeId={storeId}
                historyPageUrl={createHistoryPageUrl(storeId)}
              />
              <CanView permission={canRepayReconciliation}>
                <FinishButton
                  finishDisabled={incompleteAmount < 1}
                  handleDialog={setFinishModal}
                />
              </CanView>
            </div>
          </Grid>
        </TableCell> */}
      </TableRow>
      {isFinishDialogOpen && (
        <FinishReconciliationModal
          isOpen={isFinishDialogOpen}
          onClose={setFinishModal}
          fetchItems={fetchItems}
          url={PATCH_URL}
          getUrl={GET_URL}
          payload={payload}
          storeId={storeId}
        />
      )}
    </>
  );
};

Row.propTypes = {
  storeId: PropTypes.number.isRequired,
  totalToPayAmount: PropTypes.number.isRequired,
  bonusesSpent: PropTypes.number.isRequired,
  bonusesRefunded: PropTypes.number.isRequired,
  storeName: PropTypes.string.isRequired,
  fetchItems: PropTypes.func.isRequired,
};

export default Row;
