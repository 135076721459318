export default {
  chip: {
    marginRight: 4,
    marginBottom: 5,
  },
  chips: {
    maxWidth: 300,
  },
  addButton: {
    marginLeft: 10,
  },
};
