import {
  BIN_KEY,
  COMPANY_KEY,
  LEGAL_ADDRESS_KEY,
  PARTNERSHIP_AGREEMENT_KEY,
  PARTNERSHIP_START_DATE_KEY,
} from '@esentai/core/features/merchants/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { boolean, date, object, string } from 'yup';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import styles from './styles';

const errorsMapping = {
  'data-error': {
    field: BIN_KEY,
    message: 'Данный БИН/ИИН уже существует',
  },
};

const formikOptions = {
  mapPropsToValues: ({
    company,
    // eslint-disable-next-line camelcase
    identification_number,
    // eslint-disable-next-line camelcase
    legal_address,
    partnership_start_date,
    status,
  }) => ({
    [COMPANY_KEY]: company,
    [BIN_KEY]: identification_number,
    [LEGAL_ADDRESS_KEY]: legal_address,
    [PARTNERSHIP_START_DATE_KEY]: partnership_start_date,
    merchantStatus: status,
  }),

  validationSchema: object().shape({
    [COMPANY_KEY]: string()
      .max(200)
      .required(),
    [PARTNERSHIP_AGREEMENT_KEY]: string()
      .max(200)
      .required(),
    [PARTNERSHIP_START_DATE_KEY]: date().required(),
    [BIN_KEY]: string()
      .matches(/^[0-9]{12}$/, 'БИН/ИИН должен состоять из 12-ти цифр')
      .required(),
    merchantStatus: boolean().nullable(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
