import {
  COMMENT_KEY,
  STATUS_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import {
  APPROVED_STATUS,
  DECLINED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import { TextField } from '@material-ui/core';
import React, { Fragment } from 'react';

import AsyncButton from '@/components/AsyncButton';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import FormikPropTypes from '@/prop-types/formik';

const fieldNames = {
  [COMMENT_KEY]: 'причина отклонения',
};

const Moderation = props => {
  const {
    classes,
    Field,
    RequiredMessages,
    SubmitButton,
    values,
    handleChange,
  } = props;

  return (
    <Fragment>
      <FieldRow label="Модерация">
        <RadioBoxGroup
          name={STATUS_KEY}
          value={values[STATUS_KEY]}
          onChange={handleChange}
        >
          <RadioBox
            value={APPROVED_STATUS}
            label="Одобрить"
            helperText="Кампания перейдет в статус ожидания своего старта."
          />
          <RadioBox
            value={DECLINED_STATUS}
            label="Отклонить"
            helperText="Кампания вернется автору. Вам необходимо будет указать причину отказа."
          />
        </RadioBoxGroup>

        {values[STATUS_KEY] === DECLINED_STATUS && (
          <div>
            <FieldDescription
              title="Причина отклонения кампании"
              desc="Обоснуйте автору кампании, почему вы отклонили ее. Постарайтесь расписать подробно все причины, чтобы пользователь сразу сделал все необходимые правки."
            />
            <Field
              multiline
              Target={TextField}
              name={COMMENT_KEY}
              className={classes.textField}
            />
          </div>
        )}
      </FieldRow>

      <FieldRow>
        <div className={classes.footer}>
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            Подтвердить
          </SubmitButton>
          <RequiredMessages
            fieldNames={fieldNames}
            className={classes.requiredMessages}
          />
        </div>
      </FieldRow>
    </Fragment>
  );
};

Moderation.propTypes = {
  ...FormikPropTypes,
};

export default Moderation;
