export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  excelButton: {
    marginTop: 15,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#1e88e5',
  },
};
