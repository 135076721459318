import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { dateTimeFormat } from '@/utils/format';

const Row = props => {
  const {
    to_customer_id,
    from_customer_id,
    record_created,
    staff_fullname,
  } = props.item;

  return (
    <TableRow>
      <TableCell>
        <Link to={`/users/${from_customer_id}`}>{from_customer_id || '-'}</Link>
      </TableCell>
      <TableCell>
        <Link to={`/users/${to_customer_id}`}>{to_customer_id || '-'}</Link>
      </TableCell>
      <TableCell>{dateTimeFormat(record_created)}</TableCell>
      <TableCell>{staff_fullname || '-'}</TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
