import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { number, object, string } from 'yup';

import ImageField from '@/components/ImageField';

const ONE_MB = 1048576;

const validationFileSchema = object().shape({
  type: string().matches(
    /image\/(png|jpe?g)/,
    'Изображение должно быть в формате .JPG или .PNG',
  ),
  size: number().max(ONE_MB * 2, 'Изображение должно быть не более 2МБ'),
});

const validationDimensionSchema = object().test(
  'dimension',
  ({ value: { width, height } }) =>
    `Изображение должно быть 1000×1000 пикселей, ваше изображение ${width}×${height} пикселей`,
  ({ width, height }) => width === 1000 && height === 1000,
);

const StoreImageFormControl = ({
  onValidationError,
  storeImageError,
  value,
  onChange,
  ...props
}) => (
  <FormControl {...props}>
    <FormLabel>Файлы изображений</FormLabel>
    <ImageField
      ids={value ? [value] : []}
      onChange={onChange}
      onValidationError={onValidationError}
      validationFileSchema={validationFileSchema}
      validationDimensionSchema={validationDimensionSchema}
    />
    <FormHelperText>
      {storeImageError &&
        'Изображение должно быть в формате .JPG или .PNG, 1000×1000 пикселей и не более 2МБ.'}
    </FormHelperText>
  </FormControl>
);

StoreImageFormControl.defaultProps = {
  storeImageError: null,
  value: null,
};

StoreImageFormControl.propTypes = {
  onValidationError: PropTypes.func.isRequired,
  storeImageError: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default StoreImageFormControl;
