import React from 'react';

import DefaultMapWorkspace from '@/components/MapWorkspace';

import FloorOption from './FloorOption';
import Map from './Map';

const MapWorkspace = () => (
  <DefaultMapWorkspace FloorOption={FloorOption} Map={Map} />
);

export default MapWorkspace;
