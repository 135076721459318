import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import dao from '@esentai/core/features/giftSets/dao';
import { updateGiftSet } from '@esentai/core/features/giftSets/sagas';
import { select, takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';

import { getGiftSetId } from '@/features/giftSetPage/selectors';
import { ROUTE_PATH as GIFT_SET_PATH } from '@/features/giftSetsPage/consts';

import { FETCH_GIFT_SET, UPDATE_GIFT_SET } from './consts/types';

export const { findById: findGiftSetById } = dao.sagas;

export function* fetchGiftSet(action) {
  const { payload: giftSetId } = action;

  yield call(findGiftSetById, giftSetId);
}

export function* updateGiftSetSaga(action) {
  const { payload: attributes } = action;
  const giftSetId = yield select(getGiftSetId);

  yield call(updateGiftSet, giftSetId, {
    ...attributes,
  });
  yield put(push(GIFT_SET_PATH));
}

export function* watchFetchGiftSet() {
  yield takeEvery(FETCH_GIFT_SET, fetchGiftSet);
}

export function* watchUpdateGiftSet() {
  yield takeEvery(UPDATE_GIFT_SET, updateGiftSetSaga);
}

export default function*() {
  yield spawn(watchFetchGiftSet);
  yield takeLatest(UPDATE_GIFT_SET, createTaskSaga(updateGiftSetSaga));
}
