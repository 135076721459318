export default {
  removeButton: {
    fontSize: 12,
    color: '#767676',
    transition: 'color 0.1s',

    '&:hover': {
      color: '#646464',
    },

    '& :last-child': {
      marginLeft: 4,
    },
  },
};
