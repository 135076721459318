import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CloneButton from '../../buttons/CloneButton';
import DeactivateButton from '../../buttons/DeactivateButton';

const ActiveStatusActions = ({ itemId }) => (
  <Fragment>
    <DeactivateButton itemId={itemId} />
    <CloneButton itemId={itemId} />
  </Fragment>
);

ActiveStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default ActiveStatusActions;
