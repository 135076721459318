import {
  ARCHIVE_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/reconciliationArchiveRefunds/consts/keys';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { getArchiveState, getStoreId } from './selectors';
import { RETRY_FETCH } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export function* fetchItemsForCurrentStore(query) {
  const storeId = yield select(getStoreId);

  const isArchive = yield select(getArchiveState);

  const archiveEqQuery = mergeQueriesRight(
    query,
    makeQuery(where(ARCHIVE_KEY, equals(isArchive))),
  );

  const storeQuery = mergeQueriesRight(
    archiveEqQuery,
    makeQuery(where(STORE_ID_KEY, equals(storeId))),
  );

  yield call(itemsDuck.sagas.fetchItems, storeQuery);
}

export const fetchItems = createQuerySaga(fetchItemsForCurrentStore);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
