import {
  getAgeForParkingLevelThreeWhitelist,
  getEmailForParkingLevelThreeWhitelist,
  getFirstNameForParkingLevelThreeWhitelist,
  getGenderForParkingLevelThreeWhitelist,
  getIsPhoneVerifiedForParkingLevelThreeWhitelist,
  getLastNameForParkingLevelThreeWhitelist,
  getPhoneForParkingLevelThreeWhitelist,
  getRecordCreatedForParkingLevelThreeWhitelist,
  getStateForParkingLevelThreeWhitelist,
} from '@esentai/core/features/parkingLevelThreeWhitelist/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: parkingLevelThreeWhitelistId }) => ({
  age: getAgeForParkingLevelThreeWhitelist(state, parkingLevelThreeWhitelistId),
  email: getEmailForParkingLevelThreeWhitelist(
    state,
    parkingLevelThreeWhitelistId,
  ),
  firstName: getFirstNameForParkingLevelThreeWhitelist(
    state,
    parkingLevelThreeWhitelistId,
  ),
  lastName: getLastNameForParkingLevelThreeWhitelist(
    state,
    parkingLevelThreeWhitelistId,
  ),
  gender: getGenderForParkingLevelThreeWhitelist(
    state,
    parkingLevelThreeWhitelistId,
  ),
  recordCreated: getRecordCreatedForParkingLevelThreeWhitelist(
    state,
    parkingLevelThreeWhitelistId,
  ),
  isPhoneVerified: getIsPhoneVerifiedForParkingLevelThreeWhitelist(
    state,
    parkingLevelThreeWhitelistId,
  ),
  state: getStateForParkingLevelThreeWhitelist(
    state,
    parkingLevelThreeWhitelistId,
  ),
  phone: getPhoneForParkingLevelThreeWhitelist(
    state,
    parkingLevelThreeWhitelistId,
  ),
  parkingLevelThreeWhitelistId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
