import { query as queryLocations } from '@esentai/core/features/locations/sagas';
import { query } from '@esentai/core/query-dsl';
import { push } from 'connected-react-router';
import { put, select, spawn, takeEvery } from 'redux-saga/effects';

import { createStatisticsQueryWatcher } from '@/features/statisticsPage/sagas';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import {
  getFloorStatisticsUrl,
  getMetricStatisticsUrl,
  getRentPlaceUsersStatisticsUrl,
} from './selectors';
import {
  MOUNT,
  OPEN_RENT_PLACE,
  RETRY_FETCH,
  UPDATE_FLOOR,
  UPDATE_STAT,
} from './types';

const { createQuerySaga, synchronizeQuery } = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createStatisticsQueryWatcher(
  queryDuck,
  fetchItems,
);

export function* loadLocations() {
  yield spawn(queryLocations, query());
}

export function* openRentPlace(action) {
  const { payload: rentId } = action;
  const url = yield select(getRentPlaceUsersStatisticsUrl, rentId);

  yield put(push(url));
}

export function* updateFloor(action) {
  const { payload: floor } = action;
  const url = yield select(getFloorStatisticsUrl, floor);

  yield put(push(url));
}

export function* updateStat(action) {
  const { payload: statId } = action;
  const url = yield select(getMetricStatisticsUrl, statId);

  yield put(push(url));
}

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield takeEvery(OPEN_RENT_PLACE, openRentPlace);
  yield takeEvery(MOUNT, loadLocations);
  yield takeEvery(UPDATE_FLOOR, updateFloor);
  yield takeEvery(UPDATE_STAT, updateStat);
}
