import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import api from '@/api';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Link from '@/components/Link';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import CanView from '@/containers/CanView';
import {
  canReadPersonalData,
  canUpdateLoyaltyData,
} from '@/features/userPage/permissions';
import { downloadXls } from '@/features/userPage/utils';
import { ROUTE_PATH as USERS_PATH } from '@/features/usersPage/consts';
import { canUpdateUser } from '@/features/usersPage/permissions';

import AddBonusesModal from './AddBonusesModal';
import AddCarModal from './AddCarModal/component';
import AddressFacts from './AddressFacts';
import CreditCardsInfo from './CreditCardsInfo';
import DeleteCarModal from './DeleteCarModal/component';
import EditCarInfoModal from './EditCarInfoModal/component';
import EditLoyaltyLevelModal from './EditLoyaltyLevelModal';
import PersonalInfo from './PersonalInfo';
import PrimaryFacts from './PrimaryFacts';
import PurchasesTable from './PurchasesTable';
import RegisteredCarsInfo from './RegisteredCarsInfo';
import SecondaryFacts from './SecondaryFacts';
import SuspiciotyToggler from './SuspiciotyToggler';
import UserActivity from './UserActivity';

class User extends Component {
  state = {
    isBonusesModalOpen: false,
    isLoyaltyLevelModalOpen: false,
    isEditCarInfoModalOpen: false,
    isDeleteCarModalOpen: false,
    isAddCarModalOpen: false,
    currentCarDetails: null,
    registeredCars: [],
    creditCards: [],
    errorMessage: null,
  };

  handleToggleBonusesModal = () => {
    this.setState({ isBonusesModalOpen: !this.state.isBonusesModalOpen });
  };

  handleToggleLoyaltyLevelModal = () => {
    this.setState({
      isLoyaltyLevelModalOpen: !this.state.isLoyaltyLevelModalOpen,
    });
  };

  handleToggleEditCarInfoModal = carDetails => {
    this.setState({
      isEditCarInfoModalOpen: !this.state.isEditCarInfoModalOpen,
      currentCarDetails: carDetails,
    });
  };

  handleToggleDeleteCarModal = carDetails => {
    this.setState({
      isDeleteCarModalOpen: !this.state.isDeleteCarModalOpen,
      currentCarDetails: carDetails,
    });
  };

  handleToggleAddCarModal = () => {
    this.setState({
      isAddCarModalOpen: !this.state.isAddCarModalOpen,
    });
  };

  handleResetError = () => {
    this.setState({
      errorMessage: null,
    });
  };

  handleBackendError = err => {
    const { response } = err;

    if (response.status === 422) {
      const errorLink = response.data.issue_link || '';
      const errorMessage = response.data.detail.ru;

      this.setState({
        errorMessage: {
          errorLink,
          errorMessage,
        },
      });
    } else {
      this.handleResetError();
    }
  };

  addUserCar = async values => {
    const { userId } = this.props.match.params;

    try {
      await api
        .doPost(`/user-vehicle`, {
          user_vehicle: {
            ...values,
            user: Number(userId),
          },
        })
        .then(() => {
          this.handleCloseAddModal();
          this.loadRegisteredCars();
        })

        .catch(err => {
          this.handleBackendError(err);
        });
    } catch (err) {
      throw err;
    }
  };

  editSelectedCar = async values => {
    try {
      await api
        .doPatch(`/user-vehicle/${this.state.currentCarDetails.id}`, {
          user_vehicle: [
            {
              ...values,
              user: this.state.currentCarDetails.user,
              id: this.state.currentCarDetails.id,
            },
          ],
        })
        .then(() => {
          this.handleCloseEditModal();
          this.loadRegisteredCars();
        })
        .catch(err => {
          this.handleBackendError(err);
        });
    } catch (err) {
      throw err;
    }
  };

  deleteSelectedCar = async () => {
    try {
      await api
        .doDelete(`/user-vehicle/${this.state.currentCarDetails.id}`)
        .then(() => {
          this.handleCloseDeleteModal();
          this.loadRegisteredCars();
        })
        .catch(err => {
          this.handleBackendError(err);
        });
    } catch (err) {
      throw err;
    }
  };

  handleCloseEditModal = () => {
    this.handleResetError();
    this.handleToggleEditCarInfoModal(null);
  };

  handleCloseDeleteModal = () => {
    this.handleResetError();
    this.handleToggleDeleteCarModal(null);
  };

  handleCloseAddModal = () => {
    this.handleResetError();
    this.handleToggleAddCarModal();
  };

  loadRegisteredCars = async () => {
    const { userId } = this.props.match.params;

    try {
      const response = await api.doGet(
        `/user/${userId}?include=vehicles,credit_cards`,
      );

      this.setState({
        registeredCars: response.user_vehicle,
        creditCards: response.credit_card,
      });
    } catch (e) {
      throw e;
    }
  };

  componentDidMount() {
    this.loadRegisteredCars();
  }

  componentDidUpdate(prevState) {
    const { userId } = this.props.match.params;

    if (prevState.itemId !== userId) {
      this.loadRegisteredCars();
    }
  }

  render() {
    const {
      classes,
      item,
      name,
      isLoading,
      receiptsError,
      receiptsIds,
      isLoadingReceipts,
      receiptsTotalCount,
      isMallManager,
    } = this.props;

    const {
      isBonusesModalOpen,
      isLoyaltyLevelModalOpen,
      isAddCarModalOpen,
      isEditCarInfoModalOpen,
      isDeleteCarModalOpen,
      registeredCars,
      currentCarDetails,
      creditCards,
    } = this.state;

    if (isLoading || !item) {
      return null;
    }

    const userId = item.id;

    const downloadXlsReport = () => {
      downloadXls(userId);
    };

    return (
      <Page>
        <PageHeader gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={USERS_PATH}>Пользователи</Breadcrumb>
          </Breadcrumbs>
          <div className={classes.titleBar}>
            <PageTitle gutterBottom={false} className={classes.pageTitle}>
              {name || 'Аноним'}
            </PageTitle>
            <Link to={`/campaigns/new-filter?id[in]=${userId}`}>
              <Button
                variant="outlined"
                color="default"
                size="small"
                className={classes.sendPush}
              >
                Отправить Push уведомление
              </Button>
            </Link>
            <CanView permission={canUpdateUser}>
              <div className={classes.actionsBar}>
                <SuspiciotyToggler userId={userId} />

                <div className={classes.rightCornerActions}>
                  <UserActivity userId={userId} />
                </div>
              </div>
            </CanView>
            <CanView permission={canUpdateLoyaltyData}>
              <Button
                variant="outlined"
                color="default"
                size="small"
                className={classes.bonusesButton}
                onClick={this.handleToggleBonusesModal}
                disabled={item.manual_activity_waiting}
              >
                Добавить бонусы
              </Button>
              <Button
                variant="outlined"
                color="default"
                size="small"
                onClick={this.handleToggleLoyaltyLevelModal}
                disabled={item.manual_activity_waiting}
              >
                Изменить уровень лояльности
              </Button>
            </CanView>
          </div>
        </PageHeader>
        <PageContent>
          <div className={classes.details}>
            <CanView permission={canReadPersonalData}>
              <PersonalInfo
                userId={userId}
                classes={{ root: classes.personalInfo }}
              />
            </CanView>

            <div className={classes.facts}>
              <PrimaryFacts userId={userId} />
            </div>
          </div>

          <div className={classes.delimeter} />
          <RegisteredCarsInfo
            userId={userId}
            cars={registeredCars}
            handleToggleEditModal={this.handleToggleEditCarInfoModal}
            handleToggleDeleteModal={this.handleToggleDeleteCarModal}
            handleToggleAddModal={this.handleToggleAddCarModal}
            isMallManager={isMallManager}
          />

          <div className={classes.delimiter} />
          <AddressFacts userId={userId} />

          <div className={classes.delimiter} />
          <SecondaryFacts userId={userId} />

          {creditCards.length > 0 && (
            <>
              <div className={classes.delimiter} />
              <CreditCardsInfo
                onSuccess={this.loadRegisteredCars}
                userId={userId}
                cards={creditCards}
              />
            </>
          )}

          <Typography
            gutterBottom
            color="inherit"
            variant="h5"
            className={classes.purchasesTitle}
          >
            Покупки пользователя
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.excelButton}
            onClick={downloadXlsReport}
          >
            Вывести в Excel
          </Button>
          <PurchasesTable
            error={receiptsError}
            items={receiptsIds}
            isLoading={isLoadingReceipts}
            totalCount={receiptsTotalCount}
          />
          <AddBonusesModal
            isOpen={isBonusesModalOpen}
            onClose={() => this.handleToggleBonusesModal()}
            userId={userId}
          />
          <EditLoyaltyLevelModal
            isOpen={isLoyaltyLevelModalOpen}
            onClose={() => this.handleToggleLoyaltyLevelModal()}
            userId={userId}
          />
          <AddCarModal
            isOpen={isAddCarModalOpen}
            onClose={this.handleCloseAddModal}
            onSubmit={this.addUserCar}
            errorMessage={this.state.errorMessage}
          />
          <EditCarInfoModal
            currentCarDetails={currentCarDetails}
            isOpen={isEditCarInfoModalOpen}
            onClose={this.handleCloseEditModal}
            onSubmit={this.editSelectedCar}
            errorMessage={this.state.errorMessage}
          />
          <DeleteCarModal
            currentCarDetails={currentCarDetails}
            isOpen={isDeleteCarModalOpen}
            onClose={this.handleCloseDeleteModal}
            onSubmit={this.deleteSelectedCar}
            errorMessage={this.state.errorMessage}
          />
        </PageContent>
      </Page>
    );
  }
}

User.defaultProps = {
  item: null,
  name: null,
  receiptsError: null,
};

User.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  receiptsError: PropTypes.any,
  receiptsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isLoadingReceipts: PropTypes.bool.isRequired,
  receiptsTotalCount: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  isMallManager: PropTypes.bool.isRequired,
};

export default User;
