import { Chip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Dropdown from './Dropdown';
import Popup from './Popup';

class FilterChip extends Component {
  static propTypes = {
    FormatComponent: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    onRemove: PropTypes.func,
    renderFilter: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
  };

  static defaultProps = {
    onClose: () => {},
    onChange: null,
    onRemove: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.isEditing) {
      const { value } = nextProps;

      if (value !== prevState.value) {
        return { value };
      }
    }

    return null;
  }

  state = {
    isEditing: false,
    value: this.props.value,
  };

  render() {
    return (
      <Dropdown
        {...this.props}
        renderTrigger={this.renderTrigger}
        renderPopup={this.renderPopup}
        onOpen={this.enableEditingMode}
        onClose={this.flushState}
      />
    );
  }

  renderPopup = ({ onClose, ...popupProps }) => {
    const { name, renderFilter } = this.props;
    const { value } = this.state;

    return (
      <Popup
        {...popupProps}
        name={name}
        renderFilter={renderFilter}
        onChange={this.handleChange}
        onClose={onClose}
        onSubmit={() => {
          this.publishChange();
          onClose();
        }}
        value={value}
        disableRestoreFocus
      />
    );
  };

  renderTrigger = ({ onClick, ref, ...rest }) => {
    const {
      FormatComponent,
      name,
      onChange,
      onRemove,
      value,
      disabled,
    } = this.props;
    const Label = () => (
      <Typography>
        {name}: <FormatComponent value={value} />
      </Typography>
    );

    return (
      <span ref={ref}>
        <Chip
          {...rest}
          label={<Label />}
          onClick={onChange && !disabled ? onClick : null}
          onDelete={!disabled ? onRemove : null}
        />
      </span>
    );
  };

  flushState = () => {
    const { value, onClose } = this.props;

    this.disableEditingMode();
    this.setState({ value });

    onClose();
  };

  handleChange = value => this.setState({ value });

  publishChange = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    onChange(value);
  };

  enableEditingMode = () => this.setState({ isEditing: true });

  disableEditingMode = () => this.setState({ isEditing: false });
}

export default FilterChip;
