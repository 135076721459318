import { isVisibleToCustomers } from '@esentai/core/features/productView/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { moveViewDown } from '@/features/productsViewPage/actions';

import MoveDownButton from './component';

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(moveViewDown(itemId)),
});

const mapStateToProps = (state, { itemId }) => ({
  isVisible: isVisibleToCustomers(state, itemId),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(MoveDownButton);
