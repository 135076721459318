import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FiltersRow from '@/components/FiltersRow';
import {
  HMS_KEY,
  RECORD_CREATED_KEY,
  REFUND_DATETIME_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';
import {
  filterPrefixes,
  filterTypes,
  multipleFilterList,
} from '@/features/purchaseWithoutScanPage/filters';

const PurchaseWithoutScanFiltersRow = props => {
  const [filters, setFilters] = useState(new Map());
  const currentUrlParams = new URLSearchParams(window.location.search);

  const changeUrlParams = params => {
    props.history.push({
      pathname: '/purchases-without-scan',
      search: `?${decodeURIComponent(params.toString())}`,
    });
  };

  const getFilterDuplicate = () => {
    const map = new Map();

    filters.forEach((value, key) => {
      map.set(key, value);
    });

    return map;
  };

  const updateFilters = payload => {
    const initMap = getFilterDuplicate();

    Object.keys(payload).forEach(fieldName => {
      const mapValue = payload[fieldName];

      if (mapValue && (mapValue.has('gte') || mapValue.has('lte'))) {
        mapValue.forEach((val, key) => {
          const isDate = val instanceof Date;

          if (isDate) {
            if (fieldName === HMS_KEY) {
              currentUrlParams.set(
                `${fieldName}[${key}]`,
                moment(val).format('HH:mm:00'),
              );
            } else {
              currentUrlParams.set(`${fieldName}[${key}]`, val.toISOString());
            }
          } else {
            currentUrlParams.set(`${fieldName}[${key}]`, val);
          }
        });
      } else {
        const { prefix } = filterPrefixes.find(
          filter => filter.field === fieldName,
        );
        const value = mapValue.get(prefix);

        currentUrlParams.set(`${fieldName}[${prefix}]`, value);
      }
      initMap.set(fieldName, mapValue);
    });

    changeUrlParams(currentUrlParams);
    setFilters(initMap);
  };

  const removeFilters = payload => {
    const params = new URLSearchParams(window.location.search);
    const initMap = getFilterDuplicate();

    payload.map(filter => {
      initMap.delete(filter);

      if (multipleFilterList.includes(filter)) {
        params.delete(`${filter}[gte]`);
        params.delete(`${filter}[lte]`);
      } else {
        const { prefix } = filterPrefixes.find(({ field }) => field === filter);

        params.delete(`${filter}[${prefix}]`);
      }

      return filter;
    });

    changeUrlParams(params);
    setFilters(initMap);
  };

  useEffect(() => {
    const initMap = new Map();
    let valueMap = new Map();
    let canClean = false;

    for (const param of currentUrlParams) {
      const name = param[0];
      const value = param[1];

      if (canClean) {
        valueMap = new Map();
        canClean = false;
      }

      if (name !== 'sort') {
        let filteredName = name.replace('[gte]', '').replace('[lte]', '');

        if (multipleFilterList.includes(filteredName)) {
          const isDate =
            filteredName === RECORD_CREATED_KEY ||
            filteredName === REFUND_DATETIME_KEY;

          const isTimeDate = filteredName === HMS_KEY;

          const fieldValue = isDate ? new Date(value) : value;

          if (name.includes('gte')) {
            if (isTimeDate) {
              valueMap.set('gte', moment(fieldValue, 'HH:mm:00').toDate());
            } else {
              valueMap.set('gte', fieldValue);
            }
          } else {
            if (isTimeDate) {
              valueMap.set('lte', moment(fieldValue, 'HH:mm:00').toDate());
            } else {
              valueMap.set('lte', fieldValue);
            }
            canClean = true;
          }
        } else {
          const filter = filterPrefixes.find(
            ({ field, prefix }) => `${field}[${prefix}]` === filteredName,
          );

          filteredName = filteredName.replace(`[${filter.prefix}]`, '');

          valueMap.set(filter.prefix, value);
        }
        initMap.set(filteredName, valueMap);
      }
    }
    setFilters(initMap);
  }, []);

  return (
    <FiltersRow
      filters={filters}
      updateFilters={updateFilters}
      removeFilters={removeFilters}
      filterTypes={filterTypes}
    />
  );
};

PurchaseWithoutScanFiltersRow.propTypes = {
  history: PropTypes.object.isRequired,
};

export default PurchaseWithoutScanFiltersRow;
