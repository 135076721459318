import {
  ADDITIONAL_ENGLISH_TEXT_KEY,
  ADDITIONAL_KAZAKH_TEXT_KEY,
  ADDITIONAL_RUSSIAN_TEXT_KEY,
  ENGLISH_TITLE_KEY,
  IMAGE_URL_KEY,
  KAZAKH_TITLE_KEY,
  LAYOUT_KEY,
  RUSSIAN_TITLE_KEY,
} from '@esentai/core/features/campaigns/article/consts/keys';
import { ONLINE_SHOWCASE_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import React, { Component } from 'react';

import SecondStepForm from '@/features/campaignCreatePage/EditPage/SecondStepForm';
import FormikPropTypes from '@/prop-types/formik';

import ArticleForm from '../ArticleForm';
import ContentForm from './ContentForm';

const fieldNames = {
  [IMAGE_URL_KEY]: 'изображение баннера',
  [LAYOUT_KEY]: 'изображение',
  [RUSSIAN_TITLE_KEY]: 'заголовок на русском',
  [ENGLISH_TITLE_KEY]: 'заголовок на английском',
  [KAZAKH_TITLE_KEY]: 'заголовок на казахском',
  [ADDITIONAL_RUSSIAN_TEXT_KEY]: 'дополнительный текст на русском',
  [ADDITIONAL_ENGLISH_TEXT_KEY]: 'дополнительный текст на английском',
  [ADDITIONAL_KAZAKH_TEXT_KEY]: 'дополнительный текст на казахском',
  [ONLINE_SHOWCASE_KEY]: 'ссылка на онлайн витрину',
};

class SecondStepArticle extends Component {
  static propTypes = FormikPropTypes;

  render() {
    const { handleSubmit, SubmitButton, RequiredMessages } = this.props;

    return (
      <SecondStepForm
        fieldNames={fieldNames}
        handleSubmit={handleSubmit}
        SubmitButton={SubmitButton}
        RequiredMessages={RequiredMessages}
      >
        <ContentForm {...this.props} />
        <ArticleForm {...this.props} />
      </SecondStepForm>
    );
  }
}

export default SecondStepArticle;
