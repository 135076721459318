import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import _get from 'lodash.get';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';

import Table from './Table';
import TransferBonusesModal from './TransferBonusesModal/component';

const BonusTransferPage = ({ classes }) => {
  const [bonusTransferInfo, setBonusTransferInfo] = useState({
    loading: false,
    data: [],
    error: null,
  });

  const [isTransferModalOpen, setTransferModalOpen] = useState(false);

  const [pageInfo, setPageInfo] = useState({
    limit: 10,
    totalCount: 0,
    page: 0,
  });

  const handleToggleModal = () => {
    setTransferModalOpen(!isTransferModalOpen);
  };

  const loadBonusArchiveData = async (params = {}) => {
    setBonusTransferInfo(prevState => ({
      ...prevState,
      loading: true,
    }));

    const searchParams = new URLSearchParams(window.location.search);

    const { limit, page } = pageInfo;

    const filters = {
      limit,
      offset: limit * page,
      sort: searchParams.get('sort'),
      ...params,
    };

    try {
      const response = await api.doGet(`/receipt-transfer`, { ...filters });

      setBonusTransferInfo(prevState => ({
        ...prevState,
        data: _get(response, `payload.receipt_transfer`, []),
      }));

      setPageInfo(prevState => ({
        ...prevState,
        totalCount: response.meta.total,
      }));
    } catch (error) {
      setBonusTransferInfo(prevState => ({
        ...prevState,
        error,
      }));

      throw error;
    } finally {
      setBonusTransferInfo(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onSetParams = () => {
    const searchList = decodeURIComponent(
      new URLSearchParams(window.location.search),
    );
    const filterList = searchList.toString().split('&');
    const params = {};

    filterList.map(filter => {
      const value = filter.split('=');

      params[`${value[0]}`] = value[1];

      return filter;
    });

    return { params };
  };

  const onSetPageSize = newPageSize => {
    setPageInfo(prevState => ({
      ...prevState,
      page: 0,
      limit: newPageSize,
    }));
  };

  const onSetPage = newPage => {
    setPageInfo(prevState => ({
      ...prevState,
      page: newPage,
    }));
  };

  const hadleBonusTransfer = async values => {
    try {
      await api.doPost(`/receipt-transfer`, {
        receipt_transfer: values,
      });
      setTransferModalOpen(false);
      const { params } = onSetParams();

      loadBonusArchiveData(params);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const { params } = onSetParams();

    loadBonusArchiveData(params);
  }, [pageInfo.limit, pageInfo.page]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>Перенос бонусов и покупок</PageTitle>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.transferButton}
          onClick={handleToggleModal}
        >
          Перенести бонусы и покупки
        </Button>
        <Table
          limit={pageInfo.limit}
          items={bonusTransferInfo.data}
          isLoading={bonusTransferInfo.loading}
          totalCount={pageInfo.totalCount}
          page={pageInfo.page}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
        <TransferBonusesModal
          isOpen={isTransferModalOpen}
          onClose={handleToggleModal}
          onSubmit={hadleBonusTransfer}
          errorMessage={'error'}
        />
      </PageContent>
    </Page>
  );
};

export default BonusTransferPage;
