import { getKkmNumberForKkm } from '@esentai/core/features/kkms/selectors';
import { connect } from 'react-redux';

import Option from '@/components/AutocompleteBase/Option';

const mapStateToProps = (state, { value }) => ({
  label: getKkmNumberForKkm(state, value),
});

export default connect(mapStateToProps, () => ({}))(Option);
