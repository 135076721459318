export default () => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '330px',
    height: '100%',
    background: '#616161',
    color: '#fff',
    paddingBottom: '100px',
  },
  fullHeight: {
    height: '100%',
    overflowY: 'auto',
  },
  expand: {
    color: '#fff',
    borderColor: '#fff',
    textTransform: 'none',
    width: 40,
    position: 'fixed',
    bottom: 10,
    left: 215,
  },
  expanded: {
    width: 55,
    overflowX: 'hidden',
  },
});
