export default {
  filtersContent: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: [1, 'solid', '#EEE'],
    padding: [10, 0],
    marginBottom: -1,
  },
  filterIcon: {
    marginRight: 16,
  },
};
