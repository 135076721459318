import { Button } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
// import Button from '@/components/Button';

const AttachmentButton = ({ ...props }) => (
  <ActionButton
    text="Скачать файл"
    button={Button}
    icon={AttachmentIcon}
    target="_blank"
    href={props.fileUrl}
    {...props}
  />
);

AttachmentButton.propTypes = {
  fileUrl: PropTypes.any,
};

AttachmentButton.defaultProps = {
  fileUrl: '#',
};

export default AttachmentButton;
