import {
  BONUSES_REFUNDED_KEY,
  BONUSES_SPENT_KEY,
  LAST_RECONCILIATION_KEY,
  TOTAL_TO_PAY_KEY,
} from '@esentai/core/features/reconciliation/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>Арендатор</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TOTAL_TO_PAY_KEY}>Сумма к оплате</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={BONUSES_SPENT_KEY}>
        Сумма потраченных бонусов
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={BONUSES_REFUNDED_KEY}>
        Сумма бонусов на возврат
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={LAST_RECONCILIATION_KEY}>
        Дата последней реконсиляции
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>Действия</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
