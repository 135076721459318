import { MenuItem, Select as MuiSelect } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const Select = ({ value, options, onChange, ...props }) => (
  <MuiSelect
    {...props}
    value={value}
    IconComponent={KeyboardArrowDown}
    onChange={event => onChange(event.target.value)}
    disableUnderline
  >
    {options.map(item => (
      <MenuItem
        key={item.id}
        value={item.id}
        disabled={Boolean(item.disabled)}
        dense
      >
        {item.label}
      </MenuItem>
    ))}
  </MuiSelect>
);

Select.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Select;
