import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Map from '@/components/Map';

import Viewport from './Viewport';

class HeatMap extends Component {
  static propTypes = {
    maximumAmount: PropTypes.number.isRequired,
    minimumAmount: PropTypes.number.isRequired,
    RentPlace: PropTypes.func.isRequired,
    TooltipContent: PropTypes.func.isRequired,
  };

  render() {
    const { RentPlace, TooltipContent, ...rest } = this.props;

    return (
      <Map
        {...rest}
        RentPlace={RentPlace}
        TooltipContent={TooltipContent}
        Viewport={this.renderViewport}
      />
    );
  }

  renderRentPlace = props => {
    const { maximumAmount, minimumAmount, RentPlace } = this.props;

    return (
      <RentPlace
        {...props}
        maximumAmount={maximumAmount}
        minimumAmount={minimumAmount}
      />
    );
  };

  renderViewport = props => {
    const { maximumAmount, minimumAmount } = this.props;

    return (
      <Viewport
        {...props}
        maximumAmount={maximumAmount}
        minimumAmount={minimumAmount}
      />
    );
  };
}

export default HeatMap;
