import createItemsDuck from '@esentai/core/ducks/items';

import api from '../api';
import { RESOURCE_NAME } from '../consts';
import { getItemsRoot } from '../utils';

export default createItemsDuck(RESOURCE_NAME, {
  api,
  getRoot: getItemsRoot,
});
