import { getButtonPlacement } from '@esentai/core/features/campaigns/button/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Placement from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  placement: getButtonPlacement(state, itemId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Placement);
