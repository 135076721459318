import { RESOURCE_NAME } from '@esentai/core/features/merchants/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadMerchants = authorizeIf(canRead(RESOURCE_NAME));
export const canReadMerchant = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateMerchant = authorizeIf(canCreate(RESOURCE_NAME));
export const canUpdateMerchant = authorizeIf(canUpdate(RESOURCE_NAME));

// TODO set permission for campaign statistic
export const canReadMerchantsStatistics = () => true;
