import {
  TRACKED_LOCATION_IDS_KEY,
  TRIGGER_KEY,
} from '@esentai/core/features/campaigns/actionResult/consts/keys';
import { LOCATION_AWARE_TRIGGERS } from '@esentai/core/features/campaigns/actionResult/consts/triggers';
import { Typography } from '@material-ui/core';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import { LocationMultiAutocomplete } from '@/containers/LocationAutocomplete';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';

class LocationsForm extends Component {
  static propTypes = FormikPropTypes;

  setTrackedLocationIds = values => {
    const { setFieldValue } = this.props;

    setFieldValue(TRACKED_LOCATION_IDS_KEY, values);
  };

  render() {
    const { values } = this.props;
    const trigger = values[TRIGGER_KEY];

    if (!LOCATION_AWARE_TRIGGERS.includes(trigger)) {
      return null;
    }

    return (
      <FieldRow
        label="Локация"
        subLabel="Триггеры локаций"
        sudDesc="Триггер срабатает, когда посетитель выполнит условие в одной из указанных локаций."
      >
        <Typography gutterBottom>Активировать кампанию для локаций:</Typography>
        <CanEdit field={TRACKED_LOCATION_IDS_KEY}>
          <LocationMultiAutocomplete
            onChange={this.setTrackedLocationIds}
            selectedItem={values[TRACKED_LOCATION_IDS_KEY]}
            InputProps={{
              fullWidth: true,
              placeholder: 'Добавьте локацию триггера',
              InputProps: { disableUnderline: true },
            }}
          />
        </CanEdit>
      </FieldRow>
    );
  }
}

export default LocationsForm;
