import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import MoveDownButton from './MoveDownButton';
import MoveUpButton from './MoveUpButton';

const SortControls = ({
  classes,
  canMoveQuestionDown,
  canMoveQuestionUp,
  itemId,
}) => (
  <div>
    <MoveUpButton
      itemId={itemId}
      className={classNames({ [classes.invisible]: !canMoveQuestionUp })}
    />
    <MoveDownButton
      itemId={itemId}
      className={classNames({ [classes.invisible]: !canMoveQuestionDown })}
    />
  </div>
);

SortControls.propTypes = {
  canMoveQuestionDown: PropTypes.bool.isRequired,
  canMoveQuestionUp: PropTypes.bool.isRequired,
  itemId: PropTypes.number.isRequired,
};

export default SortControls;
