import { ID_KEY } from '@esentai/core/features/efsVisitRecords/consts/keys';
import { annotations } from '@esentai/core/features/efsVisitRecords/serializer';
import { descending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(ID_KEY),
  }),
});
