import { TYPE_KEY } from '@esentai/core/features/campaigns/banner/consts/keys';
import {
  MAIN_PAGE_TYPE,
  POPUP_TYPE,
  STORE_LIST_TYPE,
} from '@esentai/core/features/campaigns/banner/consts/types';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MainPageContentForm from './MainPageContentForm';
import PopupContentForm from './PopupContentForm';
import StoreListContentForm from './StoreListContentForm';

const typeMapping = {
  [MAIN_PAGE_TYPE]: MainPageContentForm,
  [STORE_LIST_TYPE]: StoreListContentForm,
  [POPUP_TYPE]: PopupContentForm,
};

class ContentForm extends Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
  };

  render() {
    const { values } = this.props;
    const type = values[TYPE_KEY];
    const Form = typeMapping[type];

    return <Form {...this.props} />;
  }
}

export default ContentForm;
