import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from './DeleteButton';

const Actions = ({ classes, parkingLevelThreeWhitelistId, ...props }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <DeleteButton
      parkingLevelThreeWhitelistId={parkingLevelThreeWhitelistId}
      {...props}
    />
  </Grid>
);

Actions.propTypes = {
  parkingLevelThreeWhitelistId: PropTypes.number.isRequired,
};

export default Actions;
