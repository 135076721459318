import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '@/api';
import Label from '@/features/campaignPage/Page/Label';

const Showcase = ({ payload }) => {
  const { referred_show } = payload;
  const [currentShow, setCurrentShow] = useState(null);

  const getCurrentShow = async () => {
    try {
      const response = await api.doGet(`/campaign-show/${referred_show}`);
      const show = response.campaign_show[0];

      setCurrentShow(show);
    } catch (e) {
      setCurrentShow(null);
      throw e;
    }
  };

  useEffect(() => {
    getCurrentShow();
  }, [referred_show]);

  return (
    <Label title="Онлайн витрина">
      {currentShow ? (
        <Link to={`/campaigns/show/${currentShow.id}`} target="_blank">
          {currentShow.title}
        </Link>
      ) : (
        'Отсутствует'
      )}
    </Label>
  );
};

Showcase.propTypes = {
  payload: PropTypes.object.isRequired,
};

export default Showcase;
