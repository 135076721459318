import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import Tags from '@/features/campaignCreatePage/EditPage/SecondStepCalendarEvent/TagsForm/AutocompleteInput';
import Label from '@/features/campaignPage/Page/Label';
import { formatDateTime } from '@/utils/format';

const Properties = ({ classes, startDatetime, endDatetime, tags }) => (
  <FieldRow label="Дата события">
    <div className={classes.dates}>
      {!isNil(startDatetime) && (
        <Label title="Дата начала события">
          {formatDateTime(startDatetime)}
        </Label>
      )}
      {!isNil(endDatetime) && (
        <Label title="Дата завершения события">
          {formatDateTime(endDatetime)}
        </Label>
      )}
    </div>

    {!isNil(tags) && (
      <div>
        <Label title="Тематические теги">
          <Tags disabled selectedItem={tags} />
        </Label>
      </div>
    )}
  </FieldRow>
);

Properties.propTypes = {
  startDatetime: PropTypes.instanceOf(Date),
  endDatetime: PropTypes.instanceOf(Date),
  tags: PropTypes.arrayOf(PropTypes.string),
};

Properties.defaultProps = {
  startDatetime: null,
  endDatetime: null,
  tags: null,
};

export default Properties;
