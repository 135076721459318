import { ARTICLE_COUNT_KEY } from '@esentai/core/features/applicationSettings/homeLayout/consts/payloadKeys';
import PropTypes from 'prop-types';
import React from 'react';

import TextField from '@/components/TextField';

const Articles = ({ Field }) => (
  <Field
    Target={TextField}
    name={ARTICLE_COUNT_KEY}
    label="Количество статей"
    type="number"
  />
);

Articles.propTypes = {
  Field: PropTypes.func.isRequired,
};

export default Articles;
