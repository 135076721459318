import { fetchSettings } from '@esentai/core/features/applicationSettings/actions';
import { getLastSyncDate } from '@esentai/core/features/applicationSettings/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import SiteSyncPage from './component';

const mapStateToProps = state => ({
  lastSync: getLastSyncDate(state),
});

const mapDispatchToProps = { fetchSettings };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'fetchSettings' }),
)(SiteSyncPage);
