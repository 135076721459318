import { createBrandEditPageUrl } from '@/features/brandEditPage/utils';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError,
  isLoadingItems,
  getItemsIds,
  getItemsMeta,
  getItemsTotalCount,
} = itemsDuck.selectors;

export const {
  getFilters,
  getPage,
  getLimit,
  getQuery,
  getSort,
} = queryDuck.selectors;

export const getBrandEditUrl = (state, brandId) =>
  createBrandEditPageUrl(brandId);
