export default {
  paperWrap: {
    maxWidth: 595,
    margin: 'auto',
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 300,
    padding: [56, 132, 76, 132],
    boxSizing: 'border-box',
  },
};
