import {
  getPurchaseActiveBonuses,
  getPurchaseBonusCost,
  getPurchaseBonusPercent,
  getPurchaseCashMachine,
  getPurchaseCreationDate,
  getPurchaseCustomer,
  getPurchaseFiscalId,
  getPurchaseMoneyCost,
  getPurchasePendingBonuses,
  getPurchaseReceipt,
  getPurchaseRefundBonusCost,
  getPurchaseRefundMoneyCost,
  getPurchaseStatus,
  getPurchaseStoreName,
  getPurchaseTotalCost,
  getPurchaseUserLevel,
} from '@esentai/core/features/purchases/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: purchaseId }) => ({
  cashMachine: getPurchaseCashMachine(state, purchaseId),
  uuid: getPurchaseFiscalId(state, purchaseId),
  customer: getPurchaseCustomer(state, purchaseId),
  store: getPurchaseStoreName(state, purchaseId),
  purchaseDate: getPurchaseCreationDate(state, purchaseId),
  totalCost: getPurchaseTotalCost(state, purchaseId),
  activeBonuses: getPurchaseActiveBonuses(state, purchaseId),
  pendingBonuses: getPurchasePendingBonuses(state, purchaseId),
  bonusPercent: getPurchaseBonusPercent(state, purchaseId),
  bonusCost: getPurchaseBonusCost(state, purchaseId),
  moneyCost: getPurchaseMoneyCost(state, purchaseId),
  status: getPurchaseStatus(state, purchaseId),
  refundBonusCost: getPurchaseRefundBonusCost(state, purchaseId),
  refundMoneyCost: getPurchaseRefundMoneyCost(state, purchaseId),
  userLevel: getPurchaseUserLevel(state, purchaseId),
  receipt: getPurchaseReceipt(state, purchaseId),
  purchaseId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
