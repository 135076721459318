import { connect } from 'react-redux';

import {
  getAverageCheckForItem,
  getAverageTimeSpentForItem,
  getBuyersCountForItem,
  getConversionForItem,
  getLeadsCountForItem,
  getMallVisitorsCountForItem,
  getPurchasesCountForItem,
  getUsersCountForItem,
  getVisitorsCountForItem,
} from '@/features/usersStatistics/selectors';

import Row from './component';

const mapStateToProps = (state, { item: itemId }) => ({
  averageCheck: getAverageCheckForItem(state, itemId),
  averageTimeSpent: getAverageTimeSpentForItem(state, itemId),
  buyersCount: getBuyersCountForItem(state, itemId),
  conversion: getConversionForItem(state, itemId),
  itemId,
  leadsCount: getLeadsCountForItem(state, itemId),
  purchasesCount: getPurchasesCountForItem(state, itemId),
  usersCount: getUsersCountForItem(state, itemId),
  visitorsCount: getVisitorsCountForItem(state, itemId),
  mallVisitorsCount: getMallVisitorsCountForItem(state, itemId),
});

export default connect(mapStateToProps)(Row);
