import { lighten } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
  bulkActionsRow: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },

  title: {
    color: theme.palette.secondary.dark,
  },

  separator: {
    '&::before': {
      content: '""',
      display: 'inline-block',
      height: 28,
      borderLeft: [1, 'solid', theme.palette.secondary.dark],
    },
  },
});
