import nanoid from 'nanoid';
import {
  all,
  defaultTo,
  isEmpty,
  isNil,
  omit,
  prop,
  values as valuesF,
} from 'ramda';

const defaultToRequiredErrors = defaultTo({});

export const requiredErrorsKey = `__required__${nanoid()}`;

export const isRequiredError = err =>
  err && (err.type === 'required' || err.type === 'typeError');

export const getRequiredErrors = errors =>
  defaultToRequiredErrors(prop(requiredErrorsKey, errors));

export const getValidationErrors = errors => omit([requiredErrorsKey], errors);

export const hasRequiredErrors = errors => !isEmpty(getRequiredErrors(errors));

export const isButtonDisabled = ({
  errors,
  isSubmitting,
  touched,
  values: data,
}) =>
  !isEmpty(getValidationErrors(errors)) ||
  isSubmitting ||
  hasRequiredErrors(errors) ||
  (isEmpty(touched) && all(isNil, valuesF(data)));
