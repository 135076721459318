export default {
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  limitCount: {
    display: 'inline-block',
    marginLeft: 'auto',
  },
};
