import applicationSettingsSaga from '@esentai/core/features/applicationSettings/sagas';
// import benefitItemsSaga from '@esentai/core/features/benefitItems/sagas';
import benefitsSaga from '@esentai/core/features/benefits/sagas';
import brandsSaga from '@esentai/core/features/brands/sagas';
import campaignsSaga from '@esentai/core/features/campaigns/sagas';
import creditCardsSaga from '@esentai/core/features/creditCards/sagas';
import historySaga from '@esentai/core/features/history_data/sagas';
import locationSaga from '@esentai/core/features/locations/sagas';
import loyaltiesSaga from '@esentai/core/features/loyalties/sagas';
import parkingLevelThreeWhitelistSaga from '@esentai/core/features/parkingLevelThreeWhitelist/sagas';
import parkingTowerStopListSaga from '@esentai/core/features/parkingTowerStopList/sagas';
import parkingWhitelistSaga from '@esentai/core/features/parkingWhitelist/sagas';
import parkingWhitelistFilterSaga from '@esentai/core/features/parkingWhitelistFilter/sagas';
import parkingWhitelistGroupsSaga from '@esentai/core/features/parkingWhitelistGroups/sagas';
import productCategorySaga from '@esentai/core/features/productCategory/sagas';
import productViewSaga from '@esentai/core/features/productView/sagas';
import questionsSaga from '@esentai/core/features/questions/sagas';
import storesSaga from '@esentai/core/features/stores/sagas';
import usersSaga from '@esentai/core/features/users/sagas';
import { spawn } from 'redux-saga/effects';

import anonymousPurchasesStatisticsPageSaga from '@/features/anonymousPurchasesStatisticsPage/sagas';
import authSaga from '@/features/auth/sagas';
import authPagesSaga from '@/features/authPages/sagas';
import beaconsPageSaga from '@/features/beaconsPage/sagas';
import benefitCreatePageSaga from '@/features/benefitCreatePage/sagas';
import benefitGiveOutPageSaga from '@/features/benefitGiveOutPage/sagas';
import benefitItemsPageSaga from '@/features/benefitItemsPage/sagas';
import benefitsPageSaga from '@/features/benefitsPage/sagas';
import bonusesHistoryPageSaga from '@/features/bonusesHistoryPage/sagas';
import bonusTransactionLevelHistoryPage from '@/features/bonusTransactionLevelHistoryPage/sagas';
import brandCreatePageSaga from '@/features/brandCreatePage/sagas';
import brandEditPageSaga from '@/features/brandEditPage/sagas';
import brandsPageSaga from '@/features/brandsPage/sagas';
import campaignCreatePageSaga from '@/features/campaignCreatePage/sagas';
import campaignPageSaga from '@/features/campaignPage/sagas';
import campaignsPageSaga from '@/features/campaignsPage/sagas';
import campaignsStatisticsDetailsPageSaga from '@/features/campaignsStatisticsDetailsPage/sagas';
import campaignsStatisticsPageSaga from '@/features/campaignsStatisticsPage/sagas';
import contactsEditPageSaga from '@/features/contactsEditPage/sagas';
import currentUserSaga from '@/features/currentUser/sagas';
import efsVisitRecordsPageSaga from '@/features/efsVisitRecordsPage/sagas';
import executiveReportsPageSaga from '@/features/executiveReportsPage/sagas';
import feedLayoutEditPageSaga from '@/features/feedLayoutEditPage/sagas';
import giftCertificatesPage from '@/features/giftCertificatesPage/sagas';
import giftSetEditPageSaga from '@/features/giftSetEditPage/sagas';
import giftSetPageSaga from '@/features/giftSetPage/sagas';
import giftSetsPageSaga from '@/features/giftSetsPage/sagas';
import invitesSaga from '@/features/invites/sagas';
import kkmCreatePageSaga from '@/features/kkmCreatePage/sagas';
import kkmEditPageSaga from '@/features/kkmEditPage/sagas';
import kkmPageSaga from '@/features/kkmPage/sagas';
import kkmsPageSaga from '@/features/kkmsPage/sagas';
import locationEditPageSaga from '@/features/locationEditPage/sagas';
import locationsPageSaga from '@/features/locationsPage/sagas';
import locationsStatisticsPageSaga from '@/features/locationsStatisticsPage/sagas';
import loyaltyHistoryPageSaga from '@/features/loyaltyHistoryPage/sagas';
import mallBonusCreatePageSaga from '@/features/mallBonusCreatePage/sagas';
import mallBonusesPageSaga from '@/features/mallBonusesPage/sagas';
import mapPageSaga from '@/features/mapPage/sagas';
import merchantCreatePageSaga from '@/features/merchantCreatePage/sagas';
import merchantEditPageSaga from '@/features/merchantEditPage/sagas';
import merchantPageSaga from '@/features/merchantPage/sagas';
import merchantsPageSaga from '@/features/merchantsPage/sagas';
import merchantsReconciliationArchivePageSaga from '@/features/merchantsReconciliationArchivePage/sagas';
import merchantsReconciliationDetailsPageSaga from '@/features/merchantsReconciliationDetailsPage/sagas';
import merchantsReconciliationPageSaga from '@/features/merchantsReconciliationPage/sagas';
import merchantsReconciliationReceiptsSaga from '@/features/merchantsReconciliationReceiptsPage/sagas';
import merchantsReconciliationRefundsSaga from '@/features/merchantsReconciliationRefundsPage/sagas';
import parkingLevelThreeWhitelistAddPageSaga from '@/features/parkingLevelThreeWhitelistAddPage/sagas';
import parkingLevelThreeWhitelistPageSaga from '@/features/parkingLevelThreeWhitelistPage/sagas';
import parkingTowerStopListAddPageSaga from '@/features/parkingTowerStopListAddPage/sagas';
import parkingTowerStopListPageSaga from '@/features/parkingTowerStopListPage/sagas';
import parkingWhitelistDynamicFilterPage from '@/features/parkingWhitelistDynamicFilterPage/sagas';
import parkingWhitelistPageGroupCreateSaga from '@/features/parkingWhitelistGroupCreatePage/sagas';
import parkingWhitelistGroupDetailsPageSaga from '@/features/parkingWhitelistGroupDetailsPage/sagas';
import parkingWhitelistPageGroupsSaga from '@/features/parkingWhitelistGroupsPage/sagas';
import parkingWhitelistPageSaga from '@/features/parkingWhitelistPage/sagas';
import parkingWhitelistStaticFilterPage from '@/features/parkingWhitelistStaticFilterPage/sagas';
import passwordRecoverySaga from '@/features/passwordRecovery/sagas';
import personnelSaga from '@/features/personnel/sagas';
import personnelEditPageSaga from '@/features/personnelEditPage/sagas';
import personnelPageSaga from '@/features/personnelPage/sagas';
import productEditPageSaga from '@/features/productEditPage/sagas';
import productPageSaga from '@/features/productPage/sagas';
import productsCategoryPageSaga from '@/features/productsCategoryPage/sagas';
import productsPageSaga from '@/features/productsPage/sagas';
import productsViewPageSaga from '@/features/productsViewPage/sagas';
import purchaseHistoryPageSaga from '@/features/purchaseHistoryPage/sagas';
import purchasesStatisticsPageSaga from '@/features/purchasesStatisticsPage/sagas';
import questionCreatePageSaga from '@/features/questionCreatePage/sagas';
import questionEditPageSaga from '@/features/questionEditPage/sagas';
import questionsPageSaga from '@/features/questionsPage/sagas';
import reconciliationArchivePageSaga from '@/features/reconciliationArchivePage/sagas';
import reconciliationArchiveReceiptsPageSaga from '@/features/reconciliationArchiveReceiptsPage/sagas';
import reconciliationArchiveRefundsPageSaga from '@/features/reconciliationArchiveRefundsPage/sagas';
import reconciliationHistoryPageSaga from '@/features/reconciliationHistoryPage/sagas';
import reconciliationPageSaga from '@/features/reconciliationPage/sagas';
import reconciliationPeriodBonusDetailsPageSaga from '@/features/reconciliationPeriodBonusDetailsPage/sagas';
import reconciliationPromotionsPageSaga from '@/features/reconciliationPromotionsPage/sagas';
import reconciliationReceiptBonusDetailsPageSaga from '@/features/reconciliationReceiptBonusDetailsPage/sagas';
import reconciliationReceiptsPageSaga from '@/features/reconciliationReceiptsPage/sagas';
import reconciliationRefundsPageSaga from '@/features/reconciliationRefundsPage/sagas';
import reconciliationStoreBonusDetailsPageSaga from '@/features/reconciliationStoreBonusDetailsPage/sagas';
import reconciliationStoreToCpPageSaga from '@/features/reconciliationStoreToCpPage/sagas';
import salesTurnoverDetailsPage from '@/features/salesTurnoverDetailsPage/sagas';
import salesTurnoversPage from '@/features/salesTurnoversPage/sagas';
import siteSyncPageSaga from '@/features/siteSyncPage/sagas';
import statisticsSaga from '@/features/statisticsPage/sagas';
import storeBonusCreatePageSaga from '@/features/storeBonusCreatePage/sagas';
import storeBonusesPageSaga from '@/features/storeBonusesPage/sagas';
import storeCreatePageSaga from '@/features/storeCreatePage/sagas';
import storePageSaga from '@/features/storePage/sagas';
import storeReceiptsPageSaga from '@/features/storeReceiptsPage/sagas';
import storesPageSaga from '@/features/storesPage/sagas';
import uploadSaga from '@/features/upload/sagas';
import userDeactivateSaga from '@/features/userDeactivate/sagas';
import userPageSaga from '@/features/userPage/sagas';
import usersPageSaga from '@/features/usersPage/sagas';
import usersStatisticsPageSaga from '@/features/usersStatisticsPage/sagas';

export default function*() {
  yield spawn(anonymousPurchasesStatisticsPageSaga);
  yield spawn(applicationSettingsSaga);
  yield spawn(authPagesSaga);
  yield spawn(authSaga);
  yield spawn(beaconsPageSaga);
  yield spawn(benefitCreatePageSaga);
  yield spawn(benefitGiveOutPageSaga);
  yield spawn(benefitItemsPageSaga);
  yield spawn(benefitsPageSaga);
  yield spawn(benefitsSaga);
  yield spawn(bonusTransactionLevelHistoryPage);
  yield spawn(bonusesHistoryPageSaga);
  yield spawn(brandCreatePageSaga);
  yield spawn(brandEditPageSaga);
  yield spawn(brandsPageSaga);
  yield spawn(brandsSaga);
  yield spawn(campaignCreatePageSaga);
  yield spawn(campaignPageSaga);
  yield spawn(campaignsPageSaga);
  yield spawn(campaignsSaga);
  yield spawn(campaignsStatisticsDetailsPageSaga);
  yield spawn(campaignsStatisticsPageSaga);
  yield spawn(contactsEditPageSaga);
  yield spawn(creditCardsSaga);
  yield spawn(currentUserSaga);
  yield spawn(efsVisitRecordsPageSaga);
  yield spawn(executiveReportsPageSaga);
  yield spawn(feedLayoutEditPageSaga);
  yield spawn(giftCertificatesPage);
  yield spawn(giftSetEditPageSaga);
  yield spawn(giftSetPageSaga);
  yield spawn(giftSetsPageSaga);
  yield spawn(historySaga);
  yield spawn(invitesSaga);
  yield spawn(kkmCreatePageSaga);
  yield spawn(kkmEditPageSaga);
  yield spawn(kkmPageSaga);
  yield spawn(kkmsPageSaga);
  yield spawn(locationEditPageSaga);
  yield spawn(locationSaga);
  yield spawn(locationsPageSaga);
  yield spawn(locationsStatisticsPageSaga);
  yield spawn(loyaltiesSaga);
  yield spawn(loyaltyHistoryPageSaga);
  yield spawn(mallBonusCreatePageSaga);
  yield spawn(mallBonusesPageSaga);
  yield spawn(mapPageSaga);
  yield spawn(merchantCreatePageSaga);
  yield spawn(merchantEditPageSaga);
  yield spawn(merchantPageSaga);
  yield spawn(merchantsPageSaga);
  yield spawn(merchantsPageSaga);
  yield spawn(merchantsReconciliationArchivePageSaga);
  yield spawn(merchantsReconciliationDetailsPageSaga);
  yield spawn(merchantsReconciliationPageSaga);
  yield spawn(merchantsReconciliationReceiptsSaga);
  yield spawn(merchantsReconciliationRefundsSaga);
  yield spawn(parkingLevelThreeWhitelistAddPageSaga);
  yield spawn(parkingLevelThreeWhitelistPageSaga);
  yield spawn(parkingLevelThreeWhitelistSaga);
  yield spawn(parkingLevelThreeWhitelistSaga);
  yield spawn(parkingTowerStopListAddPageSaga);
  yield spawn(parkingTowerStopListAddPageSaga);
  yield spawn(parkingTowerStopListPageSaga);
  yield spawn(parkingTowerStopListSaga);
  yield spawn(parkingWhitelistDynamicFilterPage);
  yield spawn(parkingWhitelistFilterSaga);
  yield spawn(parkingWhitelistGroupDetailsPageSaga);
  yield spawn(parkingWhitelistGroupsSaga);
  yield spawn(parkingWhitelistPageGroupCreateSaga);
  yield spawn(parkingWhitelistPageGroupsSaga);
  yield spawn(parkingWhitelistPageSaga);
  yield spawn(parkingWhitelistSaga);
  yield spawn(parkingWhitelistStaticFilterPage);
  yield spawn(passwordRecoverySaga);
  yield spawn(personnelEditPageSaga);
  yield spawn(personnelPageSaga);
  yield spawn(personnelSaga);
  yield spawn(productCategorySaga);
  yield spawn(productEditPageSaga);
  yield spawn(productPageSaga);
  yield spawn(productViewSaga);
  yield spawn(productsCategoryPageSaga);
  yield spawn(productsPageSaga);
  yield spawn(productsViewPageSaga);
  yield spawn(purchaseHistoryPageSaga);
  yield spawn(purchasesStatisticsPageSaga);
  yield spawn(questionCreatePageSaga);
  yield spawn(questionEditPageSaga);
  yield spawn(questionsPageSaga);
  yield spawn(questionsSaga);
  yield spawn(reconciliationArchivePageSaga);
  yield spawn(reconciliationArchiveReceiptsPageSaga);
  yield spawn(reconciliationArchiveRefundsPageSaga);
  yield spawn(reconciliationHistoryPageSaga);
  yield spawn(reconciliationPageSaga);
  yield spawn(reconciliationPeriodBonusDetailsPageSaga);
  yield spawn(reconciliationPromotionsPageSaga);
  yield spawn(reconciliationReceiptBonusDetailsPageSaga);
  yield spawn(reconciliationReceiptsPageSaga);
  yield spawn(reconciliationRefundsPageSaga);
  yield spawn(reconciliationStoreBonusDetailsPageSaga);
  yield spawn(reconciliationStoreToCpPageSaga);
  yield spawn(salesTurnoverDetailsPage);
  yield spawn(salesTurnoversPage);
  yield spawn(siteSyncPageSaga);
  yield spawn(statisticsSaga);
  yield spawn(storeBonusCreatePageSaga);
  yield spawn(storeBonusesPageSaga);
  yield spawn(storeCreatePageSaga);
  yield spawn(storePageSaga);
  yield spawn(storeReceiptsPageSaga);
  yield spawn(storesPageSaga);
  yield spawn(storesPageSaga);
  yield spawn(storesSaga);
  yield spawn(uploadSaga);
  yield spawn(userDeactivateSaga);
  yield spawn(userPageSaga);
  yield spawn(usersPageSaga);
  yield spawn(usersSaga);
  yield spawn(usersStatisticsPageSaga);
}
