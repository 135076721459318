import { T } from 'ramda';
import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { createEditProductLink } from '@/features/productEditPage/serializer';

const mapStateToProps = (state, { productId }) => ({
  editPageUrl: createEditProductLink(productId),
  permission: T,
});

export default connect(mapStateToProps)(EditButton);
