import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

export default (saga, path) =>
  function*(...args) {
    const value = yield* saga(...args);

    yield put(push(path));

    return value;
  };
