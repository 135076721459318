import { getRussianName } from '@esentai/core/features/locations/selectors';
import { connect } from 'react-redux';

import Option from '@/components/AutocompleteBase/Option';

const mapStateToProps = (state, { value }) => ({
  label: getRussianName(state, value),
});

export default connect(mapStateToProps, () => ({}))(Option);
