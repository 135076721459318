import {
  getEfsVisitRecordCreated,
  getEfsVisitRecordPhoneNumber,
  getEfsVisitRecordUserId,
} from '@esentai/core/features/efsVisitRecords/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: efsVisitRecordId }) => ({
  recordCreated: getEfsVisitRecordCreated(state, efsVisitRecordId),
  phoneNumber: getEfsVisitRecordPhoneNumber(state, efsVisitRecordId),
  userId: getEfsVisitRecordUserId(state, efsVisitRecordId),
  efsVisitRecordId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
