import { TableRow } from '@material-ui/core';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = () => (
  <TableRow>
    <CondenseTableCell>Старый ID</CondenseTableCell>
    <CondenseTableCell>Новый ID</CondenseTableCell>
    <CondenseTableCell>Дата переноса</CondenseTableCell>
    <CondenseTableCell style={{ textAlign: 'left' }}>
      Имя менеджера молла
    </CondenseTableCell>
  </TableRow>
);

export default HeadRow;
