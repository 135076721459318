import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { map, without } from 'ramda';
import React, { Component } from 'react';

import { getStrategy } from '@/components/StatisticsTickDate/strategies';
import { getStatId, getStatsInnerJoin } from '@/features/statisticsPage/utils';

import Metric from './Metric';

const REST_STATS_COUNT = 3;

class TooltipContent extends Component {
  render() {
    const {
      classes,
      stats,
      selectedStatsIds,
      date,
      selectedDateIndex,
      getValue,
    } = this.props;

    const primaryDescriptors = getStatsInnerJoin(stats, selectedStatsIds);
    const secondaryDescriptors = getStatsInnerJoin(
      stats,
      this.getSecondaryStats(),
    );

    return (
      <div>
        <Typography variant="caption" className={classes.date}>
          {this.formatDate(date)}
        </Typography>
        <div className={classes.primaryFacts}>
          {primaryDescriptors.map(descriptor => (
            <Metric
              primary
              classes={classes}
              key={getStatId(descriptor)}
              descriptor={descriptor}
              selectedDateIndex={selectedDateIndex}
              getValue={getValue}
            />
          ))}
        </div>
        <div className={classes.wrapper}>
          {secondaryDescriptors.map(descriptor => (
            <Metric
              classes={classes}
              key={getStatId(descriptor)}
              descriptor={descriptor}
              selectedDateIndex={selectedDateIndex}
              getValue={getValue}
            />
          ))}
        </div>
      </div>
    );
  }

  getSecondaryStats = () => {
    const { stats, selectedStatsIds } = this.props;

    const statsIds = map(getStatId, stats);

    return without(selectedStatsIds, statsIds).slice(0, REST_STATS_COUNT);
  };

  formatDate = date => {
    const { scale } = this.props;

    if (!scale) {
      return null;
    }

    const { format } = getStrategy(scale);

    return format(date);
  };
}

TooltipContent.propTypes = {
  scale: PropTypes.string.isRequired,
  selectedDateIndex: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(Date),
  selectedStatsIds: PropTypes.arrayOf(PropTypes.string),
  stats: PropTypes.arrayOf(PropTypes.object).isRequired,
  getValue: PropTypes.func.isRequired,
};

TooltipContent.defaultProps = {
  date: null,
  selectedStatsIds: [],
  rightFirst: false,
};

export default TooltipContent;
