import PropTypes from 'prop-types';
import React from 'react';

import DistributionChart from '@/components/DistributionChart';

import DayBar from './DayBar';
import EveningBar from './EveningBar';
import MorningBar from './MorningBar';

const TimeDistributionChart = ({
  percentageByMorning,
  percentageByDay,
  percentageByEvening,
}) => {
  const total = percentageByMorning + percentageByDay + percentageByEvening;

  if (total === 0) {
    return '—';
  }

  return (
    <DistributionChart>
      <MorningBar percentage={percentageByMorning} label="Утро" />
      <DayBar percentage={percentageByDay} label="Обед" />
      <EveningBar percentage={percentageByEvening} label="Вечер" />
    </DistributionChart>
  );
};

TimeDistributionChart.propTypes = {
  percentageByMorning: PropTypes.number.isRequired,
  percentageByDay: PropTypes.number.isRequired,
  percentageByEvening: PropTypes.number.isRequired,
};

export default TimeDistributionChart;
