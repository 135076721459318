import { connect } from 'react-redux';

import { selectRentPlace } from '@/features/mapPage/actions';
import {
  isRentPlaceHighlighted,
  isRentPlaceSelected,
} from '@/features/mapPage/selectors';

import RentPlace from './component';

const mapStateToProps = (state, { rentId }) => ({
  highlighted: isRentPlaceHighlighted(state, rentId),
  selected: isRentPlaceSelected(state, rentId),
});

const mapDispatchToProps = { onOpen: selectRentPlace };

export default connect(mapStateToProps, mapDispatchToProps)(RentPlace);
