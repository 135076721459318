import PropTypes from 'prop-types';
import React from 'react';

// https://github.com/mui-org/material-ui/blob/263b428f221be3b6d068865c48bf287570a9ae98/packages/material-ui/src/Chip/Chip.js#L12
const AVATAR_SIZE = 32;

const ChipProgressIndicator = ({ hasPreview, progress, ...props }) => {
  const translateX = hasPreview
    ? `calc(${progress * 100}% + ${AVATAR_SIZE * (1 - progress)}px)`
    : `${progress * 100}%`;

  return (
    <span
      aria-hidden
      style={{
        transform: `translate3d(${translateX}, 0, 0)`,
      }}
      {...props}
    />
  );
};

ChipProgressIndicator.propTypes = {
  hasPreview: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
};

export default ChipProgressIndicator;
