import { spawn, takeEvery } from 'redux-saga/effects';

import { RETRY_FETCH } from '@/features/campaignsStatisticsDetailsPage/types';
import { createStatisticsQueryWatcher } from '@/features/statisticsPage/sagas';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

const { createQuerySaga, synchronizeQuery } = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createStatisticsQueryWatcher(
  queryDuck,
  fetchItems,
);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
