import { findQuestionById } from '@esentai/core/features/questions/actions';
import {
  getQuestionLoadingError,
  isQuestionLoaded,
  isQuestionLoading,
} from '@esentai/core/features/questions/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { updateQuestion } from '@/features/questionEditPage/actions';
import { getQuestionId } from '@/features/questionEditPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import EditQuestion from './component';

const mapStateToProps = state => {
  const itemId = getQuestionId(state);

  return {
    error: getQuestionLoadingError(state, itemId),
    isLoaded: isQuestionLoaded(state, itemId),
    isLoading: isQuestionLoading(state, itemId),
    itemId,
  };
};

const mapDispatchToProps = { fetchItem: findQuestionById, updateQuestion };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(EditQuestion);
