import PropTypes from 'prop-types';
import React from 'react';

import OriginalPushNotification from '@/components/PushNotificationDevicePreview/PushNotification';
import { LOCALE_EN, LOCALE_RU } from '@/hocs/withDevicePreview/consts';

const PushNotification = ({
  selectByLocale,
  prePushEnglishTitle,
  prePushRussianTitle,
  prePushEnglishMessage,
  prePushRussianMessage,
}) => {
  const title = selectByLocale({
    [LOCALE_EN]: prePushEnglishTitle,
    [LOCALE_RU]: prePushRussianTitle,
  });
  const message = selectByLocale({
    [LOCALE_EN]: prePushEnglishMessage,
    [LOCALE_RU]: prePushRussianMessage,
  });

  return <OriginalPushNotification title={title} message={message} />;
};

PushNotification.propTypes = {
  selectByLocale: PropTypes.func.isRequired,
  prePushEnglishTitle: PropTypes.string.isRequired,
  prePushRussianTitle: PropTypes.string.isRequired,
  prePushEnglishMessage: PropTypes.string.isRequired,
  prePushRussianMessage: PropTypes.string.isRequired,
};

export default PushNotification;
