import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import ProductCategoryNameLazy from '@/containers/ProductCategoryNameLazy';
import ProductViewNameLazy from '@/containers/ProductViewNameLazy';
import StoreNameLazy from '@/containers/StoreNameLazy';
import { canUpdateProduct } from '@/features/productsPage/permissions';
import { formatMoney } from '@/utils/format';

import Actions from './Actions';

const Row = ({
  store,
  titleRu,
  subtitleRu,
  productId,
  productPageUrl,
  sku,
  price,
  category,
  views,
}) => (
  <TableRow>
    <CondenseTableCell>
      <Link to={productPageUrl}>{productId}</Link>
    </CondenseTableCell>

    <CondenseTableCell>{titleRu}</CondenseTableCell>

    <CondenseTableCell>{subtitleRu}</CondenseTableCell>

    <CondenseTableCell>
      <StoreNameLazy storeId={store} />
    </CondenseTableCell>

    <CondenseTableCell>{sku}</CondenseTableCell>

    <CondenseTableCell>{formatMoney(price)}</CondenseTableCell>

    <CondenseTableCell>
      {category > 0 ? (
        <ProductCategoryNameLazy productCategoryId={category} />
      ) : (
        ''
      )}
    </CondenseTableCell>

    <CondenseTableCell>
      {views && views.length > 0
        ? views.map((view, index) => (
            // eslint-disable-next-line react/jsx-indent
            <>
              <ProductViewNameLazy productViewId={view} key={view} />
              {index + 1 !== views.length && ','}
            </>
          ))
        : ''}
    </CondenseTableCell>

    <CanView permission={canUpdateProduct} productId={productId}>
      <CondenseTableCell>
        <Actions productId={productId} />
      </CondenseTableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  titleRu: PropTypes.string.isRequired,
  subtitleRu: PropTypes.string.isRequired,
  store: PropTypes.string.isRequired,
  productId: PropTypes.any.isRequired,
  productPageUrl: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  category: PropTypes.number.isRequired,
  views: PropTypes.array.isRequired,
};

Row.defaultProps = {
  categoryKeys: [],
  brandsIds: [],
};

export default Row;
