import createAPISerializer from '@esentai/core/serializers/api';
import { array, maybe, number, string } from '@esentai/core/serializers/params';

import {
  EMAIL_KEY,
  FULL_NAME_KEY,
  LOCATIONS_IDS_KEY,
  PASSWORD_KEY,
  ROLE_KEY,
  STORE_IDS_KEY,
  TOKEN_KEY,
} from './consts/keys';

export default createAPISerializer({
  [EMAIL_KEY]: string(),
  [FULL_NAME_KEY]: string(),
  [LOCATIONS_IDS_KEY]: maybe(array(number())),
  [PASSWORD_KEY]: string(),
  [ROLE_KEY]: string(),
  [STORE_IDS_KEY]: maybe(array(number())),
  [TOKEN_KEY]: string(),
});
