import createRbacPolicy from '@esentai/core/policies/rbac';
import { policy, where } from '@esentai/core/policy-dsl';

import {
  BITRIX_ROLE,
  EFS_SALES_ROLE,
  GUEST_EFS_ROLE,
  GUEST_PARKING_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_ADMINISTRATOR_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_SELLER_ROLE,
  MALL_VALET_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
} from '@/features/personnel/consts/roles';

import {
  bitrix as bitrixPermissions,
  efsSales as efsSalesPermissions,
  guestEfs as guestEfsPermissions,
  guestParking as guestParkingPermissions,
  mallAccountant as mallAccountantPermissions,
  mallAdministrator as mallAdministratorPermissions,
  mallCommissar as mallCommissarPermissions,
  mallManager as mallManagerPermissions,
  mallReception as mallReceptionPermissions,
  mallSeller as mallSellerPermissions,
  mallValet as mallValetPermissions,
  managingTeamMember as managingTeamMemberPermissions,
  storeAdministrator as storeAdministratorPermissions,
  storeManager as storeManagerPermissions,
  storeSeller as storeSellerPermissions,
} from './permissions';
import { getRole } from './selectors';

const permissions = policy(
  where(MALL_ADMINISTRATOR_ROLE, ...mallAdministratorPermissions),
  where(MALL_MANAGER_ROLE, ...mallManagerPermissions),
  where(MALL_SELLER_ROLE, ...mallSellerPermissions),
  where(STORE_ADMINISTRATOR_ROLE, ...storeAdministratorPermissions),
  where(STORE_MANAGER_ROLE, ...storeManagerPermissions),
  where(STORE_SELLER_ROLE, ...storeSellerPermissions),
  where(MALL_COMMISSAR_ROLE, ...mallCommissarPermissions),
  where(MALL_ACCOUNTANT_ROLE, ...mallAccountantPermissions),
  where(MALL_VALET_ROLE, ...mallValetPermissions),
  where(GUEST_PARKING_ROLE, ...guestParkingPermissions),
  where(BITRIX_ROLE, ...bitrixPermissions),
  where(EFS_SALES_ROLE, ...efsSalesPermissions),
  where(GUEST_EFS_ROLE, ...guestEfsPermissions),
  where(MALL_RECEPTION_ROLE, ...mallReceptionPermissions),
  where(MANAGING_TEAM_MEMBER_ROLE, ...managingTeamMemberPermissions),
);

export const authorize = createRbacPolicy(permissions, getRole);
export const authorizeIf = ({ permission }) => authorize(permission);
