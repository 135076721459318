import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Fragment } from 'react';

const FormatterLayout = ({ captionText, text, ...props }) => (
  <Fragment>
    <Typography {...omit(['itemId', 'dispatch'], props)}>{text}</Typography>
    {captionText ? <Typography>{captionText}</Typography> : null}
  </Fragment>
);

FormatterLayout.defaultProps = {
  captionText: null,
};

FormatterLayout.propTypes = {
  text: PropTypes.string.isRequired,
  captionText: PropTypes.string,
};

export default FormatterLayout;
