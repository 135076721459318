import Grid from '@material-ui/core/Grid';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';

import Table from './Table';

const CarListPage = ({ classes, location }) => {
  const [carsInfo, setCarsInfo] = useState({
    loading: false,
    data: [],
    error: null,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const loadCarlistData = async (params = {}) => {
    setCarsInfo(prevState => ({
      ...prevState,
      loading: true,
    }));

    const filters = {
      offset: page * pageSize,
      limit: pageSize,
      ...params,
    };

    try {
      const response = await api.doGet(`/user-vehicle-grouped`, { ...filters });

      setCarsInfo(prevState => ({
        ...prevState,
        data: _get(response, `payload.user_vehicle_v2`, []),
      }));

      setTotalCount(response.meta.total);
    } catch (error) {
      setCarsInfo(prevState => ({
        ...prevState,
        error,
      }));

      throw error;
    } finally {
      setCarsInfo(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onSetParams = () => {
    const searchList = decodeURIComponent(new URLSearchParams(location.search));
    const filterList = searchList.toString().split('&');
    const params = {};

    filterList.map(filter => {
      const value = filter.split('=');

      params[`${value[0]}`] = value[1];

      return filter;
    });

    return { params };
  };

  const onSetPage = newPage => {
    setPage(newPage);
  };

  const onSetPageSize = newPageSize => {
    setPage(0);
    setPageSize(newPageSize);
  };

  useEffect(() => {
    const { params } = onSetParams();

    loadCarlistData(params);
  }, [pageSize, page, location.search]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>Список авто</PageTitle>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        <Table
          limit={pageSize}
          items={carsInfo.data}
          isLoading={carsInfo.loading}
          totalCount={totalCount}
          page={page}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
      </PageContent>
    </Page>
  );
};

CarListPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default CarListPage;
