import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getParkingWhitelistGroupsError,
  getItemsIds: getParkingWhitelistGroupsIds,
  getItemsMeta: getParkingWhitelistGroupsMeta,
  getItemsTotalCount: getParkingWhitelistGroupsTotalCount,
  isLoadingItems: isLoadingParkingWhitelistGroups,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
