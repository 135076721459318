export default {
  selectMenu: {
    fontSize: 24,
    minHeight: 'inherit',
    textDecoration: 'underline',
  },

  icon: {
    right: 5,
  },
};
