import { getFiltersForStoreBonus } from '@esentai/core/features/storeBonuses/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { submit } from '@/features/storeBonusCreatePage/actions';
import { getStoreBonusId } from '@/features/storeBonusCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import SecondStep from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getStoreBonusId(state);

  return {
    filters: getFiltersForStoreBonus(state, id),
    id,
  };
};

const mapDispatchToProps = { onSubmit: submit };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(SecondStep);
