import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import BenefitTitleLazy from '@/containers/BenefitTitleLazy';

const SelectedItem = ({ onDelete, value }) => (
  <Chip label={<BenefitTitleLazy itemId={value} />} onDelete={onDelete} />
);

SelectedItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

export default SelectedItem;
