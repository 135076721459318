import PropTypes from 'prop-types';
import React from 'react';

const FirstFloor = ({ mapHeight, mapWidth, MapTransformer, ...props }) => (
  <MapTransformer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${mapWidth} ${mapHeight}`}
      {...props}
    >
      <g id="f7ee4c58-ec66-4d3b-a00c-eed7918d75be" data-name="Background">
        <rect
          id="b64292b6-efd0-44b3-aad5-d6d80ab3a600"
          data-name="Background"
          width="1420"
          height="1005"
          fill="#ededed"
        />
        <rect x="884" y="188" width="78" height="432" fill="#e1e0e0" />
        <rect y="661" width="1420" height="24" fill="#f7f7f7" />
        <rect y="698" width="1420" height="24" fill="#f7f7f7" />
        <path
          d="M919.34,337.85v-3.74H920V337h4v-2.08h.69V337h4.14v-2.88h.69v3.74Z"
          fill="#888685"
        />
        <path
          d="M927.59,328.82a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.54,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84V332h.87a1.55,1.55,0,0,0,1-.28,1.11,1.11,0,0,0,.36-.92,1.21,1.21,0,0,0-.32-.94,1.43,1.43,0,0,0-1-.29h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.83,1.83,0,0,1-.51-1.44,1.94,1.94,0,0,1,.51-1.5,2.19,2.19,0,0,1,1.49-.45h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.08,1.08,0,0,0,.3.85,1.17,1.17,0,0,0,.81.27H923a1.61,1.61,0,0,0,.39,0,1.25,1.25,0,0,0,.33-.16,3.8,3.8,0,0,0,.33-.29c.11-.12.24-.28.39-.47l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.93,2.93,0,0,1,.63-.06Z"
          fill="#888685"
        />
        <path
          d="M926.57,323.25h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.55,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.41,2.41,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.34,1.28,1.28,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.32,1.16,1.16,0,0,0-.39,1,1.19,1.19,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M923,318.54a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.28,1.28,0,0,0,.35.91,1.35,1.35,0,0,0,1,.37h6.54v.82H921V321h.84v0a1.84,1.84,0,0,1-.66-.55,1.66,1.66,0,0,1-.27-1,1.61,1.61,0,0,1,.48-1.26,2.24,2.24,0,0,1,1.53-.43h6.59v.82Z"
          fill="#888685"
        />
        <path
          d="M921,316.53v-.66h-1.66v-.82H921v-1.19h.63v1.19h6.49a.71.71,0,0,0,.54-.16.75.75,0,0,0,.16-.56v-.47h.69v.56a1.59,1.59,0,0,1-.32,1.13,1.5,1.5,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M929.51,309.36h-.77v0a1.74,1.74,0,0,1,.6.53,1.59,1.59,0,0,1,.26.94,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H923a1.83,1.83,0,0,0-1,.23.94.94,0,0,0-.35.85,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.8h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.31a1.23,1.23,0,0,0-1.35-1.31h-3a.57.57,0,0,1,.14.13l.17.23q.09.13.21.36c.08.15.18.34.29.57a5.89,5.89,0,0,0,.28.52,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.86,1.86,0,0,0,.56.07h.65a1.58,1.58,0,0,0,1-.27A1,1,0,0,0,928.91,310.67Z"
          fill="#888685"
        />
        <path
          d="M919.34,307.11v-.82h.75v.82Zm1.66,0v-.82h8.51v.82Z"
          fill="#888685"
        />
        <path
          d="M929.6,300.38a1.92,1.92,0,0,1-2.07,2.17H926v-.82h1.6a1.44,1.44,0,0,0,1-.33,1.35,1.35,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.54,1.54,0,0,0-1-.31H927a3,3,0,0,0-.53.05,2,2,0,0,0-.46.18,2.31,2.31,0,0,0-.43.31c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.44-.63.61a3.55,3.55,0,0,1-.57.43,2.27,2.27,0,0,1-.6.26,3.08,3.08,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.17,2.17,0,0,1,.5-1.58,2,2,0,0,1,1.49-.51h1.11v.81h-1.14a1.29,1.29,0,0,0-1,.32,1.35,1.35,0,0,0-.3,1,1.29,1.29,0,0,0,.31.91,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a3.52,3.52,0,0,1,.58-.42,2.78,2.78,0,0,1,.62-.25,3.11,3.11,0,0,1,.72-.08h.56a2.45,2.45,0,0,1,.83.13,1.57,1.57,0,0,1,.66.39,1.9,1.9,0,0,1,.44.68A2.53,2.53,0,0,1,929.6,300.38Z"
          fill="#888685"
        />
        <path
          d="M930.86,292.7v.82h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.48h-4.63a2,2,0,0,1-1.54-.52,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H921v-.82ZM927.54,296a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H923a1.43,1.43,0,0,0-1,.4,1.26,1.26,0,0,0-.36.91c0,.81.46,1.21,1.37,1.21Z"
          fill="#888685"
        />
        <path
          d="M927.56,290.43q1.35,0,1.35-1.17a1.29,1.29,0,0,0-.32-.88,1.33,1.33,0,0,0-1-.39H921v-.83h8.51V288h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.44H921v-.83Z"
          fill="#888685"
        />
        <path
          d="M929.51,282.53h-.77v0a1.71,1.71,0,0,1,.6.52,1.62,1.62,0,0,1,.26.95,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.46-.36-.73a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H923a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.92,1.92,0,0,1-.54-1.51,1.75,1.75,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57a5.58,5.58,0,0,0,.28.51,1.61,1.61,0,0,0,.3.37,1.27,1.27,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,928.91,283.83Z"
          fill="#888685"
        />
        <path
          d="M921.86,278v.14a1.37,1.37,0,0,0,.34,1,1.31,1.31,0,0,0,1,.37h6.33v.83H921v-.83h1v0a2.33,2.33,0,0,1-.39-.19,1.64,1.64,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.4,1.4,0,0,1-.1-.56Z"
          fill="#888685"
        />
        <path
          d="M926.57,272.51h.84A2.36,2.36,0,0,1,929,273a2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M919.34,583.56v-3.73H920v2.88h4v-2.09h.69v2.09h4.14v-2.88h.69v3.73Z"
          fill="#888685"
        />
        <path
          d="M927.59,574.53a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.55,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84v-.78h.87a1.56,1.56,0,0,0,1-.29,1.09,1.09,0,0,0,.36-.91,1.24,1.24,0,0,0-.32-.95,1.43,1.43,0,0,0-1-.28h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.85,1.85,0,0,1-.51-1.45,1.92,1.92,0,0,1,.51-1.49,2.19,2.19,0,0,1,1.49-.46h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.11,1.11,0,0,0,.3.86,1.17,1.17,0,0,0,.81.27H923a1.6,1.6,0,0,0,.39-.05,1.21,1.21,0,0,0,.33-.15,4.09,4.09,0,0,0,.33-.3c.11-.12.24-.27.39-.46l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.88,2.88,0,0,1,.63-.07Z"
          fill="#888685"
        />
        <path
          d="M926.57,569h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M923,564.26a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.29,1.29,0,0,0,.35.91,1.35,1.35,0,0,0,1,.36h6.54v.83H921v-.83h.84a1.87,1.87,0,0,1-.66-.56,1.93,1.93,0,0,1,.21-2.26,2.24,2.24,0,0,1,1.53-.44h6.59v.83Z"
          fill="#888685"
        />
        <path
          d="M921,562.25v-.66h-1.66v-.83H921v-1.18h.63v1.18h6.49a.76.76,0,0,0,.54-.15.79.79,0,0,0,.16-.57v-.46h.69v.55a1.63,1.63,0,0,1-.32,1.14,1.54,1.54,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M929.51,555.08h-.77v0a1.71,1.71,0,0,1,.6.52,1.64,1.64,0,0,1,.26,1,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.13,2.13,0,0,1-.41-.56c-.12-.23-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H923a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.9,1.9,0,0,1-.54-1.51,1.76,1.76,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57s.19.37.28.52a1.79,1.79,0,0,0,.3.36,1.09,1.09,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,928.91,556.38Z"
          fill="#888685"
        />
        <path
          d="M919.34,552.83V552h.75v.83Zm1.66,0V552h8.51v.83Z"
          fill="#888685"
        />
        <path
          d="M929.6,546.09a1.93,1.93,0,0,1-2.07,2.18H926v-.83h1.6a1.44,1.44,0,0,0,1-.33,1.33,1.33,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.48,1.48,0,0,0-1-.31H927a3,3,0,0,0-.53.05,2,2,0,0,0-.46.17,2.39,2.39,0,0,0-.43.32c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.45-.63.62a4.26,4.26,0,0,1-.57.43,2.24,2.24,0,0,1-.6.25,2.62,2.62,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.19,2.19,0,0,1,.5-1.58,2,2,0,0,1,1.49-.5h1.11v.81h-1.14a1.28,1.28,0,0,0-1,.31,1.35,1.35,0,0,0-.3,1,1.3,1.3,0,0,0,.31.92,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a4.22,4.22,0,0,1,.58-.42,2.34,2.34,0,0,1,.62-.24,2.63,2.63,0,0,1,.72-.09h.56a2.72,2.72,0,0,1,.83.13,1.7,1.7,0,0,1,.66.4,1.86,1.86,0,0,1,.44.67A2.53,2.53,0,0,1,929.6,546.09Z"
          fill="#888685"
        />
        <path
          d="M930.86,538.41v.83h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.47h-4.63a2.06,2.06,0,0,1-1.54-.51,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H921v-.83Zm-3.32,3.35a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H923a1.43,1.43,0,0,0-1,.4,1.22,1.22,0,0,0-.36.9c0,.81.46,1.22,1.37,1.22Z"
          fill="#888685"
        />
        <path
          d="M927.56,536.15q1.35,0,1.35-1.17a1.31,1.31,0,0,0-.32-.89,1.28,1.28,0,0,0-1-.38H921v-.83h8.51v.83h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.43H921v-.82Z"
          fill="#888685"
        />
        <path
          d="M929.51,528.25h-.77v0a1.74,1.74,0,0,1,.6.53,1.6,1.6,0,0,1,.26,1,1.7,1.7,0,0,1-.48,1.31,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.39,5.39,0,0,0-.63-1.17.71.71,0,0,0-.55-.29H923a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.11,1.11,0,0,0,.33.89,1.4,1.4,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.33,1.33,0,0,0-.34-.94,1.31,1.31,0,0,0-1-.36h-3a.39.39,0,0,1,.14.12l.17.23q.09.14.21.36c.08.15.18.34.29.58a5.58,5.58,0,0,0,.28.51,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.28A1,1,0,0,0,928.91,529.55Z"
          fill="#888685"
        />
        <path
          d="M921.86,523.67v.13a1.37,1.37,0,0,0,.34,1,1.28,1.28,0,0,0,1,.38h6.33V526H921v-.83h1v0a2.4,2.4,0,0,1-.39-.2,1.4,1.4,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.35,1.35,0,0,1-.1-.55Z"
          fill="#888685"
        />
        <path
          d="M926.57,518.22h.84a2.36,2.36,0,0,1,1.63.52,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.37,2.37,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.4h2.17a1.61,1.61,0,0,0,1.1-.35,1.27,1.27,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.4V519h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.22,1.22,0,0,0,.39,1,1.59,1.59,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <polygon
          points="1048.9 393.5 1019.2 383.5 989.5 393.5 989.5 261.5 1019.2 271.5 1048.9 261.5 1048.9 393.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1254.9 393.5 1225.2 383.5 1195.5 393.5 1195.5 261.5 1225.2 271.5 1254.9 261.5 1254.9 393.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1050.2 227.2 1060.2 197.5 1050.2 167.8 1182.2 167.8 1172.2 197.5 1182.2 227.2 1050.2 227.2"
          fill="#e1e0e0"
        />
        <polygon
          points="1180.09 531.52 1087.12 531.52 1087.12 455.5 1013.1 455.5 1013.1 474.19 991.91 474.19 991.91 620 1013.1 620 1087.12 620 1087.12 596.32 1180.09 596.32 1180.09 531.52"
          fill="#e1e0e0"
        />
        <path
          d="M1146,61.71V0h-26V56.61l-408.91,54.2a62,62,0,0,0-118,12.73L212,176.69V673h26V199.31l355.77-49.62A62,62,0,0,0,716,136.4l413.29-54.79L1297,214.29v261L1177,666.08l22,13.84,124-197.17v-281ZM654,171a36,36,0,1,1,36-36A36,36,0,0,1,654,171Z"
          fill="#f7f7f7"
        />
        <path
          d="M1010.48,552.33h3.74V553h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1019.51,560.58a2.19,2.19,0,0,1-.47,1.48,2,2,0,0,1-1.55.53A1.87,1.87,0,0,1,1016,562a2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.5,1.5,0,0,0,.29,1,1.07,1.07,0,0,0,.91.36,1.2,1.2,0,0,0,.94-.32,1.43,1.43,0,0,0,.29-1v-.26a1.5,1.5,0,0,0-.21-.81,3.78,3.78,0,0,0-.81-.81l-.87-.7a4.25,4.25,0,0,1-1-1,1.83,1.83,0,0,1-.31-1.1v-.24a1.86,1.86,0,0,1,.46-1.32,1.84,1.84,0,0,1,1.44-.51,1.93,1.93,0,0,1,1.5.51,2.15,2.15,0,0,1,.45,1.48v.62h-.77v-.65a1.51,1.51,0,0,0-.27-1,1.14,1.14,0,0,0-.91-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81V556a1.58,1.58,0,0,0,0,.38,1,1,0,0,0,.15.33,3.8,3.8,0,0,0,.29.33,5.71,5.71,0,0,0,.47.39l.87.7c.25.2.46.38.64.55a2.91,2.91,0,0,1,.41.5,2,2,0,0,1,.22.53,3,3,0,0,1,.06.63Z"
          fill="#888685"
        />
        <path
          d="M1025.08,559.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53A1.67,1.67,0,0,0,1024,555a1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1029.79,556a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V554h.82v.83h0a1.94,1.94,0,0,1,.56-.66,1.7,1.7,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.44,1.54v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1031.8,554h.66v-1.67h.82V554h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.61,1.61,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1039,562.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V556a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1037.66,561.9Z"
          fill="#888685"
        />
        <path
          d="M1041.22,552.33h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1045.64,552.33h4.19V553h-1.67v9.48h-.85V553h-1.67Z"
          fill="#888685"
        />
        <path
          d="M1055.26,560.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.58.61,2,2,0,0,1-1.57-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.57-.6,2,2,0,0,1,1.58.6,2.46,2.46,0,0,1,.54,1.68Zm-2.12,1.59a1.22,1.22,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.22,1.22,0,0,0-1-.39,1.19,1.19,0,0,0-1,.39,1.58,1.58,0,0,0-.34,1.09v4.34a1.58,1.58,0,0,0,.34,1.09A1.19,1.19,0,0,0,1053.14,561.9Z"
          fill="#888685"
        />
        <path
          d="M1063.12,554l-1.37,8.5h-.81l-1.18-6.52-1.22,6.52h-.81l-1.38-8.5h.83l1,6.58h0l1.23-6.58h.7l1.22,6.58h0l1-6.58Z"
          fill="#888685"
        />
        <path
          d="M1068.43,559.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1065v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.16,1.16,0,0,0-1-.38,1.18,1.18,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1072.19,554.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V554h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1078.33,552l-2.18,11.82h-.72l2.18-11.82Z" fill="#888685" />
        <path
          d="M1010.25,568.33h4.18V569h-1.67v9.48h-.85V569h-1.66Z"
          fill="#888685"
        />
        <path
          d="M1018.93,572a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V568.33h.82v2.5h0a2,2,0,0,1,.55-.66,1.73,1.73,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.43,1.54v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1025.37,575.56v.84a2.36,2.36,0,0,1-.52,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.54-1.67v-4.14a2.38,2.38,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1022v2.18a1.59,1.59,0,0,0,.35,1.09,1.27,1.27,0,0,0,1,.39c.84,0,1.25-.47,1.25-1.42v-.92Zm-3.4-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.16,1.16,0,0,0-1-.38,1.22,1.22,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1030,573.37v5.13h-.86V568.33h2a2.17,2.17,0,0,1,1.58.5,2,2,0,0,1,.49,1.42v1.5a2,2,0,0,1-.49,1.44,2.14,2.14,0,0,1-1.62.52h0l2.45,4.79h-1Zm0-4.35v4h1.06a1.34,1.34,0,0,0,1-.32,1.22,1.22,0,0,0,.31-.9v-1.59q0-1.2-1.29-1.2Z"
          fill="#888685"
        />
        <path
          d="M1034.66,568.33h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M1036.7,570h.66v-1.67h.83V570h1.18v.62h-1.18v6.49a.71.71,0,0,0,.16.54.75.75,0,0,0,.56.16h.46v.69h-.55a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.33-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1041.43,577.81h2.73v.69h-3.59v-.69l2.79-7.12h-2.59V570h3.45v.69Z"
          fill="#888685"
        />
        <path
          d="M1050.19,578.59a2.47,2.47,0,0,1-1-.2,1.93,1.93,0,0,1-.7-.55,2.41,2.41,0,0,1-.4-.8,3.87,3.87,0,0,1-.13-1v-5.28a4,4,0,0,1,.13-1,2.36,2.36,0,0,1,.4-.81,1.81,1.81,0,0,1,.7-.54,2.47,2.47,0,0,1,1-.2,2.69,2.69,0,0,1,1,.17,1.48,1.48,0,0,1,.66.47,1.87,1.87,0,0,1,.34.74,4.75,4.75,0,0,1,.09,1v1.2h-.84v-1.27a2.25,2.25,0,0,0-.25-1.16,1.13,1.13,0,0,0-1-.42,1.46,1.46,0,0,0-.66.14,1.29,1.29,0,0,0-.44.36,1.63,1.63,0,0,0-.25.55,2.81,2.81,0,0,0-.07.67v5.53a2.87,2.87,0,0,0,.07.67,1.63,1.63,0,0,0,.25.55,1.29,1.29,0,0,0,.44.36,1.34,1.34,0,0,0,.66.14,1.14,1.14,0,0,0,1-.44,2.29,2.29,0,0,0,.26-1.14v-1.81h.82v1.74a4.42,4.42,0,0,1-.09.92,1.86,1.86,0,0,1-.35.75,1.68,1.68,0,0,1-.66.49A2.59,2.59,0,0,1,1050.19,578.59Z"
          fill="#888685"
        />
        <path
          d="M1056.91,578.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V572a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1055.6,577.9Z"
          fill="#888685"
        />
        <path
          d="M1061.48,570.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V570h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1062.76,568.33h.82V578.5h-.82Z" fill="#888685" />
        <path
          d="M1064.8,570h.66v-1.67h.82V570h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.61,1.61,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1073,576.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.57.61,2,2,0,0,1-1.58-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.57.6,2.46,2.46,0,0,1,.54,1.68Zm-2.11,1.59a1.19,1.19,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.19,1.19,0,0,0-1-.39,1.22,1.22,0,0,0-1,.39,1.63,1.63,0,0,0-.34,1.09v4.34a1.63,1.63,0,0,0,.34,1.09A1.22,1.22,0,0,0,1070.89,577.9Z"
          fill="#888685"
        />
        <path
          d="M1077.71,572a1.63,1.63,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.33,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83V570h.83v.83h0a2.06,2.06,0,0,1,.56-.66,1.7,1.7,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.44,1.54v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1082.06,578.5l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M1088.35,568.33h.82V578.5h-.82Z" fill="#888685" />
        <path
          d="M1093.7,571.88a1.64,1.64,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.09,1.09,0,0,0-.85.35,1.41,1.41,0,0,0-.32,1v6.57h-.83V570h.83v.82h0a1.78,1.78,0,0,1,.52-.65,1.59,1.59,0,0,1,.94-.26,1.4,1.4,0,0,1,1.45,1h0a1.53,1.53,0,0,1,.57-.72,1.63,1.63,0,0,1,1-.28c1.07,0,1.61.65,1.61,2v6.64h-.83v-6.62a1.64,1.64,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.13,1.13,0,0,0-.81.32,1.33,1.33,0,0,0-.36.89v6.69h-.83Z"
          fill="#888685"
        />
        <path
          d="M1102.19,578.5v-.76h0a1.68,1.68,0,0,1-.52.59,1.62,1.62,0,0,1-1,.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.52,1.52,0,0,1,.37-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.46-.24.73-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V572a1.85,1.85,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.1,1.1,0,0,0-.89.32,1.42,1.42,0,0,0-.28.94v.82h-.79v-.76a2.13,2.13,0,0,1,.48-1.48,1.91,1.91,0,0,1,1.51-.53,1.75,1.75,0,0,1,1.46.53A2.48,2.48,0,0,1,1103,572v6.5Zm-1.3-.6a1.33,1.33,0,0,0,.94-.34,1.31,1.31,0,0,0,.36-1v-3a.53.53,0,0,1-.12.14l-.23.17-.37.22-.57.28a5.45,5.45,0,0,0-.51.29,1.2,1.2,0,0,0-.36.3,1.07,1.07,0,0,0-.22.39,1.91,1.91,0,0,0-.08.57v.64a1.58,1.58,0,0,0,.27,1A1.06,1.06,0,0,0,1100.89,577.9Z"
          fill="#888685"
        />
        <path
          d="M1104.19,570h.66v-1.67h.82V570h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.59,1.59,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1112.26,570l-2,8.61a2.63,2.63,0,0,1-.19.58,1,1,0,0,1-.73.6,2.93,2.93,0,0,1-.63.06h-.42v-.69h.41a.75.75,0,0,0,.56-.16,1.4,1.4,0,0,0,.28-.59l.19-.74h-.19L1107.8,570h.84l1.38,6.33h0l1.41-6.33Z"
          fill="#888685"
        />
        <path
          d="M1087.48,306.14h3.74v.69h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1096.51,314.39a2.17,2.17,0,0,1-.47,1.48,2,2,0,0,1-1.55.53,1.84,1.84,0,0,1-1.49-.56,2.2,2.2,0,0,1-.49-1.51v-.84h.78v.87a1.5,1.5,0,0,0,.29,1,1.07,1.07,0,0,0,.91.36,1.16,1.16,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-.95-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.84,1.84,0,0,1,1.44-.51,1.93,1.93,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.77v-.65a1.51,1.51,0,0,0-.27-1,1.14,1.14,0,0,0-.91-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.46,1.46,0,0,0,.05.38.82.82,0,0,0,.15.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M1102.08,313.37v.84a2.34,2.34,0,0,1-.51,1.63,2,2,0,0,1-1.55.56,2,2,0,0,1-1.59-.61,2.4,2.4,0,0,1-.55-1.67V310a2.39,2.39,0,0,1,.54-1.66,2.37,2.37,0,0,1,3.13,0,2.34,2.34,0,0,1,.53,1.64v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.68,1.68,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.58,1.58,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1106.79,309.76a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.77,1.77,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1108.8,307.81h.66v-1.67h.82v1.67h1.19v.63h-1.19v6.48a.8.8,0,0,0,.16.55.82.82,0,0,0,.56.15h.47v.69h-.56a1.56,1.56,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1116,316.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.58.58,0,0,1-.13.15l-.22.16a3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1114.66,315.71Z"
          fill="#888685"
        />
        <path
          d="M1118.22,306.14h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1122.6,316.31l2-10.17h1.05l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path
          d="M1128.88,307.81h.83v.85h0a2.15,2.15,0,0,1,.57-.65,1.65,1.65,0,0,1,1-.29,1.62,1.62,0,0,1,1.28.49,2.19,2.19,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.63,1.63,0,0,1-1-.27,1.9,1.9,0,0,1-.52-.63h0v2.16h-.83Zm3.35,2a1.57,1.57,0,0,0-.29-1,1.17,1.17,0,0,0-.93-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1137.66,316.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.58.58,0,0,1-.13.15l-.22.16a3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1136.35,315.71Z"
          fill="#888685"
        />
        <path
          d="M1142.23,308.66h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.54,1.54,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M1143.25,307.81h.66v-1.67h.83v1.67h1.18v.63h-1.18v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1150.32,309.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.09,1.09,0,0,0-.84.35,1.38,1.38,0,0,0-.33,1v6.57h-.82v-8.5h.82v.82h0a1.59,1.59,0,0,1,.52-.64,1.47,1.47,0,0,1,.93-.27,1.41,1.41,0,0,1,1.46,1h0a1.58,1.58,0,0,1,.56-.72,1.69,1.69,0,0,1,1-.28c1.07,0,1.6.65,1.6,1.95v6.64h-.82V309.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.1,1.1,0,0,0-.81.33,1.29,1.29,0,0,0-.36.88v6.69h-.82Z"
          fill="#888685"
        />
        <path
          d="M1159.81,313.37v.84a2.34,2.34,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.55-1.67V310a2.39,2.39,0,0,1,.54-1.66,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.56.58,2.34,2.34,0,0,1,.52,1.64v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2H1159v-1.53a1.68,1.68,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.63,1.63,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1164.52,309.76a1.58,1.58,0,0,0-.27-1,1.1,1.1,0,0,0-.9-.34,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1166.53,307.81h.66v-1.67h.83v1.67h1.18v.63H1168v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1174.45,314.39a2.13,2.13,0,0,1-.48,1.48,2,2,0,0,1-1.54.53,1.84,1.84,0,0,1-1.49-.56,2.2,2.2,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.18,1.18,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-1-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.78v-.65a1.45,1.45,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.48,1.48,0,0,0,0,.38,1,1,0,0,0,.16.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M225.42,679.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18H229l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M231.71,669.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M233.85,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M237.18,669.64h3.63v.69H238v4h2.08V675H238v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M245.21,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.16,1.16,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.57.57,0,0,1-.13.14,1.72,1.72,0,0,1-.23.17,2.59,2.59,0,0,1-.36.22l-.57.28a5.74,5.74,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,243.9,679.21Z"
          fill="#888685"
        />
        <path
          d="M249.78,672.16h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M254.22,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,252.92,679.21Z"
          fill="#888685"
        />
        <path
          d="M256.47,669.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.7,1.7,0,0,1,1.05-.29,1.63,1.63,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.52,1.52,0,0,1-1-.28,1.93,1.93,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M262.08,669.64h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M269.91,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,268.61,679.21Z"
          fill="#888685"
        />
        <path
          d="M276.24,671.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.39-6.93Z"
          fill="#888685"
        />
        <path
          d="M281.49,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11H278.1v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M286.2,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.36,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M289.26,677.86q0,1.35,1.17,1.35a1.27,1.27,0,0,0,.89-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.82v8.5h-.82V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.43-1.53v-6.58h.82Z"
          fill="#888685"
        />
        <path
          d="M298.17,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M702.81,679.81l2-10.17h1l2,10.17H707l-.48-2.49H704.1l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M709.1,669.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M711.24,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M714.57,669.64h3.63v.69h-2.77v4h2.08V675h-2.08v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M722.6,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.14,1.14,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.57.57,0,0,1-.13.14,1.72,1.72,0,0,1-.23.17,2.59,2.59,0,0,1-.36.22l-.57.28a4.63,4.63,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,721.29,679.21Z"
          fill="#888685"
        />
        <path
          d="M727.17,672.16H727a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M731.61,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.57,1.57,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.38,2.38,0,0,1,.56-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.15,2.15,0,0,1,.49-1.48,1.94,1.94,0,0,1,1.51-.53,1.79,1.79,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1,1,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,730.31,679.21Z"
          fill="#888685"
        />
        <path
          d="M733.86,669.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.68,1.68,0,0,1,1-.29,1.64,1.64,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.52,1.52,0,0,1-1-.28,1.93,1.93,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.21,1.21,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.31,1.31,0,0,0,.37,1,1.3,1.3,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M739.47,669.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M747.3,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.78,1.78,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,746,679.21Z"
          fill="#888685"
        />
        <path
          d="M753.63,671.31l-1.77,8.5h-.93l-1.77-8.5H750l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M758.88,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M763.59,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M766.65,677.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M775.56,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1180.2,679.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path d="M1186.48,669.64h.83v10.17h-.83Z" fill="#888685" />
        <path d="M1188.63,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M1192,669.64h3.63v.69h-2.78v4h2.09V675h-2.09v4.83H1192Z"
          fill="#888685"
        />
        <path
          d="M1200,679.81v-.76h0a1.73,1.73,0,0,1-.53.59,1.53,1.53,0,0,1-1,.26,1.68,1.68,0,0,1-1.31-.48,2.19,2.19,0,0,1-.46-1.53v-.69a2.49,2.49,0,0,1,.13-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,.94.94,0,0,0-.86-.34,1.14,1.14,0,0,0-.89.32,1.44,1.44,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.37,1.37,0,0,0,.94-.33,1.35,1.35,0,0,0,.36-1v-3a.39.39,0,0,1-.12.14,1.72,1.72,0,0,1-.23.17,2.25,2.25,0,0,1-.37.22c-.14.08-.34.17-.57.28a5.45,5.45,0,0,0-.51.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.79,1.79,0,0,0-.08.56v.64a1.7,1.7,0,0,0,.27,1A1.09,1.09,0,0,0,1198.68,679.21Z"
          fill="#888685"
        />
        <path
          d="M1204.56,672.16h-.14a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.37,1v6.33h-.83v-8.5h.83v1.05h0a2.4,2.4,0,0,1,.2-.39,1.21,1.21,0,0,1,.29-.35,1.64,1.64,0,0,1,.42-.26,1.43,1.43,0,0,1,.56-.1Z"
          fill="#888685"
        />
        <path
          d="M1209,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1207.69,679.21Z"
          fill="#888685"
        />
        <path
          d="M1211.25,669.64h.82v2.52h0a2.26,2.26,0,0,1,.56-.65,1.67,1.67,0,0,1,1-.29,1.65,1.65,0,0,1,1.29.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.82,1.82,0,0,1-1.34.48,1.52,1.52,0,0,1-1-.28,1.9,1.9,0,0,1-.52-.62h0v.81h-.82Zm3.34,3.63a1.51,1.51,0,0,0-.29-1,1.13,1.13,0,0,0-.92-.34,1.25,1.25,0,0,0-.92.36,1.45,1.45,0,0,0-.39,1v4.62a1.36,1.36,0,0,0,.38,1,1.29,1.29,0,0,0,.91.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1216.86,669.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1224.69,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1223.38,679.21Z"
          fill="#888685"
        />
        <path
          d="M1231,671.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M1236.27,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1241,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58H1241Z"
          fill="#888685"
        />
        <path
          d="M1244,677.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M1253,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <rect x="305.43" y="629.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="319.27"
          y="634.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="319.27"
          y1="637.1"
          x2="331.58"
          y2="637.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="319.27"
          y1="642.96"
          x2="331.58"
          y2="642.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="325.43"
          y1="637.1"
          x2="325.43"
          y2="642.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="321"
          cy="644.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="329.61"
          cy="644.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="320.38"
          y="646.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="328.99"
          y="646.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect x="150.43" y="737.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="164.27"
          y="742.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="164.27"
          y1="745.1"
          x2="176.58"
          y2="745.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="164.27"
          y1="750.96"
          x2="176.58"
          y2="750.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="170.43"
          y1="745.1"
          x2="170.43"
          y2="750.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="166"
          cy="752.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="174.61"
          cy="752.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="165.38"
          y="754.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="173.99"
          y="754.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
      </g>
      <g id="e55ad0de-e728-48ed-888b-224602948129" data-name="OUTLINE">
        <polyline
          points="860.52 273.5 860.52 189.23 729.97 214.93 729.97 266.02 359.5 265.5 297.18 295.1 305.22 409.58 356.54 409.58 356.54 429.75"
          fill="none"
          stroke="#231f20"
          strokeMiterlimit="10"
        />
        <line
          x1="860.52"
          y1="451.69"
          x2="860.52"
          y2="295.08"
          fill="none"
          stroke="#231f20"
          strokeMiterlimit="10"
        />
        <polyline
          points="860.52 483.05 860.52 619.48 687.5 619.48"
          fill="none"
          stroke="#231f20"
          strokeMiterlimit="10"
        />
        <polyline
          points="356.94 448.87 356.94 546.75 377.44 546.75 377.44 619.19 594.83 619.19"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polyline
          points="305.22 409.58 306 546.75 357 546.75"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M325.58,504.54v-4.42h.72v1.76h10v.9h-10v1.76Z"
          fill="#1e0f00"
        />
        <path
          d="M333.22,494.4h.89a2.46,2.46,0,0,1,1.72.54,2.1,2.1,0,0,1,.6,1.64,2.16,2.16,0,0,1-.65,1.69,2.59,2.59,0,0,1-1.76.58h-4.38a2.54,2.54,0,0,1-1.76-.57,2.52,2.52,0,0,1,0-3.32,2.51,2.51,0,0,1,1.74-.56h2.24V498h2.3a1.68,1.68,0,0,0,1.16-.37,1.34,1.34,0,0,0,.41-1.06c0-.89-.5-1.33-1.51-1.33h-1ZM331.14,498v-2.73h-1.61a1.77,1.77,0,0,0-1.15.33,1.25,1.25,0,0,0-.41,1,1.3,1.3,0,0,0,.41,1,1.67,1.67,0,0,0,1.15.36Z"
          fill="#1e0f00"
        />
        <path
          d="M328.24,490.42v.14a1.45,1.45,0,0,0,.36,1.05,1.38,1.38,0,0,0,1,.4h6.69v.87h-9V492h1.11v0l-.42-.21a1.58,1.58,0,0,1-.37-.32,1.48,1.48,0,0,1-.27-.44,1.55,1.55,0,0,1-.1-.59Z"
          fill="#1e0f00"
        />
        <path
          d="M328.24,486.61v.15a1.45,1.45,0,0,0,.36,1,1.38,1.38,0,0,0,1,.4h6.69v.87h-9v-.87h1.11v0A2.06,2.06,0,0,1,328,488a1.58,1.58,0,0,1-.37-.32,1.48,1.48,0,0,1-.27-.44,1.55,1.55,0,0,1-.1-.59Z"
          fill="#1e0f00"
        />
        <path
          d="M336.33,481.92h-.81v0a1.64,1.64,0,0,1,.91,1.55,1.82,1.82,0,0,1-.51,1.4,2.31,2.31,0,0,1-1.62.48h-.73a2.5,2.5,0,0,1-.88-.14,1.68,1.68,0,0,1-.61-.39,2.74,2.74,0,0,1-.44-.6c-.12-.23-.24-.49-.37-.78a5.16,5.16,0,0,0-.67-1.24.76.76,0,0,0-.58-.31h-.62a2,2,0,0,0-1.06.23,1,1,0,0,0-.37.91,1.16,1.16,0,0,0,.34.94,1.46,1.46,0,0,0,1,.3h.88v.84h-.81a2.26,2.26,0,0,1-1.57-.51,2.07,2.07,0,0,1-.56-1.6,1.86,1.86,0,0,1,.56-1.54,2.63,2.63,0,0,1,1.66-.45h6.87Zm-.63,1.38a1.28,1.28,0,0,0-1.43-1.38H331.1a.58.58,0,0,1,.15.13l.17.24a3.1,3.1,0,0,1,.23.39q.14.24.3.6l.3.55a2.07,2.07,0,0,0,.32.38,1.22,1.22,0,0,0,.42.23,2.13,2.13,0,0,0,.6.08h.68a1.69,1.69,0,0,0,1.07-.29A1.12,1.12,0,0,0,335.7,483.3Z"
          fill="#1e0f00"
        />
        <path
          d="M333.22,475.19h.89a2.56,2.56,0,0,1,1.72.51,2,2,0,0,1,.6,1.61,2.15,2.15,0,0,1-.65,1.69,2.59,2.59,0,0,1-1.76.57h-4.38a2.54,2.54,0,0,1-1.76-.57,2.11,2.11,0,0,1-.64-1.67,2.07,2.07,0,0,1,.58-1.62,2.48,2.48,0,0,1,1.71-.52h.65V476h-.72a1.78,1.78,0,0,0-1.12.31,1.22,1.22,0,0,0-.37,1,1.27,1.27,0,0,0,.41,1,1.67,1.67,0,0,0,1.15.36h4.6a1.73,1.73,0,0,0,1.16-.36,1.3,1.3,0,0,0,.41-1,1.22,1.22,0,0,0-.37-1,1.81,1.81,0,0,0-1.14-.3h-1Z"
          fill="#1e0f00"
        />
        <path
          d="M333.22,469.35h.89a2.46,2.46,0,0,1,1.72.54,2.08,2.08,0,0,1,.6,1.63,2.15,2.15,0,0,1-.65,1.69,2.54,2.54,0,0,1-1.76.58h-4.38a2.54,2.54,0,0,1-1.76-.57,2.1,2.1,0,0,1-.64-1.67,2.17,2.17,0,0,1,.61-1.65,2.56,2.56,0,0,1,1.74-.55h2.24v3.59h2.3a1.73,1.73,0,0,0,1.16-.37,1.36,1.36,0,0,0,.41-1.06c0-.89-.5-1.34-1.51-1.34h-1Zm-2.08,3.59v-2.73h-1.61a1.77,1.77,0,0,0-1.15.33,1.23,1.23,0,0,0-.41,1,1.3,1.3,0,0,0,.41,1,1.67,1.67,0,0,0,1.15.36Z"
          fill="#1e0f00"
        />
        <rect
          x="645.5"
          y="273"
          width="26.12"
          height="27.21"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="507 300.46 507 273 477.08 272.96 477.08 300.46 507 300.46"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polyline
          points="867.88 273.5 853.5 273.5 853.5 197.5 737.5 220.5 737.62 273.06 360.5 272.96 304.5 299.5 312 402 363.45 402.5 363.45 430 351.42 430"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polyline
          points="867.88 295.08 853.43 295.08 853.43 451.69 867.5 451.69"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polyline
          points="351.42 448.87 363.45 448.87 363.45 540.46 384.5 540.46 384.5 613 594.83 613 594.83 625.5"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polyline
          points="867.5 483.05 853.5 483.05 853.5 612.5 738.5 612.5 687.5 612.5 687.5 626.5"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f331c2ff-fc7e-4b38-83e4-48b15c20e049" data-name="1S.1">
        <rect
          x="363.5"
          y="513.5"
          width="45"
          height="27"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a019e059-05f2-4349-a9be-872bf90f0a9e" data-name="1S.2">
        <rect
          x="408.5"
          y="513.5"
          width="59"
          height="27"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ed8f3ab0-473a-414d-8b11-d0f7d381e0d4" data-name="1S.3">
        <rect
          x="467.5"
          y="513.5"
          width="28"
          height="27"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="af212a93-261a-4dd7-bfd5-d8e537d3c866" data-name="1S.4">
        <rect
          x="495.5"
          y="513.5"
          width="33"
          height="27"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b283e812-096e-4d52-98a9-908419c68ea4" data-name="1S.5">
        <rect
          x="528.5"
          y="513.5"
          width="58"
          height="27"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ad77ff44-c006-44ef-8b99-dc0c5effb29a" data-name="INFO">
        <rect
          x="594.5"
          y="540"
          width="92.65"
          height="35.88"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="afdc6e04-b2e9-47fe-b704-f445eefcd30c" data-name="1.0">
        <rect
          x="594.83"
          y="575.88"
          width="126.67"
          height="36.62"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b7120a5f-9eb5-4159-924b-ea8f8998d36c" data-name="1C.1">
        <rect
          x="586.5"
          y="513.5"
          width="100.38"
          height="26.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ae79e611-5ae4-4ad6-aaf5-1d9f5e6b1358" data-name="1C.2">
        <rect
          x="618"
          y="474.5"
          width="68.88"
          height="39.01"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b679d7b6-e443-481a-9d4a-04650030d0b7" data-name="1C.3">
        <polygon
          points="686.88 474.5 618 474.5 618 448.97 627.5 434.5 686.88 434.5 686.88 474.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b0b735bf-5963-4862-b08e-8514f2b693af" data-name="1C.4">
        <polygon
          points="633.5 410.5 687.15 410.5 687.15 434.5 627.5 434.5 633.5 425.5 633.5 410.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="eee36132-03f8-4f80-bc15-1f67107b2a48" data-name="1C.5">
        <polygon
          points="633.5 389.5 692.5 389.5 687.15 410.5 633.5 410.5 633.5 389.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a63a70c8-8b06-4b41-9da3-fe44d32200bc" data-name="1N.0">
        <polygon
          points="692.5 389.5 633.5 389.5 614.43 365.7 699.5 365.5 692.5 389.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a715cec6-b81f-4f39-a646-9922e806c604" data-name="1N.1">
        <rect
          x="477.08"
          y="327.5"
          width="38.42"
          height="38.2"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b7f6cc7d-6a50-4911-ac9e-9c4ef217c586" data-name="1N.2">
        <rect
          x="515.5"
          y="327.5"
          width="43"
          height="38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a3b9e3f1-3c5b-400f-b94e-0668d507bed1" data-name="1N.3">
        <rect
          x="558.5"
          y="327.5"
          width="36"
          height="38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a95ab496-937e-406f-b1c6-4cf0270002d4" data-name="1N.4">
        <rect
          x="594.5"
          y="327.5"
          width="34"
          height="38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="adfc423b-38cd-4836-bc0b-f61451481949" data-name="1N.5">
        <polygon
          points="628.5 327.5 702.09 327.5 702.09 356.55 699.5 365.5 628.5 365.5 628.5 327.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bf6026f3-9a78-4eed-87cd-0db19ee49c5d" data-name="1N.6">
        <rect
          x="671.5"
          y="273.06"
          width="30.59"
          height="54.44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a3e117d6-11c5-40c8-98f0-d29597518035" data-name="1E.1">
        <rect
          x="687.15"
          y="513.5"
          width="49.85"
          height="26.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.96"
        />
        <rect
          x="737"
          y="513.5"
          width="79"
          height="26.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.96"
        />
      </g>
      <g id="bc2b0b25-1008-4e10-949c-b973c058131c" data-name="1E.2">
        <rect
          x="779.5"
          y="483.05"
          width="36.5"
          height="30.45"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.96"
        />
      </g>
      <g id="b98506d8-3433-4090-babc-9af81a14e03f" data-name="1E.3">
        <rect
          x="737"
          y="451.5"
          width="42.53"
          height="31.55"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.21"
        />
      </g>
      <g id="a3604d6e-fd5f-4eb2-be2a-e5f1af6a2d82" data-name="1E.4">
        <rect
          x="779.5"
          y="451.69"
          width="36.5"
          height="31.36"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.98"
        />
      </g>
      <g id="e727eedf-a6ba-414a-8b84-6ae4d105378f" data-name="1E.5">
        <rect
          x="816"
          y="451.69"
          width="37.5"
          height="32.36"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.98"
        />
      </g>
      <g id="f177d7ae-e0dd-4e73-b0ea-bca3c9d30947" data-name="1E.6">
        <rect
          x="779.5"
          y="399.5"
          width="36.5"
          height="52.19"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.98"
        />
      </g>
      <g id="b4141aee-7461-48ea-ac57-1f52521e8aa8" data-name="1E.7">
        <rect
          x="779.5"
          y="356.5"
          width="36.5"
          height="43"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.98"
        />
      </g>
      <g id="ec1253cd-8095-4f49-931b-672bee284824" data-name="1E.8">
        <rect
          x="702.09"
          y="327.5"
          width="114.41"
          height="29"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.98"
        />
      </g>
      <g id="e01c1ed8-cf9b-4fc3-bfed-a694c4eadd2e" data-name="1E.9">
        <path
          d="M820.5,273.5h-13v-3.24c0-4-2.11-10.6-5.48-13.15a20,20,0,0,0-12-3.84,22.77,22.77,0,0,0-3,.22c-7.59,1-13.5,6-14.38,12.17a12.18,12.18,0,0,0-.12,1.6c0,4.58,2.77,10.69,7,13.24l0,1h0v46h25V292.71c1.33-2.79,8.19-4.63,16-5.84Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.12"
        />
      </g>
      <g id="e4185600-f8ee-4ed6-bfcd-e7ca42a4f16e" data-name="1E.10">
        <path
          d="M820.5,286.87c4-.6,8.16-1.05,12-1.37,12-1,15.35-.3,21,4.58V273.5h-33Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.12"
        />
      </g>
      <g id="e3a6a6e7-f8d5-42da-87d6-021d4346c7c6" data-name="1.36">
        <rect
          x="702.09"
          y="301"
          width="35.54"
          height="26.46"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b5db3a11-90a8-446c-8d3b-fe4b00c69776" data-name="1.34">
        <rect
          x="737"
          y="422.5"
          width="42.53"
          height="29.19"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b12cc340-527b-4760-ae85-d703950e0ffe" data-name="1.32">
        <rect
          x="737"
          y="356.55"
          width="42.53"
          height="32.95"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b7f79912-0988-40f9-b0c0-9eafbc8958e1" data-name="1.33a">
        <rect
          x="737"
          y="406.5"
          width="42.53"
          height="16"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b82c161d-fcfd-40ff-b560-349c4b35e19e" data-name="1.33b">
        <rect
          x="737"
          y="389.5"
          width="42.53"
          height="17"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b761d611-950e-4dab-91dd-a1f29339cbdd" data-name="1.31">
        <rect
          x="737.62"
          y="294.23"
          width="41.9"
          height="33.23"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bbfea7d5-fbf3-4d34-9301-97f5c00a14c9" data-name="1.30b">
        <rect
          x="737.62"
          y="281.51"
          width="41.9"
          height="12.72"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="aaa36fd8-fc0b-41a5-8f16-498a06d48bf0" data-name="1.30a">
        <path
          d="M772.5,267.26a12.18,12.18,0,0,1,.12-1.6h-35V282.5H779.5l0-2C775.27,278,772.5,271.84,772.5,267.26Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a6fcd120-b8ab-47bd-9b76-24189f194d1a" data-name="1.29">
        <path
          d="M787,253.49V210.3l-49.38,10v48.4l35-3C773.5,259.48,779.41,254.54,787,253.49Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a04a0f97-f7e5-4dc6-a003-382b43dbc3fb" data-name="1.28">
        <path
          d="M787,210.3v43.19a22.77,22.77,0,0,1,3-.22,20,20,0,0,1,12,3.85l20.7-13.45h30.7V197.5Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a3a43744-4e35-4970-8c41-88b00e73e0d5" data-name="1.27">
        <path
          d="M822.5,243.67,802,257.11c3.37,2.55,5.48,9.15,5.48,13.15v3.24h45.93V243.67Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b0d9946a-6430-45bf-a9b0-0e4b8c4d2420" data-name="1.26">
        <path
          d="M853.5,290.08c-5.65-4.88-9-5.58-21-4.58-11.42.95-26,3-28,7.21v1.79h49Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ab9f5e82-65a1-4c2d-a386-622a4c5068d5" data-name="1.25">
        <rect
          x="804.5"
          y="294.63"
          width="49"
          height="32.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b8262327-4dc1-4dd3-929b-dd94391dc4ea" data-name="1.24">
        <rect
          x="816"
          y="327.46"
          width="37.43"
          height="29.54"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f3fcd422-656a-4770-adc1-08059550110c" data-name="1.23b">
        <rect
          x="816"
          y="376.5"
          width="37.43"
          height="13"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a82053be-b828-4632-a645-46fef02fa307" data-name="1.23a">
        <rect
          x="816"
          y="356.55"
          width="37.43"
          height="20.38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b188b3e8-dc96-4e82-a332-2476e85d66b4" data-name="1.22">
        <rect
          x="816"
          y="389.5"
          width="37.43"
          height="62.19"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b6da8d0b-1842-46e5-b631-3c8002f0ccce" data-name="1.35">
        <rect
          x="737"
          y="483.05"
          width="42.53"
          height="30.46"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a6961829-c929-4a9e-9ddb-372ba9b33cb9" data-name="1.21">
        <rect
          x="816"
          y="483.05"
          width="37.43"
          height="56.95"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b438b0c2-b796-469d-a2b0-87d64df5a9c4" data-name="1.19">
        <rect
          x="721"
          y="540"
          width="132.43"
          height="72.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="abe1ba4a-0a65-4aac-b096-1d91b29631cf" data-name="1.17">
        <rect
          x="687.15"
          y="540"
          width="33.85"
          height="35.88"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M697,552.66a2.85,2.85,0,0,1,1.15.2,2.09,2.09,0,0,1,.76.57,2.53,2.53,0,0,1,.43.85,3.85,3.85,0,0,1,.13,1v5.43a3.9,3.9,0,0,1-.13,1,2.39,2.39,0,0,1-.43.84,2.09,2.09,0,0,1-.76.57,2.85,2.85,0,0,1-1.15.21h-2.26V552.66Zm-1.35,10h1.23a1.54,1.54,0,0,0,1.31-.51,2.12,2.12,0,0,0,.38-1.27v-5.73a2.5,2.5,0,0,0-.09-.69,1.4,1.4,0,0,0-.29-.57,1.28,1.28,0,0,0-.52-.38,1.89,1.89,0,0,0-.79-.15h-1.23Z"
          fill="#1e0f00"
        />
        <path
          d="M703,563.51a1.91,1.91,0,0,1-1.56-.62,2.76,2.76,0,0,1-.5-1.77v-1.6a2,2,0,0,1,.36-1.28,1.62,1.62,0,0,1,.91-.58,1.42,1.42,0,0,1-.78-.58,2,2,0,0,1-.3-1.18v-1.06q0-2.28,2.13-2.28a2.08,2.08,0,0,1,1.61.53,2.6,2.6,0,0,1,.47,1.72v.91h-.88v-.93a1.93,1.93,0,0,0-.26-1.16,1.07,1.07,0,0,0-.91-.34,1.21,1.21,0,0,0-1,.37,1.75,1.75,0,0,0-.32,1.19V556a1.38,1.38,0,0,0,.31,1,1.28,1.28,0,0,0,1,.34h2.8v.73h-.61v5.36h-.77v-.81a1.66,1.66,0,0,1-.56.65A2.11,2.11,0,0,1,703,563.51Zm.21-.73a1.28,1.28,0,0,0,1.11-.44,2.06,2.06,0,0,0,.35-1.14v-3.15h-1.43a1.22,1.22,0,0,0-1.39,1.37v1.71a2.05,2.05,0,0,0,.31,1.23A1.2,1.2,0,0,0,703.22,562.78Z"
          fill="#1e0f00"
        />
        <path
          d="M710,558h2.23v5.45h-.77v-.93a1.88,1.88,0,0,1-.55.72,1.76,1.76,0,0,1-1.14.31,2.28,2.28,0,0,1-1.06-.22,1.84,1.84,0,0,1-.71-.58,2.35,2.35,0,0,1-.41-.84,4.36,4.36,0,0,1-.12-1v-5.59a4,4,0,0,1,.13-1,2.3,2.3,0,0,1,.44-.85,1.92,1.92,0,0,1,.75-.57,2.6,2.6,0,0,1,1.1-.21,3.28,3.28,0,0,1,1.13.17,1.79,1.79,0,0,1,.71.5,1.88,1.88,0,0,1,.38.79,4.08,4.08,0,0,1,.12,1v1h-.88v-1a2.17,2.17,0,0,0-.29-1.23,1.28,1.28,0,0,0-1.15-.45,1.7,1.7,0,0,0-.71.14,1.38,1.38,0,0,0-.47.39,1.82,1.82,0,0,0-.27.58,2.58,2.58,0,0,0-.09.7V561a3.13,3.13,0,0,0,.08.7,1.59,1.59,0,0,0,.26.59,1.26,1.26,0,0,0,.47.38,1.48,1.48,0,0,0,.7.15,1.25,1.25,0,0,0,1.13-.49,2.16,2.16,0,0,0,.33-1.24v-2.37H710Z"
          fill="#1e0f00"
        />
      </g>
      <g id="e647f337-8fb5-40c6-904c-b5037d041c30" data-name="1.16b">
        <rect
          x="686.88"
          y="422.5"
          width="50.12"
          height="91.01"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M702,459.66a2.85,2.85,0,0,1,1.15.2,2.09,2.09,0,0,1,.76.57,2.53,2.53,0,0,1,.43.85,3.85,3.85,0,0,1,.13,1v5.43a3.9,3.9,0,0,1-.13,1,2.39,2.39,0,0,1-.43.84,2.09,2.09,0,0,1-.76.57,2.85,2.85,0,0,1-1.15.21h-2.26V459.66Zm-1.35,10h1.23a1.54,1.54,0,0,0,1.31-.51,2.12,2.12,0,0,0,.38-1.27v-5.73a2.5,2.5,0,0,0-.09-.69,1.4,1.4,0,0,0-.29-.57,1.28,1.28,0,0,0-.52-.38,1.89,1.89,0,0,0-.79-.15h-1.23Z"
          fill="#1e0f00"
        />
        <path
          d="M708,470.51a1.91,1.91,0,0,1-1.56-.62,2.76,2.76,0,0,1-.5-1.77v-1.6a2,2,0,0,1,.36-1.28,1.62,1.62,0,0,1,.91-.58,1.42,1.42,0,0,1-.78-.58,2,2,0,0,1-.3-1.18v-1.06q0-2.28,2.13-2.28a2.08,2.08,0,0,1,1.61.53,2.6,2.6,0,0,1,.47,1.72v.91h-.88v-.93a1.93,1.93,0,0,0-.26-1.16,1.07,1.07,0,0,0-.91-.34,1.21,1.21,0,0,0-1,.37,1.75,1.75,0,0,0-.32,1.19V463a1.38,1.38,0,0,0,.31,1,1.28,1.28,0,0,0,1,.34h2.8v.73h-.61v5.36h-.77v-.81a1.66,1.66,0,0,1-.56.65A2.11,2.11,0,0,1,708,470.51Zm.21-.73a1.28,1.28,0,0,0,1.11-.44,2.06,2.06,0,0,0,.35-1.14v-3.15h-1.43a1.22,1.22,0,0,0-1.39,1.37v1.71a2.05,2.05,0,0,0,.31,1.23A1.2,1.2,0,0,0,708.22,469.78Z"
          fill="#1e0f00"
        />
        <path
          d="M715,465h2.23v5.45h-.77v-.93a1.88,1.88,0,0,1-.55.72,1.76,1.76,0,0,1-1.14.31,2.28,2.28,0,0,1-1.06-.22,1.84,1.84,0,0,1-.71-.58,2.35,2.35,0,0,1-.41-.84,4.36,4.36,0,0,1-.12-1v-5.59a4,4,0,0,1,.13-1.05,2.3,2.3,0,0,1,.44-.85,1.92,1.92,0,0,1,.75-.57,2.6,2.6,0,0,1,1.1-.21,3.28,3.28,0,0,1,1.13.17,1.79,1.79,0,0,1,.71.5,1.88,1.88,0,0,1,.38.79,4.08,4.08,0,0,1,.12,1V463h-.88v-1a2.17,2.17,0,0,0-.29-1.23,1.28,1.28,0,0,0-1.15-.45,1.7,1.7,0,0,0-.71.14,1.38,1.38,0,0,0-.47.39,1.82,1.82,0,0,0-.27.58,2.58,2.58,0,0,0-.09.7V468a3.13,3.13,0,0,0,.08.7,1.59,1.59,0,0,0,.26.59,1.26,1.26,0,0,0,.47.38,1.48,1.48,0,0,0,.7.15,1.25,1.25,0,0,0,1.13-.49,2.16,2.16,0,0,0,.33-1.24v-2.37H715Z"
          fill="#1e0f00"
        />
      </g>
      <g id="a8c19325-c111-4d10-8e99-639288360cf7" data-name="1.15">
        <polygon
          points="686.88 422.5 687.15 410.5 702.09 356.55 737 356.55 737 422.5 686.88 422.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b4dbcab1-435e-4df8-9e5e-64ef94805488" data-name="1.14b">
        <rect
          x="645.5"
          y="300.25"
          width="26.12"
          height="27.21"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b918eae6-7797-4a88-b8b0-4528d3f75a43" data-name="1.14a">
        <polygon
          points="645.5 327.46 609.52 327.46 609.52 313.42 628.82 313.42 628.82 273.06 645.5 273 645.5 327.46"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a4058b2e-c240-4f6f-acff-d7d2c509aed8" data-name="1.12">
        <polygon
          points="609.52 327.46 609.52 313.42 628.82 313.42 628.82 273.06 546.93 273.06 546.93 327.46 609.52 327.46"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M567.73,291.66h2.08a2.22,2.22,0,0,1,1.64.51,2,2,0,0,1,.5,1.5v1.48a2,2,0,0,1-.26,1.09,1.26,1.26,0,0,1-.86.57,1.56,1.56,0,0,1,1.41,1.73v1.86a2,2,0,0,1-.52,1.5,2.27,2.27,0,0,1-1.66.51h-2.33Zm.91.72v4.11h1.14c.88,0,1.32-.42,1.32-1.27v-1.56c0-.85-.46-1.28-1.37-1.28Zm1.33,9.3c.92,0,1.38-.42,1.38-1.27v-1.94a1.2,1.2,0,0,0-.33-.93,1.57,1.57,0,0,0-1.08-.32h-1.3v4.46Z"
          fill="#1e0f00"
        />
        <path
          d="M574.49,300.35c0,.95.41,1.43,1.24,1.43a1.36,1.36,0,0,0,.94-.34,1.4,1.4,0,0,0,.41-1v-7H578v9h-.87v-.89h0a1.89,1.89,0,0,1-.6.73,1.85,1.85,0,0,1-1.05.26,1.7,1.7,0,0,1-1.33-.51,2.36,2.36,0,0,1-.46-1.62v-7h.87Z"
          fill="#1e0f00"
        />
        <path
          d="M582,294.32h-.14a1.49,1.49,0,0,0-1.05.36,1.41,1.41,0,0,0-.39,1v6.69h-.87v-9h.87v1.11h0a2.06,2.06,0,0,1,.21-.42,1.79,1.79,0,0,1,.31-.37,1.68,1.68,0,0,1,.45-.27,1.5,1.5,0,0,1,.58-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M583.31,291.66h.88v2.66h0a2.4,2.4,0,0,1,.6-.69,1.7,1.7,0,0,1,1.1-.31,1.74,1.74,0,0,1,1.36.52,2.36,2.36,0,0,1,.46,1.62v4.91a2.22,2.22,0,0,1-.5,1.62,1.88,1.88,0,0,1-1.42.52,1.59,1.59,0,0,1-1-.3,2,2,0,0,1-.56-.65h0v.85h-.88Zm3.54,3.84a1.6,1.6,0,0,0-.31-1.08,1.18,1.18,0,0,0-1-.37,1.33,1.33,0,0,0-1,.38,1.58,1.58,0,0,0-.41,1.07v4.88a1.41,1.41,0,0,0,.39,1,1.37,1.37,0,0,0,1,.36c.87,0,1.3-.48,1.3-1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M593.66,299.3v.89a2.46,2.46,0,0,1-.54,1.72,2.1,2.1,0,0,1-1.64.6,2.16,2.16,0,0,1-1.69-.65,2.59,2.59,0,0,1-.57-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.09,2.09,0,0,1,1.66-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.56,1.74v2.24h-3.59v2.3a1.67,1.67,0,0,0,.37,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.33-.5,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M597.64,294.32h-.14a1.47,1.47,0,0,0-1,.36,1.41,1.41,0,0,0-.39,1v6.69h-.88v-9h.88v1.11h0a2.67,2.67,0,0,1,.2-.42,1.85,1.85,0,0,1,.32-.37,1.79,1.79,0,0,1,.44-.27,1.55,1.55,0,0,1,.59-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M601.45,294.32h-.14a1.45,1.45,0,0,0-1,.36,1.41,1.41,0,0,0-.4,1v6.69H599v-9h.87v1.11h0a4.11,4.11,0,0,1,.21-.42,1.85,1.85,0,0,1,.32-.37,1.48,1.48,0,0,1,.44-.27,1.55,1.55,0,0,1,.59-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M607.1,293.42l-2.13,9.1a2.66,2.66,0,0,1-.2.62,1.21,1.21,0,0,1-.31.42,1.1,1.1,0,0,1-.46.22,3.23,3.23,0,0,1-.66.06h-.45v-.73h.43a.84.84,0,0,0,.6-.17,1.43,1.43,0,0,0,.29-.62l.21-.78h-.21l-1.82-8.12h.88l1.46,6.69h0l1.49-6.69Z"
          fill="#1e0f00"
        />
      </g>
      <g id="a5437262-bcdd-410a-a404-5b02079a02ea" data-name="1.11">
        <polygon
          points="546.93 327.46 546.93 273.06 507 273 507 327.46 546.93 327.46"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f91ebf6a-953b-4b18-8c9c-907cc614c5cb" data-name="1.11a">
        <rect
          x="477.08"
          y="300"
          width="29.92"
          height="27.46"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a8e16b4e-51a7-42df-9d2b-02220d7da668" data-name="1.9">
        <polygon
          points="521 365.7 614.43 365.7 633.5 389.5 633.5 402.6 521 402.6 521 365.7"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M560.76,379.46h-.06l-1.79,9h-.54l-1.8-9h0v9h-.8V377.66H557l1.65,8.29h0l1.64-8.29h1.38v10.75h-.91Z"
          fill="#1e0f00"
        />
        <path
          d="M567.66,386.1a2.59,2.59,0,0,1-.57,1.76,2.1,2.1,0,0,1-1.66.65,2.12,2.12,0,0,1-1.67-.65,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.15,2.15,0,0,1,1.67-.64,2.12,2.12,0,0,1,1.66.64,2.58,2.58,0,0,1,.57,1.77Zm-2.23,1.68a1.29,1.29,0,0,0,1-.41,1.76,1.76,0,0,0,.35-1.16v-4.59a1.76,1.76,0,0,0-.35-1.16,1.29,1.29,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.76,1.76,0,0,0-.35,1.16v4.59a1.76,1.76,0,0,0,.35,1.16A1.3,1.3,0,0,0,565.43,387.78Z"
          fill="#1e0f00"
        />
        <path
          d="M572.65,381.48a1.63,1.63,0,0,0-.3-1.07,1.14,1.14,0,0,0-.94-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.87v-9h.87v.88h0a1.92,1.92,0,0,1,.58-.69,1.77,1.77,0,0,1,1.07-.29,1.7,1.7,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M579.39,385.3v.89a2.51,2.51,0,0,1-.52,1.72,2,2,0,0,1-1.61.6,2.13,2.13,0,0,1-1.68-.65,2.59,2.59,0,0,1-.57-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.07,2.07,0,0,1,1.62.58,2.48,2.48,0,0,1,.52,1.71v.65h-.86v-.72a1.78,1.78,0,0,0-.3-1.12,1.22,1.22,0,0,0-1-.37,1.3,1.3,0,0,0-1,.41,1.72,1.72,0,0,0-.35,1.15v4.6a1.76,1.76,0,0,0,.35,1.16,1.33,1.33,0,0,0,1,.41,1.22,1.22,0,0,0,1-.37,1.81,1.81,0,0,0,.3-1.14v-1Z"
          fill="#1e0f00"
        />
        <path d="M580.82,377.66h.87v10.75h-.87Z" fill="#1e0f00" />
        <path
          d="M587.66,385.3v.89a2.46,2.46,0,0,1-.54,1.72,2.1,2.1,0,0,1-1.64.6,2.16,2.16,0,0,1-1.69-.65,2.59,2.59,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.52,2.52,0,0,1,3.32,0,2.51,2.51,0,0,1,.56,1.74v2.24h-3.59v2.3a1.67,1.67,0,0,0,.37,1.16,1.34,1.34,0,0,0,1.06.41c.89,0,1.33-.5,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.25,1.25,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M591.64,380.32h-.14a1.45,1.45,0,0,0-1,.36,1.38,1.38,0,0,0-.4,1v6.69h-.87v-9h.87v1.11h0a4.11,4.11,0,0,1,.21-.42,1.58,1.58,0,0,1,.32-.37,1.48,1.48,0,0,1,.44-.27,1.55,1.55,0,0,1,.59-.1Z"
          fill="#1e0f00"
        />
      </g>
      <g id="a241871f-15c5-48fc-9513-16ac37347e8e" data-name="1.8">
        <rect
          x="549.11"
          y="402.6"
          width="84.39"
          height="16.4"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bca98464-19ed-43bd-b14d-13e77ea1626f" data-name="1.7">
        <polygon
          points="633.5 419 633.5 425.5 618 448.97 565 448.97 549.11 425.59 549.11 419 633.5 419"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="feb2d914-ef92-4c47-8d83-16250e093b07" data-name="1.6">
        <rect
          x="545"
          y="448.97"
          width="73"
          height="64.44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M571.25,481h2.23v5.45h-.77v-.93a1.88,1.88,0,0,1-.55.72,1.76,1.76,0,0,1-1.14.31,2.28,2.28,0,0,1-1.06-.22,1.84,1.84,0,0,1-.71-.58,2.35,2.35,0,0,1-.41-.84,4.36,4.36,0,0,1-.12-1v-5.59a4,4,0,0,1,.13-1.05,2.3,2.3,0,0,1,.44-.85,1.92,1.92,0,0,1,.75-.57,2.6,2.6,0,0,1,1.1-.21,3.28,3.28,0,0,1,1.13.17,1.79,1.79,0,0,1,.71.5,2,2,0,0,1,.39.79,4.62,4.62,0,0,1,.11,1V479h-.88v-1a2.17,2.17,0,0,0-.29-1.23,1.28,1.28,0,0,0-1.15-.45,1.7,1.7,0,0,0-.71.14,1.38,1.38,0,0,0-.47.39,1.82,1.82,0,0,0-.27.58,2.58,2.58,0,0,0-.09.7V484a3.13,3.13,0,0,0,.08.7,1.59,1.59,0,0,0,.26.59,1.26,1.26,0,0,0,.47.38,1.48,1.48,0,0,0,.7.15,1.24,1.24,0,0,0,1.13-.49,2.16,2.16,0,0,0,.33-1.24v-2.37h-1.34Z"
          fill="#1e0f00"
        />
        <path
          d="M576,484.35c0,.95.41,1.43,1.24,1.43a1.36,1.36,0,0,0,.94-.34,1.4,1.4,0,0,0,.41-1v-7h.87v9h-.87v-.89h0a1.89,1.89,0,0,1-.6.73,1.85,1.85,0,0,1-1,.26,1.7,1.7,0,0,1-1.33-.51,2.36,2.36,0,0,1-.46-1.62v-7H576Z"
          fill="#1e0f00"
        />
        <path
          d="M585.31,483.3v.89a2.56,2.56,0,0,1-.51,1.72,2,2,0,0,1-1.61.6,2.16,2.16,0,0,1-1.69-.65,2.59,2.59,0,0,1-.57-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.13,2.13,0,0,1,1.67-.64,2.07,2.07,0,0,1,1.62.58,2.48,2.48,0,0,1,.52,1.71v.65h-.85v-.72a1.72,1.72,0,0,0-.31-1.12,1.2,1.2,0,0,0-1-.37,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15v4.6a1.71,1.71,0,0,0,.36,1.16,1.31,1.31,0,0,0,1,.41,1.22,1.22,0,0,0,1-.37,1.81,1.81,0,0,0,.3-1.14v-1Z"
          fill="#1e0f00"
        />
        <path
          d="M591.09,483.3v.89a2.51,2.51,0,0,1-.52,1.72,2,2,0,0,1-1.61.6,2.13,2.13,0,0,1-1.68-.65,2.59,2.59,0,0,1-.57-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2,2,0,0,1,1.61.58,2.48,2.48,0,0,1,.53,1.71v.65h-.86v-.72a1.78,1.78,0,0,0-.3-1.12,1.22,1.22,0,0,0-1-.37,1.3,1.3,0,0,0-1,.41,1.72,1.72,0,0,0-.35,1.15v4.6a1.76,1.76,0,0,0,.35,1.16,1.33,1.33,0,0,0,1,.41,1.22,1.22,0,0,0,1-.37,1.81,1.81,0,0,0,.3-1.14v-1Z"
          fill="#1e0f00"
        />
        <path
          d="M592.52,475.66h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
      </g>
      <g id="ab941f4c-cf85-4a81-852a-edc2d4ecf952" data-name="1.5">
        <rect
          x="511"
          y="448.97"
          width="34"
          height="64.44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="aa4bbc07-503d-4aab-af71-f01870131811" data-name="1.5a">
        <rect
          x="480"
          y="448.97"
          width="31"
          height="64.44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="adf58674-f67a-4279-b311-2a919cd153e4" data-name="1.4">
        <rect
          x="454.15"
          y="448.97"
          width="25.85"
          height="64.44"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b70ef1ba-e57a-4253-abbc-f63a8e0ec578" data-name="1.3">
        <polygon
          points="594.83 613 454.15 613 454.15 540.46 594.83 540 594.83 613"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ec020fd2-f83d-4010-b72a-20feb80abe3a" data-name="1.2">
        <rect
          x="384.5"
          y="540.46"
          width="69.65"
          height="72.54"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b14e599f-66c5-4b4b-bcaf-5e17e30f434d" data-name="1.1a">
        <rect
          x="477.08"
          y="365.7"
          width="43.92"
          height="36.9"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bf1ae831-5f47-4635-857c-30fb2f94224f" data-name="1.1">
        <polygon
          points="363.71 272.96 363.71 513.41 454 513.41 454 448.97 565.03 448.97 549.11 425.5 549.11 402.6 477.08 402.6 477.08 272.96 363.71 272.96"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect x="408.5" y="342.5" width="67.5" height="10" fill="#ededed" />
        <rect x="365" y="434.5" width="54" height="10" fill="#ededed" />
        <rect
          x="328.75"
          y="422.25"
          width="169.5"
          height="10"
          transform="translate(-13.75 840.75) rotate(-90)"
          fill="#ededed"
        />
      </g>
      <g id="a8bc97b0-8cbd-4bd8-b56a-23b71dcf5a07" data-name="R1">
        <polygon
          points="363.71 402.41 311.5 402.41 304.5 299.5 360.5 272.96 363.71 272.96 363.71 402.41"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e87c725e-bbaf-4aab-8a43-6c309b5d01ac" data-name="1.0">
        <rect
          x="626.33"
          y="540"
          width="35.07"
          height="20.07"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M636.73,544.66h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#ff4d00"
        />
        <path
          d="M642.62,548.48a1.69,1.69,0,0,0-.29-1.07,1.17,1.17,0,0,0-1-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.87v-9H640v.88h0a2,2,0,0,1,.59-.69,2,2,0,0,1,2.39.22,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#ff4d00"
        />
        <path
          d="M644.76,546.42h.68v-.5a1.28,1.28,0,0,1,.32-1,1.5,1.5,0,0,1,1-.3h.71v.65h-.6a.62.62,0,0,0-.47.15.71.71,0,0,0-.13.48v.48h1.2v.66h-1.2v8.33h-.88v-8.33h-.68Z"
          fill="#ff4d00"
        />
        <path
          d="M653.27,553.1a2.59,2.59,0,0,1-.58,1.76,2.1,2.1,0,0,1-1.66.65,2.12,2.12,0,0,1-1.67-.65,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.15,2.15,0,0,1,1.67-.64,2.12,2.12,0,0,1,1.66.64,2.58,2.58,0,0,1,.58,1.77ZM651,554.78a1.29,1.29,0,0,0,1-.41,1.76,1.76,0,0,0,.35-1.16v-4.59a1.76,1.76,0,0,0-.35-1.16,1.29,1.29,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.76,1.76,0,0,0-.35,1.16v4.59a1.76,1.76,0,0,0,.35,1.16A1.3,1.3,0,0,0,651,554.78Z"
          fill="#ff4d00"
        />
      </g>
      <g id="b6dfd781-6d24-424b-94d0-15fe22f9e97b" data-name="WC">
        <rect
          x="702.09"
          y="273.06"
          width="35.54"
          height="27.94"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M715.69,285.75l-1.36,8.35h-1l-1.46-10.76h.89l1.13,8.66h0l1.38-8.66h.84L717.5,292h0l1.14-8.62h.83L718,294.1h-1l-1.33-8.35Z"
          fill="#ff4d00"
        />
        <path
          d="M723.17,294.19a2.65,2.65,0,0,1-1.11-.21,2,2,0,0,1-.73-.58,2.42,2.42,0,0,1-.42-.85,3.84,3.84,0,0,1-.14-1v-5.58a4,4,0,0,1,.14-1.05,2.47,2.47,0,0,1,.42-.86,2,2,0,0,1,.73-.57,2.65,2.65,0,0,1,1.11-.21,3,3,0,0,1,1.11.18,1.66,1.66,0,0,1,.7.5,2,2,0,0,1,.35.78,4.62,4.62,0,0,1,.11,1V287h-.89v-1.34a2.39,2.39,0,0,0-.27-1.23,1.18,1.18,0,0,0-1.1-.45,1.51,1.51,0,0,0-.7.15,1.16,1.16,0,0,0-.46.39,1.67,1.67,0,0,0-.27.57,3.17,3.17,0,0,0-.07.71v5.86a3.2,3.2,0,0,0,.07.7,1.82,1.82,0,0,0,.27.58,1.35,1.35,0,0,0,.46.39,1.51,1.51,0,0,0,.7.14,1.18,1.18,0,0,0,1.11-.47,2.37,2.37,0,0,0,.27-1.2v-1.91h.88v1.84a4.23,4.23,0,0,1-.11,1,2,2,0,0,1-.36.79,1.76,1.76,0,0,1-.7.52A2.71,2.71,0,0,1,723.17,294.19Z"
          fill="#ff4d00"
        />
      </g>
      <g id="b22a15a3-ea36-496f-b82d-0e46ef3349a4" data-name="Icons">
        <circle
          cx="703.95"
          cy="593.78"
          r="1.64"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="702.26 607.88 702.26 597.25 705.64 597.25 705.64 607.88"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="696.81 584.19 700 581 700.1 581 703.29 584.19"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="711.09 581 707.9 584.19 707.8 584.19 704.61 581"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="696.29"
          y="588"
          width="15.32"
          height="20"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <circle
          cx="757.95"
          cy="466.78"
          r="1.64"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="756.26 480.88 756.26 470.25 759.64 470.25 759.64 480.88"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="750.81 457.19 754 454 754.1 454 757.29 457.19"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="765.09 454 761.9 457.19 761.8 457.19 758.61 454"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="750.29"
          y="461"
          width="15.32"
          height="20"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polygon points="873 474 873 464 868 469 873 474" fill="#ff4d00" />
        <polygon points="873 289 873 279 868 284 873 289" fill="#ff4d00" />
        <polygon
          points="636.5 629 626.5 629 631.5 624 636.5 629"
          fill="#ff4d00"
        />
        <polygon
          points="658.5 629 648.5 629 653.5 624 658.5 629"
          fill="#ff4d00"
        />
        <polyline
          points="631 536.5 637.94 536.5 651.33 522 658.27 522"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="642.78"
          cy="519.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="641.59 532.41 641.59 522.64 643.97 522.64 643.97 530.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="658 386.5 664.94 386.5 678.33 372 685.27 372"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="669.78"
          cy="369.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="668.59 382.41 668.59 372.64 670.97 372.64 670.97 380.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polygon points="351 444 351 434 356 439 351 444" fill="#ff4d00" />
      </g>
      <g id="a3803449-2d5d-4f59-b904-b99b2616e538" data-name="Надписи">
        <path
          d="M395.92,310.51a2,2,0,0,1-2.3-2.19v-1.67h.87v1.69a1.54,1.54,0,0,0,.35,1.06,1.45,1.45,0,0,0,1.1.38,1.25,1.25,0,0,0,1-.42,1.61,1.61,0,0,0,.32-1v-.57a3.24,3.24,0,0,0-.05-.56,1.66,1.66,0,0,0-.19-.48,2,2,0,0,0-.33-.46c-.14-.16-.31-.33-.51-.52l-1.09-1.08c-.26-.24-.47-.47-.65-.67a3.91,3.91,0,0,1-.45-.6,1.9,1.9,0,0,1-.27-.64,2.83,2.83,0,0,1-.09-.74v-.41a2,2,0,0,1,.57-1.49,2.23,2.23,0,0,1,1.62-.56,2.29,2.29,0,0,1,1.67.53,2.13,2.13,0,0,1,.53,1.58v1.17h-.85v-1.21a1.32,1.32,0,0,0-.34-1,1.42,1.42,0,0,0-1-.32,1.39,1.39,0,0,0-1,.33,1.24,1.24,0,0,0-.36,1V302a1.62,1.62,0,0,0,.3,1,7.32,7.32,0,0,0,.85.95l1.1,1.08.63.67a3.54,3.54,0,0,1,.45.61,2.8,2.8,0,0,1,.35,1.42v.59a2.8,2.8,0,0,1-.14.88,1.85,1.85,0,0,1-.42.7,2.08,2.08,0,0,1-.71.46A2.66,2.66,0,0,1,395.92,310.51Z"
          fill="#1e0f00"
        />
        <path
          d="M403,310.41v-.81h0a1.64,1.64,0,0,1-1.55.91A1.82,1.82,0,0,1,400,310a2.31,2.31,0,0,1-.47-1.62v-.73a2.51,2.51,0,0,1,.13-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5,5,0,0,0,1.24-.67.76.76,0,0,0,.31-.58v-.62a1.92,1.92,0,0,0-.23-1.06,1,1,0,0,0-.91-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.88h-.84v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.28,1.28,0,0,0,1.38-1.43v-3.17a.58.58,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.13-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,401.63,309.78Z"
          fill="#1e0f00"
        />
        <path
          d="M408.65,310.41l-2.38-4.58v4.58h-.88V299.66h.88v6l2.33-4.23h.89l-2.35,4.23,2.44,4.76Z"
          fill="#1e0f00"
        />
        <path
          d="M414.88,308.38a2.31,2.31,0,0,1-.5,1.57,2.14,2.14,0,0,1-1.64.56,2,2,0,0,1-1.58-.6,2.34,2.34,0,0,1-.51-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.3,1,1.18,1.18,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.58,1.58,0,0,0-.22-.85,4,4,0,0,0-.86-.86l-.92-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.48-1.4,2.51,2.51,0,0,1,3.11,0,2.24,2.24,0,0,1,.49,1.57v.65H414v-.68a1.57,1.57,0,0,0-.28-1,1.2,1.2,0,0,0-.95-.33,1.17,1.17,0,0,0-.91.32,1.24,1.24,0,0,0-.28.86v.25a1.64,1.64,0,0,0,0,.4,1,1,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,2,2,0,0,1,.23.56,2.7,2.7,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
        <path
          d="M418.87,299.66h3.84v.72h-2.94v4.19H422v.73h-2.21v5.11h-.9Z"
          fill="#1e0f00"
        />
        <path d="M424,299.66h.87v.79H424Zm0,1.76h.87v9H424Z" fill="#1e0f00" />
        <path
          d="M426.18,301.42h.68v-.5a1.28,1.28,0,0,1,.32-1,1.5,1.5,0,0,1,1.05-.3h.71v.65h-.6a.62.62,0,0,0-.47.15.71.71,0,0,0-.14.48v.48h1.21v.66h-1.21v8.33h-.87v-8.33h-.68Z"
          fill="#1e0f00"
        />
        <path
          d="M430,301.42h.7v-1.76h.87v1.76h1.26v.66h-1.26v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.5v.73h-.59a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84H430Z"
          fill="#1e0f00"
        />
        <path
          d="M437.67,303.48a1.63,1.63,0,0,0-.3-1.07,1.14,1.14,0,0,0-.94-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.87V299.66h.87v2.64h0a1.92,1.92,0,0,1,.58-.69,1.77,1.77,0,0,1,1.07-.29,1.7,1.7,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M393.43,325.41l2.13-10.75h1.11l2.16,10.75h-.92l-.51-2.63h-2.6l-.5,2.63Zm1.51-3.36h2.31l-1.15-6h0Z"
          fill="#1e0f00"
        />
        <path
          d="M404.39,316.42l-1.87,9h-1l-1.87-9h.88l1.5,7.33h0l1.48-7.33Z"
          fill="#1e0f00"
        />
        <path
          d="M410,322.3v.89a2.46,2.46,0,0,1-.54,1.72,2.1,2.1,0,0,1-1.64.6,2.16,2.16,0,0,1-1.69-.65,2.59,2.59,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.58-1.76,2.09,2.09,0,0,1,1.66-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.56,1.74v2.24h-3.59v2.3a1.67,1.67,0,0,0,.37,1.16,1.34,1.34,0,0,0,1.06.41c.89,0,1.33-.5,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M414.93,318.48a1.69,1.69,0,0,0-.29-1.07,1.16,1.16,0,0,0-.95-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.87v-9h.87v.88h0a2,2,0,0,1,.59-.69,1.73,1.73,0,0,1,1.06-.29,1.69,1.69,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M418.17,323.35c0,.95.41,1.43,1.23,1.43a1.34,1.34,0,0,0,.94-.34,1.4,1.4,0,0,0,.41-1v-7h.88v9h-.88v-.89h0a1.63,1.63,0,0,1-1.65,1,1.73,1.73,0,0,1-1.34-.51,2.41,2.41,0,0,1-.46-1.62v-7h.88Z"
          fill="#1e0f00"
        />
        <path
          d="M427.59,322.3v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.24H424v2.3a1.71,1.71,0,0,0,.36,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1ZM424,320.22h2.72v-1.61a1.77,1.77,0,0,0-.33-1.15,1.23,1.23,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.35,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M326.73,312.66h3.95v.72h-3v4.19h2.2v.73h-2.2v4.38h3v.73h-3.95Z"
          fill="#1e0f00"
        />
        <path
          d="M335.49,323.41v-.81h0a1.66,1.66,0,0,1-.56.63,1.6,1.6,0,0,1-1,.28,1.81,1.81,0,0,1-1.39-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.52,2.52,0,0,1,.6-.44c.23-.12.49-.24.78-.37a4.85,4.85,0,0,0,1.23-.67.77.77,0,0,0,.32-.58v-.62a1.91,1.91,0,0,0-.24-1.06,1,1,0,0,0-.9-.37,1.22,1.22,0,0,0-1,.34,1.52,1.52,0,0,0-.29,1v.88h-.84v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.88,1.88,0,0,1,1.54.56,2.63,2.63,0,0,1,.44,1.66v6.87Zm-1.38-.63a1.4,1.4,0,0,0,1-.36,1.38,1.38,0,0,0,.39-1.07v-3.17a.87.87,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.13-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.28,1.07A1.14,1.14,0,0,0,334.11,322.78Z"
          fill="#1e0f00"
        />
        <path
          d="M342,321.38a2.26,2.26,0,0,1-.51,1.57,2.1,2.1,0,0,1-1.63.56,2,2,0,0,1-1.58-.6,2.34,2.34,0,0,1-.51-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.3,1,1.18,1.18,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.58,1.58,0,0,0-.22-.85,4,4,0,0,0-.86-.86l-.92-.73a5.05,5.05,0,0,1-1-1,2,2,0,0,1-.32-1.17v-.25a2,2,0,0,1,.48-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.24,2.24,0,0,1,.49,1.57v.65h-.83v-.68a1.57,1.57,0,0,0-.28-1,1.21,1.21,0,0,0-1-.33,1.16,1.16,0,0,0-.9.32,1.24,1.24,0,0,0-.29.86v.25a1.62,1.62,0,0,0,0,.4,1,1,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35,6.34,6.34,0,0,0,.49.42l.92.73a8.72,8.72,0,0,1,.67.59,2.87,2.87,0,0,1,.44.53,2,2,0,0,1,.23.56,2.7,2.7,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
        <path
          d="M343.22,314.42h.7v-1.76h.87v1.76H346v.66h-1.25v6.86a.77.77,0,0,0,.17.58.82.82,0,0,0,.59.16H346v.73h-.58a1.64,1.64,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path d="M492.73,571.66h.91v10h2.69v.73h-3.6Z" fill="#1e0f00" />
        <path
          d="M502.06,580.1a2.59,2.59,0,0,1-.57,1.76,2.12,2.12,0,0,1-1.67.65,2.1,2.1,0,0,1-1.66-.65,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.12,2.12,0,0,1,1.66-.64,2.15,2.15,0,0,1,1.67.64,2.58,2.58,0,0,1,.57,1.77Zm-2.24,1.68a1.31,1.31,0,0,0,1-.41,1.76,1.76,0,0,0,.34-1.16v-4.59a1.76,1.76,0,0,0-.34-1.16,1.31,1.31,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.3,1.3,0,0,0,499.82,581.78Z"
          fill="#1e0f00"
        />
        <path
          d="M504.43,580.35c0,.95.41,1.43,1.23,1.43a1.34,1.34,0,0,0,.94-.34,1.4,1.4,0,0,0,.41-1v-7h.88v9H507v-.89h0a1.63,1.63,0,0,1-1.65,1A1.71,1.71,0,0,1,504,582a2.41,2.41,0,0,1-.46-1.62v-7h.88Z"
          fill="#1e0f00"
        />
        <path
          d="M509.44,571.66h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M516,580.38a2.26,2.26,0,0,1-.51,1.57,2.11,2.11,0,0,1-1.63.56,2,2,0,0,1-1.58-.6,2.29,2.29,0,0,1-.52-1.59v-.89h.83v.92a1.68,1.68,0,0,0,.3,1,1.17,1.17,0,0,0,1,.38,1.27,1.27,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.68,1.68,0,0,0-.22-.85,4.26,4.26,0,0,0-.86-.86l-.92-.73a4.8,4.8,0,0,1-1-1,2,2,0,0,1-.32-1.17v-.25a2,2,0,0,1,.48-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.82v-.68a1.57,1.57,0,0,0-.29-1,1.2,1.2,0,0,0-.95-.33,1.16,1.16,0,0,0-.9.32,1.24,1.24,0,0,0-.29.86v.25a1.62,1.62,0,0,0,.05.4,1,1,0,0,0,.17.35,2.25,2.25,0,0,0,.3.35c.13.12.3.26.5.42l.92.73a8.72,8.72,0,0,1,.67.59,2.87,2.87,0,0,1,.44.53,2.28,2.28,0,0,1,.23.56,3.28,3.28,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
        <path
          d="M524.93,571.66l-2.1,10.75h-1l-2.13-10.75h.92l1.73,9.12h0l1.69-9.12Z"
          fill="#1e0f00"
        />
        <path
          d="M527,580.35c0,.95.41,1.43,1.24,1.43a1.32,1.32,0,0,0,.93-.34,1.37,1.37,0,0,0,.42-1v-7h.87v9h-.87v-.89h0a1.61,1.61,0,0,1-1.65,1,1.69,1.69,0,0,1-1.33-.51,2.36,2.36,0,0,1-.46-1.62v-7H527Z"
          fill="#1e0f00"
        />
        <path d="M532,571.66h.88v.79H532Zm0,1.76h.88v9H532Z" fill="#1e0f00" />
        <path
          d="M534.19,573.42h.7v-1.76h.87v1.76H537v.66h-1.26v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.5v.73h-.59a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path
          d="M538.16,573.42h.7v-1.76h.87v1.76H541v.66h-1.25v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16H541v.73h-.58a1.66,1.66,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#1e0f00"
        />
        <path
          d="M546.84,580.1a2.59,2.59,0,0,1-.57,1.76,2.47,2.47,0,0,1-3.34,0,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.5,2.5,0,0,1,3.34,0,2.58,2.58,0,0,1,.57,1.77Zm-2.24,1.68a1.28,1.28,0,0,0,1-.41,1.76,1.76,0,0,0,.35-1.16v-4.59a1.76,1.76,0,0,0-.35-1.16,1.28,1.28,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.3,1.3,0,0,0,544.6,581.78Z"
          fill="#1e0f00"
        />
        <path
          d="M551.82,575.48a1.69,1.69,0,0,0-.29-1.07,1.16,1.16,0,0,0-.95-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.87v-9h.87v.88h0a2,2,0,0,1,.59-.69,1.71,1.71,0,0,1,1.06-.29,1.69,1.69,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M773.73,571.66h2.16a2.22,2.22,0,0,1,1.66.53,2.07,2.07,0,0,1,.51,1.5v2.74a1.89,1.89,0,0,1-2.17,2.08h-1.25v3.9h-.91Zm.91.72v5.4h1.15a1.44,1.44,0,0,0,1-.33,1.33,1.33,0,0,0,.33-1v-2.83c0-.85-.46-1.28-1.37-1.28Z"
          fill="#1e0f00"
        />
        <path
          d="M782.44,574.32h-.14a1.49,1.49,0,0,0-1,.36,1.41,1.41,0,0,0-.39,1v6.69H780v-9h.88v1.11h0a2.67,2.67,0,0,1,.2-.42,1.85,1.85,0,0,1,.32-.37,1.68,1.68,0,0,1,.45-.27,1.47,1.47,0,0,1,.58-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M787.62,582.41v-.81h0a1.64,1.64,0,0,1-1.55.91,1.84,1.84,0,0,1-1.4-.51,2.36,2.36,0,0,1-.47-1.62v-.73a2.51,2.51,0,0,1,.13-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44l.78-.37a5,5,0,0,0,1.24-.67.77.77,0,0,0,.32-.58v-.62a1.91,1.91,0,0,0-.24-1.06,1,1,0,0,0-.91-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.29,1v.88h-.85v-.81a2.26,2.26,0,0,1,.51-1.57,2.09,2.09,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.4,1.4,0,0,0,1-.36,1.38,1.38,0,0,0,.39-1.07v-3.17a1,1,0,0,1-.14.15l-.24.17a3.1,3.1,0,0,1-.39.23,6.65,6.65,0,0,1-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.42,1.42,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,786.24,581.78Z"
          fill="#1e0f00"
        />
        <path
          d="M794.85,582.41H794v-.87h0a2.31,2.31,0,0,1-.54.66,1.58,1.58,0,0,1-1,.31,1.87,1.87,0,0,1-1.42-.52,2.26,2.26,0,0,1-.5-1.62v-4.91a2.1,2.1,0,0,1,.55-1.62,1.89,1.89,0,0,1,1.37-.52,2.13,2.13,0,0,1,.6.08,1.55,1.55,0,0,1,.45.21,1.23,1.23,0,0,1,.31.29,2.54,2.54,0,0,1,.23.34h0v-2.58h.87Zm-3.54-2.08a1.66,1.66,0,0,0,.3,1.08,1.15,1.15,0,0,0,1,.37,1.36,1.36,0,0,0,1-.39,1.53,1.53,0,0,0,.38-1.1V575.5a1.54,1.54,0,0,0-.42-1.07,1.34,1.34,0,0,0-1-.38c-.86,0-1.29.48-1.29,1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M800.23,582.41v-.81h0a1.66,1.66,0,0,1-.56.63,1.6,1.6,0,0,1-1,.28,1.8,1.8,0,0,1-1.39-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.66,1.66,0,0,1,.38-.61,2.58,2.58,0,0,1,.61-.44l.77-.37a4.78,4.78,0,0,0,1.24-.67.77.77,0,0,0,.32-.58v-.62a1.91,1.91,0,0,0-.24-1.06,1,1,0,0,0-.9-.37,1.22,1.22,0,0,0-1,.34,1.52,1.52,0,0,0-.29,1v.88H797v-.81a2.26,2.26,0,0,1,.51-1.57,2.06,2.06,0,0,1,1.6-.56,1.88,1.88,0,0,1,1.54.56,2.69,2.69,0,0,1,.44,1.66v6.87Zm-1.38-.63a1.4,1.4,0,0,0,1-.36,1.38,1.38,0,0,0,.39-1.07v-3.17a.87.87,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23l-.6.3-.55.3a1.78,1.78,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,1.82,1.82,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.28,1.07A1.14,1.14,0,0,0,798.85,581.78Z"
          fill="#1e0f00"
        />
        <path
          d="M823.73,416.66h2.16a2.22,2.22,0,0,1,1.66.53,2.07,2.07,0,0,1,.51,1.5v2.74a1.89,1.89,0,0,1-2.17,2.08h-1.25v3.9h-.91Zm.91.72v5.4h1.15a1.44,1.44,0,0,0,1-.33,1.33,1.33,0,0,0,.33-1v-2.83c0-.85-.46-1.28-1.37-1.28Z"
          fill="#1e0f00"
        />
        <path
          d="M832.86,427.41v-.81h0a1.64,1.64,0,0,1-1.55.91,1.84,1.84,0,0,1-1.4-.51,2.36,2.36,0,0,1-.47-1.62v-.73a2.51,2.51,0,0,1,.13-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44l.78-.37a5,5,0,0,0,1.24-.67.77.77,0,0,0,.32-.58v-.62a1.91,1.91,0,0,0-.24-1.06,1,1,0,0,0-.91-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.29,1v.88h-.85v-.81a2.26,2.26,0,0,1,.51-1.57,2.09,2.09,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.4,1.4,0,0,0,1-.36,1.38,1.38,0,0,0,.39-1.07v-3.17a1,1,0,0,1-.14.15l-.24.17a3.1,3.1,0,0,1-.39.23,6.65,6.65,0,0,1-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.42,1.42,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,831.48,426.78Z"
          fill="#1e0f00"
        />
        <path
          d="M836.08,425.35c0,.95.41,1.43,1.23,1.43a1.34,1.34,0,0,0,.94-.34,1.4,1.4,0,0,0,.41-1v-7h.88v9h-.88v-.89h0a1.63,1.63,0,0,1-1.65,1,1.73,1.73,0,0,1-1.34-.51,2.41,2.41,0,0,1-.46-1.62v-7h.88Z"
          fill="#1e0f00"
        />
        <path d="M841.09,416.66H842v10.75h-.87Z" fill="#1e0f00" />
        <path
          d="M803,220.66a2.85,2.85,0,0,1,1.15.2,2.09,2.09,0,0,1,.76.57,2.53,2.53,0,0,1,.43.85,3.85,3.85,0,0,1,.13,1v5.43a3.9,3.9,0,0,1-.13,1,2.39,2.39,0,0,1-.43.84,2.09,2.09,0,0,1-.76.57,2.85,2.85,0,0,1-1.15.21h-2.26V220.66Zm-1.35,10h1.23a1.54,1.54,0,0,0,1.31-.51,2.12,2.12,0,0,0,.38-1.27v-5.73a2.5,2.5,0,0,0-.09-.69,1.4,1.4,0,0,0-.29-.57,1.28,1.28,0,0,0-.52-.38,1.89,1.89,0,0,0-.79-.15h-1.23Z"
          fill="#1e0f00"
        />
        <path
          d="M811.41,228.3v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.16,2.16,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.59v2.3a1.72,1.72,0,0,0,.37,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.23,1.23,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M815.44,220.66h2.08a2.22,2.22,0,0,1,1.64.51,2,2,0,0,1,.5,1.5v1.48a2.09,2.09,0,0,1-.26,1.09,1.26,1.26,0,0,1-.86.57,1.56,1.56,0,0,1,1.41,1.73v1.86a2,2,0,0,1-.52,1.5,2.27,2.27,0,0,1-1.66.51h-2.33Zm.91.72v4.11h1.14c.88,0,1.32-.42,1.32-1.27v-1.56c0-.85-.46-1.28-1.37-1.28Zm1.33,9.3c.92,0,1.38-.42,1.38-1.27v-1.94a1.2,1.2,0,0,0-.33-.93,1.57,1.57,0,0,0-1.08-.32h-1.3v4.46Z"
          fill="#1e0f00"
        />
        <path
          d="M825.77,228.3v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.09,2.09,0,0,1,1.66-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.56,1.74v2.24h-3.59v2.3a1.72,1.72,0,0,0,.37,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.33-.5,1.33-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M831.7,228.3v.89a2.51,2.51,0,0,1-.53,1.72,2.1,2.1,0,0,1-1.64.6,2.16,2.16,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.58v2.3a1.71,1.71,0,0,0,.36,1.16,1.38,1.38,0,0,0,1.07.41c.89,0,1.33-.5,1.33-1.51v-1Zm-3.58-2.08h2.73v-1.61a1.72,1.72,0,0,0-.34-1.15,1.23,1.23,0,0,0-1-.41,1.27,1.27,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M835.69,223.32h-.15a1.45,1.45,0,0,0-1,.36,1.38,1.38,0,0,0-.4,1v6.69h-.87v-9h.87v1.11h0a2.74,2.74,0,0,1,.21-.42,1.58,1.58,0,0,1,.32-.37,1.48,1.48,0,0,1,.44-.27,1.52,1.52,0,0,1,.59-.1Z"
          fill="#1e0f00"
        />
        <path
          d="M841.18,229.38a2.26,2.26,0,0,1-.51,1.57,2.11,2.11,0,0,1-1.63.56,2,2,0,0,1-1.58-.6,2.29,2.29,0,0,1-.52-1.59v-.89h.83v.92a1.68,1.68,0,0,0,.3,1.05,1.17,1.17,0,0,0,1,.38,1.25,1.25,0,0,0,1-.34,1.45,1.45,0,0,0,.31-1v-.29a1.59,1.59,0,0,0-.23-.85,3.91,3.91,0,0,0-.85-.86l-.92-.73a4.8,4.8,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.82v-.68a1.57,1.57,0,0,0-.29-1,1.49,1.49,0,0,0-1.86,0,1.24,1.24,0,0,0-.28.86v.25a1.62,1.62,0,0,0,0,.4.93.93,0,0,0,.16.35,3.12,3.12,0,0,0,.31.35c.13.12.29.26.49.42l.93.73c.26.21.48.41.67.59a3.33,3.33,0,0,1,.44.53,2.28,2.28,0,0,1,.23.56,3.28,3.28,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
        <path
          d="M699.73,391.66h3.84v.72h-2.93v4.19h2.2v.73h-2.2v5.11h-.91Z"
          fill="#1e0f00"
        />
        <path
          d="M709.28,399.3v.89a2.46,2.46,0,0,1-.54,1.72,2.06,2.06,0,0,1-1.63.6,2.16,2.16,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.24H705.7v2.3a1.71,1.71,0,0,0,.36,1.16,1.38,1.38,0,0,0,1.07.41c.89,0,1.33-.5,1.33-1.51v-1Zm-3.58-2.08h2.73v-1.61a1.72,1.72,0,0,0-.34-1.15,1.23,1.23,0,0,0-1-.41,1.27,1.27,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#1e0f00"
        />
        <path
          d="M714.27,395.48a1.63,1.63,0,0,0-.3-1.07,1.14,1.14,0,0,0-.94-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.87v-9h.87v.88h0a1.92,1.92,0,0,1,.58-.69,1.77,1.77,0,0,1,1.07-.29,1.7,1.7,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M721,402.41h-.87v-.87h0a2.31,2.31,0,0,1-.54.66,1.57,1.57,0,0,1-1.05.31,1.87,1.87,0,0,1-1.42-.52,2.26,2.26,0,0,1-.5-1.62v-4.91a2.1,2.1,0,0,1,.55-1.62,1.89,1.89,0,0,1,1.37-.52,2.13,2.13,0,0,1,.6.08,1.55,1.55,0,0,1,.45.21,1.47,1.47,0,0,1,.32.29,4,4,0,0,1,.22.34h0v-2.58H721Zm-3.54-2.08a1.66,1.66,0,0,0,.3,1.08,1.15,1.15,0,0,0,1,.37,1.36,1.36,0,0,0,1-.39,1.53,1.53,0,0,0,.38-1.1V395.5a1.54,1.54,0,0,0-.42-1.07,1.34,1.34,0,0,0-1-.38c-.86,0-1.29.48-1.29,1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M722.6,391.66h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
      </g>
    </svg>
  </MapTransformer>
);

FirstFloor.defaultProps = {
  mapWidth: 1419.89,
  mapHeight: 1005.28,
};

FirstFloor.propTypes = {
  mapHeight: PropTypes.number,
  mapWidth: PropTypes.number,
  MapTransformer: PropTypes.func.isRequired,
};

export default FirstFloor;
