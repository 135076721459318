import { connect } from 'react-redux';

import FloorOption from '@/components/MapWorkspace/FloorOption';
import { updateFloor } from '@/features/locationsStatisticsPage/actions';
import {
  getFloorUsersStatisticsUrl,
  isCurrentFloor,
} from '@/features/locationsStatisticsPage/selectors';

const mapStateToProps = (state, { floor }) => ({
  selected: isCurrentFloor(state, floor),
  statisticsUrl: getFloorUsersStatisticsUrl(state, floor),
});

const mapDispatchToProps = { updateFloor };

export default connect(mapStateToProps, mapDispatchToProps)(FloorOption);
