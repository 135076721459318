import {
  AVG_TICKET_KEY,
  NR_RECEIPTS_KEY,
} from '@/features/anonymousPurchasesStatistics/consts/keys';
import { formatMoney, formatNumber } from '@/utils/format';

export default [
  {
    key: NR_RECEIPTS_KEY,
    url_key: 'all',
    label: 'Количество покупок',
    formatter: formatNumber,
    labelFormatter: () => 'количество покупок',
  },
  {
    key: AVG_TICKET_KEY,
    url_key: 'average_sum',
    label: 'Средний чек',
    formatter: formatMoney,
    labelFormatter: () => 'средний чек',
  },
];
