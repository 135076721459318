export default {
  cars: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  fieldContainer: {
    flexBasis: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 18,
  },
};
