import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { formatHumanDate } from '@/utils/format';
import { userGender } from '@/utils/labels';

import Field from './Field';

const PersonalInfo = ({
  classes,
  birthDate,
  gender,
  phone,
  userId,
  iin,
  email,
}) => (
  <div className={classes.root}>
    <Typography
      gutterBottom
      color="textSecondary"
      variant="h5"
      className={classes.title}
    >
      Персональные данные
    </Typography>

    <div className={classes.facts}>
      <Field label="ID Клиента" className={classes.fact}>
        {userId}
      </Field>

      <Field label="Пол" className={classes.fact}>
        {userGender(gender)}
      </Field>

      <Field label="Дата рождения" className={classes.fact}>
        {birthDate ? formatHumanDate(birthDate) : 'не указана'}
      </Field>

      <Field label="Телефон" className={classes.fact}>
        {phone}
      </Field>

      <Field label="ИИН" className={classes.fact}>
        {iin}
      </Field>

      <Field label="Email" className={classes.fact}>
        {email}
      </Field>
    </div>
  </div>
);

PersonalInfo.defaultProps = {
  birthDate: null,
};

PersonalInfo.propTypes = {
  birthDate: PropTypes.instanceOf(Date),
  gender: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  iin: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default PersonalInfo;
