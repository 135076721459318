import { Grid, withStyles } from '@material-ui/core';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

const PageHeader = ({
  className,
  borderBottom,
  gutterBottom,
  classes,
  ...props
}) => (
  <Grid
    className={cx(
      className,
      borderBottom && classes.withBorderBottom,
      gutterBottom && classes.withGutterBottom,
    )}
    item
    sm={10}
    {...props}
  />
);

PageHeader.propTypes = {
  className: PropTypes.string,
  borderBottom: PropTypes.bool,
  gutterBottom: PropTypes.bool,
};

PageHeader.defaultProps = {
  className: '',
  borderBottom: false,
  gutterBottom: true,
};

export default withStyles(styles)(PageHeader);
