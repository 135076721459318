import serializer from '@/features/campaignsStatisticsPage/serializer';
import createQueryDuck from '@/redux/query';

import { ENTITY_NAME } from '../consts';
import { getQuery } from '../utils';

export default createQueryDuck(ENTITY_NAME, {
  getRoot: getQuery,
  serializer,
});
