import {
  ACTION_RESULT_TYPE,
  ARTICLE_TYPE,
  BANNER_TYPE,
  BUTTON_TYPE,
  CALENDAR_EVENT_TYPE,
  NOTIFICATION_TYPE,
  PURCHASE_RESULT_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import { hasFilter } from '@esentai/core/query-dsl';
import { not, path, pipe } from 'ramda';

import { LOCATION_ID_KEY } from '@/features/usersStatistics/consts/filterKeys';
import { PAGES_STORE_KEY } from '@/redux/constants';

import {
  ACTION_RESULT_FILTERS_IDS,
  ARTICLE_FILTERS_IDS,
  BANNER_FILTERS_IDS,
  BUTTON_FILTERS_IDS,
  CALENDAR_EVENT_FILTERS_IDS,
  NOTIFICATION_FILTERS_IDS,
  PURCHASE_RESULT_FILTERS_IDS,
} from './consts/filters';
import {
  ACTION_RESULT_METRICS_IDS,
  ARTICLE_METRICS_IDS,
  BANNER_METRICS_IDS,
  BUTTON_METRICS_IDS,
  CALENDAR_EVENT_METRICS_IDS,
  NOTIFICATION_METRICS_IDS,
  PURCHASE_RESULT_METRICS_IDS,
} from './consts/metrics';

export const getItemsRoot = path([
  PAGES_STORE_KEY,
  'campaignsStatisticsPage',
  'items',
]);

export const getQuery = path([
  PAGES_STORE_KEY,
  'campaignsStatisticsPage',
  'query',
]);

const hasLocation = hasFilter(LOCATION_ID_KEY);

export const hasLocationFilter = pipe(getQuery, hasLocation);

export const hasNotLocationFilter = pipe(hasLocationFilter, not);

export const getFiltersIdsByCampaignType = campaignType => {
  const mapping = {
    [ACTION_RESULT_TYPE]: ACTION_RESULT_FILTERS_IDS,
    [ARTICLE_TYPE]: ARTICLE_FILTERS_IDS,
    [BANNER_TYPE]: BANNER_FILTERS_IDS,
    [BUTTON_TYPE]: BUTTON_FILTERS_IDS,
    [CALENDAR_EVENT_TYPE]: CALENDAR_EVENT_FILTERS_IDS,
    [NOTIFICATION_TYPE]: NOTIFICATION_FILTERS_IDS,
    [PURCHASE_RESULT_TYPE]: PURCHASE_RESULT_FILTERS_IDS,
  };

  return mapping[campaignType];
};

export const getMetricsIdsByCampaignType = campaignType => {
  const mapping = {
    [ACTION_RESULT_TYPE]: ACTION_RESULT_METRICS_IDS,
    [ARTICLE_TYPE]: ARTICLE_METRICS_IDS,
    [BANNER_TYPE]: BANNER_METRICS_IDS,
    [BUTTON_TYPE]: BUTTON_METRICS_IDS,
    [CALENDAR_EVENT_TYPE]: CALENDAR_EVENT_METRICS_IDS,
    [NOTIFICATION_TYPE]: NOTIFICATION_METRICS_IDS,
    [PURCHASE_RESULT_TYPE]: PURCHASE_RESULT_METRICS_IDS,
  };

  return mapping[campaignType];
};
