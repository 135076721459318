export default {
  wrapper: {
    outline: 0,
    width: 920,
    maxHeight: '80%',
    overflowY: 'auto',
    padding: 27,
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,
  },

  product: {
    marginRight: 15,
  },

  hideArrows: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  errorField: {
    borderBottom: '1px solid red',
  },
};
