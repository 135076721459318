import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import BenefitTitleLazy from '@/containers/BenefitTitleLazy';

const BenefitChip = ({ value, ...props }) => (
  <Chip {...props} id={value} label={<BenefitTitleLazy itemId={value} />} />
);

BenefitChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default BenefitChip;
