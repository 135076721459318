import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import {
  formatMoney,
  formatNumber,
  formatRateAsPercentage,
} from '@/utils/format';

const CommonCells = ({
  averagePurchases,
  averageReceipts,
  averageTicket,
  children,
  percentageReceiptsByCampaign,
  purchasesCount,
}) => (
  <Fragment>
    <CondenseTableCell align="right">
      {formatNumber(purchasesCount)}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatNumber(averageReceipts.toFixed(2))}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatNumber(averagePurchases.toFixed(2))}
    </CondenseTableCell>
    <CondenseTableCell align="right">
      {formatMoney(averageTicket)}
    </CondenseTableCell>
    {children}
    <CondenseTableCell>
      {formatRateAsPercentage(percentageReceiptsByCampaign)}
    </CondenseTableCell>
  </Fragment>
);

CommonCells.propTypes = {
  averagePurchases: PropTypes.number.isRequired,
  averageReceipts: PropTypes.number.isRequired,
  averageTicket: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  percentageReceiptsByCampaign: PropTypes.number.isRequired,
  purchasesCount: PropTypes.number.isRequired,
};

export default CommonCells;
