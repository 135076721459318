import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import api from '@/api';

import EditLoyaltyLevelForm from './Form';

class EditLoyaltyLevelModal extends Component {
  submitData(values) {
    api
      .doPost('/customer-activity-history', {
        customer_activity_history: {
          ...values,
          customer_id: this.props.userId,
          type: 'LEVEL CHANGED',
        },
      })
      .then(() => {
        this.props.onClose();
        this.props.fetchItem(this.props.userId);
      });
  }

  render() {
    const { isOpen, onClose, status } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">
          Изменить уровень лояльности
        </DialogTitle>

        <DialogContent>
          <EditLoyaltyLevelForm
            onClose={onClose}
            onSubmit={this.submitData.bind(this)}
            currentStatus={status}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

EditLoyaltyLevelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  fetchItem: PropTypes.func.isRequired,
};

export default EditLoyaltyLevelModal;
