import { applySpec } from 'ramda';

import createQueryChangeWatcher from './sagas/createQueryChangeWatcher';
import createQuerySaga from './sagas/createQuerySaga';
import synchronizeQuery from './sagas/synchronizeQuery';

const sagasMap = {
  createQueryChangeWatcher,
  createQuerySaga,
  synchronizeQuery,
};

export default applySpec(sagasMap);
