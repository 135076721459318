import { can, canCreate, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { RESOURCE_NAME } from './consts';
import { CREATE_MALL_STAFF } from './consts/permissions';

export const canCreateInvite = authorizeIf(canCreate(RESOURCE_NAME));
export const canInviteMallStaff = authorizeIf(
  can(permission(RESOURCE_NAME, CREATE_MALL_STAFF)),
);
