import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import StatisticsTopBar from '@/components/StatisticsTopBar';
import {
  MAX_STATISTICS_DATE,
  MIN_STATISTICS_DATE,
} from '@/features/campaignsStatistics/consts/dates';
import { updateFilters } from '@/features/campaignsStatisticsPage/actions';
import {
  getFilters,
  getMetrics,
} from '@/features/campaignsStatisticsPage/selectors';

import InnerTopBar from './InnerTopBar';

const mapStateToProps = state => ({
  filters: getFilters(state),
  stats: getMetrics(state),
  minDate: MIN_STATISTICS_DATE,
  maxDate: MAX_STATISTICS_DATE,
});

const mapDispatchToProps = { updateFilters };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({ InnerTopBarComponent: InnerTopBar }),
)(StatisticsTopBar);
