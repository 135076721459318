import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Selection extends Component {
  static propTypes = {
    Chip: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    selectedItem: PropTypes.arrayOf(PropTypes.any),
  };

  static defaultProps = {
    selectedItem: [],
  };

  render() {
    const { selectItem, selectedItem, Chip, classes } = this.props;

    return selectedItem.map(item => (
      <Chip
        className={classes.chip}
        key={item}
        value={item}
        onDelete={selectItem}
      />
    ));
  }
}

export default Selection;
