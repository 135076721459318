import { ButtonBase } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Block } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class OrderSwitcher extends Component {
  static propTypes = {
    canMoveDown: PropTypes.bool.isRequired,
    canMoveUp: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    moveDown: PropTypes.func.isRequired,
    moveUp: PropTypes.func.isRequired,
  };

  render() {
    const { canMoveDown, canMoveUp, disabled } = this.props;
    const isShowSwitch = disabled || canMoveUp || canMoveDown;

    return isShowSwitch && this.renderSwitch();
  }

  renderSwitch() {
    const { disabled } = this.props;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.switch}>
          {this.renderDisabledState()}
          {!disabled && this.renderUpButton()}
          {!disabled && this.renderDownButton()}
        </div>
      </div>
    );
  }

  renderUpButton() {
    const { classes, canMoveUp, moveUp } = this.props;

    return (
      canMoveUp && (
        <ButtonBase
          className={classes.button}
          onClick={moveUp}
          disableTouchRipple
        >
          <ArrowUpward color="action" />
        </ButtonBase>
      )
    );
  }

  renderDownButton() {
    const { classes, canMoveDown, moveDown } = this.props;

    return (
      canMoveDown && (
        <ButtonBase
          className={classes.button}
          onClick={moveDown}
          disableTouchRipple
        >
          <ArrowDownward color="action" />
        </ButtonBase>
      )
    );
  }

  renderDisabledState() {
    const { classes, disabled } = this.props;

    return (
      disabled && (
        <ButtonBase className={classes.button} disabled>
          <Block color="disabled" />
        </ButtonBase>
      )
    );
  }
}

export default OrderSwitcher;
