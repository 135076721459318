import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/benefitsPage/ducks/query';
import { canCreateBenefit } from '@/features/benefitsPage/permissions';
import {
  getBenefitsError,
  getBenefitsIds,
  getBenefitsTotalCount,
  isLoadingBenefits,
} from '@/features/benefitsPage/selectors';
import { canReadCampaigns } from '@/features/campaignsPage/permissions';
import withQuerySync from '@/hocs/withQuerySync';

import Campaigns from './component';

const mapStateToProps = state => ({
  error: getBenefitsError(state),
  itemsIds: getBenefitsIds(state),
  totalCount: getBenefitsTotalCount(state),
  isLoading: isLoadingBenefits(state),
  showCampaignsLink: canReadCampaigns(state),
  canCreateBenefit: canCreateBenefit(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(Campaigns);
