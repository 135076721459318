import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getSaleError,
  getSaleIds,
  getSaleTotalCount,
  getStoreId,
  isLoadingSale,
} from '@/features/salesTurnoverDetailsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import Product from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getStoreId(state);

  return {
    error: getSaleError(state),
    saleIds: getSaleIds(state),
    isLoading: isLoadingSale(state),
    totalCount: getSaleTotalCount(state),
    itemId,
  };
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(Product);
