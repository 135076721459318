import { getStoreBrandsIdsByIndice } from '@esentai/core/features/stores/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { getStoreId } from '@/features/storePage/selectors';

import IndiceBrandsList from './component';
import styles from './styles';

const mapStateToProps = (state, { indice }) => {
  const storeId = getStoreId(state);

  return {
    storeBrandsIdsByIndice: getStoreBrandsIdsByIndice(state, storeId, indice),
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(IndiceBrandsList);
