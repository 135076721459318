import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { synchronize } from '@/features/siteSyncPage/actions';

import SynchronizeButton from './component';

const mapDispatchToProps = { onClick: synchronize };

export default compose(
  connect(null, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(SynchronizeButton);
