import { grey } from '@material-ui/core/colors';

export default {
  leftYAxis: {
    '& text': {
      color: '#4A90E2',
    },

    '& .domain, line': {
      color: grey[300],
    },
  },
};
