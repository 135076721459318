import PropTypes from 'prop-types';
import React from 'react';

import ButtonArchive from '@/components/ActionButtons/Archive';

const ArchiveButton = ({ isActive, ...rest }) =>
  isActive ? <ButtonArchive {...rest} /> : null;

ArchiveButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default ArchiveButton;
