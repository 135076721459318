import { TableCell, TableRow } from '@material-ui/core';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import { ROUTE_PATH as USERS_PATH } from '@/features/usersPage/consts';
import { dateTimeFormat } from '@/utils/format';

const Row = props => {
  const { id, vehicle_gov_numbers, vehicle_models, created_dates } = props.item;

  const getCar = index => {
    const carNumber = _get(vehicle_gov_numbers, index, '');
    const carModel = _get(vehicle_models, index, '');

    if (!carNumber && !carModel) {
      return '-';
    }

    return `${carNumber} ${carModel}`;
  };

  const getCreatedDate = index => {
    const date = _get(created_dates, index, '');

    if (!date) {
      return '-';
    }

    return dateTimeFormat(date);
  };

  return (
    <TableRow>
      <TableCell>
        <Link to={`${USERS_PATH}/${id}`}>{id}</Link>
      </TableCell>
      <TableCell>{getCar(0)}</TableCell>
      <TableCell>{getCreatedDate(0)}</TableCell>
      <TableCell>{getCar(1)}</TableCell>
      <TableCell>{getCreatedDate(1)}</TableCell>
      <TableCell>{getCar(2)}</TableCell>
      <TableCell>{getCreatedDate(2)}</TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
