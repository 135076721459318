export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  excelButton: {
    marginTop: 15,
  },
  fieldRow: { minHeight: 80 },
  fact: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  facts: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flexWrap: 'wrap',
    marginTop: 15,
  },
};
