import { ACTIVATE_ONCE_KEY } from '@esentai/core/features/campaigns/actionResult/consts/keys';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import FieldRow from '@/components/FieldRow';

import { CanEdit } from '../../CanEdit';

class RepetitionForm extends PureComponent {
  static propTypes = {
    activateOnce: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  };

  setActivateOnce = event => {
    const { checked } = event.target;
    const { setFieldValue } = this.props;

    setFieldValue(ACTIVATE_ONCE_KEY, checked);
  };

  render() {
    const { activateOnce } = this.props;

    return (
      <FieldRow
        label="Повторение"
        subLabel="Количество активаций триггера"
        sudDesc="Если установлен данный ограничитель, то кампания будет срабатывать для клиента лишь один раз, после чего он больше не сможет ею воспользоваться. Без ограничителя, клиент будет активировать триггер кампании каждый раз при выполнении условий."
      >
        <CanEdit field={ACTIVATE_ONCE_KEY}>
          <FormControlLabel
            label="Единоразовая кампания"
            control={
              <Checkbox
                checked={activateOnce}
                onChange={this.setActivateOnce}
              />
            }
          />
        </CanEdit>
      </FieldRow>
    );
  }
}

export default RepetitionForm;
