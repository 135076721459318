import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemPageRunner from '@/hocs/withItemPageRunner';

import component from './component';

const mapStateToProps = (
  state,
  { itemId, getItemLoadingError, isItemLoaded, isItemLoading },
) => ({
  error: getItemLoadingError(state, itemId),
  isLoaded: isItemLoaded(state, itemId),
  isLoading: isItemLoading(state, itemId),
});

const mapDispatchToProps = (dispatch, { fetchItem }) => ({
  fetchItem: (...args) => dispatch(fetchItem(...args)),
});

const LazyEditForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(component);

LazyEditForm.propTypes = {
  isItemLoaded: PropTypes.func.isRequired,
  isItemLoading: PropTypes.func.isRequired,
  fetchItem: PropTypes.func.isRequired,
  itemId: PropTypes.any.isRequired,
};

export default LazyEditForm;
