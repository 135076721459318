import { applySpec } from 'ramda';

import getFilters from './selectors/getFilters';
import getLimit from './selectors/getLimit';
import getPage from './selectors/getPage';
import getQuery from './selectors/getQuery';
import getSort from './selectors/getSort';
import isMounted from './selectors/isMounted';

const selectorsMap = {
  getFilters,
  getLimit,
  getPage,
  getQuery,
  getSort,
  isMounted,
};

export default applySpec(selectorsMap);
