import api from '@esentai/core/features/productView/api';
import {
  CATEGORY_KEY,
  NAME_RU_KEY,
} from '@esentai/core/features/productView/consts/keys';
import { equals, ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteBase from '@/components/AutocompleteBase';
import AutocompleteMultipleBase from '@/components/AutocompleteMultipleBase';
import AutocompleteAPI from '@/containers/AutocompleteAPI';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';
import withViewsFetcherWithCategory from '@/hocs/withViewsFetcherWithCategory';

import ProductViewChip from './ProductViewChip';
import ProductViewOption from './ProductViewOption';

const buildQuery = queryString => query(where(NAME_RU_KEY, ilike(queryString)));

const buildQueryWithCategory = (queryString, categoryId) =>
  query(
    where(NAME_RU_KEY, ilike(queryString || '')),
    where(
      CATEGORY_KEY,
      equals(typeof categoryId === 'number' ? categoryId : categoryId.id),
    ),
  );

const withProductViewProps = defaultProps({
  api,
  buildQuery,
  categoryId: 0,
  Chip: ProductViewChip,
  Option: ProductViewOption,
});

const withProductViewWithCategory = defaultProps({
  api,
  buildQuery: buildQueryWithCategory,
  categoryId: 0,
  Chip: ProductViewChip,
  Option: ProductViewOption,
});

export const ProductViewAutocomplete = withProductViewProps(AutocompleteAPI);

export const ProductViewMultiAutocomplete = withProductViewProps(
  AutocompleteAPIMultiple,
);

const AutocompleteAPIWithCategoryId = defaultProps({
  Autocomplete: AutocompleteBase,
})(withViewsFetcherWithCategory(AutocompleteAPI));

const AutocompleteAPIMultipleWithCategoryId = defaultProps({
  Autocomplete: AutocompleteMultipleBase,
})(withViewsFetcherWithCategory(AutocompleteAPI));

export const ProductViewAutocompleteWithCategory = withProductViewWithCategory(
  AutocompleteAPIWithCategoryId,
);

export const ProductViewMultiAutocompleteWithCategory = withProductViewWithCategory(
  AutocompleteAPIMultipleWithCategoryId,
);
