import {
  getQueryPredicate,
  hasQueryPredicate,
  mutablyOmitQueryPredicate,
} from 'awokado/structs/query';
import { compose, equals, filter, isNil, not, path } from 'ramda';

export default annotations => next => {
  const defaultValueProp = path(['options', 'defaultValue']);
  const hasDefaultValue = compose(not, isNil, defaultValueProp);
  const paramsWithDefaults = filter(hasDefaultValue, annotations);

  const serialize = (parsedParams, urlSearchParams) => {
    for (const [key, definition] of Object.entries(paramsWithDefaults)) {
      const defaultValue = defaultValueProp(definition);

      if (hasQueryPredicate(key, parsedParams)) {
        const value = getQueryPredicate(key, parsedParams);

        if (equals(value, defaultValue)) {
          mutablyOmitQueryPredicate(parsedParams, key);
        }
      }
    }

    return next.serialize(parsedParams, urlSearchParams);
  };

  return { parse: next.parse, serialize };
};
