import {
  PAYLOAD_KEY,
  TYPE_KEY,
} from '@esentai/core/features/applicationSettings/homeLayout/consts/blockKeys';
import {
  ARTICLES_BLOCK,
  BANNERS_BLOCK,
  BUTTON_BLOCK,
  CALENDAR_BLOCK,
} from '@esentai/core/features/applicationSettings/homeLayout/consts/blocks';
import {
  ARTICLE_COUNT_KEY,
  EVENT_COUNT_KEY,
} from '@esentai/core/features/applicationSettings/homeLayout/consts/payloadKeys';
import { array, number, object, string } from 'yup';

const articlePayload = object().shape({
  [ARTICLE_COUNT_KEY]: number()
    .integer('Число статей должно быть целым')
    .positive('Число статей должно быть больше нуля'),
});

const bannersPayload = object().shape({});

const buttonPayload = object().shape({});

const calendarEventPayload = object().shape({
  [EVENT_COUNT_KEY]: number()
    .integer('Число событий должно быть целым')
    .positive('Число событий должно быть больше нуля'),
});

const typeMapping = {
  [ARTICLES_BLOCK]: articlePayload,
  [BANNERS_BLOCK]: bannersPayload,
  [BUTTON_BLOCK]: buttonPayload,
  [CALENDAR_BLOCK]: calendarEventPayload,
};

const block = object({
  id: string().required(),
  [PAYLOAD_KEY]: object()
    .when(TYPE_KEY, type => typeMapping[type])
    .required(),
  [TYPE_KEY]: string().required(),
});

export default array()
  .of(block)
  .required();
