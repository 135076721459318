import {
  FACTORY_NUMBER_KEY,
  KKM_NUMBER_KEY,
  MERCHANT_ID_KEY,
  STATUS_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/kkms/consts/keys';

import { createKkmStateFilter } from '@/filters/KkmStatus';
import { createMerchantFilter } from '@/filters/Merchant';
import { createTextFilter } from '@/filters/SearchInput';
import { createStoreFilter } from '@/filters/Store';

export const filterTypes = [
  createKkmStateFilter({
    key: STATUS_KEY,
    label: 'Состояние магазина',
    quicklyAccessible: true,
  }),
  createTextFilter({
    key: KKM_NUMBER_KEY,
    label: 'Номер ККМ',
  }),
  createMerchantFilter({
    key: MERCHANT_ID_KEY,
    label: 'БИН/ИИН',
  }),
  createStoreFilter({
    key: STORE_ID_KEY,
    label: 'Магазин',
  }),
  createTextFilter({
    key: FACTORY_NUMBER_KEY,
    label: 'ЗНМ',
  }),
];
