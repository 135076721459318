import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import { placement as placementTypeLabel } from '@/features/campaignPage/labels';

class PlacementForm extends Component {
  render() {
    const { placement, classes } = this.props;

    return (
      <FieldRow label="Размещение">
        <Typography className={classes.placementText} color="inherit">
          {placementTypeLabel(placement)}
        </Typography>
      </FieldRow>
    );
  }
}

PlacementForm.propTypes = {
  placement: PropTypes.string.isRequired,
};

export default PlacementForm;
