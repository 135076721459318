export const basicValidators = {
  number: val => !isNaN(val) || val === '-',
  percentage: val => val >= 0 && val <= 100,
  int: val => /^[-+]?\d+$/gm.test(val) || val === '-',
  positiveInt: val => /^[-+]?\d+$/gm.test(val),
  text: val => /^[a-zA-Z\u0400-\u04FF ]*$/gm.test(val),
};

export const validate = (event, type) => {
  const nextValue = event.target.value + event.key;
  const validator = basicValidators[type];
  const isValid = validator(nextValue);

  if (!isValid) {
    event.preventDefault();
  }
};

export const onArrowValidate = event => {
  if (event.keyCode === 38 || event.keyCode === 40) {
    event.preventDefault();
  }
};
