import { findUserById } from '@esentai/core/features/users/actions';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Root from './component';
import styles from './styles';

const mapDispatchToProps = {
  fetchItem: findUserById,
};

export default compose(withStyles(styles))(
  connect(null, mapDispatchToProps)(Root),
);
