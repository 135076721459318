import { connect } from 'react-redux';

import TimeDistributionChart from '@/components/TimeDistributionChart';
import {
  getPercentageReceiptsByDay,
  getPercentageReceiptsByEvening,
  getPercentageReceiptsByMorning,
} from '@/features/anonymousPurchasesStatistics/selectors';

const mapStateToProps = (state, { itemId }) => ({
  percentageByMorning: getPercentageReceiptsByMorning(state, itemId),
  percentageByDay: getPercentageReceiptsByDay(state, itemId),
  percentageByEvening: getPercentageReceiptsByEvening(state, itemId),
});

export default connect(mapStateToProps)(TimeDistributionChart);
