import {
  ID_KEY,
  NAME_KEY,
} from '@esentai/core/features/parkingWhitelistGroups/consts/keys';

import { createTextFilter } from '@/filters/SearchInput';
import { createIncludeFilter } from '@/filters/SearchInputs';

export const filterTypes = [
  createIncludeFilter({
    key: ID_KEY,
    label: 'ID',
  }),
  createTextFilter({
    key: NAME_KEY,
    label: 'Название',
  }),
];
