import dao from '@esentai/core/features/products/dao';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import { FETCH_PRODUCT } from './consts/types';

export const { findById: findProductById } = dao.sagas;

export function* fetchProduct(action) {
  const { payload: productId } = action;

  yield call(findProductById, productId);
}

export function* watchFetchProduct() {
  yield takeEvery(FETCH_PRODUCT, fetchProduct);
}

export default function*() {
  yield spawn(watchFetchProduct);
}
