export default {
  root: {
    '&:not(:last-child)': {
      marginRight: 21,
    },
  },

  value: {
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
  },

  name: {
    fontWeight: 400,
  },

  description: {
    maxWidth: 265,
  },

  ratio: {
    alignSelf: 'center',
  },
};
