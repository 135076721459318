import { LOCATION_ID_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import PropTypes from 'prop-types';
import React from 'react';

import LocationPageTagLink from '@/containers/LocationPageTagLink';

const Location = ({ payload: { [LOCATION_ID_KEY]: locationId } }) => (
  <LocationPageTagLink locationId={locationId} />
);

Location.propTypes = {
  payload: PropTypes.object.isRequired,
};

export default Location;
