import { getRentIds } from '@esentai/core/features/locations/selectors';
import { connect } from 'react-redux';

import Row from './component';

const mapStateToProps = (state, { item: locationId }) => ({
  rentIds: getRentIds(state, locationId),
});

export default connect(mapStateToProps)(Row);
