import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import { ROUTE_PATH } from '@/features/mallReceptionUsersPage/consts';
import {
  ID_KEY,
  LEVEL_KEY,
  PHONE_KEY,
} from '@/features/mallReceptionUsersPage/consts/keys';

const HeadRow = ({ SortControl, ...props }) => {
  const [sortFields, setSortFields] = useState({
    [ID_KEY]: [{}],
    [PHONE_KEY]: [{}],
    [LEVEL_KEY]: [{}],
  });

  const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  const currentUrlParams = new URLSearchParams(window.location.search);

  const changeFilter = fieldName => {
    Object.keys(initialSortFields).forEach(field => {
      initialSortFields[field] = [{}];
    });

    const changedField = fieldName[0];

    initialSortFields[changedField.field] = [changedField];

    if (changedField.direction === 'asc') {
      currentUrlParams.set('sort', `${changedField.field}`);
    } else {
      currentUrlParams.set('sort', `-${changedField.field}`);
    }

    props.history.push({
      pathname: ROUTE_PATH,
      search: `?${decodeURIComponent(currentUrlParams.toString())}`,
    });

    setSortFields(initialSortFields);
  };

  useEffect(() => {
    const field = new URLSearchParams(window.location.search).get('sort');

    if (field) {
      const fieldName = field.replace(/-/g, '');

      initialSortFields[fieldName] = [
        {
          field: fieldName,
          direction: field.indexOf('-') !== -1 ? 'desc' : 'asc',
        },
      ];
      setSortFields(initialSortFields);
    }
  }, []);

  return (
    <TableRow>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[ID_KEY]}
          field={ID_KEY}
          setSort={changeFilter}
        >
          ID Клиента
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>ФИО пользователя</CondenseTableCell>
      <CondenseTableCell>
        <SortControl
          sort={sortFields[PHONE_KEY]}
          field={PHONE_KEY}
          setSort={changeFilter}
        >
          Номер телефона
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell style={{ textAlign: 'left' }}>
        <SortControl
          sort={sortFields[LEVEL_KEY]}
          field={LEVEL_KEY}
          setSort={changeFilter}
        >
          Уровень лояльности
        </SortControl>
      </CondenseTableCell>
    </TableRow>
  );
};

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default HeadRow;
