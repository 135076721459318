import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import WhitelistGroupNameLazy from '@/containers/WhitelistGroupNameLazy';

const WhitelistGroupChip = ({ value, ...props }) => (
  <Chip
    {...props}
    id={value}
    label={<WhitelistGroupNameLazy groupId={value} showSubtitle />}
  />
);

WhitelistGroupChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default WhitelistGroupChip;
