import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Button from '@/components/Button';
import { cancelRequest } from '@/features/loyaltyHistoryPage/actions';
import { canCancelRequest } from '@/features/loyaltyHistoryPage/permissions';

const mapStateToProps = () => ({
  permission: canCancelRequest,
});

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(cancelRequest(itemId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(Button);
