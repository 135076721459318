import {
  getEnglishName,
  getImages,
  getKazakhName,
  getRussianName,
  isVisibleToCustomers,
} from '@esentai/core/features/productView/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose, T } from 'ramda';
import { connect } from 'react-redux';

import { updateView } from '@/features/productsViewPage/actions';

import EditButton from './component';

const mapStateToProps = (state, { itemId }) => ({
  itemId,
  russianName: getRussianName(state, itemId),
  englishName: getEnglishName(state, itemId),
  kazakhName: getKazakhName(state, itemId),
  images: getImages(state, itemId),
  isVisible: isVisibleToCustomers(state, itemId),
});

const mapDispatchToProps = dispatch => ({
  onClick: values => dispatch(updateView(values)),
  permission: T,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(EditButton);
