import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getLocationsError,
  getItemsIds: getLocationsIds,
  getItemsMeta: getLocationsMeta,
  getItemsTotalCount: getLocationsTotalCount,
  isLoadingItems: isLoadingLocations,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
