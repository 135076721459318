import { connect } from 'react-redux';

import { cancelInvite } from '@/features/personnelPage/actions';

import RevokeButton from './component';

const mapDispatchToProps = (dispatch, { personnelId }) => ({
  onClick: () => dispatch(cancelInvite(personnelId)),
});

export default connect(null, mapDispatchToProps)(RevokeButton);
