import { T } from 'ramda';
import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { createEditStoreLink } from '@/features/storeEditPage/serializer';

const mapStateToProps = (state, { storeId }) => ({
  editPageUrl: createEditStoreLink(storeId),
  permission: T,
});

export default connect(mapStateToProps)(EditButton);
