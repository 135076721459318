import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import CanView from '@/containers/CanView';
import Actions from '@/features/parkingTowerStopListPage/Page/Row/Actions';
import { canUpdateParkingStopList } from '@/features/parkingTowerStopListPage/permissions';
import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormat } from '@/utils/format';
import { userGender } from '@/utils/labels';

const Row = ({
  classes,
  selected,
  parkingTowerStopListId,
  isActive,
  SelectionToggle,
  firstName,
  lastName,
  recordCreated,
  phone,
  gender,
}) => (
  <TableRow>
    <TableCell padding="checkbox">
      <SelectionToggle item={parkingTowerStopListId} />
    </TableCell>
    <TableCell>
      <Link to={createUserPageUrl(parkingTowerStopListId)}>
        {parkingTowerStopListId}
      </Link>
    </TableCell>
    <TableCell>{lastName || '–'}</TableCell>
    <TableCell>{firstName || '–'}</TableCell>
    <TableCell className={classes.minWidth}>{phone || '–'}</TableCell>
    <TableCell>{userGender(gender) || '–'}</TableCell>
    <TableCell style={{ whiteSpace: 'nowrap' }}>
      {dateTimeFormat(recordCreated) || '–'} {isActive ? 'yes' : 'no'}
    </TableCell>
    <CanView permission={canUpdateParkingStopList}>
      <TableCell>
        <Actions
          disabled={selected}
          parkingTowerStopListId={parkingTowerStopListId}
        />
      </TableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  parkingTowerStopListId: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  state: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.string,
  recordCreated: PropTypes.instanceOf(Date),
  isPhoneVerified: PropTypes.bool,
  email: PropTypes.string,
  age: PropTypes.number,
  selected: PropTypes.bool.isRequired,
  phone: PropTypes.string,
  SelectionToggle: PropTypes.func.isRequired,
};
Row.defaultProps = {
  isActive: null,
  state: '',
  firstName: '',
  lastName: '',
  gender: '',
  recordCreated: null,
  isPhoneVerified: false,
  email: '',
  age: null,
  phone: '',
};

export default Row;
