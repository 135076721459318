export default {
  wrapper: {
    margin: [0, 'auto', 40],
  },
  tableBlock: {
    marginTop: 20,
  },
  buttonsWrapper: {
    position: 'relative',
    float: 'right',
  },
  excelButton: {
    marginTop: 15,
  },
};
