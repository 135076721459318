import { pipe } from 'ramda';

import { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';

import {
  AVERAGE_CHECK_KEY,
  AVERAGE_TIME_SPENT,
  BUYERS_COUNT_KEY,
  CONVERSION_RATE_KEY,
  MALL_LEADS_COUNT_KEY,
  MALL_VISITORS_COUNT_KEY,
  PURCHASES_COUNT_KEY,
  UNIQUE_LOCATION_LEADS_COUNT,
  USERS_COUNT_KEY,
  VISITORS_COUNT_KEY,
} from './consts/keys';
import dao from './ducks/dao';

export const {
  getItemById,
  getItemPropById,
  isLoadingItem,
  isItemLoaded,
} = dao.selectors;

export const getAverageCheckForItem = getItemPropById(AVERAGE_CHECK_KEY);

export const getAverageTimeSpentForItem = getItemPropById(AVERAGE_TIME_SPENT);

export const getBuyersCountForItem = getItemPropById(BUYERS_COUNT_KEY);

export const getConversionForItem = getItemPropById(CONVERSION_RATE_KEY);

export const getLeadsCountForItem = getItemPropById(
  UNIQUE_LOCATION_LEADS_COUNT,
);

export const getPurchasesCountForItem = getItemPropById(PURCHASES_COUNT_KEY);

export const getDate = pipe(getItemPropById(TIMEFRAME_KEY), x => new Date(x));

export const getUsersCountForItem = getItemPropById(USERS_COUNT_KEY);

export const getVisitorsCountForItem = getItemPropById(VISITORS_COUNT_KEY);

export const getMallVisitorsCountForItem = getItemPropById(
  MALL_VISITORS_COUNT_KEY,
);

export const getUserLeadCountForItem = pipe(
  getItemPropById(MALL_LEADS_COUNT_KEY),
  () => 0,
);
