import { TableRow } from '@material-ui/core';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import TableCellTypography from '@/components/TableCellTypography';

import CommonCells from '../CommonCells';
import { propTypes as CommonCellsPropTypes } from '../CommonCells/component';

const TotalRow = ({
  averageCheck,
  averageTimeSpent,
  buyersCount,
  conversion,
  leadsCount,
  purchasesCount,
  usersCount,
  visitorsCount,
  mallVisitorsCount,
  usersLeadCount,
}) => (
  <TableRow>
    <CondenseTableCell>
      <TableCellTypography>За весь период</TableCellTypography>
    </CondenseTableCell>
    <CommonCells
      averageCheck={averageCheck}
      averageTimeSpent={averageTimeSpent}
      buyersCount={buyersCount}
      conversion={conversion}
      leadsCount={leadsCount}
      purchasesCount={purchasesCount}
      usersCount={usersCount}
      visitorsCount={visitorsCount}
      mallVisitorsCount={mallVisitorsCount}
      usersLeadCount={usersLeadCount}
    />
  </TableRow>
);

TotalRow.propTypes = {
  ...CommonCellsPropTypes,
};

export default TotalRow;
