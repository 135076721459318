import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import Label from '@/features/campaignPage/Page/Label';

const Repetition = ({ activateOnce }) => (
  <FieldRow label="Повторение">
    <Label title="Количество активаций кампании">
      {activateOnce
        ? 'Кампания сработает для пользователя только 1 раз'
        : 'Кампания сработает для пользователя каждый раз, когда он выполнит условия триггера'}
    </Label>
  </FieldRow>
);

Repetition.propTypes = {
  activateOnce: PropTypes.bool.isRequired,
};

export default Repetition;
