import { path } from 'ramda';

import api from '@/api';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

import { FEATURE_NAME } from './consts';

export const getItemsRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, FEATURE_NAME, 'query']);

export const downloadXls = () => {
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.delete('limit');
  urlParams.delete('offset');
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'issued_amount');
  }

  api
    .doBlobGet(
      `/sales-turnover-xlsx-export/?include=sale_products,brands&${decodeURIComponent(
        urlParams.toString(),
      )}`,
      null,
      'v2',
    )
    .then(response => {
      saveAs(response.data, `Обороты по магазинам.xlsx`);
    });
};
