import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import Button from '@/components/Button';
import CanView from '@/containers/CanView';

const EditButton = ({ editPageUrl, permission, ...rest }) => (
  <CanView permission={permission}>
    <ActionButton
      text="Редактировать"
      button={Button}
      icon={EditIcon}
      href={editPageUrl}
      {...rest}
    />
  </CanView>
);

EditButton.propTypes = {
  editPageUrl: PropTypes.string.isRequired,
  permission: PropTypes.func.isRequired,
};

export default EditButton;
