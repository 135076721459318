import {
  getCampaignDateEnd,
  getCampaignDateStart,
  getCampaignDaysDuration,
  getCampaignLocationIds,
  getCampaignTitle,
} from '@esentai/core/features/campaigns/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Primary from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  dateStart: getCampaignDateStart(state, itemId),
  dateEnd: getCampaignDateEnd(state, itemId),
  duration: getCampaignDaysDuration(state, itemId),
  title: getCampaignTitle(state, itemId),
  locations: getCampaignLocationIds(state, itemId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Primary);
