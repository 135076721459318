import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import {
  getParkingTowerStopListError,
  getParkingTowerStopListIds,
  getParkingTowerStopListTotalCount,
  isLoadingParkingTowerStopList,
} from '../selectors';
import ParkingTowerStopListPage from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getParkingTowerStopListError(state),
  parkingTowerStopListIds: getParkingTowerStopListIds(state),
  isLoading: isLoadingParkingTowerStopList(state),
  totalCount: getParkingTowerStopListTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(ParkingTowerStopListPage);
