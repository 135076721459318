import { TableRow } from '@material-ui/core';
import _get from 'lodash.get';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = () => (
  <TableRow>
    <CondenseTableCell>User ID</CondenseTableCell>
    <CondenseTableCell>ФИО (1С Фитнес)</CondenseTableCell>
    {!_get(window, 'efsData.isMallValet') && (
      <CondenseTableCell>
        ФИО (которое указал пользователь при регистрации)
      </CondenseTableCell>
    )}
    <CondenseTableCell>Телефон (1С Фитнес)</CondenseTableCell>
    <CondenseTableCell>Тип Абонемента (1С Фитнес)</CondenseTableCell>
    <CondenseTableCell>
      Дата начала абонемента - Дата окончания абонемента
    </CondenseTableCell>
    <CondenseTableCell>Телефон (в приложении)</CondenseTableCell>
    <CondenseTableCell>Дата создания</CondenseTableCell>
    <CondenseTableCell>Кол-во бесплатных часов</CondenseTableCell>
    <CondenseTableCell>Зарегистрирован в приложении</CondenseTableCell>
    <CondenseTableCell>Информация на текущий день</CondenseTableCell>
    <CondenseTableCell>Активный баланс</CondenseTableCell>
    {_get(window, 'efsData.isMallValet') && <CondenseTableCell />}
  </TableRow>
);

export default HeadRow;
