import {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  INVITED_STATUS,
} from '@/features/personnel/consts/stateTypes';
import RadioGroupFilter, {
  createRadioFilterTemplate,
} from '@/filters/RadioGroup';

export const options = [
  {
    key: ACTIVE_STATUS,
    label: 'Активен',
  },
  {
    key: INACTIVE_STATUS,
    label: 'Деактивирован',
  },
  {
    key: INVITED_STATUS,
    label: 'Приглашён',
  },
];

export const createPersonnelActivityStateFilter = createRadioFilterTemplate({
  initialValue: ACTIVE_STATUS,
  options,
});

export default RadioGroupFilter;
