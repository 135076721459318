import {
  getBonusesRefunded,
  getBonusesSpent,
  getIncompleteCount,
  getStoreName,
  getTotalPaidAmount,
  getTotalToPayAmount,
} from '@esentai/core/features/reconciliationArchive/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { retryFetch } from '../../actions';
import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: storeId }) => ({
  incompleteAmount: getIncompleteCount(state, storeId),
  storeName: getStoreName(state, storeId),
  totalPaidAmount: getTotalPaidAmount(state, storeId),
  totalToPayAmount: getTotalToPayAmount(state, storeId),
  bonusesSpent: getBonusesSpent(state, storeId),
  bonusesRefunded: getBonusesRefunded(state, storeId),
  storeId,
});

const mapDispatchToProps = {
  fetchItems: retryFetch,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Row);
