import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  ARCHIVED_STATUS,
  DRAFT_STATUS,
  PAUSED_STATUS,
} from '@esentai/core/features/benefits/consts/statuses';
import PropTypes from 'prop-types';
import React from 'react';

import ActiveStatusActions from './ActiveStatusActions';
import ArchivedStatusActions from './ArchivedStatusActions';
import DraftStatusActions from './DraftStatusActions';
import PausedStatusActions from './PausedStatusActions';

const components = {
  [ACTIVE_STATUS]: ActiveStatusActions,
  [ARCHIVED_STATUS]: ArchivedStatusActions,
  [DRAFT_STATUS]: DraftStatusActions,
  [PAUSED_STATUS]: PausedStatusActions,
  [APPROVED_STATUS]: PausedStatusActions,
};

const Actions = ({ classes, itemId, status }) => {
  const Component = components[status];

  return (
    <div className={classes.root}>
      <Component itemId={itemId} />
    </div>
  );
};

Actions.propTypes = {
  itemId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default Actions;
