import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import EditAndroidVersionForm from './EditAndroidVersionForm';
import EditIOSVersionForm from './EditIOSVersionForm';

class EditAppVersionModal extends Component {
  static defaultProps = {
    isIOS: false,
    forcedUpdate: false,
  };

  render() {
    const {
      isOpen,
      onClose,
      currentAppVersion,
      isIOS,
      forcedUpdate,
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">
          Редактировать версию приложения
        </DialogTitle>

        <DialogContent>
          {isIOS ? (
            <EditIOSVersionForm
              currentAppVersion={currentAppVersion}
              forcedUpdate={forcedUpdate}
              onClose={onClose}
              onSubmit={this.props.onSubmit.bind(this)}
            />
          ) : (
            <EditAndroidVersionForm
              currentAppVersion={currentAppVersion}
              forcedUpdate={forcedUpdate}
              onClose={onClose}
              onSubmit={this.props.onSubmit.bind(this)}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

EditAppVersionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentAppVersion: PropTypes.string.isRequired,
  isIOS: PropTypes.bool,
  forcedUpdate: PropTypes.bool,
};

export default EditAppVersionModal;
