import PropTypes from 'prop-types';
import React from 'react';

import { dateTimeFormatNoSecs, formatMoney } from '@/utils/format';

import Field from './Field';

const ReceiptDetails = ({ kkmNumber, totalCost, purchaseDate, classes }) => (
  <div className={classes.facts}>
    <Field label="Номер ККМ" className={classes.fact}>
      {kkmNumber}
    </Field>
    <Field label="Общая сумма чека" className={classes.fact}>
      {formatMoney(totalCost)}
    </Field>
    <Field label="Дата и время" className={classes.fact}>
      {dateTimeFormatNoSecs(purchaseDate)}
    </Field>
  </div>
);

ReceiptDetails.propTypes = {
  kkmNumber: PropTypes.string.isRequired,
  totalCost: PropTypes.number.isRequired,
  purchaseDate: PropTypes.string.isRequired,
};

export default ReceiptDetails;
