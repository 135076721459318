import { scaleLinear, scaleTime } from 'd3-scale';
import { max, min } from 'date-fns';

export const getValues = (descriptor, data) => data;

export const getYMinValue = (descriptor, data) => {
  const values = getValues(descriptor, data);

  return Math.min(0, ...values);
};

export const getYMaxValue = (descriptor, data) => {
  const values = getValues(descriptor, data);

  return Math.max(...values);
};

export const getXMinValue = (descriptor, data) => {
  const values = getValues(descriptor, data);

  return min(values);
};

export const getXMaxValue = (descriptor, data) => {
  const values = getValues(descriptor, data);

  return max(values);
};

export const getYScale = (descriptor, data) => {
  const minValue = getYMinValue(descriptor, data);
  const maxValue = getYMaxValue(descriptor, data);

  return scaleLinear().domain([minValue, maxValue]);
};

export const getXScale = (descriptor, data) => {
  const minValue = getXMinValue(descriptor, data);
  const maxValue = getXMaxValue(descriptor, data);

  return scaleTime().domain([minValue, maxValue]);
};
