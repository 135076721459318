import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import {
  getSalesTurnoversError,
  getSalesTurnoversIds,
  getSalesTurnoversTotalCount,
  isLoadingSalesTurnovers,
} from '../selectors';
import SalesTurnovers from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getSalesTurnoversError(state),
  salesTurnoversIds: getSalesTurnoversIds(state),
  isLoading: isLoadingSalesTurnovers(state),
  totalCount: getSalesTurnoversTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(SalesTurnovers);
