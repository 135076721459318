import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import { downloadXls } from '@/features/loyaltyHistoryPage/utils';

import Table from './Table';

class LoyaltyHistory extends Component {
  static propTypes = {
    error: PropTypes.any,
    historyIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, historyIds, isLoading, totalCount, classes } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>
            Исторические данные по присвоенным бонусам/уровням
          </PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.excelButton}
              onClick={downloadXls}
            >
              Вывести в Excel
            </Button>
            <Table
              error={error}
              items={historyIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default LoyaltyHistory;
