import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import { REAL_FULL_NAME_KEY } from '@/features/ipnReportsPage/consts/keys';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import EditFullName from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: () => ({
    [REAL_FULL_NAME_KEY]: '',
  }),

  validationSchema: object().shape({
    [REAL_FULL_NAME_KEY]: string().required(),
  }),

  handleSubmit(values, { props }) {
    props.onSubmit(values);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(EditFullName);
