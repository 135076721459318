import {
  getCampaignFilters,
  getCampaignSegmentSize,
  getCampaignSegmentType,
} from '@esentai/core/features/campaigns/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Segmentation from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  filters: getCampaignFilters(state, itemId),
  segmentSize: getCampaignSegmentSize(state, itemId),
  segmentType: getCampaignSegmentType(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Segmentation);
