import { grey } from '@material-ui/core/colors';

export default ({ spacing }) => ({
  row: {
    borderTop: [1, 'solid', grey[300]],
    paddingTop: spacing.unit * 2,
    marginTop: spacing.unit * 2,
    minHeight: spacing.unit * 17,
  },
});
