import React, { Fragment } from 'react';

import Banner from './Banner';
import Layout from './Layout';
import Properties from './Properties';

const CalendarEventContent = props => (
  <Fragment>
    <Properties {...props} />
    <Banner {...props} />
    <Layout {...props} />
  </Fragment>
);

export default CalendarEventContent;
