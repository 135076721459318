import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import CampaignTitleLazy from '@/containers/CampaignTitleLazy';

const CampaignChip = ({ value, ...props }) => (
  <Chip {...props} id={value} label={<CampaignTitleLazy id={value} />} />
);

CampaignChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default CampaignChip;
