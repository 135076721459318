import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import { isMallStaff } from '@/features/currentUser/selectors';
import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormat, formatMoney, formatNumber } from '@/utils/format';
import { purchaseStatus, userStatus } from '@/utils/labels';

import ReceiptModal from '../ReceiptModal';

const Row = ({
  totalCost,
  purchaseId,
  cashMachine,
  uuid,
  classes,
  customer,
  store,
  activeBonuses,
  pendingBonuses,
  bonusPercent,
  bonusCost,
  moneyCost,
  status,
  refundBonusCost,
  refundMoneyCost,
  purchaseDate,
  userLevel,
  receipt,
}) => {
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);

  const handleReceiptModal = () => {
    setIsReceiptDialogOpen(!isReceiptDialogOpen);
  };

  return (
    <>
      <TableRow>
        <TableCell>{purchaseId}</TableCell>
        <CanView permission={isMallStaff}>
          <TableCell>
            <Link to={createUserPageUrl(customer)}>{customer}</Link>
          </TableCell>
        </CanView>
        <TableCell>{userStatus(userLevel)}</TableCell>
        <TableCell>{cashMachine}</TableCell>
        <TableCell>
          <div className={classes.link} onClick={handleReceiptModal}>
            {uuid}
          </div>
        </TableCell>
        <TableCell>{store}</TableCell>
        <TableCell>{dateTimeFormat(purchaseDate)}</TableCell>
        <TableCell align="right">{formatMoney(totalCost)}</TableCell>
        <TableCell>{formatNumber(bonusPercent)}%</TableCell>
        <TableCell align="right">{formatNumber(pendingBonuses)}</TableCell>
        <TableCell align="right">{formatNumber(activeBonuses)}</TableCell>
        <TableCell>{purchaseStatus(status)}</TableCell>
        <TableCell align="right">{formatNumber(bonusCost)}</TableCell>
        <TableCell align="right">{formatMoney(moneyCost)}</TableCell>
        <TableCell align="right">{formatNumber(refundBonusCost)}</TableCell>
        <TableCell align="right">{formatMoney(refundMoneyCost)}</TableCell>
      </TableRow>
      {isReceiptDialogOpen && (
        <ReceiptModal
          uuid={uuid}
          id={purchaseId}
          receipt={receipt}
          isOpen={isReceiptDialogOpen}
          onClose={handleReceiptModal}
        />
      )}
    </>
  );
};

Row.propTypes = {
  purchaseId: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  cashMachine: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  customer: PropTypes.number.isRequired,
  store: PropTypes.string.isRequired,
  activeBonuses: PropTypes.number.isRequired,
  pendingBonuses: PropTypes.number.isRequired,
  bonusPercent: PropTypes.number.isRequired,
  bonusCost: PropTypes.number.isRequired,
  moneyCost: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  refundBonusCost: PropTypes.number.isRequired,
  refundMoneyCost: PropTypes.number.isRequired,
  purchaseDate: PropTypes.instanceOf(Date).isRequired,
  userLevel: PropTypes.number.isRequired,
  receipt: PropTypes.number.isRequired,
};

Row.defaultProps = {
  categoryKeys: [],
  brandsIds: [],
};

export default Row;
