import { annotations } from '@esentai/core/features/benefitItems/serializer';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer, getLinkCreator } from '@/serializers';

import { ROUTE_PATH } from './consts';

const serializer = createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: [{}],
  }),
  include: string({
    // defaultValue: 'location',
  }),
});

export const createLink = getLinkCreator(ROUTE_PATH, serializer);

export default serializer;
