import {
  APPROVED,
  CANCELED,
  LEVEL_CHANGED,
  RECEIPT_BONUSES,
  RECEIPT_BONUSES_CANCELLATION,
  REJECTED,
  REWARD_BONUSES,
  WAITING,
} from '@esentai/core/features/loyalties/consts/statuses';
import {
  BONUS_STATUS,
  MIXED_STATUS,
  MONEY_STATUS,
  PROCESSING_STATUS,
} from '@esentai/core/features/purchases/consts/statuses';
import {
  ACCESSORIES,
  ANCHORS,
  CHILDREN,
  ELECTRONICS,
  ENTERTAINMENT,
  FINANCIAL_SERVICES,
  FOOD,
  HEALTH_AND_BEAUTY,
  HOME,
  JEWELRY_AND_WATCHES,
  LUXURY,
  MEN,
  PREMIUM,
  RESTAURANTS_AND_CAFE,
  SERVICES,
  SHOES,
  WOMEN,
} from '@esentai/core/features/stores/consts/categories';
import {
  BLACK_STATUS,
  JET_BLACK_STATUS,
  ORANGE_STATUS,
  SILVER_STATUS,
  WHITE_STATUS,
} from '@esentai/core/features/users/consts/statuses';

import {
  BITRIX_ROLE,
  EFS_SALES_ROLE,
  GUEST_EFS_ROLE,
  GUEST_PARKING_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_ADMINISTRATOR_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_SELLER_ROLE,
  MALL_VALET_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_BENEFIT_MANAGER_ROLE,
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
} from '@/features/personnel/consts/roles';

import labelFormatter from './label-formatter';

export const activityStatus = active => (active ? 'Активен' : 'Деактивирован');

export const userGender = gender => {
  const mapping = {
    male: 'Мужской',
    female: 'Женский',
  };

  return mapping[gender] || '–';
};

export const availability = isPublic => (isPublic ? 'Для всех' : 'Приватная');

export const userRole = role => {
  const mapping = {
    [MALL_COMMISSAR_ROLE]: 'Комплаенс Менеджер',
    [MALL_ADMINISTRATOR_ROLE]: 'Администратор молла',
    [MALL_MANAGER_ROLE]: 'Менеджер молла',
    [MALL_SELLER_ROLE]: 'Бенефит-менеджер молла',
    [STORE_ADMINISTRATOR_ROLE]: 'Администратор магазина',
    [STORE_MANAGER_ROLE]: 'Менеджер магазина',
    [STORE_SELLER_ROLE]: 'Продавец магазина',
    [MALL_ACCOUNTANT_ROLE]: 'Бухгалтер',
    [MALL_VALET_ROLE]: 'Оператор паркинга',
    [GUEST_PARKING_ROLE]: 'Guest Parking',
    [BITRIX_ROLE]: 'Битрикс',
    [STORE_BENEFIT_MANAGER_ROLE]: 'Бенефит менеджер. Магазин',
    [GUEST_EFS_ROLE]: 'Guest EFS',
    [EFS_SALES_ROLE]: 'EFS Sales',
    [MALL_RECEPTION_ROLE]: 'Reception',
    [MANAGING_TEAM_MEMBER_ROLE]: 'Managing Team Member',
  };

  return mapping[role];
};

export const userStatus = status => {
  const mapping = {
    [WHITE_STATUS]: 'White',
    [SILVER_STATUS]: 'Silver',
    [BLACK_STATUS]: 'Black',
    [ORANGE_STATUS]: 'Orange',
    [JET_BLACK_STATUS]: 'JetBlack',
  };

  return mapping[status];
};

export const purchaseStatus = status => {
  const mapping = {
    [PROCESSING_STATUS]: 'В обработке',
    [MONEY_STATUS]: 'Оплата деньгами',
    [BONUS_STATUS]: 'Оплата бонусами',
    [MIXED_STATUS]: 'Сложная покупка',
  };

  return mapping[status];
};

export const activityType = status => {
  const mapping = {
    [REWARD_BONUSES]: 'Присвоение бонусов',
    [LEVEL_CHANGED]: 'Присвоение уровня лояльности',
    [RECEIPT_BONUSES]: 'Присвоение бонусов по чеку',
    [RECEIPT_BONUSES_CANCELLATION]: 'Отмена бонусов по чеку',
  };

  return mapping[status];
};

export const loyaltyAssignStatuses = status => {
  const mapping = {
    [APPROVED]: 'Одобрено',
    [REJECTED]: 'Отказано',
    [WAITING]: 'В ожидании',
    [CANCELED]: 'Отменено',
  };

  return mapping[status];
};

export const beacons = count =>
  labelFormatter(count, {
    one: 'маяк',
    few: 'маяка',
    many: 'маяков',
  });

export const bonuses = count =>
  labelFormatter(count, {
    one: 'бонус',
    few: 'бонуса',
    many: 'бонусов',
  });

export const users = count =>
  labelFormatter(count, {
    one: 'пользователь',
    few: 'пользователя',
    many: 'пользователей',
  });

export const days = count =>
  labelFormatter(count, {
    one: 'день',
    few: 'дня',
    many: 'дней',
  });

export const week = weekOutput => `${weekOutput} неделя`;

export const storeCategory = cat => {
  const mapping = {
    [LUXURY]: 'Люкс',
    [PREMIUM]: 'Премиум',
    [ANCHORS]: 'Якори',
    [RESTAURANTS_AND_CAFE]: 'Рестораны и кафе',
    [ACCESSORIES]: 'Аксессуары и сувениры',
    [CHILDREN]: 'Товары для детей',
    [ELECTRONICS]: 'Электроника',
    [ENTERTAINMENT]: 'Развлекательная программа',
    [FINANCIAL_SERVICES]: 'Финансовые услуги',
    [FOOD]: 'Фуд корт',
    [HEALTH_AND_BEAUTY]: 'Health & Beauty',
    [HOME]: 'Товары для дома',
    [JEWELRY_AND_WATCHES]: 'Ювелирные изделия и часы',
    [MEN]: 'Для мужчин',
    [SERVICES]: 'Сервисы',
    [SHOES]: 'Обувь',
    [WOMEN]: 'Для женщин',
  };

  return mapping[cat];
};
