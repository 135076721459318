import { Checkbox, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import { ACCESS_TO_STATISTIC_KEY } from '@/features/invites/consts/keys';

class StatisticAccessField extends Component {
  render() {
    const { values, classes } = this.props;

    return (
      <FieldRow
        label="Доступ к статистике"
        subLabel="Включить доступ к статистике"
        className={classes.row}
      >
        <Grid container alignItems="center">
          <Grid item className={classes.radioBox}>
            <Checkbox
              id={ACCESS_TO_STATISTIC_KEY}
              name={ACCESS_TO_STATISTIC_KEY}
              onChange={this.onChange}
              checked={values[ACCESS_TO_STATISTIC_KEY]}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5">
              Предоставить пользователю доступ к статистике
            </Typography>
          </Grid>
        </Grid>
      </FieldRow>
    );
  }

  onChange = e =>
    this.props.setFieldValue(ACCESS_TO_STATISTIC_KEY, e.target.checked);
}

StatisticAccessField.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default StatisticAccessField;
