import {
  getBonusesRefunded,
  getBonusesSpent,
  getEndDate,
  getRegistryUrl,
  getStartDate,
  getTotalToPayAmount,
  getUserId,
  getUserName,
} from '@esentai/core/features/merchantsReconciliationHistory/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';
import styles from './styles';

const mapStateToProps = (state, { item: merchantId }) => ({
  totalToPay: getTotalToPayAmount(state, merchantId),
  bonusesSpent: getBonusesSpent(state, merchantId),
  bonusesRefunded: getBonusesRefunded(state, merchantId),
  startDate: getStartDate(state, merchantId),
  endDate: getEndDate(state, merchantId),
  userId: getUserId(state, merchantId),
  userName: getUserName(state, merchantId),
  registryUrl: getRegistryUrl(state, merchantId),
  merchantId,
});

export default compose(connect(mapStateToProps), withStyles(styles))(Row);
