import { concat, map } from 'ramda';

const actionNames = {
  mount: 'MOUNT',
  sync: 'SYNC',
  unmount: 'UNMOUNT',

  updateFilters: 'UPDATE_FILTERS',
  removeFilters: 'REMOVE_FILTERS',
  updateLimit: 'UPDATE_LIMIT',
  updatePage: 'UPDATE_PAGE',
  updateSort: 'UPDATE_SORT',
};

export default namespace => map(concat(`${namespace}/`), actionNames);
