import { getFullNameForUser } from '@esentai/core/features/users/selectors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const UserName = ({ name }) => name;

UserName.propTypes = {
  name: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { userId }) => ({
  name: getFullNameForUser(state, userId),
});

export default connect(mapStateToProps)(UserName);
