import { getCampaignStatus } from '@esentai/core/features/campaigns/selectors';
import { compose } from 'ramda';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { getCampaignId } from '@/features/campaignPage/selectors';

import Status from './component';
import styles from './styles';

const mapStateToProps = state => {
  const itemId = getCampaignId(state);

  return {
    status: getCampaignStatus(state, itemId),
  };
};

export default compose(connect(mapStateToProps), injectSheet(styles))(Status);
