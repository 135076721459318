import { isVisibleToCustomers } from '@esentai/core/features/productView/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose, T } from 'ramda';
import { connect } from 'react-redux';

import { deleteView } from '@/features/productsViewPage/actions';

import DeleteButton from './component';

const mapStateToProps = (state, { itemId }) => ({
  isVisible: isVisibleToCustomers(state, itemId),
});

const mapDispatchToProps = (dispatch, { itemId }) => ({
  onClick: () => dispatch(deleteView(itemId)),
  permission: T,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(DeleteButton);
