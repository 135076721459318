import createAPISerializer from '@esentai/core/serializers/api';
import { date, number, string } from '@esentai/core/serializers/params';

import {
  CUSTOMER_AGE_KEY,
  CUSTOMER_AVERAGE_CHECK_KEY,
  CUSTOMER_BIRTH_DATE_KEY,
  CUSTOMER_GENDER_KEY,
  CUSTOMER_STATUS_KEY,
  TIME_SERIES_KEY,
} from './consts/dimensions';
import { defaultDateRange } from './consts/filters';

export const annotations = {
  [CUSTOMER_AGE_KEY]: number(),
  [CUSTOMER_AVERAGE_CHECK_KEY]: number(),
  [CUSTOMER_BIRTH_DATE_KEY]: date(),
  [CUSTOMER_GENDER_KEY]: string(),
  [CUSTOMER_STATUS_KEY]: string(),
  [TIME_SERIES_KEY]: date({
    defaultValue: defaultDateRange,
  }),
};

export default createAPISerializer(annotations);
