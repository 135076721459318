import { Button, Typography } from '@material-ui/core';
import * as React from 'react';

import api from '../../../../api';
import NoMatchMessage from '../../../../components/CommonTable/NoMatchMessage';
import Link from '../../../../components/Link';
import StoreNameLazy from '../../../../containers/StoreNameLazy';
import { createRedirectStorePageUrl } from '../../../storePage/utils';
import { GET_URL, PATCH_URL } from '../../consts';
import Field from '../Field';
import RepayReconciliationModal from './RepayReconciliationModal/RepayReconciliationModal';

interface RowData {
  total_to_pay: number;
  id: number;
  total_paid: number;
  company: string;
  saldo: number;
  stores: number[]; // TODO: Define a generic for this
}

export interface DescriptionProps {
  detailId: number;
  classes: any;
  getMerchant: (merchant) => void;
  fetchItem: () => void;
  children: (data: any) => JSX.Element;
}

export interface DescriptionState {
  data: RowData[];
  isFinishDialogOpen: boolean;
}

const styles = {
  field: {
    marginRight: 15,
  },
};

const payload = {
  merchant_reconciliation_payment: {
    merchant: 0,
    payment_file_url: '',
    paid: 0,
  },
};

export class Description extends React.Component<
  DescriptionProps,
  DescriptionState
> {
  constructor(props: DescriptionProps) {
    super(props);

    this.state = {
      data: [],
      isFinishDialogOpen: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.loadData();
  }

  setFinishModal = () => {
    this.setState({ isFinishDialogOpen: !this.state.isFinishDialogOpen });
  };

  fetchDetails = async () => {
    const { fetchItem } = this.props;

    await this.loadData();
    fetchItem();
  };

  public render(): JSX.Element {
    const { data, isFinishDialogOpen } = this.state;
    const { fetchItem, detailId } = this.props;

    return (
      <>
        {data[0] && (
          <>
            <div
              style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}
            >
              <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                Арендатор:
              </Typography>
              <Typography variant="subtitle1">{data[0].company}</Typography>
            </div>
            <div
              style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}
            >
              <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                Магазины:
              </Typography>
              {data[0].stores &&
                data[0].stores.map(store => (
                  <div style={{ textDecoration: 'underline', marginLeft: 15 }}>
                    <Link to={createRedirectStorePageUrl(store)}>
                      <StoreNameLazy storeId={store} />
                    </Link>
                  </div>
                ))}
            </div>
            <div
              style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}
            >
              <div>
                <Field label="Сальдо">{data[0].saldo}</Field>
              </div>
              <div style={{ marginLeft: 15 }}>
                <Field label="Оплаченная сумма">{data[0].total_paid}</Field>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={this.setFinishModal}
                size={'large'}
                style={{ alignSelf: 'flex-end', marginLeft: 20 }}
              >
                Добавить оплату
              </Button>
            </div>

            {isFinishDialogOpen && (
              <RepayReconciliationModal
                isOpen={isFinishDialogOpen}
                onClose={this.setFinishModal}
                fetchItems={this.fetchDetails}
                url={PATCH_URL}
                getUrl={GET_URL}
                payload={payload}
                merchantId={detailId}
              />
            )}
          </>
        )}
      </>
    );
  }

  public renderBody(data: any[]): JSX.Element {
    const { children } = this.props;

    if (data.length > 0) {
      return children(data);
    }

    return <NoMatchMessage columnsCount={100} />;
  }

  private async loadData(): Promise<void> {
    const { detailId, getMerchant } = this.props;
    const getUrl = `/merchant-reconciliation-archive/${detailId}`;

    const data = await api.doGet(getUrl);

    if (
      data.merchant_reconciliations_archive &&
      data.merchant_reconciliations_archive.length > 0
    ) {
      getMerchant(data);
      this.setState({
        data: data.merchant_reconciliations_archive,
      });
    }
  }
}
