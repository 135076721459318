import { NAME_RUSSIAN_KEY } from '@esentai/core/features/locations/consts/keys';

import { createTextFilter } from '@/filters/SearchInput';

export const filterTypes = [
  createTextFilter({
    key: NAME_RUSSIAN_KEY,
    label: 'Название (на русском)',
  }),
];
