import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { canUpdateMerchant } from '@/features/merchantsPage/permissions';

import EditButton from './EditButton';
import HistoryButton from './HistoryButton';

const Actions = ({ classes, merchantId }) => (
  <Grid className={classes.actions} container wrap="nowrap">
    <EditButton merchantId={merchantId} />
    <HistoryButton merchantId={merchantId} permission={canUpdateMerchant} />
  </Grid>
);

Actions.propTypes = {
  merchantId: PropTypes.number.isRequired,
};

export default Actions;
