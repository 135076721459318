import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { call, select, takeEvery } from 'redux-saga/effects';

import {
  resetPassword as resetPersonnelPassword,
  updatePersonnel as updatePersonnelSaga,
} from '@/features/personnel/sagas';

import { getEditableAttributes, getPersonnelId } from './selectors';
import { RESET_PASSWORD, UPDATE_PERSONNEL } from './types';

export function* resetPassword() {
  const personnelId = yield select(getPersonnelId);

  yield call(resetPersonnelPassword, { payload: personnelId });
}

export function* updatePersonnel(action) {
  const { payload: attributes } = action;
  const personnelId = yield select(getPersonnelId);
  const editableAttributes = yield select(getEditableAttributes, attributes);

  yield call(updatePersonnelSaga, personnelId, editableAttributes);
}

export default function*() {
  yield takeEvery(RESET_PASSWORD, createTaskSaga(resetPassword));
  yield takeEvery(UPDATE_PERSONNEL, createTaskSaga(updatePersonnel));
}
