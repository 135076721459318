import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import * as productCategoriesSagas from '@esentai/core/features/productCategories/sagas';
import { VISIBILITY_KEY } from '@esentai/core/features/products/consts/keys';
import { remove, updateProduct } from '@esentai/core/features/products/sagas';
import { getVisibilityForProduct } from '@esentai/core/features/products/selectors';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { DELETE_PRODUCT, RETRY_FETCH, TOGGLE_VISIBILITY } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export function* deleteProduct(action) {
  const { payload: productId } = action;

  yield call(remove, productId);
  yield call(fetchItems);
}

export function* toggleVisibility(action) {
  const { payload: productId } = action;
  const visible = yield select(getVisibilityForProduct, productId);

  yield call(updateProduct, productId, {
    [VISIBILITY_KEY]: !visible,
  });
}

export function* watchUpdateVisibility() {
  yield takeEvery(TOGGLE_VISIBILITY, createTaskSaga(toggleVisibility));
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield spawn(productCategoriesSagas.default);
  yield takeEvery(DELETE_PRODUCT, createTaskSaga(deleteProduct));
  yield spawn(watchUpdateVisibility);
}
