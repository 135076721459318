import { withStyles, Zoom } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

const Success = ({
  classes,
  children,
  transitionComponent: Transition,
  transitionProps,
}) => (
  <Transition {...transitionProps}>
    <div className={classes.wrap}>
      <CheckCircleIcon color="primary" classes={classes} />
      {children}
    </div>
  </Transition>
);

Success.propTypes = {
  transitionComponent: PropTypes.func,
  transitionProps: PropTypes.object,
};

Success.defaultProps = {
  transitionComponent: Zoom,
  transitionProps: {
    in: true,
  },
};

export default withStyles(styles)(Success);
