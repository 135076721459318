import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import { NAME_KEY } from '@/features/personnel/consts/keys';

const UserNameField = ({ Field }) => (
  <FieldRow
    label="Имя пользователя"
    subLabel="Фамилия, Имя и Отчество пользователя, отображаемые в системе"
    sudDesc="Фамилия, имя и отчетство, которыми будет обладать текущий пользователь."
  >
    <Field
      Target={TextField}
      id={NAME_KEY}
      name={NAME_KEY}
      fullWidth
      label="Фамилия, имя и отчество пользователя"
      margin="normal"
    />
  </FieldRow>
);

UserNameField.propTypes = {
  Field: PropTypes.func.isRequired,
};

export default UserNameField;
