export default {
  root: {
    display: 'inline-block',
    marginRight: 12,
  },

  switch: {
    border: [1, 'solid', '#DEDEDE'],
    color: '#3C474C',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 8,
  },

  button: {
    width: 34,
    height: 34,
  },

  count: {
    color: '#BDBDBD',
    fontSize: 12,
    textAlign: 'center',
  },
};
