import { Button, IconButton, TableCell, TableRow } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import ReceiptModal from '@/features/purchaseHistoryPage/Page/ReceiptModal';
import RefundPurchase from '@/features/purchaseWithoutScanPage/Page/Modal/RefundPurchase';
import { createUserPageUrl } from '@/features/userPage/utils';
// import { createUserPageUrl } from '@/features/userPage/utils';
import { formatMoney, formatNumber } from '@/utils/format';

const loyaltyLevels = {
  1: 'Orange',
  2: 'Black',
  3: 'JetBlack',
};

const Row = props => {
  const { classes, item } = props;

  const {
    id,
    customer,
    user_level,
    cash_machine,
    fiscal_id,
    store_name,
    record_created,
    refund_datetime,
    receipt,
    timestamp,
    total_cost,
    bonus_percent,
    active_bonuses,
    pending_bonuses,
    is_refund_available,
    staff_full_name,
    files,
  } = item;

  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);
  const [isRefundPurchaseModalOpen, openRefundPurchaseModal] = useState(false);

  const handleReceiptModal = () => {
    setIsReceiptDialogOpen(!isReceiptDialogOpen);
  };

  // const differenceBetweenToday = date => {
  //   const today = moment().utc();
  //   const currentDate = moment(`${date}Z`);
  //   const difference = today.diff(currentDate, 'days');
  //
  //   return difference;
  // };

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>
        <Link to={createUserPageUrl(customer)}>{customer}</Link>
      </TableCell>
      <TableCell>{loyaltyLevels[user_level]}</TableCell>
      <TableCell>{cash_machine}</TableCell>
      <TableCell>
        <div className={classes.link} onClick={handleReceiptModal}>
          {fiscal_id}
        </div>
      </TableCell>
      <TableCell>{store_name}</TableCell>
      <TableCell>
        {timestamp
          ? format(new Date(`${timestamp}Z`), 'dd-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>{formatMoney(total_cost)}</TableCell>
      <TableCell>{bonus_percent}%</TableCell>
      <TableCell>{formatNumber(pending_bonuses)}</TableCell>
      <TableCell>{formatNumber(active_bonuses)}</TableCell>
      <TableCell>
        {record_created
          ? format(new Date(`${record_created}Z`), 'dd-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>{staff_full_name}</TableCell>
      <TableCell>
        {refund_datetime
          ? format(new Date(`${refund_datetime}Z`), 'dd-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex' }}>
          {files && files.length
            ? files.map(
                file =>
                  file && (
                    <a href={file} target="_blank" rel="noreferrer" download>
                      <IconButton size="medium">
                        <AttachFile />
                      </IconButton>
                    </a>
                  ),
              )
            : '-'}
        </div>
      </TableCell>
      <TableCell>
        <Button
          disabled={!is_refund_available}
          onClick={() => openRefundPurchaseModal(true)}
        >
          Возврат
        </Button>
      </TableCell>
      {isReceiptDialogOpen && (
        <ReceiptModal
          uuid={fiscal_id}
          id={id}
          receipt={receipt}
          isOpen={isReceiptDialogOpen}
          onClose={handleReceiptModal}
        />
      )}

      {isRefundPurchaseModalOpen && (
        <RefundPurchase
          open={isRefundPurchaseModalOpen}
          selectedItem={item}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={() => openRefundPurchaseModal(false)}
          confirmLabel={'Возврат'}
          cancelLabel={'Отменить'}
          style={{ display: 'flex' }}
        />
      )}
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
