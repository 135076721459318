import {
  BITRIX_ROLE,
  EFS_SALES_ROLE,
  GUEST_EFS_ROLE,
  GUEST_PARKING_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_VALET_ROLE,
  STORE_ADMINISTRATOR_ROLE,
} from '@esentai/core/features/users/consts/roles';

import { ROUTE_PATH as BENEFIT_GIVE_OUT_PATH } from '@/features/benefitGiveOutPage/consts';
import { ROUTE_PATH as BITRIX_PATH } from '@/features/bitrixPage/consts';
import { getRole } from '@/features/currentUser/selectors';
import { ROUTE_PATH as EFS_SALES_PATH } from '@/features/efsSalesPage/consts';
import { ROUTE_PATH as GUEST_EFS_PATH } from '@/features/guestEfsPage/consts';
import { ROUTE_PATH as GUEST_PARKING_PATH } from '@/features/guestParkingPage/consts';
import { ROUTE_PATH as MALL_RECEPTION_PATH } from '@/features/mallReceptionPage/consts';
// import { ROUTE_PATH as MALL_VALET_PATH } from '@/features/mallValetPage/consts';
import { ROUTE_PATH as PARKING_PATH } from '@/features/parkingPage/consts';
import { ROUTE_PATH as PERSONNEL_PATH } from '@/features/personnelPage/consts';
import { ROUTE_PATH as RECONCILIATIONS_CP } from '@/features/reconciliationPage/consts';
import { getEntityPageUrl } from '@/features/statisticsPage/utils';

export const getLandingUrl = state => {
  const role = getRole(state);

  if (!role) {
    return null;
  }

  if (role === STORE_ADMINISTRATOR_ROLE) {
    return PERSONNEL_PATH;
  }

  if (role === MALL_ACCOUNTANT_ROLE) {
    return RECONCILIATIONS_CP;
  }

  if (role === MALL_VALET_ROLE) {
    return PARKING_PATH;
  }

  if (role === GUEST_PARKING_ROLE) {
    return GUEST_PARKING_PATH;
  }

  if (role === BITRIX_ROLE) {
    return BITRIX_PATH;
  }

  if (role === GUEST_EFS_ROLE) {
    return GUEST_EFS_PATH;
  }

  if (role === EFS_SALES_ROLE) {
    return EFS_SALES_PATH;
  }

  if (role === MALL_RECEPTION_ROLE) {
    return MALL_RECEPTION_PATH;
  }

  return ['store_seller', 'mall_seller', 'mall_commissar'].includes(role)
    ? BENEFIT_GIVE_OUT_PATH
    : getEntityPageUrl('users');
};
