import { defaultProps } from 'recompose';

import { createPageFilterTemplate } from '@/structs/pageFilter';
import { createAnyOfAdapter } from '@/structs/pageFilterAdapter';

import CheckboxGroupFilter from './component';
import { createFormatter } from './utils';

export const createCheckboxFilterTemplate = ({ options, ...rest }) =>
  createPageFilterTemplate({
    FilterComponent: defaultProps({ options })(CheckboxGroupFilter),
    FormatComponent: createFormatter(options),
    adapter: createAnyOfAdapter([]),
    quicklyAccessibleOptions: options,
    ...rest,
  });

export default CheckboxGroupFilter;
