import React, { Component } from 'react';

import DefaultMap from '@/components/Map';

import { Provider } from './context';
import InfoBlock from './InfoBlock';

class Map extends Component {
  state = {
    draggingBeaconId: null,
    offsetX: null,
    offsetY: null,
    tool: 'select',
  };

  handleKeyDown = event => {
    if (event.altKey && !this.isMoveToolSelected()) {
      this.chooseMoveTool();
    }
  };

  handleKeyUp = () => {
    if (this.isMoveToolSelected()) {
      this.chooseSelectTool();
    }
  };

  handleMouseDown = beaconId => {
    if (this.isMoveToolSelected()) {
      this.startBeaconDragging(beaconId);
    }
  };

  handleOffsetChange = ({ relativeDx, relativeDy }) => {
    if (this.isMoveToolSelected()) {
      this.updateBeaconOffset(relativeDx, relativeDy);
    }
  };

  handleMouseUp = () => {
    if (this.isMoveToolSelected()) {
      this.stopBeaconDragging();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  isMoveToolSelected() {
    const { tool } = this.state;

    return tool === 'move';
  }

  chooseMoveTool() {
    this.setState({ tool: 'move' });
  }

  chooseSelectTool() {
    this.setState({
      draggingBeaconId: null,
      offsetX: null,
      offsetY: null,
      tool: 'select',
    });
  }

  startBeaconDragging(beaconId) {
    this.setState({ draggingBeaconId: beaconId });

    document.addEventListener('mouseup', this.handleMouseUp);
  }

  updateBeaconOffset(offsetX, offsetY) {
    this.setState({ offsetX, offsetY });
  }

  stopBeaconDragging() {
    this.setState({ draggingBeaconId: null, offsetX: null, offsetY: null });

    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  render() {
    const moveToolSelected = this.isMoveToolSelected();

    return (
      <Provider value={{ ...this.state, onMouseDown: this.handleMouseDown }}>
        <InfoBlock />
        <DefaultMap
          lock={moveToolSelected}
          onOffsetChange={this.handleOffsetChange}
          {...this.props}
        />
      </Provider>
    );
  }
}

export default Map;
