import {
  FILTER_KEY,
  STATUS_KEY,
} from '@esentai/core/features/benefits/consts/keys';
import { ACTIVE_STATUS } from '@esentai/core/features/benefits/consts/statuses';
import { query } from '@esentai/core/query-dsl';
import { object } from 'yup';

import { SHOULD_SUBMIT_AFTER_SAVE_KEY } from '@/features/benefitCreatePage/consts/keys';

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({ filters = query(), status }) => ({
    [FILTER_KEY]: filters,
    [STATUS_KEY]: status || ACTIVE_STATUS,
    [SHOULD_SUBMIT_AFTER_SAVE_KEY]: false,
  }),

  validationSchema: object().shape({}),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
