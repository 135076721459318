import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { createHistoryData } from '@esentai/core/features/history_data/sagas';
import dao from '@esentai/core/features/kkms/dao';
import { updateKkm } from '@esentai/core/features/kkms/sagas';
import { select, takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';

import { getCurrentUserId } from '@/features/auth/selectors';
import { getKkmId } from '@/features/kkmPage/selectors';
import { ROUTE_PATH as KKM_PATH } from '@/features/kkmsPage/consts';

import { FETCH_KKM, UPDATE_KKM } from './consts/types';

export const { findById: findKkmById } = dao.sagas;

export function* fetchKkm(action) {
  const { payload: kkmId } = action;

  yield call(findKkmById, kkmId);
}

export function* updateKkmSaga(action) {
  const { payload: attributes } = action;
  const kkmId = yield select(getKkmId);
  const history = {
    object_id: kkmId,
    model: 'cash_machine',
    user_id: yield select(getCurrentUserId),
    comment: attributes.comment,
    new_data: JSON.stringify(attributes),
    operation: 'update',
  };

  yield call(updateKkm, kkmId, {
    ...attributes,
    fns_id: attributes.fns_id.trim(),
  });
  yield call(createHistoryData, history);
  yield put(push(KKM_PATH));
}

export function* watchFetchKkm() {
  yield takeEvery(FETCH_KKM, fetchKkm);
}

export function* watchUpdateKkm() {
  yield takeEvery(UPDATE_KKM, updateKkmSaga);
}

export default function*() {
  yield spawn(watchFetchKkm);
  yield takeLatest(UPDATE_KKM, createTaskSaga(updateKkmSaga));
}
