import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import Tag from '@/components/Tag';
import LocationNameLazy from '@/containers/LocationNameLazy';

const LocationPageTagLink = ({ locationId, pageUrl }) => (
  <Link to={pageUrl}>
    <Tag label={<LocationNameLazy locationId={locationId} />} />
  </Link>
);

LocationPageTagLink.propTypes = {
  locationId: PropTypes.number.isRequired,
  pageUrl: PropTypes.string.isRequired,
};

export default LocationPageTagLink;
