export default {
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },

  backButton: {
    marginRight: 20,
  },

  requiredMessages: {
    marginRight: 30,
  },
};
