import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ConstructorBlock from '@/components/ConstructorBlock';
import ConstructorBlockTypeLabel from '@/components/ConstructorBlock/TypeLabel';
import ConstructorBlockTypeSelect from '@/components/ConstructorBlock/TypeSelect';
import {
  getBlockType,
  getBlockTypeLabel,
} from '@/features/feedLayoutEditPage/structs/block';

class Block extends PureComponent {
  static propTypes = {
    block: PropTypes.object.isRequired,
    canMoveDown: PropTypes.bool.isRequired,
    canMoveUp: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onMoveDown: PropTypes.func.isRequired,
    onMoveUp: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    options: PropTypes.any.isRequired,
    BlockContent: PropTypes.func.isRequired,
  };

  getType = () => {
    const { block } = this.props;

    return getBlockType(block);
  };

  handleRemove = () => {
    const { block, onRemove } = this.props;

    onRemove(block);
  };

  handleChangeType = newType => {
    const { block, onTypeChange } = this.props;
    const type = this.getType();

    if (newType === type) {
      return;
    }

    onTypeChange(block, newType);
  };

  handleUp = () => this.props.onMoveUp(this.props.block);

  handleDown = () => this.props.onMoveDown(this.props.block);

  renderBlockContent = props => {
    const { block, BlockContent } = this.props;

    return <BlockContent {...props} block={block} />;
  };

  renderTypeLabel = props => {
    const { block } = this.props;

    return (
      <ConstructorBlockTypeLabel {...props}>
        {getBlockTypeLabel(block)}
      </ConstructorBlockTypeLabel>
    );
  };

  renderTypeSelect = props => {
    const { options } = this.props;

    return <ConstructorBlockTypeSelect {...props} options={options} />;
  };

  render() {
    const { block, canMoveDown, canMoveUp, disabled } = this.props;
    const type = getBlockType(block);

    return (
      <ConstructorBlock
        canMoveDown={canMoveDown}
        canMoveUp={canMoveUp}
        disabled={disabled}
        type={type}
        onMoveDown={this.handleDown}
        onMoveUp={this.handleUp}
        onRemove={this.handleRemove}
        onTypeChange={this.handleChangeType}
        Content={this.renderBlockContent}
        TypeLabel={this.renderTypeLabel}
        TypeSelect={this.renderTypeSelect}
      />
    );
  }
}

export default Block;
