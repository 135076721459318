import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { dateTimeFormat, formatMoney } from '@/utils/format';

import { ReceiptRefundPurchase } from '../index';

export interface ReceiptRefundPurchasesTableProps {
  items: ReceiptRefundPurchase[];
}

class ReceiptRefundPurchasesTable extends React.Component<
  ReceiptRefundPurchasesTableProps
> {
  render() {
    const { items } = this.props;

    return (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Название товара</TableCell>
            <TableCell>Сумма за единицу товара</TableCell>
            <TableCell>Количество товаров</TableCell>
            <TableCell>Дата и время возврата товара</TableCell>
            <TableCell>Итого</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => (
            <TableRow key={item.name}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{formatMoney(item.price)}</TableCell>
              <TableCell>{item.amount}</TableCell>
              <TableCell>
                {dateTimeFormat(new Date(item.record_created))}
              </TableCell>
              <TableCell>
                {item.cost
                  ? formatMoney(item.cost)
                  : formatMoney(item.amount * item.price)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default ReceiptRefundPurchasesTable;
