import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getActivationsCount,
  getActivationsToPrePushClicksPercentage,
  getActivationsToSegmentPercentage,
  getClicksCount,
  getClicksPercentageByBlackLeveledUsers,
  getClicksPercentageByJetBlackLeveledUsers,
  getClicksPercentageByOrangeLeveledUsers,
  getClicksToCoveragePercentage,
  getConversionRate,
  getCoverage,
  getPrePushClicksCount,
  getPrePushClicksToSegmentPercentage,
  getPushClicksCount,
  getPushClicksToActivationsPercentage,
  getPushClicksToSegmentPercentage,
  getSegment,
  getViewsCount,
  getViewsToSegmentPercentage,
} from '@/features/campaignsStatisticsPage/selectors';

import TotalRow from './component';
import styles from './styles';

const mapStateToProps = state => ({
  conversion: getConversionRate(state),
  coverage: getCoverage(state),
  clicksCount: getClicksCount(state),
  clicksPercentageByBlackLeveledUsers: getClicksPercentageByBlackLeveledUsers(
    state,
  ),
  clicksPercentageByOrangeLeveledUsers: getClicksPercentageByOrangeLeveledUsers(
    state,
  ),
  clicksPercentageByJetBlackLeveledUsers: getClicksPercentageByJetBlackLeveledUsers(
    state,
  ),
  clicksToCoveragePercentage: getClicksToCoveragePercentage(state),
  segment: getSegment(state),
  viewsCount: getViewsCount(state),
  viewsToSegmentPercentage: getViewsToSegmentPercentage(state),
  prePushClicksCount: getPrePushClicksCount(state),
  pushClicksCount: getPushClicksCount(state),
  activationsCount: getActivationsCount(state),
  activationsToSegmentPercentage: getActivationsToSegmentPercentage(state),
  activationsToPrePushClicksPercentage: getActivationsToPrePushClicksPercentage(
    state,
  ),
  pushClicksToActivationsPercentage: getPushClicksToActivationsPercentage(
    state,
  ),
  pushClicksToSegmentPercentage: getPushClicksToSegmentPercentage(state),
  prePushClicksToSegmentPercentage: getPrePushClicksToSegmentPercentage(state),
});

export default compose(connect(mapStateToProps), withStyles(styles))(TotalRow);
