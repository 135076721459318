import { withStyles } from '@material-ui/core';

import { createPageFilterTemplate } from '@/structs/pageFilter';
import { createAnyOfAdapter } from '@/structs/pageFilterAdapter';

import WhitelistGroupSearchInputs from './component';
import styles from './styles';

export const createWhitelistGroupFilter = createPageFilterTemplate({
  FilterComponent: withStyles(styles)(WhitelistGroupSearchInputs),
  FormatComponent: ({ value }) => value.join(', '),
  adapter: createAnyOfAdapter([]),
});

export default WhitelistGroupSearchInputs;
