import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CloneButton from '../../buttons/CloneButton';
import EditButton from '../../buttons/EditButton';

const DeclinedStatusActions = ({ itemId }) => (
  <Fragment>
    <EditButton itemId={itemId} />
    <CloneButton itemId={itemId} />
  </Fragment>
);

DeclinedStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default DeclinedStatusActions;
