export default ({ palette }) => ({
  wrapper: {
    margin: [0, 'auto', 40],
  },
  tableBlock: {
    marginTop: 20,
  },
  buttonsWrapper: {
    height: 0,
    position: 'relative',
    top: 12,
    float: 'right',
  },
  excelButton: {
    marginTop: 15,
  },
  pushFormSegment: {
    marginTop: 40,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },

  backButton: {
    marginRight: 20,
  },

  requiredMessages: {
    marginRight: 30,
  },

  userIcon: {
    // flex: 'none',
    // display: 'flex',
    // marginRight: 0,
    // marginTop: 0,
    color: palette.logo.black.esentaiColor,
  },

  usersCount: {
    fontFamily: 'Helvetica',
  },
});
