import { getPriority } from '@esentai/core/features/productCategory/selectors';
import {
  compose,
  equals,
  head,
  last,
  map,
  max,
  min,
  not,
  pipe,
  reduce,
} from 'ramda';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError,
  isLoadingItems,
  getItemsIds,
  getItemsMeta,
  getItemsTotalCount,
} = itemsDuck.selectors;

export const { getPage, getLimit, getQuery, getSort } = queryDuck.selectors;

export const isFirstPage = pipe(getPage, equals(0));

export const isLastPage = state => {
  const totalCount = getItemsTotalCount(state);
  const currentOffset = (getPage(state) + 1) * getLimit(state);

  return currentOffset > totalCount;
};

export const isFirstItem = (state, categoryId) => {
  const ids = getItemsIds(state);
  const firstItem = head(ids);

  return categoryId === firstItem;
};

export const isLastItem = (state, categoryId) => {
  const ids = getItemsIds(state);
  const lastItem = last(ids);

  return categoryId === lastItem;
};

export const getCategoriesPriorities = state =>
  pipe(
    getItemsIds,
    map(categoryId => getPriority(state, categoryId)),
  )(state);

export const getHighestPriority = pipe(
  getCategoriesPriorities,
  reduce(min, Infinity),
);

export const getLowestPriority = pipe(
  getCategoriesPriorities,
  reduce(max, -Infinity),
);

export const hasHighestPriority = (state, categoryId) => {
  const priority = getPriority(state, categoryId);
  const highestPriority = getHighestPriority(state);

  return priority === highestPriority;
};

export const hasLowestPriority = (state, categoryId) => {
  const priority = getPriority(state, categoryId);
  const lowestPriority = getLowestPriority(state);

  return priority === lowestPriority;
};

export const canMoveCategoryUp = compose(not, isFirstItem);

export const canMoveCategoryDown = compose(not, isLastItem);
