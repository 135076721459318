import api from '@/api';
import { saveAs } from '@/utils/file-saver';

export const downloadXls = ({ sort, filter }) => {
  // page, limit - Pagination params for download excel file
  // const urlParams = new URLSearchParams(window.location.search);

  // urlParams.set('page', page.toString());
  // urlParams.set('size', limit.toString());

  api
    .doBlobGetParkingRequest(`/timiryazev-passage/xlsx`, {
      // page,
      // size: limit,
      sort,
      filter,
    })
    .then(response => {
      saveAs(response.data, `Timiryazev паркинг.xlsx`);
    });
};
