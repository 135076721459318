import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import Button from './component';

const mapDispatchToProps = {
  redirect: path => push(path),
};

export default connect(null, mapDispatchToProps)(Button);
