export default {
  container: {
    minWidth: 400,
  },
  isOpenContainer: {
    minHeight: 300,
  },
  paper: {
    maxHeight: '100%',
    overflowY: 'auto',
  },
};
