import { Chip } from '@material-ui/core';
import { Done as DoneIcon } from '@material-ui/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { omit, path, pick } from 'ramda';
import React, { Component, Fragment } from 'react';

import { RETRY_ACTION } from '@/components/FileFiled/FileChip/consts';

import AvatarWithProgress from './CustomAvatar';
import ErrorButtonSet from './ErrorButtonSet';

export default class FileChip extends Component {
  static defaultProps = {
    preview: null,
    error: null,
    className: null,
    url: null,
    progress: 0,
  };

  static propTypes = {
    error: PropTypes.any,
    fileId: PropTypes.any.isRequired,
    cancelUpload: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    retryUpload: PropTypes.func.isRequired,
    isFinished: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    preview: PropTypes.string,
    progress: PropTypes.number,
    submitFile: PropTypes.func.isRequired,
    className: PropTypes.any,
    url: PropTypes.string,
  };

  state = {
    animateOnFinish: true,
  };

  handleDelete = event => {
    const {
      cancelUpload,
      deleteFile,
      error,
      isUploading,
      retryUpload,
      fileId,
    } = this.props;

    if (isUploading) {
      cancelUpload(fileId);
    }

    if (error) {
      if (path(['target', 'dataset', 'action'], event) === RETRY_ACTION) {
        return retryUpload(fileId);
      }
    }

    deleteFile(fileId);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFinished !== this.props.isFinished &&
      this.state.animateOnFinish
    ) {
      this.iv = setTimeout(
        () => this.setState({ animateOnFinish: false }),
        2000,
      );
    }

    if (this.props.isFinished && !prevProps.isFinished) {
      const { fileId, submitFile, url } = this.props;

      submitFile(fileId, url);
    }
  }

  componentWillUnmount() {
    clearInterval(this.iv);
  }

  renderIcon() {
    const { classes, error, isFinished } = this.props;

    if (error) {
      return <ErrorButtonSet className={classes.buttonSet} />;
    }

    const { animateOnFinish } = this.state;

    if (isFinished && animateOnFinish) {
      return <DoneIcon />;
    }

    return null;
  }

  render() {
    const {
      classes,
      error,
      isFinished,
      isUploading,
      name,
      className,
      preview,
      progress,
      disabled,
      ...props
    } = this.props;
    const { animateOnFinish } = this.state;

    return (
      <Fragment>
        <Chip
          avatar={
            <AvatarWithProgress
              isUploading={isUploading}
              progress={progress}
              preview={preview}
              classes={classes}
            />
          }
          className={cx(
            className,
            classes.chip,
            error && classes.hasError,
            isFinished && animateOnFinish && classes.isFinished,
            isUploading && classes.isUploading,
          )}
          classes={pick(['deleteIcon', 'label'], classes)}
          label={name}
          onDelete={!disabled ? this.handleDelete : null}
          deleteIcon={this.renderIcon()}
          {...omit(
            [
              'deleteFile',
              'retryUpload',
              'cancelUpload',
              'submitFile',
              'fileId',
            ],
            props,
          )}
        />
      </Fragment>
    );
  }
}
