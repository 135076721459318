import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Tag = ({ classes, label }) => (
  <div className={classes.root}>
    <Typography className={classes.label}>{label}</Typography>
  </div>
);

Tag.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default Tag;
