import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import { createAction } from 'redux-actions';

import query from './ducks/query';
import {
  CREATE_VIEW,
  DELETE_VIEW,
  MOVE_VIEW_DOWN,
  MOVE_VIEW_UP,
  RETRY_FETCH,
  UPDATE_VIEW,
} from './types';

export const { updateLimit, updatePage, updateSort } = query.actions;

export const deleteView = createTaskAction(DELETE_VIEW);

export const moveViewDown = createTaskAction(MOVE_VIEW_DOWN);

export const moveViewUp = createTaskAction(MOVE_VIEW_UP);

export const createView = createTaskAction(CREATE_VIEW);

export const updateView = createTaskAction(UPDATE_VIEW);

export const retryFetch = createAction(RETRY_FETCH);
