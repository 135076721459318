import { Button, Paper, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Modal from '@/components/Modal';
import { BALANCE_KEY } from '@/features/efsUsersPage/consts/keys';
import { onArrowValidate, validate } from '@/utils/validate';

// eslint-disable-next-line complexity
const AddBalance = props => {
  const { classes, onClose, onSubmit, Field, Form, values, isValid } = props;

  return (
    <Modal {...props} onClose={onClose}>
      <Paper className={classes.wrapper}>
        <Typography variant="h4" gutterBottom>
          Добавить баланс
        </Typography>

        <Form>
          <div className={classes.uuid_block}>
            <Field
              id={BALANCE_KEY}
              margin="normal"
              Target={TextField}
              name={BALANCE_KEY}
              label="Баланс"
              type="number"
              onKeyPress={event => validate(event, 'positiveInt')}
              onKeyDown={event => onArrowValidate(event)}
              className={classes.hideArrows}
            />
          </div>
        </Form>

        <div className={classes.footer}>
          <Button onClick={onClose}>Отмена</Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            disabled={!isValid || values[BALANCE_KEY] === ''}
            onClick={() => onSubmit(values)}
          >
            Добавить
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

AddBalance.propTypes = {
  onClose: PropTypes.func.isRequired,
  Field: PropTypes.func.isRequired,
  Form: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
};

AddBalance.defaultProps = {
  values: {},
};

export default AddBalance;
