import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import MainPageTopBar from '@/components/DevicePreview/MainPageTopBar';
import {
  LOCALE_EN,
  LOCALE_KZ,
  LOCALE_RU,
} from '@/hocs/withDevicePreview/consts';

const Preview = ({
  classes,
  englishTitle,
  russianTitle,
  kazakhTitle,
  imageUrl,
  selectByLocale,
}) => {
  const title = selectByLocale({
    [LOCALE_EN]: englishTitle,
    [LOCALE_RU]: russianTitle,
    [LOCALE_KZ]: kazakhTitle,
  });

  return (
    <Fragment>
      <MainPageTopBar />
      <div className={classes.container}>
        <div className={classes.imageWrapper}>
          <img className={classes.image} src={imageUrl} />
        </div>
        <div className={classes.title}>{title}</div>
      </div>
    </Fragment>
  );
};

Preview.propTypes = {
  imageUrl: PropTypes.string,
  englishTitle: PropTypes.string,
  russianTitle: PropTypes.string,
  kazakhTitle: PropTypes.string,
  selectByLocale: PropTypes.func.isRequired,
};
Preview.defaultProps = {
  imageUrl: null,
  englishTitle: null,
  russianTitle: null,
  kazakhTitle: null,
};

export default Preview;
