import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';

import CanView from '@/containers/CanView';

import { canRepayReconciliation } from '../../permissions';

const Actions = ({ classes, handleDialog, repayDisabled }) => {
  const preventDefault = event => {
    event.preventDefault();
    handleDialog();
  };

  return (
    <Grid className={classes.actions} container wrap="nowrap">
      <div className={classes.actionCell}>
        <CanView permission={canRepayReconciliation}>
          <Button
            className={classes.linkButton}
            onClick={preventDefault}
            disabled={repayDisabled}
          >
            Погасить
          </Button>
        </CanView>
      </div>
    </Grid>
  );
};

Actions.propTypes = {
  handleDialog: PropTypes.func.isRequired,
  repayDisabled: PropTypes.bool.isRequired,
};

export default Actions;
