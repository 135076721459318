import { getLocation } from 'connected-react-router';
import { path, pipe, prop } from 'ramda';

export {
  getAction,
  getLocation,
  createMatchSelector,
} from 'connected-react-router';

export const getRouterParameterByMatch = (match, paramName) =>
  path(['params', paramName], match);

export const getPathName = pipe(getLocation, prop('pathname'));

export const getQueryParams = pipe(getLocation, prop('search'));
