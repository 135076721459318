import { Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import { NotFound } from '@/components/ErrorMessages';
import FormSection from '@/components/FormSection';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import { ROUTE_PATH as MALL_BONUSES_PATH } from '@/features/mallBonusesPage/consts';

import FirstStep from './FirstStep';
import InitialStep from './InitialStep';
import NonEditableMessage from './NonEditableMessage';
import SecondStep from './SecondStep';

const steps = [
  {
    title: 'Шаг 1: Опишите бонус',
    description:
      'Заполните информацию о бонусе, продолжительности и процент начисления.',
  },
  {
    title: 'Шаг 2: Сформируйте сегмент',
    description:
      'Укажите фильтры для формирования сегмента аудитории и его свойства.',
  },
];

class EditMallBonus extends Component {
  static propTypes = {
    isFirstStepEditable: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  };

  getStep = () => {
    const { match } = this.props;
    const { params } = match;
    const { step } = params || {};

    if (step) {
      return Number(step) - 1;
    }

    return null;
  };

  getActiveStep = () => {
    const step = this.getStep();

    return step || 0;
  };

  render() {
    const { classes, isFirstStepEditable } = this.props;
    const activeStep = this.getActiveStep();

    if (!steps[activeStep]) {
      return <NotFound />;
    }

    if (activeStep === 0 && !isFirstStepEditable) {
      return <NonEditableMessage />;
    }

    return (
      <Page>
        <PageHeader gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={MALL_BONUSES_PATH}>Настройка бонусов</Breadcrumb>
          </Breadcrumbs>
          <PageTitle>Управление бонусами</PageTitle>

          <Stepper
            activeStep={activeStep}
            nonLinear
            classes={{ root: classes.stepperRoot }}
          >
            {steps.map(({ title, description }) => (
              <Step key={title} classes={{ root: classes.step }}>
                <StepLabel
                  optional={
                    <Typography variant="caption">{description}</Typography>
                  }
                  classes={{ label: classes.stepLabel }}
                >
                  {title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </PageHeader>
        <PageContent>
          <FormSection className={classes.pageTitle}>
            <Typography variant="h4" color="inherit" gutterBottom>
              {this.renderStepTitle()}
            </Typography>
          </FormSection>
          <PaperSheet className={classes.paper}>
            {this.renderActiveStep()}
          </PaperSheet>
        </PageContent>
      </Page>
    );
  }

  renderActiveStep() {
    const activeStep = this.getStep();

    if (activeStep === 0) {
      return <FirstStep />;
    } else if (activeStep === 1) {
      return <SecondStep />;
    } else if (activeStep === null) {
      return <InitialStep />;
    }

    return null;
  }

  renderStepTitle() {
    const activeStep = this.getActiveStep();
    const currentStep = steps[activeStep];

    return currentStep.title;
  }
}

export default EditMallBonus;
