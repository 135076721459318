import {
  ACTIVITY_TYPE_KEY,
  BONUSES_KEY,
  COMISSAR_KEY,
  COMMENT_KEY,
  CUSTOMER_KEY,
  ID_KEY,
  RECORD_CREATED_KEY,
  STATUS_KEY,
  TIMESTAMP_KEY,
  USER_ID_KEY,
  USER_LEVEL_KEY,
} from '@esentai/core/features/loyalties/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={ID_KEY}>ID</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>ID пользователя</CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={CUSTOMER_KEY}>Имя Фамилия пользователя</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={USER_ID_KEY}>Менеджер молла</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={COMISSAR_KEY}>Комплаенс Менеджер</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={RECORD_CREATED_KEY}>Дата и время запроса</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={TIMESTAMP_KEY}>
        Дата и время ответа Комплаенс Менеджера
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={ACTIVITY_TYPE_KEY}>Статус</SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={BONUSES_KEY}>
        Количество присвоенных бонусов
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={USER_LEVEL_KEY}>
        Присвоенный уровень лояльности
      </SortControl>
    </CondenseTableCell>

    <CondenseTableCell>
      <SortControl field={COMMENT_KEY}>Комментарий</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={STATUS_KEY}>Ответ Комплаенс Менеджера</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>Действия</CondenseTableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
