export default {
  root: {
    borderBottom: [1, 'solid', '#eee'],
    padding: [17, 0],
  },

  arrowDownIcon: {
    fontSize: 'inherit',
    verticalAlign: 'middle',
  },
};
