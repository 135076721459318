export default {
  buttonsWrapper: {
    height: 0,
    position: 'relative',
    top: 12,
    float: 'right',
  },
  rightSpacing: {
    marginRight: 65,
  },
};
