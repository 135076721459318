import createAction from 'redux-actions/es/createAction';

import { RETRY_FETCH } from './consts/types';
import query from './ducks/query';

export { findSalesTurnoverById } from '@esentai/core/features/salesTurnoverDetails/actions';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const retryFetch = createAction(RETRY_FETCH);
