import {
  NAME_ENGLISH_KEY,
  NAME_RUSSIAN_KEY,
} from '@esentai/core/features/locations/consts/keys';
import { Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import LinkAsButton from '@/components/Button';
import ButtonSet from '@/components/ButtonSet';
import FieldRow from '@/components/FieldRow';
import { ROUTE_PATH as LOCATIONS_PATH } from '@/features/locationsPage/consts';

const fieldNames = {
  [NAME_RUSSIAN_KEY]: 'название локации на русском',
  [NAME_ENGLISH_KEY]: 'название локации на английском',
};

export default class EditLocationForm extends Component {
  static propTypes = {
    Field: PropTypes.func.isRequired,
    Form: PropTypes.func.isRequired,
    RequiredMessages: PropTypes.func.isRequired,
    SubmitButton: PropTypes.func.isRequired,
    error: PropTypes.instanceOf(Error),
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
    error: null,
  };

  render() {
    const {
      Form,
      Field,
      RequiredMessages,
      SubmitButton,
      classes,
      error,
      isLoading,
    } = this.props;

    return (
      <Form>
        <FieldRow label="название локации на русском">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={NAME_RUSSIAN_KEY}
            required
            rows={4}
            label="Изменить название локации на русском"
          />
        </FieldRow>

        <FieldRow label="название локации на английском">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={NAME_ENGLISH_KEY}
            required
            rows={4}
            label="Изменить название локации на русском"
          />
        </FieldRow>

        <FieldRow>
          <ButtonSet>
            <RequiredMessages fieldNames={fieldNames} />
            <LinkAsButton size="large" href={LOCATIONS_PATH}>
              Назад
            </LinkAsButton>
            <SubmitButton
              Target={AsyncButton}
              ButtonComponent={Button}
              error={error}
              isLoading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
            >
              Сохранить
            </SubmitButton>
          </ButtonSet>
        </FieldRow>
      </Form>
    );
  }
}
