import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CampaignTitleLazy from '@/containers/CampaignTitleLazy';

const SelectedItem = ({ onDelete, value, disabled }) => (
  <Chip
    label={<CampaignTitleLazy id={value} />}
    onDelete={!disabled ? onDelete : null}
  />
);

SelectedItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SelectedItem;
