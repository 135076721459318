import dao from '@esentai/core/features/giftSets/dao';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import { FETCH_GIFT_SET } from './consts/types';

export const { findById: findGiftSetById } = dao.sagas;

export function* fetchGiftSet(action) {
  const { payload: giftSetId } = action;

  yield call(findGiftSetById, giftSetId);
}

export function* watchFetchGiftSet() {
  yield takeEvery(FETCH_GIFT_SET, fetchGiftSet);
}

export default function*() {
  yield spawn(watchFetchGiftSet);
}
