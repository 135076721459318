import {
  REWARD_BENEFITS_IDS_KEY,
  REWARD_LOYALTY_POINTS_KEY,
  REWARD_TYPE_KEY,
} from '@esentai/core/features/campaigns/actionResult/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import DelayForm from './component';
import styles from './styles';

const mapStateToProps = (state, { setFieldValue, values, Field }) => ({
  rewardBenefitsIds: values[REWARD_BENEFITS_IDS_KEY],
  rewardLoyaltyPoints: values[REWARD_LOYALTY_POINTS_KEY],
  rewardType: values[REWARD_TYPE_KEY],
  setFieldValue,
  Field,
});

export default compose(connect(mapStateToProps), withStyles(styles))(DelayForm);
