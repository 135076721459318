import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CloneButton from '../../buttons/CloneButton';

const ModeratingStatusActions = ({ itemId }) => (
  <Fragment>
    <CloneButton itemId={itemId} />
  </Fragment>
);

ModeratingStatusActions.propTypes = {
  itemId: PropTypes.number.isRequired,
};

export default ModeratingStatusActions;
