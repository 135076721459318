import { createAction } from 'redux-actions';

import query from './ducks/query';
import { USERS_RETRY_FETCH } from './types';

export const {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} = query.actions;

export const retryFetch = createAction(USERS_RETRY_FETCH);

// export const updateStore = createTaskAction(UPDATE_STORE);
// export const archiveStore = createTaskAction(ARCHIVE_STORE);

// export const { findById: findStoreById } = dao.actions;
