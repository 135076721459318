import { findParkingWhitelistById } from '@esentai/core/features/parkingWhitelist/actions';
import {
  getLastNameForParkingWhitelist,
  isParkingWhitelistLoaded,
  isParkingWhitelistLoading,
} from '@esentai/core/features/parkingWhitelist/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import WhitelistCell from './component';

const mapStateToProps = (state, { itemId, isLoaded }) => ({
  isLoaded: !isLoaded
    ? isParkingWhitelistLoaded(state, itemId)
    : isLoaded(state, itemId),
  isLoading: isParkingWhitelistLoading(state, itemId),
  itemId,
  name: getLastNameForParkingWhitelist(state, itemId),
});

const mapDispatchToProps = { fetchItem: findParkingWhitelistById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(WhitelistCell);
