import { RESOURCE_NAME } from '@esentai/core/features/benefits/consts';
import {
  canCreate,
  canDelete,
  canRead,
  canUpdate,
} from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadBenefits = authorizeIf(canRead(RESOURCE_NAME));

export const canCreateBenefit = authorizeIf(canCreate(RESOURCE_NAME));

export const canUpdateBenefit = authorizeIf(canUpdate(RESOURCE_NAME));

export const canDeleteBenefit = authorizeIf(canDelete(RESOURCE_NAME));

// TODO set permission for benefit statistic
export const canReadBenefitStatistics = () => true;
