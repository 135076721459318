import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddCategoryForm from './CategoryForm';

class CategoryModal extends Component {
  render() {
    const { isOpen, onClose, onSubmit, initialValues } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="md"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">
          {initialValues ? 'Редактировать вид' : 'Создать вид'}
        </DialogTitle>

        <DialogContent>
          <AddCategoryForm
            onClose={onClose}
            onSubmit={onSubmit}
            initialValues={initialValues}
            isEdit={initialValues}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

CategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default CategoryModal;
