import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React from 'react';

import Success from '@/components/Messages/Success';

import Form from './Form';

export const INITIAL_STRATEGY = 'initial-strategy';

export const SUCCESS_STRATEGY = 'success-strategy';

export const initialStrategy = {
  name: INITIAL_STRATEGY,
  Body: Form,
};

const SuccessBlock = () => (
  <Success>
    <Typography variant="h6">Сообщение успешно отправленно</Typography>
    <Typography variant="subtitle1">Проверьте вашу почту</Typography>
  </Success>
);

export const successStrategy = {
  name: SUCCESS_STRATEGY,
  Body: SuccessBlock,
};

const strategies = [initialStrategy, successStrategy];

export const propTypes = PropTypes.oneOf(strategies);

export const getStrategy = ({ isFinished, error }) =>
  isFinished && isNil(error) ? successStrategy : initialStrategy;

export default strategies;
