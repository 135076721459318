import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import MerchantNameLazy from '@/containers/MerchantNameLazy';

const MerchantChip = ({ value, ...props }) => (
  <Chip
    {...props}
    id={value}
    label={<MerchantNameLazy merchantId={value} showSubtitle />}
  />
);

MerchantChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default MerchantChip;
