import { RESOURCE_NAME as APP_VERSION } from '@esentai/core/features/appVersion/consts';
import { RESOURCE_NAME as BEACONS } from '@esentai/core/features/beacons/consts';
import { RESOURCE_NAME as BENEFITS } from '@esentai/core/features/benefits/consts';
import { RESOURCE_NAME as BONUS_TRANSACTIONS } from '@esentai/core/features/bonus_transactions/consts';
import { RESOURCE_NAME as BONUS_TRANSFER } from '@esentai/core/features/bonusTransfer/consts';
import { RESOURCE_NAME as CAMPAIGNS } from '@esentai/core/features/campaigns/consts';
import { RESOURCE_NAME as CREDIT_CARDS } from '@esentai/core/features/creditCards/consts';
import { RESOURCE_NAME as EFS_VISIT_RECORDS } from '@esentai/core/features/efsVisitRecords/consts';
import { RESOURCE_NAME as GIFT_CERTIFICATES } from '@esentai/core/features/giftCertificates/consts';
import { RESOURCE_NAME as GIFT_SETS } from '@esentai/core/features/giftSets/consts';
import { RESOURCE_NAME as KKMS } from '@esentai/core/features/kkms/consts';
import { RESOURCE_NAME as LOCATIONS } from '@esentai/core/features/locations/consts';
import { RESOURCE_NAME as MALL_BONUSES } from '@esentai/core/features/mallBonuses/consts';
import { RESOURCE_NAME as MALL_STATS } from '@esentai/core/features/mallStats/consts';
import { RESOURCE_NAME as MERCHANTS } from '@esentai/core/features/merchants/consts';
import { RESOURCE_NAME as PARKING_LEVEL_THREE_WHITELIST } from '@esentai/core/features/parkingLevelThreeWhitelist/consts';
import { RESOURCE_NAME as PARKING_TOWER_STOP_LIST } from '@esentai/core/features/parkingTowerStopList/consts';
import { RESOURCE_NAME as PARKING_WHITELIST } from '@esentai/core/features/parkingWhitelist/consts';
import { RESOURCE_NAME as PARKING_WHITELIST_FILTER } from '@esentai/core/features/parkingWhitelistFilter/consts';
import { RESOURCE_NAME as PARKING_WHITELIST_GROUPS } from '@esentai/core/features/parkingWhitelistGroups/consts';
import { RESOURCE_NAME as PRODUCT_CATEGORIES } from '@esentai/core/features/productCategories/consts';
import { RESOURCE_NAME as PRODUCTS } from '@esentai/core/features/products/consts';
import { RESOURCE_NAME as PURCHASE_HISTORY } from '@esentai/core/features/purchases/consts';
import {
  REPAY,
  RESOURCE_NAME as RECONCILIATIONS_CP,
} from '@esentai/core/features/reconciliation/consts';
import { RESOURCE_NAME as RECONCILIATIONS_HISTORY } from '@esentai/core/features/reconciliationHistory/consts';
import { RESOURCE_NAME as RECONCILIATION_PERIOD_BONUS_DETAILS } from '@esentai/core/features/reconciliationPeriodBonusDetails/consts';
import { RESOURCE_NAME as RECONCILIATION_RECEIPTS } from '@esentai/core/features/reconciliationReceipts/consts';
import { RESOURCE_NAME as RECONCILIATION_REFUNDS } from '@esentai/core/features/reconciliationRefunds/consts';
import { RESOURCE_NAME as RECONCILIATION_STORE_BONUS_DETAILS } from '@esentai/core/features/reconciliationStoreBonusDetails/consts';
import { RESOURCE_NAME as RECONCILIATION_STORE_TO_CP } from '@esentai/core/features/reconciliationStoreToCp/consts';
import { RESOURCE_NAME as SALES_TURNOVERS } from '@esentai/core/features/salesTurnovers/consts';
import { RESOURCE_NAME as STORE_BONUSES } from '@esentai/core/features/storeBonuses/consts';
import { RESOURCE_NAME as STORES } from '@esentai/core/features/stores/consts';
import { RESOURCE_NAME as USERS } from '@esentai/core/features/users/consts';
import {
  can,
  CREATE,
  DELETE,
  EDIT,
  permit,
  VIEW,
} from '@esentai/core/policy-dsl';

import { APP_VERSION as CHANGE_APP_VERSION } from '@/features/appVersionPage/consts/permissions';
import { BONUS_TRANSFER as CAN_TRANSFER_BONUSES } from '@/features/bonusTransferPage/consts/permissions';
import { MODERATE } from '@/features/campaignsPage/consts/permissions';
import {
  RESOURCE_NAME as PARKING,
  RESOURCE_RATE_NAME as PARKING_RATE,
} from '@/features/parkingPage/consts';
import { RESOURCE_NAME as PERSONNEL } from '@/features/personnel/consts';
import {
  EDIT_LOYALTY_DATA,
  VIEW_PERSONAL_DATA,
} from '@/features/userPage/consts/permissions';
import { RESOURCE_NAME as USERS_STATISTICS } from '@/features/usersStatistics/consts';

import { VIEW_NAVIGATION_BAR } from '../consts/permissions';

export default [
  can(VIEW_NAVIGATION_BAR),
  permit(APP_VERSION, [CHANGE_APP_VERSION]),
  permit(BEACONS, [VIEW]),
  permit(BENEFITS, [VIEW, CREATE, EDIT, DELETE]),
  permit(BONUS_TRANSACTIONS, [VIEW, EDIT, CREATE]),
  permit(BONUS_TRANSFER, [CAN_TRANSFER_BONUSES]),
  permit(CAMPAIGNS, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(CREDIT_CARDS, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(EFS_VISIT_RECORDS, [VIEW, EDIT]),
  permit(GIFT_CERTIFICATES, [VIEW, EDIT]),
  permit(GIFT_SETS, [CREATE, VIEW, EDIT, DELETE]),
  permit(KKMS, [VIEW, EDIT, CREATE, DELETE]),
  permit(LOCATIONS, [VIEW]),
  permit(MALL_BONUSES, [VIEW, EDIT, CREATE, DELETE]),
  permit(MALL_STATS, [VIEW, EDIT, CREATE, DELETE]),
  permit(MERCHANTS, [VIEW, EDIT]),
  permit(PARKING, [VIEW, CREATE, EDIT]),
  permit(PARKING_LEVEL_THREE_WHITELIST, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PARKING_RATE, [VIEW, CREATE, EDIT]),
  permit(PARKING_TOWER_STOP_LIST, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PARKING_WHITELIST, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PARKING_WHITELIST_FILTER, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PARKING_WHITELIST_GROUPS, [VIEW, CREATE, EDIT, DELETE, MODERATE]),
  permit(PERSONNEL, [VIEW]),
  permit(PRODUCTS, [VIEW, EDIT, CREATE, DELETE]),
  permit(PRODUCT_CATEGORIES, [VIEW]),
  permit(PURCHASE_HISTORY, [VIEW, EDIT, REPAY]),
  permit(RECONCILIATIONS_CP, [VIEW, EDIT, REPAY]),
  permit(RECONCILIATIONS_HISTORY, [VIEW, EDIT, REPAY]),
  permit(RECONCILIATION_PERIOD_BONUS_DETAILS, [VIEW, EDIT]),
  permit(RECONCILIATION_RECEIPTS, [VIEW, EDIT]),
  permit(RECONCILIATION_REFUNDS, [VIEW, EDIT]),
  permit(RECONCILIATION_STORE_BONUS_DETAILS, [VIEW, EDIT, REPAY]),
  permit(RECONCILIATION_STORE_TO_CP, [VIEW, EDIT, REPAY]),
  permit(SALES_TURNOVERS, [VIEW, EDIT]),
  permit(STORES, [VIEW, EDIT]),
  permit(STORE_BONUSES, [VIEW, EDIT, CREATE, DELETE]),
  permit(USERS, [VIEW, VIEW_PERSONAL_DATA, EDIT, EDIT_LOYALTY_DATA]),
  permit(USERS_STATISTICS, [VIEW]),
];
