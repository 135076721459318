import PropTypes from 'prop-types';

const LocatioNameLazy = ({ isLoaded, title }) => (isLoaded ? title : '...');

LocatioNameLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

LocatioNameLazy.defaultProps = {
  title: null,
};

export default LocatioNameLazy;
