import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/storeReceiptsPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getError,
  getItemsIds,
  getItemsTotalCount,
  getStoreId,
  isLoadingItems,
} from '../selectors';
import StoreReceipts from './component';

const mapStateToProps = state => ({
  error: getError(state),
  receiptsIds: getItemsIds(state),
  isLoading: isLoadingItems(state),
  storeId: getStoreId(state),
  totalCount: getItemsTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(StoreReceipts);
