import { compose } from 'ramda';
import { connect } from 'react-redux';

import { createInviteAndRedirect } from '@/features/invites/actions';
import { canInviteMallStaff } from '@/features/invites/permissions';
import withStrategy from '@/hocs/withStrategy';

import MakeInvite from './component';
import {
  MALL_ADMIN_STRATEGY,
  RETURN_PATH,
  STORE_ADMIN_STRATEGY,
} from './consts';
import strategies from './strategies';

const mapStateToProps = (state, { findStrategyBy }) => ({
  strategy: findStrategyBy(
    'name',
    canInviteMallStaff(state) ? MALL_ADMIN_STRATEGY : STORE_ADMIN_STRATEGY,
  ),
});

const mapDispatchToProps = {
  createInvite: invite =>
    createInviteAndRedirect({ invite, path: RETURN_PATH }),
};

export default compose(
  withStrategy({ strategies }),
  connect(mapStateToProps, mapDispatchToProps),
)(MakeInvite);
