import api from '@esentai/core/features/campaigns/api';
import {
  REFERRED_ARTICLE_ID_KEY,
  REFERRED_CALENDAR_EVENT_ID_KEY,
} from '@esentai/core/features/campaigns/banner/consts/keys';
import {
  STATUS_KEY,
  TITLE_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  DECLINED_STATUS,
  DRAFT_STATUS,
  MODERATING_STATUS,
  PAUSED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';
import {
  ARTICLE_TYPE,
  CALENDAR_EVENT_TYPE,
  SHOW_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import { REFERRED_SHOW_ID_KEY } from '@esentai/core/features/campaigns/notification/consts/keys';
import { anyOf, ilike, query, where } from '@esentai/core/query-dsl';

import pluralize from '@/utils/pluralize';

const getQueryBuilder = type => queryString =>
  query(
    where(TITLE_KEY, ilike(queryString)),
    where(TYPE_KEY, ilike(type)),
    where(
      STATUS_KEY,
      anyOf([
        ACTIVE_STATUS,
        APPROVED_STATUS,
        DECLINED_STATUS,
        type !== SHOW_TYPE && DRAFT_STATUS,
        MODERATING_STATUS,
        PAUSED_STATUS,
      ]),
    ),
  );

const articleFormatter = count =>
  pluralize(count, {
    one: `Найдена ${count} подходящая статья`,
    few: `Найдено ${count} подходящих статьи`,
    many: `Найдено ${count} подходящих статей`,
  });

const calendarEventFormatter = count =>
  pluralize(count, {
    one: `Найдена ${count} календарное событие`,
    few: `Найдено ${count} календарных события`,
    many: `Найдено ${count} календарных событий`,
  });

const showEventFormatter = count =>
  pluralize(count, {
    one: `Найдена ${count} онлайн витрина`,
    few: `Найдено ${count} онлайн витрин`,
    many: `Найдено ${count} онлайн витрин`,
  });

const propsMapping = {
  [ARTICLE_TYPE]: {
    api,
    buildQuery: getQueryBuilder(ARTICLE_TYPE),
    countCaptionFormatter: articleFormatter,
    inputProps: {
      label: 'Выбор статьи',
      helperText:
        'Укажите конкретную статью, на которую будет ссылаться баннер.',
    },
    selectedItemLabel: 'Выбор статьи',
  },
  [CALENDAR_EVENT_TYPE]: {
    api,
    buildQuery: getQueryBuilder(CALENDAR_EVENT_TYPE),
    countCaptionFormatter: calendarEventFormatter,
    inputProps: {
      label: 'Выбор календарного события',
      helperText:
        'Укажите конкретное календарное событие, на которую будет ссылаться баннер.',
    },
    selectedItemLabel: 'Выбор календарного события',
  },
  [SHOW_TYPE]: {
    api,
    buildQuery: getQueryBuilder(SHOW_TYPE),
    countCaptionFormatter: showEventFormatter,
    inputProps: {
      label: 'Выбор витрины',
      helperText:
        'Укажите конкретную витрину, на которую будет ссылаться баннер.',
    },
    selectedItemLabel: 'Выбор витрины',
  },
};

export const getPropsByType = type => propsMapping[type];

const keyMapping = {
  [SHOW_TYPE]: REFERRED_SHOW_ID_KEY,
  [ARTICLE_TYPE]: REFERRED_ARTICLE_ID_KEY,
  [CALENDAR_EVENT_TYPE]: REFERRED_CALENDAR_EVENT_ID_KEY,
};

export const getKeyByType = type => keyMapping[type];
