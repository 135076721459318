export default {
  wrapper: {
    marginTop: 30,
  },

  charts: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
};
