import { compose } from 'ramda';
import injectSheet from 'react-jss';

import withDevicePreview from '@/hocs/withDevicePreview';

import Preview from './component';
import styles from './styles';

export default compose(
  injectSheet(styles),
  withDevicePreview({ variant: 'longView' }),
)(Preview);
