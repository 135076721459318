import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

import api from '../../../../api';
import NoMatchMessage from '../../../../components/CommonTable/NoMatchMessage';

interface RowData {
  id: number;
  operation: string;
  comment: string;
  user_id: number;
  user: string;
  new_data: any; // TODO: Define a generic for this
  record_created: string;
}

export interface CmTableProps {
  detailId: number;
  headers: string[];
  children: (data: any) => JSX.Element;
}

export interface CmTableState {
  data: RowData[];
}

const styles = {
  tbody: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
};

export class CmTable extends React.Component<CmTableProps, CmTableState> {
  constructor(props: CmTableProps) {
    super(props);

    this.state = {
      data: [],
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.loadData();
  }

  public render(): JSX.Element {
    const { headers } = this.props;
    const { data } = this.state;

    return (
      <div style={{ marginTop: 25 }}>
        <Typography variant="h5">Действующая реконсиляция</Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={styles.tbody}>{this.renderBody(data)}</TableBody>
        </Table>
      </div>
    );
  }

  public renderBody(data: any[]): JSX.Element {
    const { children } = this.props;

    if (data.length > 0) {
      return children(data);
    }

    return <NoMatchMessage columnsCount={100} />;
  }

  private async loadData(): Promise<void> {
    const { detailId } = this.props;
    const getUrl = `/merchant-reconciliation/${detailId}`;

    const data = await api.doGet(getUrl);

    if (data.merchant_reconciliation.length > 0) {
      this.setState({
        data: data.merchant_reconciliation,
      });
    }
  }
}
