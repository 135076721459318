import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/personnelPage/ducks/query';
import {
  getPersonnelError,
  getPersonnelIds,
  getPersonnelTotalCount,
  isLoadingPersonnel,
} from '@/features/personnelPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import Personnel from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getPersonnelError(state),
  personnelIds: getPersonnelIds(state),
  isLoading: isLoadingPersonnel(state),
  totalCount: getPersonnelTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(Personnel);
