import { connect } from 'react-redux';

import CampaignCanEdit from '@/components/CanEdit';
import CampaignCanEditRadioGroup from '@/components/CanEditRadioBoxGroup';
import { isAttributeEditable } from '@/features/campaignCreatePage/selectors';

const mapStateToProps = (state, { field }) => ({
  canEdit: isAttributeEditable(state, field),
});

export const CanEdit = connect(mapStateToProps)(CampaignCanEdit);
export const CanEditRadioBoxGroup = connect(mapStateToProps)(
  CampaignCanEditRadioGroup,
);
