import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import api from '@/api';

import FileFiled from '../../../../../components/FileFiled';

export interface Item {
  itemId: number;
  itemImgUrl: string;
  itemCreatedDate: string;
  itemtotalToPayAmount: number;
}

export interface PayloadItem {
  merchant: number;
  payment_file_url: string;
  paid: number;
}

export interface Payload {
  merchant_reconciliation_payment: PayloadItem;
}

export interface RepayReconciliationModalProps {
  isOpen: boolean;
  onClose: () => void;
  fetchItems: () => void;
  url: string;
  getUrl: string;
  payload: Payload;
  title: string;
  confirmationMessage: string;
  items: Item[];
  merchantId: number;
}

export interface RepayReconciliationModalState {
  paidAmount: number;
  totalToPayAmount: number;
  company: string;
  error: boolean;
  errText: string;
  payload: Payload;
  imgUrl: string;
}

class RepayReconciliationModal extends React.Component<
  RepayReconciliationModalProps,
  RepayReconciliationModalState
> {
  constructor(props: RepayReconciliationModalProps) {
    super(props);

    this.state = {
      paidAmount: 0,
      totalToPayAmount: 0,
      company: '',
      imgUrl: '',
      error: false,
      errText: 'Необходимо прикрепить документ!',
      payload: props.payload,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (!this.props.items) {
      await this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    const { merchantId, getUrl } = this.props;

    const data = await api.doGet(`${getUrl}?merchant[eq]=${merchantId}`);

    if (data.payload.merchant_reconciliation_history.length > 0) {
      this.setState({
        totalToPayAmount:
          data.payload.merchant_reconciliation_history[0].total_to_pay,
        company: data.payload.merchant_reconciliation_history[0].company,
      });
    }
  }

  onSubmit(itemId) {
    const { paidAmount, imgUrl } = this.state;

    this.setState(
      {
        payload: {
          merchant_reconciliation_payment: {
            merchant: itemId,
            paid: paidAmount,
            payment_file_url: imgUrl,
          },
        },
      },
      () => {
        api.doPost(this.props.url, this.state.payload).then(() => {
          this.props.onClose();
          this.props.fetchItems();
        });
      },
    );
  }

  setImageUrl = urls => {
    this.setState({ imgUrl: urls[0] });
  };

  handleChange = event => {
    // const { totalToPayAmount } = this.state;
    if (event.target.value > 999999999999) {
      this.setState({
        error: true,
        errText: 'Максимальное количество символов 12!',
      });
    } else if (event.target.value < 0) {
      this.setState({
        error: true,
        errText: 'Необходимо ввести положительное число!',
      });
    } else {
      this.setState({ paidAmount: event.target.value, error: false });
    }
  };

  render() {
    const { isOpen, onClose, merchantId } = this.props;
    const { error, errText, paidAmount, company, imgUrl } = this.state;
    const newFileInputProps = { accept: 'application/pdf' };

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        maxWidth="sm"
        disableBackdropClick
      >
        {/* <DialogTitle id="scroll-dialog-title" style={{ textAlign: 'center' }}>
          {'Для завершения реконсиляции нужно прикрепить документ'}
        </DialogTitle> */}
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          {company ? (
            <>
              <Typography
                variant="subtitle1"
                style={{ textAlign: 'center', marginBottom: 20 }}
              >
                {company}
              </Typography>
              <DialogContentText id="alert-dialog-description">
                <div style={{ display: 'block', textAlign: 'center' }}>
                  <Typography
                    style={{ marginRight: 12, display: 'inline-block' }}
                  >
                    Введите оплаченную сумму
                  </Typography>
                  <Input
                    id="component-simple"
                    value={paidAmount}
                    type="number"
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
                {error && <Typography color="error">{errText}</Typography>}
              </DialogContentText>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <Typography
                  style={{ marginRight: 12, display: 'inline-block' }}
                >
                  Прикрепить документ
                </Typography>
                <FileFiled
                  ids={imgUrl ? [imgUrl] : []}
                  onChange={urls => this.setImageUrl(urls)}
                  maxItemsCount={1}
                  fileInputProps={newFileInputProps}
                />
              </div>
              <Button
                disabled={!imgUrl}
                onClick={() => this.onSubmit(merchantId)}
                autoFocus
                style={{
                  alignSelf: 'center',
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Завершить
              </Button>
            </>
          ) : (
            <Typography style={{ textAlign: 'center' }}>
              Нет данных для отображения
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} size={'large'}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default RepayReconciliationModal;
