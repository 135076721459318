import {
  ADDRESS_EN_KEY,
  ADDRESS_RU_KEY,
  EMAIL_KEY,
  MAP_URL_KEY,
  PHONE_KEY,
} from '@esentai/core/features/applicationSettings/consts/keys';
import { TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import ButtonSet from '@/components/ButtonSet';
import FormikPropTypes from '@/prop-types/formik';

const fieldNames = {
  [ADDRESS_EN_KEY]: 'адрес на английском',
  [ADDRESS_RU_KEY]: 'адрес на русском',
  [EMAIL_KEY]: 'email',
  [MAP_URL_KEY]: 'URL карты',
  [PHONE_KEY]: 'телефон',
};

class EditContactsForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
  };

  render() {
    const { classes, Field, Form, RequiredMessages, SubmitButton } = this.props;

    return (
      <Form>
        <div className={classes.section}>
          <Typography variant="h5" color="secondary">
            Адрес
          </Typography>
          <Field
            Target={TextField}
            id={ADDRESS_RU_KEY}
            name={ADDRESS_RU_KEY}
            helperText="На русском"
            fullWidth
          />
          <Field
            Target={TextField}
            id={ADDRESS_EN_KEY}
            name={ADDRESS_EN_KEY}
            helperText="На английском"
            fullWidth
          />
        </div>

        <div className={classes.section}>
          <Typography variant="h5" color="secondary">
            Телефон
          </Typography>
          <Field Target={TextField} id={PHONE_KEY} name={PHONE_KEY} fullWidth />
        </div>

        <div className={classes.section}>
          <Typography variant="h5" color="secondary">
            Электронная почта
          </Typography>
          <Field Target={TextField} id={EMAIL_KEY} name={EMAIL_KEY} fullWidth />
        </div>

        <div className={classes.section}>
          <Typography variant="h5" color="secondary">
            Ссылка на Яндекс.Карты
          </Typography>
          <Field
            Target={TextField}
            id={MAP_URL_KEY}
            name={MAP_URL_KEY}
            fullWidth
          />
        </div>

        <ButtonSet>
          <RequiredMessages fieldNames={fieldNames} />
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            Сохранить изменения
          </SubmitButton>
        </ButtonSet>
      </Form>
    );
  }
}

export default EditContactsForm;
