import { withStyles } from '@material-ui/core';

import { createPageFilterTemplate } from '@/structs/pageFilter';
import { createAnyOfAdapter } from '@/structs/pageFilterAdapter';

import SearchInputs from './component';
import styles from './styles';

export const createIncludeFilter = createPageFilterTemplate({
  FilterComponent: withStyles(styles)(SearchInputs),
  FormatComponent: ({ value }) => value.join(', '),
  adapter: createAnyOfAdapter([]),
});

export default SearchInputs;
