import { connect } from 'react-redux';

import { toggleActivity } from '@/features/personnel/actions';
import {
  isCurrentUser,
  isPersonnelActive,
} from '@/features/personnel/selectors';

import ToggleStatusButton from './component';

const mapStateToProps = (state, { personnelId }) => ({
  active: isPersonnelActive(state, personnelId),
  me: isCurrentUser(state, personnelId),
});

const mapDispatchToProps = (dispatch, { personnelId }) => ({
  toggleStatus: () => dispatch(toggleActivity(personnelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleStatusButton);
