import { getBannerStoreId } from '@esentai/core/features/campaigns/banner/selectors';
import { findStoreById } from '@esentai/core/features/stores/actions';
import { isStoreLoading } from '@esentai/core/features/stores/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import StoreReference from '@/features/campaignCreatePage/EditPage/SecondStepBanner/StoreInlineSearch/Suggestion';
import withItemApiRunner from '@/hocs/withItemApiRunner';

const mapStateToProps = (state, { itemId }) => {
  const storeId = getBannerStoreId(state, itemId);

  return {
    isLoading: isStoreLoading(state, storeId),
    itemId: storeId,
  };
};

const mapDispatchToProps = { fetchItem: findStoreById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(StoreReference);
