import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import Description from '@/features/parkingWhitelistDynamicFilterPage/Page/Description';
import { ROUTE_PATH as ROUTE_PARKING_WHITELIST_PATH } from '@/features/parkingWhitelistPage/consts';

class ParkingWhitelistDynamicFilterPage extends Component {
  static propTypes = {
    // error: PropTypes.any,
    parkingWhitelistFilterIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    // isLoading: PropTypes.bool.isRequired,
    // totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const {
      // error,
      parkingWhitelistFilterIds,
      // isLoading,
      // totalCount,
      classes,
    } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={ROUTE_PARKING_WHITELIST_PATH}>
              Whitelist Тимирязева
            </Breadcrumb>
          </Breadcrumbs>
          <PageTitle gutterBottom>Динамичный список</PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper} />

          <Description itemId={parkingWhitelistFilterIds[0]} />
        </PageContent>
      </Page>
    );
  }
}

export default ParkingWhitelistDynamicFilterPage;
