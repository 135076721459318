import {
  BONUS_NAME_KEY,
  COMMENT_KEY,
  END_DATE_KEY,
  PERCENT_KEY,
  START_DATE_KEY,
  STORES_KEY,
} from '@esentai/core/features/mallBonuses/consts/keys';
import { isValid, startOfDay } from 'date-fns';
import { array, date, number, object, string } from 'yup';

const today = startOfDay(new Date());

const asyncOptions = { runnerMethod: 'onSubmit' };
const formikOptions = {
  mapPropsToValues: ({
    comment,
    startDate,
    endDate,
    percent,
    stores,
    bonusName,
  }) => ({
    [COMMENT_KEY]: comment,
    [START_DATE_KEY]: startDate,
    [END_DATE_KEY]: endDate,
    [PERCENT_KEY]: percent,
    [STORES_KEY]: stores,
    [BONUS_NAME_KEY]: bonusName,
  }),

  validationSchema: object({
    [COMMENT_KEY]: string()
      .min(9, 'Минимальное количество символов 9')
      .max(201, 'Максимальное количество символов 201')
      .required(),
    [START_DATE_KEY]: date()
      .min(today, 'Бонус не может начинать действовать в прошедших числах')
      .required(),
    [END_DATE_KEY]: date().when(START_DATE_KEY, (dateStart, schema) => {
      const message = 'Дата завершения не может быть раньше, чем дата начала';

      return isValid(dateStart) ? schema.min(dateStart, message) : schema;
    }),
    [STORES_KEY]: array()
      .of(number())
      .required(),
    [PERCENT_KEY]: number()
      .max(20, 'Процент может быть не более 20')
      .required(),
    [BONUS_NAME_KEY]: string().required(),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};
const errorsMapping = {};

export default { asyncOptions, formikOptions, errorsMapping };
