import { SINGLE_RECEIPT_ITEMS_COUNT_KEY } from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { Input, InputAdornment, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldDescription from '@/components/FieldDescription';
import pluralize from '@/utils/pluralize';

const itemsLabel = purchases =>
  pluralize(purchases, {
    one: 'товара',
    few: 'товаров',
    many: 'товаров',
  });

const SingleReceiptItemsCountForm = ({
  classes,
  singleReceiptItemsCount,
  Field,
  disabled,
}) => (
  <div>
    <FieldDescription
      title="Количество товаров в покупке"
      desc="Пользователь активирует триггер, если в совершенной за период действия кампании покупке будет указанное количество товаров."
    />
    <Typography className={classes.fromLabel}>от</Typography>{' '}
    <Field
      disabled={disabled}
      type="number"
      Target={Input}
      name={SINGLE_RECEIPT_ITEMS_COUNT_KEY}
      endAdornment={
        <InputAdornment position="end">
          {itemsLabel(singleReceiptItemsCount)}
        </InputAdornment>
      }
    />
  </div>
);

SingleReceiptItemsCountForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  singleReceiptItemsCount: PropTypes.any.isRequired,
  Field: PropTypes.func.isRequired,
};

export default SingleReceiptItemsCountForm;
