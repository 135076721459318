import { TableCell, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import { canUpdateStore } from '@/features/storesPage/permissions';
import { dateTimeFormatNoSecs, getCuttedText } from '@/utils/format';

import Actions from './Actions';
import CategoriesCell from './CategoriesTitle';

const Row = ({
  active,
  categoryKeys,
  classes,
  creationDate,
  name,
  storeId,
  storePageUrl,
  SelectionToggle,
}) => (
  <TableRow
    className={classNames({
      [classes.dimmed]: !active,
    })}
  >
    <TableCell padding="checkbox">
      <SelectionToggle item={storeId} />
    </TableCell>
    <CondenseTableCell>
      <Link to={storePageUrl} title={name}>
        {getCuttedText(name, 25)}
      </Link>
    </CondenseTableCell>

    <CondenseTableCell>
      <CategoriesCell categoryKeys={categoryKeys} />
    </CondenseTableCell>

    <CondenseTableCell>{dateTimeFormatNoSecs(creationDate)}</CondenseTableCell>

    <CanView permission={canUpdateStore} storeId={storeId}>
      <CondenseTableCell>
        <Actions storeId={storeId} />
      </CondenseTableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  active: PropTypes.bool.isRequired,
  categoryKeys: PropTypes.arrayOf(PropTypes.string),
  creationDate: PropTypes.instanceOf(Date).isRequired,
  name: PropTypes.string.isRequired,
  SelectionToggle: PropTypes.func.isRequired,
  storeId: PropTypes.any.isRequired,
  storePageUrl: PropTypes.string.isRequired,
};

Row.defaultProps = {
  categoryKeys: [],
  brandsIds: [],
};

export default Row;
