import { fade } from '@material-ui/core/styles/colorManipulator';

import { randString } from '@/utils/rand';

const animationName = `cssProgress${randString()}`;

export default theme => ({
  buttonSet: {
    '&:first-child': {
      marginLeft: -8,
    },
    '&:not(:last-child)': {
      marginRight: 8,
    },
  },
  deleteIcon: {
    zIndex: 1,
    '$hasError &': {
      color: '#fff',
      '&:hover': {
        color: fade('#fff', 0.7),
      },
    },
  },

  chip: {
    overflow: 'hidden',
    position: 'relative',
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  label: {
    zIndex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '32ch',
    display: 'inline-block',
  },
  chipProgress: {
    content: "''",
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
    borderRadius: 'inherit',
    right: '100%',
    background: '#2badbe',
    animation: {
      name: animationName,
      duration: '2s',
      'timing-function': 'linear',
      'iteration-count': 'infinite',
    },
    transition: 'transform 100ms',
    backgroundImage: `linear-gradient(${[
      '-45deg',
      'rgba(0, 0, 0, .1) 25%',
      'transparent 25%',
      'transparent 50%',
      'rgba(0, 0, 0, .1) 50%',
      'rgba(0, 0, 0, .1) 75%',
      'transparent 75%',
      'transparent',
    ].join(', ')})`,
    backgroundSize: [35, 35],
  },
  [`@keyframes ${animationName}`]: {
    from: {
      backgroundPosition: [0, 0],
    },
    to: {
      backgroundPosition: [35, 35],
    },
  },
  hasError: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    '&:focus': {
      backgroundColor: fade(theme.palette.error.main, 0.8),
    },
  },
  isFinished: {
    backgroundColor: '#8BC34A',
    color: '#fff',
    '&:focus': {
      backgroundColor: fade('#8BC34A', 0.8),
    },
  },
});
