import { getBinForMerchant } from '@esentai/core/features/merchants/selectors';
import { getNameForStore } from '@esentai/core/features/stores/selectors';
import { connect } from 'react-redux';

import Option from '@/components/AutocompleteBase/Option';

const mapStateToProps = (state, { value }) => ({
  label: getNameForStore(state, value.store),
  bin: getBinForMerchant(state, value.merchant),
});

export default connect(mapStateToProps, () => ({}))(Option);
