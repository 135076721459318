import { __, anyPass } from 'ramda';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import {
  canCreateBenefit,
  canDeleteBenefit,
  canReadBenefitStatistics,
  canUpdateBenefit,
} from './permissions';

export const {
  getError: getBenefitsError,
  getItemsIds: getBenefitsIds,
  getItemsTotalCount: getBenefitsTotalCount,
  isLoadingItems: isLoadingBenefits,
} = itemsDuck.selectors;

export const {
  getFilters,
  getPage,
  getLimit,
  getSort,
  getQuery,
} = queryDuck.selectors;

export const getPermissionForActions = anyPass([
  canCreateBenefit,
  canUpdateBenefit,
  canDeleteBenefit,
  canReadBenefitStatistics,
])(__, null);
