import { getCampaignType } from '@esentai/core/features/campaigns/selectors';
import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { createFormStepURL } from '@/features/campaignCreatePage/utils';
import { canUpdateCampaign } from '@/features/campaignsPage/permissions';

const mapStateToProps = (state, { itemId }) => ({
  editPageUrl: createFormStepURL(getCampaignType(state, itemId), itemId, 1),
  permission: canUpdateCampaign,
});

export default connect(mapStateToProps)(EditButton);
