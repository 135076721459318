export default theme => ({
  wrap: {
    width: '100%',
    maxWidth: 650,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 700,
  },
  imageControl: {
    display: 'flex',
  },
  hidden: {
    display: 'none',
  },
  checkbox: {
    width: 40,
  },
  drop: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: '15px 15px',
    fontFamily: 'Roboto',
    textAlign: 'center',
    width: '100%',
  },
});
