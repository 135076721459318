import { connect } from 'react-redux';

import StatisticsTopBar from '@/components/StatisticsTopBar';
import { updateFilters } from '@/features/anonymousPurchasesStatisticsPage/actions';
import { getFilters } from '@/features/anonymousPurchasesStatisticsPage/selectors';
import stats from '@/features/anonymousPurchasesStatisticsPage/stats';
import {
  MAX_STATISTICS_DATE,
  MIN_STATISTICS_DATE,
} from '@/features/purchasesStatistics/consts/dates';

const mapStateToProps = state => ({
  filters: getFilters(state),
  stats,
  minDate: MIN_STATISTICS_DATE,
  maxDate: MAX_STATISTICS_DATE,
});

const mapDispatchToProps = { updateFilters };

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsTopBar);
