import api from '@esentai/core/features/reconciliation/api';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

import Breadcrumbs, { Breadcrumb } from '../../../../components/Breadcrumbs';
import NoMatchMessage from '../../../../components/CommonTable/NoMatchMessage';
import Link from '../../../../components/Link';
import Page from '../../../../components/Page';
import PageContent from '../../../../components/PageContent';
import PageHeader from '../../../../components/PageHeader';
import PageTitle from '../../../../components/PageTitle';
import { createMerchantDetailsPageUrl } from '../../../merchantsReconciliationDetailsPage/utils';
import { ROUTE_PATH as RECONCILIATIONS_PATH } from '../../../reconciliationPage/consts';

interface RowData {
  id: number;
  operation: string;
  comment: string;
  user_id: number;
  user: string;
  new_data: any; // TODO: Define a generic for this
  record_created: string;
  merchant_id: number;
  company: string;
  store_name: string;
}

export interface ActiveReconciliationProps {
  model: string;
  storeId: number;
  storeName: string;
  headers: string[];
  children: (data: any) => JSX.Element;
  totalCount: number;
}

export interface ActiveReconciliationState {
  limit: number;
  page: number;
  data: RowData[];
  total: number;
}

const styles = {
  tbody: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
};

export class ActiveReconciliation extends React.Component<
  ActiveReconciliationProps,
  ActiveReconciliationState
> {
  constructor(props: ActiveReconciliationProps) {
    super(props);

    this.state = {
      limit: 10,
      page: 0,
      total: 0,
      data: [],
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.loadData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.totalCount !== prevProps.totalCount) {
      this.loadData();
    }
  }

  public render(): JSX.Element {
    const { headers, storeName } = this.props;
    const { data } = this.state;
    const store =
      storeName || (data && data.length > 0 ? data[0].store_name : '');

    return (
      <Page>
        <PageHeader gutterBottom={false}>
          <Breadcrumbs>
            <Breadcrumb to={RECONCILIATIONS_PATH}>
              Отчет по реконсиляции
            </Breadcrumb>
          </Breadcrumbs>
          <PageTitle>Детализация по магазину {store}</PageTitle>
        </PageHeader>
        <PageContent>
          {data && data.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                {data.length > 1 ? 'Арендаторы' : 'Арендатор'}:
              </Typography>

              {data.map(row => (
                <Typography key={row.id} variant="subtitle1">
                  <Link to={createMerchantDetailsPageUrl(row.merchant_id)}>
                    {row.company}
                  </Link>
                </Typography>
              ))}
            </div>
          )}
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={styles.tbody}>{this.renderBody(data)}</TableBody>
          </Table>
        </PageContent>
      </Page>
    );
  }

  public renderBody(data: any[]): JSX.Element {
    const { children } = this.props;

    if (data.length > 0) {
      return children(data);
    }

    return <NoMatchMessage columnsCount={100} />;
  }

  private async loadData(): Promise<void> {
    const { storeId } = this.props;
    const filters = [['store', 'eq', storeId]];

    const data = await api.doQuery(filters);

    if (data.payload.reconciliation.length > 0) {
      this.setState({
        total: data.meta.total,
        data: data.payload.reconciliation,
      });
    }
  }
}
