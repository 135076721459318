import { Fade, Paper, Popper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React from 'react';

const TooltipPopper = ({ classes, anchorEl, selectedDateIndex, children }) => (
  <Popper
    className={classes.popper}
    open={!isNil(selectedDateIndex)}
    anchorEl={anchorEl.current}
    placement="right"
    transition
    modifiers={{
      flip: {
        enabled: true,
      },
      preventOverflow: {
        padding: 205,
        enabled: true,
        boundariesElement: 'scrollParent',
      },
    }}
  >
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={200}>
        <Paper className={classes.paper} square>
          {children}
        </Paper>
      </Fade>
    )}
  </Popper>
);

TooltipPopper.propTypes = {
  anchorEl: PropTypes.shape({ current: PropTypes.object }).isRequired,
  selectedDateIndex: PropTypes.number.isRequired,
};

export default TooltipPopper;
