import createQueryDuck from '@/redux/query';

import { FEATURE_NAME } from '../consts';
import serializer from '../serializer';
import { getQuery } from '../utils';

export default createQueryDuck(FEATURE_NAME, {
  getRoot: getQuery,
  serializer,
});
