export const ID_KEY = 'id';
export const ORDER_ID_KEY = 'order.id';
export const ORDER_CUSTOMER_ID_KEY = 'customer.id';
export const ORDER_CUSTOMER_LEVEL_KEY = 'order.customer.level';
export const BUILDING_KEY = 'building';
export const RECORD_CREATED_KEY = 'recordCreated';
export const EXIT_DATETIME_KEY = 'exitDatetime';
export const ORDER_AMOUNT_KEY = 'order.amount';
export const ORDER_STATUS_KEY = 'order.result';
export const ORDER_DEBT_KEY = 'debtAmount';
export const ORDER_ARRIVAL_KEY = 'order.arrival';
export const VEHICLE_GOV_KEY = 'vehicleGov';
export const VEHICLE_GOV_NUMBER_KEY = 'vehicleGovNumber';
export const VEHICLE_MODEL_KEY = 'vehicleModel';
export const EXIT_DATETIME = 'exit_datetime';
export const WITH_BILLING = 'with_billing';
export const CALCULATE = 'calculate';
export const DISCOUNT_TYPE_KEY = 'discount_type';
export const TOTAL_HOURS_KEY = 'discount_details.total_hours';
export const CHARGED_FROM_EFS_BALANCE_KEY = 'chargedFromEfsBalance';

export const ORDER_IS_PAID = '1';
export const ORDER_IS_NOT_PAID = '0';
export const ORDER_IN_PROCESS = '';

export const carFilters = [VEHICLE_GOV_NUMBER_KEY, VEHICLE_MODEL_KEY];

export const dateFilters = [RECORD_CREATED_KEY, EXIT_DATETIME_KEY];

export const orderStatus = status => {
  const mapping = {
    [ORDER_IS_PAID]: 'Оплачено',
    [ORDER_IS_NOT_PAID]: 'Не оплачено',
    [ORDER_IN_PROCESS]: 'В процессе',
  };

  return mapping[status];
};
