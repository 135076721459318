import { ButtonBase } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { pick } from 'ramda';
import React from 'react';

const AddButton = ({ classes, children, ...rest }) => (
  <ButtonBase {...rest} classes={pick(['root'], classes)} disableTouchRipple>
    <AddIcon className={classes.icon} />
    {children}
  </ButtonBase>
);

export default AddButton;
