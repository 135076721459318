import {
  ARTICLE_TYPE,
  CALENDAR_EVENT_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/purchaseResult/consts/destinations';
import {
  DESTINATION_KEY,
  REFERRED_BENEFIT_ID_KEY,
  REFERRED_STORE_ID_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit, CanEditRadioBoxGroup } from '../../CanEdit';
import BenefitInlineSearch from '../../SecondStepBanner/BenefitInlineSearch';
import CampaignInlineSearch from '../../SecondStepBanner/CampaignInlineSearch';
import StoreInlineSearch from '../../SecondStepBanner/StoreInlineSearch';

class DestinationForm extends Component {
  static propTypes = FormikPropTypes;

  toggleDestination = () => {
    const { values } = this.props;
    const destination = values[DESTINATION_KEY];

    if (destination) {
      this.clearDestination();
    } else {
      this.setDestination(ARTICLE_DESTINATION);
    }
  };

  clearDestination = () => {
    this.setDestination(null);
  };

  handleDestinationChange = event => {
    const { value } = event.target;

    this.setDestination(value);
  };

  setDestination = value => {
    const { setFieldValue } = this.props;

    setFieldValue(DESTINATION_KEY, value);
  };

  setReferredCampaignId = (key, value) => {
    const { setFieldValue } = this.props;

    setFieldValue(key, value);
  };

  setReferredBenefitId = value => {
    const { setFieldValue } = this.props;

    setFieldValue(REFERRED_BENEFIT_ID_KEY, value);
  };

  setStoreId = value => {
    const { setFieldValue } = this.props;

    setFieldValue(REFERRED_STORE_ID_KEY, value);
  };

  render() {
    const { values } = this.props;
    const destination = values[DESTINATION_KEY];

    return (
      <FieldRow
        label="Точка назначения"
        subLabel="Является ли пуш-сообщение ссылкой?"
        sudDesc="Пуш-сообщение может быть просто рекламным сообщением, отображающим какую-то информацию самодостаточно само по себе. Либо оно может вести на статью или экран магазина."
      >
        <CanEdit field={DESTINATION_KEY}>
          <FormControlLabel
            label="Пуш-сообщение ссылается на другую сущность в приложении"
            control={
              <Checkbox
                checked={Boolean(destination)}
                onChange={this.toggleDestination}
              />
            }
          />
        </CanEdit>

        {destination ? (
          <div>
            <FieldDescription
              title="Точка назначения пуш-сообщения"
              desc="Укажите, куда именно ссылается баннер."
            />
            <CanEditRadioBoxGroup field={REFERRED_STORE_ID_KEY}>
              <RadioBoxGroup
                name={REFERRED_STORE_ID_KEY}
                value={destination}
                onChange={this.handleDestinationChange}
              >
                <RadioBox
                  value={ARTICLE_DESTINATION}
                  label="Статья"
                  helperText="Баннер будет ссылаться на другое, уже существующее и опубликованное, рекламное размещение-статью."
                />
              </RadioBoxGroup>
            </CanEditRadioBoxGroup>
            <CanEdit field={REFERRED_STORE_ID_KEY}>
              {destination === ARTICLE_DESTINATION ? (
                <CampaignInlineSearch
                  type={ARTICLE_TYPE}
                  values={values}
                  onSelect={this.setReferredCampaignId}
                />
              ) : null}

              {destination === BENEFIT_DESTINATION ? (
                <BenefitInlineSearch
                  value={values[REFERRED_BENEFIT_ID_KEY]}
                  onSelect={this.setReferredBenefitId}
                />
              ) : null}

              {destination === CALENDAR_EVENT_DESTINATION ? (
                <CampaignInlineSearch
                  type={CALENDAR_EVENT_TYPE}
                  values={values}
                  onSelect={this.setReferredCampaignId}
                />
              ) : null}

              {destination === STORE_DESTINATION ? (
                <StoreInlineSearch
                  value={values[REFERRED_STORE_ID_KEY]}
                  onSelect={this.setStoreId}
                />
              ) : null}
            </CanEdit>
          </div>
        ) : null}
      </FieldRow>
    );
  }
}

export default DestinationForm;
