import {
  PUSH_ENGLISH_MESSAGE_KEY,
  PUSH_ENGLISH_TITLE_KEY,
  PUSH_RUSSIAN_MESSAGE_KEY,
  PUSH_RUSSIAN_TITLE_KEY,
  SEND_PUSH_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { mapProps } from 'recompose';

import PushForm from './component';
import styles from './styles';

export default compose(
  mapProps(({ setFieldValue, values, Field }) => ({
    pushEnglishTitle: values[PUSH_ENGLISH_TITLE_KEY],
    pushRussianTitle: values[PUSH_RUSSIAN_TITLE_KEY],
    pushEnglishMessage: values[PUSH_ENGLISH_MESSAGE_KEY],
    pushRussianMessage: values[PUSH_RUSSIAN_MESSAGE_KEY],
    sendingPush: values[SEND_PUSH_KEY],
    setFieldValue,
    Field,
  })),
  withStyles(styles),
)(PushForm);
