// Metrics
//
// DO NOT USE THESE KEYS FOR FILTERING.
// ONLY DISPLAYING AND SORTING ALLOWED.
//
// If you need fields for filtering, see 'dimensions.js'

export { TIMEFRAME_KEY } from '@/features/statistics/consts/keys';

export const ACTIVATIONS_COUNT_KEY = 'nr_activations';

export const CLICKS_COUNT_KEY = 'nr_clicked';

export const CLICKS_PERCENTAGE_BY_ORANGE_LEVELED_USERS_KEY =
  'percent_clicked_level_one';

export const CLICKS_PERCENTAGE_BY_BLACK_LEVELED_USERS_KEY =
  'percent_clicked_level_two';

export const CLICKS_PERCENTAGE_BY_JETBLACK_LEVELED_USERS_KEY =
  'percent_clicked_level_three';

export const CLICKS_PERCENTAGE_BY_SILVER_LEVELED_USERS_KEY =
  'percent_clicked_silver_level';

export const CLICKS_PERCENTAGE_BY_WHITE_LEVELED_USERS_KEY =
  'percent_clicked_white_level';

export const CONVERSION_KEY = 'conversion';

export const COVERAGE_KEY = 'nr_coverage';

export const EFFICIENCY_KEY = 'efficiency';

export const PRE_PUSH_CLICKS_COUNT_KEY = 'nr_clicked_prepush';

export const PUSH_CLICKS_COUNT_KEY = 'nr_clicked';

export const SCROLLS_COUNT_TO_25_PERCENT_KEY = 'nr_scrolled_25_percents';

export const SCROLLS_COUNT_TO_50_PERCENT_KEY = 'nr_scrolled_50_percents';

export const SCROLLS_COUNT_TO_75_PERCENT_KEY = 'nr_scrolled_75_percents';

export const SCROLLS_COUNT_TO_100_PERCENT_KEY = 'nr_scrolled_100_percents';

export const SEGMENT_KEY = 'nr_segment';

export const VIEWS_COUNT_KEY = 'nr_views';
