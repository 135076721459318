import { connect } from 'react-redux';

import { getArticleLayoutWithRename } from '@/features/campaignCreatePage/selectors';

import Layout from './component';

const mapStateToProps = (state, { itemId }) => ({
  layout: getArticleLayoutWithRename(state, itemId),
});

export default connect(mapStateToProps)(Layout);
