import { FEATURE_NAME } from './consts';

export const DELETE_CATEGORY = `${FEATURE_NAME}/DELETE_CATEGORY`;

export const MOVE_CATEGORY_DOWN = `${FEATURE_NAME}/MOVE_CATEGORY_DOWN`;

export const MOVE_CATEGORY_UP = `${FEATURE_NAME}/MOVE_CATEGORY_UP`;

export const CREATE_CATEGORY = `${FEATURE_NAME}/CREATE_CATEGORY`;

export const UPDATE_CATEGORY = `${FEATURE_NAME}/UPDATE_CATEGORY`;

export const RETRY_FETCH = `${FEATURE_NAME}/RETRY_FETCH`;
