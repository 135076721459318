import {
  BOUNCE_TRIGGER,
  LEAD_TRIGGER,
  OPEN_APP_TRIGGER,
  REGISTRATION_TRIGGER,
  VISITOR_TRIGGER,
} from '@esentai/core/features/campaigns/actionResult/consts/triggers';
import {
  ARTICLE_DESTINATION,
  BENEFIT_DESTINATION,
  CALENDAR_EVENT_DESTINATION,
  ONLINE_SERVICE_DESTINATION,
  STORE_DESTINATION,
} from '@esentai/core/features/campaigns/banner/consts/destinations';
import {
  MAIN_PAGE_TYPE,
  POPUP_TYPE,
  STORE_LIST_TYPE,
} from '@esentai/core/features/campaigns/banner/consts/types';
import {
  HIGH_PRIORITY,
  LOW_PRIORITY,
  MEDIUM_PRIORITY,
  VERY_HIGH_PRIORITY,
  VERY_LOW_PRIORITY,
} from '@esentai/core/features/campaigns/consts/priorities';
import {
  BENEFITS_REWARD,
  LOYALTY_POINTS_REWARD,
} from '@esentai/core/features/campaigns/purchaseResult/consts/rewards';
import {
  ALL_FEED,
  MAIN_FEED,
  PSS_FEED,
} from '@esentai/core/features/campaigns/show/consts/popupPlacementTypes';

export const actionResultTrigger = trigger => {
  const labels = {
    [BOUNCE_TRIGGER]: 'Отказ',
    [LEAD_TRIGGER]: 'Лид',
    [OPEN_APP_TRIGGER]: 'Открыто приложение',
    [REGISTRATION_TRIGGER]: 'Регистрация',
    [VISITOR_TRIGGER]: 'Посетитель',
  };

  return labels[trigger] || null;
};

export const destination = destinationType => {
  const labels = {
    [ARTICLE_DESTINATION]: 'статью',
    [BENEFIT_DESTINATION]: 'бенефит',
    [CALENDAR_EVENT_DESTINATION]: 'календарное событие',
    [ONLINE_SERVICE_DESTINATION]: 'онлайн-сервис',
    [STORE_DESTINATION]: 'магазин',
  };

  return labels[destinationType] || null;
};

export const placement = placementType => {
  const labels = {
    [ALL_FEED]: 'Все страницы',
    [MAIN_FEED]: 'Главная страница',
    [PSS_FEED]: 'Онлайн витрина',
  };

  return labels[placementType] || null;
};

export const reward = rewardType => {
  const labels = {
    [BENEFITS_REWARD]: 'бенефиты',
    [LOYALTY_POINTS_REWARD]: 'бонусы',
  };

  return labels[rewardType] || null;
};

export const bannerType = type => {
  const labels = {
    [MAIN_PAGE_TYPE]: 'Главный баннер',
    [STORE_LIST_TYPE]: 'Каталог магазинов',
    [POPUP_TYPE]: 'Popup-баннер',
  };

  return labels[type] || null;
};

export const priority = priorityType => {
  const labels = {
    [VERY_LOW_PRIORITY]: 'Очень низкий',
    [LOW_PRIORITY]: 'Низкий',
    [MEDIUM_PRIORITY]: 'Средний',
    [HIGH_PRIORITY]: 'Высокий',
    [VERY_HIGH_PRIORITY]: 'Очень высокий',
  };

  return labels[priorityType] || null;
};
