import { IconButton, TableCell, TableRow } from '@material-ui/core';
import { DeleteForever, GetApp } from '@material-ui/icons';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

const Row = props => {
  const { id, name, record_created } = props.item;

  const deleteFile = fileId => {
    window.documentsPageData.deleteDocument(fileId);
  };

  const uploadFile = fileId => {
    window.documentsPageData.uploadDocument(fileId);
  };

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>
        {record_created
          ? format(new Date(`${record_created}Z`), 'dd-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>
        <IconButton size="medium" onClick={() => uploadFile(id)}>
          <GetApp />
        </IconButton>
        {window.documentsPageData.isMallAdministrator && (
          <IconButton size="medium" onClick={() => deleteFile(id)}>
            <DeleteForever />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
