import Resource from '@esentai/core/resources/base';
import { path } from 'ramda';

export class AuthResource extends Resource {
  signIn = ({ login, password }) => {
    const { apiInstance } = this;

    return apiInstance
      .fetcher({
        url: '/staff-sign-in',
        method: 'POST',
        data: { email: login, password },
      })
      .then(response => {
        apiInstance.token = response.data.auth_token;
      });
  };

  signOut = () => {
    const { apiInstance } = this;
    const { token } = apiInstance;

    if (!token) {
      return Promise.resolve();
    }

    return apiInstance.fetcher({ url: '/sign-out' }).then(() => {
      apiInstance.token = null;
    });
  };

  getCurrentUserId = () => {
    const { apiInstance } = this;

    return apiInstance.doGet('/user/current').then(path(['user', 0, 'id']));
  };

  setToken(value) {
    const { apiInstance } = this;

    apiInstance.token = value;
  }
}

export default new AuthResource();
