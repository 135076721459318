import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SidebarMenu from './SidebarMenu';
import UserInfo from './UserInfo';

class Sidebar extends Component {
  render() {
    const { classes, expanded } = this.props;

    return (
      <aside
        className={classNames(classes.sidebar, {
          [classes.sideExpanded]: expanded,
        })}
      >
        <div
          className={classNames(classes.wrapper, {
            [classes.wrapExpanded]: expanded,
          })}
        >
          <UserInfo />
          <SidebarMenu />
        </div>
      </aside>
    );
  }
}

Sidebar.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default Sidebar;
