import PropTypes from 'prop-types';
import React from 'react';

import Chip from '@/components/AutocompleteBase/Chip';
import ProductViewNameLazy from '@/containers/ProductViewNameLazy';
import { getCuttedText } from '@/utils/format';

const ProductViewChip = ({ value, ...props }) => (
  <Chip
    {...props}
    id={value}
    label={
      <ProductViewNameLazy
        productViewId={value}
        onCutText={getCuttedText}
        maxLength={40}
      />
    }
  />
);

ProductViewChip.propTypes = {
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default ProductViewChip;
