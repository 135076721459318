import { Typography } from '@material-ui/core';
import React from 'react';

const PageTitle = ({ classes, ...rest }) => (
  <Typography
    className={classes.pageTitle}
    gutterBottom
    variant="h2"
    {...rest}
  />
);

export default PageTitle;
