import {
  getBenefitAmount,
  getBenefitComment,
  getBenefitEndDatetime,
  getBenefitEnglishDescription,
  getBenefitKazakhDescription,
  getBenefitPercent,
  getBenefitRussianDescription,
  getBenefitStartDatetime,
  getBenefitStoreId,
  getBenefitTitle,
  getBenefitTitleEn,
  getBenefitTitleKz,
  getBenefitType,
} from '@esentai/core/features/benefits/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { goToStepTwo } from '@/features/benefitCreatePage/actions';
import { getBenefitId } from '@/features/benefitCreatePage/selectors';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import FirstStep from './component';
import form from './form';
import styles from './styles';

const mapStateToProps = state => {
  const id = getBenefitId(state);

  return {
    amount: getBenefitAmount(state, id),
    comment: getBenefitComment(state, id),
    dateStart: getBenefitStartDatetime(state, id),
    dateEnd: getBenefitEndDatetime(state, id),
    storeId: getBenefitStoreId(state, id),
    type: getBenefitType(state, id),

    title: getBenefitTitle(state, id),
    titleEn: getBenefitTitleEn(state, id),
    titleKz: getBenefitTitleKz(state, id),

    russianDescription: getBenefitRussianDescription(state, id),
    englishDescription: getBenefitEnglishDescription(state, id),
    kazakhDescription: getBenefitKazakhDescription(state, id),

    percent: getBenefitPercent(state, id),
  };
};

const mapDispatchToProps = { onSubmit: goToStepTwo };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncFormik(form),
  withStyles(styles),
)(FirstStep);
