import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import {
  create,
  decreasePriority,
  increasePriority,
  remove,
  update,
} from '@esentai/core/features/productCategory/sagas';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  MOVE_CATEGORY_DOWN,
  MOVE_CATEGORY_UP,
  RETRY_FETCH,
  UPDATE_CATEGORY,
} from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* deleteCategory(action) {
  const { payload: categoryId } = action;

  yield call(remove, categoryId);
  yield call(fetchItems);
}

export function* moveCategoryDown(action) {
  yield call(decreasePriority, action);
  yield call(fetchItems);
}

export function* moveCategoryUp(action) {
  yield call(increasePriority, action);
  yield call(fetchItems);
}

export function* createCategory(action) {
  const { payload: attributes } = action;

  yield call(create, attributes);
  yield call(fetchItems);
}

export function* updateCategory(action) {
  const { payload: attributes } = action;

  yield call(update, null, attributes);
  yield call(fetchItems);
}

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield takeEvery(CREATE_CATEGORY, createTaskSaga(createCategory));
  yield takeEvery(UPDATE_CATEGORY, createTaskSaga(updateCategory));
  yield takeEvery(DELETE_CATEGORY, createTaskSaga(deleteCategory));
  yield takeEvery(MOVE_CATEGORY_DOWN, createTaskSaga(moveCategoryDown));
  yield takeEvery(MOVE_CATEGORY_UP, createTaskSaga(moveCategoryUp));
}
