import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';

export const {
  getError: getParkingWhitelistFilterError,
  getItemsIds: getParkingWhitelistFilterIds,
  getItemsMeta: getParkingWhitelistFilterMeta,
  getItemsTotalCount: getParkingWhitelistFilterTotalCount,
  isLoadingItems: isLoadingParkingWhitelistFilter,
} = itemsDuck.selectors;

export const { getFilters, getPage, getLimit, getSort } = queryDuck.selectors;
