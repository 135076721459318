import {
  ACTIVE_STATUS,
  APPROVED_STATUS,
  ARCHIVED_STATUS,
  DRAFT_STATUS,
  PAUSED_STATUS,
} from '@esentai/core/features/campaigns/consts/statuses';

import { statusLabel } from '@/features/campaignsPage/labels';
import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';

export const options = [
  ACTIVE_STATUS,
  APPROVED_STATUS,
  PAUSED_STATUS,
  DRAFT_STATUS,
  ARCHIVED_STATUS,
].map(key => ({ key, label: statusLabel(key) }));

export const createCampaignStatusFilter = createCheckboxFilterTemplate({
  options,
});

export default CheckboxGroupFilter;
