import React from 'react';

const widthContentOnly = {
  width: '1px',
};

const ColGroup = () => (
  <colgroup>
    <col style={{ width: '170px' }} />
    <col style={widthContentOnly} />
    <col style={{ width: '200px' }} />
    <col style={{ width: '250px' }} />
    <col />
  </colgroup>
);

export default ColGroup;
