import { withProps } from 'recompose';

import { CampaignAutocomplete } from '@/containers/CampaignAutocomplete';
import Autocomplete from '@/filters/Autocomplete';

export default withProps(({ value, ...ownerProps }) => ({
  ...ownerProps,
  Component: CampaignAutocomplete,
  selectedItem: value,
  InputProps: {
    placeholder: 'Название компании...',
  },
}))(Autocomplete);
