import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import UserNameLazy from '@/containers/UserNameLazy';
import { formatShortHumanDateTime } from '@/utils/format';

const Row = ({ itemId, userId, storeName, dateUsed, title }) => (
  <TableRow>
    <TableCell>{itemId}</TableCell>
    <TableCell>
      <UserNameLazy userId={userId} />
    </TableCell>
    <TableCell>{title}</TableCell>
    <TableCell>{storeName}</TableCell>
    <TableCell>{formatShortHumanDateTime(dateUsed)}</TableCell>
  </TableRow>
);

Row.propTypes = {
  itemId: PropTypes.number.isRequired,
  dateUsed: PropTypes.instanceOf(Date).isRequired,
  userId: PropTypes.number.isRequired,
  storeName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Row;
