import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PageTitleLink from '@/components/PageTitleLink';
import { ROUTE_PATH } from '@/features/parkingPage/consts';
import { ROUTE_PATH as VALET_ROUTE_PATH } from '@/features/valetParkingPage/consts';

import { downloadXls } from '../utils';
import Table from './Table';

const TimiryazevParkingPage = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const { location, classes } = props;

  const loadTimiryazevParkingData = async (sort, filter) => {
    setLoading(true);

    const filters = {
      page,
      size: pageSize,
    };

    try {
      const response = await api.doGetParkingRequest('/timiryazev-passage', {
        ...filters,
        sort,
        filter,
      });

      setData(response.content);
      setTotalCount(response.total_elements);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const onSetPageSize = newPageSize => {
    setPage(0);
    setPageSize(newPageSize);
  };

  const onSetPage = newPage => {
    setPage(newPage);
  };

  const onSetParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filter = [];
    const sort = [];

    for (const param of searchParams) {
      let filterStr = '';

      const name = param[0];
      const value = param[1];
      const filteredName = name.replace('[gte]', '').replace('[lte]', '');

      // const isDate = dateFilters.includes(filteredName);
      const isDate = false;

      if (name !== 'sort') {
        if (name.indexOf('gte') !== -1) {
          const fieldValue = isDate
            ? moment(new Date(value))
                .utc()
                .format(`YYYYMMDDTHHmmss`)
            : value;

          filterStr = `${filteredName}>${fieldValue}`;
        } else if (name.indexOf('lte') !== -1) {
          const fieldValue = isDate
            ? moment(new Date(value))
                .utc()
                .format(`YYYYMMDDTHHmmss`)
            : value;

          filterStr = `${filteredName}<${fieldValue}`;
        } else {
          filterStr = `${filteredName}:${value}`;
        }
        filter.push(filterStr);
      } else {
        sort.push(value);
      }
    }

    return {
      sort,
      filter,
    };
  };

  // const onDownloadExcel = () => {
  //   const { sort, filter } = onSetParams();
  //
  //   downloadXls({ page, limit: pageSize, sort, filter });
  // };

  useEffect(() => {
    const { sort, filter } = onSetParams();

    loadTimiryazevParkingData(sort, filter);
  }, [pageSize, page, location.search]);

  return (
    <Page>
      <PageHeader gutterBottom={false}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle gutterBottom={false}>Тимирязева</PageTitle>
          <PageTitleLink to={ROUTE_PATH}>Паркинг(SPT)</PageTitleLink>
          <PageTitleLink to={VALET_ROUTE_PATH}>Valet</PageTitleLink>
        </Grid>
      </PageHeader>
      <PageContent className={classes.tableBlock}>
        <div className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            onClick={downloadXls}
          >
            Вывести в EXCEL
          </Button>
        </div>
        <Table
          limit={pageSize}
          items={data}
          isLoading={loading}
          totalCount={totalCount}
          page={page}
          updatePage={onSetPage}
          updateLimit={onSetPageSize}
        />
      </PageContent>
    </Page>
  );
};

TimiryazevParkingPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default TimiryazevParkingPage;
