import {
  CUSTOMER_AGE_KEY,
  CUSTOMER_AVERAGE_CHECK_KEY,
  CUSTOMER_BIRTH_DATE_KEY,
  CUSTOMER_GENDER_KEY,
  CUSTOMER_STATUS_KEY,
} from '@/features/locationsStatistics/consts/dimensions';
import { createBirthdayRangeFilter } from '@/filters/BirthdayRange';
import { createGenderFilter } from '@/filters/Gender';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createUserAgeFilter } from '@/filters/UserAge';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createUserStatusFilter({
    key: CUSTOMER_STATUS_KEY,
    label: 'Статус пользователя',
    quicklyAccessible: true,
  }),
  createGenderFilter({
    key: CUSTOMER_GENDER_KEY,
    label: 'Пол пользователя',
  }),
  createBirthdayRangeFilter({
    key: CUSTOMER_BIRTH_DATE_KEY,
    label: 'Дата рождения пользователя',
  }),
  createUserAgeFilter({
    key: CUSTOMER_AGE_KEY,
    label: 'Возраст пользователя',
  }),
  createMoneyRangeFilter({
    key: CUSTOMER_AVERAGE_CHECK_KEY,
    label: 'Средний чек пользователя',
  }),
];
