import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/loyaltyHistoryPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getLoyaltyHistoryError,
  getLoyaltyHistoryIds,
  getLoyaltyHistoryTotalCount,
  isLoadingHistory,
} from '../selectors';
import LoyaltyHistory from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getLoyaltyHistoryError(state),
  historyIds: getLoyaltyHistoryIds(state),
  isLoading: isLoadingHistory(state),
  totalCount: getLoyaltyHistoryTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(LoyaltyHistory);
