import { T } from 'ramda';
import { connect } from 'react-redux';

import EditButton from '@/components/ActionButtons/Edit';
import { createEditMerchantLink } from '@/features/merchantEditPage/serializer';

const mapStateToProps = (state, { merchantId }) => ({
  editPageUrl: createEditMerchantLink(merchantId),
  permission: T,
});

export default connect(mapStateToProps)(EditButton);
