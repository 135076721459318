import PropTypes from 'prop-types';
import React from 'react';

const LocationBlock = ({ classes, name, floor }) =>
  Boolean(name) && (
    <div className={classes.root}>
      <div className={classes.name}>{name}</div>
      <div className={classes.floor}>{floor}</div>
    </div>
  );

LocationBlock.propTypes = {
  name: PropTypes.string,
  floor: PropTypes.string.isRequired,
};

LocationBlock.defaultProps = {
  name: null,
};

export default LocationBlock;
