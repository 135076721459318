import {
  BENEFIT_ID_URL_SECTION,
  STEP_ROUTE_PATH,
  STEP_URL_SECTION,
} from './consts';

export const createFormStepURL = (id, step) =>
  STEP_ROUTE_PATH.replace(BENEFIT_ID_URL_SECTION, id).replace(
    STEP_URL_SECTION,
    step,
  );
