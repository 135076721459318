import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import AsyncButton from '@/components/AsyncButton';
import PasswordField from '@/components/PasswordField';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

import { newPasswordFieldName } from './names';

const ResetPasswordForm = ({
  Form,
  Field,
  SubmitButton,
  RequiredMessages,
  classes,
}) => (
  <Form>
    <Grid container direction="column" spacing={16}>
      <Grid item>
        <Typography variant="h4" gutterBottom>
          Восстановление пароля
        </Typography>
      </Grid>
      <Grid item>
        <Field
          Target={PasswordField}
          id={newPasswordFieldName}
          name={newPasswordFieldName}
          label="Введите новый пароль"
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item className={classes.alignSelfCenter}>
        <SubmitButton
          Target={AsyncButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
        >
          Отправить
        </SubmitButton>
      </Grid>
      <Grid item className={classes.alignSelfCenter}>
        <RequiredMessages
          fieldNames={{
            [newPasswordFieldName]: 'пароль',
          }}
        />
      </Grid>
    </Grid>
  </Form>
);

ResetPasswordForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default ResetPasswordForm;
