import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { createView } from '@/features/productsViewPage/actions';
import queryDuck from '@/features/productsViewPage/ducks/query';
import {
  getError,
  getItemsIds,
  getItemsTotalCount,
  isLoadingItems,
} from '@/features/productsViewPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import ProductsViewPage from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getError(state),
  isLoading: isLoadingItems(state),
  itemsIds: getItemsIds(state),
  totalCount: getItemsTotalCount(state),
});

const mapDispatchToProps = { createView };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withQuerySync(queryDuck),
  withStyles(styles),
)(ProductsViewPage);
