import PropTypes from 'prop-types';
import React from 'react';

import { getCuttedText } from '@/utils/format';

const MerchantNameLazy = ({ isLoaded, title, subTitle, showSubtitle }) => (
  <span title={`${title} (${subTitle})`}>
    {isLoaded
      ? getCuttedText(showSubtitle ? `${title} (${subTitle})` : title, 20)
      : '...'}
  </span>
);

MerchantNameLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
};

MerchantNameLazy.defaultProps = {
  title: null,
  subTitle: null,
  showSubtitle: false,
};

export default MerchantNameLazy;
