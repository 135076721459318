import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl, ...props }) => {
  const [sortFields, setSortFields] = useState({
    record_created: [{}],
  });

  const initialSortFields = JSON.parse(JSON.stringify(sortFields));
  const currentUrlParams = new URLSearchParams(window.location.search);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeFilter = fieldName => {
    Object.keys(initialSortFields).forEach(field => {
      initialSortFields[field] = [{}];
    });

    const changedField = {
      field: 'record_created',
      direction:
        sortFields.record_created[0].direction === 'desc' ? 'asc' : 'desc',
    };

    initialSortFields[changedField.field] = [changedField];

    if (changedField === 'desc') {
      currentUrlParams.set('sort', `${changedField.field}`);
    } else {
      currentUrlParams.set('sort', `-${changedField.field}`);
    }

    props.history.push({
      pathname: '/archive-balance',
      search: `?${decodeURIComponent(currentUrlParams.toString())}`,
    });

    setSortFields(initialSortFields);
  };

  useEffect(() => {
    const field = new URLSearchParams(window.location.search).get('sort');

    if (field) {
      const fieldName = field.replace(/-/g, '');

      initialSortFields[fieldName] = [
        {
          field: fieldName,
          direction: field.indexOf('-') !== -1 ? 'desc' : 'asc',
        },
      ];
      setSortFields(initialSortFields);
    }
  }, []);

  return (
    <TableRow>
      <CondenseTableCell>Имя EFS Sales</CondenseTableCell>

      <CondenseTableCell style={{ marginLeft: 50 }}>
        <SortControl
          sort="record_created"
          setSort={changeFilter}
          field="record_created"
        >
          Дату добавления бонуса
        </SortControl>
      </CondenseTableCell>
      <CondenseTableCell>Количество бонусов</CondenseTableCell>
      <CondenseTableCell style={{ textAlign: 'left' }}>
        Имя пользователя, которому добавили бонусы
      </CondenseTableCell>
    </TableRow>
  );
};

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default HeadRow;
