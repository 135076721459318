import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import { floorLabel } from '@/features/locationsStatisticsPage/labels';

import StatusIndicator from '../StatusIndicator';
import Locations from './Locations';
import MapButton from './MapButton';

const Row = ({ itemId, majorId, minorId, name, floor }) => (
  <TableRow>
    <CondenseTableCell>
      <StatusIndicator beaconId={itemId} />
    </CondenseTableCell>
    <CondenseTableCell>{name}</CondenseTableCell>
    <CondenseTableCell>{majorId}</CondenseTableCell>
    <CondenseTableCell>{minorId}</CondenseTableCell>
    <CondenseTableCell>{floorLabel(floor)}</CondenseTableCell>
    <CondenseTableCell>
      <Locations itemId={itemId} />
    </CondenseTableCell>
    <CondenseTableCell>
      <MapButton itemId={itemId} />
    </CondenseTableCell>
  </TableRow>
);

Row.propTypes = {
  itemId: PropTypes.any.isRequired,
  majorId: PropTypes.string.isRequired,
  minorId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  floor: PropTypes.string.isRequired,
};

export default Row;
