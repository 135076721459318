import {
  CATEGORIES_KEY,
  DESCRIPTION_CHINESE_KEY,
  DESCRIPTION_ENGLISH_KEY,
  DESCRIPTION_KAZAKH_KEY,
  DESCRIPTION_RUSSIAN_KEY,
  IMAGE_URL_KEY,
  NAME_KEY,
  STORES_KEY,
} from '@esentai/core/features/brands/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { array, number, object, string } from 'yup';

import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: ({
    chineseDescription,
    categories,
    englishDescription,
    imageUrl,
    kazakhDescription,
    name,
    russianDescription,
    storesIds,
  }) => ({
    [CATEGORIES_KEY]: categories || [],
    [DESCRIPTION_CHINESE_KEY]: chineseDescription,
    [DESCRIPTION_ENGLISH_KEY]: englishDescription,
    [DESCRIPTION_KAZAKH_KEY]: kazakhDescription,
    [DESCRIPTION_RUSSIAN_KEY]: russianDescription,
    [IMAGE_URL_KEY]: imageUrl,
    [NAME_KEY]: name,
    [STORES_KEY]: storesIds,
  }),

  validationSchema: object().shape({
    [CATEGORIES_KEY]: array().of(string()),
    [DESCRIPTION_CHINESE_KEY]: string().nullable(),
    [DESCRIPTION_ENGLISH_KEY]: string().nullable(),
    [DESCRIPTION_KAZAKH_KEY]: string().nullable(),
    [DESCRIPTION_ENGLISH_KEY]: string().nullable(),
    [IMAGE_URL_KEY]: string().nullable(),
    [NAME_KEY]: string().required(),
    [STORES_KEY]: array().of(number()),
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
