import { path } from 'ramda';

import api from '@/api';
import { PAGES_STORE_KEY } from '@/redux/constants';
import { saveAs } from '@/utils/file-saver';

export const getItemsRoot = path([PAGES_STORE_KEY, 'mallBonusesPage', 'items']);

export const getQueryRoot = path([PAGES_STORE_KEY, 'mallBonusesPage', 'query']);

export const downloadXls = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const limit = Number(urlParams.get('limit')) || 10;
  const page = Number(urlParams.get('page'));
  const offset = page * limit;

  urlParams.set('limit', limit.toString());
  urlParams.set('offset', offset.toString());
  urlParams.delete('page');
  if (!urlParams.has('sort')) {
    urlParams.set('sort', 'start_datetime');
  }

  api
    .doBlobGet(
      `/mall-bonus-history-xlsx-export?include=stores,user&${decodeURIComponent(
        urlParams.toString(),
      )}`,
    )
    .then(response => {
      saveAs(response.data, `Настройка бонусов Esentai Mall.xlsx`);
    });
};
