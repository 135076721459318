import { prop } from 'ramda';

import { SECTION_ROUTE_PATH } from '../consts';

export const createSection = (id, label) => ({ id, label });

export const getSectionId = prop('id');

export const getSectionLabel = prop('label');

export const getSectionUrl = section => {
  const id = getSectionId(section);

  return SECTION_ROUTE_PATH.replace(':section', id);
};
