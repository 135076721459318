export const ROUTE_PATH = '/merchant-reconciliation-details/:details_id';

export const FEATURE_NAME = 'merchantsReconciliationDetailsPage';

export const PATCH_URL = '/merchant-reconciliation-payment/';

export const GET_URL = '/merchant-reconciliation-history/';

export const POST_URL = '/merchant-reconciliation/';

export const REPAY_CONFIRMATION_MSG =
  'Вы уверены, что хотите завершить данную реконсиляцию?';
