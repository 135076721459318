import { FormGroup } from '@material-ui/core';
import { createChainedFunction, find } from '@material-ui/core/utils/helpers';
import PropTypes from 'prop-types';
import { concat, without } from 'ramda';
import React, {
  Children,
  cloneElement,
  Component,
  isValidElement,
} from 'react';

class RadioGroup extends Component {
  radios = [];

  focus = () => {
    if (!this.controls || !this.controls.length) {
      return;
    }

    const focusControls = this.controls.filter(n => !n.disabled);

    if (!focusControls.length) {
      return;
    }

    const selectedControl = find(focusControls, n => n.checked);

    if (selectedControl) {
      selectedControl.focus();

      return;
    }

    focusControls[0].focus();
  };

  handleCheckboxChange = (event, checked) => {
    event.stopPropagation();

    const { onChange, value } = this.props;

    if (onChange) {
      if (checked) {
        onChange(event, concat([event.target.value], value));
      } else {
        onChange(event, without([event.target.value], value));
      }
    }
  };

  render() {
    const { children, name, value, ...other } = this.props;

    this.controls = [];

    return (
      <FormGroup {...other}>
        {Children.map(children, child => {
          if (!isValidElement(child)) {
            return null;
          }

          return cloneElement(child, {
            name,
            inputRef: node => {
              if (node) {
                this.controls.push(node);
              }
            },
            checked: value.includes(child.props.value),
            onChange: createChainedFunction(
              child.props.onChange,
              this.handleCheckboxChange,
            ),
          });
        })}
      </FormGroup>
    );
  }
}

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
};

export default RadioGroup;
