import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { updatePage } from '@/features/productsPage/actions';
import queryDuck from '@/features/productsPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getProductIds,
  getProductsError,
  getProductsTotalCount,
  isLoadingProducts,
} from '../selectors';
import Products from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getProductsError(state),
  productIds: getProductIds(state),
  isLoading: isLoadingProducts(state),
  totalCount: getProductsTotalCount(state),
});

const mapDispatchToProps = { updatePage };

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Products);
