import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Variant = ({ classes, content, label }) => (
  <div className={classes.container}>
    {content}
    <Typography
      variant="overline"
      color="textSecondary"
      className={classes.locale}
    >
      {label}
    </Typography>
  </div>
);

Variant.propTypes = {
  content: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Variant;
