import React from 'react';

import AsyncButton from '@/components/AsyncButton';

const SynchronizeButton = props => (
  <AsyncButton {...props} color="primary" variant="contained">
    Применить изменения на сайте
  </AsyncButton>
);

export default SynchronizeButton;
