import { NAME_KEY } from '@esentai/core/features/brands/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={NAME_KEY}>Название</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>Присутствует в магазинах</CondenseTableCell>
    <CondenseTableCell align="right" />
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
