import {
  FILTERS_KEY,
  SEGMENT_TYPE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import { ALL_USERS_SEGMENT } from '@esentai/core/features/campaigns/consts/segments';
import api from '@esentai/core/features/users/api';
import { query as q } from '@esentai/core/query-dsl';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import Button from '@/components/Button';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FilterBar from '@/components/FilterBar';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import { SHOULD_SUBMIT_AFTER_SAVE_KEY } from '@/features/campaignCreatePage/consts/keys';
import { createFormStepURL } from '@/features/campaignCreatePage/utils';
import { segmentType } from '@/features/campaignsPage/labels';
import FormikPropTypes from '@/prop-types/formik';
import { removeFilters, updateFilters } from '@/redux/query/utils';

import { CanEdit, CanEditRadioBoxGroup as CanEditGroup } from '../CanEdit';
import { filterTypes } from './filters';

const fieldNames = {};

class ThirdStep extends Component {
  static propTypes = FormikPropTypes;

  componentDidMount() {
    this.updateUsers();
  }

  constructor(props) {
    super(props);

    this.state = { users: 0 };
  }

  removeFilters = keys => {
    const { values, setFieldValue } = this.props;
    const query = values[FILTERS_KEY];

    setFieldValue(FILTERS_KEY, removeFilters(query, keys));
    setTimeout(() => this.updateUsers(), 500);
  };

  clearFilters = () => {
    const { setFieldValue } = this.props;

    setFieldValue(FILTERS_KEY, q());
    setTimeout(() => this.updateUsers(), 500);
  };

  updateFilters = updates => {
    const { values, setFieldValue } = this.props;
    const query = values[FILTERS_KEY];

    setFieldValue(FILTERS_KEY, updateFilters(query, updates));
    setFieldValue('all', false);
    setTimeout(() => this.updateUsers(), 500);
  };

  toggleShouldSubmitAfterSave = event => {
    const { checked } = event.target;
    const { setFieldValue } = this.props;

    setFieldValue(SHOULD_SUBMIT_AFTER_SAVE_KEY, checked);
  };

  updateUsers() {
    const filters = [];

    for (const [filter, entry] of this.props.values[FILTERS_KEY].get(
      'filters',
    ).entries()) {
      for (const [key, value] of entry.entries()) {
        const v = value;

        filters.push([filter, key, v]);
      }
    }
    api
      .doQuery(filters)
      .then(users => this.setState({ users: users.meta.total }));
  }

  render() {
    const {
      classes,
      campaignType,
      canModerate,
      handleSubmit,
      id,
      values,
      Field,
      Form,
      RequiredMessages,
      SubmitButton,
    } = this.props;
    const filters = [
      ...values[FILTERS_KEY].get('filters').entries(),
    ].map(([key, value]) => ({ key, value }));

    const submitCheckboxLabel = canModerate
      ? 'Запустить кампанию немедленно'
      : 'Отправить кампанию в модерацию';
    const submitButtonLabel = canModerate
      ? 'Запустить кампанию'
      : 'Отправить в модерацию';
    const { users } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <FieldRow label="Сегмент" subLabel="Сегментация аудитории">
          <CanEditGroup field={SEGMENT_TYPE_KEY}>
            <Field Target={RadioBoxGroup} name={SEGMENT_TYPE_KEY}>
              <RadioBox
                value={ALL_USERS_SEGMENT}
                label={segmentType(ALL_USERS_SEGMENT)}
                helperText="Учитываются абсолютно все пользователи: как существующие, так и в будущем подпадающие под фильтры сегментации."
              />
            </Field>
          </CanEditGroup>
          <FieldDescription
            title="Фильтр сегмента"
            desc="Вы можете добавить также дополнительные фильтры, которые отделят пользователей с определенными характеристиками. К примеру, пользователей, обладающих Silver статусом, либо мужчин старше 35 лет."
          />
          <Typography variant="subtitle1" gutterBottom>
            Включить в сегмент посетителей, у которых:
          </Typography>
          <CanEdit field={SEGMENT_TYPE_KEY}>
            <FilterBar
              filterTypes={filterTypes}
              filters={filters}
              removeFilters={this.removeFilters}
              updateFilters={this.updateFilters}
            />
          </CanEdit>
        </FieldRow>
        <FieldRow label="Количество пользователей">
          <Typography>{users}</Typography>
        </FieldRow>
        <FieldRow
          label="Запуск"
          subLabel="Запустить кампанию после сохранения?"
          sudDesc="Без включения этой настройки после нажатия кнопки «Сохранить» кампания будет перенесена в черновики."
        >
          <FormControlLabel
            label={submitCheckboxLabel}
            control={
              <Checkbox
                checked={Boolean(values[SHOULD_SUBMIT_AFTER_SAVE_KEY])}
                onChange={this.toggleShouldSubmitAfterSave}
              />
            }
          />
        </FieldRow>

        <FieldRow>
          <div className={classes.footer}>
            <SubmitButton
              Target={AsyncButton}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
            >
              {values[SHOULD_SUBMIT_AFTER_SAVE_KEY]
                ? submitButtonLabel
                : 'Сохранить черновик'}
            </SubmitButton>
            <Button
              href={createFormStepURL(campaignType, id, 2)}
              color="primary"
              size="large"
              className={classes.backButton}
            >
              Вернуться
            </Button>
            <RequiredMessages
              fieldNames={fieldNames}
              className={classes.requiredMessages}
            />
          </div>
        </FieldRow>
      </Form>
    );
  }
}

export default ThirdStep;
