import {
  BITRIX_ROLE,
  EFS_SALES_ROLE,
  GUEST_EFS_ROLE,
  GUEST_PARKING_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_ADMINISTRATOR_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_RECEPTION_ROLE,
  MALL_SELLER_ROLE,
  MALL_VALET_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
} from '@/features/personnel/consts/roles';
import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';
import { userRole } from '@/utils/labels';

const options = [
  MALL_ADMINISTRATOR_ROLE,
  MALL_MANAGER_ROLE,
  MALL_SELLER_ROLE,
  STORE_ADMINISTRATOR_ROLE,
  STORE_MANAGER_ROLE,
  STORE_SELLER_ROLE,
  MALL_COMMISSAR_ROLE,
  MALL_ACCOUNTANT_ROLE,
  MALL_VALET_ROLE,
  GUEST_PARKING_ROLE,
  BITRIX_ROLE,
  GUEST_EFS_ROLE,
  EFS_SALES_ROLE,
  MALL_RECEPTION_ROLE,
  MANAGING_TEAM_MEMBER_ROLE,
].map(key => ({
  key,
  label: userRole(key),
}));

export const createPersonnelRoleFilter = createCheckboxFilterTemplate({
  options,
});

export default CheckboxGroupFilter;
