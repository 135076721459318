import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import { createAction } from 'redux-actions';

import query from './ducks/query';
import {
  DELETE_QUESTION,
  MOVE_QUESTION_DOWN,
  MOVE_QUESTION_UP,
  RETRY_FETCH,
} from './types';

export const { updateLimit, updatePage, updateSort } = query.actions;

export const deleteQuestion = createTaskAction(DELETE_QUESTION);

export const moveQuestionDown = createTaskAction(MOVE_QUESTION_DOWN);

export const moveQuestionUp = createTaskAction(MOVE_QUESTION_UP);

export const retryFetch = createAction(RETRY_FETCH);
