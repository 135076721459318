import {
  getBirthDateForUser,
  getEmailForUser,
  getGenderForUser,
  getIinForUser,
  getPhoneForUser,
} from '@esentai/core/features/users/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import PersonalInfo from './component';
import styles from './styles';

const mapStateToProps = (state, { userId }) => ({
  userId,
  birthDate: getBirthDateForUser(state, userId),
  gender: getGenderForUser(state, userId),
  phone: getPhoneForUser(state, userId),
  iin: getIinForUser(state, userId),
  email: getEmailForUser(state, userId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(PersonalInfo);
