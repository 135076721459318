import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';

const CanEditRadioBoxGroup = ({ canEdit, children }) => {
  const child = Children.map(children.props.children, elem =>
    cloneElement(elem, {
      disabled: !canEdit,
    }),
  );

  return cloneElement(children, {}, [child]);
};

CanEditRadioBoxGroup.propTypes = {
  canEdit: PropTypes.bool.isRequired,
};

export default CanEditRadioBoxGroup;
