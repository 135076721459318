import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/executiveReportsPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getMallStatError,
  getMallStatIds,
  getMallStatsTotalCount,
  isLoadingMallStats,
} from '../selectors';
import ExecutiveReports from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getMallStatError(state),
  mallStatIds: getMallStatIds(state),
  isLoading: isLoadingMallStats(state),
  totalCount: getMallStatsTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(ExecutiveReports);
