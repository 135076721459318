import PropTypes from 'prop-types';
import React from 'react';

const SecondFloor = ({ mapHeight, mapWidth, MapTransformer, ...props }) => (
  <MapTransformer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${mapWidth} ${mapHeight}`}
      {...props}
    >
      <g id="b5f0ae9b-060c-4c36-a0f6-190612775d43" data-name="Background">
        <rect x="-1" width="1420.16" height="1001" fill="#ededed" />
        <rect x="879" y="186" width="78" height="432" fill="#f7f7f7" />
        <rect y="659" width="1415" height="24" fill="#f7f7f7" />
        <rect y="696" width="1415" height="24" fill="#f7f7f7" />
        <path
          d="M914.34,335.85v-3.74H915V335h4v-2.08h.69V335h4.14v-2.88h.69v3.74Z"
          fill="#888685"
        />
        <path
          d="M922.59,326.82a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.54,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84V330h.87a1.55,1.55,0,0,0,1-.28,1.11,1.11,0,0,0,.36-.92,1.21,1.21,0,0,0-.32-.94,1.43,1.43,0,0,0-1-.29h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.83,1.83,0,0,1-.51-1.44,1.94,1.94,0,0,1,.51-1.5,2.19,2.19,0,0,1,1.49-.45h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.08,1.08,0,0,0,.3.85,1.17,1.17,0,0,0,.81.27H918a1.61,1.61,0,0,0,.39,0,1.25,1.25,0,0,0,.33-.16,3.8,3.8,0,0,0,.33-.29c.11-.12.24-.28.39-.47l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.93,2.93,0,0,1,.63-.06Z"
          fill="#888685"
        />
        <path
          d="M921.57,321.25h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.55,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.41,2.41,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.34,1.28,1.28,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.32,1.16,1.16,0,0,0-.39,1,1.19,1.19,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M918,316.54a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.28,1.28,0,0,0,.35.91,1.35,1.35,0,0,0,1,.37h6.54v.82H916V319h.84v0a1.84,1.84,0,0,1-.66-.55,1.66,1.66,0,0,1-.27-1,1.61,1.61,0,0,1,.48-1.26,2.24,2.24,0,0,1,1.53-.43h6.59v.82Z"
          fill="#888685"
        />
        <path
          d="M916,314.53v-.66h-1.66v-.82H916v-1.19h.63v1.19h6.49a.71.71,0,0,0,.54-.16.75.75,0,0,0,.16-.56v-.47h.69v.56a1.59,1.59,0,0,1-.32,1.13,1.5,1.5,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M924.51,307.36h-.77v0a1.74,1.74,0,0,1,.6.53,1.59,1.59,0,0,1,.26.94,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.23.94.94,0,0,0-.35.85,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.8h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.31a1.23,1.23,0,0,0-1.35-1.31h-3a.57.57,0,0,1,.14.13l.17.23q.09.13.21.36c.08.15.18.34.29.57a5.89,5.89,0,0,0,.28.52,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.86,1.86,0,0,0,.56.07h.65a1.58,1.58,0,0,0,1-.27A1,1,0,0,0,923.91,308.67Z"
          fill="#888685"
        />
        <path
          d="M914.34,305.11v-.82h.75v.82Zm1.66,0v-.82h8.51v.82Z"
          fill="#888685"
        />
        <path
          d="M924.6,298.38a1.92,1.92,0,0,1-2.07,2.17H921v-.82h1.6a1.44,1.44,0,0,0,1-.33,1.35,1.35,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.54,1.54,0,0,0-1-.31H922a2.89,2.89,0,0,0-.53.05,1.77,1.77,0,0,0-.45.18,2.08,2.08,0,0,0-.44.31c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.44-.63.61a3.55,3.55,0,0,1-.57.43,2.27,2.27,0,0,1-.6.26,3.08,3.08,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.17,2.17,0,0,1,.5-1.58,2,2,0,0,1,1.49-.51h1.11v.81h-1.14a1.29,1.29,0,0,0-1,.32,1.35,1.35,0,0,0-.3,1,1.29,1.29,0,0,0,.31.91,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a3.52,3.52,0,0,1,.58-.42,2.78,2.78,0,0,1,.62-.25,3.11,3.11,0,0,1,.72-.08h.56a2.45,2.45,0,0,1,.83.13,1.57,1.57,0,0,1,.66.39,1.9,1.9,0,0,1,.44.68A2.53,2.53,0,0,1,924.6,298.38Z"
          fill="#888685"
        />
        <path
          d="M925.86,290.7v.82h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.48h-4.63a2,2,0,0,1-1.54-.52,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H916v-.82ZM922.54,294a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H918a1.43,1.43,0,0,0-1,.4,1.26,1.26,0,0,0-.36.91c0,.81.46,1.21,1.37,1.21Z"
          fill="#888685"
        />
        <path
          d="M922.56,288.43q1.35,0,1.35-1.17a1.29,1.29,0,0,0-.32-.88,1.33,1.33,0,0,0-1-.39H916v-.83h8.51V286h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.44H916v-.83Z"
          fill="#888685"
        />
        <path
          d="M924.51,280.53h-.77v0a1.71,1.71,0,0,1,.6.52,1.62,1.62,0,0,1,.26.95,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.46-.36-.73a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.92,1.92,0,0,1-.54-1.51,1.75,1.75,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57a5.58,5.58,0,0,0,.28.51,1.61,1.61,0,0,0,.3.37,1.27,1.27,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,923.91,281.83Z"
          fill="#888685"
        />
        <path
          d="M916.86,276v.14a1.37,1.37,0,0,0,.34,1,1.31,1.31,0,0,0,1,.37h6.33v.83H916v-.83h1v0a2.33,2.33,0,0,1-.39-.19,1.64,1.64,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.4,1.4,0,0,1-.1-.56Z"
          fill="#888685"
        />
        <path
          d="M921.57,270.51h.84A2.36,2.36,0,0,1,924,271a2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M914.34,581.56v-3.73H915v2.88h4v-2.09h.69v2.09h4.14v-2.88h.69v3.73Z"
          fill="#888685"
        />
        <path
          d="M922.59,572.53a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.55,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84v-.78h.87a1.56,1.56,0,0,0,1-.29,1.09,1.09,0,0,0,.36-.91,1.24,1.24,0,0,0-.32-.95,1.43,1.43,0,0,0-1-.28h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.85,1.85,0,0,1-.51-1.45,1.92,1.92,0,0,1,.51-1.49,2.19,2.19,0,0,1,1.49-.46h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.11,1.11,0,0,0,.3.86,1.17,1.17,0,0,0,.81.27H918a1.6,1.6,0,0,0,.39-.05,1.21,1.21,0,0,0,.33-.15,4.09,4.09,0,0,0,.33-.3c.11-.12.24-.27.39-.46l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.88,2.88,0,0,1,.63-.07Z"
          fill="#888685"
        />
        <path
          d="M921.57,567h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M918,562.26a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.29,1.29,0,0,0,.35.91,1.35,1.35,0,0,0,1,.36h6.54v.83H916v-.83h.84a1.87,1.87,0,0,1-.66-.56,1.93,1.93,0,0,1,.21-2.26,2.24,2.24,0,0,1,1.53-.44h6.59v.83Z"
          fill="#888685"
        />
        <path
          d="M916,560.25v-.66h-1.66v-.83H916v-1.18h.63v1.18h6.49a.76.76,0,0,0,.54-.15.79.79,0,0,0,.16-.57v-.46h.69v.55a1.63,1.63,0,0,1-.32,1.14,1.54,1.54,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M924.51,553.08h-.77v0a1.71,1.71,0,0,1,.6.52,1.64,1.64,0,0,1,.26,1,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.13,2.13,0,0,1-.41-.56c-.12-.23-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.9,1.9,0,0,1-.54-1.51,1.76,1.76,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57s.19.37.28.52a1.79,1.79,0,0,0,.3.36,1.09,1.09,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,923.91,554.38Z"
          fill="#888685"
        />
        <path
          d="M914.34,550.83V550h.75v.83Zm1.66,0V550h8.51v.83Z"
          fill="#888685"
        />
        <path
          d="M924.6,544.09a1.93,1.93,0,0,1-2.07,2.18H921v-.83h1.6a1.44,1.44,0,0,0,1-.33,1.33,1.33,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.48,1.48,0,0,0-1-.31H922a2.89,2.89,0,0,0-.53.05,1.74,1.74,0,0,0-.45.17,2.14,2.14,0,0,0-.44.32c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.45-.63.62a4.26,4.26,0,0,1-.57.43,2.24,2.24,0,0,1-.6.25,2.62,2.62,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.19,2.19,0,0,1,.5-1.58,2,2,0,0,1,1.49-.5h1.11v.81h-1.14a1.28,1.28,0,0,0-1,.31,1.35,1.35,0,0,0-.3,1,1.3,1.3,0,0,0,.31.92,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a4.22,4.22,0,0,1,.58-.42,2.34,2.34,0,0,1,.62-.24,2.63,2.63,0,0,1,.72-.09h.56a2.72,2.72,0,0,1,.83.13,1.7,1.7,0,0,1,.66.4,1.86,1.86,0,0,1,.44.67A2.53,2.53,0,0,1,924.6,544.09Z"
          fill="#888685"
        />
        <path
          d="M925.86,536.41v.83h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.47h-4.63a2.06,2.06,0,0,1-1.54-.51,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H916v-.83Zm-3.32,3.35a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H918a1.43,1.43,0,0,0-1,.4,1.22,1.22,0,0,0-.36.9c0,.81.46,1.22,1.37,1.22Z"
          fill="#888685"
        />
        <path
          d="M922.56,534.15q1.35,0,1.35-1.17a1.31,1.31,0,0,0-.32-.89,1.28,1.28,0,0,0-1-.38H916v-.83h8.51v.83h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.43H916v-.82Z"
          fill="#888685"
        />
        <path
          d="M924.51,526.25h-.77v0a1.74,1.74,0,0,1,.6.53,1.6,1.6,0,0,1,.26,1,1.7,1.7,0,0,1-.48,1.31,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.39,5.39,0,0,0-.63-1.17.71.71,0,0,0-.55-.29H918a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.11,1.11,0,0,0,.33.89,1.4,1.4,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.33,1.33,0,0,0-.34-.94,1.31,1.31,0,0,0-1-.36h-3a.39.39,0,0,1,.14.12l.17.23q.09.14.21.36c.08.15.18.34.29.58a5.58,5.58,0,0,0,.28.51,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.28A1,1,0,0,0,923.91,527.55Z"
          fill="#888685"
        />
        <path
          d="M916.86,521.67v.13a1.37,1.37,0,0,0,.34,1,1.28,1.28,0,0,0,1,.38h6.33V524H916v-.83h1v0a2.4,2.4,0,0,1-.39-.2,1.4,1.4,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.35,1.35,0,0,1-.1-.55Z"
          fill="#888685"
        />
        <path
          d="M921.57,516.22h.84a2.36,2.36,0,0,1,1.63.52,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.37,2.37,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.4h2.17a1.61,1.61,0,0,0,1.1-.35,1.27,1.27,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.4V517h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.22,1.22,0,0,0,.39,1,1.59,1.59,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <polygon
          points="1043.9 391.5 1014.2 381.5 984.5 391.5 984.5 259.5 1014.2 269.5 1043.9 259.5 1043.9 391.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1249.9 391.5 1220.2 381.5 1190.5 391.5 1190.5 259.5 1220.2 269.5 1249.9 259.5 1249.9 391.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1045.2 225.2 1055.2 195.5 1045.2 165.8 1177.2 165.8 1167.2 195.5 1177.2 225.2 1045.2 225.2"
          fill="#e1e0e0"
        />
        <polygon
          points="1175.09 529.52 1082.12 529.52 1082.12 453.5 1008.1 453.5 1008.1 472.19 986.91 472.19 986.91 618 1008.1 618 1082.12 618 1082.12 594.32 1175.09 594.32 1175.09 529.52"
          fill="#e1e0e0"
        />
        <path
          d="M1141,59.71V-2h-26V54.61l-408.91,54.2a62,62,0,0,0-118,12.73L207,174.69V671h26V197.31l355.77-49.62A62,62,0,0,0,711,134.4l413.29-54.79L1292,212.29v261L1172,664.08l22,13.84,124-197.17v-281ZM649,169a36,36,0,1,1,36-36A36,36,0,0,1,649,169Z"
          fill="#f7f7f7"
        />
        <path
          d="M1005.48,550.33h3.74V551h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1014.51,558.58a2.19,2.19,0,0,1-.47,1.48,2,2,0,0,1-1.55.53A1.87,1.87,0,0,1,1011,560a2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.5,1.5,0,0,0,.29,1,1.07,1.07,0,0,0,.91.36,1.2,1.2,0,0,0,.94-.32,1.43,1.43,0,0,0,.29-1v-.26a1.5,1.5,0,0,0-.21-.81,3.78,3.78,0,0,0-.81-.81l-.87-.7a4.25,4.25,0,0,1-1-1,1.83,1.83,0,0,1-.31-1.1v-.24a1.86,1.86,0,0,1,.46-1.32,1.84,1.84,0,0,1,1.44-.51,1.93,1.93,0,0,1,1.5.51,2.15,2.15,0,0,1,.45,1.48v.62h-.77v-.65a1.51,1.51,0,0,0-.27-1,1.14,1.14,0,0,0-.91-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81V554a1.58,1.58,0,0,0,0,.38,1,1,0,0,0,.15.33,3.8,3.8,0,0,0,.29.33,5.71,5.71,0,0,0,.47.39l.87.7c.25.2.46.38.64.55a2.91,2.91,0,0,1,.41.5,2,2,0,0,1,.22.53,3,3,0,0,1,.06.63Z"
          fill="#888685"
        />
        <path
          d="M1020.08,557.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53A1.67,1.67,0,0,0,1019,553a1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1024.79,554a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V552h.82v.83h0a1.94,1.94,0,0,1,.56-.66,1.7,1.7,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.44,1.54v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1026.8,552h.66v-1.67h.82V552h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.61,1.61,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1034,560.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V554a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1032.66,559.9Z"
          fill="#888685"
        />
        <path
          d="M1036.22,550.33h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1040.64,550.33h4.19V551h-1.67v9.48h-.85V551h-1.67Z"
          fill="#888685"
        />
        <path
          d="M1050.26,558.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.58.61,2,2,0,0,1-1.57-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.57-.6,2,2,0,0,1,1.58.6,2.46,2.46,0,0,1,.54,1.68Zm-2.12,1.59a1.22,1.22,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.22,1.22,0,0,0-1-.39,1.19,1.19,0,0,0-1,.39,1.58,1.58,0,0,0-.34,1.09v4.34a1.58,1.58,0,0,0,.34,1.09A1.19,1.19,0,0,0,1048.14,559.9Z"
          fill="#888685"
        />
        <path
          d="M1058.12,552l-1.37,8.5h-.81l-1.18-6.52-1.22,6.52h-.81l-1.38-8.5h.83l1,6.58h0l1.23-6.58h.7l1.22,6.58h0l1-6.58Z"
          fill="#888685"
        />
        <path
          d="M1063.43,557.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1060v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.16,1.16,0,0,0-1-.38,1.18,1.18,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1067.19,552.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V552h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1073.33,550l-2.18,11.82h-.72l2.18-11.82Z" fill="#888685" />
        <path
          d="M1005.25,566.33h4.18V567h-1.67v9.48h-.85V567h-1.66Z"
          fill="#888685"
        />
        <path
          d="M1013.93,570a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V566.33h.82v2.5h0a2,2,0,0,1,.55-.66,1.73,1.73,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.43,1.54v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1020.37,573.56v.84a2.36,2.36,0,0,1-.52,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.54-1.67v-4.14a2.38,2.38,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1017v2.18a1.59,1.59,0,0,0,.35,1.09,1.27,1.27,0,0,0,1,.39c.84,0,1.25-.47,1.25-1.42v-.92Zm-3.4-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.16,1.16,0,0,0-1-.38,1.22,1.22,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1025,571.37v5.13h-.86V566.33h2a2.17,2.17,0,0,1,1.58.5,2,2,0,0,1,.49,1.42v1.5a2,2,0,0,1-.49,1.44,2.14,2.14,0,0,1-1.62.52h0l2.45,4.79h-1Zm0-4.35v4h1.06a1.34,1.34,0,0,0,1-.32,1.22,1.22,0,0,0,.31-.9v-1.59q0-1.2-1.29-1.2Z"
          fill="#888685"
        />
        <path
          d="M1029.66,566.33h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M1031.7,568h.66v-1.67h.83V568h1.18v.62h-1.18v6.49a.71.71,0,0,0,.16.54.75.75,0,0,0,.56.16h.46v.69h-.55a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.33-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1036.43,575.81h2.73v.69h-3.59v-.69l2.79-7.12h-2.59V568h3.45v.69Z"
          fill="#888685"
        />
        <path
          d="M1045.19,576.59a2.47,2.47,0,0,1-1-.2,1.93,1.93,0,0,1-.7-.55,2.41,2.41,0,0,1-.4-.8,3.87,3.87,0,0,1-.13-1v-5.28a4,4,0,0,1,.13-1,2.36,2.36,0,0,1,.4-.81,1.81,1.81,0,0,1,.7-.54,2.47,2.47,0,0,1,1-.2,2.69,2.69,0,0,1,1,.17,1.48,1.48,0,0,1,.66.47,1.87,1.87,0,0,1,.34.74,4.75,4.75,0,0,1,.09,1v1.2h-.84v-1.27a2.25,2.25,0,0,0-.25-1.16,1.13,1.13,0,0,0-1-.42,1.46,1.46,0,0,0-.66.14,1.29,1.29,0,0,0-.44.36,1.63,1.63,0,0,0-.25.55,2.81,2.81,0,0,0-.07.67v5.53a2.87,2.87,0,0,0,.07.67,1.63,1.63,0,0,0,.25.55,1.29,1.29,0,0,0,.44.36,1.34,1.34,0,0,0,.66.14,1.14,1.14,0,0,0,1-.44,2.29,2.29,0,0,0,.26-1.14v-1.81h.82v1.74a4.42,4.42,0,0,1-.09.92,1.86,1.86,0,0,1-.35.75,1.68,1.68,0,0,1-.66.49A2.59,2.59,0,0,1,1045.19,576.59Z"
          fill="#888685"
        />
        <path
          d="M1051.91,576.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V570a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1050.6,575.9Z"
          fill="#888685"
        />
        <path
          d="M1056.48,568.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V568h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1057.76,566.33h.82V576.5h-.82Z" fill="#888685" />
        <path
          d="M1059.8,568h.66v-1.67h.82V568h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.61,1.61,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1068,574.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.57.61,2,2,0,0,1-1.58-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.57.6,2.46,2.46,0,0,1,.54,1.68Zm-2.11,1.59a1.19,1.19,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.19,1.19,0,0,0-1-.39,1.22,1.22,0,0,0-1,.39,1.63,1.63,0,0,0-.34,1.09v4.34a1.63,1.63,0,0,0,.34,1.09A1.22,1.22,0,0,0,1065.89,575.9Z"
          fill="#888685"
        />
        <path
          d="M1072.71,570a1.63,1.63,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.33,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83V568h.83v.83h0a2.06,2.06,0,0,1,.56-.66,1.7,1.7,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.44,1.54v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1077.06,576.5l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M1083.35,566.33h.82V576.5h-.82Z" fill="#888685" />
        <path
          d="M1088.7,569.88a1.64,1.64,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.09,1.09,0,0,0-.85.35,1.41,1.41,0,0,0-.32,1v6.57h-.83V568h.83v.82h0a1.78,1.78,0,0,1,.52-.65,1.59,1.59,0,0,1,.94-.26,1.4,1.4,0,0,1,1.45,1h0a1.53,1.53,0,0,1,.57-.72,1.63,1.63,0,0,1,1-.28c1.07,0,1.61.65,1.61,2v6.64h-.83v-6.62a1.64,1.64,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.13,1.13,0,0,0-.81.32,1.33,1.33,0,0,0-.36.89v6.69h-.83Z"
          fill="#888685"
        />
        <path
          d="M1097.19,576.5v-.76h0a1.68,1.68,0,0,1-.52.59,1.62,1.62,0,0,1-1,.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.52,1.52,0,0,1,.37-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.46-.24.73-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V570a1.85,1.85,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.1,1.1,0,0,0-.89.32,1.42,1.42,0,0,0-.28.94v.82h-.79v-.76a2.13,2.13,0,0,1,.48-1.48,1.91,1.91,0,0,1,1.51-.53,1.75,1.75,0,0,1,1.46.53A2.48,2.48,0,0,1,1098,570v6.5Zm-1.3-.6a1.33,1.33,0,0,0,.94-.34,1.31,1.31,0,0,0,.36-1v-3a.53.53,0,0,1-.12.14l-.23.17-.37.22-.57.28a5.45,5.45,0,0,0-.51.29,1.2,1.2,0,0,0-.36.3,1.07,1.07,0,0,0-.22.39,1.91,1.91,0,0,0-.08.57v.64a1.58,1.58,0,0,0,.27,1A1.06,1.06,0,0,0,1095.89,575.9Z"
          fill="#888685"
        />
        <path
          d="M1099.19,568h.66v-1.67h.82V568h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.59,1.59,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1107.26,568l-2,8.61a2.63,2.63,0,0,1-.19.58,1,1,0,0,1-.73.6,2.93,2.93,0,0,1-.63.06h-.42v-.69h.41a.75.75,0,0,0,.56-.16,1.4,1.4,0,0,0,.28-.59l.19-.74h-.19L1102.8,568h.84l1.38,6.33h0l1.41-6.33Z"
          fill="#888685"
        />
        <path
          d="M1082.48,304.14h3.74v.69h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1091.51,312.39a2.17,2.17,0,0,1-.47,1.48,2,2,0,0,1-1.55.53,1.87,1.87,0,0,1-1.49-.56,2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.5,1.5,0,0,0,.29,1,1.07,1.07,0,0,0,.91.36,1.16,1.16,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-.95-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.84,1.84,0,0,1,1.44-.51,1.93,1.93,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.77v-.65a1.51,1.51,0,0,0-.27-1,1.14,1.14,0,0,0-.91-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.46,1.46,0,0,0,.05.38.89.89,0,0,0,.15.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M1097.08,311.37v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67V308a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1101.79,307.76a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.77,1.77,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1103.8,305.81h.66v-1.67h.82v1.67h1.19v.63h-1.19v6.48a.8.8,0,0,0,.16.55.82.82,0,0,0,.56.15h.47v.69h-.56a1.56,1.56,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1111,314.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1109.66,313.71Z"
          fill="#888685"
        />
        <path
          d="M1113.22,304.14h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1117.6,314.31l2-10.17h1.05l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path
          d="M1123.88,305.81h.83v.85h0a2.15,2.15,0,0,1,.57-.65,1.65,1.65,0,0,1,1-.29,1.62,1.62,0,0,1,1.28.49,2.19,2.19,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.57,1.57,0,0,1-1-.28,1.78,1.78,0,0,1-.52-.62h0v2.16h-.83Zm3.35,2a1.57,1.57,0,0,0-.29-1,1.17,1.17,0,0,0-.93-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1132.66,314.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1131.35,313.71Z"
          fill="#888685"
        />
        <path
          d="M1137.23,306.66h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M1138.25,305.81h.66v-1.67h.83v1.67h1.18v.63h-1.18v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1145.32,307.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.09,1.09,0,0,0-.84.35,1.38,1.38,0,0,0-.33,1v6.57h-.82v-8.5h.82v.82h0a1.59,1.59,0,0,1,.52-.64,1.47,1.47,0,0,1,.93-.27,1.41,1.41,0,0,1,1.46,1h0a1.58,1.58,0,0,1,.56-.72,1.69,1.69,0,0,1,1-.28c1.07,0,1.6.65,1.6,1.95v6.64h-.82V307.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.1,1.1,0,0,0-.81.33,1.29,1.29,0,0,0-.36.88v6.69h-.82Z"
          fill="#888685"
        />
        <path
          d="M1154.81,311.37v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.55-1.67V308a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2H1154v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1159.52,307.76a1.58,1.58,0,0,0-.27-1,1.1,1.1,0,0,0-.9-.34,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1161.53,305.81h.66v-1.67h.83v1.67h1.18v.63H1163v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1169.45,312.39a2.13,2.13,0,0,1-.48,1.48,2,2,0,0,1-1.54.53,1.87,1.87,0,0,1-1.49-.56,2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.18,1.18,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-1-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.78v-.65a1.45,1.45,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.48,1.48,0,0,0,0,.38,1.12,1.12,0,0,0,.16.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M220.42,677.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18H224l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M226.71,667.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M228.85,672.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M232.18,667.64h3.63v.69H233v4h2.08V673H233v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M240.21,677.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.16,1.16,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V672h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.57.57,0,0,1-.13.14,1.72,1.72,0,0,1-.23.17,2.59,2.59,0,0,1-.36.22l-.57.28a5.74,5.74,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,238.9,677.21Z"
          fill="#888685"
        />
        <path
          d="M244.78,670.16h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M249.22,677.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V672h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,247.92,677.21Z"
          fill="#888685"
        />
        <path
          d="M251.47,667.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.7,1.7,0,0,1,1-.29,1.63,1.63,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.52,1.52,0,0,1-1-.28,1.93,1.93,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M257.08,667.64h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M264.91,677.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V672h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,263.61,677.21Z"
          fill="#888685"
        />
        <path
          d="M271.24,669.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.39-6.93Z"
          fill="#888685"
        />
        <path
          d="M276.49,674.87v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11H273.1v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M281.2,671.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.36,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M284.26,675.86q0,1.35,1.17,1.35a1.27,1.27,0,0,0,.89-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.82v8.5h-.82V677h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.43-1.53v-6.58h.82Z"
          fill="#888685"
        />
        <path
          d="M293.17,674.87v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M697.81,677.81l2-10.17h1l2,10.17H702l-.48-2.49H699.1l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M704.1,667.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M706.24,672.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M709.57,667.64h3.63v.69h-2.77v4h2.08V673h-2.08v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M717.6,677.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.14,1.14,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V672h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.57.57,0,0,1-.13.14,1.72,1.72,0,0,1-.23.17,2.59,2.59,0,0,1-.36.22l-.57.28a4.63,4.63,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,716.29,677.21Z"
          fill="#888685"
        />
        <path
          d="M722.17,670.16H722a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M726.61,677.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.57,1.57,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.38,2.38,0,0,1,.56-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V672h-.8v-.76a2.15,2.15,0,0,1,.49-1.48,1.94,1.94,0,0,1,1.51-.53,1.79,1.79,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1,1,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,725.31,677.21Z"
          fill="#888685"
        />
        <path
          d="M728.86,667.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.68,1.68,0,0,1,1-.29,1.64,1.64,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.52,1.52,0,0,1-1-.28,1.93,1.93,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.21,1.21,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.31,1.31,0,0,0,.37,1,1.3,1.3,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M734.47,667.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M742.3,677.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.78,1.78,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V672h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,741,677.21Z"
          fill="#888685"
        />
        <path
          d="M748.63,669.31l-1.77,8.5h-.93l-1.77-8.5H745l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M753.88,674.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M758.59,671.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M761.65,675.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V677h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M770.56,674.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1175.2,677.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path d="M1181.48,667.64h.83v10.17h-.83Z" fill="#888685" />
        <path d="M1183.63,672.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M1187,667.64h3.63v.69h-2.78v4h2.09V673h-2.09v4.83H1187Z"
          fill="#888685"
        />
        <path
          d="M1195,677.81v-.76h0a1.73,1.73,0,0,1-.53.59,1.53,1.53,0,0,1-1,.26,1.68,1.68,0,0,1-1.31-.48,2.19,2.19,0,0,1-.46-1.53v-.69a2.49,2.49,0,0,1,.13-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,.94.94,0,0,0-.86-.34,1.14,1.14,0,0,0-.89.32,1.44,1.44,0,0,0-.28.94V672h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.37,1.37,0,0,0,.94-.33,1.35,1.35,0,0,0,.36-1v-3a.39.39,0,0,1-.12.14,1.72,1.72,0,0,1-.23.17,2.25,2.25,0,0,1-.37.22c-.14.08-.34.17-.57.28a5.45,5.45,0,0,0-.51.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.79,1.79,0,0,0-.08.56v.64a1.7,1.7,0,0,0,.27,1A1.09,1.09,0,0,0,1193.68,677.21Z"
          fill="#888685"
        />
        <path
          d="M1199.56,670.16h-.14a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.37,1v6.33h-.83v-8.5h.83v1.05h0a2.4,2.4,0,0,1,.2-.39,1.21,1.21,0,0,1,.29-.35,1.64,1.64,0,0,1,.42-.26,1.43,1.43,0,0,1,.56-.1Z"
          fill="#888685"
        />
        <path
          d="M1204,677.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V672h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1202.69,677.21Z"
          fill="#888685"
        />
        <path
          d="M1206.25,667.64h.82v2.52h0a2.26,2.26,0,0,1,.56-.65,1.67,1.67,0,0,1,1-.29,1.65,1.65,0,0,1,1.29.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.82,1.82,0,0,1-1.34.48,1.52,1.52,0,0,1-1-.28,1.9,1.9,0,0,1-.52-.62h0v.81h-.82Zm3.34,3.63a1.51,1.51,0,0,0-.29-1,1.13,1.13,0,0,0-.92-.34,1.25,1.25,0,0,0-.92.36,1.45,1.45,0,0,0-.39,1v4.62a1.36,1.36,0,0,0,.38,1,1.29,1.29,0,0,0,.91.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1211.86,667.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1219.69,677.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V672h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1218.38,677.21Z"
          fill="#888685"
        />
        <path
          d="M1226,669.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M1231.27,674.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1236,671.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58H1236Z"
          fill="#888685"
        />
        <path
          d="M1239,675.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V677h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M1248,674.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <rect x="300.43" y="627.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="314.27"
          y="632.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="314.27"
          y1="635.1"
          x2="326.58"
          y2="635.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="314.27"
          y1="640.96"
          x2="326.58"
          y2="640.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="320.43"
          y1="635.1"
          x2="320.43"
          y2="640.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="316"
          cy="642.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="324.61"
          cy="642.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="315.38"
          y="644.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="323.99"
          y="644.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect x="145.43" y="735.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="159.27"
          y="740.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="159.27"
          y1="743.1"
          x2="171.58"
          y2="743.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="159.27"
          y1="748.96"
          x2="171.58"
          y2="748.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="165.43"
          y1="743.1"
          x2="165.43"
          y2="748.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="161"
          cy="750.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="169.61"
          cy="750.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="160.38"
          y="752.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="168.99"
          y="752.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="603.5"
          y1="615.89"
          x2="709.5"
          y2="615.89"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f23aa55f-1af1-4bc4-99e5-59cc666a0081" data-name="OUTLINE">
        <polygon
          points="837.43 622 837.43 180 521.64 246.21 378.95 246.21 378.95 622 837.43 622"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="709.5 615.89 709.5 565.94 725.88 565.94 725.88 210.75 831.33 188 831.33 615.89 709.5 615.89"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M762.73,404.66h3.95v.72h-3v4.19h2.2v.73h-2.2v4.38h3v.73h-3.95Z"
          fill="#bcbcbc"
        />
        <path
          d="M772.28,413.38a2.31,2.31,0,0,1-.5,1.57,2.14,2.14,0,0,1-1.64.56,2,2,0,0,1-1.58-.6,2.34,2.34,0,0,1-.51-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.3,1,1.19,1.19,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.58,1.58,0,0,0-.22-.85,4,4,0,0,0-.86-.86l-.92-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.83v-.68a1.57,1.57,0,0,0-.28-1,1.2,1.2,0,0,0-1-.33,1.17,1.17,0,0,0-.91.32,1.24,1.24,0,0,0-.28.86v.25a1.64,1.64,0,0,0,0,.4,1.14,1.14,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,1.7,1.7,0,0,1,.23.56,2.7,2.7,0,0,1,.07.67Z"
          fill="#bcbcbc"
        />
        <path
          d="M778.17,412.3v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.1,2.1,0,0,1,1.66-.64,2.18,2.18,0,0,1,1.66.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.59v2.3a1.72,1.72,0,0,0,.37,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.59-2.08h2.73v-1.61a1.77,1.77,0,0,0-.33-1.15,1.24,1.24,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15Z"
          fill="#bcbcbc"
        />
        <path
          d="M783.15,408.48a1.63,1.63,0,0,0-.29-1.07,1.15,1.15,0,0,0-.94-.36,1.3,1.3,0,0,0-1,.37,1.4,1.4,0,0,0-.39,1.08v6.91h-.88v-9h.88v.88h0a2.05,2.05,0,0,1,.59-.69,2,2,0,0,1,2.4.22,2.41,2.41,0,0,1,.46,1.62v7h-.88Z"
          fill="#bcbcbc"
        />
        <path
          d="M785.28,406.42h.7v-1.76h.87v1.76h1.25v.66h-1.25v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.49v.73h-.58a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#bcbcbc"
        />
        <path
          d="M792.86,415.41v-.81h0a1.72,1.72,0,0,1-.55.63,1.64,1.64,0,0,1-1,.28,1.82,1.82,0,0,1-1.4-.51,2.31,2.31,0,0,1-.48-1.62v-.73a2.5,2.5,0,0,1,.14-.88,1.68,1.68,0,0,1,.39-.61,2.74,2.74,0,0,1,.6-.44c.23-.12.49-.24.78-.37a5.16,5.16,0,0,0,1.24-.67.76.76,0,0,0,.31-.58v-.62a2,2,0,0,0-.23-1.06,1,1,0,0,0-.91-.37,1.2,1.2,0,0,0-.94.34,1.46,1.46,0,0,0-.3,1v.88h-.84v-.81a2.26,2.26,0,0,1,.51-1.57,2.07,2.07,0,0,1,1.6-.56,1.86,1.86,0,0,1,1.54.56,2.63,2.63,0,0,1,.45,1.66v6.87Zm-1.38-.63a1.3,1.3,0,0,0,1.38-1.43v-3.17a.58.58,0,0,1-.13.15l-.24.17a3.1,3.1,0,0,1-.39.23q-.24.13-.6.3l-.55.3a2.07,2.07,0,0,0-.38.32,1.22,1.22,0,0,0-.23.42,2.13,2.13,0,0,0-.08.6v.68a1.69,1.69,0,0,0,.29,1.07A1.12,1.12,0,0,0,791.48,414.78Z"
          fill="#bcbcbc"
        />
        <path
          d="M795.24,404.66h.88v.79h-.88Zm0,1.76h.88v9h-.88Z"
          fill="#bcbcbc"
        />
        <path
          d="M765.16,430.51a2.53,2.53,0,0,1-1.11-.22,1.9,1.9,0,0,1-.75-.58,2.25,2.25,0,0,1-.44-.84,3.53,3.53,0,0,1-.14-1v-5.59a3.6,3.6,0,0,1,.14-1.05,2.3,2.3,0,0,1,.44-.85,1.79,1.79,0,0,1,.75-.57,2.68,2.68,0,0,1,1.11-.21,2.73,2.73,0,0,1,1.12.21,1.89,1.89,0,0,1,.76.57,2.45,2.45,0,0,1,.44.85,4,4,0,0,1,.14,1.05v5.59a3.9,3.9,0,0,1-.14,1,2.41,2.41,0,0,1-.44.84,2,2,0,0,1-.76.58A2.57,2.57,0,0,1,765.16,430.51Zm0-.73a1.58,1.58,0,0,0,.71-.15,1.37,1.37,0,0,0,.48-.38,1.61,1.61,0,0,0,.28-.59,2.64,2.64,0,0,0,.08-.7V422.1a2.59,2.59,0,0,0-.08-.7,1.65,1.65,0,0,0-.28-.58,1.53,1.53,0,0,0-.48-.39,1.73,1.73,0,0,0-.71-.14,1.62,1.62,0,0,0-.7.14,1.38,1.38,0,0,0-.47.39,1.65,1.65,0,0,0-.28.58,2.58,2.58,0,0,0-.09.7V428a2.63,2.63,0,0,0,.09.7,1.61,1.61,0,0,0,.28.59,1.26,1.26,0,0,0,.47.38A1.48,1.48,0,0,0,765.16,429.78Z"
          fill="#bcbcbc"
        />
        <path
          d="M768.9,421.42h.69v-.5a1.27,1.27,0,0,1,.31-1,1.51,1.51,0,0,1,1.05-.3h.65v.65h-.54a.62.62,0,0,0-.47.15.71.71,0,0,0-.13.48v.48h1.82v-.5a1.28,1.28,0,0,1,.32-1,1.5,1.5,0,0,1,1-.3h.59v.65h-.48a.62.62,0,0,0-.47.15.71.71,0,0,0-.13.48v.48h1.08v.66h-1.08v8.33h-.88v-8.33h-1.82v8.33h-.87v-8.33h-.69Z"
          fill="#bcbcbc"
        />
        <path
          d="M775.46,419.66h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#bcbcbc"
        />
        <path
          d="M782.23,427.3v.89a2.56,2.56,0,0,1-.51,1.72,2,2,0,0,1-1.61.6,2.16,2.16,0,0,1-1.69-.65,2.59,2.59,0,0,1-.57-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.13,2.13,0,0,1,1.67-.64,2.07,2.07,0,0,1,1.62.58,2.48,2.48,0,0,1,.52,1.71v.65h-.85v-.72a1.78,1.78,0,0,0-.31-1.12,1.2,1.2,0,0,0-1-.37,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.36,1.15v4.6a1.71,1.71,0,0,0,.36,1.16,1.31,1.31,0,0,0,1,.41,1.22,1.22,0,0,0,1-.37,1.81,1.81,0,0,0,.3-1.14v-1Z"
          fill="#bcbcbc"
        />
        <path
          d="M788.07,427.3v.89a2.46,2.46,0,0,1-.54,1.72,2.08,2.08,0,0,1-1.63.6,2.15,2.15,0,0,1-1.69-.65,2.54,2.54,0,0,1-.58-1.76v-4.38a2.54,2.54,0,0,1,.57-1.76,2.11,2.11,0,0,1,1.67-.64,2.14,2.14,0,0,1,1.65.61,2.51,2.51,0,0,1,.55,1.74v2.24h-3.58v2.3a1.71,1.71,0,0,0,.36,1.16,1.36,1.36,0,0,0,1.06.41c.89,0,1.34-.5,1.34-1.51v-1Zm-3.58-2.08h2.72v-1.61a1.77,1.77,0,0,0-.33-1.15,1.23,1.23,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.67,1.67,0,0,0-.35,1.15Z"
          fill="#bcbcbc"
        />
        <path
          d="M793.74,428.38a2.26,2.26,0,0,1-.51,1.57,2.12,2.12,0,0,1-1.64.56,2,2,0,0,1-1.57-.6,2.29,2.29,0,0,1-.52-1.59v-.89h.82v.92a1.68,1.68,0,0,0,.31,1,1.16,1.16,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.51,1.51,0,0,0,.31-1v-.29a1.59,1.59,0,0,0-.23-.85,4.15,4.15,0,0,0-.85-.86l-.92-.73a4.57,4.57,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.82v-.68a1.57,1.57,0,0,0-.29-1,1.2,1.2,0,0,0-.95-.33,1.18,1.18,0,0,0-.91.32,1.24,1.24,0,0,0-.28.86v.25a1.25,1.25,0,0,0,.05.4.93.93,0,0,0,.16.35,3.12,3.12,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,2,2,0,0,1,.24.56,3.28,3.28,0,0,1,.07.67Z"
          fill="#bcbcbc"
        />
        <polygon
          points="419.53 542.08 409.54 542.08 409.54 514.05 384.84 514.05 384.84 615.89 419.53 615.89 419.53 542.08"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="601.56 262.92 601.56 235.17 725.88 210.75 725.88 262.92 601.56 262.92"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="499.35 320.08 531.63 320.08 531.63 250.71 522.2 252.37 499.35 252.65 499.35 320.08"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b267ddbe-4626-4d01-b609-f881ae05f68d" data-name="2S.5">
        <polygon
          points="460.5 542.08 409.54 542.08 409.26 514.05 460.5 514.05 460.5 542.08"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="af00e421-af19-4f7b-9e19-f150eb8f5238" data-name="2S.4">
        <rect
          x="460.5"
          y="514.05"
          width="28"
          height="28.03"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e84af7ab-ad92-4cdb-a596-f4f503937826" data-name="2S.3">
        <rect
          x="488.5"
          y="514.05"
          width="32"
          height="28.03"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e2a2f051-bc36-457f-94bc-239830600a38" data-name="2S.2">
        <rect
          x="520.5"
          y="514.05"
          width="32"
          height="28.03"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a18ca3b0-9150-41dd-a835-a887611e9cbf" data-name="2S.1">
        <rect
          x="552.5"
          y="514.05"
          width="33"
          height="28.03"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b9737d87-c1e4-43b7-84b5-749d8badf18f" data-name="2C.3">
        <rect
          x="585.5"
          y="514.05"
          width="51.03"
          height="28.03"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="af8b4d1f-92e6-428c-84d2-f2b1561d5f9c" data-name="2C.2">
        <polygon
          points="709.5 578.5 603.78 578.5 603.78 542.08 680.37 542.08 680.37 565.94 709.5 565.94 709.5 578.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="fffb2a3e-1f1a-409b-9a20-e81166644752" data-name="2C.1">
        <rect
          x="603.78"
          y="578.5"
          width="105.72"
          height="37.39"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ed9d6df7-f692-41c5-9664-f29d61f1cfbb" data-name="2C.4">
        <rect
          x="636.5"
          y="496.5"
          width="44"
          height="45.58"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e3af74bb-fc34-4381-a853-b40bfb3d2912" data-name="2C.5">
        <rect
          x="636.5"
          y="463.5"
          width="44"
          height="33"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a85be2e3-f1ec-4090-8de3-650ad46bd6c4" data-name="2C.6">
        <rect
          x="636.5"
          y="431.5"
          width="44"
          height="32"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f94058d0-4858-4a48-8bdb-b98ba6e16c6c" data-name="2C.7">
        <polygon
          points="680.5 431.5 636.5 431.5 636.5 382.8 696.47 382.8 680.37 393.9 680.5 431.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b2a1c387-e8e8-41f3-bd56-b2cb2db16b25" data-name="2C.8">
        <rect
          x="636.53"
          y="347.83"
          width="59.94"
          height="34.96"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ed5cef11-31d2-4285-8d96-6658e8f94917" data-name="2C.9">
        <polygon
          points="652.5 347.83 696.47 347.83 696.47 262.92 685.92 262.92 685.92 320.08 652.5 320.08 652.5 347.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b13073af-f87c-4725-81ae-7688fc2a0f6a" data-name="2N.1">
        <rect
          x="617.66"
          y="320.08"
          width="34.84"
          height="27.42"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="af7f74bd-2a2a-4c5e-a2ea-4e1c6d3cfa2c" data-name="2N.2">
        <rect
          x="591.5"
          y="320.08"
          width="26.16"
          height="27.75"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bf410858-a367-45b7-9151-720e16e035f7" data-name="2N.3">
        <rect
          x="551.5"
          y="320.08"
          width="40"
          height="27.75"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f045f6a6-adb0-49b9-ade7-130f68d0bb4b" data-name="2N.4">
        <rect
          x="520.26"
          y="320.08"
          width="31.24"
          height="27.75"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f20d4d12-b9bc-4453-933f-de8f50805dca" data-name="2.20">
        <rect
          x="568.82"
          y="415.26"
          width="67.71"
          height="31.63"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ec6c32aa-d87a-4987-96d5-a82750f06254" data-name="2.1">
        <polygon
          points="384.84 252.37 384.84 514.05 409.26 514.05 471.42 514.05 471.42 480.2 504.16 480.2 504.16 446.9 568.82 446.9 568.82 415.26 568.82 383.63 568.82 347.83 520.26 347.83 520.26 320.08 499.35 320.08 499.35 252.65 384.84 252.37"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect
          x="341"
          y="420.5"
          width="175"
          height="10"
          transform="translate(3 854) rotate(-90)"
          fill="#ededed"
        />
        <rect x="423.5" y="328" width="95.5" height="10" fill="#ededed" />
      </g>
      <g id="b6775c42-00af-44d0-b845-5390317f88ee" data-name="2.2">
        <rect
          x="419.53"
          y="542.08"
          width="52.17"
          height="73.81"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ecb2b890-bcdb-4df2-855e-5c9d2ea77496" data-name="2.3">
        <rect
          x="471.69"
          y="542.08"
          width="32.74"
          height="73.81"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a4231855-e0f4-4d89-846a-467dd635d381" data-name="2.4">
        <rect
          x="504.44"
          y="542.08"
          width="32.19"
          height="73.81"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f1a64726-565a-46e1-b937-d24b2eb3ecd9" data-name="2.5">
        <polygon
          points="603.78 542.08 568.82 542.08 568.82 593.69 590.46 593.69 590.46 577.04 603.78 577.04 603.78 542.08"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M573.48,555.66h4.43v.72h-1.77v10h-.9v-10h-1.76Z"
          fill="#1e0f00"
        />
        <path
          d="M583.65,564.1a2.59,2.59,0,0,1-.57,1.76,2.12,2.12,0,0,1-1.67.65,2.1,2.1,0,0,1-1.66-.65,2.54,2.54,0,0,1-.58-1.76v-4.37a2.53,2.53,0,0,1,.58-1.77,2.12,2.12,0,0,1,1.66-.64,2.15,2.15,0,0,1,1.67.64,2.58,2.58,0,0,1,.57,1.77Zm-2.24,1.68a1.28,1.28,0,0,0,1-.41,1.71,1.71,0,0,0,.35-1.16v-4.59a1.71,1.71,0,0,0-.35-1.16,1.28,1.28,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.3,1.3,0,0,0,581.41,565.78Z"
          fill="#1e0f00"
        />
        <path
          d="M589.55,566.41h-.87v-.87h0a2.31,2.31,0,0,1-.54.66,1.57,1.57,0,0,1-1.05.31,1.87,1.87,0,0,1-1.42-.52,2.26,2.26,0,0,1-.5-1.62v-4.91a2.1,2.1,0,0,1,.55-1.62,1.89,1.89,0,0,1,1.37-.52,2.13,2.13,0,0,1,.6.08,1.55,1.55,0,0,1,.45.21,1.23,1.23,0,0,1,.31.29,2.54,2.54,0,0,1,.23.34h0v-2.58h.87ZM586,564.33a1.66,1.66,0,0,0,.3,1.08,1.15,1.15,0,0,0,1,.37,1.36,1.36,0,0,0,1-.39,1.53,1.53,0,0,0,.38-1.1V559.5a1.54,1.54,0,0,0-.42-1.07,1.34,1.34,0,0,0-1-.38c-.86,0-1.29.48-1.29,1.45Z"
          fill="#1e0f00"
        />
        <path d="M592,555.72l-.36,1.81H591l.16-1.87H592Z" fill="#1e0f00" />
        <path
          d="M597.52,564.38A2.26,2.26,0,0,1,597,566a2.11,2.11,0,0,1-1.63.56,2,2,0,0,1-1.58-.6,2.29,2.29,0,0,1-.52-1.59v-.89h.83v.92a1.68,1.68,0,0,0,.3,1,1.17,1.17,0,0,0,1,.38,1.25,1.25,0,0,0,1-.34,1.45,1.45,0,0,0,.31-1v-.29a1.59,1.59,0,0,0-.23-.85,3.91,3.91,0,0,0-.85-.86l-.92-.73a4.8,4.8,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.82v-.68a1.57,1.57,0,0,0-.29-1,1.49,1.49,0,0,0-1.86,0,1.24,1.24,0,0,0-.28.86v.25a1.62,1.62,0,0,0,.05.4.93.93,0,0,0,.16.35,3.12,3.12,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a3.33,3.33,0,0,1,.44.53,2.28,2.28,0,0,1,.23.56,3.28,3.28,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
      </g>
      <g id="f0f2b11c-1d46-492e-88f2-ef3f5c2b68d2" data-name="2.6">
        <rect
          x="536.63"
          y="542.08"
          width="32.19"
          height="34.96"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="adb64a4d-068f-40cb-a045-085172d538d8" data-name="2.6a">
        <polygon
          points="603.78 577.04 603.78 615.89 536.63 615.89 536.63 577.04 568.82 577.04 568.82 593.69 590.46 593.69 590.46 577.04 603.78 577.04"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="be94ef35-204a-4618-8ddb-d7a17d7afad0" data-name="2.20a">
        <rect
          x="601.56"
          y="446.9"
          width="34.96"
          height="33.02"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ad6ea9ea-5693-44d2-bbba-527a874509b1" data-name="2.21">
        <rect
          x="601.56"
          y="479.92"
          width="34.96"
          height="34.13"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b51001d3-8b89-4810-bf69-61991e0b4d7d" data-name="2.22">
        <polyline
          points="601.56 446.9 568.5 446.9 568.82 514.05 601.56 514.05 601.56 446.9 568.82 446.9"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b110be22-dfe6-42cf-be3d-ae6d8712759e" data-name="2.23">
        <rect
          x="536.63"
          y="446.9"
          width="32.19"
          height="67.15"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f32c7a44-60e6-46b9-a8f5-e9c7d2cc7d58" data-name="2.24">
        <rect
          x="504.16"
          y="446.9"
          width="32.47"
          height="67.15"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bc42d93a-981e-41bb-a15b-31c0616d7016" data-name="2.24a">
        <rect
          x="471.42"
          y="480.2"
          width="32.74"
          height="33.85"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ad805a96-2439-408e-84c1-d0884cf22cfe" data-name="2.19">
        <rect
          x="568.82"
          y="382.8"
          width="67.71"
          height="32.47"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a4e05391-42ea-4e53-9a6e-c1c0c2342adb" data-name="2.18">
        <rect
          x="601.56"
          y="347.83"
          width="34.96"
          height="34.96"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a14fc8d6-bd0e-4233-8e28-85f8d23a46e6" data-name="2.17">
        <rect
          x="568.82"
          y="347.83"
          width="32.74"
          height="34.96"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f2aa5704-15d7-4b4e-af69-6d7d0e23dcd0" data-name="2.15">
        <polygon
          points="531.63 320.08 568.82 320.08 568.82 242.38 531.63 250.71 531.63 320.08"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bd4bf271-8a41-4538-9c41-e7c9dee3876f" data-name="2.14">
        <polygon
          points="568.82 320.08 617.66 320.08 617.66 284.01 633.75 284.01 633.75 262.92 601.56 262.92 601.56 235.17 568.82 242.38 568.82 320.08"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="aca8df7a-8f64-4e3b-9f2b-e6b15e0fcec6" data-name="2.13">
        <polygon
          points="617.66 320.08 685.92 320.08 685.92 262.92 633.75 262.92 633.75 284.01 617.66 284.01 617.66 320.08"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M645.48,288.29h-2.84v5.12h-.91V282.66h.91v4.9h2.84v-4.9h.9v10.75h-.9Z"
          fill="#1e0f00"
        />
        <path
          d="M648.78,291.35c0,.95.41,1.43,1.23,1.43a1.34,1.34,0,0,0,.94-.34,1.36,1.36,0,0,0,.41-1v-7h.88v9h-.88v-.89h0a1.63,1.63,0,0,1-1.65,1,1.73,1.73,0,0,1-1.34-.51,2.41,2.41,0,0,1-.46-1.62v-7h.88Z"
          fill="#1e0f00"
        />
        <path
          d="M658.17,284.42v8.29a2.41,2.41,0,0,1-.52,1.62,2.12,2.12,0,0,1-1.67.61,2.26,2.26,0,0,1-1.66-.54,1.93,1.93,0,0,1-.53-1.4v-.43h.86V293a1.28,1.28,0,0,0,.31.93,1.31,1.31,0,0,0,1,.33,1.27,1.27,0,0,0,1-.38,1.59,1.59,0,0,0,.31-1v-1.7h0a2.06,2.06,0,0,1-.54.62,1.64,1.64,0,0,1-1.05.3,1.87,1.87,0,0,1-1.42-.52,2.26,2.26,0,0,1-.5-1.62v-3.45a2.1,2.1,0,0,1,.55-1.62,1.89,1.89,0,0,1,1.37-.52,2.13,2.13,0,0,1,.6.08,1.55,1.55,0,0,1,.45.21,1.23,1.23,0,0,1,.31.29,2.54,2.54,0,0,1,.23.34h0v-.82Zm-3.54,5.46a1.64,1.64,0,0,0,.3,1.07,1.15,1.15,0,0,0,1,.37,1.41,1.41,0,0,0,1-.37,1.52,1.52,0,0,0,.41-1V286.5a1.54,1.54,0,0,0-.42-1.07,1.34,1.34,0,0,0-1-.38c-.86,0-1.29.48-1.29,1.45Z"
          fill="#1e0f00"
        />
        <path
          d="M664.15,291.1a2.59,2.59,0,0,1-.57,1.76,2.46,2.46,0,0,1-3.33,0,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.49,2.49,0,0,1,3.33,0,2.58,2.58,0,0,1,.57,1.77Zm-2.24,1.68a1.31,1.31,0,0,0,1-.41,1.76,1.76,0,0,0,.34-1.16v-4.59a1.76,1.76,0,0,0-.34-1.16,1.31,1.31,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.3,1.3,0,0,0,661.91,292.78Z"
          fill="#1e0f00"
        />
        <path
          d="M641.73,297.66h2.08a2.22,2.22,0,0,1,1.64.51,2,2,0,0,1,.5,1.5v1.48a2,2,0,0,1-.26,1.09,1.26,1.26,0,0,1-.86.57,1.56,1.56,0,0,1,1.41,1.73v1.86a2,2,0,0,1-.52,1.5,2.27,2.27,0,0,1-1.66.51h-2.33Zm.91.72v4.11h1.14c.88,0,1.32-.42,1.32-1.27v-1.56c0-.85-.46-1.28-1.37-1.28Zm1.33,9.3c.92,0,1.38-.42,1.38-1.27v-1.94a1.2,1.2,0,0,0-.33-.93,1.57,1.57,0,0,0-1.08-.32h-1.3v4.46Z"
          fill="#1e0f00"
        />
        <path
          d="M652.09,306.1a2.59,2.59,0,0,1-.57,1.76,2.1,2.1,0,0,1-1.66.65,2.12,2.12,0,0,1-1.67-.65,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.15,2.15,0,0,1,1.67-.64,2.12,2.12,0,0,1,1.66.64,2.58,2.58,0,0,1,.57,1.77Zm-2.23,1.68a1.3,1.3,0,0,0,1-.41,1.76,1.76,0,0,0,.35-1.16v-4.59a1.76,1.76,0,0,0-.35-1.16,1.5,1.5,0,0,0-2.06,0,1.71,1.71,0,0,0-.35,1.16v4.59a1.71,1.71,0,0,0,.35,1.16A1.3,1.3,0,0,0,649.86,307.78Z"
          fill="#1e0f00"
        />
        <path
          d="M657.76,306.38a2.26,2.26,0,0,1-.51,1.57,2.11,2.11,0,0,1-1.63.56,2,2,0,0,1-1.58-.6,2.29,2.29,0,0,1-.52-1.59v-.89h.83v.92a1.68,1.68,0,0,0,.3,1,1.17,1.17,0,0,0,1,.38,1.29,1.29,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.59,1.59,0,0,0-.23-.85,3.91,3.91,0,0,0-.85-.86l-.92-.73a4.8,4.8,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.82v-.68a1.57,1.57,0,0,0-.29-1,1.2,1.2,0,0,0-.95-.33,1.16,1.16,0,0,0-.9.32,1.2,1.2,0,0,0-.29.86v.25a1.62,1.62,0,0,0,.05.4.93.93,0,0,0,.16.35,3.12,3.12,0,0,0,.31.35c.13.12.29.26.5.42l.92.73c.26.21.48.41.67.59a2.87,2.87,0,0,1,.44.53,2.28,2.28,0,0,1,.23.56,3.28,3.28,0,0,1,.07.67Z"
          fill="#1e0f00"
        />
        <path
          d="M663.38,306.38a2.26,2.26,0,0,1-.51,1.57,2.14,2.14,0,0,1-1.64.56,2,2,0,0,1-1.58-.6,2.34,2.34,0,0,1-.51-1.59v-.89H660v.92a1.62,1.62,0,0,0,.31,1,1.16,1.16,0,0,0,1,.38,1.26,1.26,0,0,0,1-.34,1.5,1.5,0,0,0,.3-1v-.29a1.58,1.58,0,0,0-.22-.85,4.15,4.15,0,0,0-.85-.86l-.93-.73a4.72,4.72,0,0,1-1-1,2,2,0,0,1-.33-1.17v-.25a2,2,0,0,1,.49-1.4,2,2,0,0,1,1.53-.54,2,2,0,0,1,1.58.54,2.29,2.29,0,0,1,.48,1.57v.65h-.83v-.68a1.57,1.57,0,0,0-.28-1,1.2,1.2,0,0,0-.95-.33,1.17,1.17,0,0,0-.91.32,1.24,1.24,0,0,0-.28.86v.25a1.64,1.64,0,0,0,0,.4,1.14,1.14,0,0,0,.17.35,2.36,2.36,0,0,0,.31.35c.13.12.29.26.49.42l.92.73c.27.21.49.41.68.59a2.78,2.78,0,0,1,.43.53,1.7,1.7,0,0,1,.23.56,2.67,2.67,0,0,1,.08.67Z"
          fill="#1e0f00"
        />
      </g>
      <g id="e15f7830-51ae-44cf-a0c0-9a8c3a96e648" data-name="2.12a">
        <rect
          x="696.47"
          y="320.08"
          width="29.41"
          height="30.52"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ae5f4971-584f-40db-9bc6-5314283a21ac" data-name="2.12b">
        <rect
          x="696.47"
          y="350.61"
          width="29.41"
          height="32.19"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bba6200b-9abd-4139-9993-fe3337c0bda3" data-name="2.11">
        <polygon
          points="680.37 414.99 680.37 393.9 696.47 382.8 725.88 382.8 725.88 414.99 680.37 414.99"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b44d4b1b-4175-4a1a-9864-93234d445a00" data-name="2.10">
        <rect
          x="680.37"
          y="414.99"
          width="45.51"
          height="50.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="eead2be3-364b-4fbc-a5ca-1f43b1bd3ce0" data-name="2.8">
        <rect
          x="680.37"
          y="465.49"
          width="45.51"
          height="14.43"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a515dfbb-a7fd-4082-9bad-8f996f1cf6bc" data-name="2.9">
        <rect
          x="680.37"
          y="479.92"
          width="45.51"
          height="31.63"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M692.75,483.66v8.63a2.45,2.45,0,0,1-.5,1.62,1.94,1.94,0,0,1-1.6.6,1.86,1.86,0,0,1-1.58-.6,2.47,2.47,0,0,1-.45-1.54v-2.18h.87v2.2a1.72,1.72,0,0,0,.27,1,1,1,0,0,0,.89.38,1,1,0,0,0,.91-.38,1.69,1.69,0,0,0,.28-1v-8.72Z"
          fill="#1e0f00"
        />
        <path
          d="M694.27,483.66h.87v.79h-.87Zm0,1.76h.87v9h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M699.93,487.42a1.64,1.64,0,0,0-.26-1,1,1,0,0,0-.86-.35,1.2,1.2,0,0,0-.9.37,1.48,1.48,0,0,0-.34,1v7h-.87v-9h.87v.87h0a1.57,1.57,0,0,1,1.53-1,1.49,1.49,0,0,1,1.54,1.06h0a1.61,1.61,0,0,1,.59-.76,1.77,1.77,0,0,1,1.06-.3c1.13,0,1.69.69,1.69,2.06v7h-.87v-7a1.7,1.7,0,0,0-.26-1,1,1,0,0,0-.87-.35,1.17,1.17,0,0,0-.85.35,1.34,1.34,0,0,0-.38.94v7.07h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M708.8,487.42a1.64,1.64,0,0,0-.26-1,1,1,0,0,0-.86-.35,1.2,1.2,0,0,0-.9.37,1.48,1.48,0,0,0-.34,1v7h-.87v-9h.87v.87h0a1.57,1.57,0,0,1,1.53-1,1.49,1.49,0,0,1,1.54,1.06h0a1.61,1.61,0,0,1,.59-.76,1.77,1.77,0,0,1,1.06-.3c1.13,0,1.69.69,1.69,2.06v7H712v-7a1.7,1.7,0,0,0-.26-1,1,1,0,0,0-.87-.35,1.17,1.17,0,0,0-.85.35,1.34,1.34,0,0,0-.38.94v7.07h-.88Z"
          fill="#1e0f00"
        />
        <path
          d="M718.74,485.42l-2.13,9.1a2.66,2.66,0,0,1-.2.62,1.21,1.21,0,0,1-.31.42,1.08,1.08,0,0,1-.47.22,3.06,3.06,0,0,1-.65.06h-.45v-.73H715a.86.86,0,0,0,.6-.17,1.55,1.55,0,0,0,.29-.62l.21-.78h-.21L714,485.42h.89l1.46,6.69h0l1.49-6.69Z"
          fill="#1e0f00"
        />
        <path
          d="M691.11,509.51a2.45,2.45,0,0,1-1.1-.22,1.94,1.94,0,0,1-.74-.58,2.37,2.37,0,0,1-.42-.84,3.9,3.9,0,0,1-.13-1v-5.59a4,4,0,0,1,.13-1.05,2.42,2.42,0,0,1,.42-.85,1.82,1.82,0,0,1,.74-.57,2.6,2.6,0,0,1,1.1-.21,3.16,3.16,0,0,1,1.11.17,1.68,1.68,0,0,1,.7.5,2,2,0,0,1,.36.79,4.64,4.64,0,0,1,.1,1v1.26h-.89V501a2.32,2.32,0,0,0-.27-1.22,1.17,1.17,0,0,0-1.09-.45,1.62,1.62,0,0,0-.7.14,1.38,1.38,0,0,0-.47.39,1.61,1.61,0,0,0-.26.58,3.06,3.06,0,0,0-.08.7V507a3.13,3.13,0,0,0,.08.7,1.59,1.59,0,0,0,.26.59,1.26,1.26,0,0,0,.47.38,1.48,1.48,0,0,0,.7.15,1.17,1.17,0,0,0,1.1-.47,2.31,2.31,0,0,0,.28-1.21v-1.91h.87V507a4.34,4.34,0,0,1-.1,1,1.88,1.88,0,0,1-.37.78,1.65,1.65,0,0,1-.7.53A2.86,2.86,0,0,1,691.11,509.51Z"
          fill="#1e0f00"
        />
        <path
          d="M698.33,502.48a1.69,1.69,0,0,0-.29-1.07,1.16,1.16,0,0,0-.95-.36,1.33,1.33,0,0,0-1,.37,1.4,1.4,0,0,0-.38,1.08v6.91h-.88V498.66h.88v2.64h0a2,2,0,0,1,.59-.69,1.73,1.73,0,0,1,1.06-.29,1.69,1.69,0,0,1,1.33.51,2.36,2.36,0,0,1,.46,1.62v7h-.87Z"
          fill="#1e0f00"
        />
        <path
          d="M705.17,507.1a2.59,2.59,0,0,1-.57,1.76,2.12,2.12,0,0,1-1.67.65,2.1,2.1,0,0,1-1.66-.65,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.12,2.12,0,0,1,1.66-.64,2.15,2.15,0,0,1,1.67.64,2.58,2.58,0,0,1,.57,1.77Zm-2.24,1.68a1.28,1.28,0,0,0,1-.41,1.71,1.71,0,0,0,.35-1.16v-4.59a1.71,1.71,0,0,0-.35-1.16,1.28,1.28,0,0,0-1-.41,1.3,1.3,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.3,1.3,0,0,0,702.93,508.78Z"
          fill="#1e0f00"
        />
        <path
          d="M711.14,507.1a2.54,2.54,0,0,1-.58,1.76,2.1,2.1,0,0,1-1.66.65,2.12,2.12,0,0,1-1.67-.65,2.59,2.59,0,0,1-.57-1.76v-4.37a2.58,2.58,0,0,1,.57-1.77,2.15,2.15,0,0,1,1.67-.64,2.12,2.12,0,0,1,1.66.64,2.53,2.53,0,0,1,.58,1.77Zm-2.24,1.68a1.29,1.29,0,0,0,1-.41,1.76,1.76,0,0,0,.35-1.16v-4.59a1.76,1.76,0,0,0-.35-1.16,1.29,1.29,0,0,0-1-.41,1.27,1.27,0,0,0-1,.41,1.71,1.71,0,0,0-.36,1.16v4.59a1.71,1.71,0,0,0,.36,1.16A1.27,1.27,0,0,0,708.9,508.78Z"
          fill="#1e0f00"
        />
      </g>
      <g id="b17f2da3-6952-43b4-a229-ef34c8017f65" data-name="2.7">
        <rect
          x="680.37"
          y="511.55"
          width="45.51"
          height="54.39"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f21ad02e-c972-4706-8436-04b5145f3d85" data-name="WC">
        <rect
          x="696.47"
          y="262.92"
          width="29.41"
          height="57.58"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M707.69,285.75l-1.36,8.35h-1l-1.46-10.76h.89l1.13,8.66h0l1.38-8.66h.84L709.5,292h0l1.14-8.62h.83L710,294.1h-1l-1.33-8.35Z"
          fill="#ff4d00"
        />
        <path
          d="M715.17,294.19a2.65,2.65,0,0,1-1.11-.21,2,2,0,0,1-.73-.58,2.42,2.42,0,0,1-.42-.85,3.84,3.84,0,0,1-.14-1v-5.58a4,4,0,0,1,.14-1.05,2.47,2.47,0,0,1,.42-.86,2,2,0,0,1,.73-.57,2.65,2.65,0,0,1,1.11-.21,3,3,0,0,1,1.11.18,1.66,1.66,0,0,1,.7.5,2,2,0,0,1,.35.78,4.62,4.62,0,0,1,.11,1V287h-.89v-1.34a2.39,2.39,0,0,0-.27-1.23,1.18,1.18,0,0,0-1.1-.45,1.51,1.51,0,0,0-.7.15,1.16,1.16,0,0,0-.46.39,1.67,1.67,0,0,0-.27.57,3.17,3.17,0,0,0-.07.71v5.86a3.2,3.2,0,0,0,.07.7,1.82,1.82,0,0,0,.27.58,1.35,1.35,0,0,0,.46.39,1.51,1.51,0,0,0,.7.14,1.18,1.18,0,0,0,1.11-.47,2.37,2.37,0,0,0,.27-1.2v-1.91h.88v1.84a4.23,4.23,0,0,1-.11,1,2,2,0,0,1-.36.79,1.76,1.76,0,0,1-.7.52A2.71,2.71,0,0,1,715.17,294.19Z"
          fill="#ff4d00"
        />
      </g>
      <g id="ff68440a-f57c-45ea-a3e1-84f7ed8b3ec9" data-name="Icons">
        <polyline
          points="446 375.5 452.94 375.5 466.33 361 473.27 361"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="457.78"
          cy="358.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="456.59 371.41 456.59 361.64 458.97 361.64 458.97 369.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="653 366.5 659.94 366.5 673.33 352 680.27 352"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="664.78"
          cy="349.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="663.59 362.41 663.59 352.64 665.97 352.64 665.97 360.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="607 537.5 613.94 537.5 627.33 523 634.27 523"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="618.78"
          cy="520.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="617.59 533.41 617.59 523.64 619.97 523.64 619.97 531.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <circle
          cx="687.95"
          cy="596.78"
          r="1.64"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="686.26 610.88 686.26 600.25 689.64 600.25 689.64 610.88"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="680.81 587.19 684 584 684.1 584 687.29 587.19"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="695.09 584 691.9 587.19 691.8 587.19 688.61 584"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="680.29"
          y="591"
          width="15.32"
          height="20"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <path
          d="M628.43,596.41l2.13-10.75h1.11l2.16,10.75h-.92l-.51-2.63h-2.6l-.5,2.63Zm1.51-3.36h2.31l-1.15-6h0Z"
          fill="#bcbcbc"
        />
        <path
          d="M634.81,587.42h.7v-1.76h.87v1.76h1.25v.66h-1.25v6.86a.82.82,0,0,0,.17.58.83.83,0,0,0,.59.16h.49v.73h-.58a1.67,1.67,0,0,1-1.2-.34,1.58,1.58,0,0,1-.34-1.15v-6.84h-.7Z"
          fill="#bcbcbc"
        />
        <path
          d="M641.5,588.32h-.14a1.49,1.49,0,0,0-1.05.36,1.41,1.41,0,0,0-.39,1v6.69h-.87v-9h.87v1.11h0a2.06,2.06,0,0,1,.21-.42,1.79,1.79,0,0,1,.31-.37,1.68,1.68,0,0,1,.45-.27,1.5,1.5,0,0,1,.58-.1Z"
          fill="#bcbcbc"
        />
        <path
          d="M642.85,585.66h.88v.79h-.88Zm0,1.76h.88v9h-.88Z"
          fill="#bcbcbc"
        />
        <path
          d="M646.12,594.35c0,.95.41,1.43,1.24,1.43a1.36,1.36,0,0,0,.94-.34,1.4,1.4,0,0,0,.41-1v-7h.87v9h-.87v-.89h0a1.89,1.89,0,0,1-.6.73,1.85,1.85,0,0,1-1.05.26,1.7,1.7,0,0,1-1.33-.51,2.36,2.36,0,0,1-.46-1.62v-7h.87Z"
          fill="#bcbcbc"
        />
        <path
          d="M654.37,589.42a1.64,1.64,0,0,0-.26-1,1,1,0,0,0-.86-.35,1.2,1.2,0,0,0-.9.37,1.48,1.48,0,0,0-.34,1v6.95h-.87v-9H652v.87h0a1.71,1.71,0,0,1,.55-.68,1.6,1.6,0,0,1,1-.29,1.49,1.49,0,0,1,1.54,1.06h0a1.7,1.7,0,0,1,.6-.76,1.76,1.76,0,0,1,1-.3c1.14,0,1.7.69,1.7,2.06v7h-.87v-7a1.7,1.7,0,0,0-.26-1,1,1,0,0,0-.87-.35,1.15,1.15,0,0,0-.85.35,1.34,1.34,0,0,0-.38.94v7.07h-.88Z"
          fill="#bcbcbc"
        />
      </g>
    </svg>
  </MapTransformer>
);

SecondFloor.defaultProps = {
  mapWidth: 1418.89,
  mapHeight: 1001.28,
};

SecondFloor.propTypes = {
  mapHeight: PropTypes.number,
  mapWidth: PropTypes.number,
  MapTransformer: PropTypes.func.isRequired,
};

export default SecondFloor;
