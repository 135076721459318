import { VIDEO_VIMEO_URL_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import PropTypes from 'prop-types';
import React from 'react';

import Label from '@/features/campaignPage/Page/Label';

import { buildPlayerUrl } from './utils';

const Video = ({ payload: { [VIDEO_VIMEO_URL_KEY]: vimeoUrl } }) => (
  <Label title="Видео">
    <iframe
      src={buildPlayerUrl(vimeoUrl)}
      width="640"
      height="360"
      frameBorder="0"
      webkitallowfullscreen
      mozallowfullscreen
      title
      allowFullScreen
    />
  </Label>
);

Video.propTypes = {
  payload: PropTypes.object.isRequired,
};

export default Video;
