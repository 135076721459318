import DateFnsUtils from '@date-io/date-fns';
import {
  createGenerateClassName,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import ruLocale from 'date-fns/locale/ru';
import { create } from 'jss';
import preset from 'jss-preset-default';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import JssProvider from 'react-jss/lib/JssProvider';
import { Provider } from 'react-redux';

import Router from './Router';
import theme from './theme';

const generateClassName = createGenerateClassName();
const jss = create(preset());

const Root = ({ store }) => (
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Provider store={store}>
          <Router />
        </Provider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </JssProvider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default hot(module)(Root);
