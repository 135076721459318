export default () => ({
  sidebar: {
    height: '100%',
    width: 300,
    // transition: 'all 0.3s',
  },
  wrapper: {
    position: 'fixed',
    height: '100%',
    width: 330,
    // transition: 'all 0.3s',
  },
  sideExpanded: {
    width: 55,
  },
  wrapExpanded: {
    width: 55,
    overflowY: 'hidden',
  },
});
