export default ({ spacing }) => ({
  root: {
    width: '100%',
    maxWidth: spacing.unit * 35,
    minHeight: spacing.unit * 15,
    margin: [spacing.unit * 6, 'auto'],
  },

  text: {
    display: 'flex',
    alignItems: 'center',
  },
});
