export const MARGIN_TOP = 30;

export const MARGIN_RIGHT = 50;

export const MARGIN_BOTTOM = 30;

export const MARGIN_LEFT = 50;

export const HEIGHT = 350;

export const MARGINS = {
  top: MARGIN_TOP,
  right: MARGIN_RIGHT,
  bottom: MARGIN_BOTTOM,
  left: MARGIN_LEFT,
};

export default {
  root: {
    position: 'relative',
    height: HEIGHT,
    marginBottom: 16,
    marginTop: 16,
    width: '100%',
  },

  chartContainer: {
    position: 'absolute',
    display: 'flex',
    left: -MARGIN_LEFT,
    width: `calc(100% + ${MARGIN_LEFT + MARGIN_RIGHT}px)`,
  },
};
