import api from '@esentai/core/features/merchantsReconciliationPayment/api';
import { Button, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

import NoMatchMessage from '../../../../components/CommonTable/NoMatchMessage';

interface RowData {
  id: number;
  operation: string;
  comment: string;
  user_id: number;
  user: string;
  new_data: any; // TODO: Define a generic for this
  record_created: string;
}

export interface RepaymentTableProps {
  detailId: number;
  headers: string[];
  uploadExcel: () => void;
  children: (data: any) => JSX.Element;
}

export interface RepaymentTableState {
  limit: number;
  page: number;
  data: RowData[];
  total: number;
}

const styles = {
  tbody: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
};

export class RepaymentTable extends React.Component<
  RepaymentTableProps,
  RepaymentTableState
> {
  constructor(props: RepaymentTableProps) {
    super(props);

    this.state = {
      limit: 0,
      page: 0,
      total: 0,
      data: [],
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.loadData();
  }

  public render(): JSX.Element {
    const { headers, uploadExcel } = this.props;
    const { data } = this.state;

    return (
      <div style={{ marginTop: 15 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Детализация по оплате</Typography>
          <Button
            variant="contained"
            color="primary"
            size={'large'}
            onClick={uploadExcel}
          >
            Вывести в EXCEL
          </Button>
        </div>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={styles.tbody}>{this.renderBody(data)}</TableBody>
        </Table>
      </div>
    );
  }

  public renderBody(data: any[]): JSX.Element {
    const { children } = this.props;

    if (data.length > 0) {
      return children(data);
    }

    return <NoMatchMessage columnsCount={100} />;
  }

  private async loadData(): Promise<void> {
    const { limit, page } = this.state;
    const { detailId } = this.props;
    const filters = [['merchant', 'eq', detailId]];

    const data = await api.doQuery(filters, 'id', 'asc', limit, page);

    if (data.payload.merchant_reconciliation_payment.length > 0) {
      this.setState({
        total: data.meta.total,
        data: data.payload.merchant_reconciliation_payment,
      });
    }
  }
}
