import {
  PARAGRAPH_ENGLISH_TEXT_KEY,
  PARAGRAPH_KAZAKH_TEXT_KEY,
  PARAGRAPH_RUSSIAN_TEXT_KEY,
} from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import PropTypes from 'prop-types';
import React from 'react';

import {
  LOCALE_EN,
  LOCALE_KZ,
  LOCALE_RU,
} from '@/hocs/withDevicePreview/consts';

const ParagraphBlock = ({ classes, payload, selectByLocale }) => {
  const title = selectByLocale({
    [LOCALE_RU]: payload[PARAGRAPH_RUSSIAN_TEXT_KEY],
    [LOCALE_EN]: payload[PARAGRAPH_ENGLISH_TEXT_KEY],
    [LOCALE_KZ]: payload[PARAGRAPH_KAZAKH_TEXT_KEY],
  });

  return <div className={classes.text}>{title}</div>;
};

ParagraphBlock.propTypes = {
  payload: PropTypes.object.isRequired,
  selectByLocale: PropTypes.func.isRequired,
};

export default ParagraphBlock;
