export default {
  root: {
    marginRight: 16,
  },

  smallRoot: {},

  icons: {
    marginLeft: 10,
  },
};
