import {
  ACTIVE_BONUSES_KEY,
  BONUS_COST_KEY,
  BONUS_PERCENT_KEY,
  CASH_MACHINE_KEY,
  FISCAL_ID_KEY,
  ID_KEY,
  MONEY_COST_KEY,
  PENDING_BONUSES_KEY,
  REFUND_BONUS_COST,
  REFUND_MONEY_COST,
  STATUS_KEY,
  STORE_NAME_KEY,
  TIMESTAMP_KEY,
  TOTAL_COST_KEY,
} from '@esentai/core/features/purchases/consts/keys';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const HeadRow = ({ SortControl }) => (
  <TableRow>
    <TableCell>
      <SortControl field={ID_KEY}>ID</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={CASH_MACHINE_KEY}>Номер ККМ</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={FISCAL_ID_KEY}>UUID</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={STORE_NAME_KEY}>Магазин</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={TIMESTAMP_KEY}>Дата и время покупки</SortControl>
    </TableCell>
    <TableCell align="right">
      <SortControl field={TOTAL_COST_KEY}>Общая сумма чека</SortControl>
    </TableCell>
    <TableCell align="right">
      <SortControl field={BONUS_PERCENT_KEY}>
        Процент начисления бонусов
      </SortControl>
    </TableCell>
    <TableCell align="right">
      <SortControl field={PENDING_BONUSES_KEY}>
        Сумма бонусов к начислению
      </SortControl>
    </TableCell>
    <TableCell align="right">
      <SortControl field={ACTIVE_BONUSES_KEY}>
        Сумма начисленных бонусов
      </SortControl>
    </TableCell>
    <TableCell align="right">
      <SortControl field={STATUS_KEY}>Способ оплаты</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={BONUS_COST_KEY}>Оплата бонусами</SortControl>
    </TableCell>

    <TableCell>
      <SortControl field={MONEY_COST_KEY}>Оплата деньгами</SortControl>
    </TableCell>
    <TableCell>
      <SortControl field={REFUND_BONUS_COST}>Возврат бонусов</SortControl>
    </TableCell>

    <TableCell>
      <SortControl field={REFUND_MONEY_COST}>Возврат денег</SortControl>
    </TableCell>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
