import { findUserById } from '@esentai/core/features/users/actions';
import {
  getFullNameForUser,
  getUserById,
  getUserLoadingError,
  isLoadingUser,
} from '@esentai/core/features/users/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { isMallManager } from '@/features/currentUser/selectors';
import queryDuck from '@/features/userPage/ducks/query';
import {
  getPurchasesError,
  getPurchasesIds,
  getPurchasesTotalCount,
  isLoadingPurchases,
} from '@/features/userPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';
import withQuerySync from '@/hocs/withQuerySync';
import { getRouterParameterByMatch } from '@/redux/router/selectors';

import User from './component';
import styles from './styles';

const mapStateToProps = (state, { match }) => {
  const userId = getRouterParameterByMatch(match, 'userId');

  return {
    error: getUserLoadingError(state, userId),
    isLoading: isLoadingUser(state, userId),
    itemId: userId,
    item: getUserById(state, userId),
    name: getFullNameForUser(state, userId),
    receiptsError: getPurchasesError(state),
    receiptsIds: getPurchasesIds(state),
    isLoadingReceipts: isLoadingPurchases(state),
    receiptsTotalCount: getPurchasesTotalCount(state),
    isMallManager: isMallManager(state),
  };
};

const mapDispatchToProps = {
  fetchItem: findUserById,
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
  withStyles(styles),
)(User);
