import { MAIN_FEED_LAYOUT_KEY } from '@esentai/core/features/applicationSettings/consts/keys';
import { getMainFeedLayout } from '@esentai/core/features/applicationSettings/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { object } from 'yup';

import { createLayout } from '@/features/feedLayoutEditPage/structs/layout';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import Form from './component';
import layoutValidationSchema from './schema';
import styles from './styles';

const errorsMapping = {};

const formikOptions = {
  mapPropsToValues: ({ layout }) => ({
    [MAIN_FEED_LAYOUT_KEY]: layout ? createLayout(...layout) : createLayout(),
  }),

  validationSchema: object().shape({
    [MAIN_FEED_LAYOUT_KEY]: layoutValidationSchema,
  }),

  handleSubmit(values, { props, setSubmitting }) {
    props.onSubmit(values);
    setSubmitting(false);
  },
};

const asyncOptions = {
  runnerMethod: 'onSubmit',
};

const mapStateToProps = state => ({
  layout: getMainFeedLayout(state),
});

export default compose(
  connect(mapStateToProps),
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(Form);
