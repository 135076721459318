import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { isStoreSeller } from '@/features/currentUser/selectors';
import queryDuck from '@/features/efsVisitRecordsPage/ducks/query';
import withQuerySync from '@/hocs/withQuerySync';

import {
  getEfsVisitRecordError,
  getEfsVisitRecordIds,
  getEfsVisitRecordsTotalCount,
  isLoadingEfsVisitRecords,
} from '../selectors';
import EfsVisitRecordsPage from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getEfsVisitRecordError(state),
  efsVisitRecordIds: getEfsVisitRecordIds(state),
  isLoading: isLoadingEfsVisitRecords(state),
  totalCount: getEfsVisitRecordsTotalCount(state),
  isStoreSeller: isStoreSeller(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(EfsVisitRecordsPage);
