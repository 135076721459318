import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { createUserPageUrl } from '@/features/userPage/utils';
import { dateTimeFormatExact, formatMoney } from '@/utils/format';

const loyaltyLevels = {
  1: 'Orange',
  2: 'Black',
  3: 'JetBlack',
};

const paymentStatuses = {
  0: 'Не оплачено',
  1: 'Оплачено',
};

const statuses = {
  1: 'Парковщик принял машину',
  2: 'Машина припаркована',
  3: 'Клиент выезжает',
  4: 'Парковщик выехал и ожидает клиента',
  5: 'Клиент уехал',
};

const Row = props => {
  const {
    id,
    customer,
    order,
    record_created,
    entry_date_time,
    payment_time,
    exit_date_time,
    valet,
    valet_price,
    valet_on_start,
    parking_price,
    vehicle_model,
    payment_by_app,
    vehicle_gov_number,
    temporary_gov_number,
    temporary_vehicle_model,
    debt_amount,
    status,
  } = props.item;

  // const getAmountMoney = () => {
  //   const orderResult = Boolean(order.result);

  //   if (!orderResult || order.amount === 0) {
  //     return '-';
  //   }

  //   return formatMoney(order.amount);
  // };

  // const getDebtMoney = () => {
  //   const orderResult = Boolean(order.result);

  //   if (orderResult || order.amount === 0) {
  //     return '-';
  //   }

  //   return formatMoney(order.amount);
  // };

  const getCarInfo = (model, number) => {
    if (!model && !number) {
      return '-';
    }

    return `${model} ${number}`;
  };

  const getPaymentByApp = paymentByApp => {
    if (paymentByApp === null) {
      return '-';
    }

    return paymentByApp ? 'Приложение' : 'Неприложение';
  };

  const getValetFullName = valetObj => (valetObj ? valetObj.full_name : '-');

  const getReceivedMoneyAmount = () => {
    if (!order || order.result === 0) {
      return '-';
    }

    return formatMoney(order.amount);
  };

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{order.id}</TableCell>
      <TableCell>
        <Link to={createUserPageUrl(customer.id)}>{customer.id}</Link>
      </TableCell>
      <TableCell>{loyaltyLevels[customer.level]}</TableCell>
      <TableCell> {getCarInfo(vehicle_model, vehicle_gov_number)}</TableCell>
      <TableCell>
        {getCarInfo(temporary_vehicle_model, temporary_gov_number)}
      </TableCell>
      <TableCell>
        {record_created
          ? dateTimeFormatExact(record_created, 'DD-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>
        {entry_date_time
          ? dateTimeFormatExact(entry_date_time, 'DD-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>
        {payment_time
          ? dateTimeFormatExact(payment_time, 'DD-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>
        {exit_date_time
          ? dateTimeFormatExact(exit_date_time, 'DD-MM-yyyy HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>{formatMoney(valet_price)}</TableCell>
      <TableCell>{formatMoney(parking_price)}</TableCell>
      <TableCell>{debt_amount ? formatMoney(debt_amount) : '-'}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{statuses[status]}</TableCell>
      <TableCell>{getPaymentByApp(payment_by_app)}</TableCell>
      <TableCell>{paymentStatuses[order.result] || 'В процессе'}</TableCell>
      <TableCell>{getReceivedMoneyAmount()}</TableCell>
      <TableCell>{getValetFullName(valet_on_start)}</TableCell>
      <TableCell>{getValetFullName(valet)}</TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Row;
