import { Tooltip } from '@material-ui/core';
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

const IconTooltip = ({ classes, children, title, Icon }) => (
  <Tooltip title={title}>
    <div className={classes.tooltip}>
      {children}
      <Icon className={classes.icon} />
    </div>
  </Tooltip>
);

IconTooltip.defaultProps = {
  Icon: HelpOutlineIcon,
};

IconTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
};

export default IconTooltip;
