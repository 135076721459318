import PropTypes from 'prop-types';
import React from 'react';

const FourthFloor = ({ mapHeight, mapWidth, MapTransformer, ...props }) => (
  <MapTransformer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${mapWidth} ${mapHeight}`}
      {...props}
    >
      <g id="b075b9fe-22fc-4f46-afd1-4164ee39bb98" data-name="Background">
        <rect y="1" width="1424.89" height="1000.28" fill="#ededed" />
        <rect x="880" y="188" width="78" height="432" fill="#f7f7f7" />
        <rect y="661" width="1424.89" height="24" fill="#f7f7f7" />
        <rect y="698" width="1424.89" height="24" fill="#f7f7f7" />
        <path
          d="M911.34,337.85v-3.74H912V337h4v-2.08h.69V337h4.14v-2.88h.69v3.74Z"
          fill="#888685"
        />
        <path
          d="M919.59,328.82a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.54,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84V332h.87a1.55,1.55,0,0,0,1-.28,1.11,1.11,0,0,0,.36-.92,1.21,1.21,0,0,0-.32-.94,1.43,1.43,0,0,0-1-.29h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.83,1.83,0,0,1-.51-1.44,1.94,1.94,0,0,1,.51-1.5,2.19,2.19,0,0,1,1.49-.45h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.08,1.08,0,0,0,.3.85,1.17,1.17,0,0,0,.81.27H915a1.61,1.61,0,0,0,.39,0,1.25,1.25,0,0,0,.33-.16,3.8,3.8,0,0,0,.33-.29c.11-.12.24-.28.39-.47l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.93,2.93,0,0,1,.63-.06Z"
          fill="#888685"
        />
        <path
          d="M918.57,323.25h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.55,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.41,2.41,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.34,1.28,1.28,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.32,1.16,1.16,0,0,0-.39,1,1.19,1.19,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M915,318.54a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.28,1.28,0,0,0,.35.91,1.35,1.35,0,0,0,1,.37h6.54v.82H913V321h.84v0a1.84,1.84,0,0,1-.66-.55,1.66,1.66,0,0,1-.27-1,1.61,1.61,0,0,1,.48-1.26,2.24,2.24,0,0,1,1.53-.43h6.59v.82Z"
          fill="#888685"
        />
        <path
          d="M913,316.53v-.66h-1.66v-.82H913v-1.19h.63v1.19h6.49a.71.71,0,0,0,.54-.16.75.75,0,0,0,.16-.56v-.47h.69v.56a1.59,1.59,0,0,1-.32,1.13,1.5,1.5,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M921.51,309.36h-.77v0a1.74,1.74,0,0,1,.6.53,1.59,1.59,0,0,1,.26.94,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H915a1.83,1.83,0,0,0-1,.23.94.94,0,0,0-.35.85,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.8h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.31a1.23,1.23,0,0,0-1.35-1.31h-3a.57.57,0,0,1,.14.13l.17.23q.09.13.21.36c.08.15.18.34.29.57a5.89,5.89,0,0,0,.28.52,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.86,1.86,0,0,0,.56.07h.65a1.58,1.58,0,0,0,1-.27A1,1,0,0,0,920.91,310.67Z"
          fill="#888685"
        />
        <path
          d="M911.34,307.11v-.82h.75v.82Zm1.66,0v-.82h8.51v.82Z"
          fill="#888685"
        />
        <path
          d="M921.6,300.38a1.92,1.92,0,0,1-2.07,2.17H918v-.82h1.6a1.44,1.44,0,0,0,1-.33,1.35,1.35,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.54,1.54,0,0,0-1-.31H919a3,3,0,0,0-.53.05,2,2,0,0,0-.46.18,2.31,2.31,0,0,0-.43.31c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.44-.63.61a3.55,3.55,0,0,1-.57.43,2.27,2.27,0,0,1-.6.26,3.08,3.08,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.17,2.17,0,0,1,.5-1.58,2,2,0,0,1,1.49-.51h1.11v.81h-1.14a1.29,1.29,0,0,0-1,.32,1.35,1.35,0,0,0-.3,1,1.29,1.29,0,0,0,.31.91,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a3.52,3.52,0,0,1,.58-.42,2.78,2.78,0,0,1,.62-.25,3.11,3.11,0,0,1,.72-.08h.56a2.45,2.45,0,0,1,.83.13,1.57,1.57,0,0,1,.66.39,1.9,1.9,0,0,1,.44.68A2.53,2.53,0,0,1,921.6,300.38Z"
          fill="#888685"
        />
        <path
          d="M922.86,292.7v.82h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.48h-4.63a2,2,0,0,1-1.54-.52,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H913v-.82ZM919.54,296a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H915a1.43,1.43,0,0,0-1,.4,1.26,1.26,0,0,0-.36.91c0,.81.46,1.21,1.37,1.21Z"
          fill="#888685"
        />
        <path
          d="M919.56,290.43q1.35,0,1.35-1.17a1.29,1.29,0,0,0-.32-.88,1.33,1.33,0,0,0-1-.39H913v-.83h8.51V288h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.44H913v-.83Z"
          fill="#888685"
        />
        <path
          d="M921.51,282.53h-.77v0a1.71,1.71,0,0,1,.6.52,1.62,1.62,0,0,1,.26.95,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.46-.36-.73a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H915a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.92,1.92,0,0,1-.54-1.51,1.75,1.75,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57a5.58,5.58,0,0,0,.28.51,1.61,1.61,0,0,0,.3.37,1.27,1.27,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,920.91,283.83Z"
          fill="#888685"
        />
        <path
          d="M913.86,278v.14a1.37,1.37,0,0,0,.34,1,1.31,1.31,0,0,0,1,.37h6.33v.83H913v-.83h1v0a2.33,2.33,0,0,1-.39-.19,1.64,1.64,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.4,1.4,0,0,1-.1-.56Z"
          fill="#888685"
        />
        <path
          d="M918.57,272.51h.84A2.36,2.36,0,0,1,921,273a2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M911.34,583.56v-3.73H912v2.88h4v-2.09h.69v2.09h4.14v-2.88h.69v3.73Z"
          fill="#888685"
        />
        <path
          d="M919.59,574.53a2.14,2.14,0,0,1,1.48.48,2,2,0,0,1,.53,1.55,1.87,1.87,0,0,1-.56,1.49,2.21,2.21,0,0,1-1.51.49h-.84v-.78h.87a1.56,1.56,0,0,0,1-.29,1.09,1.09,0,0,0,.36-.91,1.24,1.24,0,0,0-.32-.95,1.43,1.43,0,0,0-1-.28h-.27a1.49,1.49,0,0,0-.8.21,3.78,3.78,0,0,0-.82.81l-.69.87a4.47,4.47,0,0,1-1,.95,1.89,1.89,0,0,1-1.11.31h-.24a1.88,1.88,0,0,1-1.32-.46,1.85,1.85,0,0,1-.51-1.45,1.92,1.92,0,0,1,.51-1.49,2.19,2.19,0,0,1,1.49-.46h.61v.78h-.64a1.45,1.45,0,0,0-1,.27,1.13,1.13,0,0,0-.32.9,1.11,1.11,0,0,0,.3.86,1.17,1.17,0,0,0,.81.27H915a1.6,1.6,0,0,0,.39-.05,1.21,1.21,0,0,0,.33-.15,4.09,4.09,0,0,0,.33-.3c.11-.12.24-.27.39-.46l.69-.87c.2-.25.39-.46.56-.64a2.91,2.91,0,0,1,.5-.41,2,2,0,0,1,.53-.22,2.88,2.88,0,0,1,.63-.07Z"
          fill="#888685"
        />
        <path
          d="M918.57,569h.84a2.36,2.36,0,0,1,1.63.51,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.38,2.38,0,0,1-1.66.55h-4.14a2.41,2.41,0,0,1-1.67-.54,2.38,2.38,0,0,1,0-3.14,2.36,2.36,0,0,1,1.64-.52h2.12v3.39h2.17a1.61,1.61,0,0,0,1.1-.35,1.26,1.26,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.39v-2.58h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.21,1.21,0,0,0,.39,1,1.54,1.54,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <path
          d="M915,564.26a1.54,1.54,0,0,0-1,.28,1.1,1.1,0,0,0-.34.89,1.29,1.29,0,0,0,.35.91,1.35,1.35,0,0,0,1,.36h6.54v.83H913v-.83h.84a1.87,1.87,0,0,1-.66-.56,1.93,1.93,0,0,1,.21-2.26,2.24,2.24,0,0,1,1.53-.44h6.59v.83Z"
          fill="#888685"
        />
        <path
          d="M913,562.25v-.66h-1.66v-.83H913v-1.18h.63v1.18h6.49a.76.76,0,0,0,.54-.15.79.79,0,0,0,.16-.57v-.46h.69v.55a1.63,1.63,0,0,1-.32,1.14,1.54,1.54,0,0,1-1.09.32h-6.47v.66Z"
          fill="#888685"
        />
        <path
          d="M921.51,555.08h-.77v0a1.71,1.71,0,0,1,.6.52,1.64,1.64,0,0,1,.26,1,1.72,1.72,0,0,1-.48,1.32,2.19,2.19,0,0,1-1.53.45h-.69a2.23,2.23,0,0,1-.83-.13,1.52,1.52,0,0,1-.58-.37,2.13,2.13,0,0,1-.41-.56c-.12-.23-.24-.47-.36-.74a5.15,5.15,0,0,0-.63-1.17.72.72,0,0,0-.55-.3H915a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.09,1.09,0,0,0,.33.89,1.34,1.34,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.9,1.9,0,0,1-.54-1.51,1.76,1.76,0,0,1,.54-1.46,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.23,1.23,0,0,0-1.35-1.3h-3a.43.43,0,0,1,.14.13s.1.13.17.22l.21.37c.08.15.18.34.29.57s.19.37.28.52a1.79,1.79,0,0,0,.3.36,1.09,1.09,0,0,0,.4.21,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.27A1.06,1.06,0,0,0,920.91,556.38Z"
          fill="#888685"
        />
        <path
          d="M911.34,552.83V552h.75v.83Zm1.66,0V552h8.51v.83Z"
          fill="#888685"
        />
        <path
          d="M921.6,546.09a1.93,1.93,0,0,1-2.07,2.18H918v-.83h1.6a1.44,1.44,0,0,0,1-.33,1.33,1.33,0,0,0,.36-1,1.16,1.16,0,0,0-.39-1,1.48,1.48,0,0,0-1-.31H919a3,3,0,0,0-.53.05,2,2,0,0,0-.46.17,2.39,2.39,0,0,0-.43.32c-.15.13-.31.29-.49.48l-1,1c-.23.24-.44.45-.63.62a4.26,4.26,0,0,1-.57.43,2.24,2.24,0,0,1-.6.25,2.62,2.62,0,0,1-.71.08h-.39a1.88,1.88,0,0,1-1.41-.54,2.1,2.1,0,0,1-.52-1.53,2.19,2.19,0,0,1,.5-1.58,2,2,0,0,1,1.49-.5h1.11v.81h-1.14a1.28,1.28,0,0,0-1,.31,1.35,1.35,0,0,0-.3,1,1.3,1.3,0,0,0,.31.92,1.16,1.16,0,0,0,.92.35h.37a1.55,1.55,0,0,0,.94-.29,8.27,8.27,0,0,0,.89-.81l1-1q.35-.35.63-.6a4.22,4.22,0,0,1,.58-.42,2.34,2.34,0,0,1,.62-.24,2.63,2.63,0,0,1,.72-.09h.56a2.72,2.72,0,0,1,.83.13,1.7,1.7,0,0,1,.66.4,1.86,1.86,0,0,1,.44.67A2.53,2.53,0,0,1,921.6,546.09Z"
          fill="#888685"
        />
        <path
          d="M922.86,538.41v.83h-2.18v0a2.08,2.08,0,0,1,.63.51,1.52,1.52,0,0,1,.29,1,1.75,1.75,0,0,1-.49,1.34,2.12,2.12,0,0,1-1.54.47h-4.63a2.06,2.06,0,0,1-1.54-.51,1.86,1.86,0,0,1-.49-1.3,1.91,1.91,0,0,1,.08-.57,1.62,1.62,0,0,1,.19-.42,1.35,1.35,0,0,1,.28-.3,2.92,2.92,0,0,1,.32-.21v0H913v-.83Zm-3.32,3.35a1.57,1.57,0,0,0,1-.28,1.11,1.11,0,0,0,.35-.92,1.27,1.27,0,0,0-.37-1,1.41,1.41,0,0,0-1-.36H915a1.43,1.43,0,0,0-1,.4,1.22,1.22,0,0,0-.36.9c0,.81.46,1.22,1.37,1.22Z"
          fill="#888685"
        />
        <path
          d="M919.56,536.15q1.35,0,1.35-1.17a1.31,1.31,0,0,0-.32-.89,1.28,1.28,0,0,0-1-.38H913v-.83h8.51v.83h-.84a1.67,1.67,0,0,1,.68.57,1.69,1.69,0,0,1,.25,1,1.61,1.61,0,0,1-.48,1.26,2.24,2.24,0,0,1-1.53.43H913v-.82Z"
          fill="#888685"
        />
        <path
          d="M921.51,528.25h-.77v0a1.74,1.74,0,0,1,.6.53,1.6,1.6,0,0,1,.26,1,1.7,1.7,0,0,1-.48,1.31,2.19,2.19,0,0,1-1.53.45h-.69a2.47,2.47,0,0,1-.83-.12,1.65,1.65,0,0,1-.58-.37,2.19,2.19,0,0,1-.41-.57c-.12-.22-.24-.47-.36-.74a5.39,5.39,0,0,0-.63-1.17.71.71,0,0,0-.55-.29H915a1.83,1.83,0,0,0-1,.22,1,1,0,0,0-.35.86,1.11,1.11,0,0,0,.33.89,1.4,1.4,0,0,0,.93.28h.83v.79h-.77a2.1,2.1,0,0,1-1.47-.48,1.94,1.94,0,0,1-.54-1.52,1.73,1.73,0,0,1,.54-1.45,2.46,2.46,0,0,1,1.56-.42h6.5Zm-.6,1.3a1.33,1.33,0,0,0-.34-.94,1.31,1.31,0,0,0-1-.36h-3a.39.39,0,0,1,.14.12l.17.23q.09.14.21.36c.08.15.18.34.29.58a5.58,5.58,0,0,0,.28.51,1.37,1.37,0,0,0,.3.36,1.11,1.11,0,0,0,.4.22,1.84,1.84,0,0,0,.56.08h.65a1.58,1.58,0,0,0,1-.28A1,1,0,0,0,920.91,529.55Z"
          fill="#888685"
        />
        <path
          d="M913.86,523.67v.13a1.37,1.37,0,0,0,.34,1,1.28,1.28,0,0,0,1,.38h6.33V526H913v-.83h1v0a2.4,2.4,0,0,1-.39-.2,1.4,1.4,0,0,1-.35-.3,1.46,1.46,0,0,1-.25-.42,1.35,1.35,0,0,1-.1-.55Z"
          fill="#888685"
        />
        <path
          d="M918.57,518.22h.84a2.36,2.36,0,0,1,1.63.52,2,2,0,0,1,.56,1.54,2,2,0,0,1-.62,1.6,2.42,2.42,0,0,1-1.66.54h-4.14a2.37,2.37,0,0,1-1.67-.54,2,2,0,0,1-.6-1.57,2,2,0,0,1,.58-1.56,2.36,2.36,0,0,1,1.64-.53h2.12v3.4h2.17a1.61,1.61,0,0,0,1.1-.35,1.27,1.27,0,0,0,.39-1c0-.84-.48-1.26-1.43-1.26h-.91Zm-2,3.4V519h-1.53a1.64,1.64,0,0,0-1.08.31,1.16,1.16,0,0,0-.39,1,1.22,1.22,0,0,0,.39,1,1.59,1.59,0,0,0,1.08.34Z"
          fill="#888685"
        />
        <polygon
          points="1044.9 393.5 1015.2 383.5 985.5 393.5 985.5 261.5 1015.2 271.5 1044.9 261.5 1044.9 393.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1250.9 393.5 1221.2 383.5 1191.5 393.5 1191.5 261.5 1221.2 271.5 1250.9 261.5 1250.9 393.5"
          fill="#e1e0e0"
        />
        <polygon
          points="1046.2 227.2 1056.2 197.5 1046.2 167.8 1178.2 167.8 1168.2 197.5 1178.2 227.2 1046.2 227.2"
          fill="#e1e0e0"
        />
        <polygon
          points="1176.09 531.52 1083.12 531.52 1083.12 455.5 1009.1 455.5 1009.1 474.19 987.91 474.19 987.91 620 1009.1 620 1083.12 620 1083.12 596.32 1176.09 596.32 1176.09 531.52"
          fill="#e1e0e0"
        />
        <path
          d="M1142,61.71V0h-26V56.61l-408.91,54.2a62,62,0,0,0-118,12.73L208,176.69V673h26V199.31l355.77-49.62A62,62,0,0,0,712,136.4l413.29-54.79L1293,214.29v261L1173,666.08l22,13.84,124-197.17v-281ZM650,171a36,36,0,1,1,36-36A36,36,0,0,1,650,171Z"
          fill="#f7f7f7"
        />
        <path
          d="M1002.48,552.33h3.74V553h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1011.51,560.58a2.14,2.14,0,0,1-.48,1.48,2,2,0,0,1-1.54.53A1.87,1.87,0,0,1,1008,562a2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.21,1.21,0,0,0,.94-.32,1.43,1.43,0,0,0,.29-1v-.26a1.5,1.5,0,0,0-.21-.81,3.78,3.78,0,0,0-.81-.81l-.87-.7a4.25,4.25,0,0,1-1-1,1.83,1.83,0,0,1-.31-1.1v-.24a1.86,1.86,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.15,2.15,0,0,1,.45,1.48v.62h-.77v-.65a1.45,1.45,0,0,0-.28-1,1.11,1.11,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81V556a1.2,1.2,0,0,0,0,.38,1,1,0,0,0,.15.33,3.8,3.8,0,0,0,.29.33,5.71,5.71,0,0,0,.47.39l.87.7c.25.2.46.38.64.55a2.91,2.91,0,0,1,.41.5,2,2,0,0,1,.22.53,3,3,0,0,1,.06.63Z"
          fill="#888685"
        />
        <path
          d="M1017.08,559.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53A1.67,1.67,0,0,0,1016,555a1.16,1.16,0,0,0-1-.38,1.18,1.18,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1021.79,556a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V554h.82v.83h0a2,2,0,0,1,.55-.66,1.73,1.73,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.43,1.54v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1023.8,554h.66v-1.67h.82V554h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.61,1.61,0,0,1-1.13-.32,1.54,1.54,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1031,562.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V556a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1029.66,561.9Z"
          fill="#888685"
        />
        <path
          d="M1033.22,552.33h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1037.64,552.33h4.19V553h-1.67v9.48h-.85V553h-1.67Z"
          fill="#888685"
        />
        <path
          d="M1047.26,560.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.58.61,2,2,0,0,1-1.57-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.57-.6,2,2,0,0,1,1.58.6,2.46,2.46,0,0,1,.54,1.68Zm-2.12,1.59a1.22,1.22,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.22,1.22,0,0,0-1-.39,1.19,1.19,0,0,0-1,.39,1.58,1.58,0,0,0-.34,1.09v4.34a1.58,1.58,0,0,0,.34,1.09A1.19,1.19,0,0,0,1045.14,561.9Z"
          fill="#888685"
        />
        <path
          d="M1055.12,554l-1.37,8.5h-.81l-1.18-6.52-1.22,6.52h-.81l-1.38-8.5h.83l1,6.58h0l1.23-6.58h.7l1.22,6.58h0l1-6.58Z"
          fill="#888685"
        />
        <path
          d="M1060.43,559.56v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.4,2.4,0,0,1,3.13,0,2.38,2.38,0,0,1,.53,1.64v2.12H1057v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.16,1.16,0,0,0-1-.38,1.18,1.18,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1064.19,554.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V554h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1070.33,552l-2.18,11.82h-.72l2.18-11.82Z" fill="#888685" />
        <path
          d="M1002.24,568.33h4.19V569h-1.67v9.48h-.85V569h-1.67Z"
          fill="#888685"
        />
        <path
          d="M1010.93,572a1.57,1.57,0,0,0-.28-1,1.09,1.09,0,0,0-.89-.33,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82V568.33h.82v2.5h0a2,2,0,0,1,.55-.66,1.73,1.73,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.43,1.54v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1017.36,575.56v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.55-1.67v-4.14a2.43,2.43,0,0,1,.54-1.67,2.05,2.05,0,0,1,1.58-.59,2,2,0,0,1,1.56.57,2.37,2.37,0,0,1,.52,1.64v2.12H1014v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.16,1.16,0,0,0-1-.38,1.22,1.22,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1022,573.37v5.13h-.86V568.33h2a2.17,2.17,0,0,1,1.58.5,2,2,0,0,1,.49,1.42v1.5a2,2,0,0,1-.49,1.44,2.14,2.14,0,0,1-1.62.52h0l2.45,4.79h-1Zm0-4.35v4h1.06a1.34,1.34,0,0,0,1-.32,1.22,1.22,0,0,0,.31-.9v-1.59q0-1.2-1.29-1.2Z"
          fill="#888685"
        />
        <path
          d="M1026.66,568.33h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M1028.7,570h.66v-1.67h.83V570h1.18v.62h-1.18v6.49a.71.71,0,0,0,.16.54.75.75,0,0,0,.56.16h.46v.69h-.55a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.33-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1033.43,577.81h2.73v.69h-3.59v-.69l2.79-7.12h-2.59V570h3.45v.69Z"
          fill="#888685"
        />
        <path
          d="M1042.19,578.59a2.47,2.47,0,0,1-1-.2,1.93,1.93,0,0,1-.7-.55,2.41,2.41,0,0,1-.4-.8,3.87,3.87,0,0,1-.13-1v-5.28a4,4,0,0,1,.13-1,2.36,2.36,0,0,1,.4-.81,1.81,1.81,0,0,1,.7-.54,2.47,2.47,0,0,1,1-.2,2.69,2.69,0,0,1,1,.17,1.48,1.48,0,0,1,.66.47,1.87,1.87,0,0,1,.34.74,4.75,4.75,0,0,1,.09,1v1.2h-.84v-1.27a2.25,2.25,0,0,0-.25-1.16,1.13,1.13,0,0,0-1-.42,1.46,1.46,0,0,0-.66.14,1.29,1.29,0,0,0-.44.36,1.63,1.63,0,0,0-.25.55,2.81,2.81,0,0,0-.07.67v5.53a2.87,2.87,0,0,0,.07.67,1.63,1.63,0,0,0,.25.55,1.29,1.29,0,0,0,.44.36,1.34,1.34,0,0,0,.66.14,1.14,1.14,0,0,0,1-.44,2.29,2.29,0,0,0,.26-1.14v-1.81h.82v1.74a4.42,4.42,0,0,1-.09.92,1.86,1.86,0,0,1-.35.75,1.68,1.68,0,0,1-.66.49A2.59,2.59,0,0,1,1042.19,578.59Z"
          fill="#888685"
        />
        <path
          d="M1048.91,578.5v-.76h0a1.72,1.72,0,0,1-.53.59,1.59,1.59,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.5,1.5,0,0,1,.36-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.47-.24.74-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V572a1.84,1.84,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.8v-.76a2.13,2.13,0,0,1,.48-1.48,1.94,1.94,0,0,1,1.52-.53,1.72,1.72,0,0,1,1.45.53,2.48,2.48,0,0,1,.42,1.56v6.5Zm-1.31-.6a1.31,1.31,0,0,0,.94-.34,1.28,1.28,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14s-.13.1-.22.17l-.37.22-.57.28c-.2.1-.37.2-.52.29a1.51,1.51,0,0,0-.36.3,1.24,1.24,0,0,0-.22.39,1.93,1.93,0,0,0-.07.57v.64a1.58,1.58,0,0,0,.27,1A1,1,0,0,0,1047.6,577.9Z"
          fill="#888685"
        />
        <path
          d="M1053.48,570.85h-.13a1.37,1.37,0,0,0-1,.34,1.32,1.32,0,0,0-.38,1v6.33h-.82V570h.82v1h0a2.81,2.81,0,0,1,.2-.38,1.69,1.69,0,0,1,.3-.36,1.46,1.46,0,0,1,.42-.25,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path d="M1054.76,568.33h.82V578.5h-.82Z" fill="#888685" />
        <path
          d="M1056.8,570h.66v-1.67h.82V570h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1065,576.31a2.45,2.45,0,0,1-.54,1.67,2,2,0,0,1-1.57.61,2,2,0,0,1-1.58-.61,2.45,2.45,0,0,1-.54-1.67v-4.12a2.46,2.46,0,0,1,.54-1.68,2,2,0,0,1,1.58-.6,2,2,0,0,1,1.57.6,2.46,2.46,0,0,1,.54,1.68Zm-2.11,1.59a1.19,1.19,0,0,0,1-.39,1.63,1.63,0,0,0,.33-1.09v-4.34a1.63,1.63,0,0,0-.33-1.09,1.19,1.19,0,0,0-1-.39,1.22,1.22,0,0,0-1,.39,1.58,1.58,0,0,0-.34,1.09v4.34a1.58,1.58,0,0,0,.34,1.09A1.22,1.22,0,0,0,1062.89,577.9Z"
          fill="#888685"
        />
        <path
          d="M1069.71,572a1.63,1.63,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.33,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83V570h.83v.83h0a2.06,2.06,0,0,1,.56-.66,1.7,1.7,0,0,1,1-.26,1.64,1.64,0,0,1,1.26.47,2.28,2.28,0,0,1,.44,1.54v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1074.06,578.5l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M1080.35,568.33h.82V578.5h-.82Z" fill="#888685" />
        <path
          d="M1085.7,571.88a1.57,1.57,0,0,0-.25-1,1,1,0,0,0-.81-.32,1.09,1.09,0,0,0-.85.35,1.36,1.36,0,0,0-.32,1v6.57h-.83V570h.83v.82h0a1.78,1.78,0,0,1,.52-.65,1.56,1.56,0,0,1,.94-.26,1.4,1.4,0,0,1,1.45,1h0a1.58,1.58,0,0,1,.56-.72,1.69,1.69,0,0,1,1-.28c1.07,0,1.61.65,1.61,2v6.64h-.83v-6.62a1.64,1.64,0,0,0-.24-1,1,1,0,0,0-.82-.32,1.13,1.13,0,0,0-.81.32,1.33,1.33,0,0,0-.36.89v6.69h-.83Z"
          fill="#888685"
        />
        <path
          d="M1094.19,578.5v-.76h0a1.68,1.68,0,0,1-.52.59,1.62,1.62,0,0,1-1,.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.23,2.23,0,0,1,.13-.83,1.52,1.52,0,0,1,.37-.58,2.19,2.19,0,0,1,.57-.41c.22-.12.46-.24.73-.36a4.45,4.45,0,0,0,1.17-.63.71.71,0,0,0,.3-.55V572a1.85,1.85,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.09,1.09,0,0,0-.89.32,1.37,1.37,0,0,0-.28.94v.82h-.79v-.76a2.13,2.13,0,0,1,.48-1.48,1.91,1.91,0,0,1,1.51-.53,1.75,1.75,0,0,1,1.46.53A2.48,2.48,0,0,1,1095,572v6.5Zm-1.3-.6a1.23,1.23,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.72,1.72,0,0,1-.22.17l-.37.22-.57.28a5.45,5.45,0,0,0-.51.29,1.39,1.39,0,0,0-.37.3,1.22,1.22,0,0,0-.21.39,1.91,1.91,0,0,0-.08.57v.64a1.58,1.58,0,0,0,.27,1A1.06,1.06,0,0,0,1092.89,577.9Z"
          fill="#888685"
        />
        <path
          d="M1096.19,570h.66v-1.67h.82V570h1.19v.62h-1.19v6.49a.76.76,0,0,0,.16.54.77.77,0,0,0,.56.16h.47v.69h-.56a1.59,1.59,0,0,1-1.13-.32,1.5,1.5,0,0,1-.32-1.09v-6.47h-.66Z"
          fill="#888685"
        />
        <path
          d="M1104.26,570l-2,8.61a2.63,2.63,0,0,1-.19.58,1.17,1.17,0,0,1-.29.39,1,1,0,0,1-.45.21,2.77,2.77,0,0,1-.62.06h-.42v-.69h.41a.75.75,0,0,0,.56-.16,1.54,1.54,0,0,0,.28-.59l.19-.74h-.19L1099.8,570h.84l1.38,6.33h0l1.41-6.33Z"
          fill="#888685"
        />
        <path
          d="M1079.48,306.14h3.74v.69h-2.88v4h2.08v.69h-2.08v4.14h2.88v.69h-3.74Z"
          fill="#888685"
        />
        <path
          d="M1088.51,314.39a2.13,2.13,0,0,1-.48,1.48,2,2,0,0,1-1.54.53,1.87,1.87,0,0,1-1.49-.56,2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.18,1.18,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-.95-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.77v-.65a1.45,1.45,0,0,0-.28-1,1.11,1.11,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.13,1.13,0,0,0,.05.38.89.89,0,0,0,.15.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M1094.08,313.37v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14,0,2.4,2.4,0,0,1-.55-1.67V310a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1098.79,309.76a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M1100.8,307.81h.66v-1.67h.82v1.67h1.19v.63h-1.19v6.48a.8.8,0,0,0,.16.55.82.82,0,0,0,.56.15h.47v.69h-.56a1.56,1.56,0,0,1-1.13-.32,1.53,1.53,0,0,1-.32-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1108,316.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1106.66,315.71Z"
          fill="#888685"
        />
        <path
          d="M1110.22,306.14h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1114.6,316.31l2-10.17h1.05l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path
          d="M1120.88,307.81h.83v.85h0a2.15,2.15,0,0,1,.57-.65,1.65,1.65,0,0,1,1-.29,1.62,1.62,0,0,1,1.28.49,2.19,2.19,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.57,1.57,0,0,1-1-.28,1.78,1.78,0,0,1-.52-.62h0v2.16h-.83Zm3.35,2a1.57,1.57,0,0,0-.29-1,1.17,1.17,0,0,0-.93-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1129.66,316.31v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94v.82h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1128.35,315.71Z"
          fill="#888685"
        />
        <path
          d="M1134.23,308.66h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M1135.25,307.81h.66v-1.67h.83v1.67h1.18v.63h-1.18v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1142.32,309.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.09,1.09,0,0,0-.84.35,1.38,1.38,0,0,0-.33,1v6.57h-.82v-8.5h.82v.82h0a1.59,1.59,0,0,1,.52-.64,1.47,1.47,0,0,1,.93-.27,1.41,1.41,0,0,1,1.46,1h0a1.58,1.58,0,0,1,.56-.72,1.69,1.69,0,0,1,1-.28c1.07,0,1.6.65,1.6,1.95v6.64h-.82V309.7a1.55,1.55,0,0,0-.25-1,1,1,0,0,0-.82-.33,1.1,1.1,0,0,0-.81.33,1.29,1.29,0,0,0-.36.88v6.69h-.82Z"
          fill="#888685"
        />
        <path
          d="M1151.81,313.37v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.45,2.45,0,0,1-.55-1.67V310a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2H1151v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.25,1.25,0,0,0-1,.38,1.62,1.62,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1156.52,309.76a1.58,1.58,0,0,0-.27-1,1.1,1.1,0,0,0-.9-.34,1.25,1.25,0,0,0-.9.34,1.32,1.32,0,0,0-.37,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M1158.53,307.81h.66v-1.67h.83v1.67h1.18v.63H1160v6.48a.75.75,0,0,0,.16.55.8.8,0,0,0,.56.15h.46v.69h-.55a1.54,1.54,0,0,1-1.13-.32,1.48,1.48,0,0,1-.33-1.09v-6.46h-.66Z"
          fill="#888685"
        />
        <path
          d="M1166.45,314.39a2.13,2.13,0,0,1-.48,1.48,2,2,0,0,1-1.54.53,1.87,1.87,0,0,1-1.49-.56,2.21,2.21,0,0,1-.49-1.51v-.84h.78v.87a1.55,1.55,0,0,0,.28,1,1.11,1.11,0,0,0,.92.36,1.18,1.18,0,0,0,.94-.32,1.38,1.38,0,0,0,.29-.95v-.27a1.47,1.47,0,0,0-.21-.8,3.57,3.57,0,0,0-.81-.82l-.87-.69a4.47,4.47,0,0,1-1-1,1.89,1.89,0,0,1-.31-1.11v-.24a1.88,1.88,0,0,1,.46-1.32,1.83,1.83,0,0,1,1.44-.51,1.94,1.94,0,0,1,1.5.51,2.16,2.16,0,0,1,.45,1.48v.62h-.78v-.65a1.45,1.45,0,0,0-.27-1,1.13,1.13,0,0,0-.9-.31,1.08,1.08,0,0,0-.85.3,1.17,1.17,0,0,0-.27.81v.24a1.48,1.48,0,0,0,0,.38,1.12,1.12,0,0,0,.16.33,2.14,2.14,0,0,0,.29.33,5.6,5.6,0,0,0,.47.4l.87.69c.25.2.46.38.64.55a2.62,2.62,0,0,1,.41.51,2,2,0,0,1,.22.52,3.1,3.1,0,0,1,.06.64Z"
          fill="#888685"
        />
        <path
          d="M217.42,679.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.46,2.49Zm1.43-3.18H221l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M223.71,669.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M225.85,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M229.18,669.64h3.63v.69H230v4h2.08V675H230v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M237.21,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.16,1.16,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.57.57,0,0,1-.13.14,1.72,1.72,0,0,1-.23.17,2.59,2.59,0,0,1-.36.22l-.57.28a5.74,5.74,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,235.9,679.21Z"
          fill="#888685"
        />
        <path
          d="M241.78,672.16h-.13a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M246.22,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,244.92,679.21Z"
          fill="#888685"
        />
        <path
          d="M248.47,669.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.7,1.7,0,0,1,1-.29,1.63,1.63,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.52,1.52,0,0,1-1-.28,1.93,1.93,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.23,1.23,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.35,1.35,0,0,0,.37,1,1.31,1.31,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M254.08,669.64h.83v.75h-.83Zm0,1.67h.83v8.5h-.83Z"
          fill="#888685"
        />
        <path
          d="M261.91,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.55,1.55,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.17,1.17,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,260.61,679.21Z"
          fill="#888685"
        />
        <path
          d="M268.24,671.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.39-6.93Z"
          fill="#888685"
        />
        <path
          d="M273.49,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11H270.1v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M278.2,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.36,1v6.54h-.83v-8.5h.83v.84h0a1.87,1.87,0,0,1,.56-.66,1.93,1.93,0,0,1,2.26.21,2.24,2.24,0,0,1,.44,1.53v6.58h-.83Z"
          fill="#888685"
        />
        <path
          d="M281.26,677.86q0,1.35,1.17,1.35a1.27,1.27,0,0,0,.89-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.82v8.5h-.82V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.43-1.53v-6.58h.82Z"
          fill="#888685"
        />
        <path
          d="M290.17,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2,2,0,0,1-1.54.56,2,2,0,0,1-1.6-.61,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.4,2.4,0,0,1,3.14,0,2.38,2.38,0,0,1,.52,1.65v2.11h-3.39v2.18a1.59,1.59,0,0,0,.35,1.09,1.26,1.26,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.19,1.19,0,0,0-1-.38,1.24,1.24,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M694.81,679.81l2-10.17h1l2,10.17H699l-.48-2.49H696.1l-.46,2.49Zm1.43-3.18h2.19l-1.1-5.64h0Z"
          fill="#888685"
        />
        <path d="M701.1,669.64h.82v10.17h-.82Z" fill="#888685" />
        <path d="M703.24,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M706.57,669.64h3.63v.69h-2.77v4h2.08V675h-2.08v4.83h-.86Z"
          fill="#888685"
        />
        <path
          d="M714.6,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.51,2.51,0,0,1,.12-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.14,1.14,0,0,0-.9.32,1.44,1.44,0,0,0-.27.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.22,1.22,0,0,0,1.31-1.35v-3a.57.57,0,0,1-.13.14,1.72,1.72,0,0,1-.23.17,2.59,2.59,0,0,1-.36.22l-.57.28a4.63,4.63,0,0,0-.52.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,713.29,679.21Z"
          fill="#888685"
        />
        <path
          d="M719.17,672.16H719a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.38,1v6.33h-.82v-8.5h.82v1.05h0a2.4,2.4,0,0,1,.2-.39,1.43,1.43,0,0,1,.3-.35,1.49,1.49,0,0,1,.42-.26,1.35,1.35,0,0,1,.55-.1Z"
          fill="#888685"
        />
        <path
          d="M723.61,679.81v-.76h0a1.6,1.6,0,0,1-.52.59,1.57,1.57,0,0,1-.95.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.6,1.6,0,0,1,.37-.58,2.38,2.38,0,0,1,.56-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,1,1,0,0,0-.86-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.15,2.15,0,0,1,.49-1.48,1.94,1.94,0,0,1,1.51-.53,1.79,1.79,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.21,1.21,0,0,0,1.3-1.35v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1,1,0,0,0-.21.4,1.79,1.79,0,0,0-.08.56v.64a1.63,1.63,0,0,0,.27,1A1.09,1.09,0,0,0,722.31,679.21Z"
          fill="#888685"
        />
        <path
          d="M725.86,669.64h.83v2.52h0a2.26,2.26,0,0,1,.56-.65,1.68,1.68,0,0,1,1-.29,1.64,1.64,0,0,1,1.28.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.79,1.79,0,0,1-1.33.48,1.52,1.52,0,0,1-1-.28,1.93,1.93,0,0,1-.53-.62h0v.81h-.83Zm3.35,3.63a1.51,1.51,0,0,0-.3-1,1.13,1.13,0,0,0-.92-.34,1.21,1.21,0,0,0-.91.36,1.45,1.45,0,0,0-.39,1v4.62a1.31,1.31,0,0,0,.37,1,1.3,1.3,0,0,0,.92.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M731.47,669.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M739.3,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.78,1.78,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,738,679.21Z"
          fill="#888685"
        />
        <path
          d="M745.63,671.31l-1.77,8.5h-.93l-1.77-8.5H742l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M750.88,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M755.59,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58h-.82Z"
          fill="#888685"
        />
        <path
          d="M758.65,677.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M767.56,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.31-1.09,1.42,1.42,0,0,0-1.93,0,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1172.2,679.81l2-10.17h1l2,10.17h-.87l-.48-2.49h-2.46l-.47,2.49Zm1.42-3.18h2.19l-1.09-5.64h0Z"
          fill="#888685"
        />
        <path d="M1178.48,669.64h.83v10.17h-.83Z" fill="#888685" />
        <path d="M1180.63,674.74h2v.69h-2Z" fill="#888685" />
        <path
          d="M1184,669.64h3.63v.69h-2.78v4h2.09V675h-2.09v4.83H1184Z"
          fill="#888685"
        />
        <path
          d="M1192,679.81v-.76h0a1.73,1.73,0,0,1-.53.59,1.53,1.53,0,0,1-1,.26,1.68,1.68,0,0,1-1.31-.48,2.19,2.19,0,0,1-.46-1.53v-.69a2.49,2.49,0,0,1,.13-.83,1.75,1.75,0,0,1,.37-.58,2.45,2.45,0,0,1,.57-.41l.73-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.87,1.87,0,0,0-.22-1,.94.94,0,0,0-.86-.34,1.14,1.14,0,0,0-.89.32,1.44,1.44,0,0,0-.28.94V674h-.79v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.51-.53,1.78,1.78,0,0,1,1.46.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.3-.6a1.37,1.37,0,0,0,.94-.33,1.35,1.35,0,0,0,.36-1v-3a.39.39,0,0,1-.12.14,1.72,1.72,0,0,1-.23.17,2.25,2.25,0,0,1-.37.22c-.14.08-.34.17-.57.28a5.45,5.45,0,0,0-.51.29,1.37,1.37,0,0,0-.36.3,1,1,0,0,0-.22.4,1.79,1.79,0,0,0-.08.56v.64a1.7,1.7,0,0,0,.27,1A1.09,1.09,0,0,0,1190.68,679.21Z"
          fill="#888685"
        />
        <path
          d="M1196.56,672.16h-.14a1.41,1.41,0,0,0-1,.34,1.33,1.33,0,0,0-.37,1v6.33h-.83v-8.5h.83v1.05h0a2.4,2.4,0,0,1,.2-.39,1.21,1.21,0,0,1,.29-.35,1.64,1.64,0,0,1,.42-.26,1.43,1.43,0,0,1,.56-.1Z"
          fill="#888685"
        />
        <path
          d="M1201,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1199.69,679.21Z"
          fill="#888685"
        />
        <path
          d="M1203.25,669.64h.82v2.52h0a2.26,2.26,0,0,1,.56-.65,1.67,1.67,0,0,1,1-.29,1.65,1.65,0,0,1,1.29.49,2.24,2.24,0,0,1,.44,1.53v4.64a2.14,2.14,0,0,1-.48,1.54,1.82,1.82,0,0,1-1.34.48,1.52,1.52,0,0,1-1-.28,1.9,1.9,0,0,1-.52-.62h0v.81h-.82Zm3.34,3.63a1.51,1.51,0,0,0-.29-1,1.13,1.13,0,0,0-.92-.34,1.25,1.25,0,0,0-.92.36,1.45,1.45,0,0,0-.39,1v4.62a1.36,1.36,0,0,0,.38,1,1.29,1.29,0,0,0,.91.33c.82,0,1.23-.45,1.23-1.36Z"
          fill="#888685"
        />
        <path
          d="M1208.86,669.64h.82v.75h-.82Zm0,1.67h.82v8.5h-.82Z"
          fill="#888685"
        />
        <path
          d="M1216.69,679.81v-.76h0a1.63,1.63,0,0,1-.53.59,1.52,1.52,0,0,1-.94.26,1.72,1.72,0,0,1-1.32-.48,2.19,2.19,0,0,1-.45-1.53v-.69a2.26,2.26,0,0,1,.13-.83,1.58,1.58,0,0,1,.36-.58,2.45,2.45,0,0,1,.57-.41l.74-.35a5.15,5.15,0,0,0,1.17-.63.74.74,0,0,0,.3-.56v-.58a1.86,1.86,0,0,0-.23-1,.93.93,0,0,0-.85-.34,1.12,1.12,0,0,0-.89.32,1.39,1.39,0,0,0-.28.94V674h-.8v-.76a2.14,2.14,0,0,1,.48-1.48,2,2,0,0,1,1.52-.53,1.76,1.76,0,0,1,1.45.53,2.51,2.51,0,0,1,.42,1.57v6.49Zm-1.31-.6a1.35,1.35,0,0,0,.94-.33,1.32,1.32,0,0,0,.37-1v-3a.43.43,0,0,1-.13.14,1.54,1.54,0,0,1-.22.17,3.62,3.62,0,0,1-.37.22l-.57.28c-.2.1-.37.2-.52.29a1.79,1.79,0,0,0-.36.3,1.18,1.18,0,0,0-.22.4,1.82,1.82,0,0,0-.07.56v.64a1.63,1.63,0,0,0,.27,1A1.07,1.07,0,0,0,1215.38,679.21Z"
          fill="#888685"
        />
        <path
          d="M1223,671.31l-1.77,8.5h-.93l-1.77-8.5h.84l1.41,6.93h0l1.4-6.93Z"
          fill="#888685"
        />
        <path
          d="M1228.27,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <path
          d="M1233,673.26a1.52,1.52,0,0,0-.28-1,1.06,1.06,0,0,0-.89-.34,1.29,1.29,0,0,0-.91.34,1.35,1.35,0,0,0-.37,1v6.54h-.82v-8.5h.82v.84h0a1.84,1.84,0,0,1,.55-.66,1.66,1.66,0,0,1,1-.27,1.61,1.61,0,0,1,1.26.48,2.24,2.24,0,0,1,.43,1.53v6.58H1233Z"
          fill="#888685"
        />
        <path
          d="M1236,677.86q0,1.35,1.17,1.35a1.24,1.24,0,0,0,.88-.32,1.29,1.29,0,0,0,.39-.95v-6.63h.83v8.5h-.83V679h0a1.52,1.52,0,0,1-1.56.93,1.61,1.61,0,0,1-1.26-.48,2.24,2.24,0,0,1-.44-1.53v-6.58h.83Z"
          fill="#888685"
        />
        <path
          d="M1245,676.87v.84a2.36,2.36,0,0,1-.51,1.63,2.4,2.4,0,0,1-3.14-.05,2.4,2.4,0,0,1-.55-1.67v-4.14a2.39,2.39,0,0,1,.54-1.66,2.39,2.39,0,0,1,3.13,0,2.39,2.39,0,0,1,.53,1.65v2.11h-3.39v2.18a1.58,1.58,0,0,0,.34,1.09,1.28,1.28,0,0,0,1,.39c.84,0,1.26-.47,1.26-1.42v-.92Zm-3.39-2h2.58v-1.53a1.67,1.67,0,0,0-.32-1.09,1.19,1.19,0,0,0-1-.38,1.21,1.21,0,0,0-1,.38,1.57,1.57,0,0,0-.34,1.09Z"
          fill="#888685"
        />
        <rect x="301.43" y="629.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="315.27"
          y="634.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="315.27"
          y1="637.1"
          x2="327.58"
          y2="637.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="315.27"
          y1="642.96"
          x2="327.58"
          y2="642.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="321.43"
          y1="637.1"
          x2="321.43"
          y2="642.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="317"
          cy="644.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="325.61"
          cy="644.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="316.38"
          y="646.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="324.99"
          y="646.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect x="146.43" y="737.39" width="40" height="23" fill="#dcdbdb" />
        <rect
          x="160.27"
          y="742.66"
          width="12.3"
          height="12.3"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="160.27"
          y1="745.1"
          x2="172.57"
          y2="745.1"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="160.27"
          y1="750.96"
          x2="172.57"
          y2="750.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <line
          x1="166.43"
          y1="745.1"
          x2="166.43"
          y2="750.96"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="162"
          cy="752.81"
          r="0.62"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <circle
          cx="170.61"
          cy="752.81"
          r="0.61"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="161.38"
          y="754.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <rect
          x="169.99"
          y="754.96"
          width="1.23"
          height="2.15"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.62"
        />
        <polyline
          points="585 377.5 591.94 377.5 605.33 363 612.27 363"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="596.78"
          cy="360.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="595.59 373.41 595.59 363.64 597.97 363.64 597.97 371.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <line
          x1="620.5"
          y1="617.13"
          x2="717.5"
          y2="617.13"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ea5341cd-bf8d-481a-b738-cd84d9da176b" data-name="OUTLINE">
        <polygon
          points="419.5 623.5 419.5 269.92 852.43 182 852.43 623.5 419.5 623.5"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="743.58 212.54 676.41 225.07 676.41 273.5 728.35 273.5 728.35 299.5 743.58 299.5 743.58 212.54"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.19"
        />
        <path
          d="M676.5,332.61v-9.4h36.41V371.5h-9.09V339.13a6.52,6.52,0,0,0-6.53-6.52Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e2eb0af2-7d13-4e25-b5f5-af12178c7406" data-name="FOOD1">
        <path
          d="M612.72,492.36l-8.35-5.74L549,451.12H520.32l-55.85,40.19a51.37,51.37,0,0,0-3.11,30.8,54.86,54.86,0,0,0,6.69,17,68.54,68.54,0,0,0,17.42,19.12c15.21,11.59,36.09,18.74,59.12,18.74,1.87,0,3.73,0,5.57-.14h0A100.78,100.78,0,0,0,591.38,566h15.25C610,566,620,569.6,620,577l.5-35Zm-20.29,37.95c-5,20.3-32.15,31.1-60.72,24.12S484,525.32,489,505s32.15-31.1,60.71-24.12S597.39,510,592.43,530.31Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a1244523-40db-432a-a5cb-a6200cdc3739" data-name="FOOD2">
        <ellipse
          cx="540.7"
          cy="517.66"
          rx="37.85"
          ry="53.25"
          transform="translate(-90.58 919.92) rotate(-76.26)"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ff0505e0-ea65-4664-b62d-4ed1b40e20dc" data-name="4C.1">
        <rect
          x="620.5"
          y="576.5"
          width="97"
          height="41"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a17e2598-abda-4e96-a411-165ad4a337c2" data-name="4C.2">
        <rect
          x="620.5"
          y="541.95"
          width="72.35"
          height="34.55"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a109c16e-1cd0-42c1-8f87-dd2ddd5d7151" data-name="4C.3">
        <path
          d="M686.07,479.83l.37-1.27-32.7-.37c-5.07,4.77-8.65,6.34-8.65,6.34l-32.37,7.83L620.5,542h72.35V526.81Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a281579f-eda6-484a-88f2-6c9d6203c0d9" data-name="4C.4">
        <path
          d="M682.46,398.5c1.53,7.77,1,19.44-4.48,36.95-7.28,23.37-17.2,36.13-24.24,42.74l32.68.37,12.18-43.11,9.79-36.95Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.07"
        />
      </g>
      <g id="ffed845a-c40b-40cc-92ba-aaa804373a1b" data-name="4C.5">
        <path
          d="M670.67,383.25s9.11,1.56,11.8,15.25h25.92l1.69-6.38H743.5V371.5H670.67Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.07"
        />
      </g>
      <g id="ba18dfa0-55ee-4e80-b391-ca960e616ae1" data-name="4C.7">
        <rect
          x="712.91"
          y="308.5"
          width="15.59"
          height="63"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.07"
        />
      </g>
      <g id="ea120f04-ad8c-4e2b-9efc-f360dc6752f7" data-name="4C.6">
        <path
          d="M697.29,332.61H670.41c-6.92,0-12.27,3.65-12.27,13.05,0,7.32,12.5,25.8,12.53,25.84h33.15V339.13A6.52,6.52,0,0,0,697.29,332.61Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.07"
        />
      </g>
      <g id="ac4df341-d20d-4375-b0e8-443583d02a65" data-name="WC_coridor">
        <polygon
          points="728.35 299.5 728.35 273.5 676.5 273.5 676.5 299.5 695.5 299.5 712.91 299.5 712.91 308.5 728.5 308.5 728.5 299.5 728.35 299.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1.07"
        />
      </g>
      <g id="ba025fd2-b814-4a7a-8e13-6f5d11cc0cfc" data-name="4.1">
        <polygon
          points="645.09 231.85 645.09 441.72 604.37 441.72 604.37 486.62 604.37 486.62 604.37 486.62 549.03 451.12 520.32 451.12 464.47 491.31 426.87 491.31 426.87 277.27 645.09 231.85"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b75a5bbb-b946-4ccd-bf63-dbdde625e2c4" data-name="4.2">
        <path
          d="M460.28,511.67a52,52,0,0,1,4.19-20.36h-37.6v30.8h34.49A51.65,51.65,0,0,1,460.28,511.67Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a9879ec5-c33c-4505-a039-4966f420a0af" data-name="4.3">
        <path
          d="M461.36,522.11H426.87v28.2h25.06l16.12-11.24A54.86,54.86,0,0,1,461.36,522.11Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b85839a0-7a4c-45f0-b7a9-4deab70841cf" data-name="4.4">
        <path
          d="M468.05,539.07l-16.12,11.24H426.87v31.32h42.29l16.31-23.44A68.54,68.54,0,0,1,468.05,539.07Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b0f3b0f0-2147-42f6-a52e-cc0a3c40ebb4" data-name="4.K4">
        <rect
          x="525"
          y="491"
          width="17"
          height="10"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a8b42b8c-23a8-4b03-8967-f6452512aa21" data-name="4.K5">
        <circle
          cx="647.7"
          cy="513.67"
          r="7.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f3699f14-8669-4abd-b47b-147d22861dfd" data-name="4.5">
        <path
          d="M544.59,576.93c-23,0-43.91-7.15-59.12-18.74l-16.31,23.44H426.87v35.5H550.16s.18-28.63,0-40.34C548.32,576.88,546.46,576.93,544.59,576.93Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path d="M467.2,594.15h-.06l-1.8,9h-.53l-1.8-9H463v9h-.79V592.34h1.25l1.65,8.3h0l1.64-8.3h1.38V603.1h-.91Z" />
        <path d="M473,603.1v-.81h0a1.82,1.82,0,0,1-.56.63,1.69,1.69,0,0,1-1,.27,1.83,1.83,0,0,1-1.39-.5,2.31,2.31,0,0,1-.48-1.62v-.73a2.48,2.48,0,0,1,.13-.88,1.6,1.6,0,0,1,.39-.61,2.33,2.33,0,0,1,.61-.44c.23-.12.49-.25.77-.37a5.48,5.48,0,0,0,1.24-.67.79.79,0,0,0,.32-.59v-.62a1.93,1.93,0,0,0-.24-1.06,1,1,0,0,0-.9-.36,1.18,1.18,0,0,0-.95.34,1.48,1.48,0,0,0-.29,1v.87h-.84v-.81a2.28,2.28,0,0,1,.5-1.56,2.06,2.06,0,0,1,1.61-.56,1.85,1.85,0,0,1,1.54.56,2.67,2.67,0,0,1,.44,1.66v6.87Zm-1.38-.64a1.44,1.44,0,0,0,1-.35A1.39,1.39,0,0,0,473,601v-3.18a.64.64,0,0,1-.14.15,1.86,1.86,0,0,1-.23.18l-.39.23-.61.3a4.35,4.35,0,0,0-.54.3,1.53,1.53,0,0,0-.38.32,1,1,0,0,0-.23.42,1.72,1.72,0,0,0-.08.59V601a1.71,1.71,0,0,0,.28,1.07A1.15,1.15,0,0,0,471.63,602.46Z" />
        <path d="M478.63,596.1a1.67,1.67,0,0,0-.27-1,1,1,0,0,0-.86-.34,1.16,1.16,0,0,0-.9.37,1.47,1.47,0,0,0-.34,1v7h-.87v-9h.87V595h0a1.76,1.76,0,0,1,.54-.68,1.6,1.6,0,0,1,1-.28,1.48,1.48,0,0,1,1.53,1.06h0a1.57,1.57,0,0,1,.59-.76A1.77,1.77,0,0,1,481,594c1.13,0,1.69.68,1.69,2.06v7h-.87v-7a1.75,1.75,0,0,0-.26-1,1,1,0,0,0-.86-.34,1.17,1.17,0,0,0-.86.34,1.41,1.41,0,0,0-.38.94v7.08h-.87Z" />
        <path d="M487.5,596.1a1.67,1.67,0,0,0-.27-1,1,1,0,0,0-.86-.34,1.16,1.16,0,0,0-.9.37,1.47,1.47,0,0,0-.34,1v7h-.87v-9h.87V595h0a1.76,1.76,0,0,1,.54-.68,1.6,1.6,0,0,1,1-.28,1.48,1.48,0,0,1,1.53,1.06h0a1.57,1.57,0,0,1,.59-.76,1.77,1.77,0,0,1,1.06-.3c1.13,0,1.69.68,1.69,2.06v7h-.87v-7a1.75,1.75,0,0,0-.26-1,1,1,0,0,0-.86-.34,1.17,1.17,0,0,0-.86.34,1.41,1.41,0,0,0-.38.94v7.08h-.87Z" />
        <path d="M496.48,603.1v-.81h0a1.79,1.79,0,0,1-.55.63,1.71,1.71,0,0,1-1,.27,1.87,1.87,0,0,1-1.4-.5,2.36,2.36,0,0,1-.47-1.62v-.73a2.48,2.48,0,0,1,.13-.88,1.6,1.6,0,0,1,.39-.61,2.45,2.45,0,0,1,.6-.44c.24-.12.5-.25.78-.37a5.73,5.73,0,0,0,1.24-.67.79.79,0,0,0,.32-.59v-.62a1.93,1.93,0,0,0-.24-1.06,1,1,0,0,0-.91-.36,1.16,1.16,0,0,0-.94.34,1.43,1.43,0,0,0-.29,1v.87h-.85v-.81a2.24,2.24,0,0,1,.51-1.56,2,2,0,0,1,1.6-.56,1.83,1.83,0,0,1,1.54.56,2.61,2.61,0,0,1,.45,1.66v6.87Zm-1.38-.64a1.44,1.44,0,0,0,1-.35,1.39,1.39,0,0,0,.39-1.07v-3.18a1,1,0,0,1-.14.15l-.24.18c-.1.07-.23.14-.39.23l-.6.3a5.45,5.45,0,0,0-.55.3,1.74,1.74,0,0,0-.38.32,1.2,1.2,0,0,0-.23.42,2,2,0,0,0-.08.59V601a1.71,1.71,0,0,0,.29,1.07A1.15,1.15,0,0,0,495.1,602.46Z" />
        <path d="M506.39,594.15h-.06l-1.79,9H504l-1.8-9h0v9h-.8V592.34h1.26l1.65,8.3h0l1.64-8.3h1.38V603.1h-.91Z" />
        <path d="M508.85,592.34h.87v.79h-.87Zm0,1.76h.87v9h-.87Z" />
        <path d="M514.63,603.1v-.81h0a1.93,1.93,0,0,1-.56.63,1.69,1.69,0,0,1-1,.27,1.87,1.87,0,0,1-1.4-.5,2.36,2.36,0,0,1-.47-1.62v-.73a2.48,2.48,0,0,1,.13-.88,1.6,1.6,0,0,1,.39-.61,2.45,2.45,0,0,1,.6-.44c.24-.12.5-.25.78-.37a5.48,5.48,0,0,0,1.24-.67.79.79,0,0,0,.32-.59v-.62a1.93,1.93,0,0,0-.24-1.06,1,1,0,0,0-.91-.36,1.18,1.18,0,0,0-.94.34,1.48,1.48,0,0,0-.29,1v.87h-.84v-.81a2.28,2.28,0,0,1,.5-1.56,2.06,2.06,0,0,1,1.61-.56,1.85,1.85,0,0,1,1.54.56,2.67,2.67,0,0,1,.44,1.66v6.87Zm-1.38-.64a1.44,1.44,0,0,0,1-.35,1.39,1.39,0,0,0,.39-1.07v-3.18a.64.64,0,0,1-.14.15l-.24.18-.38.23-.61.3a4.35,4.35,0,0,0-.54.3,1.8,1.8,0,0,0-.39.32,1.2,1.2,0,0,0-.23.42,2,2,0,0,0-.07.59V601a1.71,1.71,0,0,0,.28,1.07A1.15,1.15,0,0,0,513.25,602.46Z" />
      </g>
      <g id="ed3fb521-0caa-4052-8040-899c280c5ff3" data-name="4.10">
        <path
          d="M606.63,566H591.38a100.78,100.78,0,0,1-41.22,10.82v40.34H620V579.37A13.4,13.4,0,0,0,606.63,566Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b02c87db-ca67-4451-be76-f215bb018e09" data-name="4.11">
        <polygon
          points="645.09 484.53 612.72 492.36 604.37 486.62 604.37 441.72 645.09 441.72 645.09 484.53"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a384d997-fa94-4d0f-a159-6464f8908c4e" data-name="4.12">
        <polygon
          points="743.58 526.81 692.85 526.81 686.07 479.83 698.6 435.45 743.58 435.45 743.58 526.81"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f3113abf-a4a8-4aa9-a073-f00ef6bb7cb6" data-name="4.13">
        <polygon
          points="698.6 435.45 710.08 392.12 743.58 392.12 743.58 435.45 698.6 435.45"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a5052cf4-c91d-4a09-a6ed-86f88bed3b00" data-name="4.14">
        <rect
          x="692.85"
          y="541.95"
          width="24.65"
          height="34.46"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b23c8a68-53d4-47d1-a8bb-aace971ba876" data-name="4.15">
        <path
          d="M676.5,323.21H645.09v60h25.58V371.5S658.14,353,658.14,345.66c0-9.4,5.35-13.05,12.27-13.05h6.09Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M678,435.45c15.08-48.38-7.31-52.2-7.31-52.2H645.09V484.53S665.45,475.65,678,435.45Z"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bdace9ab-3047-42d6-b7e7-e570b6fef529" data-name="4.16">
        <rect
          x="728.35"
          y="332.61"
          width="17.23"
          height="38.89"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="beb307b5-b706-45d3-9920-23127d389275" data-name="4.17">
        <polygon
          points="843.82 541.95 796.74 541.95 796.74 526.81 743.58 526.81 743.58 212.54 843.82 192.18 843.82 541.95"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <rect x="745" y="372" width="51.74" height="19" fill="#ededed" />
        <rect x="747" y="446" width="49.74" height="34" fill="#ededed" />
        <rect
          x="710.24"
          y="439.5"
          width="154"
          height="19"
          transform="translate(1236.24 -338.24) rotate(90)"
          fill="#ededed"
        />
        <path d="M784,306.37v8.73h-.79V304.34h1l2.83,8.39v-8.39h.79V315.1h-.86Z" />
        <path d="M789.3,304.34h4v.73h-3v4.19h2.21V310H790.2v4.38h3v.73h-4Z" />
        <path d="M796.39,309.4l-1.75-5.06h.94l1.44,4.3,1.43-4.3h.89l-1.75,5.11,2,5.65h-.94L797,310.23l-1.6,4.87h-.89Z" />
        <path d="M800.61,304.34H805v.73h-1.77v10h-.9v-10h-1.76Z" />
      </g>
      <g id="b4b81a4c-e2f1-4222-918c-a0a540a90493" data-name="4.23">
        <rect
          x="717.5"
          y="541.95"
          width="126.32"
          height="75.18"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e41a1509-ed8e-4872-a0af-4a43a7994a6c" data-name="4E.1">
        <rect
          x="692.85"
          y="526.81"
          width="50.65"
          height="15.14"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="a1df8953-5af7-4952-ab1a-f729028f9e03" data-name="4E.2">
        <rect
          x="743.5"
          y="526.81"
          width="53"
          height="15.14"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b63ff8b7-fe37-4efe-91f5-b1dc4d7b3a48" data-name="4E.3">
        <rect
          x="777.5"
          y="480"
          width="19"
          height="46.81"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="b3f854a1-59a8-4e40-9be2-f6825c317a20" data-name="4E.4">
        <rect
          x="746.5"
          y="446.5"
          width="50"
          height="33.5"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="e8b90985-0341-463e-861d-28ab5fec5146" data-name="4E.5">
        <rect
          x="777.5"
          y="392.12"
          width="19"
          height="54.38"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="f834a6ee-0f7c-44eb-a00b-19c9a278f41c" data-name="4E.6">
        <rect
          x="743.5"
          y="371.5"
          width="53"
          height="20.62"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ea9216cb-04ac-42c7-a4f7-4c7b14435786" data-name="CL">
        <rect
          x="695.5"
          y="299.5"
          width="17.41"
          height="23.71"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="ec2e1d72-a732-4ecd-a981-c87b5bf7fefe" data-name="MED">
        <rect
          x="728.35"
          y="299.5"
          width="15.23"
          height="33"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <polygon
          points="742 317 738.25 317 738.25 313.25 734.75 313.25 734.75 317 731 317 731 320.5 734.75 320.5 734.75 324.25 738.25 324.25 738.25 320.5 742 320.5 742 317"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.76"
        />
      </g>
      <g id="a4a34f06-f3e6-40e2-84f9-398089057339" data-name="WC">
        <polygon
          points="676.41 225.07 645.09 231.85 645.09 323.21 676.5 323.21 676.41 225.07"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M657.69,281.75l-1.36,8.35h-1l-1.46-10.76h.89l1.13,8.66h0l1.38-8.66h.84L659.5,288h0l1.14-8.62h.83L660,290.1h-1l-1.33-8.35Z"
          fill="#ff4d00"
        />
        <path
          d="M665.17,290.19a2.65,2.65,0,0,1-1.11-.21,2,2,0,0,1-.73-.58,2.42,2.42,0,0,1-.42-.85,3.84,3.84,0,0,1-.14-1v-5.58a4,4,0,0,1,.14-1.05,2.47,2.47,0,0,1,.42-.86,2,2,0,0,1,.73-.57,2.65,2.65,0,0,1,1.11-.21,3,3,0,0,1,1.11.18,1.66,1.66,0,0,1,.7.5,2,2,0,0,1,.35.78,4.62,4.62,0,0,1,.11,1V283h-.89v-1.34a2.39,2.39,0,0,0-.27-1.23,1.18,1.18,0,0,0-1.1-.45,1.51,1.51,0,0,0-.7.15,1.16,1.16,0,0,0-.46.39,1.67,1.67,0,0,0-.27.57,3.17,3.17,0,0,0-.07.71v5.86a3.2,3.2,0,0,0,.07.7,1.82,1.82,0,0,0,.27.58,1.35,1.35,0,0,0,.46.39,1.51,1.51,0,0,0,.7.14,1.18,1.18,0,0,0,1.11-.47,2.37,2.37,0,0,0,.27-1.2v-1.91h.88v1.84a4.23,4.23,0,0,1-.11,1,2,2,0,0,1-.36.79,1.76,1.76,0,0,1-.7.52A2.71,2.71,0,0,1,665.17,290.19Z"
          fill="#ff4d00"
        />
      </g>
      <g id="fe9c0442-141e-493a-ba55-c25a0c016236" data-name="BABY">
        <rect
          x="676.5"
          y="299.5"
          width="19"
          height="23.71"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
        <path
          d="M703,309.63a1.63,1.63,0,1,1,1.63,1.62v.86"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.82"
        />
        <path
          d="M710,315.54H699a.57.57,0,0,1-.35-1l5.86-2.58,5.85,2.58A.57.57,0,0,1,710,315.54Z"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.82"
        />
        <path
          d="M685.41,313.35a6.54,6.54,0,0,1,3.46-2.24,2.16,2.16,0,0,0,.85-.72c.85-1.14.39-2.44-.75-3.29l.16.11c-1.14-.85-2.52-.94-3.38.2a2.16,2.16,0,0,0-.46,1,6.47,6.47,0,0,1-1.19,3.95"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.82"
        />
        <circle
          cx="683.05"
          cy="315.05"
          r="1.59"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.82"
        />
        <rect
          x="680.43"
          y="312.74"
          width="7.93"
          height="1.19"
          rx="0.59"
          transform="matrix(0.8, 0.6, -0.6, 0.8, 325.76, -348.45)"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
          strokeWidth="0.82"
        />
      </g>
      <g id="e14b6f2b-5bc6-4b67-a776-59a94df34dfd" data-name="4.K6">
        <circle
          cx="618.7"
          cy="513.67"
          r="7.83"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bce71d4e-e775-4f27-87bf-155284b3e568" data-name="4.K7">
        <rect
          x="542.5"
          y="516.5"
          width="15"
          height="7"
          fill="#dcdbdb"
          stroke="#000"
          strokeMiterlimit="10"
        />
      </g>
      <g id="fb5d5b33-8492-4dde-848f-e2e31bb2a9e1" data-name="Icons">
        <circle
          cx="700.95"
          cy="598.78"
          r="1.64"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="699.26 612.88 699.26 602.25 702.64 602.25 702.64 612.88"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="693.81 589.19 697 586 697.1 586 700.29 589.19"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="708.09 586 704.9 589.19 704.8 589.19 701.61 586"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="693.29"
          y="593"
          width="15.32"
          height="20"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <circle
          cx="756.95"
          cy="462.78"
          r="1.64"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="755.26 476.88 755.26 466.25 758.64 466.25 758.64 476.88"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="749.81 453.19 753 450 753.1 450 756.29 453.19"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="764.09 450 760.9 453.19 760.8 453.19 757.61 450"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="749.29"
          y="457"
          width="15.32"
          height="20"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <circle
          cx="549.95"
          cy="538.78"
          r="1.64"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="548.26 552.88 548.26 542.25 551.64 542.25 551.64 552.88"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="542.81 529.19 546 526 546.1 526 549.29 529.19"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="557.09 526 553.9 529.19 553.8 529.19 550.61 526"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="542.29"
          y="533"
          width="15.32"
          height="20"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="669 357.5 675.94 357.5 689.33 343 696.27 343"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="680.78"
          cy="340.49"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="679.59 353.41 679.59 343.64 681.97 343.64 681.97 351.14"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="634 570.41 640.94 570.41 654.33 555.91 661.27 555.91"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <ellipse
          cx="645.78"
          cy="553.4"
          rx="1.5"
          ry="1.49"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="644.59 566.32 644.59 556.54 646.97 556.54 646.97 564.05"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <polyline
          points="546 398 540.88 398 540.88 403.13 535.75 403.13 535.75 408.25 530.63 408.25 530.63 413.38 525.5 413.38 525.5 418.5 520.5 418.5"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <rect
          x="704.1"
          y="277.37"
          width="19.96"
          height="12.38"
          fill="none"
          stroke="#ff4d00"
          strokeMiterlimit="10"
        />
        <path
          d="M707.6,287.6l1.44-7.44h1.13l1.45,7.44h-1l-.28-1.56H708.8l-.28,1.56Zm1.34-2.35h1.26l-.62-3.5h0Z"
          fill="#ff4d00"
        />
        <path
          d="M711.56,280.16h3.21V281h-1.11v6.65h-1V281h-1.12Z"
          fill="#ff4d00"
        />
        <path
          d="M719,282.14h0l-1,5.46h-.49l-1.07-5.46h0v5.46h-.81v-7.44h1.18l1,4.9h0l.91-4.9h1.29v7.44H719Z"
          fill="#ff4d00"
        />
      </g>
    </svg>
  </MapTransformer>
);

FourthFloor.defaultProps = {
  mapWidth: 1424.89,
  mapHeight: 1001.28,
};

FourthFloor.propTypes = {
  mapHeight: PropTypes.number,
  mapWidth: PropTypes.number,
  MapTransformer: PropTypes.func.isRequired,
};

export default FourthFloor;
