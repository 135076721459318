import { connect } from 'react-redux';

import { scheduleDeactivate } from '@/features/userDeactivate/actions';
import {
  getError,
  isDeactivatingUser,
} from '@/features/userDeactivate/selectors';

const mapStateToProps = (state, { userId }) => ({
  error: getError(state),
  isLoading: isDeactivatingUser(state, userId),
});

const mapDispatchToProps = (dispatch, { userId }) => ({
  toggleActivity: () => dispatch(scheduleDeactivate([userId])),
});

export default connect(mapStateToProps, mapDispatchToProps);
