import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import Label from '@/features/campaignPage/Page/Label';
import { formatDateTime } from '@/utils/format';

class Schedule extends Component {
  static propTypes = {
    scheduleDateTime: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    scheduleDateTime: [],
  };

  render() {
    const { scheduleDateTime, classes } = this.props;
    const [firstMailing, ...restSchedule] = scheduleDateTime;

    return (
      Boolean(firstMailing) && (
        <FieldRow label="Свойства">
          <Label title="Количество рассылок">
            <div>Пуш-сообщение будет отправлено по графику:</div>
          </Label>
          <Label title="Дата первой рассылки">
            <div>{formatDateTime(firstMailing)}</div>
          </Label>
          {Boolean(restSchedule.length) && (
            <Label title="Дальнейшие повторения">
              {restSchedule.map(date => (
                <div key={date} className={classes.dateItem}>
                  {formatDateTime(date)}
                </div>
              ))}
            </Label>
          )}
        </FieldRow>
      )
    );
  }
}

export default Schedule;
