import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import MainPageTopBar from '@/components/DevicePreview/MainPageTopBar';
import {
  LOCALE_EN,
  LOCALE_KZ,
  LOCALE_RU,
} from '@/hocs/withDevicePreview/consts';

import '../../../../../../css/overrides-carousel.css';

const Preview = ({
  classes,
  imgUrls,
  englishTitle,
  russianTitle,
  kazakhTitle,
  locale,
}) => {
  const title = useMemo(() => {
    switch (locale) {
      case LOCALE_RU:
        return russianTitle;
      case LOCALE_EN:
        return englishTitle;
      case LOCALE_KZ:
        return kazakhTitle;
      default:
        return russianTitle;
    }
  }, [locale, russianTitle, englishTitle, kazakhTitle]);

  return (
    <Fragment>
      <MainPageTopBar />
      <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        <Carousel autoPlay showThumbs={false} showIndicators={false}>
          {imgUrls.map(url => (
            <img key={url} className={classes.image} src={url} />
          ))}
        </Carousel>
      </div>
    </Fragment>
  );
};

Preview.propTypes = {
  englishTitle: PropTypes.string.isRequired,
  russianTitle: PropTypes.string.isRequired,
  kazakhTitle: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  imgUrls: PropTypes.any.isRequired,
};

Preview.defaultProps = {
  additionalEnglishText: null,
  additionalRussianText: null,
};

export default Preview;
