import { isFieldEditable as isActionResultFieldEditable } from '@esentai/core/features/campaigns/actionResult/selectors';
import {
  getArticleLayout,
  isArticleFieldEditable,
} from '@esentai/core/features/campaigns/article/selectors';
import { isBannerFieldEditable } from '@esentai/core/features/campaigns/banner/selectors';
import { isButtonFieldEditable } from '@esentai/core/features/campaigns/button/selectors';
import {
  getEventLayout,
  isEventFieldEditable,
} from '@esentai/core/features/campaigns/calendarEvent/selectors';
import {
  ACTION_RESULT_TYPE,
  ARTICLE_TYPE,
  BANNER_TYPE,
  BUTTON_TYPE,
  CALENDAR_EVENT_TYPE,
  NOTIFICATION_TYPE,
  PURCHASE_RESULT_TYPE,
  SHOW_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import { isFieldEditable as isNotificationFieldEditable } from '@esentai/core/features/campaigns/notification/selectors';
import { isFieldEditable as isPurchaseResultFieldEditable } from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { isCampaignFieldEditable } from '@esentai/core/features/campaigns/selectors';
import { isFieldEditable as isShowFieldEditable } from '@esentai/core/features/campaigns/show/selectors';
import { curry, either, isNil, pickBy, pipe, prop } from 'ramda';

import { renameTitleToSubtitle } from '@/features/campaignCreatePage/utils';
import { createMatchSelector } from '@/redux/router/selectors';

import { STEP_ROUTE_PATH } from './consts';

const getParams = pipe(createMatchSelector(STEP_ROUTE_PATH), prop('params'));

export const getCampaignType = pipe(getParams, prop('type'));

export const getCampaignId = pipe(getParams, prop('id'));

export const isNewCampaign = pipe(getCampaignId, isNil);

export const getArticleLayoutWithRename = pipe(
  getArticleLayout,
  renameTitleToSubtitle,
);

export const getEventLayoutWithRename = pipe(
  getEventLayout,
  renameTitleToSubtitle,
);

export const isTypeSpecificAttributeEditable = (state, attribute) => {
  const campaignId = getCampaignId(state);
  const campaignType = getCampaignType(state);
  const selectors = {
    [ACTION_RESULT_TYPE]: isActionResultFieldEditable,
    [ARTICLE_TYPE]: isArticleFieldEditable,
    [BANNER_TYPE]: isBannerFieldEditable,
    [BUTTON_TYPE]: isButtonFieldEditable,
    [CALENDAR_EVENT_TYPE]: isEventFieldEditable,
    [NOTIFICATION_TYPE]: isNotificationFieldEditable,
    [PURCHASE_RESULT_TYPE]: isPurchaseResultFieldEditable,
    [SHOW_TYPE]: isShowFieldEditable,
  };

  if (isNewCampaign(state)) {
    return true;
  }

  const selector = selectors[campaignType];

  return selector(state, campaignId, attribute);
};

export const isCommonAttributeEditable = (state, attribute) => {
  const campaignId = getCampaignId(state);

  return isCampaignFieldEditable(state, campaignId, attribute);
};

export const isAttributeEditable = either(
  isCommonAttributeEditable,
  isTypeSpecificAttributeEditable,
);

export const getEditableAttributes = curry((state, attributes) =>
  pickBy((_, attribute) => isAttributeEditable(state, attribute), attributes),
);
