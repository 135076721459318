import { findMerchantById } from '@esentai/core/features/merchants/actions';
import {
  getMerchantLoadingError,
  isMerchantLoaded,
  isMerchantLoading,
} from '@esentai/core/features/merchants/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import { updateMerchant } from '@/features/merchantEditPage/actions';
import { fetchMerchant as fetchItem } from '@/features/merchantPage/actions';
import { getRouterParameterByMatch } from '@/redux/router/selectors';

import component from './component';

const ID_KEY = 'merchantId';

const mapStateToProps = (state, { match }) => {
  const merchantId = getRouterParameterByMatch(match, ID_KEY);

  return {
    error: getMerchantLoadingError(state, merchantId),
    isLoaded: isMerchantLoaded(state, merchantId),
    isLoading: isMerchantLoading(state, merchantId),
    merchantId,
  };
};

const mapDispatchToProps = (dispatch, { match }) => ({
  updateMerchant: values =>
    dispatch(
      updateMerchant({
        ...values,
        merchantId: getRouterParameterByMatch(match, ID_KEY),
        status: values.merchantStatus ? 'active' : 'inactive',
        stores: values.stores,
      }),
    ),
  fetchItem,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    fetchItem: findMerchantById,
    getItemLoadingError: getMerchantLoadingError,
    isItemLoaded: isMerchantLoaded,
    isItemLoading: isMerchantLoading,
  }),
)(component);
