import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import FlowRateChart from '@/components/FlowRateChart';

const ActionResult = ({
  activationsCount,
  pushClicksCount,
  activationsToSegmentPercentage,
  pushClicksToActivationsPercentage,
}) => (
  <Fragment>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={activationsCount}
        percentage={activationsToSegmentPercentage}
      />
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <FlowRateChart
        value={pushClicksCount}
        percentage={pushClicksToActivationsPercentage}
      />
    </CondenseTableCell>
  </Fragment>
);

ActionResult.propTypes = {
  activationsCount: PropTypes.number.isRequired,
  pushClicksCount: PropTypes.number.isRequired,
  activationsToSegmentPercentage: PropTypes.number.isRequired,
  pushClicksToActivationsPercentage: PropTypes.number.isRequired,
};

export default ActionResult;
