import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AnonymousPurchasesStatistics from '@/features/anonymousPurchasesStatisticsPage/Page';
import CampaignsStatistics from '@/features/campaignsStatisticsPage/Page';
import LocationsStatistics from '@/features/locationsStatisticsPage/Page';
import PurchasesStatistics from '@/features/purchasesStatisticsPage/Page';
import UsersStatistics from '@/features/usersStatisticsPage/Page';

const entityToComponent = {
  anonymous_purchases: AnonymousPurchasesStatistics,
  campaigns: CampaignsStatistics,
  locations: LocationsStatistics,
  purchases: PurchasesStatistics,
  users: UsersStatistics,
};

class Statistics extends Component {
  static propTypes = {
    entityId: PropTypes.string.isRequired,
  };

  render() {
    const { entityId } = this.props;
    const Page = entityToComponent[entityId];

    return <Page />;
  }
}

export default Statistics;
