import {
  getDescriptionEnForGiftSet,
  getDescriptionKzForGiftSet,
  getDescriptionRuForGiftSet,
  getImagesForGiftSet,
  getIndexForGiftSet,
  getPriceForGiftSet,
  getSkuForGiftSet,
  getSubtitleEnForGiftSet,
  getSubtitleKzForGiftSet,
  getSubtitleRuForGiftSet,
  getTitleEnForGiftSet,
  getTitleKzForGiftSet,
  getTitleRuForGiftSet,
} from '@esentai/core/features/giftSets/selectors';
import { connect } from 'react-redux';

import Description from './component';

const mapStateToProps = (state, { giftSetId }) => ({
  titleRu: getTitleRuForGiftSet(state, giftSetId),
  titleEn: getTitleEnForGiftSet(state, giftSetId),
  titleKz: getTitleKzForGiftSet(state, giftSetId),

  subtitleRu: getSubtitleRuForGiftSet(state, giftSetId),
  subtitleEn: getSubtitleEnForGiftSet(state, giftSetId),
  subtitleKz: getSubtitleKzForGiftSet(state, giftSetId),

  descriptionRu: getDescriptionRuForGiftSet(state, giftSetId),
  descriptionEn: getDescriptionEnForGiftSet(state, giftSetId),
  descriptionKz: getDescriptionKzForGiftSet(state, giftSetId),

  images: getImagesForGiftSet(state, giftSetId),
  index: getIndexForGiftSet(state, giftSetId),
  sku: getSkuForGiftSet(state, giftSetId),
  price: getPriceForGiftSet(state, giftSetId),
});

export default connect(mapStateToProps)(Description);
