export default theme => ({
  container: {
    marginBottom: 8,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  },

  locale: {
    marginLeft: 5,
    display: 'inline',
    verticalAlign: 'middle',
  },
});
