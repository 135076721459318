import {
  DATE_END_KEY,
  DATE_START_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import { SEND_SCHEDULE_KEY } from '@esentai/core/features/campaigns/notification/consts/keys';
import { addDays, addMonths, addWeeks } from 'date-fns';
import {
  compose,
  concat,
  empty,
  evolve,
  isNil,
  max,
  mergeRight,
  min,
  not,
  propSatisfies,
  reject,
  times,
  when,
} from 'ramda';

import { MAX_SCHEDULE_SIZE } from './consts';
import {
  INITIAL_SEND_DATETIME,
  REPEAT_SEND_DATETIMES,
  SHOULD_REPEAT_SEND,
} from './consts/keys';

export const generateDailySchedule = seed =>
  times(x => addDays(seed, x + 1), MAX_SCHEDULE_SIZE);

export const generateDayAfterDaySchedule = seed =>
  times(x => addDays(seed, (x + 1) * 2), MAX_SCHEDULE_SIZE);

export const generateWeeklySchedule = seed =>
  times(x => addWeeks(seed, x + 1), MAX_SCHEDULE_SIZE);

export const generateMonthlySchedule = seed =>
  times(x => addMonths(seed, x + 1), MAX_SCHEDULE_SIZE);

export const computeStartAndEndDates = values =>
  mergeRight(values, {
    [DATE_START_KEY]: values[SEND_SCHEDULE_KEY].reduce(min),
    [DATE_END_KEY]: values[SEND_SCHEDULE_KEY].reduce(max),
  });

export const mergeSchedule = values =>
  mergeRight(values, {
    [SEND_SCHEDULE_KEY]: concat(
      [values[INITIAL_SEND_DATETIME]],
      values[REPEAT_SEND_DATETIMES],
    ),
  });

const isDisabled = propSatisfies(not);
const resetRepetSendDates = evolve({
  [REPEAT_SEND_DATETIMES]: empty,
});
const clearRepeatSendDatesHoles = evolve({
  [REPEAT_SEND_DATETIMES]: reject(isNil),
});

export const cleanUpValues = compose(
  when(isDisabled(SHOULD_REPEAT_SEND), resetRepetSendDates),
  clearRepeatSendDatesHoles,
);
