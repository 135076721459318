import { getIn, validateYupSchema, withFormik, yupToFormErrors } from 'formik';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import { filter, isEmpty, reject } from 'ramda';
import React, { Component } from 'react';

import RequiredMessageList from '@/components/RequiredMessage';

import {
  getRequiredErrors,
  getValidationErrors,
  hasRequiredErrors,
  isButtonDisabled,
  isRequiredError,
  requiredErrorsKey,
} from './utils';

export default ({ validationSchema, ...options }) => WrappedComponent => {
  if (validationSchema) {
    const schema = validationSchema;

    options.validate = (values, props) =>
      validateYupSchema(
        values,
        typeof schema === 'function' ? schema(props) : schema,
      ).catch(err => {
        const errors = yupToFormErrors({
          inner: reject(isRequiredError, err.inner),
        });

        const requiredErrors = yupToFormErrors({
          inner: filter(isRequiredError, err.inner),
        });

        // eslint-disable-next-line no-throw-literal
        throw {
          ...errors,
          [requiredErrorsKey]: requiredErrors,
        };
      });
  }
  class WithValidation extends Component {
    static propTypes = {
      errors: PropTypes.object.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      handleBlur: PropTypes.func.isRequired,
      handleChange: PropTypes.func.isRequired,
      values: PropTypes.object,
      touched: PropTypes.object,
      isSubmitting: PropTypes.bool,
    };

    static defaultProps = {
      isSubmitting: false,
      values: {},
      touched: {},
    };

    render() {
      const { errors, ...props } = this.props;

      return (
        <WrappedComponent
          {...props}
          errors={getValidationErrors(errors)}
          requiredErrors={getRequiredErrors(errors)}
          hasRequiredErrors={hasRequiredErrors(errors)}
          RequiredMessages={this.renderRequiredMessages}
          Form={this.renderForm}
          Field={this.renderField}
          SubmitButton={this.renderSubmitButton}
        />
      );
    }

    renderRequiredMessages = props => {
      const requiredErrors = getRequiredErrors(this.props.errors);

      return (
        !isEmpty(requiredErrors) && (
          <RequiredMessageList requiredErrors={requiredErrors} {...props} />
        )
      );
    };

    renderForm = props => {
      const { handleSubmit } = this.props;

      return <form onSubmit={handleSubmit} {...props} />;
    };

    renderField = ({ Target, name, helperText, ...rest }) => {
      const { handleBlur, handleChange, values, errors } = this.props;

      const isIndexedName = name.match(/(\d+)/);

      let hasError = false;
      const error = getIn(errors, name);
      const requiredErrors = getRequiredErrors(this.props.errors);

      if (isIndexedName) {
        const index = isIndexedName[0];
        const fieldArray = name.split(`[${index}].`);

        hasError =
          Boolean(error) ||
          _get(requiredErrors, `${fieldArray[0]}[${index}][${fieldArray[1]}]`);
      } else {
        hasError = Boolean(error) || (requiredErrors && requiredErrors[name]);
      }

      const value = getIn(values, name);
      let maxLength = '';

      try {
        const validation = validationSchema.fields[name];
        const option = validation.tests.find(v => v.OPTIONS.name === 'max');

        if (option) {
          maxLength = `${value.length}/${option.OPTIONS.params.max}`;
        }
      } catch (e) {
        // TODO
      }

      return (
        <Target
          error={hasError}
          helperText={
            hasError
              ? error || 'Поле не заполено'
              : `${helperText || ''} ${maxLength}`
          }
          name={name}
          id={name}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
          {...rest}
        />
      );
    };

    renderSubmitButton = ({ Target, disabled, ...rest }) => {
      const { errors, isSubmitting, touched, values } = this.props;

      return (
        <Target
          disabled={
            disabled ||
            isButtonDisabled({
              errors,
              isSubmitting,
              touched,
              values,
            })
          }
          {...rest}
        />
      );
    };
  }

  return withFormik(options)(WithValidation);
};
