import {
  AccountBox,
  BlurOff,
  Business,
  CardGiftcard,
  CardTravel,
  Group,
  ImportExportOutlined,
  Money,
  ReceiptOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
  Spa,
  Store,
  StoreOutlined,
  SupervisorAccount,
  Unarchive,
  ViewComfy,
} from '@material-ui/icons';

import { ROUTE_PATH as ARCHIVE_BALANCE_PATH } from '@/features/archiveBalancePage/consts';
import { ROUTE_PATH as BONUSES_HISTORY_PATH } from '@/features/bonusesHistoryPage/consts';
import { ROUTE_PATH as EFS_USERS_PATH } from '@/features/efsUsersPage/consts';
import { ROUTE_PATH as EXECUTIVE_REPORTS_PATH } from '@/features/executiveReportsPage/consts';
import { ROUTE_PATH as GIFT_CERTIFICATES_PATH } from '@/features/giftCertificatesPage/consts';
import { ROUTE_PATH as IPN_REPORTS_PATH } from '@/features/ipnReportsPage/consts';
import { ROUTE_PATH as LOYALTY_HISTORY_PATH } from '@/features/loyaltyHistoryPage/consts';
import { ROUTE_PATH as MALL_BONUSES_PATH } from '@/features/mallBonusesPage/consts';
import { ROUTE_PATH as MERCHANTS_PATH } from '@/features/merchantsPage/consts';
import { ROUTE_PATH as MERCHANTS_RECONCILIATION_PATH } from '@/features/merchantsReconciliationPage/consts';
import { ROUTE_PATH as PURCHASE_HISTORY_PATH } from '@/features/purchaseHistoryPage/consts';
import { ROUTE_PATH as PURCHASES_WITHOUT_SCAN_PATH } from '@/features/purchaseWithoutScanPage/consts';
import { ROUTE_PATH as RECONCILIATION_PATH } from '@/features/reconciliationPage/consts';
import { ROUTE_PATH as RECONCILIATION_STORE_CP_PATH } from '@/features/reconciliationStoreToCpPage/consts';
import { ROUTE_PATH as SALES_TURNOVERS_PATH } from '@/features/salesTurnoversPage/consts';
import { ROUTE_PATH as USERS_PATH } from '@/features/usersPage/consts';

export const mallAccountantMenu = [
  {
    label: 'Пользователи',
    Icon: AccountBox,
    items: [
      {
        label: 'Список пользователей',
        Icon: Group,
        path: USERS_PATH,
      },
      {
        label: 'Архив по бонусам/уровням',
        Icon: ImportExportOutlined,
        path: LOYALTY_HISTORY_PATH,
      },
      {
        label: 'Архив бонусов по чекам',
        Icon: ReceiptOutlined,
        path: BONUSES_HISTORY_PATH,
      },
      {
        label: 'Архив по добавлению баланса',
        path: ARCHIVE_BALANCE_PATH,
        Icon: Unarchive,
      },
      {
        label: 'Подарочные сертификаты',
        Icon: CardGiftcard,
        path: GIFT_CERTIFICATES_PATH,
      },
      {
        label: 'Пользователи EFS',
        path: EFS_USERS_PATH,
        Icon: Spa,
      },
    ],
  },
  {
    label: 'Список арендаторов',
    path: MERCHANTS_PATH,
    Icon: CardTravel,
  },
  {
    label: 'Настройка бонусов',
    path: MALL_BONUSES_PATH,
    Icon: Store,
  },
  {
    label: 'Покупки',
    Icon: ShoppingCart,
    items: [
      {
        label: 'История покупок',
        path: PURCHASE_HISTORY_PATH,
        Icon: ShoppingCartOutlined,
      },
      {
        label: 'Покупки без сканирования',
        path: PURCHASES_WITHOUT_SCAN_PATH,
        Icon: BlurOff,
      },
    ],
  },
  {
    label: 'Обороты по магазинам',
    path: SALES_TURNOVERS_PATH,
    Icon: Money,
  },
  {
    label: 'Подарочные сертификаты',
    Icon: CardGiftcard,
    path: GIFT_CERTIFICATES_PATH,
  },
  {
    label: 'Реконсиляция',
    Icon: AccountBox,
    items: [
      {
        label: 'Бонусы от Esentai Mall',
        Icon: Business,
        items: [
          {
            label: 'Арендаторы',
            path: MERCHANTS_RECONCILIATION_PATH,
            Icon: SupervisorAccount,
          },
          {
            label: 'Магазины',
            path: RECONCILIATION_PATH,
            Icon: StoreOutlined,
          },
        ],
      },
      {
        label: 'Бонусы от имени магазина',
        path: RECONCILIATION_STORE_CP_PATH,
        Icon: Store,
      },
    ],
  },
  {
    label: 'Отчеты',
    Icon: AccountBox,
    items: [
      {
        label: 'ИПН',
        Icon: Business,
        path: IPN_REPORTS_PATH,
      },
      {
        label: 'Executive report',
        Icon: ViewComfy,
        path: EXECUTIVE_REPORTS_PATH,
      },
    ],
  },
];
