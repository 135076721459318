import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React from 'react';

import FieldRow from '@/components/FieldRow';
import Label from '@/features/campaignPage/Page/Label';
import { formatMoney, formatNumber } from '@/utils/format';

const Triggers = ({
  periodReceipts,
  receiptsCount,
  singleReceipt,
  singleReceiptItemsCount,
}) => (
  <FieldRow label="Условия покупки">
    <Label title="Кампания будет активирована, если:">
      {!isNil(periodReceipts) && (
        <div>
          Сумма покупок за период действия кампании составит более{' '}
          {formatNumber(periodReceipts)}
        </div>
      )}
      {!isNil(receiptsCount) && (
        <div>
          Количество покупок за период действия кампании составит более{' '}
          {formatNumber(receiptsCount)}
        </div>
      )}
      {!isNil(singleReceipt) && (
        <div>
          Сумма одной покупки составит более {formatMoney(singleReceipt)}
        </div>
      )}
      {!isNil(singleReceiptItemsCount) && (
        <div>
          Количество товаров в любой одной покупке составит более{' '}
          {formatNumber(singleReceiptItemsCount)}
        </div>
      )}
      {isNil(periodReceipts) &&
        isNil(receiptsCount) &&
        isNil(singleReceipt) &&
        isNil(singleReceiptItemsCount) && (
          <div>Пользователь совешил любую покупку в указанных магазинах</div>
        )}
    </Label>
  </FieldRow>
);

Triggers.propTypes = {
  periodReceipts: PropTypes.number,
  receiptsCount: PropTypes.number,
  singleReceipt: PropTypes.number,
  singleReceiptItemsCount: PropTypes.number,
};

Triggers.defaultProps = {
  periodReceipts: null,
  receiptsCount: null,
  singleReceipt: null,
  singleReceiptItemsCount: null,
};

export default Triggers;
