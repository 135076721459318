import { TimePicker } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import React from 'react';

import { formatTime } from '@/utils/format';

const TimeRangeFilter = ({
  onChange,
  value,
  ampm,
  okLabel,
  cancelLabel,
  fromInputProps,
  toInputProps,
}) => (
  <div>
    <TimePicker
      {...fromInputProps}
      label="от"
      ampm={ampm}
      clearable
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      clearLabel={'Очистить'}
      labelFunc={formatTime}
      value={value.from || null}
      onChange={time => onChange({ ...value, from: time })}
    />
    <TimePicker
      {...toInputProps}
      label="до"
      ampm={ampm}
      clearable
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      clearLabel={'Очистить'}
      labelFunc={formatTime}
      value={value.to || null}
      onChange={time => onChange({ ...value, to: time })}
    />
  </div>
);

TimeRangeFilter.defaultProps = {
  fromInputProps: {},
  toInputProps: {},
  ampm: false,
  okLabel: 'Да',
  cancelLabel: 'Отмена',
};

TimeRangeFilter.propTypes = {
  fromInputProps: PropTypes.object,
  toInputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  ampm: PropTypes.bool,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

export default TimeRangeFilter;
