import {
  getCampaignDateEnd,
  getCampaignDateStart,
  getCampaignStatus,
} from '@esentai/core/features/campaigns/selectors';
import { connect } from 'react-redux';

import StatusLabel from './component';

const mapStateToProps = (state, { itemId }) => ({
  dateStart: getCampaignDateStart(state, itemId),
  dateEnd: getCampaignDateEnd(state, itemId),
  status: getCampaignStatus(state, itemId),
});

export default connect(mapStateToProps)(StatusLabel);
