import { withStyles } from '@material-ui/core';
import { goBack } from 'connected-react-router';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import BackButton from './component';
import styles from './styles';

export default compose(
  withStyles(styles),
  connect(null, { goBack }),
)(BackButton);
