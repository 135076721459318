import createItemsDuck from '@esentai/core/ducks/items';
import { RESOURCE_NAME } from '@esentai/core/features/salesTurnoverDetails/consts';

import api from '@/features/salesTurnoverDetailsPage/api';

import { getItemsRoot } from '../utils';

export default createItemsDuck([RESOURCE_NAME], {
  api,
  getRoot: getItemsRoot,
});
