import {
  FACTORY_NUMBER_KEY,
  KKM_NUMBER_KEY,
  MERCHANT_ID_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/kkms/consts/keys';
import { TextField } from '@material-ui/core';
import React, { Component } from 'react';

import AsyncButton from '@/components/AsyncButton';
import LinkAsButton from '@/components/Button';
import ButtonSet from '@/components/ButtonSet';
import FieldRow from '@/components/FieldRow';
import MerchantBinLazy from '@/containers/MerchantBinLazy';
import { StoreWithMerchantsAutocomplete } from '@/containers/StoreWithMechantsAutocomplete';
import Label from '@/features/campaignPage/Page/Label';
import { ROUTE_PATH as KKMS_PATH } from '@/features/kkmsPage/consts';
import FormikPropTypes from '@/prop-types/formik';

const fieldNames = {
  [KKM_NUMBER_KEY]: 'Номер ККМ',
  [STORE_ID_KEY]: 'Выберите магазин',
  [MERCHANT_ID_KEY]: 'БИН/ИИН',
  [FACTORY_NUMBER_KEY]: 'ЗНМ',
};

class NewKkmForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = { selectedStore: null };
  }

  onChangeStore = option => {
    this.setState({ selectedStore: option });
    if (option) {
      this.props.setFieldValue(MERCHANT_ID_KEY, option.merchant);
      this.props.setFieldValue(STORE_ID_KEY, option.store);
    }
  };

  render() {
    const {
      classes,
      submitButtonText,
      Field,
      Form,
      RequiredMessages,
      SubmitButton,
      errors,
      values,
    } = this.props;
    const { selectedStore } = this.state;

    return (
      <Form>
        <FieldRow label="Номер ККМ">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={KKM_NUMBER_KEY}
            required
            rows={4}
            label="Номер ККМ"
            InputProps={{
              placeholder: 'Номер ККМ',
              error: errors[KKM_NUMBER_KEY],
            }}
          />
        </FieldRow>

        <FieldRow label="ЗНМ">
          <Field
            margin="normal"
            Target={TextField}
            className={classes.wrap}
            name={FACTORY_NUMBER_KEY}
            required
            rows={4}
            label="ЗНМ"
            InputProps={{
              placeholder: 'ЗНМ',
              error: errors[FACTORY_NUMBER_KEY],
            }}
          />
        </FieldRow>

        <FieldRow label="Магазин" sudDesc="Выберите магазин">
          <StoreWithMerchantsAutocomplete
            id={STORE_ID_KEY}
            className={classes.wrap}
            name={STORE_ID_KEY}
            onChange={this.onChangeStore}
            selectedItem={selectedStore}
            InputProps={{
              placeholder: 'Магазин',
              error: errors[STORE_ID_KEY],
            }}
          />
        </FieldRow>

        {values.merchant_id && (
          <FieldRow label="БИН/ИИН">
            <Label>
              <MerchantBinLazy merchantId={values.merchant_id} />
            </Label>
          </FieldRow>
        )}

        <ButtonSet>
          <RequiredMessages fieldNames={fieldNames} />
          <LinkAsButton size="large" href={KKMS_PATH}>
            Отменить
          </LinkAsButton>
          <SubmitButton
            Target={AsyncButton}
            type="submit"
            color="primary"
            size="large"
            variant="contained"
          >
            {submitButtonText}
          </SubmitButton>
        </ButtonSet>
      </Form>
    );
  }
}

export default NewKkmForm;
