import { PERIOD_RECEIPTS_KEY } from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { Input, InputAdornment, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import FieldDescription from '@/components/FieldDescription';

const PeriodReceiptsForm = ({ classes, Field, disabled }) => (
  <div>
    <FieldDescription
      title="Сумма покупок"
      desc="Пользователь активирует триггер, если совершит любое количество покупок на указанную сумму за период действия кампании."
    />
    <Typography className={classes.fromLabel}>от</Typography>{' '}
    <Field
      disabled={disabled}
      Target={Input}
      name={PERIOD_RECEIPTS_KEY}
      endAdornment={<InputAdornment position="end">₸</InputAdornment>}
    />
  </div>
);

PeriodReceiptsForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  Field: PropTypes.func.isRequired,
};

export default PeriodReceiptsForm;
