import { __, anyPass } from 'ramda';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import {
  canCreateCampaign,
  canDeleteCampaign,
  canReadCampaignStatistics,
  canUpdateCampaign,
} from './permissions';

export const {
  getError: getCampaginsError,
  getItemsIds: getCampaginsIds,
  getItemsTotalCount: getCampaginsTotalCount,
  isLoadingItems: isLoadingCampagins,
} = itemsDuck.selectors;

export const {
  getFilters,
  getPage,
  getLimit,
  getSort,
  getQuery,
} = queryDuck.selectors;

export const getPermissionForActions = anyPass([
  canCreateCampaign,
  canUpdateCampaign,
  canDeleteCampaign,
  canReadCampaignStatistics,
])(__, null);
