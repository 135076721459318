import React from 'react';

import ArticleConstructorForm from '@/components/ArticleConstructorForm';
import FormikPropTypes from '@/prop-types/formik';

const ArticleForm = props => (
  <ArticleConstructorForm {...props} label="Наполнение статьи" />
);

ArticleForm.propTypes = FormikPropTypes;

export default ArticleForm;
