import {
  HAS_TRIGGERS_KEY,
  PERIOD_RECEIPTS_KEY,
  RECEIPTS_COUNT_KEY,
  SINGLE_RECEIPT_ITEMS_COUNT_KEY,
  SINGLE_RECEIPT_KEY,
  TRIGGERS_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { mapProps } from 'recompose';

import TriggersForm from './component';

export default mapProps(({ setFieldValue, values, Field }) => ({
  hasTriggers: values[HAS_TRIGGERS_KEY],
  periodReceipts: values[PERIOD_RECEIPTS_KEY],
  receiptsCount: values[RECEIPTS_COUNT_KEY],
  singleReceipt: values[SINGLE_RECEIPT_KEY],
  singleReceiptItemsCount: values[SINGLE_RECEIPT_ITEMS_COUNT_KEY],
  triggers: values[TRIGGERS_KEY],
  setFieldValue,
  Field,
}))(TriggersForm);
