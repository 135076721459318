import createQueryDuck from '@/redux/query';

import { FEATURE_NAME } from '../consts';
import serializer from '../serializer';
import { getCurrentQuery } from '../utils';

export default createQueryDuck(FEATURE_NAME, {
  getRoot: getCurrentQuery,
  serializer,
});
