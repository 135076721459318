import {
  BOUNCE_TRIGGER,
  LEAD_TRIGGER,
  OPEN_APP_TRIGGER,
  REGISTRATION_TRIGGER,
  VISITOR_TRIGGER,
} from '@esentai/core/features/campaigns/actionResult/consts/triggers';

import { actionResultTrigger } from '@/features/campaignPage/labels';
import CheckboxGroupFilter, {
  createCheckboxFilterTemplate,
} from '@/filters/CheckboxGroup';

export const options = [
  BOUNCE_TRIGGER,
  LEAD_TRIGGER,
  OPEN_APP_TRIGGER,
  REGISTRATION_TRIGGER,
  VISITOR_TRIGGER,
].map(key => ({ key, label: actionResultTrigger(key) }));

export const createActionResultTriggerFilter = createCheckboxFilterTemplate({
  options,
});

export default CheckboxGroupFilter;
