import { ArrowUpward } from '@material-ui/icons';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import AsyncButton from '@/components/AsyncButton';

const MoveUpButton = props => (
  <ActionButton
    text="Поднять выше"
    button={AsyncButton}
    icon={ArrowUpward}
    {...props}
  />
);

export default MoveUpButton;
