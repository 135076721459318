import { DATE_CREATION_KEY } from '@esentai/core/features/products/consts/keys';
import { annotations } from '@esentai/core/features/products/serializer';
import { descending } from '@esentai/core/query-dsl';
import { sort } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: descending(DATE_CREATION_KEY),
  }),
});
