import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AddLink from '@/components/AddLink';
import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import CanView from '@/containers/CanView';
import { isMallStaff } from '@/features/currentUser/selectors';
import { ROUTE_PATH as CREATE_KKM_PATH } from '@/features/kkmCreatePage/consts/index';
import { downloadXls } from '@/features/kkmsPage/utils';

import Table from './Table';

class Kkms extends Component {
  static propTypes = {
    error: PropTypes.any,
    kkmIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  render() {
    const { error, kkmIds, isLoading, totalCount, classes } = this.props;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <PageTitle gutterBottom={false}>Справочник ККМ</PageTitle>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.excelButton}
              onClick={downloadXls}
            >
              Вывести в Excel
            </Button>
            <CanView permission={isMallStaff}>
              <Tooltip title="Добавить новый ККМ">
                <AddLink to={CREATE_KKM_PATH} />
              </Tooltip>
            </CanView>
            <Table
              error={error}
              items={kkmIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </PageContent>
      </Page>
    );
  }
}

export default Kkms;
