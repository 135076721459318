import { getBenefitRewardIds } from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Benefits from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  benefitRewardIds: getBenefitRewardIds(state, itemId),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Benefits);
