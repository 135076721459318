import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';

const Spinner = () => (
  <Grid container justify="center">
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
);

export default Spinner;
