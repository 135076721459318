import { STORE_ID_KEY } from '@esentai/core/features/benefits/consts/keys';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import { StoreAutocomplete } from '@/containers/StoreAutocomplete';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';

class StoreForm extends Component {
  static propTypes = FormikPropTypes;

  setStoreId = value => {
    const { setFieldValue } = this.props;

    setFieldValue(STORE_ID_KEY, value);
  };

  render() {
    const { values } = this.props;

    return (
      <FieldRow
        label="Магазин-спонсор"
        subLabel="Магазин, предоставляющий бенефиты"
        sudDesc="Укажите, какой арендатор предоставляет бенефиты для реализации. Это поможет отслеживать статистику."
      >
        <CanEdit field={STORE_ID_KEY}>
          <StoreAutocomplete
            onChange={this.setStoreId}
            selectedItem={values[STORE_ID_KEY]}
            InputProps={{
              fullWidth: true,
              placeholder: 'Добавьте магазин-спонсор',
              InputProps: { disableUnderline: true },
            }}
          />
        </CanEdit>
      </FieldRow>
    );
  }
}

export default StoreForm;
