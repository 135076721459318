import PropTypes from 'prop-types';

const UserNameLazy = ({ isLoaded, title }) => (isLoaded ? title : '...');

UserNameLazy.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

UserNameLazy.defaultProps = {
  title: null,
};

export default UserNameLazy;
