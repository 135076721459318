import {
  findStoreById,
  updateStore,
} from '@esentai/core/features/stores/actions';
import {
  getStoreLoadingError,
  isStoreLoaded,
  isStoreLoading,
} from '@esentai/core/features/stores/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import { getRouterParameterByMatch } from '@/redux/router/selectors';

import component from './component';

const ID_KEY = 'storeId';

const mapStateToProps = (_, { match }) => ({
  storeId: getRouterParameterByMatch(match, ID_KEY),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  updateStore: values =>
    dispatch(
      updateStore({
        ...values,
        storeId: getRouterParameterByMatch(match, ID_KEY),
      }),
    ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    fetchItem: findStoreById,
    getItemLoadingError: getStoreLoadingError,
    isItemLoaded: isStoreLoaded,
    isItemLoading: isStoreLoading,
  }),
)(component);
