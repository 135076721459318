import { subYears } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import DateRange from '@/filters/DateRange';

const MINIMUM_USER_AGE = 18;
const EXPECTED_MAXIMUM_USER_AGE = 100;

const BirthdayRangeFilter = props => {
  const { value } = props;
  const from = value.from || null;
  const today = new Date();
  const minDate = subYears(today, EXPECTED_MAXIMUM_USER_AGE);
  const maxDate = subYears(today, MINIMUM_USER_AGE);

  return (
    <DateRange
      {...props}
      fromInputProps={{ minDate, maxDate }}
      toInputProps={{ minDate: from, maxDate }}
    />
  );
};

BirthdayRangeFilter.propTypes = {
  value: PropTypes.object.isRequired,
};

export default BirthdayRangeFilter;
