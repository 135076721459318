import {
  articlesCountBlock,
  bannersBlock,
  buttonPlacementBlock,
  calendarEventsCountBlock,
  emptyBlock,
  showcaseIdBlock,
} from '@esentai/core/features/applicationSettings/homeLayout/blocks';
import {
  PAYLOAD_KEY,
  TYPE_KEY,
} from '@esentai/core/features/applicationSettings/homeLayout/consts/blockKeys';
import {
  ARTICLES_BLOCK,
  BANNERS_BLOCK,
  BUTTON_BLOCK,
  CALENDAR_BLOCK,
  SHOWCASES_BLOCK,
} from '@esentai/core/features/applicationSettings/homeLayout/consts/blocks';
import { BUTTON_PLACEMENT_KEY } from '@esentai/core/features/applicationSettings/homeLayout/consts/payloadKeys';
import nanoid from 'nanoid';
import { compose, equals, omit, prop } from 'ramda';

const withUniqueId = object => ({
  ...object,
  id: nanoid(),
});

const withoutUniqueId = omit(['id']);

export const getBlockId = prop('id');

export const getBlockType = prop(TYPE_KEY);

export const createBlock = object => {
  const id = getBlockId(object);

  if (!id) {
    return withUniqueId(object);
  }

  return object;
};

export const createArticlesBlock = compose(withUniqueId, articlesCountBlock);

export const createBannersBlock = compose(withUniqueId, bannersBlock);

export const createButtonBlock = compose(withUniqueId, buttonPlacementBlock);

export const createCalendarEventsBlock = compose(
  withUniqueId,
  calendarEventsCountBlock,
);

export const createEmptyBlock = compose(withUniqueId, emptyBlock);

export const createShowCaseBlock = compose(withUniqueId, showcaseIdBlock);

export const getBlockTypeLabel = block => {
  const labels = {
    [ARTICLES_BLOCK]: 'Статьи',
    [BANNERS_BLOCK]: 'Главный баннер',
    [BUTTON_BLOCK]: 'Кнопка',
    [CALENDAR_BLOCK]: 'Календарные события',
    [SHOWCASES_BLOCK]: 'Онлайн витрина',
  };
  const type = getBlockType(block);

  return labels[type];
};

export const getBlockPayload = prop(PAYLOAD_KEY);

export const getButtonBlockPlacement = compose(
  prop(BUTTON_PLACEMENT_KEY),
  getBlockPayload,
);

export const isBannerBlock = compose(equals(BANNERS_BLOCK), getBlockType);

export const isButtonBlock = compose(equals(BUTTON_BLOCK), getBlockType);

export const areBlocksEqual = (firstBlock, secondBlock) =>
  equals(withoutUniqueId(firstBlock), withoutUniqueId(secondBlock));
