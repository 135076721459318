import {
  deleteParkingTowerStopList,
  updateParkingTowerStopList,
} from '@esentai/core/features/parkingTowerStopList/actions';
import { getUserById } from '@esentai/core/features/users/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import {
  removeFilters,
  updateFilters,
  updateLimit,
  updatePage,
  updateSort,
} from '../actions';
import queryDuck from '../ducks/query';
import {
  getError,
  getFilters,
  getItemsIds,
  getItemsTotalCount,
  getLimit,
  getPage,
  getSort,
  isLoadingUsers,
} from '../selectors';
import ParkingTowerStopListAddPage from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getError(state),
  userIds: getItemsIds(state),
  usersIsLoading: isLoadingUsers(state),
  usersTotalCount: getItemsTotalCount(state),
  getUserById: userId => getUserById(state, userId),
  limit: getLimit(state),
  page: getPage(state),
  sort: getSort(state),
  filters: getFilters(state),
});
const mapDispatchToProps = {
  updateLimit,
  updatePage,
  updateSort,
  removeFilters,
  updateFilters,
  updateParkingTowerStopList,
  deleteParkingTowerStopList,
};

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ParkingTowerStopListAddPage);
