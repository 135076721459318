import {
  REWARD_BENEFITS_IDS_KEY,
  REWARD_LOYALTY_POINTS_KEY,
  REWARD_TYPE_KEY,
} from '@esentai/core/features/campaigns/purchaseResult/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { mapProps } from 'recompose';

import DelayForm from './component';
import styles from './styles';

export default compose(
  mapProps(({ setFieldValue, values, Field }) => ({
    rewardBenefitsIds: values[REWARD_BENEFITS_IDS_KEY],
    rewardLoyaltyPoints: values[REWARD_LOYALTY_POINTS_KEY],
    rewardType: values[REWARD_TYPE_KEY],
    setFieldValue,
    Field,
  })),
  withStyles(styles),
)(DelayForm);
