import giftSetsApi from '@esentai/core/features/giftSets/api';
import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';

import api from '@/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import CanView from '@/components/CanView';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PageTitleLink from '@/components/PageTitleLink';
import PaperSheet from '@/components/PaperSheet';
import SortableGallery from '@/components/SortableGallery';
import { ROUTE_PATH as GIFT_SETS_PATH } from '@/features/giftSetsPage/consts';

import { PATCH_URL } from '../consts';
import NotFoundIcon from './notFoundIcon.svg';

const GiftSetsArrangement = ({ classes }) => {
  const [giftSets, setGiftSets] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const maxCheckboxCount = 2;

  const onSetGiftSets = products => {
    setGiftSets(
      products.map((cur, idx) => {
        cur.index = idx + 1;
        cur.src = cur.images.length > 0 ? cur.images[0] : '';
        cur.width = 200;
        cur.height = 200;
        cur.checked = false;

        return cur;
      }),
    );
  };

  const getCheckedItems = () => giftSets.filter(cur => cur.checked === true);

  const checkItem = (curItem, checkedItems) => {
    curItem.checked = !curItem.checked;
    setGiftSets(checkedItems);
  };

  const setCheckedItem = itemId => {
    const tempItems = [...giftSets];
    const checkedItems = getCheckedItems();
    let curItem = giftSets.find(res => res.id === itemId);

    if (checkedItems.length < maxCheckboxCount) {
      if (curItem) {
        checkItem(curItem, tempItems);
      }
    } else if (checkedItems.length === maxCheckboxCount) {
      curItem = checkedItems.find(res => res.id === itemId);
      if (curItem) {
        checkItem(curItem, tempItems);
      }
    }
  };

  const fetchGiftSets = async () => {
    const data = await giftSetsApi.doQuery([], 'index', 'asc', 9999);

    if (data.payload.gift_set && data.payload.gift_set.length > 0) {
      onSetGiftSets(data.payload.gift_set);
      setIsVisible(false);
    }
  };

  useEffect(() => {
    fetchGiftSets();
  }, []);

  const saveNewArrangement = () => {
    setIsVisible(true);
    const data = {};

    data.gift_set = giftSets.map(cur => {
      const temp = {};

      temp.id = cur.id;
      temp.index = cur.index;

      return temp;
    });

    api.doPatch(PATCH_URL, data).then(() => {
      fetchGiftSets();
    });
  };

  const swapItems = () => {
    const checkedItems = giftSets.filter(cur => cur.checked === true);
    const tempIdx1 = checkedItems[0].index;
    const tempIdx2 = checkedItems[1].index;

    const tempList = [...giftSets];

    tempList[tempIdx1 - 1].index = tempIdx2;
    tempList[tempIdx2 - 1].index = tempIdx1;
    [tempList[tempIdx1 - 1], tempList[tempIdx2 - 1]] = [
      tempList[tempIdx2 - 1],
      tempList[tempIdx1 - 1],
    ];

    setGiftSets(tempList);
  };

  return (
    <Page>
      <PageHeader borderBottom gutterBottom={false} xl={8}>
        <Breadcrumbs />
        <Grid container alignItems="baseline">
          <PageTitle>Компоновка ленты</PageTitle>
          <PageTitleLink to={GIFT_SETS_PATH}>Подарочные наборы</PageTitleLink>
        </Grid>
      </PageHeader>
      <PageContent xl={8}>
        <PaperSheet>
          <div className={classes.saveButton}>
            <Button
              variant="outlined"
              color="secondary"
              disabled={getCheckedItems().length < 2}
              onClick={() => swapItems()}
            >
              Поменять местами
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveNewArrangement()}
              style={{ marginTop: 15 }}
            >
              Сохранить
            </Button>
          </div>
          {!isVisible && giftSets && giftSets.length > 0 && (
            <SortableGallery
              items={giftSets}
              setItems={onSetGiftSets}
              setCheckedItem={setCheckedItem}
              axis={'xy'}
            />
          )}
          <div className={classes.spinner}>
            <CanView isVisible={isVisible}>
              <CircularProgress />
            </CanView>
            <CanView isVisible={giftSets.length === 0}>
              <NotFoundIcon className={classes.repairIcon} />
              <Typography
                variant="h4"
                color="textSecondary"
                className={classes.title}
              >
                Ничего не найдено
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                По вашему запросу ничего не найдено, пожалуйста измените
                критерии поиска
              </Typography>
            </CanView>
          </div>
        </PaperSheet>
      </PageContent>
    </Page>
  );
};

export default GiftSetsArrangement;
