import {
  getCampaignDateEnd,
  getCampaignDateStart,
  getCampaignDateTimeEnd,
  getCampaignDateTimeStart,
} from '@esentai/core/features/campaigns/selectors';
import { connect } from 'react-redux';

import { getCampaignId } from '@/features/campaignCreatePage/selectors';

import DateComparer from './component';

const mapStateToProps = (state, { itemId }) => {
  const id = getCampaignId(state);

  return {
    dateStart: getCampaignDateStart(state, itemId),
    dateEnd: getCampaignDateEnd(state, itemId),
    campaignDateStart: getCampaignDateTimeStart(state, id),
    campaignDateEnd: getCampaignDateTimeEnd(state, id),
  };
};

export default connect(mapStateToProps)(DateComparer);
