import {
  DATETIME_END_KEY,
  DATETIME_START_KEY,
  LOCATION_IDS_KEY,
  TITLE_KEY,
  TYPE_KEY,
} from '@esentai/core/features/campaigns/consts/keys';
import { PAUSED_STATUS } from '@esentai/core/features/campaigns/consts/statuses';
import {
  ARTICLE_TYPE,
  BANNER_TYPE,
  NOTIFICATION_TYPE,
  SHOW_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import AsyncButton from '@/components/AsyncButton';
import Button from '@/components/Button';
import DateTimePicker from '@/components/DateTimePicker';
import FieldRow from '@/components/FieldRow';
import RadioBox, { RadioBoxGroup } from '@/components/RadioTile';
import TextField from '@/components/TextField';
import {
  TYPES_WITHOUT_END_DATE,
  TYPES_WITHOUT_START_DATE,
} from '@/features/campaignCreatePage/consts/types';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../CanEdit';

const fieldNames = {
  [DATETIME_START_KEY]: 'дата начала кампании',
  [DATETIME_END_KEY]: 'дата окончания кампании',
  [TITLE_KEY]: 'название кампании',
  [LOCATION_IDS_KEY]: 'статистика локаций',
};

const FirstStep = props => {
  const {
    classes,
    isEdit,
    Field,
    Form,
    RequiredMessages,
    SubmitButton,
    values,
    handleChange,
    handleSubmit,
    remove,
    currentStatus,
  } = props;

  const type = values[TYPE_KEY];
  const hasNoStartDate = TYPES_WITHOUT_START_DATE.includes(type);
  const hasNoEndDate = TYPES_WITHOUT_END_DATE.includes(type);
  const hasNoPeriod = hasNoStartDate || hasNoEndDate;

  return (
    <Form onSubmit={handleSubmit}>
      <FieldRow label="Описание">
        <Field
          Target={TextField}
          label="Название кампании"
          helperText="Название кампании будет отображаться только для вас и модераторов в списке кампаний"
          name={TITLE_KEY}
          symbolsMax={200}
          margin="none"
          className={classes.titleField}
        />

        <div className={classes.datepickers}>
          <CanEdit field={DATETIME_START_KEY}>
            <Field
              disablePast
              clearable
              Target={DateTimePicker}
              label="Дата начала проведения кампании"
              helperText="Дата непосредственного начала кампании, отображаемая для покупателей. Активен с 00:00:01."
              name={DATETIME_START_KEY}
              className={classes.datepicker}
              disabled={hasNoStartDate}
            />
          </CanEdit>
          <CanEdit field={DATETIME_END_KEY}>
            <Field
              disablePast
              clearable
              Target={DateTimePicker}
              minDate={values[DATETIME_START_KEY]}
              label="Дата завершения кампании"
              helperText="Дата непосредственного завершения кампании, отображаемая для покупателей. Действителен до 23:59:59."
              name={DATETIME_END_KEY}
              className={classes.datepicker}
              disabled={hasNoEndDate}
            />
          </CanEdit>
          {hasNoPeriod && (
            <Typography variant="caption">
              Выбранный тип кампании не подразумевает период ее действия.
            </Typography>
          )}
        </div>
      </FieldRow>

      <FieldRow label="Стратегия" subLabel="Тип маркетинговой кампании">
        <RadioBoxGroup
          name={TYPE_KEY}
          value={values[TYPE_KEY]}
          onChange={handleChange}
        >
          <RadioBox
            value={BANNER_TYPE}
            label="Баннер"
            helperText="Размещение рекламной вставки в различных местах приложения."
            disabled={isEdit}
          />
          <RadioBox
            value={ARTICLE_TYPE}
            label="Статья"
            helperText="Размещение рекламной статьи, на которую могут ссылаться другие кампании. Будет отображаться в главной ленте мобильного приложения."
            disabled={isEdit}
          />
          <RadioBox
            value={NOTIFICATION_TYPE}
            label="Разовая рассылка"
            helperText="Единоразовая рассылка Push-сообщения клиентам приложения."
            disabled={isEdit}
          />
          <RadioBox
            value={SHOW_TYPE}
            label="Онлайн витрина"
            helperText="Витрина с товарами."
            disabled={isEdit}
          />
        </RadioBoxGroup>
      </FieldRow>
      <FieldRow>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            {remove && currentStatus !== PAUSED_STATUS ? (
              <Button
                className={classes.cancelButton}
                button={AsyncButton}
                onClick={() => remove()}
              >
                Отменить создание кампании
              </Button>
            ) : (
              <Button className={classes.goBackLink} href={'/campaigns'}>
                Вернуться назад
              </Button>
            )}
            <SubmitButton
              Target={AsyncButton}
              type="submit"
              color="primary"
              size="large"
              variant="contained"
            >
              Перейти к настройке кампании
            </SubmitButton>
          </Grid>
          <Grid item>
            <RequiredMessages fieldNames={fieldNames} />
          </Grid>
        </Grid>
      </FieldRow>
    </Form>
  );
};

FirstStep.propTypes = {
  ...FormikPropTypes,
  isEdit: PropTypes.bool,
};

FirstStep.defaultProps = {
  isEdit: false,
};

export default FirstStep;
