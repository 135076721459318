import {
  getNameForStore,
  isStoreActive,
} from '@esentai/core/features/stores/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Suggestion from './component';
import styles from './styles';

const mapStateToProps = (state, { itemId }) => ({
  isActive: isStoreActive(state, itemId),
  name: getNameForStore(state, itemId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Suggestion);
