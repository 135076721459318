import { getLoyaltyPointsReward } from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { connect } from 'react-redux';

import Bonuses from '@/features/campaignPage/Page/Rewards/Bonuses';

const mapStateToProps = (state, { itemId }) => ({
  bonusesReward: getLoyaltyPointsReward(state, itemId),
});

export default connect(mapStateToProps)(Bonuses);
