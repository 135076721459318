import { compose } from 'ramda';
import { connect } from 'react-redux';

import queryDuck from '@/features/locationsPage/ducks/query';
import {
  getLocationsError,
  getLocationsIds,
  getLocationsTotalCount,
  isLoadingLocations,
} from '@/features/locationsPage/selectors';
import withQuerySync from '@/hocs/withQuerySync';

import Locations from './component';

const mapStateToProps = state => ({
  error: getLocationsError(state),
  itemsIds: getLocationsIds(state),
  isLoading: isLoadingLocations(state),
  totalCount: getLocationsTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
)(Locations);
