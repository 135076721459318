import {
  IMAGES_LAYOUT,
  LOCATION_LAYOUT,
  ONLINE_SHOWCASE_LAYOUT,
  PARAGRAPH_LAYOUT,
  VIDEO_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/layouts';

import { SUBTITLE_LAYOUT } from '@/components/ArticleConstructorForm/consts';

export default {
  [IMAGES_LAYOUT]: 15,
  [SUBTITLE_LAYOUT]: 15,
  [PARAGRAPH_LAYOUT]: 15,
  [VIDEO_LAYOUT]: 5,
  [LOCATION_LAYOUT]: 1,
  [ONLINE_SHOWCASE_LAYOUT]: 1,
};
