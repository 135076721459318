import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';

import { TURN_OFF_STRATEGY, TURN_ON_STRATEGY } from './consts';
import { propTypes as strategyPropTypes } from './strategies';

export default class PasswordField extends Component {
  static propTypes = {
    strategy: strategyPropTypes.isRequired,
    InputProps: PropTypes.object,
    setStrategy: PropTypes.func.isRequired,
  };

  static defaultProps = {
    InputProps: {},
  };

  render() {
    const { strategy, InputProps } = this.props;
    const { type, Icon } = strategy;
    const textFieldProps = omit(
      ['strategy', 'setStrategy', 'findStrategyBy'],
      this.props,
    );

    return (
      <TextField
        {...textFieldProps}
        type={type}
        InputProps={{
          endAdornment: this.renderAdornment(Icon),
          ...InputProps,
        }}
      />
    );
  }

  handleClick = () => {
    const { strategy, setStrategy } = this.props;
    const { name } = strategy;

    setStrategy(
      name === TURN_ON_STRATEGY ? TURN_OFF_STRATEGY : TURN_ON_STRATEGY,
    );
  };

  renderAdornment = Icon => (
    <InputAdornment position="end">
      <IconButton aria-label="Показать пароль" onClick={this.handleClick}>
        <Icon />
      </IconButton>
    </InputAdornment>
  );
}
