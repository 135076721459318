import {
  EMPTY_LAYOUT,
  IMAGES_LAYOUT,
  LOCATION_LAYOUT,
  PARAGRAPH_LAYOUT,
  TITLE_LAYOUT,
  VIDEO_LAYOUT,
} from '@esentai/core/features/campaigns/article/consts/layouts';

import { SUBTITLE_LAYOUT } from '@/components/ArticleConstructorForm/consts';

import ImagesBlock from './ImagesBlock';
import LocationBlock from './LocationBlock';
import ParagraphBlock from './ParagraphBlock';
import SubtitleBlock from './SubtitleBlock';
import TitleBlock from './TitleBlock';
import VideoBlock from './VideoBlock';

export default {
  [EMPTY_LAYOUT]: () => null,
  [IMAGES_LAYOUT]: ImagesBlock,
  [TITLE_LAYOUT]: TitleBlock,
  [SUBTITLE_LAYOUT]: SubtitleBlock,
  [PARAGRAPH_LAYOUT]: ParagraphBlock,
  [VIDEO_LAYOUT]: VideoBlock,
  [LOCATION_LAYOUT]: LocationBlock,
};
