import {
  ACTIVE_KEY,
  END_DATE_KEY,
  START_DATE_KEY,
  STORES_KEY,
} from '@esentai/core/features/mallBonuses/consts/keys';

import { createDateRangeFilter } from '@/filters/DateRange';
import { createMallBonusActivityStateFilter } from '@/filters/MallBonusActivityState';
import { createStoreFilter } from '@/filters/Store';
import { createMultipleValuesRelationAdapter } from '@/structs/pageFilterAdapter';

export const filterTypes = [
  createMallBonusActivityStateFilter({
    key: ACTIVE_KEY,
    label: 'Активность',
    quicklyAccessible: true,
  }),
  createStoreFilter({
    key: STORES_KEY,
    label: 'Магазин',
    adapter: createMultipleValuesRelationAdapter(null),
  }),
  createDateRangeFilter({
    key: START_DATE_KEY,
    label: 'Дата начала',
  }),
  createDateRangeFilter({
    key: END_DATE_KEY,
    label: 'Дата окончания',
  }),
];
