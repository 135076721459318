import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const ImageWrapper = ({ height, classes, ...props }) => (
  <img className={cx(height && classes.stackItem)} {...props} />
);

ImageWrapper.defaultProps = {
  height: null,
};

ImageWrapper.propTypes = {
  height: PropTypes.number,
};

export default ImageWrapper;
