import {
  getProductLoadingError,
  isProductLoaded,
  isProductLoading,
} from '@esentai/core/features/products/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { fetchProduct as fetchItem } from '@/features/productPage/actions';
import { getProductId } from '@/features/productPage/selectors';
import withItemPageRunner from '@/hocs/withItemPageRunner';

import Product from './component';

const mapStateToProps = state => {
  const itemId = getProductId(state);

  return {
    error: getProductLoadingError(state, itemId),
    isLoaded: isProductLoaded(state, itemId),
    isLoading: isProductLoading(state, itemId),
    itemId,
  };
};

const mapDispatchToProps = {
  fetchItem,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemPageRunner(),
)(Product);
