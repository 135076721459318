import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PageTitleLink from '@/components/PageTitleLink';
import CanView from '@/containers/CanView';
import { isMallStaff } from '@/features/currentUser/selectors';
import { ROUTE_PATH as MALL_BONUSES_PATH } from '@/features/mallBonusesPage/consts';
import { downloadXls } from '@/features/storeBonusesPage/utils';

import { SelectBonusType } from '../../../components/SelectBonusType/SelectBonusType';
import Table from './Table';

class StoreBonuses extends Component {
  static propTypes = {
    error: PropTypes.any,
    storeBonusIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);

    this.state = { dialog: false };
  }

  render() {
    const { error, storeBonusIds, isLoading, totalCount, classes } = this.props;
    const { dialog } = this.state;

    return (
      <Page>
        <PageHeader borderBottom gutterBottom={false}>
          <Breadcrumbs />
          <Grid container alignItems="baseline">
            <PageTitle gutterBottom={false}>Магазин</PageTitle>
            <PageTitleLink to={MALL_BONUSES_PATH}>Esentai Mall</PageTitleLink>
          </Grid>
        </PageHeader>
        <PageContent>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.excelButton}
              onClick={downloadXls}
            >
              Вывести в Excel
            </Button>
            <CanView permission={isMallStaff}>
              <Button
                variant="contained"
                color="primary"
                className={classes.excelButton}
                onClick={() => {
                  this.setState({ dialog: true });
                }}
              >
                Создать настройку бонусов
              </Button>
            </CanView>
            <Table
              error={error}
              items={storeBonusIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
          <SelectBonusType
            open={dialog}
            onClose={() => this.setState({ dialog: false })}
          />
        </PageContent>
      </Page>
    );
  }
}

export default StoreBonuses;
