import React, { Fragment } from 'react';

const numericColumnWidth = '120';

const ColGroup = () => (
  <Fragment>
    <colgroup width="170" />
    <colgroup span={2}>
      <col width={numericColumnWidth} />
      <col width={numericColumnWidth} />
    </colgroup>
    <colgroup width="1*" />
  </Fragment>
);

export default ColGroup;
