import { getPhoneForUser } from '@esentai/core/features/users/selectors';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Done, ErrorOutline } from '@material-ui/icons';
import _get from 'lodash.get';
import React, { useState } from 'react';

import api from '@/api';
import { UserPhoneAutocomplete } from '@/containers/UserPhoneAutocomplete';
import {
  CUSTOMER_ID_KEY,
  PHONE_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';
import { validate } from '@/utils/validate';

const TransferBonusesForm = ({
  Form,
  resetForm,
  state,
  classes,
  setCustomerId,
}) => {
  const [customer, setCustomer] = useState({
    data: {},
    loading: false,
    error: null,
  });

  const [customerForm, setCustomerForm] = useState({
    [CUSTOMER_ID_KEY]: '',
    [PHONE_KEY]: '',
  });

  const isUserAvailable = Boolean(Object.keys(customer.data).length);

  const searchCustomer = async () => {
    setCustomer(prevState => ({
      ...prevState,
      data: {},
      loading: true,
      error: null,
    }));

    try {
      const params = {
        'phone[ilike]': getPhoneForUser(state, customerForm[PHONE_KEY]),
        'id[eq]': customerForm[CUSTOMER_ID_KEY],
        'role[eq]': 'customer',
      };

      const response = await api.doGet(`/user`, params);

      const userData = _get(response, 'payload.user', []);

      setCustomer(prevState => ({
        ...prevState,
        data: _get(userData, '0', {}),
        error: userData.length ? null : 'User not found',
      }));
      setCustomerId(userData[0].id);

      resetForm();
    } catch (error) {
      setCustomer(prevState => ({
        ...prevState,
        error,
      }));
      setCustomerId(null);
      throw error;
    } finally {
      setCustomer(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  return (
    <Form>
      <Grid container direction="column" spacing={16}>
        <Grid item>
          <TextField
            label="ID покупателя"
            value={customerForm[CUSTOMER_ID_KEY]}
            margin="normal"
            type="number"
            className={classes.hideArrows}
            style={{ marginRight: 15, marginBottom: 20 }}
            onChange={event => {
              setCustomerForm({
                ...customerForm,
                [CUSTOMER_ID_KEY]: event.target.value,
              });
            }}
            onKeyPress={event => {
              validate(event, 'positiveInt');

              if (
                event.key === 'Enter' &&
                (customerForm[CUSTOMER_ID_KEY] || customerForm[PHONE_KEY])
              ) {
                searchCustomer();
              }
            }}
          />
        </Grid>
        <Grid item>
          <UserPhoneAutocomplete
            onChange={event =>
              setCustomerForm(prevState => ({
                ...prevState,
                [PHONE_KEY]: event,
              }))
            }
            // onBlur={handleBlur}
            selectedItem={customerForm[PHONE_KEY]}
            InputProps={{
              placeholder: 'Телефон',
            }}
            isSmall
          />
        </Grid>

        <Grid item>
          <Button
            disabled={
              !customerForm[CUSTOMER_ID_KEY] && !customerForm[PHONE_KEY]
            }
            onClick={searchCustomer}
          >
            Поиск пользователя
          </Button>
        </Grid>

        <Grid item>
          <div className={classes.customerInfo}>
            {!customer.error && (
              <>
                <Typography variant="subtitle1" style={{ marginRight: 15 }}>
                  ФИО:
                </Typography>
                {isUserAvailable && (
                  <div className={classes.customerInfo}>
                    <Typography
                      variant="subtitle1"
                      className={classes.successAccent}
                    >
                      {`${_get(customer, 'data.first_name', '-')} ${_get(
                        customer,
                        'data.last_name',
                        '-',
                      )}`}
                    </Typography>
                    <Done
                      className={classes.successAccent}
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                )}
              </>
            )}
            {customer.loading && (
              <div className={classes.errorWrapper}>
                <CircularProgress color="secondary" size={10} />
              </div>
            )}
            {customer.error && (
              <div className={classes.errorWrapper}>
                <Typography variant="subtitle1" color="error">
                  Пользователь не найден
                </Typography>
                <ErrorOutline style={{ marginLeft: '10px' }} color="error" />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

TransferBonusesForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default TransferBonusesForm;
