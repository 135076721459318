import { connect } from 'react-redux';

import {
  getConversionRate,
  getPrePushClicksCount,
  getPushClicksToSegmentPercentage,
  getSegment,
} from '@/features/campaignsStatisticsDetailsPage/selectors';

import NotificationFacts from './component';

const mapStateToProps = (state, { itemId }) => ({
  segmentSize: getSegment(state, itemId),
  prePushClicksCount: getPrePushClicksCount(state, itemId),
  conversion: getConversionRate(state, itemId),
  pushClicksToSegmentPercentage: getPushClicksToSegmentPercentage(
    state,
    itemId,
  ),
});

export default connect(mapStateToProps)(NotificationFacts);
