import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';

import AsyncButton from '@/components/AsyncButton';
import { FormikWithValidationPropTypes } from '@/prop-types/formik';

import { loginFieldName } from './names';

const ForgotPasswordForm = ({
  Form,
  Field,
  SubmitButton,
  RequiredMessages,
  classes,
}) => (
  <Form>
    <Grid container direction="column" spacing={16}>
      <Grid item>
        <Typography variant="h4" gutterBottom>
          Восстановление доступа
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">Получите код подтверждения</Typography>
        <Typography color="textSecondary">
          Чтобы получить код подтверждения, введите адрес электронной почты,
          указанный для аккаунта.
        </Typography>
      </Grid>
      <Grid item>
        <Field
          Target={TextField}
          id={loginFieldName}
          name={loginFieldName}
          label="Логин или e-mail"
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item className={classes.alignSelfCenter}>
        <SubmitButton
          Target={AsyncButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
        >
          Отправить
        </SubmitButton>
      </Grid>
      <Grid item className={classes.alignSelfCenter}>
        <RequiredMessages fieldNames={{ [loginFieldName]: 'логин' }} />
      </Grid>
    </Grid>
  </Form>
);

ForgotPasswordForm.propTypes = {
  ...FormikWithValidationPropTypes,
};

export default ForgotPasswordForm;
