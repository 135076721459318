import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { object, string } from 'yup';

import styles from '@/features/authPages/styles';
import withAsyncFormik from '@/hocs/withAsyncFormik';

import CategoryForm from './component';

const errorsMapping = {};
const formikOptions = {
  mapPropsToValues: ({ initialValues }) => ({
    name_ru: initialValues ? initialValues.russianName : null,
    name_en: initialValues ? initialValues.englishName : null,
    name_kz: initialValues ? initialValues.kazakhName : null,
  }),

  validationSchema: object().shape({
    name_ru: string()
      .min(1, 'Название RU должно содержать менее 1 символа')
      .max(50, 'Название RU должно содержать не более 50 символов')
      .required(),
    name_en: string()
      .min(1, 'Название EN должно содержать менее 1 символа')
      .max(50, 'Название EN должно содержать не более 50 символов')
      .required(),
    name_kz: string()
      .min(1, 'Название KZ должно содержать менее 1 символа')
      .max(50, 'Название KZ должно содержать не более 50 символов')
      .required(),
  }),
  // eslint-disable-next-line camelcase
  handleSubmit({ name_ru, name_en, name_kz }, { props, setSubmitting }) {
    props.onSubmit(
      props.initialValues
        ? {
            name_ru,
            name_en,
            name_kz,
            id: props.initialValues.itemId,
          }
        : {
            name_ru,
            name_en,
            name_kz,
            visible: false,
          },
    );
    props.onClose();
    setSubmitting(false);
  },
};

const asyncOptions = { runnerMethod: 'onSubmit' };

export default compose(
  withAsyncFormik({ errorsMapping, formikOptions, asyncOptions }),
  withStyles(styles),
)(CategoryForm);
