import { IconButton, InputAdornment } from '@material-ui/core';
import {
  Event as EventIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import { DatePicker as OriginalDatePicker } from 'material-ui-pickers';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class DatePicker extends Component {
  handleChange = value => {
    const { onChange, name } = this.props;
    const fakeEvent = { target: { value, name } };

    onChange(fakeEvent);
  };

  render() {
    const { disabled, InputProps } = this.props;

    return (
      <OriginalDatePicker
        autoOk
        cancelLabel="Отмена"
        clearLabel="Сбросить"
        format="dd/MM/yyyy - EEEE"
        keyboardIcon={<EventIcon />}
        leftArrowIcon={<KeyboardArrowLeftIcon />}
        rightArrowIcon={<KeyboardArrowRightIcon />}
        {...this.props}
        onChange={this.handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={disabled}>
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
      />
    );
  }
}

DatePicker.defaultProps = {
  name: null,
  disabled: false,
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  InputProps: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DatePicker;
