import { TextField } from '@material-ui/core';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';

import {
  AMOUNT_KEY,
  CURRENT_AMOUNT_KEY,
  NAME_KEY,
  PRICE_KEY,
  PURCHASE_KEY,
  REFUND_AMOUNT_KEY,
} from '@/features/purchaseWithoutScanPage/consts/keys';
import { classList } from '@/features/purchaseWithoutScanPage/utils';
import { onArrowValidate, validate } from '@/utils/validate';

const ProductItem = props => {
  const { Field, index, classes, isRefund, children } = props;

  const calculateTotal = () => {
    const productObj = props.values[PURCHASE_KEY][index];

    return (
      (isRefund ? _get(productObj, 'refund_amount', 0) : productObj.amount) *
      productObj.price
    );
  };

  return (
    <div className={classes.productWrapper}>
      <Field
        margin="normal"
        Target={TextField}
        className={classes.product}
        disabled={isRefund}
        inputProps={{ maxLength: 200 }}
        name={`${PURCHASE_KEY}[${index}].${NAME_KEY}`}
        label="Товар"
      />
      {isRefund ? (
        <>
          <Field
            disabled
            margin="normal"
            Target={TextField}
            className={classes.product}
            type="number"
            name={`${PURCHASE_KEY}[${index}].${CURRENT_AMOUNT_KEY}`}
            label="Общее кол-во"
            style={{ marginRight: 15 }}
          />
          <Field
            margin="normal"
            Target={TextField}
            className={classes.product}
            type="number"
            onKeyPress={event => validate(event, 'positiveInt')}
            name={`${PURCHASE_KEY}[${index}].${REFUND_AMOUNT_KEY}`}
            label="Кол-во"
          />
        </>
      ) : (
        <Field
          margin="normal"
          Target={TextField}
          className={classes.product}
          type="number"
          name={`${PURCHASE_KEY}[${index}].${AMOUNT_KEY}`}
          onKeyPress={event => validate(event, 'positiveInt')}
          label="Кол-во"
        />
      )}

      <Field
        margin="normal"
        Target={TextField}
        className={classList(classes.product, classes.hideArrows)}
        type="number"
        disabled={isRefund}
        name={`${PURCHASE_KEY}[${index}].${PRICE_KEY}`}
        onKeyPress={event => validate(event, 'positiveInt')}
        onKeyDown={event => onArrowValidate(event)}
        label="Стоимость"
      />

      <TextField
        style={{ marginBottom: 13 }}
        disabled
        id="standard-disabled"
        label="Итого"
        value={calculateTotal()}
      />
      {children}
    </div>
  );
};

ProductItem.propTypes = {
  Field: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  isRefund: PropTypes.bool,
};

ProductItem.defaultProps = {
  isRefund: false,
};

export default ProductItem;
