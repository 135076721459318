import PropTypes from 'prop-types';
import React from 'react';

const PushNotificationPreview = ({ application, classes, title, message }) => (
  <div className={classes.root}>
    <div className={classes.application}>
      <div className={classes.icon} />
      <div className={classes.applicationName}>{application}</div>
    </div>
    <div className={classes.body}>
      <div className={classes.title}>{title}</div>
      <div className={classes.message}>{message}</div>
    </div>
  </div>
);

PushNotificationPreview.defaultProps = {
  application: 'MyEsentai',
};

PushNotificationPreview.propTypes = {
  application: PropTypes.string,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PushNotificationPreview;
