import { BLACK_STATUS } from '@esentai/core/features/users/consts/statuses';

import { getBarComponent } from './utils';

export const BlackBar = getBarComponent({
  styles: {
    bar: {
      backgroundColor: '#000000',
    },
  },
  status: BLACK_STATUS,
});
