import {
  ID_KEY,
  ORDER_CUSTOMER_ID_KEY,
  ORDER_CUSTOMER_LEVEL_KEY,
  ORDER_DEBT_KEY,
  ORDER_PAYMENT_TYPE_KEY,
  ORDER_STATUS_KEY,
  PARKING_PRICE_KEY,
  PARKING_RECORD_CREATED_KEY,
  PARKING_RECORD_PAYMENT_TIME_KEY,
  RECEIVED_AMOUNT_KEY,
  RECORD_CREATED_KEY,
  RECORD_PAYMENT_TIME_KEY,
  TEMPORARY_CAR_MODEL_KEY,
  TEMPORARY_CAR_NUMBER_KEY,
  VALET_FULLNAME_KEY,
  VALET_ONSTART_FULLNAME_KEY,
  VALET_PRICE_KEY,
  VEHICLE_GOV_NUMBER_KEY,
  VEHICLE_MODEL_KEY,
} from '@/features/valetParkingPage/consts/keys';
import { createDateTimeRangeFilter } from '@/filters/DateTimeRange';
import { createMoneyRangeFilter } from '@/filters/MoneyRange';
import { createParkingOrderStatusFilter } from '@/filters/ParkingOrderStatus';
import { createParkingOrderPaymentFilter } from '@/filters/ParkingPaymentMethod';
import { createEqualsFilter, createTextFilter } from '@/filters/SearchInput';
import { createUserStatusFilter } from '@/filters/UserStatus';

export const filterTypes = [
  createEqualsFilter({
    key: ID_KEY,
    label: 'ID заезда/выезда',
  }),
  createEqualsFilter({
    key: ORDER_CUSTOMER_ID_KEY,
    label: 'ID пользователя',
  }),
  createTextFilter({
    key: VEHICLE_MODEL_KEY,
    label: 'Модель авто - основной',
  }),
  createTextFilter({
    key: VEHICLE_GOV_NUMBER_KEY,
    label: 'Гос. номер авто - основной',
  }),
  createTextFilter({
    key: TEMPORARY_CAR_MODEL_KEY,
    label: 'Модель авто - временный',
  }),
  createTextFilter({
    key: TEMPORARY_CAR_NUMBER_KEY,
    label: 'Гос. номер авто - временный',
  }),
  createDateTimeRangeFilter({
    key: RECORD_CREATED_KEY,
    label: 'Дата и время принятия',
  }),
  createDateTimeRangeFilter({
    key: PARKING_RECORD_CREATED_KEY,
    label: 'Дата и время заезда',
  }),
  createDateTimeRangeFilter({
    key: RECORD_PAYMENT_TIME_KEY,
    label: 'Дата и время выдачи',
  }),
  createDateTimeRangeFilter({
    key: PARKING_RECORD_PAYMENT_TIME_KEY,
    label: 'Дата и время выезда',
  }),
  createMoneyRangeFilter({
    key: VALET_PRICE_KEY,
    label: 'Стоимость услуги',
  }),
  createMoneyRangeFilter({
    key: PARKING_PRICE_KEY,
    label: 'Стоимость заезда',
  }),
  createMoneyRangeFilter({
    key: ORDER_DEBT_KEY,
    label: 'Долг',
  }),
  createParkingOrderPaymentFilter({
    key: ORDER_PAYMENT_TYPE_KEY,
    label: 'Способ оплаты',
  }),
  createParkingOrderStatusFilter({
    key: ORDER_STATUS_KEY,
    label: 'Статус оплаты',
  }),
  createMoneyRangeFilter({
    key: RECEIVED_AMOUNT_KEY,
    label: 'Получено денег',
  }),
  createTextFilter({
    key: VALET_ONSTART_FULLNAME_KEY,
    label: 'ФИО Valet парковщика',
  }),
  createTextFilter({
    key: VALET_FULLNAME_KEY,
    label: 'ФИО Valet принимающий оплату',
  }),
  createUserStatusFilter({
    key: ORDER_CUSTOMER_LEVEL_KEY,
    label: 'Статус',
    quicklyAccessible: true,
  }),
];

export const filterPrefixes = [
  {
    field: ID_KEY,
    prefix: 'eq',
  },
  {
    field: ORDER_CUSTOMER_ID_KEY,
    prefix: 'eq',
  },
  {
    field: ORDER_STATUS_KEY,
    prefix: 'eq',
  },
  {
    field: ORDER_PAYMENT_TYPE_KEY,
    prefix: 'eq',
  },
  {
    field: VALET_PRICE_KEY,
    prefix: 'in',
  },
  {
    field: PARKING_PRICE_KEY,
    prefix: 'in',
  },
  {
    field: RECEIVED_AMOUNT_KEY,
    prefix: 'in',
  },
  {
    field: ORDER_CUSTOMER_LEVEL_KEY,
    prefix: 'in',
  },
  {
    field: ORDER_DEBT_KEY,
    prefix: 'in',
  },
  {
    field: RECORD_CREATED_KEY,
    prefix: 'eq',
  },
  {
    field: PARKING_RECORD_CREATED_KEY,
    prefix: 'eq',
  },
  {
    field: PARKING_RECORD_PAYMENT_TIME_KEY,
    prefix: 'eq',
  },
  {
    field: RECORD_PAYMENT_TIME_KEY,
    prefix: 'eq',
  },
  {
    field: VEHICLE_GOV_NUMBER_KEY,
    prefix: 'ilike',
  },
  {
    field: VEHICLE_MODEL_KEY,
    prefix: 'ilike',
  },
  {
    field: TEMPORARY_CAR_NUMBER_KEY,
    prefix: 'ilike',
  },
  {
    field: TEMPORARY_CAR_MODEL_KEY,
    prefix: 'ilike',
  },
  {
    field: VALET_FULLNAME_KEY,
    prefix: 'ilike',
  },
  {
    field: VALET_ONSTART_FULLNAME_KEY,
    prefix: 'ilike',
  },
];

export const multipleFilterList = [
  RECORD_CREATED_KEY, // Дата и время принятия
  PARKING_RECORD_CREATED_KEY, // Дата и время заезда
  RECORD_PAYMENT_TIME_KEY, // Дата и время выдачи
  PARKING_RECORD_PAYMENT_TIME_KEY, // Дата и время выезда
  VALET_PRICE_KEY, // Стоимость услуги
  PARKING_PRICE_KEY, // Стоимость заезда
  ORDER_DEBT_KEY, // Долг
  RECEIVED_AMOUNT_KEY, // Получено денег
];
