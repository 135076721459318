// Dimensions
//
// DO NOT USE THESE KEYS FOR DISPLAYING AND SORTING.
// ONLY FILTERING ALLOWED.
//
// If you need fields for displaying and sorting, see 'keys.js'

export {
  CUSTOMER_ID_KEY,
  LOCATION_ID_KEY,
  TIME_SERIES_KEY,
} from '@/features/purchasesStatistics/consts/keys';
