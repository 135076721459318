import { Typography } from '@material-ui/core';
import React from 'react';

const PageSubtitle = ({ classes, ...rest }) => (
  <Typography
    className={classes.content}
    variant="subtitle1"
    gutterBottom
    {...rest}
  />
);

export default PageSubtitle;
