import { getBarComponent } from './utils';

const MorningBar = getBarComponent({
  styles: {
    bar: {
      backgroundColor: '#a0a0a0',
    },
  },
});

export default MorningBar;
