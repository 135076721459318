import { RESOURCE_NAME } from '@esentai/core/features/mallReceptions/consts';
import { can, permission } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { MALL_RECEPTION } from './consts/permissions';

export const canMallReception = authorizeIf(
  can(permission(RESOURCE_NAME, MALL_RECEPTION)),
);
