import { getNameForParkingWhitelistGroup } from '@esentai/core/features/parkingWhitelistGroups/selectors';
import { connect } from 'react-redux';

import WhitelistGroupOption from '@/components/AutocompleteBase/Option';

const mapStateToProps = (state, { value }) => ({
  label: getNameForParkingWhitelistGroup(state, value),
});

export default connect(mapStateToProps, () => ({}))(WhitelistGroupOption);
