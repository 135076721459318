import api from '@esentai/core/features/kkms/api';
import {
  KKM_NUMBER_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/kkms/consts/keys';
import { equals, ilike, query, where } from '@esentai/core/query-dsl';
import { defaultProps } from 'recompose';

import AutocompleteBase from '@/components/AutocompleteBase';
import AutocompleteMultipleBase from '@/components/AutocompleteMultipleBase';
import AutocompleteAPI from '@/containers/AutocompleteAPI/component';
import AutocompleteAPIMultiple from '@/containers/AutocompleteAPIMultiple';
import withItemsFetcherWithStore from '@/hocs/withItemsFetcherWithStore';

import KkmChip from './KkmChip';
import KkmOption from './KkmOption';

const buildQuery = queryString =>
  query(where(KKM_NUMBER_KEY, ilike(queryString)));

const buildQueryWithStore = (queryString, storeId) =>
  query(
    where(KKM_NUMBER_KEY, ilike(queryString)),
    where(STORE_ID_KEY, equals(storeId)),
  );

const withKkmProps = defaultProps({
  api,
  buildQuery,
  Chip: KkmChip,
  Option: KkmOption,
});

const withKkmPropsWithStore = defaultProps({
  api,
  buildQuery: buildQueryWithStore,
  storeId: 0,
  Chip: KkmChip,
  Option: KkmOption,
});

export const KkmAutocomplete = withKkmProps(AutocompleteAPI);

export const KkmMultiAutocomplete = withKkmProps(AutocompleteAPIMultiple);

const AutocompleteAPIWithStoreId = defaultProps({
  Autocomplete: AutocompleteBase,
})(withItemsFetcherWithStore(AutocompleteAPI));

const AutocompleteAPIMultipleWithStoreId = defaultProps({
  Autocomplete: AutocompleteMultipleBase,
})(withItemsFetcherWithStore(AutocompleteAPI));

export const KkmMultiAutocompleteWithStore = withKkmPropsWithStore(
  AutocompleteAPIMultipleWithStoreId,
);

export const KkmAutocompleteWithStore = withKkmPropsWithStore(
  AutocompleteAPIWithStoreId,
);
