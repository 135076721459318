import { findKkmById } from '@esentai/core/features/kkms/actions';
import {
  getKkmNumberForKkm,
  isKkmLoaded,
  isKkmLoading,
} from '@esentai/core/features/kkms/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withItemApiRunner from '@/hocs/withItemApiRunner';

import KkmNameLazy from './component';

const mapStateToProps = (state, { kkmId }) => ({
  isLoaded: isKkmLoaded(state, kkmId),
  isLoading: isKkmLoading(state, kkmId),
  itemId: kkmId,
  title: getKkmNumberForKkm(state, kkmId),
});

const mapDispatchToProps = { fetchItem: findKkmById };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withItemApiRunner(),
)(KkmNameLazy);
