import { IMAGE_URL_KEY } from '@esentai/core/features/campaigns/banner/consts/keys';
import React, { Component } from 'react';

import FieldRow from '@/components/FieldRow';
import ImageField from '@/components/ImageField';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../../CanEdit';
import Preview from './Preview';

class StoreListContentForm extends Component {
  static propTypes = FormikPropTypes;

  setImageUrl = urls => {
    const { setFieldValue } = this.props;
    const [url] = urls;

    setFieldValue(IMAGE_URL_KEY, url);
  };

  render() {
    const { values } = this.props;

    return (
      <FieldRow
        label="Наполнение"
        subLabel="Изображение баннера"
        sudDesc="Баннер обязательно должен иметь изображение."
        Aside={this.renderPreview}
      >
        <CanEdit field={IMAGE_URL_KEY}>
          <ImageField
            ids={values[IMAGE_URL_KEY] ? [values[IMAGE_URL_KEY]] : []}
            onChange={this.setImageUrl}
          />
        </CanEdit>
      </FieldRow>
    );
  }

  renderPreview = () => {
    const { values } = this.props;

    return <Preview imageUrl={values[IMAGE_URL_KEY]} />;
  };
}

export default StoreListContentForm;
