import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DefaultAutocomplete from '@/components/AutocompleteBase';

class AutocompleteAPI extends Component {
  static propTypes = {
    Autocomplete: PropTypes.func,
    fetchItems: PropTypes.func.isRequired,
    suggestions: PropTypes.array,
    categoryId: PropTypes.number,
  };

  static defaultProps = {
    Autocomplete: DefaultAutocomplete,
    suggestions: [],
    categoryId: null,
  };

  componentDidMount() {
    if (this.props.categoryId) {
      this.props.fetchItems('', this.props.categoryId);
    } else {
      this.props.fetchItems('');
    }
  }

  render() {
    const { Autocomplete, fetchItems, suggestions, ...rest } = this.props;

    return (
      <Autocomplete
        {...rest}
        options={suggestions}
        onInputValueChange={(value, ref) => {
          fetchItems(value, ref);
        }}
      />
    );
  }
}

export default AutocompleteAPI;
