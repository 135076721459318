import PropTypes from 'prop-types';
import React from 'react';
import { defaultProps } from 'recompose';

import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';

import FormCreate from './Form';

const Form = defaultProps({
  submitButtonText: 'Сохранить вопрос',
})(FormCreate);

const EditQuestion = ({ isLoaded, itemId, updateQuestion }) =>
  isLoaded && (
    <SettingsPage>
      <SettingsPageHeader>
        <SettingsPageTitle>Изменить вопрос</SettingsPageTitle>
      </SettingsPageHeader>
      <SettingsPageContent>
        <Form onSubmit={updateQuestion} questionId={itemId} />
      </SettingsPageContent>
    </SettingsPage>
  );

EditQuestion.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  updateQuestion: PropTypes.func.isRequired,
};

export default EditQuestion;
