import { equals, pipe } from 'ramda';

import { getCurrentUserId } from '@/features/auth/selectors';

import {
  ALLOWED_LOCATION_IDS_KEY,
  EMAIL_KEY,
  LAST_ACTIVITY_DATE_KEY,
  NAME_KEY,
  ROLE_KEY,
  STATE_KEY,
  STORE_IDS_KEY,
} from './consts/keys';
import {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  INVITED_STATUS,
} from './consts/stateTypes';
import dao from './dao';

export const {
  getItemById: getPersonalityById,
  getItemLoadingError: getPersonnelLoadingError,
  getItemPropById: getPersonalityPropById,
  isLoadingItem: isLoadingPersonality,
  isItemLoaded: isLoadedPersonality,
} = dao.selectors;

export const getEmailForPersonnel = getPersonalityPropById(EMAIL_KEY);

export const getLastActivityDateForPersonnel = getPersonalityPropById(
  LAST_ACTIVITY_DATE_KEY,
);

export const getNameForPersonnel = getPersonalityPropById(NAME_KEY);

export const getRoleForPersonnel = getPersonalityPropById(ROLE_KEY);

export const getStateForPersonnel = getPersonalityPropById(STATE_KEY);

export const getStoresForPersonnel = getPersonalityPropById(STORE_IDS_KEY);

export const getLocationsForPersonnel = getPersonalityPropById(
  ALLOWED_LOCATION_IDS_KEY,
);

export const isPersonnelActive = pipe(
  getStateForPersonnel,
  equals(ACTIVE_STATUS),
);

export const isPersonnelInactive = pipe(
  getStateForPersonnel,
  equals(INACTIVE_STATUS),
);

export const isPersonnelInvited = pipe(
  getStateForPersonnel,
  equals(INVITED_STATUS),
);

export const isCurrentUser = (state, personnelId) =>
  getCurrentUserId(state) === personnelId;
