import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import withQuerySync from '@/hocs/withQuerySync';

import queryDuck from '../ducks/query';
import {
  getParkingWhitelistGroupsError,
  getParkingWhitelistGroupsIds,
  getParkingWhitelistGroupsTotalCount,
  isLoadingParkingWhitelistGroups,
} from '../selectors';
import ParkingWhitelistGroups from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getParkingWhitelistGroupsError(state),
  parkingWhitelistGroupsIds: getParkingWhitelistGroupsIds(state),
  isLoading: isLoadingParkingWhitelistGroups(state),
  totalCount: getParkingWhitelistGroupsTotalCount(state),
});

export default compose(
  withQuerySync(queryDuck),
  connect(mapStateToProps),
  withStyles(styles),
)(ParkingWhitelistGroups);
