import { getRussianQuestion } from '@esentai/core/features/questions/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import Row from './component';

const mapStateToProps = (state, { item: itemId }) => ({
  itemId,
  question: getRussianQuestion(state, itemId),
});

export default compose(connect(mapStateToProps))(Row);
