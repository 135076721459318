import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DateTimePicker from '@/components/DateTimePicker';

class RepeatTick extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    value: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    value: null,
  };

  onChange = event => {
    const { id, onChange } = this.props;
    const { value } = event.target;

    onChange(id, value);
  };

  onRemove = () => {
    const { id, onRemove } = this.props;

    onRemove(id);
  };

  render() {
    const { classes, value, disabled } = this.props;

    return (
      <div className={classes.root}>
        <DateTimePicker
          disabled={disabled}
          disablePast
          helperText="Точный день, в который будет разослано сообщение"
          value={value}
          onChange={this.onChange}
        />
        <IconButton disabled={disabled} onClick={this.onRemove}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  }
}

export default RepeatTick;
