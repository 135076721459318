import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router';

import AppLoading from '@/components/AppLoading';

const Home = ({ landingUrl }) =>
  landingUrl ? <Redirect exact to={landingUrl} /> : <AppLoading />;

Home.propTypes = {
  landingUrl: PropTypes.string,
};

Home.defaultProps = {
  landingUrl: null,
};

export default Home;
