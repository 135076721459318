import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withProps } from 'recompose';

import { recoverPassword } from '@/features/passwordRecovery/actions';
import withStrategy from '@/hocs/withStrategy';

import ForgotPassword from './component';
import strategies, { getStrategy } from './strategies';

const mapStateToProps = ({ router: { location } }) => {
  const query = new URLSearchParams(location.search);

  return {
    email: query.get('email'),
  };
};
const mapDispatchToProps = { recoverPassword };

export default compose(
  withRouter,
  withStrategy({ strategies }),
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'recoverPassword' }),
  withProps(ownProps => ({ strategy: getStrategy(ownProps) })),
)(ForgotPassword);
