import {
  can,
  canDelete,
  canRead,
  canUpdate,
  permission,
} from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

import { RESOURCE_NAME } from './consts';
import { EDIT_LOCATIONS_ACCESS } from './consts/permissions';

export const canReadPersonnel = authorizeIf(canRead(RESOURCE_NAME));

export const canUpdatePersonnel = authorizeIf(canUpdate(RESOURCE_NAME));

export const canDeletePersonnel = authorizeIf(canDelete(RESOURCE_NAME));

export const canEditLocationsAccess = authorizeIf(
  can(permission(RESOURCE_NAME, EDIT_LOCATIONS_ACCESS)),
);
