import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { BlackBar } from './BlackBar';
import { JetBlackBar } from './JetBlackBar';
import { OrangeBar } from './OrangeBar';

const StatusDistributionChart = ({
  classes,
  orangeCampaignsPercentage,
  jetBlackCampaignsPercentage,
  blackCampaignsPercentage,
}) => (
  <div className={classes.wrapper}>
    <Typography variant="h4" className={classes.label}>
      Дистрибьюция переходов по статусу
    </Typography>
    <div className={classes.bar}>
      <OrangeBar percentage={orangeCampaignsPercentage} />
      <BlackBar percentage={blackCampaignsPercentage} />
      <JetBlackBar percentage={jetBlackCampaignsPercentage} />
    </div>
  </div>
);

StatusDistributionChart.propTypes = {
  orangeCampaignsPercentage: PropTypes.number.isRequired,
  jetBlackCampaignsPercentage: PropTypes.number.isRequired,
  blackCampaignsPercentage: PropTypes.number.isRequired,
};

export default StatusDistributionChart;
