import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';
import Table from '@/features/parkingWhitelistGroupDetailsPage/Page/Table';
import { ROUTE_PATH as WHITELIST_GROUPS_PATH } from '@/features/parkingWhitelistGroupsPage/consts';

const WhitelistGroupDetailsPage = ({
  name,
  isLoaded,
  userIds,
  getUserById,
  error,
  history,
  isLoading,
  parkingWhitelistIds,
  totalCount,
}) => {
  const [users, setUsers] = useState([]);
  // const loadUsers = () => {
  //   console.log(userIds);
  //   // setUsers(userIds.map(id => getUserById(id)));
  //   console.log(getUserById(14));
  //
  //   // const filtersForQuery = [];
  //
  //   // filters.forEach(filter => {
  //   //   for (const [filterType, value] of filter.value.entries()) {
  //   //     filtersForQuery.push([filter.key, filterType, value]);
  //   //   }
  //   // });
  //
  //   // api
  //   //   .doQuery(filtersForQuery, 'id', 'asc', 1000)
  //   //   .then(userQuery => this.setState({ users: userQuery.payload.user }))
  //   //   .finally(() => this.setState({ isUsersLoading: false }));
  // };

  console.log(error, parkingWhitelistIds, isLoading, totalCount);
  // useEffect(() => {
  //   if (userIds?.length) {
  //     loadUsers();
  //   }
  // }, [userIds]);

  return (
    isLoaded && (
      <SettingsPage>
        <SettingsPageHeader borderBottom={false} gutterBottom>
          <Breadcrumbs>
            <Breadcrumb to={WHITELIST_GROUPS_PATH}>
              Whitelist Тимирязева группы
            </Breadcrumb>
          </Breadcrumbs>
          <SettingsPageTitle>Группа «{name}»</SettingsPageTitle>
        </SettingsPageHeader>
        <SettingsPageContent>
          <div style={{ maxWidth: '100%', margin: '0 10px' }}>
            <Table
              error={error}
              items={parkingWhitelistIds}
              isLoading={isLoading}
              totalCount={totalCount}
            />
          </div>
        </SettingsPageContent>
      </SettingsPage>
    )
  );
};

WhitelistGroupDetailsPage.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  userIds: PropTypes.array,
  name: PropTypes.string,
  getUserById: PropTypes.func.isRequired,
  error: PropTypes.any,
  parkingWhitelistIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

WhitelistGroupDetailsPage.defaultProps = {
  userIds: [],
  name: '',
};

export default WhitelistGroupDetailsPage;
