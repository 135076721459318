export default ({ spacing }) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  dimmed: {
    backgroundColor: '#F5F5F5',
  },

  cancelButton: {
    marginRight: spacing.unit * 2,
  },
});
