import { Modal as MaterialModal } from '@material-ui/core';
import React, { Component } from 'react';

class Modal extends Component {
  render() {
    const { children, ...rest } = this.props;

    return <MaterialModal {...rest}>{children}</MaterialModal>;
  }
}

export default Modal;
