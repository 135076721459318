import { RESOURCE_NAME } from '@esentai/core/features/reconciliationRefunds/consts';
import { canCreate, canRead, canUpdate } from '@esentai/core/policy-dsl';

import { authorizeIf } from '@/features/currentUser/rbac';

export const canReadReconciliationRefunds = authorizeIf(canRead(RESOURCE_NAME));
export const canCreateReconciliationRefunds = authorizeIf(
  canCreate(RESOURCE_NAME),
);
export const canUpdateReconciliationRefunds = authorizeIf(
  canUpdate(RESOURCE_NAME),
);
