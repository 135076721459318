import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BackButton from '@/components/BackButton';
import Breadcrumbs, { Breadcrumb } from '@/components/Breadcrumbs';
import Page from '@/components/Page';
import PageContent from '@/components/PageContent';
import PageHeader from '@/components/PageHeader';
import PageTitle from '@/components/PageTitle';
import PaperSheet from '@/components/PaperSheet';
import { ROUTE_PATH as GIFT_SETS_PATH } from '@/features/giftSetsPage/consts';

import Actions from './Actions';
import Description from './Description';

class GiftSet extends Component {
  static propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    itemId: PropTypes.string.isRequired,
  };

  render() {
    const { isLoaded, itemId } = this.props;

    if (!isLoaded) {
      return null;
    }

    return (
      <>
        <Page>
          <PageHeader gutterBottom={false}>
            <Breadcrumbs>
              <Breadcrumb to={GIFT_SETS_PATH}>Подарочный набор</Breadcrumb>
            </Breadcrumbs>
            <BackButton />
            <PageTitle>Редактирование набора</PageTitle>
          </PageHeader>
          <PageContent>
            <PaperSheet>
              <Description giftSetId={itemId} />
              <Actions />
            </PaperSheet>
          </PageContent>
        </Page>
      </>
    );
  }
}

export default GiftSet;
