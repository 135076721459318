import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Benefit from '@/features/campaignPage/Page/Rewards/Benefits';

const BenefitRewardList = ({ benefitRewardIds, ...props }) => (
  <Fragment>
    {benefitRewardIds &&
      benefitRewardIds.map(benefitId => (
        <Benefit
          key={benefitId}
          benefitId={benefitId}
          SelectionControl={() => null}
          {...props}
        />
      ))}
  </Fragment>
);

BenefitRewardList.propTypes = {
  benefitRewardIds: PropTypes.array,
};

BenefitRewardList.defaultProps = {
  benefitRewardIds: [],
};

export default BenefitRewardList;
