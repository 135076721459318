import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import UserNameLazy from '@/containers/UserNameLazy';
import { dateTimeFormat, formatNumber } from '@/utils/format';

const Row = ({ id, customerId, amount, classes, recordCreated }) => (
  <TableRow>
    <TableCell>{id}</TableCell>
    <TableCell>
      {customerId ? (
        <Link className={classes.link} to={`/users/${customerId}`}>
          {customerId}
        </Link>
      ) : (
        '-'
      )}
    </TableCell>
    <TableCell>
      {customerId ? (
        <Link className={classes.link} to={`/users/${customerId}`}>
          <UserNameLazy userId={customerId} />
        </Link>
      ) : (
        '-'
      )}
    </TableCell>
    {/* <TableCell>*/}
    {/*  {commissarId ? (*/}
    {/*    <Link className={classes.link} to={`/users/${commissarId}`}>*/}
    {/*      <UserNameLazy userId={commissarId} />*/}
    {/*    </Link>*/}
    {/*  ) : (*/}
    {/*    '-'*/}
    {/*  )}*/}
    {/* </TableCell>*/}
    {/* <TableCell>*/}
    {/*  {userId ? (*/}
    {/*    <Link className={classes.link} to={`/users/${userId}`}>*/}
    {/*      <UserNameLazy userId={userId} />*/}
    {/*    </Link>*/}
    {/*  ) : (*/}
    {/*    '-'*/}
    {/*  )}*/}
    {/* </TableCell>*/}

    {/* <TableCell>{type}</TableCell>*/}
    <TableCell>{amount ? formatNumber(amount) : '-'}</TableCell>
    <TableCell>{recordCreated ? dateTimeFormat(recordCreated) : '-'}</TableCell>
    <TableCell>Изменение уровня</TableCell>
  </TableRow>
);

Row.propTypes = {
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired,
  commissarId: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  recordCreated: PropTypes.instanceOf(Date).isRequired,
};

export default Row;
