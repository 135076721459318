import { LAYOUT_KEY } from '@esentai/core/features/campaigns/article/consts/keys';
import React from 'react';

import Block from '@/components/ArticleConstructorForm/Block';

import { CanEdit } from '../../CanEdit';

const BlockWithPermissions = props => (
  <CanEdit field={LAYOUT_KEY}>
    <Block {...props} />
  </CanEdit>
);

export default BlockWithPermissions;
