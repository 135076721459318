import { path, prop } from 'ramda';

import { ENTITIES_STORE_KEY } from '@/redux/constants';

import { ENTITY_NAME } from './consts';
import { LOCATION_ID_KEY } from './consts/keys';

export const getDaoRoot = path([ENTITIES_STORE_KEY, ENTITY_NAME]);

export const getIdProp = prop(LOCATION_ID_KEY);
