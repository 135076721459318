export default {
  radioBox: {
    marginRight: 16,
    marginBottom: 16,
    padding: [4, 20, 18, 20],
    color: 'rgba(0, 0, 0, 0.87)',
    width: 300,
    height: ({ children }) => (children ? 200 : 155),
    boxShadow: [0, 4, 10, '#bbb'],
    cursor: 'pointer',
  },

  checked: {
    backgroundColor: '#eee',
  },

  control: {},

  disabled: {
    pointerEvents: 'none',
  },

  label: {
    fontSize: 24,
    lineHeight: 1,
    alignSelf: 'flex-start',
    paddingTop: 13,
  },

  body: {
    marginLeft: 35,
  },
};
