import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { formatMoney } from '@/utils/format';

import { ReceiptPurchase } from '../index';

export interface ReceiptPurchasesTableProps {
  items: ReceiptPurchase[];
}

class ReceiptPurchasesTable extends React.Component<
  ReceiptPurchasesTableProps
> {
  render() {
    const { items } = this.props;

    return (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Название товара</TableCell>
            <TableCell>Сумма за единицу товара</TableCell>
            <TableCell>Количество товаров</TableCell>
            <TableCell>Итого</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => (
            <TableRow key={item.name}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{formatMoney(item.price)}</TableCell>
              <TableCell>{item.current_amount}</TableCell>
              <TableCell>{formatMoney(item.cost)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default ReceiptPurchasesTable;
