import createTaskSaga from '@esentai/core/ducks/tasks/createSaga';
import { remove } from '@esentai/core/features/brands/sagas';
import { call, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { DELETE_BRAND, RETRY_FETCH } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export const fetchItems = createQuerySaga(itemsDuck.sagas.fetchItems);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* deleteBrand(action) {
  const { payload: brandId } = action;

  yield call(remove, brandId);
  yield call(fetchItems);
}

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
  yield takeEvery(DELETE_BRAND, createTaskSaga(deleteBrand));
}
