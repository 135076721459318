const aspectRatio = `${(9 / 16) * 100}%`;

export default {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: 10,
  },

  image: ({ imageUrl }) => ({
    flexBasis: '100%',
    [imageUrl ? 'backgroundImage' : 'backgroundColor']: imageUrl
      ? `url(${imageUrl})`
      : '#C7C7C7',
    paddingBottom: aspectRatio,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: '0 auto',
    marginBottom: 6,
  }),

  placeholder: {
    flexBasis: 'calc(50% - 3px)',
    height: 60,
    backgroundColor: '#C7C7C7',
    marginBottom: 6,
  },
};
