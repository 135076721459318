import { withStyles } from '@material-ui/core';

import { createRangeFilterTemplate } from '@/filters/Range';
import { createFormatter } from '@/filters/Range/utils';
import { formatHumanDateTime } from '@/utils/format';

import DateTimeRangeFilter from './component';
import styles from './styles';

const FilterComponent = withStyles(styles)(DateTimeRangeFilter);

export const createDateTimeRangeFilter = createRangeFilterTemplate({
  FilterComponent,
  FormatComponent: createFormatter({ format: formatHumanDateTime }),
});

export default FilterComponent;
