export default {
  root: {
    color: '#bdbdbd',
    fontSize: 24,
    fontFamily: 'Roboto',
    justifyContent: 'flex-start',
    transition: 'color 0.1s',

    '&:hover': {
      color: '#a0a0a0',
    },
  },

  icon: {
    marginRight: 8,
  },
};
