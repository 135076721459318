import PropTypes from 'prop-types';
import React from 'react';

import PageWrapper from '@/features/authPages/PageWrapper';

import { propTypes as strategyPropTypes } from './strategies';

const ForgotPassword = ({ strategy, email, recoverPassword }) => {
  const { Body } = strategy;

  return (
    <PageWrapper>
      <Body onSubmit={recoverPassword} email={email} />
    </PageWrapper>
  );
};

ForgotPassword.propTypes = {
  email: PropTypes.string,
  recoverPassword: PropTypes.func.isRequired,
  strategy: strategyPropTypes.isRequired,
};

ForgotPassword.defaultProps = {
  email: null,
};

export default ForgotPassword;
