import {
  ACCESSORIES,
  ANCHORS,
  CHILDREN,
  FOOD,
  HEALTH_AND_BEAUTY,
  HOME,
  JEWELRY_AND_WATCHES,
  LUXURY,
  PREMIUM,
  RESTAURANTS_AND_CAFE,
  SERVICES,
} from '@esentai/core/features/stores/consts/categories';

const options = [
  {
    key: LUXURY,
    label: 'Люкс',
  },
  {
    key: PREMIUM,
    label: 'Премиум',
  },
  {
    key: FOOD,
    label: 'Фуд корт',
  },
  {
    key: RESTAURANTS_AND_CAFE,
    label: 'Рестораны и кафе',
  },
  {
    key: ANCHORS,
    label: 'Якори',
  },
  {
    key: SERVICES,
    label: 'Сервисы',
  },
  {
    key: ACCESSORIES,
    label: 'Аксессуары и сувениры',
  },
  {
    key: HOME,
    label: 'Товары для дома',
  },
  {
    key: HEALTH_AND_BEAUTY,
    label: 'Health & Beauty',
  },
  {
    key: JEWELRY_AND_WATCHES,
    label: 'Ювелирные изделия и часы',
  },
  {
    key: CHILDREN,
    label: 'Товары для детей',
  },
  // {
  //   key: ELECTRONICS,
  //   label: 'Электроника',
  // },
  // {
  //   key: ENTERTAINMENT,
  //   label: 'Развлекательная программа',
  // },
  // {
  //   key: FINANCIAL_SERVICES,
  //   label: 'Финансовые услуги',
  // },
  // {
  //   key: MEN,
  //   label: 'Для мужчин',
  // },
  // {
  //   key: SERVICES,
  //   label: 'Сервисы',
  // },
  // {
  //   key: SHOES,
  //   label: 'Обувь',
  // },
  // {
  //   key: WOMEN,
  //   label: 'Для женщин',
  // },
];

export default options;
