export default {
  root: {
    display: 'flex',
    flexDirection: 'row',
  },

  submitButton: {
    marginRight: 20,
    marginBottom: 32,
  },
};
