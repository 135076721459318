import {
  ACTION_RESULT_TYPE,
  ARTICLE_TYPE,
  BANNER_TYPE,
  BUTTON_TYPE,
  CALENDAR_EVENT_TYPE,
  NOTIFICATION_TYPE,
  PURCHASE_RESULT_TYPE,
} from '@esentai/core/features/campaigns/consts/types';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import StatusDistributionChart from '@/components/StatusDistributionChart';
import TableCellTypography from '@/components/TableCellTypography';
import { formatConversion, formatNumber } from '@/utils/format';

import ActionResult from './ActionResult';
import BannerArticleCalendarEvent from './BannerArticleCalendarEvent';
import Notification from './Notification';
import PurchaseResult from './PurchaseResult';

const cellsContentComponents = {
  [ACTION_RESULT_TYPE]: ActionResult,
  [ARTICLE_TYPE]: BannerArticleCalendarEvent,
  [BANNER_TYPE]: BannerArticleCalendarEvent,
  [BUTTON_TYPE]: Notification,
  [CALENDAR_EVENT_TYPE]: BannerArticleCalendarEvent,
  [NOTIFICATION_TYPE]: Notification,
  [PURCHASE_RESULT_TYPE]: PurchaseResult,
};

const RowCellsContent = ({
  classes,
  segment,
  FirstCell,
  conversion,
  campaignType,
  clicksPercentageByOrangeLeveledUsers,
  clicksPercentageByJetBlackLeveledUsers,
  clicksPercentageByBlackLeveledUsers,
  ...rest
}) => {
  const CellsContent = cellsContentComponents[campaignType];

  return (
    <Fragment>
      <CondenseTableCell>{FirstCell}</CondenseTableCell>
      <CondenseTableCell align="right">
        {formatNumber(segment)}
      </CondenseTableCell>
      <CellsContent {...rest} />
      <CondenseTableCell align="right">
        <TableCellTypography>
          {formatConversion(conversion)}
        </TableCellTypography>
      </CondenseTableCell>
      <CondenseTableCell align="right">
        <TableCellTypography
          classes={{
            root: classes.chart,
          }}
        >
          <StatusDistributionChart
            orangeUsersPercentage={clicksPercentageByOrangeLeveledUsers}
            jetBlackUsersPercentage={clicksPercentageByJetBlackLeveledUsers}
            blackUsersPercentage={clicksPercentageByBlackLeveledUsers}
          />
        </TableCellTypography>
      </CondenseTableCell>
    </Fragment>
  );
};

RowCellsContent.propTypes = {
  segment: PropTypes.number,
  FirstCell: PropTypes.func.isRequired,
  conversion: PropTypes.number,
  campaignType: PropTypes.string.isRequired,
  clicksPercentageByOrangeLeveledUsers: PropTypes.number,
  clicksPercentageByJetBlackLeveledUsers: PropTypes.number,
  clicksPercentageByBlackLeveledUsers: PropTypes.number,
};

RowCellsContent.defaultProps = {
  segment: null,
  conversion: null,
  clicksPercentageByOrangeLeveledUsers: null,
  clicksPercentageByJetBlackLeveledUsers: null,
  clicksPercentageByBlackLeveledUsers: null,
};

export default RowCellsContent;
