import { createPageFilterTemplate } from '@/structs/pageFilter';
import { createEqualsAdapter } from '@/structs/pageFilterAdapter';

import StoreFilter from './component';
import FormatComponent from './FormatComponent';

export const createStoreFilter = createPageFilterTemplate({
  FilterComponent: StoreFilter,
  FormatComponent,
  adapter: createEqualsAdapter(null),
});

export default StoreFilter;
