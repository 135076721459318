import {
  ACTIVE_BONUSES_KEY,
  DEVICES_CHANGES_COUNT_KEY,
  ID_KEY,
  IS_SUSPICIOUS_KEY,
  LAST_ACTIVITY_DATE_KEY,
  LAST_NAME_KEY,
  LAST_PURCHASE_DATE_KEY,
  MONEY_SPENT_KEY,
  RECEIPTS_COUNT_KEY,
  RECORD_CREATED_KEY,
  STATUS_KEY,
} from '@esentai/core/features/users/consts/keys';
import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CondenseTableCell from '@/components/CondenseTableCell';
import CanView from '@/containers/CanView';
import { canUpdateUser } from '@/features/usersPage/permissions';

const HeadRow = ({ classes, SortControl }) => (
  <TableRow>
    <CondenseTableCell>
      <SortControl field={ID_KEY}>ID Клиента</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={LAST_NAME_KEY}>Имя, фамилия пользователя</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={STATUS_KEY}>Уровень лояльности</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={MONEY_SPENT_KEY}>Сумма покупок</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={RECEIPTS_COUNT_KEY}>Количество покупок</SortControl>
    </CondenseTableCell>
    <CondenseTableCell className={classes.secondaryColumn}>
      <SortControl field={LAST_PURCHASE_DATE_KEY}>
        Последняя покупка
      </SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={ACTIVE_BONUSES_KEY}>Активные бонусы</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={DEVICES_CHANGES_COUNT_KEY}>
        Смен устройств
      </SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={IS_SUSPICIOUS_KEY}>Подозрительный</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={RECORD_CREATED_KEY}>Дата регистрации</SortControl>
    </CondenseTableCell>
    <CondenseTableCell>
      <SortControl field={LAST_ACTIVITY_DATE_KEY}>
        Последняя активность
      </SortControl>
    </CondenseTableCell>
    <CanView permission={canUpdateUser}>
      <CondenseTableCell>Действия</CondenseTableCell>
    </CanView>
  </TableRow>
);

HeadRow.propTypes = {
  SortControl: PropTypes.func.isRequired,
};

export default HeadRow;
