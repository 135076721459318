import { prop } from 'ramda';

// eslint-disable-next-line import/no-cycle
import {
  createPageFilterUpdate,
  isPageFilterValueEmpty,
  readPageFilterValue,
} from './pageFilter';

export const createQuickFilter = (value, label, pageFilter) => ({
  label,
  pageFilter,
  value,
});

export const getQuickFilterId = prop('label');

export const getQuickFilterLabel = prop('label');

export const isQuickFilterSelected = (quickFilter, filter) => {
  const { pageFilter, value } = quickFilter;

  if (isPageFilterValueEmpty(pageFilter, filter)) {
    return false;
  }

  const filterValue = readPageFilterValue(pageFilter, filter);

  return Array.isArray(filterValue)
    ? filterValue.includes(value)
    : filterValue === value;
};

export const getQuickFilterUpdate = quickFilter => {
  const { pageFilter, value } = quickFilter;

  return createPageFilterUpdate(pageFilter, value);
};
