import { goBack } from 'connected-react-router';
import { omit } from 'ramda';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';

import Button from '@/components/Button';

const mapDispatchToProps = { onClick: goBack };

export default compose(
  connect(null, mapDispatchToProps),
  mapProps(props => ({
    size: 'large',
    variant: 'text',
    ...omit(['dispatch'], props),
  })),
)(Button);
