import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import CanView from '@/containers/CanView';
import { ROUTE_PATH as GROUPS_ROUTE_PATH } from '@/features/parkingWhitelistGroupsPage/consts';
import Actions from '@/features/parkingWhitelistGroupsPage/Page/Row/Actions';
import { canUpdateWhitelistGroups } from '@/features/parkingWhitelistGroupsPage/permissions';

const Row = ({
  // classes,
  parkingWhitelistGroupId,
  // isActive,
  // SelectionToggle,
  name,
  users,
}) => (
  <TableRow>
    {/* <TableCell padding="checkbox">*/}
    {/*  <SelectionToggle item={parkingWhitelistGroupId} />*/}
    {/* </TableCell>*/}
    <TableCell>
      <Link to={`${GROUPS_ROUTE_PATH}/${parkingWhitelistGroupId}`}>
        {parkingWhitelistGroupId}
      </Link>
    </TableCell>
    <TableCell>{name || '–'}</TableCell>
    <TableCell>{users?.length}</TableCell>
    {/* <TableCell style={{ whiteSpace: 'nowrap' }}>*/}
    {/*  {dateTimeFormat(recordCreated) || '–'}*/}
    {/* </TableCell>*/}
    <CanView permission={canUpdateWhitelistGroups}>
      <TableCell>
        <Actions parkingWhitelistGroupId={parkingWhitelistGroupId} />
      </TableCell>
    </CanView>
  </TableRow>
);

Row.propTypes = {
  parkingWhitelistGroupId: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  users: PropTypes.array,
  // recordCreated: PropTypes.instanceOf(Date),
  SelectionToggle: PropTypes.func.isRequired,
};
Row.defaultProps = {
  isActive: null,
  name: '',
  users: [],
  recordCreated: null,
};

export default Row;
