import {
  START_DATE_KEY,
  STORE_KEY,
  USER_KEY,
} from '@esentai/core/features/storeBonuses/consts/keys';
import { annotations } from '@esentai/core/features/storeBonuses/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

export default createPageSerializer({
  ...annotations,

  sort: sort({
    defaultValue: ascending(START_DATE_KEY),
  }),
  include: string({
    defaultValue: [STORE_KEY, USER_KEY],
  }),
});
