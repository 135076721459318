import {
  END_DATE_KEY,
  START_DATE_KEY,
} from '@esentai/core/features/storeBonuses/consts/keys';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { mapProps } from 'recompose';

import { REPEAT_SEND_DATETIMES } from '@/features/storeBonusCreatePage/consts/keys';

import RepeatSchedule from './component';
import styles from './styles';

export default compose(
  mapProps(({ setFieldValue, values, Field }) => ({
    initialSendDatetime: values[START_DATE_KEY],
    finalSendDatetime: values[END_DATE_KEY],
    repeatSendDatetimes: values[REPEAT_SEND_DATETIMES],
    setFieldValue,
    Field,
  })),
  withStyles(styles),
)(RepeatSchedule);
