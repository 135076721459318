export default {
  datepickers: {
    display: 'flex',
    margin: [16, 0, 8],
    alignItems: 'center',
  },

  datepicker: {
    flex: 'none',
    width: 320,
    margin: [0, 10, 30, 0],
  },

  titleField: {
    width: 651,
    marginBottom: 15,
  },

  reconciliationSelect: {
    display: 'flex',
    flexDirection: 'column',
    margin: [16, 0, 8],
    alignItems: 'center',
  },
};
