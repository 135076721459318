import {
  getBannerEnglishTitle,
  getBannerRussianTitle,
} from '@esentai/core/features/campaigns/banner/selectors';
import { connect } from 'react-redux';

import Banner from './component';

const mapStateToProps = (state, { itemId }) => ({
  russianTitle: getBannerRussianTitle(state, itemId),
  englishTitle: getBannerEnglishTitle(state, itemId),
});

export default connect(mapStateToProps)(Banner);
