import PropTypes from 'prop-types';
import React from 'react';

const Autocomplete = ({ Component, ...props }) => <Component {...props} />;

Autocomplete.propTypes = {
  Component: PropTypes.func.isRequired,
};

export default Autocomplete;
