import { yupToFormErrors } from 'formik';
import PropTypes from 'prop-types';
import { mergeRight } from 'ramda';
import React, { Component } from 'react';

import FileFiled from '@/components/FileFiled';
import { getImageSize } from '@/utils/image-meta';
import { isImageFile } from '@/utils/is-image-file';

import { getValidationErrors } from './utils';

export default class ImageField extends Component {
  static propTypes = {
    onValidationError: PropTypes.func,
    upload: PropTypes.func,
    fileInputProps: PropTypes.object,
    onChange: PropTypes.func,
    validationDimensionSchema: PropTypes.shape({
      width: PropTypes.any,
      height: PropTypes.any,
    }),
    validationFileSchema: PropTypes.shape({
      type: PropTypes.any,
      size: PropTypes.any,
      name: PropTypes.any,
      lastModified: PropTypes.any,
    }),
    includePdf: PropTypes.bool,
  };

  static defaultProps = {
    onValidationError: () => {},
    onChange: () => {},
    upload: () => {},
    validationDimensionSchema: null,
    validationFileSchema: null,
    fileInputProps: {},
    includePdf: false,
  };

  upload = async newFiles => {
    const {
      validationDimensionSchema,
      validationFileSchema,
      onValidationError,
    } = this.props;

    const files = newFiles.filter(isImageFile);

    if (files.length === 0) {
      return onValidationError({ type: 'Только изображения' });
    }

    for (const file of files) {
      if (validationFileSchema) {
        const error = await getValidationErrors(file, validationFileSchema);

        if (error) {
          return onValidationError(yupToFormErrors(error));
        }
      }

      if (validationDimensionSchema) {
        const size = await getImageSize(file);
        const error = await getValidationErrors(
          size,
          validationDimensionSchema,
        );

        if (error) {
          return onValidationError(yupToFormErrors(error));
        }
      }
    }

    const { upload } = this.props;

    onValidationError(null);
    upload(files);
  };

  render() {
    const { fileInputProps, includePdf, ...other } = this.props;
    const newFileInputProps = mergeRight(
      { accept: `image/jpeg,image/png,${includePdf ? 'application/pdf' : ''}` },
      fileInputProps,
    );

    return (
      <FileFiled
        {...other}
        fileInputProps={newFileInputProps}
        upload={this.upload}
      />
    );
  }
}
