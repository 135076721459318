import { connect } from 'react-redux';

import {
  getAveragePurchasesById,
  getAverageReceiptsById,
  getAverageTicketById,
  getPercentageReceiptsByCampaignById,
  getPurchasesCountById,
} from '@/features/purchasesStatistics/selectors';

import Row from './component';

const mapStateToProps = (state, { item: itemId }) => ({
  averagePurchases: getAveragePurchasesById(state, itemId),
  averageReceipts: getAverageReceiptsById(state, itemId),
  averageTicket: getAverageTicketById(state, itemId),
  purchasesCount: getPurchasesCountById(state, itemId),
  percentageReceiptsByCampaign: getPercentageReceiptsByCampaignById(
    state,
    itemId,
  ),
  itemId,
});

export default connect(mapStateToProps)(Row);
