import { IMG_RLS_KEY } from '@esentai/core/features/campaigns/show/consts/keys';
import api from '@esentai/core/features/products/api';
import { Avatar, Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import React, { Component } from 'react';

import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FormikPropTypes from '@/prop-types/formik';

class ContentForm extends Component {
  static propTypes = FormikPropTypes;

  constructor(props) {
    super(props);

    this.state = {
      products: props.productIds || [],
      images: props.values.image_urls || [],
      addProduct: -1,
      items: [],
    };
  }

  componentDidMount() {
    this.loadStores();
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.productIds &&
      this.props.productIds &&
      this.props.productIds.length > 0
    ) {
      this.loadStores();
    }
  }

  addNew() {
    this.setState({ addProduct: 0 });
  }

  loadStores() {
    const { images } = this.state;

    if (this.props.productIds) {
      api.doQuery([], 'id', 'asc', 9999).then(data => {
        const curProducts = data.payload.product.reduce((d, c) => {
          d[c.id] = c;

          return d;
        }, {});

        this.props.productIds.reduce((imgs, cur) => {
          imgs.push(...curProducts[cur].images);

          return imgs;
        }, images);

        this.setState(
          { items: curProducts, products: this.props.productIds, images },
          this.saveImages(),
        );
      });
    }
  }

  saveImages() {
    const { images } = this.state;
    const { setFieldValue } = this.props;

    setFieldValue(IMG_RLS_KEY, images);
  }

  render() {
    const { classes } = this.props;
    const { products, items } = this.state;
    const itemsCount = Object.keys(items).length;

    return (
      <>
        <FieldRow label="Товары">
          <Grid container spacing={40}>
            <Grid item xs={12}>
              <FieldDescription title="" />
              <div className={classes.list}>
                {itemsCount > 0 && (
                  <List>
                    {products
                      .filter(p => p && items[p])
                      .map((prod, ind) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <ListItem key={ind} className={classes.item}>
                          <ListItemAvatar>
                            <Avatar
                              src={
                                items[prod] && items[prod].images.length > 0
                                  ? items[prod].images[0]
                                  : ''
                              }
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={items[prod].title_ru}
                            secondary={`${items[prod].price} Тенге`}
                          />
                        </ListItem>
                      ))}
                  </List>
                )}
              </div>
            </Grid>
          </Grid>
        </FieldRow>
      </>
    );
  }
}

export default ContentForm;
