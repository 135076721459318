import { findKkmById } from '@esentai/core/features/kkms/actions';
import {
  getKkmLoadingError,
  isKkmLoaded,
  isKkmLoading,
} from '@esentai/core/features/kkms/selectors';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';

import { updateKkm } from '@/features/kkmEditPage/actions';
import { fetchKkm as fetchItem } from '@/features/kkmPage/actions';
import { getRouterParameterByMatch } from '@/redux/router/selectors';

import component from './component';

const ID_KEY = 'kkmId';

const mapStateToProps = (state, { match }) => {
  const kkmId = getRouterParameterByMatch(match, ID_KEY);

  return {
    error: getKkmLoadingError(state, kkmId),
    isLoaded: isKkmLoaded(state, kkmId),
    isLoading: isKkmLoading(state, kkmId),
    kkmId,
  };
};

const mapDispatchToProps = (dispatch, { match }) => ({
  updateKkm: values =>
    dispatch(
      updateKkm({
        ...values,
        kkmId: getRouterParameterByMatch(match, ID_KEY),
        status: values.kkmStatus ? 'active' : 'inactive',
      }),
    ),
  fetchItem,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    fetchItem: findKkmById,
    getItemLoadingError: getKkmLoadingError,
    isItemLoaded: isKkmLoaded,
    isItemLoading: isKkmLoading,
  }),
)(component);
