// import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

// import ActivateButton from '../../buttons/ActivateButton';
// import ArchiveButton from '../../buttons/ArchiveButton';
// import CloneButton from '../../buttons/CloneButton';
// import EditButton from '../../buttons/EditButton';
// import StatisticButton from '../../buttons/StatisticButton';

const PausedStatusActions = () => (
  <Fragment>
    {/* <ActivateButton itemId={itemId} /> */}
    {/* <CloneButton itemId={itemId} /> */}
    {/* <EditButton itemId={itemId} /> */}
    {/* <ArchiveButton itemId={itemId} /> */}
    {/* <StatisticButton itemId={itemId} /> */}
  </Fragment>
);

// PausedStatusActions.propTypes = {
//   itemId: PropTypes.string.isRequired,
// };

export default PausedStatusActions;
