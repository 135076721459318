import { LAYOUT_KEY } from '@esentai/core/features/campaigns/article/consts/keys';
import { ID_KEY } from '@esentai/core/features/campaigns/article/consts/layoutKeys';
import { ONLINE_SHOWCASE_LAYOUT } from '@esentai/core/features/campaigns/article/consts/layouts';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  createEmptyBlock,
  createOnlineShowCaseBlock,
  getCountByType,
} from '@/components/ArticleConstructorForm/utils';
import FieldDescription from '@/components/FieldDescription';
import FieldRow from '@/components/FieldRow';
import FormikPropTypes from '@/prop-types/formik';
import moveItem from '@/utils/move-item';

import DefaultAddButton from './AddButton';
import DefaultBlock from './Block';
import Preview from './Preview';

class ArticleConstructorForm extends Component {
  static propTypes = {
    ...FormikPropTypes,
    label: PropTypes.string.isRequired,
  };

  state = {
    isAddShowcaseButtonVisible: false,
  };

  static defaultProps = {
    Block: DefaultBlock,
    AddButton: DefaultAddButton,
  };

  renderPreview = () => <Preview layout={this.props.values[LAYOUT_KEY]} />;

  addBlock = () => {
    const newLayout = [...this.getLayout(), createEmptyBlock()];

    this.setLayout(newLayout);
  };

  addShowCaseBlock = () => {
    const newLayout = [...this.getLayout(), createOnlineShowCaseBlock()];

    this.setLayout(newLayout);
    this.handleAddShowcaseButton(newLayout);
  };

  handleRemoveBlock = block => {
    const newLayout = this.getLayout().filter(item => item !== block);

    this.setLayout(newLayout);
    this.handleAddShowcaseButton(newLayout);
  };

  changePosition = (block, where) => {
    const newLayout = moveItem(this.getLayout(), block, where);

    this.setLayout(newLayout);
  };

  handleChange = data => {
    const newLayout = this.getLayout().map(item =>
      item[ID_KEY] === data[ID_KEY] ? data : item,
    );

    this.setLayout(newLayout);
  };

  getLayout = () => {
    const { values } = this.props;

    return values[LAYOUT_KEY];
  };

  handleAddShowcaseButton(layout) {
    const isShowcase = Boolean(
      layout.find(item => item.type === ONLINE_SHOWCASE_LAYOUT),
    );

    if (isShowcase) {
      this.setState({
        isAddShowcaseButtonVisible: false,
      });
    } else {
      this.setState({
        isAddShowcaseButtonVisible: true,
      });
    }
  }

  setLayout = layout => {
    const { setFieldValue } = this.props;

    setFieldValue(LAYOUT_KEY, layout, false);
  };

  handleBlockUp = data => this.changePosition(data, 'up');

  handleBlockDown = data => this.changePosition(data, 'down');

  componentDidMount() {
    const { values } = this.props;
    const layout = values[LAYOUT_KEY];

    this.handleAddShowcaseButton(layout);
  }

  render() {
    const {
      label,
      Field,
      setFieldValue,
      errors,
      Block,
      AddButton,
    } = this.props;

    const [
      firstRequiredBlock,
      secondRequiredBlock,
      thirdRequiredBlock,
      ...otherBlocks
    ] = this.getLayout();
    const countByType = getCountByType(otherBlocks);

    return (
      <FieldRow
        label={label}
        subLabel="Конструктор статьи"
        sudDesc="С помощью конструктора статьи вы можете из заранее подготовленных блоков составить страницу контента. У статьи есть несколько обязательных блоков, которые вы не можете убрать или изменить их местоположение. Однако, в останом вы свобдны конструировать страницу так, как вам угодно."
        Aside={this.renderPreview}
      >
        <Grid container spacing={40}>
          <Grid item xs={12}>
            <FieldDescription
              title="Главный баннер"
              desc="Обязательный блок. Главный баннер статьи размещается в самом верху экрана. Этот блок с типом «Изображение» может принимать только вид одиночного изображения или слайдера."
            />

            <Block
              data={firstRequiredBlock}
              errors={errors}
              Field={Field}
              index={0}
              setFieldValue={setFieldValue}
              onChange={this.handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FieldDescription
              title="Заголовок статьи"
              desc="Обязательный блок. Заголовок будет отображаться под баннером. Не более 50 символов. Заголовок статьи является самым главным заголовком, поэтому он отличается от других заголовков размером."
            />

            <Block
              data={secondRequiredBlock}
              errors={errors}
              Field={Field}
              index={1}
              setFieldValue={setFieldValue}
              onChange={this.handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FieldDescription
              title="Первый текстовый блок"
              desc="Обязательный блок. Первый текстовый блок отображается сразу после заголовка. "
            />

            <Block
              data={thirdRequiredBlock}
              errors={errors}
              Field={Field}
              index={2}
              setFieldValue={setFieldValue}
              onChange={this.handleChange}
              required
            />
          </Grid>

          {this.state.isAddShowcaseButtonVisible && (
            <Grid item xs={12}>
              <AddButton onClick={this.addShowCaseBlock}>
                Добавить онлайн витрину
              </AddButton>
            </Grid>
          )}

          {otherBlocks.map((data, index) => (
            <Grid item key={data[ID_KEY]} xs={12}>
              <Block
                data={data}
                errors={errors}
                Field={Field}
                index={index + 3}
                countByType={countByType}
                setFieldValue={setFieldValue}
                onRemove={this.handleRemoveBlock}
                onChange={this.handleChange}
                onUp={this.handleBlockUp}
                onDown={this.handleBlockDown}
                position={this.getPosition(data)}
              />
            </Grid>
          ))}

          {this.isShowAddButton() && (
            <Grid item xs={12}>
              <AddButton onClick={this.addBlock}>
                Добавить еще один блок
              </AddButton>
            </Grid>
          )}
        </Grid>
      </FieldRow>
    );
  }

  isShowAddButton() {
    return false;
  }

  getPosition(data) {
    const layout = this.getLayout();
    const targetBlocks = layout.slice(3);

    const index = targetBlocks.indexOf(data);

    if (targetBlocks.length <= 1) {
      return null;
    } else if (index === 0) {
      return 'start';
    } else if (index === targetBlocks.length - 1) {
      return 'end';
    }

    return 'middle';
  }
}

export default ArticleConstructorForm;
