import { connect } from 'react-redux';

import { createStorePageUrl } from '@/features/storePage/utils';

import StorePageLink from './component';

const mapStateToProps = (state, { storeId }) => ({
  storePath: createStorePageUrl(storeId),
});

export default connect(mapStateToProps)(StorePageLink);
