import { MAP_SECTION } from '@/features/settingsPage/consts/sections';
import { getSectionUrl } from '@/features/settingsPage/structs/section';

export const ROUTE_PATH = getSectionUrl(MAP_SECTION);

export const BEACON_ROUTE_PATH = `${ROUTE_PATH}/beacon/:beacon`;

export const FLOOR_ROUTE_PATH = `${ROUTE_PATH}/floor/:floor`;

export const LOCATION_ROUTE_PATH = `${ROUTE_PATH}/location/:location`;

export const BEACON_AND_FLOOR_ROUTE_PATH = `${FLOOR_ROUTE_PATH}/beacon/:beacon`;

export const LOCATION_AND_FLOOR_ROUTE_PATH = `${FLOOR_ROUTE_PATH}/location/:location`;

export const FEATURE_NAME = 'mapPage';
