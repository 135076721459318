import { Button, CircularProgress } from '@material-ui/core';
import {
  Check as CheckIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import React, { Component } from 'react';

const DEFAULT_STAGE = 'default';
const LOADING_STAGE = 'loading';
const SUCCESS_STAGE = 'success';
const FAILURE_STAGE = 'failure';

const getNextStage = (currentStage, { isLoading, error }) => {
  if (currentStage === DEFAULT_STAGE && isLoading) {
    return LOADING_STAGE;
  }

  if (currentStage === LOADING_STAGE) {
    if (isLoading) {
      return LOADING_STAGE;
    }

    if (error) {
      return FAILURE_STAGE;
    }

    return SUCCESS_STAGE;
  }

  return currentStage;
};

class AsyncButton extends Component {
  static propTypes = {
    ButtonComponent: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    error: PropTypes.instanceOf(Error),
    returnDelay: PropTypes.number,
  };

  static defaultProps = {
    ButtonComponent: Button,
    disabled: false,
    error: null,
    returnDelay: 2000,
    isLoading: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { stage } = prevState;
    const nextStage = getNextStage(stage, nextProps);

    if (stage !== nextStage) {
      return { stage: nextStage };
    }

    return null;
  }

  state = {
    stage: getNextStage(DEFAULT_STAGE, this.props),
  };

  componentDidUpdate() {
    const { stage } = this.state;

    if (stage === SUCCESS_STAGE || stage === FAILURE_STAGE) {
      const { returnDelay } = this.props;

      clearTimeout(this._timeout);

      this._timeout = setTimeout(
        () => this.setState({ stage: DEFAULT_STAGE }),
        returnDelay,
      );
    }
  }

  render() {
    const { stage } = this.state;
    const { ButtonComponent, classes, disabled, ...rest } = this.props;
    const isInLoadingStage = stage === LOADING_STAGE;
    const otherClasses = omit(['successIcon', 'errorIcon'], classes);

    return (
      <ButtonComponent
        {...omit(['returnDelay', 'isLoading', 'error'], rest)}
        classes={otherClasses}
        disabled={disabled || isInLoadingStage}
      >
        {this.renderContent()}
      </ButtonComponent>
    );
  }

  componentWillUnmount() {
    clearTimeout(this._timeout);
  }

  renderContent() {
    const { stage } = this.state;
    const { children, classes } = this.props;

    if (stage === LOADING_STAGE) {
      return <CircularProgress size={24} />;
    }

    if (stage === SUCCESS_STAGE) {
      return <CheckIcon className={classes.successIcon} />;
    }

    if (stage === FAILURE_STAGE) {
      return <HighlightOffIcon className={classes.errorIcon} />;
    }

    return children;
  }
}

export default AsyncButton;
