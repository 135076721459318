import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import { createAction } from 'redux-actions';

import query from './ducks/query';
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  MOVE_CATEGORY_DOWN,
  MOVE_CATEGORY_UP,
  RETRY_FETCH,
  UPDATE_CATEGORY,
} from './types';

export const { updateLimit, updatePage, updateSort } = query.actions;

export const deleteCategory = createTaskAction(DELETE_CATEGORY);

export const moveCategoryDown = createTaskAction(MOVE_CATEGORY_DOWN);

export const moveCategoryUp = createTaskAction(MOVE_CATEGORY_UP);

export const createCategory = createTaskAction(CREATE_CATEGORY);

export const updateCategory = createTaskAction(UPDATE_CATEGORY);

export const retryFetch = createAction(RETRY_FETCH);
