import { getBarComponent } from './utils';

const EveningBar = getBarComponent({
  styles: {
    bar: {
      backgroundColor: '#e4e4e4',
    },
  },
});

export default EveningBar;
