import { Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ShowcaseTooltip = ({ classes, title, children }) => (
  <Tooltip
    title={<Typography className={classes.textColor}>{title}</Typography>}
    placement="top"
  >
    <span>{children}</span>
  </Tooltip>
);

ShowcaseTooltip.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ShowcaseTooltip;
