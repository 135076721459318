import { archiveStore } from '@esentai/core/features/stores/actions';
import { isStoreActive } from '@esentai/core/features/stores/selectors';
import withAsyncRunner from '@esentai/core/hocs/withAsyncRunner';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import { canUpdateStore } from '@/features/storesPage/permissions';

import ArchiveButton from './component';

const mapStateToProps = (state, { storeId }) => ({
  isActive: isStoreActive(state, storeId),
  permission: canUpdateStore,
});

const mapDispatchToProps = (dispatch, { storeId }) => ({
  onClick: () => dispatch(archiveStore(storeId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAsyncRunner({ runnerMethod: 'onClick' }),
)(ArchiveButton);
