import {
  ARCHIVE_ID_KEY,
  STORE_ID_KEY,
} from '@esentai/core/features/reconciliationReceipts/consts/keys';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, select, spawn, takeEvery } from 'redux-saga/effects';

import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { getArchiveId, getArchiveStoreId, getStoreId } from './selectors';
import { RETRY_FETCH } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

export function* fetchItemsForCurrentStore(query) {
  let storeId = yield select(getStoreId);

  if (!storeId) {
    storeId = yield select(getArchiveStoreId);
  }
  const archiveId = yield select(getArchiveId);
  const storeQuery = mergeQueriesRight(
    query,
    makeQuery(where(STORE_ID_KEY, equals(storeId))),
  );
  const archiveQuery = archiveId
    ? mergeQueriesRight(
        storeQuery,
        makeQuery(where(ARCHIVE_ID_KEY, equals(archiveId))),
      )
    : storeQuery;

  yield call(itemsDuck.sagas.fetchItems, archiveQuery);
}

export const fetchItems = createQuerySaga(fetchItemsForCurrentStore);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
