import { compose, indexBy, map, prop, props } from 'ramda';

import categories from '@/components/CategoriesInput/categories';

const categoriesLabels = compose(
  map(prop('label')),
  indexBy(prop('key')),
)(categories);

const CategoriesTitle = ({ categoryKeys }) => {
  if (categoryKeys) {
    return props(categoryKeys, categoriesLabels).join(', ');
  }

  return null;
};

export default CategoriesTitle;
