import {
  getPeriodReceipts,
  getReceiptsCount,
  getSingleReceipt,
  getSingleReceiptItemsCount,
} from '@esentai/core/features/campaigns/purchaseResult/selectors';
import { connect } from 'react-redux';

import Triggers from './component';

const mapStateToProps = (state, { itemId }) => ({
  periodReceipts: getPeriodReceipts(state, itemId),
  receiptsCount: getReceiptsCount(state, itemId),
  singleReceipt: getSingleReceipt(state, itemId),
  singleReceiptItemsCount: getSingleReceiptItemsCount(state, itemId),
});

export default connect(mapStateToProps)(Triggers);
