import {
  EVENT_END_DATETIME_KEY,
  EVENT_START_DATETIME_KEY,
} from '@esentai/core/features/campaigns/calendarEvent/consts/keys';
import { DATE_END_KEY } from '@esentai/core/features/campaigns/consts/keys';
import { Grid } from '@material-ui/core';
import React, { Component } from 'react';

import DateTimePicker from '@/components/DateTimePicker';
import FieldRow from '@/components/FieldRow';
import FormikPropTypes from '@/prop-types/formik';

import { CanEdit } from '../../CanEdit';

class DateForm extends Component {
  static propTypes = FormikPropTypes;

  handleChangeEndDate = event => {
    const { setFieldValue } = this.props;
    const { value } = event.target;

    setFieldValue(EVENT_END_DATETIME_KEY, value);
    setFieldValue(DATE_END_KEY, value);
  };

  render() {
    const { values, classes, Field } = this.props;
    const startDatetime = values[EVENT_START_DATETIME_KEY];

    return (
      <FieldRow
        label="Дата проведения"
        subLabel="Дата проведения календарного события"
        sudDesc="Дата и время начала и завершения события, которые будут отображаться в календаре мобильного приложения."
      >
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <CanEdit field={EVENT_START_DATETIME_KEY}>
              <Field
                disablePast
                label="Дата начала"
                className={classes.datepicker}
                Target={DateTimePicker}
                name={EVENT_START_DATETIME_KEY}
              />
            </CanEdit>
          </Grid>

          <Grid item xs={12}>
            <CanEdit field={EVENT_END_DATETIME_KEY}>
              <Field
                label="Дата завершения"
                className={classes.datepicker}
                Target={DateTimePicker}
                name={EVENT_END_DATETIME_KEY}
                onChange={this.handleChangeEndDate}
                minDate={startDatetime}
              />
            </CanEdit>
          </Grid>
        </Grid>
      </FieldRow>
    );
  }
}

export default DateForm;
