import {
  getActiveSpentForUser,
  getAverageCheckForUser,
  getBillingStartDateForUser,
  getDeviceChangesCountForUser,
  getMoneySpentForUser,
  getPendingSpentForUser,
  getReceiptsCountForUser,
  getTotalSpentForUser,
} from '@esentai/core/features/users/selectors';
import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import SecondaryFacts from './component';
import styles from './styles';

const mapStateToProps = (state, { userId }) => ({
  averageCheck: getAverageCheckForUser(state, userId),
  totalSpent: getTotalSpentForUser(state, userId),
  activeSpent: getActiveSpentForUser(state, userId),
  pendingSpent: getPendingSpentForUser(state, userId),
  moneySpent: getMoneySpentForUser(state, userId),
  billingStartDate: new Date(getBillingStartDateForUser(state, userId)),
  deviceChangesCount: getDeviceChangesCountForUser(state, userId),
  receiptsCount: getReceiptsCountForUser(state, userId),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(SecondaryFacts);
