import createTaskAction from '@esentai/core/ducks/tasks/createTaskAction';
import createAction from 'redux-actions/lib/createAction';

import {
  FETCH_LOCATION,
  UPDATE_LOCATION,
} from '@/features/locationEditPage/consts/types';

export const updateLocation = createTaskAction(
  UPDATE_LOCATION,
  undefined,
  task => task.locationId,
);

export const fetchLocation = createAction(FETCH_LOCATION);
