import { TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import CondenseTableCell from '@/components/CondenseTableCell';
import { ROUTE_PATH as CATEGORY_PATH } from '@/features/productsCategoryPage/consts';

import Actions from './Actions';
import SortControls from './SortControls';

const Row = ({ russianName, itemId }) => (
  <TableRow>
    <CondenseTableCell>
      <Link to={`${CATEGORY_PATH}/${itemId}`}>{russianName}</Link>
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <SortControls itemId={itemId} />
    </CondenseTableCell>
    <CondenseTableCell align="right">
      <Actions itemId={itemId} />
    </CondenseTableCell>
  </TableRow>
);

Row.propTypes = {
  itemId: PropTypes.number.isRequired,
  russianName: PropTypes.string.isRequired,
};

export default Row;
