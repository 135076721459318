import { withStyles } from '@material-ui/core';
import { compose } from 'ramda';
import { connect } from 'react-redux';

import {
  getError,
  isLoadingItems,
} from '@/features/locationsStatisticsPage/selectors';

import MapWorkspace from './component';
import styles from './styles';

const mapStateToProps = state => ({
  error: getError(state),
  loading: isLoadingItems(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(MapWorkspace);
