import { NAME_KEY } from '@esentai/core/features/brands/consts/keys';
import { STORES_RELATION } from '@esentai/core/features/brands/consts/relations';
import { annotations } from '@esentai/core/features/brands/serializer';
import { ascending } from '@esentai/core/query-dsl';
import { sort, string } from '@esentai/core/serializers/params';

import { createPageSerializer } from '@/serializers';

const serializer = createPageSerializer({
  ...annotations,

  include: string({
    defaultValue: [STORES_RELATION],
  }),
  sort: sort({
    defaultValue: ascending(NAME_KEY),
  }),
});

export default serializer;
