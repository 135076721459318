import { MODERATING_STATUS } from '@esentai/core/features/campaigns/consts/statuses';
import { getCampaignStatus } from '@esentai/core/features/campaigns/selectors';
import { both, compose, equals, pipe, prop } from 'ramda';

import { canModerateCampaign } from '@/features/campaignsPage/permissions';
import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';

const getParams = pipe(createMatchSelector(ROUTE_PATH), prop('params'));

export const getCampaignType = pipe(getParams, prop('type'));

export const getCampaignId = pipe(getParams, prop('id'));

export const canModerate = state => {
  const campaignId = getCampaignId(state);
  const isModerating = compose(equals(MODERATING_STATUS), getCampaignStatus);
  const predicate = both(isModerating, canModerateCampaign);

  return predicate(state, campaignId);
};

export const getProductId = pipe(getParams, prop('id'));
