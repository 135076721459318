import { connect } from 'react-redux';

import {
  getClicksCount,
  getClicksToCoveragePercentage,
  getConversionRate,
  getCoverage,
  getSegment,
  getViewsCount,
  getViewsToSegmentPercentage,
} from '@/features/campaignsStatisticsDetailsPage/selectors';

import AllFacts from './component';

const mapStateToProps = (state, { itemId }) => ({
  segmentSize: getSegment(state, itemId),
  viewsCount: getViewsCount(state, itemId),
  coverage: getCoverage(state, itemId),
  clicksCount: getClicksCount(state, itemId),
  viewsToSegmentPercentage: getViewsToSegmentPercentage(state, itemId),
  clicksToCoveragePercentage: getClicksToCoveragePercentage(state, itemId),
  conversion: getConversionRate(state, itemId),
});

export default connect(mapStateToProps)(AllFacts);
