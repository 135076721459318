import PropTypes from 'prop-types';
import React from 'react';
import { defaultProps } from 'recompose';

import SettingsPage from '@/components/SettingsPage';
import SettingsPageContent from '@/components/SettingsPageContent';
import SettingsPageHeader from '@/components/SettingsPageHeader';
import SettingsPageTitle from '@/components/SettingsPageTitle';

import FormCreate from './Form';

const Form = defaultProps({
  englishAnswer: '',
  englishQuestion: '',

  russianAnswer: '',
  russianQuestion: '',

  kazakhAnswer: '',
  kazakhQuestion: '',

  submitButtonText: 'Создать вопрос',
})(FormCreate);

const NewQuestion = ({ createQuestion }) => (
  <SettingsPage>
    <SettingsPageHeader>
      <SettingsPageTitle>Создать вопрос</SettingsPageTitle>
    </SettingsPageHeader>
    <SettingsPageContent>
      <Form onSubmit={createQuestion} />
    </SettingsPageContent>
  </SettingsPage>
);

NewQuestion.propTypes = {
  createQuestion: PropTypes.func.isRequired,
};

export default NewQuestion;
