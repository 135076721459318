import PropTypes from 'prop-types';
import React from 'react';

import LocationNameLazy from '@/containers/LocationNameLazy';

const FormatComponent = ({ value }) => <LocationNameLazy locationId={value} />;

FormatComponent.propTypes = {
  value: PropTypes.string.isRequired,
};

export default FormatComponent;
