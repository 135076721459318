import { Chip as MuiChip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Chip extends Component {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
  };

  onDelete = event => {
    const { onDelete, id } = this.props;

    onDelete(id, event);
  };

  render() {
    const { onDelete, id, ...props } = this.props;

    return (
      <MuiChip {...props} key={id} onDelete={onDelete ? this.onDelete : null} />
    );
  }
}

export default Chip;
