import { PinDrop as PinDropIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import ActionButton from '@/components/ActionButton';
import Button from '@/components/Button';
import CanView from '@/containers/CanView';

const MapButton = ({ mapPageUrl, permission, ...rest }) => (
  <CanView permission={permission}>
    <ActionButton
      text="Показать на карте"
      button={Button}
      icon={PinDropIcon}
      href={mapPageUrl}
      {...rest}
    />
  </CanView>
);

MapButton.propTypes = {
  mapPageUrl: PropTypes.string.isRequired,
  permission: PropTypes.func.isRequired,
};

export default MapButton;
