import PropTypes from 'prop-types';
import React from 'react';

import Link from '@/components/Link';
import Tag from '@/components/Tag';
import StoreNameLazy from '@/containers/StoreNameLazy';

const StorePageLink = ({ storePath, storeId }) => (
  <Link to={storePath}>
    <Tag label={<StoreNameLazy storeId={storeId} />} />
  </Link>
);

StorePageLink.propTypes = {
  storeId: PropTypes.number.isRequired,
  storePath: PropTypes.string.isRequired,
};

export default StorePageLink;
