import { GROUP_ID_KEY } from '@esentai/core/features/parkingLevelThreeWhitelist/consts/keys';
import { mergeQueriesRight } from '@esentai/core/queries';
import { equals, query as makeQuery, where } from '@esentai/core/query-dsl';
import { call, select } from '@redux-saga/core/effects';
import { compose, prop } from 'ramda';
import { spawn, takeEvery } from 'redux-saga/effects';

import { createMatchSelector } from '@/redux/router/selectors';

import { ROUTE_PATH } from './consts';
import itemsDuck from './ducks/items';
import queryDuck from './ducks/query';
import { RETRY_FETCH } from './types';

const {
  createQuerySaga,
  createQueryChangeWatcher,
  synchronizeQuery,
} = queryDuck.sagas;

const { getQuery } = queryDuck.selectors;
//
// export const fetchItemsForGroup = function*() {
//   // const { params } = yield select(createMatchSelector({ path: ROUTE_PATH }));
//   // const { groupId } = params;
//   const query = yield select(getQuery);
//   const newQuery = mergeQueriesRight(
//     query,
//     makeQuery(where(GROUP_ID_KEY, equals(14))),
//   );
//
//   console.log(query, newQuery);
//
//   yield call(itemsDuck.sagas.fetchItems, query);
// };

const getParams = createMatchSelector(ROUTE_PATH);

export const getGroupId = compose(prop('groupId'), prop('params'), getParams);

export function* fetchItemsForGroup(query) {
  const storeId = yield select(getGroupId) || 14;
  const storeQuery = mergeQueriesRight(
    query,
    makeQuery(where(GROUP_ID_KEY, equals(storeId))),
  );

  yield call(itemsDuck.sagas.fetchItems, storeQuery);
}

export const fetchItems = createQuerySaga(fetchItemsForGroup);

export const watchQueryChange = createQueryChangeWatcher(fetchItems);

export function* watchRetry() {
  yield takeEvery(RETRY_FETCH, fetchItems);
}

export default function*() {
  yield spawn(watchRetry);
  yield spawn(synchronizeQuery);
  yield spawn(watchQueryChange);
}
